/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import React, {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Modal } from 'components/UiControls/modal/modal';
import { ModalOptions } from 'utils/enum';
import {
  Column,
  Row,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { IUsageRecord } from 'utils/models/payment.model';
import { ISOStringToDate, toDateWithHours } from 'utils/date.util';
import { twBaseClickable } from 'components/shared/twin.styles';
import Loader from 'components/loader/loader';
import { BillingService } from 'services';

interface IPromiseParams {
  resolve: (option: ModalOptions) => void;
  reject: (err: any) => void;
}

interface IProps {
  title?: string;
  cancelText?: string;
  confirmText?: string;
}

export const HistoryDetailsModal = forwardRef(
  (props: IProps, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (): Promise<unknown> => {
      setIsLoading(true);
      BillingService.getConsumptionHistory()
        .then((res) => {
          setIsLoading(false);
          const rawHistory: IUsageRecord[] = res.data.data;
          const formattedHistory: IUsageRecord[] = rawHistory
            .map((record) => {
              record.uploaddatetime = ISOStringToDate(record?.uploaddatetime);
              return record;
            })
            .sort(
              (a, b) =>
                new Date(b.uploaddatetime).getTime() -
                new Date(a.uploaddatetime).getTime(),
            );

          setHistory(formattedHistory);
        })
        .catch(() => setIsLoading(false));

      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const hideModal = () => setIsOpen(false);

    // const handleYes = () => {
    //   hideModal();
    //   promiseInfo.current?.resolve(ModalOptions.YES);
    // };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current?.resolve(ModalOptions.CANCEL);
    };

    const columns = useMemo(
      (): Column<IUsageRecord>[] => [
        { Header: 'Date', accessor: 'uploaddatetime' },
        { Header: 'File name', accessor: 'filename' },
        { Header: 'Plan', accessor: 'tenantplan' },
        { Header: 'Usage (minutes)', accessor: 'transcriptionminutes' },
      ],
      [],
    );

    const [history, setHistory] = useState<IUsageRecord[]>([]);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,

      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      nextPage,
      previousPage,
      state: { pageIndex },
    } = useTable(
      {
        columns,
        data: history,
      },
      useSortBy,
      usePagination,
    ) as any;

    return (
      <Modal show={isOpen} modalClosed={hideModal}>
        <div className="userModal_Popus">
          <h2>{props.title || 'Confirm'}</h2>

          <div tw="font-size[1.4rem] flex flex-col">
            <table {...getTableProps()} tw="max-w-full">
              <thead>
                {headerGroups.map((headerGroup: any) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    tw="whitespace-nowrap"
                  >
                    {headerGroup.headers.map((column: any) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows?.length === 0 && (
                  <tr>
                    <td colSpan={4}>
                      {isLoading ? (
                        <div tw="flex justify-center my-4">
                          <span tw="width[4rem] flex justify-center items-center">
                            <Loader />
                          </span>
                        </div>
                      ) : (
                        'Nothing to show'
                      )}
                    </td>
                  </tr>
                )}
                {page.map((row: Row<IUsageRecord>) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      <td tw="min-width[15rem]">
                        <span tw="whitespace-nowrap!">{toDateWithHours(row.original.uploaddatetime)}</span>
                      </td>
                      <td tw="max-h-10" title={row.original.filename}>
                        <span className="line-clamp-1" tw="word-break[break-all]">
                          {row.original.filename}
                        </span>
                      </td>
                      <td>{row.original.tenantplan}</td>
                      <td tw="text-center whitespace-nowrap">
                        {row.original.transcriptionminutes}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {history?.length > 0 && (
              <div
                tw="flex w-full justify-center items-center"
                css={paginationStyles}
              >
                <button
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {'<'}
                </button>
                <span tw="px-2">
                  Page{' '}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>
                </span>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  {'>'}
                </button>
              </div>
            )}
          </div>

          <div className="grid-x">
            <div className="cell small-12">
              <div className="btn_section">
                <button
                  type="button"
                  className="button cancel"
                  onClick={handleCancel}
                >
                  {props?.cancelText || 'Close'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);

const paginationStyles = css`
  &:focus,
  button {
    ${tw`(p-2 w-12 h-12 mx-1)!`}
    ${twBaseClickable}
  }
`;
