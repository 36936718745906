/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import '@emotion/react';

import React, {
  forwardRef,
  ReactElement,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Modal } from 'components/UiControls/modal/modal';
import { Dropdown, HTTPStatus, ModalOptions } from 'utils/enum';
import {
  BulkUploadPayload,
  PlaylistIdOption as PlaylistPreviewOption,
  ProgramIdOption as ProgramPreviewOption,
} from 'utils/models';
import { CustomSelectSearch } from 'components/shared/CustomSelectSearch';
import { debounce, isEmpty } from 'lodash';
import { GroupBase, StylesConfig } from 'react-select';
import { GetOmnyPreviewResponse, UploadService } from 'services';
import { useToggle } from 'react-use';
import { CollectionDropdown } from '../CollectionDropdown/CollectionDropdown';
import { OmnyService } from 'services/omny.service';
import { AxiosResponse } from 'axios';
import {
  toOmnyPlaylistOptions,
  toOmnyProgramOptions,
} from 'utils/adapter.util';

type BulkUploadOption = Partial<BulkUploadPayload> & {
  option: ModalOptions;
};

interface IPromiseParams {
  resolve: (payload: BulkUploadOption) => void;
  reject: (err: any) => void;
}

export interface BulkUploadRef {
  show: () => Promise<BulkUploadOption>;
}

interface Props {}

export const OmnyBulkUploadModal = forwardRef(
  (props: Props, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const [isOpen, setIsOpen] = useState(false);

    const initialState = {
      programId: null,
      apiKey: null,
      collectionId: null,
      playlistId: null,
      playlistProgramId: null,
    };

    const [state, setState] = useState<BulkUploadPayload>(initialState);

    const [isLoadingList, toggleLoadingList] = useToggle(false);

    const [programOptions, setProgramOptions] = useState<
      ProgramPreviewOption[]
    >([]);
    const [playlistOptions, setPlaylistOptions] = useState<
      PlaylistPreviewOption[]
    >([]);

    // Has API Key and at least Program or Playlist selected
    const isValidPreview =
      state.apiKey && (state.programId || state.playlistId);

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (): Promise<unknown> => {
      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const handleReset = () => {
      setState(initialState);
      resetOptionList();
    };

    const hideModal = () => {
      handleReset();
      setIsOpen(false);
    };

    const handlePreview = () => {
      hideModal();

      promiseInfo.current?.resolve({
        option: ModalOptions.YES,
        programId: state.playlistId ? state.playlistProgramId : state.programId,
        // organisationId: state.organisationId,
        apiKey: state.apiKey,
        collectionId: state.collectionId,
        playlistId: state.playlistId,
      });
    };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current?.resolve({ option: ModalOptions.CANCEL });
    };

    const resetOptionList = () => {
      setProgramOptions([]);
      setPlaylistOptions([]);

      setState({
        ...state,
        programId: null,
        playlistId: null,
        playlistProgramId: null,
      });
    };

    const getProgramId = async (inputKey: string, shouldUseApiKey = true) => {
      if (isEmpty(inputKey)) return;

      try {
        toggleLoadingList(true);

        let response: AxiosResponse<GetOmnyPreviewResponse>;

        if (shouldUseApiKey) {
          response = await OmnyService.getProgramId(inputKey);
        } else {
          response = await UploadService.getProgramIdOmny(inputKey);
        }

        if (response.status === HTTPStatus.SUCCESS) {
          const data = response.data;

          const listProgramLabel: ProgramPreviewOption[] = toOmnyProgramOptions(
            data.programs,
          );
          setProgramOptions(listProgramLabel);

          const listPlaylistLabel: PlaylistPreviewOption[] =
            toOmnyPlaylistOptions(data.playlists);
          setPlaylistOptions(listPlaylistLabel);

          setState({
            ...state,
            apiKey: inputKey,
          });
        }
      } catch (error) {
        console.log('error :>> ', error);
        resetOptionList();
      } finally {
        toggleLoadingList(false);
      }
    };

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      const value = e.target.value.trim();

      setState({ ...state, apiKey: value });
      getProgramId(value);
    };

    const debounceGetProgramId = useCallback(debounce(handleInput, 400), []);

    const renderCustomOption = (option: ProgramPreviewOption) => (
      <div tw="flex flex-row">
        <div tw="max-width[5.5rem] mr-4 flex flex-basis[5.5rem]">
          <img alt="" src={option.imageURL} />
        </div>

        <div tw="flex flex-col w-full">
          <div
            tw="text-15 font-black text-left text-sonnant-dark break-words"
            className="line-clamp-2"
          >
            {option.label}
          </div>
          <div tw="text-12">{option.value}</div>
        </div>
      </div>
    );

    const handleSelectProgramOption = (option: ProgramPreviewOption) => {
      setState({
        ...state,
        programId: option.value,

        playlistId: null,
        playlistProgramId: null,
      });
    };

    const handleSelectPlaylistOption = (option: PlaylistPreviewOption) => {
      setState({
        ...state,
        programId: null,

        playlistId: option.value,
        playlistProgramId: option.programId,
      });
    };

    const getLabel = (
      options: (ProgramPreviewOption | PlaylistPreviewOption)[],
      id: string | null,
    ) => {
      return options.find(({ value }) => value === id)?.label;
    };

    return (
      <div>
        <Modal
          show={isOpen}
          modalClosed={() => {
            hideModal();
            promiseInfo.current?.resolve({ option: ModalOptions.CLOSE });
          }}
        >
          <form
            tw="px-2"
            autoComplete="off"
            onSubmit={(e) => e.preventDefault()}
          >
            <h1 tw="font-semibold text-sonnant-dark font-size[3.5rem]">
              Omny bulk upload
            </h1>

            <div tw="flex flex-col mt-5 pb-3 pt-0!">
              <div tw="flex flex-row items-center">
                <label tw="flex flex-nowrap text-16 mr-3">API Key</label>
              </div>

              <div tw="flex flex-row max-width[75rem]">
                <div tw="flex-1 relative ">
                  <input
                    tw="flex padding-right[4rem]!"
                    type="text"
                    onChange={debounceGetProgramId}
                    placeholder="Please enter your API Key"
                  />
                </div>
              </div>
            </div>

            <div tw="flex flex-row gap-x-4 pb-6 pt-0!">
              <div tw="flex flex-col flex-1">
                <div tw="flex flex-row items-center">
                  <label tw="flex flex-nowrap text-16 mr-3">Programs</label>
                </div>

                <div tw="flex-1 relative w-full">
                  <CustomSelectSearch
                    options={programOptions}
                    defaultValue={getLabel(programOptions, state.programId)}
                    isLoading={isLoadingList}
                    customStyles={defaultStyles}
                    formatOptionLabel={renderCustomOption}
                    onChange={handleSelectProgramOption}
                    canCreate={false}
                    mode={Dropdown.SIMPLE_TEXT}
                  />
                </div>
              </div>

              <div tw="text-sonnant-grey-6 flex items-end text-13 font-medium mb-3">
                OR
              </div>

              <div tw="flex flex-col flex-1">
                <div tw="flex flex-row items-center">
                  <label tw="flex flex-nowrap text-16 mr-3">Playlists</label>
                </div>

                <div tw="flex-1 relative w-full">
                  <CustomSelectSearch
                    options={playlistOptions}
                    defaultValue={getLabel(playlistOptions, state.playlistId)}
                    isLoading={isLoadingList}
                    customStyles={defaultStyles}
                    formatOptionLabel={renderCustomOption}
                    onChange={handleSelectPlaylistOption}
                    canCreate={false}
                    mode={Dropdown.SIMPLE_TEXT}
                  />
                </div>
              </div>
            </div>

            <div tw="flex [> div]:(flex text-16 font-medium text-sonnant-grey-6)!">
              <div tw="flex-col">
                <div tw="mb-2">Add to collection</div>
                <div>
                  <CollectionDropdown
                    defaultValue={state.collectionId ?? ''}
                    onChange={(value) => {
                      setState({ ...state, collectionId: value });
                    }}
                    menuPlacement="bottom"
                    isMultiSelect={false}
                  />
                </div>
              </div>
            </div>
            <div tw="flex flex-col pb-3 pt-0!">
              <div tw="flex justify-end">
                <button
                  type="button"
                  className="button btn-secondary large"
                  tw="mr-6 hover:(border-sonnant-grey-3!)"
                  onClick={handleCancel}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="button btn-primary large"
                  onClick={handlePreview}
                  disabled={!isValidPreview}
                >
                  Preview
                </button>
              </div>
            </div>
          </form>
        </Modal>
      </div>
    );
  },
);

const defaultStyles: StylesConfig<any, false, GroupBase<any>> = {
  container: (base) => ({
    ...base,
    height: '4rem',
    zIndex: 20,
  }),
  control: (base) => ({
    ...base,
    height: '4rem',
    padding: 0,
    backgroundColor: '#f0f3f6',
    borderColor: 'transparent',
  }),
  menu: (base) => ({
    ...base,
    marginBottom: '4px',
    marginTop: '4px',
  }),
  menuList: (base) => ({
    ...base,
    overflowX: 'hidden',
  }),
  input: (base) => ({
    ...base,
    height: '3rem',
    paddingTop: '3px',
    position: 'relative',
    top: '-8px',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    paddingLeft: '0.8rem',
    fontSize: '1.53rem',
  }),
  placeholder: (base) => ({
    ...base,
    color: 'rgb(51, 51, 51)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '1.5rem',
  }),
  option: (base) => ({
    ...base,
    cursor: 'pointer',
    color: 'rgb(51, 51, 51)',
  }),
  noOptionsMessage: (base) => ({
    ...base,
    fontSize: '1.5rem',
  }),
  loadingMessage: (base) => ({
    ...base,
    fontSize: '1.5rem',
  }),
};
