import {
  add,
  addDays,
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  formatRelative,
  isBefore,
  isFuture,
  startOfDay,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns';
import { format as formatTz, utcToZonedTime } from 'date-fns-tz';
import enAu from 'date-fns/locale/en-AU';
import { isNaN, toNumber } from 'lodash';
import { CalendarFilter } from './enum';
import { DateRange } from './models';

export const timestampToDate = (timestamp: any, timeZone: string): string => {
  if (!timestamp) timestamp = 0;
  const date = timestamp ? new Date(timestamp * 1000) : new Date();

  return format(toDateTz(date, timeZone) as Date, 'PPp');
};

export const ISOStringToDate = (ISOString: string): string => {
  if (!ISOString) return 'Invalid date';

  return format(new Date(ISOString), 'PPp');
};

export const isInFuture = (timestamp: number): boolean => {
  if (!timestamp) return false; // included NaN

  return isBefore(new Date(), new Date(timestamp * 1000));
};

export const toDateWithHours = (
  rawDate: string | Date | number | null | undefined,
  timeZone?: string,
): string => {
  if (!rawDate) return 'Invalid date';

  try {
    if (!timeZone) {
      return format(new Date(rawDate), 'dd MMM yyyy - HH:mm') ?? 'Invalid Date';
    } else {
      return (
        format(
          utcToZonedTime(new Date(rawDate), timeZone),
          'dd MMM yyyy - HH:mm',
        ) ?? 'Invalid Date'
      );
    }
  } catch {
    return 'Invalid Date';
  }
};

export const toDateWithoutHours = (rawDate: string | Date | number) => {
  if (!rawDate) return 'Invalid date';
  return format(new Date(rawDate), 'dd MMM yyyy') ?? 'Invalid Date';
};

export const toHours = (rawDate: string | Date): string => {
  if (!rawDate) return '';

  return format(new Date(rawDate), 'hh:mm aaa') ?? 'Invalid Date';
};

export const toFormat = (
  rawDate: string | number | Date,
  pattern: string,
): string => {
  if (!rawDate) return 'Invalid date';

  return format(new Date(rawDate), pattern) ?? 'Invalid Date';
};

export const getDateExport = (timeZone?: string): string => {
  const zonedDate = utcToZonedTime(
    new Date(),
    timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
  return formatTz(zonedDate, 'yyyy-MM-dd_p', { timeZone });
};

export const toDateTz = (
  date: string | Date,
  timeZone?: string,
): string | Date => {
  const zonedDate = utcToZonedTime(
    new Date(date),
    timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
  );

  return zonedDate;
};

export const toFormatTz = (
  date: string | Date,
  format: string,
  timeZone: string,
): string => {
  return formatTz(toDateTz(date), format, { timeZone });
};

const formatRelativeLocale: any = {
  lastWeek: "'Last' eeee - dd MMM yyyy",
  yesterday: "'Yesterday' - dd MMM yyyy",
  today: "'Today' - dd MMM yyyy",
  tomorrow: "'Tomorrow' - dd MMM yyyy",
  nextWeek: "'Next' eeee - dd MMM yyyy",
  other: "'Older' - dd MMM yyyy",
  // other: "'Older'",
};

export const toRelativeDays = (fromDate: Date | string): string => {
  try {
    return formatRelative(new Date(fromDate), new Date(), {
      locale: {
        ...enAu,
        formatRelative: (token) => formatRelativeLocale[token],
      },
    });
  } catch (err: any) {
    console.log(`Parsing error`, err);
    return 'Invalid date';
  }
};

export const toRelativeDaysTz = (
  fromDate: Date | string,
  timeZone?: string,
): string => {
  if (!timeZone) {
    return toRelativeDays(fromDate);
  }

  try {
    return formatRelative(
      utcToZonedTime(new Date(fromDate), timeZone),
      utcToZonedTime(new Date(), timeZone),
      {
        locale: {
          ...enAu,
          formatRelative: (token) => formatRelativeLocale[token],
        },
      },
    );
  } catch (err: any) {
    console.log(`Parsing error`, err);
    return 'Invalid date';
  }
};

export const toDateWithTime = (date: Date | number): string => {
  return format(date, "MMMM d 'at' p");
};

export const toDateApiKey = (
  rawDate: string | Date | number,
  timeZone?: string,
): string => {
  if (!rawDate) return 'Invalid date';

  // Handle case where date is a string of number (timestamp)
  const formattedDate = !isNaN(rawDate) ? toNumber(rawDate) : rawDate;

  try {
    const dateFormat = 'eee, MMMM d, yyyy, p';
    if (!timeZone) {
      return format(new Date(formattedDate), dateFormat) ?? 'Invalid Date';
    } else {
      return (
        format(utcToZonedTime(new Date(formattedDate), timeZone), dateFormat) ??
        'Invalid Date'
      );
    }
  } catch {
    return 'Invalid Date';
  }
};

export const shortDate = (date: string | number | Date): string => {
  return toFormat(date, 'd MMM');
};

export const shortDateYear = (date: string | number | Date): string => {
  return toFormat(date, 'd MMM yyyy');
};

export const getWeekAfter = (fromDate: string | number | Date): Date => {
  const weekAfter = addDays(new Date(fromDate), 6);

  return isFuture(weekAfter) ? new Date() : weekAfter;
};

export const getMonthAfter = (fromDate: string | number | Date): Date => {
  const monthAfter = endOfMonth(new Date(fromDate));
  return isFuture(monthAfter) ? new Date() : monthAfter;
};

export const getYearAfter = (fromDate: string | number | Date): Date => {
  const yearAfter = endOfYear(new Date(fromDate));

  return isFuture(yearAfter) ? new Date() : yearAfter;
};

export const getDateBetween = (
  timestamp: Date | number,
  calendarMode: CalendarFilter | null,
): [Date, Date] => {
  const startDate = startOfDay(timestamp);

  switch (calendarMode) {
    case CalendarFilter.WEEK:
      return [startDate, endOfDay(getWeekAfter(timestamp))];
    case CalendarFilter.MONTH:
      return [startDate, endOfDay(getMonthAfter(timestamp))];
    case CalendarFilter.YEAR:
      return [startDate, endOfDay(getYearAfter(timestamp))];
    default:
      return [startDate, endOfDay(timestamp)];
  }
};

export const startOfDayISO = (
  rawInput: Date | number | string | undefined | null,
): string | undefined => {
  if (!rawInput) return undefined;

  const noTzDate = fixTimezoneOffset(new Date(rawInput as string));

  return new Date(noTzDate).toISOString();
};

export const endOfDayISO = (
  rawInput: Date | number | string | undefined | null,
): string | undefined => {
  if (!rawInput) return undefined;

  const noTzDate = fixTimezoneOffset(new Date(rawInput as string));

  return endOfDay(new Date(noTzDate)).toISOString();
};

export const getTodayShort = () => {
  return format(new Date(), 'dd/MM/yyyy');
};

export const fixTimezoneOffset = (date: Date): string => {
  if (!date) return '';

  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toJSON();
};

export const getDateRangeCalendar = (
  newDate: Date | null,
  calendarMode: CalendarFilter | null,
): DateRange => {
  const date = newDate;

  if (!date) {
    return { startDate: null, endDate: null };
  }

  switch (calendarMode) {
    case CalendarFilter.DAY:
      return { startDate: date, endDate: date };
    case CalendarFilter.WEEK:
      const weekStart = startOfWeek(date, { weekStartsOn: 1 });
      const weekEnd = endOfWeek(date, { weekStartsOn: 1 });

      return { startDate: weekStart, endDate: weekEnd };
    case CalendarFilter.MONTH:
      const monthStart = startOfMonth(date);
      const monthEnd = endOfMonth(date);

      return { startDate: monthStart, endDate: monthEnd };
    default:
      return { startDate: null, endDate: null };
  }
};

export const getPreviousDays = (selectedDate: Date, days: number) => {
  const start = sub(selectedDate, { days: days - 1 });

  return eachDayOfInterval({ start, end: selectedDate }).map((date) => ({
    label: format(date, 'd MMM'),
    value: date,
  }));
};

export const getPreviousWeeks = (selectedDate: Date, weeks: number) => {
  let endDate = add(endOfWeek(selectedDate), { days: 1 });

  return Array.from({ length: weeks }, () => {
    const startDate = sub(endDate, { days: 6 });

    const weekLabel = `${format(startDate, 'd MMM')} - ${format(
      endDate,
      'd MMM',
    )}`;

    endDate = sub(startDate, { days: 1 });

    return {
      label: weekLabel,
      values: eachDayOfInterval({
        start: startDate,
        end: add(endDate, { days: 7 }),
      }),
    };
  }).reverse();
};

export const getPreviousMonths = (selectedDate: Date, months: number) => {
  const selectedMonthStart = endOfMonth(selectedDate);

  if (isBefore(selectedDate, sub(selectedMonthStart, { days: 15 }))) {
    months += 1;
  }

  let previousMonthStart = startOfMonth(selectedMonthStart);

  return Array.from({ length: months }, () => {
    const monthLabel = format(previousMonthStart, 'MMM');

    const previousMonthEnd = endOfMonth(previousMonthStart);

    const values = eachDayOfInterval({
      start: previousMonthStart,
      end: previousMonthEnd,
    });

    previousMonthStart = sub(previousMonthStart, { months: 1 });

    return { label: monthLabel, values };
  }).reverse();
};
