/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { OmnyFeed } from 'utils/models';
import { PaginationPreviewItem } from '../PaginationPreviewModal/PaginationPreviewItem';
import { isEmpty } from 'lodash';
import { Info } from '../Info';

type Props = {
  previewList: OmnyFeed[];
  selectedIds: string[];
  handleCheckSelected: (id: string) => void;
};

export const OmnyPreviewPagination = ({
  previewList,
  selectedIds,
  ...props
}: Props) => {
  return (
    <div tw="border[1px solid] border-sonnant-grey-light mb-6 max-height[70vh] overflow-x-hidden overflow-y-auto rounded">
      {previewList?.map((omny) => (
        <PaginationPreviewItem
          key={omny?.id}
          omny={omny}
          handleCheckSelected={props.handleCheckSelected}
          selectedIds={selectedIds}
        />
      ))}

      {isEmpty(previewList) && (
        <div tw="min-h-[50vh] pl-3 py-2.5">
          <Info
            text="Nothing to show here. Please check your URL or filter conditions and try again."
            hideSpacingBottom
          />
        </div>
      )}
    </div>
  );
};
