import axios, { AxiosResponse } from 'axios';
import {
  IMediaEntities,
  IRichTextInput,
} from 'components/shared/RichTextEditor/rich-text.model';
import {
  AUDIO_THUMBNAIL,
  VIDEO_THUMBNAIL,
} from 'components/shared/twin.styles';
import {
  ClipVersionCount,
  CollectionOptionEnum,
  CreatedReportEnum,
  MentionReportTypeEnum,
  Ratio,
  VideoStatusText,
  YoutubeChannel,
} from './../../../utils/enum';
// import * as FileSaver from 'file-saver';
import {
  cloneDeep,
  defaultTo,
  difference,
  first,
  floor,
  forIn,
  inRange,
  isArray,
  isEmpty,
  isEqual,
  isNil,
  keys,
  last,
  map,
  max,
  orderBy,
  padStart,
  range,
  round,
  toNumber,
  uniq,
  uniqBy,
  uniqWith,
  values,
} from 'lodash';
import toast from 'react-hot-toast';
import {
  AdMarkerLayerResponse,
  FbSdkResponse,
  MediaService,
  ShareService,
  TranscriptService,
  TrendingInsightKey,
  TrendingTermSearchInsight,
  TrendingTermSearchInsightResponse,
} from 'services';
import {
  CollectionApiResponse,
  CollectionOption,
} from 'services/collection.service';
import { BaseRange, Editor, Node, Path, Range as SlateRange } from 'slate';
import { ReactEditor } from 'slate-react';
import {
  CaptionElement,
  CustomElement,
  CustomText,
} from 'types/slate-custom-types';
import { toRelativeDaysTz } from 'utils/date.util';
import {
  AccountStatus,
  ClipStatusCode,
  CollectionType,
  NotificationFilter,
  NotificationType,
  SubscriptionEnum,
  TrendingLevel,
  VideoStatusCode,
} from 'utils/enum';
import {
  AdMarkerItem,
  AdmarkerLayerPayload,
  CollectionSidebarItem,
  CombinedCaption,
  CreatedReports,
  CreatedReportsPayload,
  CustomTerm,
  IABItemModel,
  IClips,
  ILibraryItem,
  IMetadata,
  IntegrationOptions,
  LayerCustomTerm,
  MentionReport,
  OmnyFeed,
  OmnyRawResponse,
  PreviewRawResponse,
  RssFeed,
  SpeakerDiarisation,
  SpeakerPayload,
  SpeakerState,
  Term,
  YoutubeChannelExtractor,
  YoutubeChannelItem,
} from 'utils/models';
import { IKeyItem, IOccurrence } from 'utils/models/layers.model';
import {
  Bucket,
  ChapterLayerData,
  GeneratedChartRaw,
  GroupNotify,
  GroupRevival,
  IPublishVersion,
  Insights,
  MegaphoneIntegrationOption,
  MegaphoneRawResponse,
  MentionLayerData,
  NotifyList,
  ProgramIdOption,
  RawRevival,
  RevivalItemModel,
  RevivalPagination,
  SeriesKeyValue,
} from 'utils/models/media.model';
import {
  ISubscription,
  RawNotification,
  UserResponse,
} from 'utils/models/payment.model';
import {
  IChapterRaw,
  IParagraphRaw,
  ISpeakerRaw,
} from 'utils/models/transcript.model';
import { REGEX } from 'utils/regex';
import { customToast } from 'utils/toast.util';
import {
  createCustomLayer,
  createManualKeyword,
  toSlateLineText,
} from 'utils/transcript.util';
import { filenameFormatter, getUser, isBlank, waitAsync } from 'utils/utils';
import { v4 } from 'uuid';

import { config } from 'components/config';
import { Option } from 'components/shared/CustomSelectSearch';
import { TextItem } from 'components/shared/MonetisationTextSearch/MonetisationTextSearch';
import * as CryptoJS from 'crypto-js';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { iabMock } from 'mocks/iab.mock';
import { ExportAliasTerm, TrendingTerm } from 'services/insight.service';
import { MegaphoneProgramIdsResponse } from 'services/megaphone.service';
import { ISort } from 'slices/layers.slice';
import { ARCHIVE_TAG_NAME, CHART_ITEM_COUNT } from 'utils/constants';
import { Routes } from 'utils/routes';
import { getTenantidFromIdToken } from 'utils/utils';

export const convertTimeFormat = (time: any): string => {
  time = time * 1000;

  const s = parseInt(String((time / 1000) % 60));
  const m = parseInt(String((time / (1000 * 60)) % 60));
  const h = parseInt(String((time / (1000 * 60 * 60)) % 24));

  const hours = h < 10 ? `0${h}` : `${h}`;
  const minutes = m < 10 ? `0${m}` : `${m}`;
  const seconds = s < 10 ? `0${s}` : `${s}`;

  return `${hours}:${minutes}:${seconds}`;
};

export const msToDayFormat = (ms: number) => {
  if (isNaN(ms)) ms = 0;

  const totalSeconds = ms / 1000;

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = String(Math.floor((totalSeconds / 60) % 60)).padStart(2, '0');
  const seconds = String(Math.floor(totalSeconds % 60)).padStart(2, '0');

  return `${hours}h ${minutes}m ${seconds}s`;
};

export const msToTime = (ms: number) => {
  if (isNaN(ms)) ms = 0;

  // milisec -> hrs
  const hrs = floor(ms / (1000 * 60 * 60));
  ms = ms % (1000 * 60 * 60);
  // milisec -> min
  const mins = floor(ms / (1000 * 60));
  ms = ms % (1000 * 60);
  // milisec -> sec
  const secs = floor(ms / 1000);

  return (
    padStart(hrs.toString(), 2, '0') +
    ':' +
    padStart(mins.toString(), 2, '0') +
    ':' +
    padStart(secs.toString(), 2, '0')
  );
};

export const secToTime = (sec: number | string): string => {
  return msToTime(toNumber(sec) * 1000);
};

export const msToTimeCode = (s: number | string): string => {
  s = toNumber(s);
  // Pad to 2 or 3 digits, default is 2
  function pad(n: number, z?: number) {
    z = z || 2;
    return ('00' + n).slice(-z);
  }

  const ms = Math.floor(s % 1000);
  s = (s - ms) / 1000;
  const secs = Math.floor(s % 60);
  s = (s - secs) / 60;
  const mins = Math.floor(s % 60);
  const hrs = Math.floor((s - mins) / 60);
  return pad(hrs) + ':' + pad(mins) + ':' + pad(secs) + '.' + pad(ms, 3);
};

export const secToTimeCode = (sec: number) => {
  const ms = sec * 1000;

  return msToTimeCode(ms);
};

export const secToMin = (sec: number) => {
  let s = sec * 1000;

  function pad(n: number, z?: number) {
    z = z || 2;
    return ('00' + n).slice(-z);
  }

  const ms = Math.floor(s % 1000);
  s = (s - ms) / 1000;
  const secs = Math.floor(s % 60);
  s = (s - secs) / 60;
  const mins = Math.floor(s % 60);
  const hrs = Math.floor((s - mins) / 60);

  if (hrs > 0) {
    return pad(hrs) + ':' + pad(mins) + ':' + pad(secs);
  } else {
    return pad(mins) + ':' + pad(secs);
  }
};

export const msToSec = (ms: any): number => {
  if (isNil(ms) || isNaN(ms)) return 0;

  return round(toNumber(ms) / 1000, 3);
};

export const isPublicShared = (): boolean => {
  return window.location.pathname.startsWith(Routes.PUBLIC_SHARED);
};

export const isSignupPage = (): boolean => {
  return window.location.pathname.startsWith(Routes.SIGNUP);
};

export const isSigninPage = (): boolean => {
  return window.location.pathname.startsWith(Routes.SIGNIN);
};

export const isAuthPage = (): boolean => {
  return [
    Routes.SIGNUP,
    Routes.SIGNIN,
    Routes.CONFIRM_PASS,
    Routes.CHANGE_PASS,
    Routes.MESSAGE,
    Routes.EMBED,
  ].some((route) => window.location.pathname.startsWith(route));
};

export const hasNavigator = (): boolean => {
  return [
    Routes.LIBRARY,
    Routes.COLLECTIONS,
    Routes.REVIVE,
    Routes.INSIGHTS,
    Routes.SETTINGS,
    Routes.PREFERENCES,
    Routes.REPORTING,
    Routes.ADMIN_PORTAL,
  ].some((route) => window.location.pathname.startsWith(route));
};

export const hasNavArrow = (): boolean => {
  return [
    Routes.SETTINGS,
    Routes.PREFERENCES,
    Routes.REPORTING,
    Routes.ADMIN_PORTAL,
  ].some((route) => window.location.pathname.startsWith(route));
};

export const isDisplayClips = (): boolean => {
  return window.location.pathname.startsWith('/clips/');
};

export const isLibraryPage = (): boolean => {
  return window.location.pathname === '/library';
};

export const isTranscriptionPage = (): boolean => {
  return window.location.pathname.startsWith(Routes.TRANSCRIPTION);
};

export const isPreferencesPage = (): boolean => {
  return window.location.pathname.startsWith(Routes.PREFERENCES);
};

export const isAccountSettingsPage = (): boolean => {
  return window.location.pathname.startsWith(Routes.SETTINGS);
};

export const isCollectionPage = (): boolean => {
  return window.location.pathname.startsWith(Routes.COLLECTIONS);
};

export const isInsightsPage = (): boolean => {
  return window.location.pathname.startsWith(Routes.INSIGHTS);
};

export const isRevivalPage = (): boolean => {
  return window.location.pathname.startsWith(Routes.REVIVE);
};

export const getStringDynamo = (raw: string) => {
  if (isEmpty(raw) || isEqual(raw, 'NULL')) return '';

  return raw;
};

export const getTimeRemain = (
  totalSec: unknown,
  currentSec: unknown,
): string => {
  const total = toNumber(totalSec || 0); // In case NaN
  const current = toNumber(currentSec || 0); // In case NaN

  return `-${convertTimeFormat(total - current)}`;
};

export const getProgressTime = (percent = 0, totalSec: unknown): string => {
  const total = toNumber(totalSec || 0); // In case NaN

  return convertTimeFormat(percent * total);
};

export const formatLibraryTitle = (library: ILibraryItem[]): ILibraryItem[] => {
  if (!library) return [];

  return library.map((item) => {
    const newItem: ILibraryItem = {
      ...item,
      createddatetime: item?.createddatetime
        ? new Date(item.createddatetime)
        : new Date(),
      modifieddatetime: item?.modifieddatetime
        ? new Date(item.modifieddatetime)
        : new Date(),
    };
    if (
      isBlank(item?.title) &&
      [
        VideoStatusCode.TRANSCRIBING,
        VideoStatusCode.UPLOADING,
        VideoStatusCode.AI_PROCESSING,
      ].includes(toNumber(item?.statuscode))
    ) {
      newItem.title = filenameFormatter(item?.filename);
    }
    return newItem;
  });
};

export const isSuggestedClips = (item: IClips | null): boolean => {
  if (!item) return false;

  return (
    item?.versioncount?.startsWith('suggessted') &&
    String(item?.statuscode) !== ClipStatusCode.SHARED
  );
};

export const isCombinedClips = (item: IClips | null): boolean => {
  if (!item) return false;

  return item?.versioncount?.startsWith('combined');
};

export const isTrendingClips = (item: IClips | null): boolean => {
  if (!item) return false;

  return (
    item?.versioncount?.startsWith('trending') &&
    String(item?.statuscode) !== ClipStatusCode.SHARED
  );
};

export const isOriginalClips = (item: IClips | null): boolean => {
  if (!item) return false;

  return item?.versioncount === ClipVersionCount.ORIGINAL;
};

export const isSharedClips = (item: IClips | null): boolean => {
  if (!item) return false;

  return isEqual(String(item?.statuscode), ClipStatusCode.SHARED);
};

export const isFromSuggested = (item: IClips | null): boolean => {
  if (!item) return false;

  return item?.versioncount?.startsWith('suggessted');
};

export const isTopicClip = (item: IClips | null): boolean => {
  if (!item) return false;

  return !!item?.is_topic_clip;
};

export const getLatestTitle = (
  item: ILibraryItem | null | IMetadata,
): string => {
  return isBlank(item?.title) ? filenameFormatter(item?.filename) : item?.title;
};

export const toDecimal = (num: any): string => {
  var str = toNumber(num).toFixed(2);
  return str.replace(/0{1}$/, '');
};

export const getTierName = (subscription: ISubscription | null) => {
  switch (subscription?.tenantplan) {
    case SubscriptionEnum.TRIAL:
    case SubscriptionEnum.ENTERPRISE_TRIAL:
      return 'Free Trial';
    case SubscriptionEnum.PAYG:
      return 'PAYG';
    case SubscriptionEnum.STANDARD:
      return 'Standard';
    default:
      return 'Unknown';
  }
};

export const getDaysRemaining = (days: number): number => (days > 0 ? days : 0);

export const countActiveUser = (users: UserResponse[]): number => {
  if (!users) return 0;

  const activeUserCount = users.filter((user) => {
    return (
      (user.Status === AccountStatus.ACTIVE &&
        !(
          user.Email?.endsWith('@sonnant.com') ||
          user.Email?.endsWith('@ptnglobalcorp.com') ||
          isEqual(user.Email, 'support@sonnant.com')
        )) ||
      isEqual(user.Email, getUser())
    );
  }).length; // Include Admin user

  return activeUserCount;
};

export const canRedirect = (item: ILibraryItem) => {
  const exceptStatus = [
    VideoStatusCode.UPLOADING,
    VideoStatusCode.TRANSCRIBING,
    // VideoStatusCode.AI_PROCESSING,
    VideoStatusCode.PAYMENT_REQUIRED,
    VideoStatusCode.ERROR,
  ];
  return !exceptStatus.includes(toNumber(item?.statuscode));
};

export const isPaymentRequired = (item: ILibraryItem) => {
  return toNumber(item?.statuscode) === VideoStatusCode.PAYMENT_REQUIRED;
};

export const canShare = (item: any) => {
  return ![
    VideoStatusCode.UPLOADING,
    VideoStatusCode.AI_PROCESSING,
    VideoStatusCode.TRANSCRIBING,
    VideoStatusCode.ERROR,
    VideoStatusCode.PAYMENT_REQUIRED,
  ].includes(toNumber(item?.statuscode));
};

export const isDevMode = (): boolean => {
  return ['dev', 'devLocal', 'stage', 'stageLocal'].includes(
    process.env?.REACT_APP_STAGE ?? '',
  );
};

export const isLocalMode = (): boolean => {
  return ['devLocal', 'stageLocal'].includes(
    process.env?.REACT_APP_STAGE ?? '',
  );
};

export const isMaintenanceMode = (): boolean => {
  return (
    !!process.env?.REACT_APP_IS_MAINTENANCE &&
    process.env?.REACT_APP_IS_MAINTENANCE === 'true'
  );
};

export const isUncountedUser = (user: UserResponse): boolean => {
  if (!user) return false;

  return (
    isEqual(user.Status, AccountStatus.ACTIVE) &&
    !isEqual(user.Email, getUser()) &&
    (isEqual(user.Email, 'support@sonnant.com') ||
      user.Email?.endsWith('@sonnant.com') ||
      user.Email?.endsWith('@ptnglobalcorp.com'))
  );
};

export const getSlateSelection = (): any => {
  const editor = window.Editor;
  let selection: BaseRange | null = editor.selection;

  let select = window.getSelection();
  const selectionRangeStart = select?.getRangeAt(0);
  const selectionRangeEnd = select?.getRangeAt(select.rangeCount - 1);
  const startNode: any = selectionRangeStart?.startContainer.parentNode;
  const endNode: any = selectionRangeEnd?.endContainer.parentNode;
  let range = new Range();
  range.setStart(startNode?.firstChild, 0);
  range.setEnd(
    selectionRangeEnd?.endContainer || endNode.lastChild || endNode.firstChild,
    selectionRangeEnd?.endOffset || 0,
  );
  select?.removeAllRanges();
  select?.addRange(range);

  if (!selection) {
    try {
      selection = ReactEditor.toSlateRange(editor, select!, {
        exactMatch: false,
        // suppressThrow: false
      });
    } catch (error: any) {
      if (!isPublicShared()) {
        customToast.error(
          'Cannot get correct snippet range. Please try again!',
        );
      }
      return;
    }
  }

  const fragment = Node.fragment(editor, selection!);

  const firstText: any = first((first(fragment) as any).children);
  const lastText: any = last((last(fragment) as any).children);

  const start = firstText.data.dataStart;
  const end = lastText.data.dataEnd;
  const text = Editor.string(editor, selection!);

  const selected = {
    start,
    end,
    text,
  };

  return selected;
};

export const isAudioByMIME = (mimeType?: string) => {
  return mimeType?.startsWith('audio/') || mimeType?.includes('mp3');
};

export const isVideoByMIME = (mimeType?: string) => {
  return mimeType?.includes('video') || mimeType?.includes('mp4');
};

export const hasChangeThumb = (item: ILibraryItem): boolean => {
  if (!item) return false;

  if (
    ![
      VideoStatusCode.NOT_EDITED,
      VideoStatusCode.EDITING,
      VideoStatusCode.DRAFT,
    ].includes(toNumber(item.statuscode))
  ) {
    return false;
  }

  return (
    !item?.thumbnail?.startsWith(AUDIO_THUMBNAIL) &&
    !item?.thumbnail?.startsWith(VIDEO_THUMBNAIL)
  );
};

export const isSingular = (num: any) => {
  const numeric = toNumber(num);
  if (isNaN(numeric)) return false;

  return numeric === 1; // 0 is not singular
};

export const scrollToNearestNode = (
  startTime: number,
  isFollow: boolean = true,
) => {
  if (isNil(startTime) || !window?.Editor?.children) return;

  // Use data.end to current node instead of the next
  const foundTranscript: IRichTextInput = window.Editor.children.find(
    (t: any) => t?.data?.end >= startTime,
  ) as any;

  if (!foundTranscript) return;

  scrollToNode(foundTranscript.data.start, isFollow);
};

export const scrollToNode = (startTime: number, isFollow: boolean = false) => {
  if (isNil(startTime)) return;

  const currentWordElement: any = window.document.querySelector(
    `div[data-slate-node="element"][data-start="${startTime}"]`,
  );

  if (isFollow) {
    currentWordElement?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
  }
};

export const scrollToText = (startTime: number, isFollow: boolean = true) => {
  if (isNil(startTime)) return;

  const currentTextElement: any = window.document.querySelector(
    `span[data-slate-leaf="true"][data-start="${startTime}"]`,
  );

  if (isFollow) {
    currentTextElement?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
  }
};

export const getLeafDOM = (startTime: any) => {
  if (isNil(startTime)) return null;

  return window.document.querySelector(
    `span.word[data-slate-leaf="true"][data-start="${startTime}"]`,
  );
};

export const urlToPath = (url = '') => {
  return url.split('?')[0].split('/').slice(3).join('/');
};

export const isEmptySlateNode = (
  nodes: IRichTextInput[] | null | undefined,
) => {
  if (!nodes) return true;

  return nodes.every((node) => isEmpty(node?.children));
};

export const formatRaw = (rawList: IParagraphRaw[]) => {
  // const rawLength = rawList.length - 1;

  return rawList.filter((r, index) => {
    const lastRaw = rawList[index - 1 < 0 ? 0 : index - 1];
    // const nextRaw = rawList[index + 1 > rawLength ? rawLength : index + 1];

    if (r.startTime < lastRaw.endTime && r.endTime < lastRaw.startTime) {
      return false;
    }
    return true;
  });
};

export const normalizeSummary = (rawSummary: string | null | undefined) => {
  if (!rawSummary) return '';

  if (rawSummary.startsWith('"') && rawSummary.endsWith('"')) {
    return JSON.parse(rawSummary); // Remove first and last char
  }

  return rawSummary;
};

export const processChapters = (
  chapters: IChapterRaw[],
  source: CustomElement[],
): IChapterRaw[] => {
  // If empty header return the first sentence
  if (chapters.length === 1 && isEmpty(chapters[0].headline)) {
    const firstSentence = first(source)
      ?.children?.map((c: CustomText) => c?.text)
      ?.join(' ')
      ?.split('. ')?.[0];

    return [
      {
        ...chapters[0],
        headline: firstSentence || 'Chapter 1',
      },
    ];
  }
  return chapters;
};

export const between = (n: number, from: number, to: number): boolean => {
  return n >= from && n <= to;
};

export const betweenNotEqual = (
  n: number,
  from: number,
  to: number,
): boolean => {
  return n > from && n < to;
};

export const inIframe = (): boolean => {
  try {
    return window.self !== window.top;
  } catch (e: any) {
    return true;
  }
};

export const isFirefox = (): boolean => {
  return navigator.userAgent.indexOf('Firefox') > -1;
};

export const firefoxVersion = (): number => {
  return parseFloat(navigator.userAgent.split('Firefox/').pop()!);
};

export const shouldUsePlyr = (): boolean => {
  return true;
  // return isFirefox() && firefoxVersion() >= 92;
};

export const isFirefox92 = (): boolean => {
  return isFirefox() && firefoxVersion() >= 92;
};

export const vttBlobAsync = async (url: string) => {
  try {
    const reqOptions: RequestInit = {
      method: 'GET',
      redirect: 'follow',
    };

    const raw = await fetch(url, reqOptions);
    const res = await raw.text();
    const myBlob = new Blob([res], { type: 'text/plain' });

    const objectURL = URL.createObjectURL(myBlob);
    return objectURL;
  } catch {
    console.log(`[ERROR] - Getting raw vtt error`);
  }
};

export const getVttAsync = async (vttUrl: string): Promise<string> => {
  if (isFirefox() && shouldUsePlyr()) {
    const blob = (await vttBlobAsync(vttUrl)) ?? '';
    return blob;
  }

  return vttUrl;
};

export const appendMultipleAppliedList = (
  terms: Term[],
  multipleTerms: string,
  list: LayerCustomTerm[],
): Term[] => {
  const newTerms = [...terms];
  const splitTerms = multipleTerms.trim().split(',');
  const MAX_LENGTH_VB = 64;
  const MAX_TERM_COUNT = 1000;
  let termsCount = getTermsCountAppliedList(list);

  for (let term of splitTerms) {
    if (term?.length >= MAX_LENGTH_VB) {
      customToast.error('Term should be less than 64 characters');
      continue;
    }

    const newAddedTerm = createManualTerm(newTerms, term);

    if (newAddedTerm) {
      if (termsCount < MAX_TERM_COUNT) {
        newTerms.push(newAddedTerm);
        termsCount++;
      } else {
        customToast.error('Reached limit of 1000 custom terms');
      }
    }
  }

  return newTerms;
};

export const appendMultipleTerms = (
  terms: Term[],
  multipleTerms: string,
  globalList: CustomTerm[],
): Term[] => {
  const newTerms = [...terms];
  const splitTerms = multipleTerms.trim().split(',');
  const MAX_LENGTH_VB = 64;
  const MAX_TERM_COUNT = 1000;
  let termsCount = getTermsCount(globalList);

  for (let term of splitTerms) {
    if (term?.length >= MAX_LENGTH_VB) {
      customToast.error('Term should be less than 64 characters');
      continue;
    }

    const newAddedTerm = createManualTerm(newTerms, term);

    if (newAddedTerm) {
      if (termsCount < MAX_TERM_COUNT) {
        newTerms.push(newAddedTerm);
        termsCount++;
      } else {
        customToast.error('Reached limit of 1000 custom terms');
      }
    }
  }

  return newTerms;
};

export const createManualTerm = (terms: Term[], newTermRaw: string) => {
  const newTerm = newTermRaw?.trim();
  if (!terms || !newTerm) return;

  if (terms.map((term) => term.name).includes(newTerm)) {
    customToast.error(`'${newTerm}' is already existed`);
    return;
  }

  return {
    id: v4(),
    name: newTerm,
    dateCreated: new Date(),
    alternatives: [],
  } as Term;
};

export const processCSV = (str: string) => {
  const rows: any = str?.split('\n');
  if (rows[rows.length - 1] === '') {
    rows.pop();
  }
  const output: any = [];
  rows.forEach((line: string) => {
    if (line.split('"').length > 1) {
      let arr: any = [];
      line.split('"').forEach((word: string) => {
        if (word && word !== ',' && word !== '\r') {
          arr.push(word.replace(',', '').trim());
        }
      });
      arr = arr.filter((word: string) => word !== '');
      output.push(arr);
    } else {
      output.push(line.split(','));
    }
  });
  return output;
};

export const processTerms = (rawData: any, termList: Term[]) => {
  const input = processCSV(rawData);
  let terms = cloneDeep(termList);
  const existedTerms: string[] = [];

  input.forEach((row: any) => {
    // Term same name
    const termName = row?.[0].trim();
    if (termList?.map((t) => t.name).includes(termName)) {
      let idx = termList.map((t) => t.name).indexOf(termName);

      existedTerms.push(termName);
      row = row.filter((r: any) => r?.trim());
      row.shift();
      if (idx >= 0) {
        row.forEach((term: string, index: number) => {
          if (index === 0) {
            terms[idx].type = term.replace(',', '');
          } else {
            terms?.[idx]?.alternatives?.push(
              getAlternative(term, 1, terms[idx].alternatives?.length),
            );
          }
        });
      }
    } else {
      if (terms.map((t) => t.name).includes(termName)) {
        let idx = terms.map((t) => t.name).indexOf(termName);
        row.shift();
        isArray(row) &&
          row?.forEach((term: string, index: number) => {
            if (index === 0) {
              terms[idx].type = term.replace(',', '');
            } else {
              terms[idx].alternatives?.push(
                getAlternative(term, 1, terms[idx].alternatives?.length),
              );
            }
          });
      } else {
        terms.push({
          id: v4(),
          name: termName.trim(),
          type:
            row?.[1]
              ?.replace(',', '')
              ?.replace('\r', '')
              ?.split(',')
              ?.join('')
              ?.trim() ?? '',
          alternatives: getAlternative(row.slice(2, row.length), 2),
          dateCreated: new Date(),
        });
      }
    }
  });
  if (existedTerms.length > 0) {
    customToast.success(
      `Duplicated terms have been removed`,
      'Upload completed',
    );
  } else {
    customToast.success('Upload successfully');
  }
  const result = validateTerms(terms);

  return result.data.map((term) => ({ ...term, fromCSV: true }));
};

export const validateTerms = (
  terms: Term[],
): {
  data: Term[];
  hasError: boolean;
  errorTerms: string[];
} => {
  let hasError = false;
  const errorTerms: string[] = [];

  const validatedData = terms
    .filter((term) => {
      if (!term?.name) return false;

      return true;
    })
    // eslint-disable-next-line no-useless-escape
    .map((t) => ({ ...t, name: t.name.replace(/[,\.]/gm, '') }));
  // Remove note not-allowed special chars

  return {
    data: validatedData,
    hasError,
    errorTerms,
  };
};

const getAlternative = (input: any, option: number, pos?: number) => {
  if (option === 2) {
    // input.shift();
    let alt: any = [];
    input.forEach((element: string, i: number) => {
      if (!element?.trim()) return;

      alt.push({
        id: v4(),
        name: element.replace('\r', '').replace('"', ''),
      });
    });
    return alt;
  } else {
    return {
      id: v4(),
      name: input.replace('\r', '').replace('"', ''),
    };
  }
};

export const exportCSV = (termList: Term[], filename: string) => {
  const terms = termList.map((term) => handleExport(term)).join('\n');
  const csvContent = 'data:text/csv;charset=utf-8,' + terms;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

const handleExport = (term: Term) => {
  let output = '"' + term.name + '"';
  if (term?.type) {
    output += ',' + term.type;
  } else if (
    Array.isArray(term?.alternatives) &&
    term?.alternatives?.length > 0
  ) {
    output += ',';
  }

  term?.alternatives?.map((word: Term) => (output += `,"${word.name}"`));
  return output;
};

export const getMentionsByTerms = ({
  terms,
  captions = window.Editor.children,
  isAppliedList,
}: {
  terms: IKeyItem[];
  captions: CustomElement[]; // Default
  isAppliedList?: boolean;
}): MentionReport[] => {
  if (!terms || !window?.Editor?.children) return [];

  const mentions: MentionReport[] = [];

  terms.forEach((term) => {
    let captionIndexBefore = -1;
    term.mentions?.[0]?.occurrences.forEach((occ, occIndex) => {
      const captionIndex = captions.findIndex((c: CaptionElement) =>
        inRange(occ?.s!, c?.data?.start, c?.data?.end),
      );

      if (captionIndex < 0) {
        customToast.error(
          'Cannot load occurrences from transcripts. Contact support for more information.',
        );
        return;
      }
      // in one caption
      if (captionIndex === captionIndexBefore) {
        mentions[mentions.length - 1].occNo.push(occIndex + 1);
        return;
      }
      captionIndexBefore = captionIndex;

      const newCaption = cloneDeep(captions[captionIndex]);
      if (occ?.e! > captions[captionIndex].data?.end) {
        range(captionIndex + 1, captions.length).forEach((i) => {
          if (captions[i].data?.end <= occ?.e!) {
            const spaceBetweenCaption = {
              text: ' ',
              data: {
                dataStart: newCaption?.data?.end,
                dataEnd: newCaption?.data?.end,
                confidence: 1,
              },
            };
            newCaption.children.push(
              spaceBetweenCaption,
              ...(captions[i]?.children ?? []),
            );
          }
        });
      }

      mentions.push({
        id: mentions?.length ?? 0 + 1,
        term: isAppliedList ? term?.custom ?? term?.keyword : term?.keyword,
        type: term?.type || '',
        enabled: true,
        caption: newCaption,
        occNo: [occIndex + 1],
        totalOcc: term?.mentions?.[0]?.occurrences?.length,
      });
    });
  });

  return mentions;
};

export const cutNegativeTime = (transcript: CaptionElement[]) => {
  return transcript.map((tran) => {
    const cloneTran = { ...tran };
    if (tran?.data?.start < 0 || tran?.data?.end < 0) {
      cloneTran.children = cloneTran.children.filter(
        // NOT NEGATIVE
        (w) => !(w?.data?.dataStart < 0 || w?.data?.dataEnd < 0),
      );
      cloneTran.data.start = 0;
    }

    return cloneTran;
  });
};

export const calTimeCombined = (captions: CombinedCaption[]) => {
  let total = 0;
  removeDuplicate(captions).forEach((c) => {
    const cutTime = c.e - c.s;
    total += cutTime;
  });
  return total;
};

const removeDuplicate = (captions: CombinedCaption[]) => {
  return captions.filter((cap, index) => {
    const prevCap = captions?.[index - 1];

    if (isEqual(cap?.s, prevCap?.s) && isEqual(cap?.e, prevCap?.e)) {
      return false;
    }

    return true;
  });
};

export const mergeSameTerm = (terms: IKeyItem[]): IKeyItem[] => {
  const map = new Map<string, IKeyItem>();

  terms.forEach((item) => {
    const term = cloneDeep(item);

    const keywordTrim = term?.keyword?.trim();
    if (!keywordTrim) return;

    if (!map.has(keywordTrim)) {
      map.set(keywordTrim, term);
    } else {
      const existed = map.get(keywordTrim)!;

      const existedOcc = existed?.mentions?.[0]?.occurrences;
      if (!isArray(existedOcc)) return;

      const totalOcc = existedOcc.concat(
        ...(term?.mentions?.[0]?.occurrences ?? []),
      );
      existed.mentions[0].occurrences = uniqBy(totalOcc, 's');

      map.set(keywordTrim, existed!);
    }
  });

  return [...(map.values() as any)] as IKeyItem[];
};

export const mergeSameTermEntities = (entities: IMediaEntities) => {
  const newEntities = { ...entities };
  forIn(newEntities, (value, key) => {
    newEntities[key] = mergeSameTerm(value!);
  });
  return newEntities;
};

export const getTagTerm = (
  terms: IKeyItem[],
  startTime: number,
  endTime: number,
) => {
  let tagString = '';

  terms.forEach((term) => {
    if (
      term.mentions[0].occurrences.some(
        (occ) => toNumber(occ?.s) >= startTime && toNumber(occ?.s) <= endTime,
      )
    ) {
      tagString += term.keyword + ', ';
    }
  });
  return tagString;
};

export const generateIframe = ({
  embedUrl,
  embedTitle,
  view = 'standard',
  width = '100%',
  height = 180,
}: {
  embedUrl: string;
  embedTitle: string;
  view?: string;
  width?: string;
  height?: number;
}) => {
  if (!embedUrl || isEmpty(embedUrl)) return '';

  // Default standard for invalid param
  if (!['standard', 'portrait', 'landscape', 'full'].includes(view)) {
    view = 'standard';
  }

  const iframe = `<iframe id="iframePublicShare" src="${embedUrl}" width="${width}" height="${height}" allow="fullscreen;autoplay; clipboard-write" frameborder="0" title="${embedTitle}"/>`;

  return iframe;
};

export const nextId = (items: Partial<{ id: string | number }[]>): number => {
  return (max(items.map((item) => toNumber(item?.id))) ?? 0) + 1;
};

export const getTermsCount = (customLists: CustomTerm[]): number => {
  return customLists
    .filter((t) => t?.active)
    .reduce((total, list) => {
      // terms in list + terms in alternatives
      return (
        total +
        (list?.terms?.length ?? 0) +
        (list.terms?.reduce(
          (t, term) => t + (term.alternatives?.length ?? 0),
          0,
        ) ?? 0)
      );
    }, 0);
};

export const getTermsCountAppliedList = (list: LayerCustomTerm[]): number => {
  return list
    .filter((t) => t?.active)
    .reduce((total, list) => {
      // terms in list + terms in alternatives
      return (
        total +
        (list?.matched?.length ?? 0) +
        (list.matched?.reduce(
          (t, term) => t + (term.alternatives?.length ?? 0),
          0,
        ) ?? 0)
      );
    }, 0);
};

export const groupSingleRawNotiByTime = (
  noti: RawNotification,
  timeZone: string,
): GroupNotify => {
  const relativeDay = toRelativeDaysTz(noti.created_at, timeZone);
  const notiItem = toNotificationItem(noti);

  return { date: relativeDay, items: [notiItem] };
};

export const groupMultiRawNotiByTime = (
  rawNoti: RawNotification[],
  timeZone: string,
): GroupNotify[] => {
  const notiMap = new Map<string, NotifyList[]>();

  const sortedGroups = orderBy(rawNoti, (i) => new Date(i.created_at), [
    'desc',
  ]);

  sortedGroups.forEach((noti) => {
    const relativeDay = toRelativeDaysTz(noti.created_at, timeZone);
    const notiItem = toNotificationItem(noti);

    if (!notiMap.has(relativeDay)) {
      notiMap.set(relativeDay, [notiItem]);
    } else {
      const curItems = notiMap.get(relativeDay)!;
      notiMap.set(relativeDay, [...curItems, notiItem]);
    }
  });

  const result: GroupNotify[] = [];

  notiMap.forEach((items, key) => {
    result.push({
      date: key,
      items: orderBy(items, (i) => new Date(i.date), ['desc']),
    });
  });

  return result;
};

export const toNotificationItem = (raw: RawNotification): NotifyList => {
  return {
    id: raw?.id,
    date: raw?.created_at,
    mediaid: raw?.media?.media_id ?? raw?.media_id,
    fileName: raw?.media?.title,
    versioncount: raw?.media?.versioncount ?? null,
    type: raw?.type,
    filter: notiTypeToFilterEnum(raw?.type),
    data: raw?.data ?? null,
  };
};

const notiTypeToFilterEnum = (type: NotificationType): NotificationFilter => {
  if ([NotificationType.TRENDING].includes(type)) {
    return NotificationFilter.TRENDING;
  }

  if (
    [
      NotificationType.MEDIA_PROGRESS,
      NotificationType.MENTION_PROCESSING,
      NotificationType.CLIP_PROGRESS,
      NotificationType.INTEGRATE_OMNY_PROGRESSING,
      NotificationType.GENERATED_TERM_PROGRESSING,
    ].includes(type)
  ) {
    return NotificationFilter.PROGRESS;
  }

  if (
    [
      NotificationType.MEDIA_COMPLETE,
      NotificationType.CLIP_COMPLETE,
      NotificationType.MENTION_DOWNLOAD,
      NotificationType.EXPORT_DOWNLOAD,
      NotificationType.INTEGRATE_OMNY_COMPLETED,
      NotificationType.INTEGRATE_MEGAPHONE_COMPLETED,
      NotificationType.GENERATED_TERM_COMPLETED,
    ].includes(type)
  ) {
    return NotificationFilter.COMPLETE;
  }

  if (
    [
      NotificationType.MEDIA_ERROR,
      NotificationType.INTEGRATE_OMNY_ERROR,
      NotificationType.INTEGRATE_MEGAPHONE_ERROR,
      NotificationType.GENERATED_TERM_ERROR,
    ].includes(type)
  ) {
    return NotificationFilter.ERROR;
  }

  if ([NotificationType.WATCH_LIST_DETECTED].includes(type)) {
    return NotificationFilter.WATCH;
  }

  return NotificationFilter.UNKNOWN;
};

export const groupRawRevivalsByTime = (
  revivalPaginator: RevivalPagination,
  timeZone: string,
): GroupRevival[] => {
  const dayMap = new Map<string, RevivalItemModel[]>();
  const dateData: Date[] = [];

  const sortedRevivals = orderBy(
    revivalPaginator.data,
    (item) => new Date(item.created_at),
    ['desc'],
  );

  sortedRevivals.forEach((rawRevival) => {
    const relativeDay = toRelativeDaysTz(rawRevival.created_at, timeZone);
    const revival = toRevivalItem(rawRevival);

    if (!dayMap.has(relativeDay)) {
      dayMap.set(relativeDay, [revival]);
      dateData.push(rawRevival.created_at);
    } else {
      const curItems = dayMap.get(relativeDay)!;
      dayMap.set(relativeDay, [...curItems, revival]);
    }
  });

  const result: GroupRevival[] = [];
  let index = 0;

  dayMap.forEach((items, key) => {
    result.push({
      id: v4(),
      type: key,
      date: dateData?.[index],
      items: items,
    });
    index += 1;
  });

  return result;
};

export const toRevivalItem = (raw: RawRevival): RevivalItemModel => ({
  id: raw.id,
  libraryItem: {
    mediaid: raw.media.media_id,
    thumbnail: raw.media.thumbnail,
    title: raw.media.title,
    video_thumbnail: raw.media.video_thumbnail,
    mediatype: raw.media?.media_type as string,
  },
  topTags: raw.top_tags,
  twitterTrendingTags: raw.topic_twitter_trending,
  headline: raw.headline,
  trendingOn: raw.trending_mentions.map((m) => m.provider),
  hasMetrics: !isEmpty(raw?.term_metric),
  mentionCount: toNumber(raw?.term_metric?.count),
  retweetLevel: raw?.term_metric?.retweet_level || TrendingLevel.NONE,
  replyLevel: raw?.term_metric?.reply_level || TrendingLevel.NONE,
});

// One of items in NotificationType enum
export const isNotifySocketMessage = (actionType: string): boolean => {
  return values(NotificationType).includes(actionType as NotificationType);
};

/* From stackoverflow: https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn */
export const toNumberAbbr = (rawNumber: any) => {
  let newValue: any = toNumber(rawNumber);
  if (rawNumber >= 1000) {
    var suffixes = ['', 'K', 'M', 'B', 'T'];
    var suffixNum = Math.floor(('' + rawNumber).length / 3);
    var shortValue: any = '';

    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0
          ? rawNumber / Math.pow(1000, suffixNum)
          : rawNumber
        ).toPrecision(precision),
      );
      var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
};

export const getParentLibraryItem = (
  publishedVersion: IPublishVersion,
): Partial<ILibraryItem> | undefined => {
  const versions = publishedVersion?.versions ?? [];
  const orig = versions.find((v) => v.versioncount === '0');

  if (!orig) return;

  return {
    createddatetime: orig?.datetime,
    filename: orig?.versionname,
    length: orig?.length,
    mediacontenttype: orig?.mediatype,
    mediaid: publishedVersion?.mediaid,
    modifieddatetime: orig?.datetime,
    thumbnail: orig?.thumbnail ?? '',
    title: publishedVersion?.title ?? '',
    video_thumbnail: orig?.video_thumbnail ?? '',
  };
};

export const applyIABSearch = ({
  items,
  searchTerm,
  sort,
}: {
  items: IABItemModel[];
  searchTerm: string;
  sort?: ISort;
}): IABItemModel[] => {
  if (isEmpty(items)) return [];

  const term = searchTerm.toLowerCase().trim();
  return filterParent(items, term);
};

export const filterParent = (items: IABItemModel[], term: string) => {
  return items?.filter((i) => i?.name?.toLowerCase()?.includes(term)) ?? [];
};

export const applyIABSearchChildren = (
  items: IABItemModel[],
  searchTerm: string,
) => {
  const term = searchTerm.toLowerCase().trim();

  return filterNested(
    items,
    ({ name, id }: Partial<IABItemModel>) =>
      name?.trim()?.toLowerCase()?.includes(term) ||
      id?.trim()?.toLocaleLowerCase()?.includes(term),
  );
};

export const filterNested = (array: IABItemModel[], fn: Function) => {
  return array.reduce((r: any[], o) => {
    let subItems: any = filterNested(o.subItems || [], fn);

    if (fn(o) || subItems.length) {
      r.push(Object.assign({}, o, subItems.length && { subItems }));
    }
    return r;
  }, []);
};

export const isEmbedPage = (): boolean => {
  return window.location.pathname.startsWith('/embed/');
};

export const getShareUrlAsync = async (mediaid: string) => {
  customToast.loading('Getting shared URL...');
  // GET LIST OF VERSIONS
  const {
    data: { versions },
  }: AxiosResponse<IPublishVersion> = await MediaService.getPublishVersion({
    mediaid,
  });

  const originalVersion = versions.find((v) => v.versioncount === '0');

  if (!originalVersion) return;

  let shareURL = '';
  if (originalVersion?.share_url) {
    shareURL = originalVersion.share_url;
  } else {
    // GET ShareId original version
    const response: AxiosResponse<string> = await ShareService.share({
      mediaid,
      versioncount: '0',
      endtime: msToTimeCode(originalVersion.length),
    });
    shareURL = response.data;
  }

  toast.dismiss();

  return shareURL;
};

export const removeSearchQuery = (param: string) => {
  try {
    let url = new URL(window.location.href);
    url.searchParams.delete(param);

    return url.pathname + url.search + url.hash;
  } catch (err: any) {
    return window.location.pathname;
  }
};

export const addQueryToUrl = (url: string, query: string) => {
  let path = url;
  path += (url.split('?')[1] ? '&' : '?') + query;

  return path;
};

export const toCustomVocab = (
  appliedList: LayerCustomTerm | undefined | null,
): CustomTerm | null => {
  if (!appliedList) return null;

  return {
    id: appliedList.listId,
    name: appliedList.listName,
    active: appliedList.active,
    terms:
      appliedList?.matched?.map((term) => ({
        id: term?.id!,
        name: term?.custom ?? term?.keyword,
        dateCreated: new Date(),
      })) ?? [],
    versionNo: 1,
    dateCreated: new Date(),
    lastModified: new Date(),
    canApply: false,
  };
};

export const cloneNewCustomVocab = (
  appliedList: LayerCustomTerm | undefined | null,
): CustomTerm | null => {
  if (!appliedList) return null;

  return {
    id: v4(),
    name: appliedList.listName,
    active: false,
    canApply: false,
    terms: appliedList.matched.map((term) => ({
      id: v4(),
      name: term?.custom ?? term?.keyword,
      dateCreated: new Date(),
      alternatives: [],
    })),
    versionNo: 1,
    dateCreated: new Date(),
    lastModified: new Date(),
  };
};

// a function to receive a name then return a new name but ends with number inside ()
// if name ends with (number) then it will increment the number by 1
export const cloneName = (rawName: string = '') => {
  const name = rawName.trim();
  const match = name.match(/\((\d+)\)/);
  if (match) {
    const number = parseInt(match[1], 10);
    return name.replace(`(${number})`, `(${number + 1})`);
  }
  return `${name} (1)`;
};

export const createKeywordByString = (
  str: string,
  captions?: CaptionElement[],
): IKeyItem | undefined => {
  str = str?.trim();
  if (!str) return;

  const transcripts: CustomElement = captions ?? window.Editor.children;
  return createManualKeyword(str, toSlateLineText(transcripts), transcripts);
};

export const createListKeyword = (
  arr: string[],
  captions?: CaptionElement[],
): IKeyItem[] => {
  return arr
    .map((str) => createKeywordByString(str, captions))
    .filter(Boolean) as IKeyItem[];
};

export const cloneNameRecursive = (
  listName: string[] = [],
  newName: string,
) => {
  const cloneNameRecursive = (name: string): string => {
    const isExist = listName.find((oldName) => oldName === name);

    return isExist ? cloneNameRecursive(cloneName(name)) : name;
  };

  return cloneNameRecursive(newName);
};

export const getCaptionsSlate = (
  player: {
    isParagraphMode: boolean;
    caption: CaptionElement[];
  } & any,
): CaptionElement[] => {
  return player?.isParagraphMode
    ? (player.caption as CaptionElement[])
    : (window.Editor.children as CaptionElement[]);
};

export const getMentionsOffset = ({
  rawMentions = [],
  captionBefore = 0,
  captionAfter = 0,
  captions = window.Editor.children as CaptionElement[],
}: {
  rawMentions: CombinedCaption[];
  captionBefore: number;
  captionAfter: number;
  captions: CaptionElement[];
}): CombinedCaption[] => {
  const uniqMentions = uniqWith(
    rawMentions,
    (prev, next) => isEqual(prev?.s, next?.s) && isEqual(prev?.e, next?.e),
  );

  // Get start of previous captions and end of next captions
  const offsetMentions = uniqMentions.map((m) => {
    const foundIndex = captions?.findIndex(
      (c) => c?.data?.start >= (m?.s || 0),
    );
    const foundBefore =
      captions?.[
        foundIndex - captionBefore < 0
          ? foundIndex - 1
          : foundIndex - captionBefore
      ] ?? captions?.[foundIndex];
    const foundAfter = captions?.[foundIndex + captionAfter];

    return {
      s: foundBefore?.data?.start || 0,
      e: foundAfter?.data?.end || last(captions)?.data?.end || 0,
    };
  });

  // Join together if there is overlap
  const result: CombinedCaption[] = [];
  offsetMentions.forEach((m, mIndex, orig) => {
    if (result.length === 0) {
      result.push(m);
    } else {
      const prev = orig?.[mIndex - 1];
      if (prev && m?.s <= prev?.e && m?.e >= prev?.s) {
        const last = result.pop()!;
        last.e = m?.e;
        result.push(last);
      } else {
        result.push(m);
      }
    }
  });

  console.log(`[Combined result]`, result);

  return result;
};

export const searchTranscriptionV2Async = async ({
  mediaid,
  terms,
}: {
  mediaid: string;
  terms: string[];
}): Promise<IKeyItem[]> => {
  if (!mediaid || isEmpty(terms)) return [];

  try {
    customToast.loading('Searching transcription...');
    const response = await TranscriptService.searchTranscript({
      mediaid,
      terms: terms.map((t) => t?.trim()),
    });
    toast.dismiss();

    return response.data as IKeyItem[] | [];
  } catch (err: any) {
    console.log(`[ERROR]`, err);
    customToast.error('Something went wrong');
    return [];
  }
};

export const loginFacebookSDKAsync = async (): Promise<FbSdkResponse> => {
  return new Promise((resolve, reject) => {
    if (!(window as any)?.FB) return reject(new Error('FB SDK not found'));

    window.FB.login(
      (res) => {
        if (res?.status !== 'connected') {
          customToast.error(
            'Facebook Authorization cannot be completed. Please try again later',
          );
          return reject(new Error('User is not authorized'));
        }

        window.FB.api(
          '/me?fields=id,picture.type(large),first_name,last_name,email',
          (user: FbSdkResponse) => {
            return resolve(user);
          },
        );
      },
      { scope: 'email' },
    );
  });
};

export const findRangeByText = (
  textSearch: string,
  options?: {
    isMatchCase?: boolean;
    isWholeWords?: boolean;
  },
): SlateRange[] => {
  textSearch = textSearch?.trim();
  if (!textSearch || !window?.Editor?.children) return [];

  const ranges: SlateRange[] = [];
  const terms = breakWords(textSearch);
  const rows: CaptionElement[] = window.Editor.children as CaptionElement[];
  const { isMatchCase, isWholeWords } = { ...options };

  rows.forEach((row, rowIndex) => {
    // SINGLE WORD TextSearch
    if (terms?.length === 1) {
      const words = row?.children?.map((c) => c.text) ?? [];
      // FIND ALL WORDS IN A ROW
      const colIndexes = findIndexes({
        words,
        textSearch,
        isMatchCase,
        isWholeWords,
      });

      if (colIndexes.length > 0) {
        // HIGHLIGHT FOUND WORDS
        colIndexes.forEach((col) => {
          /* anchor: start cursor | focus: end cursor | offset: index of characters in word */
          ranges.push({
            anchor: {
              offset: col.offset, // Zero if whole word
              path: [rowIndex, col.index] as Path,
            },
            focus: {
              // equal to greater than anchor offset
              offset: col.offset + textSearch.length,
              path: [rowIndex, col.index] as Path,
            },
            highlight: true,
          } as SlateRange & { highlight: boolean });
        });
      }

      // MULTIPLE WORDS TextSearch
    } else if (terms?.length > 1) {
      const words = breakWords(Node.string(row));
      const editWords = row?.children?.map((c) => c.text) ?? [];

      words.forEach((word, colIndex) => {
        // FIRST WORD MATCHED
        if (isEqualCase(word, terms[0], isMatchCase)) {
          // CHECK IF WORDS MATCHED CONTINUOUSLY
          const matched = isMatchedByMultipleWords({
            terms,
            words,
            colIndex,
            isMatchCase,
            isWholeWords,
          });

          if (matched) {
            // NO ADDED TEXT
            if (words.length === editWords.length) {
              ranges.push({
                anchor: {
                  offset: 0,
                  path: [rowIndex, colIndex],
                },
                focus: {
                  offset: last(terms ?? [])?.length,
                  path: [rowIndex, colIndex + terms.length - 1],
                },
                highlight: true,
              } as SlateRange & { highlight: boolean });
              // MISMATCH AFTER ADDED TEXT
            } else {
              editWords.forEach((word, wordIndex) => {
                let firstMatch = terms[0];

                if (hasDuplicateWordInCol(word, firstMatch)) {
                  findIndexWordInString(firstMatch, word).forEach((index) => {
                    const range = {
                      anchor: {
                        offset: index,
                        path: [rowIndex, wordIndex],
                      },
                      focus: {
                        offset: textSearch!.length + index,
                        path: [rowIndex, wordIndex],
                      },
                      highlight: true,
                    } as SlateRange & { highlight: boolean };
                    ranges.push(range);
                  });
                }

                if (!isMatchCase) {
                  word = word.toLowerCase();
                  firstMatch = firstMatch.toLowerCase();
                }

                const firstIndex = word.indexOf(firstMatch);

                let offset = word.length;
                let loop = 1;
                // FOUND WORD
                if (firstIndex > -1) {
                  while (firstIndex + textSearch?.length > offset) {
                    offset += editWords?.[wordIndex + loop]?.length;
                    loop++;
                  }
                  /*
                  loop = 1: search term inside one text node
                  loop > 1: search term spread over multiple text nodes
                   */
                  const range = {
                    anchor: {
                      offset: firstIndex,
                      path: [rowIndex, wordIndex],
                    },
                    focus: {
                      offset:
                        loop > 1
                          ? last(terms)!.length
                          : firstIndex + textSearch!.length,
                      path: [
                        rowIndex,
                        loop > 1 ? wordIndex + loop - 1 : wordIndex,
                      ],
                    },
                    highlight: true,
                  } as SlateRange & { highlight: boolean };

                  const text = Node.fragment(
                    window.Editor,
                    range,
                  ) as CaptionElement[];

                  const textFound = text?.[0]?.children
                    ?.map((c) => c.text)
                    .join('')
                    .trim()
                    .toLowerCase();

                  // FOUND TEXT HAS TO BE THE SAME AS SEARCH TERM
                  if (textFound.includes(textSearch.toLowerCase())) {
                    ranges.push(range);
                  }
                }
              });
            }
          }
        }
      });
    }
  });

  return uniqWith(ranges, isEqual);
};

export const breakWords = (sentence: string) => {
  if (!sentence) return [];

  return sentence.split(/([,.!?\s]+)/).filter((t) => t?.trim());
};

type WordOffset = {
  index: number;
  offset: number;
};

const findIndexes = ({
  words,
  textSearch,
  isMatchCase,
  isWholeWords,
}: {
  words: string[];
  textSearch: string;
  isMatchCase?: boolean;
  isWholeWords?: boolean;
}): WordOffset[] => {
  // Find all occurrences of textSearch in words by REDUCE
  const result = words.reduce(
    (array: WordOffset[], word: string, index: number) => {
      // MATCH CASE CHECK
      if (!isMatchCase) {
        word = word.toLowerCase();
        textSearch = textSearch.toLowerCase();
      }

      if (hasDuplicateWordInCol(word, textSearch)) {
        findIndexWordInString(textSearch, word).forEach((wordIndex) => {
          array.push({
            index,
            offset: wordIndex,
          });
        });
      }

      // WHOLE WORD CHECK
      const found = isWholeWords
        ? word.trim() === textSearch.trim()
        : word.includes(textSearch);

      // Fow whole words, offset should be 0
      if (found) {
        array.push({
          index,
          offset: isWholeWords ? 0 : word.indexOf(textSearch),
        });
      }

      return array;
    },
    [],
  );

  return result;
};

const isEqualCase = (a: string, b: string, isMatchCase?: boolean): boolean => {
  if (isMatchCase) return a === b;
  return a?.toLowerCase() === b?.toLowerCase();
};

const isMatchedByMultipleWords = ({
  terms,
  words,
  colIndex,
  isMatchCase,
  isWholeWords,
}: {
  terms: string[];
  words: string[];
  colIndex: number;
  isMatchCase?: boolean;
  isWholeWords?: boolean;
}): boolean => {
  const matched = terms.every((term, termIndex) => {
    let wordTranscript = words?.[colIndex + termIndex] ?? '';
    if (!isMatchCase) {
      term = term.toLowerCase();
      wordTranscript = wordTranscript.toLowerCase();
    }

    // SINGLE WORD HANDLED BY OTHER FUNCTION
    if (termIndex === 0) return wordTranscript === term;

    // WHOLE WORD CHECK
    return isWholeWords
      ? wordTranscript === term
      : wordTranscript.startsWith(term);
  });

  return matched;
};

export const jumpToRangeNth = (ranges: SlateRange[], nth: number) => {
  try {
    if (nth < 0 || nth > ranges.length + 1 || !ranges?.[nth - 1]) return;

    // FIND NODE BY RANGE
    const textNodes: CaptionElement[] = Node.fragment(
      window.Editor,
      ranges[nth - 1],
    ) as CaptionElement[];
    if (isEmpty(textNodes) || !isArray(textNodes)) return;

    const time = first(textNodes[0]?.children)?.data?.dataStart ?? 0;
    if (window?.Video) {
      window.Video.currentTime = time;
      scrollToText(time);
    }
  } catch (error: any) {
    console.log(`jumpToRangeNtn ERROR`, error);
  }
};

export const focusOccNth = (
  ranges: SlateRange[],
  nth: number,
): (SlateRange & { highlight?: boolean; focusHighlight?: boolean })[] => {
  if (isEmpty(ranges) || nth < 0 || nth > ranges?.length + 1) return [];

  return (
    ranges?.map((range, index) =>
      nth - 1 === index ? { ...range, focusHighlight: true } : range,
    ) ?? []
  );
};
export const getWindowOptions = (width = 550, height = 730): string => {
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;

  return `menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=${width}, height=${height}, top=${top}, left=${left}`;
};

export const readCookie = (key: string): string | undefined => {
  return window.document.cookie
    ?.split(';')
    ?.find((row) => row?.trim()?.startsWith(`${key}=`))
    ?.split('=')[1];
};

export const countSpacesBegin = (text: string): number => {
  return text?.search(/\S|$/) ?? 0;
};

export const getExtFromS3Url = (url: string | undefined): string | null => {
  try {
    if (!url) return null;

    const link = new URL(url);
    const extension = link?.pathname?.split('.')?.slice(-1)[0];
    return extension;
  } catch {
    return null;
  }
};

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const addUUID = (data: RssFeed[]): (RssFeed & { id: string })[] => {
  if (!data) return [];

  return data
    ?.map((item) => ({ ...item, id: v4() }))
    ?.filter((item) => !isEmpty(item?.enclosure?.url));
};

export const validateRssFeeds = (feeds: RssFeed[]): boolean => {
  const isValid = feeds.every((feed) => !isEmpty(feed?.enclosure?.url));

  return isValid;
};

export const ratioToCssString = (ratio: Ratio) => {
  const [width, height] = ratio.split(':');

  if (isNil(width) || isNil(height)) return '16 / 9';
  return `${width} / ${height}`;
};

export const ratioFontSize = (ratio: Ratio) => {
  if (ratio === Ratio['9_16']) return '14px';

  if ([Ratio['4_3'], Ratio['1_1']].includes(ratio)) return '16px';

  return '18px';
};

export const extractApplePodcast = async (link: string): Promise<string> => {
  if (!link?.startsWith('https://podcasts.apple.com/')) return link;

  const { data } = await axios.get('https://itunes.apple.com/search', {
    params: {
      limit: 5,
      term: link,
      media: 'podcast',
    },
  });

  if (!data?.results?.length) {
    throw new Error('Apple Podcast URL is not valid. Please try again.');
  }

  return data.results[0]?.feedUrl ?? link;
};

export const getCurrentMediaid = (): string => {
  return window.location.pathname?.split('/')?.slice(-1)?.pop() ?? '';
};

// Function isPunc to check input text is punctuation or not
export const isPunc = (str: string): boolean => {
  return /^[.,\\/#!$%\\^&\\*;:{}=\-_`~()]$/.test(str?.trim());
};

export const toCollectionInfo = (
  item: CollectionSidebarItem,
): CollectionOption => {
  return {
    label: item.name,
    value: item.id,
    type: CollectionType.STANDARD,
  };
};

export const toCollectionItem = (
  source: CollectionApiResponse,
): CollectionSidebarItem => {
  return {
    id: source?.collection_id ?? '',
    name: source?.collection_name ?? '',
    type: CollectionType.STANDARD,
    order: source?.collection_order ?? '',
    isArchived: source.isArchived,
  };
};

export const isValidInstagramCaption = (caption: string): boolean => {
  if (isEmpty(caption)) return false;

  const MAX_CHARS = 2220;
  const MAX_HASHTAG = 30;
  const MAX_MENTION = 20;

  const hashtagCount = caption?.match(REGEX.HASHTAG_COUNT)?.length ?? 0;
  const mentionCount = caption?.match(REGEX.MENTION_COUNT)?.length ?? 0;

  if (
    caption.length > MAX_CHARS ||
    hashtagCount > MAX_HASHTAG ||
    mentionCount > MAX_MENTION
  ) {
    return false;
  }

  return true;
};

export const toOmnyOptions = (omny: OmnyRawResponse): IntegrationOptions => {
  return {
    isEnabled: omny?.enabled_api ?? false,
    apiKey: decrypt(omny?.api_key),
    webhookSecret: decrypt(omny?.webhook_secret),
    collectionOption: omny?.collection_option ?? CollectionOptionEnum.OMNY,
    collectionId: omny?.collection_id ?? null,
    id: omny?.id ?? v4(),
    provider: omny?.provider ?? '',
    integrationName: omny?.integration_name ?? '',
    filter: {
      // Default single option in case of empty
      programFilters: !isEmpty(omny?.filter?.programFilters)
        ? omny.filter.programFilters
        : [{ programId: '', programName: '' }],
      titleContains: omny?.filter?.titleContains,
      isUseProgramName: omny?.filter?.isUseProgramName,
      isAutomatePushBack: omny?.filter?.isAutomatePushBack,
      integrationSettings: {
        hasMonetisation: defaultTo(
          omny?.filter?.integrationSettings?.hasMonetisation,
          true,
        ),
        hasChapters: defaultTo(
          omny?.filter?.integrationSettings?.hasChapters,
          true,
        ),
        hasTags: defaultTo(omny?.filter?.integrationSettings?.hasTags, true),
        hasSummary: defaultTo(
          omny?.filter?.integrationSettings?.hasSummary,
          true,
        ),
      },
    },
  } as IntegrationOptions;
};

export const toMegaphoneOptions = (
  megaphone: MegaphoneRawResponse,
): MegaphoneIntegrationOption => {
  return {
    isEnabled: megaphone?.enabled_api ?? false,
    apiKey: decrypt(megaphone?.api_key),
    collectionOption:
      megaphone?.collection_option ?? CollectionOptionEnum.MEGAPHONE,
    collectionId: megaphone?.collection_id ?? null,
    id: megaphone?.id ?? v4(),
    provider: megaphone?.provider ?? '',
    integrationName: megaphone?.integration_name ?? '',
    filter: {
      // Default single option in case of empty
      programFilters: !isEmpty(megaphone?.filter?.programFilters)
        ? megaphone.filter.programFilters
        : [{ programId: '', networkId: '' }],
      isUseProgramName: megaphone?.filter?.isUseProgramName,
      isAutomatePushBack: megaphone?.filter?.isAutomatePushBack,
      integrationSettings: {
        hasMonetisation: defaultTo(
          megaphone?.filter?.integrationSettings?.hasMonetisation,
          true,
        ),
      },
    },
  } as MegaphoneIntegrationOption;
};

export const searchTermsAsync = async (
  termsArray: string[],
  mediaid: string,
  player: any,
) => {
  // Search by backend logic
  let foundTerms = await searchTranscriptionV2Async({
    mediaid: mediaid,
    terms: termsArray,
  });

  // Fallback search frontend
  if (isEmpty(foundTerms)) {
    const sourceText = toSlateLineText(getCaptionsSlate(player));
    foundTerms = createCustomLayer(
      termsArray,
      sourceText,
      getCaptionsSlate(player),
    );
  }

  return foundTerms;
};

export const getNewFocusList = async (
  result: any,
  mediaid: string,
  player: any,
  foundList?: LayerCustomTerm,
): Promise<LayerCustomTerm | null> => {
  const termsArray = result?.payload?.terms?.map((term: Term) => term.name);

  const termsWithType: {
    name: string;
    type: string;
    fromCSV?: boolean;
  }[] = result?.payload?.terms?.map((term: Term) => ({
    name: term.name,
    type: term?.type,
    fromCSV: term?.fromCSV,
  }));

  if (!foundList) return null;

  const oldTerms = foundList.matched.map((m) => m?.custom).filter(Boolean);

  const newTerms = difference(termsArray, oldTerms);
  const removedTerms = difference(oldTerms, termsArray);

  let foundTerms = await searchTermsAsync(termsArray, mediaid, player);

  if (isEmpty(oldTerms)) {
    foundList.matched = [...foundTerms];
  } else {
    // Only add new terms, retain existed terms
    foundList.matched = [
      ...foundList.matched.filter(
        (matchedTerm) => !removedTerms.includes(matchedTerm?.custom),
      ),
      ...foundTerms
        .filter((term) => newTerms.includes(term?.custom))
        .map((keyword) => {
          const found = termsWithType?.find(
            (term) => term?.name === keyword?.custom,
          );

          return {
            ...keyword,
            type: found?.type,
            fromCSV: found?.fromCSV,
          };
        }),
    ];
  }

  if (!isEmpty(newTerms) || !isEmpty(removedTerms)) {
    customToast.success('You should save a version to apply the changes.');
  }

  return foundList;
};

export const toOmnyPreview = (omny: PreviewRawResponse): OmnyFeed => {
  return {
    id: omny.id,
    title: omny.title,
    description: omny.description,
    imageURL: omny.image_url,
    pubDate: omny.published_utc,
    duration: omny.duration_seconds,
    summary: omny.summary ?? '',
  };
};

export const toSpeakerConfigs = (speaker: SpeakerPayload): SpeakerState => {
  return {
    hasSpeakerDiarisation: speaker?.speaker_config?.speaker_diarisation ?? true,
    hasSpeakerIdentification:
      speaker?.speaker_config?.speaker_identification ?? false,
    recommendedSensitivity:
      speaker?.speaker_config?.speaker_diarisation_sensitivity ?? 85,
    speakers: speaker?.speaker_items ?? [],
  };
};

export const toIClipExportItems = (library: any[]): any => {
  return library.map((item: any) => {
    return {
      versionname: filenameFormatter(item.filename ?? item.versionname),
      length: item.length,
      datetime: item.modifieddatetime,
      status: item.status,
      isSelected: true,
    };
  });
};

const decrypt = (secret: string) => {
  if (isEmpty(secret)) return '';

  let password = `tenant_id:${getTenantidFromIdToken()}`;
  let salt = 'salt';
  let iterations = 128;
  let bytes = CryptoJS.PBKDF2(password, salt, {
    keySize: 48,
    iterations: iterations,
  });
  let iv = CryptoJS.enc.Hex.parse(bytes.toString().slice(0, 32));
  let key = CryptoJS.enc.Hex.parse(bytes.toString().slice(32, 96));
  let ciphertext = CryptoJS.AES.decrypt(secret, key, { iv: iv });
  return ciphertext.toString(CryptoJS.enc.Utf8);
};

export const hasDuplicateWordInCol = (
  colString: string,
  searchTerm: string,
) => {
  let words = breakWords(colString);
  let count = 0;

  words.forEach((word) => {
    if (isEqual(word, searchTerm)) count += 1;
  });

  return count > 1;
};

export const findIndexWordInString = (
  searchTerm: string,
  colString: string,
) => {
  let startIndex = 0;
  let index = colString.indexOf(searchTerm, startIndex);
  let indices = [];
  const MAX_LOOP = 5000;
  let loopCount = 0;

  while (index > -1 && loopCount <= MAX_LOOP) {
    indices.push(index);
    startIndex = index + searchTerm.length;
    index = colString.indexOf(searchTerm, startIndex);
    loopCount++;
  }

  return indices;
};

export const hasExpiredURL = async (url: string): Promise<boolean> => {
  try {
    const response = await axios.get(url);
    if (response.status === 200) return false;
    return true;
  } catch {
    return true;
  }
};

export const downloadMultipleFiles = async ({
  urls,
  shouldCheckExpired,
}: {
  urls: string[];
  shouldCheckExpired?: boolean;
}) => {
  if (!isEmpty(urls)) {
    console.log('Downloading', urls);

    const link = document.createElement('a');
    link.setAttribute('download', 'true');
    link.style.display = 'none';

    document.body.appendChild(link);

    if (isArray(urls) && shouldCheckExpired) {
      try {
        await axios.get(urls?.[0]); // Check if link is alive
      } catch {
        customToast.error('Download link expired. Please try again.');
        return;
      }
    }

    // Has to use for await here to prevent requests cancelling
    for await (const url of urls) {
      link.setAttribute('href', url);
      link.click();
      await waitAsync(2500);
      console.log('Downloading', url);
    }

    document.body.removeChild(link);
  }
};

export const fillHierarchyString = (
  iabItem: IABItemModel[],
): IABItemModel[] => {
  return iabItem.map((item) => ({
    ...item,
    hierarchy: item?.hierarchy ?? findNestedIAB(item?.raw_hierarchy_name),
  }));
};

export const findNestedIAB = (rawHierarchy: string | undefined): string => {
  if (!rawHierarchy) return '';

  let result: string[] = [];
  const names = rawHierarchy.split('>');

  names.forEach((name) => {
    iabMock.allCategories.forEach((category) => {
      const nestedIABs = findNested(category?.subItems!, name);

      if (!isEmpty(nestedIABs)) {
        result = uniq([...result, ...nestedIABs]);
      }
    });
  });

  return result.join(' > ');
};

const findNested = (subItems: IABItemModel[], name: string) => {
  let result: string[] = [];

  subItems.forEach((item) => {
    if (item?.name === name) {
      result.push(`${item?.name} (${item?.id})`);
    }

    if (!isEmpty(item?.subItems)) {
      result = [...result, ...findNested(item?.subItems!, name)];
    }
  });

  return result;
};

export const preprocessAdMarkers = (
  midRolls: AdMarkerItem[],
  mediaLength: number,
) => {
  const hasPreRoll = first(midRolls)?.second === 0;
  const hasPostRoll = last(midRolls)?.second === mediaLength;

  const newMidRolls = [...(midRolls ?? [])];

  if (hasPreRoll) {
    newMidRolls.shift();
  }

  if (hasPostRoll) {
    newMidRolls.pop();
  }

  return {
    preRoll: hasPreRoll,
    midRolls: newMidRolls?.map((item) => item?.second) ?? [],
    postRoll: hasPostRoll,
  };
};

export const findDuplicate = (values: number[], minGapSecond = 1) => {
  const indexDuplicates: number[] = [];

  values.forEach((value, index) => {
    if (value - values?.[index - 1] < minGapSecond) {
      indexDuplicates.push(index - 1);
    }
  });

  return indexDuplicates;
};

export const getYoutubeChannelInfo = (
  url: string,
): YoutubeChannelExtractor | null => {
  const safeUrl = url?.trim();

  if (!safeUrl) return null;

  const isNewChannelUrl = safeUrl.includes('youtube.com/@');

  if (isNewChannelUrl) {
    const channelName = last(safeUrl.split('@')) ?? '';
    return {
      channelId: null,
      channelName: channelName,
      isChannelId: false,
      isValidChannelUrl: true,
    };
  }

  const regexMatches = REGEX.YOUTUBE_CHANNEL_EXTRACTOR.exec(safeUrl);

  // Match 3 groups of regex executor
  if (!isEmpty(regexMatches) && regexMatches && regexMatches?.length >= 4) {
    const channelType = regexMatches[1] as YoutubeChannel;
    const channelUrl = regexMatches[3] as string;

    // Valid URL but not a channel URL
    if (
      ![YoutubeChannel.ID, YoutubeChannel.NAME, YoutubeChannel.USER].includes(
        channelType,
      )
    ) {
      return {
        channelId: null,
        channelName: null,
        isChannelId: false,
        isValidChannelUrl: false,
      };
    }

    // Has valid channel URL
    const hasChannelName = [YoutubeChannel.NAME, YoutubeChannel.USER].includes(
      channelType,
    );

    return {
      channelId: hasChannelName ? null : channelUrl,
      channelName: hasChannelName ? channelUrl : null,
      isChannelId: !hasChannelName,
      isValidChannelUrl: true,
    };
  }

  return null;
};

export const convertYoutubeChannelPreview = (
  videos: YoutubeChannelItem[],
): RssFeed[] => {
  return videos.map((video) => ({
    id: v4(),
    title: video?.title,
    description: video?.accessibility?.title,
    imageURL: last(video?.thumbnails)?.url ?? '',
    pubDate: extractPublishTime(video),
    duration: video?.duration?.padStart(8, '00:00:00') ?? '',
    enclosure: {
      length: null,
      type: null,
      url: video?.url,
    },
    summary: video?.accessibility?.title,
    explicit: false,
  }));
};

const extractPublishTime = (video: YoutubeChannelItem): string => {
  // Title format: {title} by {channel_name} xxx ago xx minute, xx seconds
  const desc = video?.accessibility?.title
    ?.replace(video?.title, '')
    ?.replace(`by ${video?.channel?.name}`, '');

  const word = 'ago';
  // get string xxx ago, cut out to to right
  const time = desc?.substring(0, desc?.indexOf(word) + word?.length);

  return time?.trim() ?? '';
};

export const isSensitiveIabId = (id: string | undefined): boolean => {
  if (!id?.trim()) return false;

  const safeId = id?.toLowerCase();

  return safeId === 'v9i3On'.toLowerCase();
};

export const splitStringByComma = (rawString: string) => {
  const safeString = rawString?.trim();

  if (!safeString) return [];

  const rawValues = rawString.split(',');

  return uniq(rawValues.map((value) => value.trim())).filter(Boolean);
};

export const getEnumKeyByEnumValue = (myEnum: any, value: string) => {
  const key = keys(myEnum).filter((key) => myEnum[key] === value);
  return !isEmpty(key) ? key[0] : '';
};

export const extractValidEmails = (rawEmailString: string): string[] => {
  if (isEmpty(rawEmailString?.trim())) return [];

  const emailArray = rawEmailString?.split(',');

  return (
    emailArray
      ?.map((email) => email?.trim())
      ?.filter((email) => REGEX.EMAIL_ADDRESS.test(email?.trim())) ?? []
  );
};

export const getAdmarkerLayerByTime = (
  admarker: AdMarkerItem[],
  startTime: number,
  lockedList: IABItemModel[],
  keywords: IKeyItem[],
  entities: IMediaEntities,
  totalMarkers: number,
  mediaid: string,
  mediaLength: number,
) => {
  const endTime =
    admarker.find((item) => item?.second > startTime)?.second || 0;

  const idxCaptionAfter = (
    window.Editor?.children as CaptionElement[]
  ).findIndex((caption) => caption?.data.start > startTime);

  const idxCaptionBefore = (
    window.Editor?.children as CaptionElement[]
  ).findIndex(
    (caption) =>
      caption?.data.start < startTime && startTime < caption?.data.end,
  );

  const iabString = lockedList
    .map((item) => {
      const isInclude = item.chapters?.some(
        (chapter) => chapter.s <= startTime && chapter.e <= endTime,
      );
      return isInclude ? item.name : '';
    })
    .join(' ');

  let tagString = getTagTerm(keywords, startTime, endTime);

  forIn(entities, (value, key) => {
    tagString += getTagTerm(value ?? [], startTime, endTime);
  });

  let marker = 'Mid-roll';
  if (startTime === 0) {
    marker = 'Pre-roll';
    return {
      marker,
      totalMarkers,
      startTime,
      adsServed: 1,
      iab: '',
      tags: '',
      transcriptPrior: (window.Editor.children as CaptionElement[])?.[
        idxCaptionBefore
      ]?.children
        .map((item) => item.text)
        .join(' '),
      transcriptAfter: (window.Editor.children as CaptionElement[])?.[
        idxCaptionAfter
      ]?.children
        .map((item) => item.text)
        .join(' '),
      url:
        window.location.origin + `/transcription/${mediaid}?time=${startTime}`,
    };
  }
  if (startTime === mediaLength) {
    marker = 'Post-roll';
  }

  const newAdMarkerTranscript: AdmarkerLayerPayload = {
    marker,
    totalMarkers,
    startTime,
    adsServed: 1,
    iab: iabString,
    tags: tagString,
    transcriptPrior: (window.Editor.children as CaptionElement[])?.[
      idxCaptionBefore
    ]?.children
      .map((item) => item.text)
      .join(' '),
    transcriptAfter: (window.Editor.children as CaptionElement[])?.[
      idxCaptionAfter
    ]?.children
      .map((item) => item.text)
      .join(' '),
    url: window.location.origin + `/transcription/${mediaid}?time=${startTime}`,
  };

  return newAdMarkerTranscript;
};

const KEY_DEFAULT_COLLECTION = 'defaultCollectionId';

export const getCollectionIdLocal = () => {
  return window.sessionStorage.getItem(KEY_DEFAULT_COLLECTION);
};

export const setCollectionIdLocal = (defaultId: string | null) => {
  window.sessionStorage.setItem(KEY_DEFAULT_COLLECTION, defaultId ?? '');
};

export const removeCollectionIdLocal = () => {
  window.sessionStorage.removeItem(KEY_DEFAULT_COLLECTION);
};

export const handleGetChapterData = (
  chapters: IChapterRaw[],
  terms: IKeyItem[],
  lockedList: IABItemModel[],
  mediaid: string,
) => {
  const newChapterData: ChapterLayerData[] = [];

  chapters.forEach((chapter) => {
    const iabString = lockedList
      .map((item) => {
        const isInclude = item.chapters?.some(
          (chapterItem) =>
            chapterItem.s <= toNumber(chapter.startTime) &&
            chapterItem.e <= toNumber(chapter.endTime),
        );
        return isInclude ? item.name : '';
      })
      .filter(Boolean)
      .join(', ');

    const tagString = getTagTerm(
      terms,
      toNumber(chapter.startTime),
      toNumber(chapter.endTime),
    );

    const transcripts = getTranscriptByTime(chapter.startTime, chapter.endTime);
    const transcriptString = transcripts
      .map((caption) => {
        return caption.children.map((c) => c.text).join('');
      })
      .filter(Boolean)
      .join(' ');

    const newChapter: ChapterLayerData = {
      id: toNumber(chapter.id),
      index: toNumber(chapter.id) + 1,
      startTime: toNumber(chapter.startTime),
      endTime: toNumber(chapter.endTime),
      headline: chapter.headline,
      iabCategories: iabString,
      tags: tagString,
      transcript: transcriptString,
      url:
        window.location.origin +
        `/transcription/${mediaid}?time=${toNumber(chapter.startTime)}`,
    };

    newChapterData.push(newChapter);
  });

  return newChapterData;
};

export const getTranscriptByTime = (startTime?: number, endTime?: number) => {
  // 221.5 (198 - 217) (219 - 220) (223 - 226)
  // 121 (108 - 115) (115 - 123)
  const startIndex = (window.Editor.children as CaptionElement[]).findIndex(
    (child, index, children) => {
      const isOutsideCaption =
        children?.[index - 1]?.data?.end <= toNumber(startTime) &&
        child?.data.start >= toNumber(startTime);

      const isBetweenCaption =
        child?.data.start <= toNumber(startTime) &&
        child?.data.end >= toNumber(startTime);

      return isOutsideCaption || isBetweenCaption;
    },
  );

  const endIndex = (window.Editor.children as CaptionElement[]).findIndex(
    (child) =>
      child?.data.start <= toNumber(endTime) &&
      child?.data.end >= toNumber(endTime),
  );

  if (startIndex > 0 && endIndex < 0) {
    return (window.Editor.children as CaptionElement[]).slice(
      startIndex,
      (window.Editor.children as CaptionElement[]).length,
    );
  }
  return (window.Editor.children as CaptionElement[]).slice(
    startIndex,
    endIndex + 1,
  );
};

export const handleGetMentionReportData = (
  focusList: LayerCustomTerm,
  lockedList: IABItemModel[],
  mediaid: string,
) => {
  const newMentionReportData: MentionLayerData[] = [];

  focusList?.matched.forEach((item) => {
    const occurrences = item.mentions[0].occurrences;

    occurrences.forEach((occurrence, index) => {
      const caption = (window.Editor.children as CaptionElement[]).find(
        (child) =>
          child?.data.start <= toNumber(occurrence.s) &&
          child?.data.end >= toNumber(occurrence.e ?? occurrence.s),
      );

      if (caption) {
        const iabString = lockedList
          .map((item) => {
            const isInclude = item.chapters?.some(
              (chapter) =>
                chapter.s <= toNumber(occurrence.s) &&
                chapter.e <= toNumber(occurrence.e ?? occurrence.s),
            );
            return isInclude ? item.name : '';
          })
          .join(' ');

        const newMentionReportDataItem = {
          id: v4(),
          index: index + 1,
          term: item.keyword,
          startTime: toNumber(caption.data.start),
          endTime: toNumber(caption.data.end),
          duration: toNumber(caption.data.end) - toNumber(caption.data.start),
          totalMentions: occurrences.length,
          iab: iabString,
          transcript: caption.children.map((c) => c.text).join(''),
          url:
            window.location.origin +
            `/transcription/${mediaid}?time=${toNumber(occurrence.s)}`,
        };

        newMentionReportData.push(newMentionReportDataItem);
      }
    });
  });

  return newMentionReportData;
};

export const hasLockedByStatusText = (status: string): boolean => {
  return [
    VideoStatusText.UPLOADING,
    VideoStatusText.TRANSCRIBING,
    VideoStatusText.AI_PROCESSING,
    VideoStatusText.EDITING,
  ].includes(status as VideoStatusText);
};

export const percentToTime = (percent: number, duration: number) => {
  if (!duration || !percent) return 0;

  return (percent / 100) * duration;
};

export const timeToPercent = (time: number, duration: number) => {
  if (!duration || !time) return 0;

  return (time / duration) * 100;
};

export const getStatusItem = (item: IClips): string => {
  if (!item) return '';

  if (isTrendingClips(item)) {
    return isEqual(String(item?.statuscode), ClipStatusCode.CREATED)
      ? 'Trending'
      : item?.status;
  }

  if (isEqual(String(item?.statuscode), ClipStatusCode.CREATING))
    return 'Creating';

  if (isSuggestedClips(item)) {
    return isEqual(String(item?.statuscode), ClipStatusCode.CREATED)
      ? 'Suggested'
      : item?.status;
  }
  const isOriginal = isOriginalClips(item);

  if (isOriginal && item.statuscode === ClipStatusCode.CREATED) {
    return 'Original';
  }

  return isOriginal && isSharedClips(item) ? 'Shared' : item?.status;
};

export const getCurrentSubdomain = (): string | undefined => {
  const domainParts = window.location.hostname.split('.');

  // Eg: example.sonnant.ai => example
  if (domainParts?.length === 3) {
    return domainParts?.[0];
  }

  return undefined;
};

export const getDetailCompareIntegration = (
  integrations: IntegrationOptions[],
) => {
  return integrations.map((i) => ({
    apiKey: i?.apiKey,
    webhookSecret: i?.webhookSecret,
  }));
};

export const formatCreatedReports = (
  payload: CreatedReportsPayload[],
): CreatedReports[] => {
  return payload.map((p) => ({
    id: p?.report_id,
    title: p?.report_title,
    status: p?.status,
    createdAt: p?.created_at,
    createdBy: p?.created_by,
    type: p?.type_report,
  }));
};

export const getJoinedSegments = (
  speakerSegments: IOccurrence[],
): IOccurrence[] => {
  if (isEmpty(speakerSegments)) return [];

  const joinedSegments = [];

  for (let i = 0; i < speakerSegments?.length; i++) {
    const currentSegment = speakerSegments[i];

    if (i === 0) {
      joinedSegments.push(currentSegment);
      continue;
    }

    let lastSegment: IOccurrence = { ...joinedSegments.pop()! };

    // if (currentSegment.s === lastSegment.e) {
    const gapSecond = (currentSegment?.s ?? 0) - (lastSegment?.e ?? 0);

    // Ignore 1 second gap
    if (gapSecond < 1) {
      lastSegment.e = currentSegment.e;
      joinedSegments.push({
        ...lastSegment,
        wordCount:
          (lastSegment?.wordCount ?? 0) + (currentSegment?.wordCount ?? 0),
      } as IOccurrence);
    } else {
      joinedSegments.push(lastSegment);
      joinedSegments.push(currentSegment);
    }
  }
  return joinedSegments;
};

export const countWords = (str: string): number => {
  const matches = str.match(/[\w\d\\’\\'-]+/gi);

  return matches ? matches?.length : 0;
};

export const toSpeakerRaw = (speakers: SpeakerDiarisation[]): ISpeakerRaw[] => {
  return speakers.map((s, index) => ({
    id: index,
    name: s.speakerName,
    inUsed: true,
  }));
};

export const toSpeakerIdentification = (
  speakers: ISpeakerRaw[],
  hasIdentify: boolean,
): SpeakerDiarisation[] => {
  return speakers.map((s) => ({
    id: v4(),
    speakerName: s.name,
    hasIdentify,
    hasPrompt: true,
  }));
};

export const trimAllSpaces = (str: string): string => {
  return str.replace(/\s+/g, ' ').trim();
};

export const getTypeMentionReports = (type: string) => {
  if (type === MentionReportTypeEnum.SINGLE_REPORT) return 'Single-item report';
  if (type === MentionReportTypeEnum.MULTIPLE_REPORT)
    return 'Multi-items report';
  if (type === MentionReportTypeEnum.SINGLE_COMBINED)
    return 'Single-item combined clip';
  if (type === MentionReportTypeEnum.MULTIPLE_COMBINED)
    return 'Multi-items combined clip';
  if (type === null) return '-';

  return '';
};

export const getTypeCreatedReports = (type: string) => {
  if (type === CreatedReportEnum.DRAFT) return 'Created';
  if (type === CreatedReportEnum.CREATING) return 'Creating';
  if (type === CreatedReportEnum.ERROR) return 'Error';

  return '';
};

export const getMediaidFromClip = (item: IClips) =>
  item?.media_id ?? item?.publishpath?.split('/')?.[4];

export const handleAdmarkerPayload = (
  response: AdMarkerLayerResponse[],
): AdmarkerLayerPayload[] => {
  return response.map((res) => {
    return {
      totalMarkers: response.length,
      iab: res.iab,
      marker: res.marker,
      url: res.marker_url,
      startTime: res.start_time,
      adsServed: res?.ads_served ?? 1,
      tags: res.tags,
      transcriptPrior: res.transcript_prior,
      transcriptAfter: res.transcript_after,
    };
  });
};

export const convertPieDataList = (
  insightsResponse: Insights,
): SeriesKeyValue => {
  const result: SeriesKeyValue = {};

  map(insightsResponse, (value, key) => {
    value.buckets.forEach((bucket) => {
      if (!result[key]) result[key] = [];

      if (bucket.is_enabled) {
        const termAlias = getAliasBucket(bucket);

        result[key].push([termAlias, bucket.doc_count, bucket.key]);
      }
    });
  });

  return result;
};

export const convertPieBucket = (pinnedChartsRaw: GeneratedChartRaw) => {
  const result: SeriesKeyValue = {
    [pinnedChartsRaw.key_term]: [],
  };

  pinnedChartsRaw?.buckets?.forEach((bucket) => {
    if (bucket.is_enabled) {
      const termAlias = getAliasBucket(bucket);

      result[pinnedChartsRaw.key_term].push([
        termAlias,
        bucket.doc_count,
        bucket.key,
      ]);
    }
  });

  return result;
};

const getAliasBucket = (bucket: Bucket): string => {
  return bucket?.alias ?? bucket.key;
};

export const getWindowScrollY = (element: HTMLDivElement | null): number => {
  if (!element) return 0;

  return element?.scrollTop ?? 0;
};

export const scrollToY = (
  element: HTMLDivElement | null,
  scrollPosition: number,
) => {
  if (!element) return;

  return element.scrollTo(0, scrollPosition);
};

export const embedToShareUrl = (): string => {
  try {
    const embedUrl = window.location.pathname;

    if (!embedUrl.startsWith(Routes.EMBED)) {
      console.error('Current side is not embedded page!');

      return '';
    }

    return (
      config.LIVE_ENV_URL +
      window.location.pathname.replace(/\/embed(\/v2)?/, Routes.PUBLIC_SHARED)
    );
  } catch (error) {
    console.log('Failed to parse share URL', error);
    return '';
  }
};

export const convertTermList = (
  insightsResponse: Insights,
): ExportAliasTerm[] => {
  const result: ExportAliasTerm[] = [];

  map(insightsResponse, (value, key) => {
    value.buckets.forEach((bucket) => {
      result.push({
        original: bucket.key,
        isEnabled: bucket.is_enabled,
        alias: bucket?.alias ?? null,
        doc_count: bucket.doc_count,
        relevance_score: bucket?.relevance_score ?? 0,
      });
    });
  });

  return result;
};

export const convertBucketToTermList = (
  buckets: Bucket[],
): ExportAliasTerm[] => {
  return buckets.map((bucket) => ({
    original: bucket.key,
    isEnabled: bucket.is_enabled,
    alias: bucket?.alias ?? null,
    doc_count: bucket.doc_count,
    relevance_score: bucket?.relevance_score ?? 0,
  }));
};

export const getTotalDocCount = (buckets: TrendingInsightKey[]): number => {
  return buckets.reduce((total, bucket) => total + bucket.doc_count, 0);
};

export const toAliasTerms = (
  trendingTerms: TrendingTermSearchInsight[],
): ExportAliasTerm[] => {
  return trendingTerms.map((trendingTerm) => ({
    original: trendingTerm?.term,
    isEnabled: defaultTo(trendingTerm?.is_enabled, true),
    alias: defaultTo(trendingTerm?.alias, null),
    doc_count: getTotalDocCount(trendingTerm.term_count_over_time.buckets),
    tagline: trendingTerm?.tagline,
  }));
};

export const saveHtmlToPngAsync = async (
  htmlElement: HTMLElement,
  filename: string,
  callback: (document: Document) => void,
) => {
  if (!htmlElement) {
    console.error('Invalid HTML input');

    return;
  }

  const canvas = await html2canvas(htmlElement, {
    logging: true,
    scale: 2, // avoid zooming out creates small image
    onclone: callback,
  });

  if (!canvas) return;

  canvas.toBlob((blob) => {
    if (!blob) return;

    saveAs(blob, filename);
  });
};

export const getSearchParamUrl = (param: string): string | null => {
  const href = window.location.href;

  try {
    const url = new URL(href);

    return url?.searchParams?.get(param);
  } catch (error) {
    console.log('error :>> ', error);

    return null;
  }
};

const STORAGE_NAME_IGNORED_CONFIRM_BLACK_LIST = btoa(
  'isShowIgnoredConfirmAddBlacklist',
);

export const getIsIgnoredConfirmAddBlacklistStorage = (): boolean => {
  return (
    window.sessionStorage.getItem(STORAGE_NAME_IGNORED_CONFIRM_BLACK_LIST) ===
    'true'
  );
};

export const setIsIgnoredConfirmAddBlacklistStorage = (
  isShowConfirmAddBlacklistLocal: boolean,
) => {
  window.sessionStorage.setItem(
    STORAGE_NAME_IGNORED_CONFIRM_BLACK_LIST,
    String(isShowConfirmAddBlacklistLocal),
  );
};

export const appendId = (array: string[]): TextItem[] =>
  array.map((text) => ({ text: text?.trim(), id: v4() }));

export const isAxiosError = (error: unknown) => axios.isAxiosError(error);

export const processAliasDisplayTerms = (
  apiTermList: ExportAliasTerm[],
): ExportAliasTerm[] => {
  const enabledAliasTerms: ExportAliasTerm[] = [];
  const disabledAliasTerms: ExportAliasTerm[] = [];

  apiTermList.forEach((aliasTerm) => {
    if (aliasTerm.isEnabled && enabledAliasTerms.length < CHART_ITEM_COUNT) {
      enabledAliasTerms.push(aliasTerm);
    } else {
      aliasTerm.isEnabled = false;
      disabledAliasTerms.push(aliasTerm);
    }
  });

  const sortedList: ExportAliasTerm[] = orderBy(
    [...enabledAliasTerms, ...disabledAliasTerms],
    ['relevance_score', 'doc_count'],
    ['desc', 'desc'],
  );

  return sortedList;
};

export const getTermExplorationChartTitle = (
  title: string,
  layer?: string,
): string => {
  return isEmpty(layer)
    ? `Term Exploration of '${title}'`
    : `Term Exploration of '${title}' - ${layer}`;
};

export const convertCustomTermToOption = (
  customTerms: CustomTerm[],
): Option[] => {
  return customTerms.map((customTerm) => ({
    label: customTerm.name,
    value: customTerm.id,
  }));
};

export const isArchiveTag = (tagName: string) => {
  return tagName === ARCHIVE_TAG_NAME;
};

export const toProgramIdOptions = (
  programIdResponses: MegaphoneProgramIdsResponse[],
): ProgramIdOption[] => {
  return programIdResponses.map((programIdResponse) => ({
    label: programIdResponse.title,
    value: programIdResponse.program_id,
    imageURL: programIdResponse.image_public_url,
  }));
};

export const addTaglineToTrendingTermSearchInsight = (
  data: TrendingTermSearchInsightResponse[],
  trendingTerms: TrendingTerm[],
): TrendingTermSearchInsight[] => {
  return data.map((item) => ({
    ...item,
    tagline: defaultTo(
      trendingTerms?.find((term) => term.term === item.term)?.tagline,
      '',
    ),
  }));
};
