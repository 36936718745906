import { cloneDeep, isEqual, isNil, toNumber } from 'lodash';
import { VideoStatusCode, VideoStatusText } from 'utils/enum';
import * as types from '../actions/types';
import {
  ILibraryItem,
  Insights,
  LibraryStatistics,
  SagaAction,
} from 'utils/models';
import { toDateTz } from 'utils/date.util';
import { getSearchParamUrl } from 'components/VideoPlayer/Transcription/MediaUtilities';

interface InitialStateType {
  loading: boolean;
  error: boolean;
  data: any;
  location: string;
  selectedRows: ILibraryItem[];
  focusRow: ILibraryItem | null;
  isSelectMode: boolean;
  statistics?: any;
  showSearch: boolean;
  searchTerm: string;
  [type: string]: any;
}
const searchParam = getSearchParamUrl('search');
const layerParam = getSearchParamUrl('layer') as keyof Insights;

const getInitialSearchTerm = (): string => {
  if (searchParam && layerParam) {
    return layerParam === 'transcripts' ? searchParam : '';
  }

  return searchParam ?? '';
};

const initialState = {
  loading: false,
  error: false,
  data: [],
  location: 'Australia/Sydney',
  focusRow: null,
  selectedRows: [],
  isSelectMode: false,
  showSearch: false,
  searchTerm: getInitialSearchTerm(),
};

export const libraryReducer = (
  state: InitialStateType = initialState,
  action: SagaAction,
) => {
  switch (action.type) {
    case types.SET_FOCUS_ROW: {
      return { ...state, focusRow: action.payload };
    }
    case types.SET_LIBRARY_LOADING: {
      return { ...state, loading: action?.isLoading };
    }
    case types.FETCH_LIBRARY: {
      return { ...state, loading: true, error: false };
    }
    case types.FETCH_LIBRARY_NO_LOADING: {
      return { ...state, error: false };
    }
    case types.UPDATE_TITLE: {
      return { ...state, error: false };
    }

    case types.UPDATE_TITLE_FAIL: {
      return { ...state, error: true, data: action.payload };
    }

    case types.SET_SELECTED_ROWS: {
      return { ...state, selectedRows: action.payload };
    }

    case types.SET_SELECT_MODE: {
      return { ...state, isSelectMode: action.payload };
    }

    case types.SET_LIBRARY: {
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
        // Default value of empty
        statistics:
          action?.payload?.total ??
          ({
            sharedItems: 0,
            totalAudio: 0,
            totalDurations: 0,
            totalFiles: 0,
            totalVideo: 0,
          } as LibraryStatistics),
      };
    }
    case types.SET_LOCATION: {
      return {
        ...state,
        location: action.payload,
      };
    }
    case types.FAILED_LIBRARY: {
      return { ...state, loading: false, error: true };
    }
    case types.TOGGLE_SEARCH_BAR: {
      return { ...state, showSearch: action.isSearchVisible };
    }
    case types.SEARCH_CLICK: {
      return {
        ...state,
        searchTerm: action.searchTerm,
      };
    }
    case types.SEARCH_CLEAR: {
      return { ...state, searchTerm: '' };
    }
    case types.TRIGGER_MEDIA_ID: {
      const socketItem = action.payload;
      if (!socketItem.modifieddatetime) {
        socketItem.modifieddatetime = toDateTz(new Date(), state.location);
      }

      if (
        isEqual(
          toNumber(socketItem.statuscode),
          VideoStatusCode.THUMBNAIL_UPDATING,
        )
      ) {
        socketItem.status = VideoStatusText.THUMBNAIL_UPDATING;
      }

      const libraryItems = cloneDeep(state.data?.library) ?? [];

      if (
        isEqual(
          toNumber(socketItem.statuscode),
          VideoStatusCode.DELETE_SUSPENDED,
        )
      ) {
        return {
          ...state,
        };
      }
      const foundIndex = libraryItems.findIndex((item: any) =>
        isEqual(item.mediaid, socketItem.mediaid),
      );

      // NOT FOUND
      if (foundIndex < 0) {
        libraryItems.unshift(socketItem); // Insert at beginning
        // EXISTED
      } else {
        socketItem.userid = libraryItems[foundIndex].userid;
        if (libraryItems[foundIndex].createddatetime) {
          socketItem.createddatetime = libraryItems[foundIndex].createddatetime;
        } else if (socketItem.createddatetime) {
          socketItem.createddatetime = toDateTz(
            socketItem.createddatetime,
            state.location,
          );
        }
        libraryItems[foundIndex] = {
          ...libraryItems[foundIndex],
          ...socketItem,
        };
      }
      return {
        ...state,
        data: { ...state.data, library: libraryItems },
      };
    }
    case types.ADD_CLIP: {
      if (!state?.data?.library) return state;

      const libraryItems: ILibraryItem[] = [...state.data?.library];
      const foundItem = libraryItems.find(
        (item: ILibraryItem) => item?.mediaid === action?.mediaid,
      );

      if (!isNil(foundItem?.clips_count)) {
        foundItem!.clips_count += 1;
      }

      return {
        ...state,
        data: { ...state.data, library: libraryItems },
      };
    }

    case types.REMOVE_CLIP: {
      if (!state?.data?.library) return state;

      const libraryItems: ILibraryItem[] = [...state.data?.library];
      const foundItem = libraryItems.find(
        (item: ILibraryItem) => item?.mediaid === action?.mediaid,
      );

      if (!isNil(foundItem?.clips_count) && foundItem!.clips_count > 0) {
        foundItem!.clips_count -= 1;
      }

      return {
        ...state,
        data: { ...state.data, library: libraryItems },
      };
    }

    case types.REMOVE_CLIP_FROM_COLLECTION: {
      if (!state?.data?.library) return state;

      const libraryItems: ILibraryItem[] = [...state.data?.library];
      const remainData = libraryItems.filter(
        (item: ILibraryItem) => item.mediaid !== action?.mediaid,
      );

      return {
        ...state,
        data: { ...state.data, library: remainData },
      };
    }

    default:
      return state;
  }
};
