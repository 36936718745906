import { BaseAPI } from 'apis/amazon';
import { AxiosResponse } from 'axios';
import { RevivalPagination } from 'utils/models';

export const ReviveService = {
  // GET /revive
  getReviveItems: (): Promise<AxiosResponse<RevivalPagination>> => {
    return BaseAPI.get('/revive');
  },
};
