/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { CollapsibleList } from '@rmwc/list';
import { Ripple } from '@rmwc/ripple';
import { ReactComponent as CollapsedSvg } from 'assets/Icons/chevron_right.svg';
import { ReactComponent as ExpandedSvg } from 'assets/Icons/expand_more.svg';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useMount, useToggle } from 'react-use';
import { ListOption } from 'utils/models';
import { Info } from '../Info';
import { SearchInput } from '../SearchInput';
import { SimpleCheckbox } from '../SimpleCheckbox';
import { scrollbarWidthCss, textShadow } from '../twin.styles';
import { searchBy } from 'utils/generic.util';

type Props = {
  title: string;
  optionList: ListOption[];
  onSelectionChanged: (selectedIds: string[]) => void;
  defaultSelectedIds: string[];

  isLoading?: boolean;
  defaultOpen?: boolean;
};

const MAX_ITEMS_SHOW = 4;

export const FilterCollapsibleItem = ({
  title,
  optionList,
  isLoading = false,
  defaultOpen = false,
  defaultSelectedIds,
  ...props
}: Props) => {
  const [isExpanded, toggleExpanded] = useToggle(defaultOpen);
  const [isViewAll, toggleViewAll] = useToggle(false);

  const [searchText, setSearchText] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const searchedItems = useMemo(() => {
    if (isEmpty(searchText.trim())) return optionList;

    const searchedItems = optionList.filter(searchBy(searchText, ['label']));

    return searchedItems;
  }, [searchText, optionList.length]);

  useEffect(() => {
    setSelectedIds(defaultSelectedIds);
  }, [defaultSelectedIds]);

  useMount(() => {
    if (isEmpty(defaultSelectedIds)) return;

    // Persist selections
    props.onSelectionChanged(defaultSelectedIds);
  });

  const displayItems = isViewAll
    ? searchedItems
    : searchedItems.slice(0, MAX_ITEMS_SHOW);

  const handleToggleSelect = (item: ListOption) => {
    const isExisted = selectedIds.includes(item.value);

    const newSelectedIds = isExisted
      ? selectedIds.filter((value) => value !== item.value)
      : selectedIds.concat(item.value);

    setSelectedIds(newSelectedIds);

    props.onSelectionChanged(newSelectedIds);
  };

  return (
    <div
      tw="bg-[#f9f9f9] rounded"
      css={[childrenAnimationCss(isExpanded)]}
      onClick={(e) => e.stopPropagation()}
    >
      <CollapsibleList
        handle={
          <Ripple tw="w-full bg-white rounded" onClick={toggleExpanded}>
            <div tw="flex items-center py-1 cursor-pointer [svg]:(fill[#7f8090])">
              <span tw="flex">
                {isExpanded ? <ExpandedSvg /> : <CollapsedSvg />}
              </span>

              <div tw="flex flex-nowrap text-14 font-medium text-sonnant-grey-6 opacity-95 select-none">
                {title}
              </div>
            </div>
          </Ripple>
        }
        open={isExpanded}
      >
        {isExpanded && (
          <div tw="flex flex-col gap-y-2 px-4 py-4 pb-2">
            <div
              tw="flex justify-center items-center"
              css={[customSearchInputStyle]}
            >
              <SearchInput
                setTerm={setSearchText}
                placeholder="Search item"
                noShrink
              />
            </div>

            <div
              tw="flex flex-col pl-1 max-h-[20rem] overflow-y-auto"
              css={[scrollbarWidthCss(4)]}
            >
              {displayItems.map((item, index) => (
                <div
                  key={index}
                  tw="flex justify-start items-center flex-row w-full select-none [label]:(font-medium)"
                  title={item.label}
                >
                  <SimpleCheckbox
                    checked={selectedIds.includes(item.value)}
                    onChange={() => handleToggleSelect(item)}
                    label={item.label}
                  />
                </div>
              ))}

              {isEmpty(displayItems) && (
                <div>
                  <Info
                    text="No results found"
                    fontSize="1.3rem"
                    hideSpacingBottom
                  />
                </div>
              )}
            </div>

            {isLoading && <Info text="Loading..." />}

            {searchedItems.length > MAX_ITEMS_SHOW && (
              <div css={viewAllStyle} onClick={toggleViewAll}>
                {isViewAll ? 'Show less...' : 'View all...'}
              </div>
            )}
          </div>
        )}
      </CollapsibleList>
    </div>
  );
};

const customSearchInputStyle = css`
  input:not([type='checkbox']) {
    ${tw`(text-13 placeholder:(text-12) font-normal h-[3rem] min-height[unset] pl-[1rem] bg-white border[1px solid] border-sonnant-grey-light rounded-md rounded-r-none border-r-0)!`}
  }

  .search-container button {
    ${tw`(flex justify-center items-center h-[3rem] w-[3rem] border-width[1px] border-l-0 border-sonnant-grey-light rounded-r-lg)!`}
  }
`;

const viewAllStyle = css`
  ${tw`pl-1 -mt-2 text-13 text-blue-500 font-medium cursor-pointer`}

  :hover {
    ${textShadow('darkgrey')}
  }
`;

const childrenAnimationCss = (isExpanded: boolean) => css`
  .rmwc-collapsible-list__children {
  }
`;
