/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Info } from 'components/shared/Info';
import { WaveSurferVideo } from 'components/shared/Wavesurfer/WaveSurferVideo';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useEmbedContext } from '../../utils/useEmbedContext';

type Props = {};

export const EmbedTimeTrack = (props: Props) => {
  const mediaPlayer = useEmbedContext();

  const media = useSelector((state: RootState) => state.media);

  return (
    <div tw="flex-1 relative z-0">
      {media?.url && mediaPlayer?.videoRef.current ? (
        <div>
          <WaveSurferVideo
            src={media?.url}
            ref={mediaPlayer.videoRef}
            shouldHideRegion={true}
            progressColor={'#9B99FF'}
            customParams={{
              waveColor: '#D8D8D8',
              cursorColor: '#9B99FF',
              cursorWidth: 2,
              barWidth: 3,
              barRadius: 2,
              barGap: 2,
              hideScrollbar: true,
              normalize: true,
              fillParent: true,
              height: 60,
            }}
          />
        </div>
      ) : (
        <Info text="Loading..." />
      )}
    </div>
  );
};
