/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { cloneDeep, findIndex, isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';

import { Option } from 'components/shared/CustomSelectSearch';
import {
  addMegaphoneIntegrationFilter,
  removeMegaphoneIntegrationFilter,
  updateSingleMegaphoneIntegration,
} from 'slices/megaphone.slice';
import { CollectionOptionEnum } from 'utils/enum';
import {
  MegaphoneIntegrationOption,
  MegaphoneIntegrationSettingsPayload,
} from 'utils/models';
import { customToast } from 'utils/toast.util';
import { MegaphoneIntegrationFilterItem } from './MegaphoneIntergrationFilterItem';

type Props = {
  integration: MegaphoneIntegrationOption;
};

export const MegaphoneIntegrationFilter = ({
  integration,
  ...props
}: Props) => {
  const dispatch = useDispatch();

  const lastProgramFilterIndex = integration.filter.programFilters.length - 1;

  const handleChangeNetworkId = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const value = e.target.value;

    updateProgramFilter(index, 'networkId', value);
  };

  const handleAddNewFilter = () => {
    dispatch(addMegaphoneIntegrationFilter(integration));
  };

  const handleRemoveFilter = (index: number) => {
    dispatch(
      removeMegaphoneIntegrationFilter({
        integration,
        index,
      }),
    );
  };

  const handleChangeIntegration = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const type = e.target.type;

    const newIntegration = { ...integration };

    if (type === 'checkbox') {
      const checked = e.target.checked;

      if (name === 'isUseProgramName') {
        newIntegration.collectionOption = checked
          ? CollectionOptionEnum.MEGAPHONE
          : CollectionOptionEnum.COLLECTION;

        if (checked === true) {
          newIntegration.collectionId = null;
        }
      }

      dispatch(
        updateSingleMegaphoneIntegration({
          ...newIntegration,
          filter: { ...newIntegration.filter, [name]: checked },
        }),
      );
    }
  };

  const handleChangeIntegrationData = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const name = e.target.name;
    const checked = e.target.checked;

    const newIntegration = cloneDeep(integration);

    newIntegration.filter.integrationSettings[
      name as keyof MegaphoneIntegrationSettingsPayload
    ] = checked;

    dispatch(updateSingleMegaphoneIntegration(newIntegration));
  };

  const updateProgramFilter = (
    index: number,
    field: 'networkId' | 'programId',
    value: string,
  ) => {
    const newIntegration = cloneDeep(integration);

    newIntegration.filter.programFilters[index][field] = value;

    if (field === 'networkId') {
      newIntegration.filter.programFilters[index].programId = '';
    }

    dispatch(updateSingleMegaphoneIntegration(newIntegration));
  };

  const handleSelectProgramId = (newValue: Option, index: number) => {
    // Avoid default empty event dispatch from select
    if (isEmpty(newValue.value) && isEmpty(newValue.label)) return;

    const newProgramFilter = [...integration.filter.programFilters];

    const foundDuplicate = findIndex(
      newProgramFilter,
      (filter) => filter.programId === newValue.value,
    );

    if (foundDuplicate >= 0) {
      customToast.error('Program ID already exists');

      return;
    }

    updateProgramFilter(index, 'programId', newValue.value);
  };

  return (
    <div tw="w-1/2 mt-[1.5rem] flex flex-col pl-[3rem]">
      <div tw="flex flex-wrap gap-y-[1rem]">
        {integration.filter.programFilters?.map((filter, index) => (
          <MegaphoneIntegrationFilterItem
            key={index}
            apiKey={integration.apiKey}
            filter={filter}
            index={index}
            lastProgramFilterIndex={lastProgramFilterIndex}
            onChangeNetworkId={handleChangeNetworkId}
            onSelectProgramId={handleSelectProgramId}
            onAddNewFilter={handleAddNewFilter}
            onRemoveFilter={handleRemoveFilter}
          />
        ))}
      </div>

      <div tw="flex justify-start space-x-8">
        <label tw="min-w-[27rem] flex[unset]! cursor-pointer">
          <input
            name="isUseProgramName"
            checked={integration.filter.isUseProgramName}
            onChange={handleChangeIntegration}
            type="checkbox"
          />
          <span className="checkmark" style={{ top: '4px' }}></span>
          Use Megaphone program name
        </label>

        <label tw="min-w-[27rem] flex[unset]! cursor-pointer">
          <input
            name="isAutomatePushBack"
            checked={integration.filter.isAutomatePushBack}
            onChange={handleChangeIntegration}
            type="checkbox"
          />
          <span className="checkmark" style={{ top: '4px' }}></span>
          Automate integration back to Megaphone
        </label>
      </div>

      <div tw="my-5">
        <label tw="flex flex-nowrap text-16 mr-3">Integration data</label>
        <div tw="flex justify-between mt-3">
          <div tw="w-[25rem]">
            <label tw="max-w-full flex w-1/2 flex[unset]! cursor-pointer">
              <input
                name="hasMonetisation"
                checked={integration.filter.integrationSettings.hasMonetisation}
                onChange={handleChangeIntegrationData}
                type="checkbox"
              />
              <span className="checkmark" style={{ top: '4px' }}></span>
              Monetisation
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
