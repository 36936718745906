/** @jsxImportSource @emotion/react */
import 'twin.macro';

import {
  isSuggestedClips,
  isTrendingClips,
  msToTime,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { isEqual, toNumber } from 'lodash';
import { Row } from 'react-table';
import { toDateWithHours } from 'utils/date.util';
import { ClipStatusCode } from 'utils/enum';
import { IClips } from 'utils/models';

interface IProps {
  row: Row<IClips>;
  handleRowClick?: (item: IClips) => unknown;
  selectedRow?: IClips;
}

export const ShareItemRow = ({ row, ...props }: IProps) => {
  const item = row.original;

  const handleRowClick = () => {
    props?.handleRowClick?.(item);
  };

  const isSelected = isEqual(
    item.versioncount,
    props?.selectedRow?.versioncount,
  );

  const getStatusText = (): string => {
    if (
      [ClipStatusCode.CREATING, ClipStatusCode.CREATED].includes(
        item?.statuscode,
      ) &&
      !item?.from_suggested
    ) {
      if (isSuggestedClips(item)) {
        return 'Suggested';
      }

      if (isTrendingClips(item)) {
        return 'Trending';
      }
    }

    if (
      item?.versioncount === '0' &&
      item?.statuscode === ClipStatusCode.CREATED
    ) {
      return 'Original';
    }

    switch (item?.statuscode) {
      case ClipStatusCode.CREATING:
        return 'Creating';
      case ClipStatusCode.CREATED:
        return 'Created';
      case ClipStatusCode.SHARED:
        return 'Shared';
      default:
        return item?.status ?? '';
    }
  };

  return (
    <tr
      {...row.getRowProps()}
      onClick={() => handleRowClick()}
      className={`background_share ${isSelected && 'active'}`}
    >
      <td tw="px-2.5! pr-6!" title={item.versionname}>
        {item.versionname}
      </td>
      <td width="11%">{`${msToTime(toNumber(item.length))}`}</td>
      <td width="18%">{`${toDateWithHours(item.datetime)}`}</td>
      <td width="9%">{getStatusText()}</td>
    </tr>
  );
};
