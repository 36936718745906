/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { SimpleMenuSurface } from '@rmwc/menu';
import { isEmpty, keys } from 'lodash';
import React, { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { userSelector } from 'slices/payment.slice';
import { FilterLayer } from 'utils/enum';
import { scrollbarWidthCss } from '../twin.styles';
import { FilterCollapsibleItem } from './FilterCollapsibleItem';
import { FilterMenuOptions } from 'utils/models';
import { useStateReset } from 'hooks/useStateReset';
import { restrictedTags } from 'slices/tag.slice';

type Props = {
  menuIsOpen: boolean;
  anchorElement: ReactElement;
  selectionFilters: FilterMenuOptions;
  filterLayers?: FilterLayer[];

  onApplyFilters: (filters: FilterMenuOptions) => void;
  onClearFilters: () => void;

  onCloseMenu?: (filters: FilterMenuOptions) => void;
  fixed?: boolean;
  showReset?: boolean;
  showApply?: boolean;
};

export const FilterWrapper = React.memo(
  ({
    menuIsOpen,
    anchorElement,
    filterLayers = [],
    selectionFilters,
    fixed = true,
    showReset,
    showApply = true,
    ...props
  }: Props) => {
    const tag = useSelector((state: RootState) => state.tags);
    const collection = useSelector((state: RootState) => state.collection);
    const omny = useSelector((state: RootState) => state.omny);

    const visibleTags = useSelector(restrictedTags);

    const isLoadingOmny = useSelector(userSelector).isLoading;

    const tagOptions = useMemo(() => {
      return visibleTags.map((tag) => ({
        label: tag.tagName,
        value: tag.tagId,
      }));
    }, [visibleTags]);

    const collectionOptions = useMemo(() => {
      return collection.items.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    }, [collection.items]);

    const orgOptions = useMemo(() => {
      return omny.settings
        .map(({ id, integrationName }) => ({
          label: integrationName,
          value: id,
        }))
        .filter((org) => !isEmpty(org.label));
    }, [omny.settings]);

    const [filterOptions, setFilterOptions, resetFilterOptions] =
      useStateReset<FilterMenuOptions>({
        tagIds: [],
        orgIds: [],
        collectionIds: [],
      });

    const hasLayer = (layer: FilterLayer): boolean => {
      if (isEmpty(filterLayers)) return true;

      return filterLayers.includes(layer);
    };

    const setPartialFilters =
      (key: keyof FilterMenuOptions) => (ids: string[]) => {
        setFilterOptions((oldFilters) => ({
          ...oldFilters,
          [key]: ids,
        }));
      };

    const handleCloseMenu = () => {
      // Prevent duplicated events
      if (menuIsOpen === true) return;

      if (typeof props.onCloseMenu !== 'function') return;

      props.onCloseMenu(filterOptions);
    };

    const onClickApplyButton = () => {
      const isEmptyFilters = keys(filterOptions).every((key) =>
        isEmpty(filterOptions[key as keyof FilterMenuOptions]),
      );

      if (isEmptyFilters) {
        props.onClearFilters();
      } else {
        props.onApplyFilters(filterOptions);
      }
    };

    const onClickResetButton = () => {
      resetFilterOptions();

      props.onClearFilters();
    };

    return (
      <div onClick={(e) => e.stopPropagation()}>
        <SimpleMenuSurface
          open={menuIsOpen}
          handle={anchorElement}
          anchorCorner="bottomLeft"
          onClose={handleCloseMenu}
          fixed={fixed}
          tw="my-[4px] min-w-[28rem] rounded-lg overflow-hidden z-[21]"
        >
          <div tw="px-3 py-3">
            <div tw="text-18 pl-2.5 font-medium opacity-90">Filters</div>

            <div
              tw="max-h-[60vh] overflow-y-auto my-2"
              css={[scrollbarWidthCss(4)]}
            >
              {hasLayer(FilterLayer.TAG) && (
                <FilterCollapsibleItem
                  title="Tags"
                  optionList={tagOptions}
                  defaultSelectedIds={selectionFilters['tagIds']}
                  onSelectionChanged={setPartialFilters('tagIds')}
                  isLoading={tag.isLoading}
                  defaultOpen
                />
              )}

              {hasLayer(FilterLayer.COLLECTION) && (
                <FilterCollapsibleItem
                  title="Collections"
                  optionList={collectionOptions}
                  defaultSelectedIds={selectionFilters['collectionIds']}
                  onSelectionChanged={setPartialFilters('collectionIds')}
                  isLoading={collection.isLoading}
                />
              )}

              {hasLayer(FilterLayer.ORG) && (
                <FilterCollapsibleItem
                  title="Organisation Name"
                  optionList={orgOptions}
                  defaultSelectedIds={selectionFilters['orgIds']}
                  onSelectionChanged={setPartialFilters('orgIds')}
                  isLoading={isLoadingOmny}
                />
              )}
            </div>

            <div tw="flex gap-x-3 justify-end [button]:(text-13)!">
              {showReset && (
                <button
                  className="button btn-primary medium"
                  tw="(px-[1rem] mb-0 bg-sonnant-red)!"
                  onClick={onClickResetButton}
                >
                  Reset
                </button>
              )}

              {showApply && (
                <button
                  className="button btn-primary medium"
                  tw="(px-[1rem] mb-0)!"
                  onClick={onClickApplyButton}
                >
                  Apply
                </button>
              )}
            </div>
          </div>
        </SimpleMenuSurface>
      </div>
    );
  },
);
