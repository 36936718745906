/** @jsxImportSource @emotion/react */
import React, { useRef } from 'react';
import { SimpleMenuSurface } from '@rmwc/menu';
import { ReactComponent as BellSvg } from 'assets/Icons/bell.svg';
import { ReactComponent as BellWithDotSvg } from 'assets/Icons/notifications_not_empty.svg';
import { useClickAway, useToggle } from 'react-use';
import { ReactComponent as CloseIcon } from 'assets/Icons/close.svg';
import { ReactComponent as ProcessIcon } from 'assets/Icons/process.svg';
import { ReactComponent as ErrorIcon } from 'assets/Icons/error.svg';
import { ReactComponent as CompleteIcon } from 'assets/Icons/complete.svg';
import { ReactComponent as WatchIcon } from 'assets/Icons/watchlist.svg';
import { NotifyDetail } from './NotifyDetail';
import {
  bellCss,
  bgTrending,
  hoverDropShadow,
} from 'components/shared/twin.styles';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import { useState } from 'react';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { Hint } from 'components/shared/Hint';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { clearAllNotify } from 'slices/notification.slice';
import { NotificationFilter } from 'utils/enum';
import { Modal } from 'components/UiControls/modal/modal';
import { Share } from 'components/Share/Share';
import { ShareSocialTrending } from 'utils/models';
import {
  NotiDeletePayload,
  NotificationService,
} from 'services/notification.service';
import { getTenantidFromIdToken } from 'utils/utils';
import { memo } from 'react';

export const GlobalNotifications = memo(() => {
  // console.count('GlobalNotifications');
  const dispatch = useDispatch();
  const notiItems = useSelector(
    (state: RootState) => state.notification.notifications,
  );
  const isLoaded = useSelector(
    (state: RootState) => state.notification.isLoaded,
  );
  const userProfile = useSelector((state: RootState) => state.userProfile);

  const [isShowNotify, toggleShowNotify] = useToggle(false);
  const [filterArray, setFilterArray] = useState<NotificationFilter[]>([]);
  const [isOpenShareModal, toggleOpenShareModal] = useToggle(false);
  const [shareItem, setShareItem] = useState<any>(null);

  const clickAwayRef = useRef(null);
  useClickAway(clickAwayRef, () => {
    if (!isShowNotify) return;

    toggleShowNotify(false);
  });

  const filteredItems = notiItems
    .map((notify) => {
      const cloneNotify = cloneDeep(notify);

      if (isEmpty(filterArray)) return cloneNotify;

      cloneNotify.items = cloneNotify.items.filter((i) =>
        filterArray.includes(i.filter),
      );

      return cloneNotify;
    })
    .map((group) => ({
      ...group,
      items: group.items.filter(
        (i) => !i?.versioncount?.startsWith('trending'),
      ),
    }))
    .filter((group) => !isEmpty(group?.items));

  const toggleFilterBy = (type: NotificationFilter) => {
    // ADD new or REMOVE filter from list
    if (!filterArray.includes(type)) {
      setFilterArray([...filterArray, type]);
    } else {
      setFilterArray(filterArray.filter((t) => !isEqual(t, type)));
    }
  };

  const handleCloseModal = () => {
    setShareItem(null);
    toggleOpenShareModal(false);
  };

  const handleShareSocial = (shareItem: ShareSocialTrending) => {
    setShareItem(shareItem);
    toggleOpenShareModal(true);
  };

  const handleClearAll = async () => {
    dispatch(clearAllNotify(filterArray));

    let ids: string[] = [];
    if (isEmpty(filterArray)) {
      // Clear all WITHOUT filter
      ids = notiItems.reduce((ids: string[], group) => {
        ids.push(...group.items.map((i) => i.id));
        return ids;
      }, []);
    } else {
      // Clear all WITH filter
      filterArray.forEach((filteredStatus) => {
        notiItems
          .map((group) => ({
            ...group,
            items: group.items.filter((i) => i?.filter === filteredStatus),
          }))
          .forEach((group) => group.items.forEach((i) => ids.push(i.id)));
      });
    }

    try {
      const payload: NotiDeletePayload = {
        ids,
        isClearAll: true,
        filterType: filterArray,
        tenantId: getTenantidFromIdToken(),
        email: userProfile?.email,
      };

      await NotificationService.deleteMultipleIds(payload);
    } catch (err: any) {
      console.log('err :>> ', err);
    }
  };

  const handleClickBell = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    toggleShowNotify();
  };

  return (
    <div ref={clickAwayRef}>
      <SimpleMenuSurface
        open={isShowNotify}
        handle={
          <Hint
            disabled={isLoaded}
            text="Loading notifications"
            enterDelay={0}
            leaveDelay={0}
          >
            <div tw="cursor-pointer!">
              <div
                css={[bellCss]}
                tw="select-none flex items-center"
                onClick={handleClickBell}
                className={!isLoaded ? 'disabled' : ''}
              >
                {isEmpty(notiItems) ? <BellSvg /> : <BellWithDotSvg />}
              </div>
            </div>
          </Hint>
        }
        tw="(top[4rem] right[1.2rem] z-[21])!"
      >
        <div tw="width[42rem]">
          <div tw="relative max-height[80vh] overflow-hidden">
            <Hint text="Close notification">
              <div
                tw="absolute top[0.6rem] right[1.4rem] cursor-pointer"
                onClick={toggleShowNotify}
              >
                <CloseIcon width={12} height={12} css={hoverDropShadow(0.3)} />
              </div>
            </Hint>
            <div tw="w-full flex justify-between mt-3">
              <div tw="p-5 text-sonnant-purple-2 font-weight[500] w-9/12">
                Notifications
              </div>
              <Hint text="Clear all notifications">
                <div
                  tw="text-13 mr-5 mb-1 self-end ml-5 cursor-pointer hover:(underline)"
                  onClick={handleClearAll}
                >
                  Clear all
                </div>
              </Hint>
            </div>
            <hr css={dividerCss} />
            <div
              tw="overflow-y-auto max-height[calc(80vh - 11rem)]"
              css={scrollCss}
            >
              {isShowNotify &&
                filteredItems.map((item, index) => (
                  <NotifyDetail
                    key={index}
                    date={item.date}
                    list={item}
                    onClose={() => toggleShowNotify(false)}
                    onOpenShareModal={handleShareSocial}
                  />
                ))}
              {(notiItems?.length === 0 || filteredItems?.length === 0) && (
                <div tw="color[#54566C] text-xl font-medium my-4 ml-6">
                  Nothing to show. We'll notify you on any update
                </div>
              )}
            </div>
          </div>
          <hr css={dividerCss} />
          <div tw="flex items-center mt-5 mb-5 ml-8 justify-around w-11/12">
            <Hint text="Trending item">
              <div
                css={[
                  tw`shadow cursor-pointer`,
                  bgTrending,
                  trendingStyle,
                  filterArray.includes(NotificationFilter.TRENDING) && [
                    tw`box-shadow[0px 0px 4px 1px #436de5]`,
                  ],
                ]}
                onClick={() => toggleFilterBy(NotificationFilter.TRENDING)}
              >
                Trending
              </div>
            </Hint>

            <Hint text="Processing in progress">
              <div
                css={[
                  filterButtonCss,
                  filterArray.includes(NotificationFilter.PROGRESS)
                    ? [tw`bg-sonnant-orange`, activeFilterCss]
                    : tw`hover:(bg-sonnant-grey-3)`,
                ]}
                onClick={() => toggleFilterBy(NotificationFilter.PROGRESS)}
              >
                <ProcessIcon />
              </div>
            </Hint>

            <Hint text="Item completed">
              <div
                css={[
                  filterButtonCss,
                  filterArray.includes(NotificationFilter.COMPLETE)
                    ? [tw`bg-sonnant-green`, activeFilterCss]
                    : tw`hover:(bg-sonnant-grey-3)`,
                ]}
                onClick={() => toggleFilterBy(NotificationFilter.COMPLETE)}
              >
                <CompleteIcon />
              </div>
            </Hint>

            <Hint text="Error detected" enterDelay={200}>
              <div
                css={[
                  filterButtonCss,
                  filterArray.includes(NotificationFilter.ERROR)
                    ? [tw`background[red]`, activeFilterCss]
                    : tw`hover:(bg-sonnant-grey-3)`,
                ]}
                onClick={() => toggleFilterBy(NotificationFilter.ERROR)}
              >
                <ErrorIcon />
              </div>
            </Hint>

            <Hint text="Watch list detected" enterDelay={200}>
              <div
                css={[
                  filterButtonCss,
                  filterArray.includes(NotificationFilter.WATCH)
                    ? [tw`bg-sonnant-purple-2`, activeFilterCss]
                    : tw`hover:(bg-sonnant-grey-3)`,
                ]}
                onClick={() => toggleFilterBy(NotificationFilter.WATCH)}
              >
                <WatchIcon />
              </div>
            </Hint>
          </div>
        </div>
      </SimpleMenuSurface>

      {shareItem && (
        <Modal
          show={isOpenShareModal}
          modalClosed={handleCloseModal}
          classes="share-modal-width"
        >
          <Share
            mediaid={shareItem?.mediaid!}
            shareItem={shareItem}
          />
        </Modal>
      )}
    </div>
  );
});

const trendingStyle = css`
  top: 12px;
  left: 12px;
  height: 31px;
  line-height: 1;
  color: white;
  padding: 0.8rem;
  border-radius: 3px;
  font-size: 14px;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  &:hover,
  &:active,
  &:focus {
    border: 0px;
  }
`;

const dividerCss = css`
  ${tw`(border-none border-bottom[1.5px solid] border-sonnant-purple-2 width[95%] m-auto)!`}
`;

const filterButtonCss = css`
  ${tw`flex self-center cursor-pointer padding[6px] rounded-lg`}
`;

const activeFilterCss = css`
  ${tw`shadow-lg`}

  svg {
    fill: #fff !important;
  }
`;

const scrollCss = css`
  &::-webkit-scrollbar {
    width: 4px !important;
  }
`;
