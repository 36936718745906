/** @jsxImportSource @emotion/react */
import 'twin.macro';
import tw from 'twin.macro';
import React, { useRef, useState } from 'react';
import { SearchInput } from 'components/shared/SearchInput';
import { ReactComponent as AddTermSvg } from 'assets/Icons/library_add_black_24dp.svg';
import { ReactComponent as CloseSvg } from 'assets/Icons/close.svg';
import { RssSubscriptionItem } from './RssSubscriptionItem';
import { useEffectOnce, useToggle } from 'react-use';
import { Keys, ModalOptions } from 'utils/enum';
import {
  addUUID,
  extractApplePodcast,
  validateRssFeeds,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { Loader } from 'components/loader/loader';
import { customToast } from 'utils/toast.util';
import { UploadService } from 'services';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSubscriptionItem, setSubscribedFeeds } from 'slices/rss.slice';
import { RootState } from 'reducers';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { ConfirmModalProps } from 'utils/models';
import { RssService } from 'services/rss.service';
import { Info } from 'components/shared/Info';
import { isEmpty } from 'lodash';

export const RssSubscriptions = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isShowInput, toggleShowInput] = useToggle(false);
  const [isLoading, toggleLoading] = useToggle(true);
  const [rssURL, setRssURL] = useState('');

  const subscribedFeeds = useSelector(
    (state: RootState) => state.rss.subscribedFeeds,
  );

  const dispatch = useDispatch();

  const confirmModalRef = useRef<any>(null);

  useEffectOnce(() => {
    fetchRssSubscriptions();
  });

  const fetchRssSubscriptions = async () => {
    try {
      const { data: rssResponse } = await RssService.getRssSubscriptions();
      dispatch(setSubscribedFeeds(rssResponse.data));
    } catch (err: any) {
      console.log('err :>> ', err);
    } finally {
      toggleLoading(false);
    }
  };

  const handleAddSubscription = () => {
    toggleShowInput(true);
  };

  const handleKeyDownInput = async (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
      if (!rssURL?.trim()) {
        customToast.error('RSS URL cannot be empty');
        return;
      }
      try {
        toggleShowInput(false);
        customToast.loading('Validating RSS URL');

        const extractedURL = await extractApplePodcast(rssURL?.trim());
        const { data: rssFeeds } = await UploadService.previewRss({
          url: extractedURL,
        });
        const feeds = addUUID(rssFeeds?.data?.episodes);

        const isValidFeeds = validateRssFeeds(feeds);

        if (!isValidFeeds) {
          throw new Error(
            'No links to the media files could be found in the RSS feed.',
          );
        } else {
          customToast.loading('Subscribing RSS URL');
          await UploadService.uploadRss({
            feeds: [], // No upload on Subscribing RSS
            meta: rssFeeds?.data?.meta,
            isSubscribed: true,
            rawURL: extractedURL,
          });
          customToast.success('Added RSS Subscription successfully');
          // Reset input
          setRssURL('');

          fetchRssSubscriptions();
        }
      } catch (error: any) {
        console.log('error :>> ', error);
        customToast.error(
          isEmpty(error?.response)
            ? error?.message
            : 'RSS format is not valid. Please try again',
        );
      }
    }
  };

  const searchedSubs = subscribedFeeds.filter(
    (rss) =>
      rss?.title?.toLowerCase()?.includes(searchTerm?.trim().toLowerCase()) ||
      rss?.rss_url?.toLowerCase()?.includes(searchTerm?.trim().toLowerCase()),
  );
  const closeInput = () => {
    setRssURL('');
    toggleShowInput(false);
  };

  const handleDeleteRss = async (id?: string) => {
    if (!id) return;

    const modalOption = await confirmModalRef.current?.show({
      title: 'Confirm',
      message: (
        <>
          <p>Do you want to delete this subscription?</p>
        </>
      ),
      confirmText: 'Confirm',
    } as ConfirmModalProps);

    if (modalOption === ModalOptions.YES) {
      const deleteAsync = RssService.deleteRssSubscriptions(id);
      customToast.promise(deleteAsync, {
        loading: 'Deleting RSS Subscription',
        success: 'RSS Subscription deleted',
        error: 'Delete RSS Subscription failed',
      });

      try {
        await deleteAsync;
        dispatch(deleteSubscriptionItem(id));
      } catch (err: any) {
        console.log('err :>> ', err);
      }
    }
  };

  const rssStyles = {
    headFontColor: () => [
      tw`[> th]:(font-black! text-black! uppercase font-size[14px]! px-10 py-2)`,
    ],
  };

  return (
    <>
      <div>
        <div tw="flex items-center justify-end mb-5">
          <div tw="mr-5">
            <SearchInput
              placeholder="Search Subscriptions"
              setTerm={setSearchTerm}
            />
          </div>
          {!isShowInput ? (
            <span tw="cursor-pointer">
              <button
                className="button btn-primary large"
                onClick={handleAddSubscription}
                tw="(max-width[unset] width[auto] flex items-center px-4 mb-0 whitespace-nowrap)!"
              >
                <AddTermSvg tw="mr-3" /> Add Subscription
              </button>
            </span>
          ) : (
            <div
              className="button keywordBtn keywordEdit"
              tw="p-0! mb-0 mr-0! w-full"
            >
              <input
                type="text"
                value={rssURL}
                onChange={(e) => setRssURL(e.target.value)}
                onBlur={closeInput}
                onKeyDown={handleKeyDownInput}
                className="small-10 keywordInput"
                placeholder="Enter a RSS URL"
                autoFocus
                tw="rounded-r-none! w-full placeholder:(text-13)! padding-right[3.2rem]!"
              />

              <button
                onClick={closeInput}
                className="small-2 cancelBtn"
                tw="rounded-l-none"
              >
                <CloseSvg className="whiteIcon" />
              </button>
            </div>
          )}
        </div>
        <div className="cell grid-padding-x medium-12">
          <div className="userData_table" tw="mt-10">
            <table>
              <thead>
                <tr css={rssStyles.headFontColor}>
                  <th tw="width[2%] p-0! pl-2! text-center">
                    <span>#</span>
                  </th>
                  <th tw="width[28%]">NAME</th>
                  <th tw="width[25%]">URL</th>
                  <th tw="width[10%] min-width[17rem]!">LAST UPLOAD</th>
                  <th tw="width[10%]! min-width[17.5rem]! max-width[16rem]! text-center">
                    COLLECTION
                  </th>
                  <th tw="width[5%] text-center">SUBSCRIBED</th>
                  <th tw="width[5%]">DEL</th>
                </tr>
              </thead>
              <tbody>
                {searchedSubs.map((rss, index) => {
                  return (
                    <RssSubscriptionItem
                      item={rss}
                      order={index + 1}
                      key={index}
                      onDelete={handleDeleteRss}
                    />
                  );
                })}

                {searchedSubs?.length === 0 && !isLoading && (
                  <tr>
                    <td colSpan={5} tw="py-3! items-center [div]:(mb-0)">
                      <Info
                        text={
                          searchTerm
                            ? 'No results found'
                            : 'There no items in subscription list'
                        }
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {isLoading && isEmpty(subscribedFeeds) && (
              <div tw="w-full flex justify-center mt-8">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>

      <ConfirmModal ref={confirmModalRef} />
    </>
  );
};
