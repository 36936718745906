/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { ReactComponent as BinSvg } from 'assets/Icons/bin.svg';
import { ReactComponent as AddSvg } from 'assets/Icons/plus_icon.svg';
import {
  CustomSelectSearch,
  Option,
} from 'components/shared/CustomSelectSearch';
import { Hint } from 'components/shared/Hint';
import { QuestionHint } from 'components/shared/QuestionHint';
import { cloneDeep, findIndex, isArray, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GroupBase, StylesConfig } from 'react-select';
import {
  addIntegrationFilter,
  removeIntegrationFilter,
  updateSingleIntegration,
} from 'slices/omny.slice';
import { CollectionOptionEnum } from 'utils/enum';
import {
  IntegrationOptions,
  IntegrationSettingsPayload,
  ProgramIdOption,
} from 'utils/models';
import { customToast } from 'utils/toast.util';

type Props = {
  integration: IntegrationOptions;
  programIds: ProgramIdOption[];
  isLoading?: boolean;
};

export const IntegrationFilter = (props: Props) => {
  const { integration, programIds, isLoading = false } = props;

  const dispatch = useDispatch();

  const [listProgramIdsDropdown, setListProgramIdsDropdown] =
    useState<ProgramIdOption[]>();

  useEffect(() => {
    if (isEmpty(programIds) && isArray(programIds)) {
      resetProgramList();
      return;
    }

    setListProgramIdsDropdown(programIds);
    // handleSelectProgramId(programIds[0]);
  }, [programIds]);

  const handleSelectProgramId = (newValue: Option, index: number) => {
    // Avoid default empty event dispath from select
    if (isEmpty(newValue.value) && isEmpty(newValue.label)) return;

    const newIntegration = { ...integration };
    const newProgramFilter = [...newIntegration.filter.programFilters];

    const foundDuplicate = findIndex(
      newProgramFilter,
      (filter) => filter.programId === newValue.value,
    );
    if (foundDuplicate >= 0) {
      customToast.error('Program ID already exists');
      return;
    }

    const foundProgramFilter = { ...newProgramFilter[index] };

    foundProgramFilter.programId = newValue.value;
    foundProgramFilter.programName = newValue.label;

    newProgramFilter[index] = foundProgramFilter;

    dispatch(
      updateSingleIntegration({
        ...newIntegration,
        filter: {
          ...integration.filter,
          programFilters: newProgramFilter,
        },
      }),
    );
  };

  const resetProgramList = () => {
    setListProgramIdsDropdown([]);
    handleSelectProgramId(
      {
        value: '',
        label: '',
      },
      0,
    );
  };

  const handleChangeIntegration = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    const type = e.target.type;

    const newIntegration = { ...integration };

    if (type === 'checkbox') {
      const checked = e.target.checked;

      if (name === 'isUseProgramName') {
        newIntegration.collectionOption = checked
          ? CollectionOptionEnum.OMNY
          : CollectionOptionEnum.COLLECTION;

        if (checked === true) {
          newIntegration.collectionId = null;
        }
      }

      dispatch(
        updateSingleIntegration({
          ...newIntegration,
          filter: { ...newIntegration.filter, [name]: checked },
        }),
      );

      return;
    }

    dispatch(
      updateSingleIntegration({
        ...newIntegration,
        filter: { ...newIntegration.filter, [name]: [value] },
      }),
    );
  };

  const renderCustomOption = (option: ProgramIdOption) => (
    <div tw="flex flex-row">
      <div tw="max-width[5.5rem] mr-4 flex">
        <img alt="" src={option.imageURL} />
      </div>

      <div tw="flex flex-col w-full">
        <div
          tw="text-15 font-black text-left text-sonnant-dark break-words"
          className="line-clamp-2"
        >
          {option.label}
        </div>
        <div tw="text-12">{option.value}</div>
      </div>
    </div>
  );

  const handleAddNewFilter = () => {
    dispatch(addIntegrationFilter(integration));
  };

  const handleRemoveFilter = (index: number) => {
    dispatch(
      removeIntegrationFilter({
        integration,
        index,
      }),
    );
  };

  const handleChangeIntegrationData = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const name = e.target.name;
    const checked = e.target.checked;

    const newIntegration = cloneDeep(integration);

    newIntegration.filter.integrationSettings[
      name as keyof IntegrationSettingsPayload
    ] = checked;

    dispatch(updateSingleIntegration(newIntegration));
  };

  return (
    <div tw="w-1/2 mt-[1.5rem] flex flex-col pl-[3rem]">
      <div tw="flex flex-wrap gap-y-[1rem]">
        {integration.filter.programFilters?.map((filter, index) => {
          return (
            <div tw="flex space-x-8">
              <div key={index} tw="flex flex-col pt-0! flex-1 min-w-[27rem]">
                <div tw="flex flex-row items-center">
                  <label tw="flex flex-nowrap text-16 mr-3">Program ID</label>
                </div>

                <div tw="flex flex-row max-width[75rem]">
                  <div tw="flex-1 relative w-full">
                    <CustomSelectSearch
                      options={listProgramIdsDropdown}
                      defaultValue={filter.programId}
                      isLoading={isLoading}
                      customStyles={defaultStyles}
                      formatOptionLabel={renderCustomOption}
                      onChange={(newValue: Option) =>
                        handleSelectProgramId(newValue, index)
                      }
                      menuPlacement="bottom"
                      canCreate={false}
                    />
                  </div>
                </div>
              </div>
              <div tw="flex flex-col pt-0! flex-1 min-width[20rem]">
                <div tw="flex flex-row items-center">
                  <label tw="flex flex-nowrap text-16 mr-3">Program name</label>
                </div>

                <div tw="flex flex-row max-width[75rem]">
                  <div tw="flex-1 relative ">
                    <input
                      tw="flex"
                      type="text"
                      name="programName"
                      disabled
                      value={filter.programName}
                    />
                  </div>
                </div>
              </div>
              <div tw="flex flex-1 space-x-5">
                {index === integration.filter.programFilters.length - 1 && (
                  <Hint
                    text="Add new program filter"
                    enterDelay={100}
                    leaveDelay={0}
                  >
                    <button
                      onClick={handleAddNewFilter}
                      tw="flex justify-center self-end mb-[1rem] pt-0! items-center cursor-pointer rounded-lg h-[4rem] w-[4rem]"
                      className="btn btn-secondary"
                    >
                      <AddSvg tw="w-[3rem]" />
                    </button>
                  </Hint>
                )}
                <Hint
                  text="Remove program fitler"
                  enterDelay={100}
                  leaveDelay={0}
                >
                  <button
                    onClick={() => handleRemoveFilter(index)}
                    tw="flex justify-center self-end mb-[1rem] pt-0! items-center cursor-pointer h-[4rem] w-[4rem]"
                    className="btn btn-secondary"
                  >
                    <BinSvg fill="red" />
                  </button>
                </Hint>
              </div>
            </div>
          );
        })}
      </div>

      <div tw="flex-col mt-3">
        <div tw="flex flex-col pt-0!">
          <div tw="flex flex-row items-center">
            <label tw="flex flex-nowrap text-16 mr-3">Title contains</label>
            <QuestionHint
              notTransparent
              hideCloseButton
              type={'information'}
              align={'bottomLeft'}
              action={'click'}
              text={<div>Title is case insensitive</div>}
              customStyle={customTooltipCss}
            />
          </div>

          <div tw="flex flex-row max-width[75rem]">
            <div tw="flex-1 relative ">
              <input
                tw="flex"
                type="text"
                name="titleContains"
                value={integration.filter.titleContains}
                onChange={handleChangeIntegration}
              />
            </div>
          </div>
        </div>
      </div>

      <div tw="flex justify-between gap-[3rem]">
        <label tw="max-w-full w-1/2 flex[unset]! cursor-pointer">
          <input
            name="isUseProgramName"
            checked={integration.filter.isUseProgramName}
            onChange={handleChangeIntegration}
            type="checkbox"
          />
          <span className="checkmark" style={{ top: '4px' }}></span>
          Use Omny program name
        </label>

        <label tw="max-w-full w-1/2 flex[unset]! cursor-pointer">
          <input
            name="isAutomatePushBack"
            checked={integration.filter.isAutomatePushBack}
            onChange={handleChangeIntegration}
            type="checkbox"
          />
          <span className="checkmark" style={{ top: '4px' }}></span>
          Automate integration back to Omny
        </label>
      </div>
      <div tw="my-5">
        <label tw="flex flex-nowrap text-16 mr-3">Integration data</label>
        <div tw="flex justify-between mt-3">
          <div tw="w-[25rem]">
            <label tw="max-w-full flex w-1/2 flex[unset]! cursor-pointer">
              <input
                name="hasMonetisation"
                checked={integration.filter.integrationSettings.hasMonetisation}
                onChange={handleChangeIntegrationData}
                type="checkbox"
              />
              <span className="checkmark" style={{ top: '4px' }}></span>
              Monetisation
            </label>

            <label tw="max-w-full flex w-1/2 flex[unset]! cursor-pointer">
              <input
                name="hasTags"
                checked={integration.filter.integrationSettings.hasTags}
                onChange={handleChangeIntegrationData}
                type="checkbox"
              />
              <span className="checkmark" style={{ top: '4px' }}></span>
              Tags
            </label>
          </div>

          <div tw="flex-auto">
            <label tw="max-w-full flex w-1/2 flex[unset]! cursor-pointer">
              <input
                name="hasChapters"
                checked={integration.filter.integrationSettings.hasChapters}
                onChange={handleChangeIntegrationData}
                type="checkbox"
              />
              <span className="checkmark" style={{ top: '4px' }}></span>
              Chapters
            </label>

            <label tw="max-w-full flex w-1/2 flex[unset]! cursor-pointer">
              <input
                name="hasSummary"
                checked={integration.filter.integrationSettings.hasSummary}
                onChange={handleChangeIntegrationData}
                type="checkbox"
              />
              <span className="checkmark" style={{ top: '4px' }}></span>
              Description/Summary
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export const defaultStyles: StylesConfig<any, false, GroupBase<any>> = {
  container: (base) => ({
    ...base,
    height: '4rem',
    width: '100%',
  }),
  control: (base) => ({
    ...base,
    height: '4rem',
    width: '100%',
    padding: 0,
    backgroundColor: '#f0f3f6',
    borderColor: 'transparent',
  }),
  menu: (base) => ({
    ...base,
    marginBottom: '4px',
    marginTop: '4px',
  }),
  menuList: (base) => ({
    ...base,
    fontSize: '1.5rem',
    overflowX: 'hidden',
  }),
  input: (base) => ({
    ...base,
    height: '3rem',
    paddingTop: '3px',
    position: 'relative',
    top: '-8px',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    paddingLeft: '0.8rem',
    fontSize: '1.53rem',
  }),
  placeholder: (base) => ({
    ...base,
    color: 'rgb(51, 51, 51)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
  option: (base) => ({
    ...base,
    cursor: 'pointer',
    color: 'rgb(51, 51, 51)',
  }),
};

export const customTooltipCss = css`
  ${tw`font-size[1rem]`}

  .notification {
    ${tw`lg-up:width[40rem]! md-down:(width[20rem] break-words)`}
  }
  .notificationWrapper {
    ${tw`margin-top[1rem]!`}
  }
`;
