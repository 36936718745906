import 'assets/styles/plyr.css';
import './App.scss';

import { IS_USE_SONNANT_V2 } from 'components/config';
import { DisplayVersions } from 'components/DisplayVersions/DisplayVersion';
import { ExpiredPage } from 'components/ExpiredPage/ExpiredPage';
import { MaintenancePage } from 'components/MaintenancePage/MaintenancePage';
import { Preferences } from 'components/Preferences/Settings/Preferences';
import { PrivateRoute } from 'components/private/PrivateRoute';
import { Reporting } from 'components/Reporting/Reporting';
import { AuthForm } from 'components/shared/AuthForm/AuthForm';
import { SharePostWithInstagram } from 'components/SharePostLinkedIn/SharePostInstagram';
import { SharePostTiktok } from 'components/SharePostLinkedIn/SharePostTiktok';
import { SharePostYoutube } from 'components/SharePostLinkedIn/SharePostYoutube';
import {
  isDevMode,
  isMaintenanceMode,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { Transcription } from 'components/VideoPlayer/Transcription/Transcription';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AccountNavbar } from './components/AccountSetting/AccountNavbar/AccountNavBar';
import { Explore } from './components/Explore/Explore';
import { Library } from './components/Library/Library';
import { Login } from './components/Login/Login';
import { NavbarMenu } from './components/Navbar/NavbarMenu';
import { ChangePassword } from './components/passwrodChange/ChangePassword';
import { PublicRoute } from './components/public/publicRoute';
import { PublicShare } from './components/public/publicShare/publicShare';
import { ResetPassword } from './components/ResetPassword/ResetPassword';
import { SharePostWithFacebook } from './components/SharePostLinkedIn/SharePostFacebook';
import { SharePostWithLinkedIn } from './components/SharePostLinkedIn/SharePostLinkedIn';
import { SharePostWithTwitter } from './components/SharePostLinkedIn/SharePostTwitter';
import { SignUp } from './components/SignUp/SignUp';
import { Message } from './components/UiControls/successUnsucessPage';
import { PageNotFound } from './utils/PageNotFound';
import {
  generateUUID,
  hasSubdomain,
  hiddenNavbar,
} from './utils/utils';
import { IframeRunner } from 'components/public/IframeRunner/IframeRunner';
import { PublicEmbedV2 } from 'components/public/PublicEmbed/PublicEmbedV2';
import { PublicEmbed } from 'components/public/PublicEmbed/PublicEmbed';
import { AdminPortal } from 'components/AdminPortal/AdminPortal';

export const userEditingId = generateUUID();

export function App() {
  useEffect(() => {
    localStorage.setItem(
      'r_version',
      `Sonnant_18062021_env_${process.env.NODE_ENV}`,
    );
  }, []);

  return (
    <div className="App">
      {!hiddenNavbar() && <NavbarMenu />}

      <Toaster position="top-center" reverseOrder={false} />
      <Switch>
        {isMaintenanceMode() && (
          <>
            <Redirect from="*" to="/maintenance" />
            <PublicRoute path="*" component={MaintenancePage} />
          </>
        )}

        <PublicRoute path="/download-expired" component={ExpiredPage} />

        <PublicRoute restricted={false} path="/" exact component={Explore} />

        <PublicRoute
          path="/signin"
          exact
          component={IS_USE_SONNANT_V2 ? AuthForm : Login}
        />

        {!hasSubdomain() && (
          <PublicRoute
            path="/signup"
            exact
            component={IS_USE_SONNANT_V2 ? AuthForm : SignUp}
          />
        )}

        <PublicRoute path="/message" exact component={Message} />

        <PublicRoute path="/confirmpassword" exact component={ResetPassword} />

        {!hasSubdomain() && (
          <PublicRoute path="/linkedin" component={SharePostWithLinkedIn} />
        )}
        {!hasSubdomain() && (
          <PublicRoute path="/twitter" component={SharePostWithTwitter} />
        )}
        {!hasSubdomain() && (
          <PublicRoute path="/facebook" component={SharePostWithFacebook} />
        )}
        {!hasSubdomain() && (
          <PublicRoute path="/youtube" component={SharePostYoutube} />
        )}
        {!hasSubdomain() && (
          <PublicRoute path="/tiktok" component={SharePostTiktok} />
        )}
        {!hasSubdomain() && (
          <PublicRoute path="/instagram" component={SharePostWithInstagram} />
        )}
        {!hasSubdomain() && (
          <PublicRoute path="/shared/:id" component={PublicShare} />
        )}

        {/* OFFICIAL EMBED VERSION */}
        {!hasSubdomain() && (
          <PublicRoute exact path="/embed/:id" component={PublicEmbedV2} />
        )}

        {/* LEGACY EMBED VERSION */}
        {!hasSubdomain() && isDevMode() && (
          <PublicRoute exact path="/embed/v1/:id" component={PublicEmbed} />
        )}

        {isDevMode() && (
          <PublicRoute path="/test-embed" component={IframeRunner} />
        )}

        <PrivateRoute path="/library" exact component={Library} />

        <PrivateRoute path="/collections/:id" exact component={Library} />

        <PrivateRoute path="/revive" exact component={Library} />

        <PrivateRoute path="/insights" exact component={Library} />

        <PrivateRoute
          path="/clips/:mediaid"
          exact
          component={DisplayVersions}
        />
        <PrivateRoute
          path="/transcription/:id"
          exact
          component={Transcription}
        />
        <PrivateRoute
          path="/account-settings"
          exact
          component={AccountNavbar}
        />

        <PrivateRoute path="/admin-portal" exact component={AdminPortal} />

        {!hasSubdomain() && (
          <PrivateRoute
            path="/changepassword"
            exact
            component={ChangePassword}
          />
        )}

        <PrivateRoute path="/preferences" exact component={Preferences} />

        <PrivateRoute path="/reporting" exact component={Reporting} />

        <Route path="/404" component={PageNotFound} />
        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </div>
  );
}
