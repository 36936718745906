import * as actionType from '../actions/types';
import * as actions from '../actions';
import { put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { AuthService } from 'services';

export const UpdatePasswordsaga = function* (action: any) {
  try {
    const response: AxiosResponse = yield AuthService.changePassword({
      ...action.passwordsData,
    });
    yield put({
      type: actionType.UPDATE_PASSWORD_SUCCESS,
      data: response.data,
    });
    yield put(actions.handleStatusAlert(response.data, true));
  } catch (error: any) {
    yield put({ type: actionType.UPDATE_PASSWORD_FAILED, payload: error });
    yield put(actions.handleStatusAlert(error?.data, false));
  }
};

export default function* WatchUpdatePasswordsaga() {
  yield takeLatest(actionType.INITIATE_PASSWORD_UPDATE, UpdatePasswordsaga);
}
