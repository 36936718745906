/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './displayVersion.scss';
import { ILibraryItem, HistoryItem } from 'utils/models';
import $ from 'jquery';
import { ReactComponent as ListViewIcon } from '../../assets/Icons/ListView.svg';
import { ReactComponent as ThumbnailViewIcon } from '../../assets/Icons/ThumbnailView.svg';
import { ReactComponent as ListViewActive } from '../../assets/Icons/List View.svg';
import { ReactComponent as ThumbnailViewActive } from '../../assets/Icons/Thumbnail View.svg';
import { ReactComponent as SortButton } from '../../assets/Icons/sortDsc.svg';
import { ReactComponent as SortAscButton } from '../../assets/Icons/sortAsc.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  clearSelectedClips,
  setCurrentView,
  setFocusClip,
  setSelectedClips,
  toggleSelectMode,
} from 'slices/clips.slice';
import { ViewMode, ClipStatusCode } from 'utils/enum';
import { isEmpty, isEqual, toNumber } from 'lodash';
import {
  canRedirect,
  isCombinedClips,
  msToTime,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { filenameFormatter, isEmptyOrSpaces } from 'utils/utils';
import {
  menuDivider,
  simpleMenuDisabled,
  simpleMenuItem,
} from 'components/shared/twin.styles';
import { ReactComponent as Share } from 'assets/Icons/Share.svg';
import { ReactComponent as Preview } from 'assets/Icons/preview_black.svg';
import { ReactComponent as Delete } from 'assets/Icons/delete.svg';
import { ReactComponent as Export } from 'assets/Icons/Export.svg';
import { ReactComponent as Movie } from 'assets/Icons/movie.svg';
import { ReactComponent as TextSnippet } from 'assets/Icons/textSnippet.svg';
import { ReactComponent as Subtitles } from 'assets/Icons/subtitles.svg';
import { ReactComponent as EditNote } from 'assets/Icons/editNote.svg';
import { Hint } from 'components/shared/Hint';
import { HintRestrictedPublish } from 'components/shared/HintRestrictedPublish';
import { HintRestrictedEdit } from 'components/shared/HintRestrictedEdit';

interface IProps {
  data: ILibraryItem;
  isSortAsc: boolean;
  toggleSort: () => unknown;
  openViewShare: () => unknown;
  deleteVersionMedias: any;
  dropdownNameChanged?: any;
  handleExportContentItem?: () => void;
  handlePublishContentItem?: () => void;
  handleShowPreviewPublish?: (startTime: number, endTime: number) => unknown;
}

export const OptionBar = ({
  data: libraryItem,
  isSortAsc,
  toggleSort,
  openViewShare,
  deleteVersionMedias,
  dropdownNameChanged,
  handleExportContentItem,
  handlePublishContentItem,
  handleShowPreviewPublish,
}: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const noTranscript = libraryItem?.noTranscript;

  const [dropdownName, setDropdownName] = useState('modified');

  const clips = useSelector((state: RootState) => state.clips);
  const versions = useSelector((state: RootState) => state.getPublishedVersion);

  const isListView = isEqual(clips.currentView, ViewMode.LIST_VIEW);
  const isSelected = !isEmpty(clips.selectedClips);
  const isMultiSelect = clips.selectedClips.length > 1;

  const allClips = [
    ...(versions?.data?.versions ?? []),
    ...(versions?.data?.suggestedVersions ?? []),
    ...(versions?.data?.trendingVersions ?? []),
  ];
  const isSelectedAll =
    allClips?.length === clips?.selectedClips?.length && allClips?.length > 0;

  useEffect(() => {
    $(document).foundation();
  }, []);

  const handleDropdownClick = (e: any) => {
    setDropdownName(e.currentTarget.dataset.id);
    dropdownNameChanged(e.currentTarget.dataset.id);
  };

  const toggleSelect = () => {
    dispatch(clearSelectedClips());
    dispatch(toggleSelectMode(null));
    dispatch(setFocusClip(null));
  };

  const hasOriginal = (): boolean => {
    return clips.selectedClips.some((item) => isEqual(item.versioncount, '0'));
  };

  const hasCombined = (): boolean => {
    return clips.selectedClips.some((item) => isCombinedClips(item));
  };

  const historyItem = {
    mediaid: libraryItem?.mediaid,
    filename: isEmptyOrSpaces(libraryItem?.title)
      ? filenameFormatter(libraryItem?.filename)
      : libraryItem?.title,
    videoLength: msToTime(toNumber(libraryItem?.length)),
  };

  const handleRedirectClick = (
    item: ILibraryItem,
    historyItem: HistoryItem,
  ) => {
    if (!canRedirect(item)) return;

    history.push(`/transcription/${item?.mediaid}`, historyItem);
  };

  const handleViewTranscript = (item: ILibraryItem) => {
    const newHistoryItem = { ...historyItem, paragraphMode: true };
    handleRedirectClick(item, newHistoryItem);
  };

  const handleEditCaption = (item: ILibraryItem) => {
    const newHistoryItem = { ...historyItem, editMode: true };
    handleRedirectClick(item, newHistoryItem);
  };

  const shouldDisableExport = () => {
    return clips.selectedClips.some(({ statuscode }) =>
      [
        ClipStatusCode.CREATING,
        ClipStatusCode.CREATION_FAILED,
        ClipStatusCode.DELETED,
      ].includes(statuscode),
    );
  };

  const hasFailedItems = clips.selectedClips.some((clip) =>
    [ClipStatusCode.CREATION_FAILED].includes(clip?.statuscode),
  );

  const isOriginalClip = isEqual(clips?.selectedClips[0]?.versioncount, '0');

  const handleSelectAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (isSelectedAll) {
      dispatch(setSelectedClips([]));
    } else {
      dispatch(setSelectedClips(allClips));
    }
  };

  return (
    <div
      className="grid-x row_bg"
      tw="flex items-center justify-between padding-right[2.5rem]"
    >
      <div className="menu sor_group" tw="flex items-center">
        <button
          type="button"
          className={'button ' + (isListView ? 'active' : 'list ')}
          tw="border[1px solid red]"
          onClick={() => dispatch(setCurrentView(ViewMode.LIST_VIEW))}
        >
          {isListView ? <ListViewActive /> : <ListViewIcon />}
        </button>
        <button
          type="button"
          className={'button ' + (isListView ? 'thumb' : 'active')}
          tw="border[1px solid red]"
          onClick={() => dispatch(setCurrentView(ViewMode.THUMBNAIL))}
        >
          {isListView ? <ThumbnailViewActive /> : <ThumbnailViewIcon />}
        </button>
        {!isListView && (
          <div className="button-group sort_modify" tw="flex items-center">
            <div className="dropdown ">
              <a className="button">
                Sort by {dropdownName}
                <span className="arrow-down"></span>
              </a>
              <ul className="dropdown-sort-content manageDropDown">
                <li onClick={handleDropdownClick} data-id="modified">
                  <a>Sort by modified</a>
                </li>
              </ul>
            </div>
            <div className="sort-icon" onClick={toggleSort}>
              {isSortAsc ? <SortAscButton /> : <SortButton />}
            </div>
          </div>
        )}
      </div>

      <div className="menu align-right">
        {
          <Hint
            disabled={isSelectedAll}
            text={
              isSelectedAll
                ? 'Deselect all selected items.'
                : 'Select all current items.'
            }
          >
            <button
              tw="(mx-0)!"
              className={'button select'}
              onClick={handleSelectAll}
            >
              {isSelectedAll ? 'Deselect all' : 'Select all'}
            </button>
          </Hint>
        }

        <div
          className={
            'dropdown button-group reset_block ' + (!isSelected ? '' : 'active')
          }
        >
          <button
            data-toggle="display-version"
            className={`dropbtn button ct_width arrow-only manage_library`}
            tw="mx-1.5!"
            css={[isSelected ? tw`block!` : tw`hidden!`]}
            disabled={
              clips.selectedClips.length > 1 &&
              clips.selectedClips.some((clip) => clip.versioncount === '0')
            }
          >
            Manage Versions ({clips.selectedClips.length}){' '}
            <span className="arrow-down"></span>
          </button>
          <ul
            className="manageDropDown dropdown-pane"
            data-position="bottom"
            data-alignment="right"
            data-close-on-click="true"
            id="display-version"
            data-dropdown
            tw="py-1"
            css={[customUlCss]}
          >
            {isSelected && !isMultiSelect && !hasFailedItems && (
              <HintRestrictedPublish>
                <li onClick={openViewShare}>
                  <div tw={'flex space-x-2 items-center'}>
                    <span tw="flex width[3.5rem]">
                      <Share css={iconStyle} />
                    </span>
                    <a tw="w-full">Share</a>
                  </div>
                </li>
              </HintRestrictedPublish>
            )}

            {(isSelected || isMultiSelect) &&
              !isOriginalClip &&
              !hasFailedItems && (
                <>
                  <li
                    onClick={handleExportContentItem}
                    className={noTranscript ? 'disabled' : ''}
                  >
                    <div tw={'flex space-x-2 items-center'}>
                      <span
                        tw="flex width[3.5rem]"
                        css={[shouldDisableExport() && simpleMenuDisabled]}
                      >
                        <Export css={iconStyle} />
                      </span>
                      <a tw="w-full" css={[simpleMenuItem]}>
                        Export
                      </a>
                    </div>
                  </li>
                </>
              )}

            {isSelected && !isMultiSelect && !hasFailedItems && (
              <>
                <hr css={[menuDivider]} />

                <Hint
                  disabled={!hasCombined()}
                  text="Combined clip cannot be edited"
                  enterDelay={300}
                  leaveDelay={0}
                >
                  <div>
                    <li
                      onClick={handlePublishContentItem}
                      className={
                        noTranscript || hasCombined() ? 'disabled' : ''
                      }
                    >
                      <div
                        tw={'flex space-x-3 items-center'}
                        css={[
                          isOriginalClip && tw`space-x-0!`,
                          noTranscript && simpleMenuDisabled,
                        ]}
                      >
                        <span tw="flex width[3.5rem]">
                          <Movie css={iconStyle} />
                        </span>
                        <a tw="w-full ml-2!" onClick={handlePublishContentItem}>
                          {!isOriginalClip ? (
                            'Edit'
                          ) : (
                            <span tw="ml-1.5">Create clip</span>
                          )}
                        </a>
                      </div>
                    </li>
                  </div>
                </Hint>
                <li
                  onClick={() =>
                    handleShowPreviewPublish?.(
                      clips.selectedClips[0]?.starttime!,
                      clips.selectedClips[0]?.endtime!,
                    )
                  }
                >
                  <div tw={'flex space-x-2 items-center'}>
                    <span tw="flex width[3.5rem]">
                      <Preview css={iconStyle} />
                    </span>
                    <a tw="w-full ml-1.5!">View</a>
                  </div>
                </li>
              </>
            )}
            {/* <li
              className={
                !isItemSelect || isMultiSelect || statusVersion == 'Original'
                  ? 'disabled'
                  : ''
              }
            >
              <a>Edit published settings</a>
            </li> */}
            {/* <li
              className={
                isMultiSelect || statusVersion != 'Original' ? 'disabled' : ''
              }
            >
              <a>Publish version</a>
            </li> */}
            {/* <li className={!isSelected || isMultiSelect ? 'disabled' : ''}>
              <div tw={'flex space-x-2 items-center'}>
                <Share css={iconStyle} />
                <a tw="w-full" onClick={openModalToShare}>Share version</a>
              </div>
            </li> */}
            {/* <li className={'disabled'}>
              <a>Revoke Share</a>
            </li> */}
            {/* <li className={'disabled'}>
              <a>Revoke embeded</a>
            </li> */}
            {isOriginalClip && isSelected && !isMultiSelect && (
              <>
                <hr css={[menuDivider]} />
                <li
                  onClick={() => handleRedirectClick(libraryItem, historyItem)}
                >
                  <div tw={'flex space-x-0 items-center'}>
                    <TextSnippet css={iconStyle} />
                    <a tw="w-full">View transcript</a>
                  </div>
                </li>
                <HintRestrictedEdit>
                  <li onClick={() => handleEditCaption(libraryItem)}>
                    <div
                      tw={'flex space-x-0 items-center'}
                      className={noTranscript ? 'disabled' : ''}
                    >
                      <Subtitles css={iconStyle} />
                      <a tw="w-full">Edit (captions)</a>
                    </div>
                  </li>
                </HintRestrictedEdit>
                <HintRestrictedEdit>
                  <li
                    onClick={() => handleViewTranscript(libraryItem)}
                    className={noTranscript ? 'disabled' : ''}
                  >
                    <div tw={'flex space-x-0 items-center'}>
                      <EditNote css={iconStyle} />
                      <a tw="w-full">Edit (transcript)</a>
                    </div>
                  </li>
                </HintRestrictedEdit>
              </>
            )}
            <hr
              css={[
                menuDivider,
                !isSelected || hasOriginal() ? tw`hidden!` : tw`block!`,
              ]}
            />
            <li
              css={[!isSelected || hasOriginal() ? tw`hidden!` : tw`block!`]}
              onClick={() => deleteVersionMedias()}
            >
              <div tw={'flex space-x-1 items-center'}>
                <span tw="flex width[3.5rem]">
                  <Delete css={iconStyle} tw="width[3.5rem]" />
                </span>
                <a tw="w-full">Delete</a>
              </div>
            </li>
          </ul>
        </div>

        {clips.isSelectMode && (
          <button
            className={'button ' + (!clips.isSelectMode ? 'select' : 'cancel')}
            tw="(mx-0)!"
            onClick={toggleSelect}
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  );
};

const iconStyle = css`
  fill: #7f8090 !important;
  width: 4.5rem;
  padding-left: 1rem;
`;

const customUlCss = css`
  li {
    ${tw`(active:(bg-sonnant-grey-light [a]:(text-black)))!`}
  }
`;
