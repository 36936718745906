/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { Export } from 'components/Export/Export';
import { RevivalTab } from 'components/RevivalTab/RevivalTab';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { IntegrationModal } from 'components/shared/IntegrationModal/IntegrationModal';
import { PreviewClipModal } from 'components/shared/PreviewClipModal/PreviewClipModal';
import { isEmpty, isEqual, isNil, toNumber } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useEffectOnce, useSearchParam, useUnmount } from 'react-use';
import { RootState } from 'reducers';
import { LibraryService, MediaService } from 'services';
import {
  ClipStatusCode,
  HTTPStatus,
  MediaProvider,
  ModalOptions,
  SearchField,
  VideoStatusCode,
} from 'utils/enum';
import { customToast } from 'utils/toast.util';
import * as actions from '../../actions';
import * as types from '../../actions/types';
import {
  getFileName,
  isNavigatedFromTranscription,
  isRefreshed,
  replaceUrlNoReload,
  setLocalStorageFilter,
  waitAsync,
} from '../../utils/utils';
import { Loader } from '../loader/loader';
import { Publish } from '../Publish/Publish';
import { Share } from '../Share/Share';
import { Modal } from '../UiControls/modal/modal';
import './library.scss';
import { ListView } from './Listview';
import { Sort } from './Sort';
import { ThumbNails } from './ThumbNails';
import { CollapsibleList } from '@rmwc/list';
import { FilterBar } from './Filters/FilterBar';
import { CollectionDnDWrapper } from './CollectionDnDWrapper/CollectionDnDWrapper';
import {
  getCollectionIdLocal,
  isCollectionPage,
  isInsightsPage,
  isLibraryPage,
  isRevivalPage,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import {
  resetSelectedCollectionId,
  setSelectedCollectionId,
  setShowCollectionSidebar,
} from 'slices/collection.slice';
import { setLibrary } from '../../actions';
import { Routes } from 'utils/routes';
import { InsightsTab } from 'components/InsightsTab/InsightsTab';
import {
  toggleReloadLibrary,
  toggleShowDeleteModal,
  toggleShowExportModal,
  toggleShowIntegrateModal,
  toggleShowMentionReportModal,
  toggleShowPreviewModal,
  toggleShowPublishModal,
  toggleShowRerunModal,
  toggleShowShareModal,
} from 'slices/toggle.slice';
import { LibraryPagination } from './LibraryPagination';
import { ClipSearch } from './ClipSearch';
import { NoSearchResults } from 'components/shared/NoSearchResults';
import { setFocusClip, setSelectedClips } from 'slices/clips.slice';
import { EmptyLibraryBanner } from 'components/shared/EmptyLibraryBanner';
import { ILibraryItem, Term } from 'utils/models';
import { MultiMentionReportModal } from 'components/shared/MultiMentionReportModal/MultiMentionReportModal';
import { setFavorite } from 'slices/global.slice';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { ensureArray } from 'utils/generic.util';

export const Library = () => {
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  const searchParam = useSearchParam('search');
  const layerParam = useSearchParam('layer');

  const hasTermAutoFill = !isEmpty(searchParam) && !isEmpty(layerParam);

  const previewModalRef = useRef<any>();
  const confirmDeleteModalRef = useRef<any>(null);
  const closeDeleteModalRef = useRef<any>(null);
  const integrationModalRef = useRef<any>(null);

  const library = useSelector((state: RootState) => state.library);
  const collection = useSelector((state: RootState) => state.collection);
  const pagination = useSelector((state: RootState) => state.pagination);
  const isReloadLibrary = useSelector(
    (state: RootState) => state.toggle.isReloadLibrary,
  );
  const selectedRows = useSelector(
    (state: RootState) => state.library.selectedRows,
  );
  const clips = useSelector((state: RootState) => state.clips);
  const advancedSearch = useSelector(
    (state: RootState) => state.advancedSearch,
  );
  const toggle = useSelector((state: RootState) => state.toggle);

  const [isDeletingItem, setIsDeletingItem] = useState(false);
  const [openPublishModal, setopenPublishModal] = useState(false);
  const [openShareModal, setopenShareModal] = useState(false);
  const [openExportModal, setopenExportModal] = useState(false);

  const { page_number, page_size, sortBy, isAsc } = pagination;
  const { isShowClipsOnly } = advancedSearch;
  const { searchTerm, focusRow } = library;

  const defaultCollectionIdLocal = getCollectionIdLocal();

  const location = useLocation();
  const history = useHistory();

  const hasLibraryItems = !isEmpty(library?.data?.library);

  const multiMentionReportModalRef = useRef<any>(null);
  const confirmModalRef = useRef<any>(null);

  const searchParams = advancedSearch.searchParams;

  const mediaidList = isEmpty(library?.selectedRows)
    ? [library?.focusRow?.mediaid]
    : library?.selectedRows?.map(({ mediaid }: ILibraryItem) => mediaid);

  const isAdvancedSearch =
    searchTerm ||
    advancedSearch.isShowAdvancedFilter ||
    searchParams.uploadDate ||
    searchParams.customDate ||
    searchParams.type ||
    searchParams.duration ||
    searchParams.searchBy ||
    !isEmpty(searchParams.speakerNames) ||
    !isEmpty(searchParams.searchValues);

  const integrationProviders = library?.focusRow?.provider
    ? [library?.focusRow?.provider]
    : [MediaProvider.OMNY, MediaProvider.MEGAPHONE];

  // LISTENERS for all changes on Pagination and Sorting
  useEffect(() => {
    dispatch(actions.setLibraryLoading(true));

    if (isNil(defaultCollectionIdLocal)) return;

    fetchLibraryAsync();
  }, [
    page_number,
    page_size,
    sortBy,
    isAsc,
    isShowClipsOnly,
    searchTerm,
    isReloadLibrary,
    defaultCollectionIdLocal,
  ]);

  useEffectOnce(() => {
    fetchFavoriteActions();
  });

  const fetchFavoriteActions = async () => {
    try {
      const { data } = await LibraryService.getFavoriteAction();
      dispatch(setFavorite(data));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLibraryAsync = async () => {
    try {
      let collectionId = collection.selectedId;

      if (isCollectionPage() && params?.id) {
        collectionId = params?.id;
      }

      // click pie insight
      const fromRoute = (history.location.state as any)?.from;

      if (fromRoute === Routes.INSIGHTS) {
        collectionId = null;
      }

      const isBackFromTranscriptPage =
        isLibraryPage() && !isRefreshed() && isNavigatedFromTranscription();

      const isClickedChartInsightsPage = fromRoute === Routes.INSIGHTS;

      const isRefreshedFromMyCollection = isLibraryPage() && isRefreshed();

      if (
        isBackFromTranscriptPage ||
        isRefreshedFromMyCollection ||
        isClickedChartInsightsPage ||
        isInsightsPage() ||
        hasTermAutoFill
      ) {
        console.log('[INFO] Ignored default collection load');
      } else if (!isEmpty(defaultCollectionIdLocal) && isNil(collectionId)) {
        collectionId = defaultCollectionIdLocal;
        dispatch(setSelectedCollectionId(defaultCollectionIdLocal ?? ''));

        if (!isRevivalPage()) {
          // Update URL without triggering side-effect
          replaceUrlNoReload(`/collections/${defaultCollectionIdLocal}`);
        }
      }

      const newParams = {
        ...advancedSearch.searchParams,
        text: searchTerm,
        collection_id: collectionId ?? undefined,
        isShowClipsOnly: advancedSearch.isShowClipsOnly,
        page_number: page_number,
        page_size: page_size,
        order: pagination.isAsc ? 'asc' : 'desc',
        sortBy: pagination.sortBy,
        searchField:
          advancedSearch.searchParams?.searchField || SearchField.KEYWORD,
        searchValues: advancedSearch.searchParams.searchValues,
      };

      const { data: result } = await LibraryService.postAdvancedSearch(
        newParams,
      );

      dispatch(setLibrary(result));
    } catch (error) {
      console.log('[Error]', error);

      const status = (error as AxiosError).response?.status;

      if (status === HTTPStatus.RESTRICTED) {
        dispatch(resetSelectedCollectionId());

        history.push(Routes.NOT_FOUND);
      }
    } finally {
      dispatch(actions.setLibraryLoading(false));
    }
  };

  useEffect(() => {
    if (isEmpty(pagination)) return;

    setLocalStorageFilter({
      isListView: pagination.isListView,
      page_number,
      page_size,
      sortBy,
      isAsc,
      isShowClipsOnly: !!advancedSearch.isShowClipsOnly,
    });
  }, [pagination, advancedSearch.isShowClipsOnly]);

  useEffect(() => {
    if (
      (isLibraryPage() || isCollectionPage()) &&
      !advancedSearch.isShowClipsOnly
    ) {
      dispatch(setShowCollectionSidebar(true));
      if (params?.id) {
        dispatch(setSelectedCollectionId(params.id));
      }
    } else {
      dispatch(setShowCollectionSidebar(false));
    }
  }, [isLibraryPage(), isCollectionPage(), advancedSearch.isShowClipsOnly]);

  useUnmount(() => {
    dispatch(actions.setSelectedRows([]));
  });

  const handleDeleteVersions = async () => {
    const clipsToDelete = clips.selectedClips;

    const deleteItems = clipsToDelete.map((clip) => ({
      mediaid: clip.media_id ?? '',
      versioncount: clip.versioncount,
    }));
    const deleteVersionRequest = MediaService.deleteVersion(deleteItems);

    customToast.promise(deleteVersionRequest, {
      loading: 'Deleting clip(s)',
      success: 'Clip(s) deleted successfully',
      error: 'Clip(s) delete failed',
    });
    try {
      await deleteVersionRequest;
      dispatch(setSelectedClips([]));
      dispatch(setFocusClip(null));
      dispatch(toggleReloadLibrary());
    } catch (error: any) {
      console.log('HTTP Error :>> ', error);
    }
  };

  useEffect(() => {
    if (toggle.isShowDeleteModal) {
      handleDeleteItems();
      dispatch(toggleShowDeleteModal(false));
    }
    if (toggle.isShowPublishModal) {
      setopenPublishModal(true);
      dispatch(toggleShowPublishModal(false));
    }
    if (toggle.isShowShareModal) {
      setopenShareModal(true);
      dispatch(toggleShowShareModal(false));
    }
    if (toggle.isShowExportModal) {
      setopenExportModal(true);
      dispatch(toggleShowExportModal(false));
    }
    if (toggle.isShowIntegrateModal) {
      handleIntegrate();
      dispatch(toggleShowIntegrateModal(false));
    }
    if (toggle.isShowPreviewModal) {
      handleShowPreviewPublish();
      dispatch(toggleShowPreviewModal(false));
    }
    if (toggle.isShowMentionReportModal) {
      handleMentionReport();
      dispatch(toggleShowMentionReportModal(false));
    }
    if (toggle.isShowRerunModal) {
      handleRerun();
      dispatch(toggleShowRerunModal(false));
    }
  }, [
    toggle.isShowDeleteModal,
    toggle.isShowPublishModal,
    toggle.isShowShareModal,
    toggle.isShowExportModal,
    toggle.isShowIntegrateModal,
    toggle.isShowPreviewModal,
    toggle.isShowMentionReportModal,
    toggle.isShowRerunModal,
  ]);

  const handleMentionReport = async () => {
    const result: {
      option: ModalOptions;
      terms: Term[];
      reportName: string;
      isCombined?: boolean;
    } = await multiMentionReportModalRef.current?.show({
      librarySearchTerm: library?.searchTerm,
      defaultTitle:
        library?.selectedRows?.[0]?.title ??
        library?.focusRow?.title + ' - Mentions Report',
    });

    if (result?.option === ModalOptions.YES) {
      const selectedIds = selectedRows[0]?.mediaid ?? focusRow?.mediaid;

      const getMultiMentionsAsync = MediaService.getMentionReport({
        mediaIds: [selectedIds],
        terms: result?.terms ?? [],
        reportName: result?.reportName ?? '',
        isCombined: result?.isCombined,
      });

      customToast.promise(waitAsync(2000), {
        loading: 'Running in progress',
        success: (
          <div>
            <div>Submitted for background processing.</div>
            <div>It may take a few minutes to be ready.</div>
          </div>
        ),
        error: 'Generating Mention Report failed. Please check and try again.',
      });

      await getMultiMentionsAsync;
    }
  };

  const handleDeleteItems = async () => {
    const result = await confirmDeleteModalRef.current?.show();
    if (result === ModalOptions.YES) {
      if (advancedSearch.isShowClipsOnly) {
        await handleDeleteVersions();
        return;
      } else {
        const selectedIds = selectedRows.map((i: ILibraryItem) => i.mediaid);
        const deleteMediaIds = !isEmpty(selectedIds)
          ? selectedIds
          : [focusRow?.mediaid];
        //Can DELETE MULTIPLE
        if (deleteMediaIds.length > 0) {
          try {
            setIsDeletingItem(true);
            await MediaService.deleteMediaItem(deleteMediaIds);
            const newData = library.data.library.filter(
              (item: any) => !deleteMediaIds.includes(item.mediaid),
            );

            dispatch(actions.setLibrary({ library: newData }));
            setIsDeletingItem(false);
            // Show modal without care about the result
            await closeDeleteModalRef.current?.show();

            reloadLibraryItem();
            dispatch(toggleReloadLibrary());
          } catch (error) {
            customToast.error('Something went wrong');
          } finally {
            setIsDeletingItem(false);
          }
        }
      }
    }
  };

  const reloadLibraryItem = () => {
    dispatch({ type: types.CLOSE_STATUS_LIBRARY });
    dispatch(actions.setSelectedRows([]));
    dispatch(setSelectedClips([]));
    dispatch(actions.setSelectMode(false));
  };

  const handleModalShare = () => {
    if (selectedRows.length === 1 || clips.selectedClips.length === 1) {
      setopenShareModal(true);
    }
  };

  const handleModalPublish = () => {
    if (selectedRows.length === 1 || clips.selectedClips.length === 1) {
      setopenPublishModal(true);
      if (advancedSearch.isShowClipsOnly) {
        dispatch(setFocusClip(clips.selectedClips[0]));
      } else {
        dispatch(actions.setFocusRow(selectedRows[0]));
      }
    }
  };

  const handlepublishmodalclosed = () => {
    dispatch({
      type: types.CLOSE_PUBLISH_LIBRARY,
    });
    setopenPublishModal(false);
  };

  const handlesharemodalclosed = () => {
    setopenShareModal(false);
    dispatch({ type: 'RESET_PUBLISHED_VERSION' });
  };

  const handleExportModalClosed = () => {
    setopenExportModal(false);
  };

  const handleExportContentItem = () => {
    setopenExportModal(true);
  };

  const handleShowPreview = (item: any) => {
    const options = {
      startTime: item.starttime,
      endTime: item.endtime,
      mediaid: focusRow?.mediaid,
      title: item.versionname,
      versioncount: item.versioncount,
      thumbnail: item.thumbnail,
    };
    previewModalRef?.current?.show(options);
  };

  const handleRerun = async () => {
    const item = advancedSearch.isShowClipsOnly
      ? clips.focusClip
      : focusRow ?? selectedRows[0];

    try {
      const modalOption = await confirmModalRef.current?.show({
        title: 'Rerun AI',
        message: (
          <div>
            You are about to rerun the AI processing for the selected item(s)
            from your library. This is a destructive process. Should you
            proceed, this might impact updates made to speaker diarisation,
            chapterisation and entities. <b>Would you like to continue?</b>
          </div>
        ),
        confirmText: 'Confirm',
      });

      if (modalOption === ModalOptions.YES) {
        customToast.loading('Sending request');
        const { data: response } = await MediaService.regenarate({
          action: 're/run-ai-processing',
          payload: {
            media_id: item?.mediaid,
            status: item?.statuscode.toString(),
          },
        });
        toast.dismiss();

        if (response?.succeed) {
          customToast.success(response?.message);
        } else {
          if (toNumber(item?.statuscode) === VideoStatusCode.EDITING) {
            customToast.error(
              'Cannot rerun AI processing for this item. Please finish editing the item first.',
            );
          } else {
            customToast.error(response?.message);
          }
        }
      }
    } catch (err: any) {
      customToast.error('Something went wrong');
      console.log('err :>> ', err);
    }
  };

  const handleIntegrate = async () => {
    await integrationModalRef.current?.show({
      collectionIds: isEmpty(collection.selectedId)
        ? undefined
        : ensureArray(collection.selectedId),
    });
  };

  const handleShowPreviewPublish = (startTime?: number, endTime?: number) => {
    if (advancedSearch.isShowClipsOnly) {
      if (isEqual(clips.focusClip?.statuscode, ClipStatusCode.CREATION_FAILED))
        return;

      dispatch(setFocusClip(clips.selectedClips[0]));

      const options = {
        startTime,
        endTime,
        mediaid: clips?.focusClip?.media_id!,
        title: clips.focusClip?.versionname,
        versioncount: clips?.focusClip?.versioncount,
        thumbnail: clips.focusClip?.thumbnail,
      };

      showPreviewCLip(options);
      return;
    }

    previewModalRef?.current?.show({ startTime, endTime });
  };

  const showPreviewCLip = async (options: any) => {
    const result = await previewModalRef?.current?.show(options);
    if (result.option === ModalOptions.YES) {
      handleOpenModels(result.type);
    }
  };

  const handleOpenModels = (type: string) => {
    switch (type) {
      case 'Share':
        handleModalShare();
        break;
      case 'Export':
        handleExportContentItem();
        break;
      case 'Edit':
        handleModalPublish();
        break;
      default:
        break;
    }
  };

  return (
    <div
      className="libraryWrapper fluid"
      id="main-container"
      css={[customCollapseList]}
    >
      <>
        {location.pathname === Routes.INSIGHTS ? (
          <InsightsTab />
        ) : (
          <CollectionDnDWrapper>
            <CollapsibleList
              handle={<Sort />}
              open={advancedSearch.isShowAdvancedFilter}
            >
              <div
                css={[
                  tw`border-bottom[1px solid] border-b-sonnant-grey-3`,
                  (isLibraryPage() || isCollectionPage()) && tw`min-h-[16rem]`,
                ]}
              >
                {advancedSearch.isShowAdvancedFilter && <FilterBar />}
              </div>
            </CollapsibleList>

            {location.pathname === Routes.REVIVE && <RevivalTab />}

            <div
              tw="relative z-index[2] overflow-auto"
              css={[
                advancedSearch.isShowAdvancedFilter
                  ? tw`height[calc(100vh - 12rem - 17rem)]`
                  : tw`height[calc(100vh - 13rem)]`,
              ]}
            >
              {!library.loading &&
                !isAdvancedSearch &&
                !hasLibraryItems &&
                !(searchTerm || advancedSearch.isShowAdvancedFilter) && (
                  <EmptyLibraryBanner />
                )}

              {isAdvancedSearch && !hasLibraryItems && <NoSearchResults />}

              {!advancedSearch.isShowClipsOnly &&
                (pagination.isListView ? <ListView /> : <ThumbNails />)}

              {advancedSearch.isShowClipsOnly && hasLibraryItems && (
                <ClipSearch />
              )}

              {library.loading && (
                <div className="loader__component">
                  <Loader />
                </div>
              )}

              {(pagination.isListView || advancedSearch.isShowClipsOnly) && (
                <div tw="w-full flex justify-center items-center">
                  <LibraryPagination />
                </div>
              )}
            </div>
          </CollectionDnDWrapper>
        )}

        {isDeletingItem && (
          <div className="loader__component">
            <Loader />
          </div>
        )}

        <ConfirmModal
          ref={confirmDeleteModalRef}
          message="You are about to delete the selected item(s) from your library.
        Should you proceed, this item will be permanently deleted and cannot be recovered.
        Would you like to continue?"
          title="Delete from library"
          confirmText="Delete item"
        />

        <ConfirmModal ref={confirmModalRef} />

        <ConfirmModal
          ref={closeDeleteModalRef}
          message="Item deleted"
          title="Delete from library"
          hideConfirm
          hideCancel
        />
        <Modal
          classes="publish-modal-width"
          show={openPublishModal}
          modalClosed={handlepublishmodalclosed}
        >
          <Publish
            mediaid={
              advancedSearch.isShowClipsOnly
                ? clips.focusClip?.media_id!
                : focusRow?.mediaid! ?? selectedRows[0]?.mediaid!
            }
            filename={
              advancedSearch.isShowClipsOnly
                ? clips.focusClip?.versionname
                : getFileName(focusRow)
            }
            duration={toNumber(
              advancedSearch.isShowClipsOnly
                ? clips.focusClip?.length
                : focusRow?.length,
            )}
            closeShareModal={handlepublishmodalclosed}
            isOpen={openPublishModal}
            handleShowPreview={handleShowPreviewPublish}
            parentItem={
              advancedSearch.isShowClipsOnly ? clips.focusClip : focusRow
            }
          />
        </Modal>

        <Modal
          show={openShareModal}
          modalClosed={handlesharemodalclosed}
          classes="share-modal-width"
        >
          <Share
            mediaid={
              advancedSearch.isShowClipsOnly
                ? clips.selectedClips[0]?.media_id!
                : focusRow?.mediaid! ?? selectedRows[0]?.mediaid!
            }
          />
        </Modal>

        <Modal show={openExportModal} modalClosed={handleExportModalClosed}>
          <Export
            mediaid={
              advancedSearch.isShowClipsOnly
                ? clips.selectedClips[0]?.media_id
                : focusRow?.mediaid ?? selectedRows[0]?.mediaid
            }
            filename={
              advancedSearch.isShowClipsOnly
                ? clips.selectedClips[0]?.versionname
                : focusRow?.filename
            }
            closeExportModal={handleExportModalClosed}
            handleShowPreview={handleShowPreview}
          />
        </Modal>

        <PreviewClipModal ref={previewModalRef} />

        <IntegrationModal
          providers={integrationProviders}
          mediaids={mediaidList}
          ref={integrationModalRef}
        />

        <MultiMentionReportModal ref={multiMentionReportModalRef} />
      </>
      {/* )} */}
    </div>
  );
};

const customCollapseList = css`
  .rmwc-collapsible-list__children-inner {
    ${tw`(overflow-visible)!`}
  }

  .rmwc-collapsible-list__children {
    ${tw`(overflow-visible)!`}
  }
`;
