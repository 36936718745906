import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IMediaEntities,
  IRichTextInput,
} from 'components/shared/RichTextEditor/rich-text.model';
import { formatRaw } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { isEmpty, isNil, max, isArray, uniqBy } from 'lodash';
import { Transforms } from 'slate';
import { CustomElement } from 'types/slate-custom-types';
import { ICaption, IKeyItem, LayerCustomTerm } from 'utils/models';
import { IParagraphRaw, ISpeakerRaw } from 'utils/models/transcript.model';
import {
  toSlateStructure,
  getSpeakersFromCaption,
  toRawPara,
  checkInUsed,
  formatSpeaker,
} from 'utils/transcript.util';
import { Range } from 'slate';
import { v4 } from 'uuid';
import { customToast } from 'utils/toast.util';

const playerSlice = createSlice({
  name: 'player',
  initialState: {
    mediaid: null as string | null,
    finderOptions: {
      isMatchCase: null as boolean | null,
      isWholeWords: null as boolean | null,
      presentOccId: 0 as number,
      textSearch: '',
      searchRanges: [] as Range[],
    },
    selectedTagId: -1 as number | null,
    isSave: false,
    editModeListener: null as unknown,
    // MULTIPLE SELECT
    selectedTerms: [] as IKeyItem[],
    focusTerms: [] as IKeyItem[],
    // TRANSCRIPT DATA
    isProcessingData: true,
    isParagraphMode: false as boolean,
    paragraph: [] as IRichTextInput[],
    rawParagraph: [] as IParagraphRaw[],
    caption: [] as IRichTextInput[],
    transcript: [] as ICaption[],
    keywords: [] as IKeyItem[],
    keywordString: '' as string,
    entities: {
      person: [] as IKeyItem[],
      product: [] as IKeyItem[],
      organisation: [] as IKeyItem[],
      location: [] as IKeyItem[],
      nationality: [] as IKeyItem[],
    } as IMediaEntities,
    speakers: [] as ISpeakerRaw[],
    summary: null as string | null,
    origSummary: null as string | null,
    isCompleteLazy: false,
    layerCustomTerms: [] as LayerCustomTerm[],
    focusLayerCustomTermId: null as null | string,
    loadCompleted: false,
  },
  reducers: {
    editTermInCustomTerms: (state, { payload }: PayloadAction<IKeyItem>) => {
      const foundIndexTerms = state.layerCustomTerms.findIndex(
        (i) => i.listId === state.focusLayerCustomTermId,
      );
      if (foundIndexTerms >= 0) {
        const newCustomTerm = [...state.layerCustomTerms];
        const foundIndex = newCustomTerm[foundIndexTerms].matched.findIndex(
          (i) => i.id === payload.id,
        );
        if (foundIndex >= 0) {
          newCustomTerm[foundIndexTerms].matched[foundIndex] = payload;
          state.layerCustomTerms = newCustomTerm;
        }
      }
    },
    addTermIntoCustomTerms: (state, { payload }: PayloadAction<IKeyItem[]>) => {
      const foundIndex = state.layerCustomTerms.findIndex(
        (i) => i.listId === state.focusLayerCustomTermId,
      );
      if (foundIndex >= 0) {
        const newCustomTerm = [...state.layerCustomTerms];
        newCustomTerm[foundIndex].matched = [
          ...newCustomTerm[foundIndex].matched,
          ...payload,
        ];
        state.layerCustomTerms = newCustomTerm;
      }
    },
    setInitialValue: (
      state,
      {
        payload,
      }: PayloadAction<{
        keywords: IKeyItem[];
        entities: IMediaEntities;
      }>,
    ) => {
      if (!payload) return;
      const { keywords, entities } = payload;
      state.keywords = keywords ?? [];
      state.keywordString = state.keywords.map((k) => k.keyword).join(' ');
      state.entities = entities;
    },
    clearReplaceAll: (state) => {
      state.finderOptions.presentOccId = 0;
      state.finderOptions.searchRanges = [];
    },
    setTextSearch: (state, action) => {
      state.finderOptions.textSearch = action.payload;
    },
    setPresentOccId: (state, action) => {
      state.finderOptions.presentOccId = action.payload;
    },
    setKeywordString: (state, action) => {
      state.keywordString = action.payload;
    },
    resetFinder: (state) => {
      state.finderOptions.isMatchCase = null;
      state.finderOptions.isWholeWords = null;
      state.finderOptions.presentOccId = 0;
      state.finderOptions.textSearch = '';
    },
    resetPlayer: (state) => {
      state.paragraph = [];
      state.transcript = [];
      state.caption = [];
      state.editModeListener = null;
      state.keywords = [];
      state.entities = {} as any;
      state.speakers = [];
      state.summary = '';
      state.isProcessingData = true;
      state.isParagraphMode = false;
      state.selectedTagId = -1;
      state.selectedTerms = [];
      state.layerCustomTerms = [];
      state.rawParagraph = [];
      state.keywordString = '';
      state.origSummary = null;
      state.focusLayerCustomTermId = null;
    },
    setFinderOption: (state, action) => {
      state.finderOptions = action.payload;
    },
    setSelectedTagId: (state, action) => {
      state.selectedTagId = action.payload;
    },
    triggerEditModeEvent: (state) => {
      state.editModeListener = {};
    },
    setParagraph: (state, action) => {
      state.paragraph = action.payload;
    },
    // setTranscript: (state, action) => {
    //   state.transcript = action.payload;
    // },
    setCaption: (state, { payload }: PayloadAction<any>) => {
      state.caption = payload?.captions.map((c: IRichTextInput) => {
        const caption = { ...c };
        if (isEmpty(caption.children)) {
          caption.children = [
            {
              text: '',
            } as any,
          ];
        }
        return caption;
      });
      state.paragraph = toSlateStructure(
        payload?.captions,
        formatRaw(payload?.rawPara),
        'paragraph',
        true,
      );
      state.rawParagraph = payload?.rawPara ?? [];
      state.isProcessingData = false;

      if (isEmpty(payload?.speakers)) {
        state.speakers = checkInUsed(
          getSpeakersFromCaption(payload.captions),
          state.isParagraphMode ? state.paragraph : state.caption,
        );
      } else {
        state.speakers = checkInUsed(
          payload.speakers,
          state.isParagraphMode ? state.paragraph : state.caption,
        );
      }

      if (payload.isParagraphMode) {
        state.isParagraphMode = true;
      }
    },
    syncCaptionAndParagraph: (state) => {
      if (state.isParagraphMode === false) {
        state.caption = window.Editor?.children as IRichTextInput[];
      }

      if (state.isParagraphMode === true) {
        state.rawParagraph = toRawPara(window.Editor.children);
        state.paragraph = window.Editor?.children as IRichTextInput[];
      }
    },
    setKeywordsSlice: (state, action) => {
      state.keywords = action?.payload ?? [];
      state.keywordString = state.keywords.map((k) => k.keyword).join(' ');
    },
    setEntitiesSlice: (state, action) => {
      state.entities = action?.payload ?? {};
    },
    setSummary: (
      state,
      {
        payload,
      }: PayloadAction<{
        summary: string;
        mediaid: string | null;
        isOrig?: boolean;
        fromSocket?: boolean;
      }>,
    ) => {
      state.summary = payload.summary;
      state.mediaid = payload.mediaid;

      if (payload?.isOrig === true) {
        state.origSummary = payload.summary;
      }
    },
    updateKeywordAlter: (
      state,
      { payload }: PayloadAction<{ index: number; newKeyword: IKeyItem }>,
    ) => {
      const { index, newKeyword } = payload;
      state.keywords[index] = newKeyword;
      const foundIndex = state.selectedTerms.findIndex(
        (i) => i.keyword === newKeyword.keyword,
      );
      if (foundIndex >= 0) {
        state.selectedTerms[foundIndex] = newKeyword;
      }
    },
    updateAlter: (state, action) => {
      // const { newKeyword } = action.payload;
    },
    updateKeyword: (state, action) => {
      state.keywords = action.payload;
    },
    updateEntities: (state, action) => {
      state.entities = action.payload;
    },
    setSpeakers: (state, action: PayloadAction<ISpeakerRaw[]>) => {
      state.speakers = action.payload;
    },
    addSpeaker: (state, action: PayloadAction<string>) => {
      if (state.speakers.some((sp) => sp.name === action.payload)) {
        return;
      }

      const nextId = (max(state.speakers?.map((s) => s?.id)) ?? 0) + 1;

      state.speakers = checkInUsed(
        [...state.speakers, { id: nextId, name: action.payload }],
        window.Editor.children,
      );
    },
    removeSpeaker: (state, action: PayloadAction<ISpeakerRaw>) => {
      state.speakers = state.speakers.filter((s) => s.id !== action.payload.id);
    },
    removeSpeakerByName: (state, action: PayloadAction<string>) => {
      state.speakers = state.speakers.filter((s) => s.name !== action.payload);
    },
    updateCurrentSpeaker: (
      state,
      {
        payload,
      }: PayloadAction<{
        oldName: string;
        newName: string;
        isOverWrite?: boolean;
      }>,
    ) => {
      if (!payload) return;

      const speakerMapFn = (p: CustomElement, index: number) => {
        const speakers = p?.data?.speaker;
        const speakerName = formatSpeaker(
          isArray(speakers) && !isEmpty(speakers) ? speakers[0] : speakers,
        );
        if (
          speakerName &&
          speakerName?.trim().toLocaleLowerCase() ===
            payload?.oldName?.trim().toLocaleLowerCase()
        ) {
          Transforms.setNodes(
            window.Editor,
            {
              data: { ...p.data, speaker: payload?.newName?.trim() },
            } as Partial<CustomElement>,
            { at: [index] },
          );
        }
        return p;
      };

      customToast.loading('Updating...');
      if (state.isParagraphMode) {
        // state.paragraph = state.paragraph.map(speakerMapFn);
        window.Editor.children.map(speakerMapFn);
      } else {
        // state.caption = state.caption.map(speakerMapFn);
        window.Editor.children.map(speakerMapFn);
      }

      // Update speaker array with new name
      if (payload?.isOverWrite) {
        const newSpeaker = uniqBy(
          state.speakers.map((s) => ({
            ...s,
            name: s.name === payload.oldName ? payload.newName : s.name,
          })),
          'name',
        );

        // Add old speaker to speakers but unused
        newSpeaker.push({
          id: state.speakers.length + 1,
          name: payload.oldName,
          inUsed: false,
          occurrences: [],
        });
        state.speakers = newSpeaker;
      }

      customToast.success('Update completed');
    },
    toggleParagraphMode: (state, action) => {
      if (isNil(action) || isNil(action?.payload)) {
        state.isParagraphMode = !state.isParagraphMode;
      } else {
        state.isParagraphMode = action.payload;
      }
      if (!window?.Editor?.children) return;

      // Caption -> Para
      if (state.isParagraphMode === true) {
        state.paragraph = [];
        state.paragraph = toSlateStructure(
          window.Editor.children,
          formatRaw(state?.rawParagraph),
          'paragraph',
        );
        state.caption = [];
        state.caption = window.Editor.children as CustomElement[];
        window.Editor.children = state.paragraph;
      }

      // Para -> Caption
      if (state.isParagraphMode === false) {
        state.rawParagraph = [];
        state.rawParagraph = toRawPara(window.Editor.children);

        let fullCaptions = [...state.caption];
        state.caption = [];
        state.caption = toSlateStructure(
          window.Editor.children,
          toRawPara(fullCaptions),
          'caption',
        );
        fullCaptions = [];
        window.Editor.children = state.caption;
      }
    },
    setIsCompleteLazy: (state, { payload }: PayloadAction<boolean>) => {
      state.isCompleteLazy = payload;
    },
    updateSelectedTerm: (state, { payload }: PayloadAction<IKeyItem>) => {
      if (!payload) return;

      const foundIndex = state.selectedTerms.findIndex(
        (term) => term.id === payload.id,
      );

      if (foundIndex >= 0) {
        state.selectedTerms[foundIndex] = payload;
      }
    },
    setSingleSelectedTerms: (state, { payload }: PayloadAction<IKeyItem>) => {
      if (!payload) return;

      state.selectedTerms = [payload];
    },
    clearSelectedTerm: (state) => {
      state.selectedTerms = []; // Not use
      state.selectedTagId = null;
      // state.focusLayerCustomTerm = null;
    },
    addSelectedTerm: (state, { payload }: PayloadAction<IKeyItem>) => {
      if (!payload) return;
      state.selectedTerms = [...state.selectedTerms, payload];
    },
    setSelectedTerm: (state, { payload }: PayloadAction<IKeyItem[]>) => {
      if (isNil(payload)) return;

      state.selectedTerms = payload;
    },
    setFocusTerm: (state, { payload }: PayloadAction<IKeyItem[]>) => {
      if (isNil(payload)) return;

      state.focusTerms = payload;
    },
    updateFocusTermItem: (state, { payload }: PayloadAction<IKeyItem>) => {
      if (isNil(payload)) return;

      state.focusTerms = state.focusTerms.map((t) => {
        if (t?.keyword === payload.keyword) {
          return payload;
        }
        return t;
      });
    },
    removeSelectedTerm: (state, { payload }: PayloadAction<IKeyItem>) => {
      if (!payload) return;

      state.selectedTerms = state.selectedTerms.filter(
        (t) => t?.keyword !== payload.keyword,
      );
    },
    setLayerCustomTerms: (
      state,
      { payload }: PayloadAction<LayerCustomTerm[]>,
    ) => {
      state.layerCustomTerms = payload.map((listRaw) => {
        const list = { ...listRaw };
        list.matched = uniqBy(
          list.matched.map((m) => ({ id: m?.id ?? v4(), ...m })),
          'keyword',
        );
        return list;
      });

      if (payload?.length > 0 && isNil(state.focusLayerCustomTermId)) {
        state.focusLayerCustomTermId = payload[0]?.listId;
      }
    },
    setFocusLayerCustomTerm: (
      state,
      { payload }: PayloadAction<LayerCustomTerm | null>,
    ) => {
      state.focusLayerCustomTermId = payload?.listId!;
    },
    removeLayerCustomerTerm: (state, { payload }: PayloadAction<IKeyItem>) => {
      if (!payload || !state.focusLayerCustomTermId) return;

      const focusId = state.focusLayerCustomTermId;
      const foundListIndex = state.layerCustomTerms.findIndex(
        (l) => l.listId === focusId,
      );

      const foundList = state.layerCustomTerms[foundListIndex];
      state.layerCustomTerms[foundListIndex].matched =
        foundList?.matched?.filter((k) => k?.id !== payload?.id);
    },
    appendLayerCustomerTerm: (
      state,
      { payload }: PayloadAction<LayerCustomTerm>,
    ) => {
      if (!payload) return;

      // Auto focus if there is only 1 item
      if (state.layerCustomTerms?.length === 0) {
        state.focusLayerCustomTermId = payload.listId;
      }

      state.layerCustomTerms = [...state.layerCustomTerms, payload];
    },
    updateLayerCustomerTerm: (
      state,
      { payload }: PayloadAction<LayerCustomTerm>,
    ) => {
      if (!payload) return;

      const foundIndex = state.layerCustomTerms.findIndex(
        ({ listId }) => listId === payload?.listId,
      );

      if (foundIndex > -1) {
        state.layerCustomTerms[foundIndex] = payload;
      }
    },
    deleteLayerCustomerTerm: (
      state,
      { payload }: PayloadAction<LayerCustomTerm>,
    ) => {
      if (!payload) return;

      state.layerCustomTerms = state.layerCustomTerms.filter(
        ({ listId }) => listId !== payload.listId,
      );

      if (state.layerCustomTerms?.length === 0) {
        state.focusLayerCustomTermId = null;
      } else if (state.focusLayerCustomTermId === payload.listId) {
        state.focusLayerCustomTermId = state.layerCustomTerms?.[0].listId;
      }
    },
    setLoadCompleted: (state, { payload }: PayloadAction<boolean>) => {
      state.loadCompleted = payload;
    },
    setSearchRanges: (state, { payload }: PayloadAction<Range[]>) => {
      state.finderOptions.searchRanges = payload;
    },
    resetSearchRanges: (state) => {
      state.finderOptions.searchRanges = [];
    },
    replaceSingleRange: (state, { payload }: PayloadAction<Range>) => {
      if (!payload) return;

      state.finderOptions.searchRanges =
        state.finderOptions.searchRanges.filter(
          (range) => !Range.equals(range, payload),
        );
      if (state.finderOptions.searchRanges.length === 0) {
        state.finderOptions.presentOccId = 0;
      } else {
        state.finderOptions.presentOccId--;
      }
    },
    syncLatestSpeaker: (state) => {
      // Check inUsed status of current speakers
      const latestSpeakers = checkInUsed(
        state.speakers,
        window.Editor.children,
      );

      state.speakers = latestSpeakers;

      const selectedSpeaker = latestSpeakers?.find(
        (sp) => sp.name === state.selectedTerms?.[0]?.keyword,
      );

      // Update mention occurrences for selected speaker
      if (selectedSpeaker) {
        state.selectedTerms = [
          {
            inUsed: selectedSpeaker?.inUsed,
            keyword: selectedSpeaker?.name,
            mentions: [
              {
                speakerName: selectedSpeaker?.name,
                occurrences: selectedSpeaker?.occurrences ?? [],
              },
            ],
          },
        ];
      }
    },
  },
});

export const {
  editTermInCustomTerms,
  addTermIntoCustomTerms,
  updateAlter,
  updateKeywordAlter,
  updateEntities,
  clearReplaceAll,
  updateSelectedTerm,
  setTextSearch,
  setPresentOccId,
  updateKeyword,
  resetFinder,
  setFocusTerm,
  resetPlayer,
  setFinderOption,
  setSelectedTagId,
  triggerEditModeEvent,
  setParagraph,
  setKeywordsSlice,
  setEntitiesSlice,
  updateFocusTermItem,
  setKeywordString,
  setSummary,
  setSpeakers,
  setCaption,
  addSpeaker,
  removeSpeaker,
  updateCurrentSpeaker,
  toggleParagraphMode,
  setIsCompleteLazy,
  setSingleSelectedTerms,
  addSelectedTerm,
  removeSelectedTerm,
  setInitialValue,
  clearSelectedTerm,
  setLayerCustomTerms,
  setFocusLayerCustomTerm,
  removeLayerCustomerTerm,
  setSelectedTerm,
  appendLayerCustomerTerm,
  updateLayerCustomerTerm,
  deleteLayerCustomerTerm,
  setLoadCompleted,
  setSearchRanges,
  resetSearchRanges,
  replaceSingleRange,
  syncCaptionAndParagraph,
  syncLatestSpeaker,
  removeSpeakerByName,
} = playerSlice.actions;

export const playerReducer = playerSlice.reducer;
