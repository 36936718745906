/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import {
  elementScroll,
  useVirtualizer,
  VirtualizerOptions,
} from '@tanstack/react-virtual';
import { first, isEmpty, isNil, last, toNumber } from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { easeInOutQuint } from 'utils/utils';
import { Info } from '../Info';
import { greyScrollbarVertical } from '../twin.styles';
import { SerializedStyles } from '@emotion/react';

type Props = {
  columns: ColumnDef<unknown, any>[];
  tableData: unknown[];
  noDataMessage: string;
  tableRow: (row: Row<any>, index: number) => JSX.Element;
  stickyHeader?: boolean;
  customCss?: SerializedStyles;
  indexScrollTo?: number | null;
};

export const VirtualTable = ({
  columns,
  tableData,
  tableRow,
  noDataMessage,
  stickyHeader = false,
  customCss,
  indexScrollTo,
}: Props) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const scrollingRef = useRef<number>();

  let table = useReactTable({
    columns: useMemo(() => columns, [columns]),
    data: useMemo(() => tableData, [tableData]),

    getCoreRowModel: getCoreRowModel(),
  });

  const { rows } = table.getRowModel();

  const scrollToFn: VirtualizerOptions<any, any>['scrollToFn'] =
    React.useCallback((offset, canSmooth, instance) => {
      const duration = 1000;
      const start = toNumber(tableContainerRef.current?.scrollTop);
      const startTime = (scrollingRef.current = Date.now());

      const run = () => {
        if (scrollingRef.current !== startTime) return;
        const now = Date.now();
        const elapsed = now - startTime;
        const progress = easeInOutQuint(Math.min(elapsed / duration, 1));
        const interpolated = start + (offset - start) * progress;

        if (elapsed < duration) {
          elementScroll(interpolated, canSmooth, instance);
          requestAnimationFrame(run);
        } else {
          elementScroll(interpolated, canSmooth, instance);
        }
      };

      requestAnimationFrame(run);
    }, []);

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    count: rows.length,
    // must equal to the height of the row
    // eslint-disable-next-line react-hooks/exhaustive-deps
    estimateSize: useMemo(() => () => 120, [rows]), // max height of the row
    overscan: 1, // number of items renders above and below the visible area
    scrollToFn,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize();

  const paddingTop = first(virtualRows)?.start || 0;
  const paddingBottom = totalSize - (last(virtualRows)?.end || 0);

  useEffect(() => {
    if (isNil(indexScrollTo)) return;
    rowVirtualizer?.scrollToIndex(indexScrollTo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexScrollTo, rowVirtualizer]);

  return (
    <div
      ref={tableContainerRef}
      tw="overflow-auto md-down:overflow-x-auto scrollbar-width[thin]"
      css={[greyScrollbarVertical, customCss]}
      style={{ height: '100%' }}
    >
      <table
        // style={{ width: table.getCenterTotalSize() }}
        tw="text-15!"
      >
        <thead css={[stickyHeader && tw`sticky top[-1px]`]}>
          {table.getHeaderGroups().map((headerGroup, groupIndex: number) => (
            <tr key={groupIndex}>
              {headerGroup.headers.map((header, headerIndex: number) => (
                <th
                  tw="(text-sonnant-grey-6 opacity-95)!"
                  style={(header.column.columnDef as any)?.style}
                  key={headerIndex}
                >
                  <div>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}

          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index] as Row<unknown>;

            return tableRow(row, virtualRow.index);
          })}

          {isEmpty(rows) && (
            <tr tw="pl-3 py-3">
              <td colSpan={8}>
                <Info
                  text={noDataMessage}
                  fontSize="1.6rem"
                  hideSpacingBottom
                />
              </td>
            </tr>
          )}

          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
