/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import tw from 'twin.macro';

import { ReactComponent as DelSvg } from 'assets/Icons/x-cancel-red_28dp.svg';
import { ReactComponent as EditSvg } from 'assets/Icons/edit_black_18dp.svg';

import { disabledCss, hoverDropShadow } from 'components/shared/twin.styles';
import { Draggable } from 'react-beautiful-dnd';
import { TagResponse, TagService } from 'services/tag.service';
import { RootState } from 'reducers';
import { useDispatch, useSelector } from 'react-redux';
import { TextInput } from 'components/shared/TextInput';
import { useToggle } from 'react-use';
import { first, isEmpty } from 'lodash';
import { customToast } from 'utils/toast.util';
import { updateTag } from 'slices/tag.slice';
import { HintDisallowed } from 'components/shared/HintDisallowed';
import { Hint } from 'components/shared/Hint';

interface Props {
  index: number;
  tagItem: TagResponse;
  isArchiveTag?: boolean;

  onDeleteList: (item: TagResponse) => void;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const TagListItem = ({
  isArchiveTag = false,
  tagItem,
  index,
  ...props
}: Props) => {
  const dispatch = useDispatch();

  const tag = useSelector((state: RootState) => state.tags);

  const isTagSelected = tag.selectedTagId === tagItem.tagId;

  const [isEditing, toggleEditing] = useToggle(false);

  const handleUpdateTagName = (rawName: string) => {
    const newTagName = rawName?.trim() ?? '';

    if (isEmpty(newTagName)) return;

    if (newTagName === tagItem.tagName) {
      cancelEditing();
      return;
    }

    const existingTag = tag.activeTags.filter(
      (tag) => tag.tagName === newTagName,
    );

    if (!isEmpty(existingTag)) {
      customToast.warning(
        <div>
          <strong>{first(existingTag)?.tagName}</strong> is already existing.
          <div>Please check and try again!</div>
        </div>,
      );

      return;
    }

    const updatedTag = {
      ...tagItem,
      tagName: newTagName,
    };

    dispatch(updateTag(updatedTag));
    updateTagNameAsync(newTagName);

    cancelEditing();
  };

  const enableEditing = () => {
    if (isEditing || isArchiveTag) return;

    toggleEditing(true);
  };

  const cancelEditing = () => {
    toggleEditing(false);
  };

  const updateTagNameAsync = async (newTagName: string) => {
    const updateAsync = TagService.updateTag({
      tagId: tagItem.tagId,
      tagName: newTagName,
    });

    customToast.promise(updateAsync, {
      loading: 'Updating',
      success: 'Done',
      error: 'Failed to update tag name',
    });

    try {
      await updateAsync;
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  return (
    <Draggable
      key={tagItem.tagId}
      draggableId={tagItem.tagName}
      index={index}
      isDragDisabled={true}
    >
      {(provided) => (
        <div
          key={tagItem.tagId}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          tw="flex w-full justify-between py-2 rounded -ml-3 pl-3 pr-2 cursor-pointer"
          onClick={props.onClick}
          css={[
            isTagSelected
              ? tw`background[#DAD9FE]`
              : tw`hover:(bg-sonnant-grey-2)`,
            isArchiveTag && tw`font-bold text-sonnant-blue`,
          ]}
        >
          <div tw="flex items-center opacity[0.85] font-weight[500]">
            <span tw="mr-6">{index + 1}.</span>
            {isEditing ? (
              <TextInput
                tw="pl-2 font-medium placeholder:(font-light)"
                defaultText={tagItem.tagName}
                placeholder={tagItem.tagName}
                onPressEnter={handleUpdateTagName}
                onPressEsc={cancelEditing}
                onBlur={cancelEditing}
              />
            ) : (
              <Hint
                text="Any tag naming as 'Archive' with it's associated media items would be not visible on the Library or Insights by default."
                maxWidth="30rem"
                align="right"
                notTransparent
                enterDelay={200}
                disabled={!isArchiveTag}
              >
                <span tw="break-all" onDoubleClick={enableEditing}>
                  {tagItem.tagName}
                </span>
              </Hint>
            )}
          </div>

          <div
            tw="flex items-center cursor-pointer space-x-6"
            onClick={(e) => e.stopPropagation()}
          >
            {/* EDIT */}
            <HintDisallowed
              disabled={isArchiveTag}
              hintEnabled="Rename this tag"
              hintDisabled="Archive tag cannot be edited or deleted"
            >
              <div
                tw="w-[2.4rem] h-[2.4rem] flex items-center justify-center bg-white hover:bg-sonnant-grey-2 border[2px solid black] mr-2 cursor-pointer rounded-full"
                css={[hoverDropShadow(0.1), isArchiveTag && disabledCss]}
                onClick={enableEditing}
              >
                <EditSvg />
              </div>
            </HintDisallowed>

            {/* DELETE */}
            <HintDisallowed
              disabled={isArchiveTag}
              hintEnabled="Delete this tag"
              hintDisabled="Archive tag cannot be edited or deleted"
            >
              <div
                tw="flex cursor-pointer"
                css={[hoverDropShadow(0.2), isArchiveTag && disabledCss]}
                onClick={() => props?.onDeleteList(tagItem)}
              >
                <DelSvg />
              </div>
            </HintDisallowed>
          </div>
        </div>
      )}
    </Draggable>
  );
};
