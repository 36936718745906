import { SagaAction } from 'utils/models';
import * as types from '../actions/types';
import { getToken, getUser } from '../utils/utils';

interface InitialStateType {
  loading: boolean;
  error: boolean;
  token: any;
  errorMessage: string;
  username: any;
  isLoggedIn: boolean;
  profilePic: null;
}

const initialState = {
  loading: false,
  error: false,
  token: getToken(),
  errorMessage: '',
  username: getUser(),
  isLoggedIn: false,
  profilePic: null,
};
// eslint-disable-next-line import/no-anonymous-default-export
export const loginReducer = (
  state: InitialStateType = initialState,
  action: SagaAction,
) => {
  switch (action.type) {
    case types.INIT_LOGIN: {
      return { ...state, loading: true, error: false, errorMessage: '' };
    }
    case types.LOGIN_SUCCESS: {
      return { ...state, isLoggedIn: true };
    }
    case types.SET_TOKEN: {
      return {
        ...state,
        loading: false,
        token: action.token,
        username: action.username,
      };
    }
    case types.SET_TOKEN_FAILED: {
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.error,
      };
    }
    case types.HANDLE_PROFILE_PIC: {
      return {
        ...state,
        profilePic: action.profilePic,
      };
    }
    default:
      return state;
  }
};
