/** @jsxImportSource @emotion/react */
import React, { ReactElement, useRef } from 'react';
import { css } from '@emotion/react';
import { customToast } from 'utils/toast.util';
import tw from 'twin.macro';
import { config } from 'components/config';
import { AuthService, ShareService } from 'services';
import {
  getWindowOptions,
  readCookie,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { HTTPStatus, ModalOptions, Social } from 'utils/enum';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { ConfirmModalProps } from 'utils/models';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { IntegrationService } from 'services/integration.service';
import { AxiosError } from 'axios';
import { SocialConnectionModal } from 'components/shared/SocialConnectionModal/SocialConnectionModal';
import {
  BasicConfirmModal,
  SocialConnectionModalPayload,
} from 'utils/models/modal.model';

type ShareSocialProps = {
  shareURL?: string;
  shareId: string;
  icon: ReactElement;
  name: Social;
  openModalSharePost: (provider: Social, shareId: string) => void;
  disabled?: boolean;
};

export const ShareSocialButton = (props: ShareSocialProps) => {
  const { shareURL, icon, name, shareId } = props;

  const payment = useSelector((state: RootState) => state.payment);

  const confirmModalRef = useRef<BasicConfirmModal>(null);
  const socialConnectionModalRef = useRef<SocialConnectionModalPayload>(null);

  const preventShareSocial = () =>
    customToast.error(
      'Selected clip item has not been shared. Please check and try again.',
    );

  //authorization LinkedIn
  const authorizationLinkedIn = (shareId: string) => {
    const oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${config.LINKEDIN_CLIENT_ID}&redirect_uri=${config.LINKEDIN_REDIRECT_URL}&state=${shareId}&scope=r_liteprofile%20r_emailaddress%20w_member_social`;
    popupShare(oauthUrl, Social.LINKEDIN, shareId);
  };

  // authorization Facebook
  const authorizationFacebook = async (ShareURL: string, shareId: string) => {
    // if (true) {
    const oauthUrl = `https://www.facebook.com/dialog/oauth?client_id=${config.FB_CLIENT_ID}&redirect_uri=${config.FB_REDIRECT_URL}&response_type=code&scope=pages_show_list,pages_manage_posts,pages_read_engagement`;
    window.document.cookie = `mediaKeyFacebook=${shareId};domain=.${window.location.host}; path=/`;
    popupShare(oauthUrl, Social.LINKEDIN, shareId);
    // } else {
    //   const mediaData = await MediaService.getPublicDetails(shareId);
    //   let Content = `${mediaData?.data?.versionname} \n${removeHTMLTag(
    //     normalizeSummary(mediaData?.data?.metadata?.summary),
    //   )} \n`;
    //   mediaData?.data?.metadata?.keywords.forEach((element: any) => {
    //     Content = `${Content} #${removeSpaceChars(element.keyword)
    //       .replace(/\s/gm, '')
    //       .replace(/\W/gm, '_')} `;
    //   });
    //   const oauthUrl = `https://www.facebook.com/sharer/sharer.php?u=${ShareURL}&quote=${Content}`;
    //   window.open(oauthUrl, getWindowOptions());
    // }
  };

  // authorization Twitter
  const authorizationTwitter = async (shareId: string) => {
    try {
      customToast.loading('Invoking Twitter authorization');
      const { data: oauthToken } = await AuthService.getTwitterToken();
      toast.dismiss();

      const oauthUrl = `https://api.twitter.com/oauth/authorize?oauth_token=${oauthToken}`;
      window.document.cookie = `mediaKeyTwitter=${shareId};domain=.${window.location.host}; path=/`;
      popupShare(oauthUrl, Social.TWITTER, shareId);
    } catch (err: any) {
      customToast.error('Something went wrong');
    }
  };

  // authorization Youtube
  const authorizationYoutube = async (shareId: string) => {
    try {
      customToast.loading('Fetching connected account info...');

      const { data } = await IntegrationService.getYoutubeIntegration();

      toast.dismiss();

      const result = await socialConnectionModalRef.current?.show({
        title: 'Youtube connected accounts',
        accessToken: data.access_token,
        payload: data,
      });

      if (result === ModalOptions.YES) {
        window.document.cookie = `access_token=${data.access_token};domain=.${window.location.host}; path=/`;
        window.document.cookie = `provider=${Social.YOUTUBE};domain=.${window.location.host}; path=/`;
        window.document.cookie = `mediaKeyYoutube=${shareId};domain=.${window.location.host}; path=/`;

        props.openModalSharePost(Social.YOUTUBE, shareId);
        return;
      } else {
        requestWeb0Auth2Youtube(shareId);
        return;
      }
    } catch (error: any) {
      console.log('error :>> ', error?.response);

      if ((error as AxiosError).response?.status === HTTPStatus.NOT_FOUND) {
        // customToast.error(
        //   <div>
        //     <div>No connect accounts found!</div>
        //     <div>Navigating to authentication page...</div>
        //   </div>,
        // );

        requestWeb0Auth2Youtube(shareId);
      }
    }
  };

  const requestWeb0Auth2Youtube = (shareId: string) => {
    try {
      const scopes = [
        'https://www.googleapis.com/auth/youtube.upload',
        'https://www.googleapis.com/auth/youtube.readonly',
      ].join(' ');

      const oauthUrl = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${config.YT_CLIENT_ID}&redirect_uri=${config.YT_REDIRECT_URL}&scope=${scopes}&prompt=consent&access_type=offline`;

      console.log('config :>> ', config);

      window.document.cookie = `mediaKeyYoutube=${shareId};domain=.${window.location.host}; path=/`;
      popupShare(oauthUrl, Social.YOUTUBE, shareId);
    } catch (err: any) {
      customToast.error('Something went wrong');
    }
  };

  const authorizationTiktok = async (shareId: string) => {
    try {
      let url = 'https://open-api.tiktok.com/platform/oauth/connect/';
      url += `?client_key=${config.TIKTOK_CLIENT_ID}`;
      url +=
        '&scope=user.info.basic,video.list,video.upload,video.upload,share.sound.create';
      url += '&response_type=code';
      url += `&redirect_uri=${config.TIKTOK_REDIRECT_URL}`;
      url += '&state=' + shareId;
      window.document.cookie = `mediaKeyTikTok=${shareId};domain=.${window.location.host}; path=/`;
      popupShare(url, Social.TIKTOK, shareId);
    } catch (err: any) {
      customToast.error('Something went wrong');
    }
  };

  //authorization Instagram
  const authorizationInstagram = (shareId: string) => {
    const oauthUrl = `https://www.facebook.com/dialog/oauth?client_id=${config.FB_CLIENT_ID}&redirect_uri=${config.INSTAGRAM_REDIRECT_URL}&response_type=code&scope=pages_show_list,instagram_basic,instagram_content_publish,pages_read_engagement`;
    window.document.cookie = `mediaKeyInstagram=${shareId};domain=.${window.location.host}; path=/`;
    popupShare(oauthUrl, Social.INSTAGRAM, shareId);
  };
  const sendClipTikTok = async () => {
    const result = await confirmModalRef.current?.show({
      title: 'Send clip to TikTok',
      message: (
        <div>
          <div>Your clip will be sent to your Share TikTok Platform.</div>
          <div>
            You will need to edit it before publishing.{' '}
            <strong>Would you like to proceed?</strong>
          </div>
        </div>
      ),
    } as ConfirmModalProps);

    if (result !== ModalOptions.YES) return;

    const params = {
      access_token: readCookie('access_token'),
      mediaKey: readCookie('mediaKeyTikTok'),
      provider: readCookie('provider'),
    };

    const shareSocialAsync = ShareService.shareSocial(Social.TIKTOK, params);
    customToast.promise(shareSocialAsync, {
      loading: 'Sending clip to TikTok',
      success: 'Your clip was sent to your TikTok account.',
      error: 'Failed to send video to TikTok Share',
    });
  };

  const popupShare = (
    oauthUrl: string,
    provider: Social,
    existedShareId: string,
  ) => {
    const popup = window.open(oauthUrl, provider, getWindowOptions());
    window.document.cookie = `state=false; domain=.${config.DOMAIN_NAME}; path=/`;

    const popupTick = setInterval(async function () {
      if (popup?.closed) {
        const state = readCookie('state');
        clearInterval(popupTick);

        // if state is true, then user has authorized
        if (state !== 'true') return;

        if (provider === Social.TIKTOK) {
          await sendClipTikTok();
        } else if (provider === Social.YOUTUBE) {
          const code = readCookie('code');
          if (!code) return;

          try {
            await IntegrationService.sendCodeOAuthYoutube({ code });

            props?.openModalSharePost?.(provider, existedShareId);
          } catch (error) {
            console.log('error', error);
          }
        } else {
          props?.openModalSharePost?.(provider, existedShareId);
        }
      }
    }, 500);
  };

  const currentAuthorization = () => {
    if (payment.isExpiredTrial) {
      customToast.trialExpired();
      return;
    }
    if (!shareURL) return preventShareSocial();
    if (props?.disabled === true) return;

    if (name === Social.LINKEDIN) authorizationLinkedIn(shareId);
    if (name === Social.FACEBOOK) authorizationFacebook(shareURL, shareId);
    if (name === Social.TWITTER) authorizationTwitter(shareId);
    if (name === Social.YOUTUBE) authorizationYoutube(shareId);
    if (name === Social.TIKTOK) authorizationTiktok(shareId);
    if (name === Social.INSTAGRAM) authorizationInstagram(shareId);
  };

  return (
    <>
      <div css={[actionIcon]} onClick={currentAuthorization}>
        {icon}
        <span>{name}</span>
      </div>
      <ConfirmModal ref={confirmModalRef} />

      {name === Social.YOUTUBE && (
        <SocialConnectionModal ref={socialConnectionModalRef} />
      )}
    </>
  );
};

const actionIcon = css`
  ${tw`font-size[1.2rem] width[5.5rem]! flex flex-col items-center text-sonnant-grey-5 cursor-pointer`}
  &:not(:last-child) {
    ${tw`mr-8`}
  }
  svg {
    width: 3.5rem;
    margin-bottom: -4px;
  }
`;
