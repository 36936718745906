import { Link } from 'react-router-dom';
import { isLoggedIn } from './utils';

export const PageNotFound = () => {
  const token = localStorage.getItem('token');
  let message = `The page you are looking for cannot be found. If attempting to reach a page by entering the address manually, 
    please check the (case-sensitive) details. We recommend using links provided within the app to navigate directly.`;
  let subDomain: any = window.location.host.split('.')[0];
  subDomain =
    subDomain !== 'voice-intellect' && subDomain !== 'sonnant' ? true : false;

  return (
    <div className="grid-x successNotification">
      <div className="small-4 mgn-auto">
        <div className="notificationContainer">
          <h1>Page Not Found</h1>
          <h3>{message}</h3>
        </div>
        {isLoggedIn() && (
          <Link
            to={`${
              token && subDomain
                ? '/library'
                : subDomain
                ? '/signin'
                : '/signup'
            }`}
          >
            <button className="button btn-primary">{`Return to ${
              token && subDomain ? 'Home' : subDomain ? 'Sign in' : 'Sign up'
            } Page`}</button>
          </Link>
        )}
      </div>
    </div>
  );
};
