import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { first, isEmpty } from 'lodash';
import { AdminPortalService } from 'services/admin-portal.service';
import { UserGroupResponse } from 'utils/models';

export type AdminPortalState = {
  userGroups: UserGroupResponse[];
  isLoading: boolean;
  selectedUserGroupId: string | null;
  shouldRefetchCollections: boolean;
};

export const fetchAllUserGroups = createAsyncThunk<UserGroupResponse[]>(
  'adminPortal/fetchAll',
  async () => {
    const { data } = await AdminPortalService.getAllUserGroups();

    return data;
  },
);

const initialState: AdminPortalState = {
  userGroups: [],
  isLoading: false,
  selectedUserGroupId: null,
  shouldRefetchCollections: false,
};

const adminPortalSlice = createSlice({
  name: 'admin-portal',
  initialState,
  reducers: {
    setUserGroups: (
      state,
      { payload }: PayloadAction<AdminPortalState['userGroups']>,
    ) => {
      state.userGroups = payload;
    },
    appendUserGroup: (
      state,
      {
        payload,
      }: PayloadAction<Pick<UserGroupResponse, 'groupId' | 'groupName'>>,
    ) => {
      const newGroupProperties: UserGroupResponse = {
        createdAt: new Date(),
        modifiedAt: new Date(),
        isDefault: false,
        isAdmin: false,
        isEnabledEdit: true,
        isEnabledPublish: true,
        ...payload,
      };

      state.userGroups.push(newGroupProperties);
    },
    deleteUserGroup: (state, { payload }: PayloadAction<string>) => {
      const filteredUserGroups = state.userGroups.filter(
        ({ groupId }) => groupId !== payload,
      );

      state.userGroups = filteredUserGroups;

      if (isEmpty(filteredUserGroups)) {
        state.selectedUserGroupId = null;
      } else {
        state.selectedUserGroupId = first(filteredUserGroups)
          ?.groupId as string;
      }
    },
    setSelectedUserGroupId: (
      state,
      { payload }: PayloadAction<AdminPortalState['selectedUserGroupId']>,
    ) => {
      state.selectedUserGroupId = payload;
    },
    toggleRefetchCollections: (state, action?: PayloadAction<boolean>) => {
      if (typeof action?.payload === 'boolean') {
        state.shouldRefetchCollections = action.payload;
      } else {
        state.shouldRefetchCollections = !state.shouldRefetchCollections;
      }
    },
    updateUserGroupItem: (
      state,
      { payload }: PayloadAction<UserGroupResponse>,
    ) => {
      const foundIndex = state.userGroups.findIndex(
        (userGroup) => userGroup.groupId === payload.groupId,
      );

      if (foundIndex < 0) return;

      state.userGroups[foundIndex] = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUserGroups.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllUserGroups.fulfilled, (state, { payload }) => {
        state.isLoading = false;

        if (isEmpty(payload)) {
          state.selectedUserGroupId = null;
        } else if (isEmpty(state.selectedUserGroupId)) {
          state.selectedUserGroupId = first(payload)?.groupId as string;
        }

        state.userGroups = payload;
      })
      .addCase(fetchAllUserGroups.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const {
  setUserGroups,
  appendUserGroup,
  deleteUserGroup,
  setSelectedUserGroupId,
  toggleRefetchCollections,
  updateUserGroupItem,
} = adminPortalSlice.actions;

export const adminPortalReducer = adminPortalSlice.reducer;
