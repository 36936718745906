/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

type Props = {
  bgImage: string;
  zIndex: number;
};

export const EmbedBackgroundBlur = ({ bgImage, zIndex }: Props) => {
  return (
    <div css={overlayContainerCss(zIndex)}>
      <div css={overlayBgImageCss(bgImage)}></div>
      <div css={overlayBgBlackCSS}></div>
    </div>
  );
};

const overlayContainerCss = (zIndex: number) => css`
  z-index: ${zIndex};

  ${tw`absolute top-0 right-0 bottom-0 left-0 overflow-hidden`};
`;

const overlayBgImageCss = (backgroundImage: string) => css`
  background-image: url(${backgroundImage});
  background-position: 50% 50%;
  filter: blur(20px);

  ${tw`absolute bg-cover top-[-2rem] right-[-2rem] bottom-[-2rem] left-[-2rem]`}
`;

const overlayBgBlackCSS = css`
  ${tw`bg-[rgba(0, 0, 0, 0.5)] absolute top-0 right-0 bottom-0 left-0 pointer-events-none`}
`;
