/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { ReactComponent as ExpiredSVG } from 'assets/Icons/expired.svg';
import { ReactComponent as FileSVG } from 'assets/Icons/file.svg';
import { useHistory, Link } from 'react-router-dom';
import { Routes } from 'utils/routes';

export const ExpiredPage = () => {
  const history = useHistory();
  const handleReturn = () => {
    history.push(Routes.CREATED_REPORTS);
  };
  return (
    <div tw="w-screen height[calc(100vh - 7rem)] mt-[-2rem] flex items-center justify-center">
      <div tw="flex flex-col items-center space-y-7">
        <ExpiredSVG tw="zoom[0.35]" />

        <div tw="(font-size[2.2rem] mt-12 mb-3 font-medium text-sonnant-dark)!">
          The download link you followed has expired.
        </div>

        <button
          onClick={handleReturn}
          className="button btn-primary large"
          tw="(inline-flex items-center justify-center gap-2.5 w-[unset] pl-4 pr-5 max-w-[unset])!"
        >
          <FileSVG />
          <span>Back to Created Reports</span>
        </button>

        <Link to="/library" tw="text-15 hover:underline mt-7! flex gap-2">
          Return to Library page
        </Link>
      </div>
    </div>
  );
};
