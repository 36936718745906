import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import Store from './store/store';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Duration } from 'utils/constants';

const tagManagerArgs = {
  gtmId: 'GTM-TPLLPNC',
};

// replace console.* for disable log on production
if (process?.env?.REACT_APP_STAGE === 'prod') {
  console.log = () => {};
  console.debug = () => {};
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Duration.minutes(5),
      cacheTime: Duration.minutes(10),
      refetchOnWindowFocus: true,
    },
  },
});

TagManager.initialize(tagManagerArgs);
ReactDOM.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <Store>
      <Router>
        <App />
      </Router>
    </Store>

    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
