import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { defaultDemographics } from 'utils/default/defaultSetting';
import { Demographics, DemographicsFieldName } from 'utils/models';

export type UpdateDemographicsPayload = {
  fieldName: DemographicsFieldName;
  selectedValues: string[];
};

type DemographicsState = {
  items: Demographics;
};

const initialState: DemographicsState = {
  items: defaultDemographics,
};

export const demographicsSlice = createSlice({
  name: 'demographics',
  initialState,
  reducers: {
    resetDemographics: (state) => {
      state.items = defaultDemographics;
    },
    updatePartialDemographics: (
      state,
      { payload }: PayloadAction<UpdateDemographicsPayload>,
    ) => {
      state.items[payload.fieldName] = payload.selectedValues;
    },
  },
});

export const { resetDemographics, updatePartialDemographics } =
  demographicsSlice.actions;

export const demographicsReducer = demographicsSlice.reducer;
