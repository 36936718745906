/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import React, {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Modal } from 'components/UiControls/modal/modal';
import { ModalOptions } from 'utils/enum';
import { IabMediaDetailsModalPayload } from 'utils/models/modal.model';
import { toNumber } from 'lodash';
import { SortTable } from '../SortTable/SortTable';
import { msToTime } from 'components/VideoPlayer/Transcription/MediaUtilities';
// import { ReactComponent as WarningSvg } from 'assets/Icons/warning.svg';
import { textLink } from '../twin.styles';
import { Link } from 'react-router-dom';
import { Routes } from 'utils/routes';
import { ReactComponent as OpenNewTabSvg } from 'assets/Icons/open_in_new.svg';
import { InsightService } from 'services/insight.service';
import { useToggle } from 'react-use';
import Loader from 'components/loader/loader';
import { ILibraryItem } from 'utils/models';

type IPromiseParams = {
  resolve: (option: ModalOptions) => void;
  reject: (err: any) => void;
};

type Props = {};

export const IabMediaDetailsModal = forwardRef(
  (props: Props, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const [title, setTitle] = useState<string>();
    const [mediaItems, setMediaItems] = useState<ILibraryItem[]>([]);

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, toggleLoading] = useToggle(false);

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (
      props: IabMediaDetailsModalPayload,
    ): Promise<unknown> => {
      setTitle(props.name);

      getIabMediaInfoAsync(props.name, props.size);

      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const getIabMediaInfoAsync = async (
      categoryName: string,
      pageSize: number,
    ) => {
      try {
        toggleLoading(true);
        const { data } = await InsightService.getIabMediaDetailsAsync({
          categoryName,
          pageSize,
        });

        setMediaItems(data);
      } catch (error) {
        console.log('error :>> ', error);
      } finally {
        toggleLoading(false);
      }
    };

    const hideModal = () => {
      setMediaItems([]);

      setIsOpen(false);
    };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current?.resolve(ModalOptions.CANCEL);
    };

    return (
      <Modal
        show={isOpen}
        modalClosed={() => {
          hideModal();
          promiseInfo.current?.resolve(ModalOptions.CLOSE);
        }}
      >
        <div tw="relative min-h-[40rem]">
          <h3 tw="font-semibold text-sonnant-dark font-size[2.8rem] mb-3">
            {title}
          </h3>

          {isLoading && (
            <div
              className="loader__component"
              tw="absolute top[4rem] opacity-95"
            >
              <Loader />
            </div>
          )}

          <div
            className="listViewTable"
            css={[customCss]}
            tw="overflow-y-auto overflow-x-hidden maxHeight[calc(100vh - 25rem)] border[1px solid] border-sonnant-grey-1"
          >
            <SortTable
              columns={[
                {
                  Header: ' ',
                  accessor: 'thumbnail',
                  width: '5%',
                  styles: { minWidth: '10rem' },
                },
                { Header: 'TITLE', accessor: 'title' },
                { Header: 'DURATION', accessor: 'length', width: '15%' },
              ]}
              data={mediaItems}
            >
              {({ original: row }) => (
                <tr>
                  <td>
                    <img
                      tw="w-[5.5rem] min-h-[5.5rem] object-contain"
                      src={row.thumbnail}
                      alt=""
                    />
                  </td>
                  <td>
                    <div tw="flex items-center justify-between gap-x-3 pr-6">
                      <Link
                        to={`${Routes.TRANSCRIPTION}/${row.mediaid}`}
                        css={[textLink, tw`font-normal relative`]}
                        target="_blank"
                      >
                        <div
                          className="line-clamp-1"
                          tw="mr-[2.3rem] word-break[break-all]"
                        >
                          {row?.title}
                        </div>

                        <OpenNewTabSvg
                          tw="absolute bottom-[2px] right-0"
                          fill="grey"
                        />
                      </Link>
                      {/* {row.is_sensitive && (
                        <span>
                          <WarningSvg
                            onClick={(e) => e.stopPropagation()}
                            css={svgShadow(1.2)}
                          />
                        </span>
                      )} */}
                    </div>
                  </td>
                  <td>{msToTime(toNumber(row?.length))}</td>
                </tr>
              )}
            </SortTable>
          </div>
          <div tw="flex justify-end mt-4">
            <button
              type="button"
              className="button btn-secondary large"
              tw="hover:(border-sonnant-grey-3!)"
              onClick={handleCancel}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    );
  },
);

const customCss = css`
  table > thead > tr > th {
    ${tw`(py-[0.8rem])!`}
  }
`;
