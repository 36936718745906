import { PublicAPI } from 'apis/public-api';
import { AxiosResponse } from 'axios';
import { ApiKey } from 'utils/models';

export interface ApiKeyResponse {
  href: string;
  data: ApiKey[];
}

export interface APIKeyCreationResponse {
  data: ApiKey & { sub_domain: string; tenant_id: string };
}

export const ApiKeyService = {
  // GET /tenants/api-keys
  getApiKeys: (): Promise<AxiosResponse<ApiKeyResponse>> => {
    return PublicAPI.get('/api-keys');
  },

  // POST /tenants/api-keys/new
  createNewApiKey: (
    apiKeyName: string,
  ): Promise<AxiosResponse<APIKeyCreationResponse>> => {
    // const apiKeyName = `${getCurrentSubdomain()}-api-key-${getDateExport()}`;

    return PublicAPI.post('/api-keys/new', { name: apiKeyName });
  },

  // POST /tenants/api-keys/:api_key/revoke
  revokeApiKey: (apiKey: string): Promise<AxiosResponse<any>> => {
    return PublicAPI.post(`/api-keys/${apiKey}/revoke`, undefined);
  },
};
