import { BaseAPI } from 'apis/amazon';
import { NotificationFilter } from 'utils/enum';

export type NotiDeletePayload = {
  ids: string[];
  isClearAll?: boolean;
  filterType?: NotificationFilter[] | null;
  tenantId?: string;
  email?: string;
};

export const NotificationService = {
  // PUT /notification
  deleteMultipleIds: (payload: NotiDeletePayload) => {
    return BaseAPI.put('/notification', payload);
  },
};
