/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { isEqual, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import Select, {
  GroupBase,
  MenuPlacement,
  SingleValue,
  StylesConfig,
  ThemeConfig,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {
  CollectionType,
  DemographicsType,
  Dropdown,
  Keys,
  Layers,
} from 'utils/enum';

interface Props {
  options?: Option[];
  layerOptions?: LayerOption[];
  defaultValue?: null | string | Option[];
  noOptionsMessage?: string | null;
  onChange?: (newValue: any) => void;
  formatOptionLabel?: any;
  isSearchable?: boolean;
  disabled?: boolean;
  customStyles?: StylesConfig<Option, false, GroupBase<Option>>;
  tabIndex?: number;
  mode?: Dropdown;
  handleAddNew?: (value: string) => void;
  menuPlacement?: MenuPlacement | undefined;
  isTopicTag?: boolean;
  isMultiSelect?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  handleChangeSearchValue?: (value: string) => void;
  handleKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  formatCreateLabel?: (value: string) => string;
  onMenuClose?: () => void;
  [type: string]: any;
}

export type BaseOption = {
  label: string;
  value: string;
};

export type Option = BaseOption & {
  type?: CollectionType;
};

export type DemographicsOption = BaseOption & {
  type?: DemographicsType;
};

interface LayerOption {
  label: string;
  type: Layers;
}

export const CustomSelectSearch = ({
  options,
  noOptionsMessage = 'No results found',
  defaultValue,
  isSearchable = true,
  disabled = false,
  menuPlacement = 'auto',
  canCreate = true,
  isMultiSelect = false,
  isClearable,
  mode,
  handleAddNew,
  handleChangeSearchValue,
  handleKeyDown,
  onMenuClose,
  ...props
}: Props) => {
  const [inputSearch, setInputSearch] = useState('');
  const [selectValue, setSelectValue] = useState<any>(defaultValue);
  const [searchedOptions, setSearchedOptions] = useState<Option[]>();
  const [isOpen, toggleOpen] = useState<undefined | boolean>(undefined);

  useEffect(() => {
    // RSS Collection destination
    if (mode === Dropdown.COLLECTION) {
      if (!options) return;

      if (isMultiSelect) {
        setSelectValue(defaultValue || 'Select ...');

        return;
      }

      const collectionName = options.find(
        (option) => option?.value === defaultValue,
      )?.label;
      setSelectValue(collectionName || 'Select...');
      return;
      // Other destinations
    } else if (mode === Dropdown.TOPIC_CLIP) {
      if (isNil(defaultValue)) {
        handleOnChange(searchedOptions?.[0]?.label as any);
      } else {
        setSelectValue(defaultValue || 'Select ...');
      }
    } else if (mode === Dropdown.SIMPLE_TEXT) {
      // TODO something here, currently no
      setSelectValue(defaultValue || 'Select ...');
    } else {
      if (isNil(defaultValue)) {
        setSelectValue(searchedOptions?.[0]?.label as any);
      } else {
        setSelectValue(defaultValue || 'Select ...');
      }
    }
  }, [defaultValue, options]);

  useEffect(() => {
    setSearchedOptions(
      options?.filter((item: Option) =>
        String(item?.label)
          ?.toLowerCase()
          ?.includes(inputSearch?.toLowerCase()),
      ) ?? [],
    );
  }, [options, inputSearch]);

  const handleOnChange = (newValue: SingleValue<Option>) => {
    props?.onChange?.(newValue);
    setInputSearch('');
  };

  const handleInputChange = (value: string) => {
    setInputSearch(value);
    handleChangeSearchValue?.(value);
  };

  const onMenuOpen = () => {
    if (!isNil(isOpen)) {
      toggleOpen(undefined);
    }
  };

  const customFilterFn = (
    option: { label: string; value: string },
    input: string,
  ) => option?.label?.toLowerCase().includes(input.toLowerCase());

  return (
    <div css={[customSelectCss]}>
      {!canCreate ? (
        <Select
          onMenuOpen={onMenuOpen}
          menuIsOpen={isOpen}
          isLoading={props?.isLoading}
          menuPortalTarget={
            isEqual(mode, Dropdown.COLLECTION) ? document.body : undefined
          }
          menuShouldBlockScroll={true}
          escapeClearsValue={true}
          options={props.layerOptions || searchedOptions}
          styles={props?.customStyles ?? defaultStyles}
          theme={themeFn}
          noOptionsMessage={() => noOptionsMessage}
          onChange={handleOnChange}
          onInputChange={handleInputChange}
          menuPlacement={menuPlacement}
          isMulti={isMultiSelect as any}
          // closeMenuOnSelect
          tabIndex={props?.tabIndex}
          onKeyDown={(e) => {
            if (disabled) {
              e.preventDefault();
              return;
            }
            handleKeyDown?.(e);

            if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
              toggleOpen(false);
              setInputSearch('');
            }
          }}
          inputValue={inputSearch}
          value={selectValue as any}
          placeholder={selectValue}
          formatOptionLabel={props.formatOptionLabel}
          isSearchable={isSearchable}
          isClearable={isClearable}
          css={[
            props?.isTopicTag && tw`w-1/2`,
            isMultiSelect && tw`w-full h-full`,
          ]}
          filterOption={customFilterFn}
          onFocus={(e) => e.stopPropagation()}
          isDisabled={disabled}
          onMenuClose={onMenuClose}
        />
      ) : (
        <CreatableSelect
          // menuIsOpen
          isLoading={props?.isLoading}
          menuPosition={'fixed'}
          menuShouldBlockScroll={true}
          isDisabled={disabled}
          menuPortalTarget={
            isEqual(mode, Dropdown.COLLECTION) ? document.body : undefined
          }
          escapeClearsValue={true}
          options={props.layerOptions || searchedOptions}
          styles={props?.customStyles ?? defaultStyles}
          theme={themeFn}
          noOptionsMessage={() => noOptionsMessage}
          onChange={handleOnChange}
          onInputChange={handleInputChange}
          onCreateOption={(value) => {
            const isCreated = handleAddNew?.(value);

            if (!isCreated) return;

            setSelectValue(value);
          }}
          menuPlacement={menuPlacement}
          closeMenuOnSelect
          tabIndex={props?.tabIndex}
          isMulti={isMultiSelect as any}
          onKeyDown={(e) => {
            if (disabled) {
              e.preventDefault();
              return;
            }
            handleKeyDown?.(e);
          }}
          value={selectValue as any}
          placeholder={selectValue}
          formatOptionLabel={props.formatOptionLabel}
          isSearchable={isSearchable}
          css={[props?.isTopicTag && tw`w-1/2`]}
          formatCreateLabel={props.formatCreateLabel}
          filterOption={customFilterFn}
          isClearable={isClearable}
          onFocus={(e) => e.stopPropagation()}
        />
      )}
    </div>
  );
};

const themeFn: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#DEEBFF',
  },
});

export const defaultStyles: StylesConfig<Option, false, GroupBase<Option>> = {
  container: (base) => ({
    ...base,
    height: '4rem',
    width: '25rem',
    zIndex: 20,
  }),
  control: (base) => ({
    ...base,
    height: '4rem',
    padding: 0,
    backgroundColor: '#f0f3f6',
    borderColor: 'transparent',
  }),
  menu: (base) => ({
    ...base,
    marginBottom: '4px',
  }),
  menuList: (base) => ({
    ...base,
    overflowX: 'hidden',
  }),
  input: (base) => ({
    ...base,
    height: '3rem',
    paddingTop: '3px',
    position: 'relative',
    top: '-8px',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    paddingLeft: '0.8rem',
  }),
  placeholder: (base) => ({
    ...base,
    color: 'rgb(51, 51, 51)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
  option: (base) => ({
    ...base,
    cursor: 'pointer',
    color: 'rgb(51, 51, 51)',
    // backgroundColor: '#DEEBFF'
  }),
};

// Be careful to add global styles HERE - Should keep it unchanged
// It may take effect for the RatioPicker component
const customSelectCss = css`
  z-index: 20 !important;
  input {
    ${tw`(border-none bg-transparent p-0)!`}
  }
`;
