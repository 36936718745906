/** @jsxImportSource @emotion/react */
import 'twin.macro';

type Props = {
  header: string;
};

export const PortalHeading = ({ header }: Props) => {
  return <div tw="my-3.5 text-sonnant-grey-6 font-semibold">{header}</div>;
};
