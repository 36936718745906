/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'foundation-sites';
import { usePaymentMessage } from 'hooks/usePaymentMessage';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tierSelector } from 'slices/payment.slice';
import 'twin.macro';
import * as actionTypes from '../../../actions/types';
import { getTenantidFromIdToken, getUserRole } from '../../../utils/utils';
import '../AccountNavbar/AccountNavbar.scss';
import { Billing } from '../Billing/Billing';
import { TenantProfile } from '../TenantProfile/TenantProfile';
import { UsageReporting } from '../UsageReporting/UsageReporting';
import { User } from '../User/User';
import { UserProfile } from '../UserProfile/UserProfile';

export const AccountNavbar = () => {
  const [isAdmin, setIsAdmin] = useState(true);
  const { isInternal } = useSelector(tierSelector);

  const dispatch = useDispatch();

  const canShowUsageReporting = [
    'lypebebns', // demo - dev
    'lq2iq79ut', // ptn - dev,
    '6c8rgfura', // demo - stage
    '3kxtmm5yl', // ptn - stage,
    'i4rnjg3fa', // demo - prod,
    'hzlukuesa', // ptn - prod,
    'yfcewwlau', // devtestvidhu1
    'jshjo0u7x', // yairtest - prod
  ].includes(getTenantidFromIdToken());

  usePaymentMessage();

  useEffect(() => {
    setIsAdmin(getUserRole() === 'Administrator' ? true : false);
    $(document).foundation();
    dispatch({ type: actionTypes.HANDLE_ALERT_CLOSE });
  }, []);

  return (
    <div className="useraccountsettings">
      <div className="tab_section">
        <ul
          className="tabs"
          data-tabs
          id="collapsing-tabs"
          data-deep-link="true"
          data-update-history="true"
          data-deep-link-smudge="true"
          data-deep-link-smudge-delay="500"
        >
          {isAdmin && (
            <li className="tabs-title is-active">
              <a href="#account-profile">Account Profile</a>
            </li>
          )}
          <li className={`tabs-title ${!isAdmin ? 'is-active' : ''}`}>
            <a href="#user-profile">User Profile</a>
          </li>
          {isAdmin && (
            <li className="tabs-title" aria-selected="true">
              <a href="#users-management">Users</a>
            </li>
          )}
          {isAdmin && canShowUsageReporting && (
            <li className="tabs-title" aria-selected="true">
              <a href="#usage-reporting">Usage Reporting</a>
            </li>
          )}
          {isAdmin && !isInternal && (
            <li className="tabs-title">
              <a href="#billing">Billing</a>
            </li>
          )}
        </ul>
      </div>
      <div
        tw="border-none"
        className="tabs-content"
        data-tabs-content="collapsing-tabs"
      >
        <div
          className={`tabs-panel ${isAdmin ? 'is-active' : ''}`}
          id="account-profile"
          tw="p-0"
        >
          <TenantProfile />
        </div>
        <div
          className={`tabs-panel ${!isAdmin ? 'is-active' : ''}`}
          id="user-profile"
          tw="p-0"
        >
          <UserProfile />
        </div>
        <div className="tabs-panel" id="users-management" tw="p-0">
          <User />
        </div>
        {canShowUsageReporting && (
          <div className="tabs-panel" id="usage-reporting" tw="p-0">
            <UsageReporting />
          </div>
        )}
        <div className="tabs-panel" id="billing" tw="p-0">
          <Billing />
        </div>
      </div>
    </div>
  );
};
