/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as ErrorServer } from 'assets/Icons/computer.svg';
import { ReactComponent as NoResult } from 'assets/Icons/404_error.svg';
import { ReactComponent as HomeSvg } from 'assets/Icons/home.svg';
import { ReactComponent as RestrictedSvg } from 'assets/Icons/restricted-icon.svg';

import { defaultTo } from 'lodash';
import { match } from 'ts-pattern';
import { HTTPStatus } from 'utils/enum';

type Props = {
  onClickReturn: () => void;

  statusCode?: HTTPStatus;
  hiddenMsg?: boolean;
  errorMsg?: string;
  returnButtonText?: string;
};

export const MediaError = ({
  onClickReturn,
  errorMsg,
  statusCode = HTTPStatus.SERVER_ERROR,
  hiddenMsg = false,
  returnButtonText = 'Back to library',
}: Props) => {
  return (
    <div tw="w-screen height[calc(100vh - 13rem)] mt-[-2rem] flex items-center justify-center">
      <div tw="text-center space-y-7">
        {match(statusCode)
          .with(HTTPStatus.NOT_FOUND, () => <NoResult tw="zoom[0.6]" />)
          .with(HTTPStatus.RESTRICTED, () => <RestrictedSvg tw="zoom[0.4]" />)
          .otherwise(() => (
            <ErrorServer tw="zoom[0.6]" />
          ))}

        {!hiddenMsg && (
          <div tw="(font-size[2.2rem] mt-[3rem] mb-[1.5rem] font-medium text-sonnant-dark)!">
            {statusCode === HTTPStatus.NOT_FOUND
              ? 'Content item does not exist or has been deleted'
              : defaultTo(errorMsg, 'Oops! Something went wrong')}
          </div>
        )}

        <button
          onClick={onClickReturn}
          className="button btn-primary large"
          tw="(w-[unset] pl-[8px] pr-[10px] block mx-auto)!"
        >
          <span tw="flex gap-[5px]">
            <span>
              <HomeSvg />
            </span>
            <span>{returnButtonText}</span>
          </span>
        </button>
      </div>
    </div>
  );
};
