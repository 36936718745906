/** @jsxImportSource @emotion/react */
import 'twin.macro';

import React from 'react';
import { useSelector } from 'react-redux';
import {
  AlertBlock,
  inValidErrorMsg,
  noMatchErrorMsg,
  PasswordMatch,
  requiredErrorMsg,
  validatePasswordField,
  ValidityCheck,
} from '../../../utils/utils';
import Loader from '../../loader/loader';
import { RootState } from 'reducers';
import { Notification } from '../../UiControls/notification/Notification';
import './UserProfile.scss';

interface IProps {
  modalClosed: (e: React.MouseEvent) => void;
  data: IData;
  handlePasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleNotificationClose: (name?: string) => void;
  handleClose: (name?: string) => void;
  error: IError;
  handlePasswordUpdate: (e: React.MouseEvent) => void;
}

interface IData {
  oldpassword: string;
  newpassword: string;
  confirmpassword: string;
}

interface IError {
  oldpassword: boolean;
  newpassword: boolean;
  confirmpassword: boolean;
  isvalid: boolean;
  ismatch: boolean;
}

export const UpdatePassword = ({
  modalClosed,
  data,
  handlePasswordChange,
  handleNotificationClose,
  handleClose,
  error,
  handlePasswordUpdate,
}: IProps) => {
  const displayAlert = useSelector((state: RootState) => state.alerts);
  const updatedPasswordData = useSelector(
    (state: RootState) => state.updatePassword,
  );
  const userProfile = useSelector((state: RootState) => state.userProfile);
  const { statusMessage, isSuccess } = displayAlert;

  return (
    <div className="userModal_Popus" id="updatePassword">
      {updatedPasswordData.loading && (
        <div className="loader__component">
          <Loader />
        </div>
      )}
      <h1 tw="mb-0!">Update password</h1>

      {statusMessage && (
        <div tw="my-6">
          <AlertBlock
            isSuccess={isSuccess}
            statusMessage={statusMessage}
            handleClose={handleClose}
          />
        </div>
      )}

      <div className="grid-x">
        {userProfile?.email && (
          <div className="cell grid-padding-x small-12">
            <label>
              Email Address (Username)
              <input
                type="text"
                placeholder="Username"
                name="username"
                value={userProfile?.email}
                readOnly
              />
            </label>
          </div>
        )}
        <div className="cell grid-padding-x small-12">
          <label>
            Current Password
            <input
              type="password"
              placeholder="Current Password"
              name="oldpassword"
              value={data.oldpassword}
              onChange={handlePasswordChange}
            />
            <Notification
              name={'oldpassword'}
              close={handleNotificationClose}
              type={!error.oldpassword ? 'error' : null}
              message={requiredErrorMsg('Current Password')}
            />
          </label>
        </div>
        <div className="cell grid-padding-x small-12">
          <label>
            New Password
            <input
              type="password"
              placeholder="New Password"
              name="newpassword"
              value={data.newpassword}
              onChange={handlePasswordChange}
            />
            <Notification
              name={'newpassword'}
              close={handleNotificationClose}
              type={!error.newpassword || !error.isvalid ? 'error' : null}
              message={
                !error.newpassword
                  ? requiredErrorMsg('New Password')
                  : inValidErrorMsg
              }
            />
          </label>
        </div>
        <div className="cell grid-padding-x small-12">
          <label>
            Confirm New Password
            <input
              type="password"
              placeholder="Confirm New Password"
              name="confirmpassword"
              value={data.confirmpassword}
              onChange={handlePasswordChange}
            />
            <Notification
              name={'confirmpassword'}
              close={handleNotificationClose}
              type={!error.ismatch || !error.confirmpassword ? 'error' : null}
              message={
                !error.ismatch
                  ? noMatchErrorMsg
                  : requiredErrorMsg('Confirm New Password')
              }
            />
          </label>
        </div>
      </div>
      <div className="grid-x uploadpw">
        <div className="cell small-12">
          <div className="btn_section">
            <button
              type="button"
              className="button cancel"
              onClick={modalClosed}
            >
              Cancel
            </button>
            <button
              type="button"
              className="button updatepassword"
              disabled={
                !error.isvalid ||
                validatePasswordField(data.newpassword) ||
                ValidityCheck(
                  data.oldpassword,
                  data.newpassword,
                  data.confirmpassword,
                ) ||
                PasswordMatch(data.newpassword, data.confirmpassword)
              }
              onClick={handlePasswordUpdate}
            >
              Update Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
