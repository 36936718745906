import { SagaAction } from 'utils/models';
import * as types from '../actions/types';

interface InitialStateType {
  loading: boolean;
  companyName: string;
  description: string;
  domainName: string;
  image: null;
}

const initialState = {
  loading: false,
  companyName: '',
  description: '',
  domainName: '',
  image: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export const tenantReducer = (
  state: InitialStateType = initialState,
  action: SagaAction,
) => {
  switch (action.type) {
    case types.GET_TENANT_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        companyName: action.data.users?.CompanyName,
        description: action.data.users?.Description,
        domainName: action.data.users?.DomainName,
        image: action.data.users?.Image,
      };
    }
    case types.GET_TENANT_DATA_FAILED: {
      return {
        ...state,
        loading: false,
        companyName: '',
        description: '',
        domainName: '',
      };
    }
    default:
      return state;
  }
};
