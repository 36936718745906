/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import React, {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Modal } from 'components/UiControls/modal/modal';
import { Keys, ModalOptions } from 'utils/enum';
import { isEmpty, isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { toDateWithoutHours } from 'utils/date.util';
import { customToast } from 'utils/toast.util';

interface IPromiseParams {
  resolve: (payload: PinnedChartModalPayload) => void;
  reject: (err: any) => void;
}

interface PinnedChartModalPayload {
  option: ModalOptions;
  chartName?: string;
}

export interface PinnedChartCreationRef {
  show: () => Required<PinnedChartModalPayload>;
}

interface Props {}

export const PinnedChartCreationModal = forwardRef(
  (props: Props, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const insight = useSelector((state: RootState) => state.insight);

    const [isOpen, setIsOpen] = useState(false);

    const [chartName, setChartName] = useState('');

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (): Promise<unknown> => {
      const title = getPinnedChartTitle();
      setChartName(title);

      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const getPinnedChartTitle = (): string => {
      const chartNo = insight.pinnedCharts.length + 1;
      const { startDate, endDate } = insight.dateRange;

      const from = toDateWithoutHours(startDate as Date);
      const to = isNil(endDate) ? 'Today' : toDateWithoutHours(endDate as Date);

      const timePeriod = isNil(startDate) ? '[Library]' : `[${from} - ${to}]`;

      return `Pinned Mentions Analytics Chart - ${chartNo} ${timePeriod}`;
    };

    const hideModal = () => {
      setChartName('');
      setIsOpen(false);
    };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current?.resolve({ option: ModalOptions.CANCEL });
    };

    const handleYes = () => {
      if (isEmpty(chartName?.trim())) {
        customToast.warning('Chart title cannot be blank');
        return;
      }

      hideModal();
      promiseInfo.current?.resolve({
        option: ModalOptions.YES,
        chartName: chartName,
      });
    };

    return (
      <div css={[customModalCss]}>
        <Modal
          show={isOpen}
          modalClosed={() => {
            hideModal();
            promiseInfo.current?.resolve({ option: ModalOptions.CLOSE });
          }}
        >
          <div>
            <h2 tw="font-semibold text-sonnant-dark">Pin this chart</h2>

            <div tw="mt-4">
              <label>Name</label>
              <input
                type="text"
                placeholder="Give this chart a name..."
                value={chartName}
                onChange={(e) => setChartName(e.target.value)}
                onKeyDown={(e) => {
                  e.key === Keys.ENTER && handleYes();
                }}
                autoFocus
                maxLength={128}
              />
            </div>

            <div tw="mt-9">
              <div tw="w-full flex justify-end">
                <button
                  type="button"
                  className="button btn-secondary large"
                  tw="mr-6 hover:(border-sonnant-grey-3!)"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="button btn-primary large"
                  disabled={isEmpty(chartName?.trim())}
                  onClick={handleYes}
                >
                  Pin chart
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  },
);

const customModalCss = css`
  .Modal {
    ${tw`(width[52rem] left[calc(50% - 26rem)])!`}
  }
`;
