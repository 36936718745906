/** @jsxImportSource @emotion/react */
import 'twin.macro';

/* eslint-disable react-hooks/exhaustive-deps */
import { Change, diffWords } from 'diff';
import React, { useEffect, useRef } from 'react';
import './Summary.scss';
import { useState } from 'react';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { ModalOptions } from 'utils/enum';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setSummary } from 'slices/player.slice';
import { isEmpty } from 'lodash';
import { isPublicShared } from '../../MediaUtilities';
import { useUpdate } from 'react-use';
import { removeHTMLTag } from 'utils/utils';
interface IProps {
  baseMediaid: string;
}

export const SummaryEditor = ({ baseMediaid }: IProps) => {
  const { summary, origSummary, mediaid } = useSelector(
    (state: RootState) => state.player,
  );
  const toggle = useSelector((state: RootState) => state.toggle);

  const textRef = useRef(origSummary ?? '');
  const confirmModalRef = useRef<any>(null);
  const dispatch = useDispatch();

  const [firstTouch, setFirstTouch] = useState(true);

  useEffect(() => {
    textRef.current = origSummary ?? '';
  }, [origSummary]);

  const update = useUpdate();
  useEffect(() => {
    // Fallback if server return array of text
    if (summary && mediaid === baseMediaid) {
      textRef.current = summary;
    }
    if (isPublicShared()) {
      textRef.current = removeHTMLTag(summary ?? '');
      update();
    }
  }, [summary]);

  const handleInput = async (e: React.FormEvent<HTMLDivElement>) => {
    const currentTarget = e.currentTarget;

    if (firstTouch) {
      const result = await confirmModalRef?.current?.show();
      if (result === ModalOptions.YES) {
        updateInput(currentTarget?.innerHTML);
        setFirstTouch(false);
      } else {
        currentTarget.innerHTML = origSummary ?? '';
      }
    } else {
      updateInput(currentTarget?.innerHTML);
    }
  };

  const updateInput = (textContent: string | null) => {
    textRef.current = textContent ?? '';

    if (!toggle.isShowHumanEdit) return;

    let wordsPartials: Change[] = [];

    if (!isEmpty(origSummary?.trim())) {
      wordsPartials = diffWords(origSummary!, textRef?.current ?? '');
    } else {
      return;
    }
    let text = '';
    wordsPartials.forEach((part: Change) => {
      if (part?.added) {
        // if (part.value.length < removedText.length) {
        // remove text
        text += '<mark class="humanEdit">' + part.value + '</mark>';
        return;
      } else {
        if (!part.removed) {
          text += part.value;
        }
      }
    });
    textRef.current = text;
  };

  const handleOnMouseLeave = () => {
    dispatch(
      setSummary({ summary: textRef.current ?? '', mediaid: baseMediaid }),
    );
  };

  const pasteSummary = (e: any) => {
    e.preventDefault();
    let text = (e.originalEvent || e).clipboardData.getData('text/plain');

    // insert text manually
    document.execCommand('insertHTML', false, text);
  };

  return (
    <>
      <div id="summarisation-container">
        <div className="summary-header" tw="sm-down:(m-2)!">
          Summary
        </div>
        <div className="editTextBg">
          <div
            className={`summary-text editInpt ${
              !toggle?.isShowHumanEdit && 'no-human-edit'
            }`}
            tw="overflow-wrap[break-word] font-family['Roboto'] text-left"
            contentEditable={toggle.isEditMode}
            dangerouslySetInnerHTML={{ __html: textRef.current ?? '' }}
            onInput={handleInput}
            onMouseLeave={handleOnMouseLeave}
            placeholder="Transcription summary..."
            onPaste={pasteSummary}
          ></div>
        </div>
      </div>

      <ConfirmModal
        message="Do you want to change default summary"
        title="Confirm"
        ref={confirmModalRef}
      />
    </>
  );
};
