/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import React, { MouseEvent, useEffect } from 'react';

import { ReactComponent as AppleSvg } from 'assets/Icons/apple-filled.svg';
import { ReactComponent as FacebookSvg } from 'assets/Icons/facebook-filled.svg';
import { ReactComponent as GoogleSvg } from 'assets/Icons/google-filled.svg';
import { loadFacebookSdkScript } from 'utils/scripts/loadFacebookAuthScript';
import { loadAppleJsScript } from 'utils/scripts/loadAppleAuthScript';
import { loadGoogleScriptV1 } from 'utils/scripts/loadGoogleScriptV1';
import { hoverDropShadow } from '../twin.styles';
import { Hint } from '../Hint';
import { isSignupPage } from 'components/VideoPlayer/Transcription/MediaUtilities';

const style = {
  iconStyle: () => tw`h-16 w-16`,
};

type Props = {
  handleLoginGoogleButton: (e: MouseEvent<HTMLDivElement>) => unknown;
  handleLoginAppleButton: (e: MouseEvent<HTMLDivElement>) => unknown;
  handleLoginFacebookButton: (e: MouseEvent<HTMLDivElement>) => unknown;
};

export const AuthSocialButtonV2 = ({
  handleLoginGoogleButton,
  handleLoginAppleButton,
  handleLoginFacebookButton,
}: Props) => {
  const hintText = isSignupPage() ? 'Sign up' : 'Sign in';

  useEffect(() => {
    loadFacebookSdkScript();
    loadAppleJsScript();
    loadGoogleScriptV1();
  }, []);

  return (
    <div tw="flex items-center space-x-11">
      <Hint text={`${hintText} with Google`} enterDelay={200}>
        <div tw="flex items-center cursor-pointer" onClick={handleLoginGoogleButton}>
          <GoogleSvg css={[style.iconStyle, hoverDropShadow(0.2)]} />
        </div>
      </Hint>

      <Hint text={`${hintText} with Apple`} enterDelay={200}>
        <div tw="flex items-center cursor-pointer" onClick={handleLoginAppleButton}>
          <AppleSvg css={[style.iconStyle, hoverDropShadow(0.2)]} />
        </div>
      </Hint>

      <Hint text={`${hintText} with Facebook`} enterDelay={200}>
        <div tw="flex items-center cursor-pointer" onClick={handleLoginFacebookButton}>
          <FacebookSvg css={[style.iconStyle, hoverDropShadow(0.3)]} />
        </div>
      </Hint>
    </div>
  );
};
