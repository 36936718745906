/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { ReactComponent as PreviewSvg } from 'assets/Icons/find_white.svg';
import Axios, { AxiosError, CancelTokenSource } from 'axios';
import {
  addTaglineToTrendingTermSearchInsight,
  processAliasDisplayTerms,
  toAliasTerms,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import Loader from 'components/loader/loader';
import { InsightAdvancedSelectionMenu } from 'components/shared/AdvancedSelectionMenu/InsightAdvancedSelectionMenu';
import { CollectionDropdown } from 'components/shared/CollectionDropdown/CollectionDropdown';
import { InsightEditTermsModal } from 'components/shared/ContentExplorationChartsModal/InsightEditTermsModal';
import { DatePickerSingleInput } from 'components/shared/DatePicker/DatePickerSingleInput';
import { InsightChart } from 'components/shared/InsightChart/InsightChart';
import { NoFilterInsights } from 'components/shared/NoFilterInsights';
import { NoResultInsights } from 'components/shared/NoResultInsights';
import { QuestionHint } from 'components/shared/QuestionHint';
import { customDateRangInputStyle } from 'components/shared/twin.styles';
import { subDays, subMonths, subWeeks } from 'date-fns';
import { defaultTo, first, isEmpty, isNil, pick, uniq, uniqBy } from 'lodash';
import { ReactElement, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce, useLatest, useToggle } from 'react-use';
import { RootState } from 'reducers';
import {
  TrendingTermSearchInsight,
  TrendingTermSearchInsightResponse,
  UserService,
} from 'services';
import { ExportAliasTerm, InsightService } from 'services/insight.service';
import { setAliasTerms } from 'slices/alias-term.slice';
import {
  setDateRange,
  setSelectedCollectionIds,
} from 'slices/trending-insight.slice';
import { startOfDayISO } from 'utils/date.util';
import { CalendarFilter, HTTPStatus, Layers } from 'utils/enum';
import { AliasTerm } from 'utils/models';
import { TermEditModalRef } from 'utils/models/modal.model';
import { customToast } from 'utils/toast.util';
import { getTenantidFromIdToken } from 'utils/utils';
import { TrendingTermList } from './shared/TrendingTermList/TrendingTermList';

export const TrendingCharts = () => {
  const dispatch = useDispatch();

  const aliasTerm = useSelector((state: RootState) => state.aliasTerm);
  const latestAliasTerm = useLatest(aliasTerm);

  const insightEditTermsModalRef = useRef<TermEditModalRef>(null);

  const insightTrending = useSelector(
    (state: RootState) => state.insightTrending,
  );

  const [isFetching, toggleFetching] = useToggle(false);
  const [chartData, setChartData] = useState<TrendingTermSearchInsight[]>([]);
  const [isEmptyBuckets, toggleEmptyBuckets] = useToggle(false);
  const [isNoResultsFound, toggleNoResultsFound] = useToggle(false);

  const [selectedTrendingTerm, setSelectedTrendingTerm] =
    useState<TrendingTermSearchInsight | null>(null);
  const [termList, setTermList] = useState<ExportAliasTerm[]>([]);
  const [tempAliasTerms, setTempAliasTerms] = useState<AliasTerm[]>(
    aliasTerm.aliasTerms,
  );

  const isGenerateDisabled =
    !insightTrending.calendarMode ||
    !insightTrending.dateRange.startDate ||
    isFetching;

  const cancelTokenRef = useRef<CancelTokenSource>();

  const hasDataInsight = !isEmpty(chartData) && !isEmptyBuckets;

  const isNoRequiredInputs =
    isNil(insightTrending.dateRange.dateSelected) ||
    isNil(insightTrending.calendarMode);

  const chartTrendingData: TrendingTermSearchInsight[] = useMemo(() => {
    if (!selectedTrendingTerm) return [];

    const filteredChartData = chartData.filter(
      (item) => item.term === selectedTrendingTerm?.term && item.is_enabled,
    );

    return filteredChartData;
  }, [selectedTrendingTerm, chartData]);

  useEffectOnce(() => {
    handleGenerateAsync();
  });

  const handleChangeCollection = (collectionIds: string[]) => {
    dispatch(setSelectedCollectionIds(collectionIds));
  };

  const handleGetTrendingTermsAsync = async () => {
    if (!isNil(cancelTokenRef.current)) {
      cancelTokenRef.current.cancel();
    }

    if (isNil(insightTrending.calendarMode)) return;

    try {
      // To avoid multiple long request at the same time
      const cancelToken = Axios.CancelToken.source();
      cancelTokenRef.current = cancelToken;

      toggleFetching(true);
      const { data } = await InsightService.getTrendingTermsAsync(
        {
          fromDate: startOfDayISO(insightTrending.dateRange.startDate),
          toDate: startOfDayISO(insightTrending.dateRange.endDate),
          collectionIds: insightTrending.selectedCollectionIds,
          calendarMode: insightTrending.calendarMode,
        },
        cancelToken,
      );
      cancelTokenRef.current = undefined;

      return Promise.resolve(data);
    } catch (err) {
      console.log('err :>> ', err);
      toggleFetching(false);

      toggleNoResultsFound(true);

      return Promise.reject(err);
    }
  };

  const handleGenerateAsync = async () => {
    if (!insightTrending.calendarMode || !insightTrending.dateRange.startDate) {
      return;
    }

    try {
      toggleFetching(true);

      const trendingTermsData = await handleGetTrendingTermsAsync();

      const queryTerms = uniq(trendingTermsData).map(({ term }) => term);

      if (isEmpty(queryTerms)) {
        toggleFetching(false);
        setChartData([]);
        return;
      }

      const { data } = await InsightService.postTrendingInsightsAsync<
        TrendingTermSearchInsightResponse[]
      >({
        category: 'keywords',
        terms: queryTerms,
        fromDate: startOfDayISO(
          getDateBefore(insightTrending.dateRange.startDate),
        ),
        toDate: startOfDayISO(insightTrending.dateRange.endDate),
        calendarInterval: insightTrending.calendarMode,
        collectionIds: insightTrending.selectedCollectionIds,
      });

      const addedTaglineData: TrendingTermSearchInsight[] =
        addTaglineToTrendingTermSearchInsight(data, trendingTermsData ?? []);

      const isEmptyBuckets = addedTaglineData?.every((item) =>
        isEmpty(item?.term_count_over_time.buckets),
      );
      toggleEmptyBuckets(isEmptyBuckets);

      toggleNoResultsFound(isEmptyBuckets);

      const noneEmptyBuckets = addedTaglineData.filter(
        (series) => !isEmpty(series?.term_count_over_time?.buckets),
      );

      const firstEnabledTerm = first(
        noneEmptyBuckets.filter((term) => term.is_enabled),
      );

      setSelectedTrendingTerm(defaultTo(firstEnabledTerm, null));

      setChartData(noneEmptyBuckets);

      processAliasTerms(noneEmptyBuckets);
    } catch (err) {
      console.log('err :>> ', err);

      const statusCode = (err as AxiosError).response?.status;

      if (statusCode === HTTPStatus.TIMEOUT) {
        customToast.error(
          'Request reached timeout. Please choose another time range and try again.',
        );
      }

      setChartData([]);

      toggleNoResultsFound(true);
    } finally {
      toggleFetching(false);
    }
  };

  const getDateBefore = (date: Date | null) => {
    if (!date) return undefined;

    switch (insightTrending.calendarMode) {
      case CalendarFilter.DAY:
        return subDays(new Date(date), 30);
      case CalendarFilter.WEEK:
        return subWeeks(new Date(date), 4);
      case CalendarFilter.MONTH:
        return subMonths(new Date(date), 3);
      default:
        return undefined;
    }
  };

  const handleDateChange = (date: Date | null) => {
    dispatch(setDateRange({ dateSelected: date }));
  };

  const handleChangedTrendingTerm = (term: TrendingTermSearchInsight) => {
    setSelectedTrendingTerm(term);
  };

  const handleClickEdit = async () => {
    if (!insightEditTermsModalRef.current) return;

    const result = await insightEditTermsModalRef.current.show({});

    if (result.shouldRefresh) {
      handleGenerateAsync();
    }
  };

  const handleSaveEditedTerms = async (aliasEditedTerms: AliasTerm[]) => {
    const uniqAliasTerms: AliasTerm[] = uniqBy(
      [...aliasEditedTerms, ...tempAliasTerms],
      'original',
    ).filter(({ isEnabled, alias }) => !(isEnabled && isNil(alias)));

    const tenantid = getTenantidFromIdToken();

    const saveAsync = UserService.savePreference({
      tenantid,
      setting_alias_terms: uniqAliasTerms
        ? JSON.stringify(uniqAliasTerms)
        : undefined,
    });

    customToast.promise(saveAsync, {
      loading: 'Saving changes...',
      success: 'Saved successfully',
      error: 'Save failed',
    });

    try {
      await saveAsync;

      dispatch(setAliasTerms(uniqAliasTerms));
    } catch (error: any) {
      console.log('error :>> ', error);
    }
  };

  const processAliasTerms = (
    trendingTermSearchInsights: TrendingTermSearchInsight[],
  ) => {
    const apiTermList = toAliasTerms(trendingTermSearchInsights);

    setTermList(apiTermList);

    const processedTerms = processAliasDisplayTerms(apiTermList);

    const disabledTerms: AliasTerm[] = processedTerms
      .filter(({ isEnabled, alias }) => !(isEnabled && isNil(alias)))
      .map((term) => pick(term, ['original', 'alias', 'isEnabled']));

    const combinedTempAliasTerms = uniqBy(
      disabledTerms.concat(latestAliasTerm.current.aliasTerms),
      'original',
    );

    setTempAliasTerms(combinedTempAliasTerms);
  };

  const renderTrendingChart = (): ReactElement => {
    if (isFetching) {
      return (
        <div tw="min-h-[40rem] flex justify-center my-3 items-center shadow rounded">
          <Loader />
        </div>
      );
    }

    if (hasDataInsight) {
      return (
        <div tw="flex flex-row gap-x-5 gap-y-6 lg-down:flex-col">
          <div tw="flex w-full h-full">
            <TrendingTermList
              selectedTerm={selectedTrendingTerm}
              trendingTerms={chartData}
              onChangeTrendingTerm={handleChangedTrendingTerm}
              onClickEditTerm={handleClickEdit}
            />
          </div>

          <div tw="w-full px-10 min-h-[40rem] pt-6 shadow rounded border border-solid border-sonnant-grey-1 relative z-0">
            <InsightChart
              insightOptions={insightTrending}
              height={500}
              chartData={chartTrendingData}
              hiddenPinner
              isTrendingChart
            />
          </div>
        </div>
      );
    }

    if (isNoRequiredInputs) {
      return (
        <div tw="min-h-[40rem] flex items-center shadow border[1px solid] border-sonnant-grey-2">
          <NoFilterInsights
            title="Input required"
            message="Please select a date range to populate the chart."
          />
        </div>
      );
    }

    if (isNoResultsFound) {
      return (
        <div tw="min-h-[40rem] flex items-center shadow border[1px solid] border-sonnant-grey-2">
          <NoResultInsights message="No data found for the requested period, please modify your filters or date range and try again." />
        </div>
      );
    }

    return (
      <div tw="min-h-[40rem] flex items-center shadow border[1px solid] border-sonnant-grey-2">
        <NoFilterInsights
          title="Input required"
          message="Please select a date range to populate the chart."
        />
      </div>
    );
  };

  return (
    <div tw="w-full flex flex-wrap px-3 mt-8">
      <div tw="text-20 font-semibold">Trending</div>

      <div tw="w-full mt-3 rounded border[1px solid] border-sonnant-grey-light shadow px-5 pb-5">
        <div tw="py-6 flex flex-wrap gap-4 relative z-index[1]">
          <div tw="flex flex-wrap flex-1 gap-x-10 gap-y-3 justify-between">
            <div tw="flex flex-wrap flex-1 gap-x-6 gap-y-3 items-end">
              <div tw="min-w-[20rem]">
                <label tw="flex items-center gap-x-3">
                  <span tw="text-15">Collection</span>
                  <span tw="flex [svg]:(h-[1.8rem] w-[1.8rem])">
                    <QuestionHint
                      type="information"
                      align="bottomLeft"
                      action="hover"
                      customStyle={customTooltipCss}
                      hideCloseButton
                      text={
                        <div tw="line-height[1.5]">
                          Collection refers to the folder for podcasts or shows
                        </div>
                      }
                    />
                  </span>
                </label>
                <CollectionDropdown
                  selectedCollections={insightTrending.selectedCollectionIds}
                  onMultiSelectChange={handleChangeCollection}
                  isMultiSelect
                  canCreate={false}
                />
              </div>

              <div css={[customDateRangInputStyle]}>
                <label>
                  <span tw="text-15">Date</span>
                </label>
                <div tw="flex items-center space-x-10 relative z-[1]">
                  <DatePickerSingleInput
                    dateSelected={
                      insightTrending.dateRange?.dateSelected ?? null
                    }
                    onDateChange={handleDateChange}
                  />
                </div>
              </div>

              <InsightAdvancedSelectionMenu />

              <div tw="flex items-end">
                <button
                  className="button large btn-primary"
                  onClick={handleGenerateAsync}
                  tw="flex justify-center items-center height[4rem]! shadow m-0"
                  disabled={isGenerateDisabled}
                >
                  <span tw="flex">
                    <PreviewSvg tw="mr-3" />
                  </span>
                  Generate
                </button>
              </div>
            </div>
          </div>
        </div>

        {renderTrendingChart()}
      </div>

      <InsightEditTermsModal
        isTrendingInsight
        ref={insightEditTermsModalRef}
        isLoading={isFetching}
        currentLayer={Layers.KEY_TERM}
        onLayerChange={() => {}}
        termList={termList}
        onSaveEditedTerms={handleSaveEditedTerms}
      />
    </div>
  );
};

const customTooltipCss = css`
  ${tw`font-size[1rem]`}

  .notification {
    ${tw`lg-up:width[40rem]! md-down:(width[20rem] break-words)`}
  }
  .notificationWrapper {
    ${tw`margin-top[1rem]!`}
  }

  svg {
    ${tw`(height[1.5rem] width[1.5rem])!`}
  }
`;
