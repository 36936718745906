/** @jsxImportSource @emotion/react */
import 'twin.macro';

import {
  flexRender,
  getCoreRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import {
  elementScroll,
  useVirtualizer,
  VirtualizerOptions,
} from '@tanstack/react-virtual';
import { ReactComponent as AddSvg } from 'assets/Icons/add_circle.svg';
import { ReactComponent as MinusSvg } from 'assets/Icons/minus_circle.svg';
import Loader from 'components/loader/loader';
import { Hint } from 'components/shared/Hint';
import { HintDisallowed } from 'components/shared/HintDisallowed';
import { Info } from 'components/shared/Info';
import { AdMarkerModal } from 'components/shared/modals/AdMarkerModal';
import { TimeCodeChapterModal } from 'components/shared/TimeCodeModal/TimeCodeChapterModal';
import { greyScrollbarVertical } from 'components/shared/twin.styles';
import { cloneDeep, floor, isEmpty, isNil, last, toNumber } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { RootState } from 'reducers';
import { MediaService } from 'services';
import {
  setAdMarker,
  togglePostRoll,
  togglePreRoll,
} from 'slices/admarker.slice';
import { tierSelector } from 'slices/payment.slice';
import { AdmarkerField, ModalOptions } from 'utils/enum';
import { AdmarkerLayerPayload } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { easeInOutQuint, getJsonV2Path } from 'utils/utils';
import { v4 } from 'uuid';
import {
  getAdmarkerLayerByTime,
  handleAdmarkerPayload,
  isPublicShared,
  msToSec,
} from '../../MediaUtilities';
import { AdMarkerRow } from './AdMarkerRow';

type Props = {};

export const AdMarkerLayer = (props: Props) => {
  const dispatch = useDispatch();

  const adMarker = useSelector((state: RootState) => state.adMarker);
  const media = useSelector((state: RootState) => state.media);
  const toggle = useSelector((state: RootState) => state.toggle);
  const iab = useSelector((state: RootState) => state.iab);
  const player = useSelector((state: RootState) => state.player);

  const tier = useSelector(tierSelector);

  const [adMarkerData, setAdmarkerData] = useState<AdmarkerLayerPayload[]>([]);
  const [isLoading, toggleLoading] = useToggle(true);
  const [selectedAdmarker, setSelectedAdmarker] = useState<number[]>([]);

  const videoDuration = msToSec(media?.metadata?.length);

  const adMarkerModalRef = useRef<any>(null);
  const timecodeRef = useRef<any>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const scrollingRef = useRef<number>();

  const handleDoubleClickAdMarker = async (
    adMarkerItem: AdmarkerLayerPayload,
  ) => {
    const response = await adMarkerModalRef.current?.show({
      adMarkerItem: adMarkerItem,
      mediaid: media?.metadata?.mediaid,
    });

    if (response?.option !== ModalOptions.YES) return;

    const foundIndex = adMarker.midRolls?.findIndex(
      (item) => item?.second === adMarkerItem?.startTime,
    );

    if (foundIndex < 0) return;

    // Compare upto second
    const foundDuplicateIndex = adMarker?.midRolls?.findIndex(
      (item) => floor(item.second) === floor(response?.newTime),
    );

    if (foundDuplicateIndex >= 0) {
      customToast.error(
        'Ad marker cannot be duplicated. Please check and try again.',
      );
      return;
    }

    const newItems = cloneDeep(adMarker?.midRolls);

    if (newItems) {
      newItems[foundIndex].second = response?.newTime;

      // Editing pre-roll
      if (foundIndex === 0) {
        const hasPreRoll = response?.newTime === 0;
        dispatch(togglePreRoll(hasPreRoll));
      }

      // Editing post-roll
      if (foundIndex === adMarker?.midRolls?.length - 1) {
        const hasPostRoll = response?.newTime >= videoDuration;
        dispatch(togglePostRoll(hasPostRoll));
      }

      const sortedAdMarkers = [...newItems].sort(
        (a, b) => a?.second - b?.second,
      );

      const newTranscriptAdmarker = getAdmarkerLayerByTime(
        sortedAdMarkers,
        response?.newTime,
        iab.lockedList,
        player.keywords,
        player.entities,
        adMarkerData.length + 1,
        media.metadata.mediaid,
        msToSec(media?.metadata?.length),
      );

      if (isNil(newTranscriptAdmarker)) {
        return;
      }

      const newAdMarkerData = [...adMarkerData];
      const newIndex = newAdMarkerData.findIndex(
        (i) => newTranscriptAdmarker.startTime < i.startTime,
      );

      newAdMarkerData[foundIndex] = newTranscriptAdmarker;

      dispatch(setAdMarker(sortedAdMarkers));

      setAdmarkerData(
        newAdMarkerData.sort((a, b) => a.startTime - b.startTime),
      );
      rowVirtualizer.scrollToIndex(
        newIndex > 0 ? newIndex : newAdMarkerData.length - 1,
      );
    }
  };

  const handleAddAdMarker = () => {
    const videoDuration = msToSec(media?.metadata?.length);
    const MIN_GAP_SECOND = 10;

    // None of the AdMarkers exists
    if (isEmpty(adMarker.midRolls)) {
      dispatch(setAdMarker([{ id: v4(), second: videoDuration / 2 }]));
      return;
    }

    let lastAdMarkerSecond = last(adMarker.midRolls)?.second || 0;

    if (adMarker.postRoll) {
      // -1 for PostRoll
      const lastMidRollIndex = adMarker?.midRolls?.length - 2;

      lastAdMarkerSecond = adMarker.midRolls?.[lastMidRollIndex]?.second || 0;
    }

    // Not enough space to add another AdMarker
    if (videoDuration - lastAdMarkerSecond < MIN_GAP_SECOND) {
      customToast.error(
        <div>
          <div>
            There is not enough time between the last mid-roll ad marker and the
            end of the file to insert a new ad marker.
          </div>
          <div tw="mt-2">
            Move the last ad marker to make at least <b>10 seconds</b> of space
            before the end.
          </div>
        </div>,
        {
          duration: 3000,
          style: {
            maxWidth: '420px',
          },
        },
      );
      return;
    }

    // Time between the last MidRolls and the end of duration
    const startTime = (lastAdMarkerSecond + videoDuration) / 2;

    const newAdMarker = [
      ...adMarker.midRolls,
      {
        id: v4(),
        second: startTime,
      },
    ];

    const sortedAdMarkers = [...newAdMarker].sort(
      (a, b) => a?.second - b?.second,
    );

    const newAdmarkerTranscript = getAdmarkerLayerByTime(
      sortedAdMarkers,
      startTime,
      iab.lockedList,
      player.keywords,
      player.entities,
      adMarkerData.length + 1,
      media.metadata.mediaid,
      msToSec(media?.metadata?.length),
    );

    if (isNil(newAdmarkerTranscript)) {
      return;
    }

    const newAdMarkerData = [...adMarkerData];
    newAdMarkerData.splice(
      newAdMarkerData.length - 1,
      0,
      newAdmarkerTranscript,
    );
    setAdmarkerData(newAdMarkerData);

    rowVirtualizer.scrollToIndex(newAdMarkerData.length - 1);
    dispatch(setAdMarker(sortedAdMarkers));
  };

  const handleSelectAdmarker = (item: AdmarkerLayerPayload) => {
    if (selectedAdmarker.includes(item.startTime)) {
      setSelectedAdmarker(selectedAdmarker.filter((i) => i !== item.startTime));
      return;
    }
    setSelectedAdmarker([...selectedAdmarker, item.startTime]);
  };

  const handleRemoveAdMarker = () => {
    const newAdMarker = adMarker.midRolls.filter(
      (item) => !selectedAdmarker.includes(item.second),
    );
    dispatch(setAdMarker(newAdMarker));

    const newAdmarkerData = adMarkerData.filter(
      (i) => !selectedAdmarker.includes(i.startTime),
    );
    setAdmarkerData(newAdmarkerData);
    setSelectedAdmarker([]);

    const firstMidRoll = newAdMarker?.[0];
    const lastMidRoll = newAdMarker?.[newAdMarker?.length - 1];

    // Remove PreRoll
    if (adMarker.preRoll && firstMidRoll?.second !== 0) {
      dispatch(togglePreRoll(false));
    }

    // Remove PostRoll
    if (adMarker.postRoll && lastMidRoll?.second !== videoDuration) {
      dispatch(togglePostRoll(false));
    }
  };

  const getAdMarkerLayer = async () => {
    try {
      toggleLoading(true);
      const jsonPath = getJsonV2Path(media?.jsonfileurl);

      if (!jsonPath) {
        customToast.error('Media JSON file not found!');
        return;
      }

      const response = await MediaService.getAdMarkerReport({
        mediaId: media.metadata.mediaid,
        jsonV2Path: jsonPath,
      });
      const data = handleAdmarkerPayload(response.data);
      setAdmarkerData(data);
    } catch (error) {
      console.log(error);
    } finally {
      toggleLoading(false);
    }
  };

  useEffect(() => {
    if (!media?.jsonfileurl) return;

    if (isPublicShared()) return;
    
    getAdMarkerLayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media?.jsonfileurl]);

  useEffect(() => {
    const newAdmarkerData = adMarker.midRolls.map((item) => {
      const newAdmarkerTranscript = getAdmarkerLayerByTime(
        adMarker.midRolls,
        item.second,
        iab.lockedList,
        player.keywords,
        player.entities,
        adMarkerData.length + 1,
        media.metadata.mediaid,
        msToSec(media?.metadata?.length),
      );
      return newAdmarkerTranscript;
    });
    setAdmarkerData(newAdmarkerData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adMarker.midRolls,
    adMarkerData.length,
    iab.lockedList,
    media.metadata.mediaid,
    player.entities,
    player.keywords,
  ]);

  const columns: any = useMemo(
    () => [
      {
        header: ' ',
        accessor: '',
        id: 'Select',
        size: 40,
      },
      {
        header: '',
        accessor: 'type',
        id: AdmarkerField.TYPE,
        size: 50,
        style: {
          width: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
      {
        header: 'Marker',
        accessor: 'title',
        id: AdmarkerField.TITLE,
        size: 100,
      },
      {
        header: 'Start Time',
        accessor: 'startTime',
        style: {
          textAlign: 'center',
        },
        id: AdmarkerField.START_TIME,
        size: 100,
      },
      {
        header: 'Ads served',
        accessor: 'adsServed',
        style: {
          textAlign: 'center',
        },
        id: AdmarkerField.ADS_SER,
        size: 90,
      },
      {
        header: 'Tags',
        accessor: 'tags',
        style: {
          textAlign: 'center',
          width: '150px',
        },
        id: AdmarkerField.TAGS,
        size: 200,
      },
      {
        header: 'IAB Categories',
        accessor: 'iab',
        style: {
          textAlign: 'center',
        },
        id: AdmarkerField.IAB,
        size: 150,
      },
      {
        header: 'Transcript Prior',
        accessor: 'transcript',
        style: {
          textAlign: 'center',
        },
        id: AdmarkerField.TRANSCRIPT,
        size: 215,
      },
      {
        header: 'Transcript After',
        style: {
          textAlign: 'center',
        },
        accessor: 'transcriptAfter',
        id: AdmarkerField.TRANSCRIPT_AFTER,
        size: 215,
      },
      {
        header: 'URL',
        style: {
          textAlign: 'center',
        },
        accessor: 'url',
        id: AdmarkerField.URL,
        size: 50,
      },
    ],
    [],
  );

  const scrollToFn: VirtualizerOptions<any, any>['scrollToFn'] =
    React.useCallback((offset, canSmooth, instance) => {
      const duration = 1000;
      const start = toNumber(tableContainerRef.current?.scrollTop);
      const startTime = (scrollingRef.current = Date.now());

      const run = () => {
        if (scrollingRef.current !== startTime) return;
        const now = Date.now();
        const elapsed = now - startTime;
        const progress = easeInOutQuint(Math.min(elapsed / duration, 1));
        const interpolated = start + (offset - start) * progress;

        if (elapsed < duration) {
          elementScroll(interpolated, canSmooth, instance);
          requestAnimationFrame(run);
        } else {
          elementScroll(interpolated, canSmooth, instance);
        }
      };

      requestAnimationFrame(run);
    }, []);

  let table = useReactTable({
    columns,
    data: useMemo(() => adMarkerData, [adMarkerData]),

    getCoreRowModel: getCoreRowModel(),
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    count: rows.length,
    // must equal to the height of the row
    // eslint-disable-next-line react-hooks/exhaustive-deps
    estimateSize: useMemo(() => () => 120, [rows]), // max height of the row
    overscan: 1, // number of items renders above and below the visible area
    scrollToFn,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize();

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  if (isLoading) {
    return (
      <div tw="relative h-[calc(100vh - 21rem)]">
        <div className="loader__component" tw="absolute opacity-100">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div tw="relative mt-6 pb-4 overflow-y-auto mr-2">
      <div tw="flex w-[100%] justify-between text-[1.5rem] mb-1.5">
        <div tw="flex space-x-3">
          <div tw="font-medium text-sonnant-blue-dark">Ad markers</div>

          {toggle.isEditMode && (
            <>
              {(tier.isEnterprise || tier.isInternal) && (
                <Hint text="Add new Ad marker" enterDelay={100}>
                  <div tw="cursor-pointer flex" onClick={handleAddAdMarker}>
                    <AddSvg fill="#5551FF" />
                  </div>
                </Hint>
              )}

              <HintDisallowed
                disabled={isEmpty(selectedAdmarker)}
                hintEnabled="Removed selected markers"
                hintDisabled="None item selected"
                tw="flex items-center"
                onClick={handleRemoveAdMarker}
              >
                <MinusSvg fill="#5551FF" width={20} height={20} />
              </HintDisallowed>
            </>
          )}
        </div>

        <div tw="font-medium flex space-x-5">
          <div>Total Markers:</div>
          <div>{adMarker.midRolls.length}</div>
        </div>
      </div>

      <div className="listViewTable">
        <div
          ref={tableContainerRef}
          tw="height[calc(100vh - 25.3rem)] overflow-auto md-down:overflow-x-auto scrollbar-width[thin]"
          css={[greyScrollbarVertical]}
        >
          <table
            // style={{ width: table.getCenterTotalSize() }}
            tw="text-10!"
          >
            <thead>
              {table
                .getHeaderGroups()
                .map((headerGroup, groupIndex: number) => (
                  <tr key={groupIndex}>
                    {headerGroup.headers.map((header, headerIndex: number) => (
                      <th
                        tw="(text-sonnant-grey-6 opacity-95)!"
                        style={(header.column.columnDef as any)?.style}
                        key={headerIndex}
                      >
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
            </thead>

            <tbody>
              {paddingTop > 0 && (
                <tr>
                  <td style={{ height: `${paddingTop}px` }} />
                </tr>
              )}

              {virtualRows.map((virtualRow) => {
                const row = rows[virtualRow.index] as Row<AdmarkerLayerPayload>;

                return (
                  <AdMarkerRow
                    index={virtualRow.index}
                    key={virtualRow.index}
                    itemRow={row}
                    selectedAdmarker={selectedAdmarker}
                    handleSelectAdmarker={handleSelectAdmarker}
                    handleDoubleClickAdMarker={handleDoubleClickAdMarker}
                  />
                );
              })}

              {isEmpty(rows) && (
                <tr tw="pl-3 py-3">
                  <td colSpan={8}>
                    <Info
                      text="There are no Ad Markers detected for this content item"
                      fontSize="1.6rem"
                      hideSpacingBottom
                    />
                  </td>
                </tr>
              )}

              {paddingBottom > 0 && (
                <tr>
                  <td style={{ height: `${paddingBottom}px` }} />
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* <div>
        {adMarker.midRolls?.map((item) => (
          <div key={item.id}>
            <AdMarker
              item={item}
              handleClickAdMarker={handleClickAdMarker}
              handleDoubleClickAdMarker={handleDoubleClickAdMarker}
              handleDeleteAdMarker={handleDeleteAdMarker}
            />
          </div>
        ))}

        {isArray(adMarker?.midRolls) &&
          adMarker?.midRolls?.length === 0 &&
          !toggle.isEditMode && (
            <Info text="There are no ad markers for this content item." />
          )}
      </div> */}
      {/* {toggle.isEditMode && !isPublicShared() && (
        <>
          <Ripple>
            <div
              tw="text-16 mt-2 font-medium px-2 text-sonnant-grey-5 cursor-pointer rounded select-none"
              onClick={handleAddAdMarker}
            >
              + Add ad marker
            </div>
          </Ripple>

        </>
      )} */}
      <TimeCodeChapterModal ref={timecodeRef} />

      <AdMarkerModal ref={adMarkerModalRef} />
    </div>
  );
};
