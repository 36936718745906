/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { isEmpty } from 'lodash';

import { RssFeedDisplay } from 'utils/models';
import { Info } from '../Info';
import { RssPreviewItemPagination } from './RssPreviewItemPagination';

type Props = {
  previewList: RssFeedDisplay[];
  selectedRssFeeds: RssFeedDisplay[];
  handleCheckSelected: (feed: RssFeedDisplay) => void;
};

export const RssPreviewPagination = ({
  previewList,
  selectedRssFeeds,
  ...props
}: Props) => {
  return (
    <div tw="border[1px solid] border-sonnant-grey-light mb-6 max-height[70vh] overflow-x-hidden overflow-y-auto rounded">
      {previewList?.map((feed) => (
        <RssPreviewItemPagination
          key={feed?.id}
          feed={feed}
          handleCheckSelected={props.handleCheckSelected}
          selectedRssFeeds={selectedRssFeeds}
        />
      ))}

      {isEmpty(previewList) && (
        <div tw="min-h-[50vh] pl-3 py-2.5">
          <Info
            text="Nothing to show here. Please check your URL or filter conditions and try again."
            hideSpacingBottom
          />
        </div>
      )}
    </div>
  );
};
