export const sign_up_success_message =
  'You have successfully created the Sonnant Library for your company.';
export const sign_up_check_message = 'Please check your welcome email.';
export const sign_up_fail_message =
  'We attempted to create a Sonnant Library for your company, but were unsuccessful. Please try again.';

export const HINT_NO_EDITING = 'Editing function restricted by Admin';
export const HINT_NO_PUBLISH = 'Publishing function restricted by Admin';

export const ARCHIVE_TAG_NAME = 'Archive';

export const UNLIMITED = 999999;

export const MAX_INSIGHT_TERM = 10;
export const MAX_TERMS_IN_VOCAB = 25;

export const MAX_PINNABLE_CHART = 3;

export const MAX_INSIGHT_EDIT_COUNT = 100;
export const MAX_TERM_EDIT_COUNT = 50;

export const CHART_ITEM_COUNT = 20;

export const MAX_TERM_EXPLORATION_CHART = 4;

export const MAX_TRENDING_TERM = 20;

export const Duration = {
  millis(millis: number) {
    return millis;
  },
  seconds(seconds: number) {
    return seconds * this.millis(1000);
  },
  minutes(minutes: number) {
    return minutes * this.seconds(60);
  },
  hours(hours: number) {
    return hours * this.minutes(60);
  },
  days(days: number) {
    return days * this.hours(24);
  },
  weeks(weeks: number) {
    return weeks * this.days(7);
  },
  months(months: number) {
    return months * this.days(30);
  },
  years(years: number) {
    return years * this.days(365);
  },
};
