import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITranscript } from 'utils/models/transcript.model';

const initialState = {
  data: {
    formatVersion: '',
    language: '',
    companyName: 'Sonnant',
    mediaId: '',
    length: '',
    captions: [],
    dateCreated: '',
    paragraphs: [],
    chapters: [],
    keywords: [],
    entities: {},
    summary: '',
    speakers: [],
    customTerms: [],
    iabCategories: {
      confirmedList: [],
      lockedList: [],
      recommendedList: [],
    },
  } as ITranscript,
};

const originalSlice = createSlice({
  name: 'mediadata',
  initialState,
  reducers: {
    setOriginalData: (state, { payload }: PayloadAction<ITranscript>) => {
      state.data = { ...payload } as any;
    },
    resetOriginalData: (state) => {
      state.data = { ...initialState.data } as any;
    },
  },
});

export const { setOriginalData, resetOriginalData } = originalSlice.actions;

export const originalReducer = originalSlice.reducer;
