/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';
import { Modal } from 'components/UiControls/modal/modal';
import React, {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ModalOptions, Ratio } from 'utils/enum';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useEffect } from 'react';
import { isEmpty, isNil, isEqual } from 'lodash';
import { useToggle, useUnmount, useVideo } from 'react-use';
import {
  getLatestTitle,
  isAudioByMIME,
  isDisplayClips,
  shouldUsePlyr,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import Plyr from 'plyr';
import { audioThumbStyles, plyrResetStyle } from '../twin.styles';
import { IMediaDetail } from 'utils/models';
import { ReactComponent as ShareSvg } from 'assets/Icons/Share.svg';
import { ReactComponent as ExportSvg } from '../../../assets/Icons/Export.svg';
import { ReactComponent as MovieSvg } from 'assets/Icons/movie.svg';
import { MediaService } from 'services';
import Loader from 'components/loader/loader';
import { customToast } from 'utils/toast.util';
import { HintRestrictedPublish } from '../HintRestrictedPublish';

interface ReviewPayload {
  option: ModalOptions;
  type?: 'Share' | 'Export' | 'Edit';
}

interface IPromiseParams {
  resolve: (option: ReviewPayload) => void;
  reject: (err: any) => void;
}

export interface IPreviewOptions {
  startTime: number;
  endTime: number;
  mediaid?: string;
  title?: string;
  versioncount?: string;
  mediaObj?: IMediaDetail;
  hasButtons?: boolean;
  thumbnail?: string;
}

interface IProps {
  title?: string;
  cancelText?: string;
  confirmText?: string;
  mediaSrc?: string;
  vttPath?: string;
  startTimeMs?: number;
  endTimeMs?: number;
  isMentionReport?: boolean;
}

const uniqueTimestamp = new Date().getTime();

export const PreviewClipModal = forwardRef(
  (props: IProps, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const [isOpen, setIsOpen] = useState(false);
    const [, toggleLoading] = useToggle(false);
    const [isAutoPlay, toggleIsAutoPlay] = useToggle(false);
    const [previewDetail, setPreviewDetail] = useState<any>(null);
    const [plyrInstance, setPlyrInstance] = useState<Plyr | null>(null);
    const { media, clips, advancedSearch } = useSelector(
      (state: RootState) => state,
    );

    const [rawMediaSrc, setRawMediaSrc] = useState<string | undefined>(
      props?.mediaSrc || media?.url,
    );
    const [rawVttPath, setRawVttPath] = useState<string | undefined>(
      props?.vttPath || media?.subtitleurl,
    );

    const [timeFragment, setTimeFragment] = useState('#t=0.01');
    const [mediaid, setMediaid] = useState<string | null>(null);
    const [showOptions, setShowOptions] = useState<IPreviewOptions>();
    const [videoTitle, setVideoTitle] = useState<string | null>('');
    const [versioncount, setVersioncount] = useState<string | undefined>(
      undefined,
    );
    const [hasButtons, setHasButtons] = useState(true);
    const isOriginalClip = isEqual(clips?.focusClip?.versioncount, '0');

    const isSuggestedAudio =
      previewDetail?.hasBurnIn &&
      showOptions?.versioncount?.startsWith('suggessted');

    const hasThumbnail =
      ([
        Ratio['9_16'],
        Ratio['4_3'],
        Ratio['1_1'],
        Ratio['DEFAULT'],
        Ratio['EMPTY'],
      ].includes(previewDetail?.dar) &&
        !previewDetail?.hasBurnIn) ||
      isSuggestedAudio;

    const thumbUrl =
      previewDetail?.thumbnail_url ||
      showOptions?.thumbnail ||
      clips.focusClip?.thumbnail;

    const [video, videoState, controls, videoRef] = useVideo(
      <video
        tw="w-full! rounded-md shadow"
        css={[
          isAudioByMIME(previewDetail?.mediatype) &&
            hasThumbnail &&
            audioThumbStyles(thumbUrl),
          tw`block`,
          !shouldUsePlyr() &&
            tw`(absolute top-0 h-full w-full bg-sonnant-grey-1 overflow-hidden)!`,
        ]}
        src={rawMediaSrc + timeFragment}
        poster={thumbUrl ? `${thumbUrl}?q=${uniqueTimestamp}` : undefined}
        controls={false}
        autoPlay={isAutoPlay}
        preload="auto"
        crossOrigin="anonymous"
        controlsList="nodownload"
        playsInline
        onClick={() => (videoState.paused ? controls.play() : controls.pause())}
        onContextMenu={(e) => e.preventDefault()}
      >
        {rawVttPath && (
          <track
            label="English"
            kind="subtitles"
            srcLang="en"
            src={rawVttPath}
            default
          ></track>
        )}
        {showOptions?.mediaObj?.subtitleurl && (
          <track
            label="English"
            kind="subtitles"
            srcLang="en"
            src={showOptions.mediaObj.subtitleurl}
            default
          ></track>
        )}
      </video>,
    );

    const initPlyr = () => {
      if (shouldUsePlyr()) {
        const instance = new Plyr(videoRef.current as any, {
          captions: { active: true, update: true },
          ratio: '16:9',
          controls: [
            'play-large',
            'play',
            'progress',
            'current-time',
            'mute',
            'volume',
            'captions',
            'settings',
            'fullscreen',
          ],
          autoplay: isAutoPlay || props?.isMentionReport,
        });
        setPlyrInstance(instance);
      }
    };

    useUnmount(() => {
      if (plyrInstance) {
        plyrInstance.destroy();
      }
    });

    useEffect(() => {
      if (isNil(props?.mediaSrc) || isNil(props?.vttPath)) return;

      setRawMediaSrc(props.mediaSrc);
      setRawVttPath(props.vttPath);
    }, [props.mediaSrc, props.vttPath]);

    useEffect(() => {
      if (!mediaid) return;
      toggleIsAutoPlay(false);
      setRawVttPath(media?.subtitleurl);

      fetchMediaAsync(mediaid, versioncount);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaid, versioncount]);

    // Mention auto loop
    useEffect(() => {
      if (!props?.isMentionReport || !showOptions || !plyrInstance) return;

      if (plyrInstance?.currentTime >= showOptions?.endTime) {
        controls.seek(showOptions?.startTime);
        controls.play();
      }
    }, [videoState.time]);

    const fetchMediaAsync = async (mediaid: string, versioncount?: string) => {
      try {
        toggleLoading(true);
        const mediaResponse = await MediaService.getPreview({
          mediaid,
          versioncount,
        });

        //Reset mediaSrc and vtt
        setRawMediaSrc(undefined);
        setRawVttPath(undefined);

        initPlyr();

        setPreviewDetail(mediaResponse.data);

        if (!showOptions?.mediaObj) {
          setRawMediaSrc(mediaResponse.data?.url);
          setRawVttPath(mediaResponse.data.subtitleurl);
        } else {
          setRawMediaSrc(showOptions.mediaObj?.url);
          setRawVttPath(showOptions.mediaObj.subtitleurl);
        }
        // toggleIsAutoPlay(true);
        toggleCaption(false);
        if (isEmpty(showOptions?.mediaObj)) {
          toggleCaption(true);
        }
      } catch (err: any) {
        console.log('HTTP ERROR', err);
        customToast.error('Something went wrong');
      } finally {
        toggleLoading(false);
      }
    };

    const toggleCaption = (isShowCaptions: boolean) => {
      if (videoRef?.current) {
        videoRef.current.textTracks[0].mode = isShowCaptions
          ? 'showing'
          : 'hidden';
      }
    };

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (options: IPreviewOptions): Promise<unknown> => {
      // initPlyr();
      setShowOptions(options);

      if (!isNil(options?.hasButtons)) {
        setHasButtons(options?.hasButtons);
      }

      if (!isNil(options?.mediaid)) {
        setMediaid(options.mediaid);
      }

      if (!isNil(options?.versioncount)) {
        setVersioncount(options.versioncount);
      }

      if (!isNil(options?.startTime) && !isNil(options?.endTime)) {
        // start from 0
        let startTime = 0.001;
        let endTime = options.endTime - startTime;
        // start from startTime
        if (props.isMentionReport) {
          startTime = options.startTime;
          endTime = options.endTime;
        }

        setTimeFragment(`#t=${startTime},${endTime}`);
      }

      const title = options?.title?.trim() ?? '';
      if (!isEmpty(title)) {
        setVideoTitle(title);
      }
      toggleCaption(false);
      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const hideModal = () => {
      controls.pause();
      plyrInstance?.destroy?.();

      setHasButtons(true);
      setMediaid(null);
      setIsOpen(false);
      setPreviewDetail(null);
      setPlyrInstance(null);
      setRawMediaSrc(undefined);
      setRawVttPath(undefined);
    };

    const handleYes = (type: ReviewPayload['type']) => {
      hideModal();
      promiseInfo.current.resolve({
        option: ModalOptions.YES,
        type,
      });
    };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current.resolve({
        option: ModalOptions.CANCEL,
      });
    };

    return (
      <Modal show={isOpen} modalClosed={handleCancel}>
        <div
          className="userModal_Popus"
          css={[shouldUsePlyr() && plyrFirefoxCss]}
        >
          <h1>{props.title || 'Preview'}</h1>

          <div
            tw="mt-6 font-medium text-sonnant-dark word-break[break-all]"
            className="line-clamp-1"
          >
            {videoTitle || getLatestTitle(media?.metadata)}
          </div>

          <div tw="shadow rounded mt-4">
            {!plyrInstance && (
              <div tw="flex justify-center items-center height[40rem]">
                <Loader />
              </div>
            )}
            <div
              css={[
                !shouldUsePlyr() &&
                  tw`(w-full h-auto relative padding-top[56.25%] rounded)!`,
                !plyrInstance && tw`hidden!`,
              ]}
            >
              {video}
            </div>
          </div>

          <div className="grid-x" tw="mt-6 flex flex-row justify-between">
            <div tw="flex flex-row space-x-6">
              {(advancedSearch.isShowClipsOnly || isDisplayClips()) &&
                hasButtons && (
                  <HintRestrictedPublish>
                    <button
                      type="button"
                      className="button btn-secondary large"
                      tw="flex justify-center items-center"
                      onClick={() => handleYes('Share')}
                    >
                      <ShareSvg tw="mr-4" /> <span tw="font-medium">Share</span>
                    </button>
                  </HintRestrictedPublish>
                )}
              {(advancedSearch.isShowClipsOnly || isDisplayClips()) &&
                hasButtons && (
                  <button
                    type="button"
                    className="button btn-secondary large"
                    tw="flex justify-center items-center"
                    onClick={() => handleYes('Export')}
                  >
                    <ExportSvg tw="mr-4" /> <span tw="font-medium">Export</span>
                  </button>
                )}
            </div>

            <div tw="flex flex-row space-x-6">
              {(advancedSearch.isShowClipsOnly || isDisplayClips()) &&
                !versioncount?.startsWith('combined') &&
                hasButtons && (
                  <button
                    type="button"
                    className={`button btn-secondary large`}
                    tw="(flex justify-center items-center w-[unset] max-w-[unset] px-3)!"
                    onClick={() => handleYes('Edit')}
                  >
                    <MovieSvg tw="mr-3 fill-current text-gray-500" />
                    <span tw="font-medium">
                      {isOriginalClip ? 'Create clip' : 'Edit'}
                    </span>
                  </button>
                )}
              <button
                type="button"
                className="button btn-primary large"
                tw="flex justify-center items-center"
                onClick={handleCancel}
              >
                {props?.confirmText || 'Close'}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);

export const plyrFirefoxCss = css`
  .plyr.plyr--full-ui.plyr--video.plyr--html5 {
    background: #f0f0f4;
  }

  ${plyrResetStyle}
`;
