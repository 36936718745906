/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { ReactComponent as AddCollectionSvg } from 'assets/Icons/add-collection.svg';
import { ReactComponent as NoneCollectionSvg } from 'assets/Icons/cross.svg';
import { ReactComponent as SmartCollectionSvg } from 'assets/Icons/smart-collection.svg';
import { ReactComponent as StandardCollectionSvg } from 'assets/Icons/standard-collection.svg';
import { isEmpty } from 'lodash';
import { useToggle } from 'react-use';
import { CollectionType } from 'utils/enum';
import { CollectionDropdownOption } from 'utils/models';
import { SimpleCheckbox } from '../SimpleCheckbox';

type Props = {
  option: CollectionDropdownOption;
  optionList: string[];
  onClearSelected: () => void;
  onSelectOption: (option: CollectionDropdownOption) => void;
};

export const CollectionMultipleItem = ({
  option,
  optionList,
  onClearSelected,
  onSelectOption,
}: Props) => {
  const [hovering, toggleHovering] = useToggle(false);

  const isShowCheckbox =
    (!isEmpty(option.value) && hovering && !option?.__isNew__) ||
    optionList.includes(option.value);

  const handleClickOption = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (option?.__isNew__) return;

    if (option.type === CollectionType.NONE) {
      onClearSelected();

      return;
    }

    onSelectOption(option);
  };

  return (
    <div
      tw="flex flex-row items-center h-[3.4rem] relative cursor-pointer p-[2rem 1rem]"
      onMouseOver={() => toggleHovering(true)}
      onMouseLeave={() => toggleHovering(false)}
      onClick={handleClickOption}
    >
      <div tw="max-width[3.5rem] mr-4 flex">
        {option.type === CollectionType.NONE ? (
          <NoneCollectionSvg />
        ) : option.type === CollectionType.SMART ? (
          <SmartCollectionSvg />
        ) : option?.__isNew__ ? (
          <AddCollectionSvg fill="#7f8090" />
        ) : (
          <StandardCollectionSvg />
        )}
      </div>

      <div tw="flex flex-col w-full">
        <div
          css={[
            tw`text-15 text-left text-sonnant-dark break-words line-height[1.4] max-w-[14rem]`,
            isShowCheckbox && tw`max-w-[11rem]`,
          ]}
          className="line-clamp-1"
          title={option.label}
        >
          {option.label}
        </div>
      </div>

      {isShowCheckbox && (
        <div tw="flex absolute right-[4px]" onClick={(e) => e.preventDefault()}>
          <SimpleCheckbox
            name={option.value}
            checked={optionList.includes(option.value)}
            onChange={() => {}}
          />
        </div>
      )}
    </div>
  );
};
