/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import { FilterTextBox } from './FilterTextBox';
import {
  AdvancedFilterType,
  DatePeriodFilter,
  DurationFilter,
  MediaType,
  SearchField,
  SearchType,
  SortField,
} from 'utils/enum';
import { FilterOption } from './FilterOption';
import { isEmpty, values } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { FilterInput } from './FilterInput';
import { FilterCustomDate } from './FilterCustomDate';
import { ReactComponent as ClearSvg } from 'assets/Icons/x-cancel-red.svg';
import {
  resetAdvancedSearch,
  setSearchField,
  setSearchParams,
  toggleSearchClipsMode,
} from 'slices/advanced-search.slice';
import { Hint } from 'components/shared/Hint';
import { Switcher } from 'components/shared/Switcher';
import { changeSortBy, setPageNumber } from 'slices/pagination.slice';
import { handleSearchClear, setSelectedRows, setSelectMode } from 'actions';
import { toggleReloadLibrary } from 'slices/toggle.slice';
import { CustomSelectSearch } from 'components/shared/CustomSelectSearch';
import { GroupBase, StylesConfig } from 'react-select';
import { useState } from 'react';
import { SpeakerDiarisation } from 'utils/models';
import { resetSelectedCollectionId } from 'slices/collection.slice';
import { useHistory } from 'react-router-dom';
import { setSelectedClips, toggleSelectMode } from 'slices/clips.slice';

interface Option {
  label: string;
  value: string;
}

const tagLayers: { name: string; field: SearchField }[] = [
  { name: 'Key terms', field: SearchField.KEYWORD },
  { name: 'IAB categories', field: SearchField.IAB },
  { name: 'People', field: SearchField.PEOPLE },
  { name: 'Products', field: SearchField.PRODUCT },
  { name: 'Organisations', field: SearchField.ORG },
  { name: 'Locations', field: SearchField.LOCATION },
  { name: 'Nationalities', field: SearchField.NATIONALITY },
];

export const FilterBar = () => {
  const dispatch = useDispatch();

  const [newSpeakerName, setNewSpeakerName] = useState('');

  const history = useHistory();

  const advancedSearch = useSelector(
    (state: RootState) => state.advancedSearch,
  );
  const speakerIdentification = useSelector(
    (state: RootState) => state.speakerIdentification,
  );

  const currentSearchField = (): string =>
    tagLayers?.find(
      (layer) => layer.field === advancedSearch.searchParams.searchField,
    )?.name ?? '';

  const handleClearFilter = () => {
    dispatch(handleSearchClear());
    dispatch(resetAdvancedSearch());
    dispatch(toggleReloadLibrary());
  };

  const handleChangeSearchValue = (value: string) => {
    setNewSpeakerName(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      const isExistedName = speakerIdentification.speakers.some((speaker) =>
        speaker?.speakerName
          ?.toLowerCase()
          ?.includes(newSpeakerName?.trim()?.toLowerCase()),
      );

      if (isExistedName) {
        event.preventDefault();
      }

      dispatch(
        setSearchParams({
          type: AdvancedFilterType.SPEAKERS,
          data: newSpeakerName,
        }),
      );
    }
  };

  return (
    <div tw="flex">
      <div tw="flex-1"></div>

      <div tw="flex justify-center relative space-x-8 width[65%] mx-auto text-xl py-5 pb-0 border-bottom[1px solid]">
        {/* UPLOAD DATE */}
        <div css={childStyle}>
          <div css={headerStyle}>Upload date</div>
          <hr css={hrStyle} />
          {values(DatePeriodFilter)
            .filter((item) => item !== DatePeriodFilter.CUSTOM)
            .map((period, index) => (
              <FilterOption
                typeEnum={AdvancedFilterType.DATE}
                key={index}
                textEnum={period}
              />
            ))}

          <FilterCustomDate />
        </div>
        {/* TYPE */}
        <div css={childStyle}>
          <div css={headerStyle}>Type</div>
          <hr css={hrStyle} />
          {values(MediaType).map((media, index) => (
            <FilterOption
              typeEnum={AdvancedFilterType.TYPE}
              key={index}
              textEnum={media}
            />
          ))}

          <div tw="mt-5" css={headerStyle}>
            Search by
          </div>
          <hr css={hrStyle} />
          <div tw="margin-top[4px]">
            <FilterOption
              typeEnum={AdvancedFilterType.TITLE}
              textEnum={SearchType.TITLE}
            />
          </div>
        </div>
        {/* DURATION */}
        <div css={childStyle}>
          <div css={headerStyle}>Duration</div>
          <hr css={hrStyle} />
          {values(DurationFilter).map((duration) => (
            <FilterOption
              typeEnum={AdvancedFilterType.DURATION}
              key={duration}
              textEnum={duration}
            />
          ))}
        </div>
        {/* SPEAKER NAME */}
        <div css={childStyle}>
          <div css={[headerStyle, tw`(width[18rem] flex items-center)!`]}>
            <span>Includes: Speaker name</span>
          </div>
          <hr css={hrStyle} />
          <div>
            <div tw="max-height[9.9rem]!" css={[customInputContainer]}>
              {advancedSearch.searchParams.speakerNames.map(
                (speakerName, index) => (
                  <FilterTextBox
                    typeEnum={AdvancedFilterType.SPEAKERS}
                    key={index}
                    text={speakerName}
                  />
                ),
              )}
            </div>

            <div
              tw="my-2"
              css={[
                isEmpty(advancedSearch.searchParams.speakerNames) && tw`mt-0`,
              ]}
            >
              <CustomSelectSearch
                options={speakerIdentification.speakers
                  // hasPrompt only
                  .filter((speaker: SpeakerDiarisation) => speaker?.hasPrompt)
                  .map((item) => ({
                    label: item.speakerName,
                    value: item.speakerName,
                  }))}
                canCreate={false}
                defaultValue={'+ Add speaker name'}
                handleChangeSearchValue={handleChangeSearchValue}
                handleKeyDown={handleKeyDown}
                onChange={(newValue) => {
                  dispatch(
                    setSearchParams({
                      type: AdvancedFilterType.SPEAKERS,
                      data: newValue.value,
                    }),
                  );
                }}
                customStyles={customStyles}
              />
            </div>
          </div>
        </div>
        {/* KEYWORDS */}
        <div css={childStyle}>
          <div css={[headerStyle, tw`(width[18rem])!`]}>
            <span>Includes:</span>
            <div className="dropdown" css={[customDropdownCss]}>
              <span tw="(width[unset] py-0 px-3 flex items-center text-12 whitespace-nowrap hover:(bg-sonnant-grey-1))!">
                {currentSearchField()}
                <span className="arrow-down" tw="-mt-1"></span>
              </span>
              <ul className="dropdown-sort-content manageDropDown">
                {tagLayers.map((layer, index) => (
                  <li
                    key={index}
                    css={[layerItemCss]}
                    onClick={() => {
                      dispatch(setSearchField(layer.field));
                    }}
                  >
                    <a>{layer.name}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <hr css={hrStyle} />
          <div>
            <div tw="max-height[9.9rem]!" css={[customInputContainer]}>
              {advancedSearch?.searchParams?.searchValues?.map(
                (keyword, index) => (
                  <FilterTextBox
                    typeEnum={AdvancedFilterType.CUSTOM}
                    key={index}
                    text={keyword}
                  />
                ),
              )}
            </div>
            <FilterInput
              typeEnum={AdvancedFilterType.CUSTOM}
              displayText="+ Add filter"
              placeholder="Give a value"
            />
          </div>
        </div>
      </div>

      <div tw="flex-1 flex justify-center items-center min-width[21rem] flex-col">
        <div tw="flex items-center font-medium text-sonnant-red hover:(underline cursor-pointer) text-12 align-self[flex-start] mt-5 ml-2.5">
          <span tw="flex items-center mr-1">
            <ClearSvg tw="width[2rem] height[2rem]" />
          </span>
          <span
            tw="relative top[1px] focus:(font-medium)"
            onClick={handleClearFilter}
          >
            Clear filters
          </span>
        </div>
        <div tw="flex justify-center items-center text-13 font-medium border text-sonnant-grey-6 space-x-2.5 mr-3 line-height[1.2] relative h-full">
          <span>Content item</span>
          <Hint text="Scroll transcript with play">
            <Switcher
              checked={advancedSearch.isShowClipsOnly}
              onCheckedChange={() => {
                dispatch(toggleSearchClipsMode());
                dispatch(setPageNumber(1));
                dispatch(changeSortBy(SortField.MODIFIED));

                // reset select mode
                dispatch(setSelectMode(false));
                dispatch(toggleSelectMode(false));
                dispatch(setSelectedRows([]));
                dispatch(setSelectedClips([]));

                dispatch(resetSelectedCollectionId());
                history.push('/library');
              }}
            />
          </Hint>
          <span>Clips item</span>
        </div>
      </div>
    </div>
  );
};

const customStyles: StylesConfig<Option, false, GroupBase<Option>> = {
  container: (base) => ({
    ...base,
    height: '3rem',
    width: '25rem',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    display: 'none',
  }),
  control: (base) => ({
    ...base,
    border: 'none',
    minHeight: '3rem',
  }),
  menu: (base) => ({
    ...base,
    marginBottom: '4px',
    marginTop: '4px',
    zIndex: 3,
  }),
  menuList: (base) => ({
    ...base,
    overflowX: 'hidden',
  }),
  input: (base) => ({
    ...base,
    height: '0.5rem',
    position: 'relative',
    top: '-2rem',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    paddingLeft: '0.8rem',
    height: '2rem',
  }),
  placeholder: (base) => ({
    ...base,
    color: 'rgb(5, 10, 56)',
    top: '-1px',
    position: 'relative',
    fontWeight: 500,
    opacity: 0.75,
  }),
  option: (base) => ({
    ...base,
    cursor: 'pointer',
    color: 'rgb(51, 51, 51)',
    height: '3rem',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word',
    padding: '4px 12px',
  }),
};

const hrStyle = css`
  ${tw`mt-0 mb-1 border[1px solid grey]`}
`;
const childStyle = css`
  ${tw`flex-1 text-sonnant-blue-dark cursor-pointer`}
`;

const headerStyle = css`
  ${tw`font-bold text-sonnant-blue pl-1.5 whitespace-nowrap`}
`;

const customInputContainer = css`
  ${tw`max-height[13rem] overflow-x-auto px-0.5`}

  ::-webkit-scrollbar {
    width: 4px;
  }
`;

const layerItemCss = css`
  ${tw`text-13 whitespace-nowrap px-2 py-1 font-medium select-none`}

  ${tw`active:(bg-sonnant-grey-4 [a]:text-sonnant-dark)!`}
`;

const customDropdownCss = css`
  ${tw``}
`;
