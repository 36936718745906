import { isInsightsPage } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { UseQueryOptions, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { InsightService } from 'services/insight.service';
import { setGeneratedChartsData } from 'slices/term-exploration.slice';
import { Duration } from 'utils/constants';
import { GeneratedChartRaw } from 'utils/models';
import { getTenantidFromIdToken } from 'utils/utils';

const tenantid = getTenantidFromIdToken();

export const useQueryGeneratedTermInsight = (
  options?: UseQueryOptions<GeneratedChartRaw[]>,
) => {
  const dispatch = useDispatch();

  const queryFnAsync = async () => {
    const { data } = await InsightService.fetchAllGeneratedTermCharts();

    return data;
  };

  const queryResult = useQuery({
    queryKey: ['insight/generate-word-cloud', tenantid],
    queryFn: queryFnAsync,
    staleTime: Duration.minutes(1),
    cacheTime: Duration.minutes(2),
    enabled: isInsightsPage(),
    retry: 3,
    onSuccess: (data) => {
      dispatch(setGeneratedChartsData(data));
    },
    ...options,
  });

  return queryResult;
};
