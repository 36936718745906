/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'components/UiControls/modal/modal';
import { ReactComponent as ShareLinkedin } from 'assets/Icons/ShareLinkedin.svg';
import { ReactComponent as ShareTwitter } from 'assets/Icons/x-twitter-logo.svg';
import { ReactComponent as Divider } from 'assets/Icons/Divider.svg';
import { ReactComponent as ShareEmbed } from 'assets/Icons/ShareEmbed.svg';
import { ReactComponent as ShareFacebook } from 'assets/Icons/ShareFacebook.svg';
import { ReactComponent as ShareTiktok } from 'assets/Icons/tiktok_logo.svg';
import { ReactComponent as ShareInstagram } from 'assets/Icons/instagram.svg';
import Loader from '../../loader/loader';
import { config } from '../../config';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  covertSharedToEmbedUrl,
  removeHTMLTag,
  removeSpaceChars,
} from 'utils/utils';
import {
  generateIframe,
  getExtFromS3Url,
  getWindowOptions,
  isValidInstagramCaption,
  mergeSameTerm,
  msToSec,
  normalizeSummary,
  readCookie,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { audioThumbStyles, plyrResetStyle } from '../twin.styles';
import Plyr from 'plyr';
import { useCopyToClipboard, useToggle } from 'react-use';
import { isEmpty, isNil } from 'lodash';
import { AuthService, ShareService } from 'services';
// import { ReactComponent as ShareYoutube } from 'assets/Icons/circle_youtube_icon.svg';
import { ReactComponent as ShareYoutube } from 'assets/Icons/youtube-logo-red.svg';
import { customToast } from 'utils/toast.util';
import { ModalOptions, Social } from 'utils/enum';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { ConfirmModalProps } from 'utils/models';

type sharePublishModalProp = {
  openModal: boolean;
  handleModalClose: () => void;
  shareURL: string;
  mobile?: boolean;
  length?: number;
};

export const SharePublishModal = (props: sharePublishModalProp) => {
  const { openModal, handleModalClose, shareURL } = props;
  const [openModalPost, handleModelPost] = useState(false);
  const [vttPath, setVttPath] = useState('');
  const [isPosting, setIsPosting] = useState(false);
  const [content, setContent] = useState('');
  const media = useSelector((state: RootState) => state.media);

  const [urlVideo, setUrlVideo] = useState('');
  const shareIds = window.location.pathname.split('-').splice(-1);
  const shareId = shareIds[shareIds.length - 1];
  const shareSocialVideoRef = useRef<any>(null);
  const [embedCode, setEmbedCode] = useState('');
  const textAreaRef = useRef<any>(null);
  const [isShowEmbed, setIsShowEmbed] = useToggle(false);
  const [, copyToClipboard] = useCopyToClipboard();

  //Add Youtube
  const [isYoutubeShare, setIsYoutubeShare] = useState(false);
  const [clipTitle, setClipTitle] = useState('');
  const [clipCategory, setClipCategory] = useState<string>('1');
  const [privacyStatus, setPrivacyStatus] = useState<string>('public');

  const confirmModalRef = useRef<any>(null);

  const mediaData = media?.metadata;

  const isVideo = mediaData?.mediacontenttype === 'video/mp4';

  const canShareTikTok: boolean =
    ['video/webm', 'video/mp4'].includes(mediaData?.mediacontenttype ?? '') &&
    getExtFromS3Url(media?.url) !== 'm4v' &&
    msToSec(media?.itemLengthMs) >= 3 &&
    msToSec(media?.itemLengthMs) < 60;

  const canShareInstagram =
    ['video/quicktime', 'video/mp4'].includes(
      mediaData?.mediacontenttype ?? '',
    ) &&
    getExtFromS3Url(media?.url) !== 'm4v' &&
    msToSec(media?.itemLengthMs) >= 3 &&
    msToSec(media?.itemLengthMs) < 60;

  const canShareYoutube =
    (!isVideo && mediaData?.captionstype === 'OPEN') || isVideo;

  useEffect(() => {
    let isActive = true;

    if (media?.metadata?.captionstype === 'OPEN') {
      isActive = false;
    }

    new Plyr(shareSocialVideoRef.current, {
      captions: { active: isActive },
      ratio: '16:9',
      controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'captions',
        'settings',
        'fullscreen',
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareSocialVideoRef?.current, media?.metadata?.captionstype]);

  const openModalSharePost = (provider: Social) => {
    handleModelPost(true);
    let Content = '';
    if (
      [
        Social.LINKEDIN,
        Social.FACEBOOK,
        Social.YOUTUBE,
        Social.INSTAGRAM,
      ].includes(provider)
    ) {
      Content = `${media?.versionname} \n${removeHTMLTag(
        normalizeSummary(media?.metadata?.summary),
      )} \n`;
    } else {
      Content = `${media?.versionname}\n${window.location.href}`;
    }

    mergeSameTerm(media?.metadata?.keywords).forEach((element) => {
      Content = `${Content} #${removeSpaceChars(element.keyword)
        .replace(/\s/gm, '')
        .replace(/\W/gm, '_')} `;
    });

    if (provider === Social.YOUTUBE) {
      setIsYoutubeShare(true);
      setClipTitle(media?.versionname);
    }

    setUrlVideo(media.url);
    setContent(Content);
    setVttPath(media.subtitleurl);
  };
  const closeModalSharePost = () => {
    setIsYoutubeShare(false);
    handleModelPost(false);
  };
  const handleChangeSummary = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setContent(event.target.value);
  };
  const authorizationLinkedIn = () => {
    const oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${config.LINKEDIN_CLIENT_ID}&redirect_uri=${config.LINKEDIN_REDIRECT_URL}&state=${shareId}&scope=r_liteprofile%20r_emailaddress%20w_member_social`;
    popupShare(oauthUrl, Social.LINKEDIN);
  };

  // authorization Twitter
  const authorizationTwitter = async () => {
    const { data: oauthToken } = await AuthService.getTwitterToken();

    const oauthUrl = `https://api.twitter.com/oauth/authorize?oauth_token=${oauthToken}`;
    popupShare(oauthUrl, Social.TWITTER);
  };

  // authorization Twitter
  const authorizationYoutube = async () => {
    try {
      const oauthUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/youtube.upload&include_granted_scopes=true&state=${shareId}&redirect_uri=${config.YT_REDIRECT_URL}&response_type=token&client_id=${config.YT_CLIENT_ID}`;
      window.document.cookie = `mediaKeyTwitter=${shareId};domain=.${window.location.host}; path=/`;
      popupShare(oauthUrl, Social.YOUTUBE);
    } catch (err: any) {
      customToast.error('Something went wrong');
    }
  };

  // authorization Facebook
  const authorizationFacebook = async (ShareURL: string, shareId: string) => {
    // if (true) {
    const oauthUrl = `https://www.facebook.com/dialog/oauth?client_id=${config.FB_CLIENT_ID}&redirect_uri=${config.FB_REDIRECT_URL}&response_type=code&scope=pages_show_list,pages_manage_posts,pages_read_engagement`;
    window.document.cookie = `mediaKeyFacebook=${shareId};domain=.${window.location.host}; path=/`;
    popupShare(oauthUrl, Social.FACEBOOK);
    // } else {
    //   const mediaData = await MediaService.getPublicDetails(shareId);
    //   let Content = `${mediaData?.data?.versionname} \n${removeHTMLTag(
    //     normalizeSummary(mediaData?.data?.metadata?.summary),
    //   )} \n`;
    //   mediaData?.data?.metadata?.keywords.forEach((element: any) => {
    //     Content = `${Content} #${removeSpaceChars(element.keyword)
    //       .replace(/\s/gm, '')
    //       .replace(/\W/gm, '_')} `;
    //   });
    //   const width = 550,
    //     height = 730,
    //     left = window.screen.width / 2 - width / 2,
    //     top = window.screen.height / 2 - height / 2;
    //   const options = `menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=${width}, height=${height}, top=${top}, left=${left}`;
    //   const oauthUrl = `https://www.facebook.com/sharer/sharer.php?u=${ShareURL}&quote=${Content}`;
    //   window.open(oauthUrl, options);
    // }
  };

  const sendClipTikTok = async () => {
    const result = await confirmModalRef.current.show({
      title: 'Send clip to TikTok',
      message: (
        <div>
          <div>Your clip will be sent to your Share TikTok Platform.</div>
          <div>
            You will need to edit it before publishing.{' '}
            <strong>Would you like to proceed?</strong>
          </div>
        </div>
      ),
    } as ConfirmModalProps);

    if (result !== ModalOptions.YES) return;

    const params = {
      access_token: readCookie('access_token'),
      mediaKey: readCookie('mediaKeyTikTok'),
      provider: readCookie('provider'),
    };

    const shareSocialAsync = ShareService.shareSocial(Social.TIKTOK, params);
    customToast.promise(shareSocialAsync, {
      loading: 'Sending clip to TikTok',
      success: 'Your clip was sent to your TikTok account.',
      error: 'Failed to send video to TikTok Share',
    });
  };

  const popupShare = (oauthUrl: string, provider: Social) => {
    const popup = window.open(oauthUrl, provider, getWindowOptions());
    window.document.cookie = `state=false; domain=.${config.DOMAIN_NAME}; path=/`;

    const popupTick = setInterval(async function () {
      if (popup?.closed) {
        const state = readCookie('state');
        clearInterval(popupTick);

        // if state is true, then user has authorized
        if (state !== 'true') return;

        if (provider === Social.TIKTOK) {
          await sendClipTikTok();
        } else {
          openModalSharePost(provider);
        }
      }
    }, 500);
  };

  const authorizationTiktok = async () => {
    try {
      let url = 'https://open-api.tiktok.com/platform/oauth/connect/';
      url += `?client_key=${config.TIKTOK_CLIENT_ID}`;
      url +=
        '&scope=user.info.basic,video.list,video.upload,video.upload,share.sound.create';
      url += '&response_type=code';
      url += `&redirect_uri=${config.TIKTOK_REDIRECT_URL}`;
      url += '&state=' + shareId;
      window.document.cookie = `mediaKeyTikTok=${shareId};domain=.${window.location.host}; path=/`;
      popupShare(url, Social.TIKTOK);
    } catch (err: any) {
      customToast.error('Something went wrong');
    }
  };

  const authorizationInstagram = async () => {
    try {
      const oauthUrl = `https://www.facebook.com/dialog/oauth?client_id=${config.FB_CLIENT_ID}&redirect_uri=${config.INSTAGRAM_REDIRECT_URL}&response_type=code&scope=pages_show_list,instagram_basic,instagram_content_publish,pages_read_engagement`;
      window.document.cookie = `mediaKeyInstagram=${shareId};domain=.${window.location.host}; path=/`;
      popupShare(oauthUrl, Social.INSTAGRAM);
    } catch (err: any) {
      customToast.error('Something went wrong');
    }
  };

  const handleModalSharePost = async () => {
    try {
      const provider = readCookie('provider');
      if (!provider) return;

      let params = {};
      let messageSuccess = '';

      setIsPosting(true);

      if (provider === Social.LINKEDIN) {
        params = {
          code: readCookie('code'),
          mediaKey: shareId,
          content: content,
          provider: provider,
        };
        messageSuccess =
          'Shared to your LinkedIn account. It may take a few minutes to be published.';
      } else if (provider === Social.FACEBOOK) {
        if (isEmpty(JSON.parse(readCookie('page_data') ?? '{}'))) {
          customToast.error(
            'At least one page should be selected to be available for sharing. Please try again.',
          );
          return;
        }

        params = {
          page_data: readCookie('page_data'),
          mediaKey: readCookie('mediaKeyFacebook'),
          content: content,
          provider: provider,
        };
        messageSuccess =
          'Shared to your Facebook page(s). It may take a few minutes to be published.';
      } else if (provider === Social.TWITTER) {
        if (content.length <= 280) {
          params = {
            oauth_token: readCookie('oauth_token'),
            oauth_verifier: readCookie('oauth_verifier'),
            mediaKey: shareId,
            content: content,
            provider: provider,
          };
          messageSuccess =
            'Shared to your Twitter account. It may take a few minutes to be published.';
        } else {
          setIsPosting(false);
          customToast.error(
            'Twitter just allow the character limit for tweets to 280',
          );
        }
      } else if (provider === Social.YOUTUBE) {
        params = {
          access_token: readCookie('access_token'),
          mediaKey: readCookie('mediaKeyYoutube'),
          clipCategory: clipCategory,
          privacyStatus: privacyStatus,
          content: content,
          provider: provider,
          title: clipTitle,
        };
        messageSuccess =
          'Your item has been uploaded into your Youtube Channel';
      } else if (provider === Social.INSTAGRAM) {
        if (!isValidInstagramCaption(content)) {
          customToast.error(
            <div>
              Instagram caption should contain maximum <b>2200 characters</b>,{' '}
              <b>30 hashtags</b>,
              <div>
                and <b>20 @ tags</b>. Please check and try again.
              </div>
            </div>,
          );
          return;
        }

        if (isEmpty(JSON.parse(readCookie('page_data') ?? '{}'))) {
          customToast.error(
            'At least one page should be selected to be available for sharing. Please try again.',
          );
          return;
        }

        params = {
          page_data: readCookie('page_data'),
          mediaKey: readCookie('mediaKeyInstagram'),
          content: content,
          provider: provider,
        };
        messageSuccess =
          'Shared to your Instagram. It may take a few minutes to be published.';
      }

      if (isEmpty(params)) return;

      const response = await ShareService.shareSocial(provider, params);

      if (response.data === 'SUCCESS') {
        customToast.success(messageSuccess);
      } else {
        customToast.error('Failed to share post');
      }
    } catch (error: any) {
      customToast.error('Failed to share post');
    } finally {
      setIsPosting(false);
      handleModelPost(false);
    }
  };

  const showEmbedCode = () => {
    const embedUrl = covertSharedToEmbedUrl(shareURL);

    if (isNil(embedUrl)) {
      customToast.error('Embed code could not be generated');
      return;
    }

    setEmbedCode(
      generateIframe({ embedUrl: embedUrl, embedTitle: media.metadata.title }),
    );
    setIsShowEmbed(!isShowEmbed);
  };

  return (
    <div css={customShareModalCss}>
      <Modal show={openModal} modalClosed={handleModalClose}>
        <div tw="flex-row">
          <div
            tw="font-bold text-4xl text-black mt-5"
            style={{ fontFamily: 'Roboto' }}
          >
            Share content
          </div>
          <div tw="flex justify-center mt-4 ">
            <Divider />
          </div>
          <div className="content-share-social">
            <div tw="flex flex-wrap">
              <div className="link-social">
                <div className="link-social-element">
                  <a type="button" onClick={() => authorizationLinkedIn()}>
                    <ShareLinkedin />
                  </a>
                  <p>LinkedIn</p>
                </div>
                <div className="link-social-element">
                  <a type="button" onClick={() => showEmbedCode()}>
                    <ShareEmbed />
                  </a>
                  <p>Embed</p>
                </div>
              </div>
              <div className="link-social">
                <div
                  className="link-social-element"
                  data-href="https://developers.facebook.com/docs/plugins/"
                  data-layout="button"
                  data-size="large"
                >
                  <a
                    type="button"
                    onClick={() => authorizationFacebook(shareURL, shareId)}
                  >
                    <ShareFacebook />
                  </a>
                  <p>Facebook </p>
                </div>
                <div className="link-social-element">
                  <a type="button" onClick={() => authorizationTwitter()}>
                    <ShareTwitter
                      tw="h-[4.8rem] w-[4.8rem] bg-black rounded-full p-2"
                      className="svg-shadow-hover"
                    />
                  </a>
                  <p>Twitter</p>
                </div>
              </div>
              <div className="link-social">
                {canShareYoutube && (
                  <div className="link-social-element">
                    <a type="button" onClick={() => authorizationYoutube()}>
                      <ShareYoutube
                        tw="h-20 mt--1 width[6rem]"
                        className="svg-shadow-hover"
                      />
                    </a>
                    <p>Youtube</p>
                  </div>
                )}
                {canShareTikTok && (
                  <div className="link-social-element">
                    <a type="button" onClick={() => authorizationTiktok()}>
                      <ShareTiktok
                        tw="h-20 mt--1"
                        className="svg-shadow-hover"
                      />
                      <p>TikTok</p>
                    </a>
                  </div>
                )}
                {canShareInstagram && (
                  <div className="link-social-element" tw="mr-0!">
                    <a
                      type="button"
                      tw="text-center"
                      onClick={() => authorizationInstagram()}
                    >
                      <ShareInstagram
                        tw="h-20 mt--1"
                        className="svg-shadow-hover"
                      />
                      <p>Instagram</p>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div tw="flex justify-center mt-4 ">
            <Divider tw=" " />
          </div>
        </div>
        <div
          tw="font-bold text-4xl text-black mt-5 mb-2"
          style={{ fontFamily: 'Roboto' }}
        >
          Share link
        </div>
        <div tw="flex-1 relative mr-4 ">
          <input type="text" value={shareURL} maxLength={100} tw="pr-24!" />
          <a
            type="button"
            tw="absolute  top-2.5 right-5 "
            onClick={() => {
              shareURL && customToast.success('Copied to clipboard');
              copyToClipboard(shareURL);
            }}
          >
            copy
          </a>
        </div>
        {isShowEmbed && (
          <>
            <div
              tw="font-bold text-4xl text-black mt-5 mb-2"
              style={{ fontFamily: 'Roboto' }}
            >
              Embed clip:
            </div>
            <div tw="flex-1 relative mr-4 ">
              <textarea
                ref={textAreaRef}
                className="embed-area"
                rows={3}
                cols={50}
                readOnly={true}
                value={embedCode}
                tw="mt-1 border[2px solid #D8D8FF]!"
                css={[embedArea]}
              />
              <a
                type="button"
                tw="absolute top[6px] right[2px] px-3 rounded-lg font-size[14px]! background[#f0f3f6]"
                onClick={() => {
                  embedCode && customToast.success('Copied to clipboard');
                  // naviagtor.clipboard.writeText(embedCode);
                  textAreaRef.current?.select();
                  document.execCommand('copy');
                }}
              >
                copy
              </a>
            </div>
          </>
        )}
        <div className="public-post-share-modal">
          <Modal show={openModalPost} modalClosed={closeModalSharePost}>
            {isPosting ? (
              <div tw="h-full! flex justify-center py-24">
                {' '}
                <Loader />{' '}
              </div>
            ) : (
              <div>
                <div tw=" flex-row">
                  {isYoutubeShare && (
                    <>
                      <div tw="z-0">
                        <label
                          htmlFor="title"
                          tw="font-bold text-4xl text-black mt-5"
                        >
                          Title
                        </label>
                        <input
                          type="text"
                          value={clipTitle}
                          name="title"
                          tw="w-full mb-0"
                          disabled={false}
                          onChange={(e) => setClipTitle(e.target.value)}
                        />
                      </div>
                      <div tw="flex space-x-6">
                        <div tw="w-1/2">
                          <label
                            htmlFor="category"
                            tw="font-bold text-4xl text-black mt-3"
                          >
                            Category
                          </label>
                          <select
                            tw="height[40px] w-full mt-2 mb-3 outline-none cursor-pointer border[2px solid transparent] shadow-sm"
                            onChange={(e) => setClipCategory(e.target.value)}
                            css={[customSelect]}
                            value={clipCategory}
                            name="category"
                          >
                            <option value="1"> Film &amp; Animation</option>
                            <option value="2">Autos &amp; Vehicles</option>
                            <option value="10">Music</option>
                            <option value="15">Pets &amp; Animals</option>
                            <option value="17">Sports</option>
                            <option value="19">Travel &amp; Events</option>
                            <option value="20">Gaming</option>
                            <option value="22">People &amp; Blogs</option>
                            <option value="23">Comedy</option>
                            <option value="24">Entertainment</option>
                            <option value="25">News &amp; Politics</option>
                            <option value="26">How-to &amp; Style</option>
                            <option value="27">Education</option>
                            <option value="28">Science &amp; Technology</option>
                            <option value="29">
                              Non-profits &amp; Activism
                            </option>
                          </select>
                        </div>
                        <div tw="w-1/2">
                          <label
                            htmlFor="privacyStatus"
                            tw="font-bold text-4xl text-black mt-3"
                          >
                            Privacy status
                          </label>
                          <select
                            tw="height[40px] w-full mt-2 mb-3 outline-none cursor-pointer border[2px solid transparent] shadow-sm"
                            onChange={(e) => setPrivacyStatus(e.target.value)}
                            css={[customSelect]}
                            name="privacyStatus"
                          >
                            <option value="public">Public</option>
                            <option value="unlisted">Unlisted</option>
                            <option value="private">Private</option>
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    tw="font-bold text-4xl text-black mt-5"
                    style={{ fontFamily: 'Roboto' }}
                  >
                    {!isYoutubeShare ? 'Content' : 'Description'}
                  </div>
                  <div tw="flex-1 mt-4">
                    <div>
                      <textarea
                        className="textarea"
                        value={content}
                        style={{ resize: 'none' }}
                        onChange={handleChangeSummary}
                        tw="(p-6 pt-4)!"
                      />
                    </div>
                  </div>
                </div>
                <div
                  tw="font-bold text-4xl text-black mt-5 mb-2"
                  style={{ fontFamily: 'Roboto' }}
                >
                  {media?.metadata?.haveMp3 ? 'Audio' : 'Video'}
                </div>
                <div tw="flex-1 relative" css={plyrResetStyle}>
                  <video
                    ref={shareSocialVideoRef}
                    tw="w-full!"
                    css={[audioThumbStyles(media?.metadata?.thumbnail)]}
                    src={urlVideo + '#t=0.1'}
                    controls={true}
                    // autoPlay="true"
                    preload="auto"
                    crossOrigin="anonymous"
                    controlsList="nodownload"
                    onContextMenu={(e) => e.preventDefault()}
                    playsInline
                  >
                    <track
                      label="English"
                      kind="subtitles"
                      srcLang="en"
                      src={vttPath}
                      default
                    ></track>
                  </video>
                  <div className="grid-x" tw="flex justify-end mt-8 mb-3">
                    <button
                      style={{ marginLeft: 2 }}
                      type="button"
                      className="button btn-secondary large cancel small-3 cell"
                      tw="mr-5 mb-0"
                      onClick={handleModalClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={() => handleModalSharePost()}
                      className="button btn-primary  large apply"
                      tw="mb-0!"
                    >
                      Post
                    </button>
                  </div>
                </div>
              </div>
            )}
          </Modal>
        </div>
      </Modal>
      <ConfirmModal ref={confirmModalRef} />
    </div>
  );
};

const embedArea = css`
  & {
    overflow-y: scroll;
    font-size: 1.4rem !important;
    resize: none; /* Remove this if you want the user to resize the textarea */
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  &[rows] {
    height: auto;
    background: #f0f3f6;
    border-radius: 5px;
    border: none;
  }
`;

const customSelect = css`
  option {
    ${tw`cursor-pointer!`}
  }
`;

const customShareModalCss = css`
  .Modal {
    ${tw`sm-down:(width[90%]! left[calc(50% - 45%)]!)`}
  }
  .link-social .link-social-element {
    ${tw`sm-down:(mr-4 ml-4)`}
  }

  .link-social .link-social-element a {
    ${tw`sm-down:(height[36px]!)`}
  }

  .link-social .link-social-element a svg {
    ${tw`sm-down:(width[30px]! height[30px]!)`}
  }

  .link-social .link-social-element p {
    ${tw`sm-down:(font-size[12px]!)`}
  }
  .customClose {
    ${tw`sm-down:(color[black])`}
  }
`;
