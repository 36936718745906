/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import tw from 'twin.macro';
import React, { useState, useRef, useEffect } from 'react';
import { useClickAway, useToggle } from 'react-use';
import { ILibraryItem, IClips } from 'utils/models';
import { RootState } from 'reducers';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as Audio } from 'assets/Icons/audio_list.svg';
import { ReactComponent as Video } from 'assets/Icons/video_type.svg';
import {
  getMediaidFromClip,
  getStatusItem,
  isAudioByMIME,
  msToTime,
  toNumberAbbr,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { toNumber, isEqual } from 'lodash';
import { ThreeDotMenu } from 'components/shared/ThreeDotMenu/ThreeDotMenu';
import {
  putRenamePublishTitle,
  setFocusClip,
  setSelectedClips,
} from 'slices/clips.slice';
import { ClipStatusCode, Keys } from 'utils/enum';
import { Notification } from '../UiControls/notification/Notification';
import { toDateWithHours } from 'utils/date.util';
import { Hint } from 'components/shared/Hint';
import { PrettyCheckbox } from 'components/shared/PrettyCheckbox';

interface Props {
  item: IClips;
  index?: number;
  isOriginal?: boolean;
  libraryItem?: ILibraryItem;
  handleShare?: () => void;
  handleExport?: () => void;
  handlePublish?: () => void;
  handleShowPreviewPublish?: (startTime: number, endTime: number) => unknown;
  handleShowPreview?: any;
  handleDeleteVersionMedias?: (singleItem: IClips) => void;
  handleRedirectClick: (item: any) => void;
  handleIntegrate?: () => void;
  fromSearchClips?: boolean;
}

export const ListViewClipsItem = ({
  item,
  isOriginal,
  libraryItem,
  handleShare,
  handleExport,
  handlePublish,
  handleShowPreviewPublish,
  handleShowPreview,
  handleDeleteVersionMedias,
  handleRedirectClick,
  handleIntegrate,
  fromSearchClips,
}: Props) => {
  const dispatch = useDispatch();
  const clips = useSelector((state: RootState) => state.clips);
  const advancedSearch = useSelector(
    (state: RootState) => state.advancedSearch,
  );
  const [openMenu, setOpenMenu] = useState(false);
  const [isRenaming, toggleRenaming] = useToggle(false);
  const [itemTitle, setItemTitle] = useState<string>(item?.versionname ?? '');
  const [isHovering, toggleHovering] = useToggle(false);

  const [error, setError] = useState({
    isInvalidTitle: false,
  });

  const isShared = isEqual(
    toNumber(item?.statuscode),
    toNumber(ClipStatusCode.SHARED),
  );

  useEffect(() => {
    if (isRenaming === false && !error.isInvalidTitle) {
      setItemTitle(item?.versionname ?? '');
    }
  }, [item?.versionname]);

  const ref = useRef(null);

  const clickThreeDot = () => {
    dispatch(setFocusClip(item));
    setOpenMenu(!openMenu);
  };

  useClickAway(ref, () => {
    setOpenMenu(false);
    if (!itemTitle?.trim()) {
      setError({ isInvalidTitle: true });
    }

    if (error.isInvalidTitle) {
      setItemTitle(item?.versionname ?? '');
      closeInput();
    }
  });

  const isSelected = () =>
    clips.selectedClips?.some(
      (clip) =>
        isEqual(clip.versioncount, item.versioncount) &&
        isEqual(clip.media_id, item.media_id),
    );

  const handleItemClicked = () => {
    let cloneSelected = [...clips.selectedClips];

    if (isSelected()) {
      cloneSelected = cloneSelected.filter(
        (selectedClip) =>
          !(
            isEqual(selectedClip.versioncount, item.versioncount) &&
            isEqual(getMediaidFromClip(selectedClip), getMediaidFromClip(item))
          ),
      );
    } else {
      cloneSelected.push(item);
    }

    dispatch(setFocusClip(cloneSelected?.[0]));
    dispatch(setSelectedClips(cloneSelected));
  };

  const handleDeleteSingleItem = () => {
    handleDeleteVersionMedias?.(item);
  };

  const triggerSaveTitle = () => {
    if (isEqual(item?.versionname, itemTitle)) return;

    setItemTitle(itemTitle);
    toggleRenaming(false);
    dispatch(
      putRenamePublishTitle({
        mediaid: clips?.focusClip?.media_id!,
        versioncount: item?.versioncount,
        newTitle: itemTitle,
      }),
    );
  };

  const handleKeyDownTitle = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === Keys.ESC) {
      toggleRenaming(false);
      setError({ isInvalidTitle: false });
      setItemTitle(item?.versionname ?? '');
    } else if (event.key === Keys.ENTER || event.key === Keys.ENTER_NUMPAD) {
      if (!itemTitle) {
        return setError({ isInvalidTitle: true });
      }
      triggerSaveTitle();
    }
  };

  const closeInput = () => {
    if (error.isInvalidTitle) setItemTitle(item?.versionname ?? '');
    setError({ isInvalidTitle: false });
    toggleRenaming(false);
  };

  return (
    <tr
      className={
        clips.isSelectMode ? ` list-container ${isSelected() && 'active'}` : ''
      }
      css={[isOriginal && tw`bg-sonnant-grey-4!`, tw`pt-6`]}
      onClick={() => {
        if (fromSearchClips) {
          if (clips.isSelectMode) {
            handleItemClicked();
            return;
          }
          handleRedirectClick(item);
          return;
        }

        if (!clips.isSelectMode) {
          handleShowPreview?.(item);
          return;
        }
        handleItemClicked();
      }}
      onMouseEnter={() => toggleHovering(true)}
      onMouseLeave={() => toggleHovering(false)}
    >
      <td>
        {(isHovering || isSelected()) && (
          <div className="select_button">
            <PrettyCheckbox
              checked={isSelected()}
              isListView
              onChange={handleItemClicked}
            />
          </div>
        )}
      </td>
      <td>
        <div
          className="overlay_lock_icon"
          tw="flex! items-center! justify-center"
        >
          {isAudioByMIME(item?.mediatype) ? (
            <Hint text="Audio file">
              <Audio />
            </Hint>
          ) : (
            <Hint text="Video file">
              <Video />
            </Hint>
          )}
        </div>
      </td>
      <td>
        {isRenaming ? (
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              name="title"
              onKeyDown={handleKeyDownTitle}
              value={itemTitle}
              onChange={(e) => setItemTitle(e.target.value)}
              onBlur={closeInput}
              placeholder="Title"
              maxLength={128}
              autoFocus={true}
            />
            <Notification
              direction=""
              name={'emailError'}
              customStyle={{ top: '3.5rem', left: 0 }}
              close={closeInput}
              type={error.isInvalidTitle ? 'error' : null}
              message={'Invalid Title.'}
            />
          </div>
        ) : (
          <div className="title_block" key={item.versioncount}>
            <div tw="font-size[1.5rem] text-sonnant-darker!">
              {/* {item.versionname} */}
              {itemTitle}
            </div>
          </div>
        )}
      </td>
      <td>{`${msToTime(toNumber(item?.length))}`}</td>
      <td>{`${
        fromSearchClips
          ? item?.createddatetime
          : toDateWithHours(item?.datetime ?? item?.updated_at)
      }`}</td>
      <td tw="text-center">
        {(isShared || advancedSearch.isShowClipsOnly) &&
          toNumberAbbr(item.viewed)}
      </td>
      <td>{getStatusItem(item)}</td>
      <td
        className="customThumbnail"
        onClick={(e) => {
          if (!clips?.isSelectMode) {
            e.stopPropagation();
          }
        }}
      >
        <div ref={ref} className="dropdown show">
          <ThreeDotMenu
            openMenu={clips?.isSelectMode ? false : openMenu}
            clickThreeDot={clickThreeDot}
            libraryItem={libraryItem}
            itemClips={item}
            handleRename={() => toggleRenaming(true)}
            handleShare={handleShare}
            handleExport={handleExport}
            handlePublish={handlePublish}
            handleShowPreviewPublish={handleShowPreviewPublish}
            handleDelete={handleDeleteSingleItem}
            handleIntegrate={handleIntegrate}
            fromSearchClips
          />
        </div>
      </td>
    </tr>
  );
};
