/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import axios from 'axios';
import {
  cutNegativeTime,
  shouldUsePlyr,
  mergeSameTerm,
  mergeSameTermEntities,
  normalizeSummary,
  processChapters,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { isArray, isEmpty, isNil, toNumber } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { setLayerList } from 'slices/layers.slice';
import { setMediaItem } from 'slices/media.slice';
import { setCaption, setInitialValue, setSummary } from 'slices/player.slice';
import { CaptionStatus } from 'utils/enum';
import { IMetadata } from 'utils/models';
import { MediaControl } from 'components/VideoPlayer/Transcription/MediaPlayer/Player/components/MediaControl';
import { CaptionEditor } from 'components/VideoPlayer/Caption/CaptionEditor';
import { RootState } from 'reducers';
import { LayerNavigator } from 'components/VideoPlayer/Transcription/MediaPlayer/Player/components/LayerNavigator';
import { Hint } from 'components/shared/Hint';
import { ReactComponent as ShareSvg } from '../../../assets/Icons/Share.svg';
import { ReactComponent as ExportSvg } from '../../../assets/Icons/Export.svg';
import { setChapter } from 'slices/chapter.slice';
import { toggleFollowing } from 'slices/toggle.slice';
import { setLoadCompleted } from 'slices/player.slice';
import { Switcher } from 'components/shared/Switcher';
// import { ReactComponent as Search } from '../../../assets/Icons/search.svg';
import { SharePublishModal } from 'components/shared/modals/SharePublishModal';
import PublicExport from '../../Export/PublicExport';
import * as actionTypes from 'actions/types';
import { Modal } from '../../UiControls/modal/modal';
import Loader from 'components/loader/loader';
import { getLayerName, removeHTMLTag } from 'utils/utils';
import SonnantLogo from 'components/UiControls/homeSVG/sonnantlogo';
import { hoverDropShadow } from 'components/shared/twin.styles';
import { MediaService } from 'services';
import { customToast } from 'utils/toast.util';
import { useEmbedParams } from 'hooks/useEmbedParams';

export const PublicEmbed = (props: any) => {
  const dispatch = useDispatch();
  const mediaElement = useRef<any>(null);

  let shareId: any;
  const player = useSelector((state: RootState) => state.player);
  const toggle = useSelector((state: RootState) => state.toggle);
  const media = useSelector((state: RootState) => state.media);
  const layers = useSelector((state: RootState) => state.layers);

  const noTranscript = isArray(player?.caption) && isEmpty(player?.caption);

  const {
    hasSummary,
    hasTranscript,
    hasPlayer,
    hasKeyTerms,
    hasExport,
    hasShare,
    hasLogo,
  } = useEmbedParams();

  const [filename, setFilename] = useState<any>();
  const [mediaSummary, setMediadSummary] = useState<any>();
  const [isWaterMark, setIsWaterMark] = useState(true);
  const [fetching, toggleFetching] = useToggle(false);
  const [length, setLength] = useState(0);

  const [openModal, handleModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  // const [inputSearch, setInputSearch] = useState('');

  const [shareOptions, setShareOptions] = useState({
    hasKeyTerm: true,
    hasSummary: true,
    hasPeople: true,
    hasProducts: true,
    hasOrgs: true,
    hasLocations: true,
    hasNationalities: true,
    hasChapter: true,
    hasGlobalList: true,
    mediaid: '',
    mpx: '',
    filename: '',
    versioncount: '',
  });
  const [exportOptions, setExportOptions] = useState({
    allowMediaDownload: true,
    allowTranscriptDownload: true,
    allowCaptionDownload: false,
    haveMp3: false,
    haveMp4: false,
    isBurnedIn: false,
  });

  const handleModalClose = () => {
    handleModal(false);
  };

  const handleExportModalClosed = () => {
    dispatch({ type: actionTypes.UNSET_PUBLIC_EXPORT_CONTENT_ITEM });
    setOpenExportModal(false);
  };

  const canShowExport = () => {
    return (
      props.exportOptions.allowMediaDownload ||
      props.exportOptions.allowCaptionDownload
    );
  };

  useEffect(() => {
    async function fetchData() {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      shareId = window.location.pathname.split('-').splice(-1);
      shareId = shareId[shareId.length - 1];
      getMediaDetails();

      window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    }
    fetchData();
  }, [shareId]);

  const patchResponseAPI = async (mediaData: any) => {
    dispatch(setMediaItem(mediaData));

    const jsonUrl = mediaData?.jsonfileurl;
    if (!jsonUrl) {
      customToast.error('Cannot load resource file');
      return;
    }

    try {
      toggleFetching(true);
      const { data: transcriptData } = await axios.get(jsonUrl);
      toggleFetching(false);
      setLength(toNumber(transcriptData?.length) ?? 0);
      const uniqKeywords = mergeSameTerm(transcriptData.keywords);
      const uniqEntities = mergeSameTermEntities(transcriptData.entities);
      setMediadSummary(normalizeSummary(transcriptData.summary));

      dispatch(
        setInitialValue({
          keywords: uniqKeywords,
          entities: uniqEntities,
        }),
      );

      dispatch(
        setCaption({
          captions: cutNegativeTime(transcriptData.captions),
          rawPara: transcriptData.paragraphs,
          speakers: transcriptData?.speakers,
        }),
      );

      dispatch(
        setChapter(
          processChapters(transcriptData.chapters, transcriptData.captions),
        ),
      );

      dispatch(
        setSummary({
          summary: normalizeSummary(transcriptData.summary),
          mediaid: null,
        }),
      );

      dispatch(setLoadCompleted(true));
    } catch (err: any) {
      toggleFetching(false);
      if ([404].includes(err?.response?.status)) {
        customToast.error('Resource not found');
      }
    }
  };

  const showHideCaption = (captionStatus: any) => {
    if (shouldUsePlyr()) return;

    for (let i = 0; i < mediaElement?.current?.textTracks.length; i++) {
      if (captionStatus === CaptionStatus.OPEN_CAPTION) {
        mediaElement.current.textTracks[i].mode = 'hidden';
      }

      if (captionStatus === CaptionStatus.CLOSED_CAPTION) {
        mediaElement.current.textTracks[i].mode = 'showing';
      }
    }
  };

  const getMediaDetails = async () => {
    try {
      toggleFetching(true);
      dispatch(setLoadCompleted(false));
      const mediaData = await MediaService.getPublicDetails(shareId);

      patchResponseAPI(mediaData.data);

      const metadata: IMetadata = mediaData?.data.metadata;

      const options: any = {
        hasSummary: !!metadata?.summaries,
        hasChapter: !!metadata?.chapters,
        hasKeyTerm: !!metadata?.keyterms,
        hasPeople: !!metadata?.names,
        hasProducts: !!metadata?.products,
        hasOrgs: !!metadata?.orgs,
        hasLocations: !!metadata?.locations,
        hasNationalities: !!metadata?.nationalities,
        hasDownload: !!metadata?.download,
        hasGlobalList: !!metadata?.globalList,
        hasSpeakers: !!metadata?.speakers,
        mediaid: metadata?.mediaid,
        mpx: metadata?.mediacontenttype,
        filename: metadata?.filename,
        versioncount: metadata?.versioncount,
      };

      setShareOptions(options);
      dispatch(setLayerList(options));

      if (!isNil(metadata?.watermark)) {
        setIsWaterMark(metadata?.watermark);
      }

      setExportOptions({
        allowMediaDownload: true,
        allowCaptionDownload: !!metadata?.captiondownload,
        allowTranscriptDownload: !!metadata?.download,
        haveMp3: metadata?.haveMp3 === true,
        haveMp4: metadata?.haveMp4 === true,
        isBurnedIn: metadata?.captionstype === 'OPEN',
      });

      mediaData &&
        mediaData.data &&
        mediaData.data.versionname &&
        setFilename(mediaData.data.versionname);

      showHideCaption(metadata?.captionstype);
    } catch (err: any) {
      console.log('err :>> ', err);
      toggleFetching(false);
    }
  };

  return (
    <>
      {fetching && (
        <div className="loader__component" tw="opacity[.95]">
          <Loader />
        </div>
      )}

      <div tw="height[calc(100vh)] px-8 py-3 pb-0! flex flex-col text-sonnant-dark overflow-y-auto">
        <div className="header" tw="flex flex-col mb-3">
          <h3>{filename}</h3>
          {hasSummary && (
            <div
              className="summary"
              tw="text-17 md-down:height[20vh] md-down:overflow-y-auto "
            >
              <p>{removeHTMLTag(mediaSummary)}</p>
            </div>
          )}
        </div>
        <div
          className="media-container"
          tw="flex-1 flex gap-8 md-down:flex-col!"
          css={[
            hasTranscript
              ? hasKeyTerms
                ? tw`flex-row`
                : tw`flex-col`
              : tw`flex-col`,
          ]}
        >
          {hasPlayer && (
            <div
              className="player-container"
              tw="relative max-height[40vh] md-down:w-full! md-down:mt-4"
              css={[hasKeyTerms && hasTranscript ? tw`width[55%]` : tw`w-full`]}
            >
              <MediaControl
                ref={mediaElement}
                hasWatermark={isWaterMark}
                isMediaLoading={false}
              />
            </div>
          )}

          <div
            className="keyterms-container"
            tw="flex-1 flex flex-col"
            css={[customKeyTermsCss]}
          >
            {hasKeyTerms && (
              <div tw="flex-1 flex flex-col">
                <h3>{getLayerName(layers.currentLayer)}</h3>
                <LayerNavigator isPublicPlayer={true} isEditing={false} />
              </div>
            )}
            <div
              tw="flex w-full justify-end"
              css={[(hasExport || hasShare) && tw`mt-7!`]}
            >
              {hasShare && (
                <Hint text="Share this file and its clips to socials, web or public player">
                  <a
                    className="button large btn-secondary"
                    tw="flex justify-center items-center font-medium"
                    onClick={() => handleModal(true)}
                  >
                    <ShareSvg tw="mr-4" /> <span>Share</span>
                  </a>
                </Hint>
              )}

              {hasExport && (
                <Hint
                  text={
                    noTranscript
                      ? 'There are no transcript to export'
                      : 'Export assets from this file'
                  }
                >
                  <span css={[noTranscript && tw`cursor-not-allowed`]}>
                    <a
                      className={`button large btn-secondary`}
                      tw="flex justify-center items-center font-medium ml-6"
                      onClick={() => {
                        if (!canShowExport) return;
                        setOpenExportModal(true);
                      }}
                      css={[
                        noTranscript && tw`opacity[0.5] pointer-events-none`,
                      ]}
                    >
                      <ExportSvg tw="mr-4" /> <span>Export</span>
                    </a>
                  </span>
                </Hint>
              )}

              {hasLogo && (
                <div tw="ml-6 cursor-pointer" css={hoverDropShadow(0.2)}>
                  <a
                    href="https://sonnant.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SonnantLogo />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        {hasTranscript && (
          <>
            <div tw="flex flex-row justify-between items-center my-4">
              <div tw="flex flex-row items-center space-x-32">
                <h3 tw="mb-0">Transcripts</h3>
                {/* <div tw="flex mt-3">
                  <input
                    type="text"
                    placeholder="Search"
                    maxLength={100}
                    tw="( w-full rounded-r-none)!"
                    onChange={(e) => setInputSearch(e.target.value)}
                  />
                  <button
                    type="button"
                    tw="(rounded-l-none)!"
                    className="button btn-secondary tiny searchSpan"
                    onClick={handleOnSearch}
                  >
                    <Search />
                  </button>
                </div> */}
              </div>
              <div>
                <Switcher
                  title="Scroll transcript with play"
                  checked={toggle.isFollowing}
                  onCheckedChange={(isOn) => dispatch(toggleFollowing(isOn))}
                />
              </div>
            </div>
            <div
              className="transcript-container"
              tw="overflow-y-auto flex-1 min-height[220px]"
              css={[customTranscript]}
            >
              {!player.isParagraphMode && !isEmpty(player?.caption) && (
                <CaptionEditor />
              )}
              {noTranscript && (
                <div tw="font-medium text-sonnant-grey-6 text-16 mt-5">
                  There are no transcripts for this content item
                </div>
              )}
            </div>
          </>
        )}
        <div className="public-share-social-modal">
          <SharePublishModal
            openModal={openModal}
            handleModalClose={handleModalClose}
            shareURL={
              window.location.origin +
              window.location.pathname.replace('/embed', '/shared')
            }
            mobile={false}
            length={length}
          />
        </div>
        <div>
          <Modal show={openExportModal} modalClosed={handleExportModalClosed}>
            <PublicExport
              exportOptions={exportOptions}
              mediaid={media?.metadata?.mediaid}
              filename={media?.metadata?.filename}
              mpx={shareOptions.mpx}
              versioncount={shareOptions.versioncount}
              closeExportModal={handleExportModalClosed}
            />
          </Modal>
        </div>
      </div>
    </>
  );
};

const customTranscript = css`
  &::-webkit-scrollbar-thumb {
    background-color: unset !important;
  }
`;

const customKeyTermsCss = css`
  .keywords {
    height: unset !important;
  }

  .key-scroll-wrapper {
    max-height: 162px;
    overflow: auto;
  }
`;
