/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import axios, { AxiosError } from 'axios';
import Plyr from 'plyr';

import {
  cutNegativeTime,
  embedToShareUrl,
  getLatestTitle,
  mergeSameTerm,
  mergeSameTermEntities,
  normalizeSummary,
  processChapters,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import Loader from 'components/loader/loader';
import { useEmbedParams } from 'hooks/useEmbedParams';
import { useShareId } from 'hooks/useShareId';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce, useToggle, useVideo } from 'react-use';
import { RootState } from 'reducers';
import { MediaService } from 'services';
import { setChapter } from 'slices/chapter.slice';
import {
  setEmbedExportOptions,
  setEmbedParams,
  setPublicShareUrl,
} from 'slices/embed.slice';
import { setMediaItem, setWaveform } from 'slices/media.slice';
import {
  setCaption,
  setInitialValue,
  setLoadCompleted,
  setSummary,
} from 'slices/player.slice';
import { CaptionStatus, HTTPStatus, Layers } from 'utils/enum';
import { EmbedListLayer, IMetadata } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { EmbedTimeTrack } from './partials/EmbedPlayer/EmbedTimeTrack';
import { EmbedTitleBar } from './partials/EmbedPlayer/EmbedTitleBar';
import { EmbedToolkit } from './partials/EmbedPlayer/EmbedToolkit';
import { EmbedTranscript } from './partials/EmbedTranscript/EmbedTranscript';
import { MediaPlayerHook } from './utils/embed.model';
import { EmbedContext } from './utils/useEmbedContext';
import { isEmpty, isNil } from 'lodash';
import { match } from 'ts-pattern';
import { EmbedError } from './shared/EmbedError';
import { setIABCategoriesS3 } from 'slices/iab.slice';
import { setCurrentLayer, setLayerList } from 'slices/layers.slice';

type Props = {};

export const PublicEmbedV2 = (props: Props) => {
  const dispatch = useDispatch();

  const shareId = useShareId();
  const embedParams = useEmbedParams();

  const [fetching, toggleFetching] = useToggle(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const media = useSelector((state: RootState) => state.media);

  const isVideo =
    !!media.metadata?.haveMp4 &&
    media.metadata?.captionstype !== CaptionStatus.OPEN_CAPTION;

  const [video, videoState, controls, videoRef] = useVideo(
    <video
      tw="max-h-full"
      css={[!isVideo && tw`hidden!`]}
      src={media?.url}
      controls={false}
      autoPlay={false}
      preload="auto"
      crossOrigin="anonymous"
      controlsList="nodownload"
      playsInline
      onClick={() => (videoState.paused ? controls.play() : controls.pause())}
      onContextMenu={(e) => e.preventDefault()}
    >
      {/* <track
        label="English"
        kind="subtitles"
        srcLang="en"
        src={media.subtitleurl}
      ></track> */}
    </video>,
  );

  const mediaPlayer: MediaPlayerHook = {
    video,
    videoState,
    controls,
    videoRef,
  };

  useLayoutEffect(() => {
    if (!videoRef.current) return;

    new Plyr(videoRef.current, {
      captions: { active: false, update: false },
      fullscreen: { enabled: false },
      hideControls: true,
      clickToPlay: true,
      controls: ['play-large'],
      enabled: isVideo,
      storage: { enabled: false },
    });

    window.Video = videoRef.current;
  }, [videoRef.current]);

  useEffectOnce(() => {
    dispatch(setEmbedParams(embedParams));

    const shareUrl = embedToShareUrl();
    dispatch(setPublicShareUrl(shareUrl));
  });

  useEffect(() => {
    getMediaDetails();
  }, [shareId]);

  const patchResponseAPI = async (mediaData: any) => {
    dispatch(setMediaItem(mediaData));

    const jsonUrl = mediaData?.jsonfileurl;
    if (!jsonUrl) {
      customToast.error('Cannot load resource file');
      return;
    }

    try {
      toggleFetching(true);
      const { data: transcriptData } = await axios.get(jsonUrl);
      toggleFetching(false);
      const uniqKeywords = mergeSameTerm(transcriptData.keywords);
      const uniqEntities = mergeSameTermEntities(transcriptData.entities);

      dispatch(
        setInitialValue({
          keywords: uniqKeywords,
          entities: uniqEntities,
        }),
      );

      dispatch(
        setCaption({
          captions: cutNegativeTime(transcriptData.captions),
          rawPara: transcriptData.paragraphs,
          speakers: transcriptData?.speakers,
        }),
      );

      dispatch(
        setChapter(
          processChapters(transcriptData.chapters, transcriptData.captions),
        ),
      );

      if (!isEmpty(transcriptData?.iabCategories)) {
        dispatch(setIABCategoriesS3(transcriptData.iabCategories));
      }

      dispatch(
        setSummary({
          summary: normalizeSummary(transcriptData.summary),
          mediaid: null,
        }),
      );

      if (transcriptData?.waveform) {
        dispatch(setWaveform(transcriptData.waveform));
      }

      dispatch(setLoadCompleted(true));
    } catch (err: any) {
      toggleFetching(false);

      if ([404].includes(err?.response?.status)) {
        customToast.error('Resource not found');
      }
    }
  };

  const getMediaDetails = async () => {
    try {
      toggleFetching(true);
      dispatch(setLoadCompleted(false));
      const mediaData = await MediaService.getPublicDetails(shareId);

      patchResponseAPI(mediaData.data);

      const metadata: IMetadata = mediaData?.data.metadata;

      const options: EmbedListLayer = {
        hasTranscript: true,
        hasSummary: Boolean(metadata?.summaries),
        hasIAB: Boolean(metadata?.iab),
        hasChapter: Boolean(metadata?.chapters),
        hasKeyTerm: Boolean(metadata?.keyterms),
        hasPeople: Boolean(metadata?.names),
        hasProducts: Boolean(metadata?.products),
        hasOrgs: Boolean(metadata?.orgs),
        hasLocations: Boolean(metadata?.locations),
        hasNationalities: Boolean(metadata?.nationalities),
      };

      dispatch(setLayerList(options));
      dispatch(setCurrentLayer(Layers.TRANSCRIPT));

      dispatch(
        setEmbedExportOptions({
          allowMediaDownload: true,
          allowCaptionDownload: !!metadata?.captiondownload,
          allowTranscriptDownload: !!metadata?.download,
          haveMp3: metadata?.haveMp3 === true,
          haveMp4: metadata?.haveMp4 === true,
          isBurnedIn: metadata?.captionstype === CaptionStatus.OPEN_CAPTION,
        }),
      );

      setErrorMessage(null);
    } catch (error) {
      console.log('err :>> ', error);

      const statusCode = (error as AxiosError).response?.status;

      match(statusCode)
        .with(HTTPStatus.REVOKED, () => {
          setErrorMessage('This item has been revoked from embedded sharing.');
        })
        .with(HTTPStatus.SUBSCRIPTION_EXPIRED, () => {
          setErrorMessage(
            'This URL is currently inactive. Please upgrade to activate it.',
          );
        })
        .otherwise(() => {
          setErrorMessage('Something went wrong!');
        });
    } finally {
      toggleFetching(false);
    }
  };

  if (fetching || !shareId) {
    return (
      <div className="loader__component" tw="opacity[.95]">
        <Loader />
      </div>
    );
  }

  if (!isNil(errorMessage)) {
    return (
      <EmbedError
        hasTranscript={embedParams.hasTranscript}
        errorMessage={errorMessage}
      />
    );
  }

  return (
    <EmbedContext.Provider value={mediaPlayer}>
      <div tw="h-screen flex flex-col border[1px solid] border-sonnant-grey-light shadow">
        <div tw="flex gap-x-5 items-center z-[1] p-3 pr-5 h-[18rem]">
          <div tw="h-full rounded" css={[customPlayerCss]}>
            {video}
            <img
              tw="max-h-full"
              css={[isVideo && tw`hidden`]}
              src={media.metadata?.thumbnail}
              alt={getLatestTitle(media.metadata)}
            />
          </div>

          <div tw="flex-1 flex flex-col gap-y-2 h-full text-sonnant-dark">
            <EmbedTitleBar />
            <EmbedTimeTrack />
            <EmbedToolkit />
          </div>
        </div>

        {embedParams.hasTranscript && (
          <div tw="overflow-hidden">
            <hr tw="(max-w-[unset] my-0 mx-3)!" />

            <EmbedTranscript />
          </div>
        )}
      </div>
    </EmbedContext.Provider>
  );
};

const customPlayerCss = css`
  .plyr.plyr--video.plyr--html5 {
    ${tw`h-full`}
  }

  button.plyr__control {
    ${tw`rounded-full`}
  }
`;
