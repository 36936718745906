/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Modal } from 'components/UiControls/modal/modal';
import { isNil } from 'lodash';
import React, {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { toDateWithHours } from 'utils/date.util';
import { ModalOptions } from 'utils/enum';
import { ConfirmModalProps } from 'utils/models';
import { SocialConnectionShowPayload } from 'utils/models/modal.model';
import { textLink } from '../twin.styles';

interface IPromiseParams {
  resolve: (option: ModalOptions) => void;
  reject: (err: any) => void;
}

export const SocialConnectionModal = forwardRef(
  (parentProps: ConfirmModalProps, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const location = useSelector(
      (state: RootState) => state.userProfile.location,
    );

    const [props, setProps] = useState<SocialConnectionShowPayload | null>(
      null,
    );
    const [isOpen, setIsOpen] = useState(false);

    const channelInfo = props?.payload.channel_info;

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (
      showProps?: SocialConnectionShowPayload,
    ): Promise<unknown> => {
      if (!isNil(showProps)) {
        setProps(showProps);
      }

      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const hideModal = () => setIsOpen(false);

    const handleYes = () => {
      hideModal();
      promiseInfo.current?.resolve(ModalOptions.YES);
    };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current?.resolve(ModalOptions.CANCEL);
    };

    return (
      <Modal
        classes={parentProps?.classes}
        show={isOpen}
        modalClosed={() => {
          hideModal();
          promiseInfo.current?.resolve(ModalOptions.CLOSE);
        }}
      >
        <div>
          <h1 tw="font-semibold text-sonnant-dark font-size[3.5rem]">
            {props?.title}
          </h1>

          <div tw="mt-3 mb-5">
            <div tw="flex items-center gap-x-5 px-3 py-1.5 bg-blue-100 rounded shadow">
              <div>
                <img
                  src={channelInfo?.thumbnails.default.url}
                  alt={channelInfo?.title}
                  tw="rounded"
                />
              </div>
              <div tw="text-sonnant-dark">
                <div tw="font-medium text-18">
                  {channelInfo?.localized.title}
                </div>
                <div tw="text-13 opacity-80">
                  {channelInfo?.localized.description}
                </div>
                <a
                  href={`https://www.youtube.com/${channelInfo?.customUrl}`}
                  tw="italic text-13 opacity-90"
                  css={[textLink]}
                  target="_blank"
                  rel="noreferrer"
                >
                  {channelInfo?.customUrl}
                </a>
                {channelInfo?.publishedAt && (
                  <div tw="text-15">
                    Published at:{' '}
                    {toDateWithHours(channelInfo.publishedAt, location)}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            <div tw="w-full flex justify-end gap-x-5">
              <button
                type="button"
                className="button btn-secondary large"
                onClick={handleCancel}
                tw="(px-4 max-width[unset] width[auto])!"
              >
                Use another account
              </button>

              <button
                type="button"
                className="button btn-primary large"
                tw="hover:(border-sonnant-grey-3)! (px-4 max-width[unset] width[auto])!"
                onClick={handleYes}
              >
                Use connected account
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);
