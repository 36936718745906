/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-mixed-operators */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { isEmpty, isUndefined, isNull, keys } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REGEX } from 'utils/regex';
import * as actions from '../../../actions';
import * as actionTypes from '../../../actions/types';
import { RootState } from 'reducers';
import { ReactComponent as Paramount } from '../../../assets/Icons/Avatar_Photo.svg';
import LocationsList from '../../../assets/Mockdata/locations.json';
import {
  AlertBlock,
  formateImageURL,
  getSignUpProvider,
  validatePassword,
  validateUserName,
} from '../../../utils/utils';
import Loader from '../../loader/loader';
import { Modal } from '../../UiControls/modal/modal';
import '../UserProfile/UserProfile.scss';
import { UpdatePassword } from './UpdatePassword';
import { UserService } from 'services';
import { customToast } from 'utils/toast.util';
import { CustomSelectSearch } from 'components/shared/CustomSelectSearch';
import { ResetBottomBar } from 'components/shared/ResetBottomBar/ResetBottomBar';

interface ILocationList {
  label: string;
  value: string;
}

export const UserProfile = () => {
  const dispatch = useDispatch();
  const defaultuserDetails = useSelector(
    (state: RootState | any) => state.userProfile,
  );
  const displayAlert = useSelector((state: RootState) => state.alerts);
  const { subscription } = useSelector((state: RootState) => state.payment);

  const [openModal, handleModal] = useState(false);
  const [openModalUpdateUser, handleModalUpdateUser] = useState(false);
  const [userDetails, setUserDetails] = useState(defaultuserDetails);
  const [error, setError] = useState({
    oldpassword: true,
    newpassword: true,
    confirmpassword: true,
    isvalid: true,
    ismatch: true,
  });
  const [userPasswordDetails, setUserPassword] = useState({
    oldpassword: '',
    newpassword: '',
    confirmpassword: '',
  });
  const [isUserProfileUpdated, setUserProfileUpdated] = useState(false);
  const [userProfilePic, setUserProfilePic] = useState('NULL');
  const [avatarBase64, setAvatarBase64] = useState<
    string | ArrayBuffer | null
  >();
  const [avatar, setAvatar] = useState<any>(null);

  const profilePic = useRef<any>(null);
  const locationIndex = useRef<any>(null);

  const { statusMessage, isSuccess } = displayAlert;
  const { firstname, lastname, email, location, loading } = userDetails;

  useEffect(() => {
    setUserDetails(defaultuserDetails);
    getSelectedIndex(defaultuserDetails.location);
    if (
      defaultuserDetails &&
      defaultuserDetails.image &&
      defaultuserDetails !== 'NULL' &&
      !avatarBase64
    ) {
      let image = formateImageURL(defaultuserDetails.image);
      profilePic.current.src = image;
      setUserProfilePic(image);
    }
  }, [defaultuserDetails]);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target;

    if (value === validateUserName(value)) {
      setUserDetails((prevState: RootState) => ({
        ...prevState,
        [name]: validateUserName(value),
      }));
      setUserProfileUpdated(true);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let formData = userPasswordDetails as any;
    formData[name] = value;
    handleError(name, value, formData);
    setUserPassword((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleError = (name: string, value: string, data: any) => {
    let errordata = validatePassword(name, value, data, error);
    setError({ ...error, ...errordata.data });
  };

  const setIntialState = () => {
    return {
      oldpassword: true,
      newpassword: true,
      confirmpassword: true,
      isvalid: true,
      ismatch: true,
    };
  };

  const handleReset = () => {
    if (defaultuserDetails.image && defaultuserDetails.image !== 'NULL') {
      setUserProfilePic(defaultuserDetails.image);
      if (profilePic.current) {
        profilePic.current.src = formateImageURL(defaultuserDetails.image);
      }
    }
    setUserDetails(defaultuserDetails);
    setUserProfileUpdated(false);
  };

  const displayModal = (e: React.MouseEvent) => {
    e.preventDefault();
    handleModal(true);
  };

  const handleModalClose = () => {
    handleModal(false);
    setUserPassword({
      oldpassword: '',
      newpassword: '',
      confirmpassword: '',
    });
    setError({ ...error, ...setIntialState() });
    handleClose();
  };

  const handleClose = () => {
    dispatch(actions.handleStatusAlertClose());
  };

  const handleNotificationClose = (property?: string | any) => {
    if (property === 'oldpassword') {
      setError((prevState) => ({ ...prevState, [property]: true }));
    } else if (property === 'newpassword') {
      setError((prevState) => ({
        ...prevState,
        [property]: true,
        isvalid: true,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        [property]: true,
        ismatch: true,
      }));
    }
  };

  const handlePasswordUpdate = () => {
    const { newpassword, oldpassword } = userPasswordDetails;
    dispatch({
      type: actionTypes.INITIATE_PASSWORD_UPDATE,
      passwordsData: { newpassword: newpassword, currentpassword: oldpassword },
    });
    setUserPassword({
      oldpassword: '',
      newpassword: '',
      confirmpassword: '',
    });
  };

  const saveUserProfileData = async () => {
    const payload = {
      firstname,
      lastname,
      location,
    } as any;

    if (
      !isEmpty(avatarBase64) &&
      !isUndefined(avatarBase64) &&
      !isNull(avatarBase64)
    ) {
      payload.image = (avatarBase64 as string).split(',')[1];
      payload.extension = avatar?.name?.match(REGEX.FILE_EXTENSION)[0] || '';
    }

    const userUpdateAsync = UserService.updateUserDetails(payload);
    customToast.promise(userUpdateAsync, {
      loading: 'Updating...',
      success: 'User updated successfully',
      error: 'User update failed',
    });

    try {
      await userUpdateAsync;
      setUserProfileUpdated(false);
      dispatch({ type: 'GET_PROFILES' });
    } catch (error) {
      console.error('error :>> ', error);
    }
  };

  const getSelectedIndex = (location: string) => {
    let index = (LocationsList as any)[location];
    if (locationIndex.current) {
      locationIndex.current.value = index;
    }
  };

  const handleProfilePicUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    let file;
    if (e?.target?.files !== null) {
      file = e.target.files[0];
    }

    if (!file?.type.startsWith('image/')) {
      customToast.error('Invalid file format! Please try again');
      return;
    }
    setAvatar(file);

    if (file && file.size <= 4 * 1024 * 1024) {
      // 4MB limit
      var reader = new FileReader();
      reader.onloadend = function () {
        profilePic.current.src = reader.result;
        setAvatarBase64(reader.result);
        if (reader.result) {
          setUserProfilePic(
            reader.result.toString().replace(/^data:(.*,)?/, ''),
          );
        }
        setUserProfileUpdated(true);
      };
      reader.readAsDataURL(file);
    } else {
      customToast.error('Picture should be less than 4MB');
    }
  };
  const handleModalUpdateUserClose = () => {
    handleModalUpdateUser(false);
    handleClose();
  };

  const handleSelectLocation = (newValue: ILocationList) => {
    if (isNull(newValue)) {
      return;
    }

    setUserDetails((prevState: RootState) => ({
      ...prevState,
      location: newValue.value,
    }));

    if (newValue.value !== userDetails.location) {
      setUserProfileUpdated(true);
    }
  };

  return (
    <div>
      {(loading || subscription.isLoading) && (
        <div className="loader__component" tw="top[calc(13rem - 1px)]">
          <Loader />
        </div>
      )}
      <ResetBottomBar
        isShow={isUserProfileUpdated}
        onReset={handleReset}
        onSubmit={saveUserProfileData}
      />

      <div
        className="grid-container fluid"
        tw="overflow-y-auto height[calc(100vh - 13rem)]"
      >
        <div className="grid-x tenantProfile">
          <div className="cell large-6">
            <div className="tenantTitle">
              <h2>User Profile</h2>
            </div>
          </div>
        </div>
        <div className="grid-x tenantProfile_picture">
          <div className="cell large-6">
            <h3>Profile Picture</h3>
            <div className="mgn_btm">
              <span className="paramount">
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  id="userProfilePic"
                  onChange={handleProfilePicUpload}
                  tw="hidden"
                />
                <div className="imageWrapper">
                  <img
                    id="output"
                    className={`${
                      userProfilePic ? 'userProfilePic' : 'noImage'
                    }`}
                    tw="shadow"
                    ref={profilePic}
                  />

                  {!userProfilePic && <Paramount />}
                </div>
                <label className="paramount_title" htmlFor="userProfilePic">
                  Edit User Profile Picture
                </label>
              </span>
            </div>
            <h3>Profile Details</h3>
            <form onSubmit={e => e.preventDefault()}>
              <div className="grid-x grid-margin-x">
                <div className="cell small-6">
                  <label>
                    First Name
                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstname"
                      value={firstname}
                      onChange={handleChange}
                    />
                  </label>
                </div>
                <div className="cell grid-padding-x small-6">
                  <label>
                    Last Name
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lastname"
                      value={lastname}
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </div>

              <label>
                Email Address (Username)
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  defaultValue={email}
                  onChange={handleChange}
                  readOnly
                />
              </label>
              <h3>Update Password</h3>
              <div className="grid-x grid-margin-x">
                <button
                  disabled={['Google', 'Apple', 'Facebook'].includes(
                    getSignUpProvider(),
                  )}
                  className="button btn-secondary passwordUpdate"
                  onClick={displayModal}
                >
                  Update Password
                </button>
              </div>
              <h3>Location</h3>
              <div className="grid-x" tw="mb-8">
                <div className="cell small-4">
                  <label>
                    Country
                    <CustomSelectSearch
                      options={keys(LocationsList).map((item) => ({
                        label: item,
                        value: item,
                      }))}
                      onChange={handleSelectLocation}
                      defaultValue={userDetails.location}
                      canCreate={false}
                    />
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal show={openModal} modalClosed={handleModalClose}>
        <UpdatePassword
          modalClosed={handleModalClose}
          data={userPasswordDetails}
          handlePasswordChange={handlePasswordChange}
          handleNotificationClose={handleNotificationClose}
          handleClose={handleClose}
          handlePasswordUpdate={handlePasswordUpdate}
          error={error}
        />
      </Modal>
      <Modal
        show={openModalUpdateUser}
        modalClosed={handleModalUpdateUserClose}
      >
        <h2>Update User Profile Picture </h2>
        <div className="cell small-12">
          {statusMessage && (
            <AlertBlock
              statusMessage={statusMessage}
              isSuccess={isSuccess}
              hideClose={true}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};
