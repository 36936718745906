import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { first, isArray, isEmpty, isEqual } from 'lodash';
import { RootState } from 'reducers';
import { SortKey } from 'utils/models';
import { Layers } from '../utils/enum';
import { getLayerEnumList } from '../utils/utils';

export const layerSelector = createSelector(
  (state: RootState) => state.layers,
  (layers) => ({
    isTranscript: isEqual(layers.currentLayer, Layers.TRANSCRIPT),
    isSummary: isEqual(layers.currentLayer, Layers.SUMMARY),
    isKeyTerm: isEqual(layers.currentLayer, Layers.KEY_TERM),
    isChapter: isEqual(layers.currentLayer, Layers.CHAPTER),
    isAdMarker: isEqual(layers.currentLayer, Layers.AD_MARKER),
    isCustomTerms: isEqual(layers.currentLayer, Layers.CUSTOM_TERM),
    isIAB: isEqual(layers.currentLayer, Layers.IAB),
    isSpeaker: isEqual(layers.currentLayer, Layers.SPEAKER),
    isAdBreak: isEqual(layers.currentLayer, Layers.AD_BREAK),
    isSegment: isEqual(layers.currentLayer, Layers.SEGMENT),
    isGarmReport: isEqual(layers.currentLayer, Layers.GARM_REPORT),
    enum: layers.currentLayer,
  }),
);

export interface ISort {
  by: string;
  name: string;
  order?: SortKey;
  isAsc: boolean;
}

const layersSlice = createSlice({
  name: 'layers',
  initialState: {
    currentLayer: Layers.KEY_TERM,
    lastLayer: null as Layers | null,
    layerList: getLayerEnumList(),
    layerLength: getLayerEnumList().length,
    searchTerm: '' as string,
    sort: {
      by: 'alphabet' as 'alphabet' | 'relevance' | 'appearance',
      name: '',
      isAsc: true,
    } as ISort,
  },
  reducers: {
    prevLayer: (state) => {
      const layers = state.layerList;

      if (isEmpty(layers)) return;

      const currentIndex = layers.findIndex(
        (layer) => layer === state.currentLayer,
      );

      state.lastLayer = state.currentLayer;
      if (currentIndex > 0) {
        state.currentLayer = layers[currentIndex - 1];
      } else {
        state.currentLayer = layers[layers.length - 1];
      }
    },
    nextLayer: (state) => {
      const layers = state.layerList;

      if (isEmpty(layers)) return;

      const currentIndex = layers.findIndex(
        (layer) => layer === state.currentLayer,
      );

      state.lastLayer = state.currentLayer;
      if (currentIndex < layers.length - 1) {
        state.currentLayer = layers[currentIndex + 1];
      } else {
        state.currentLayer = layers[0];
      }
    },
    setCurrentLayer: (state, action) => {
      state.lastLayer = state.currentLayer;
      state.currentLayer = action.payload;
    },
    setLayerList: (state, action) => {
      let optionLayers: Layers[] = [];
      const {
        hasSummary,
        hasIAB,
        hasChapter,
        hasKeyTerm,
        hasPeople,
        hasProducts,
        hasOrgs,
        hasLocations,
        hasNationalities,
        hasGlobalList,
        hasAdMarkersLayer,
        hasSpeakers,
        hasAdBreaks,
        hasSegments,
        hasTranscript,
      } = action.payload;
      hasTranscript && optionLayers.push(Layers.TRANSCRIPT);
      hasSummary && optionLayers.push(Layers.SUMMARY);
      hasIAB && optionLayers.push(Layers.IAB);
      hasGlobalList && optionLayers.push(Layers.CUSTOM_TERM);
      hasChapter && optionLayers.push(Layers.CHAPTER);
      hasAdMarkersLayer && optionLayers.push(Layers.AD_MARKER);
      hasAdBreaks && optionLayers.push(Layers.AD_BREAK);
      hasSegments && optionLayers.push(Layers.SEGMENT);
      hasSpeakers && optionLayers.push(Layers.SPEAKER);
      hasKeyTerm && optionLayers.push(Layers.KEY_TERM);
      hasPeople && optionLayers.push(Layers.PEOPLE);
      hasProducts && optionLayers.push(Layers.PRODUCT);
      hasOrgs && optionLayers.push(Layers.ORG);
      hasLocations && optionLayers.push(Layers.LOCATION);
      hasNationalities && optionLayers.push(Layers.NATIONALITY);

      state.layerList = optionLayers;
      state.layerLength = state.layerList.length;
      const keyTermIndex = state.layerList.findIndex((layer) =>
        isEqual(layer, Layers.KEY_TERM),
      );
      state.currentLayer = optionLayers?.[keyTermIndex] ?? optionLayers?.[0]; // Default as KEY_TERM or FIRST ITEM
    },
    overrideLayerList: (state, { payload }: PayloadAction<Layers[]>) => {
      if (!isArray(payload)) return;

      state.layerList = payload;
      state.layerLength = payload.length;

      state.currentLayer = first(payload) ?? Layers.KEY_TERM;
      state.lastLayer = null;
    },
    restoreLayerList: (state) => {
      state.layerList = getLayerEnumList();
      state.layerLength = getLayerEnumList().length;
      state.lastLayer = null;
    },
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setSortBy: (state, action: PayloadAction<{ by: string; name: string }>) => {
      const { payload } = action;

      if (payload.by) state.sort.by = action.payload.by;
      if (payload.name) state.sort.name = action.payload.name;
    },
    toggleSortOrder: (state) => {
      state.sort.isAsc = !state.sort.isAsc;
    },
    addLayerToLayerList: (state, action: PayloadAction<Layers>) => {
      state.layerList.push(action.payload);
      state.layerLength += 1;
    },
    removeLayerFromLayerList: (state, action: PayloadAction<Layers>) => {
      const index = state.layerList.findIndex(
        (layer) => layer === action.payload,
      );

      if (index > -1) {
        state.layerList.splice(index, 1);
        state.layerLength -= 1;
      }
    },
  },
});

export const {
  prevLayer,
  nextLayer,
  setCurrentLayer,
  removeLayerFromLayerList,
  setLayerList,
  addLayerToLayerList,
  restoreLayerList,
  setSearchTerm,
  setSortBy,
  toggleSortOrder,
  overrideLayerList,
} = layersSlice.actions;

export const layersReducers = layersSlice.reducer;
