import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { RssService } from 'services/rss.service';
import { RssSubscription } from 'utils/models';
import { customToast } from 'utils/toast.util';

const rssSlice = createSlice({
  name: 'rss',
  initialState: {
    subscribedFeeds: [] as RssSubscription[],
    notSelectedIds: [] as string[],
  },
  reducers: {
    toggleSelectedRssId: (
      state,
      { payload }: PayloadAction<string | undefined>,
    ) => {
      if (!payload) return;

      const existed = state.notSelectedIds.includes(payload);

      if (existed) {
        state.notSelectedIds = state.notSelectedIds.filter(
          (id) => id !== payload,
        );
      } else {
        state.notSelectedIds.push(payload);
      }
    },
    resetNotSelectedIds: (state) => {
      state.notSelectedIds = [];
    },
    setNotSelectedIds: (state, { payload }: PayloadAction<string[]>) => {
      state.notSelectedIds = payload;
    },
    appendRssSubscription: (
      state,
      { payload }: PayloadAction<RssSubscription>,
    ) => {
      state.subscribedFeeds.push(payload);
    },
    setSubscribedFeeds: (
      state,
      { payload }: PayloadAction<RssSubscription[]>,
    ) => {
      state.subscribedFeeds = orderBy(
        payload.map((feed) => {
          const cloneFeed = { ...feed };
          if (typeof cloneFeed?.subscribed === 'string') {
            cloneFeed.subscribed = cloneFeed?.subscribed === '1';
          }

          return cloneFeed;
        }),
        ['created_at'],
        ['asc'],
      );
    },
    deleteSubscriptionItem: (state, { payload }: PayloadAction<string>) => {
      state.subscribedFeeds = state.subscribedFeeds.filter(
        (item) => item.id !== payload,
      );
    },
    toggleSubscribedRss: (state, { payload: subId }: PayloadAction<string>) => {
      if (!subId) return;

      const foundIndex = state.subscribedFeeds.findIndex(
        (feed) => feed?.id === subId,
      );

      if (foundIndex < 0) return;

      const isChecked = state.subscribedFeeds[foundIndex].subscribed;
      state.subscribedFeeds[foundIndex].subscribed = !isChecked;

      (async () => {
        try {
          const updateAsync = RssService.updateRssSubscription({
            subscription_id: subId,
            subscribed: !isChecked ? '1' : '0',
          });

          customToast.promise(updateAsync, {
            loading: 'Updating...',
            success: 'RSS subscription updated successfully',
            error: 'RSS subscription update failed',
          });

          await updateAsync;
        } catch {
          customToast.error('Failed to update subscription');
        }
      })();
    },
  },
});

export const {
  toggleSelectedRssId,
  resetNotSelectedIds,
  appendRssSubscription,
  setNotSelectedIds,
  setSubscribedFeeds,
  deleteSubscriptionItem,
  toggleSubscribedRss,
} = rssSlice.actions;

export const rssReducer = rssSlice.reducer;
