/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import UppyAwsS3 from '@uppy/aws-s3';
import Uppy, { UploadResult, UppyFile } from '@uppy/core';
import UppyDropbox from '@uppy/dropbox';
import UppyGGDrive from '@uppy/google-drive';
import UppyOneDrive from '@uppy/onedrive';
import {
  Dashboard, useUppy
} from '@uppy/react';
import UppyReduxStore from '@uppy/store-redux';
import UppyURL from '@uppy/url';
import * as types from 'actions/types';
import { config } from 'components/config';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLatest } from 'react-use';
import { RootState } from 'reducers';
import { UploadService } from 'services';
import { rootStore } from 'store/store';
import { SubscriptionEnum } from 'utils/enum';
import { REGEX } from 'utils/regex';
import { customToast } from 'utils/toast.util';
import { filenameFormatter } from 'utils/utils';
import { uploadCustomStyles } from './upload.twin';
import NoSleep from 'nosleep.js';



const basicTypes = [
  '.mp3',
  '.mp4',
  '.mkv',
  '.aac',
  '.au',
  '.3gp',
  '.flac',
  '.m4a',
  '.m4v',
  '.cf',
  '.mov',
  '.mpg',
  '.webm',
  '.wav',
  '.amr',
];

const enterpriseTypes = [
  '.flv',
  '.wmv',
  '.avi',
  '.mpeg',
  '.aiff',
  '.ra',
  '.wma',
  '.asf',
];

const uppyOptions: Uppy.UppyOptions = {
  id: 'uppyInstance',
  autoProceed: true,
  debug: false,
  store: UppyReduxStore({
    store: rootStore,
    id: 'uppyStore',
  }),
  restrictions: {
    // maxFileSize: 1610611911, // 1.5GB limit
    // maxTotalFileSize: 1610611911, // 1.5GB limit
    allowedFileTypes: [...basicTypes, ...enterpriseTypes],
    // maxNumberOfFiles: 10,
  },
  locale: {
    strings: {
      exceedsSize: 'Maximum file size exceeded. Files must not exceed',
      companionError: 'Connection with companion failed',
    },
  },
};

const COMPANION_URL = config.UPPY_COMPANION_URL;
// const COMPANION_URL = 'http://localhost:3020';

export const UppyUploader = () => {
  const dispatch = useDispatch();
  const payment = useSelector((state: RootState) => state.payment);
  const collection = useSelector((state: RootState) => state.collection);
  const collectionLatest = useLatest(collection);
  const subRef = useLatest(payment.subscription);

  const handleFileAdded = (
    currentFile: UppyFile<{}, {}>,
    files: { [key: string]: UppyFile<{}, {}> },
  ): boolean => {
    const fileType = currentFile.name?.match(REGEX.FILE_EXTENSION)?.[0] ?? '';

    if (
      enterpriseTypes.includes(fileType) &&
      (subRef.current?.tenantplan === SubscriptionEnum.TRIAL ||
        subRef.current?.tenantplan === SubscriptionEnum.PAYG)
    ) {
      customToast.error(
        'This file type is only supported for Standard or Enterprise accounts.',
      );
      return false;
    }
    return true;
  };

  const uppyInstance = useUppy(() => {
    return (
      Uppy({ ...uppyOptions, onBeforeFileAdded: handleFileAdded })
        // .use(Tus, { endpoint: 'https://tusd.tusdemo.net/files/' })
        // .use(XHRUpload, { endpoint: `${config.apiGateway.URL}/upload` }) // Need to implement
        .use(UppyGGDrive, { companionUrl: COMPANION_URL })
        .use(UppyOneDrive, { companionUrl: COMPANION_URL })
        .use(UppyDropbox, { companionUrl: COMPANION_URL })
        .use(UppyURL, { companionUrl: COMPANION_URL })
        .use(UppyAwsS3, {
          timeout: 0, // Ignore time out check
          limit: 1, // Allow 1 file at same time
          getUploadParameters: async (file: Uppy.UppyFile) => {
            try {
              const res = await UploadService.uploadLocalFile({
                filename: file.name,
                mediatype: file?.type ?? '',
                title: filenameFormatter(file.name),
                extension: file?.extension,
                size: file?.size,
                collection_id: collectionLatest?.current?.uploadSelectedId || undefined,
              });

              const s3Payload = {
                method: 'POST',
                url: res.data.url,
                fields: res.data.fields,
                headers: {},
              };

              return s3Payload;
            } catch (error: any) {
              customToast.error(`Upload failed: ${error}`);
              console.log('Upload Error:', error);
            }
          },
        })
    );
  });

  useEffect(() => {
    // Prevent sleep on uploading
    const noSleep = new NoSleep();
    
    // Stop awake mode on upload completed
    uppyInstance.on('complete', (result) => {
      noSleep.isEnabled && noSleep.disable();

      handleUploadComplete(result);
    });

    // Stop awake mode on user cancelling
    uppyInstance.on('cancel-all', () => {
      noSleep.isEnabled && noSleep.disable();
    })

    // Awake on uploading
    uppyInstance.on('upload-progress', (res) => {
      noSleep.enable();
    });

    // return () => uppyInstance.close();
  }, []);

  const handleUploadComplete = (result: UploadResult) => {
    if (result.failed) return;

    dispatch({
      type: types.UPLOAD_SUCCESS,
      successMessage: 'Your upload was successful - all files transferred',
    });
  };

  useEffect(() => {
    if (
      subRef.current?.tenantplan === SubscriptionEnum.TRIAL ||
      subRef.current?.tenantplan === SubscriptionEnum.PAYG
    ) {
      uppyInstance.setOptions({
        restrictions: {
          maxNumberOfFiles: 1,
        },
        locale: {
          strings: {
            youCanOnlyUploadX: {
              0: 'Multiple files upload is only supported for Standard or Enterprise accounts.',
            },
          },
        },
      });
    }
  }, [subRef.current?.tenantplan]);

  return (
    <div css={[uploadCustomStyles]}>
      <Dashboard
        uppy={uppyInstance}
        plugins={['GoogleDrive', 'OneDrive', 'Dropbox', 'Url']}
        height={320}
        showProgressDetails={true}
        proudlyDisplayPoweredByUppy={false}
        // @ts-ignore
        inline={true}
      />

      {/* <h2>Progress Bar</h2>
        <ProgressBar uppy={uppyInstance} hideAfterFinish={false} /> */}

      {/* <h2>File Input</h2>
        <FileInput uppy={uppyInstance} /> */}
    </div>
  );
};
