/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { css } from '@emotion/react';
import tw from 'twin.macro';
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from '../../actions/types';
import './export.scss';
import Loader from '../loader/loader';
import { ReactComponent as QuestionSvg } from 'assets/Icons/Vector_question.svg';
import {
  isCollectionPage,
  isDisplayClips,
  isLibraryPage,
  isRevivalPage,
  isTranscriptionPage,
  isVideoByMIME,
  msToTime,
  toIClipExportItems,
} from '../VideoPlayer/Transcription/MediaUtilities';
import { ClipStatus, ClipStatusCode, ClipVersionCount } from 'utils/enum';
import { isEmpty, isEqual, isNil } from 'lodash';
import { toDateWithHours } from 'utils/date.util';
import { ReactComponent as PlaySvg } from 'assets/Icons/fPlay.svg';
import { Tooltip } from '@rmwc/tooltip';
import { Notification } from 'components/UiControls/notification/Notification';
import { SortTable } from 'components/shared/SortTable/SortTable';
import { IClips, IPublishVersion } from 'utils/models';
import { RootState } from 'reducers';
import { ExportParams, MediaService } from 'services';
import { exportToastSuccess } from 'utils/utils';
import { useToggle } from 'react-use';
import { customToast } from 'utils/toast.util';

interface IProps {
  closeExportModal: () => void;
  handleShowPreview: (item: any) => void;
  mediaid: string | undefined;
  filename: string | undefined;
  existedData?: IPublishVersion | null;
  focusVersioncount?: string;
}

export const Export = (props: IProps) => {
  const dispatch = useDispatch();

  const clips = useSelector((state: RootState) => state.clips);
  const selectedRows = useSelector(
    (state: RootState) => state.library.selectedRows,
  );
  const advancedSearch = useSelector(
    (state: RootState) => state.advancedSearch,
  );
  const payment = useSelector((state: RootState) => state.payment);

  const [isAdobePdfTC, setIsAdobePdfTC] = useState(false);
  const [isDocxTC, setIsDocxTC] = useState(false);
  const [isTxtTC, setIsTxtTC] = useState(false);
  const [isAdobePdfSP, setIsAdobePdfSP] = useState(false);
  const [isDocxSP, setIsDocxSP] = useState(false);
  const [isTxtSP, setIsTxtSP] = useState(false);

  const [isWebVtt, setIsWebVtt] = useState(false);
  const [isMp4, setIsMp4] = useState(false);
  const [isMp3, setIsMp3] = useState(false);
  const [hasMedia, setHasMedia] = useState(false);
  const [isSrt, setIsSrt] = useState(false);
  let { loading, data } = useSelector(
    (state: RootState) => state.getPublishedVersion,
  );
  const library = useSelector((state: RootState) => state.library);
  const [publishedItemList, setPublishedItemList] = useState<IClips[]>([]);
  const [haveMp4, setHaveMp4] = useState<Boolean>(false);
  const [haveMp3, setHaveMp3] = useState(false);
  const [selectedItems, setSelectedItems] = useState<IClips[]>([]);
  const [isSingleExporting, toggleSingleExporting] = useToggle(false);
  const { focusRow } = library;

  const isThumbnailView = isLibraryPage() || isCollectionPage();

  const currentFocusRows = advancedSearch.isShowClipsOnly
    ? clips.selectedClips
    : selectedRows;

  const hasValidOptions: boolean =
    isAdobePdfTC ||
    isDocxTC ||
    isTxtTC ||
    isAdobePdfSP ||
    isDocxSP ||
    isTxtSP ||
    isWebVtt ||
    isMp4 ||
    isMp3 ||
    isSrt ||
    hasMedia;

  useEffect(() => {
    // At least 1 row has to be selected
    if (isNil(currentFocusRows) || currentFocusRows.length <= 1) return;

    const items = toIClipExportItems(currentFocusRows); // convert to IClips format
    setPublishedItemList(items); // show to ui
    setSelectedItems(items); // background when select
  }, [currentFocusRows]);

  useEffect(() => {
    if (!selectedItems) return;
    setIsMp3(false);
    setIsMp4(false);

    // File has burned in
    const hasBurnedIn = selectedItems.some((item) => item.hasBurnIn);

    if (data?.haveMp3) {
      setHaveMp4(hasBurnedIn as Boolean);
      setHaveMp3(!hasBurnedIn);
    }
    if (data?.haveMp4) {
      setHaveMp4(true);
      setHaveMp3(false);
    }
  }, [selectedItems]);

  useEffect(() => {
    // Not running on multi items selected
    if (isThumbnailView && currentFocusRows.length >= 2) return;

    if (loading) {
      setHaveMp4(false);
      setHaveMp3(false);
    } else {
      let versions = [];
      if (props?.existedData) {
        versions = props.existedData.versions?.map((item) => {
          const cloneItem = { ...item };
          if (isEqual(cloneItem.versioncount, clips.focusClip?.versioncount)) {
            cloneItem.isSelected = true;
            setSelectedItems((selectedItem) => [...selectedItem, cloneItem]);
          } else {
            cloneItem.isSelected = false;
          }
          return cloneItem;
        });
      } else {
        // single export in search clips
        if (advancedSearch.isShowClipsOnly) {
          versions = [
            // Suggested versions
            ...(data?.suggestedVersions ?? []),
            // Crated + Shared versions
            ...(data?.versions?.filter(
              (item: IClips) =>
                item?.versioncount !== ClipVersionCount.ORIGINAL,
            ) ?? []),
            // Trending versions
            ...(data?.trendingVersions ?? []),
          ];

          const focusVersion = versions?.find(
            (item: any) => item?.versioncount === clips.focusClip?.versioncount,
          );

          setSelectedItems(focusVersion ? [focusVersion] : []);
        } else {
          let found = false;
          versions = data?.versions?.map((item: IClips, index: number) => {
            const cloneItem = { ...item };
            // Select first item that not in CREATING
            if (!isEqual(item?.status, ClipStatus.CREATING) && !found) {
              cloneItem.isSelected = true;
              setSelectedItems([cloneItem]);
              found = true;
            } else {
              cloneItem.isSelected = false;
            }
            return cloneItem;
          });
        }
      }

      setHaveMp4(data?.haveMp4);
      setHaveMp3(data?.haveMp3);
      setPublishedItemList(versions);

      if (!isNil(props?.focusVersioncount)) {
        const version = versions?.find(
          (v: any) => v?.versioncount === props?.focusVersioncount,
        );
        if (!version) return;
        setSelectedItems([version]);

        const data = versions?.map((data: any) =>
          data.versioncount === version.versioncount
            ? { ...data, isSelected: true }
            : { ...data, isSelected: false },
        );
        setPublishedItemList(data);
      }
    }
  }, [loading, props?.existedData]);

  useEffect(() => {
    if (props?.existedData) {
      data = props.existedData;
      return;
    }
    if (!!props.mediaid) {
      dispatch({
        type: actionTypes.INIT_PUBLISHED_VERSION_LIBRARY_WATCH,
        mediaid: props.mediaid,
      });
    }
  }, [props.mediaid]);

  const exportSingle = (exportedData: any) => {
    if (Object.keys(exportedData).length) {
      let lstUrl: string[] = [];
      if (exportedData.mp3) {
        lstUrl.push(exportedData.mp3);
      }
      if (exportedData.mp4) {
        lstUrl.push(exportedData.mp4);
      }
      if (exportedData.textData.notZipUrl) {
        lstUrl.push(exportedData.textData.notZipUrl);
      } else if (exportedData.textData.zipUrl) {
        lstUrl.push(exportedData.textData.zipUrl);
      }

      let timeOut = 500;
      for (let index in lstUrl) {
        setTimeout(function () {
          downloadURI(lstUrl[index]);
        }, timeOut);
        timeOut += 2000;
      }
    }
  };

  const downloadURI = (uri: string) => {
    window.open(uri, '_self');
  };

  const handleExportAsync = async () => {
    // At least one option to be selected
    if (payment.isExpiredTrial) {
      customToast.trialExpired();
      return;
    }
    if (!hasValidOptions) return;

    let exportItems =
      (isThumbnailView && !advancedSearch.isShowClipsOnly
        ? selectedRows
        : selectedItems) ?? [];

    if (isEmpty(exportItems)) {
      exportItems = [focusRow];
    }
    const isSingleExport = exportItems?.length === 1;

    // Use selectedItems for single item Library page
    if (isSingleExport) {
      exportItems = [...selectedItems];
    }

    const singleItem = isSingleExport ? exportItems[0] : null;
    const mimeType = singleItem?.mediacontenttype ?? singleItem?.mediatype;

    const isMp4 =
      hasMedia && (isVideoByMIME(mimeType) || singleItem?.hasBurnIn);

    const parentMediaid = props?.mediaid;

    const payload: ExportParams = {
      isSingleExport: isSingleExport,
      mediaid: isSingleExport ? parentMediaid : '',
      versioncount: singleItem?.versioncount ?? 0, // Default Original
      mp4: isSingleExport && hasMedia ? (isMp4 ? 1 : 0) : 0,
      mp3: isSingleExport && hasMedia ? (!isMp4 ? 1 : 0) : 0,
      mediaItems: isSingleExport
        ? null
        : isThumbnailView
        ? currentFocusRows.map((item: any) => ({
            mediaid: item?.mediaid ?? item?.media_id,
            versioncount: item?.versioncount ?? '0',
            isMp4: item?.hasBurnIn
              ? true
              : isVideoByMIME(item?.mediatype ?? item?.mediacontenttype),
          }))
        : selectedItems.map((item: IClips) => ({
            mediaid: props?.mediaid,
            versioncount: item?.versioncount,
            isMp4: item?.hasBurnIn ? true : isVideoByMIME(item?.mediatype),
          })),
      // isOrigin for export original version
      isOrigin:
        ((isThumbnailView && !isSingleExport) ||
          (isSingleExport && singleItem?.versioncount === '0')) &&
        !advancedSearch.isShowClipsOnly
          ? 1
          : 0,
      vtt: isWebVtt ? 1 : 0,
      srt: isSrt ? 1 : 0,
      pdfTC: isAdobePdfTC ? 1 : 0,
      docxTC: isDocxTC ? 1 : 0,
      txtTC: isTxtTC ? 1 : 0,
      pdfSP: isAdobePdfSP ? 1 : 0,
      docxSP: isDocxSP ? 1 : 0,
      txtSP: isTxtSP ? 1 : 0,
      hasMedia: hasMedia ? 1 : 0,
    };

    try {
      toggleSingleExporting(true);

      const { data: exportedData } = await MediaService.getExport(payload);

      if (isSingleExport) {
        exportSingle(exportedData);
      } else {
        exportToastSuccess();
      }
    } catch (err: any) {
      customToast.error('Failed to export. Please try again later.');
      console.log('err :>> ', err);
    } finally {
      toggleSingleExporting(false);
    }
  };

  useEffect(() => {
    if (clips.selectedClips.length > 0 && isDisplayClips()) {
      let itemList: IClips[] = [];

      publishedItemList.forEach((item) => {
        const isSelected = clips.selectedClips.some(
          (clip) => clip.versioncount === item.versioncount,
        );

        if (isSelected) {
          let cloneItem = { ...item, isSelected: true };
          itemList.push(cloneItem);
        }
      });

      setSelectedItems(itemList);
    }
  }, [publishedItemList]);

  const isSelectedItem = (item: IClips) => {
    const hasSelected = selectedItems.some(
      (clip) => clip.versioncount === item.versioncount,
    );

    return hasSelected;
  };

  const handleRowClick = (
    item: IClips,
    e: React.MouseEvent<HTMLTableRowElement>,
  ) => {
    if (isEqual(item.status, ClipStatus.CREATING)) return;

    // Normal click
    if (!e?.ctrlKey) {
      setSelectedItems([{ ...item, isSelected: true }]);
      return;
    }

    // Hold Ctrl + click
    if (isSelectedItem(item)) {
      // At least 1 row has to be selected
      if (selectedItems.length <= 1) return;

      // Item not existed -> Remove from list
      let selectedClips = selectedItems
        .filter((clip) => clip.versioncount !== item.versioncount)
        .map((item) => ({ ...item, isSelected: true }));

      setSelectedItems(selectedClips);
    } else {
      // Item not existed -> Append to list
      setSelectedItems((items) => [...items, { ...item, isSelected: true }]);
    }
  };

  const getStatusText = (item: IClips) => {
    if (
      item?.versioncount === '0' &&
      item?.statuscode === ClipStatusCode.CREATED
    ) {
      return 'Original';
    } else return item.status;
  };

  const handlePdfchangeTC = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAdobePdfTC(e.target.checked);
  };
  const handleDocxchangeTC = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDocxTC(e.target.checked);
  };
  const handlePlaintTextTC = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsTxtTC(e.target.checked);
  };

  const handlePdfchangeSP = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAdobePdfSP(e.target.checked);
  };
  const handleDocxchangeSP = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDocxSP(e.target.checked);
  };
  const handlePlaintTextSP = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsTxtSP(e.target.checked);
  };

  const handleVttchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsWebVtt(e.target.checked);
  };

  // const handleMp3change = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setIsMp3(e.target.checked);
  // };

  // const handleMp4change = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setIsMp4(e.target.checked);
  // };

  const handleMediaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasMedia(e.target.checked);
  };

  const handleSrtchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSrt(e.target.checked);
  };

  return (
    <>
      {isSingleExporting && (
        <div className="loader__component">
          <Loader />
        </div>
      )}
      <>
        <form className="exportmodal" onSubmit={e => e.preventDefault()}>
          <div className="grid-x">
            <div className="cell large-12">
              <h2>Export</h2>
            </div>
          </div>
          <div>
            <div tw="flex items-center mt-8">
              <h3 tw="mt-0! mr-4">Select a clip to export</h3>
              <Tooltip
                align="bottomLeft"
                tw="z-index[100]"
                leaveDelay={300}
                content={
                  <div tw="width[30rem] top-0 left[-1.5rem] font-size[1.6rem] line-height[1.9rem] border-radius[3px] absolute">
                    <Notification
                      hideCloseButton
                      name="share-tooltip"
                      type="information"
                      message={
                        <div tw="py-1">
                          <p>
                            Select the clip or original content items that you
                            wish to export, using control-click to select
                            multiple items. Then select what components you want
                            included. Multiple items will be exported as a ZIP
                            file.
                          </p>
                        </div>
                      }
                    />
                  </div>
                }
                css={customTooltipStyles}
              >
                <div className="questionsvg">
                  <QuestionSvg />
                </div>
              </Tooltip>
            </div>
          </div>

          <SortTable
            columns={[
              { Header: 'CLIP NAME', accessor: 'versionname' },
              { Header: 'DURATION', accessor: 'length', width: '12%' },
              { Header: 'LAST MODIFIED', accessor: 'datetime', width: '20%' },
              { Header: 'STATUS', accessor: 'status', width: '10%' },
              { Header: 'PREVIEW', width: '7%' },
            ]}
            data={[...publishedItemList] ?? []}
            maxRowShown={3}
            isLoading={loading}
          >
            {({ original: item }) => (
              <tr
                key={item.versioncount}
                onClick={(e) => handleRowClick(item, e)}
                className={`background_share ${
                  isSelectedItem(item) && 'active'
                }`}
              >
                <td tw="pl-3!" title={item.versionname}>
                  {item.versionname}
                </td>
                <td width="12%">{`${msToTime(
                  item.length as unknown as number,
                )}`}</td>
                <td width="20%">{`${toDateWithHours(item.datetime)}`}</td>
                <td width="10%">{`${getStatusText(item)}`}</td>
                <td width="7%" tw="text-center pr-0!">
                  <div
                    tw="text-sonnant-purple-2 hover:(text-sonnant-purple-3 underline) flex justify-center"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      if (isEqual(item.status, ClipStatus.CREATING)) return;
                      props?.handleShowPreview?.({
                        ...item,
                        hasButtons: false,
                      });
                    }}
                    title={
                      isEqual(item.status, ClipStatus.CREATING)
                        ? 'Burning in progress'
                        : ''
                    }
                  >
                    <PlaySvg
                      tw="max-height[2.4rem]"
                      className={`svg-shadow-hover ${
                        isEqual(item.status, ClipStatus.CREATING) && 'disabled'
                      }`}
                    />
                  </div>
                </td>
              </tr>
            )}
          </SortTable>

          <hr></hr>

          <div className="grid-x">
            <h3 className="small-12 cell">Select items for export</h3>
          </div>

          <div className="grid">
            <fieldset className="group-legend1">
              {(haveMp3 || haveMp4 || currentFocusRows.length > 1) && (
                <legend tw="font-weight[500]">Media File</legend>
              )}

              {currentFocusRows.length > 1 && (
                <label
                  htmlFor="checkbox11"
                  className="cell cus-inline-block cus-large-5 cus-left-10"
                  tw="cursor-pointer"
                >
                  <input
                    id="checkbox11"
                    type="checkbox"
                    checked={hasMedia}
                    onChange={handleMediaChange}
                  />
                  <span className="checkmark"></span>
                  Media source file
                </label>
              )}

              {haveMp3 &&
                (currentFocusRows.length <= 1 ||
                  isRevivalPage() ||
                  isTranscriptionPage()) && (
                  <label
                    htmlFor="checkbox11"
                    className="cell cus-inline-block cus-large-5 cus-left-10"
                    tw="cursor-pointer"
                  >
                    <input
                      id="checkbox11"
                      type="checkbox"
                      checked={hasMedia}
                      // checked={isMp3}
                      // onChange={handleMp3change}
                      onChange={handleMediaChange}
                    />
                    <span className="checkmark"></span>
                    MPEG-3 (.mp3)
                  </label>
                )}
              {haveMp4 &&
                (currentFocusRows.length <= 1 ||
                  isRevivalPage() ||
                  isTranscriptionPage()) && (
                  <label
                    htmlFor="checkbox12"
                    className=" cell cus-inline-block cus-large-5 cus-left-10"
                    tw="cursor-pointer"
                  >
                    <input
                      id="checkbox12"
                      type="checkbox"
                      checked={hasMedia}
                      // checked={isMp4}
                      // onChange={handleMp4change}
                      onChange={handleMediaChange}
                    />
                    <span className="checkmark"></span>
                    MPEG-4 (.mp4)
                  </label>
                )}
            </fieldset>
            <fieldset className="group-legend1">
              <legend tw="font-weight[500]">Captions File</legend>
              <label
                htmlFor="checkbox21"
                className="cell cus-inline-block cus-large-5 cus-left-10"
                tw="cursor-pointer"
              >
                <input
                  id="checkbox21"
                  type="checkbox"
                  checked={isWebVtt}
                  onChange={handleVttchange}
                />
                <span className="checkmark"></span>
                WebVTT (.vtt)
              </label>
              <label
                htmlFor="checkbox22"
                className="cell cus-inline-block cus-large-5 cus-left-10"
                tw="cursor-pointer"
              >
                <input
                  id="checkbox22"
                  type="checkbox"
                  checked={isSrt}
                  onChange={handleSrtchange}
                />
                <span className="checkmark"></span>
                SubRip (.srt)
              </label>
            </fieldset>
          </div>

          <fieldset className="group-legend2">
            <legend tw="font-weight[500]">Transcript - Timed Captions</legend>
            <label
              htmlFor="checkbox31"
              className="cell cus-inline-block small-3 cus-left-5"
              tw="cursor-pointer"
            >
              <input
                id="checkbox31"
                type="checkbox"
                checked={isAdobePdfTC}
                onChange={handlePdfchangeTC}
              />
              <span className="checkmark"></span>
              PDF (.pdf)
            </label>
            <label
              htmlFor="checkbox32"
              className="cell cus-inline-block small-3"
              tw="cursor-pointer"
            >
              <input
                id="checkbox32"
                type="checkbox"
                checked={isDocxTC}
                onChange={handleDocxchangeTC}
              />
              <span className="checkmark"></span>
              Word (.docx)
            </label>
            <label
              htmlFor="checkbox33"
              className="cell cus-inline-block small-3"
              tw="cursor-pointer"
            >
              <input
                id="checkbox33"
                type="checkbox"
                checked={isTxtTC}
                onChange={handlePlaintTextTC}
              />
              <span className="checkmark"></span>
              Plain Text (.txt)
            </label>
          </fieldset>

          <fieldset className="group-legend2">
            <legend tw="font-weight[500]">
              Transcript - Speaker Paragraphs
            </legend>
            <label
              htmlFor="checkbox41"
              className="cell cus-inline-block small-3 cus-left-5"
              tw="cursor-pointer"
            >
              <input
                id="checkbox41"
                type="checkbox"
                checked={isAdobePdfSP}
                onChange={handlePdfchangeSP}
              />
              <span className="checkmark"></span>
              PDF (.pdf)
            </label>
            <label
              htmlFor="checkbox42"
              className="cell cus-inline-block small-3"
              tw="cursor-pointer"
            >
              <input
                id="checkbox42"
                type="checkbox"
                checked={isDocxSP}
                onChange={handleDocxchangeSP}
              />
              <span className="checkmark"></span>
              Word (.docx)
            </label>
            <label
              htmlFor="checkbox43"
              className="cell cus-inline-block small-3"
              tw="cursor-pointer"
            >
              <input
                id="checkbox43"
                type="checkbox"
                checked={isTxtSP}
                onChange={handlePlaintTextSP}
              />
              <span className="checkmark"></span>
              Plain Text (.txt)
            </label>
          </fieldset>

          <div tw="mt-5 flex-row-reverse flex">
            <button
              type="button"
              className="button btn-primary  large apply"
              onClick={handleExportAsync}
              disabled={!hasValidOptions || isEmpty(publishedItemList)}
            >
              Download
              {!!selectedItems?.length && (
                <span> ({selectedItems?.length})</span>
              )}
            </button>
            <button
              type="button"
              className="button btn-secondary large cancel"
              tw="mr-5"
              onClick={props.closeExportModal}
            >
              Cancel
            </button>
          </div>
        </form>
      </>
    </>
  );
};

const customTooltipStyles = css`
  ${tw`font-size[1rem]`}

  .rmwc-tooltip-inner {
    background: none;
  }
`;
