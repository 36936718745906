/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import * as actions from 'actions';
import { ReactComponent as AscendingArrow } from 'assets/Icons/Ascending_Arrow.svg';
import { ReactComponent as DescendingArrow } from 'assets/Icons/Descending_Arrow.svg';
import { isNil } from 'lodash';
import { MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  changeOrder,
  changeSortBy,
  validateSortField,
} from 'slices/pagination.slice';
import { SortField } from 'utils/enum';
import { ILibraryItem } from 'utils/models';

import { flexRender, Header } from '@tanstack/react-table';

type Props = {
  header: Header<ILibraryItem, unknown>;
  index: number;
};

export const ListViewHeader = ({ header, index }: Props) => {
  const dispatch = useDispatch();

  const pagination = useSelector((state: RootState) => state.pagination);

  const isSorted = pagination.sortBy === validateSortField(header.id);
  const isAsc = isSorted ? !pagination.isAsc : true;

  const handleSortOrder = (type: SortField, isAsc?: boolean) => {
    if (isNil(isAsc)) {
      isAsc = true;
    }

    dispatch(changeSortBy(type));
    dispatch(changeOrder(isAsc));

    dispatch(actions.setSelectedRows([]));
  };

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    header.getResizeHandler()?.(e);
  };

  const shouldStyleCenter = ![SortField.TYPE, SortField.TITLE].includes(
    header.id as any,
  );

  const handleClickSort = (e: MouseEvent<HTMLDivElement>) => {
    handleSortOrder(header.id as any, isAsc);
  };


  return (
    <th
      style={{
        ...(header.column.columnDef as any)?.style,
        width: header.getSize(),
        position: 'relative',
      }}
      title={(header.column.columnDef as any)?.hint}
      tw="cursor-pointer"
    >
      <div
        onMouseDown={handleMouseDown}
        css={[resizableCss, header.column.getIsResizing() && isResizingStyle]}
      />
      <div tw="relative" onClick={(e) => handleClickSort(e)} css={headerCss}>
        <div
          tw="flex items-center space-x-3"
          css={[shouldStyleCenter && tw`justify-center`]}
        >
          {isSorted && (
            <div tw="flex flex-col space-y-2" css={[index !== 0 && tw`pl-3`]}>
              <div tw="flex items-center" css={[!isAsc && tw`opacity-40`]}>
                <DescendingArrow />
              </div>
              <div tw="flex items-center" css={[isAsc && tw`opacity-40`]}>
                <AscendingArrow />
              </div>
            </div>
          )}
          {/* if header is title */}
          <div css={[index === 1 && tw`ml-10`]}>
            {flexRender(header.column.columnDef.header, header.getContext())}
          </div>
        </div>
      </div>
    </th>
  );
};

const headerCss = css`
  ${tw`hover:[> div]:(opacity-100)!`}
`;

const resizableCss = css`
  ${tw`h-1/2 absolute right-0 top-5 cursor[col-resize] select-none touch-action[none]`}
  ${tw`border-left[2px solid] border-sonnant-grey-1`}
  ${tw`hover:(border-sonnant-purple-2 border-width[3px])`}
`;

const isResizingStyle = css`
  ${tw`(opacity[1] border-width[3px] border-sonnant-purple-2)!`}
`;
