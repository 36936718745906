/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import Loader from 'components/loader/loader';
import { Option } from 'components/shared/CustomSelectSearch';
import { DropdownSelectionWrapper } from 'components/shared/HintDropdownWrapper/HintDropdownWrapper';
import { TextItem } from 'components/shared/MonetisationTextSearch/MonetisationTextSearch';
import { SearchInput } from 'components/shared/SearchInput';
import { useGroupIdQuery } from 'hooks/queries/useGroupIdQuery';
import { isNil, uniqBy, orderBy, differenceBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { AdminPortalService } from 'services/admin-portal.service';
import { extract, remove, withId } from 'utils/generic.util';
import { customToast } from 'utils/toast.util';
import { ListBox } from '../shared/ListBox';
import { PortalHeading } from '../shared/PortalHeading';
import { useAvailableUsersQuery } from 'hooks/queries/useAvailableUsersQuery';
import { toggleRefetchCollections } from 'slices/admin-portal.slice';
import { getJwtUserEmail } from 'utils/utils';
import { useUsersQuery } from 'hooks/queries/useUsersQuery';
import { DefaultGroups } from 'utils/enum';
import { useEffectOnce } from 'react-use';
import { AvailableUser } from 'utils/models';

type Props = {};

const jwtUserEmail = getJwtUserEmail();

export const PortalUsersPane = (props: Props) => {
  const dispatch = useDispatch();

  const adminPortal = useSelector((state: RootState) => state.adminPortal);

  const [groupUsers, setGroupUsers] = useState<TextItem[]>([]);
  const [searchTextUser, setSearchTextUser] = useState<string>();

  const groupId = adminPortal.selectedUserGroupId;

  const currentGroup = adminPortal.userGroups.find(
    (group) => group.groupId === groupId,
  );

  const isCurrentDefaultGroup = currentGroup?.isDefault;

  const { data: tenantUsers, isLoading: isLoadingUserDropdown } =
    useUsersQuery();

  const {
    data: userResponse,
    isLoading: isLoadingDropdown,
    refetch: refetchAvailableUsers,
  } = useAvailableUsersQuery();

  const { isLoading: isLoadingGroupUsers } = useGroupIdQuery({
    groupId,
    options: {
      onSuccess: (groupInfo) => {
        if (isNil(groupInfo)) return;

        setGroupUsers(groupInfo.userIds.map(createGroupUser));
      },
    },
  });

  const getUserLabel = (user: AvailableUser): string => {
    if (isNil(user.groupName)) {
      return user.email;
    }
    return `${user.email} - ${user.groupName}`;
  };

  const userOptions = useMemo<Option[]>(() => {
    if (isNil(userResponse) || currentGroup?.groupName === DefaultGroups.ADMIN)
      return [];

    const userResponseOrdered = orderBy(
      userResponse,
      ['groupName', 'createdDate'],
      ['asc', 'asc'],
    );

    const availableUsers = userResponseOrdered
      .filter((user) => user.groupName !== DefaultGroups.ADMIN)
      .map((user) => ({
        label: getUserLabel(user),
        value: user.email,
      }))
      .filter((email) => email.value !== jwtUserEmail);

    return differenceBy(
      availableUsers,
      groupUsers.map((user) => ({ value: user.text })),
      'value',
    );
  }, [userResponse, groupUsers]);

  useEffectOnce(() => {
    refetchAvailableUsers();
  });

  const handleSelectUser = (option: Option) => {
    const email = option.value;

    if (groupUsers.map(({ text }) => text).includes(email)) {
      customToast.error('The user already exists in this group');

      return;
    }

    const userList = groupUsers.concat(createGroupUser(email));

    setGroupUsers(uniqBy(userList, 'text'));
    saveChangesApiAsync(userList);
  };

  const createGroupUser = (email: string): TextItem => {
    return withId({ text: email, canRemove: !isCurrentDefaultGroup });
  };

  const handleRemoveUser = (user: TextItem) => {
    const userList = remove(groupUsers, 'id', user.id);

    setGroupUsers(userList);
    saveChangesApiAsync(userList);
  };

  const saveChangesApiAsync = async (groupUsers: TextItem[]) => {
    if (!groupId) {
      customToast.error('Missing group id. Please check and try again.');
      return;
    }

    const updateAsync = AdminPortalService.updateUserGroup(groupId, {
      userIds: extract(groupUsers, 'text'),
    });

    customToast.promise(updateAsync, {
      loading: 'Updating',
      success: 'Done',
      error: 'Failed to update user group',
    });

    try {
      await updateAsync;

      dispatch(toggleRefetchCollections(true));
      refetchAvailableUsers();
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const ensureValidUsers = (users: TextItem[]): TextItem[] => {
    // User has to be in available users
    return users.filter((item) =>
      tenantUsers?.users?.some((user) => user.Email === item.text),
    );
  };

  return (
    <div tw="relative w-full flex-1">
      {(isLoadingGroupUsers || isLoadingUserDropdown) && (
        <div tw="absolute w-full h-full flex justify-center items-center bg-white z-[1] opacity-90">
          <Loader />
        </div>
      )}

      <PortalHeading header="Users" />

      <DropdownSelectionWrapper
        options={userOptions}
        searchValue={searchTextUser}
        onSelect={handleSelectUser}
        isLoading={isLoadingDropdown}
      >
        <SearchInput
          setTerm={setSearchTextUser}
          placeholder="Search to add a user into the selected user group"
        />
      </DropdownSelectionWrapper>

      <ListBox
        items={ensureValidUsers(groupUsers)}
        onRemove={handleRemoveUser}
      />
    </div>
  );
};
