/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { CardCell } from 'components/Library/ThumbNailsItem/CardCell';
import { ILibraryItem, RevivalItemModel as Revival } from 'utils/models';
import { ThreeDotMenu } from 'components/shared/ThreeDotMenu/ThreeDotMenu';
import { useToggle } from 'react-use';

import { ReactComponent as FacebookSvg } from 'assets/Icons/ShareFacebook.svg';
import { ReactComponent as LinkedInSvg } from 'assets/Icons/ShareLinkedin.svg';
import { ReactComponent as TwitterSvg } from 'assets/Icons/x-twitter-logo.svg';
import GoogleTrendsIcon from 'assets/Icons/google_trends.png';
import { getLatestTitle } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { Social } from 'utils/enum';
import { useDispatch } from 'react-redux';
import { setFocusRevival } from 'slices/revival.slice';
import {
  hoverDropShadow,
  tagCss,
  tagWrapper,
} from 'components/shared/twin.styles';
import { Hint } from 'components/shared/Hint';
import { isEmpty } from 'lodash';
import { getTagTrendingTitle, getTagTrendingUrl } from 'utils/utils';
import { HintRestrictedPublish } from 'components/shared/HintRestrictedPublish';
import { useItemFunction } from 'hooks/useItemFunction';

interface Props {
  listId: string;
  revival: Revival;
  isToday: boolean;
  toggleOpenPublishModal: any;
  toggleOpenShareModal: any;
  toggleOpenExportModal: any;
  onOpenShareModal?: (item: Partial<ILibraryItem>, social: Social) => void;
}

export const RevivalItem = ({ revival, ...props }: Props) => {
  const {
    toggleOpenPublishModal,
    toggleOpenShareModal,
    toggleOpenExportModal,
  } = props;

  const dispatch = useDispatch();

  const libraryItem = revival.libraryItem;
  const [isHovering, toggleHovering] = useToggle(false);

  const [hasMenu, toggleMenu] = useToggle(false);
  const [collapsed, toggleCollapsed] = useToggle(!props.isToday);

  const { handleViewClip } = useItemFunction({
    item: revival.libraryItem as ILibraryItem,
  });

  const isTrendingOn = (social: Social): boolean => {
    return revival?.trendingOn?.includes(social);
  };

  const handleShareSocial = async (social: Social) => {
    if (!revival?.libraryItem?.mediaid) return;
    dispatch(setFocusRevival(revival));

    props?.onOpenShareModal?.(revival?.libraryItem, social);
  };

  return (
    <div
      tw="2xl-up:w-1/6 lg-up:w-1/4 md-up:w-1/3 sm-down:w-1/2 xs:w-full! mt-0 overflow-hidden text-15! pb-6 m-0"
      className="thumbNails_block"
      onMouseEnter={() => toggleHovering(true)}
      onMouseLeave={() => toggleHovering(false)}
    >
      <div
        className="card"
        tw="p-0! justify-between border[1px solid]! border-sonnant-grey-3! rounded-lg shadow-lg hover:(shadow-xl) p-5! mx-5"
        css={[!collapsed && tw`h-full`]}
      >
        <CardCell libraryItem={libraryItem as ILibraryItem} isTrending />

        <div
          className="videoThumbnail"
          tw="flex my-5"
          onMouseLeave={() => toggleMenu(false)}
        >
          <div
            tw="w-full min-height[4.4rem]! text-15 font-semibold line-height[2.2rem] max-height[6.6rem] text-sonnant-darker margin-right[4rem]"
            className="line-clamp-2"
          >
            {getLatestTitle(libraryItem as ILibraryItem) || 'Empty title'}
          </div>
          <div
            className="dropdown show thumbnailDropdown"
            tw="block! position[unset]! transition[all 150ms ease-in-out]"
            css={[
              isHovering ? tw`visible opacity-100` : tw`invisible opacity-0`,
            ]}
          >
            <ThreeDotMenu
              openMenu={hasMenu}
              style={{ marginLeft: '-4rem', marginTop: '35px' }}
              clickThreeDot={() => {
                dispatch(setFocusRevival(revival));
                toggleMenu();
              }}
              libraryItem={libraryItem as ILibraryItem}
              handlePublish={() => toggleOpenPublishModal(true)}
              handleShare={() => {
                toggleOpenShareModal(true);
              }}
              handleExport={() => {
                toggleOpenExportModal(true);
              }}
              handleViewClip={handleViewClip}
              isRevival
            />
          </div>
        </div>

        <div tw="flex justify-start w-full transition-all flex-1 flex-col">
          <div tw="flex justify-between">
            <div css={[itemHeader]}>Top Tags</div>
            <div tw="cursor-pointer mr-3 mt-2" onClick={toggleCollapsed}>
              <div
                tw="transition[all 0.3s ease-in-out]"
                css={!collapsed && tw`transform[rotate(-180deg)]`}
                className="arrow-down arrow-style"
              />
            </div>
          </div>
          {!collapsed && (
            <div>
              <div tw="flex flex-wrap overflow-x-hidden" css={[tagWrapper]}>
                {revival.topTags.map((tag, index) => (
                  <Hint
                    disabled={isEmpty(revival.twitterTrendingTags)}
                    text={
                      <span>
                        Trending tags:{' '}
                        {revival.twitterTrendingTags.map((tag, index) => (
                          <>
                            <a
                              key={tag}
                              href={getTagTrendingUrl(revival?.trendingOn, tag)}
                              target="_blank"
                              rel="noopener noreferrer"
                              tw="underline cursor-pointer text-white hover:(text-white text-shadow[0 0 1px white])"
                              title={getTagTrendingTitle(revival?.trendingOn)}
                            >
                              {tag}
                            </a>
                            <span>
                              {index < revival.twitterTrendingTags.length - 1 &&
                                ', '}{' '}
                            </span>
                          </>
                        ))}
                      </span>
                    }
                    enterDelay={200}
                    leaveDelay={200}
                    fontSize={1.1}
                    notTransparent
                  >
                    <a
                      href={getTagTrendingUrl(revival?.trendingOn, tag)}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                      css={tagCss}
                    >
                      {tag}
                    </a>
                  </Hint>
                ))}
              </div>
            </div>
          )}
        </div>

        <div
          css={[collapsed && tw`hidden`]}
          tw="transition[all 1s ease-in-out]!"
        >
          <div tw="mb-4">
            <div css={[itemHeader]}>Headline</div>
            <div tw="-mt-2 font-semibold text-sonnant-dark min-height[2.4rem]">
              {revival?.headline || ''}
            </div>
          </div>

          <div tw="flex-1 items-end flex">
            <div tw="flex justify-between w-full">
              <div>
                <div css={[itemHeader]} tw="text-13 mb-1.5">
                  Trending on
                </div>
                <div tw="flex">
                  {isTrendingOn(Social.FACEBOOK) && (
                    <div>
                      <FacebookSvg css={[socialIcon, hoverDropShadow(0.2)]} />
                    </div>
                  )}
                  {isTrendingOn(Social.LINKEDIN) && (
                    <div>
                      <LinkedInSvg css={[socialIcon, hoverDropShadow(0.2)]} />
                    </div>
                  )}
                  {isTrendingOn(Social.TWITTER) && (
                    <HintRestrictedPublish>
                      <Hint
                        text="Share suggested trending clip on Twitter"
                        enterDelay={100}
                      >
                        <div
                          tw="cursor-pointer"
                          onClick={() => handleShareSocial(Social.TWITTER)}
                        >
                          <TwitterSvg
                            css={[socialIcon, hoverDropShadow(0.2)]}
                            tw="w-[2.6rem] h-[2.6rem] bg-black rounded-full p-1"
                          />
                        </div>
                      </Hint>
                    </HintRestrictedPublish>
                  )}
                  {isTrendingOn(Social.GOOGLE) && (
                    <div>
                      <img
                        src={GoogleTrendsIcon}
                        tw="w-[2.4rem]"
                        alt="Google Trends"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const itemHeader = css`
  ${tw`text-sonnant-purple-3 text-14 font-medium mb-3`}
`;

const socialIcon = css`
  ${tw`height[2.4rem] width[2.4rem]`}

  margin-right: .5rem;
`;
