/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Modal } from 'components/UiControls/modal/modal';
import { ModalOptions } from 'utils/enum';
import { isEmpty, isNil } from 'lodash';
import { RssPreviewItem } from './RssPreviewItem';
import { RssFeed } from 'utils/models';
import { RootState } from 'reducers';
import { useDispatch, useSelector } from 'react-redux';
import { resetNotSelectedIds, setNotSelectedIds } from 'slices/rss.slice';
import { useToggle } from 'react-use';
import { Info } from '../Info';
import { QuestionHint } from '../QuestionHint';

interface IPromiseParams {
  resolve: ({
    option,
    selectedFeeds,
  }: {
    option: ModalOptions;
    selectedFeeds?: RssFeed[];
  }) => void;
  reject: (err: any) => void;
}

interface Props {}

export const RssPreviewModal = forwardRef(
  (parentProps: Props, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));
    const dispatch = useDispatch();

    const rss = useSelector((state: RootState) => state.rss);

    const [rssFeeds, setRssFeeds] = useState<RssFeed[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isSelectAll, toggleSelectAll] = useToggle(true);

    const [fromYoutube, setFromYoutube] = useState(false);
    const [channelName, setChannelName] = useState('');
    const [currentItem, setCurrentItem] = useState<number>(0);
    const [totalVideo, setTotalVideo] = useState<number>(0);

    const hasReachYoutubeLimit = fromYoutube && currentItem < totalVideo;

    const selectedCount = rssFeeds.filter(
      (feed) => !rss.notSelectedIds.includes(feed?.id!),
    )?.length;

    useEffect(() => {
      if (isSelectAll && !isEmpty(rss?.notSelectedIds)) {
        toggleSelectAll(false);
      }

      if (isEmpty(rss?.notSelectedIds)) {
        toggleSelectAll(true);
      }
    }, [rss.notSelectedIds]);

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async ({
      rssFeeds,
      fromYoutube,
      title,
      currentItem,
      totalVideo,
    }: {
      rssFeeds: RssFeed[];
      fromYoutube?: boolean;
      title?: string;
      currentItem?: number;
      totalVideo?: number;
    }): Promise<unknown> => {
      !isEmpty(rssFeeds) && setRssFeeds(rssFeeds);
      !isNil(fromYoutube) && setFromYoutube(fromYoutube);
      !isNil(title) && setChannelName(title);
      !isNil(currentItem) && setCurrentItem(currentItem);
      !isNil(totalVideo) && setTotalVideo(totalVideo);

      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const hideModal = () => {
      setIsOpen(false);
      dispatch(resetNotSelectedIds());
      toggleSelectAll(true);
      setRssFeeds([]);
      setCurrentItem(0);
      setTotalVideo(0);
      setFromYoutube(false);
      setChannelName('');
    };

    const handleYes = async () => {
      const selectedFeeds = rssFeeds.filter(
        (feed) => !rss.notSelectedIds.includes(feed?.id!),
      );

      hideModal();
      promiseInfo.current?.resolve({
        option: ModalOptions.YES,
        selectedFeeds,
      });
    };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current?.resolve({ option: ModalOptions.CANCEL });
    };

    const handleToggleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
      const checked = e?.target?.checked;
      toggleSelectAll(checked);

      if (checked) {
        dispatch(resetNotSelectedIds());
      } else {
        const allIds = rssFeeds?.map(({ id }) => id!) ?? [];
        dispatch(setNotSelectedIds(allIds));
      }
    };

    return (
      <Modal
        show={isOpen}
        modalClosed={() => {
          hideModal();
          promiseInfo.current?.resolve({ option: ModalOptions.CLOSE });
        }}
      >
        <div tw="mt-2">
          <h1 tw="font-semibold text-sonnant-dark font-size[3rem]">
            {fromYoutube
              ? channelName || 'YouTube Channel Preview'
              : 'RSS Feed Preview'}
          </h1>

          <div tw="flex justify-between items-baseline">
            <label tw="flex[unset]! text-16 select-none cursor-pointer margin-left[8px]">
              <div>
                <input
                  name="caption"
                  checked={isSelectAll}
                  onChange={handleToggleSelectAll}
                  type="checkbox"
                />
                <span className="checkmark" style={{ top: '7px' }}></span>
                Select all
              </div>
            </label>
            <div tw="font-medium text-16 flex">
              {hasReachYoutubeLimit && (
                <span tw="mr-3 relative bottom[-1px]">
                  <QuestionHint
                    action="hover"
                    align="bottom"
                    type="information"
                    hideCloseButton
                    notTransparent
                    customStyle={customHintCss}
                    text={
                      <span className="slkslfjsldjfsdjf">
                        <div>
                          Only the first 500 videos from a YouTube Channel can
                          be loaded into Sonnant.
                        </div>
                        <div tw="mt-2">
                          Please contact{' '}
                          <a
                            tw="color[#1779ba]!"
                            href="mailto:support@sonnant.com"
                          >
                            support@sonnant.com
                          </a>{' '}
                          if you need this limit extended.
                        </div>
                      </span>
                    }
                  />
                </span>
              )}
              <span>
                Total items:{' '}
                {hasReachYoutubeLimit
                  ? `${currentItem}/${totalVideo.toLocaleString('en-AU')}`
                  : rssFeeds?.length}
              </span>
            </div>
          </div>
          <div
            tw="mb-6 max-height[70vh] overflow-x-hidden overflow-y-auto rounded"
            css={[
              !isEmpty(rssFeeds) &&
                tw`border[1px solid] border-sonnant-grey-light`,
            ]}
          >
            {rssFeeds?.map((feed) => (
              <RssPreviewItem
                key={feed?.id}
                feed={feed}
                fromYoutube={fromYoutube}
              />
            ))}
            {isEmpty(rssFeeds) && (
              <div tw="pl-3 py-2.5 mt-2 shadow border[1px solid] border-sonnant-grey-light">
                <Info
                  text="Nothing to show here. Please check your URL and try again."
                  hideSpacingBottom
                />
              </div>
            )}
          </div>

          <div className="grid-x">
            <div className="cell small-12">
              <div tw="w-full flex justify-end">
                <button
                  type="button"
                  className="button btn-secondary large"
                  tw="mr-6 hover:(border-sonnant-grey-3!)"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="button btn-primary large"
                  onClick={handleYes}
                  disabled={!selectedCount}
                >
                  Upload {selectedCount > 0 && <span>({selectedCount})</span>}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);

const customHintCss = css`
  .rmwc-tooltip {
    z-index: 999 !important;
  }

  .notificationWrapper {
    ${tw`width[30rem] mt-2 margin-left[-0.4rem]`};
  }
`;
