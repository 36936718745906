/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ApexOptions } from 'apexcharts';
import { isEmpty, isEqual, round, sum } from 'lodash';
import React, { useCallback } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getInsightLayerName } from 'utils/adapter.util';
import { ApexChartType, IabBrandSafe } from 'utils/enum';
import { Insights } from 'utils/models';
import { apexDonutColorsTheme } from 'utils/utils';
import { Info } from '../Info';

type Props = {
  series: number[];
  type: ApexChartType;
  optionKey: string;
  optionValue: [string, number, string][];
  width: number;
  handleClickPie: (value: string, searchField: keyof Insights) => void;
  hideTitle?: boolean;
};

export const DonutChart = React.memo(
  ({
    series,
    type,
    optionKey,
    optionValue = [],
    width,
    handleClickPie,
    hideTitle = false,
  }: Props) => {
    const sampleColors = useCallback((samples: [string, number, string][]) => {
      const colors = Array.from({ length: samples.length });

      let safeColorIndex = samples.findIndex(([name]) =>
        isEqual(name, IabBrandSafe.NAME),
      );

      // If found Brand Safe -> ignore first index
      const fromIndex = safeColorIndex < 0 ? 0 : 1;

      safeColorIndex < 0 && (safeColorIndex = 0);
      colors[safeColorIndex] = IabBrandSafe.COLOR;

      for (let i = fromIndex; i < colors.length; i++) {
        const nextColorIndex = (safeColorIndex + i) % colors.length;

        colors[nextColorIndex] =
          apexDonutColorsTheme[nextColorIndex % apexDonutColorsTheme.length];
      }

      return colors;
    }, []);

    const donutChartOption = (
      buckets: [string, number, string][],
      key: string,
    ): ApexOptions => ({
      chart: {
        width: '100%',
        height: '100%',
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 1500,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const original = buckets[config.dataPointIndex][2];

            if (typeof key !== 'undefined') {
              handleClickPie(original, key as keyof Insights);
            }
          },
        },
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
          dataLabels: {
            offset: 0,
            minAngleToShowLabel: 12,
          },
          donut: {
            size: '70%',
          },
        },
      },
      labels: buckets?.map(([key]) => key),
      dataLabels: {
        enabled: true,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45,
        },
        formatter: function (val: number, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];

          if (name.length > 12) {
            return [name.slice(0, 12) + '...', val.toFixed(1) + '%'] as any;
          }
          return [name, val.toFixed(1) + '%'];
        },

        style: {
          fontSize: '11px',
          fontFamily:
            "'Lexend', 'Roboto', 'Arial', 'Helvetica', 'Verdana', sans-serif",
        },
      },
      title: {
        text: !hideTitle
          ? `Top 20 ${getInsightLayerName(key as keyof Insights)}`
          : undefined,
        style: {
          fontSize: '18px',
        },
      },
      tooltip: {
        y: {
          formatter: function (value, opts) {
            const total = sum(opts.config.series);

            const percent = round((value / total) * 100, 2);

            return `${value} (${percent}%)`;
          },
        },
      },
      legend: {
        show: true,
        offsetY: 20,
        onItemHover: {
          highlightDataSeries: true,
        },
        formatter(legendName) {
          if (legendName.length > 24) {
            return legendName.slice(0, 24) + '...';
          }
          return legendName;
        },
      },
      colors: key === 'sensitive' ? sampleColors(buckets) : undefined,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    });

    if (isEmpty(series)) {
      return <Info text="Nothing to show" />;
    }

    return (
      <ReactApexChart
        options={donutChartOption(optionValue, optionKey)}
        series={series}
        type={type}
        width={width}
      />
    );
  },
);
