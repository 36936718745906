/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-mixed-operators */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { NumberInput } from 'components/shared/NumberInput';
import { QuestionHint } from 'components/shared/QuestionHint';
import { SuggestedTotal } from 'utils/models';

type Props = {
  title: string;
  hintText: string;
  inputName: string;

  value: string | number;
  disabled?: boolean;
  onChangeNumber: (value: SuggestedTotal) => void;
  onBlur?: () => void;
  min: number;
  step: number;
  max?: number;
};

export const SuggestedItem = ({
  title,
  hintText,
  inputName,
  value,
  disabled = false,
  onChangeNumber,
  onBlur,
  min,
  step,
  max,
}: Props) => {
  const handleChangeSuggestedNumberTotal = (newNumber: number) => {
    const newNumberObject = {
      inputName: inputName,
      number: newNumber,
    };

    onChangeNumber(newNumberObject);
  };

  return (
    <div css={suggestedTotalCss}>
      {title}
      <NumberInput
        value={value}
        min={min}
        step={step}
        max={max}
        onChangeNumber={handleChangeSuggestedNumberTotal}
        onBlur={onBlur}
        name={inputName}
        disabled={disabled}
        tw="(w-[5rem] h-[3rem] m-2)!"
      />

      <span css={suggestedTotalHintCss}>
        <QuestionHint
          notTransparent
          hideCloseButton
          type={'information'}
          align={'bottomLeft'}
          action={'hover'}
          text={<div tw="py-1 whitespace-nowrap">{hintText}</div>}
          customStyle={hintCustomStyle}
        />
      </span>
    </div>
  );
};

const suggestedTotalCss = css`
  ${tw`flex flex-row w-full items-center justify-end text-13 text-sonnant-grey-6 opacity-90 font-medium`}
`;

const suggestedTotalHintCss = css`
  ${tw`flex justify-start items-center width[7rem] pl-3`}
`;

const hintCustomStyle = css`
  ${tw`font-size[1rem]`}

  .notificationWrapper {
    ${tw`margin-top[1rem]!`}
  }
`;
