import { BaseAPI } from 'apis/amazon';
import { AxiosResponse } from 'axios';
import { IntegrationEnum } from 'utils/enum';
import { YoutubeSocialPayload } from 'utils/models';

export type IntegratePayload = {
  media_ids?: string[] | undefined;
  service?: string | undefined;
  collection_ids?: string[] | undefined;
  number_ads?: number;
};

export type ReprocessPayload = {
  collectionId: string | undefined;
  numberOfAds: number;
};

export type YoutubeCode = {
  code: string;
};

export const IntegrationService = {
  // POST /integration
  integrate: (payload: IntegratePayload) => {
    return BaseAPI.post('/integration', payload);
  },

  // POST /integration/ad-marker/re-processing
  reprocessAdMarker: (payload: ReprocessPayload) => {
    return BaseAPI.post('/integration/ad-marker/re-processing', {
      collection_id: payload.collectionId,
      number_ads: payload.numberOfAds,
      service: IntegrationEnum.OMNY,
    });
  },

  // GET /integration/social?provider=youtube
  getYoutubeIntegration: (): Promise<AxiosResponse<YoutubeSocialPayload>> => {
    return BaseAPI.get('/integration/social', {
      params: {
        provider: 'youtube',
      },
    });
  },

  sendCodeOAuthYoutube: (payload: YoutubeCode) => {
    return BaseAPI.post('/integration/social', {
      code: payload.code,
      provider: 'youtube',
    });
  },
};
