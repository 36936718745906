/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

import {
  formatBytes,
  secToTime,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { RootState } from 'reducers';
import { toDateWithHours } from 'utils/date.util';
import { extract } from 'utils/generic.util';
import { RssFeedDisplay } from 'utils/models';
import { AUDIO_THUMBNAIL } from '../twin.styles';

type Props = {
  feed: RssFeedDisplay;
  selectedRssFeeds: RssFeedDisplay[];
  handleCheckSelected: (feed: RssFeedDisplay) => void;
};

export const RssPreviewItemPagination = ({
  feed,
  selectedRssFeeds,
  ...props
}: Props) => {
  const location = useSelector(
    (state: RootState) => state.userProfile.location,
  );

  const handleToggleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    props.handleCheckSelected(feed);
  };

  return (
    <div tw="flex items-center text-sonnant-dark hover:(bg-sonnant-grey-light)! cursor-pointer p-3">
      <label tw="flex[unset]! cursor-pointer">
        <div>
          <input
            name="caption"
            checked={extract(selectedRssFeeds, 'id').includes(feed?.id)}
            onChange={handleToggleCheckbox}
            type="checkbox"
          />
          <span className="checkmark" style={{ top: '5px' }}></span>
        </div>
      </label>

      <div tw="mr-6 max-width[15rem]!">
        <img
          tw="max-width[15rem]! shadow-sm"
          src={feed?.imageURL || AUDIO_THUMBNAIL}
          alt={feed?.title}
        />
      </div>

      <div>
        <div tw="font-semibold text-sonnant-dark">{feed?.title}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: feed?.summary ?? feed?.description,
          }}
          tw="text-14 text-sonnant-grey-4"
          className="line-clamp-3"
        ></div>

        <div tw="text-18 mt-2 font-medium opacity-95">
          {feed?.duration && secToTime(feed?.duration)}
          {feed?.enclosure?.length && (
            <>- {formatBytes(+feed.enclosure.length)}</>
          )}
        </div>

        <div tw="text-15 opacity-75">
          {toDateWithHours(feed?.pubDate, location)}
        </div>
      </div>
    </div>
  );
};
