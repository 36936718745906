import { BaseAPI } from 'apis/amazon';
import { validateSortField } from 'slices/pagination.slice';
import {
  DatePeriodFilter,
  DurationFilter,
  FavoriteEnum,
  MediaType,
} from 'utils/enum';
import { CustomDateParams } from 'utils/models';

export type GetLibraryParams = {
  // library params
  page_number?: number;
  page_size?: number;
  collection_id?: string;
  sortBy?: string;
  order?: string;

  // advanced search params
  // collectionId?: string | null;
  isShowClipsOnly?: boolean;
  uploadDate?: DatePeriodFilter | null;
  customDate?: CustomDateParams | null;
  type?: MediaType | null;
  duration?: DurationFilter | null;
  speakerNames?: string[];

  // search params
  text?: string;
  searchField: string;
  searchValues: string[];
  [key: string]: string[] | any;
};

export type BucketInsight = {
  buckets: InsightKey[];
};
export type TrendingBucketInsight = {
  buckets: TrendingInsightKey[];
};

export type InsightKey = {
  collections: InsightBucket;
  media_ids: InsightBucket;
  doc_count: number;
  key: number;
  term_count: any;
};
export type TrendingInsightKey = {
  collections: InsightBucket;
  media_ids: InsightTrendingBucket;
  doc_count: number;
  key: number;
  term_count: any;
};

export type BaseBucket<T> = {
  buckets: T[];
  doc_count_error_upper_bound: number;
  sum_other_doc_count: number;
};

export type InsightBucket = BaseBucket<BucketKeyValue>;
export type InsightTrendingBucket = BaseBucket<TrendingBucketKeyValue>;

export type BucketKeyValue = {
  doc_count: number;
  key: string;
};

export type TrendingBucketKeyValue = {
  doc_count: number;
  key: string;
  thumbnail: string;
  title: string;
};

export type PostInsightResponse = {
  term_count_over_time: BucketInsight;
};
export type PostTrendingInsightResponse = {
  term_count_over_time: TrendingBucketInsight;
};

export type TermSearchInsight = {
  term: string;
} & PostInsightResponse;

export type TrendingTermSearchInsightResponse = {
  term: string;
  alias?: string;
  is_enabled?: boolean;
} & PostTrendingInsightResponse;

export type TrendingTermSearchInsight = {
  tagline?: string;
} & TrendingTermSearchInsightResponse;

export const LibraryService = {
  // GET /library
  getLibraryItems: (
    params: Omit<GetLibraryParams, 'searchField' | 'searchValues'>,
  ) => {
    return BaseAPI.get('/library', {
      params: {
        ...params,
        sortBy: validateSortField(params.sortBy),
      },
    });
  },

  // POST /library
  postAdvancedSearch: (params: GetLibraryParams) => {
    return BaseAPI.post('/library', {
      ...params,
      sortBy: validateSortField(params.sortBy),
      [params.searchField]: (params?.searchValues ?? []) as string[],
    });
  },

  getFavoriteAction: () => {
    return BaseAPI.get('/favourite');
  },

  updateFavoriteAction: (payload: FavoriteEnum[]) => {
    return BaseAPI.post('/favourite', {
      favourite_items: payload,
    });
  },
};
