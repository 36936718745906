/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import 'twin.macro';
import tw from 'twin.macro';
import './Share.scss';

import { ReactComponent as AscendingArrow } from 'assets/Icons/Ascending_Arrow.svg';
import { ReactComponent as DescendingArrow } from 'assets/Icons/Descending_Arrow.svg';
import { ReactComponent as ShareEmbed } from 'assets/Icons/ShareEmbed.svg';
import { ReactComponent as ShareFacebook } from 'assets/Icons/ShareFacebook.svg';
import { ReactComponent as ShareLinkedin } from 'assets/Icons/ShareLinkedin.svg';
import { ReactComponent as ShareTwitter } from 'assets/Icons/x-twitter-logo.svg';
import { ReactComponent as ShareTiktok } from 'assets/Icons/tiktok_logo.svg';
// import { ReactComponent as ShareYoutube } from 'assets/Icons/circle_youtube_icon.svg';
import { ReactComponent as ShareYoutube } from 'assets/Icons/youtube-logo-red.svg';
import { ReactComponent as ShareInstagram } from 'assets/Icons/instagram.svg';
import { ReactComponent as QuestionSvg } from 'assets/Icons/Vector_question.svg';
import { AxiosResponse } from 'axios';
import { config } from 'components/config';
import {
  audioThumbStyles,
  plyrResetStyle,
} from 'components/shared/twin.styles';
import { VideoControlBar } from 'components/shared/VideoControlBar';
import { Notification } from 'components/UiControls/notification/Notification';
import {
  isDisplayClips,
  shouldUsePlyr,
  mergeSameTerm,
  msToTimeCode,
  normalizeSummary,
  getWindowOptions,
  readCookie,
  msToSec,
  isAudioByMIME,
  isVideoByMIME,
  getExtFromS3Url,
  isValidInstagramCaption,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { defaultTo, isEmpty, isEqual, isNil, orderBy } from 'lodash';
import Plyr from 'plyr';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Column, HeaderGroup, Row, useSortBy, useTable } from 'react-table';
import { useCopyToClipboard, useToggle, useVideo } from 'react-use';
import { RootState } from 'reducers';
import { setFocusClip, setParentMediaid } from 'slices/clips.slice';
import { ClipStatus, ClipStatusCode, Ratio, Social } from 'utils/enum';
import {
  IClips,
  IKeyItem,
  IMediaDetail,
  IPublishVersion,
  ShareSocialTrending,
} from 'utils/models';
import { removeHTMLTag, removeSpaceChars, waitAsync } from 'utils/utils';

import { Tooltip } from '@rmwc/tooltip';

import Loader from '../loader/loader';
import { Modal } from '../UiControls/modal/modal';
import { ShareItemRow } from './ShareItemRow';
import { EmbedModal } from './EmbedModal/EmbedModal';
import { SharingOptionModal } from 'components/shared/SharingOptionModal/SharingOptionModal';
import { customToast } from 'utils/toast.util';
import { AuthService, ShareService, MediaService } from 'services';
import { ShareSocialButton } from './ShareSocialButton';
import { TikTokHelper } from './TikTokHelper';
import { YoutubeHelper } from './YoutubeHelper';
import { InstagramHelper } from './InstagramHelper';

interface IProps {
  mediaid: string;
  shareItem?: ShareSocialTrending;
  focusVersioncount?: string;
}

export const Share = (props: IProps) => {
  const dispatch = useDispatch();
  const [, copyToClipboard] = useCopyToClipboard();

  const publishedVersion = useSelector(
    (state: RootState) => state.getPublishedVersion,
  );

  const [shareVersions, setShareVersions] = useState<IClips[]>([]);
  const [selectedItem, setSelectedItem] = useState<IClips>();

  const [summary, setSummary] = useState('');
  const [recommendedRatio, setRecommendedRatio] = useState<Ratio>(
    Ratio['16_9'],
  );
  const [openModal, handleModal] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [isYoutubeShare, setIsYoutubeShare] = useState(false);
  const [clipTitle, setClipTitle] = useState('');
  const [clipCategory, setClipCategory] = useState<string>('1');
  const [privacyStatus, setPrivacyStatus] = useState<string>('public');
  const [publishedVersionName, setPublishedVersionName] = useState<string>();

  const [shareLoading, toggleShareLoading] = useToggle(false);
  const [revokeLoading, toggleRevokeLoading] = useToggle(false);
  const [versionsLoading, toggleVersionsLoading] = useToggle(false);

  const clips = useSelector((state: RootState) => state.clips);
  const preferenceState = useSelector((state: RootState) => state.global);

  const canShare =
    ![
      ClipStatusCode.SHARED,
      ClipStatusCode.CREATING,
      ClipStatusCode.CREATION_FAILED,
    ].includes(selectedItem?.statuscode!) && selectedItem;
  const canRevoke = isEqual(selectedItem?.statuscode, ClipStatusCode.SHARED);
  const isShared = isEqual(selectedItem?.statuscode, ClipStatusCode.SHARED);
  const shareURL = selectedItem?.share_url ?? '';
  const shareId = shareURL ? shareURL.split('-').slice(-1)[0] : '';
  const isCreating = selectedItem?.statuscode === ClipStatusCode.CREATING;

  const canShareTikTok =
    ['video/webm', 'video/mp4'].includes(selectedItem?.mediatype ?? '') &&
    getExtFromS3Url(selectedItem?.url) !== 'm4v' &&
    msToSec(selectedItem?.length) >= 3 &&
    msToSec(selectedItem?.length) < 60;

  const canShareInstagram =
    (['video/quicktime', 'video/mp4', 'video/webm'].includes(
      selectedItem?.mediatype ?? '',
    ) ||
      selectedItem?.hasBurnIn === true) &&
    getExtFromS3Url(selectedItem?.url) !== 'm4v' &&
    msToSec(selectedItem?.length) >= 3 &&
    msToSec(selectedItem?.length) < 60;

  const isAudio = isAudioByMIME(selectedItem?.mediatype);
  const isVideo = isVideoByMIME(selectedItem?.mediatype);
  const canShareYoutube = (isAudio && selectedItem?.hasBurnIn) || isVideo;

  const shareSocialVideoRef = useRef<any>(null);
  const embedModalRef = useRef<any>(null);
  const shareOptionModalRef = useRef<any>(null);

  const previewVideoLoading = versionsLoading || !selectedItem?.url;

  const [timeFragment] = useState('#t=0.01');

  const [video, videoState, controls, videoRef] = useVideo(
    <video
      css={[
        !clips?.focusClip?.hasBurnIn &&
          audioThumbStyles(clips.focusClip?.thumbnail),
        previewVideoLoading ? tw`hidden` : tw`block`,
        shouldUsePlyr() &&
          tw`(absolute top-0 height[calc(100% - 8.5rem)] w-full bg-sonnant-grey-1 overflow-hidden)!`,
      ]}
      // tw="w-full! rounded-t-md cursor-pointer background-color[#f0f0f4]!"
      src={selectedItem?.url ?? '' + timeFragment}
      controls={false}
      autoPlay={false}
      preload="metadata"
      crossOrigin="anonymous"
      controlsList="nodownload"
      playsInline
      onClick={() => {
        const isFullScreen = (videoRef.current as any)
          ?.webkitDisplayingFullscreen;
        if (isFullScreen) return;
        videoState.paused ? controls.play() : controls.pause();
      }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <track
        label="English"
        kind="subtitles"
        srcLang="en"
        src={selectedItem?.subtitleurl}
        default
      ></track>
    </video>,
  );

  useEffect(() => {
    new Plyr(shareSocialVideoRef.current, {
      captions: { active: true, update: true },
      ratio: '16:9',
      controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'captions',
        'settings',
        'fullscreen',
      ],
      storage: { enabled: false },
    });
  }, [shareSocialVideoRef?.current, openModal]);

  useEffect(() => {
    if (!props.mediaid) return;

    // Callback after complete async
    fetchVersionAsync((versions: IClips[]) => {
      if (!isNil(props?.focusVersioncount)) {
        const notiItemVersion = versions.find(
          (v) => v?.versioncount === props?.focusVersioncount,
        );

        notiItemVersion && focusExistedVersion(versions, notiItemVersion);
      }
      /*
        Share from NOTIFICATION
        Have to run after enough data
      */
      if (!isEmpty(props?.shareItem)) {
        const { provider, shareURL, shareId } = props.shareItem!;

        if (provider === Social.LINKEDIN) {
          authorizationLinkedIn(shareId);
        } else if (provider === Social.FACEBOOK) {
          authorizationFacebook(shareURL, shareId);
        } else if (provider === Social.TWITTER) {
          authorizationTwitter(shareId);
        } else if (provider === Social.INSTAGRAM) {
          authorizationInstagram(shareId);
        }

        const origVersion = versions.find((v) => v?.versioncount === '0');
        if (origVersion) {
          focusExistedVersion(versions, origVersion);
        }
      }
    });

    return () => {
      setShareVersions([]);
    };
  }, [props.mediaid]);

  //authorization LinkedIn
  const authorizationLinkedIn = (shareId: string) => {
    const oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${config.LINKEDIN_CLIENT_ID}&redirect_uri=${config.LINKEDIN_REDIRECT_URL}&state=${shareId}&scope=r_liteprofile%20r_emailaddress%20w_member_social`;
    popupShare(oauthUrl, Social.LINKEDIN, shareId);
  };

  // authorization Facebook
  const authorizationFacebook = async (ShareURL: string, shareId: string) => {
    // if (true) {
    const oauthUrl = `https://www.facebook.com/dialog/oauth?client_id=${config.FB_CLIENT_ID}&redirect_uri=${config.FB_REDIRECT_URL}&response_type=code&scope=pages_show_list,pages_manage_posts,pages_read_engagement`;
    window.document.cookie = `mediaKeyFacebook=${shareId};domain=.${window.location.host}; path=/`;
    popupShare(oauthUrl, Social.FACEBOOK, shareId);
  };

  // authorization Twitter
  const authorizationTwitter = async (shareId: string) => {
    try {
      const { data: oauthToken } = await AuthService.getTwitterToken();

      const oauthUrl = `https://api.twitter.com/oauth/authorize?oauth_token=${oauthToken}`;
      window.document.cookie = `mediaKeyTwitter=${shareId};domain=.${window.location.host}; path=/`;
      popupShare(oauthUrl, Social.TWITTER, shareId);
    } catch (err: any) {
      customToast.error('Something went wrong');
    }
  };
  //authorization Instagram
  const authorizationInstagram = (shareId: string) => {
    const oauthUrl = `https://www.facebook.com/dialog/oauth?client_id=${config.FB_CLIENT_ID}&redirect_uri=${config.INSTAGRAM_REDIRECT_URL}&response_type=code&scope=pages_show_list,instagram_basic,instagram_content_publish,pages_read_engagement`;
    window.document.cookie = `mediaKeyInstagram=${shareId};domain=.${window.location.host}; path=/`;
    popupShare(oauthUrl, Social.INSTAGRAM, shareId);
  };

  const popupShare = (
    oauthUrl: string,
    provider: Social,
    existedShareId: string,
  ) => {
    const popup = window.open(oauthUrl, provider, getWindowOptions());
    window.document.cookie = `state=false; domain=.${config.DOMAIN_NAME}; path=/`;

    const popupTick = setInterval(function () {
      if (popup?.closed) {
        clearInterval(popupTick);
        const state = readCookie('state');

        if (state === 'true') {
          openModalSharePost(provider, existedShareId);
        }
      }
    }, 500);
  };

  const fetchVersionAsync = async (
    callback: (versions: IClips[]) => unknown,
  ) => {
    try {
      toggleVersionsLoading(true);
      const { data }: AxiosResponse<IPublishVersion> =
        await MediaService.getPublishVersion({ mediaid: props.mediaid });

      const totalList = orderBy(
        [
          ...data.versions,
          ...data.suggestedVersions,
          ...(data.trendingVersions ?? []),
        ],
        (v) => new Date(v.datetime),
        ['desc'],
      );

      setPublishedVersionName(data.title);
      setShareVersions(totalList);
      dispatch(setParentMediaid(props.mediaid));

      if (!isEmpty(clips?.focusClip) && isDisplayClips()) {
        focusExistedVersion(totalList, clips.focusClip!);
      } else {
        focusFirstVersion(totalList);
      }

      callback(totalList);
    } finally {
      toggleVersionsLoading(false);
    }
  };

  const focusFirstVersion = (versions: IClips[]) => {
    const focusItem = versions?.find(
      (v) => !isEqual(v?.statuscode, ClipStatusCode.CREATING),
    );

    if (focusItem) {
      setSelectedItem(focusItem);
      dispatch(setFocusClip(focusItem));
    }
  };

  const focusExistedVersion = (versions: IClips[], existed: IClips) => {
    if (!versions || !existed) return;

    const focusItem = versions?.find((v) =>
      isEqual(v?.versioncount, existed?.versioncount),
    );

    if (focusItem) {
      setSelectedItem(focusItem);
      dispatch(setFocusClip(focusItem));
    }
  };

  const handleModalSharePost = async () => {
    try {
      const provider = readCookie('provider');
      if (!provider) return;

      let params = {};
      let messageSuccess = '';

      setIsPosting(true);

      if (provider === Social.TWITTER) {
        if (summary.length <= 280) {
          params = {
            oauth_token: readCookie('oauth_token'),
            oauth_verifier: readCookie('oauth_verifier'),
            mediaKey: readCookie('mediaKeyTwitter'),
            content: summary,
            provider: provider,
          };
          messageSuccess =
            'Shared to your Twitter account. It may take a few minutes to be published.';
        } else {
          setIsPosting(false);
          customToast.error(
            'Twitter just allow the character limit for tweets to 280',
          );
          return;
        }
      } else if (provider === Social.LINKEDIN) {
        params = {
          code: readCookie('code'),
          mediaKey: readCookie('mediaKey'),
          content: summary,
          provider: provider,
        };
        messageSuccess =
          'Shared to your LinkedIn account. It may take a few minutes to be published.';
      } else if (provider === Social.FACEBOOK) {
        if (isEmpty(JSON.parse(readCookie('page_data') ?? '{}'))) {
          customToast.error(
            'At least one page should be selected to be available for sharing. Please try again.',
          );
          return;
        }

        params = {
          page_data: readCookie('page_data'),
          mediaKey: readCookie('mediaKeyFacebook'),
          content: summary,
          provider: provider,
        };
        messageSuccess =
          'Shared to your Facebook page(s). It may take a few minutes to be published.';
      } else if (provider === Social.YOUTUBE) {
        params = {
          access_token: readCookie('access_token'),
          mediaKey: readCookie('mediaKeyYoutube'),
          clipCategory: clipCategory,
          privacyStatus: privacyStatus,
          content: summary,
          provider: provider,
          title: clipTitle,
          recommendedRatio: recommendedRatio,
        };
        messageSuccess =
          'Your clip was sent to your Youtube Channel. It may take a few minutes to be published.';
      } else if (provider === Social.INSTAGRAM) {
        if (!isValidInstagramCaption(summary)) {
          customToast.error(
            <div>
              Instagram caption should contain maximum <b>2200 characters</b>,{' '}
              <b>30 hashtags</b>,
              <div>
                and <b>20 @ tags</b>. Please check and try again.
              </div>
            </div>,
          );
          return;
        }

        if (isEmpty(JSON.parse(readCookie('page_data') ?? '{}'))) {
          customToast.error(
            'At least one page should be selected to be available for sharing. Please try again.',
          );
          return;
        }

        params = {
          page_data: readCookie('page_data'),
          mediaKey: readCookie('mediaKeyInstagram'),
          content: summary,
          provider: provider,
          recommendRatio: recommendedRatio,
        };
        messageSuccess =
          'Shared to your Instagram. It may take a few minutes to be published.';
      }
      if (isEmpty(params)) return;

      const response = await ShareService.shareSocial(provider, params);
      if (response.data === 'SUCCESS') {
        customToast.success(messageSuccess);
      } else {
        customToast.error('Failed to share post');
      }
      handleModal(false);
    } catch (error: any) {
      handleModal(false);
      customToast.error('Failed to share post');
    } finally {
      setIsPosting(false);
    }
  };

  const preventDoubleCaption = async () => {
    if (!videoRef?.current?.textTracks?.[0]) return;

    if (videoRef.current.textTracks[0].mode === 'showing') {
      videoRef.current.textTracks[0].mode = 'hidden';
      await waitAsync(1500);
      videoRef.current.textTracks[0].mode = 'showing';
    }
  };

  const handleRowClick = (item: IClips) => {
    controls.pause();
    preventDoubleCaption();
    setSelectedItem(item);
    dispatch(setFocusClip(item));
  };

  const handleRevoke = async (item?: IClips) => {
    if (!item) return;

    const revokeRequestAsync = ShareService.revokeShare({
      mediaid: props.mediaid,
      versioncount: item?.versioncount,
    });

    customToast.promise(revokeRequestAsync, {
      loading: 'Revoking...',
      success: 'Revoke successful',
      error: 'Revoke failed',
    });

    try {
      toggleRevokeLoading(true);
      await revokeRequestAsync;

      updateRenderItems(item?.versioncount, {
        statuscode: ClipStatusCode.CREATED,
        status: ClipStatus.CREATED,
        share_url: null,
      });
    } catch (err: any) {
      console.log('err :>> ', err);
    } finally {
      toggleRevokeLoading(false);
    }
  };

  const handleShare = async (item?: IClips) => {
    if (!item) return;

    try {
      toggleShareLoading(true);
      const payload: any = {
        mediaid: props?.mediaid,
        versioncount: item.versioncount,
      };

      if (item?.versioncount === '0') {
        payload.endtime = msToTimeCode(item.length);
      }

      customToast.loading('Creating publish link');
      const { data: link } = await ShareService.share(payload);
      customToast.success('Publish link created successfully');

      const updatedItem: any = {
        statuscode: ClipStatusCode.SHARED,
        status: ClipStatus.SHARED,
        share_url: link,
      };

      if (item?.versioncount === '0') {
        updatedItem.options = { ...preferenceState.preference };
      }

      updateRenderItems(item.versioncount, updatedItem);
    } catch (err: any) {
      customToast.error('Something went wrong');
    } finally {
      toggleShareLoading(false);
    }
  };

  // Update latest data into Rendered List
  const updateRenderItems = (
    versioncount: string,
    newProperty: Partial<IClips>,
  ) => {
    const cloneVersions = [...shareVersions];
    const foundIndex = cloneVersions?.findIndex(
      (v) => v?.versioncount === versioncount,
    );

    // Check if existed
    const foundVersion = cloneVersions?.[foundIndex];
    if (!foundVersion) return;

    // Override found item
    cloneVersions[foundIndex] = { ...foundVersion, ...newProperty };
    setShareVersions(cloneVersions);

    // Renew current selected for new update item
    setSelectedItem(cloneVersions[foundIndex]);
    dispatch(setFocusClip(cloneVersions[foundIndex]));
  };

  const openModalSharePost = async (provider: Social, shareId: string) => {
    let mediaData: AxiosResponse<IMediaDetail> | undefined = undefined;
    try {
      customToast.loading('Loading media details');

      mediaData = await MediaService.getPublicDetails(shareId);
      toast.dismiss();
    } catch {
      customToast.error('Failed to get media details');
      toast.dismiss();
    }
    if (!mediaData) return;

    handleModal(true);
    let content = '';

    if (
      [
        Social.LINKEDIN,
        Social.FACEBOOK,
        Social.YOUTUBE,
        Social.INSTAGRAM,
      ].includes(provider)
    ) {
      content = `${mediaData?.data?.versionname} \n${removeHTMLTag(
        normalizeSummary(mediaData?.data?.metadata?.summary),
      )} \n`;
    } else {
      content = `${mediaData?.data?.versionname}\n${shareURL}\n`;
    }
    mergeSameTerm(mediaData?.data?.metadata?.keywords).forEach(
      (element: IKeyItem) => {
        content = `${content} #${removeSpaceChars(element.keyword)
          .replace(/\s/gm, '')
          .replace(/\W/gm, '_')} `;
      },
    );

    if (provider === Social.YOUTUBE) {
      setIsYoutubeShare(true);
      setClipTitle(mediaData?.data?.versionname);
    }
    if (provider === Social.TIKTOK || provider === Social.INSTAGRAM) {
      setRecommendedRatio(Ratio['9_16']);
    }
    setSummary(content);
  };

  const handleModalClose = () => {
    handleModal(false);
    setIsYoutubeShare(false);
  };

  const columns = useMemo(
    (): Column<IClips>[] => [
      { Header: 'CLIP NAME', accessor: 'versionname' },
      { Header: 'DURATION', accessor: 'length', width: '11%' },
      { Header: 'LAST MODIFIED', accessor: 'datetime', width: '18%' },
      { Header: 'STATUS', accessor: 'status', width: '9%' },
    ],
    [],
  );

  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data: shareVersions ?? [] },
    useSortBy,
  );

  const handleEmbedModal = async () => {
    if (isEmpty(shareURL)) return;
    embedModalRef.current?.show();
  };

  const handleShowSharingOptions = async () => {
    await shareOptionModalRef.current?.show();
  };

  return (
    <div className="sharemodal">
      <div className="grid-x">
        <div className="cell large-12">
          <h2>Share a clip</h2>
        </div>
      </div>
      <div tw="mt-8 flex items-center justify-between w-full">
        <div tw="flex justify-start items-center">
          <h3 tw="mr-4 ">Start by selecting a clip</h3>
          <TooltipNotification />
        </div>
      </div>

      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: HeaderGroup<IClips>) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              tw="select-none"
              css={[shareVersions?.length > 4 && tw`relative right-2.5`]}
            >
              {headerGroup.headers.map((column: any, index: number) => (
                <th
                  width={column.width}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  css={index === 0 && tw`pl-2.5!`}
                >
                  {column.render('Header')}
                  {column.isSorted &&
                    (column.isSortedDesc ? (
                      <DescendingArrow tw="ml-2 relative top[-2px] opacity-60" />
                    ) : (
                      <AscendingArrow tw="ml-2 relative top[-2px] opacity-60" />
                    ))}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {shareVersions?.length === 0 && (
            <div tw="w-full flex justify-center">
              <span tw="width[6rem]">
                <Loader />
              </span>
            </div>
          )}
          {rows.map((row: Row<IClips>) => {
            prepareRow(row);
            return (
              <ShareItemRow
                key={row.id}
                selectedRow={selectedItem}
                row={row}
                handleRowClick={handleRowClick}
              />
            );
          })}
        </tbody>
      </table>
      <hr tw="max-w-full" />

      <div tw="flex mt-6">
        {isCreating && (
          <div tw="w-1/3 text-sonnant-dark text-16 font-medium">
            Clip creating
          </div>
        )}
        <div tw="w-1/3" css={[isCreating && tw`hidden`]}>
          <div
            css={[
              sharePlayerStyle,
              shouldUsePlyr() &&
                !previewVideoLoading &&
                tw`(w-full h-full relative padding-top[56.25%] rounded)!`,
            ]}
          >
            {previewVideoLoading && (
              <div tw="w-full flex border[1px solid] border-sonnant-grey-2 rounded-t-lg min-height[150px] shadow">
                <Loader tw="m-auto" />
              </div>
            )}
            {video}
            {videoRef?.current && (
              <VideoControlBar
                state={videoState}
                controls={controls}
                ref={videoRef}
              />
            )}
          </div>
        </div>
        <div tw="pl-8 flex-1">
          <div tw="flex justify-between items-center">
            <div tw="flex items-center">
              <label tw="whitespace-nowrap mr-3 width[13rem]">
                Clip sharing:
              </label>
              <div>
                <button
                  type="button"
                  className="button btn-secondary large cancel small-3 cell"
                  onClick={handleShowSharingOptions}
                  css={shareInput}
                  tw="width[unset]!"
                  disabled={!isShared}
                >
                  Options
                </button>
              </div>
            </div>

            <div tw="flex items-center">
              <div tw="flex flex-nowrap">
                <button
                  disabled={!canRevoke || revokeLoading}
                  type="button"
                  className="button btn-secondary large cancel"
                  tw="px-5! width[unset]!"
                  css={shareInput}
                  onClick={() => handleRevoke(selectedItem)}
                >
                  Revoke
                </button>
                <button
                  type="button"
                  className="button btn-primary large apply"
                  disabled={!canShare || shareLoading}
                  onClick={() => handleShare(selectedItem)}
                  tw="ml-4 width[unset]!"
                  css={shareInput}
                >
                  Share
                </button>
              </div>
            </div>
          </div>
          <div tw="flex items-center my-6 mt-12!">
            <label css={[shareLabel]} tw="whitespace-nowrap">
              Publish link:
            </label>
            <div tw="flex-1 relative mr-4 ">
              <input
                type="text"
                title={shareURL}
                value={shareLoading ? 'Loading...' : shareURL}
                maxLength={100}
                tw="mb-0 w-full! pr-20!"
                css={shareInput}
                disabled={!shareURL}
              />
              <a
                type="button"
                tw="absolute font-size[14px]! (color[#1779ba] p-0 mb-0 line-height[1] top[11px] right[11px])!"
                onClick={() => {
                  if (!shareURL) return;

                  copyToClipboard(shareURL);
                  customToast.success('Copied to clipboard');
                }}
                className={!shareURL ? 'disabled' : ''}
              >
                copy
              </a>
            </div>

            <div style={{ textAlign: 'right' }}>
              <button
                type="button"
                onClick={() => {
                  window.open(shareURL);
                }}
                className="button btn-primary large apply"
                tw="width[unset]!"
                css={shareInput}
                disabled={!shareURL}
              >
                Open
              </button>
            </div>
          </div>
          <div tw="flex items-center">
            <label css={[shareLabel]} tw="whitespace-nowrap">
              Create post:
            </label>
            <div tw="flex -ml-2">
              <ShareSocialButton
                shareId={shareId}
                shareURL={shareURL}
                openModalSharePost={openModalSharePost}
                icon={
                  <>
                    <ShareLinkedin
                      css={!shareURL && cannotShareCss}
                      className="svg-shadow-hover"
                    />
                  </>
                }
                name={Social.LINKEDIN}
              />

              <ShareSocialButton
                shareId={shareId}
                openModalSharePost={openModalSharePost}
                shareURL={shareURL}
                icon={
                  <>
                    <ShareFacebook
                      css={!shareURL && cannotShareCss}
                      className="svg-shadow-hover"
                    />
                  </>
                }
                name={Social.FACEBOOK}
              />

              <ShareSocialButton
                shareId={shareId}
                shareURL={shareURL}
                openModalSharePost={openModalSharePost}
                icon={
                  <div tw="flex items-center h-full">
                    <ShareTwitter
                      css={!shareURL && cannotShareCss}
                      tw="w-[3.5rem] h-[3.5rem] bg-black rounded-full p-2"
                      className="svg-shadow-hover"
                    />
                  </div>
                }
                name={Social.TWITTER}
              />

              <ShareSocialButton
                shareId={shareId}
                shareURL={shareURL}
                openModalSharePost={openModalSharePost}
                disabled={!canShareYoutube}
                icon={
                  <YoutubeHelper
                  //  disabled={canShareYoutube}
                  >
                    <ShareYoutube
                      css={(!shareURL || !canShareYoutube) && cannotShareCss}
                      tw="h-20 mt--1 width[5rem]!"
                      className="svg-shadow-hover"
                    />
                  </YoutubeHelper>
                }
                name={Social.YOUTUBE}
              />

              <ShareSocialButton
                shareId={shareId}
                openModalSharePost={openModalSharePost}
                shareURL={shareURL}
                disabled={!canShareTikTok}
                icon={
                  <TikTokHelper disabled={canShareTikTok}>
                    <ShareTiktok
                      css={(!shareURL || !canShareTikTok) && cannotShareCss}
                      tw="h-20 mt--1"
                      className="svg-shadow-hover"
                    />
                  </TikTokHelper>
                }
                name={Social.TIKTOK}
              />

              <ShareSocialButton
                shareId={shareId}
                openModalSharePost={openModalSharePost}
                shareURL={shareURL}
                disabled={!canShareInstagram}
                icon={
                  <InstagramHelper disabled={canShareInstagram}>
                    <ShareInstagram
                      css={(!shareURL || !canShareInstagram) && cannotShareCss}
                      tw="h-20 mt--1"
                      className="svg-shadow-hover"
                    />
                  </InstagramHelper>
                }
                name={Social.INSTAGRAM}
              />
            </div>
          </div>
          <div tw="flex items-center">
            <label css={[shareLabel]} tw="whitespace-nowrap">
              Embed clip:
            </label>
            <div
              css={[actionIcon, !shareURL && cannotShareCss]}
              tw="-ml-2"
              onClick={handleEmbedModal}
            >
              <ShareEmbed className="svg-shadow-hover" />
              Embed
            </div>
          </div>
        </div>
      </div>

      <div className="public-post-share-modal">
        <Modal
          show={openModal}
          modalClosed={handleModalClose}
          classes="nested-share-modal"
        >
          {isPosting ? (
            <div tw="h-full! flex justify-center py-24">
              <Loader />
            </div>
          ) : (
            <div>
              <div tw="flex-row">
                {isYoutubeShare && (
                  <>
                    <div tw="z-0">
                      <label
                        htmlFor="title"
                        tw="font-bold text-4xl text-black mt-5"
                      >
                        Title
                      </label>
                      <input
                        type="text"
                        value={clipTitle}
                        name="title"
                        tw="w-full mb-0"
                        css={[
                          !clipTitle?.trim() &&
                            tw`border-sonnant-red! hover:(border-sonnant-red)! focus:(border-sonnant-red)!`,
                        ]}
                        disabled={false}
                        required
                        maxLength={100}
                        onChange={(e) => setClipTitle(e.target.value)}
                        placeholder={'Give shared clip a name...'}
                      />
                      {!clipTitle?.trim() && (
                        <div tw="text-13 text-sonnant-red -mb-6">
                          Title cannot be blank
                        </div>
                      )}
                    </div>
                    <div tw="flex space-x-6">
                      <div tw="w-1/2">
                        <label
                          htmlFor="category"
                          tw="font-bold text-4xl text-black mt-3"
                        >
                          Category
                        </label>
                        <select
                          tw="height[40px] w-full mt-2 mb-3 outline-none cursor-pointer border[2px solid transparent] shadow-sm"
                          onChange={(e) => setClipCategory(e.target.value)}
                          css={[customSelect]}
                          value={clipCategory}
                          name="category"
                        >
                          <option value="1"> Film &amp; Animation</option>
                          <option value="2">Autos &amp; Vehicles</option>
                          <option value="10">Music</option>
                          <option value="15">Pets &amp; Animals</option>
                          <option value="17">Sports</option>
                          <option value="19">Travel &amp; Events</option>
                          <option value="20">Gaming</option>
                          <option value="22">People &amp; Blogs</option>
                          <option value="23">Comedy</option>
                          <option value="24">Entertainment</option>
                          <option value="25">News &amp; Politics</option>
                          <option value="26">How-to &amp; Style</option>
                          <option value="27">Education</option>
                          <option value="28">Science &amp; Technology</option>
                          <option value="29">Non-profits &amp; Activism</option>
                        </select>
                      </div>
                      <div tw="w-1/2">
                        <label
                          htmlFor="privacyStatus"
                          tw="font-bold text-4xl text-black mt-3"
                        >
                          Privacy status
                        </label>
                        <select
                          tw="height[40px] w-full mt-2 mb-3 outline-none cursor-pointer border[2px solid transparent] shadow-sm"
                          onChange={(e) => setPrivacyStatus(e.target.value)}
                          css={[customSelect]}
                          name="privacyStatus"
                        >
                          <option value="public">Public</option>
                          <option value="unlisted">Unlisted</option>
                          <option value="private">Private</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}
                <div
                  tw="font-bold text-4xl text-black mt-5"
                  style={{ fontFamily: 'Roboto' }}
                >
                  {!isYoutubeShare ? 'Content' : 'Description'}
                </div>
                <div tw="flex-1 mt-4">
                  <div>
                    <textarea
                      className="textarea"
                      contentEditable="true"
                      value={normalizeSummary(summary)}
                      style={{ resize: 'none' }}
                      onChange={(e) => setSummary(e.target.value)}
                      tw="(p-6 pt-4 border[2px solid transparent])!"
                      maxLength={5000}
                      autoFocus
                    />
                  </div>
                </div>
              </div>
              <div
                tw="font-bold text-4xl text-black mt-6 mb-3"
                style={{ fontFamily: 'Roboto' }}
              >
                {publishedVersion?.data?.haveMp3 ? 'Audio' : 'Video'}
              </div>
              <div tw="flex-1 relative" css={plyrResetStyle}>
                <video
                  ref={shareSocialVideoRef}
                  tw="w-full!"
                  css={[audioThumbStyles(clips.focusClip?.thumbnail)]}
                  src={selectedItem?.url + '#t=0.1'}
                  controls={true}
                  // autoPlay="true"
                  crossOrigin="anonymous"
                  controlsList="nodownload"
                  preload="metadata"
                  onContextMenu={(e) => e.preventDefault()}
                  playsInline
                >
                  <track
                    label="English"
                    kind="subtitles"
                    srcLang="en"
                    src={selectedItem?.subtitleurl}
                    default
                  ></track>
                </video>
                <div className="grid-x" tw="flex justify-end mt-4">
                  <button
                    style={{ marginLeft: 2 }}
                    type="button"
                    className="button btn-secondary large cancel small-3 cell"
                    tw="mr-5 line-height[unset]"
                    onClick={handleModalClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    disabled={
                      !summary || (isYoutubeShare && !clipTitle?.trim())
                    }
                    onClick={() => handleModalSharePost()}
                    className="button btn-primary large apply"
                    tw="line-height[unset] focus:(bg-sonnant-purple-2)!"
                  >
                    Post
                  </button>
                </div>
              </div>
            </div>
          )}
        </Modal>

        <EmbedModal
          shareUrl={shareURL}
          ref={embedModalRef}
          embedTitle={
            selectedItem?.versionname === ClipStatus.ORIGINAL
              ? defaultTo(publishedVersionName, ClipStatus.ORIGINAL)
              : defaultTo(selectedItem?.versionname, '')
          }
        />

        <SharingOptionModal ref={shareOptionModalRef} />
      </div>
    </div>
  );
};

const shareLabel = css`
  ${tw`min-width[13rem] mr-3`}
`;

const actionIcon = css`
  ${tw`font-size[1.2rem] width[5.5rem]! flex flex-col items-center text-sonnant-grey-5 cursor-pointer`}
  &:not(:last-child) {
    ${tw`mr-8`}
  }

  svg {
    width: 3.5rem;
    margin-bottom: -4px;
  }
`;

const shareInput = css`
  ${tw`(height[3.5rem] line-height[unset] min-width[10rem] font-size[1.4rem])!`}
`;

const cannotShareCss = css`
  ${tw`opacity-50 cursor-not-allowed!`}
`;

const sharePlayerStyle = css`
  & ::cue {
    font-size: 1rem !important;
  }

  .plyr__captions {
    bottom: -1rem;
    line-height: 1.2;
  }

  .plyr__caption {
    font-size: 1.1rem;
  }
`;

const customTooltipStyles = css`
  ${tw`font-size[1rem]`}

  .rmwc-tooltip-inner {
    background: none;
  }
`;

const TooltipNotification = memo(() => (
  <Tooltip
    align="bottomLeft"
    tw="z-index[100]"
    content={
      <div tw="width[30rem] top-0 left[-1.5rem] font-size[1.6rem] line-height[1.9rem] border-radius[3px] absolute">
        <Notification
          hideCloseButton
          name="share-tooltip"
          type="information"
          message={
            <div tw="py-1">
              <p>
                Select a clip, then share it to allow you to share it to
                Socials, public player and enable export.
              </p>
            </div>
          }
        />
      </div>
    }
    css={customTooltipStyles}
  >
    <div className="questionsvg">
      <QuestionSvg />
    </div>
  </Tooltip>
));

const customSelect = css`
  option {
    ${tw`cursor-pointer!`}
  }
`;
