/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';
import 'twin.macro';

import { config, IS_USE_SONNANT_V2 } from 'components/config';
import { SubscriptionNavbar } from 'components/shared/SubscriptionNavbar/SubscriptionNavbar';
import {
  downloadMultipleFiles,
  getCurrentMediaid,
  getStringDynamo,
  hasNavArrow,
  hasNavigator,
  isAuthPage,
  isDevMode,
  isDisplayClips,
  isEmbedPage,
  isLibraryPage,
  isMaintenanceMode,
  isNotifySocketMessage,
  isPublicShared,
  isTranscriptionPage,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import $ from 'jquery';
import { debounce, isEmpty, isEqual, toNumber } from 'lodash';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import {
  Keys,
  NotificationType,
  ToastNotiEnum,
  VideoStatusCode,
} from 'utils/enum';
import * as actions from 'actions';
import * as types from 'actions/types';
import './NavbarMenu.scss';
import {
  formateImageURL,
  getLocalProfilePic,
  getTenantidFromIdToken,
  removeExtension,
  isLoggedIn,
  clearSession,
  getSubdomainFromURL,
} from '../../utils/utils';
import { index as HomeIcon } from '../UiControls/homeSVG/index';
import SonnantLogo from '../UiControls/homeSVG/sonnantlogo';
import SonnantLogoText from '../UiControls/homeSVG/sonnantLogoText';
import { ReactComponent as ArrowBack } from '../../assets/Icons/backArrow.svg';
import SonnantLogoPink from 'assets/auth/logo-pink.png';

import { ReactComponent as Upload } from '../../assets/Icons/upload.svg';
import { ReactComponent as AddSvg } from '../../assets/Icons/add_24dp.svg';
import { IdleTimerContainer } from '../../components/IdleTimerContainer/IdleTimerContainer';
import { getIdToken } from '../../utils/utils';
import { Fab } from '@rmwc/fab';
import './NavbarMenu.scss';
import { PercentProgress } from 'components/shared/PercentProgress';
import { ThumbnailModal } from 'components/shared/ThumbnailModal/ThumbnailModal';
import { UppyUploadModal } from 'components/UppyUploadModal/UppyUploadModal';
import { Hint } from 'components/shared/Hint';
import { setSummary } from 'slices/player.slice';
import { setMediaTitle } from 'slices/media.slice';
import { RootState } from 'reducers';
import { useLatest, useSearchParam } from 'react-use';
import { GlobalNotifications } from 'components/Notification/GlobalNotification';
import { hoverDropShadow, navbarTabCss } from 'components/shared/twin.styles';
import {
  insertSocketMessage,
  updateNotifications,
} from 'slices/notification.slice';
import { RawNotification } from 'utils/models/payment.model';
import { customToast } from 'utils/toast.util';
import { hasSubdomain } from 'utils/utils';
import {
  fetchCollections,
  resetSelectedCollectionId,
  setShowCollectionSidebar,
  toggleCollectionSidebar,
} from 'slices/collection.slice';
import { getDateExport } from 'utils/date.util';
import { toggleUploadModal } from 'slices/toggle.slice';
import { Routes } from 'utils/routes';
import { fetchAllTags } from 'slices/tag.slice';
import { useQueryGeneratedTermInsight } from 'hooks/queries/useQueryGeneratedTermInsight';
import { fetchAllUserGroups } from 'slices/admin-portal.slice';
import { userSelector } from 'slices/payment.slice';
import { useQueryUserGroup } from 'hooks/queries/useQueryUserGroup';

export const NavbarMenu = memo(() => {
  // console.count('==> NavbarMenu');

  const history = useHistory();
  const dispatch = useDispatch();

  const { refetch: refetchGeneratedTermInsight } =
    useQueryGeneratedTermInsight();
  useQueryUserGroup();

  const token = useSelector((state: RootState) => state.login.token);
  const media = useSelector((state: RootState) => state.media);
  const tenant = useSelector((state: RootState) => state.tenant);
  const userProfile = useSelector((state: RootState) => state.userProfile);
  const userProfileLatest = useLatest(userProfile);
  const clips = useSelector((state: RootState) => state.clips);
  const global = useSelector((state: RootState) => state.global);
  const uppyStore = useSelector((state: any) => state.uppy?.uppyStore);
  const versions = useSelector((state: RootState) => state.getPublishedVersion);
  const timeZone = useSelector(
    (state: RootState) => state.userProfile.location,
  );
  const modalOpen = useSelector(
    (state: RootState) => state.toggle.isShowUploadModal,
  );

  const collection = useSelector((state: RootState) => state.collection);
  const collectionLatest = useLatest(collection);

  const search = useSelector((state: RootState) => state.advancedSearch);
  const searchLatest = useLatest(search);

  const pagination = useSelector((state: RootState) => state.pagination);
  const pagiLatest = useLatest(pagination);

  const userSlice = useSelector(userSelector);

  const versionsRef = useLatest(versions);

  const routerState: any = useLocation().state;
  const uploadModalRef = useRef<any>(null);

  const [isRenaming, setIsRenaming] = useState(false);

  const [displayTitle, setDisplayTitle] = useState('');
  const [collectionTitle, setCollectionTitle] = useState('');

  const thumbnailModalRef = useRef<any>(null);

  useEffect(() => {
    const thumbnailParams = global.showThumbnailModal;
    if (!isEmpty(thumbnailParams)) {
      thumbnailModalRef.current?.show(thumbnailParams);
    }
  }, [global.showThumbnailModal]);

  useEffect(() => {
    if (!routerState?.filename) return;

    setDisplayTitle(routerState?.filename);
  }, [routerState?.filename]);

  useEffect(() => {
    handleUppyModal();
  }, [modalOpen]);

  const handleUppyModal = async () => {
    if (modalOpen === true) {
      await uploadModalRef.current?.show();
      setModalOpen(false);
    }
  };

  useEffect(() => {
    if (isEmpty(media)) return;

    const title =
      media?.metadata?.title && media.metadata.title !== 'NULL'
        ? media.metadata.title
        : removeExtension(media?.metadata?.filename);
    setDisplayTitle(title);
  }, [media]);

  useEffect(() => {
    $(document).foundation();

    if (hasSubdomain()) {
      dispatch(fetchCollections());
      dispatch(fetchAllTags());
      dispatch(fetchAllUserGroups());
      dispatch({ type: 'GET_PROFILES' });
    }
  }, []);

  const setModalOpen = (isOpen: boolean) => {
    dispatch(toggleUploadModal(isOpen));
  };

  const getInitialTitle = () => {
    const title =
      media?.metadata?.title && media.metadata.title !== 'NULL'
        ? media.metadata.title
        : removeExtension(media?.metadata?.filename);
    return routerState?.filename ?? title;
  };

  useEffect(() => {
    if (isEmpty(clips)) return;

    setCollectionTitle(clips.title);
  }, [clips?.title]);

  const getCollectionTitle = () => {
    return collectionTitle || '';
  };

  const getTitleShared = () => {
    return media?.versionname ?? getInitialTitle() ?? '';
  };

  const isPublic = (path: string) => {
    if (
      path === '/' ||
      path.match('/signin') ||
      path === '/signup' ||
      path === '/message' ||
      path === '/confirmpassword' ||
      path === '/404' ||
      isMaintenanceMode()
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleClickIcon = (e: any) => {
    e.preventDefault();
    if (isPublicShared() || isMaintenanceMode()) {
      window.open('https://www.sonnant.com', '_blank');
    } else if (
      window.location.pathname === '/message' ||
      window.location.pathname === '/signup'
    ) {
      history.push('/signup');
    } else {
      history.push('/library');
    }
  };

  const handleRenameTitle = () => {
    debouncedSingleClick.cancel();
    setIsRenaming(true);
  };

  const resetTitle = () => {
    if (isTranscriptionPage()) {
      setDisplayTitle(getInitialTitle());
    } else if (isDisplayClips()) {
      setCollectionTitle(clips?.title ?? '');
    }
  };

  const handleFinishRename = (e: any) => {
    if (e.key === Keys.ESC) {
      setIsRenaming(false);
      resetTitle();
      return;
    }
    if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
      submitRenaming();
    }
  };

  const submitRenaming = () => {
    setIsRenaming(false);

    let oldTitle = '';
    let newTitle = '';

    if (isTranscriptionPage()) {
      oldTitle = getStringDynamo(media?.metadata?.title);
      newTitle = displayTitle?.trim() ?? '';
    } else if (isDisplayClips()) {
      oldTitle = getStringDynamo(versions.data?.title);
      newTitle = collectionTitle?.trim() ?? '';
    }

    if (isEmpty(newTitle)) {
      customToast.error('Invalid Title.');
      setIsRenaming(false);
      resetTitle();
      return;
    }

    // Title NOT changed, DO NOTHING
    if (isEqual(oldTitle, newTitle)) return;

    const payload = {
      mediaid: isTranscriptionPage() ? media.metadata.mediaid : clips.mediaid,
      title: newTitle,
      tenantid: getTenantidFromIdToken(),
    };

    dispatch({
      type: types.UPDATE_TITLE_SAGA,
      payload,
    });

    if (isTranscriptionPage()) {
      dispatch(setMediaTitle(newTitle));
    }
  };

  let currentPath = history.location.pathname.toLowerCase();

  const getTenantPicture = (image: string) => {
    if (!image || image === 'NULL') return <HomeIcon />;

    return (
      <img
        alt=""
        tw="height[5rem] width[5rem] min-width[5rem] rounded-full shadow-lg object-contain"
        src={formateImageURL(image)}
        key={image}
      />
    );
  };

  const getUserPicture = (image: string) => {
    if (!image || image === 'NULL') return <HomeIcon />;

    const localPictureURL = getLocalProfilePic();
    let imageURL = formateImageURL(image) || localPictureURL;

    return (
      <img
        src={imageURL}
        alt=""
        tw="height[4.5rem] width[4.5rem] min-width[4.5rem] rounded-full shadow-lg! object-contain cursor-pointer"
        key={image}
      />
    );
  };

  const embedded = useSearchParam('embed');
  const isEmbedded = (): boolean => {
    if (!embedded) return false;

    return isEqual(embedded, 'true');
  };

  const handleLogout = () => {
    clearSession();
    dispatch(actions.setToken(null, null));
    history.push('/');
  };

  const TopLeftNav = () => {
    if (currentPath === '/') {
      return (
        <li>
          <Link
            to={Routes.SIGNIN}
            type="button"
            className="button btn-primary SignIn button large"
          >
            Sign in
          </Link>
        </li>
      );
    }
    if (
      (token && !isPublic(currentPath) && currentPath !== '/changepassword') ||
      currentPath.match('/shared')
    ) {
      return (
        <>
          <IdleTimerContainer />

          <div tw="mr-4">
            <PercentProgress
              percent={uppyStore.totalProgress}
              setOpenUpload={setModalOpen}
            />
          </div>

          <div className="right-menu-container" tw="flex items-center">
            <>
              {currentPath.match('/shared') ? (
                !isEmbedded() &&
                media.metadata?.isSubscriber === false && (
                  <Hint text="Sign Up">
                    <button
                      onClick={() => history.push('/signup')}
                      type="button"
                      className="button btn-primary large btn-signup"
                    >
                      {/* <Upload /> */}
                      <span>Sign up</span>
                    </button>
                  </Hint>
                )
              ) : (
                <Hint text="Upload new content item">
                  <button
                    onClick={() => setModalOpen(true)}
                    type="button"
                    className="button btn-primary large upload primary-btn"
                    tw="sm-down:(width[4rem] min-width[auto] p-0)!"
                    css={uploadCss}
                  >
                    <Upload />
                    <span className="uploadTitle" tw="sm-down:hidden">
                      {' '}
                      Upload
                    </span>
                  </button>
                </Hint>
              )}
            </>
            {!isPublicShared() && (
              <>
                <Hint text="Notifications">
                  <GlobalNotifications />
                </Hint>
                <ul className="dropdown menu" data-dropdown-menu>
                  <li>
                    {getUserPicture(userProfile?.image)}

                    <ul className="menu accountDropDown">
                      <li>
                        <Link to={Routes.SETTINGS}>Account settings</Link>
                      </li>
                      {userSlice.isAdministrator && (
                        <li>
                          <Link to={Routes.ADMIN_PORTAL}>Admin portal</Link>
                        </li>
                      )}
                      <li>
                        <Link to={Routes.PREFERENCES}>Preferences</Link>
                      </li>
                      <li>
                        <Link to={Routes.REPORTING}>Reporting</Link>
                      </li>
                      <li>
                        <a
                          href="https://www.sonnant.com/faq"
                          target="_blank"
                          rel="noreferrer"
                        >
                          FAQ
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://share.hsforms.com/1nmpq9BJkQCmJr4u7dnxiVg4db8k"
                        >
                          Support
                        </a>
                      </li>
                      <li>
                        <a onClick={handleLogout}>Sign out</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </>
            )}
          </div>
        </>
      );
    }
  };

  // WEBSOCKET V2
  const clientRef = useRef<any>(null);
  const [waitingToReconnect, setWaitingToReconnect] = useState<any>(null);

  const b64DecodeUnicode = (str: string) => {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
  };
  useEffect(() => {
    const token = getIdToken();
    if (!token || !isLoggedIn()) {
      return;
    }

    if (waitingToReconnect) {
      return;
    }

    if (!clientRef.current) {
      const client = new WebSocket(`${config.WEBSOCKET_URL}/?token=${token}`);
      clientRef.current = client;
      (window as any).client = client;

      client.onerror = (e) => console.error(e);

      client.onopen = () => {};

      client.onclose = () => {
        if (clientRef.current) {
          // console.log('ws closed by server');
        } else {
          // console.log('ws closed by app component unmount');
          return;
        }

        if (waitingToReconnect) {
          return;
        }
        setWaitingToReconnect(true);
        setTimeout(() => setWaitingToReconnect(null), 5000);
      };

      client.onmessage = (message) => {
        const response = JSON.parse(message.data);
        const actionType = atob(response.actionType);

        if (isDevMode()) {
          console.log('FROM SOCKET: ', {
            actionType,
            payload: JSON.parse(atob(response.payload)),
          });
        }

        if (actionType === 'media/status') {
          const payload = JSON.parse(b64DecodeUnicode(response.payload));
          const currentMediaid = getCurrentMediaid();

          const latestCollection = collectionLatest?.current;

          if (
            latestCollection?.showSidebar &&
            latestCollection?.selectedId &&
            latestCollection?.uploadSelectedId !== null &&
            currentMediaid !== latestCollection?.uploadSelectedId
          ) {
            return;
          }

          // Not allow to insert item on clips mode or not first page
          if (
            searchLatest.current.isShowClipsOnly ||
            pagiLatest.current.page_number > 1
          ) {
            return;
          }

          dispatch({
            type: types.TRIGGER_MEDIA_ID,
            payload,
          });

          if (
            [VideoStatusCode.TRANSCRIBING].includes(
              toNumber(payload?.statuscode),
            ) &&
            payload?.voicemediaid !== 'NULL' &&
            !['/preferences', '/account-settings', '/reporting'].includes(
              window.location.pathname,
            )
          ) {
            dispatch({ type: 'GET_PROFILES' });
          }

          if (payload.isUpdateSummary && isTranscriptionPage()) {
            const currentMediaid = getCurrentMediaid();

            if (currentMediaid === payload?.mediaid) {
              console.log('OVERRIDE SUMMARY');
              dispatch(
                setSummary({
                  mediaid: payload.mediaid,
                  summary: decodeURIComponent(payload.summary),
                  fromSocket: true,
                }),
              );
            }
          }

          dispatch(
            updateNotifications({
              mediaid: payload.mediaid,
              title: payload.title,
            }),
          );
        } else if (actionType === 'media/publish_status') {
          const socketMediaid = JSON.parse(atob(response.payload)).mediaid;
          const reduxMediaid = versionsRef.current.data?.mediaid;

          if (socketMediaid !== reduxMediaid) return;

          dispatch({
            type: types.INIT_PUBLISHED_VERSION_LIBRARY_WATCH,
            mediaid: socketMediaid,
          });
        } else if (isNotifySocketMessage(actionType)) {
          const payload: RawNotification = JSON.parse(
            b64DecodeUnicode(response.payload),
          );
          dispatch(insertSocketMessage({ payload, timeZone }));

          if (payload?.type === NotificationType.MENTION_DOWNLOAD) {
            // Callback for click action

            const report = payload?.data;

            const reportName = `${report?.report_name}`;
            // const reportName = `${report?.report_name}`;

            const downloadUrl = report?.is_combined
              ? report?.combined_url
              : report?.report_url;

            customToast.downloadable({
              reportName: reportName,
              toastType: ToastNotiEnum.MULTI_MENTION,
              clickCallbackFn: () => {
                if (downloadUrl) {
                  window.open(downloadUrl, '_self');
                } else {
                  customToast.error(
                    report?.is_combined
                      ? 'No mention occurrences found. Please check and try again.'
                      : 'Report failed to generate. Please check and try again.',
                  );
                }
              },
            });
          } else if (payload?.type === NotificationType.EXPORT_DOWNLOAD) {
            const report = payload?.data;

            const downloadUrls: string[] = [
              report?.fileUrl,
              ...(report?.mediaUrl ?? []),
            ].filter(Boolean);

            if (!isEmpty(downloadUrls)) {
              customToast.downloadable({
                reportName: `[${getSubdomainFromURL(window.location.href)}] ${
                  isLibraryPage() ? 'Library' : 'Clip'
                } Export ${getDateExport(
                  userProfileLatest?.current?.location,
                )}`,
                toastType: ToastNotiEnum.MULTI_EXPORT,
                clickCallbackFn: () => {
                  downloadMultipleFiles({
                    urls: downloadUrls,
                    shouldCheckExpired: false,
                  });
                },
              });
            } else {
              customToast.error('Something went wrong.');
            }
          } else if (actionType === NotificationType.INTEGRATE_OMNY_ERROR) {
            customToast.error(
              'Reprocessing of collection has failed. Please check and try again. If error persists, contact Sonnant Support.',
            );
          } else if (
            actionType === NotificationType.GENERATED_TERM_PROGRESSING
          ) {
            refetchGeneratedTermInsight();
          } else if (actionType === NotificationType.GENERATED_TERM_COMPLETED) {
            customToast.showGeneratedTermComplete({
              clickCallbackFn: () => {
                history.push(Routes.INSIGHTS);
              },
            });

            refetchGeneratedTermInsight();
          }
        }
      };

      return () => {
        clientRef.current = null;
        client.close();
      };
    }
  }, [waitingToReconnect, isLoggedIn()]);

  const handleClipTitleClick = () => {
    history.push(`/transcription/${clips?.mediaid}`);
  };

  const debouncedSingleClick = debounce(handleClipTitleClick, 300);

  return (
    <>
      <div
        className={`top-bar ${
          (isPublic(currentPath) && currentPath !== '/') ||
          currentPath === '/changepassword' ||
          isMaintenanceMode()
            ? ''
            : 'white'
        }`}
        id="responsive-menu"
        css={[
          window.location.pathname.startsWith('/library')
            ? tw`z-index[2]`
            : tw`z-index[1]`,
          isEmbedPage() && tw`hidden`,
          IS_USE_SONNANT_V2 && isAuthPage() && tw`md-up:hidden`,
        ]}
        tw="flex-nowrap sm-down:(padding[4px])!"
      >
        <div className="" tw="flex-1 h-full">
          <div
            className="top-bar-left"
            tw="flex justify-between h-full! box-border!"
          >
            <ul className="menu margin-left-2" tw="flex-nowrap flex-1">
              <li
                className="menu-text"
                tw="md-down:mr-8! lg:mr-8! sm-down:(flex)!"
              >
                <Hint
                  text={
                    currentPath.startsWith('/shared')
                      ? 'Sonnant home page'
                      : 'Return to library'
                  }
                >
                  <Link to="#" onClick={handleClickIcon}>
                    {(isPublic(currentPath) && currentPath !== '/') ||
                    currentPath === Routes.CHANGE_PASS ||
                    isPublicShared() ? (
                      <>
                        {media.metadata?.isSubscriber &&
                        media.metadata?.tenantLogo ? (
                          <div tw="mr-3">
                            {getTenantPicture(media.metadata.tenantLogo)}
                          </div>
                        ) : !isAuthPage() ? (
                          <SonnantLogo />
                        ) : (
                          <img
                            tw="max-height[7rem] sm-down:(min-width[14rem])"
                            src={SonnantLogoPink}
                            alt="Sonnant Logo"
                          />
                        )}
                      </>
                    ) : (
                      <div>{getTenantPicture(tenant?.image)}</div>
                    )}
                  </Link>
                </Hint>
              </li>
              {token && !isPublic(currentPath) && hasNavigator() && (
                <>
                  <li
                    tw="flex items-center sm-down:hidden"
                    css={[navbarTabCss]}
                  >
                    {hasNavArrow() && (
                      <ArrowBack
                        className="navigateBack mb-0"
                        tw="align-bottom cursor-pointer mr-3!"
                        css={[hoverDropShadow(0.2)]}
                        onClick={history.goBack}
                      />
                    )}
                    <Hint
                      text={
                        isLibraryPage()
                          ? 'Content library view'
                          : 'Return to library'
                      }
                    >
                      <NavLink
                        to={Routes.LIBRARY}
                        className="active"
                        activeClassName="underline"
                        onClick={() => {
                          const path = window.location.pathname;

                          if (path !== Routes.LIBRARY) return;

                          // Return back library on Collection View
                          if (collection.showSidebar && collection.selectedId) {
                            dispatch(toggleCollectionSidebar());
                            dispatch(resetSelectedCollectionId());

                            dispatch(actions.initLibrary());
                            dispatch(actions.setSelectMode(false));
                          }
                        }}
                      >
                        Library
                      </NavLink>
                    </Hint>
                  </li>
                  <li tw="sm-down:hidden" css={[navbarTabCss]}>
                    <Hint text="View currently trending items from your library">
                      <NavLink
                        to={Routes.REVIVE}
                        activeClassName="underline"
                        className="active"
                        onClick={() => {
                          dispatch(setShowCollectionSidebar(false));
                        }}
                      >
                        Revive
                      </NavLink>
                    </Hint>
                  </li>
                  <li tw="sm-down:hidden" css={[navbarTabCss]}>
                    <Hint text="View information about the contents within your library">
                      <NavLink
                        to={Routes.INSIGHTS}
                        activeClassName="underline"
                        className="active"
                      >
                        Insights
                      </NavLink>
                    </Hint>
                  </li>
                </>
              )}
              {token && !isPublic(currentPath) && isDisplayClips() && (
                <li className="margin-3 ct_width" tw="flex items-center">
                  <ArrowBack
                    className="navigateBack mb-0"
                    tw="align-bottom cursor-pointer mr-3!"
                    css={[hoverDropShadow(0.2)]}
                    onClick={history.goBack}
                  />
                  <Hint text="Return to library">
                    <NavLink to={Routes.LIBRARY} className="active">
                      Library
                    </NavLink>
                  </Hint>
                  <div
                    tw="flex!"
                    css={
                      getCollectionTitle() || isRenaming
                        ? tw`flex!`
                        : tw`hidden!`
                    }
                  >
                    <div tw="mx-1.5">/</div>
                    <Hint text="Double click to rename the content item">
                      <div
                        tw="flex-1 font-medium break-all cursor-pointer hover:underline"
                        className="line-clamp-1"
                        onBlur={submitRenaming}
                      >
                        {!isRenaming ? (
                          <span
                            onClick={debouncedSingleClick}
                            onDoubleClick={handleRenameTitle}
                          >
                            {getCollectionTitle()}
                          </span>
                        ) : (
                          <input
                            value={getCollectionTitle()}
                            onChange={(e) => setCollectionTitle(e.target.value)}
                            onKeyDown={handleFinishRename}
                            tw="w-full"
                            maxLength={128}
                            className="input-rename"
                            autoFocus={true}
                          />
                        )}
                      </div>
                    </Hint>
                    <div tw="mx-1.5">/</div>
                    <div>Clips</div>
                  </div>
                </li>
              )}
              {token &&
                !isPublic(currentPath) &&
                currentPath.startsWith('/transcription') && (
                  <li
                    className="margin-3 ct_width"
                    tw="flex items-center flex-1 mr-0!"
                  >
                    <Hint text="Return to library">
                      <NavLink to={Routes.LIBRARY} className="active">
                        Library
                      </NavLink>
                    </Hint>
                    <div
                      tw="flex!"
                      css={getInitialTitle() ? tw`flex!` : tw`hidden!`}
                    >
                      <div tw="mx-1.5">/</div>
                      <Hint text="Double click to rename the content item">
                        <div
                          tw="font-medium break-all"
                          className="line-clamp-1"
                          onDoubleClick={handleRenameTitle}
                          onBlur={submitRenaming}
                        >
                          {!isRenaming ? (
                            displayTitle ?? getInitialTitle() ?? ''
                          ) : (
                            <input
                              value={displayTitle}
                              onChange={(e) => setDisplayTitle(e.target.value)}
                              onKeyDown={handleFinishRename}
                              tw="w-full"
                              maxLength={128}
                              className="input-rename"
                              autoFocus={true}
                            />
                          )}
                        </div>
                      </Hint>
                      <div tw="mx-1.5">/</div>
                      <div>Transcript</div>
                    </div>
                  </li>
                )}
              {currentPath.match('/shared') && (
                <>
                  <li
                    className="margin-3 ct_width sonnant-logo"
                    tw="pt-1! flex items-center"
                  >
                    <a href="https://www.sonnant.com" target="_blank">
                      <SonnantLogoText />
                    </a>
                    <div
                      tw="ml-5 mt-1 font-medium color[#201F66] xs:hidden sm:hidden"
                      title={getTitleShared()}
                    >
                      {getTitleShared()}
                    </div>
                  </li>
                </>
              )}
            </ul>
            <div tw="flex items-center">
              <SubscriptionNavbar />
            </div>
          </div>
        </div>
        <div className="">
          <ul tw="flex-nowrap items-start!" className="menu SignInSignUp">
            {TopLeftNav()}
            {/* <TopLeftNav /> */}
          </ul>
        </div>
      </div>

      {/* ONLY SHOW UPLOAD MODAL ON UPLOADING FOR PERFORMANCE */}
      {(modalOpen || !isEmpty(uppyStore?.files)) && (
        <UppyUploadModal ref={uploadModalRef} />
      )}

      {global.showThumbnailModal && <ThumbnailModal ref={thumbnailModalRef} />}

      {isLibraryPage() && !isDisplayClips() && (
        <div tw="fixed bottom[5%] right[4%] z-index[3] shadow-2xl rounded-full">
          <Hint text="Upload video / audio files">
            <Fab tw="bg-sonnant-purple-2" onClick={() => setModalOpen(true)}>
              <AddSvg />
            </Fab>
          </Hint>
        </div>
      )}
    </>
  );
});

const uploadCss = css`
  svg {
    ${tw`sm-down:(m-0)!`}
  }
`;
