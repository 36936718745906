/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { Row } from '@tanstack/react-table';
import { ReactComponent as ChevronSvg } from 'assets/Icons/chervon.svg';
import { ReactComponent as CopySvg } from 'assets/Icons/copy_url.svg';
import { ReactComponent as MentionSvg } from 'assets/Icons/mention.svg';
import { Hint } from 'components/shared/Hint';
import {
  lineCss,
  minimizedRowCss,
  roundedRippleHoverCss,
} from 'components/shared/twin.styles';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCopyToClipboard, useToggle } from 'react-use';
import { RootState } from 'reducers';
import { MentionLayerData } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { msToTime, scrollToNearestNode } from '../../MediaUtilities';
import { PrettyCheckbox } from 'components/shared/PrettyCheckbox';
import { css } from '@emotion/react';

interface IProps {
  itemRow: Row<MentionLayerData>;
  selectedRowIds: string[];
  handleSelectRow: (id: string) => void;
}

export const MentionReportRow = memo(function AdMarkerKMemorized({
  itemRow,
  selectedRowIds,
  handleSelectRow,
}: IProps) {
  const toggle = useSelector((state: RootState) => state.toggle);

  const [isMinimized, toggleMinimized] = useToggle(true);
  const [isHovering, setIsHovering] = useState(false);

  const [, copyToClipboard] = useCopyToClipboard();

  const mentionReportItem = itemRow.original;

  const cells = itemRow.getVisibleCells();

  const isSelected = selectedRowIds.includes(mentionReportItem.id);

  const handleCopyLink = () => {
    copyToClipboard(mentionReportItem.url);
    customToast.success('Copied to clipboard');
  };

  const handleClickRow = (isClickIcon?: boolean) => {
    if (!isClickIcon && toggle.isEditMode) return;

    scrollToNearestNode(mentionReportItem.startTime);
    window.Video.currentTime = mentionReportItem.startTime;
  };

  return (
    <tr
      css={[lineCss, minimizedRowCss(isMinimized)]}
      onClick={() => handleClickRow()}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {/* HIDE CHECKBOX */}
      <td
        key={cells[0]?.id}
        tw="min-w-[3rem] max-w-[4rem] relative"
        style={{ width: cells[0]?.column?.getSize(), height: '120px' }}
      >
        {toggle.isEditMode && (isHovering || isSelected) && (
          <PrettyCheckbox
            checkboxCss={css`
              top: calc(50% - 0.9rem) !important;
            `}
            checked={isSelected}
            onChange={() => handleSelectRow(mentionReportItem.id)}
          />
        )}
      </td>
      <td
        key={cells[1]?.id}
        style={{ width: cells[1]?.column?.getSize() }}
        onClick={() => handleClickRow(true)}
      >
        <span tw="flex items-center w-full p-2" css={[roundedRippleHoverCss]}>
          <MentionSvg tw="fill[#5551FF]" width={24} height={24} />
        </span>
      </td>
      <td
        key={cells[2]?.id}
        style={{ width: cells[2]?.column?.getSize(), textAlign: 'center' }}
        className="bottom"
        tabIndex={1}
        tw="min-width[52px]"
      >
        <div>{mentionReportItem.index}</div>
      </td>
      <td
        key={cells[3]?.id}
        style={{ minWidth: cells[3]?.column?.getSize(), textAlign: 'center' }}
        className="bottom"
        tabIndex={1}
      >
        {msToTime(mentionReportItem.startTime * 1000)}
      </td>
      <td
        key={cells[4]?.id}
        style={{ minWidth: cells[4]?.column?.getSize(), textAlign: 'center' }}
      >
        {msToTime(mentionReportItem.endTime * 1000)}
      </td>
      <td
        style={{ minWidth: cells[5]?.column?.getSize(), textAlign: 'center' }}
        key={cells[5]?.id}
      >
        {msToTime(mentionReportItem.duration * 1000)}
      </td>
      <td
        key={cells[6]?.id}
        style={{ minWidth: cells[6]?.column?.getSize(), textAlign: 'center' }}
        tabIndex={1}
      >
        {mentionReportItem.totalMentions}
      </td>
      <td
        key={cells[7]?.id}
        style={{ minWidth: cells[7]?.column?.getSize(), textAlign: 'center' }}
        tabIndex={1}
      >
        {mentionReportItem.term}
      </td>
      <td
        key={cells[8]?.id}
        style={{ width: cells[8]?.column?.getSize(), textAlign: 'center' }}
        tabIndex={1}
      >
        <div className="long-text" tw="px-4">
          {mentionReportItem.iab}
        </div>
      </td>
      <td
        key={cells[9]?.id}
        style={{ width: cells[9]?.column?.getSize() }}
        tabIndex={1}
      >
        <div className="long-text" tw="px-4">
          {mentionReportItem.transcript}
        </div>
      </td>
      <td
        key={cells[10]?.id}
        style={{ width: cells[10]?.column?.getSize(), textAlign: 'center' }}
        tw="min-width[80px] cursor-pointer"
        tabIndex={1}
        className="bottom"
      >
        <Hint text="Copy item URL" enterDelay={100} leaveDelay={0}>
          <div key={cells[10]?.id} css={[roundedRippleHoverCss]}>
            <CopySvg onClick={handleCopyLink} width={20} height={20} />
          </div>
        </Hint>

        <Hint
          text={isMinimized ? 'Show more' : 'Show less'}
          enterDelay={100}
          leaveDelay={0}
        >
          <div
            css={[roundedRippleHoverCss]}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              toggleMinimized();
            }}
          >
            <ChevronSvg
              tw="transition[all 0.3s ease-in-out]"
              css={[!isMinimized && tw`transform[rotate(180deg)]`]}
              width={16}
              height={16}
            />
          </div>
        </Hint>
      </td>
    </tr>
  );
});
