/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Info } from 'components/shared/Info';
import { isArray, isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { SpecialSegment } from 'utils/models/transcript.model';
import { scrollToNearestNode } from '../../MediaUtilities';
import { MediaSegmentLayerItem } from './MediaSegmentLayerItem';

type Props = {};

export const MediaSegmentLayer = (props: Props) => {
  const handleClickSegment = (clickedSegment: SpecialSegment) => {
    const segmentStart = clickedSegment?.start;

    if (window?.Video && !isNil(segmentStart)) {
      window.Video.currentTime = segmentStart;
      scrollToNearestNode(segmentStart);
    }
  };

  const mediaSegment = useSelector((state: RootState) => state.mediaSegment);

  return (
    <div tw="mt-6 pb-4 height[100vh - 62rem] overflow-y-auto">
      <div>
        {mediaSegment.items?.map((segment) => (
          <div key={segment.id}>
            <MediaSegmentLayerItem
              item={segment}
              onClickSegment={handleClickSegment}
            />
          </div>
        ))}

        {isArray(mediaSegment.items) && mediaSegment.items?.length === 0 && (
          <Info text="There are no special segment(s) detected for this content item." />
        )}
      </div>
    </div>
  );
};
