/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { css } from '@emotion/react';
import tw from 'twin.macro';
import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as Lock } from '../../assets/Icons/Lock.svg';
import { ReactComponent as TopicSvg } from 'assets/Icons/topic.svg';
import { ReactComponent as SonnantLogoSvg } from 'assets/Icons/logo-sonnant-white.svg';
import { ThreeDotMenu } from 'components/shared/ThreeDotMenu/ThreeDotMenu';
import { ILibraryItem, IClips } from 'utils/models';
import { isEqual, toNumber } from 'lodash';
import {
  getMediaidFromClip,
  getStatusItem,
  isFromSuggested,
  isTopicClip,
  msToTime,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { ClipStatusCode, Keys } from 'utils/enum';
import { RootState } from 'reducers';
import { useDispatch, useSelector } from 'react-redux';
import {
  putRenamePublishTitle,
  setFocusClip,
  setSelectedClips,
} from 'slices/clips.slice';
import { useToggle, useClickAway, useVideo } from 'react-use';
import { Notification } from '../UiControls/notification/Notification';
import { toDateWithHours } from 'utils/date.util';
import { Hint } from 'components/shared/Hint';
import { AUDIO_THUMBNAIL, bgTrending } from 'components/shared/twin.styles';
import { PrettyCheckbox } from 'components/shared/PrettyCheckbox';

interface Props {
  item: IClips;
  handleRedirectClick: (item: any) => void;
  handleShare?: () => void;
  handleExport?: () => void;
  handlePublish?: () => void;
  handleShowPreviewPublish?: (startTime: number, endTime: number) => unknown;
  handleShowPreview?: any;
  libraryItem?: ILibraryItem;
  isOriginal?: boolean;
  isSuggested?: boolean;
  handleDeleteVersionMedias?: (singleItem: IClips) => void;
  handleIntegrate?: () => void;
  isTrending?: boolean;
  fromSearchClips?: boolean;
}

export const ThumbnailClipsItem = ({
  item,
  libraryItem,
  handleRedirectClick,
  handleShare,
  handleExport,
  handlePublish,
  handleShowPreviewPublish,
  handleShowPreview,
  handleDeleteVersionMedias,
  isOriginal,
  isSuggested,
  handleIntegrate,
  ...props
}: Props) => {
  const clips = useSelector((state: RootState) => state.clips);

  const [openMenu, setOpenMenu] = useState(false);
  const [newTitle, setNewTitle] = useState(item?.versionname);
  const [error, setError] = useState({
    isInvalidTitle: false,
  });
  const ref = useRef(null);

  const [isRenaming, toggleRenaming] = useToggle(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!videoThumbRef.current) return;

    if (isHovering) {
      videoThumbRef.current.src = item?.video_thumbnail ?? '';
      controls.play();
    } else {
      videoThumbRef.current.src = '';
      setIsVideoReady(false);
    }
  }, [isHovering]);

  const [videoThumb, , controls, videoThumbRef] = useVideo(
    <video
      src={undefined}
      tw="absolute top-0 h-full w-full object-contain"
      autoPlay={true}
      controls={false}
      muted={true}
      preload="metadata"
      controlsList="nodownload"
      loop={true}
      onContextMenu={(e) => e.preventDefault()}
    />,
  );

  const clickThreeDot = () => {
    dispatch(setFocusClip(item));
    setOpenMenu(!openMenu);
  };

  const isCreating = () => isEqual(item?.statuscode, ClipStatusCode.CREATING);
  const isCreated = () => isEqual(item?.statuscode, ClipStatusCode.CREATED);
  const isSelected = () =>
    clips.selectedClips?.some(
      (clip) =>
        isEqual(clip.versioncount, item.versioncount) &&
        isEqual(getMediaidFromClip(clip), getMediaidFromClip(item)),
    );

  const handleItemClicked = () => {
    let cloneSelected = [...clips.selectedClips];

    if (isSelected()) {
      cloneSelected = cloneSelected.filter(
        (s) =>
          !(
            isEqual(s.versioncount, item.versioncount) &&
            isEqual(getMediaidFromClip(s), getMediaidFromClip(item))
          ),
      );
    } else {
      cloneSelected.push(item);
    }

    dispatch(setFocusClip(cloneSelected?.[0]));
    dispatch(setSelectedClips(cloneSelected));
  };

  const handleCancelEditing = () => {
    toggleRenaming(false);
  };

  const handleKeyDownTitle = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === Keys.ESC) {
      handleCancelEditing();
      setError({ isInvalidTitle: false });
      const oldFileName = item?.versionname;
      setNewTitle(oldFileName);
    } else if (event.key === Keys.ENTER || event.key === Keys.ENTER_NUMPAD) {
      triggerSaveTitle();
    }
  };

  const triggerSaveTitle = () => {
    if (!newTitle) {
      setError({ isInvalidTitle: true });
      return;
    }
    toggleRenaming(false);

    if (isEqual(item.versionname, newTitle)) return;

    dispatch(
      putRenamePublishTitle({
        mediaid: getMediaidFromClip(item) ?? clips.mediaid,
        versioncount: item.versioncount,
        newTitle,
      }),
    );
  };

  const handleDeleteSingleItem = () => {
    handleDeleteVersionMedias?.(item);
  };

  useEffect(() => {
    if (isRenaming === false && !error.isInvalidTitle) {
      setNewTitle(item?.versionname ?? '');
    }
  }, [item?.versionname]);

  const handleCloseNotification = () => {
    setError({ isInvalidTitle: false });
    toggleRenaming(false);
    if (!newTitle) {
      const oldFileName = item?.versionname;
      setNewTitle(oldFileName);
      return;
    }
  };

  useClickAway(ref, () => {
    if (error.isInvalidTitle) {
      toggleRenaming(false);
      setError({ isInvalidTitle: false });

      const oldFileName = item?.versionname;
      setNewTitle(oldFileName);
    }
  });

  const hasFailedItem = [ClipStatusCode.CREATION_FAILED].includes(
    String(item?.statuscode) as ClipStatusCode,
  );

  return (
    <div
      key={item.versioncount}
      className="cell grid-padding-x small-12 medium-3 large-3 xlarge-2"
      onMouseLeave={() => setOpenMenu(false)}
    >
      <div
        className="card cell"
        tw="pt-1"
        css={[isOriginal && tw`bg-sonnant-grey-4! padding-top[6rem] -mt-24`]}
      >
        <Hint
          // disabled={hasFailedItem}
          text={
            hasFailedItem
              ? item?.is_topic_clip
                ? 'Theme not found. Please check and try again.'
                : 'Something went wrong. Please check and try again.'
              : 'Click to show preview'
          }
        >
          <div
            onClick={() => {
              if (isCreating()) return;

              if (props?.fromSearchClips) {
                if (clips.isSelectMode) {
                  handleItemClicked();
                  return;
                }
                handleRedirectClick(item);
                return;
              }

              if (!clips.isSelectMode) {
                dispatch(setFocusClip(item));
                handleShowPreview?.(item);
                return;
              }
              handleItemClicked();
            }}
            className={`image-container ${
              !isCreating() && clips.isSelectMode && isSelected() && 'active'
            }`}
            tw="w-full h-auto overflow-hidden padding-top[56.3%] border-radius[.5rem] shadow-md"
            css={[isSelected() && tw`(outline[3px solid #19AFA7])!`]}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            {(!isCreated() || isSuggested || isOriginal) && (
              <div
                className="overlay__status"
                css={[
                  tw`shadow-md`,
                  isSuggested && tw`bg-sonnant-green!`,
                  hasFailedItem && tw`bg-sonnant-red!`,
                  props?.isTrending && bgTrending,
                ]}
              >
                {getStatusItem(item)}
              </div>
            )}

            {(isSuggested || item?.from_suggested || isFromSuggested(item)) &&
              !isTopicClip(item) && (
                <div className="overlay_collection_icon" tw="flex">
                  <SonnantLogoSvg className="svg-shadow" />
                </div>
              )}

            {isTopicClip(item) && (
              <div
                className="overlay_collection_icon"
                tw="(flex bottom[0.5rem] right[0.5rem])!"
              >
                <TopicSvg className="svg-shadow" />
              </div>
            )}

            {isCreating() ? (
              <div className="overlay_lock_icon svg-shadow">
                <Lock />
              </div>
            ) : (
              (isHovering || isSelected()) && (
                <div className="content-overlay">
                  <PrettyCheckbox
                    checked={isSelected()}
                    onChange={handleItemClicked}
                  />
                </div>
              )
            )}

            <img
              src={item?.thumbnail || AUDIO_THUMBNAIL}
              css={[isVideoReady ? tw`invisible` : tw`visible`]}
              className="thumbnailimg"
              tw="absolute top-0 h-full object-contain"
            />
            {videoThumb}
          </div>
        </Hint>
        <div ref={ref} className="card-section customThumbnail">
          <div className="flex-container flex-dir-row-reverse">
            <div className="card-actions float-right align-justify">
              {!clips.isSelectMode && (
                <div className="dropdown show thumbnailDropdown">
                  <ThreeDotMenu
                    openMenu={openMenu}
                    clickThreeDot={clickThreeDot}
                    handleRename={() => {
                      setOpenMenu(false);
                      toggleRenaming(true);
                    }}
                    libraryItem={libraryItem}
                    itemClips={item}
                    handleShare={handleShare}
                    handleExport={handleExport}
                    handlePublish={handlePublish}
                    handleShowPreviewPublish={handleShowPreviewPublish}
                    handleDelete={handleDeleteSingleItem}
                    handleIntegrate={handleIntegrate}
                    fromSearchClips={props?.fromSearchClips}
                  />
                </div>
              )}
            </div>

            {isRenaming ? (
              <div className="title_rename" tw="mt-0">
                <input
                  tw="border[2px solid transparent]!"
                  type="text"
                  name="title"
                  onChange={(e) => setNewTitle(e.target.value)}
                  onKeyDown={handleKeyDownTitle}
                  onBlur={triggerSaveTitle}
                  value={newTitle}
                  placeholder="Title"
                  maxLength={128}
                  autoFocus={true}
                />
                <Notification
                  name={'emailError'}
                  customStyle={{ top: '3.5rem', left: 0 }}
                  close={handleCloseNotification}
                  type={error.isInvalidTitle ? 'error' : null}
                  message={'Invalid Title.'}
                  direction={null}
                />
              </div>
            ) : (
              <div className="title_block">
                <h5
                  css={[isOriginal && tw`text-white!`]}
                  onDoubleClick={() => !isOriginal && toggleRenaming(true)}
                >
                  {newTitle}
                </h5>
              </div>
            )}
          </div>
          <div
            className="time_block"
            css={[
              isOriginal &&
                css`
                  span {
                    ${tw`text-white!`}
                  }
                `,
            ]}
          >
            <span>{`${
              props?.fromSearchClips
                ? item?.createddatetime
                : toDateWithHours(item?.datetime ?? item?.updated_at)
            }`}</span>
            <span>{`${msToTime(toNumber(item?.length))}`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
