/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Export } from 'components/Export/Export';
import { Publish } from 'components/Publish/Publish';
import { Share } from 'components/Share/Share';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { PreviewClipModal } from 'components/shared/PreviewClipModal/PreviewClipModal';
import { centerByFlex } from 'components/shared/twin.styles';
import {
  getLatestTitle,
  getParentLibraryItem,
  isDisplayClips,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { isEmpty, isEqual, orderBy, toNumber } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useSearchParam, useToggle, useUnmount } from 'react-use';
import { RootState } from 'reducers';
import {
  setClipTitle,
  setFocusClip,
  setParentMediaid,
  setSelectedClips,
} from 'slices/clips.slice';
import {
  ClipStatus,
  ModalOptions,
  RegenerateEnum,
  ViewMode,
  ClipStatusCode,
  HTTPStatus,
} from 'utils/enum';
import { IClips, ILibraryItem, IPublishVersion } from 'utils/models';
import * as actionTypes from '../../actions/types';
import { filenameFormatter } from '../../utils/utils';
import Loader from '../loader/loader';
import { Modal } from '../UiControls/modal/modal';
import { ClipListView } from './ClipListView';
import { ClipThumbnailView } from './ClipThumbnailView';
import './displayVersion.scss';
import { OptionBar } from './OptionBar';
import { MediaService } from 'services';
import { customToast } from 'utils/toast.util';
import { IntegrationModal } from 'components/shared/IntegrationModal/IntegrationModal';
import { removeClip } from 'actions';
import CloseSVG from '../UiControls/closeButtonSVG/closeSVG';
import { AxiosError } from 'axios';
import { match } from 'ts-pattern';
import { MediaError } from 'components/shared/MediaError';

interface IProps {
  location: History;
}

export const DisplayVersions = (props: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const urlVersioncount = useSearchParam('versioncount');

  const { mediaid } =
    useParams<{ mediaid: string }>() || props?.location?.state;

  const [data, setData] = useState<IPublishVersion | null>(null);
  const [loading, toggleLoading] = useToggle(false);

  const clips = useSelector((state: RootState) => state.clips);
  const publishedVersion = useSelector(
    (state: RootState) => state.getPublishedVersion,
  );

  const integrationProviders = publishedVersion?.data?.provider
    ? [publishedVersion.data.provider]
    : undefined;

  const previewModalRef = useRef<any>();
  const confirmModalRef = useRef<any>(null);
  const recreateConfirmRef = useRef<any>(null);
  const regenerateRef = useRef<any>(null);
  const integrationModalRef = useRef<any>(null);

  const [parentLibraryItem, setParentLibraryItem] = useState<ILibraryItem>(
    props?.location?.state,
  );
  const [versionData, setVersionData] = useState<IClips[]>([]);
  const [suggestedClips, setSuggestedClips] = useState<IClips[]>([]);
  const [trendingClips, setTrendingClips] = useState<IClips[]>([]);
  const [isSortAsc, setIsSortAsc] = useState(true);
  const [shareViewLoading, setShareViewLoading] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openExportModal, setopenExportModal] = useState(false);
  const [openPublishModal, setopenPublishModal] = useState(false);
  const [notiVersioncount, setNotiVersioncount] = useState<string | undefined>(
    undefined,
  );
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [errorStatus, setErrorStatus] = useState<HTTPStatus>();

  useEffect(() => {
    const versionCount = urlVersioncount?.trim();
    if (!versionCount || !parentLibraryItem || !window.location.search) return;
    const suggestedItem =
      data?.suggestedVersions.find((i) => i?.versioncount === versionCount) ??
      data?.versions.find((i) => i?.versioncount === versionCount);
    if (suggestedItem && !clips.focusClip) {
      const newFocusClip: IClips = {
        datetime: suggestedItem?.datetime,
        length: suggestedItem?.length,
        starttime: suggestedItem?.starttime,
        endtime: suggestedItem?.endtime,
        status: suggestedItem?.status,
        versioncount: suggestedItem?.versioncount,
        thumbnail: suggestedItem?.thumbnail,
        versionname: suggestedItem?.versionname,
        statuscode: suggestedItem?.statuscode,
        hasButtons: true,
      };
      dispatch(setFocusClip(newFocusClip));
    }
    const options = {
      mediaid: mediaid,
      title: suggestedItem?.versionname ?? parentLibraryItem?.title,
      versioncount: versionCount,
      thumbnail: parentLibraryItem?.thumbnail,
    };

    setNotiVersioncount(versionCount);
    showPreviewCLip(options);
    // Remove query part
    history.replace(window.location.pathname);
  }, [urlVersioncount, parentLibraryItem]);

  useUnmount(() => {
    dispatch(setFocusClip(null));
    dispatch(setSelectedClips([]));
    dispatch(setClipTitle(''));
    dispatch({ type: 'RESET_PUBLISHED_VERSION' });
    setNotiVersioncount(undefined);

    setErrorMsg(null);
    setErrorStatus(undefined);
  });
  useEffect(() => {
    if (!isEmpty(versionData)) {
      fetchPublishVersion();
    }
  }, [mediaid]);

  // React to Socket fired
  useEffect(() => {
    if (isEmpty(publishedVersion?.data?.versions)) return;

    setData(publishedVersion.data);
    setVersionData(publishedVersion.data?.versions ?? []);
    setSuggestedClips(publishedVersion.data?.suggestedVersions ?? []);
    setTrendingClips(publishedVersion.data?.trendingVersions ?? []);
  }, [publishedVersion.data]);

  const handleShareModalClosed = () => {
    setOpenShareModal(false);
    dispatch(setFocusClip(null));
    // fetchPublishVersion();
  };

  function handleShareContentItem() {
    setOpenShareModal(true);
  }

  const handleExportModalClosed = () => {
    setopenExportModal(false);
    dispatch(setFocusClip(null));
  };

  const handleExportContentItem = () => {
    setopenExportModal(true);
  };

  function handlePublishContentItem() {
    setopenPublishModal(true);
  }

  const handlepublishmodalclosed = () => {
    dispatch({
      type: actionTypes.CLOSE_PUBLISH_LIBRARY,
    });
    dispatch(setFocusClip(null));
    setopenPublishModal(false);
  };

  const handlePublishSuccess = ({ isRecreate }: { isRecreate: boolean }) => {
    fetchPublishVersion();

    if (isRecreate) {
      recreateConfirmRef.current?.show();
    }
  };

  useEffect(() => {
    fetchPublishVersion();
  }, []);

  const fetchPublishVersion = async () => {
    try {
      toggleLoading(true);
      const response = await MediaService.getPublishVersion({ mediaid });
      const newVersions: IClips[] = response?.data?.versions ?? [];
      dispatch(setClipTitle(response?.data?.title));
      setVersionData(newVersions);
      setData(response.data);
      dispatch({
        type: actionTypes.SET_PUBLISHED_VERSION_LIBRARY,
        data: response,
      });

      if (isEmpty(props?.location?.state)) {
        const parent = getParentLibraryItem(response.data);
        parent && setParentLibraryItem(parent as ILibraryItem);
      }
    } catch (error) {
      const response = (error as AxiosError).response;
      const statusCode = response?.status;

      match(statusCode)
        .with(HTTPStatus.NOT_FOUND, (code) => {
          setErrorMsg(
            'This item is no longer available or deleted. Please check and try again.',
          );
          setErrorStatus(code);
        })
        .with(HTTPStatus.RESTRICTED, (code) => {
          setErrorMsg(response?.data);
          setErrorStatus(code);
        })
        .otherwise(() => {
          const msg = 'Something went wrong';

          customToast.error(msg);
          setErrorMsg(msg);
          setErrorStatus(statusCode);
        });
    } finally {
      toggleLoading(false);
    }
  };

  useEffect(() => {
    setVersionData(data?.versions ?? []);
    setSuggestedClips(data?.suggestedVersions ?? []);
    setTrendingClips(data?.trendingVersions ?? []);

    dispatch(setParentMediaid(data?.mediaid ?? ''));
  }, [loading]);

  const handleSortClick = () => {
    if (!data) return;

    setIsSortAsc(!isSortAsc);

    const sortedVersions = orderBy(
      data.versions,
      (item) => new Date(item.datetime),
      [isSortAsc ? 'asc' : 'desc'],
    );
    setVersionData(sortedVersions);
  };

  const handleDropdown = (e: any) => {};

  const openViewShare = async () => {
    if (clips.selectedClips.length === 1) {
      handleShareContentItem();
    }
  };

  const handleDeleteVersionMedias = async (singleItem: IClips) => {
    const result = await confirmModalRef.current?.show();
    if (result !== ModalOptions.YES) {
      return;
    }
    const clipsToDelete = isEmpty(singleItem)
      ? clips.selectedClips
      : [singleItem];
    const deleteItems = clipsToDelete.map((clip) => ({
      mediaid,
      versioncount: clip.versioncount,
    }));
    const deleteVersionRequest = MediaService.deleteVersion(deleteItems);
    customToast.promise(deleteVersionRequest, {
      loading: 'Deleting clip(s)',
      success: 'Clip(s) deleted successfully',
      error: 'Clip(s) delete failed',
    });
    try {
      await deleteVersionRequest;
      fetchPublishVersion();
      dispatch(removeClip(mediaid));
    } catch (error: any) {
      console.log('HTTP Error :>> ', error);
    }
  };

  const handleShowPreview = async (item: IClips) => {
    if (isEqual(item?.statuscode, ClipStatusCode.CREATION_FAILED)) return;

    const options = {
      // startTime: item?.starttime,
      // endTime: item?.endtime,
      mediaid: clips.mediaid,
      title: item?.versionname ?? getLatestTitle(parentLibraryItem),
      versioncount: item?.versioncount,
      hasButtons: item?.hasButtons,
      thumbnail: item?.thumbnail,
    };
    showPreviewCLip(options);
  };

  const handleShowPreviewPublish = async (
    startTime: number,
    endTime: number,
  ) => {
    if (isEqual(clips.focusClip?.statuscode, ClipStatusCode.CREATION_FAILED))
      return;

    const options = {
      startTime,
      endTime,
      mediaid: clips.mediaid,
      title: clips.focusClip?.versionname,
      versioncount: clips?.focusClip?.versioncount,
      thumbnail: clips.focusClip?.thumbnail,
    };

    showPreviewCLip(options);
  };

  const showPreviewCLip = async (options: any) => {
    const result = await previewModalRef?.current?.show(options);
    if (result.option === ModalOptions.YES) {
      handleOpenModels(result.type);
    } else {
      dispatch(setFocusClip(null));
    }
  };

  const handleOpenModels = (type: string) => {
    switch (type) {
      case 'Share':
        handleShareContentItem();
        break;
      case 'Export':
        handleExportContentItem();
        break;
      case 'Edit':
        handlePublishContentItem();
        break;
      default:
        break;
    }
  };

  const getExistedData = () => {
    if (
      (String(clips.focusClip?.versioncount) === '0' ||
        clips.focusClip?.status === ClipStatus.SHARED) &&
      !clips.isSelectMode
    ) {
      return data;
    } else if (isDisplayClips() && data) {
      const cloneVersion =
        data?.suggestedVersions?.map((item) => ({
          ...item,
          status: ClipStatus.SUGGESTED,
        })) ?? [];
      data?.versions.forEach((item) => {
        if (String(item.versioncount) !== '0') {
          cloneVersion.unshift(item);
        }
      });
      const cloneData: IPublishVersion = {
        ...data,
        versions: cloneVersion,
      };

      return cloneData;
    }
    return data;
  };

  const handleRedirectClick = async (item: IClips) => {
    if (!isEmpty(item?.share_url)) {
      const openShare = window.open(item?.share_url!, 'Share');
      openShare?.focus();
    }
  };

  const handleRegenerateSuggested = async () => {
    const origVersion = data?.versions.find((v) => v.versioncount === '0');
    if (!origVersion) return;

    const regenerateAsync = MediaService.regenarate({
      action: RegenerateEnum.SUGGESTED_CLIP,
      payload: {
        media_id: mediaid,
        subtitle_url: origVersion?.subtitleurl?.split('?')?.[0],
        suggested_clip: data?.suggestedVersions.map((v) => v.versioncount),
      },
    });

    try {
      toast.loading('Please wait...');
      await regenerateAsync;
      fetchPublishVersion();
      toast.dismiss();

      regenerateRef.current?.show();
    } catch (err: any) {
      customToast.error('Something went wrong');
      console.log('err :>> ', err);
    }
  };

  const handleIntegrate = async () => {
    await integrationModalRef.current?.show();
  };

  const handleFallbackError = () => {
    setErrorMsg(null);
    setErrorStatus(undefined);

    history.push('/library');
  };

  if (errorMsg || errorStatus) {
    return (
      <div>
        <p className="topMessage error">
          {errorMsg} <CloseSVG name={'error'} close={handleFallbackError} />
        </p>

        <MediaError
          statusCode={errorStatus}
          hiddenMsg
          onClickReturn={handleFallbackError}
        />
      </div>
    );
  }

  return (
    <>
      <div className="libraryWrapper fluid">
        <div>
          <OptionBar
            data={{
              ...parentLibraryItem,
              noTranscript: publishedVersion.data?.noTranscript,
            }}
            toggleSort={handleSortClick}
            dropdownNameChanged={handleDropdown}
            isSortAsc={isSortAsc}
            openViewShare={openViewShare}
            deleteVersionMedias={handleDeleteVersionMedias}
            handleExportContentItem={handleExportContentItem}
            handlePublishContentItem={handlePublishContentItem}
            handleShowPreviewPublish={handleShowPreviewPublish}
          />
          <div>
            {loading && (
              <div tw="height[calc(100vh - 7rem)]" css={centerByFlex}>
                <Loader />
              </div>
            )}

            {clips.currentView === ViewMode.LIST_VIEW ? (
              <ClipListView
                data={{
                  ...parentLibraryItem,
                  noTranscript: publishedVersion.data?.noTranscript,
                }}
                versionData={versionData}
                suggestedClips={suggestedClips}
                trendingClips={trendingClips}
                handleShareContentItem={handleShareContentItem}
                handleExportContentItem={handleExportContentItem}
                handlePublishContentItem={handlePublishContentItem}
                handleShowPreviewPublish={handleShowPreviewPublish}
                handleShowPreview={handleShowPreview}
                handleDeleteVersionMedias={handleDeleteVersionMedias}
                handleRedirectClick={handleRedirectClick}
                handleIntegrate={handleIntegrate}
              />
            ) : (
              <ClipThumbnailView
                data={{
                  ...parentLibraryItem,
                  noTranscript: publishedVersion.data?.noTranscript,
                }}
                versionData={versionData}
                suggestedClips={suggestedClips}
                trendingClips={trendingClips}
                setShareViewLoading={setShareViewLoading}
                handleShareContentItem={handleShareContentItem}
                handleExportContentItem={handleExportContentItem}
                handlePublishContentItem={handlePublishContentItem}
                handleShowPreviewPublish={handleShowPreviewPublish}
                handleShowPreview={handleShowPreview}
                handleDeleteVersionMedias={handleDeleteVersionMedias}
                handleRedirectClick={handleRedirectClick}
                onRegenerateSuggested={handleRegenerateSuggested}
                handleIntegrate={handleIntegrate}
              />
            )}
          </div>
        </div>
      </div>
      {shareViewLoading && (
        <div className="loader__component">
          <Loader />
        </div>
      )}

      <Modal
        show={openShareModal}
        modalClosed={handleShareModalClosed}
        classes="share-modal-width"
      >
        <Share mediaid={mediaid} focusVersioncount={notiVersioncount} />
      </Modal>

      <Modal show={openExportModal} modalClosed={handleExportModalClosed}>
        <Export
          mediaid={mediaid}
          existedData={getExistedData()}
          filename={
            isDisplayClips()
              ? clips?.focusClip?.versionname
              : filenameFormatter(parentLibraryItem?.filename)
          }
          closeExportModal={handleExportModalClosed}
          handleShowPreview={handleShowPreview}
          // focusVersioncount={notiVersioncount}
        />
      </Modal>
      <Modal
        classes="publish-modal-width"
        show={openPublishModal}
        modalClosed={handlepublishmodalclosed}
      >
        <Publish
          existedData={data}
          mediaid={mediaid}
          filename={
            (isDisplayClips() && clips?.focusClip?.versionname) ||
            filenameFormatter(parentLibraryItem?.title)
          }
          duration={toNumber(parentLibraryItem?.length)}
          startTime={isDisplayClips() ? clips?.focusClip?.starttime : 0}
          endTime={isDisplayClips() ? clips?.focusClip?.endtime : 0}
          onSuccess={handlePublishSuccess}
          closeShareModal={handlepublishmodalclosed}
          isOpen={openPublishModal}
          parentItem={clips.focusClip}
          isDisplayClips
        />
      </Modal>
      <PreviewClipModal ref={previewModalRef} />
      <ConfirmModal
        ref={confirmModalRef}
        title="Delete from clips"
        message={
          <div>
            <div>
              You are about to delete the selected clip(s) from your library.
            </div>
            <div>
              Should you proceed, this item will be permanently deleted and can
              not be recovered.
            </div>
            <div tw="font-medium">Would you like to continue?</div>
          </div>
        }
      />

      <ConfirmModal
        ref={recreateConfirmRef}
        title="Clip created"
        message="The clip is being created using the latest version of the transcript and the other options chosen. If this item was shared, then that shared URL will automatically reference the new version of the clip."
        hideCancel
        confirmText={'OK'}
        type="info"
      />

      <ConfirmModal
        ref={regenerateRef}
        title="Request submitted"
        message="The suggested clips generation has been initiated. This may take 10 minutes or longer before the clips are visible."
        hideCancel
        confirmText={'Close'}
        type="info"
      />

      <IntegrationModal
        providers={integrationProviders}
        mediaids={[clips?.mediaid]}
        ref={integrationModalRef}
      />
    </>
  );
};
