/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import React, { InputHTMLAttributes, useRef } from 'react';

type Props = {
  hasError?: boolean;
  hasWarning?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const TextField = ({
  hasError,
  hasWarning,
  name,
  disabled,
  maxLength,
  onChange,
  onFocus,
  onInput,
  onKeyPress,
  onBlur,
  value,
  type = 'text',
  ...props
}: Props) => {
  const inputRef = useRef<any>(null);

  const handleClickInput = () => {
    inputRef?.current.focus();
  };

  return (
    <div css={customStyle} onClick={handleClickInput}>
      <input
        id={name}
        type={type}
        onChange={onChange}
        onFocus={onFocus}
        onInput={onInput}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        value={value}
        disabled={disabled}
        ref={inputRef}
        maxLength={maxLength}
        css={[
          inputStyleCss,
          hasError && hasErrorCss,
          hasWarning && hasWarningCss,
        ]}
        {...props}
      />
      <label htmlFor={name} css={[tw`text-sonnant-purple-3`]}>
        {name}
      </label>
    </div>
  );
};

const inputStyleCss = css`
  ${tw`transition[all 0.2s ease-in-out]`}
  ${tw`rounded py-3 pb-0 mb-0 focus:(border-sonnant-purple-1 outline-none)`}
  ${tw`(bg-transparent text-15 border font-normal border-sonnant-grey-4 focus:(border-width[1px]))!`}
`;

const hasErrorCss = css`
  ${tw`(border-red-400)!`}
`;

const hasWarningCss = css`
  ${tw`(border-confidence-medium)!`}
`;

const customStyle = css`
  ${tw`relative`}

  input:focus + label,
  input:active + label,
  input:-webkit-autofill + label,
  input[value]:not([value='']) + label {
    transition: all 0.2s ease-out;

    ${tw`(top-[-1rem] bg-white font-medium text-sonnant-purple-3 text-13 ml-0)!`}
  }

  label {
    transition: all 0.2s ease-out;
    ${tw`(absolute top-[50%] transform[translateY(-50%)] ml-[1rem])!`}
    ${tw`(line-height[initial] text-15 cursor-text select-none)!`}
  }
`;
