import { SagaAction } from 'utils/models';
import * as types from '../actions/types';

interface InitialStateType {
  exportLoading: boolean;
  error: boolean;
  exportedData: {};
  statusCode: null;
}

const initialState = {
  exportLoading: false,
  error: false,
  exportedData: {},
  statusCode: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export const publicExportReducer = (
  state: InitialStateType = initialState,
  action: SagaAction,
) => {
  switch (action.type) {
    case types.INIT_PUBLIC_EXPORT_CONTENT_ITEM_WATCH: {
      return { ...state, exportLoading: true };
    }
    case types.SET_PUBLIC_EXPORT_CONTENT_ITEM: {
      return { ...state, exportLoading: false, exportedData: action.data.data };
    }
    case types.FAILED_PUBLIC_EXPORT_CONTENT_ITEM: {
      return { ...state, exportLoading: false, statusMessage: action.payload };
    }
    case types.UNSET_PUBLIC_EXPORT_CONTENT_ITEM: {
      return { ...state, exportLoading: false, exportedData: {} };
    }
    default:
      return state;
  }
};
