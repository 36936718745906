/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { isEmpty, uniq } from 'lodash';
import React, {
  ReactElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useToggle } from 'react-use';

import { Modal } from 'components/UiControls/modal/modal';
import Loader from 'components/loader/loader';
import { endOfDayISO, startOfDayISO } from 'utils/date.util';
import { defaultDatesRange } from 'utils/default/defaultSetting';
import { ModalOptions, SelectionMode } from 'utils/enum';
import { extract } from 'utils/generic.util';
import { CustomDateParams, OmnyFeed, PaginationModalProps } from 'utils/models';
import { OmnyPreviewPagination } from '../PreviewPaginationWrapper/OmnyPreviewPagination';
import { PreviewPaginationWrapper } from '../PreviewPaginationWrapper/PreviewPaginationWrapper';

type PromiseParams = {
  resolve: (payload: PaginationModalPayload) => void;
  reject: (err: any) => void;
};

type Props = {
  cancelText?: string;
  confirmText?: string;

  isLoading?: boolean;
  isDateRangeDisabled?: boolean;
  previewList: OmnyFeed[];
  totalItemCount: number;
  cursor: number;

  onChangePage: (page: number) => void;
  onChangeDate: (startDate: Date | null, endDate: Date | null) => void;
};

type PaginationModalPayload = {
  option: ModalOptions;
  selectedIds?: string[];
  isUploadAll?: boolean;
  startDate?: string;
  endDate?: string;
};

export type PaginationPreviewModalRef = {
  show: (params: PaginationModalProps) => Promise<PaginationModalPayload>;
};

export const PaginationPreviewModal = forwardRef(
  (
    {
      isLoading = false,
      isDateRangeDisabled = false,
      totalItemCount,
      cursor,
      previewList,
      ...props
    }: Props,
    ref: React.Ref<unknown>,
  ): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const [title, setTitle] = useState<string>('');
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [menuSelectionMode, setMenuSelectionMode] = useState<SelectionMode>(
      SelectionMode.PAGE,
    );
    const [dateRange, setDateRange] =
      useState<CustomDateParams>(defaultDatesRange);

    const [isOpen, toggleIsOpen] = useToggle(false);

    const isEmptyItems = totalItemCount === 0;

    const currentPageIndex = cursor;

    // AUTO select mode when update items
    useEffect(() => {
      if (isEmptyItems) {
        setMenuSelectionMode(SelectionMode.NONE);

        return;
      }

      const allIdList = extract(previewList, 'id');

      const isAllItemsSelected = allIdList.every((value) =>
        selectedIds.includes(value),
      );

      if (isAllItemsSelected && menuSelectionMode !== SelectionMode.ALL) {
        setMenuSelectionMode(SelectionMode.PAGE);
      }
    }, [selectedIds, previewList]);

    // AUTO check all items when select mode
    useEffect(() => {
      if ([SelectionMode.PAGE, SelectionMode.ALL].includes(menuSelectionMode)) {
        const omnyPreviewIds = extract(previewList, 'id');

        const newSelectedIds = uniq(selectedIds.concat(omnyPreviewIds));

        setSelectedIds(newSelectedIds);
      }
    }, [menuSelectionMode, previewList]);

    const setCurrentPageIndex = (pageIndex: number) => {
      props.onChangePage(pageIndex);
    };

    const promiseInfo = useRef<PromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (
      customProps: PaginationModalProps,
    ): Promise<unknown> => {
      const { title } = customProps;

      toggleIsOpen(true);

      setTitle(title);

      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };
      });
    };

    const handleReset = () => {
      setSelectedIds([]);
    };

    const hideModal = () => {
      handleReset();

      toggleIsOpen(false);
    };

    const handleYes = () => {
      hideModal();

      const isUploadAll = menuSelectionMode === SelectionMode.ALL;

      promiseInfo.current?.resolve({
        option: ModalOptions.YES,
        selectedIds: isUploadAll ? [] : selectedIds,
        isUploadAll: isUploadAll,
        startDate: startOfDayISO(dateRange.startDate),
        endDate: endOfDayISO(dateRange.endDate),
      });
    };

    const handleCancel = () => {
      hideModal();

      promiseInfo.current?.resolve({
        option: ModalOptions.CANCEL,
      });
    };

    const handleChangePage =
      (step: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        e.stopPropagation();

        const pageNumber = currentPageIndex + step;

        setCurrentPageIndex(pageNumber);

        if (menuSelectionMode !== SelectionMode.ALL) {
          setMenuSelectionMode(SelectionMode.NONE);
        }
      };

    const handleCheckSelected = (id: string) => {
      const newSelectedIds: string[] = [...selectedIds];

      // uncheck
      if (newSelectedIds.includes(id)) {
        newSelectedIds.splice(newSelectedIds.indexOf(id), 1);

        setMenuSelectionMode(SelectionMode.NONE);
      } else {
        newSelectedIds.push(id);
      }

      setSelectedIds(newSelectedIds);
    };

    const getUploadButtonText = () => {
      if (menuSelectionMode === SelectionMode.ALL)
        return `Upload all (${totalItemCount})`;

      return selectedIds?.length > 0
        ? `Upload (${selectedIds.length})`
        : 'Upload';
    };

    const handleDatesChange = ({ startDate, endDate }: CustomDateParams) => {
      setSelectedIds([]);

      props.onChangeDate(startDate, endDate);

      setDateRange({ startDate, endDate, focusedInput: null });
    };

    const handleChangeMenuSelectionMode = (selectedMode: SelectionMode) => {
      if (selectedMode === menuSelectionMode) return;

      if (selectedMode !== SelectionMode.NONE) {
        setMenuSelectionMode(selectedMode);

        return;
      }

      if (menuSelectionMode === SelectionMode.ALL) {
        // Uncheck all
        setSelectedIds([]);
      } else {
        // Uncheck page items
        const omnyPreviewIds = extract(previewList, 'id');

        const newSelectedIds = selectedIds.filter(
          (id) => !omnyPreviewIds.includes(id),
        );

        setSelectedIds(newSelectedIds);
      }

      setMenuSelectionMode(selectedMode);
    };

    return (
      <Modal show={isOpen} modalClosed={hideModal}>
        <div className="userModal_Popus">
          <h2>{title}</h2>

          {isLoading && (
            <div tw="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-white z-[20] opacity[0.95] rounded-md">
              <Loader />
            </div>
          )}

          <PreviewPaginationWrapper
            isLoading={isLoading}
            isDateRangeDisabled={isDateRangeDisabled}
            menuSelectionMode={menuSelectionMode}
            totalItems={totalItemCount}
            currentPageIndex={currentPageIndex}
            onSelectModeChange={handleChangeMenuSelectionMode}
            onDateRangeChange={handleDatesChange}
            onPageChange={handleChangePage}
          >
            <OmnyPreviewPagination
              previewList={previewList}
              selectedIds={selectedIds}
              handleCheckSelected={handleCheckSelected}
            />
          </PreviewPaginationWrapper>

          <div tw="flex justify-end mt-6">
            <div className="btn_section">
              <button
                type="button"
                className="button cancel large"
                tw="flex justify-center items-center"
                onClick={handleCancel}
              >
                {props?.cancelText || 'Close'}
              </button>
            </div>
            <div>
              <button
                type="button"
                className="button btn-primary large"
                tw="flex justify-center items-center (w-auto px-5)!"
                disabled={isEmpty(selectedIds)}
                onClick={handleYes}
              >
                {getUploadButtonText()}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);
