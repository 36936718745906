/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { CheckmarkIcon, ErrorIcon, LoaderIcon  } from 'react-hot-toast';
import { ToastType } from 'react-hot-toast/dist/core/types';
import { ReactComponent as WarningSvg } from 'assets/Icons/warning_toast.svg';
import { ReactElement } from 'react';

interface Props {
  type?: ToastType | 'no-icon' | 'warning';
  title?: string | ReactElement;
  message?: string | ReactElement;
}
export const CustomToast = ({ type, title, message, ...props }: Props) => {
  return (
    <div tw="flex items-center text-sonnant-dark">
      {type && (
        <div tw="mr-6">
          {type === 'success' && <CheckmarkIcon />}
          {type === 'error' && <ErrorIcon />}
          {type === 'loading' && <LoaderIcon />}
          {type === 'warning' && <WarningSvg width={24} height={24} />}
        </div>
      )}
      <div>
        <div tw="font-medium text-15">{title || 'Title'}</div>
        {message && (
          <div tw="word-break[break-word] text-14 mt-1">
            {message || 'Message'}
          </div>
        )}
      </div>
    </div>
  );
};
