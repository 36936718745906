/** @jsxImportSource @emotion/react */
import { ReactComponent as StarSvg } from 'assets/Icons/star.svg';
import { ReactComponent as StarFilledSvg } from 'assets/Icons/star_filled.svg';
import {
  isCollectionPage,
  isLibraryPage,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { isNil } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { RootState } from 'reducers';
import { addFavorite, removeFavorite } from 'slices/global.slice';
import 'twin.macro';
import { ThreeDotMenuItemProps } from 'utils/models';
import { menuDivider, simpleMenuItem } from '../twin.styles';
import { HintRestrictedChecker } from '../HintRestrictedChecker';
import { FavoriteEnum } from 'utils/enum';

type Props = {
  item: ThreeDotMenuItemProps;
};

export const ThreeDotMenuItem = (props: Props) => {
  const {
    id,
    isHrDivider,
    isHide,
    isFavorited,
    itemCss,
    handleOnClick,
    SvgComponent,
    itemName,
    className,
    shouldHideFavorite,
  } = props.item;

  const advancedSearch = useSelector(
    (state: RootState) => state.advancedSearch,
  );

  const [isHovering, toggleHovering] = useToggle(false);

  const dispatch = useDispatch();

  if (isHide) return null;

  if (typeof isHrDivider === 'boolean') {
    return isHrDivider ? <hr css={[menuDivider]} /> : null;
  }

  const handleMouse = (isHovering: boolean) => {
    toggleHovering(isHovering);
  };

  const handleAddFavorite = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    if (isNil(id)) return;

    dispatch(addFavorite(id));
  };

  const handleRemoveFavorite = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    if (isNil(id)) return;

    dispatch(removeFavorite(id));
  };

  const shouldRestrictedPublish = [
    FavoriteEnum.SHARE,
    FavoriteEnum.INTEGRATE,
  ].includes(id as FavoriteEnum);

  const shouldRestrictedEdit = [
    FavoriteEnum.EDIT_CAPTIONS,
    FavoriteEnum.EDIT_TRANSCRIPT,
  ].includes(id as FavoriteEnum);

  return (
    <HintRestrictedChecker
      isRestrictedPublish={shouldRestrictedPublish}
      isRestrictedEdit={shouldRestrictedEdit}
    >
      <div
        tw="relative border border-sonnant-red"
        onMouseEnter={() => handleMouse(true)}
        onMouseLeave={() => handleMouse(false)}
      >
        <span
          css={[simpleMenuItem, itemCss]}
          onClick={handleOnClick}
          className={className}
          tw="flex justify-between items-center"
        >
          <div tw={'flex space-x-4 items-center'}>
            {SvgComponent}
            <div>{itemName}</div>
          </div>
        </span>

        {(isLibraryPage() || isCollectionPage()) &&
          !advancedSearch.isShowClipsOnly && (
            <div tw="h-[18px] flex items-center absolute cursor-pointer top[50%] right[1.2rem] transform[translateY(-50%)]">
              {isFavorited && (
                <StarFilledSvg
                  width={16}
                  height={16}
                  fill="#7f8090"
                  onClick={handleRemoveFavorite}
                />
              )}
              {!shouldHideFavorite && !isFavorited && isHovering && (
                <StarSvg
                  width={16}
                  height={16}
                  fill="#7f8090"
                  onClick={handleAddFavorite}
                />
              )}
            </div>
          )}
      </div>
    </HintRestrictedChecker>
  );
};
