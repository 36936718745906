/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as ExpandedSvg } from 'assets/Icons/expand_more.svg';
import { ReactComponent as FilterSvg } from 'assets/Icons/filter.svg';

type Props = {
  disabled?: boolean;
  label?: string;

  onClick: () => void;
};

export const InsightAdvancedSelectionButton = ({
  disabled = false,
  label = 'Filter by time range',
  ...props
}: Props) => {
  return (
    <button
      className="button large btn-primary"
      tw="(mb-0 flex justify-center items-center gap-x-2.5 shadow w-[unset] min-w-[23rem] pl-[1rem] pr-[1.2rem])!"
      onClick={props.onClick}
      disabled={disabled}
    >
      <span tw="flex">
        <FilterSvg />
      </span>

      <span>{label}</span>

      <span tw="flex">
        <ExpandedSvg fill="white" />
      </span>
    </button>
  );
};
