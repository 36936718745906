/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';
import {
  hoverDropShadow,
  noneBorder,
  purpleBorder,
} from 'components/shared/twin.styles';
import { ReactComponent as Search } from 'assets/Icons/search.svg';
import CloseSVG from 'components/UiControls/closeButtonSVG/closeSVG';

import React, { useCallback, useRef, useState } from 'react';
import { CreatedReports } from 'utils/models';
import { SortTable } from 'components/shared/SortTable/SortTable';
import { ReactComponent as CancelIcon } from 'assets/Icons/cancel.svg';

import { ReactComponent as Export } from 'assets/Icons/Export.svg';
import { toDateWithHours } from 'utils/date.util';
import Loader from 'components/loader/loader';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import {
  CreatedReportEnum,
  HTTPStatus,
  Keys,
  MentionReportTypeEnum,
  ModalOptions,
} from 'utils/enum';
import { isEmpty, isNil } from 'lodash';
import { ReportService } from 'services';
import {
  formatCreatedReports,
  getTypeCreatedReports,
  getTypeMentionReports,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import {
  useClickAway,
  useEffectOnce,
  useMount,
  useSearchParam,
  useToggle,
} from 'react-use';
import { customToast } from 'utils/toast.util';
import { Info } from 'components/shared/Info';
import { Paginator } from '../../shared/Paginator';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { ExpiredHint } from './../../shared/ExpiredHint/ExpiredHint';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Routes } from 'utils/routes';

type CreatedReportItemProps = {
  row: CreatedReports;
  index: number;
};

export const CreatedReportsTab = () => {
  const [isLoading, toggleLoading] = useToggle(false);
  const [searchText, setSearchText] = useState('');
  const [createdReports, setCreatedReports] = useState<CreatedReports[]>([]);
  const [pageNumber, setPageNumber] = useState(1);

  const payment = useSelector((state: RootState) => state.payment);

  const s3DownloadBase64 = useSearchParam('download');

  const history = useHistory();

  const downloadReports = useCallback(async () => {
    if (isNil(s3DownloadBase64)) return;
    
    try {
      const modifiedEncoded = s3DownloadBase64.replace(/-/g, '+').replace(/_/g, '/');
      
      const s3DownloadURL = atob(modifiedEncoded);

      const response = await axios.get(s3DownloadURL, {
        headers: {
          'Access-Control-Allow-Origin': window.location.origin,
        },
      });

      if (response.status === HTTPStatus.SUCCESS) {
        window.open(s3DownloadURL);
      }
    } catch (err) {
      console.log('err', err)
      history.push(Routes.DOWNLOAD_EXPIRED);
    } finally {
      history.replace(window.location.pathname);
    }
  }, [s3DownloadBase64]);

  useEffectOnce(() => {
    downloadReports();
  });

  const PAGE_SIZE = 50;

  const fromIndex = pageNumber * PAGE_SIZE - PAGE_SIZE;
  const toIndex = pageNumber * PAGE_SIZE;

  const searchedReports = createdReports
    .filter(
      (report: CreatedReports) =>
        report.title.toLowerCase().includes(searchText) ||
        report.createdBy.toLowerCase().includes(searchText),
    )
    .slice(fromIndex, toIndex);

  const confirmModalRef = useRef<any>();

  useMount(() => {
    fetchCreatedReports();
  });

  const fetchCreatedReports = async () => {
    try {
      toggleLoading(true);
      const payload = await ReportService.getCreatedReports();

      const reports = formatCreatedReports(payload.data);
      setCreatedReports(reports);
    } catch (err: any) {
      console.log('err :>> ', err);
    } finally {
      toggleLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    const modalOption = await confirmModalRef.current?.show();

    if (modalOption === ModalOptions.YES) {
      deleteReportAsync(id);
    }
  };

  const deleteReportAsync = async (id: string) => {
    const deleteAsync = ReportService.deleteCreatedReports([id]);

    customToast.promise(deleteAsync, {
      loading: 'Deleting...',
      success: 'Report deleted successfully',
      error: 'Report delete failed',
    });

    try {
      await deleteAsync;

      const remainedReports = createdReports.filter(
        (report) => report.id !== id,
      );

      setCreatedReports(remainedReports);
    } catch (err: any) {
      console.log('err :>> ', err);
    }
  };

  const isCombinedClip = (type: MentionReportTypeEnum) => {
    return [
      MentionReportTypeEnum.MULTIPLE_COMBINED,
      MentionReportTypeEnum.SINGLE_COMBINED,
    ].includes(type);
  };

  const handleDownload = async (
    status: CreatedReportEnum,
    type: MentionReportTypeEnum,
    id: string,
  ) => {
    if (payment.subscription.isLoading) return;

    if (payment.isExpiredTrial && isCombinedClip(type)) {
      customToast.trialExpired();
      return;
    }

    try {
      if (status !== CreatedReportEnum.DRAFT) return;
      toggleLoading(true);

      const payload = await ReportService.downloadCreatedReports(id);

      window.open(payload.data.report_url, '_self');
    } catch (err: any) {
      console.log('err :>> ', err);
    } finally {
      toggleLoading(false);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value?.toLowerCase();

    setSearchText(text);
  };

  const CreatedReportItem = ({ row, index }: CreatedReportItemProps) => {
    const [isRenaming, toggleRename] = useToggle(false);
    const [newName, setNewName] = useState(row.title);

    const inputRef = useRef<any>(null);

    const payment = useSelector((state: RootState) => state.payment);

    const isDisabledDownload =
      (payment.isExpiredTrial &&
        [
          MentionReportTypeEnum.SINGLE_COMBINED,
          MentionReportTypeEnum.MULTIPLE_COMBINED,
        ].includes(row?.type as any)) ||
      row?.status !== CreatedReportEnum.DRAFT;

    const handleRenameTitle = () => {
      toggleRename(true);
    };

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      const text = e.target.value;

      setNewName(text);
    };

    const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === Keys.ESC) {
        setNewName(row.title);
        toggleRename(false);

        return;
      }

      if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
        if (isEmpty(newName.trim())) {
          customToast.error('Report name cannot be empty.');
          return;
        }

        // update UI
        const foundIndex = createdReports.findIndex(
          (report) => report.id === row.id,
        );

        if (foundIndex >= 0) {
          const newReports = [...createdReports];
          newReports[foundIndex].title = newName.trim();
          setCreatedReports(newReports);
        }

        const renameAsync = ReportService.putCreatedReports(row.id, newName);

        customToast.promise(renameAsync, {
          loading: 'Renaming...',
          success: 'Report renamed successfully',
          error: 'Report rename failed',
        });

        await renameAsync;
      }
    };

    useClickAway(inputRef, () => {
      toggleRename(false);
    });

    return (
      <tr>
        <td>
          <span tw="text-center font-medium">{index + 1}</span>
        </td>
        <td ref={inputRef}>
          {!isRenaming ? (
            <span
              onDoubleClick={handleRenameTitle}
              className="line-clamp-1"
              tw="word-break[break-all] cursor-pointer"
            >
              {row?.title}
            </span>
          ) : (
            <input
              css={[purpleBorder]}
              autoFocus
              tw="w-3/4"
              value={newName}
              onChange={handleInput}
              onKeyDown={handleKeyDown}
            />
          )}
        </td>
        <td tw="text-center">{getTypeMentionReports(row?.type)}</td>
        <td>{row?.createdBy}</td>
        <td>{toDateWithHours(row?.createdAt)}</td>
        <td tw="text-center">{getTypeCreatedReports(row?.status)}</td>
        <td
          tw="text-center cursor-pointer"
          onClick={() =>
            handleDownload(
              row.status as CreatedReportEnum,
              row?.type as MentionReportTypeEnum,
              row?.id,
            )
          }
          css={[isDisabledDownload && tw`cursor-not-allowed`]}
        >
          <ExpiredHint disabled={!payment.isExpiredTrial}>
            <div
              css={[
                (payment.subscription.isLoading || isDisabledDownload) &&
                  tw`opacity-60 pointer-events-none`,
              ]}
            >
              <Export />
            </div>
          </ExpiredHint>
        </td>
        <td
          tw="text-center cursor-pointer"
          onClick={() => handleDelete(row?.id)}
        >
          <CancelIcon css={hoverDropShadow(0.2)} fill="red" />
        </td>
      </tr>
    );
  };

  return (
    <>
      {isLoading && (
        <div
          className="loader__component"
          tw="height[calc(100vh - 13rem)] top[13rem] opacity-60"
        >
          <Loader />
        </div>
      )}

      <div tw="max-width[90%] md-down:(w-full) m-auto flex flex-col items-center space-y-10 mt-6">
        <div tw="w-full flex justify-center">
          <div className="search-container" tw="sm:hidden! flex -mr-1">
            <div tw="flex relative">
              <input
                className="input-group-field input-search"
                tw="(height[4rem] min-width[20rem] rounded-r-none rounded-l pl-4 pr-2 font-size[1.5rem] caret-color[#7F8090] placeholder:(text-14))!"
                css={[noneBorder]}
                type="input"
                placeholder="Search created reports"
                value={searchText}
                maxLength={128}
                onChange={handleSearch}
              />
            </div>
            <button
              tw="(rounded-l-none width[4rem] height[4rem] mb-0 rounded-r focus:(bg-white) hover:(border[2px solid #F0F3F6]) box-shadow[none])! md-down:(width[4rem])!"
              className="button btn-secondary"
            >
              {searchText ? (
                <CloseSVG
                  close={() => setSearchText('')}
                  color="#7F8090"
                  css={hoverDropShadow(0.3)}
                />
              ) : (
                <Search css={hoverDropShadow(0.3)} />
              )}
            </button>
          </div>
        </div>

        <div css={customTableStyle} className="listViewTable">
          <SortTable
            columns={
              [
                {
                  Header: '#',
                  accessor: '',
                  styles: {
                    textAlign: 'center',
                    width: '4rem',
                  },
                },
                {
                  Header: 'TITLE',
                  accessor: 'title',
                  width: '100%',
                },
                {
                  Header: 'TYPE',
                  accessor: 'type',
                  styles: {
                    width: '25rem',
                    textAlign: 'center',
                  },
                },
                {
                  Header: 'CREATED BY',
                  accessor: 'createdBy',
                  styles: {
                    maxWidth: '13rem',
                    minWidth: '10rem',
                  },
                },
                {
                  Header: 'CREATED',
                  accessor: 'createdAt',
                  styles: {
                    minWidth: '16rem',
                    maxWidth: '30rem',
                  },
                },
                {
                  Header: 'STATUS',
                  accessor: 'status',
                  styles: {
                    textAlign: 'center',
                    maxWidth: '10rem',
                    minWidth: '8rem',
                  },
                },

                {
                  Header: 'DOWNLOAD',
                  styles: {
                    textAlign: 'center',
                    maxWidth: '8rem',
                  },
                },
                {
                  Header: 'DELETE',
                  styles: {
                    textAlign: 'center',
                    maxWidth: '8rem',
                  },
                },
              ] as any
            }
            data={searchedReports as CreatedReports[]}
            isFixedTable
          >
            {({ original: row, index }) => (
              <CreatedReportItem row={row} index={index} />
            )}
          </SortTable>

          {isEmpty(searchedReports) && (
            <div tw="ml-4">
              <Info
                text={searchText ? 'No result found.' : 'Nothing to show.'}
              />
            </div>
          )}
        </div>

        {!isEmpty(createdReports) && (
          <Paginator
            totalItemsLength={createdReports.length}
            pageSize={PAGE_SIZE}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
          />
        )}
      </div>

      <ConfirmModal
        ref={confirmModalRef}
        message="Do you want to delete this created report?"
        title="Delete"
        confirmText="Confirm"
        classes="disable-enable-user-modal-width"
      />
    </>
  );
};

const customTableStyle = css`
  table thead tr {
    top: -1.1rem;
  }
`;
