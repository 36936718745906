/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Hint } from 'components/shared/Hint';
import React, { useRef } from 'react';
import { ILibraryItem, RevivalItemModel } from 'utils/models';
import { ReactComponent as VideoIconSvg } from 'assets/Icons/video_type.svg';
import { ReactComponent as AudioIconSvg } from 'assets/Icons/audio_list.svg';
import { ReactComponent as ShareFacebook } from 'assets/Icons/ShareFacebook.svg';
import { ReactComponent as ShareLinkedin } from 'assets/Icons/ShareLinkedin.svg';
import { ReactComponent as ShareTwitter } from 'assets/Icons/x-twitter-logo.svg';
import GoogleTrendsIcon from 'assets/Icons/google_trends.png';
import { ThreeDotMenu } from 'components/shared/ThreeDotMenu/ThreeDotMenu';
import { useClickAway, useToggle } from 'react-use';
import { useDispatch } from 'react-redux';
import { setFocusRevival } from 'slices/revival.slice';
import { Social } from 'utils/enum';
import { getLatestTitle } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { useHistory } from 'react-router';
import { hoverDropShadow } from 'components/shared/twin.styles';
import { getTagTrendingUrl } from 'utils/utils';
import { HintRestrictedPublish } from 'components/shared/HintRestrictedPublish';
import { useItemFunction } from 'hooks/useItemFunction';

interface RevivalListItemsProps {
  revival: RevivalItemModel;
  toggleOpenExportModal?: any;
  toggleOpenShareModal?: any;
  toggleOpenPublishModal?: any;
  onOpenShareModal?: (item: Partial<ILibraryItem>, social: Social) => void;
}

export const RevivalListItems = (props: RevivalListItemsProps) => {
  const {
    revival,
    toggleOpenExportModal,
    toggleOpenShareModal,
    toggleOpenPublishModal,
  } = props;
  const [isOpenMenu, toggleOpenMenu] = useToggle(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const threeDotRef = useRef<any>(null);

  const { handleViewClip } = useItemFunction({
    item: revival.libraryItem as ILibraryItem,
  });

  useClickAway(threeDotRef, () => {
    toggleOpenMenu(false);
  });

  const clickThreeDot = () => {
    toggleOpenMenu(!isOpenMenu);
    dispatch(setFocusRevival(revival));
  };

  const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement>) => {
    const mediaid = revival?.libraryItem?.mediaid;
    if (mediaid) {
      history.push(`/clips/${mediaid}`);
    }
  };

  const handleShareSocial = async (social: Social) => {
    if (!revival?.libraryItem?.mediaid) return;
    dispatch(setFocusRevival(revival));

    props?.onOpenShareModal?.(revival?.libraryItem, social);
  };

  return (
    <tr onClick={handleRowClick}>
      <td width="1%"></td>
      <td width="5%" tw="text-center" className="bottom" tabIndex={1}>
        <div
          className="overlay_lock_icon"
          tw="flex! items-center! justify-start"
        >
          {revival.libraryItem?.mediatype === 'audio' ? (
            <Hint text="Audio file">
              <AudioIconSvg />
            </Hint>
          ) : (
            <Hint text="Video file">
              <VideoIconSvg />
            </Hint>
          )}
        </div>
      </td>
      <td
        tw="text-black font-medium h-full! opacity[0.98]"
        title={getLatestTitle(revival.libraryItem as ILibraryItem)}
      >
        <span
          tw="2xl-up:max-width[60rem]!"
          className="line-clamp-2"
          title={getLatestTitle(revival.libraryItem as ILibraryItem)}
        >
          {getLatestTitle(revival.libraryItem as ILibraryItem) || 'Empty title'}
        </span>
      </td>
      <td>
        <span className="line-clamp-2" title={revival?.headline}>
          {revival?.headline ? revival.headline : ''}
        </span>
      </td>
      <td width="8%" tw="text-center">
        <div tw="flex items-center justify-center">
          {revival.trendingOn.includes(Social.TWITTER) && (
            <HintRestrictedPublish>
              <Hint
                text="Share suggested trending clip on Twitter"
                enterDelay={100}
              >
                <ShareTwitter
                  css={hoverDropShadow(0.2)}
                  width={26}
                  height={26}
                  tw="bg-black rounded-full p-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShareSocial(Social.TWITTER);
                  }}
                />
              </Hint>
            </HintRestrictedPublish>
          )}
          {revival.trendingOn.includes(Social.FACEBOOK) && (
            <ShareFacebook width={20} height={20} css={hoverDropShadow(0.2)} />
          )}
          {revival.trendingOn.includes(Social.LINKEDIN) && (
            <ShareLinkedin width={20} height={20} css={hoverDropShadow(0.2)} />
          )}
          {revival.trendingOn.includes(Social.GOOGLE) && (
            <div>
              <img src={GoogleTrendsIcon} tw="w-[2.4rem]" alt="Google Trends" />
            </div>
          )}
        </div>
      </td>
      <td>
        <Hint
          text={revival.topTags.join(', ')}
          enterDelay={100}
          leaveDelay={0}
          arrow
        >
          <div className="line-clamp-2" tw="inline-block">
            {revival.topTags.join(', ')}
          </div>
        </Hint>
      </td>
      <td>
        <div className="line-clamp-2">
          {revival.twitterTrendingTags.map((tag, index) => (
            <a
              key={tag}
              href={getTagTrendingUrl(revival?.trendingOn, tag)}
              target="_blank"
              rel="noopener noreferrer"
              tw="flex items-center hover:(underline) cursor-pointer color[inherit] hover:(color[inherit]) focus:(color[inherit])"
              onClick={(e) => e.stopPropagation()}
            >
              {tag}
              {index < revival.twitterTrendingTags.length - 1 && ', '}
            </a>
          ))}
        </div>
      </td>
      <td
        className="customThumbnail"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div ref={threeDotRef} className="dropdown show">
          <ThreeDotMenu
            openMenu={isOpenMenu}
            clickThreeDot={clickThreeDot}
            libraryItem={revival.libraryItem as any}
            handleShare={() => toggleOpenShareModal(true)}
            handleExport={() => toggleOpenExportModal(true)}
            handlePublish={() => toggleOpenPublishModal(true)}
            handleViewClip={handleViewClip}
            isRevival
          />
        </div>
      </td>
    </tr>
  );
};
