/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { Switcher } from 'components/shared/Switcher';
import React, { useEffect, useRef, useState } from 'react';
import { Slider } from 'components/shared/Slider/Slider';
import { ReactComponent as AddTermSvg } from 'assets/Icons/library_add_black_24dp.svg';
import { Hint } from 'components/shared/Hint';
import { SpeakerTabItem } from './SpeakerTabItem';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  addSpeakerIdentificationItem,
  deleteSpeakerIdentificationItem,
  setSpeakerState,
  setSpeakerSensitivity,
  toggleSpeakerDiarisation,
  toggleSpeakerIdentification,
} from 'slices/speaker-identification.slice';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { ModalOptions } from 'utils/enum';
import { SpeakerCreationModal } from 'components/shared/SpeakerCreationModal/SpeakerCreationModal';
import { css } from '@emotion/react';
import tw from 'twin.macro';
import { QuestionHint } from 'components/shared/QuestionHint';
import { SearchInput } from 'components/shared/SearchInput';
import { Info } from 'components/shared/Info';
import { isEmpty, isEqual } from 'lodash';
import { UserService } from 'services';
import { getTenantidFromIdToken, isValidSpeaker } from 'utils/utils';
import Loader from 'components/loader/loader';
import { customToast } from 'utils/toast.util';
import { trimAllSpaces } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { ResetBottomBar } from 'components/shared/ResetBottomBar/ResetBottomBar';
import { defaultSetting } from 'utils/default/defaultSetting';
import { ConfirmModalProps } from 'utils/models';

export const SpeakersTab = () => {
  const speakerIdentification = useSelector(
    (state: RootState) => state.speakerIdentification,
  );
  const profile = useSelector((state: RootState) => state.payment.subscription);
  const dispatch = useDispatch();

  const [initialState, setInitialState] = useState(speakerIdentification);

  const shouldDisabledReset = isEqual(defaultSetting.speakers, initialState);

  useEffect(() => {
    if (profile.isLoading === false) {
      setInitialState(speakerIdentification);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.isLoading]);

  const isSpeakerIdentificationDisabled =
    !speakerIdentification.hasSpeakerDiarisation;

  const isIdentificationSensitivityDisabled =
    !speakerIdentification.hasSpeakerIdentification;

  const [searchName, setSearchName] = useState('');

  const searchedSpeakers = speakerIdentification.speakers.filter((speaker) =>
    speaker?.speakerName
      .toLowerCase()
      .includes(searchName.trim().toLowerCase()),
  );

  const speakerCreationModalRef = useRef<any>(null);
  const confirmRef = useRef<any>(null);

  const toggleGlobalSettings = (
    checked: boolean,
    name: 'diarisation' | 'identification',
  ) => {
    if (name === 'diarisation') {
      dispatch(toggleSpeakerDiarisation(checked));
    } else {
      dispatch(toggleSpeakerIdentification(checked));
    }
  };

  const onChangeSlider = (value: number) => {
    dispatch(setSpeakerSensitivity(value));
  };

  const checkDisableButton = () => {
    return isEqual(initialState, speakerIdentification);
  };

  const handleClickDelete = async (id: string) => {
    const option = await confirmRef.current?.show({
      title: 'Delete speaker',
      message: (
        <span>
          Deleting a speaker which has been identified in a content item is
          strongly discouraged. This will not impact the speaker identification
          within any content items, but even if this speaker is re-added it will
          not be linked to any previous identifications.
        </span>
      ),
      confirmText: 'Delete',
    });
    if (option === ModalOptions.YES) {
      dispatch(deleteSpeakerIdentificationItem(id));
    }
  };

  const handleAddSpeaker = async () => {
    const result = await speakerCreationModalRef.current?.show({
      title: 'Add new speaker',
      headline: 'Speaker',
      btnTitle: 'Add',
    });

    if (result.option === ModalOptions.YES) {
      const formattedName = trimAllSpaces(result?.name);
      if (
        !isValidSpeaker({
          speakerName: formattedName,
          speakerList: speakerIdentification.speakers.map((s) => s.speakerName),
        })
      ) {
        return;
      }
      dispatch(addSpeakerIdentificationItem(result.name));
    }
  };

  const handleApply = async (isResetDefault?: boolean) => {
    const currentPayload = isResetDefault
      ? defaultSetting.speakers
      : speakerIdentification;

    const tenantid = getTenantidFromIdToken();
    const updateAsync = UserService.savePreference({
      tenantid,
      speakers: JSON.stringify({
        speaker_config: {
          speaker_diarisation: currentPayload.hasSpeakerDiarisation,
          speaker_diarisation_sensitivity:
            currentPayload.recommendedSensitivity,
          speaker_identification: currentPayload.hasSpeakerIdentification,
        },
        speaker_items: currentPayload.speakers,
      }),
    });

    customToast.promise(updateAsync, {
      loading: 'Updating...',
      success: 'Changes saved',
      error: 'Save failed',
    });

    try {

      await updateAsync;

      if (isResetDefault) {
        dispatch(setSpeakerState(defaultSetting.speakers));
      }

      setInitialState(currentPayload);
    } catch (error: any) {
      console.log('error', error);
    } finally {
    }
  };

  const handleReset = () => {
    dispatch(setSpeakerState(initialState));
  };

  const handleResetDefault = async () => {
    const result = await confirmRef.current?.show({
      title: 'Confirm reset to default',
      confirmText: 'Confirm',
      message: (
        <div>
          Are you sure you want to reset preferences configuration to default?
        </div>
      ),
    } as ConfirmModalProps);

    if (result !== ModalOptions.YES) return;

    handleApply(true);
  };

  return (
    <>
      {profile.isLoading && (
        <div className="loader__component" tw="top[calc(13rem - 1px)]">
          <Loader />
        </div>
      )}

      <div tw="w-full">
        <div tw="md-up:(width[95%]) m-auto p-5 flex-col mt-5">
          <div tw="flex items-center">
            <div tw="min-width[20rem]">Speaker diarisation</div>
            <div tw="relative flex space-x-6 items-center">
              <Switcher
                hasLabel={true}
                color="#00AF7A"
                checked={speakerIdentification.hasSpeakerDiarisation}
                onCheckedChange={(checked) =>
                  toggleGlobalSettings(checked, 'diarisation')
                }
              />
              <QuestionHint
                notTransparent
                hideCloseButton
                type={'information'}
                align={'bottom'}
                action={'hover'}
                text={
                  <span tw="text-15">
                    Speaker diarisation detects when the speaker changes and
                    links together different segments of speech by the same
                    speaker in a content item. If this is turned off, all
                    speaker diarisation and identification will be disabled, but
                    processing time will be reduced.
                  </span>
                }
                customStyle={customTooltipCss}
              />
            </div>
          </div>

          <div tw="flex items-center ">
            <div tw="flex items-center">
              <div tw="min-width[20rem]">Speaker identification</div>
              <div tw="flex space-x-6 items-center">
                <Switcher
                  hasLabel={true}
                  disabled={isSpeakerIdentificationDisabled}
                  color="#00AF7A"
                  checked={speakerIdentification.hasSpeakerIdentification}
                  onCheckedChange={(checked) =>
                    toggleGlobalSettings(checked, 'identification')
                  }
                />
                <QuestionHint
                  notTransparent
                  hideCloseButton
                  type={'information'}
                  align={'bottom'}
                  action={'hover'}
                  text={
                    <span tw="text-15">
                      Speaker identification takes examples of each speakers’
                      voice and then tries to determine whether speakers in
                      newly uploaded content items are similar enough to default
                      the speaker’s name to one of the existing speakers. This
                      may result in misidentification of speakers, use the
                      threshold to determine how accurate a match has to be
                      before the speaker name is defaulted.
                    </span>
                  }
                  customStyle={customTooltipCss}
                />
              </div>
            </div>
            <div tw="flex width[100%] max-width[120rem] ml-20 items-center">
              <div tw=" flex items-center whitespace-nowrap mr-10">
                Identification sensitivity
              </div>

              <Slider
                disabled={isIdentificationSensitivityDisabled}
                defaultValue={speakerIdentification.recommendedSensitivity}
                onChangeSlider={onChangeSlider}
              />

              <div tw="ml-5">
                <QuestionHint
                  notTransparent
                  hideCloseButton
                  type={'information'}
                  align={'bottom'}
                  action={'hover'}
                  text={
                    <div tw="text-15">
                      Identification sensitivity determines the likelihood of
                      matching identified speakers in new content with
                      previously identified speakers.
                      <ul tw="ml-8 mt-3">
                        <li>
                          If identification is turned off, no matches will be
                          found.
                        </li>

                        <li>
                          Below 30% sensitivity, many matches will be found
                          (which can lead to misidentification) and the closest
                          match is selected.
                        </li>

                        <li>
                          At 100% sensitivity, an exact match must be found
                          which could lead to no speaker being identified
                        </li>
                      </ul>
                    </div>
                  }
                  customStyle={customTooltip}
                />
              </div>
            </div>
          </div>
          <div tw="mt-8">
            <div tw="flex w-full justify-end mb-6">
              <div tw="mr-6">
                <div className="search-container" tw="sm:hidden! flex -mr-1">
                  <SearchInput
                    setTerm={setSearchName}
                    placeholder="Search Speakers"
                    noShrink
                  />
                </div>
              </div>

              <Hint
                // disabled={!hasReachLimit()}
                enterDelay={200}
                leaveDelay={100}
                text="Subscribe to get unlimited access to Custom Vocabs"
              >
                <div>
                  <button
                    className="button large btn-primary"
                    tw="max-width[unset]! width[auto]! flex items-center px-5! whitespace-nowrap mb-0"
                    onClick={handleAddSpeaker}
                  >
                    <AddTermSvg tw="mr-3" /> Add Speaker
                  </button>
                </div>
              </Hint>
            </div>

            <div tw="flex w-full justify-between uppercase font-black text-13 px-2">
              <div tw="flex">
                <div tw="mr-6">#</div>
                <div>SPEAKER NAME</div>
              </div>
              <div tw="flex space-x-3 mr-6 text-sonnant-dark">
                <div tw="mr-3">PROMPT</div>
                <div tw="relative right[3px]">IDENTIFY</div>
                <div tw="relative right[-1px]">EDIT</div>
                <div tw="relative right[-1px]">DEL</div>
              </div>
            </div>

            {isEmpty(searchedSpeakers) && (
              <Info text={searchName ? 'No result found' : 'Nothing to show'} />
            )}

            {searchedSpeakers.map((speaker, index) => (
              <SpeakerTabItem
                key={index}
                index={index}
                speaker={speaker}
                handleClickDelete={handleClickDelete}
              />
            ))}
          </div>
        </div>
        <div tw="mt-10 mr-20">
          <div className="btnReset">
            <button
              type="button"
              onClick={handleResetDefault}
              className={` success button apply 
            `}
              disabled={shouldDisabledReset}
            >
              Reset
            </button>
          </div>
        </div>

        <ResetBottomBar
          isShow={!checkDisableButton()}
          onReset={handleReset}
          onSubmit={handleApply}
        />

        <ConfirmModal ref={confirmRef} />

        <SpeakerCreationModal ref={speakerCreationModalRef} />
      </div>
    </>
  );
};

const customTooltipCss = css`
  ${tw`text-10 text-justify`}

  .notification {
    ${tw`lg-up:width[40rem]! md-down:(width[40rem] break-words)`}
  }

  .notificationWrapper {
    ${tw`margin-top[1rem]!`}
  }

  .notificationWrapper.information::before {
    ${tw`(top[-0.25rem] left[-0.3rem] relative)`}
  }
`;

const customTooltip = css`
  .notification {
    ${tw`lg-up:width[20rem]! md-down:(width[20rem] break-words)!`}
  }

  /* Float noti to the left */
  .notification {
    ${tw`(width[42rem] relative right[37rem])!`}
  }

  .notificationWrapper.information::before {
    ${tw`(top[0.5rem] left[-0.45rem] relative)`}
  }
`;
