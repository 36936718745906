import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { AuthService } from 'services';
import * as actions from '../actions';
import * as actionType from '../actions/types';
import { setToken, getDomainWithoutSubdomain } from '../utils/utils';

function* loginSaga(action: any) {
  try {
    const { history, nextQuery, ...rest } = action.payload;
    const signInRes: AxiosResponse = yield AuthService.signin({ ...rest });

    const domainUrl = `${window.location.protocol}//${
      rest.subdomain
    }.${getDomainWithoutSubdomain()}`;

    if (signInRes.data.ChallengeName) {
      yield setToken({
        AccessToken: signInRes.data.Session,
        IdToken: null,
        RefreshToken: null,
      });
      yield put(actions.setToken(null, null));
      yield history.push(
        `/changepassword?next=${encodeURIComponent(domainUrl)}`,
        { userDetails: signInRes.data, subdomain: rest.subdomain, nextQuery }
      );
    } else {
      if (nextQuery) {
        yield window.location.replace(
          `${domainUrl}?next=${encodeURIComponent(nextQuery)}&access_token=${
            signInRes.data.AccessToken
          }&id_token=${signInRes.data.IdToken}&refresh_token=${
            signInRes.data.RefreshToken
          }`
        );
      } else {
        yield window.location.replace(
          `${domainUrl}?access_token=${signInRes.data.AccessToken}&id_token=${signInRes.data.IdToken}&refresh_token=${signInRes.data.RefreshToken}`
        );
      }
    }
  } catch (error: any) {
    yield put(actions.setTokenFailed(error.data));
  }
}

export default function* WatchloginSaga() {
  yield takeLatest(actionType.INIT_LOGIN, loginSaga);
}
