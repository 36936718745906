/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import React, { useEffect, useState } from 'react';
import { AuthSocialButtonV2 } from 'components/shared/AuthSocialButtons/AuthSocialButtonV2';
import { AppleAuthError, Social } from 'utils/enum';
import { useSearchParam, useToggle } from 'react-use';
import * as actions from '../../actions';
import {
  AppleIdResponse,
  AuthService,
  FbSdkResponse,
  SigninSocialPayload,
  SubdomainPayload,
} from 'services';
import {
  getDomainWithoutSubdomain,
  getEmailErrorMessage,
  getSubdomainFromURL,
  invalidEmail,
  isEmptyOrSpaces,
} from 'utils/utils';
import { Link, useHistory, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { customToast } from 'utils/toast.util';
import { loginFacebookSDKAsync } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { ForgotPassword } from './ForgotPassword';
import { isEmpty, isNil, isNull } from 'lodash';
import { SearchInput } from 'components/shared/SearchInput';
import { css } from '@emotion/react';
import Loader from 'components/loader/loader';
import { useDispatch, useSelector } from 'react-redux';
import { loadFacebookSdkScript } from 'utils/scripts/loadFacebookAuthScript';
import { loadAppleJsScript } from 'utils/scripts/loadAppleAuthScript';
import { loadGoogleScriptV1 } from 'utils/scripts/loadGoogleScriptV1';
import recaptchaComponent from 'utils/scripts/recaptchaComponent';
import { ErrorForm } from 'components/shared/ErrorForm/ErrorForm';
import { TextField } from 'components/shared/TextField/TextField';
import { textLink } from 'components/shared/twin.styles';
import './Login.scss';

export const LoginV2 = () => {
  const [provider, setProvider] = useState<Social | null>(null);
  const [isLoading, toggleLoading] = useToggle(false);
  const [socialCredential, setSocialCredential] = useState<any>(null);
  const [subdomain, setSubdomain] = useState<string[]>([]);
  const [loginForm, setLoginForm] = useState<{
    email: string;
    password: string;
  }>({ email: '', password: '' });
  const [selectAccount, setSelectAccount] = useState<any>('');
  const [searchTenantTerm, setSearchTenantTerm] = useState('');
  const [isModalOpen, toggleModalOpen] = useToggle(false);

  const [error, setError] = useState<{
    emailError: boolean;
    passwordError: boolean;
    apiError: boolean;
    apiErrorMessage: string;
    emailMessage: string;
  }>({
    emailError: false,
    passwordError: false,
    apiError: false,
    apiErrorMessage: '',
    emailMessage: '',
  });

  const search = useLocation().search;
  const nextQuery = new URLSearchParams(search).get('next');
  const dispatch = useDispatch();
  const history = useHistory();

  const [initSelectAccount] = useState<any>(() => {
    if (nextQuery) {
      const domain = new URL(nextQuery);

      let hostnameArr = domain.hostname.replace('www.', '').split('.');
      if (hostnameArr.length > 2) {
        return hostnameArr[0];
      }
    }
    return null;
  });

  const loginData = useSelector((state: any) => state.login);

  const nextUrl = useSearchParam('next');
  const nextSubdomain = getSubdomainFromURL(nextUrl);

  const decodeUrl = decodeURIComponent(search);
  const replaceQuestionMark = decodeUrl.replace(/\?/g, '&');

  const userName = new URLSearchParams(replaceQuestionMark)
    .get('username')
    ?.toString();
  const password = new URLSearchParams(replaceQuestionMark)
    .get('password')
    ?.toString();

  const searchedTenants = subdomain.filter((tenant: any) =>
    tenant.includes(searchTenantTerm.toLowerCase()),
  );

  const hasSubdomain = !initSelectAccount && !isEmpty(subdomain);
  const hasMultiSubdomain = hasSubdomain && subdomain.length > 1;

  useEffect(() => {
    recaptchaComponent.init();
    if (loginData.errorMessage) {
      setError((preValue: any) => ({
        ...preValue,
        apiError: true,
        apiErrorMessage: loginData.errorMessage,
      }));
    }

    const close = (e: any) => {
      if (e.keyCode === 27) {
        handleModalClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [loginData.errorMessage]);

  useEffect(() => {
    if (!isNull(initSelectAccount)) {
      setSelectAccount(initSelectAccount);
    }
  }, [initSelectAccount]);

  useEffect(() => {
    if (searchedTenants.length > 0 && isEmpty(selectAccount)) {
      setSelectAccount(searchedTenants[0]);
    }

    // if (
    //   (!isEmpty(subdomain) && isEmpty(searchedTenants)) ||
    //   isEmpty(loginForm.email) ||
    //   isEmpty(loginForm.password)
    // ) {
    //   toggleDisableLogin(true);
    // } else {
    //   toggleDisableLogin(false);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTenantTerm, subdomain, loginForm]);

  useEffect(() => {
    if (error.apiError) {
      customToast.error(error.apiErrorMessage);
      setError({ ...error, apiError: false, apiErrorMessage: '' });
    }
  }, [error.apiErrorMessage, error.apiError]);

  const handleModalClose = () => {
    toggleModalOpen(false);
  };

  const handleModalOpen = () => {
    toggleModalOpen(true);
  };

  const loginRedirectAsync = async (payload: SigninSocialPayload) => {
    try {
      toggleLoading(true);
      const signInResult = await AuthService.signinSocial(payload);

      const { IdToken, AccessToken, RefreshToken } = { ...signInResult.data };

      const domainUrl = `${window.location.protocol}//${
        payload?.subdomain
      }.${getDomainWithoutSubdomain()}`;

      if (nextQuery) {
        window.location.replace(
          `${domainUrl}?next=${encodeURIComponent(
            nextQuery,
          )}&access_token=${AccessToken}&id_token=${IdToken}&refresh_token=${RefreshToken}`,
        );
      } else {
        window.location.replace(
          `${domainUrl}?access_token=${AccessToken}&id_token=${IdToken}&refresh_token=${RefreshToken}`,
        );
      }
    } catch (error: any) {
      setSocialCredential(null);

      let message = '';
      switch (error?.status) {
        case 404:
          if (payload?.provider) {
            message = `Your ${payload.provider} account does not link to this tenant. Please use another login method.`;
          }
          break;
        case 401:
          message = error?.data;
          break;
      }

      setError({
        ...error,
        apiError: true,
        apiErrorMessage:
          message ||
          'Something went wrong. Please contact support for more information.',
      });
    } finally {
      toggleLoading(false);
    }
  };

  const loadSocialAccountSubdomainAsync = async ({
    email,
    token = '',
    provider,
  }: SubdomainPayload) => {
    try {
      // check email
      toggleLoading(true);
      const getSubdomainBySocialProvider = await AuthService.getSubdomain({
        email,
        provider,
      });

      if (getSubdomainBySocialProvider.status !== 200) {
        setSocialCredential(null);
        setProvider(null);
        setError({
          ...error,
          apiError: true,
          apiErrorMessage: 'Something went wrong',
        });
        toggleLoading(false);
        return;
      }

      if (getSubdomainBySocialProvider.data.length === 0) {
        setSocialCredential(null);
        setProvider(null);
        setError({
          ...error,
          apiError: true,
          apiErrorMessage: 'User not found.',
        });
        toggleLoading(false);
        return;
      }

      // call signin with on tenant
      if (getSubdomainBySocialProvider.data.length === 1) {
        await loginRedirectAsync({
          email,
          subdomain: nextQuery
            ? nextSubdomain!
            : getSubdomainBySocialProvider.data[0],
          token,
          provider: provider!,
        });
      }

      if (getSubdomainBySocialProvider.data.length > 1) {
        if (initSelectAccount) {
          await loginRedirectAsync({
            email,
            subdomain: initSelectAccount,
            token,
            provider: provider!,
          });
        } else {
          setSubdomain(getSubdomainBySocialProvider.data);
          toggleLoading(false);
        }
      }
    } catch {
      setError({
        ...error,
        apiError: true,
        apiErrorMessage: 'Something went wrong',
      });
      toggleLoading(false);
    }
  };

  const handleLoginGoogleButton = async () => {
    try {
      const user = await window?.gapi?.auth2?.getAuthInstance()?.signIn();

      toggleLoading(true);
      setSocialCredential(user?.getAuthResponse());
      setProvider(Social.GOOGLE);
      const idToken = user?.getAuthResponse()?.id_token;
      const decodedToken: any = jwt_decode(idToken);

      await loadSocialAccountSubdomainAsync({
        email: decodedToken?.email,
        token: idToken,
        provider: Social.GOOGLE,
      });
    } catch (err: any) {
      console.log('err :>> ', err);
    }
  };

  const handleLoginAppleButton = async (
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    if (typeof (window as any)?.AppleID?.auth?.signIn !== 'function') {
      customToast.error('Apple signin is not ready. Please try again later');
      return;
    }

    try {
      toggleLoading(true);
      const appleIdResponse: AppleIdResponse = await (
        window as any
      ).AppleID.auth.signIn();
      setSocialCredential(appleIdResponse);
      setProvider(Social.APPLE);

      const rawIdToken = appleIdResponse?.authorization?.id_token;
      const idTokenDecoded: any = jwt_decode(rawIdToken);
      // console.log('idTokenDecoded :>> ', idTokenDecoded);

      await loadSocialAccountSubdomainAsync({
        email: idTokenDecoded?.email,
        token: rawIdToken,
        provider: Social.APPLE,
      });
    } catch (error: any) {
      console.log('error :>> ', error);
      let message = 'Apple Authorization cannot be completed';
      switch (error?.error) {
        case AppleAuthError.POPUP_BLOCKED:
          message =
            'Popup blocked by browser. Please allow popups for this site';
          break;
        default:
          break;
      }
      customToast.error(message);
    } finally {
      toggleLoading(false);
    }
  };

  const handleLoginFacebookButton = async () => {
    try {
      toggleLoading(true);
      const user = await loginFacebookSDKAsync();

      if (!user?.email) {
        return customToast.error(
          'Cannot read email from your Facebook account. Please try again.',
        );
      }

      setSocialCredential(user);
      setProvider(Social.FACEBOOK);

      await loadSocialAccountSubdomainAsync({
        email: user?.email,
        token: user?.id,
        provider: Social.FACEBOOK,
      });
    } catch (error: any) {
      console.log('error :>> ', error);
    } finally {
      toggleLoading(false);
    }
  };

  const getSubdomainByEmail = () => {
    AuthService.getSubdomain({ email: loginForm.email })
      .then(({ data: domains }) => {
        toggleLoading(false);
        setSubdomain(domains);

        if (domains.length === 0) {
          setError({
            ...error,
            emailMessage: 'User email not found.',
            emailError: true,
          });
        }
      })
      .catch((error) => {
        toggleLoading(false);
        console.log('error', error);
      });
  };

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: 'email' | 'password',
  ) => {
    if (type === 'email') {
      setSubdomain([]);
      setError({
        ...error,
        emailError: false,
      });
    }
    setLoginForm({
      ...loginForm,
      password: '',
      [type]: e.target.value,
    });
  };

  const handleLoginSocialSubdomain = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    toggleLoading(true);

    let email = '';
    let token = '';

    switch (provider) {
      case Social.GOOGLE:
        token = socialCredential?.id_token;

        const decodedToken: any = jwt_decode(token);
        email = decodedToken?.email ?? '';
        break;
      case Social.APPLE:
        token = socialCredential?.authorization?.id_token;

        const idTokenDecoded: any = jwt_decode(token);
        email = idTokenDecoded?.email ?? '';
        break;
      case Social.FACEBOOK:
        const user = socialCredential as FbSdkResponse;

        email = user?.email;
        token = user?.id;
        break;
      default:
        customToast.error('Invalid social provider');
        return;
    }

    try {
      await loginRedirectAsync({
        email,
        subdomain: selectAccount,
        token,
        provider: provider!,
      });
    } catch {
      // console.log('error :>> ', error);
      setError({
        ...error,
        apiError: true,
        apiErrorMessage: 'Something went wrong',
      });
      toggleLoading(false);
    }
  };

  const autoLogin = (username: string, password: string) => {
    dispatch(
      actions.initLogin({
        subdomain: initSelectAccount
          ? initSelectAccount
          : selectAccount || subdomain[0],
        email: username?.trim(),
        password: password,
        history,
        nextQuery,
      }),
    );
  };

  const hasLoginFormError = () => {
    const { email, password } = { ...loginForm };

    const isBlankEmail = isEmptyOrSpaces(email?.trim());
    const isBlankPassword = isEmptyOrSpaces(password?.trim());
    const isInvalidEmail = invalidEmail(email?.trim());

    setError((preValue: any) => ({
      ...preValue,
      passwordError: isBlankPassword,
      emailError: isBlankEmail || isInvalidEmail,
      emailMessage: getEmailErrorMessage(email),
    }));

    return isBlankPassword || isBlankEmail || isInvalidEmail;
  };

  const handleClose = (property: any) => {
    setError((preValue: any) => ({ ...preValue, [property]: false }));
  };

  const handleSignIn = () => {
    if (hasLoginFormError()) {
      return;
    }

    if (
      selectAccount === '' ||
      loginForm.email === '' ||
      loginForm.password === ''
    ) {
      return;
    }
    dispatch(
      actions.initLogin({
        subdomain: initSelectAccount
          ? initSelectAccount
          : selectAccount || subdomain[0],
        email: loginForm.email.trim(),
        password: loginForm.password,
        history,
        nextQuery,
      }),
    );
  };

  const triggerGetSubdomain = () => {
    let checked = true;

    if (loginForm.email === '') {
      setError({
        ...error,
        emailMessage: 'Email Address is a mandatory field.',
        emailError: true,
      });
      checked = false;
    }

    const isInvalidEmail = invalidEmail(loginForm.email?.trim());
    if (isInvalidEmail) {
      setError({
        ...error,
        emailMessage: 'This email address is considered invalid.',
        emailError: true,
      });
      checked = false;
    }

    if (!isEmpty(subdomain) || !isEmpty(loginForm.password)) {
      handleSignIn();
    }

    if (!initSelectAccount && checked) {
      toggleLoading(true);
      getSubdomainByEmail();
    }
  };

  useEffect(() => {
    loadFacebookSdkScript();
    loadAppleJsScript();
    loadGoogleScriptV1();
  }, []);

  useEffect(() => {
    if (isNil(userName) || isNil(password)) {
      return;
    }

    if (userName !== '' && password !== '') {
      setLoginForm((prevValue: any) => ({
        ...prevValue,
        email: userName,
        password: password,
      }));

      autoLogin(userName!, password!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePressEmail = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (loginForm.email === '') {
        setError({
          ...error,
          emailMessage: 'Email Address is a mandatory field.',
          emailError: true,
        });
        return;
      }

      const isInvalidEmail = invalidEmail(loginForm.email?.trim());
      if (isInvalidEmail) {
        setError({
          ...error,
          emailMessage: 'This email address is considered invalid.',
          emailError: true,
        });
        return;
      }

      if (!initSelectAccount) {
        toggleLoading(true);
        getSubdomainByEmail();
      }
      triggerGetSubdomain();
    }
  };

  return (
    <div tw="px-5">
      {(isLoading || loginData.loading) && (
        <div className="loader__component">
          <Loader />
        </div>
      )}

      <div tw="font-semibold text-[2.7rem] text-black">Welcome back</div>
      <div tw="mt-5 text-12 text-black">
        Don't have an account?{' '}
        <Link css={[textLink]} to="/signup">
          <span tw="text-sonnant-purple-3 font-semibold cursor-pointer">
            Sign Up
          </span>
        </Link>
      </div>

      <>
        <div tw="flex mt-9 mb-10 items-center">
          <div tw="text-12 min-width[9rem] text-black">Sign in with</div>
          <AuthSocialButtonV2
            handleLoginGoogleButton={handleLoginGoogleButton}
            handleLoginAppleButton={handleLoginAppleButton}
            handleLoginFacebookButton={handleLoginFacebookButton}
          />
        </div>

        <div tw="mb-6">
          <div tw="flex flex-col h-full space-y-9">
            <TextField
              name="Email"
              onChange={(e) => handleInput(e, 'email')}
              onKeyPress={handlePressEmail}
              onFocus={() => handleClose('emailError')}
              value={loginForm.email}
              // placeholder="john@example.com"
            />

            {error.emailError && (
              <ErrorForm type="error" msg={error.emailMessage} />
            )}
            {(nextSubdomain || hasSubdomain) && (
              <>
                <TextField
                  name="Password"
                  onChange={(e) => handleInput(e, 'password')}
                  onKeyPress={handlePressEmail}
                  onFocus={() => handleClose('emailError')}
                  value={loginForm.password}
                  onInput={() => setError({ ...error, passwordError: false })}
                  type="password"
                  // placeholder='**********'
                />

                {error.passwordError && (
                  <ErrorForm type="error" msg="Password is a mandatory field" />
                )}
              </>
            )}
          </div>
        </div>

        {hasMultiSubdomain && (
          <>
            <div tw="w-full h-[25%] flex flex-col justify-center">
              <div tw="flex flex-row justify-between items-center mb-1.5">
                <label tw="text-sonnant-purple-3 text-13 relative">
                  Select your account
                </label>
                {subdomain?.length > 10 && (
                  <div css={[customSearchInputStyle]}>
                    <SearchInput
                      placeholder="Search tenant name"
                      setTerm={(term) => {
                        setSelectAccount(undefined);
                        setSearchTenantTerm(term);
                      }}
                      autoFocus
                      isSearchTenantLogin
                    />
                  </div>
                )}
              </div>
              <select
                id="account"
                name="account"
                size={10}
                css={selectAccountCss}
                onChange={(e) => {
                  setSelectAccount(e.target.value);
                }}
                value={selectAccount}
              >
                {!isEmpty(searchedTenants) ? (
                  searchedTenants.map((item: string, i: number) => (
                    <option
                      key={i}
                      value={item}
                      onClick={() => setSelectAccount(item)}
                    >
                      {item}
                    </option>
                  ))
                ) : (
                  <option> No results found </option>
                )}
              </select>
            </div>
          </>
        )}
      </>

      {socialCredential && !nextQuery ? (
        <button
          onClick={handleLoginSocialSubdomain}
          tw="w-full font-medium h-[4.2rem] rounded-2xl bg-[#26165f] hover:bg-[#110a2b]"
        >
          Go to account
        </button>
      ) : (
        <>
          <div
            onClick={triggerGetSubdomain}
            tw="text-16 font-medium w-full py-3.5 mt-4 cursor-pointer text-white flex items-center justify-center rounded-2xl bg-[#26165f] hover:bg-[#110a2b] active:bg-[#1f1252]"
            css={[!hasSubdomain && tw`mt-7`]}
          >
            Sign In
          </div>

          <div
            onClick={handleModalOpen}
            tw="text-13 text-center mt-8 cursor-pointer"
            css={[textLink, tw`font-medium text-sonnant-purple-3!`]}
          >
            Forgot password?
          </div>

          <a
            css={[
              textLink,
              tw`block mt-10 text-center font-medium text-sonnant-purple-3! text-13`,
            ]}
            href={`${
              window.location.protocol
            }//${getDomainWithoutSubdomain()}/signin`}
          >
            Sign into a different account
          </a>
        </>
      )}

      <ForgotPassword show={isModalOpen} setModalOpen={handleModalClose} />
    </div>
  );
};

const selectAccountCss = css`
  ${tw`height[12rem] p-0 border[1px solid transparent]! overflow-y-auto`}

  option {
    ${tw`cursor-pointer padding[2px 4px] rounded-sm`}
  }
`;

const customSearchInputStyle = css`
  input:not([type='checkbox']) {
    height: 28px !important;
    width: 12rem !important;
    font-size: 12px !important;
    font-weight: normal;
    font-family: inherit;
  }

  .search-container button {
    height: 28px !important;
    width: 28px !important;
    padding-right: 2px !important;
    padding-bottom: 2px !important;
  }
`;
