/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import React, {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Modal } from 'components/UiControls/modal/modal';
import { ModalOptions } from 'utils/enum';
import { isEmpty } from 'lodash';
import { ConfirmModalProps } from 'utils/models';

interface IPromiseParams {
  resolve: (option: ModalOptions) => void;
  reject: (err: any) => void;
}

export const ConfirmModal = forwardRef(
  (parentProps: ConfirmModalProps, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const [modalProps, setModalProps] =
      useState<ConfirmModalProps>(parentProps);

    const [isOpen, setIsOpen] = useState(false);

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (customProps: ConfirmModalProps): Promise<unknown> => {
      if (!isEmpty(customProps)) {
        setModalProps({ ...modalProps, ...customProps });
      }

      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const hideModal = () => setIsOpen(false);

    const handleYes = () => {
      hideModal();
      promiseInfo.current?.resolve(ModalOptions.YES);
    };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current?.resolve(ModalOptions.CANCEL);
    };

    return (
      <Modal
        classes={parentProps?.classes}
        show={isOpen}
        modalClosed={() => {
          hideModal();
          promiseInfo.current?.resolve(ModalOptions.CLOSE);
        }}
      >
        <div css={customCss}>
          <h1 tw="font-semibold text-sonnant-dark font-size[3.5rem]">
            {modalProps.title || 'Confirm'}
          </h1>
          <div className="grid-x">
            <div className="cell small-12">
              <div
                tw="rounded text-center bg-sonnant-orange text-white p-6 mt-4 mb-10 text-16"
                css={[
                  modalProps?.type === 'info' && tw`bg-sonnant-green!`,
                  modalProps?.type === 'warning' && tw`bg-sonnant-orange!`,
                  modalProps?.type === 'error' && tw`bg-sonnant-red!`,
                ]}
              >
                <div>{modalProps.message || '-- Missing Message --'}</div>
              </div>
            </div>
          </div>

          <div className="grid-x">
            <div className="cell small-12">
              <div tw="w-full flex justify-end">
                {!modalProps?.hideCancel && (
                  <button
                    type="button"
                    className="button btn-secondary large"
                    tw="mr-6 hover:(border-sonnant-grey-3!)"
                    onClick={handleCancel}
                  >
                    {modalProps?.cancelText || 'Cancel'}
                  </button>
                )}
                {!modalProps?.hideConfirm && (
                  <button
                    type="button"
                    className="button btn-primary large"
                    onClick={handleYes}
                  >
                    {modalProps?.confirmText || 'Yes'}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);

const customCss = css`
  p {
    ${tw`mb-0`}
  }
`;
