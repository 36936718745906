/** @jsxImportSource @emotion/react */
import 'twin.macro';
import React from 'react'

interface IProps {
  userNameEditing: string;
  onCancel: () => void;
  onUnlock: () => void;
}

export const LockingWarning = (props: IProps) => {
  const { onCancel, onUnlock, userNameEditing } = props;

  return (
    <>
      <div className="userModal_Popus">
        <h1>Confirm to update</h1>
        <>
          <div className="grid-x">
            <div className="cell small-12">
              <div className="deleteInfo">
                <div tw="text-16 text-white py-2">
                  <div>
                    This item currently locked for editing by <strong>{userNameEditing}</strong>.
                  </div>
                  <div>
                    Choosing to Unlock and Edit may result in the loss of any
                    unsaved changes.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid-x">
            <div className="cell small-12">
              <div className="btn_section">
                <button
                  type="button"
                  className="button cancel"
                  onClick={onCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="button delete"
                  style={{ width: "auto" }}
                  onClick={onUnlock}
                >
                  Unlock and Edit
                </button>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};
