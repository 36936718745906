/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { Ripple } from '@rmwc/ripple';
import { ReactComponent as CheckSvg } from 'assets/Icons/check.svg';
import { ReactComponent as CloseIcon } from 'assets/Icons/close.svg';
import { ReactComponent as NoCheckSvg } from 'assets/Icons/nocheck.svg';
import { useState } from 'react';
import { useToggle } from 'react-use';
import { Keys } from 'utils/enum';
import { AliasTerm } from 'utils/models';
import { Hint } from '../Hint';
import { isEmpty, isEqual, isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { customToast } from 'utils/toast.util';

type Props = {
  editingTerms: AliasTerm[];
  aliasTerm: AliasTerm;
  onRemoveTag: (tag: AliasTerm) => void;
  onToggleEnabled: (tag: AliasTerm) => void;
  onUpdateTerm: (tag: AliasTerm) => void;
  isHighlightTerm: boolean;

  isEditable?: boolean;
};

export const AliasTermItem = ({
  editingTerms,
  aliasTerm,
  isHighlightTerm,
  isEditable,
  ...props
}: Props) => {
  const global = useSelector((state: RootState) => state.global);
  const blacklistInsight = global.blacklistInsight;

  const displayTermName = aliasTerm.alias ?? aliasTerm.original;

  const [isEditing, toggleEditing] = useToggle(false);
  const [editingText, setEditingText] = useState<string>(displayTermName);

  const handleClickToggleTag = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    const newState = !aliasTerm.isEnabled;

    const editedTerm: AliasTerm = {
      ...aliasTerm,
      isEnabled: newState,
    };

    props.onToggleEnabled(editedTerm);
  };

  const handleClickRemoveTag = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();

    props.onRemoveTag(aliasTerm);
  };

  const cancelEditing = () => {
    setEditingText(displayTermName);
    toggleEditing(false);
  };

  const handleInputKeyword = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const formattedInput = editingText?.replace(/\s+/g, ' ').trim();

    if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
      const isValidAlias = validateAlias(formattedInput);

      if (isValidAlias) {
        const isResetOriginal = isEqual(formattedInput, aliasTerm.original);

        props.onUpdateTerm({
          ...aliasTerm,
          alias: isResetOriginal ? null : formattedInput,
        });

        cancelEditing();
      }
    }

    if (e.key === Keys.ESC) {
      cancelEditing();
    }
  };

  const validateAlias = (alias: string): boolean => {
    // Bypass validation on reset to original term
    if (alias === aliasTerm.original) {
      return true;
    }

    if (isEmpty(alias)) {
      customToast.warning('Cannot enter a empty term.');

      return false;
    }

    const originalList = editingTerms.map(({ original }) => original);
    const isExistedInOriginalList = checkExistedInList(alias, originalList);

    if (isExistedInOriginalList) {
      customToast.warning(
        'Cannot perform this action as the entered alias term has already existed original term.',
      );

      return false;
    }

    const aliasList = editingTerms
      .map((term) => term.alias)
      .filter(Boolean) as string[];
    const isExistedInAliasList = checkExistedInList(alias, aliasList);

    if (isExistedInAliasList) {
      customToast.warning(
        'Cannot perform this action as the entered alias term has already existed alias term.',
      );

      return false;
    }

    const blacklistedList = blacklistInsight.map(({ name }) =>
      name.toLowerCase(),
    );
    const isExistedInBlacklist = checkExistedInList(
      alias.toLowerCase(),
      blacklistedList,
    );

    if (isExistedInBlacklist) {
      customToast.warning(
        'Cannot perform this action as the entered alias term has already been blacklisted.',
      );

      return false;
    }

    return true;
  };

  const checkExistedInList = (
    alias: string | null | undefined,
    items: string[],
  ): boolean => {
    if (isNil(alias)) return false;

    return items.includes(alias);
  };

  return isEditing ? (
    // EDIT MODE
    <div className="button keywordBtn keywordEdit" tw="p-0 m-0">
      <input
        type="text"
        value={editingText}
        onChange={(e) => setEditingText(e.target.value)}
        onKeyDown={handleInputKeyword}
        onBlur={cancelEditing}
        className="keywordInput"
        tw="text-13 rounded-r-none!"
        maxLength={128}
        autoFocus
      />
      <button
        onClick={cancelEditing}
        className="cancelBtn small-2"
        tw="rounded-l-none!"
      >
        <CloseIcon className="whiteIcon" />
      </button>
    </div>
  ) : (
    // VIEW MODE
    <Ripple
      onClick={handleClickToggleTag}
      onDoubleClick={() => {
        if (!isEditable) return;

        setEditingText(displayTermName);
        toggleEditing(true);
      }}
    >
      <div
        tw="flex items-center justify-around gap-x-1.5 border[1px solid] text-14 text-sonnant-dark py-1.5 pl-[6px] pr-[8px] rounded-full border-sonnant-grey-light cursor-pointer transition[all 200ms ease-in-out] h-[4rem]"
        css={[
          aliasTerm.isEnabled
            ? tw`text-sonnant-dark bg-[#FEF3CC] border[1px solid] border-color[#FCCF4D]`
            : tw`text-gray-800 bg-gray-100`,
          isHighlightTerm ? tw`bg-green-100 border-green-400 shadow` : tw`px-5`,
        ]}
      >
        {aliasTerm.isEnabled ? (
          <CheckSvg
            tw="w-[1.8rem] h-[1.8rem]"
            css={[isHighlightTerm ? tw`text-green-600` : tw`hidden`]}
          />
        ) : (
          <NoCheckSvg tw="w-[1.8rem] h-[1.6rem]" />
        )}

        <Hint
          text={`Alias of "${aliasTerm.original}"`}
          disabled={isEmpty(aliasTerm.alias)}
          enterDelay={200}
          leaveDelay={100}
          notTransparent
          arrow
        >
          <span css={[!isEmpty(aliasTerm?.alias) && tw`font-semibold`]}>
            {displayTermName}
          </span>
        </Hint>

        <Hint text="Remove term" enterDelay={300} arrow>
          <span
            tw="flex justify-center items-center rounded-full h-[2rem] w-[2rem] border[1px solid] border-sonnant-grey-light hover:bg-red-300"
            onClick={handleClickRemoveTag}
          >
            <CloseIcon tw="h-[0.8rem] w-[0.8rem]" />
          </span>
        </Hint>
      </div>
    </Ripple>
  );
};
