/* eslint-disable no-useless-escape */
/* eslint-disable no-mixed-operators */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import {
  isLocalMode,
  removeCollectionIdLocal,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { Option } from 'components/shared/CustomSelectSearch';
import { IMediaEntities } from 'components/shared/RichTextEditor/rich-text.model';
import jwt_decode from 'jwt-decode';
import { first, isEmpty, isNaN, isNil, toNumber } from 'lodash';
import { GroupBase, StylesConfig } from 'react-select';
import { CollectionService } from 'services/collection.service';
import { match } from 'ts-pattern';
import { VideoStatusText } from 'utils/enum';
import CloseSVG from '../components/UiControls/closeButtonSVG/closeSVG';
import { Layers, Social, SortField } from './enum';
import { CollectionSidebarItem, Insights } from './models';
import { REGEX } from './regex';
import { Routes } from './routes';
import { customToast } from './toast.util';

interface IToken {
  AccessToken: string | null;
  IdToken: string | null;
  RefreshToken: string | null;
}
export interface IFilterConditioner {
  isListView: boolean;
  page_number: number;
  page_size: number;
  sortBy: SortField;
  isAsc: boolean;
  isShowClipsOnly: boolean;
}
interface IAlertBlock {
  statusMessage: string;
  isSuccess: boolean;
  hideClose?: boolean;
  handleClose?: () => void;
}

export const validatePasswordField = (password: string) => {
  if (passwordCheck.test(password)) {
    return false;
  } else {
    return true;
  }
};

export const ValidityCheck = (...props: any) => {
  for (let i = 0; i < props.length; i++) {
    if (!props[i]) return true;
  }
  return false;
};
export const invalidEmail = (value: string) => {
  value = value?.trim();
  if (!value) return true;

  if (
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/.test(
      value,
    )
  ) {
    return false;
  }
  return true;
};

export const Subdomain = () => {
  let hostname = window.location.hostname;
  if (process.env.NODE_ENV === 'development') return 'rajeshwari';

  return hostname;
};

export const hasSubdomain = () => {
  let hostname = window.location.hostname.replace('www.', '').split('.');
  if (hostname.length > 2) return true;
  return false;
};

export const isLoggedIn = () => {
  const token = !!getToken();
  const expiresIn = getTokenExpiresIn();
  const leftTokenExpiresIn = expiresIn - Math.ceil(Date.now() / 1000);

  if (!token || leftTokenExpiresIn <= 1) {
    return false;
  }
  return true;
};

export const validateDomain = (value: string) => {
  if (isEmptyOrSpaces(value)) return false;

  let domainCheck = /^(?:(w)(?!\1{3})|[^w])+$/;

  if (domainCheck.test(value)) {
    if (value.match('www')) {
      return true;
    }
  }
  return false;
};

export const PasswordMatch = (first: string, second: string) =>
  first !== second;
export const setToken = (token: IToken) => {
  try {
    if (!token) return;

    localStorage.setItem('token', JSON.stringify(token));
  } catch (ex) {
    console.log('TOKEN catch', JSON.stringify(token));
    console.log('Failed decode token', ex);
  }
};
export const clearToken = () => {
  localStorage.removeItem('token');
};
export const setProfileImage = (profilePic: string) => {
  localStorage.setItem('profilePic', formateImageURL(profilePic));
};

export const getLocalProfilePic = () => {
  return localStorage.getItem('profilePic') || '';
};

export const formateImageURL = (imageURL: string) => {
  if (
    imageURL &&
    imageURL !== 'NULL' &&
    imageURL.match('data:image/png;base64')
  ) {
    return imageURL;
  } else if (imageURL?.startsWith('http')) {
    return imageURL;
  } else {
    return 'data:image/png;base64,' + imageURL;
  }
};

export const setRefreshToken = (r_token: string) => {
  if (!r_token) return;

  localStorage.setItem('r_token', r_token);
};

export const setUser = (user: any) => localStorage.setItem('user', user);
export const getToken = () => JSON.parse(localStorage.getItem('token') as any);
export const getIdToken = () => {
  const token = getToken();
  if (!token?.IdToken) return;

  return token.IdToken;
};
export const getRefreshToken = () => {
  const token = getToken();
  if (!token?.RefreshToken) return;

  return token.RefreshToken;
};
export const getUser = () => localStorage.getItem('user');

export const getTokenExpiresIn = () => {
  const token = getToken();
  if (!token?.AccessToken) return;
  const accessToken = token.AccessToken;

  try {
    const decodedToken = jwt_decode(accessToken) as any;
    if (!decodedToken?.exp) return;

    return decodedToken.exp;
  } catch (e) {
    console.log('Token decode failed :>> ', e);
    return;
  }
};
export const getJwtUserEmail = (): string | undefined => {
  const token = getToken();
  if (!token?.AccessToken) return;

  const accessToken = token.AccessToken;

  try {
    const decodedToken = jwt_decode(accessToken) as any;
    if (!decodedToken?.username) return;

    return decodedToken.username;
  } catch (e) {
    console.log('Token decode failed :>> ', e);
    return;
  }
};

export const isTokenAlive = () => {
  if (window.location.pathname.startsWith('/changepassword')) return true;

  const token = !!getToken();
  const expiresIn = getTokenExpiresIn();
  const leftTokenExpiresIn = expiresIn - Math.ceil(Date.now() / 1000);

  return token && leftTokenExpiresIn > 1;
};
export const removeToken = () => localStorage.removeItem('token');

export const clearSession = () => {
  // localStorage.clear();
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('profilePic');
  localStorage.removeItem('isLoggedIn');
  removeCollectionIdLocal();
};

export const setIsLoggedIn = () => localStorage.setItem('isLoggedIn', 1 as any);

export const baseURL = () => {
  let hostname = window.location.pathname;
  if (hostname.includes('tharunagarwal.com')) {
    return 'https://1ldk9z0514.execute-api.ap-south-1.amazonaws.com/Prod';
  }
  return 'https://rythmbs8e1.execute-api.ap-southeast-2.amazonaws.com/prod';
};

export const getLocalStorageFilter = (): IFilterConditioner => {
  return JSON.parse(localStorage.getItem('filterConditioner') as any);
};

export const setLocalStorageFilter = (
  filterConditioner: IFilterConditioner,
) => {
  localStorage.setItem('filterConditioner', JSON.stringify(filterConditioner));
};

export const validateUserName = (name: string) => {
  let alphaNumeric = /^[a-zA-Z0-9\-\s]+$/;
  if (!name || name.match(alphaNumeric)) {
    return name;
  } else {
    return name.slice(0, name.length - 1);
  }
};

export const formateName = (str: string) => {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
};

export const getUserRole = (): string | null => {
  let idToken = getIdToken();
  if (idToken) return (jwt_decode(idToken) as any)['custom:role'];
  else return null;
};

export const getSignUpProvider = () => {
  let idToken = getIdToken();
  if (idToken) return (jwt_decode(idToken) as any)['custom:provider'];
  else return false;
};

export const AlertBlock = (props: IAlertBlock) => {
  return (
    <p className={`topMessage ${props.isSuccess ? 'success' : 'error'}`}>
      {props?.statusMessage}
      {!props?.hideClose && (
        <CloseSVG name={'error'} close={props.handleClose} />
      )}
    </p>
  );
};

export const passwordCheck =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!`~^%&|}{+=*_\[|\])(.,?><;:'"-])[A-Za-z\d#$@^~`!%&|*}{+=_)(.,?;:\[|\]'"><-]{8,50}$/;
export const requiredErrorMsg = (fieldName: string) =>
  `${fieldName} is a mandatory field`;
export const inValidErrorMsg = `Your password should be at least eight characters long and must include at least: one uppercase, one lowercase, one number, one special character (e.g. !@#$%^&*...) Maximum of 50 characters.`;

export const noMatchErrorMsg = 'New password must match confirm password';
export const invalidEmailError = `This email address is considered invalid.`;

export const validatePassword = (
  name: any,
  value: any,
  data: any,
  errorSet: any,
) => {
  let confirmpassword = data.confirmpassword;
  let newpassword = data.newpassword;
  let errorObj = errorSet;
  let count = 0;
  let isPasswordSetValid = false;

  if (value.length) {
    errorObj[name] = true;
    if (name === 'newpassword') {
      if (passwordCheck.test(value)) {
        errorObj['isvalid'] = true;
      } else {
        errorObj['isvalid'] = false;
        errorObj['ismatch'] = true;
      }
    }
    if (
      name !== 'oldpassword' &&
      data.newpassword.length &&
      data.confirmpassword.length
    ) {
      if (!PasswordMatch(newpassword, confirmpassword)) {
        errorObj['ismatch'] = true;
      } else {
        errorObj['ismatch'] = false;
      }
      if (passwordCheck.test(data.newpassword)) {
        errorObj['isvalid'] = true;
      } else {
        errorObj['isvalid'] = false;
      }
    }
  } else {
    errorObj[name] = false;
  }
  for (let item in errorObj) {
    if (errorObj[item]) {
      count++;
    }
  }
  if (
    count === Object.keys(errorObj).length &&
    confirmpassword &&
    newpassword
  ) {
    isPasswordSetValid = true;
    return { data: errorObj, isPasswordSetValid };
  }
  isPasswordSetValid = false;
  return { data: errorObj, isPasswordSetValid };
};

export const validateEmail = (email: string) => {
  let emailRegExp =
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
  if (email.length) {
    if (emailRegExp.test(email)) {
      return null;
    } else {
      return 'invalid';
    }
  } else {
    return 'required';
  }
};

export function parseMilliseconds(milliseconds: number) {
  if (typeof milliseconds !== 'number') {
    throw new TypeError('Expected a number');
  }

  return {
    days: Math.trunc(milliseconds / 86400000),
    hours: Math.trunc(milliseconds / 3600000) % 24,
    minutes: Math.trunc(milliseconds / 60000) % 60,
    seconds: Math.trunc(milliseconds / 1000) % 60,
    milliseconds: Math.trunc(milliseconds) % 1000,
    microseconds: Math.trunc(milliseconds * 1000) % 1000,
    nanoseconds: Math.trunc(milliseconds * 1e6) % 1000,
  };
}

export function filenameFormatter(value: any) {
  if (!value) return '';

  let title = value.split(/\.(?=[^\.]+$)/);
  return title[0];
}

export function isEmptyOrSpaces(str: string) {
  return str == null || str.match(/^ *$/) !== null;
}

export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const getEmailErrorMessage = (email: string) => {
  if (isEmptyOrSpaces(email)) {
    return 'Email Address is a mandatory field';
  }
  return invalidEmail(email) ? 'This email address is considered invalid.' : '';
};

export const removeHTMLTag = (input = '') => {
  if (!input) return input;

  return removeMultipleSpaces(input.toString()?.replace(/<[^>]*>?/gm, ''));
};

export const removeSpaceChars = (input = '') => {
  if (!input) return input;

  return input.replace(/\s/gm, ' ');
};

export const removeSpace = (input = '') => {
  if (!input) return input;

  return input.replace(/\s/gm, '');
};

export const removeMultipleSpaces = (input = '') => {
  if (!input) return input;

  // return input.replace(/\s+[\.,']?/gm, ' ').replaceAll("\\s+(?=[),])", "");
  return input.replace(/\s+[\.,']?/gm, ' ');
};

export const removeEmptyArrayOfStrings = (inputArray: any) => {
  if (!inputArray) return;
  return inputArray
    ?.filter((item: any) => item)
    ?.filter((item: any) => item.match(REGEX.SPLIT_WORD));
};

export const waitAsync = async (milliseconds: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      // @ts-ignore
      return resolve();
    }, milliseconds);
  });
};

export const isNonEmptyArrayOfStrings = (value: any) => {
  return (
    Array.isArray(value) &&
    value.length &&
    value.every((item) => typeof item === 'string')
  );
};

export const isVBHistory = (history: any) => {
  return history?.length === 1 && history.every((h: any) => h?.type === 'VB');
};

export const isInputNumber = (value: any) => {
  const reg = /^\d+$/;
  return reg.test(value);
};

export const isBlank = (input: any) => {
  return !input?.trim() || input === 'NULL';
};

export const getTopicLayerName = (layerEnum: any) => {
  switch (layerEnum) {
    case Layers.ORG:
      return 'Organisations';
    case Layers.PEOPLE:
      return 'People';
    case Layers.LOCATION:
      return 'Locations';
    case Layers.PRODUCT:
      return 'Products';
    case Layers.NATIONALITY:
      return 'Nationalities';
    case Layers.IAB:
      return 'IAB Categories';
    case Layers.TRANSCRIPT:
      return 'Transcripts';
    case Layers.GARM_REPORT:
      return 'Sensitive Topics';
    default:
      return 'Key terms';
  }
};

export const getLayerFromKeyInsights = (key: keyof Insights): Layers => {
  return match(key)
    .with('keywords', () => Layers.KEY_TERM)
    .with('locations', () => Layers.LOCATION)
    .with('nationalities', () => Layers.NATIONALITY)
    .with('orgs', () => Layers.ORG)
    .with('people', () => Layers.PEOPLE)
    .with('products', () => Layers.PRODUCT)
    .with('iab', () => Layers.IAB)
    .with('speakers', () => Layers.SPEAKER)
    .with('sensitive', () => Layers.GARM_REPORT)
    .with('transcripts', () => Layers.TRANSCRIPT)
    .otherwise(() => Layers.KEY_TERM);
};

export const getLayerName = (layerEnum: any) => {
  return match(layerEnum)
    .with(Layers.KEY_TERM, () => 'Key terms')
    .with(Layers.CUSTOM_TERM, () => 'Mentions report')
    .with(Layers.SUMMARY, () => 'Summary')
    .with(Layers.IAB, () => 'IAB categories')
    .with(Layers.CHAPTER, () => 'Chapters')
    .with(Layers.ORG, () => 'Organisations')
    .with(Layers.PEOPLE, () => 'People')
    .with(Layers.LOCATION, () => 'Locations')
    .with(Layers.PRODUCT, () => 'Products')
    .with(Layers.NATIONALITY, () => 'Nationalities')
    .with(Layers.HUMAN_EDIT, () => 'Confidence')
    .with(Layers.AD_MARKER, () => 'Ad markers')
    .with(Layers.AD_BREAK, () => 'Ad breaks')
    .with(Layers.SEGMENT, () => 'Detected segments')
    .with(Layers.SPEAKER, () => 'Speakers')
    .with(Layers.GARM_REPORT, () => 'GARM Report')
    .with(Layers.TRANSCRIPT, () => 'Transcript')
    .otherwise(() => '');
};

export const getLayerEntity = (layerEnum: any): keyof IMediaEntities => {
  switch (layerEnum) {
    case Layers.ORG:
      return 'organisation';
    case Layers.PEOPLE:
      return 'person';
    case Layers.LOCATION:
      return 'location';
    case Layers.PRODUCT:
      return 'product';
    case Layers.NATIONALITY:
      return 'nationality';
    default:
      return '';
  }
};

export const getLayerAddMessage = (layerEnum: any) => {
  switch (layerEnum) {
    case Layers.KEY_TERM:
      return 'Key term';
    case Layers.CUSTOM_TERM:
      return 'Term';
    case Layers.ORG:
      return 'Organisation';
    case Layers.PEOPLE:
      return 'Person';
    case Layers.LOCATION:
      return 'Location';
    case Layers.PRODUCT:
      return 'Product';
    case Layers.NATIONALITY:
      return 'Nationality';
    case Layers.IAB:
      return 'Category';
    default:
      return '';
  }
};

export const getPlaceholderMessage = (layerEnum: any) => {
  if (layerEnum === Layers.CUSTOM_TERM) {
    return 'Add term to list (use comma for multiple items)';
  }

  return getLayerAddMessage(layerEnum);
};

export const hasTranscript = (layerEnum: any) => {
  return (
    [
      Layers.KEY_TERM,
      Layers.ORG,
      Layers.PEOPLE,
      Layers.LOCATION,
      Layers.PRODUCT,
      Layers.NATIONALITY,
      // NOT METADATA
      Layers.HUMAN_EDIT,
      // Layers.AD_MARKER,
      Layers.AD_BREAK,
      Layers.SEGMENT,
      Layers.IAB,
      Layers.SPEAKER,
    ].includes(layerEnum) || isNil(layerEnum)
  );
};

export const getLayerEnumList = () => {
  return [
    Layers.SUMMARY,
    Layers.IAB,
    Layers.CUSTOM_TERM,
    Layers.CHAPTER,
    Layers.AD_MARKER,
    Layers.AD_BREAK,
    Layers.SEGMENT,
    Layers.SPEAKER,
    Layers.KEY_TERM,
    Layers.PEOPLE,
    Layers.PRODUCT,
    Layers.ORG,
    Layers.LOCATION,
    Layers.NATIONALITY,
  ];
};

export const removeExtension = (value: any) => {
  if (!value) return '';

  let title = value.split(/\.(?=[^.]+$)/);
  return title[0];
};

export const difference = (after: any, before: any) => {
  const cloneBefore = [...before];
  const cloneAfter = [...after];

  after.forEach((wordAfter: any) => {
    const beforeIndex = cloneBefore.findIndex(
      (wordBefore) => wordBefore === wordAfter,
    );

    if (beforeIndex >= 0) {
      const afterIndex = cloneAfter.findIndex(
        (wordBefore) => wordBefore === wordAfter,
      );

      cloneBefore.splice(beforeIndex, 1);
      cloneAfter.splice(afterIndex, 1);
    }
  });

  return {
    wordAdded: cloneAfter,
    wordRemoved: cloneBefore,
  };
};

export const createHistoryTree = (undo = [], present = {}, redo = []) => {
  return {
    undo,
    present,
    redo,
  };
};

export const typeMediaEntities = (layer: Layers) => {
  switch (layer) {
    case Layers.KEY_TERM:
      return 'keywords';
    case Layers.LOCATION:
      return 'locations';
    case Layers.NATIONALITY:
      return 'nationalities';
    case Layers.ORG:
      return 'orgs';
    case Layers.PEOPLE:
      return 'people';
    case Layers.PRODUCT:
      return 'products';
    case Layers.IAB:
      return 'iab';
    case Layers.TRANSCRIPT:
      return 'transcripts';
    case Layers.GARM_REPORT:
      return 'sensitive';
    default:
      return 'transcripts';
  }
};

export const snakeCaseEmail = (email: string) => {
  return email.replace(/\W/gm, '_');
};

export const getTenantidFromIdToken = () => {
  let idToken = getIdToken();
  if (!idToken) return null;

  const cognitoUrl = (jwt_decode(idToken) as any)?.iss ?? '';
  return cognitoUrl.split('_').splice(-1)[0]?.toLowerCase() ?? '';
};

export const clearQueryString = (path: any) => {
  const search = window.location.search;
  return path.replace(search, '');
};

export const getFileName = (metadata: any) => {
  return isEmptyOrSpaces(metadata?.title) || metadata?.title === 'NULL'
    ? filenameFormatter(metadata?.filename)
    : metadata?.title;
};

export const toMoneyFormat = (rawMoney: any) => {
  const money = toNumber(rawMoney);
  if (isNaN(money)) return '$0';

  return money.toLocaleString('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const getDomainWithoutSubdomain = (url?: any) => {
  if (!url) {
    url = window.location.origin;
  }

  const path = new URL(url);
  if (path.hostname === 'localhost') {
    return path.origin;
  }

  const urlParts = path.hostname.split('.');

  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join('.');
};

export const getSubdomainFromURL = (
  url?: string | null,
): string | undefined => {
  if (!url) {
    url = window.location.href;
  }

  try {
    const path = new URL(url);
    if (path.hostname === 'localhost') {
      return path.hostname;
    }

    const urlParts = path.hostname.split('.');

    return urlParts?.[0];
  } catch {
    return undefined;
  }
};

export const addItemsToCollectionAsync = async ({
  collectionId,
  ids,
}: {
  collectionId: string;
  ids: string[];
}) => {
  const addItemsAsync = CollectionService.addItemsToCollection(
    collectionId,
    ids,
  );

  customToast.promise(addItemsAsync, {
    loading: 'Adding to collection',
    success: 'Added to collection',
    error: 'Failed to add items to collection',
  });

  try {
    await addItemsAsync;
  } catch (err: any) {
    console.log('err :>> ', err);
  }
};

export const removeItemsFromCollectionAsync = async ({
  collectionId,
  ids,
}: {
  collectionId: string;
  ids: string[];
}) => {
  const removeItemsAsync = CollectionService.removeItemsFromCollection(
    collectionId,
    ids,
  );

  customToast.promise(removeItemsAsync, {
    loading: 'Removing items from collection',
    success: 'Item removed from collection',
    error: 'Failed to remove item from collection',
  });

  try {
    await removeItemsAsync;
  } catch (err: any) {
    console.log('err :>> ', err);
  }
};

export const hasExistedName = (
  collections: CollectionSidebarItem[],
  name: string,
): boolean => {
  const hasSameName = collections.some(
    (collection) => collection?.name === name,
  );

  // Collection name must be unique
  if (hasSameName) {
    customToast.error(
      <div>
        <b tw="word-break[break-word]">{name}</b> already existed. Please try
        another name.
      </div>,
    );
  }

  return hasSameName;
};

export const exportToastSuccess = () => {
  customToast.success(
    <div>
      <div>Submitted for background processing.</div>
      <div>It may take a few minutes to be ready.</div>
    </div>,
  );
};

export const statusOrders = [
  VideoStatusText.UPLOADING,
  VideoStatusText.TRANSCRIBING,
  VideoStatusText.AI_PROCESSING,
  VideoStatusText.PAYMENT_REQUIRED,
  VideoStatusText.THUMBNAIL_UPDATING,
  VideoStatusText.ERROR,
  VideoStatusText.EDITING,
  VideoStatusText.DRAFT,
  '',
];

export const lockedOrders = [
  VideoStatusText.TRANSCRIBING,
  VideoStatusText.EDITING,
  VideoStatusText.DRAFT,
  '',
  VideoStatusText.UPLOADING,
  VideoStatusText.AI_PROCESSING,
  VideoStatusText.PAYMENT_REQUIRED,
  VideoStatusText.THUMBNAIL_UPDATING,
  VideoStatusText.ERROR,
];

export const isValidSpeaker = ({
  speakerName,
  speakerList,
}: {
  speakerName: string;
  speakerList?: string[];
}): boolean => {
  if (isEmpty(speakerName)) {
    customToast.error('Speaker name cannot be empty.');
    return false;
  }

  const isWrongFormat = REGEX.SPEAKER_NUMBER.test(speakerName);

  if (isWrongFormat) {
    customToast.error(
      <div>
        Wrong format. Speaker name should be different from{' '}
        <b>Speaker [number]</b>
      </div>,
    );
    return false;
  }

  if (speakerList && !isEmpty(speakerList)) {
    const isExisted = speakerList.some(
      (speaker) => speaker?.trim().toLowerCase() === speakerName.toLowerCase(),
    );

    if (isExisted) {
      customToast.error(
        <div>
          <b>{speakerName}</b> already existed. Please try another name.
        </div>,
      );

      return false;
    }
  }

  // If all conditions passed, the speaker name get VALID
  return true;
};

export const getJsonV2Path = (rawLink: string | null | undefined) => {
  if (!rawLink?.trim()) return '';

  const fullPath = rawLink.split('?').slice(0, -1)?.[0] ?? '';

  return fullPath.substring(fullPath.indexOf('media/'));
};

export const isRefreshed = () => {
  return (
    (window.performance.navigation &&
      window.performance.navigation.type ===
        performance.navigation.TYPE_RELOAD) ||
    window.performance
      ?.getEntriesByType('navigation')
      ?.map((nav: any) => nav?.type)
      ?.includes('reload')
  );
};

export const isNavigatedFromTranscription = (): boolean => {
  try {
    const lastUrl = new URL(document.referrer);

    return lastUrl.pathname.startsWith(Routes.TRANSCRIPTION);
  } catch (error) {
    return false;
  }
};

export const replaceUrlNoReload = (newUrl: string) => {
  window.history.replaceState(null, '', newUrl);
};

export const easeInOutQuint = (t: number) => {
  return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
};

export const apexDonutColorsTheme: string[] = [
  '#FEB019',
  '#FF4560',
  '#775DD0',
  '#008FFB',
];

export const apexPrimaryColors = [...apexDonutColorsTheme, '#00E396'];

export const apexColorsTheme: string[] = [
  '#008FFB',
  '#FEB019',
  '#FF4560',
  '#775DD0',
  '#3F51B5',
  '#03A9F4',
  '#F9CE1D',
  '#FF9800',
  '#33B2DF',
  '#D4526E',
  '#A5978B',
  '#81D4FA',
  '#FD6A6A',
  '#2B908F',
  '#F9A3A4',
  '#FA4443',
  '#69D2E7',
  '#449DD1',
  '#F86624',
  '#EA3546',
  '#662E9B',
  '#D7263D',
  '#F46036',
  '#E2C044',
  '#662E9B',
  '#F86624',
  '#F9C80E',
  '#EA3546',
  '#5C4742',
  '#A5978B',
  '#8D5B4C',
  '#5A2A27',
  '#C4BBAF',
  '#A300D6',
  '#7D02EB',
  '#5653FE',
  '#2983FF',
  '#00B1F2',
];

export const apexDarkThemeColors = [
  '#FF4560',
  '#FF9800',
  '#775DD0',
  '#FD6A6A',
  '#5653FE',
  '#2E294E',
  '#5A2A27',
  '#546E7A',
  '#FEB019',
  '#03A9F4',
  '#A300D6',
  '#3F51B5',
  '#F9C80E',
  '#1B998B',
  '#A5978B',
];

export const isValidSearchBy = (searchBy: string): boolean => {
  return [
    'iab',
    'keywords',
    'locations',
    'nationalities',
    'orgs',
    'people',
    'products',
    'sensitive',
    'speakers',
  ].includes(searchBy);
};

export const getTagTrendingUrl = (trendingList: string[], tag: string) => {
  const social = first(trendingList);

  switch (social) {
    case Social.TWITTER:
      return `https://twitter.com/search?q=${encodeURIComponent(tag)}`;

    case Social.GOOGLE:
      return `https://trends.google.com/trends/explore?q=${encodeURIComponent(
        tag,
      )}`;

    default:
      return '';
  }
};

export const getTagTrendingTitle = (trendingList: string[]) => {
  const social = first(trendingList);

  switch (social) {
    case Social.TWITTER:
      return 'View on Twitter';
    case Social.GOOGLE:
      return 'View on Google Trends';

    default:
      return '';
  }
};

export const replaceEmbedLocalEnv = (rawEmbedCode: string): string => {
  if (!isLocalMode()) return rawEmbedCode;

  const protocol = window.location.protocol;
  const host = window.location.host.split('.').splice(-2).join('.');

  return rawEmbedCode.replace(
    /(https:\/\/sonnant.xyz)|(https:\/\/voice-intellect.com)/gm,
    `${protocol}//${host}`,
  );
};

export const covertSharedToEmbedUrl = (
  sharedUrl: string,
): string | undefined => {
  try {
    const embedUrlObj = new URL(sharedUrl);

    embedUrlObj.pathname = embedUrlObj.pathname.replace(
      Routes.PUBLIC_SHARED,
      Routes.EMBED,
    );

    return embedUrlObj.toString();
  } catch (error) {
    console.error('[ERROR] Failed to convert URL object:', sharedUrl);
    return undefined;
  }
};

export const hiddenNavbar = (): boolean => {
  return [Routes.SIGNUP, Routes.SIGNIN, Routes.EMBED].some((route) =>
    window.location.pathname.startsWith(route),
  );
};

export const selectDropdownStyles: StylesConfig<
  Option,
  false,
  GroupBase<Option>
> = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  input: (base) => ({
    ...base,
    width: '19rem',
    height: '3rem',
    paddingTop: '3px',
    position: 'relative',
    top: '-8px',
    fontSize: '1.4rem',
    pointerEvents: 'none',
  }),
  container: (base) => ({
    ...base,
    height: '4rem',
    width: '24.5rem',
    // zIndex: 1000, // Prevent overlapping the modal Integrations Tab
  }),
  menu: (base) => ({
    ...base,
    marginBottom: '4px',
    marginTop: '1px',
    width: '20rem',
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '340px',
  }),
  control: (base) => ({
    ...base,
    backgroundColor: '#f0f3f6',
    borderColor: 'transparent',
    cursor: 'pointer',
    marginRight: 0,
    height: '4rem',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    paddingLeft: '0.8rem',
    height: 'inherit',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: '38px',
  }),
  placeholder: (base) => ({
    ...base,
    color: 'black',
    // color: '#54566c',
    fontWeight: 'normal',
    fontSize: '14px',
    width: 'auto',
    height: '4rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    lineHeight: '36px',
    position: 'relative',
    top: '1px',
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    cursor: 'pointer',
    padding: 0,
    backgroundColor: isSelected || isFocused ? '#DEEBFF' : 'unset',
  }),
  noOptionsMessage: (base) => ({
    ...base,
    padding: '0.3rem',
    fontSize: '14px',
  }),
};
