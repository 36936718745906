/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactElement } from 'react';
import { Tooltip } from '@rmwc/tooltip';

interface Props {
  disabled?: boolean;
  className?: string;
  popupElem: ReactElement;
  children: ReactElement;
  enterDelay?: number;
  leaveDelay?: number;
  [type: string]: any;
}

export const HoverTooltip = ({
  disabled,
  className,
  popupElem,
  children,
  ...props
}: Props) => {
  return disabled === true ? (
    <>{children}</>
  ) : (
    <Tooltip
      className={className}
      content={popupElem}
      align="topRight"
      activateOn="hover"
      tw="cursor-pointer"
      showArrow
      enterDelay={props?.enterDelay || 500}
      leaveDelay={props?.leaveDelay || 200}
    >
      {children}
    </Tooltip>
  );
};
