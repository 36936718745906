/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/** @jsxImportSource @emotion/react */
import { DateRangeInput } from '@datepicker-react/styled';
import { css } from '@emotion/react';
import { ReactComponent as Export } from 'assets/Icons/Export.svg';
import { ReactComponent as PreviewSvg } from 'assets/Icons/find_white.svg';
import { ReactComponent as FilterSvg } from 'assets/Icons/filter.svg';
import Loader from 'components/loader/loader';
import { SearchInput } from 'components/shared/SearchInput';
import { SortTable } from 'components/shared/SortTable/SortTable';
import { debounce, isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import 'twin.macro';
import tw from 'twin.macro';
import { customToast } from 'utils/toast.util';
import { Info } from 'components/shared/Info';
import { Keys } from 'utils/enum';
import { SubdomainResponse } from 'utils/models';
import {
  AuthService,
  BillingService,
  TableRow,
  UsageReportResponse,
} from 'services';
import { resetReactRangeCss } from 'components/shared/twin.styles';

type ExcelRender = {
  isLoaded: boolean;
  rows: any;
};

const usageReportingStyle = {
  header: tw`text-2xl text-center p-5 bg-gray-200! font-black`,
  row: tw`text-xl p-8 break-words text-center`,
  icon: () => [tw`[> path]:fill[white]`],
  selectAccount: () => [
    tw`height[12rem] p-0 border[1px solid transparent]! overflow-y-auto
      [> option]:(cursor-pointer padding[2px 4px] rounded-sm)
    `,
  ],
  table: () => [
    tw`height[calc(100vh - 40rem)] min-height[26rem] overflow-y-auto md-down:overflow-x-auto scrollbar-width[thin]`,
  ],
};

export const UsageReporting = () => {
  const [excelData, setExcelData] = useState<ExcelRender>({
    isLoaded: false,
    rows: [] as TableRow[],
  });

  const [selectAccount, setSelectAccount] = useState<string | undefined>();
  const [searchTenantTerm, setSearchTenantTerm] = useState('');
  const [subdomainArray, setSubdomainArray] = useState<SubdomainResponse[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const allData = useRef<TableRow[]>([]);
  const [subDomain, setSubDomain] = useState<string>('');
  const [billingType, setBillingType] = useState<string>('all');

  const searchedTenants = subdomainArray.filter((tenant) =>
    tenant?.DomainName?.S.includes(searchTenantTerm?.trim().toLowerCase()),
  );

  const getDateDetail = (date: Date) => {
    return [date.getDate(), date.getMonth() + 1, date.getFullYear()];
  };

  const handlePreview = async () => {
    try {
      setIsLoading(true);
      if (dateRange?.startDate && dateRange?.endDate) {
        const report = await fetchUsageReportAsync();

        setExcelData({
          isLoaded: true,
          rows: report?.data ?? [],
        });
        allData.current = report?.data ?? [];

        resetFilter();
      }
    } catch (error) {
      console.log(error);
      customToast.error('Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const handleExport = async (domainName?: string) => {
    try {
      setIsLoading(true);
      customToast.loading('Exporting...');

      const report = await fetchUsageReportAsync(domainName);

      setIsLoading(false);
      window.open(report.url, '_self');
    } catch (error) {
      console.log(error);
      customToast.error('Something went wrong');
    } finally {
      toast.dismiss();
      setIsLoading(false);
    }
  };

  const fetchUsageReportAsync = async (
    domainName?: string,
  ): Promise<UsageReportResponse> => {
    const [startDay, startMonth, startYear] = getDateDetail(
      dateRange.startDate,
    );
    const [endDay, endMonth, endYear] = getDateDetail(dateRange.endDate);
    const response = await BillingService.getUsageReport({
      startDate: {
        day: startDay,
        month: startMonth,
        year: startYear,
      },
      endDate: {
        day: endDay,
        month: endMonth,
        year: endYear,
      },
      domainName: !isEmpty(domainName) ? domainName : undefined,
    });

    return response.data;
  };

  const resetFilter = () => {
    setSubDomain('');
    setBillingType('all');
  };

  const handleSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectAccount(e.target.value);
  };

  const handleFilter = (term?: string) => {
    const searchTerm = (term ?? subDomain)?.trim().toLowerCase();

    const filteredRows = allData.current.filter(
      (row: TableRow) =>
        row?.domain_name?.includes(searchTerm) &&
        (billingType === 'all'
          ? true
          : row?.plan.toLowerCase() === billingType),
    );
    setExcelData({
      ...excelData,
      rows: filteredRows,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await AuthService.getAllSubdomain();
        setSubdomainArray(response.data);
      } catch (error) {
        console.log(error);
        customToast.error('Something went wrong');
      }
    })();
  }, []);

  const handleInputDomainName = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
      handleFilter();
    }
    if (e.key === Keys.ESC) {
      setSubDomain('');
      handleFilter('');
    }
  };

  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    focusedInput: null,
  });

  return (
    <div>
      <div
        className="grid-container fluid"
        tw="overflow-y-auto height[calc(100vh - 13rem)]"
      >
        {isLoading && (
          <div className="loader__component">
            <Loader />
          </div>
        )}
        <div className="grid-x tenantProfile">
          <div className="cell large-6">
            <div className="tenantTitle">
              <h2>Usage Reporting</h2>
            </div>
          </div>
        </div>
        <div tw="flex items-center mb-6 flex-wrap">
          <div css={[customDateRangInputStyle]} tw="flex items-center mr-6">
            <div tw="font-medium text-18 mr-6">Date Range:</div>

            <span css={[resetReactRangeCss]}>
              <DateRangeInput
                onDatesChange={debounce((data) => {
                  setDateRange(data);
                }, 0)}
                onFocusChange={(focusedInput) => {
                  setDateRange({
                    ...dateRange,
                    focusedInput: focusedInput as any,
                  });
                }}
                startDate={dateRange.startDate} // Date or null
                endDate={dateRange.endDate} // Date or null
                focusedInput={dateRange.focusedInput} // START_DATE, END_DATE or null
                maxBookingDate={new Date()}
                displayFormat="dd/MM/yyyy"
              />
            </span>
            <button
              className="button large btn-primary"
              onClick={handlePreview}
              tw="mb-0 flex justify-center items-center height[3.6rem]! shadow ml-5"
              disabled={!dateRange?.startDate || !dateRange?.endDate}
            >
              <span tw="flex">
                <PreviewSvg tw="mr-3" />
              </span>
              Preview
            </button>
          </div>

          <div tw="flex-1 mr-6">
            <div tw="flex items-center">
              {excelData.isLoaded && (
                <div tw="flex items-center space-x-5">
                  <input
                    tw="outline-none placeholder:(text-14!)"
                    placeholder="Enter Domain Name"
                    value={subDomain}
                    onChange={(e) => setSubDomain(e.target.value)}
                    onKeyDown={handleInputDomainName}
                  />
                  <select
                    tw="height[40px] w-64 mt-3 mb-3 outline-none cursor-pointer border[2px solid transparent] shadow-sm"
                    onChange={(e) => setBillingType(e.target.value)}
                    css={[customSelect]}
                    value={billingType}
                  >
                    <option value="all">All</option>
                    <option value="trial">Trial</option>
                    <option value="payg">PAYG</option>
                    <option value="standard">Standard</option>
                    <option value="internal">Internal</option>
                    <option value="enterprise">Enterprise</option>
                  </select>
                  <button
                    onClick={() => handleFilter()}
                    className="button large btn-primary"
                    tw="mb-0 flex justify-center items-center height[3.6rem]! shadow"
                  >
                    <span tw="flex">
                      <FilterSvg tw="mr-2" />
                    </span>
                    Filter
                  </button>
                </div>
              )}
            </div>
          </div>
          <div>
            <button
              disabled={!excelData?.isLoaded}
              onClick={() => handleExport('')}
              className="button btn-primary large"
              tw="mb-0 flex justify-center items-center height[3.6rem]! shadow"
            >
              <span tw="flex">
                <Export tw="mr-3" css={usageReportingStyle.icon} />
              </span>
              Download
            </button>
          </div>
        </div>

        {excelData?.isLoaded && (
          <div css={[usageReportingStyle.table, tableStyle]}>
            <SortTable
              columns={
                [
                  {
                    Header: 'Customer Name',
                    accessor: 'customer_name',
                  },

                  {
                    Header: 'Domain name',
                    accessor: 'domain_name',
                  },
                  {
                    Header: 'Usage',
                    accessor: 'usage',
                  },
                  {
                    Header: 'Customer (Billing) Type',
                    accessor: 'plan',
                  },
                  {
                    Header: 'Customer Id',
                    accessor: 'customer_id',
                  },
                  {
                    Header: 'Subscription Id',
                    accessor: 'subscription_id',
                  },
                  {
                    Header: 'Subscription Item Id',
                    accessor: 'subscription_item_id',
                  },
                ] as any
              }
              data={excelData.rows as TableRow[]}
              isFixedTable
            >
              {({ original: row }) => {
                return (
                  <tr>
                    <td>{row?.customer_name}</td>
                    <td>{row?.domain_name}</td>
                    <td>{row?.usage}</td>
                    <td>{row?.plan}</td>
                    <td>{row?.customer_id}</td>
                    <td>{row?.subscription_id}</td>
                    <td>{row?.subscription_item_id}</td>
                  </tr>
                );
              }}
            </SortTable>
            {!excelData?.rows?.length && (
              <tr>
                <td
                  colSpan={7}
                  tw="text-sonnant-dark text-3xl font-semibold p-0! pl-3!"
                >
                  <Info text="No results found" />
                </td>
              </tr>
            )}
          </div>
        )}

        <div className="grid-x" tw="mt-12">
          <div className="cell large-6">
            <h3>Tenant name</h3>
            <div tw="w-4/5">
              <label tw="text-sonnant-blue font-medium font-size[1.5rem]! relative">
                <div tw="flex items-center justify-between">
                  <div tw="flex-1 flex justify-between items-center mb-3 mr-6">
                    <div>Select tenant name</div>
                    <div css={[customSearchInputStyle]}>
                      <SearchInput
                        placeholder="Search tenant name"
                        setTerm={(term) => {
                          setSelectAccount(undefined);
                          setSearchTenantTerm(term);
                        }}
                        autoFocus
                      />
                    </div>
                  </div>
                  <div tw="mb-3">
                    <button
                      disabled={
                        !selectAccount ||
                        !dateRange?.startDate ||
                        !dateRange?.endDate
                      }
                      onClick={() => handleExport(selectAccount)}
                      className="button btn-primary large"
                      tw="mb-0 flex justify-center items-center height[3.6rem]! shadow"
                    >
                      <span tw="flex">
                        <Export tw="mr-3" css={usageReportingStyle.icon} />
                      </span>
                      Download
                    </button>
                  </div>
                </div>
                <select
                  id="account"
                  name="account"
                  size={10}
                  css={usageReportingStyle.selectAccount}
                  onChange={handleSelected}
                  value={selectAccount}
                >
                  {searchedTenants?.map((item, i) => (
                    <option
                      key={i}
                      value={item?.DomainName?.S}
                      onClick={() => setSelectAccount(item?.DomainName?.S)}
                    >
                      {item?.DomainName?.S}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const customDateRangInputStyle = css`
  * {
    font-family: 'Roboto' !important;
    z-index: 10 !important;
  }

  input:not([type='checkbox']) {
    height: 36px;
    font-size: 14px;
    font-weight: normal;
    font-family: inherit;
    min-height: 36px;
    padding: 0 40px !important;
  }

  label {
    border: none;

    div {
      top: 12px;
    }
  }

  & > div > div:nth-of-type(2) {
    z-index: 10;
  }
`;

const customSearchInputStyle = css`
  input:not([type='checkbox']) {
    height: 36px !important;
    font-size: 14px !important;
    font-weight: normal;
    font-family: inherit;
  }

  .search-container button {
    height: 36px !important;
    width: 36px !important;
  }
`;

const tableStyle = css`
  th {
    :nth-of-type(1) {
      ${tw`w-1/6!`} ${usageReportingStyle.header}
    }
    :nth-of-type(2) {
      ${tw`w-1/3!`} ${usageReportingStyle.header}
    }
    :nth-of-type(3) {
      ${tw`w-1/12!`} ${usageReportingStyle.header}
    }

    :nth-of-type(4) {
      ${tw`w-1/3!`} ${usageReportingStyle.header}
    }
    :nth-of-type(5) {
      ${tw`w-1/6!`} ${usageReportingStyle.header}
    }
    :nth-of-type(6) {
      ${tw`w-1/3!`} ${usageReportingStyle.header}
    }
    :nth-of-type(7) {
      ${tw`w-1/6!`} ${usageReportingStyle.header}
    }
  }
  th {
    font-size: 14px;
    white-space: nowrap;
    cursor: pointer;
  }
  td {
    ${usageReportingStyle.row}
  }

  tbody tr {
    :hover {
      ${tw`bg-sonnant-grey-light cursor-pointer`}
    }
  }
`;

const customSelect = css`
  option {
    ${tw`cursor-pointer!`}
  }
`;
