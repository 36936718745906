/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { css } from '@emotion/react';
import { Row } from '@tanstack/react-table';
import { ReactComponent as ChevronSvg } from 'assets/Icons/chervon.svg';
import { ReactComponent as CopySvg } from 'assets/Icons/copy_url.svg';
import { ReactComponent as PublishSvg } from 'assets/Icons/movie_black_24dp.svg';
import { ReactComponent as BookmarkSvg } from 'assets/Icons/bookmark.svg';
import { Hint } from 'components/shared/Hint';
import { PrettyCheckbox } from 'components/shared/PrettyCheckbox';
import {
  lineCss,
  minimizedRowCss,
  roundedRippleHoverCss,
} from 'components/shared/twin.styles';
import { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useClickAway, useCopyToClipboard, useToggle } from 'react-use';
import { RootState } from 'reducers';
import { ChapterLayerData } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { msToTime, scrollToNearestNode } from '../../MediaUtilities';
import { debounce, isEmpty } from 'lodash';
import { Keys } from 'utils/enum';
interface IProps {
  itemRow: Row<ChapterLayerData>;
  selectedChapter: number[];
  handleCreateClip: (start: number, end: number, headline: string) => void;
  handleSelectChapter: (item: ChapterLayerData) => void;
  handleDoubleClickChapter: (item: ChapterLayerData) => void;
  handleChangeHeadline: (item: ChapterLayerData, newHeadline: string) => void;
}

export const ChapterRow = memo(function ({
  itemRow,
  selectedChapter,
  handleCreateClip,
  handleSelectChapter,
  handleDoubleClickChapter,
  handleChangeHeadline,
}: IProps) {
  const toggle = useSelector((state: RootState) => state.toggle);

  const [isMinimized, toggleMinimized] = useToggle(true);
  const [isHovering, toggleHovering] = useToggle(false);
  const [isEditing, toggleEditing] = useToggle(false);

  const inputRef = useRef<any>(null);

  const [, copyToClipboard] = useCopyToClipboard();

  const chapterItem = itemRow.original;
  const isSelected = selectedChapter.includes(chapterItem.startTime);

  const cells = itemRow.getVisibleCells();

  const handleCopyLink = () => {
    copyToClipboard(chapterItem.url);
    customToast.success('Copied to clipboard');
  };

  const handleClickRow = (isBypass = false) => {
    if (toggle.isEditMode && isBypass === false) return;

    scrollToNearestNode(chapterItem.startTime);
    window.Video.currentTime = chapterItem.startTime;
  };

  const handleEditing = () => {
    if (!toggle.isEditMode) return;

    toggleEditing();

    setTimeout(() => {
      inputRef.current.value = chapterItem.headline;
      inputRef.current.focus();
    }, 0);
  };

  const handleOnChangeHeadline = debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      handleChangeHeadline(chapterItem, value);
    },
    500,
  );

  const handleKeyDownHeadline = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ([Keys.ENTER, Keys.ESC, Keys.ENTER_NUMPAD].includes(e.key as Keys)) {
      toggleEditing(false);
    }

    if ([Keys.ENTER, Keys.ENTER_NUMPAD].includes(e.key as Keys)) {
      e.preventDefault();
      handleChangeHeadline(chapterItem, e.currentTarget.value);
    }
  };

  useClickAway(inputRef, () => {
    toggleEditing(false);
  });

  return (
    <tr
      css={[lineCss, minimizedRowCss(isMinimized)]}
      onMouseEnter={() => toggleHovering(true)}
      onMouseLeave={() => toggleHovering(false)}
      onClick={() => handleClickRow()}
    >
      {/* HIDE CHECKBOX */}
      <td
        tw="min-width[30px] max-width[40px]"
        key={cells[0]?.id}
        style={{ width: cells[0]?.column?.getSize(), position: 'relative' }}
      >
        {toggle.isEditMode && (isHovering || isSelected) && (
          <PrettyCheckbox
            checkboxCss={css`
              top: calc(50% - 0.9rem) !important;
            `}
            checked={isSelected}
            onChange={() => handleSelectChapter(chapterItem)}
          />
        )}
      </td>
      <td key={cells[1]?.id} style={{ width: cells[1]?.column?.getSize() }}>
        <span
          tw="flex items-center justify-center p-2 w-full rounded-full bg-sonnant-purple-2"
          onClick={() => handleClickRow(true)}
        >
          <BookmarkSvg tw="fill[#fff]" width={20} height={20} />
        </span>
      </td>
      <td
        key={cells[2]?.id}
        style={{ width: cells[2]?.column?.getSize(), textAlign: 'center' }}
        className="bottom"
        tabIndex={1}
        tw="min-width[52px]"
      >
        <div>{chapterItem.index}</div>
      </td>
      <td
        key={cells[3]?.id}
        style={{ minWidth: cells[3]?.column?.getSize(), textAlign: 'center' }}
        className="bottom"
        tabIndex={1}
        onDoubleClick={() => handleDoubleClickChapter(chapterItem)}
      >
        <Hint
          text="Double click to edit"
          enterDelay={200}
          leaveDelay={0}
          disabled={!toggle.isEditMode}
        >
          <span
            tw="px-1"
            css={[
              toggle.isEditMode &&
                tw`border[1px dashed] border-disabled rounded select-none`,
            ]}
          >
            {msToTime(chapterItem.startTime * 1000)}
          </span>
        </Hint>
      </td>
      <td
        key={cells[4]?.id}
        style={{ minWidth: cells[4]?.column?.getSize(), textAlign: 'center' }}
        onDoubleClick={handleEditing}
      >
        {isEditing ? (
          <input
            tw="max-w-[18rem] (shadow-sm border border-solid border-gray-600)!"
            onChange={handleOnChangeHeadline}
            onKeyDown={handleKeyDownHeadline}
            ref={inputRef}
          />
        ) : (
          <Hint
            text="Double click to edit"
            enterDelay={200}
            leaveDelay={0}
            disabled={!toggle.isEditMode}
          >
            <div
              css={[
                toggle.isEditMode &&
                  !isEmpty(chapterItem.headline) &&
                  tw`border[1px dashed] border-disabled rounded select-none`,
              ]}
              tw="min-width[18rem]"
            >
              {chapterItem.headline}
            </div>
          </Hint>
        )}
      </td>
      <td
        style={{ minWidth: cells[5]?.column?.getSize() }}
        key={cells[5]?.id}
        title={(!isMinimized && chapterItem.tags) || ''}
      >
        <div className="long-text">{chapterItem.tags}</div>
      </td>
      <td
        key={cells[6]?.id}
        style={{ minWidth: cells[6]?.column?.getSize() }}
        tabIndex={1}
        title={(!isMinimized && chapterItem.iabCategories) || ''}
      >
        <div className="long-text">{chapterItem.iabCategories}</div>
      </td>
      <td
        key={cells[7]?.id}
        style={{ minWidth: cells[7]?.column?.getSize() }}
        tabIndex={1}
      >
        <div
          className="long-text"
          title={(!isMinimized && chapterItem.transcript) || ''}
        >
          {chapterItem.transcript}
        </div>
      </td>
      <td
        key={cells[8]?.id}
        style={{ width: cells[8]?.column?.getSize(), textAlign: 'center' }}
        tabIndex={1}
        onClick={() =>
          handleCreateClip(
            chapterItem.startTime,
            chapterItem.endTime,
            chapterItem.headline,
          )
        }
      >
        <div key={cells[8]?.id} css={[roundedRippleHoverCss]}>
          <PublishSvg fill="#7f8090" width={24} height={24} />
        </div>
      </td>
      <td
        key={cells[9]?.id}
        style={{ width: cells[9]?.column?.getSize(), textAlign: 'center' }}
        tw="min-width[80px] cursor-pointer"
        tabIndex={1}
        className="bottom"
      >
        <Hint text="Copy item URL" enterDelay={100} leaveDelay={0}>
          <div key={cells[9]?.id} css={[roundedRippleHoverCss]}>
            <CopySvg onClick={handleCopyLink} width={20} height={20} />
          </div>
        </Hint>

        <Hint
          text={isMinimized ? 'Show more' : 'Show less'}
          enterDelay={100}
          leaveDelay={0}
        >
          <div
            css={[roundedRippleHoverCss]}
            onClick={(e) => {
              toggleMinimized();
            }}
          >
            <ChevronSvg
              tw="transition[all 0.3s ease-in-out]"
              css={[!isMinimized && tw`transform[rotate(180deg)]`]}
              width={16}
              height={16}
            />
          </div>
        </Hint>
      </td>
    </tr>
  );
});
