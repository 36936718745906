import { TrendingLevel } from './../../utils/enum';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw, { theme } from 'twin.macro';
import { isNaN, isNil } from 'lodash';
import { GroupBase, StylesConfig } from 'react-select';
import { SegmentType } from 'utils/models/transcript.model';

// const COLOR_HUMAN = '#211FCC';
const PURPLE_2 = '#5551FF';
const GREEN = '#19AFA7';
const WHITE = '#FFF';
const COLOR_WHITE_1 = '#E6E6EC';

export const AUDIO_THUMBNAIL =
  'https://sonnantthumbnails.s3-ap-southeast-2.amazonaws.com/audio/audio-thumbnail.jpg';
export const VIDEO_THUMBNAIL =
  'https://sonnantthumbnails.s3-ap-southeast-2.amazonaws.com/video/video-thumbnail.jpg';

export const humanEditStyles = css`
  ${tw`(text-sonnant-purple-3)`}
`;

export const audioThumbStyles = (thumbUrl?: string) => css`
  background: url(${thumbUrl || AUDIO_THUMBNAIL}) !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
`;

export const audioPlayerStyles = css`
  background: url(${AUDIO_THUMBNAIL}) !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
`;

export const numberNoArrow = css`
  ${tw`max-w-sm w-full text-right outline-none`}
  ${tw`(border[2px] border-transparent border-solid)!`}
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const customDateRangInputStyle = css`
  input:not([type='checkbox']) {
    height: 40px;
    font-size: 14px;
    font-weight: normal;
    font-family: inherit;
    min-height: 38px;
    padding: 0 40px !important;

    &::placeholder {
      font-weight: normal !important;
      font-size: 15px !important;
      color: #7f8090 !important;
    }
  }

  label {
    border: none;

    div {
      top: 14px;
    }
  }

  & > div > div:nth-of-type(2) {
    z-index: 10;
  }
`;

export const twBaseClickable = css`
  ${tw`
    hover:(bg-sonnant-purple-2 text-white)!
    active:(bg-sonnant-green)!
  `}
`;

export const maximizeCss = css`
  ${tw`word-break[break-word] min-width[185px] max-height[250px]!`};

  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const minimizeCss = css`
  ${tw`word-break[break-word] min-width[185px] max-height[120px]!`};

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const timecodeEditable = css`
  border: 1px dashed ${COLOR_WHITE_1};
  ${tw`rounded pl-0.5 -ml-0.5`}
`;

export const notClickableText = css`
  ${tw`(text-sonnant-grey-4 no-underline cursor-not-allowed pointer-events-none)!`}
`;

export const simpleMenuItem = css`
  ${tw`hover:(bg-sonnant-grey-light text-black) text-black padding-top[7px] padding-bottom[7px] px-6 block font-size[14px] cursor-pointer h-[36px]`}
`;

export const menuDivider = css`
  ${tw`border-color[rgb(236,236,236)] width[90%] m-auto`}
`;

export const simpleMenuDisabled = css`
  ${tw`text-disabled pointer-events-none! bg-transparent opacity-40`}
`;

export const userUncounted = css`
  td {
    ${tw`text-sonnant-purple-1`}
  }
`;

export const lineCss = css`
  ${tw`cursor-pointer`}
  td {
    max-width: unset !important;
    transition: all 0.3s ease-in;
  }
`;

export const minimizedRowCss = (isMinimized: boolean) => css`
  & td {
    ${tw`height[12rem]`}
  }
  & td > div.long-text {
    ${tw`transition[all 0.3s ease-in-out]`}

    ${isMinimized ? minimizeCss : maximizeCss}
  }
`;

export const textLink = css`
  ${tw`hover:(underline opacity-95 text-shadow[0 0 1px lightgrey]) color[inherit]! cursor-pointer font-bold`}
`;

export const hoverTextShadow = (color?: string) => css`
  &:hover {
    text-shadow: 0 0 1px ${color || 'lightgrey'};
  }
`;

export const textShadow = (color?: string) => css`
  text-shadow: 0 0 1px ${color || 'lightgrey'};
`;

export const playPauseStyle = css`
  max-width: 40px;
  max-height: 40px;
  border-radius: 50%;
  fill: white;
  cursor: pointer;
  ${tw`bg-sonnant-purple-2`}
`;

export const centerByTranslate = css`
  ${tw`relative top-1/2 left-1/2 transform[translate(-50%, -50%)]`}
`;

export const centerByFlex = css`
  ${tw`flex justify-center items-center`}
`;

export const thumbnailStyles = css`
  ${tw`absolute top-0 h-full w-full object-contain (bg-center bg-cover)!`}
`;

export const noneBorder = css`
  ${tw`(border-none outline-none)!`}
  &:hover, &:focus {
    ${tw`(border-none outline-none)!`}
  }
`;

export const purpleBorder = css`
  ${tw`(border-solid border-sonnant-purple-2 outline-none)!`}

  &:hover, &:focus {
    ${tw`(border-sonnant-purple-2 outline-none)!`}
  }
`;

export const normalButton = css`
  &:hover {
    ${tw`border-transparent!`}
  }
  &:active {
    ${tw`bg-sonnant-grey-3!`}
  }
`;

export const svgShadow = (dropShadow: number) => css`
  -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, ${dropShadow}));
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, ${dropShadow}));
`;

export const hoverDropShadow = (dropShadow: number) => css`
  &:hover {
    ${svgShadow(dropShadow)}
    ${tw`cursor-pointer`}
  }
`;

export const svgHoverShadow = (dropShadow: number) => css`
  -webkit-filter: drop-shadow(
    1px 1px 1px rgba(0, 0, 0, ${dropShadow})
  ) !important;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, ${dropShadow})) !important;
`;

export const greyFocus = css`
  &:active,
  &:focus {
    ${tw`bg-sonnant-grey-4!`}
  }
`;

export const highlightNodeCss = css`
  ${tw`my-0 pt-8 border[3px solid] border-sonnant-purple-2 background[rgb(240, 240, 255)]`}
`;

export const highlightNodeHoverCss = css`
  &:hover {
    ${tw`my-0 pt-8 border-none border-bottom[3px solid]! border-sonnant-purple-2! background[rgb(240, 240, 255)]`}
  }
`;

export const disableIconCss = css`
  cursor: pointer;
  a.button {
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export const iconStyles = css`
  ${tw`flex cursor-pointer rounded`}

  svg g {
    fill: #7f8090;
  }
`;

export const highlightSvgCss = css`
  .playBackPause {
    ${tw`bg-sonnant-purple-1!`}

    &:hover path {
      fill: #fff !important;
    }
  }

  .playBackPlay {
    path {
      fill: #9b99ff !important;
    }
  }
`;

export const transcriptButtonCss = css`
  &:hover {
    ${tw`(border[2px solid] border-sonnant-purple-1)!`}
    ${highlightSvgCss}
  }
  &:focus {
    ${tw`(bg-white border[2px solid inherit])!`}
  }
`;

export const splitterCss = css`
  position: relative;
  border: 1px solid lightgray;

  &#splitter-css {
    height: 0 !important;
  }

  &::before {
    content: '';
    position: absolute;
    top: -4px;
    right: calc(50% - 2rem);
    border: 1px solid grey;
    height: 3px;
    width: 2rem;
    border-left: none;
    border-right: none;
  }
`;

export const splitterVerticalCss = css`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 2rem);
    right: 1px;
    border: 1px solid grey;
    width: 3px;
    height: 2rem;
    border-top: none;
    border-bottom: none;
    /* border-left: none; */
  }
`;

export const plyrResetStyle = css`
  .plyr__control {
    border-radius: 3px;
  }

  .plyr__progress input {
    ${tw`(left[-7px])!`}
  }

  input,
  input:focus {
    /* ${tw`(bg-transparent border-none p-0 m-0 color[#00b3ff])!`} */
    ${tw`(bg-transparent border-none p-0 m-0 color[#5451F6])!`}
  }

  button:focus {
    ${tw`(bg-transparent)`}
  }
`;

export const arrowCss = css`
  path {
    ${tw`fill[#fff]`}
    ${svgShadow(0.2)}

    &:hover {
      ${svgShadow(0.7)}
    }
  }
`;

export const arrowActive = css`
  path {
    ${tw`fill[#b2b3bc]`}
  }
`;

export const customSelect = css`
  option {
    ${tw`cursor-pointer!`}
  }
`;

export const dropdownCss = css`
  ${tw`select-none`}
  > a {
    ${greyFocus}
  }
  li {
    ${tw`text-left pl-4 cursor-pointer`}
    ${greyFocus}

    a {
      ${tw`text-13`}
    }
  }
`;

export const navbarTabCss = css`
  ${tw`h-full mr-3!`}
  a {
    ${tw`(h-full flex items-center box-border px-3)!`}

    &:hover {
      ${tw`bg-sonnant-grey-1!`}
    }
  }
`;

export const navbarTabActiveCss = css`
  ${tw`(border-bottom[3px solid] border-sonnant-purple-2)!`}
`;

export const bgTrending = css`
  background: linear-gradient(180deg, #19afa7 0%, #5551ff 100%);
`;

export const bellCss = css`
  ${tw`(bg-none mr-5 hover:(bg-none) cursor-pointer)!`}

  &:hover {
    svg {
      ${tw`shadow-lg!`}
    }
    svg rect {
      stroke: ${PURPLE_2};
    }
    svg path {
      fill: ${PURPLE_2};
    }
  }

  &:active {
    svg rect {
      fill: ${GREEN};
      stroke: ${GREEN};
    }
    svg path {
      fill: ${WHITE};
    }
  }
`;

export const tagWrapper = css`
  ${tw`max-height[13.5rem] overflow-y-auto`}

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 3px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: unset !important;
  }

  .keywordBtn {
    ${tw`(min-width[unset] mb-2 mr-2)!`}
  }
`;

export const tagCss = css`
  ${tw`
    flex items-center justify-center
    mb-2 mr-2 whitespace-nowrap height[4rem]
    border[2px solid] border-sonnant-grey-3 hover:(border-sonnant-purple-2)
    rounded-md cursor-pointer py-2 px-5 font-medium text-sonnant-grey-6 hover:text-sonnant-grey-6 visited:text-sonnant-grey-6`}

  &:active {
    ${tw`bg-sonnant-green border-transparent! text-white shadow`}
  }
`;

export const trendingLevelColor = (level: string) => css`
  ${level === TrendingLevel.NONE && tw`text-sonnant-grey-4`}
  ${level === TrendingLevel.LOW && tw`text-sonnant-purple-1`}
  ${level === TrendingLevel.MEDIUM && tw`text-sonnant-purple-2`}
  ${level === TrendingLevel.HIGH && tw`text-sonnant-orange`}
  ${level === TrendingLevel.VIRAL && tw`text-sonnant-red`}
`;

export const iabConfidenceColor = (
  confidence: number | null | undefined,
  isManual?: boolean,
) => {
  if (isNil(confidence) || isNaN(confidence) || isManual) return tw``;

  if (confidence <= 0.5 && confidence > 0)
    return tw`bg-sonnant-iab-confidence-low`;

  if (confidence > 0.5 && confidence <= 0.94)
    return tw`bg-sonnant-iab-confidence-medium`;

  if (confidence >= 0.95 && confidence <= 1)
    return tw`bg-sonnant-iab-confidence-high`;

  return tw``;
};

export const disableButton = css`
  ${tw`
    background-color[#7f8090]
    opacity-40
    pointer-events[none]
  `}
`;

export const borderGradient = (bdWidth: string) => css`
  :not(:hover):before,
  :active:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -${bdWidth}; /* border width */
    border-radius: inherit;
    background: radial-gradient(circle, #ba2da4 0%, #5549f2 100%);
  }
`;

export const borderGradientBg = (bdWidth: string, bgColor: string) => css`
  &,
  &:hover,
  &:focus,
  &:active {
    border: double ${bdWidth} transparent !important;
    background-image: linear-gradient(${bgColor}, ${bgColor}),
      radial-gradient(circle, #ba2da4 0%, #5549f2 100%) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
  }
`;

export const resetReactRangeCss = css`
  div:first-of-type
    > div:nth-of-type(2)
    > div:first-of-type
    > div[display='block']:first-of-type {
    z-index: 11 !important;
  }

  button[data-testid='DatepickerClose']:hover,
  button[aria-label='Reset dates']:hover,
  button[data-testid='DatepickerNavButton']:hover {
    background-color: inherit !important;
    box-shadow: none !important;
  }
`;

export const topicTagCustomStyles: StylesConfig<any, false, GroupBase<any>> = {
  container: (base) => ({
    ...base,
    height: '4rem',
    width: '25rem',
    zIndex: 20,
  }),
  control: (base) => ({
    ...base,
    height: '4rem',
    padding: 0,
    backgroundColor: '#f0f3f6',
    borderColor: 'transparent',
  }),
  menu: (base) => ({
    ...base,
    marginBottom: '4px',
    marginTop: '3px',
  }),
  menuList: (base) => ({
    ...base,
    overflowX: 'hidden',
  }),
  input: (base) => ({
    ...base,
    height: '3rem',
    paddingTop: '3px',
    position: 'relative',
    top: '-8px',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    paddingLeft: '0.8rem',
    cursor: 'pointer',
  }),
  placeholder: (base) => ({
    ...base,
    color: 'rgb(51, 51, 51)',
    fontWeight: 'normal',
    fontSize: 15,
  }),
  option: (base) => ({
    ...base,
    cursor: 'pointer',
    color: 'rgb(51, 51, 51)',
    fontWeight: 'normal',
    padding: '3px 12px',
  }),
};

export const itemStyles = css`
  ${tw`flex justify-between items-center text-2xl font-medium opacity[.75] w-full cursor-pointer py-1 px-2 rounded`}
  &:hover {
    ${tw`bg-sonnant-grey-light`}
  }
`;

export const mediumTooltipCss = css`
  ${tw`font-size[1rem]`}

  .notification {
    ${tw`lg-up:width[60rem]! md-down:(width[40rem] break-words)`}
  }
  .notificationWrapper {
    ${tw`margin-top[1rem]!`}
  }
`;

export const greyScrollbar = css`
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    ${tw`bg-white`}
  }

  ::-webkit-scrollbar {
    ${tw`bg-sonnant-grey-light w-[0.8rem] h-[12rem]`}
  }

  ::-webkit-scrollbar-track-piece {
    ${tw`bg-white`}
  }

  ::-webkit-scrollbar-thumb {
    min-height: 50px;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    ${tw`bg-sonnant-grey-light`}
  }
`;

export const greyScrollbarVertical = css`
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    ${tw`bg-white`}
  }

  ::-webkit-scrollbar {
    ${tw`bg-sonnant-grey-light w-[0.6rem] h-[0.6rem]`}
  }

  ::-webkit-scrollbar-track-piece {
    ${tw`bg-white`}
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    ${tw`bg-sonnant-grey-light`}
  }
`;

export const roundedRippleHoverCss = css`
  ${tw`inline-flex items-center justify-center w-[3.3rem] h-[3.3rem] rounded-full transform transition-all cursor-pointer hover:bg-gray-200 active:bg-gray-300`}
`;

// For belonging wrapper only
export const scrollbarWidthCss = (width: number) => css`
  ::-webkit-scrollbar {
    width: ${width}px !important;
  }
`;

// For child elements as well
export const scrollbarWidthChildCss = (width: number) => css`
  & ::-webkit-scrollbar {
    width: ${width}px !important;
  }
`;

export const font = (size: string) => css`
  ${tw`font-size[size]`}
`;

export const disabledSubItemsCss = css`
  & > div {
    ${tw`cursor-not-allowed opacity[0.3] bg-white pointer-events-none`}
  }
`;

export const blurBg = (percent: number) => css`
  background: rgba(0, 0, 0, ${percent});
`;

const getColorSegment = (type: SegmentType): string => {
  switch (type) {
    case SegmentType.AD_BREAK:
      return theme`colors.sonnant-orange`;
    case SegmentType.TRAFFIC:
      return theme`colors.sonnant-green`;
    case SegmentType.MUSIC:
      return theme`colors.sonnant-purple.1`;
    case SegmentType.USER_DEFINED:
      return theme`colors.sonnant-red`;
    default:
      return theme`colors.sonnant-grey.6`;
  }
};

export const fillSegment = (type: SegmentType) => css`
  fill: ${getColorSegment(type)};
`;

export const bgSegment = (type: SegmentType) => css`
  background-color: ${getColorSegment(type)};
`;

export const disabledCss = css`
  ${tw`cursor-not-allowed opacity-[0.3] bg-white pointer-events-none`}
`;

export const lineClamp = (line: number) => css`
  ${tw`overflow-hidden display[-webkit-box] -webkit-box-orient[vertical]`}

  -webkit-line-clamp: ${line};
  line-clamp: ${line};
`;
