/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GroupBase, StylesConfig } from 'react-select';
import { useToggle } from 'react-use';
import { RootState } from 'reducers';

import { ReactComponent as NoneSelectionSvg } from 'assets/Icons/cross.svg';
import { Hint } from 'components/shared/Hint';
import { DemographicsType, StringEnum } from 'utils/enum';
import { appendOrDelete, len } from 'utils/generic.util';
import { DemographicsCategory, DemographicsFieldName } from 'utils/models';
import { selectDropdownStyles } from 'utils/utils';
import {
  CustomSelectSearch,
  DemographicsOption,
  Option,
} from '../../CustomSelectSearch';
import { SimpleCheckbox } from '../../SimpleCheckbox';

type Props = {
  category: DemographicsCategory;

  onSelectOptions: (
    fieldName: DemographicsFieldName,
    options: string[],
  ) => void;
};

export const DemographicsDropdown = ({ category, ...props }: Props) => {
  const demographics = useSelector((state: RootState) => state.demographics);

  const selectedValues = demographics.items[category.fieldName];

  const dropdownOptions: DemographicsOption[] = [
    {
      label: 'No selection',
      value: StringEnum.EMPTY,
      type: DemographicsType.NONE,
    },
    ...category.options,
  ];

  const [hovering, toggleHovering] = useToggle(false);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const shouldShowHintMessage =
    hovering && !isEmpty(selectedOptions) && len(selectedOptions) > 1;

  useEffect(() => {
    setSelectedOptions(selectedValues);
  }, [selectedValues]);

  const handleClickOption = (
    e: React.MouseEvent,
    option: DemographicsOption,
  ) => {
    e.stopPropagation();

    if (option.type === DemographicsType.NONE) {
      setSelectedOptions([]);
      return;
    }

    setSelectedOptions(appendOrDelete(selectedOptions, option.value));
  };

  const handleCloseDropdown = () => {
    props.onSelectOptions(category.fieldName, selectedOptions);

    toggleHovering(false);
  };

  const renderCustomOption = (option: DemographicsOption) => (
    <div
      key={option.value}
      tw="flex justify-start items-center flex-1 flex-row w-full [label]:(font-medium) pl-4 py-2"
      title={option.label}
      onClick={(e) => handleClickOption(e, option)}
    >
      {option.type === DemographicsType.NONE ? (
        <div tw="flex flex-1 flex-row justify-start items-center cursor-pointer space-x-3">
          <div tw="max-w-[2rem] flex">
            <NoneSelectionSvg />
          </div>
          <label>{option.label}</label>
        </div>
      ) : (
        <div tw="flex" onClick={(e) => e.preventDefault()}>
          <SimpleCheckbox
            checked={selectedOptions.includes(option.value)}
            onChange={() => {}}
            label={option.label}
          />
        </div>
      )}
    </div>
  );

  const getDropdownPlaceHolder = (): string => {
    if (isEmpty(selectedOptions)) return 'None selected';

    if (selectedOptions.length === 1) {
      const selectedOption = dropdownOptions.find(
        ({ value }) => value === selectedOptions[0],
      );

      return selectedOption?.label ?? '';
    }

    const validOptions = dropdownOptions.filter(({ value }) => !isEmpty(value));

    if (selectedOptions.length === validOptions.length) return 'All selected';

    return 'Multi-items selected';
  };

  const getDropdownHint = (): string => {
    return dropdownOptions
      .filter(({ value }) => selectedOptions.includes(value))
      .map(({ label }) => label)
      .join(', ');
  };

  return (
    <div>
      <label>
        <span tw="text-15">{category.category}</span>
      </label>

      <Hint
        open={shouldShowHintMessage}
        text={getDropdownHint()}
        enterDelay={200}
        leaveDelay={100}
      >
        <div
          onMouseOver={() => toggleHovering(true)}
          onMouseLeave={() => toggleHovering(false)}
          css={[
            isEmpty(selectedOptions) &&
              !hovering &&
              tw`outline[1px solid lightblue] rounded`,
          ]}
        >
          <CustomSelectSearch
            options={dropdownOptions as Option[]}
            formatOptionLabel={renderCustomOption}
            customStyles={customSelectStyles}
            canCreate={false}
            defaultValue={getDropdownPlaceHolder()}
            onMenuClose={handleCloseDropdown}
          />
        </div>
      </Hint>
    </div>
  );
};

const customSelectStyles: StylesConfig<any, false, GroupBase<any>> = {
  ...selectDropdownStyles,
  container: (base) => ({
    ...base,
    width: 'auto',
  }),
  menu: (base) => ({
    ...base,
    marginTop: '4px',
    width: '22.6rem',
  }),
};
