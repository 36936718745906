/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { toDateWithHours } from 'utils/date.util';
import { css } from '@emotion/react';
import { NotifyList, ShareSocialTrending } from 'utils/models';
import { ReactComponent as TrendIcon } from 'assets/Icons/trending.svg';
import { ReactComponent as ShareFacebook } from 'assets/Icons/ShareFacebook.svg';
import { ReactComponent as ShareLinkedin } from 'assets/Icons/ShareLinkedin.svg';
import { ReactComponent as ShareTwitter } from 'assets/Icons/x-twitter-logo.svg';
import { ReactComponent as CancelIcon } from 'assets/Icons/cancel.svg';
import { ReactComponent as ProcessIcon } from 'assets/Icons/process.svg';
import { ReactComponent as ErrorIcon } from 'assets/Icons/error.svg';
import { ReactComponent as CompleteIcon } from 'assets/Icons/complete.svg';
import { ReactComponent as WatchIcon } from 'assets/Icons/watchlist.svg';
import { Ripple } from '@rmwc/ripple';
import tw from 'twin.macro';
import { hoverDropShadow, svgShadow } from 'components/shared/twin.styles';
import { Hint } from 'components/shared/Hint';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteSingleNotify,
  setFocusNotification,
} from 'slices/notification.slice';
import { NotificationFilter, NotificationType, Social } from 'utils/enum';
import { useHistory, useLocation } from 'react-router';
import { resetPlayer } from 'slices/player.slice';
import {
  downloadMultipleFiles,
  getShareUrlAsync,
  hasExpiredURL,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { RootState } from 'reducers';
import { isEmpty, isEqual } from 'lodash';
import {
  NotiDeletePayload,
  NotificationService,
} from 'services/notification.service';
import { customToast } from 'utils/toast.util';
import { getTenantidFromIdToken } from 'utils/utils';
import { Routes } from 'utils/routes';
import { HintRestrictedPublish } from 'components/shared/HintRestrictedPublish';

type NotifyItemsProps = {
  item: NotifyList;
  onClose?: () => void;
  onOpenShareModal?: (shareItem: ShareSocialTrending) => void;
};

export const NotifyItem = ({ item, ...props }: NotifyItemsProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userProfile = useSelector((state: RootState) => state.userProfile);

  const isTrending = item.filter === NotificationFilter.TRENDING;
  const isProgress =
    item.filter === NotificationFilter.PROGRESS ||
    item.type === NotificationType.MENTION_PROCESSING;
  const isComplete = item.filter === NotificationFilter.COMPLETE;
  const isError = item.filter === NotificationFilter.ERROR;
  const isWatchList = item.filter === NotificationFilter.WATCH;

  const deleteSingle = async () => {
    dispatch(deleteSingleNotify(item.id));

    try {
      const payload: NotiDeletePayload = {
        ids: [item.id],
        isClearAll: false,
        tenantId: getTenantidFromIdToken(),
        filterType: [],
        email: userProfile?.email,
      };

      await NotificationService.deleteMultipleIds(payload);
    } catch (err: any) {
      console.log('err :>> ', err);
    }
  };

  const currentPath = useLocation().pathname;

  const handleClick = async (e: React.MouseEvent<HTMLElement>) => {
    if (isComplete) {
      if (item.type === NotificationType.MEDIA_COMPLETE) {
        if (isEqual(currentPath, `/transcription/${item.mediaid}`)) {
          props.onClose?.();
          return;
        } else {
          dispatch(resetPlayer());
          history.push(`/transcription/${item.mediaid}`);
        }
      } else if (item.type === NotificationType.CLIP_COMPLETE) {
        history.push(
          `/clips/${item.mediaid}?versioncount=${item?.versioncount ?? ''}`,
        );
        window.location.reload();
      } else if (item?.type === NotificationType.MENTION_DOWNLOAD) {
        // Trigger excel generation
        const report = item?.data;

        const downloadUrl = report?.is_combined
          ? report?.combined_url
          : report?.report_url;

        if (downloadUrl) {
          const isExpired = await hasExpiredURL(downloadUrl);
          if (isExpired) {
            customToast.error('Download link expired. Please try again.');
            return;
          }
          window.open(downloadUrl, '_self');
        } else {
          customToast.error(
            report?.is_combined
              ? 'No mention occurrences found. Please check and try again.'
              : 'Report failed to generate. Please check and try again.',
          );
        }
      } else if (item?.type === NotificationType.EXPORT_DOWNLOAD) {
        const report = item?.data;

        const downloadUrls: string[] = [
          report?.fileUrl,
          ...(report?.mediaUrl ?? []),
        ].filter(Boolean);

        if (!isEmpty(downloadUrls)) {
          downloadMultipleFiles({
            urls: downloadUrls,
            shouldCheckExpired: true,
          });
        } else {
          customToast.error('Something went wrong.');
        }
      } else if (item.type === NotificationType.GENERATED_TERM_COMPLETED) {
        history.push(Routes.INSIGHTS);

        return;
      }
      deleteSingle();
    } else if (isTrending) {
      history.push('/revive');
      dispatch(setFocusNotification(item));
    } else if (isWatchList) {
      const term = item?.data?.[0]?.terms[0];

      history.push(
        `/transcription/${item.mediaid}?search=${term?.name}&time=${term?.occurrences?.[0]?.s}`,
      );
    } else {
      if (item.type === NotificationType.MENTION_PROCESSING) {
        history.push(Routes.CREATED_REPORTS);
      }
      return;
    }

    props.onClose?.();
  };

  const handleShareSocial = async (social: Social) => {
    try {
      const shareURL = await getShareUrlAsync(item.mediaid);
      if (!shareURL) return;

      // PASS TO PARENT
      props?.onOpenShareModal?.({
        mediaid: item.mediaid,
        shareURL,
        shareId: shareURL?.split('-').splice(-1)[0],
        provider: social,
      } as ShareSocialTrending);
    } catch (err: any) {
      console.log('err :>> ', err);
      customToast.error('Something went wrong');
    }
  };

  const getCompleteMsg = () => {
    if (item?.type?.startsWith('clip/')) return 'Clip created';

    if (item?.type === NotificationType.MENTION_DOWNLOAD)
      return 'Download ready';

    if (item?.type === NotificationType.EXPORT_DOWNLOAD) return 'Export ready';

    return 'Processing completed';
  };

  const getNotiName = () => {
    switch (item.type) {
      case NotificationType.MENTION_DOWNLOAD:
      case NotificationType.MENTION_PROCESSING:
        return item?.data?.report_name ?? 'Multi-item Mentions Report';

      case NotificationType.EXPORT_DOWNLOAD:
        return item?.data?.report_name ?? 'Multi-item Export';

      case NotificationType.WATCH_LIST_DETECTED:
        return item?.fileName ?? 'Watch List term found';

      case NotificationType.INTEGRATE_OMNY_PROGRESSING:
      case NotificationType.INTEGRATE_OMNY_COMPLETED:
      case NotificationType.INTEGRATE_OMNY_ERROR:
        return 'Reprocessing collection';

      case NotificationType.INTEGRATE_MEGAPHONE_COMPLETED:
        return 'Megaphone integration completed';
      case NotificationType.INTEGRATE_MEGAPHONE_ERROR:
        return 'Error when integrating Megaphone';

      case NotificationType.GENERATED_TERM_PROGRESSING:
        return 'Word Cloud is being generated';
      case NotificationType.GENERATED_TERM_COMPLETED:
        return 'Word Cloud generation is completed';
      case NotificationType.GENERATED_TERM_ERROR:
        return 'Error when generating Word Cloud';

      default:
        return item.fileName || '[File name]';
    }
  };

  return (
    <Ripple onClick={handleClick}>
      <div tw="py-5 flex items-center justify-between cursor-pointer">
        <div tw="flex flex-1 mr-3">
          <div tw="mx-6 flex items-center margin-top[2px]">
            {isTrending && <TrendIcon />}
            {isProgress && <ProcessIcon />}
            {isComplete && <CompleteIcon />}
            {isError && <ErrorIcon />}
            {isWatchList && <WatchIcon />}
          </div>

          <div>
            <div tw="text-12 text-sonnant-grey-5 mb-2">
              {toDateWithHours(item.date, userProfile.location)}
            </div>
            <div
              tw="text-13 font-medium word-break[break-all]"
              className="line-clamp-1"
              title={item.fileName}
            >
              {getNotiName()}
            </div>
          </div>
        </div>

        {isError && (
          <div css={[tw`background[red] hover:(underline)`, buttonStatus]}>
            <div tw="text-center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://share.hsforms.com/1nmpq9BJkQCmJr4u7dnxiVg4db8k"
                tw="(text-white)!"
              >
                Contact Support
              </a>
            </div>
          </div>
        )}
        {isProgress && (
          <div css={[tw`bg-sonnant-orange`, buttonStatus]}>
            <div tw="margin[auto] text-center">Processing in progress</div>
          </div>
        )}
        {isComplete && (
          <div css={[tw`background-color[#19AFA7]`, buttonStatus]}>
            <div tw="margin[auto] text-center">{getCompleteMsg()}</div>
          </div>
        )}
        {isWatchList && (
          <div css={[tw`bg-sonnant-purple-2`, buttonStatus]}>
            <div tw="margin[auto] text-center">Watch list term found</div>
          </div>
        )}

        {isTrending && (
          <div tw="min-width[11rem] mt-3 flex justify-between items-center">
            <HintRestrictedPublish>
              <Hint text="Share to LinkedIn">
                <div
                  tw="text-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShareSocial(Social.LINKEDIN);
                  }}
                >
                  <ShareLinkedin
                    width={20}
                    height={20}
                    css={[svgShadow(0.1), hoverDropShadow(0.4)]}
                  />
                  <div css={socialIconCss}>LinkedIn</div>
                </div>
              </Hint>
            </HintRestrictedPublish>

            <HintRestrictedPublish>
              <Hint text="Share to Facebook">
                <div
                  tw="text-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShareSocial(Social.FACEBOOK);
                  }}
                >
                  <ShareFacebook
                    width={20}
                    height={20}
                    css={[svgShadow(0.1), hoverDropShadow(0.4)]}
                  />
                  <div css={socialIconCss}>Facebook</div>
                </div>
              </Hint>
            </HintRestrictedPublish>

            <HintRestrictedPublish>
              <Hint text="Share to Twitter">
                <div
                  tw="text-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShareSocial(Social.TWITTER);
                  }}
                >
                  <ShareTwitter
                    width={20}
                    height={20}
                    css={[svgShadow(0.1), hoverDropShadow(0.4)]}
                    tw="bg-black rounded-full p-1"
                  />
                  <div css={socialIconCss}>Twitter</div>
                </div>
              </Hint>
            </HintRestrictedPublish>
          </div>
        )}
        <Hint text="Clear item" enterDelay={200}>
          <div
            tw="flex items-center! justify-start height[50%] mx-4 cursor-pointer"
            css={isTrending && tw`-mt-2.5!`}
            onClick={(e) => {
              e.stopPropagation();
              deleteSingle();
            }}
          >
            <CancelIcon
              css={hoverDropShadow(0.2)}
              width={20}
              height={20}
              fill="red"
            />
          </div>
        </Hint>
      </div>
    </Ripple>
  );
};

const buttonStatus = css`
  color: white;
  font-weight: 500;
  height: 45px;
  align-items: center;
  display: flex;
  border-radius: 10px;
  padding: 1px;
  line-height: 16px;
  word-wrap: break-word;

  ${tw`width[10rem] mx-2 text-13 px-3 py-3`}
`;

const socialIconCss = css`
  ${tw`-mt-2.5 font-size[8px] text-sonnant-grey-4`}
`;
