/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { GroupNotify, ShareSocialTrending } from 'utils/models';
import { NotifyItem } from './NotifyItem';

interface NotifyDetailProps {
  list: GroupNotify;
  date: string;
  onClose?: () => void;
  onOpenShareModal?: (shareItem: ShareSocialTrending) => void;
}

export const NotifyDetail = ({ list, date, ...props }: NotifyDetailProps) => {
  return (
    <>
      <div tw="ml-5 mt-6 mb-2 text-sonnant-grey-5 font-size[11px]">
        {date.toUpperCase()}
      </div>

      {list.items
        .map((item) => (
          <NotifyItem
            key={item.id}
            item={item}
            onClose={props.onClose}
            onOpenShareModal={props.onOpenShareModal}
          />
        ))}

      <hr tw="background-color[#f0f0f0] height[1px] border[0] margin-left[14%] width[77%] my-0!" />
    </>
  );
};
