/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import 'twin.macro';
import tw from 'twin.macro';

import { CircularProgress } from '@rmwc/circular-progress';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { RootState } from 'reducers';
import { Hint } from './Hint';

interface IProps {
  percent: number;
  size?: number;
  setOpenUpload?: (isOpen: boolean) => unknown;
}

export const PercentProgress = ({
  percent = 0,
  size = 40,
  ...props
}: IProps) => {
  const timerRef = useRef<any>(null);

  const uppyStore = useSelector(
    (state: RootState) => (state.uppy as any).uppyStore,
  );

  const [isShow, toggleShow] = useToggle(false);

  useEffect(() => {
    const canShow =
      (percent > 0 && percent <= 100) || !isEmpty(uppyStore?.files);
    toggleShow(canShow);

    if (percent === 100 && isShow) {
      timerRef.current = setTimeout(() => {
        toggleShow(false);

        !isNil(timerRef?.current) && clearTimeout(timerRef.current);
      }, 10 * 1000); // 10s
    }
  }, [percent]);

  const handleClick = () => {
    if (typeof props.setOpenUpload === 'function') {
      props.setOpenUpload(true);
    }
  };

  return (
    <>
      {isShow && (
        <Hint text={percent === 100 ? 'Upload completed' : 'Upload progress'}>
          <div
            tw="flex relative cursor-pointer"
            css={processStyle}
            onClick={handleClick}
          >
            <div tw="absolute font-size[.85rem] font-bold top[50%] left[50%] transform[translate(-50%, -50%)]">
              {percent}%
            </div>
            <CircularProgress size={size} progress={percent / 100} tw="flex" />
          </div>
        </Hint>
      )}
    </>
  );
};

const processStyle = css`
  svg {
    circle {
      stroke-width: 0.4rem !important;
      stroke: #9b26af;
      position: relative;
    }
  }
  &::before {
    content: '';
    ${tw`absolute top[1px] left[1px] width[95%] height[95%] rounded-full border[4px solid] border-sonnant-grey-3`}
  }
`;
