/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useCountdown } from '../../hooks/useCountdown';
import { parseMilliseconds } from '../../utils/utils';
interface IProps {
  setTimeLeftTrigger: (e: number) => void;
}

export const CountDownComponent = ({ setTimeLeftTrigger }: IProps) => {
  const timeLeft = useCountdown((Date.now() + 30000) as any);
  const { seconds } = parseMilliseconds(timeLeft);

  useEffect(() => {
    setTimeLeftTrigger(timeLeft);
  }, [timeLeft]);

  return (
    <>
      <p>
        Your session is going to expire in: <b>{seconds}</b>{' '}
        {seconds <= 1 ? 'second' : 'seconds'}
      </p>
    </>
  );
};
