import React from 'react';
import './backdrop.scss';

interface IProps {
  show: boolean;
disable: boolean;
children: any;
clicked?: (e:React.MouseEvent) => void;
}

export const Backdrop = (props: IProps) =>
  props.show ? (
    <div
      className="Backdrop"
      style={{ zIndex: props.disable ? 101 : 100 }}
      onClick={props.clicked}
    >
      {props.children}
    </div>
  ) : null;


