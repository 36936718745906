/* eslint-disable react-hooks/exhaustive-deps */
import { handleSearchClick } from 'actions';
import { first, isEmpty, isNil } from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  toggleAdvancedFilter,
  setSearchValues,
  setSearchParams,
  setSearchField,
  resetCustomData,
} from 'slices/advanced-search.slice';
import { setSelectedCollectionId } from 'slices/collection.slice';
import { setPageNumber } from 'slices/pagination.slice';
import { toggleReloadLibrary } from 'slices/toggle.slice';
import { getSearchFieldByInsight } from 'utils/adapter.util';
import { AdvancedFilterType } from 'utils/enum';
import { Insights } from 'utils/models';
import { Routes } from 'utils/routes';
import { isValidSearchBy } from 'utils/utils';

type AdvancedParams = {
  searchTerms: string[];
  collectionId?: string | null;
  startDate?: Date | null | string;
  endDate?: Date | null | string;
  searchBy: keyof Insights;
};

export const useAdvancedAutofill = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const fn = useCallback((params: AdvancedParams) => {
    if (isEmpty(params.searchTerms)) return;

    if (isNil(params.collectionId) || isEmpty(params.collectionId)) {
      history.push(Routes.LIBRARY, {
        from: Routes.INSIGHTS,
      });
      dispatch(setSelectedCollectionId(null));
    } else {
      history.push(`${Routes.COLLECTIONS}/${params.collectionId}`);
      dispatch(setSelectedCollectionId(params.collectionId));
    }

    dispatch(toggleAdvancedFilter(true));

    if (!isNil(params.startDate)) {
      dispatch(
        setSearchParams({
          type: AdvancedFilterType.DATE,
          data: {
            startDate: params.startDate,
            endDate: params?.endDate || new Date(),
          },
          isCustomDate: true,
        }),
      );
    } else {
      dispatch(resetCustomData());
    }

    if (isValidSearchBy(params.searchBy)) {
      dispatch(setSearchValues(params.searchTerms));
      dispatch(setSearchField(getSearchFieldByInsight(params.searchBy)));
    } else {
      dispatch(handleSearchClick(first(params.searchTerms) as string));
    }

    dispatch(setPageNumber(1));
    dispatch(toggleReloadLibrary());
  }, []);

  return fn;
};
