import * as actionType from '../actions/types';
import { put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { MediaService } from 'services';
import { customToast } from 'utils/toast.util';

export const initPublicExportContentItem = function* (action: any) {
  try {
    const exportData: AxiosResponse = yield MediaService.publicExport({
      mediaid: action.mediaid,
      versioncount: action.versioncount,
      mediaItems: action.mediaItems,
      filename: action.filename,
      mp4: action.mp4,
      mp3: action.mp3,
      vtt: action.vtt,
      srt: action.srt,
      pdfTC: action.pdfTC,
      docxTC: action.docxTC,
      txtTC: action.txtTC,
      pdfSP: action.pdfSP,
      docxSP: action.docxSP,
      txtSP: action.txtSP,
      isBurnedIn: action?.isBurnedIn,
    });
    yield put({
      type: actionType.SET_PUBLIC_EXPORT_CONTENT_ITEM,
      data: exportData,
    });
  } catch (error: any) {
    customToast.error('Something went wrong');
    yield put({
      type: actionType.FAILED_PUBLIC_EXPORT_CONTENT_ITEM,
      payload: error.response.data,
    });
  }
};

export default function* WatchInitPublicExportContentItem() {
  yield takeLatest(
    actionType.INIT_PUBLIC_EXPORT_CONTENT_ITEM_WATCH,
    initPublicExportContentItem
  );
}
