 /* eslint-disable jsx-a11y/anchor-is-valid */
 /* eslint-disable react-hooks/exhaustive-deps */
 /* eslint-disable array-callback-return */
 /** @jsxImportSource @emotion/react */
 import $ from 'jquery';
 
 import React, { useEffect, useState } from 'react';
 import { useSelector } from 'react-redux';
 import { ReactComponent as Exportsvg } from '../../../../assets/Icons/Export.svg';
 import { ReactComponent as Replace } from '../../../../assets/Icons/Find_Replace.svg';
 import { ReactComponent as Layer } from '../../../../assets/Icons/Layer.svg';
 import { ReactComponent as Sharesvg } from '../../../../assets/Icons/Share.svg';
 import { ReactComponent as Event } from 'assets/Icons/Event.svg';
 
 import { LayerDropdown } from '../LayerDropdown/LayerDropdown';
 import { Modal } from '../../../UiControls/modal/modal';
 import './MediaToolkit.scss';
 import { useDispatch } from 'react-redux';
 import * as actionTypes from 'actions/types';
 import PublicExport from '../../../Export/PublicExport';
 import { SharePublishModal } from 'components/shared/modals/SharePublishModal';
 // @ts-ignore
 import * as Mousetrap from 'mousetrap';
 // @ts-ignore
 import addGlobalBinds from 'bind-mousetrap-global';
 // @ts-ignore
 import mousetrapPause from 'mousetrap-pause';
 import { isEmpty, isEqual, toNumber } from 'lodash';
 import { useHistory } from 'react-router-dom';
 import { Hint } from 'components/shared/Hint';
 import { toggleFinder } from 'slices/toggle.slice';
 import { disableIconCss } from 'components/shared/twin.styles';
 import { toggleParagraphMode } from 'slices/player.slice';
 import { VideoStatusCode } from 'utils/enum';
 import { isEmptySlateNode } from '../MediaUtilities';
 import { hasTranscript } from 'utils/utils';
 import { RootState } from 'reducers';
 
 interface IProps {
   shareOptions: IShareOptions;
   exportOptions: IExportOptions;
   editMode: boolean;
   jsonSrt: any;
   filename: string;
   PlayOrPauseVideo: () => void;
 }
 
 interface IExportOptions {
   allowMediaDownload: boolean;
   allowCaptionDownload: boolean;
   allowTranscriptDownload: boolean;
   haveMp3: boolean;
   haveMp4: boolean;
   isBurnedIn: boolean;
 }
 
 interface IShareOptions {
   hasKeyTerm?: boolean ;
   hasSummary?: boolean;
   hasPeople?: boolean;
   hasProducts?: boolean;
   hasOrgs?: boolean;
   hasLocations?: boolean;
   hasNationalities?: boolean;
   hasChapter?: boolean;
   hasGlobalList?: boolean;
   hasIAB?: boolean;
   hasAdMarkersLayer?: boolean;
   hasSpeakers?: boolean;
   hasAdBreaks?: boolean;
   mediaid?: string;
   filename?: string ;
   mpx?: string;
   versioncount?: string;
 }
 
 addGlobalBinds(Mousetrap);
 const MousetrapPauser = mousetrapPause(Mousetrap);
 
 const MediaToolkit = (props: IProps) => {
   const [openModal, handleModal] = useState(false);
   const [openExportModal, setOpenExportModal] = useState(false);
   const layerLength = useSelector(
     (state: RootState) => state.layers.layerLength,
   );
   const player = useSelector((state: RootState) => state.player);
   const media = useSelector((state: RootState) => state.media);
   const layers = useSelector((state: RootState) => state.layers);
   const history = useHistory();
   const dispatch = useDispatch();
   useEffect(() => {
     $(document).foundation();
   }, []);
   const handleModalClose = () => {
     handleModal(false);
   };
 
   Mousetrap.bindGlobal(['ctrl+space'], function () {
     props.PlayOrPauseVideo();
     return false;
   });
 
   const handleExportModalClosed = () => {
     dispatch({ type: actionTypes.UNSET_PUBLIC_EXPORT_CONTENT_ITEM });
     setOpenExportModal(false);
   };
 
   const canShowExport = () => {
     return (
       props.exportOptions.allowMediaDownload ||
       props.exportOptions.allowCaptionDownload
     );
   };
 
   const shouldPauseMousetrapArray = [openModal];
 
   useEffect(() => {
     const conditions = shouldPauseMousetrapArray;
 
     if (conditions.some((isShow) => isShow === true)) {
       MousetrapPauser.pause();
     } else if (conditions.every((isShow) => isShow === false)) {
       MousetrapPauser.unpause();
     }
   }, shouldPauseMousetrapArray);
 
   const isAIProcessing = isEqual(
     toNumber(media?.statuscode),
     VideoStatusCode.AI_PROCESSING,
   );
   const noTranscript = media?.metadata?.noTranscript;
 
   return (
     <div className="grid-container fluid media_toolkit_container">
       <div className="grid-x">
         <div className="cell large-5">
           <div
             className="contole_bnt_group"
             css={(isEmpty(media) || noTranscript) && disableIconCss}
           >
             <Hint text="Toggle format of transcript">
               <a
                 className={`button small ${
                   player.isParagraphMode && 'btnActiveBg'
                 } ${
                   (isEmpty(player?.paragraph) ||
                     isEmptySlateNode(player?.paragraph) ||
                     !hasTranscript(layers.currentLayer)) &&
                   'disabled'
                 }`}
                 onClick={() => {
                   if (isEmpty(player.paragraph)) {
                     return;
                   }
                   dispatch(toggleParagraphMode(null));
                   history.replace(
                     window.location.pathname +
                       `?mode=${
                         !player.isParagraphMode ? 'paragraph' : 'caption'
                       }`,
                   );
                 }}
               >
                 <Event /> <span>View</span>
               </a>
             </Hint>
             <Hint text="View extracted file data and information">
               <a
                 className={`button small ${layerLength < 1 && 'disabled'}`}
                 type="button"
                 data-toggle="layers-dropdown"
               >
                 <Layer /> <span>Layers</span>
               </a>
             </Hint>
             <LayerDropdown
               hasSummary={props.shareOptions.hasSummary}
               hasIAB={props.shareOptions.hasIAB}
               hasKeyTerm={props.shareOptions.hasKeyTerm}
               hasPeople={props.shareOptions.hasPeople}
               hasProducts={props.shareOptions.hasProducts}
               hasOrgs={props.shareOptions.hasOrgs}
               hasLocations={props.shareOptions.hasLocations}
               hasNationalities={props.shareOptions.hasNationalities}
               hasChapter={props.shareOptions.hasChapter}
               hasSpeakers={props.shareOptions?.hasSpeakers}
               // hasAdMarkers={props.shareOptions?.hasAdMarkersLayer}
               // hasGlobalList={props.shareOptions.hasGlobalList}
               hasGlobalList={false}
               hasAdMarkers={false}
             />
 
             <Hint text="Find">
               <a
                 className="button tiny"
                 onClick={() => dispatch(toggleFinder(null))}
               >
                 <Replace />
               </a>
             </Hint>
 
             <Hint text="Share this file and its clips to socials, web or public player">
               <a className="button small" onClick={() => handleModal(true)}>
                 <Sharesvg /> <span>Share</span>
               </a>
             </Hint>
 
             <Hint text="Export assets from this file">
               <a
                 className={`button small ${
                   (!canShowExport() || isAIProcessing) && 'disabled'
                 }`}
                 onClick={() => {
                   if (!canShowExport) return;
                   setOpenExportModal(true);
                 }}
               >
                 <Exportsvg /> <span>Export</span>
               </a>
             </Hint>
 
             {/* <a className="button tiny disabled">
               <Dots />
             </a> */}
           </div>
         </div>
       </div>
 
       <div className="public-share-social-modal">
         <SharePublishModal
           openModal={openModal}
           handleModalClose={handleModalClose}
           shareURL={window.location.href}
           mobile={true}
         />
       </div>
       <div>
         <Modal show={openExportModal} modalClosed={handleExportModalClosed}>
           <PublicExport
             exportOptions={props?.exportOptions}
             mediaid={props.shareOptions.mediaid}
             filename={props.shareOptions.filename}
             mpx={props.shareOptions.mpx}
             versioncount={props.shareOptions.versioncount}
             closeExportModal={handleExportModalClosed}
           />
         </Modal>
       </div>
     </div>
   );
 };
 
	 export default MediaToolkit;