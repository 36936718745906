/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'react-reflex/styles.css';
import 'twin.macro';
import tw from 'twin.macro';
import './Player.scss';

import axios, { AxiosError, AxiosResponse } from 'axios';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { ToastMessage } from 'components/shared/ToastMessage/ToastMessage';
import {
  greyScrollbar,
  scrollbarWidthCss,
  splitterCss,
  splitterVerticalCss,
} from 'components/shared/twin.styles';
import { Modal } from 'components/UiControls/modal/modal';
import { useReflex } from 'hooks/useReflex';
import $ from 'jquery';
import {
  differenceBy,
  get,
  has,
  isArray,
  isEmpty,
  isEqual,
  isEqualWith,
  isNil,
  toNumber,
  uniq,
} from 'lodash';
import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { useHistory, useParams } from 'react-router-dom';
import {
  useEffectOnce,
  useMeasure,
  useSearchParam,
  useToggle,
} from 'react-use';
import { RootState } from 'reducers';
import { Descendant, Editor } from 'slate';
import { setCurrentPath } from 'slices/caption.slice';
import { resetHistoryChapter, setChapterInitial } from 'slices/chapter.slice';
import { toggleInPageLogin } from 'slices/global.slice';
import {
  layerSelector,
  removeLayerFromLayerList,
  setCurrentLayer,
} from 'slices/layers.slice';
import {
  clearMediaUrl,
  resetMedia,
  setMediaItem,
  setNewMediaVtt,
  setWaveform,
} from 'slices/media.slice';
import { changeLoading } from 'slices/pagination.slice';
import {
  resetPlayer,
  setCaption,
  setEntitiesSlice,
  setKeywordsSlice,
  setLayerCustomTerms,
  setLoadCompleted,
  setParagraph,
  setSummary,
  syncCaptionAndParagraph,
} from 'slices/player.slice';
import {
  toggleEditMode,
  toggleLastManualSave,
  toggleWarning,
} from 'slices/toggle.slice';
import { HTTPStatus, Layers, ModalOptions, VideoStatusCode } from 'utils/enum';
import { IHistory, IMediaDetail } from 'utils/models';
import {
  IChapterRaw,
  IParagraphRaw,
  ISpeakerRaw,
  ITranscript,
} from 'utils/models/transcript.model';
import {
  normalizeTranscript,
  toRawPara,
  toSlateStructure,
} from 'utils/transcript.util';
import { getFileName, hasTranscript } from 'utils/utils';

import { ToolkitSkeleton } from 'components/shared/Skeleton/ToolkitSkeleton';
import { TranscriptSkeleton } from 'components/shared/Skeleton/TranscriptSkeleton';
import { MediaError } from 'components/shared/MediaError';
import { Beforeunload } from 'react-beforeunload';
import { LockEditingPayload, MediaService, TranscriptService } from 'services';
import { resetAdBreakItems, setAdBreak } from 'slices/ad-break.slice';
import { setMidRollsBySeconds } from 'slices/admarker.slice';
import { resetIABLists, setIABCategoriesS3 } from 'slices/iab.slice';
import { resetOriginalData, setOriginalData } from 'slices/original.slice';
import {
  setSpeakerIdentification,
  triggerSaveSpeakers,
} from 'slices/speaker-identification.slice';
import { toDateTz, toFormatTz } from 'utils/date.util';
import { REGEX } from 'utils/regex';
import { Routes } from 'utils/routes';
import { customToast } from 'utils/toast.util';
import { userEditingId } from '../../../../../App';
import { useAutoSave } from '../../../../../hooks/useAutoSave';
import useEventListener from '../../../../CustomHooks/UseEventListener';
import { HighlightPopper } from '../../../../HighlightPopper/HighlightPopper';
import Loader from '../../../../loader/loader';
import {
  formatRaw,
  isTranscriptionPage,
  msToSec,
  normalizeSummary,
  preprocessAdMarkers,
  processChapters,
  scrollToNode,
  toSpeakerIdentification,
  toSpeakerRaw,
  urlToPath,
} from '../../MediaUtilities';
import { AdBreakLayer } from '../AdBreakLayer/AdBreakLayer';
import { AdMarkerLayer } from '../AdMarkerLayer/AdMarkerLayer';
import { GarmReportLayer } from '../GarmReportLayer/GarmReportLayer';
import { IABLayer } from '../IABLayer/IABLayer';
import { MentionReportLayer } from '../MentionReportLayer/MentionReportLayer';
import { SummaryEditor } from '../Summary/SummaryEditor';
import { SummaryLayer } from '../Summary/SummaryLayer';
import { FindAndReplaceBar } from './components/FindAndReplaceBar';
import { LockingWarning } from './components/LockingWarning';
import { MediaControl } from './components/MediaControl';
import { ChapterLayer } from '../ChapterLayer/ChapterLayer';
import {
  resetMediaSegmentItems,
  setMediaSegments,
} from 'slices/media-segment.slice';
import { MediaSegmentLayer } from '../MediaSegmentLayer/MediaSegmentLayer';
import { match } from 'ts-pattern';

const CaptionEditor = lazy(() =>
  import('components/VideoPlayer/Caption/CaptionEditor').then(
    ({ CaptionEditor }) => ({ default: CaptionEditor }),
  ),
);
const ParagraphEditor = lazy(() =>
  import('components/VideoPlayer/Paragraph/ParagraphEditor').then(
    ({ ParagraphEditor }) => ({ default: ParagraphEditor }),
  ),
);

const LayerNavigator = lazy(() =>
  import('./components/LayerNavigator').then(({ LayerNavigator }) => ({
    default: LayerNavigator,
  })),
);
const MediaTimeTrack = lazy(() =>
  import('./components/MediaTimeTrack').then(({ MediaTimeTrack }) => ({
    default: MediaTimeTrack,
  })),
);
const MediaToolkit = lazy(() =>
  import('../../MediaToolkitBar/MediaToolkit').then(({ MediaToolkit }) => ({
    default: MediaToolkit,
  })),
);

export const Player = () => {
  const dispatch = useDispatch();

  const iabId = useSearchParam('iab-category');
  const mode = useSearchParam('mode');

  const layers = useSelector((state: RootState) => state.layers);
  const currentLayer = useSelector(layerSelector);
  const player = useSelector((state: RootState) => state.player);
  const media = useSelector((state: RootState) => state.media);
  const caption = useSelector((state: RootState) => state.caption);
  const chapterReducer = useSelector((state: RootState) => state.chapter);
  const toggle = useSelector((state: RootState) => state.toggle);
  const location = useSelector((state: RootState) => state.library.location);
  const iab = useSelector((state: RootState) => state.iab);
  const original = useSelector((state: RootState) => state.original.data);
  const adMarker = useSelector((state: RootState) => state.adMarker);
  const adBreaks = useSelector((state: RootState) => state.adBreaks);
  const mediaSegment = useSelector((state: RootState) => state.mediaSegment);
  const payment = useSelector((state: RootState) => state.payment);

  const speakerIdentification = useSelector(
    (state: RootState) => state.speakerIdentification,
  );

  const [isMediaLoading, updateIsMediaLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [mediaHistory, setMediaHistory] = useState<IHistory[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [warningEditing, setWarningEditing] = useState<any>({
    userNameEditing: '',
  });
  const [, setErrorEditing] = useState({ userNameEditing: '' });
  const [editingId] = useState(userEditingId);
  const [isEditCheck, setIsEditCheck] = useState(false);
  const [hideCurrentItem, setHideCurrentItem] = useState(false);
  const [isFetching, toggleFetching] = useToggle(false);

  const [isShowError, toggleShowError] = useToggle(false);
  const [errorMsg, setErrorMsg] = useState();
  const [errorStatus, setErrorStatus] = useState<HTTPStatus>();

  const history = useHistory<any>();

  const mediaRef = useRef<any>(null);

  const isLeftLayer =
    currentLayer.isAdMarker ||
    currentLayer.isCustomTerms ||
    currentLayer.isChapter;

  const { reflexRef, flexLeft, flexRight } = useReflex(isLeftLayer);

  const [verticalRef, { width: rightSectionWidth }] = useMeasure();

  let { id: mediaid }: any = useParams();

  const isAIProcessing = isEqual(
    toNumber(media?.statuscode),
    VideoStatusCode.AI_PROCESSING,
  );

  const currentMediaElement = mediaRef && mediaRef.current;

  // console.count('--- RENDER PLAYER');
  useEffect(() => {
    dispatch(clearMediaUrl());
  }, [mediaid]);

  useEffect(() => {
    if (!isNil(iabId)) {
      dispatch(setCurrentLayer(Layers.IAB));
    }
  }, [iabId]);

  useEffect(() => {
    if (isNil(layers.lastLayer) || isNil(layers.currentLayer)) return;

    const nonTranscriptLayers = [Layers.SUMMARY, Layers.IAB];
    if (
      !nonTranscriptLayers.includes(layers.lastLayer) &&
      nonTranscriptLayers.includes(layers.currentLayer)
    ) {
      console.log('TRIGGER SYNC TRANSCRIPT');
      dispatch(syncCaptionAndParagraph());
    }
  }, [layers.currentLayer]);

  useEffect(() => {
    history.block(function (nextPath): any {
      if (
        !isTranscriptionPage() ||
        nextPath?.pathname.startsWith('/transcription/') ||
        nextPath?.pathname === '/' ||
        player.isProcessingData
      )
        return true;
      if (hasChanges()) {
        return 'Changes you made may not be saved. Would you like to proceed?';
      }
    });
  }, []);

  useAutoSave({
    handleAutoSave: () => handleSaveTranscript(true),
    dependOn: [
      window?.Editor?.children,
      player?.caption,
      player?.rawParagraph,
      player?.keywords,
      player?.speakers,
      player?.layerCustomTerms,
      player?.paragraph,
      player?.entities,
      chapterReducer?.chapter,
      player,
      toggle?.isLastManualSave,
      media as any,
      editMode as any,
    ], // Prevent closure issue
    isEditMode: editMode,
  });

  useEffectOnce(() => {
    return () => {
      // reset children in editor
      if (!isEmpty(window?.Editor?.children)) {
        window.Editor.children = [];
      }

      setErrorMsg(undefined);
      setErrorStatus(undefined);

      dispatch(resetMedia());
      dispatch(resetPlayer());
      dispatch(setCurrentLayer(Layers.KEY_TERM));
      dispatch(setCurrentPath([]));
      dispatch(toggleWarning(false));
      dispatch(resetOriginalData());
      dispatch(resetIABLists());
      dispatch(resetAdBreakItems());
      dispatch(resetMediaSegmentItems());
    };
  });

  useEffect(() => {
    if (toggle.isEditMode !== editMode) {
      dispatch(toggleEditMode(editMode));
    }
  }, [editMode]);

  useEffect(() => {
    getMediaDetails();
  }, [mediaid]);

  const getMediaDetails = async () => {
    try {
      dispatch(setLoadCompleted(false));
      const { data: mediaResponse }: AxiosResponse<IMediaDetail> =
        await MediaService.getMediaDetail({ mediaid });

      patchResponseAPI(mediaResponse);
    } catch (err) {
      const response = (err as AxiosError).response;
      const statusCode = response?.status;

      match(statusCode)
        .with(HTTPStatus.NOT_FOUND, (code) => {
          setErrorStatus(code);
        })
        .with(HTTPStatus.RESTRICTED, (code) => {
          toggleShowError(true);
          setErrorMsg(response?.data);
          setErrorStatus(code);
        })
        .otherwise(() => toggleShowError(true));
    }
  };

  const patchResponseAPI = async (mediaData: IMediaDetail) => {
    dispatch(
      setMediaItem({
        ...mediaData,
        metadata: { ...media?.metadata, ...mediaData?.metadata },
        statuscode: mediaData?.statuscode ?? media?.statuscode,
      }),
    );

    const mHistory = mediaData?.history;
    setMediaHistory(mHistory);

    const jsonUrl = mediaData?.jsonfileurl;
    if (!jsonUrl) {
      console.log('EMPTY S3 URL');
      setErrorStatus(HTTPStatus.NOT_FOUND);
      return;
    }

    try {
      const { data: transcriptData }: AxiosResponse<ITranscript> =
        await axios.get(jsonUrl);
      // const s = performance.now();

      dispatchS3Data(transcriptData);
      dispatch(setOriginalData(transcriptData));

      // const e = performance.now();
      // console.log('PATCH :>> ', (e - s) / 1000);
    } catch (err: any) {
      if ([404].includes(err?.response?.status)) {
        setErrorStatus(HTTPStatus.NOT_FOUND);
      }
    } finally {
      dispatch(setLoadCompleted(true));
    }
  };

  const dispatchS3Data = (transcriptData: ITranscript) => {
    setTimeout(() => {
      dispatch(
        setCaption({
          captions: transcriptData.captions,
          rawPara: transcriptData.paragraphs,
          speakers: transcriptData?.speakers,
          isParagraphMode: mode === 'paragraph',
        }),
      );
    }, 0);

    // Ad breaks metadata layer
    dispatch(setAdBreak(transcriptData?.ad_breaks ?? null));

    if (isNil(transcriptData?.ad_breaks)) {
      console.log('No Ad breaks detected!');
      dispatch(removeLayerFromLayerList(Layers.AD_BREAK));
    }

    // Ad detected segments layer
    if (isArray(transcriptData?.detectedSegments)) {
      dispatch(setMediaSegments(transcriptData.detectedSegments));
    } else {
      console.log('No special segments detected!');
      dispatch(removeLayerFromLayerList(Layers.SEGMENT));
    }

    dispatch(setLayerCustomTerms(transcriptData?.customTerms ?? []));

    dispatch(setKeywordsSlice(transcriptData.keywords));
    dispatch(setEntitiesSlice(transcriptData.entities));
    dispatch(
      setChapterInitial(
        processChapters(transcriptData.chapters, transcriptData.captions),
      ),
    );
    dispatch(
      setSummary({
        summary: normalizeSummary(transcriptData.summary),
        mediaid,
        isOrig: true,
      }),
    );

    if (transcriptData?.waveform) {
      dispatch(setWaveform(transcriptData.waveform));
    }

    if (!isEmpty(transcriptData?.iabCategories)) {
      dispatch(setIABCategoriesS3(transcriptData.iabCategories));
    }

    if (!isNil(transcriptData?.monetisation)) {
      dispatch(
        setMidRollsBySeconds({
          ...transcriptData.monetisation,
          videoDuration: msToSec(transcriptData.length),
        }),
      );
    }

    dispatch(setLoadCompleted(true));
  };

  const playByShiftTab = () => {
    const previous: any = Editor.previous(window.Editor, {
      at: caption.currentPath,
    })?.[0];
    if (!previous) return;

    mediaRef.current.currentTime = previous.data?.start;
    scrollToNode(previous?.data?.start);
  };

  const playByTab = () => {
    const next: any = Editor.next(window.Editor, {
      at: caption.currentPath,
    })?.[0];
    if (!next) return;

    mediaRef.current.currentTime = next.data?.start;
    scrollToNode(next?.data?.start);
  };

  const getTranscriptObject = (
    isSaveTranscript?: boolean,
  ): ITranscript | undefined => {
    let captionTranscript: Descendant[] = [];
    let rawPara: IParagraphRaw[] = [];
    if (!window?.Editor?.children) return;

    if (player.isParagraphMode) {
      captionTranscript = toSlateStructure(
        window.Editor.children,
        toRawPara(player?.caption),
        'caption',
      );
      rawPara = toRawPara(window.Editor.children);
    } else {
      captionTranscript = window.Editor.children;
      const para = toSlateStructure(
        window.Editor.children,
        formatRaw(player?.rawParagraph),
        'paragraph',
      );
      rawPara = toRawPara(para);
      if (isSaveTranscript) {
        dispatch(setParagraph(para));
      }
    }

    const processedMonetisation = preprocessAdMarkers(
      adMarker?.midRolls ?? [],
      msToSec(media?.metadata?.length),
    );

    return {
      formatVersion: media?.metadata?.formatversion ?? '3.0.31',
      language: media?.metadata?.language ?? 'en-AU',
      companyName: 'Sonnant',
      mediaId: media?.metadata?.mediaid ?? '',
      length: media?.metadata?.length ?? '0',
      dateCreated: media?.metadata?.datecreated ?? new Date().toISOString(),
      captions: normalizeTranscript(captionTranscript),
      paragraphs: rawPara,
      chapters: chapterReducer.chapter,
      keywords: player.keywords,
      entities: player.entities,
      summary: player?.summary ?? '',
      speakers: player?.speakers ?? [],
      waveform: media?.waveform,
      customTerms: player?.layerCustomTerms ?? [],
      iabCategories: {
        lockedList: iab.lockedList ?? [],
        recommendedList: iab.recommendedList ?? [],
      },
      ad_breaks: adBreaks?.items,
      detectedSegments: mediaSegment.items,
      monetisation: processedMonetisation,
    };
  };

  const handleSaveTranscript = async (
    autosave = false,
    isRegenerateSummary = false,
  ) => {
    dispatch(toggleWarning(false));
    // RESET autosave timer
    dispatch(toggleLastManualSave(!autosave));
    if (!window?.Editor?.children) return;

    const bodyObject = getTranscriptObject(true);

    // Detect newly added speaker -> add to speaker.slice
    saveSpeakersChange(player?.speakers ?? []);

    if (bodyObject) {
      dispatch(setOriginalData(bodyObject));
    }

    try {
      setIsSaving(true);

      const { data: presignedS3 } = await TranscriptService.saveTranscript({
        media_id: mediaid,
        auto_save: autosave === true ? true : undefined,
      });

      await axios.put(presignedS3.url, bodyObject);

      // SYNC LATEST Transcripts and Metadata
      setTimeout(() => {
        dispatchS3Data(bodyObject as any);
        // dispatch(setOriginalData(bodyObject as any));
      }, 0);

      await TranscriptService.generateSubtitle(
        {
          media_id: mediaid,
          auto_save: autosave === true ? true : undefined,
          is_speaker_changed: hasSpeakerChanged(),
        },
        urlToPath(presignedS3.url),
      );

      // handle regenerate summary > save summary to current version
      if (isRegenerateSummary) {
        MediaService.getSummary({
          object_name: urlToPath(presignedS3.url),
        });
      }

      dispatch(changeLoading(true));
      if (isRegenerateSummary) {
        customToast.success(
          'Summary regeneration in progress. The new summary will be updated in few minutes.',
          'Saved',
        );
      } else if (!autosave) {
        customToast.success('Saved');
      } else {
        const time = toFormatTz(toDateTz(new Date(), location), 'p', location);
        toast.custom(
          (t) =>
            ToastMessage({
              title: 'Success',
              message: 'Auto save completed at ' + time,
              type: 'success',
              close: () => {
                toast.remove(t.id);
              },
            }),
          {
            position: 'bottom-right',
            duration: 5000 * 60, // 5 min
          },
        );
      }

      doAfterSavingTranscript(mediaid);
      setIsSaving(false);
      setHideCurrentItem(false); // change value for effect re-render
    } catch (error: any) {
      console.log('error :>> ', error);
      setIsSaving(false);
      $('.saveBtn').removeClass('btnActiveBg');
      if (
        [401, 403].includes(error?.response?.status) &&
        isTranscriptionPage()
      ) {
        dispatch(toggleInPageLogin(true));
      } else {
        if (autosave === false) {
          customToast.error('Save failed');
        } else {
          const time = toFormatTz(
            toDateTz(new Date(), location),
            'p',
            location,
          );
          toast.custom(
            (t) =>
              ToastMessage({
                title: 'Error',
                message: 'Auto save failed at ' + time,
                type: 'error',
                close: () => {
                  toast.remove(t.id);
                },
              }),
            {
              position: 'bottom-right',
              duration: Infinity,
            },
          );
        }
      }
    }
  };

  const hasSpeakerChanged = (): boolean => {
    const oldSpeakers =
      original?.captions?.map((cap: any) => cap?.data?.speaker) ?? [];
    const newSpeakers =
      window?.Editor?.children?.map((cap: any) => cap?.data?.speaker) ?? [];

    const hasChanged = !isEqual(oldSpeakers, newSpeakers);

    return hasChanged;
  };

  const doAfterSavingTranscript = async (mediaid: string) => {
    try {
      const mediaData: AxiosResponse<IMediaDetail> =
        await MediaService.getMediaDetail({ mediaid });

      const mHistory = mediaData?.data?.history;
      setMediaHistory(mHistory);

      dispatch(setNewMediaVtt(mediaData?.data?.subtitleurl));
    } catch (err: any) {
      console.log('err :>> ', err);
    }
  };

  const saveSpeakersChange = (currentSpeakers: ISpeakerRaw[]) => {
    const globalSpeakers = toSpeakerRaw(speakerIdentification.speakers);

    const differentSpeakers = differenceBy(
      currentSpeakers,
      globalSpeakers,
      'name',
    );

    const newSpeakersRaw: ISpeakerRaw[] = [];
    differentSpeakers.forEach((speaker) => {
      const isWrongFormat = REGEX.SPEAKER_NUMBER.test(speaker.name);

      if (!isWrongFormat) {
        newSpeakersRaw.push(speaker);
      }
    });

    const newSpeakerIdentification = uniq([
      ...speakerIdentification.speakers,
      ...toSpeakerIdentification(
        newSpeakersRaw,
        speakerIdentification.hasSpeakerIdentification,
      ),
    ]);

    dispatch(setSpeakerIdentification(newSpeakerIdentification));

    dispatch(
      triggerSaveSpeakers({
        newSpeakers: newSpeakerIdentification,
        runBackground: true,
      }),
    );
  };

  const lockEditing = async (
    isLock?: boolean,
    isForceEdit?: boolean,
    isSaved?: boolean,
  ) => {
    let params: LockEditingPayload = {
      mediaid: mediaid,
      isEditing: isLock,
      editingId: editingId,
    };
    if (isLock && isForceEdit) {
      params.isForceEdit = true;
    }
    if (isSaved) {
      params.isSaved = true;
    }

    toast.dismiss();

    try {
      const response = await MediaService.lockEditing(params);
      if (response.status === 200) {
        const { isOverridden, userName } = response.data;

        if (isOverridden && isLock) {
          setWarningEditing({ userNameEditing: userName });
          return false;
        } else if (isOverridden && !isLock) {
          setErrorEditing({ userNameEditing: userName });
          return false;
        } else if (!isOverridden && isLock) {
          setWarningEditing({});
        }
        return true;
      }
      return false;
    } catch (err: any) {
      if ([401, 403].includes(err?.response?.status) && isTranscriptionPage()) {
        dispatch(toggleInPageLogin(true));
      }
      return false;
    }
  };

  const confirmModalRef = useRef<any>(null);

  const confirmExitEditModal = async () => {
    if (editMode === true && hasChanges()) {
      const result = await confirmModalRef.current?.show();
      return result === ModalOptions.YES;
    }
    return true;
  };

  const handleEditMode = async () => {
    if (payment.isExpiredTrial) {
      customToast.trialExpired();
      return;
    }
    if (editMode === true && hasChanges()) {
      const result = await confirmModalRef.current?.show();
      if (result !== ModalOptions.YES) {
        return false;
      }
    }
    try {
      setIsEditCheck(true);
      const rsLocking = await lockEditing(!editMode, false);
      setIsEditCheck(false);

      if (!rsLocking) {
        return;
      }
    } catch (e: any) {
      setIsEditCheck(false);
      if ([401, 403].includes(e?.response?.statusCode)) {
        history.push('/signin');
      }
    }
    setEditMode((prev) => {
      if (!prev) {
        mediaRef.current?.pause();
        return true;
      }
      return false;
    });
    return true;
  };

  useEffect(() => {
    if (history.location?.state?.editMode === true) {
      handleEditMode();
    }
  }, []);

  const mediaStartHandler = () => updateIsMediaLoading(true);

  const mediaCanPlayHandler = () => updateIsMediaLoading(false);

  const pausePlayer = () => {
    mediaRef.current.pause();
  };

  // media history api implementation
  const getMediaHistory = async (data: any) => {
    const confirmed = await confirmExitEditModal();
    if (!confirmed) return;

    const param = {
      mediaid: data.mediaid,
      type: data.type,
      versioncount: data.versioncount,
    };

    try {
      pausePlayer();
      toggleFetching(true);

      dispatch(clearMediaUrl());
      dispatch(setLoadCompleted(false));
      const mediaData = (await MediaService.getHistory(param)).data;

      await patchResponseAPI(mediaData);
      dispatch(resetHistoryChapter());
    } catch (err: any) {
      if ([401, 403].includes(err?.response.status) && isTranscriptionPage()) {
        dispatch(toggleInPageLogin(true));
      } else {
        customToast.error('Load history Failed');
      }
    } finally {
      toggleFetching(false);
    }
  };

  // useEventListener('timeupdate', handleVideoTimeUpdate, currentMediaElement);
  useEventListener('loadstart', mediaStartHandler, currentMediaElement);
  useEventListener('loadedmetadata', mediaCanPlayHandler, currentMediaElement);

  const getVideoFlexRatio = () => {
    // Default height
    // if (!rightSectionWidth || isMediaLoading) return 400;
    const ratio = media?.metadata?.ratio;

    // HEIGHT by RATIO + 42px of control bar
    return rightSectionWidth * (ratio || 0.5625) + 42;
  };

  const hasChanges = () => {
    if (!toggle.isEditMode) return false;
    const currentData = getTranscriptObject();

    const objectKeys: (keyof ITranscript)[] = [
      'captions',
      'chapters',
      'customTerms',
      'entities',
      'iabCategories',
      'keywords',
      // 'paragraphs',
    ];

    return objectKeys.some((k) => {
      if (k === 'chapters') {
        const excludeId = (chapter: IChapterRaw) => ({
          startTime: chapter.startTime,
          endTime: chapter.endTime,
          headline: chapter.headline,
        });

        // EXCLUDE ID ON COMPARING
        const currentChapter = get(currentData, k)?.map(excludeId);
        const originalChapter = get(original, k)?.map(excludeId);

        return !isEqualWith(currentChapter, originalChapter);
      }
      if (has(original, k) && has(currentData, k)) {
        // check if key exist in both
        return !isEqual(get(currentData, k), get(original, k));
      }
      return false;
    });
  };

  const handleBackToLibrary = () => {
    history.push(Routes.LIBRARY);
  };

  const hasResponseErrors = errorStatus || isShowError;

  return (
    <>
      <Beforeunload
        onBeforeunload={(event) => {
          // Prevent exit page
          if (hasChanges() && !toggle.isShowExportModal) event.preventDefault();
        }}
      />
      {isFetching && (
        <div className="loader__component">
          <Loader />
        </div>
      )}

      <div
        className="player-container"
        tw="flex flex-col flex-1"
        id="main-container"
      >
        <Suspense fallback={<ToolkitSkeleton />}>
          <MediaToolkit
            playByShiftTab={playByShiftTab}
            playByTab={playByTab}
            updateTranscript={handleSaveTranscript}
            HandleEditMode={handleEditMode}
            editMode={editMode}
            isEditCheck={isEditCheck}
            filename={getFileName(media?.metadata)}
            mediaid={mediaid}
            mediaHistory={mediaHistory}
            getHistoryMedia={getMediaHistory}
            isSaving={isSaving}
          />
        </Suspense>

        {hasResponseErrors && (
          <MediaError
            statusCode={errorStatus}
            errorMsg={errorMsg}
            onClickReturn={handleBackToLibrary}
          />
        )}

        {!hasResponseErrors && (
          <div className="grid-x mediaPlayer mediaPlayer_grid_aign" tw="flex-1">
            <div className="cell large-auto">
              <div className="tabs-content" data-tabs-content="collapsing-tabs">
                <div
                  className="tabs-panel is-active"
                  id="transcription"
                  tw="p-0"
                >
                  <div className="grid-container fluid">
                    <div
                      className="grid-x grid-margin-x video_mgn"
                      tw="mt-0 pt-0!"
                      ref={reflexRef as any}
                    >
                      <ReflexContainer
                        orientation="vertical"
                        windowResizeAware={true}
                      >
                        <ReflexElement
                          className="left-pane"
                          flex={flexLeft.grow}
                          minSize={flexLeft.minWidth}
                          // maxSize={flexLeft.maxWidth}
                          css={[
                            tw`h-[calc(100vh - 21rem)]!`,
                            greyScrollbar,
                            scrollbarWidthCss(4),
                          ]}
                        >
                          {currentLayer.isAdMarker && <AdMarkerLayer />}
                          {currentLayer.isChapter && <ChapterLayer />}
                          {currentLayer.isCustomTerms && <MentionReportLayer />}

                          <div
                            tw="w-full! z-index[1]"
                            css={[
                              'transition: all 0.5s ease',
                              toggle.isShowReplaceModal
                                ? tw`static!`
                                : tw`sticky! top-0!`, // absolute for iPad
                            ]}
                          >
                            <FindAndReplaceBar
                              enableEditMode={() => handleEditMode()}
                            />
                          </div>

                          <div>
                            {hasTranscript(layers.currentLayer) && (
                              <div
                                tw="height[calc(100vh - 21rem)] overflow-y-auto"
                                css={[greyScrollbar]}
                              >
                                <HighlightPopper
                                  filename={getFileName(media?.metadata)}
                                  mediaid={mediaid}
                                  editMode={editMode}
                                  disabled={isAIProcessing}
                                >
                                  {!player.isParagraphMode &&
                                    !isEmpty(player?.caption) && (
                                      <Suspense
                                        fallback={
                                          <div>
                                            <TranscriptSkeleton count={10} />
                                          </div>
                                        }
                                      >
                                        <CaptionEditor />
                                      </Suspense>
                                    )}

                                  {player.isParagraphMode &&
                                    !isEmpty(player.paragraph) && (
                                      <Suspense
                                        fallback={
                                          <div>
                                            <TranscriptSkeleton count={10} />
                                          </div>
                                        }
                                      >
                                        <ParagraphEditor />
                                      </Suspense>
                                    )}

                                  {player.isProcessingData &&
                                    !isLeftLayer &&
                                    !currentLayer.isSummary && (
                                      <TranscriptSkeleton
                                        count={20}
                                        tw="relative"
                                      />
                                    )}

                                  {!player.isProcessingData &&
                                    isArray(player?.caption) &&
                                    player.caption.length === 0 && (
                                      <div tw="font-medium text-sonnant-grey-6 text-16 mt-5">
                                        There are no transcripts for this
                                        content item
                                      </div>
                                    )}
                                </HighlightPopper>
                              </div>
                            )}

                            {/* SUMMARY METADATA LAYER */}
                            {currentLayer.isSummary &&
                              !isEmpty(player?.caption) &&
                              media && <SummaryEditor baseMediaid={mediaid} />}

                            {currentLayer.isGarmReport && <GarmReportLayer />}
                          </div>
                        </ReflexElement>

                        <ReflexSplitter
                          tw="(height[calc(100vh - 20rem)] border-2 border-white bg-transparent z-index[unset] border-r-0 border-width[3.5px] hover:border-sonnant-purple-2 active:border-sonnant-purple-2)!"
                          css={splitterVerticalCss}
                        />

                        <ReflexElement
                          className="right-pane"
                          tw="overflow-visible!"
                          flex={flexRight.grow}
                          style={{ maxWidth: flexRight.maxWidth }}
                          // minSize={flexRight.minWidth}
                          // maxSize={flexRight.maxWidth}
                        >
                          <div
                            className="video-container"
                            tw="flex flex-col mt-2 pl-2 w-full!"
                            ref={verticalRef as any}
                          >
                            <ReflexContainer>
                              <ReflexElement
                                // 42 is height of control bar
                                minSize={
                                  isMediaLoading
                                    ? 350
                                    : toggle.isMinimise
                                    ? 42
                                    : 100
                                }
                                maxSize={
                                  !isMediaLoading && toggle.isMinimise
                                    ? 42
                                    : undefined
                                }
                                size={
                                  toggle.isMinimise ? 42 : getVideoFlexRatio()
                                }
                              >
                                <MediaControl
                                  ref={mediaRef}
                                  // isMediaLoading={isMediaLoading}
                                  isMediaLoading={false}
                                />
                              </ReflexElement>

                              <ReflexSplitter
                                tw="(my-1 border-white bg-transparent z-index[unset] margin-top[3px] rounded-lg border-width[4px] border-bottom[none] hover:(border-sonnant-purple-2 shadow) active:border-sonnant-purple-2)!"
                                css={splitterCss}
                                style={{ height: '0 !important' }}
                                // @ts-ignore

                                id="splitter-css"
                              />

                              <ReflexElement
                                minSize={150}
                                flex={1}
                                tw="flex flex-col overflow-hidden!"
                              >
                                {!currentLayer.isGarmReport && (
                                  <Suspense fallback={<div>Loading...</div>}>
                                    <LayerNavigator
                                      isEditing={editMode}
                                      hideCurrentItem={hideCurrentItem}
                                    />
                                  </Suspense>
                                )}

                                {currentLayer.isSummary && (
                                  <SummaryLayer
                                    handleSaveTranscript={handleSaveTranscript}
                                  />
                                )}

                                {currentLayer.isAdBreak && <AdBreakLayer />}

                                {currentLayer.isSegment && (
                                  <MediaSegmentLayer />
                                )}

                                {currentLayer.isIAB && <IABLayer />}

                                {isLeftLayer && (
                                  <div className="transcript-auto-scroll">
                                    <HighlightPopper
                                      filename={getFileName(media?.metadata)}
                                      mediaid={mediaid}
                                      editMode={editMode}
                                      disabled={isAIProcessing}
                                    >
                                      {!player.isParagraphMode &&
                                        !isEmpty(player?.caption) && (
                                          <Suspense
                                            fallback={
                                              <div>
                                                <TranscriptSkeleton
                                                  count={10}
                                                />
                                              </div>
                                            }
                                          >
                                            <CaptionEditor />
                                          </Suspense>
                                        )}

                                      {player.isParagraphMode &&
                                        !isEmpty(player.paragraph) && (
                                          <Suspense
                                            fallback={
                                              <div>
                                                <TranscriptSkeleton
                                                  count={10}
                                                />
                                              </div>
                                            }
                                          >
                                            <ParagraphEditor />
                                          </Suspense>
                                        )}

                                      {player.isProcessingData &&
                                        !currentLayer.isSummary && (
                                          <TranscriptSkeleton
                                            count={20}
                                            tw="relative"
                                          />
                                        )}

                                      {!player.isProcessingData &&
                                        isArray(player?.caption) &&
                                        player.caption.length === 0 && (
                                          <div tw="font-medium text-sonnant-grey-6 text-16 mt-5">
                                            There are no transcripts for this
                                            content item
                                          </div>
                                        )}
                                    </HighlightPopper>
                                  </div>
                                )}
                              </ReflexElement>
                            </ReflexContainer>
                          </div>
                        </ReflexElement>
                      </ReflexContainer>
                    </div>
                  </div>
                  <div className="grid-x">
                    <div className="cell large-auto">
                      <Suspense fallback={null}>
                        <MediaTimeTrack
                          isDisabled={isMediaLoading || !player.loadCompleted}
                        />
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* MODAL SECTION */}
        <Modal
          show={warningEditing && warningEditing?.userNameEditing}
          modalClosed={() => setWarningEditing({})}
        >
          <LockingWarning
            userNameEditing={warningEditing?.userNameEditing}
            onCancel={() => setWarningEditing(false)}
            onUnlock={() => {
              lockEditing(true, true).then(console.log);

              setEditMode((prev) => {
                if (!prev) {
                  mediaRef.current?.pause();
                  return true;
                }
                return false;
              });
            }}
          />
        </Modal>

        {/* <Modal
          show={!!(errorEditing && errorEditing?.userNameEditing)}
          modalClosed={() => history.push('/library')}
        >
          <LockingError
            userNameEditing={errorEditing?.userNameEditing}
            onClose={() => history.push('/library')}
          />
        </Modal> */}
        <ConfirmModal
          ref={confirmModalRef}
          title="Exit edit mode?"
          message={
            <div>
              Changes you made may not be saved.{' '}
              <b>Would you like to proceed?</b>
            </div>
          }
        />
      </div>
    </>
  );
};
