/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { Checkbox } from 'pretty-checkbox-react';
import {
  font,
  hoverTextShadow,
  simpleMenuDisabled,
  svgShadow,
} from './twin.styles';

import '@djthoms/pretty-checkbox';
import { v4 } from 'uuid';

type Props = {
  checked: boolean;
  onChange: (checked?: boolean) => void;
  label: string;
  fontSize?: string;
  disabled?: boolean;
};

export const LabelCheckbox = ({
  label,
  checked,
  fontSize,
  disabled,
  onChange,
}: Props) => {
  const uuid = v4();

  return (
    <div
      css={[colorCss, disabled && simpleMenuDisabled]}
      tw="flex items-center text-14 gap-x-4"
      onClick={(e) => e.stopPropagation()}
    >
      <Checkbox
        id={uuid}
        checked={checked}
        color="primary"
        hasFocus
        variant="fill"
        animation="jelly"
        onChange={(e) => {
          const checked = e.target.checked;

          if (typeof onChange === 'function') {
            onChange(checked);
          }
        }}
        icon={
          <svg className="svg svg-icon" viewBox="0 0 20 20">
            <path
              tw="fill[white]"
              d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
            ></path>
          </svg>
        }
        css={[svgShadow(0.15)]}
        tw="mr-0"
      />
      <label
        htmlFor={uuid}
        tw="inline-block font-size[inherit] font-weight[inherit] text-sonnant-dark opacity[0.9] hover:cursor-pointer select-none line-height[unset]!"
        css={[hoverTextShadow(), fontSize && font(fontSize)]}
      >
        {label}
      </label>
    </div>
  );
};

const colorCss = css`
  .pretty input:checked ~ .state.p-primary label:after,
  .pretty.p-toggle .state.p-primary label:after {
    ${tw`bg-sonnant-green!`}
  }
`;
