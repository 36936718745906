import { isNil } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { tierSelector } from 'slices/payment.slice';
import { Hint } from '../Hint';

export const ExpiredHint = ({ children, disabled }: { children: JSX.Element, disabled?: boolean }) => {
  // if (!payment.isExpiredTrial ) return <div>{children}</div>;
  const payment = useSelector((state: RootState) => state.payment);
  const tier = useSelector(tierSelector);

  const isEnterpriseTrialExpired = tier.isEnterpriseTrial && payment.isExpiredTrial;
  const shouldDisabled = !isNil(disabled) ? disabled : !isEnterpriseTrialExpired;
  return (
    <Hint
      text={'Subscription expired. Payment required for this action.'}
      enterDelay={300}
      leaveDelay={0}
      // disabled tooltip if have subscription
      disabled={shouldDisabled}
      notTransparent
      arrow
    >
      <div>{children}</div>
    </Hint>
  );
};
