/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { SimpleMenuSurface } from '@rmwc/menu';
import { ReactComponent as AddToCollectionSvg } from 'assets/Icons/folder_add_to.svg';
import { ReactComponent as StandardCollectionSvg } from 'assets/Icons/standard-collection.svg';
import { ReactComponent as SmartCollectionSvg } from 'assets/Icons/smart-collection.svg';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useClickAway, useToggle } from 'react-use';
import { RootState } from 'reducers';
import { iconStyle } from '../Sort';
import { CollectionType } from 'utils/enum';
import { Ripple } from '@rmwc/ripple';
import { ILibraryItem } from 'utils/models';
import { addItemsToCollectionAsync, hasExistedName } from 'utils/utils';
import { TextInput } from 'components/shared/TextInput';
import { customToast } from 'utils/toast.util';
import { CollectionService } from 'services/collection.service';
import { v4 } from 'uuid';
import {
  addNewCollection,
  deleteCollection,
  updateNewAddedCollectionId,
} from 'slices/collection.slice';
import { AxiosError } from 'axios';

interface Props {}

export const AddToCollectionMenu = (props: Props) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const collection = useSelector((state: RootState) => state.collection);
  const library = useSelector((state: RootState) => state.library);

  const [newCollectionName, setNewCollectionName] = useState('');

  const [show, toggleShow] = useToggle(false);
  const [isEditing, toggleEditing] = useToggle(false);

  const dispatch = useDispatch();

  useClickAway(menuRef, () => {
    if (!show) return;

    toggleShow(false);
    toggleEditing(false);
  });

  const handleClickAddToCollection = (collectionId: string) => {
    toggleShow(false);
    toggleEditing(false);

    const mediaIds: string[] =
      library.selectedRows.map((row: ILibraryItem) => row?.mediaid) ?? [];

    if (!collectionId || !mediaIds?.length) return;

    addItemsToCollectionAsync({
      collectionId,
      ids: mediaIds,
    });
  };

  const handleEnter = async (newCollectionName: string) => {
    const collectionName = newCollectionName?.trim();

    if (!collectionName) {
      customToast.error('Collection name is required');
      return;
    }

    // Collection name must be unique
    if (hasExistedName(collection.items, collectionName)) {
      return;
    }

    handleEsc();

    const addCollectionAsync = CollectionService.createCollection({
      collection_name: collectionName,
    });

    const randomId = v4();

    try {
      // Add placeholder collection
      dispatch(
        addNewCollection({
          id: randomId,
          type: CollectionType.STANDARD,
          name: collectionName,
        }),
      );

      customToast.loading('Creating collection...');
      const { data: collectionId } = await addCollectionAsync;

      customToast.success(
        <div>
          <b>{collectionName}</b> created successfully.
        </div>,
      );

      // Replace with real collection id
      dispatch(
        updateNewAddedCollectionId({
          oldId: randomId,
          newId: collectionId,
        }),
      );

      // add items to collection
      handleClickAddToCollection(collectionId);
    } catch (err: any) {
      // Rollback if create collection failed
      dispatch(deleteCollection(randomId));
      console.log('err', err);

      const errorMessage = (err as AxiosError)?.response?.data;

      errorMessage && customToast.error(errorMessage);
    }
  };

  const handleEsc = () => {
    setNewCollectionName('');
    toggleEditing(false);
  };

  return (
    <div ref={menuRef}>
      <SimpleMenuSurface
        open={show}
        // fixed
        handle={
          <li onClick={() => toggleShow(true)}>
            <div tw={'flex space-x-2 items-center'}>
              <AddToCollectionSvg css={iconStyle} />
              <a>Add to collection</a>
            </div>
          </li>
        }
        tw="py-1"
        anchorCorner="topRight"
      >
        <div tw="w-full">
          {collection.items.map((item) => (
            <Ripple
              key={item.id}
              tw="px-4 py-1.5 rounded flex justify-between items-center cursor-pointer w-full"
              onClick={() => handleClickAddToCollection(item?.id)}
            >
              <div tw="flex items-center justify-between">
                <span tw="flex flex-1">
                  <span tw="flex items-center">
                    {item.type === CollectionType.SMART ? (
                      <SmartCollectionSvg />
                    ) : (
                      <StandardCollectionSvg />
                    )}
                  </span>
                  <div
                    tw="w-full font-weight[inherit] ml-3 text-15 select-none break-all! whitespace-normal width[19rem]!"
                    className="line-clamp-2"
                  >
                    {item.name ?? 'My collections'}
                  </div>
                </span>
              </div>
            </Ripple>
          ))}
        </div>
        {!isEditing ? (
          <Ripple
            tw="py-1.5 rounded flex justify-between items-center cursor-pointer"
            onClick={() => {
              toggleEditing(true);
            }}
          >
            <span tw={'-ml-2 flex items-center w-full'}>
              <span tw="flex flex-1">
                <span tw="flex items-center">
                  <AddToCollectionSvg css={iconStyle} />
                </span>
                <span tw="font-weight[inherit] text-15 select-none relative top[1px]">
                  Add new collection
                </span>
              </span>
            </span>
          </Ripple>
        ) : (
          <span tw={'-ml-2 flex items-center w-full'}>
            <span tw="flex flex-1">
              <span tw="flex items-center">
                <AddToCollectionSvg css={iconStyle} />
              </span>
              <TextInput
                defaultText={newCollectionName}
                placeholder="Type name"
                onPressEnter={handleEnter}
                onPressEsc={handleEsc}
                tw="(text-15 padding-left[6px] height[3.5rem] font-medium placeholder:(font-light text-14))!"
              />
            </span>
          </span>
        )}
      </SimpleMenuSurface>
    </div>
  );
};
