/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { ReactComponent as WarningSvg } from 'assets/Icons/warning.svg';
import { isSensitiveIabId } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { iabConfidenceColor, svgShadow } from 'components/shared/twin.styles';
import { isEmpty, isNil, orderBy } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { IABItemModel } from 'utils/models';
import { useEmbedContext } from '../../utils/useEmbedContext';
import { EmbedLayerEmpty } from '../../shared/EmbedLayerEmpty';
import { setEmbedFocusTerm } from 'slices/embed.slice';
import { ensureArray, extract } from 'utils/generic.util';

type Props = {};

export const EmbedIabViewer = (props: Props) => {
  const dispatch = useDispatch();

  const { controls } = useEmbedContext();

  const iab = useSelector((state: RootState) => state.iab);

  const iabItems = orderBy(iab.lockedList, (i) => i.relevance, 'desc');

  const [currentItem, setCurrentItem] = useState<IABItemModel | null>(null);

  const handleClickItem = (iabItem: IABItemModel) => {
    setCurrentItem(iabItem);
    jumpToChapterIndex({ iabItem, index: 0 });

    dispatch(
      setEmbedFocusTerm({
        name: iabItem.name,
        occurrences: extract(ensureArray(iabItem.chapters), 's'),
      }),
    );
  };

  const jumpToChapterIndex = ({
    iabItem,
    index,
  }: {
    iabItem: IABItemModel;
    index: number;
  }) => {
    if (!isEmpty(iabItem?.chapters)) return;

    const time = iabItem?.chapters?.[index]?.s;

    !isNil(time) && controls.seek(time);
  };

  const isActiveIABItem = (iabItem: IABItemModel) => {
    if (isNil(currentItem)) return false;

    return iabItem?.id === currentItem?.id;
  };

  const isSensitive = (item: IABItemModel): boolean => {
    return isSensitiveIabId(item?.id) || isSensitiveIabId(item?.parentId);
  };

  return (
    <div tw="flex flex-wrap gap-x-4 gap-y-4 mt-1">
      {iabItems.map((iabItem) => (
        <div key={iabItem.id}>
          <span
            tw="rounded-full flex text-15 text-center text-sonnant-dark font-medium border[2px solid] border-sonnant-grey-3  whitespace-nowrap py-3 pl-4 pr-4 hover:(border-sonnant-purple-2) cursor-pointer"
            title={iabItem?.hierarchy}
            css={[
              iabConfidenceColor(iabItem?.relevance, iabItem?.isManual),
              isActiveIABItem(iabItem) && tw`border-sonnant-purple-2 shadow`,
            ]}
            onClick={() => handleClickItem(iabItem)}
          >
            <div tw="flex items-center">
              {isSensitive(iabItem) && (
                <div tw="flex items-center mr-2 relative top-[-2px]">
                  <WarningSvg css={[svgShadow(0.35)]} />
                </div>
              )}

              <div
                css={[isActiveIABItem(iabItem) && tw`text-sonnant-purple-2`]}
              >
                <span>
                  {iabItem.name} ({iabItem.id})
                </span>

                {iabItem.chapters?.length && (
                  <span tw="border[1px solid] border-sonnant-grey-5 rounded-full min-width[2.5rem] h-10 inline-block items-center text-center ml-2 px-1.5">
                    {iabItem.chapters.length}
                  </span>
                )}
              </div>
            </div>
          </span>
        </div>
      ))}

      {isEmpty(iabItems) && <EmbedLayerEmpty />}
    </div>
  );
};
