/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { ReactComponent as EmbedWideSvg } from 'assets/Icons/embed-wider-icon.svg';
import { ReactComponent as EmbedFullSizeSvg } from 'assets/Icons/embed-full-size-icon.svg';

import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { EmbedStyle } from 'utils/models';

import { v4 } from 'uuid';

type Props = {
  onSelect: (style: EmbedStyle) => void;
};

const embedStyles: EmbedStyle[] = [
  {
    id: v4(),
    header: 'Wide player',
    description: 'Inline player adapts to full width',
    height: 180,
    hasTranscript: false,
    icon: <EmbedWideSvg tw="w-[6rem] h-[1.8rem]" />,
  },
  {
    id: v4(),
    header: 'Transcript',
    description: 'Includes Transcript, Summary and Entities',
    height: 680,
    hasTranscript: true,
    icon: <EmbedFullSizeSvg tw="w-[6rem] h-[6rem]" />,
  },
];

export const EmbedStyleSelect = ({ onSelect }: Props) => {
  const [selectedStyle, setSelectedStyle] = useState<EmbedStyle>(
    embedStyles[0],
  );

  useEffect(() => {
    onSelect(selectedStyle);
  }, [selectedStyle]);

  return (
    <div tw="flex justify-between gap-x-8">
      {embedStyles.map((embedStyle) => (
        <EmbedStyleItem
          key={embedStyle.id}
          isSelected={isEqual(selectedStyle?.id, embedStyle.id)}
          style={embedStyle}
          onSelect={setSelectedStyle}
        />
      ))}
    </div>
  );
};

const EmbedStyleItem = ({
  style: item,
  isSelected,
  onSelect,
}: {
  style: EmbedStyle;
  isSelected: boolean;
  onSelect: (item: EmbedStyle) => void;
}) => {
  const handleClick = () => {
    onSelect(item);
  };

  return (
    <div
      tw="flex flex-1 p-3 gap-x-4 rounded-md shadow outline[2px solid #e6e6ec] cursor-pointer"
      css={[isSelected && tw`outline[2px solid #9B99FF] bg-[#f5f3ff]`]}
      onClick={handleClick}
    >
      <div tw="flex items-center">
        <span tw="flex p-1 border[1px solid] border-sonnant-grey-light shadow rounded-sm bg-purple-50">
          {item.icon}
        </span>
      </div>
      <div>
        <div>
          <strong tw="text-sonnant-dark text-15">{item.header}</strong>
        </div>
        <div tw="text-sonnant-grey-6 text-13">{item.description}</div>
      </div>
    </div>
  );
};
