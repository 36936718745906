export enum Routes {
  SIGNIN = '/signin',
  SIGNUP = '/signup',
  CONFIRM_PASS = '/confirmpassword',
  CHANGE_PASS = '/changepassword',
  MESSAGE = '/message',

  NOT_FOUND = '/404',
  ROOT = '/',

  LIBRARY = '/library',
  REVIVE = '/revive',
  INSIGHTS = '/insights',
  TRANSCRIPTION = '/transcription',
  CLIPS = '/clips',
  COLLECTIONS = '/collections',
  SETTINGS = '/account-settings',
  ADMIN_PORTAL = '/admin-portal',
  PREFERENCES = '/preferences',
  REPORTING = '/reporting',
  PUBLIC_SHARED = '/shared',
  CREATED_REPORTS = '/reporting#created-reports',
  DOWNLOAD_EXPIRED = '/download-expired',
  EMBED = '/embed',
}
