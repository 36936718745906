/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import 'twin.macro';

import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Modal } from 'components/UiControls/modal/modal';
import { InputTimeCode } from './InputTimeCode';
import { isNil, round } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { IStartEnd } from 'utils/models/transcript.model';
import { Editor, Path } from 'slate';
import { RootState } from 'reducers';
import {
  msToSec,
  secToTime,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { toggleEditTimecodeMode } from 'slices/toggle.slice';
import { customToast } from 'utils/toast.util';

interface IPromiseParams {
  resolve: (params: any) => void;
  reject: (err: any) => void;
}

interface Param {
  path?: Path;
  start: number;
  end: number;
  prevEnd?: number;
  nextStart?: number;
  mode?: 'chapter' | 'caption' | 'paragraph';
  chapterId?: number;
}

interface IProps {}

const modalStyles = css`
  & div.Modal {
    width: 50rem;
    left: calc(50% - 25rem);
  }
`;

export const TimecodeModal = forwardRef(
  (props: IProps, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show, hide }));

    const dispatch = useDispatch();
    const media = useSelector((state: RootState) => state.media);
    const { chapter } = useSelector((state: RootState) => state.chapter);
    const toggle = useSelector((state: RootState) => state.toggle);

    const [isOpen, setIsOpen] = useState(false);

    const [initialStart, setInitialStart] = useState(0);
    const [initialEnd, setInitialEnd] = useState(0);

    const [timecodeStart, setTimecodeStart] = useState(0);
    const [timecodeEnd, setTimecodeEnd] = useState(0);

    const [prevEnd, setPrevEnd] = useState(0);
    const [nextStart, setNextStart] = useState(0);

    const [params, setParams] = useState<Param>();

    let gapBefore = round(timecodeStart - prevEnd, 2);
    let gapAfter = round(nextStart - timecodeEnd, 2);

    useEffect(() => {
      if (isOpen === false) {
        setInitialStart(0);
        setInitialEnd(0);
      }
      if (toggle.isEditTimeCodeMode !== isOpen) {
        dispatch(toggleEditTimecodeMode(isOpen));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const modalPromise = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (params: Param): Promise<unknown> => {
      setParams(params);
      return new Promise((resolve, reject) => {
        modalPromise.current = {
          resolve,
          reject,
        };
        const { path, start, end, prevEnd, nextStart } = { ...params };

        setInitialStart(start);
        setInitialEnd(end);

        if (!isNil(path)) {
          const nextNode: any = Editor.next(window.Editor, { at: path })?.[0];
          const prevNode: any = Editor.previous(window.Editor, {
            at: path,
          })?.[0];

          // Default startTime for first
          setPrevEnd(prevNode?.data?.end || 0);

          // Default endTime for last
          setNextStart(
            nextNode?.data?.start || msToSec(media?.metadata?.length),
          );
        }

        if (!isNil(prevEnd) && !isNil(nextStart)) {
          setPrevEnd(prevEnd);
          setNextStart(nextStart);
        }

        setIsOpen(true);
      });
    };

    const hide = () => {
      setIsOpen(false);
    };

    const handleYes = async () => {
      if (params?.mode === 'chapter') {
        const existed = chapter?.some(
          (chap) =>
            chap?.id !== params?.chapterId &&
            secToTime(chap?.startTime) === secToTime(timecodeStart),
        );
        if (existed) {
          customToast.error('Cannot create chapter with the same start time');
          return;
        }
      }

      const shouldUpdateScript: IStartEnd = {
        start: timecodeStart,
        end: timecodeEnd,
      };
      hide();
      modalPromise.current?.resolve(shouldUpdateScript);
    };

    const handleCancel = () => {
      hide();
      modalPromise.current?.resolve(null);
    };

    return (
      <div css={modalStyles}>
        <Modal show={isOpen} modalClosed={handleCancel}>
          <div tw="flex flex-col">
            <h2>Adjust Timing</h2>
            <div>
              <div tw="w-full flex justify-end">
                <div tw="min-width[33.33%] text-2xl font-medium opacity-70 whitespace-nowrap">
                  Gap before: {gapBefore} Sec
                </div>
              </div>
              <div tw="text-3xl font-medium opacity-90">Start Timecode</div>
              <InputTimeCode
                time={initialStart ?? 0}
                handleTime={(timeStart) => setTimecodeStart(+timeStart)}
                hasError={gapBefore < 0 || timecodeStart >= timecodeEnd}
              />
              {timecodeStart >= timecodeEnd && <div tw="text-red-500 text-12 ml-2.5">Start time cannot be greater than or equal end time</div>}
            </div>

            <div tw="mt-4">
              <div tw="text-3xl font-medium opacity-90">End Timecode</div>
              <InputTimeCode
                time={initialEnd ?? 0}
                handleTime={(timeEnd) => setTimecodeEnd(+timeEnd)}
                hasError={gapAfter < 0 || timecodeEnd > window?.Video?.duration}
              />
              {timecodeEnd > window?.Video?.duration && <div tw="text-red-500 text-12 ml-2.5">End time cannot be greater than video duration</div>}

              <div tw="w-full flex justify-end mt-3">
                <div tw="min-width[33.33%] text-2xl font-medium opacity-70 whitespace-nowrap">
                  Gap after: {gapAfter} Sec
                </div>
              </div>
            </div>

            <div tw="flex space-x-12 mt-8 justify-center">
              <button
                type="button"
                className="btn-secondary"
                tw="w-2/6 h-16"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className={`btn-primary ${
                  (gapBefore < 0 || gapAfter < 0 || timecodeStart >= timecodeEnd) && 'disabled'
                }`}
                tw="w-2/6 h-16"
                onClick={handleYes}
              >
                Apply
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  },
);
