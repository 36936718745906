/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import React, {
  ReactElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { HTTPStatus, Keys, ModalOptions } from 'utils/enum';
import { UppyUploader } from './UppyUploader';

import * as types from 'actions/types';
import { ReactComponent as QuestionSvg } from 'assets/Icons/Vector_question.svg';
import { ReactComponent as RssSvg } from 'assets/Icons/rss-feed.svg';
import { ReactComponent as YoutubeLogoSvg } from 'assets/Icons/youtube-logo-red.svg';
import {
  convertYoutubeChannelPreview,
  getYoutubeChannelInfo,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { CollectionDropdown } from 'components/shared/CollectionDropdown/CollectionDropdown';
import { CustomToast } from 'components/shared/CustomToast';
import { Hint } from 'components/shared/Hint';
import { RssPaginationPreviewModal } from 'components/shared/RssPaginationPreviewModal/RssPaginationPreviewModal';
import { RssPreviewModal } from 'components/shared/RssPreviewModal/RssPreviewModal';
import { cloneDeep, isEmpty } from 'lodash';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useKeyPressEvent, useToggle } from 'react-use';
import { RootState } from 'reducers';
import { UploadService } from 'services';
import {
  resetUploadSelectedCollectionId,
  setUploadSelectedCollectionId,
} from 'slices/collection.slice';
import { tierSelector } from 'slices/payment.slice';
import { toggleUploadModal } from 'slices/toggle.slice';
import { RssFeed, YoutubeChannelExtractor } from 'utils/models';
import { REGEX } from 'utils/regex';
import { customToast } from 'utils/toast.util';
import { waitAsync } from 'utils/utils';

interface IPromiseParams {
  resolve: (option: ModalOptions) => void;
  reject: (err: any) => void;
}

interface IProps {}

export const UppyUploadModal = forwardRef(
  (props: IProps, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const dispatch = useDispatch();

    const uppy = useSelector((state: RootState) => state.uppy);
    const toggle = useSelector((state: RootState) => state.toggle);
    const collection = useSelector((state: RootState) => state.collection);
    const tier = useSelector(tierSelector);

    const isOpen = toggle.isShowUploadModal;
    const [fromURL, setFromURL] = useState('');
    const [rssURL, setRssURL] = useState('');
    const [isSubscribeRss, toggleSubscribeRss] = useToggle(true);
    const [isDisableCheckbox, setDisableCheckbox] = useState(false);

    const invalidRssURL =
      isEmpty(rssURL?.trim()) || rssURL?.trim().match(REGEX.URL_CHECK) === null;

    const invalidYoutubeURL =
      isEmpty(fromURL?.trim()) ||
      fromURL?.trim().match(REGEX.URL_CHECK) === null;

    const rssPreviewModalRef = useRef<any>(null);
    const rssPaginationPreviewModalRef = useRef<any>(null);

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    useKeyPressEvent(Keys.ESC, () => {
      if (isOpen) {
        handleCancel();
      }
    });

    const show = async (): Promise<unknown> => {
      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        dispatch(toggleUploadModal(true));
      });
    };

    const hideModal = () => {
      dispatch(toggleUploadModal(false));
      setFromURL('');
      setRssURL('');
    };

    const handleCancel = () => {
      hideModal();
      if (!isEmpty((uppy as any)?.uppyStore?.error)) {
        dispatch({ type: types.INIT_LIBRARY_NO_LOADING });
      }
      promiseInfo.current?.resolve(ModalOptions.CANCEL);
    };

    const handleUrlUpload = async () => {
      if (isEmpty(fromURL?.trim())) {
        toast(
          <CustomToast
            type="error"
            title="Error"
            message="URL cannot be empty"
          />,
        );
        return;
      }

      try {
        const collectionDestId = collection.uploadSelectedId || undefined;

        const channelInfo = getYoutubeChannelInfo(fromURL);

        let uploadUrls = [fromURL];

        const { isValidChannelUrl, channelId, channelName } = {
          ...channelInfo,
        };

        if (isValidChannelUrl) {
          if (!channelInfo || (!channelId && !channelName)) {
            customToast.error(
              'Not a valid Youtube Channel URL. Please check and try again.',
            );
          } else {
            uploadUrls = await getSelectedVideosChannel(channelInfo);
          }
        }

        if (uploadUrls?.length === 0) return;

        const uploadAsync = UploadService.uploadUrl({
          media_urls: uploadUrls,
          collection_id: collectionDestId,
          is_from_channel: channelInfo?.isValidChannelUrl ? true : undefined,
        });

        handleCancel();

        customToast.loading('Uploading...');
        await waitAsync(1500);
        await uploadAsync;

        customToast.success(
          <div>
            <div>Submitted for background uploading.</div>
            <div>It may take a few minutes to be completed.</div>
          </div>,
        );
      } catch (error: any) {
        toast.dismiss();
        dispatch({ type: types.INIT_LIBRARY_NO_LOADING });

        switch (error?.response?.status) {
          case HTTPStatus.BAD_REQUEST:
            toast(
              <CustomToast
                type="error"
                title="Error"
                message="This is not a valid YouTube URL or supported domain"
              />,
              { style: { maxWidth: '410px' } },
            );
            break;
          case HTTPStatus.NOT_FOUND:
            toast(
              <CustomToast
                type="error"
                title="Error"
                message={
                  error?.response?.data || 'Upload failed. Resource not found.'
                }
              />,
            );
            break;
          case HTTPStatus.PAYLOAD_TOO_LARGE:
            toast(
              <CustomToast
                type="error"
                title="Error"
                message="Upload failed. File too large."
              />,
            );
            break;
          case HTTPStatus.NOT_SUPPORTED:
            toast(
              <CustomToast
                type="error"
                title="Error"
                message="Youtube live streaming links are not supported. Please check and try again."
              />,
              { style: { maxWidth: '400px' } },
            );
            break;
          case HTTPStatus.FAILED:
            toast(
              <CustomToast
                type="error"
                title="Error"
                message="Upload failed"
              />,
            );
            break;
          default:
            toast(
              <CustomToast
                type="error"
                title="Error"
                message={error?.response?.data ?? 'Something went wrong'}
              />,
            );
            break;
        }
        console.log('error :>> ', cloneDeep(error.response));
      } finally {
        setFromURL('');
      }
    };

    const getSelectedVideosChannel = async (
      channelInfo: YoutubeChannelExtractor,
    ): Promise<string[]> => {
      const previewYoutubeAsync =
        UploadService.previewYoutubeChannel(channelInfo);

      customToast.promise(previewYoutubeAsync, {
        loading: 'Loading Youtube Channel details',
        success: 'Loaded successfully',
        error: 'Failed to load Youtube Channel details',
      });

      try {
        const { data } = await previewYoutubeAsync;

        const channelData = convertYoutubeChannelPreview(data?.items ?? []);

        // Reusable modal for Youtube Channel uploading
        const result = await rssPreviewModalRef.current?.show({
          rssFeeds: channelData,
          fromYoutube: true,
          title: data?.items?.[0]?.channel?.name,
          currentItem: data?.currentItem,
          totalVideo: data?.totalVideo,
        });

        if (result?.option === ModalOptions.YES) {
          return (
            result?.selectedFeeds.map(
              (feed: RssFeed) => feed?.enclosure?.url,
            ) ?? []
          );
        } else {
          return [];
        }
      } catch {
        return [];
      }
    };

    const handleSelectUploadCollectionId = (collectionId: string) => {
      dispatch(setUploadSelectedCollectionId(collectionId));
    };

    useEffect(() => {
      if (tier.isTrial || tier.isPAYG) {
        setDisableCheckbox(true);
        toggleSubscribeRss(false);
      }
    }, [tier]);

    useEffect(() => {
      if (isOpen === false) return;

      if (collection?.selectedId) {
        dispatch(setUploadSelectedCollectionId(collection.selectedId));
        return;
      }

      dispatch(resetUploadSelectedCollectionId());
    }, [isOpen]);

    const handleRssUpload = async () => {
      if (invalidRssURL || isEmpty(rssURL?.trim())) return;

      customToast.loading('Parsing RSS Feed');

      await rssPaginationPreviewModalRef.current?.show({
        rssURL: rssURL?.trim(),
        isSubscribeRss,
      });

      // Reset input
      setRssURL('');
    };

    return (
      <>
        <div
          className={isOpen ? 'Backdrop' : ''}
          tw="background[rgba(0, 0, 0, .7)]"
        >
          <div
            className="Modal"
            css={isOpen ? tw`top[8vh]! visible` : tw`top[-250vh]! invisible`}
          >
            <span onClick={handleCancel} className="customClose">
              &times;
            </span>
            <div>
              <h1 tw="text-sonnant-dark font-medium">
                Upload video / audio files
              </h1>
              <div tw="text-3xl py-4">
                Once uploaded, you'll have full control over your library of
                content.
              </div>
              <hr />

              <UppyUploader />

              <hr />
              <div tw="w-full text-center my-8 font-medium text-3xl!">
                Import files from
              </div>

              <div tw="flex w-3/4 m-auto mb-10 shadow rounded overflow-hidden">
                <div tw="relative flex-1">
                  <YoutubeLogoSvg tw="height[4rem] width[4rem] absolute ml-2.5" />
                  <Hint
                    text="Not a valid URL"
                    arrow
                    open={invalidYoutubeURL && !!fromURL?.trim()}
                  >
                    <input
                      type="text"
                      value={fromURL}
                      onChange={(e) => setFromURL(e.target.value)}
                      onKeyDown={(e) => {
                        if (invalidYoutubeURL) return;

                        e.key === Keys.ENTER && handleUrlUpload();
                      }}
                      placeholder="Enter a YouTube video or channel URL"
                      tw="(pl-20 rounded-r-none rounded-l-none mb-0 border[2px solid transparent])!"
                      autoComplete="off"
                    />
                  </Hint>
                </div>

                <button
                  className={`btn-primary large`}
                  tw="(min-width[unset] width[unset] px-6 rounded-l-none shadow-lg active:(border-none bg-sonnant-purple-3 text-white))!"
                  onClick={handleUrlUpload}
                  disabled={invalidYoutubeURL}
                >
                  Upload
                </button>
              </div>

              <div tw="flex w-3/4 m-auto mb-10 shadow rounded overflow-hidden">
                <div tw="relative flex-1 flex items-center">
                  <RssSvg tw="height[2.4rem] width[2.4rem] absolute ml-5" />
                  <Hint
                    text="Not a valid URL"
                    arrow
                    open={invalidRssURL && !!rssURL?.trim()}
                  >
                    <span tw="w-full">
                      <input
                        type="text"
                        value={rssURL}
                        onChange={(e) => setRssURL(e.target.value)}
                        placeholder="Enter a RSS URL"
                        tw="(pl-20 rounded-r-none rounded-l-none mb-0 border[2px solid transparent] padding-right[12rem])!"
                        autoComplete="off"
                      />
                    </span>
                  </Hint>
                  <Hint
                    text="RSS Subscription is only available for subscription customers."
                    arrow
                    disabled={!isDisableCheckbox}
                    enterDelay={100}
                  >
                    <label
                      tw="absolute cursor-pointer right[1.5rem] select-none"
                      css={[
                        isDisableCheckbox && tw`opacity-70 cursor-not-allowed`,
                      ]}
                    >
                      <input
                        name="caption"
                        checked={isSubscribeRss}
                        onChange={toggleSubscribeRss}
                        type="checkbox"
                        disabled={isDisableCheckbox}
                      />
                      <span className="checkmark" style={{ top: '5px' }}></span>
                      Subscribe
                    </label>
                  </Hint>
                </div>

                <button
                  className={`btn-primary large`}
                  tw="(min-width[unset] width[unset] px-6 rounded-l-none shadow-lg active:(border-none bg-sonnant-purple-3 text-white))!"
                  onClick={handleRssUpload}
                  disabled={invalidRssURL}
                >
                  Upload
                </button>
              </div>

              <div tw="text-2xl">
                We currently support the following file formats{' '}
                <Hint
                  text=".mp3, .mp4, .flv, .mkv, .wmv, .avi, .mpeg, .aac, .aiff, .au, .3gp, .flac, .ra, .m4a, .wma, .m4v, .cf, .mov, .mpg, .webm, .wav, .asf, .amr"
                  enterDelay={0}
                >
                  <span tw="cursor-pointer" className="questionsvg">
                    <QuestionSvg tw="width[1.6rem] height[1.6rem] relative top[2px]" />
                  </span>
                </Hint>{' '}
                {/* with a maximum file size limit of 1.5GB */}
              </div>

              <hr />

              <div tw="flex justify-center items-center w-full">
                <div tw="flex justify-center items-center text-sonnant-dark">
                  <span tw="text-15 mr-3">Add to collection:</span>
                  <CollectionDropdown
                    defaultValue={collection.uploadSelectedId!}
                    onChange={handleSelectUploadCollectionId}
                    menuPlacement="top"
                    isMultiSelect={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <RssPreviewModal ref={rssPreviewModalRef} />
        <RssPaginationPreviewModal ref={rssPaginationPreviewModalRef} />
      </>
    );
  },
);
