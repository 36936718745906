/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { CustomSelectSearch } from 'components/shared/CustomSelectSearch';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GroupBase, StylesConfig } from 'react-select';
import { RootState } from 'reducers';
import Loader from 'components/loader/loader';
import { isEmpty, isEqual, unionBy } from 'lodash';

import { ReactComponent as PersonalCollectionSvg } from 'assets/Icons/user-collection.svg';
import { QuestionHint } from 'components/shared/QuestionHint';
import {
  handleSaveCollectionSetting,
  setSelectedCollectionId,
} from 'slices/collection.slice';
import { CollectionType, ModalOptions } from 'utils/enum';
import { ReactComponent as StandardCollectionSvg } from 'assets/Icons/standard-collection.svg';
import { ReactComponent as NoneCollectionSvg } from 'assets/Icons/cross.svg';
import { ResetBottomBar } from 'components/shared/ResetBottomBar/ResetBottomBar';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { ConfirmModalProps } from 'utils/models';
import { defaultSetting } from 'utils/default/defaultSetting';
import { toCollectionInfo } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { CollectionOption } from 'services/collection.service';

type CollectionInfo = {
  collectionId: string | null;
  collectionName: string | null;
};

interface CollectionState {
  defaultCollection: CollectionInfo;
  visibleCollections: CollectionOption[];
}

export const CollectionSetting = () => {
  const collections = useSelector((state: RootState) => state.collection);

  const dispatch = useDispatch();

  const [initialState, setInitialState] = useState<CollectionState>(
    defaultSetting.collectionSetting,
  );

  const [state, setState] = useState<CollectionState>(
    defaultSetting.collectionSetting,
  );

  useEffect(() => {
    if (collections.isLoading) return;

    const newState = {
      visibleCollections: collections.visibleCollection.map(toCollectionInfo),
      defaultCollection: {
        collectionId: collections.defaultCollectionId,
        collectionName:
          collections.items.find(
            (i) => i.id === collections.defaultCollectionId,
          )?.name ?? null,
      },
    };

    setState(newState);
    setInitialState(newState);
  }, [collections]);

  const confirmModalRef = useRef<any>();

  const hasChanges = !isEqual(initialState, state);
  const hasDisableReset = isEqual(defaultSetting.collectionSetting, state);
  const hasSelectAll =
    state.visibleCollections.length === collections.items.length;
  const collectionOptions = collections.items.map((item) => ({
    label: item?.name,
    value: item?.id,
  }));

  const handleReset = () => {
    if (!initialState) return;

    setState(initialState);
  };

  const handleSelectDefaultCollection = (option: CollectionOption) => {
    const { defaultCollection } = state;
    const newVisibleCollection = state.visibleCollections.filter(
      (item) => item.value !== defaultCollection.collectionId,
    );

    if (!isEmpty(option.value)) {
      newVisibleCollection.push(option);
    }

    setState({
      ...state,
      visibleCollections: unionBy(newVisibleCollection, 'value'),
      defaultCollection: {
        collectionId: !isEmpty(option.value) ? option.value : null,
        collectionName: !isEmpty(option.value) ? option.label : null,
      },
    });
  };

  const handleSelectVisibleCollection = (value: CollectionOption[]) => {
    setState({ ...state, visibleCollections: value });
  };

  const handleApplySettings = async (isResetDefault?: boolean) => {
    dispatch(
      setSelectedCollectionId(state.defaultCollection?.collectionId ?? null),
    );

    setInitialState(state);
    dispatch(
      handleSaveCollectionSetting({
        data: state,
        isResetDefault,
      }),
    );
  };

  const handleSelectAllCollections = () => {
    const visibleOptions = collections.items.map((collection) => ({
      label: collection.name,
      value: collection.id,
      type: CollectionType.STANDARD,
    }));

    setState({ ...state, visibleCollections: visibleOptions });
  };

  const handleResetDefault = async () => {
    const result = await confirmModalRef.current?.show({
      title: 'Confirm reset to default',
      confirmText: 'Confirm',
      message: (
        <div>
          Are you sure you want to reset collections configuration to default?
        </div>
      ),
    } as ConfirmModalProps);

    if (result !== ModalOptions.YES) return;

    handleApplySettings(true);
  };

  return (
    <>
      {collections.isLoading && (
        <div
          className="loader__component"
          tw="height[calc(100vh - 13rem)] top[13rem] opacity-100"
        >
          <Loader />
        </div>
      )}

      <ResetBottomBar
        isShow={hasChanges}
        onReset={handleReset}
        onSubmit={handleApplySettings}
      />

      <ConfirmModal
        ref={confirmModalRef}
        message="Please upgrade to enable additional users"
        title="Confirm"
        confirmText="Upgrade"
        classes="disable-enable-user-modal-width"
      />

      <form onSubmit={(e) => e.preventDefault()}>
        <div tw="w-full flex flex-row justify-between">
          <div className="btnReset" tw="flex flex-nowrap order-1">
            <button
              type="button"
              className={` success button apply`}
              onClick={handleResetDefault}
              disabled={hasDisableReset}
            >
              Reset
            </button>
          </div>

          <div tw="pl-10 pt-8">
            <div tw="text-[3rem] font-extrabold pt-2">Collection Settings</div>

            <div tw="font-extrabold text-18 mt-14 mb-7 flex items-center space-x-4 text-sonnant-dark">
              <span tw="flex">
                <PersonalCollectionSvg tw="relative top[-1px]" />
              </span>
              <div>Personalized Collection View</div>
            </div>

            <div css={[labelCss]}>
              <span>Default library collection</span>
              <span tw="flex">
                <QuestionHint
                  type="information"
                  align="bottomLeft"
                  action="hover"
                  customStyle={customTooltipCss}
                  hideCloseButton
                  text={
                    <div tw="py-1">
                      <p>
                        The collection that would be automatically shown when
                        you first opened the collection view from library page.
                      </p>

                      <p>
                        The default library collection could be only selected
                        from the list of visible collections below.
                      </p>
                    </div>
                  }
                />
              </span>
            </div>
            <div tw="mt-3">
              <CustomSelectSearch
                options={[
                  {
                    label: 'No default collection',
                    value: '',
                    type: CollectionType.NONE,
                  },
                  ...collectionOptions.map((option) => ({
                    ...option,
                    type: CollectionType.STANDARD,
                  })),
                ]}
                formatOptionLabel={renderCustomOption}
                onChange={handleSelectDefaultCollection}
                defaultValue={state.defaultCollection.collectionName}
                canCreate={false}
                customStyles={{ ...defaultStyles, ...customStyleCollection }}
              />
            </div>

            <div
              tw="flex items-end justify-between min-width[55rem]"
              css={[labelCss]}
            >
              <div tw="flex items-center space-x-5">
                <span>Visible Collections</span>
                <span tw="flex">
                  <QuestionHint
                    type="information"
                    align="bottomLeft"
                    action="hover"
                    customStyle={customTooltipCss}
                    hideCloseButton
                    text={
                      <div tw="py-1">
                        <p>
                          This list of collection that filtered out from the
                          whole list. These items would only show on you
                          collection view on library page.
                        </p>
                        <p>
                          Clear all selected items to show all collections to
                          your view.
                        </p>
                      </div>
                    }
                  />
                </span>
              </div>

              <div tw="flex items-center" className="btnReset">
                <button
                  type="button"
                  className={`success button apply`}
                  onClick={handleSelectAllCollections}
                  disabled={hasSelectAll}
                >
                  Select all
                </button>
              </div>
            </div>

            <div tw="w-full mt-3 mb-8">
              <CustomSelectSearch
                options={collectionOptions.map((option) => ({
                  ...option,
                  type: CollectionType.STANDARD,
                }))}
                isMultiSelect
                onChange={handleSelectVisibleCollection}
                defaultValue={
                  isEmpty(state.visibleCollections)
                    ? 'Leave empty to show all collections'
                    : state.visibleCollections
                }
                formatOptionLabel={renderCustomOption}
                canCreate={false}
                customStyles={customStyles}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

const renderCustomOption = (option: CollectionOption) => (
  <div tw="flex flex-row">
    <div tw="max-width[3.5rem] mr-4 flex">
      {option.type === CollectionType.NONE ? (
        <NoneCollectionSvg />
      ) : (
        <StandardCollectionSvg />
      )}
    </div>

    <div tw="flex flex-col w-full">
      <div
        tw="text-15 text-left text-sonnant-dark break-words"
        className="line-clamp-2"
      >
        {option.label}
      </div>
    </div>
  </div>
);

const customStyleCollection: StylesConfig<any, false, GroupBase<any>> = {
  container: (base) => ({
    ...base,
    width: '25rem',
    zIndex: 'unset',
  }),
};

const customStyles: StylesConfig<any, false, GroupBase<any>> = {
  container: (base) => ({
    ...base,
    width: '25rem',
  }),
  control: (base) => ({
    ...base,
    maxWidth: '1000px',
    minWidth: '550px',
  }),
  menu: (base) => ({
    ...base,
    marginTop: '3px',
  }),
  menuList: (base) => ({
    ...base,
    overflowX: 'hidden',
  }),
  input: (base) => ({
    ...base,
    height: '2rem',
    position: 'relative',
    top: '-1.2rem',
    fontSize: '1.4rem',
  }),
  valueContainer: (base) => ({
    ...base,
    gap: '6px',
    padding: '6px',
    minHeight: '2.5rem',
    // maxHeight: '20.5rem'
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data?.color ?? 'rgba(102, 102, 102, 0.1)',
      color: data?.color ? '#fff' : 'rgb(102, 102, 102)',
    };
  },
  multiValueLabel: (styles, { data }) => {
    return {
      ...styles,
      color: data?.color ? '#fff' : '#000',
    };
  },
  placeholder: (base) => ({
    ...base,
    color: '#777777',
    position: 'relative',
    top: '1px',
    fontWeight: 400,
    opacity: 0.85,
    fontSize: '1.4rem',
    lineHeight: 1,
  }),
  option: (base) => ({
    ...base,
    cursor: 'pointer',
    color: 'rgb(51, 51, 51)',
    height: '3rem',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word',
    padding: '4px 12px',
  }),
};

const defaultStyles: StylesConfig<any, false, GroupBase<any>> = {
  container: (base) => ({
    ...base,
    height: '4rem',
    width: '25rem',
    zIndex: 20,
  }),
  control: (base) => ({
    ...base,
    height: '4rem',
    padding: 0,
    backgroundColor: '#f0f3f6',
    borderColor: 'transparent',
  }),
  menu: (base) => ({
    ...base,
    marginBottom: '4px',
    marginTop: '4px',
  }),
  menuList: (base) => ({
    ...base,
    overflowX: 'hidden',
  }),
  input: (base) => ({
    ...base,
    height: '3rem',
    paddingTop: '3px',
    position: 'relative',
    top: '-8px',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    paddingLeft: '0.8rem',
    fontSize: '1.53rem',
  }),
  placeholder: (base) => ({
    ...base,
    color: 'rgb(51, 51, 51)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }),
  option: (base) => ({
    ...base,
    cursor: 'pointer',
    color: 'rgb(51, 51, 51)',
  }),
};

const labelCss = css`
  ${tw`flex column-gap[8px] text-16 font-medium text-sonnant-grey-6`}
`;

const customTooltipCss = css`
  ${tw`font-size[1rem]`}

  .notification {
    ${tw`lg-up:width[40rem]! md-down:(width[30rem] break-words)`}
  }
  .notificationWrapper {
    ${tw`margin-top[1rem]!`}
  }
`;
