import axios from 'axios';
import { getToken } from '../utils/utils';
import { config } from 'components/config';
import { Duration } from 'utils/constants';

const LambdaAPIConfig = () => {
  const defaultOptions = {
    baseURL: `${config.LIVE_ENV_URL}/api/endpoints`,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': window.location.origin,
    },
    timeout: Duration.minutes(20), // 15 min
  };

  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use(function (config) {
    const token = getToken();
    config.headers.Authorization = token?.AccessToken ?? '';

    return config;
  });

  return instance;
};

export const LambdaAPI = LambdaAPIConfig();
