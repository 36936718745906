/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { ReactComponent as AppleSvg } from 'assets/Icons/apple.svg';
import { ReactComponent as FacebookSvg } from 'assets/Icons/facebook.svg';
import { ReactComponent as GoogleSvg } from 'assets/Icons/google.svg';

import { MouseEvent, useEffect } from 'react';

import { loadAppleJsScript } from 'utils/scripts/loadAppleAuthScript';
import { loadFacebookSdkScript } from 'utils/scripts/loadFacebookAuthScript';
import { loadGoogleScriptV1 } from 'utils/scripts/loadGoogleScriptV1';
import { borderGradientBg } from '../twin.styles';

interface Props {
  text: string;
  handleLoginGoogleButton: (e: MouseEvent<HTMLDivElement>) => unknown;
  handleLoginAppleButton: (e: MouseEvent<HTMLDivElement>) => unknown;
  handleLoginFacebookButton: (e: MouseEvent<HTMLDivElement>) => unknown;
  hasOrDivider?: boolean;
}

export const AuthSocialButtons = ({
  text,
  handleLoginGoogleButton,
  handleLoginAppleButton,
  handleLoginFacebookButton,
  hasOrDivider,
}: Props) => {
  useEffect(() => {
    loadFacebookSdkScript();
    loadAppleJsScript();
    loadGoogleScriptV1();
  }, []);

  return (
    <>
      <div tw="height[44px] width[400px] flex justify-center sm-down:(w-full)">
        <div
          id="google-auth-button"
          onClick={handleLoginGoogleButton}
          tw="relative height[4rem]! w-full flex justify-center items-center color[#3c4043] text-16 font-medium cursor-pointer rounded-full hover:(shadow background-color[rgba(66,133,244,0.04)])!"
          css={[borderGradientBg('2px', 'white')]}
        >
          <GoogleSvg tw="absolute left[0.4rem]" />
          {text} with Google
        </div>
      </div>
      <div
        css={[socialButtonCss]}
        tw="mt-1 mb-2 bg-black text-white"
        onClick={handleLoginAppleButton}
      >
        <div tw="flex items-center">
          <AppleSvg tw="absolute width[2.8rem]! left[0.9rem]" />
          <span>{text} with Apple</span>
        </div>
      </div>
      <div
        css={[socialButtonCss]}
        tw="mt-1 background[#1877f2] text-white"
        onClick={handleLoginFacebookButton}
      >
        <div tw="flex items-center">
          <FacebookSvg tw="absolute width[2.8rem]! left[0.9rem]" />
          <span>{text} with Facebook</span>
        </div>
      </div>

      {hasOrDivider && (
        <div tw="text-sonnant-dark mt-12 -mb-8 font-medium width[10rem] text-center border-bottom[3px solid] line-height[0]">
          <span tw="bg-white px-3 relative top[2px]">or</span>
        </div>
      )}
    </>
  );
};

const socialButtonCss = css`
  ${tw`relative height[4rem] width[40rem] sm-down:(w-full min-width[25rem])! cursor-pointer hover:(shadow-lg) flex justify-center items-center font-medium text-16 rounded-full`}
`;
