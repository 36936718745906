/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useClickAway, useToggle } from 'react-use';
import { Term } from 'utils/models';
import { ReactComponent as CloseIcon } from 'assets/Icons/close.svg';
import { SimpleMenuSurface } from '@rmwc/menu';
import { Keys } from 'utils/enum';
import { Ripple } from '@rmwc/ripple';
import { AlterItem } from './AlterItem';
import { getTermsCount } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { RootState } from 'reducers';
import { uniqBy } from 'lodash';
import { v4 } from 'uuid';

interface IProps {
  id: number;
  term: Term;
  handleRemoveTerm: (term: Term) => unknown;
  handleUpdateTerm?: (term: Term) => unknown;
  setNewAlter?: (newTerm: Term[], id: string) => void;
  shouldShowAlter: boolean;
  editable?: boolean;
}

export const TermItem = ({ editable = true, ...props }: IProps) => {
  const { setNewAlter } = props;
  const alternatives = props.term.alternatives ?? [];

  const global = useSelector((state: RootState) => state.global);

  const [isEditing, toggleEditing] = useToggle(false);
  const [isShowInput, toggleInput] = useToggle(false);
  const [isShowAlter, toggleAlter] = useToggle(false);
  const [isShowDefault] = useToggle(true);

  const [newText, setNewText] = useState(props?.term.name);

  const inputRef = useRef(null);
  const hoverRef = useRef<any>(null);
  const [newAlterInput, setNewAlterInput] = useState('');

  useEffect(() => {
    if (!global?.focusCustomList) return;

    cancelEditing();
  }, [global.focusCustomList]);

  const handleInputKeyword = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const newTextTrim = newText?.replace(/\s+/g, ' ').trim();

    if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
      if (newTextTrim !== props.term.name) {
        props?.handleUpdateTerm?.({ ...props.term, name: newTextTrim });
        cancelEditing();
      }
    }
    if (e.key === Keys.ESC) {
      cancelEditing();
    }
  };

  const cancelEditing = () => {
    setNewText(props?.term.name);
    toggleEditing(false);
  };

  const resetInput = () => {
    setNewAlterInput('');
    toggleInput(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e.code === Keys.ESC) {
      resetInput();
    }
    if (e.code === Keys.ENTER || e.code === Keys.ENTER_NUMPAD) {
      if (newAlterInput) {
        const newTermsList = alternatives?.concat({
          id: v4(),
          name: newAlterInput,
          dateCreated: new Date(),
        });
        setNewAlter?.(validateTerms(newTermsList)!, props.term.id);
      }

      resetInput();
    }
  };

  const handleRemoveAlter = (id: string) => {
    const newAlterList = alternatives?.filter((alter) => alter.id !== id);
    setNewAlter?.(newAlterList!, props.term.id);
  };

  const enterEdit = (termId: string, newAlter: string) => {
    const newTermsList = alternatives?.map((t) => {
      if (t.id === termId) {
        return { ...t, name: newAlter };
      }
      return t;
    });
    setNewAlter?.(validateTerms(newTermsList), props.term.id);
  };

  const validateTerms = (alters: Term[]): Term[] => {
    const parentName = props?.term?.name?.trim().toLowerCase();
    const excludeParents = alters.filter(
      (alter) => alter.name?.trim().toLowerCase() !== parentName,
    );
    return uniqBy(excludeParents, 'name');
  };

  useClickAway(inputRef, () => {
    toggleAlter(false);
  });

  return isEditing ? (
    // INPUT MODE
    <div className="button keywordBtn keywordEdit" tw="p-0">
      <input
        type="text"
        value={newText}
        onChange={(e) => setNewText(e.target.value)}
        onKeyDown={handleInputKeyword}
        onBlur={cancelEditing}
        className="small-10 keywordInput"
        tw="text-13 rounded-r-none!"
        maxLength={128}
        autoFocus
      />
      <button
        onClick={cancelEditing}
        className="small-2 cancelBtn"
        tw="rounded-l-none!"
      >
        <CloseIcon className="whiteIcon" />
      </button>
    </div>
  ) : (
    // VIEW MODE
    <>
      <div
        tw="flex mr-0.5"
        onMouseLeave={() => {
          if (isShowInput) return;
          toggleAlter(false);
          clearTimeout(hoverRef.current);
        }}
      >
        <SimpleMenuSurface
          open={isShowAlter && props?.shouldShowAlter}
          // anchorCorner={'bottomStart'}
          onClose={resetInput}
          handle={
            <>
              <button
                type="button"
                className={`button keywordBtn btn-secondary ${
                  false && 'selectedKeyword'
                }`}
                onClick={() => {}}
                onDoubleClick={() => {
                  if (!editable) return;
                  
                  setNewText(props?.term.name);
                  toggleEditing(true);
                }}
                tw="md-down:font-size[1.4rem] flex items-center"
                onMouseEnter={() =>
                  (hoverRef.current = setTimeout(() => toggleAlter(true), 1000))
                }
              >
                <span
                  className="keywordval"
                  title={
                    // Only show on terms longer than 24 chars
                    props?.term?.name?.length > 24 ? props?.term?.name : ''
                  }
                >
                  {props?.term.name}
                </span>
                <div
                  className="closeButton"
                  onClick={() => props?.handleRemoveTerm(props?.term)}
                >
                  <CloseIcon />
                </div>
              </button>
            </>
          }
          tw="top[70%]!"
        >
          <div tw="max-width[30rem] min-width[12.5rem] p-4 top-5">
            {isShowDefault && (
              <AlterItem
                term={{
                  id: v4(),
                  name: props?.term?.name,
                  dateCreated: new Date(),
                }}
                isDefault
                enterEdit={enterEdit}
                handleRemoveAlter={handleRemoveAlter}
              />
            )}
            {alternatives?.map((term) => (
              <AlterItem
                term={term}
                key={term.id}
                enterEdit={enterEdit}
                handleRemoveAlter={handleRemoveAlter}
              />
            ))}
            {props?.shouldShowAlter &&
              getTermsCount(global.customTerms) < 1000 && (
                <>
                  {isShowInput ? (
                    <div>
                      <input
                        ref={inputRef}
                        tw="(text-2xl height[3rem] pl-3 mb-0 text-14 placeholder:(text-13))!"
                        type="text"
                        value={newAlterInput}
                        onChange={(e) => {
                          if (e.target.value.length <= 64) {
                            setNewAlterInput(e.target.value);
                          }
                        }}
                        placeholder="Add alternative"
                        onKeyDown={handleKeyDown}
                        autoFocus
                      />
                    </div>
                  ) : (
                    <Ripple>
                      <div
                        css={[itemStyles, tw`cursor-pointer`]}
                        tw="text-13 -mt-2"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          toggleInput(true);
                        }}
                      >
                        + Add Alternative
                      </div>
                    </Ripple>
                  )}
                </>
              )}
          </div>
        </SimpleMenuSurface>
      </div>
    </>
  );
};

const itemStyles = css`
  ${tw`flex justify-between items-center text-2xl font-medium opacity[.75] w-full cursor-pointer py-1 px-2 rounded`}
  &:hover {
    ${tw`bg-sonnant-grey-light`}
  }
`;
