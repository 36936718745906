import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDateRangeCalendar } from 'utils/date.util';
import { CalendarFilter, Layers, PinnedChartType } from 'utils/enum';
import { InsightState } from './insight.slice';

export type TrendingInsightState = Omit<
  InsightState,
  'pinnedCharts' | 'vocabListId' | 'calendarMode'
> & {
  calendarMode: CalendarFilter | null;
};

const insightTrendingSlice = createSlice({
  name: 'insight-trending',
  initialState: {
    id: PinnedChartType.ORIGINAL,
    title: 'Mentions Analytics',

    searchInputTerm: '',
    chartType: 'bar',
    calendarMode: null,

    vocabTerms: [],
    inputTerms: [],

    showDataLabels: false,
    showQuarterMarkers: false,
    groupByCollection: false,

    selectedTagIds: [],
    selectedCollectionIds: [],
    selectedOrgIds: [],

    selectedLayer: Layers.TRANSCRIPT,
    selectedMetadataLayer: Layers.KEY_TERM,
    dateRange: {
      dateSelected: null,
      startDate: null,
      endDate: null,
      focusedInput: null,
    },

    pinnedCharts: [],
  } as TrendingInsightState,
  reducers: {
    setDateRange: (
      state,
      {
        payload,
      }: PayloadAction<Pick<TrendingInsightState['dateRange'], 'dateSelected'>>,
    ) => {
      if (!payload.dateSelected) {
        state.dateRange = {
          dateSelected: null,
          startDate: null,
          endDate: null,
          focusedInput: null,
        };

        return;
      }

      const dateRangeFromDate = getDateRangeCalendar(
        payload.dateSelected,
        state.calendarMode,
      );

      state.dateRange = {
        ...dateRangeFromDate,
        dateSelected: payload.dateSelected,
        focusedInput: null,
      };
    },
    setSelectedCollectionIds: (
      state,
      { payload }: PayloadAction<TrendingInsightState['selectedCollectionIds']>,
    ) => {
      state.selectedCollectionIds = payload;
    },
    setCalendarMode: (
      state,
      { payload }: PayloadAction<TrendingInsightState['calendarMode']>,
    ) => {
      state.calendarMode = payload;

      if (!state.dateRange.dateSelected) return;

      const dateRangeFromDate = getDateRangeCalendar(
        state.dateRange.dateSelected,
        payload,
      );

      state.dateRange = {
        ...state.dateRange,
        ...dateRangeFromDate,
      };
    },
    toggleDataLabels: (state, action?: PayloadAction<boolean | undefined>) => {
      if (typeof action?.payload === 'boolean') {
        state.showDataLabels = action.payload;
      } else {
        state.showDataLabels = !state.showDataLabels;
      }
    },
  },
});

export const {
  setDateRange,
  setSelectedCollectionIds,
  setCalendarMode,
  toggleDataLabels,
} = insightTrendingSlice.actions;

export const insightTrendingReducer = insightTrendingSlice.reducer;
