import React from "react";
import { Link } from "react-router-dom";
import "./success.scss";

interface IProps {
 location: LocationType
}

interface LocationType {
  state: StateType
}

type StateType = {
  name: string;
  message: string;
  checkmessage: any;
  type: any;
  route: any;
}

export const Message = (props: IProps) => {
  const name = props.location.state && props.location.state.name;
  const message = props.location.state && props.location.state.message;
  const checkmessage =
    props.location.state && props.location.state.checkmessage;
  const type = props.location.state && props.location.state.type;
  const route = props.location.state && props.location.state.route;
  return name ? (
    <div className="grid-x successNotification">
      <div className="small-4 mgn-auto">
        <div className="notificationContainer">
          <h1>{`${name} ${type}`}</h1>
          <h3>{message}</h3>
          <h3>{checkmessage}</h3>
        </div>
        {route && (
          <Link to={route}>
            <button>Return to Sign up</button>
          </Link>
        )}
      </div>
    </div>
  ) : null;
};
