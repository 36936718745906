/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import {
  cloneDeep,
  debounce,
  defaultTo,
  isEmpty,
  isEqual,
  set,
  trim,
} from 'lodash';
import {
  ReactElement,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useToggle } from 'react-use';

import { defaultStyles } from 'components/Preferences/Integrations/IntegrationFilter';
import { Modal } from 'components/UiControls/modal/modal';
import { toProgramIdOptions } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { useStateReset } from 'hooks/useStateReset';
import { MegaphoneService } from 'services/megaphone.service';
import { Dropdown, ModalOptions } from 'utils/enum';
import { ProgramIdOption } from 'utils/models';
import { CollectionDropdown } from '../CollectionDropdown/CollectionDropdown';
import { CustomSelectSearch } from '../CustomSelectSearch';

type IPromiseParams = {
  resolve: (payload: MegaphoneBulkUploadModalResult) => void;
  reject: (err: any) => void;
};

type Props = {};

type MegaphoneBulkUploadPayload = {
  apiKey: string;
  networkId: string;
  programId: string;
  collectionId: string | null;
};

type MegaphoneBulkUploadModalResult = Partial<MegaphoneBulkUploadPayload> & {
  option: ModalOptions;
};

export type MegaphoneBulkUploadModalRef = {
  show: () => Promise<MegaphoneBulkUploadModalResult>;
};

export const MegaphoneBulkUploadModal = forwardRef(
  (props: Props, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const promiseInfo = useRef<IPromiseParams>({
      resolve: (payload: MegaphoneBulkUploadModalResult) => {},
      reject: () => {},
    });

    const initialState: MegaphoneBulkUploadPayload = {
      apiKey: '',
      networkId: '',
      programId: '',
      collectionId: null,
    };

    const [state, setState, resetState] =
      useStateReset<MegaphoneBulkUploadPayload>(initialState);

    const [isOpen, toggleIsOpen] = useToggle(false);
    const [isLoading, toggleIsLoading] = useToggle(false);

    const [programIdOptions, setProgramIdOptions] = useState<ProgramIdOption[]>(
      [],
    );

    const isRequiredFieldsEmpty =
      isEmpty(state.apiKey) ||
      isEmpty(state.networkId) ||
      isEmpty(state.programId);

    const isDisabledPreview = isRequiredFieldsEmpty || isLoading;

    const programLabel = useMemo(() => {
      if (isEmpty(programIdOptions) || isEmpty(state.programId)) return '';

      return programIdOptions.find(({ value }) => value === state.programId)
        ?.label;
    }, [programIdOptions, state.programId]);

    useEffect(() => {
      const trimmedApiKey = trim(state.apiKey || '');
      const trimmedNetworkId = trim(state.networkId || '');

      if (!trimmedApiKey || !trimmedNetworkId) {
        setProgramIdOptions([]);

        return;
      }

      debounceGetProgramIds(trimmedApiKey, trimmedNetworkId);
    }, [state.apiKey, state.networkId]);

    const show = async (): Promise<unknown> => {
      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        toggleIsOpen(true);
      });
    };

    const hideModal = () => {
      toggleIsOpen(false);

      resetState();
    };

    const handleModalClose = () => {
      hideModal();

      promiseInfo.current?.resolve({ option: ModalOptions.CLOSE });
    };

    const handleChangeInput = (
      e: React.ChangeEvent<HTMLInputElement>,
      key: keyof MegaphoneBulkUploadPayload,
    ) => {
      const updatedState = cloneDeep(state);

      set(updatedState, key, e.target.value);

      if (isEqual(key, 'networkId') || isEqual(key, 'apiKey')) {
        set(updatedState, 'programId', '');
      }

      setState(updatedState);
    };

    const updateStateField = (
      field: keyof MegaphoneBulkUploadPayload,
      value: string | null,
    ) => {
      const updatedState = cloneDeep(state);

      set(updatedState, field, value);

      setState(updatedState);
    };

    const handleSelectProgram = (option: ProgramIdOption) => {
      updateStateField('programId', option.value);
    };

    const handleSelectCollection = (collectionId: string) => {
      updateStateField('collectionId', collectionId);
    };

    const handleGetProgramIdsAsync = async (
      apiKey: string,
      networkId: string,
    ) => {
      try {
        toggleIsLoading(true);

        const res = await MegaphoneService.getProgramIds({
          apiKey,
          networkId,
        });

        setProgramIdOptions(toProgramIdOptions(res.data));
      } catch (err) {
        console.log('[LOG] err: >>>', err);

        setProgramIdOptions([]);
      } finally {
        toggleIsLoading(false);
      }
    };

    const debounceGetProgramIds = useCallback(
      debounce(handleGetProgramIdsAsync, 400),
      [],
    );

    const handlePreview = () => {
      promiseInfo.current?.resolve({
        option: ModalOptions.YES,
        apiKey: state.apiKey,
        networkId: state.networkId,
        programId: state.programId,
        collectionId: state.collectionId,
      });

      hideModal();
    };

    const renderCustomOption = (option: ProgramIdOption) => (
      <div tw="flex flex-row">
        <div tw="max-w-[5.5rem] mr-4 flex flex-basis[5.5rem]">
          <img alt="preview-item-alt" src={option.imageURL} />
        </div>

        <div tw="flex flex-col w-full">
          <div
            tw="text-15 font-black text-left text-sonnant-dark break-words"
            className="line-clamp-2"
          >
            {option.label}
          </div>
          <div tw="text-12">{option.value}</div>
        </div>
      </div>
    );

    return (
      <Modal show={isOpen} modalClosed={handleModalClose}>
        <form tw="px-2" autoComplete="off" onSubmit={(e) => e.preventDefault()}>
          <h1 tw="font-semibold text-sonnant-dark text-[3.5rem]">
            Megaphone bulk upload
          </h1>

          <div tw="flex flex-col mt-5 pb-3 pt-0!">
            <div tw="flex flex-row items-center">
              <label tw="flex flex-nowrap text-16 mr-3">API Key</label>
            </div>

            <div tw="flex flex-row max-w-[75rem]">
              <div tw="flex-1 relative">
                <input
                  tw="flex pr-[4rem]!"
                  type="text"
                  value={defaultTo(state.apiKey, '')}
                  onChange={(e) => handleChangeInput(e, 'apiKey')}
                  placeholder="Please enter your API Key"
                />
              </div>
            </div>
          </div>

          <div tw="flex flex-row gap-x-4 pb-6 pt-0!">
            <div tw="flex flex-col flex-1">
              <div tw="flex flex-row items-center">
                <label tw="flex flex-nowrap text-16 mr-3">Network ID</label>
              </div>

              <div tw="flex flex-row max-w-[75rem]">
                <div tw="flex-1 relative">
                  <input
                    tw="flex pr-[4rem]!"
                    type="text"
                    value={defaultTo(state.networkId, '')}
                    onChange={(e) => handleChangeInput(e, 'networkId')}
                    placeholder="Please enter your Network ID"
                  />
                </div>
              </div>
            </div>

            <div tw="flex flex-col flex-1">
              <div tw="flex flex-row items-center">
                <label tw="flex flex-nowrap text-16 mr-3">Program</label>
              </div>

              <div tw="flex-1 relative w-full">
                <CustomSelectSearch
                  options={programIdOptions}
                  defaultValue={programLabel}
                  isLoading={isLoading}
                  customStyles={defaultStyles}
                  formatOptionLabel={renderCustomOption}
                  onChange={handleSelectProgram}
                  canCreate={false}
                  mode={Dropdown.SIMPLE_TEXT}
                />
              </div>
            </div>
          </div>

          <div tw="flex [> div]:(flex text-16 font-medium text-sonnant-grey-6)!">
            <div tw="flex-col">
              <div tw="mb-2">Add to collection</div>
              <div>
                <CollectionDropdown
                  defaultValue={defaultTo(state.collectionId, '')}
                  onChange={handleSelectCollection}
                  menuPlacement="bottom"
                  isMultiSelect={false}
                />
              </div>
            </div>
          </div>
          <div tw="flex flex-col pb-3 pt-0!">
            <div tw="flex justify-end">
              <button
                type="button"
                className="button btn-secondary large"
                tw="mr-6 hover:(border-sonnant-grey-3!)"
                onClick={handleModalClose}
              >
                Cancel
              </button>

              <button
                type="button"
                className="button btn-primary large"
                onClick={handlePreview}
                disabled={isDisabledPreview}
              >
                Preview
              </button>
            </div>
          </div>
        </form>
      </Modal>
    );
  },
);
