/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';

import Loader from 'components/loader/loader';
import { Separator } from 'components/shared/Separator';
import { PortalAccessPane } from './components/PortalAccessPane';
import { PortalUsersPane } from './components/PortalUsersPane';
import { PortalGroupsPane } from './components/PortalGroupsPane';
import { useUnmount } from 'react-use';
import { fetchCollections } from 'slices/collection.slice';
import { toggleRefetchCollections } from 'slices/admin-portal.slice';

type Props = {};

export const AdminPortal = (props: Props) => {
  const dispatch = useDispatch();
  const adminPortal = useSelector((state: RootState) => state.adminPortal);

  const isLoadingAdminPortal = adminPortal.isLoading;

  useUnmount(() => {
    if (!adminPortal.shouldRefetchCollections) return;

    dispatch(fetchCollections());
    dispatch(toggleRefetchCollections(false));
  });

  return (
    <div tw="p-[3rem] h-[calc(100vh - 7rem)] overflow-y-auto">
      <div tw="text-sonnant-dark font-semibold font-size[3rem]">
        Admin Portal
      </div>

      <div tw="pt-10">
        {isLoadingAdminPortal && (
          <div className="loader__component" tw="top-[7rem]">
            <Loader />
          </div>
        )}

        <div tw="flex flex-wrap justify-between">
          <PortalGroupsPane />
          <Separator />

          <PortalUsersPane />
          <Separator />

          <PortalAccessPane />
        </div>
      </div>
    </div>
  );
};
