/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { ReactComponent as AddSvg } from 'assets/Icons/add_circle.svg';
import {
  CustomSelectSearch,
  Option,
} from 'components/shared/CustomSelectSearch';
import {
  AddEditTermModal,
} from 'components/shared/CutomTermsModal/AddEditTermModal';
import { MentionReportModal } from 'components/shared/MentionReport/MentionReportModal';
import {
  greyScrollbar,
  greyScrollbarVertical,
  scrollbarWidthCss,
} from 'components/shared/twin.styles';
import { VirtualTable } from 'components/shared/VirtualTable/VirtualTable';
import { cloneDeep, isEmpty, isNil, min, toNumber } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { ReactComponent as MinusSvg } from 'assets/Icons/minus_circle.svg';

import {
  setFocusLayerCustomTerm,
  setFocusTerm,
  setSingleSelectedTerms,
  updateLayerCustomerTerm,
} from 'slices/player.slice';
import { CustomTermType, ModalOptions, Mode } from 'utils/enum';
import { IKeyItem, LayerCustomTerm, MentionLayerData } from 'utils/models';
import { BasicConfirmModal, TermListModal, VocabEditablePayload } from 'utils/models/modal.model';
import { Keyword } from '../../Keyword/Keyword';
import {
  getNewFocusList,
  handleGetMentionReportData,
  toCustomVocab,
} from '../../MediaUtilities';
import { MENTION_COLUMN_HEADERS } from './columns';
import { MentionReportRow } from './MentionReportRow';
import { HintDisallowed } from 'components/shared/HintDisallowed';

type Props = {};

export const MentionReportLayer = (props: Props) => {
  const dispatch = useDispatch();

  const media = useSelector((state: RootState) => state.media);
  const toggle = useSelector((state: RootState) => state.toggle);
  const player = useSelector((state: RootState) => state.player);
  const iab = useSelector((state: RootState) => state.iab);

  const [mentionLayerData, setMentionLayerData] = useState<MentionLayerData[]>(
    [],
  );

  const [appliedListOptions, setAppliedListOptions] = useState<Option[]>([]);
  const [currentAppliedListOption, setCurrentAppliedListOption] =
    useState<Option>();

  const [appliedList, setAppliedList] = useState<LayerCustomTerm[]>([]);
  const [currentAppliedList, setCurrentAppliedList] =
    useState<LayerCustomTerm>();
  const [indexScrollTo, setIndexScrollTo] = useState<number | null>(null);
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const addEditModalRef = useRef<TermListModal>(null);
  const mentionReportModalRef = useRef<BasicConfirmModal>(null);

  useEffect(() => {
    if (isNil(player.focusLayerCustomTermId) || isNil(window?.Editor)) return;

    const focusList = player.layerCustomTerms?.find(
      (t) => t?.listId === player?.focusLayerCustomTermId,
    );

    if (isNil(focusList)) return;

    const mentionData = handleGetMentionReportData(
      focusList,
      iab.lockedList,
      media?.metadata?.mediaid,
    );
    setMentionLayerData(mentionData);
  }, [player.focusLayerCustomTermId, player.layerCustomTerms, window.Editor]);

  useEffect(() => {
    const customList =
      player.layerCustomTerms?.find(
        (t) => t?.listId === player?.focusLayerCustomTermId,
      )?.matched ?? [];

    dispatch(setFocusTerm(customList));
  }, [player.focusLayerCustomTermId, player.layerCustomTerms]);

  useEffect(() => {
    if (isNil(player.layerCustomTerms)) return;

    const activeAppliedList = player.layerCustomTerms.filter((l) => l?.active);

    setAppliedList(activeAppliedList);

    const appliedListOptions = activeAppliedList.map((list) => ({
      value: list.listId,
      label: list.listName,
    }));

    setAppliedListOptions(appliedListOptions);

    const oldAppliedListOptions = appliedListOptions.find(
      (o) => o.value === currentAppliedListOption?.value,
    );

    setCurrentAppliedListOption(oldAppliedListOptions ?? appliedListOptions[0]);

    if (isNil(currentAppliedList)) setCurrentAppliedList(activeAppliedList[0]);
  }, [player.layerCustomTerms]);

  const handleMentionModal = async () => {
    await mentionReportModalRef.current?.show();
  };

  const handleTermListModal = async (appliedList?: LayerCustomTerm) => {
    if (isNil(appliedList)) return;
    const result = await addEditModalRef.current?.show({
      mode: Mode.EDIT,
      type: CustomTermType.APPLIED,
      item: toCustomVocab(appliedList),
    } as VocabEditablePayload);

    if (result?.option !== ModalOptions.YES) return;

    const foundList = cloneDeep(
      player.layerCustomTerms?.find(
        ({ listId }) => listId === appliedList?.listId,
      ),
    );

    const newList = await getNewFocusList(
      result,
      media?.metadata?.mediaid,
      player,
      foundList,
    );

    if (isNil(newList)) return;

    const newCustomList = {
      ...newList,
      listName: result.payload?.name,
    };

    setCurrentAppliedList(newCustomList);

    dispatch(updateLayerCustomerTerm(newCustomList));
  };

  const handleRemoveItem = (term: IKeyItem) => {
    const newMentionReportData = mentionLayerData.filter(
      (m) => m?.term !== term?.keyword,
    );

    if (isNil(currentAppliedList)) return;

    const newCurrentList = {
      ...currentAppliedList,
      matched: currentAppliedList.matched.filter(
        (t) => t?.keyword !== term?.keyword,
      ),
    };

    setCurrentAppliedList(newCurrentList);
    dispatch(updateLayerCustomerTerm(newCurrentList));
    setMentionLayerData(newMentionReportData);
  };

  const handleChangeAppliedList = (newValue: any) => {
    const payload = appliedList.find((l) => l.listId === newValue.value);

    if (isNil(payload)) return;

    setCurrentAppliedListOption({
      value: payload.listId,
      label: payload.listName,
    });
    setCurrentAppliedList(payload);
    dispatch(setFocusLayerCustomTerm(payload));
  };

  const handleClickKeyword = (item: IKeyItem) => {
    dispatch(setSingleSelectedTerms(item));

    const foundIndex = mentionLayerData.findIndex(
      (m) => m?.term === item?.keyword,
    );

    if (foundIndex === -1) return;

    const VISIBLE_ROWS_ABOVE = 4;

    // To make sure the first occurrence at top
    const scrollIndex =
      foundIndex <= VISIBLE_ROWS_ABOVE
        ? foundIndex
        : foundIndex + VISIBLE_ROWS_ABOVE;

    setIndexScrollTo(min([scrollIndex, mentionLayerData.length]) as number);
  };

  const handleSelectRow = (id: string) => {
    if (selectedRowIds.includes(id)) {
      setSelectedRowIds(selectedRowIds.filter((rowId) => rowId !== id));
    } else {
      setSelectedRowIds([...selectedRowIds, id]);
    }
  };

  const handleRemoveRow = () => {
    const deletedTerms = mentionLayerData.filter((row) =>
      selectedRowIds.includes(row?.id),
    );

    const newMentionReportData = mentionLayerData.filter(
      (row) => !selectedRowIds.includes(row?.id),
    );

    const newCurrentTerms = [...player.focusTerms];

    deletedTerms.forEach((term) => {
      const foundIndex = newCurrentTerms.findIndex(
        (t) => t?.keyword === term?.term,
      );

      if (foundIndex < 0) return; // Not found

      const newMentions = cloneDeep(newCurrentTerms[foundIndex]?.mentions);

      const foundOccIndex = newMentions[0].occurrences.findIndex(
        (occ) =>
          term?.startTime <= toNumber(occ?.s) &&
          toNumber(occ?.e) <= term?.endTime,
      );

      if (foundOccIndex < 0) return;

      newMentions[0].occurrences.splice(foundOccIndex, 1);

      newCurrentTerms[foundIndex] = {
        ...newCurrentTerms[foundIndex],
        mentions: [...newMentions],
      };
    });

    dispatch(setFocusTerm(newCurrentTerms));

    setMentionLayerData(newMentionReportData);
    setSelectedRowIds([]);
  };

  return (
    <div tw="height[calc(100vh - 21rem)] flex flex-col pt-4 mr-2">
      <div tw="flex gap-x-3 w-[100%] justify-between text-[1.5rem] mb-1.5">
        <div>
          <div>
            <div tw="flex space-x-3">
              <div tw="font-medium text-sonnant-blue-dark">Reporting terms</div>

              <HintDisallowed
                hintEnabled="Add new report terms"
                hintDisabled="Edit mode is off"
                disabled={!toggle.isEditMode}
              >
                <div
                  tw="cursor-pointer flex"
                  className={`${
                    (isEmpty(appliedListOptions) || !toggle.isEditMode) &&
                    'disabled'
                  }`}
                  onClick={() => handleTermListModal(currentAppliedList)}
                >
                  <AddSvg fill="#5551FF" />
                </div>
              </HintDisallowed>

              <HintDisallowed
                disabled={isEmpty(selectedRowIds)}
                hintEnabled="Removed selected mention(s)"
                hintDisabled="None item selected"
                tw="flex items-center"
                onClick={handleRemoveRow}
              >
                <MinusSvg
                  tw="cursor-pointer"
                  fill="#5551FF"
                  width={20}
                  height={20}
                />
              </HintDisallowed>
            </div>
          </div>

          <div
            tw="flex flex-wrap max-h-[10.4rem] overflow-y-auto mt-[4px]"
            css={[
              greyScrollbarVertical,
              scrollbarWidthCss(3),
              customKeywordCss,
            ]}
          >
            {player.focusTerms.map((item, key) => (
              <Keyword
                val={item}
                key={key}
                index={key}
                isEditingTranscript={toggle.isEditMode}
                handleRemoveKeyword={(e: Event) => {
                  e.stopPropagation();
                  handleRemoveItem(item);
                }}
                disableEdit
                setCurrentKeyword={() => handleClickKeyword(item)}
                showMentionModal={handleMentionModal}
              />
            ))}
          </div>
        </div>

        <div tw="font-medium pr-1">
          <div tw="text-right">Custom List</div>
          <CustomSelectSearch
            defaultValue={currentAppliedListOption?.label}
            canCreate={false}
            onChange={handleChangeAppliedList}
            options={appliedListOptions}
          />
        </div>
      </div>

      <div tw="flex-grow[1] overflow-y-auto" css={[greyScrollbar]}>
        <VirtualTable
          columns={MENTION_COLUMN_HEADERS}
          tableData={mentionLayerData}
          noDataMessage="There is no Mention report detected for this custom list"
          tableRow={(row, index) => (
            <MentionReportRow
              key={index}
              itemRow={row as any}
              selectedRowIds={selectedRowIds}
              handleSelectRow={handleSelectRow}
            />
          )}
          stickyHeader
          indexScrollTo={indexScrollTo}
        />
      </div>

      <AddEditTermModal ref={addEditModalRef} isAppliedList />
      <MentionReportModal ref={mentionReportModalRef} isAppliedList />
    </div>
  );
};

const customKeywordCss = css`
  .keywordBtn {
    ${tw`min-w-[8rem]`}
  }
`;
