import { useSearchParam } from 'react-use';
import { EmbedParams } from 'utils/models';

export const useEmbedParams = (): EmbedParams => {
  const hasSummary = useSearchParam('summary') === 'true';
  const hasTranscript = useSearchParam('transcript') === 'true';
  const hasPlayer = useSearchParam('player') === 'true';
  const hasKeyTerms = useSearchParam('keyterms') === 'true';
  const hasExport = useSearchParam('export') === 'true';
  const hasShare = useSearchParam('share') === 'true';
  const hasLogo = useSearchParam('logo') === 'true';

  return {
    hasSummary,
    hasTranscript,
    hasPlayer,
    hasKeyTerms,
    hasExport,
    hasShare,
    hasLogo,
  };
};
