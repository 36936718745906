import { AxiosResponse } from 'axios';

import { BaseAPI } from 'apis/amazon';

export type GetMegaphoneProgramIdsParams = {
  apiKey: string;
  networkId: string;
};

export type MegaphoneProgramIdsResponse = {
  network_id: string;
  program_id: string;
  title: string;
  image_public_url: string;
  summary: string;
  slug: string | null;
  episodes_count: number;
};

export const MegaphoneService = {
  getProgramIds: (
    params: GetMegaphoneProgramIdsParams,
  ): Promise<AxiosResponse<MegaphoneProgramIdsResponse[]>> => {
    return BaseAPI.get('/upload/megaphone/preview', {
      params: {
        apikey: params.apiKey,
        networkid: params.networkId,
      },
    });
  },
};
