/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { DateSingleInput } from '@datepicker-react/styled';
import { debounce } from 'lodash';
import { useToggle } from 'react-use';
import { resetReactRangeCss, simpleMenuDisabled } from '../twin.styles';

type Props = {
  dateSelected: Date | null;
  disabled?: boolean;
  onDateChange: (date: Date | null) => void;
};

export const DatePickerSingleInput = ({
  dateSelected,
  disabled = false,
  onDateChange,
}: Props) => {
  const [isShowDatePicker, setIsShowDatePicker] = useToggle(false);

  return (
    <span css={[resetReactRangeCss, disabled && simpleMenuDisabled]}>
      <DateSingleInput
        onDateChange={debounce(({ date, showDatepicker }) => {
          onDateChange(date);

          setIsShowDatePicker(showDatepicker);
        }, 0)}
        onFocusChange={(isShowDatepicker) => {
          setIsShowDatePicker(isShowDatepicker);
        }}
        date={dateSelected} // Date or null
        showDatepicker={isShowDatePicker} // boolean
        displayFormat="dd/MM/yyyy"
        maxBookingDate={new Date()}
        inputId="dateSingleInputId"
      />
    </span>
  );
};
