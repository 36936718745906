/** @jsxImportSource @emotion/react */
import 'twin.macro';

import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { CollectionType } from 'utils/enum';
import { ReactComponent as StandardCollectionSvg } from 'assets/Icons/standard-collection.svg';
import { ReactComponent as SmartCollectionSvg } from 'assets/Icons/smart-collection.svg';
import { TextInput } from 'components/shared/TextInput';
import {
  addNewCollection,
  deleteCollection,
  hideAddingInput,
  updateNewAddedCollectionId,
} from 'slices/collection.slice';
import { useClickAway } from 'react-use';
import { isNil } from 'lodash';
import { customToast } from 'utils/toast.util';
import { CollectionService } from 'services/collection.service';
import { hasExistedName } from 'utils/utils';
import { v4 } from 'uuid';
import { AxiosError } from 'axios';

interface Props {}

export const AddCollectionInput = (props: Props) => {
  const dispatch = useDispatch();

  const collection = useSelector((state: RootState) => state.collection);

  const [newCollectionName, setNewCollectionName] = useState<string>('');

  const wrapperRef = useRef<HTMLDivElement>(null);

  useClickAway(wrapperRef, () => {
    if (isNil(collection.newCollectionType)) return;

    exitAddingMode();
  });

  const handleEnter = async (newCollectionName: string) => {
    const collectionName = newCollectionName?.trim();

    if (!collectionName) {
      customToast.error('Collection name is required');
      return;
    }

    // Collection name must be unique
    if (hasExistedName(collection.items, collectionName)) {
      return;
    }

    exitAddingMode();

    const addCollectionAsync = CollectionService.createCollection({
      collection_name: collectionName,
    });

    const randomId = v4();

    try {
      // Add placeholder collection
      dispatch(
        addNewCollection({
          id: randomId,
          type: CollectionType.STANDARD,
          name: collectionName,
          order: (collection.items.length + 1).toString(),
        }),
      );

      customToast.loading('Creating collection...');
      const { data: collectionId } = await addCollectionAsync;

      customToast.success(
        <div>
          <b>{collectionName}</b> created successfully.
        </div>,
      );

      // Replace with real collection id
      dispatch(
        updateNewAddedCollectionId({
          oldId: randomId,
          newId: collectionId,
        }),
      );
    } catch (err) {
      // Rollback if create collection failed
      dispatch(deleteCollection(randomId));
      console.log('err', err);

      const errorMessage = (err as AxiosError)?.response?.data;

      errorMessage && customToast.error(errorMessage);
    }
  };

  const handleEsc = () => {
    exitAddingMode();
  };

  const exitAddingMode = () => {
    setNewCollectionName('');
    dispatch(hideAddingInput());
  };

  return (
    <div
      tw="flex items-center justify-between w-full mt-0 ml-1 px-6"
      ref={wrapperRef}
    >
      <span tw="flex flex-1">
        <span tw="flex items-center">
          {collection.newCollectionType === CollectionType.SMART ? (
            <SmartCollectionSvg />
          ) : (
            <StandardCollectionSvg />
          )}
        </span>
        <span tw="ml-2">
          <TextInput
            defaultText={newCollectionName}
            placeholder="Your collection name"
            onPressEnter={handleEnter}
            onPressEsc={handleEsc}
            tw="(text-15 padding-left[6px] height[3.5rem] font-medium placeholder:(font-light text-14))!"
          />
        </span>
      </span>
    </div>
  );
};
