/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { css } from '@emotion/react';
import { Row } from '@tanstack/react-table';
import { ReactComponent as AdMarkerSvg } from 'assets/Icons/ad-marker.svg';
import { ReactComponent as CopySvg } from 'assets/Icons/copy_url.svg';
import { ReactComponent as ChevronSvg } from 'assets/Icons/chervon.svg';
import { PrettyCheckbox } from 'components/shared/PrettyCheckbox';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useCopyToClipboard, useToggle } from 'react-use';
import { RootState } from 'reducers';
import tw from 'twin.macro';
import { AdmarkerLayerPayload } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { msToTime, scrollToNearestNode } from '../../MediaUtilities';
import { Hint } from 'components/shared/Hint';
import {
  lineCss,
  minimizedRowCss,
  roundedRippleHoverCss,
  timecodeEditable,
} from 'components/shared/twin.styles';
import { AdMarkerEnum } from 'utils/enum';
import { ReactEditor } from 'slate-react';
interface IProps {
  itemRow: Row<AdmarkerLayerPayload>;
  setLibraryItem?: any;
  handleSelectAdmarker: (item: AdmarkerLayerPayload) => void;
  handleDoubleClickAdMarker: (item: AdmarkerLayerPayload) => void;
  selectedAdmarker: number[];
  index: number;
}

export const AdMarkerRow = memo(function AdMarkerKMemorized({
  itemRow,
  selectedAdmarker,
  handleSelectAdmarker,
  handleDoubleClickAdMarker,
}: IProps) {
  const [, copyToClipboard] = useCopyToClipboard();

  const adMarkerItem = itemRow.original;
  const cells = itemRow.getVisibleCells();
  const isSelected = selectedAdmarker.includes(adMarkerItem.startTime);

  const toggle = useSelector((state: RootState) => state.toggle);

  const [isHovering, toggleHovering] = useToggle(false);
  const [isMinimized, toggleMinimized] = useToggle(true);

  const isPreOrPostRoll =
    adMarkerItem.marker.includes(AdMarkerEnum.PRE_ROLL) ||
    adMarkerItem.marker.includes(AdMarkerEnum.POST_ROLL);

  const handleCopyLink = () => {
    copyToClipboard(adMarkerItem.url);
    customToast.success('Copied to clipboard');
  };

  const isReadOnly = ReactEditor.isReadOnly(window.Editor);

  const handleOnClickAdmarker = (isAdmarkerIcon?: boolean) => {
    if (toggle.isEditMode && !isAdmarkerIcon) return;

    scrollToNearestNode(adMarkerItem.startTime);
    window.Video.currentTime = adMarkerItem.startTime;
  };

  return (
    <tr
      onMouseEnter={() => toggleHovering(true)}
      onMouseLeave={() => toggleHovering(false)}
      css={[lineCss, minimizedRowCss(isMinimized)]}
      onClick={() => handleOnClickAdmarker()}
    >
      <td
        tw="min-width[30px] max-width[40px]"
        key={cells[0]?.id}
        style={{ width: cells[0]?.column?.getSize() }}
      >
        {toggle.isEditMode && (isHovering || isSelected) && (
          <PrettyCheckbox
            checkboxCss={css`
              top: calc(50% - 0.9rem) !important;
            `}
            checked={isSelected}
            onChange={() => handleSelectAdmarker(adMarkerItem)}
          />
        )}
      </td>
      <td
        key={cells[1]?.id}
        style={{ width: cells[1]?.column?.getSize() }}
        onClick={() => handleOnClickAdmarker(true)}
      >
        <span tw="flex items-center w-full">
          <AdMarkerSvg css={isPreOrPostRoll && tw`fill[#E7483D]`} />
        </span>
      </td>
      <td
        key={cells[2]?.id}
        style={{ width: cells[2]?.column?.getSize() }}
        className="bottom"
        tabIndex={1}
        tw="min-width[52px]"
      >
        <div>{adMarkerItem.marker}</div>
      </td>
      <td
        key={cells[3]?.id}
        style={{ width: cells[3]?.column?.getSize(), textAlign: 'center' }}
        className="bottom"
        tabIndex={1}
        onDoubleClick={() => {
          if (isReadOnly) {
            customToast.error('Edit mode is off');
            return;
          }
          handleDoubleClickAdMarker(adMarkerItem);
        }}
      >
        <div
          css={[!isReadOnly && timecodeEditable]}
          title="Double click to edit"
          tw="cursor-pointer lg-down:(w-full) whitespace-nowrap"
        >
          {msToTime(adMarkerItem.startTime * 1000)}
        </div>
      </td>
      <td
        key={cells[4]?.id}
        style={{ width: cells[4]?.column?.getSize(), textAlign: 'center' }}
      >
        {adMarkerItem.adsServed}
      </td>
      <td style={{ width: cells[5]?.column?.getSize() }} key={cells[5]?.id}>
        <div className="long-text">{adMarkerItem.tags}</div>
      </td>
      <td
        key={cells[6]?.id}
        style={{ width: cells[6]?.column?.getSize() }}
        tabIndex={1}
      >
        <div className="long-text">{adMarkerItem.iab}</div>
      </td>
      <td
        key={cells[7]?.id}
        style={{ width: cells[7]?.column?.getSize() }}
        tabIndex={1}
      >
        <div className="long-text" tw="px-4">
          {adMarkerItem.transcriptPrior}
        </div>
      </td>
      <td
        key={cells[8]?.id}
        style={{ width: cells[8]?.column?.getSize() }}
        tabIndex={1}
      >
        <div className="long-text" tw="px-4">
          {adMarkerItem.transcriptAfter}
        </div>
      </td>
      <td
        key={cells[9]?.id}
        style={{ width: cells[9]?.column?.getSize(), textAlign: 'center' }}
        tw="min-width[80px] cursor-pointer"
        tabIndex={1}
        className="bottom"
      >
        <Hint text="Copy item URL" enterDelay={100} leaveDelay={0}>
          <div css={[roundedRippleHoverCss]}>
            <CopySvg onClick={handleCopyLink} width={20} height={20} />
          </div>
        </Hint>

        <Hint
          text={isMinimized ? 'Show more' : 'Show less'}
          enterDelay={100}
          leaveDelay={0}
        >
          <div css={[roundedRippleHoverCss]} onClick={toggleMinimized}>
            <ChevronSvg
              tw="transition[all 0.3s ease-in-out]"
              css={[!isMinimized && tw`transform[rotate(180deg)]`]}
              width={16}
              height={16}
            />
          </div>
        </Hint>
      </td>
    </tr>
  );
});

