/** @jsxImportSource @emotion/react */
import 'twin.macro';
import SystemDownImg from 'assets/Icons/system-down.png';


interface Props {}

export const MaintenancePage = (props: Props) => {
  return (
    <div tw="height[calc(100vh - 70px)]! text-sonnant-blue font-bold text-center font-size[2.4rem] xl-up:font-size[4rem] flex flex-col justify-between py-16 px-8 background[#E7E7E7] line-height[1.4]">
      <div>Sonnant is currently down for scheduled maintenance.</div>
      <div tw="w-full flex justify-center">
        <img
          src={SystemDownImg}
          alt="System down for maintenance"
          tw="m-auto max-height[60vh] max-width[80vw]"
        />
      </div>
      <div>We can't wait to receive your uploads again.</div>
    </div>
  );
};
