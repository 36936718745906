import { SagaAction } from 'utils/models';
import * as types from '../actions/types';

interface InitialStateType {
  loading: boolean;
  error: boolean;
  data: {
    mediaid: string;
    versions: any;
  };
  statusCode: null;
}

const initialState = {
  loading: false,
  error: false,
  data: {
    mediaid: '',
    versions: [],
  },
  statusCode: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export const getPublishedVersionReducer = (
  state: InitialStateType = initialState,
  action: SagaAction,
) => {
  switch (action.type) {
    case types.INIT_PUBLISHED_VERSION_LIBRARY_WATCH: {
      return { ...state, loading: true };
    }
    case types.SET_PUBLISHED_VERSION_LIBRARY: {
      return {
        ...state,
        loading: false,
        data: action?.data?.data,
        statusCode: action.data.status,
      };
    }
    case types.FAILED_PUBLISHED_VERSION_LIBRARY: {
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        statusCode: action.payload.status,
      };
    }
    case types.CLOSE_PUBLISHED_VERSION_LIBRARY: {
      return { ...state, loading: false, data: {}, statusCode: null };
    }
    case types.RESET_PUBLISHED_VERSION: {
      return {
        ...state,
        loading: false,
        data: {
          mediaid: '',
          versions: [],
        },
        statusCode: null,
      };
    }
    default:
      return { ...state };
  }
};
