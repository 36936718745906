/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { SimpleMenu } from '@rmwc/menu';
import { Row } from '@tanstack/react-table';
import { ReactComponent as EditAttributes } from 'assets/Icons/EditAttributes.svg';
import { ReactComponent as Export } from 'assets/Icons/Export.svg';
import { ReactComponent as Share } from 'assets/Icons/Share.svg';
import { ReactComponent as AddThumbnailSvg } from 'assets/Icons/add_photo_alternate.svg';
import { ReactComponent as Delete } from 'assets/Icons/delete.svg';
import { ReactComponent as EditNote } from 'assets/Icons/editNote.svg';
import { ReactComponent as RemoveFromCollectionSvg } from 'assets/Icons/folder_delete.svg';
import { ReactComponent as Link } from 'assets/Icons/link.svg';
import { ReactComponent as Movie } from 'assets/Icons/movie.svg';
import { ReactComponent as IntegrateSvg } from 'assets/Icons/power.svg';
import { ReactComponent as Preview } from 'assets/Icons/preview_black.svg';
import { ReactComponent as Rerun } from 'assets/Icons/rerun.svg';
import { ReactComponent as Subtitles } from 'assets/Icons/subtitles.svg';
import { ReactComponent as TextSnippet } from 'assets/Icons/textSnippet.svg';
import { ReactComponent as ThreeDotSvg } from 'assets/Icons/three_dot.svg';
import { ReactComponent as Version } from 'assets/Icons/versions.svg';
import { ReactComponent as MentionSvg } from 'assets/Icons/voice.svg';
import {
  canRedirect,
  canShare,
  getLatestTitle,
  getMediaidFromClip,
  isAudioByMIME,
  isCollectionPage,
  isCombinedClips,
  isDisplayClips,
  isLibraryPage,
  isPaymentRequired,
  isRevivalPage,
  isSuggestedClips,
  isTrendingClips,
  msToTime,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { simpleMenuDisabled } from 'components/shared/twin.styles';
import { isEmpty, isEqual, isNil, toNumber } from 'lodash';
import { CSSProperties, useMemo, useRef } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToggle } from 'react-use';
import { RootState } from 'reducers';
import { MediaService } from 'services';
import { toggleThumbnailModal } from 'slices/global.slice';
import { tierSelector } from 'slices/payment.slice';
import { toggleReloadLibrary } from 'slices/toggle.slice';
import {
  ClipStatusCode,
  FavoriteEnum,
  ModalOptions,
  VideoStatusCode,
} from 'utils/enum';
import { HistoryItem, IClips, ILibraryItem } from 'utils/models';
import { customToast } from 'utils/toast.util';
import {
  filenameFormatter,
  isEmptyOrSpaces,
  removeItemsFromCollectionAsync,
} from 'utils/utils';
import * as actions from '../../../actions';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { ExpiredHint } from '../ExpiredHint/ExpiredHint';
import { Hint } from '../Hint';
import { ThreeDotMenuItem } from './ThreeDotMenuItem';

interface IProps {
  openMenu: boolean;
  libraryItem?: ILibraryItem;
  itemClips?: IClips;
  itemListView?: Row<ILibraryItem>;
  handleRename?: () => void;
  handleShare?: () => void;
  handleDelete?: () => void;
  handlePublish?: () => void;
  handleViewClip?: () => void;
  handleExport?: () => void;
  clickThreeDot?: () => void;
  handleIntegrate?: () => void;
  handleCopyLink?: () => void;
  handleMentionReport?: () => void;
  handleShowPreviewPublish?: (
    startTime: number,
    endTime: number,
  ) => unknown | any;
  style?: CSSProperties;
  isRevival?: boolean;
  fromSearchClips?: boolean;
}

export const ThreeDotMenu = ({
  openMenu,
  clickThreeDot,
  libraryItem,
  itemClips,
  itemListView,
  handleRename,
  handleShare,
  handleDelete,
  handlePublish,
  handleViewClip,
  handleExport,
  handleCopyLink,
  handleMentionReport,
  handleShowPreviewPublish,
  isRevival,
  fromSearchClips,
  ...props
}: IProps) => {
  // console.count('---- ThreeDotMenu');
  const history = useHistory();
  const dispatch = useDispatch();

  const clips = useSelector((state: RootState) => state.clips);
  const collection = useSelector((state: RootState) => state.collection);
  const payment = useSelector((state: RootState) => state.payment);
  const favorite = useSelector((state: RootState) => state.global.favorite);

  const tier = useSelector(tierSelector);

  // Lazing loading menu -> improve performance
  const [isShowRealMenu, toggleShowRealMenu] = useToggle(false);

  const confirmModalRef = useRef<any>(null);

  // Library parent item
  const item: any = libraryItem ?? itemListView?.original;

  const noTranscript = item?.noTranscript;

  // Clip item on View CLips
  const focusItem: any = isDisplayClips() ? clips.focusClip! : item;

  const isCreatingClip =
    (isDisplayClips() || fromSearchClips) &&
    toNumber(clips?.focusClip?.statuscode) ===
      toNumber(ClipStatusCode.CREATING) &&
    toNumber(clips?.focusClip?.versioncount) !== 0;

  const isCreatedClipError =
    (isDisplayClips() || fromSearchClips) &&
    toNumber(clips?.focusClip?.statuscode) ===
      toNumber(ClipStatusCode.CREATION_FAILED);

  const statusCode = item?.statuscode;

  const shouldDisableBySubscription =
    payment.subscription?.isLoading || payment.isExpiredTrial;

  const shouldDisable = (): boolean => {
    if (isCreatingClip || isCreatedClipError) return true;

    if (
      [
        VideoStatusCode.DRAFT,
        VideoStatusCode.EDITING,
        VideoStatusCode.NOT_EDITED,
        VideoStatusCode.THUMBNAIL_UPDATING,
      ].includes(toNumber(statusCode)) ||
      isNil(statusCode)
    ) {
      return false;
    }

    return true;
  };

  const handleClickFakeMenu = () => {
    toggleShowRealMenu(!isShowRealMenu);
    clickThreeDot?.();
  };

  const shouldDisableRerun = (): boolean => {
    return (
      [
        VideoStatusCode.TRANSCRIBING,
        VideoStatusCode.AI_PROCESSING,
        VideoStatusCode.PAYMENT_REQUIRED,
        VideoStatusCode.ERROR,
      ].includes(toNumber(statusCode)) ||
      tier.isPAYG ||
      tier.isTrial ||
      payment.isExpiredTrial
    );
  };

  const historyItem = {
    mediaid: item?.mediaid,
    filename: isEmptyOrSpaces(item?.title)
      ? filenameFormatter(item?.filename)
      : item?.title,
    videoLength: msToTime(toNumber(item?.length)),
  };

  const handleRedirectClick = (
    item: ILibraryItem,
    historyItem: HistoryItem,
    isParagraph: boolean,
  ) => {
    if (!canRedirect(item)) return;

    history.push(
      `/transcription/${item?.mediaid}?mode=${
        isParagraph ? 'paragraph' : 'caption'
      }`,
      historyItem,
    );
  };

  const handleEditTranscript = (item: ILibraryItem) => {
    const newHistoryItem = {
      ...historyItem,
      editMode: true,
      paragraphMode: true,
    };

    handleRedirectClick(item, newHistoryItem, true);
  };

  const handleEditCaption = (item: ILibraryItem) => {
    const newHistoryItem = {
      ...historyItem,
      editMode: true,
      paragraphMode: false,
    };
    handleRedirectClick(item, newHistoryItem, false);
  };

  const handleRerun = async () => {
    try {
      const modalOption = await confirmModalRef.current?.show({
        title: 'Rerun AI',
        message: (
          <div>
            You are about to rerun the AI processing for the selected item(s)
            from your library. This is a destructive process. Should you
            proceed, this might impact updates made to speaker diarisation,
            chapterisation and entities. <b>Would you like to continue?</b>
          </div>
        ),
        confirmText: 'Confirm',
      });
      if (modalOption === ModalOptions.YES) {
        customToast.loading('Sending request');
        const { data: response } = await MediaService.regenarate({
          action: 're/run-ai-processing',
          payload: {
            media_id: item?.mediaid,
            status: item?.statuscode,
          },
        });
        toast.dismiss();

        if (response?.succeed) {
          customToast.success(response?.message);
        } else {
          if (toNumber(focusItem?.statuscode) === VideoStatusCode.EDITING) {
            customToast.error(
              'Cannot rerun AI processing for this item. Please finish editing the item first.',
            );
          } else {
            customToast.error(response?.message);
          }
        }
      }
    } catch (err: any) {
      customToast.error('Something went wrong');
      console.log('err :>> ', err);
    }
  };

  const handleRemoveFromCollection = async (
    e: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    e.preventDefault();

    const modalOption = await confirmModalRef.current?.show({
      title: 'Remove from collection',
      message: (
        <div>
          <div>
            This will just delete selected items from your collection, they will
            still remain in your library.
          </div>
          <b>Would you like to continue?</b>
        </div>
      ),
      confirmText: 'Delete',
    });

    if (modalOption !== ModalOptions.YES) return;

    if (!collection?.selectedId || !focusItem?.mediaid) return;

    try {
      dispatch(actions.removeClipFromCollections(focusItem?.mediaid));

      await removeItemsFromCollectionAsync({
        collectionId: collection.selectedId,
        ids: [focusItem.mediaid],
      });
    } catch (err) {
      dispatch(toggleReloadLibrary());
    }
  };

  const openSharedNewTab = (item: IClips) => {
    if (!item) return;

    if (item?.share_url) {
      const openShare = window.open(item.share_url, 'Share');
      openShare?.focus();
    }
  };

  const handleIntegrate = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    props?.handleIntegrate?.();
  };

  const handleView = () => {
    handleShowPreviewPublish?.(itemClips?.starttime!, itemClips?.endtime!);
  };

  const handleUpdateThumbnail = () => {
    dispatch(
      toggleThumbnailModal({
        mediaid: item?.mediaid ?? getMediaidFromClip(clips.focusClip!),
        title: isDisplayClips()
          ? clips.focusClip?.versionname
          : getLatestTitle(item as any),
        versioncount: !isEmpty(itemClips) ? itemClips?.versioncount : undefined,
        isAudio: isAudioByMIME(item?.mediacontenttype),
        thumbnail: focusItem?.thumbnail,
      }),
    );
  };

  const isOriginalClip = isEqual(itemClips?.versioncount, '0');
  const canRecreate = fromSearchClips || (isDisplayClips() && !isOriginalClip);
  const isLibraryItemThumbnailUpdating =
    isOriginalClip &&
    toNumber(libraryItem?.statuscode) === VideoStatusCode.THUMBNAIL_UPDATING;

  const currentItem = fromSearchClips || isDisplayClips() ? itemClips : item;

  const disableByStalledStatus =
    (isPaymentRequired(item) || shouldDisable()) && !canShare(currentItem);

  const getListMenuItem = useMemo(() => {
    return [
      {
        id: FavoriteEnum.SHARE,
        itemName: 'Share',
        itemCss:
          (disableByStalledStatus || shouldDisableBySubscription) &&
          simpleMenuDisabled,
        SvgComponent: <Share css={iconStyle} />,
        handleOnClick: handleShare,
        isFavorited: favorite.includes(FavoriteEnum.SHARE),
      },
      {
        id: FavoriteEnum.INTEGRATE,
        itemName: 'Integrate',
        itemCss:
          (disableByStalledStatus || shouldDisableBySubscription) &&
          simpleMenuDisabled,
        SvgComponent: (
          <IntegrateSvg
            css={[iconStyle, tw`(width[2.5rem] height[2.5rem])!`]}
          />
        ),
        handleOnClick: handleIntegrate,
        isHide:
          fromSearchClips ||
          (!isLibraryPage() && !isCollectionPage() && !isOriginalClip),
        isFavorited: favorite.includes(FavoriteEnum.INTEGRATE),
      },
      {
        id: FavoriteEnum.VIEW_SHARED_ITEM,
        itemName: 'View shared item',
        SvgComponent: <Preview css={iconStyle} tw="height[2.2rem]" />,
        handleOnClick: () => openSharedNewTab(focusItem),
        isHide:
          fromSearchClips ||
          !isDisplayClips() ||
          !focusItem ||
          !isEqual(focusItem?.statuscode, ClipStatusCode.SHARED),
        isDisabledExpiredHint: true,
        isFavorited: favorite.includes(FavoriteEnum.VIEW_SHARED_ITEM),
      },
      {
        id: FavoriteEnum.EXPORT,
        itemName: 'Export',
        itemCss:
          (shouldDisableBySubscription || shouldDisable() || noTranscript) &&
          simpleMenuDisabled,
        SvgComponent: <Export css={iconStyle} />,
        handleOnClick: handleExport,
        isFavorited: favorite.includes(FavoriteEnum.EXPORT),
      },
      {
        isHrDivider: true,
      },
      {
        id: FavoriteEnum.CREATE_CLIP,
        itemName: canRecreate ? 'Edit' : 'Create clip',
        itemCss:
          (disableByStalledStatus ||
            noTranscript ||
            shouldDisableBySubscription) &&
          simpleMenuDisabled,
        SvgComponent: <Movie css={iconStyle} />,
        handleOnClick: handlePublish,
        className: isCombinedClips(focusItem) ? 'disabled' : '',
        isFavorited: favorite.includes(FavoriteEnum.CREATE_CLIP),
      },
      {
        id: FavoriteEnum.VIEW_CLIPS,
        itemName: 'View clips',
        itemCss: shouldDisable() && simpleMenuDisabled,
        SvgComponent: <Version css={backgroundIcon} />,
        handleOnClick: handleViewClip,
        isHide: fromSearchClips || isDisplayClips(),
        isDisabledExpiredHint: true,
        isFavorited: favorite.includes(FavoriteEnum.VIEW_CLIPS),
      },
      {
        id: FavoriteEnum.VIEW,
        itemName: 'View',
        itemCss: shouldDisable() && simpleMenuDisabled,
        SvgComponent: <Preview css={iconStyle} tw="height[2.2rem]" />,
        handleOnClick: handleView,
        isHide: !fromSearchClips && !isDisplayClips(),
        isDisabledExpiredHint: true,
        isFavorited: favorite.includes(FavoriteEnum.VIEW),
      },
      {
        id: FavoriteEnum.UPDATE_THUMBNAIL,
        itemName: 'Update thumbnail',
        itemCss:
          (shouldDisable() ||
            isLibraryItemThumbnailUpdating ||
            focusItem?.statuscode === ClipStatusCode.THUMBNAIL_UPDATING ||
            shouldDisableBySubscription) &&
          simpleMenuDisabled,

        SvgComponent: <AddThumbnailSvg css={iconStyle} />,
        handleOnClick: handleUpdateThumbnail,
        isHide:
          isRevival ||
          isSuggestedClips(clips?.focusClip) ||
          isTrendingClips(clips?.focusClip),
        isFavorited: favorite.includes(FavoriteEnum.UPDATE_THUMBNAIL),
      },
      {
        isHrDivider: !(isDisplayClips() && fromSearchClips && isOriginalClip),
      },
      {
        id: FavoriteEnum.VIEW_TRANSCRIPT,
        itemName: 'View transcript',
        itemCss: shouldDisable() && simpleMenuDisabled,
        SvgComponent: <TextSnippet css={iconStyle} />,
        handleOnClick: () => handleRedirectClick(item, historyItem, true),
        isHide: fromSearchClips || (isDisplayClips() && !isOriginalClip),
        isDisabledExpiredHint: true,
        isFavorited: favorite.includes(FavoriteEnum.VIEW_TRANSCRIPT),
      },
      {
        id: FavoriteEnum.EDIT_CAPTIONS,
        itemName: 'Edit (captions)',
        itemCss:
          (shouldDisable() || noTranscript || shouldDisableBySubscription) &&
          simpleMenuDisabled,
        SvgComponent: <Subtitles css={iconStyle} />,
        handleOnClick: () => handleEditCaption(item),
        isHide: fromSearchClips || (isDisplayClips() && !isOriginalClip),
        isFavorited: favorite.includes(FavoriteEnum.EDIT_CAPTIONS),
      },
      {
        id: FavoriteEnum.EDIT_TRANSCRIPT,
        itemName: 'Edit (transcript)',
        itemCss:
          (shouldDisable() || noTranscript || shouldDisableBySubscription) &&
          simpleMenuDisabled,
        SvgComponent: <EditNote css={iconStyle} />,
        handleOnClick: () => handleEditTranscript(item),
        isHide: fromSearchClips || (isDisplayClips() && !isOriginalClip),
        isFavorited: favorite.includes(FavoriteEnum.EDIT_TRANSCRIPT),
      },
      {
        isHrDivider:
          !isRevivalPage() &&
          !(isDisplayClips() && !isOriginalClip) &&
          !(isDisplayClips() && fromSearchClips && isOriginalClip) &&
          !(isLibraryPage() && fromSearchClips),
      },
      {
        id: FavoriteEnum.COPY_LINK,
        itemName: 'Copy link',
        itemCss:
          (shouldDisable() || noTranscript || shouldDisableBySubscription) &&
          simpleMenuDisabled,
        SvgComponent: <Link css={iconStyle} />,
        handleOnClick: handleCopyLink,
        isHide:
          isRevival || fromSearchClips || (isDisplayClips() && !isOriginalClip),
        isFavorited: favorite.includes(FavoriteEnum.COPY_LINK),
      },
      {
        id: FavoriteEnum.MENTIONS_REPORT,
        itemName: 'Mention report',
        itemCss:
          (shouldDisable() || noTranscript || shouldDisableBySubscription) &&
          simpleMenuDisabled,
        SvgComponent: (
          <MentionSvg
            css={[iconStyle, tw`[> path:nth-of-type(2)]:fill[#7f8090]`]}
          />
        ),
        handleOnClick: handleMentionReport,
        isHide:
          isRevival || fromSearchClips || (isDisplayClips() && !isOriginalClip),
        isFavorited: favorite.includes(FavoriteEnum.MENTIONS_REPORT),
      },
      {
        id: FavoriteEnum.RERUN_AI,
        itemName: 'Rerun AI',
        itemCss:
          (shouldDisableRerun() ||
            noTranscript ||
            shouldDisableBySubscription) &&
          simpleMenuDisabled,
        SvgComponent: <Rerun css={iconStyle} />,
        handleOnClick: handleRerun,
        isHide:
          isRevival ||
          fromSearchClips ||
          window.location.pathname.startsWith('/clips') ||
          (isDisplayClips() && isOriginalClip),
        isFavorited: favorite.includes(FavoriteEnum.RERUN_AI),
      },
      {
        isHrDivider:
          !isRevivalPage() &&
          !(isDisplayClips() && isOriginalClip) &&
          !(isDisplayClips() && !isOriginalClip) &&
          !(isLibraryPage() && fromSearchClips),
      },
      {
        id: FavoriteEnum.RENAME,
        itemName: 'Rename',
        SvgComponent: <EditAttributes css={iconStyle} />,
        handleOnClick: handleRename,
        isHide: isRevival || isOriginalClip,
        isFavorited: favorite.includes(FavoriteEnum.RENAME),
      },
      {
        id: FavoriteEnum.DELETE,
        itemName: 'Delete',
        SvgComponent: <Delete css={iconStyle} />,
        handleOnClick: handleDelete,
        isHide: isRevival || (isDisplayClips() && isOriginalClip),
        isFavorited: favorite.includes(FavoriteEnum.DELETE),
      },
      {
        itemName: 'Remove from collection',
        SvgComponent: <RemoveFromCollectionSvg css={iconStyle} />,
        handleOnClick: handleRemoveFromCollection,
        isHide: !isCollectionPage(),
        shouldHideFavorite: true,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableByStalledStatus, shouldDisableBySubscription, favorite]);

  return (
    <>
      {isShowRealMenu ? (
        <SimpleMenu
          tw="mt-16"
          // @ts-ignore
          style={props?.style}
          open={openMenu}
          fixed
          // @ts-ignore
          css={[tw`min-width[215px]`, customChildren]}
          handle={
            <div
              role="button"
              tw="cursor-pointer mb-0 flex justify-center items-center"
              className="button dot"
              onClick={clickThreeDot}
              style={{ width: '36px', height: '32px' }}
            >
              <ThreeDotSvg fill="currentColor" tw="ml-[-4px]" />
            </div>
          }
        >
          {getListMenuItem.map((item, index) => {
            return (
              <ExpiredHint key={index} disabled={item.isDisabledExpiredHint}>
                <ThreeDotMenuItem item={item} />
              </ExpiredHint>
            );
          })}
        </SimpleMenu>
      ) : (
        <Hint text="More actions" notTransparent>
          <div
            role="button"
            tw="cursor-pointer mb-0 flex justify-center items-center"
            className="button dot"
            onClick={handleClickFakeMenu}
            style={{ width: '36px', height: '32px' }}
          >
            <ThreeDotSvg fill="currentColor" tw="ml-[-4px]" />
          </div>
        </Hint>
      )}

      <ConfirmModal ref={confirmModalRef} type="warning" />
    </>
  );
};

const customChildren = css`
  ul.mdc-list.mdc-menu__items {
    ${tw`(py-1.5 font-family['Lexend', 'Roboto', 'Arial', 'Helvetica', 'Verdana', sans-serif])!`}
  }
`;

const backgroundIcon = css`
  width: 25px;
  path {
    fill: #7f8090 !important;
  }
`;

const iconStyle = css`
  fill: #7f8090 !important;
  width: 25px;
`;
