/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import React from 'react';
import BackgroundImage from 'assets/Images/background.jpg';
import ForegroundSonnantWave from 'assets/Images/background-waveform.png';
import { css } from '@emotion/react';
import SonnantLogoPink from 'assets/auth/logo-pink.png';
import { LoginV2 } from 'components/Login/LoginV2';
import { useLocation } from 'react-router-dom';
import { Routes } from 'utils/routes';
import { SignUpV2 } from 'components/SignUp/SignUpFormV2';

export const AuthForm = () => {
  const location = useLocation();

  const isLoginPage = location.pathname === Routes.SIGNIN;

  return (
    <div tw="relative box-sizing[border-box] h-screen w-screen">
      {/* UNDERLAY BACKGROUND */}
      <div css={[underlayBgCss(BackgroundImage)]} />

      {/* FOREGROUND */}
      <div tw="top-0 z-index[1] flex h-full w-full items-center justify-center absolute">
        <div css={[authWrapper]}>
          {/* LEFT PANE - FORM */}
          <div css={[leftPaneWrapper]}>
            <div tw="w-full">{isLoginPage ? <LoginV2 /> : <SignUpV2 />}</div>
          </div>

          {/* RIGHT PANE - FOREGROUND */}
          <div css={[rightPaneWrapper(ForegroundSonnantWave)]}>
            <img
              tw="absolute top-1/2 transform[translateY(-100%)] max-height[6rem] sm-down:(min-width[14rem])"
              src={SonnantLogoPink}
              alt="Sonnant Logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const authWrapper = css`
  ${tw`lg-up:min-height[60rem] md-down:(w-full h-full) md-up:(w-[80%]) lg-up:(w-[60%])`}
  ${tw`flex rounded-2xl shadow-2xl overflow-hidden`}
`;

const leftPaneWrapper = css`
  ${tw`flex items-center md-down:items-start flex[50%] bg-white md-down:(padding[4rem 2rem] pt-[6rem]) md-up:(padding[8rem]) lg-up:(padding[4rem 2rem]) xl-up:(padding[8rem 6rem])`}
`;

const rightPaneWrapper = (foregroundImage: string) => css`
  background: url(${foregroundImage});

  ${tw`flex[50%] min-h-full md-down:(hidden)`}
  ${tw`z-10! top-0 shadow-2xl bg-cover`};
`;

const underlayBgCss = (bgImage: string) => css`
  background: url(${bgImage});

  ${tw`xl-up:zoom[1.35] filter[blur(1px)] opacity[0.4]`}
  ${tw`bg-center absolute w-full h-full z-index[0] sm-down:hidden`}
`;
