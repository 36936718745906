/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

interface Props {
  text: string;
  hideSpacingBottom?: boolean;
  fontSize?: string;
}

export const Info = ({ ...props }: Props) => {
  return (
    <div
      tw="color[#54566C] text-2xl font-medium"
      css={[
        !props?.hideSpacingBottom && tw`mb-6`,
        props?.fontSize && customFontSize(props.fontSize),
      ]}
    >
      {props?.text || 'Nothing to show'}
    </div>
  );
};

const customFontSize = (fontSize: string) => css`
  font-size: ${fontSize};
`;
