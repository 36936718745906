import { MentionReportField } from 'utils/enum';

export const CHAPTER_COLUMN_HEADERS = [
  {
    header: ' ',
    accessor: '',
    id: 'Select',
    size: 40,
    style: {
      width: '40px',
    }
  },
  {
    header: ' ',
    accessor: '',
    id: MentionReportField.TYPE,
    size: 30,
    style: {
      width: '30px',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  {
    header: "#",
    accessor: 'id',
    id: MentionReportField.ID,
    size: 30,
    style: {
      width: '30px',
      textAlign: 'center',
    },
  },
  {
    header: 'Start Time',
    accessor: 'startTime',
    style: {
      textAlign: 'center',
      width: '100px',
    },
    id: MentionReportField.START_TIME,
    size: 100,
  },
  {
    header: 'Headline',
    accessor: 'headline',
    style: {
      textAlign: 'center',
      width: '100px',
    },
    id: MentionReportField.END_TIME,
    size: 100,
  },
  {
    header: 'Tags',
    accessor: 'duration',
    style: {
      textAlign: 'center',
      width: '100px',
    },
    id: MentionReportField.DURATION,
    size: 230,
  },
  {
    header: 'IAB Categories',
    accessor: 'totalMentions',
    style: {
      textAlign: 'center',
      width: '100px',
    },
    id: MentionReportField.TOTAL,
    size: 250,
  },
  {
    header: 'Chapter start',
    accessor: 'term',
    style: {
      textAlign: 'center',
      width: '100px',
    },
    id: MentionReportField.TERM,
    size: 250,
  },
  {
    header: 'Create clip',
    accessor: 'iab',
    style: {
      textAlign: 'center',
      width: '90px'
    },
    id: MentionReportField.IAB,
    size: 90,
  },
  {
    header: 'URL',
    accessor: 'transcript',
    style: {
      textAlign: 'center',
      width: '50px'
    },
    id: MentionReportField.TRANSCRIPT,
    size: 50,
  },
];
