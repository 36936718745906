/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { handleSearchClear, setSelectedRows } from 'actions';
import Loader from 'components/loader/loader';
import { Option } from 'components/shared/CustomSelectSearch';
import { DemographicsModal } from 'components/shared/DemographicsModal/DemographicsModal';
import { IabMediaDetailsModal } from 'components/shared/IabMediaDetailsModal/IabMediaDetailsModal';
import { ColumnChart } from 'components/shared/InsightChart/ColumnChart';
import { RadialBarChart } from 'components/shared/InsightChart/RadialBarChart';
import {
  IabOptionDropdown,
  InsightIabDropdown,
  iabDefaultOption,
} from 'components/shared/InsightIabDropdown/InsightIabDropdown';
import { useAdvertisingIabQuery } from 'hooks/queries/useAdvertisingIabQuery';
import { useAdvancedAutofill } from 'hooks/useAdvancedAutofill';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';
import { InsightService } from 'services/insight.service';
import { resetAdvancedSearch } from 'slices/advanced-search.slice';
import {
  ColorBucket,
  convertTopShows,
  toColumChartAdSlots,
  toColumChartIabTags,
} from 'utils/adapter.util';
import { ModalOptions } from 'utils/enum';
import { ensureArray, singleOrNull } from 'utils/generic.util';
import {
  AdvertisingCollection,
  Demographics,
  FilterMenuOptions,
  IabBucket,
  Insights,
  TopShow,
} from 'utils/models';
import {
  DemographicsModalRef,
  IabMediaDetailsModalRef,
} from 'utils/models/modal.model';
import { getTenantidFromIdToken } from 'utils/utils';
import {
  AdvancedFilterMenu,
  defaultAdvancedFilters,
} from './shared/AdvancedFilterMenu';
import { AdvertisingTopShows } from './shared/AdvertisingTopShows';

import PodderLogoPng from 'assets/Icons/PodderLogo.png';
import { ReactComponent as FilterSvg } from 'assets/Icons/filter.svg';
import { isEmpty, values } from 'lodash';
import { customToast } from 'utils/toast.util';
import { saveAs } from 'file-saver';
import { ReactComponent as ExportSvg } from '../../assets/Icons/Export.svg';
import { Hint } from 'components/shared/Hint';

type Props = {};

export const AdvertisingCharts = React.memo((props: Props) => {
  const dispatch = useDispatch();

  const canShowDemographics = [
    'in8cdoghz', // phongto - dev
    '8gqehub0o', // haudang - dev
    'rgljhgijv', // minhthu - dev
    'rxazcpzrg', // longtruongdevtest - dev

    '3kxtmm5yl', // ptn - stage
    '0gqx1vpsc', // anhtest - stage
    'i4ryvralq', // yairtest - stage
    'lcwpkvcv9', // teststage - stage

    'hzlukuesa', // ptn - prod
    'yfcewwlau', // devtestvidhu1 - prod
    'jshjo0u7x', // yairtest - prod
    'mli0ymyoc', // podderapp - prod
  ].includes(getTenantidFromIdToken());

  const [isLoading, toggleIsLoading] = useToggle(false);
  const [isExporting, toggleExporting] = useToggle(false);

  const [advancedFilters, setAdvancedFilters] = useState<FilterMenuOptions>(
    defaultAdvancedFilters,
  );

  const [iabBuckets, setIabBuckets] = useState<IabBucket[]>([]);
  const [iabRadialBuckets, setIabRadialBuckets] = useState<IabBucket[]>();
  const [iabOptionItem, setIabOptionItem] = useState<Option>(iabDefaultOption);

  const [adMarkerBuckets, setAdMarkerBuckets] = useState<ColorBucket[]>([]);
  const [iabTagBuckets, setIabTagBuckets] = useState<ColorBucket[]>([]);
  const [adTopCollections, setAdTopCollections] = useState<
    AdvertisingCollection[]
  >([]);

  const [topShows, setTopShows] = useState<TopShow[]>([]);

  const autofillAdvancedSearchHook = useAdvancedAutofill();

  const iabMediaDetailsModalRef = useRef<IabMediaDetailsModalRef>();
  const demographicsModalRef = useRef<DemographicsModalRef>(null);

  const {
    isLoading: isLoadingAdvertisingIab,
    refetch: refetchAdvertisingIabQuery,
  } = useAdvertisingIabQuery({
    params: {
      collectionIds: advancedFilters.collectionIds,
      tagIds: advancedFilters.tagIds,
      integrationIds: advancedFilters.orgIds,
      categoryName: iabOptionItem?.value || undefined,
    },
    options: {
      onSuccess: (data) => {
        setIabBuckets(data.iab.buckets);
        setIabRadialBuckets(data.iab_total_relevance.buckets);
        setAdTopCollections(data.collections);

        setTopShows(convertTopShows(data.collections));

        setAdMarkerBuckets(toColumChartAdSlots(data.ad_markers));
        setIabTagBuckets(toColumChartIabTags(data.iab_tags));
      },
    },
  });

  useEffect(() => {
    refetchAdvertisingIabQuery();
  }, [iabOptionItem, advancedFilters]);

  const handleChangeIabCategory = (iabItem: Option) => {
    setIabOptionItem(iabItem);
  };

  const handleClickPie = (searchTerm: string) => {
    dispatch(handleSearchClear());
    dispatch(resetAdvancedSearch());
    dispatch(setSelectedRows([]));

    autofillAdvancedSearchHook({
      searchTerms: [searchTerm],
      collectionId: singleOrNull<string>(advancedFilters.collectionIds),
      searchBy: 'iab' as keyof Insights,
    });
  };

  const handleClickIabTagNumber = async (option: IabOptionDropdown) => {
    await iabMediaDetailsModalRef.current?.show({
      name: option.label,
      size: option.totalItems ?? 100,
    });
  };

  const handlePostDemographicsAsync = async (params: Demographics) => {
    try {
      toggleIsLoading(true);

      const { data } = await InsightService.postDemographicsAsync({
        ...params,
        ...advancedFilters,
        iab: ensureArray(iabOptionItem.value),
      });

      const demographicsTopShows: TopShow[] = data.map((item) => ({
        showName: item.podcast_name,
        score: item.reach,
      }));

      setTopShows(demographicsTopShows);
    } catch (error) {
      console.log('[error]: >>>', error);
    } finally {
      toggleIsLoading(false);
    }
  };

  const handleClickDemographics = async () => {
    const result = await demographicsModalRef.current?.show();

    if (result?.option !== ModalOptions.YES) return;

    if (!result?.payload) return;

    if (values(result.payload).every((value) => isEmpty(value))) {
      setTopShows(convertTopShows(adTopCollections));
      return;
    }

    handlePostDemographicsAsync(result.payload);
  };

  const handleExportExcel = async () => {
    const exportAsync = InsightService.exportAdvertisingProfileInsight({
      collectionIds: advancedFilters.collectionIds,
      tagIds: advancedFilters.tagIds,
      integrationIds: advancedFilters.orgIds,
      categoryName: iabOptionItem?.value || undefined,
    });

    customToast.promise(exportAsync, {
      loading: 'Exporting Excel report...',
      success: 'Done',
      error: 'Failed to export Excel report',
    });

    try {
      toggleExporting(true);

      const { data: s3DownloadLink } = await exportAsync;

      saveAs(s3DownloadLink);
    } catch (error) {
      console.log('error :>> ', error);
    } finally {
      toggleExporting(false);
    }
  };

  return (
    <div tw="h-full flex flex-col">
      <div tw="text-20 font-semibold mb-3">Advertising Profile</div>

      <div tw="flex flex-1 flex-col w-full rounded border[1px solid] border-sonnant-grey-light shadow px-5 pt-2 relative">
        {(isLoadingAdvertisingIab || isLoading) && (
          <div tw="absolute w-full h-full flex justify-center items-center bg-white z-[20] opacity[0.95] top-0 left-0">
            <Loader />
          </div>
        )}
        <div tw="flex flex-row justify-between items-center">
          <div tw="flex flex-row gap-x-5 items-end">
            <div tw="relative z-[1] min-w-[15rem] text-14">
              <label>
                <span tw="text-15">IAB Category</span>
              </label>

              <InsightIabDropdown
                selectedOption={iabOptionItem}
                iabSortedBuckets={iabBuckets}
                onClickTagNumber={handleClickIabTagNumber}
                onChangeOption={handleChangeIabCategory}
                isLoading={isLoadingAdvertisingIab}
              />
            </div>

            {/* FILTER BUTTON */}
            <div tw="flex items-end">
              <AdvancedFilterMenu
                selectedFilters={advancedFilters}
                onMenuClose={setAdvancedFilters}
              />
            </div>

            {canShowDemographics && (
              <div tw="flex justify-center items-center">
                <button
                  type="button"
                  className="button btn-primary large"
                  tw="(flex items-center mb-0 max-w-[unset] w-auto gap-x-2 px-[6px])!"
                  onClick={handleClickDemographics}
                >
                  <FilterSvg />
                  <span>Demographics</span>
                  <img
                    src={PodderLogoPng}
                    alt="Podder Logo"
                    tw="w-[2.8rem] mx-1"
                  />
                </button>
              </div>
            )}
          </div>

          <div tw="flex h-full items-end">
            <Hint text="Export out the results from the Advertising Profile">
              <button
                className="button btn-secondary large"
                tw="mb-0 flex items-center justify-center gap-x-2"
                onClick={handleExportExcel}
                disabled={isExporting}
              >
                <ExportSvg /> <span>Export</span>
              </button>
            </Hint>
          </div>
        </div>

        <div tw="flex-1 flex justify-evenly mx-3 my-3 py-8">
          <div tw="flex flex-col gap-y-8 min-w-[40rem]">
            {adMarkerBuckets && (
              <div tw="flex-1 min-h-[25rem] cursor-pointer">
                <ColumnChart
                  title="Total number of Ad slots"
                  buckets={adMarkerBuckets}
                  handleClickPie={() => {}}
                />
              </div>
            )}

            {iabTagBuckets && (
              <div tw="flex-1 min-h-[25rem] cursor-pointer">
                <ColumnChart
                  title="Total number of tags"
                  buckets={iabTagBuckets}
                  handleClickPie={() => {}}
                />
              </div>
            )}
          </div>

          <div tw="flex flex-col gap-y-6">
            {iabRadialBuckets && (
              <div tw="cursor-pointer">
                <RadialBarChart
                  buckets={iabRadialBuckets}
                  width={450}
                  handleClickPie={handleClickPie}
                />
              </div>
            )}

            <AdvertisingTopShows topShows={topShows} />
          </div>
        </div>
      </div>

      <IabMediaDetailsModal ref={iabMediaDetailsModalRef} />
      <DemographicsModal ref={demographicsModalRef} />
    </div>
  );
});
