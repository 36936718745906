/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import {
  Editable,
  ReactEditor,
  Slate,
  withReact,
  DefaultElement,
  RenderElementProps,
} from 'slate-react';
import { withHistory } from 'slate-history';

import {
  createEditor,
  Editor,
  Node,
  Path,
  SetNodeOperation,
  Transforms,
} from 'slate';
import React, { Suspense, useCallback, useMemo, useRef, useState } from 'react';
import { IRichTextInput } from './rich-text.model';
import { useEffect } from 'react';
import { inRange, isArray, isEmpty, isNil, uniqBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useLatest } from 'react-use';
import { setCanRedo, setCanUndo } from 'slices/transcript.slice';
import { TimecodeModal } from '../modals/TimecodeModal';
import { setCurrentPath } from 'slices/caption.slice';
import {
  focusOccNth,
  getSlateSelection,
  isPublicShared,
  scrollToNode,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { Keys } from 'utils/enum';
import { breakCaption } from 'utils/caption.util';
import {
  setCurrentChapterTime,
  setSplitCaption,
  setSplitParagraph,
} from 'slices/chapter.slice';
import { CustomEditor, CustomElement } from 'types/slate-custom-types';
import { Layers } from 'utils/enum';
import { toggleEditCaption } from 'slices/toggle.slice';
import { IKeyItem } from 'utils/models';
import { TranscriptSkeleton } from '../Skeleton/TranscriptSkeleton';

const Transcript = React.lazy(() =>
  import(
    'components/VideoPlayer/Transcription/MediaPlayer/Transcript/Transcript'
  ).then((module) => ({ default: module.Transcript })),
);

declare global {
  interface Window {
    Editor: CustomEditor;
    Video: HTMLVideoElement;
  }
}

const commonStyles = css`
  & ::selection {
    ${tw`bg-human-selection!`}
  }
`;

interface IRichTextEditorProps {
  document: IRichTextInput[];
  videoTimePosition: number;
  setDocument: (transcript: CustomElement[]) => void;
}

export const RichTextEditor = ({ ...props }: IRichTextEditorProps) => {
  // const [documentSrt, setDocumentSrt] = useState<Descendant[]>(document);

  // console.count('RICHTEXT')

  const editor = useMemo<CustomEditor>(
    () => withHistory(withReact(createEditor() as ReactEditor)),
    [],
  );
  // FOR DEV ONLY
  // const editorRef = useRef<any>();
  // if (!editorRef.current)
  //   editorRef.current = withHistory(withReact(createEditor() as ReactEditor));
  // const editor = editorRef.current;

  const dispatch = useDispatch();
  const toggle = useSelector((state: RootState) => state.toggle);

  const timeRef = useLatest(props.videoTimePosition);

  const renderElement = useCallback(
    (props: RenderElementProps) => (
      <Element
        {...props}
        isEditMode={toggle.isEditMode}
        handleChangeTimecode={handleChangeTimecode}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const transcript = useSelector((state: RootState) => state.transcript);

  const [blockStart, setBlockStart] = useState(0);
  const timecodeRef = useRef<any>(null);

  useEffect(() => {
    if (!editor) return;

    window.Editor = editor;
  }, [editor]);

  const onChangeEdit = (e: any) => {
    if (!isNil(editor?.history)) {
      const canUndo = !isEmpty(
        editor?.history?.undos.filter(
          (i: any) => i?.[0]?.type !== 'set_selection',
        ),
      );
      const canRedo = !isEmpty(
        editor?.history?.redos.filter(
          (i: any) => i?.[0]?.type !== 'set_selection',
        ),
      );

      if (transcript.canUndo !== canUndo) {
        dispatch(setCanUndo(canUndo));
      }

      if (transcript.canRedo !== canRedo) {
        dispatch(setCanRedo(canRedo));
      }
      // dispatch(toggleWarning(true));
    } else {
      // dispatch(toggleWarning(false));
    }
    // setDocumentSrt(e);
    props.setDocument(e);
  };

  // search text
  const search = useSelector(
    (state: RootState) => state.player.finderOptions.textSearch,
  );

  const finderOptions = useSelector((state: RootState) => state.player.finderOptions);

  const selectedTagId = useSelector(
    (state: RootState) => state.player.selectedTagId,
  );
  const keywords = useSelector((state: RootState) => state.player.keywords);
  const keywordLatest = useLatest(keywords);
  const entities = useSelector((state: RootState) => state.player.entities);
  const player = useSelector((state: RootState) => state.player);

  const layers = useSelector((state: RootState) => state.layers);
  const [keyWordArray, setKeywordArray] = useState<IKeyItem[] | undefined>(
    keywordLatest.current,
  );

  useEffect(() => {
    if (layers.currentLayer === Layers.CUSTOM_TERM) {
      const customTerms =
        player.layerCustomTerms?.find(
          (t) => t?.listId === player?.focusLayerCustomTermId,
        )?.matched ?? [];
      setKeywordArray(customTerms);
    }
    if (layers.currentLayer === Layers.PEOPLE) {
      setKeywordArray(entities.person);
    }
    if (layers.currentLayer === Layers.PRODUCT) {
      setKeywordArray(entities.product);
    }
    if (layers.currentLayer === Layers.LOCATION) {
      setKeywordArray(entities.location);
    }
    if (layers.currentLayer === Layers.NATIONALITY) {
      setKeywordArray(entities.nationality);
    }
    if (layers.currentLayer === Layers.ORG) {
      setKeywordArray(entities.organisation);
    }
    if (layers.currentLayer === Layers.KEY_TERM) {
      setKeywordArray(keywordLatest.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    layers.currentLayer,
    keywordLatest.current,
    player.focusLayerCustomTermId,
  ]);

  const keywordRes = useRef<number>(0);
  const keywordResArray = useRef<any>([]);
  const rangeKeyword = useRef<any>([]);

  const textKeyword = useSelector(
    (state: RootState) => state.caption.textKeyword,
  );

  const lengthKeyword = useRef(0);
  const textKeywordChange = useRef('');
  const editCaption = useSelector(
    (state: RootState) => state.toggle.isEditCaption,
  );

  useEffect(() => {
    // edit keyword
    if (rangeKeyword.current.length && textKeyword && editCaption) {
      const oldText = keywords[selectedTagId!].keyword;
      const arrayFilter = uniqBy(rangeKeyword.current, JSON.stringify);

      arrayFilter.forEach((path: any) => {
        const edges = Editor.edges(window.Editor, [path[0]]);
        const newPath = {
          anchor: {
            path: path,
            offset: 0,
          },
          focus: {
            path: [path[0], path[1] + textKeyword.split(' ').length],
            offset: oldText.split(' ').slice(-1).length - 1,
          },
        };

        if (newPath.focus.path[1] > edges[1].path[1]) {
          newPath.focus.path[0] += 1;
          newPath.focus.path[1] = 0;
          newPath.focus.offset = textKeyword.length - 1;
        }
        const editor: any = window.Editor;
        let isLastWord = false;
        let newTextKeyword = `${textKeyword} `;
        const keywordEditLength = path[1] + textKeyword.split(' ').length;
        const lengthCaption = editor.children?.[path[0]]?.children.length;

        if (keywordEditLength < lengthCaption) {
          // can fully in one line
          if (
            editor.children?.[path[0]]?.children?.[
              keywordEditLength
            ].text.includes('.', ',', '?', '!')
          ) {
            isLastWord = true;
          }
        }
        if (keywordEditLength === lengthCaption - 1) {
          // last word in line
          isLastWord = true;
        }
        if (isLastWord) {
          newTextKeyword = `${textKeyword}`;
        }
        if (ReactEditor.hasRange(window.Editor, newPath)) {
          Transforms.insertText(editor, newTextKeyword, {
            at: newPath,
          });
        }
      });
      rangeKeyword.current = [];
      dispatch(toggleEditCaption(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textKeyword, editCaption]);

  const nodeStartRef = useRef<any>(null);

  const currentId = useRef<any>(-1);

  const decorate = useCallback(
    ([node, path]) => {
      // HIGHT SEARCH RESULT
      if (!isNil(node?.type) && search) {
        return focusOccNth(
          finderOptions?.searchRanges,
          finderOptions?.presentOccId,
        );
      }

      if (currentId.current !== selectedTagId) {
        currentId.current = selectedTagId;
        rangeKeyword.current = [];
      }
      if (keywordRes.current) {
        keywordRes.current -= 1;
        return [keywordResArray.current.shift()];
      }
      let ranges: any = [];

      const nodeStart = node?.data?.start ?? node?.data?.dataStart;
      const nodeEnd = node?.data?.end ?? node?.data?.dataEnd;
      // Highlight current word
      if (
        inRange(timeRef.current, nodeStart, nodeEnd) &&
        !toggle.isShowFinder
        // && toggle.isFollowing
      ) {
        let wordIndex = 0;
        let wordLength = 0;

        node?.children?.forEach((w: any, i: number) => {
          if (inRange(timeRef.current, w?.data?.dataStart, w?.data?.dataEnd)) {
            wordIndex = i;
            wordLength = w.text.length;
            if (nodeStart !== nodeStartRef.current) {
              scrollToElement(nodeStart, path);
              nodeStartRef.current = nodeStart;
            }
          }
        });

        ranges = [
          {
            anchor: { path: [path[0], wordIndex], offset: 0 },
            focus: { path: [path[0], wordIndex], offset: wordLength },
            isCurrent: true,
          },
        ];
      }

      // click keyword

      if (selectedTagId !== null && selectedTagId !== -1 && node.text) {
        keyWordArray?.[selectedTagId]?.mentions[0]?.occurrences.forEach(
          (occ) => {
            if (node?.data?.dataStart === occ?.s) {
              textKeywordChange.current = node?.text;
              const keywordLength =
                keyWordArray?.[selectedTagId].keyword.split(' ');
              if (!keywordLength) return [];
              keywordRes.current = keywordLength.length - 1;
              lengthKeyword.current = keywordLength?.length;
              keywordLength.forEach((value, index) => {
                if (index !== 0) {
                  keywordResArray.current.push({
                    focus: { path, offset: 0 },
                    anchor: {
                      path: [path[0], path[1] + index],
                      offset: value.length + 1,
                    },
                    isKeyword: true,
                  });
                } else {
                  ranges = [
                    {
                      focus: { path, offset: 0 },
                      anchor: {
                        path: [path[0], path[1] + 1],
                        offset: keywordLength[0].length + 1,
                      },
                      isKeyword: true,
                    },
                  ];
                  rangeKeyword.current.push(path);
                }
              });
            }
          },
        );
        return ranges;
      }

      return ranges;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      search,
      toggle.isFollowing,
      selectedTagId,
      finderOptions?.isMatchCase,
      finderOptions?.isWholeWords,
      finderOptions?.searchRanges,
      finderOptions?.presentOccId,
      keyWordArray
    ],
  );

  const scrollToElement = (startTime: number, path: Path) => {
    setBlockStart(startTime);
    dispatch(setCurrentPath(path));
    dispatch(setCurrentChapterTime(startTime));
    scrollToNode(startTime, toggle.isFollowing);
  };

  const handleChangeTimecode = async (element: any) => {
    const path = ReactEditor.findPath(editor, element);
    const result = await timecodeRef.current?.show({
      path,
      start: element?.data?.start,
      end: element?.data?.end,
    });

    if (!result) return;

    // Perform update node
    const op: SetNodeOperation = {
      type: 'set_node',
      path,
      properties: {},
      newProperties: {
        data: { ...element.data, start: result?.start, end: result?.end },
      } as Partial<Node>,
    };
    editor.apply(op);
  };

  const newTimeCode = useSelector(
    (state: RootState) => state.chapter.newTimeCode,
  );
  const isParagraphMode = useSelector(
    (state: RootState) => state.player.isParagraphMode,
  );
  const isSplitCaption = useSelector(
    (state: RootState) => state.chapter.isSplitCaption,
  );
  const isSplitParagraph = useSelector(
    (state: RootState) => state.chapter.isSplitParagraph,
  );

  useEffect(() => {
    if (
      (!isParagraphMode && isSplitCaption) ||
      (isParagraphMode && isSplitParagraph)
    ) {
      const editor = window?.Editor;
      const parentNode: any = editor.children.find((child: any) => {
        return child?.data?.end >= newTimeCode;
      });
      if (parentNode) {
        try {
          const anchorPath = [parentNode.id - 1];
          parentNode.children.find((child: any, index: number) => {
            if (child.data?.dataStart >= newTimeCode) {
              anchorPath.push(index);
              return child;
            }
            return false;
          });

          if (anchorPath[0] === 0) return;

          Transforms.select(editor, anchorPath);
          // Break chapter
          breakCaption([anchorPath[0]], true);
        } catch (error: any) {
          console.log('Split Transcript Error :>> ', error);
        }
      }
      if (!isParagraphMode && isSplitCaption) {
        dispatch(setSplitCaption(false));
      }
      if (isParagraphMode && isSplitParagraph) {
        dispatch(setSplitParagraph(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParagraphMode, isSplitCaption, isSplitParagraph]);

  // SPLIT NODE ON ENTER
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // Select current node only on CTRL + A
    if (e.key === 'a' && e.ctrlKey) {
      e.preventDefault();
      const sel: any = window.Editor.selection;
      const parentNode = Editor.parent(editor, Editor.path(editor, sel));
      Transforms.select(window.Editor, parentNode[1]);
      return;
    }

    // Insert line break
    if (e.key === Keys.ENTER && e.altKey) {
      e.preventDefault();
      const editor = window?.Editor;
      editor.insertText('\n');
      return;
    }

    if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
      e.preventDefault();
      const editor = window?.Editor;
      const sel: any = window.Editor.selection;
      // Get current Transcript NODE by cursor position
      const parentNode = Editor.parent(editor, Editor.path(editor, sel));
      if (!sel || !editor || !parentNode) return;

      // Break to TWO NODE
      breakCaption(parentNode[1]);
      ReactEditor.deselect(window.Editor);
    }
  };

  const clickTranscript = () => {
    if (!isPublicShared()) return;

    try {
      const slateSelection = getSlateSelection();

      if (!isNil(slateSelection?.start)) {
        window.Video.currentTime = slateSelection.start + 0.001;
      }
    } catch (err: any) {
      // Not valid click point
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    if (
      isArray(e?.clipboardData?.types) &&
      e.clipboardData.types.includes('text/plain')
    ) {
      const textPlain = e.clipboardData.getData('text/plain');

      window?.Editor?.insertText(textPlain);
      e.preventDefault();
    }
  };

  return (
    <div css={[commonStyles]}>
      <style scoped>
        {`div[data-slate-node="element"][data-start="${blockStart}"] {
          margin-top: 0;
          margin-bottom: 0;
          padding-top: 2rem;
          background-color: rgb(240, 240, 255);
          border-bottom: 3px solid rgb(85, 81, 255);
        }`}
      </style>
      <Slate editor={editor} value={props.document} onChange={onChangeEdit}>
        <Editable
          decorate={decorate}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          readOnly={!toggle.isEditMode}
          onKeyDown={handleKeyDown}
          // spellCheck={true}
          onMouseUp={clickTranscript}
          onPaste={handlePaste}
        />
      </Slate>

      <TimecodeModal ref={timecodeRef} />
    </div>
  );
};

const Element = (props: any) => {
  const { element, handleChangeTimecode } = props;

  switch (element?.type) {
    case 'caption':
    case 'paragraph':
      return (
        <Suspense fallback={<TranscriptSkeleton />}>
          <Transcript
            // {...props}
            attributes={props.attributes}
            element={props.element}
            children={props.children}
            onChangeTimecode={handleChangeTimecode}
          ></Transcript>
        </Suspense>
      );

    default:
      // For the default case, we delegate to Slate's default rendering.
      return <DefaultElement {...props} />;
  }
};

const Leaf = (props: any) => {
  const { attributes, children, leaf } = props;

  return (
    <span
      {...attributes}
      className="word"
      data-start={`${leaf.data?.dataStart}`}
      data-end={`${leaf.data?.dataEnd}`}
      css={[
        leaf.highlight && tw`bg-green-200`,
        leaf?.focusHighlight && tw`background-color[#66ee96]!`,
        leaf.isKeyword && tw`bg-sonnant-keyword`,
        leaf.isCurrent &&
          tw`text-sonnant-purple-2 text-shadow[0 0 1px #5551FF]!`,
      ]}
      // title={`${leaf.text} | ${leaf?.data?.dataStart} - ${leaf?.data?.dataEnd}`}
    >
      {children}
    </span>
  );
};
