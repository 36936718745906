/** @jsxImportSource @emotion/react */
import 'twin.macro';
import React, { useEffect, useState } from 'react';
import { ceil, toNumber } from 'lodash';
import { Keys } from 'utils/enum';

type Props = {
  totalItemsLength: number;
  pageSize: number;
  setPageNumber: (newPageNumber: number) => void;
  pageNumber: number;
};

export const Paginator = (props: Props) => {
  const {
    totalItemsLength: totalItems,
    pageSize,
    setPageNumber,
    pageNumber,
  } = props;

  const [pageNumberState, setPageNumberState] = useState(pageNumber);

  useEffect(() => {
    setPageNumberState(pageNumber);
  }, [pageNumber]);

  const totalPage = ceil(totalItems / pageSize);

  const getPossiblePage = (num: number | string) => {
    const page = toNumber(num);

    if (page >= totalPage) {
      return totalPage;
    }
    if (page < 1) {
      return 1;
    }
    return page;
  };

  const handleClickPrevPage = () => {
    setPageNumber(pageNumber - 1);
  };

  const handleGotoPage = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
      const newPageNumber = getPossiblePage(pageNumberState);
      setPageNumber(newPageNumber);
    }
  };

  const handleClickNextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageNumberState(toNumber(e.target.value));
  };

  return (
    <div tw="grid grid-flow-col space-x-8 justify-between items-center">
      <div>
        <button
          tw="border-0 h-16 w-44 rounded-md my-12 background[#5551ff] text-xl hover:background[#5551ff]"
          className="button"
          onClick={handleClickPrevPage}
          disabled={pageNumber <= 1}
        >
          Previous
        </button>
      </div>
      <div tw="flex items-center">
        <input
          tw="width[5.5rem]! text-center outline-none mr-2 mb-0!"
          //   css={numberNoArrow}
          type="number"
          value={pageNumberState || 1}
          onKeyDown={handleGotoPage}
          onChange={handleOnChange}
          min={1}
          max={totalPage}
        />
        <div tw="whitespace-nowrap">of {totalPage ?? 1}</div>
      </div>
      <div>
        <button
          tw="border-0 h-16 w-44 rounded-md my-12 background[#5551ff] text-xl hover:background[#5551ff]"
          className="button"
          onClick={handleClickNextPage}
          disabled={pageNumber >= totalPage}
        >
          Next
        </button>
      </div>
    </div>
  );
};
