/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as AddTermSvg } from 'assets/Icons/library_add_black_24dp.svg';

import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { Info } from 'components/shared/Info';
import { SearchInput } from 'components/shared/SearchInput';
import {
  SingleNameCreationModal,
  SingleNameCreationModalRef,
} from 'components/shared/SingleNameCreationModal/SingleNameCreationModal';
import { first, isEmpty, sortBy } from 'lodash';
import { useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { RootState } from 'reducers';
import { AdminPortalService } from 'services/admin-portal.service';
import {
  appendUserGroup,
  deleteUserGroup,
  fetchAllUserGroups,
  setSelectedUserGroupId,
  toggleRefetchCollections,
} from 'slices/admin-portal.slice';
import { HTTPStatus, ModalOptions, StringEnum } from 'utils/enum';
import { BasicConfirmModal } from 'utils/models/modal.model';
import { customToast } from 'utils/toast.util';
import { PortalGroupItem } from './PortalGroupItem';
import { searchBy } from 'utils/generic.util';
import { AxiosError } from 'axios';
import { DefaultGroupNames, UserGroupResponse } from 'utils/models';

type Props = {};

export const PortalGroupsPane = (props: Props) => {
  const dispatch = useDispatch();

  const singleNameCreationModal = useRef<SingleNameCreationModalRef>(null);
  const confirmModalRef = useRef<BasicConfirmModal>(null);

  const adminPortal = useSelector((state: RootState) => state.adminPortal);

  const [searchText, setSearchText] = useState<string>(StringEnum.EMPTY);
  const [isLoading, toggleLoading] = useToggle(false);

  // Ensure Admin and User always on top
  const userGroups = sortBy(adminPortal.userGroups, (item) => {
    const order: DefaultGroupNames[] = ['Administrators', 'Users'];

    const groupIndex = order.indexOf(item.groupName as DefaultGroupNames);

    return groupIndex >= 0 ? groupIndex : Infinity;
  });

  const searchedGroups = userGroups.filter(searchBy(searchText, ['groupName']));

  const isEmptyGroupList = isEmpty(userGroups) && !searchText;
  const isEmptySearchResult = isEmpty(searchedGroups) && searchText;

  const handleClickAddGroup = async () => {
    const result = await singleNameCreationModal.current?.show();

    if (result?.option !== ModalOptions.YES) return;

    const groupName = result.singleName?.trim() ?? '';

    const existingUserGroup = userGroups.filter(
      (userGroup) => userGroup.groupName === groupName,
    );

    if (!isEmpty(existingUserGroup)) {
      customToast.warning(
        <div>
          <strong>{first(existingUserGroup)?.groupName}</strong> is already
          existing.
          <div>Please check and try again!</div>
        </div>,
      );

      return;
    }

    addNewUserGroupAsync(groupName);
  };

  const addNewUserGroupAsync = async (newGroupName: string) => {
    const newUserGroupAsync = AdminPortalService.saveNewUserGroup({
      groupName: newGroupName,
    });

    customToast.promise(newUserGroupAsync, {
      loading: (
        <span>
          Creating <strong>{newGroupName}</strong>
        </span>
      ),
      success: (
        <span>
          <strong>{newGroupName}</strong> created!
        </span>
      ),
      error: 'Creation failed or existed group. Please check and try again.',
    });

    try {
      toggleLoading(true);

      const { data: newUserGroupId } = await newUserGroupAsync;

      const groupInfo = {
        groupId: newUserGroupId,
        groupName: newGroupName,
      };

      dispatch(appendUserGroup(groupInfo));
      dispatch(fetchAllUserGroups());
    } catch (error) {
      console.log('error :>> ', error);
    } finally {
      toggleLoading(false);
    }
  };

  const handleDeleteGroup = async (item: UserGroupResponse) => {
    const result = await confirmModalRef.current?.show();

    if (result !== ModalOptions.YES) return;

    try {
      customToast.loading('Validating user group...');
      await AdminPortalService.deleteUserGroup(item.groupId);
      customToast.success('Deleted user group successfully');

      dispatch(toggleRefetchCollections(true));
      dispatch(deleteUserGroup(item.groupId));
      dispatch(fetchAllUserGroups());
    } catch (error) {
      console.log('error :>> ', error);
      const statusCode = (error as AxiosError).response?.status;

      switch (statusCode) {
        case HTTPStatus.BAD_REQUEST:
          customToast.warning(
            'User Group is not empty and cannot be deleted. Please check and try again.',
          );
          break;
        default:
          customToast.error('Something went wrong');
          break;
      }
    }
  };

  const handleSelectUserGroup = (userGroupId: string) => {
    dispatch(setSelectedUserGroupId(userGroupId));
  };

  return (
    <div tw="w-full flex-1 text-black">
      <div tw="w-full flex gap-x-5 justify-end">
        <SearchInput setTerm={setSearchText} placeholder="Search Groups" />

        <button
          className="button large btn-primary"
          tw="(max-w-[unset] w-auto px-3)! flex items-center whitespace-nowrap mb-0"
          onClick={handleClickAddGroup}
          disabled={isLoading}
        >
          <AddTermSvg tw="mr-3" /> Create User Group
        </button>
      </div>

      <div tw="mt-8">
        <div tw="flex w-full justify-between text-12 uppercase font-black">
          <div tw="flex">
            <div tw="mr-6">#</div>
            <div>USER GROUPS</div>
          </div>
          <div tw="flex mr-6 text-sonnant-dark">
            {/* <div tw="mr-[2.6rem]">ADMIN</div> */}
            <div tw="mr-[2.6rem]">EDIT</div>
            <div tw="mr-[5rem]">PUBLISH</div>
            <div>DEL</div>
          </div>
        </div>
        <DragDropContext onDragEnd={() => {}}>
          <Droppable droppableId="portalGroupDnd">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {searchedGroups.map((groupItem, index) => (
                  <PortalGroupItem
                    key={groupItem.groupId}
                    index={index}
                    groupItem={groupItem}
                    handleDeleteGroup={handleDeleteGroup}
                    onClick={() => handleSelectUserGroup(groupItem.groupId)}
                  />
                ))}

                {isEmptyGroupList && (
                  <div tw="mt-5">
                    <Info text="There are no groups created yet." />
                  </div>
                )}

                {isEmptySearchResult && <Info text="No results found" />}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <SingleNameCreationModal
        ref={singleNameCreationModal}
        title="Create new User Group"
        label="User group"
        placeHolder="Please enter your new group name..."
        confirmText="Create group"
      />

      <ConfirmModal
        ref={confirmModalRef}
        title="Delete confirm"
        message={
          <div>
            You're about delete user group and its associated configurations.
            <br />
            This action cannot be undone.
            <strong>Would you like to proceed?</strong>
          </div>
        }
      />
    </div>
  );
};
