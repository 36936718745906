import React from "react";

const SonnantLogoText = () => {
  return (
    <svg
      width="128"
      height="26"
      viewBox="0 0 180 40"
      fill="#2C2BA6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="st0" d="M184,34c-0.4-0.4-0.8-0.6-1.3-0.6h-1.5c-1.2,0-2.1-0.5-2.9-1.4c-0.8-0.9-1.2-2.1-1.2-3.4V16.7h4.4
    c0.6,0,1.1-0.2,1.5-0.5c0.4-0.4,0.6-0.8,0.6-1.3c0-0.6-0.2-1.1-0.6-1.4c-0.4-0.3-0.9-0.5-1.5-0.5h-4.4v-7c0-0.6-0.2-1.2-0.6-1.6
    c-0.4-0.4-0.9-0.6-1.6-0.6c-0.6,0-1.2,0.2-1.6,0.6c-0.4,0.4-0.6,0.9-0.6,1.6v7h-2.4c-0.6,0-1.1,0.2-1.5,0.5
    c-0.4,0.4-0.6,0.8-0.6,1.4c0,0.5,0.2,0.9,0.6,1.3c0.4,0.4,0.9,0.5,1.5,0.5h2.4v11.9c0,1.8,0.4,3.3,1.1,4.7c0.7,1.4,1.7,2.4,3,3.3
    c1.3,0.8,2.7,1.2,4.3,1.2h0.9c0.7,0,1.3-0.2,1.8-0.6s0.7-0.9,0.7-1.6C184.5,35,184.3,34.4,184,34z M15.4,16.7
    c-2.4-0.3-4.2-0.7-5.5-1.3c-1.3-0.6-2.2-1.4-2.7-2.3c-0.5-0.9-0.8-1.8-0.8-2.8C6.4,8.4,7.2,7,8.7,6c1.6-1,3.5-1.4,5.9-1.4
    c1.4,0,2.5,0.2,3.5,0.6c1,0.4,1.9,0.9,2.6,1.5c0.7,0.6,1.3,1.4,1.8,2.2c0.3,0.5,0.8,0.9,1.4,1.1c0.6,0.2,1.2,0.1,1.8-0.2
    c0.5-0.3,0.8-0.8,0.9-1.3s-0.1-1.2-0.5-1.8c-0.8-1.2-1.7-2.3-2.7-3.2c-1.1-0.9-2.3-1.7-3.7-2.2c-1.4-0.5-3.1-0.8-4.9-0.8
    c-2.4,0-4.6,0.4-6.5,1.1C6.3,2.4,4.7,3.5,3.6,5c-1.1,1.4-1.7,3.2-1.7,5.2c0,2.8,1.1,5.1,3.3,7c2.2,1.9,5.2,3,9,3.4
    c3,0.4,5.1,1.2,6.5,2.5c1.3,1.3,2,2.9,2,4.6c0,1.4-0.4,2.6-1.1,3.5s-1.8,1.7-3.1,2.2s-2.7,0.8-4.3,0.8c-1.4,0-2.7-0.2-3.9-0.6
    s-2.3-1-3.2-1.6s-1.5-1.4-1.9-2.3c-0.3-0.5-0.6-0.9-1.1-1.2c-0.5-0.3-1-0.3-1.5-0.2c-0.7,0.2-1.2,0.5-1.5,1c-0.3,0.5-0.3,1.1,0,1.7
    c0.7,1.5,1.6,2.7,3,3.8c1.3,1.1,2.9,2,4.6,2.6c1.8,0.6,3.7,0.9,5.7,0.9c1.7,0,3.3-0.2,4.9-0.7c1.6-0.5,2.9-1.1,4.2-2
    c1.2-0.9,2.2-2,2.8-3.3c0.7-1.3,1-2.9,1-4.6c0-3-1-5.5-3-7.4C22.1,18.3,19.2,17.1,15.4,16.7z M48.8,13.5c-2-1.1-4.2-1.7-6.7-1.7
    c-2.5,0-4.8,0.6-6.7,1.7c-2,1.1-3.5,2.7-4.6,4.6c-1.1,2-1.7,4.2-1.7,6.8c0,2.6,0.6,4.8,1.7,6.8c1.1,2,2.7,3.5,4.6,4.6
    c2,1.1,4.2,1.7,6.7,1.7c2.5,0,4.8-0.6,6.7-1.7s3.5-2.7,4.6-4.6c1.1-2,1.7-4.2,1.7-6.8c0-2.6-0.6-4.8-1.7-6.8
    C52.3,16.1,50.8,14.6,48.8,13.5z M49.8,29.6C49,31,48,32.1,46.7,32.9c-1.3,0.8-2.8,1.2-4.5,1.2c-1.7,0-3.2-0.4-4.5-1.2
    c-1.3-0.8-2.4-1.9-3.1-3.2c-0.8-1.4-1.1-2.9-1.1-4.7c0-1.8,0.4-3.4,1.1-4.8c0.8-1.4,1.8-2.5,3.1-3.3c1.3-0.8,2.8-1.2,4.5-1.2
    c1.7,0,3.2,0.4,4.5,1.2c1.3,0.8,2.4,1.9,3.1,3.3c0.8,1.4,1.1,3,1.1,4.8C50.9,26.7,50.5,28.3,49.8,29.6z M77,13.1
    c-1.7-0.9-3.7-1.4-5.8-1.4c-2.1,0-3.9,0.4-5.6,1.3c-1,0.5-1.8,1.2-2.6,2v-0.9c0-0.7-0.2-1.2-0.6-1.6S61.4,12,60.8,12
    s-1.2,0.2-1.6,0.6c-0.4,0.4-0.6,0.9-0.6,1.6v21.4c0,0.6,0.2,1.2,0.6,1.6c0.4,0.4,0.9,0.6,1.6,0.6s1.2-0.2,1.6-0.6
    c0.4-0.4,0.6-0.9,0.6-1.6V22.1c0-1.2,0.3-2.3,1-3.2c0.7-0.9,1.5-1.7,2.7-2.3c1.1-0.6,2.4-0.9,3.8-0.9c1.5,0,2.8,0.3,4,0.9
    s2.1,1.5,2.8,2.6s1,2.7,1,4.5v11.8c0,0.6,0.2,1.2,0.6,1.6c0.4,0.4,0.9,0.6,1.6,0.6c0.6,0,1.2-0.2,1.6-0.6c0.4-0.4,0.6-0.9,0.6-1.6
    V23.8c0-2.6-0.5-4.8-1.5-6.6C80.1,15.4,78.8,14.1,77,13.1z M161.7,13.1c-1.7-0.9-3.7-1.4-5.9-1.4c-2.1,0-3.9,0.4-5.6,1.3
    c-1,0.5-1.8,1.2-2.6,2v-0.9c0-0.7-0.2-1.2-0.6-1.6s-0.9-0.6-1.6-0.6c-0.7,0-1.2,0.2-1.6,0.6s-0.6,0.9-0.6,1.6v21.4
    c0,0.6,0.2,1.2,0.6,1.6c0.4,0.4,0.9,0.6,1.6,0.6c0.7,0,1.2-0.2,1.6-0.6c0.4-0.4,0.6-0.9,0.6-1.6V22.1c0-1.2,0.3-2.3,1-3.2
    c0.7-0.9,1.5-1.7,2.7-2.3c1.1-0.6,2.4-0.9,3.8-0.9c1.5,0,2.8,0.3,4,0.9c1.2,0.6,2.1,1.5,2.8,2.6c0.7,1.2,1,2.7,1,4.5v11.8
    c0,0.6,0.2,1.2,0.6,1.6c0.4,0.4,0.9,0.6,1.6,0.6c0.6,0,1.2-0.2,1.6-0.6c0.4-0.4,0.6-0.9,0.6-1.6V23.8c0-2.6-0.5-4.8-1.5-6.6
    C164.8,15.4,163.4,14.1,161.7,13.1z M133.4,13.5c-1.9-1.2-4.2-1.7-6.6-1.7c-2.5,0-4.7,0.6-6.6,1.7s-3.5,2.7-4.7,4.7
    c-1.2,2-1.7,4.2-1.7,6.7c0,2.5,0.5,4.7,1.6,6.7s2.5,3.5,4.4,4.7c1.9,1.1,3.9,1.7,6.3,1.7c2.3,0,4.4-0.6,6.2-1.7
    c1.2-0.8,2.3-1.7,3.1-2.8v2.2c0,0.6,0.2,1.1,0.6,1.6c0.4,0.4,1,0.6,1.6,0.6c0.7,0,1.2-0.2,1.6-0.6c0.4-0.4,0.6-0.9,0.6-1.6V24.9
    c0-2.5-0.6-4.7-1.7-6.7C136.9,16.2,135.4,14.6,133.4,13.5z M134.4,29.6c-0.8,1.4-1.8,2.5-3.1,3.3c-1.3,0.8-2.8,1.2-4.5,1.2
    c-1.6,0-3.1-0.4-4.5-1.2c-1.3-0.8-2.4-1.9-3.2-3.3c-0.8-1.4-1.2-2.9-1.2-4.7c0-1.8,0.4-3.3,1.2-4.7c0.8-1.4,1.8-2.5,3.2-3.3
    c1.3-0.8,2.8-1.2,4.5-1.2c1.7,0,3.2,0.4,4.5,1.2c1.3,0.8,2.3,1.9,3.1,3.3c0.8,1.4,1.2,2.9,1.2,4.7C135.6,26.6,135.2,28.2,134.4,29.6
    z M105.3,13.1c-1.7-0.9-3.7-1.4-5.8-1.4c-2.1,0-3.9,0.4-5.6,1.3c-1,0.5-1.8,1.2-2.6,2v-0.9c0-0.7-0.2-1.2-0.6-1.6
    c-0.4-0.4-0.9-0.6-1.6-0.6c-0.7,0-1.2,0.2-1.6,0.6c-0.4,0.4-0.6,0.9-0.6,1.6v21.4c0,0.6,0.2,1.2,0.6,1.6c0.4,0.4,0.9,0.6,1.6,0.6
    c0.7,0,1.2-0.2,1.6-0.6c0.4-0.4,0.6-0.9,0.6-1.6V22.1c0-1.2,0.3-2.3,1-3.2c0.7-0.9,1.6-1.7,2.7-2.3c1.1-0.6,2.4-0.9,3.8-0.9
    c1.5,0,2.8,0.3,4,0.9s2.1,1.5,2.8,2.6s1,2.7,1,4.5v11.8c0,0.6,0.2,1.2,0.6,1.6c0.4,0.4,0.9,0.6,1.6,0.6c0.6,0,1.2-0.2,1.6-0.6
    c0.4-0.4,0.6-0.9,0.6-1.6V23.8c0-2.6-0.5-4.8-1.5-6.6C108.4,15.4,107,14.1,105.3,13.1z"/>
    </svg>
  );
};

export default SonnantLogoText;
