import { ILibraryItem, IPagingRequest, IRenameRequest } from 'utils/models';
import * as types from './types';

export const initLibrary = (data?: IPagingRequest) => {
  return {
    type: types.INIT_LIBRARY,
    data: data,
  };
};

export const setLibraryLoading = (isLoading: boolean) => {
  return {
    type: types.SET_LIBRARY_LOADING,
    isLoading,
  };
};

export const fetchLibrary = () => {
  return {
    type: types.FETCH_LIBRARY,
    // payload:Comment
  };
};

export const fetchLibraryNoLoading = (payload?: IPagingRequest) => {
  return {
    type: types.FETCH_LIBRARY_NO_LOADING,
    payload,
  };
};

export const setLibrary = (data: { library: ILibraryItem[] }) => {
  return {
    type: types.SET_LIBRARY,
    payload: data,
  };
};
export const fetchfailedLibrary = () => {
  return {
    type: types.FAILED_LIBRARY,
    // payload:data
  };
};

//login actions
export const initLogin = (loginData: unknown) => {
  return {
    type: types.INIT_LOGIN,
    payload: loginData,
  };
};
export const setToken = (token: string | null, username: string | null) => {
  return {
    type: types.SET_TOKEN,
    token,
    username,
  };
};
export const setTokenFailed = (error: unknown) => {
  return {
    type: types.SET_TOKEN_FAILED,
    error,
  };
};

export const handleStatusAlert = (statusMsg: string, isSuccess: boolean) => {
  return {
    type: types.HANDLE_ALERT_DISPLAY,
    statusMsg,
    isSuccess,
  };
};

export const handleStatusAlertClose = () => {
  return {
    type: types.HANDLE_ALERT_CLOSE,
  };
};

export const handleSearchClick = (searchTerm: string) => {
  return {
    type: types.SEARCH_CLICK,
    searchTerm,
  };
};

export const handleSearchClear = () => {
  return {
    type: types.SEARCH_CLEAR,
  };
};

export const handleProfilePic = (profilePic: string) => {
  return {
    type: types.HANDLE_PROFILE_PIC,
    profilePic,
  };
};

export const renameLibraryItem = (payload: IRenameRequest) => ({
  type: types.UPDATE_TITLE_SAGA,
  payload,
});

export const setSelectedRows = (selectedRows: ILibraryItem[]) => ({
  type: types.SET_SELECTED_ROWS,
  payload: selectedRows,
});

export const setSelectMode = (isSelectMode: boolean) => ({
  type: types.SET_SELECT_MODE,
  payload: isSelectMode,
});

export const setFocusRow = (row: ILibraryItem | null) => ({
  type: types.SET_FOCUS_ROW,
  payload: row,
})

export const addClip = (mediaid: string) => ({
  type: types.ADD_CLIP,
  mediaid,
});

export const removeClip = (mediaid: string) => ({
  type: types.REMOVE_CLIP,
  mediaid,
});

export const removeClipFromCollections = (mediaid: string) => ({
  type: types.REMOVE_CLIP_FROM_COLLECTION,
  mediaid,
});
