import { AxiosResponse } from 'axios';
import { BaseAPI } from 'apis/amazon';
import { SubdomainResponse } from 'utils/models';
import { Social } from 'utils/enum';

export type AuthPayload = {
  subdomain: string;
  username: string;
  session: string;
  newpassword: string;
};

export type SignupPayload = {
  companyname: string;
  email: string;
  firstname: string;
  lastname: string;
  subdomain: string;
  profilePicture?: string;
  provider?: Social;
};

export type SignupSocialPayload = SignupPayload & {
  token?: string;
};

export type SignupSocialResponse = {
  AccessToken: string;
  IdToken: string;
  RefreshToken: string;
};

export type SigninPayload = {
  email: string;
  password: string;
  subdomain: string;
};

export type ChangePasswordPayload = {
  currentpassword: string;
  newpassword: string;
};

export type ForgetPasswordPayload = {
  subdomain: string;
  username: string;
};

export type ConfirmPasswordPayload = {
  newpassword: string;
  subdomain: string;
  username: string;
  verificationcode: string;
};

export type SigninSocialPayload = {
  email: string;
  subdomain: string;
  token: string;
  provider: Social;
};

export interface AppleIdResponse {
  authorization: {
    code: string;
    id_token: string;
    state?: string;
  };
}

export interface GoogleIdResponse {
  clientId: string;
  credential: string;
  select_by: string;
}

export interface FbSdkResponse {
  id: string;
  picture: { data: FbPicture };
  first_name: string;
  last_name: string;
  email: string;
}

export interface FbPicture {
  height: number;
  is_silhouette: boolean;
  url: string;
  width: number;
}

export interface Data {}

export type SubdomainPayload = {
  email: string;
  token?: string;
  provider?: Social;
};

export const AuthService = {
  // POST /auth
  auth: (payload: AuthPayload): Promise<AxiosResponse<string>> => {
    return BaseAPI.post('/auth', payload);
  },

  // POST /auth/register
  register: (payload: SignupPayload): Promise<AxiosResponse<string>> => {
    return BaseAPI.post('/auth/register', payload);
  },

  // POST /auth/register/social
  registerSocial: (
    payload: SignupSocialPayload,
  ): Promise<AxiosResponse<SignupSocialResponse>> => {
    return BaseAPI.post('/auth/register-social', payload);
  },

  // POST /auth/signin
  signin: (payload: SigninPayload): Promise<void> => {
    return BaseAPI.post('/auth/signin', payload);
  },

  // POST /auth/signin-social
  signinSocial: (
    payload: SigninSocialPayload,
  ): Promise<AxiosResponse<SignupSocialResponse>> => {
    return BaseAPI.post('/auth/signin-social', payload);
  },

  // POST /auth/get-subdomain
  getSubdomain: (
    payload: SubdomainPayload,
  ): Promise<AxiosResponse<string[]>> => {
    return BaseAPI.post('/auth/get-subdomain', payload);
  },

  // PUT /auth/change-password
  changePassword: (
    payload: ChangePasswordPayload,
  ): Promise<AxiosResponse<string>> => {
    return BaseAPI.post('/auth/change-password', payload);
  },

  // PUT /auth/change-password
  forgetPassword: (
    payload: ForgetPasswordPayload,
  ): Promise<AxiosResponse<string>> => {
    return BaseAPI.post('/auth/forgot-password', payload);
  },

  // POST /auth/confirm-password
  confirmPassword: (
    payload: ConfirmPasswordPayload,
  ): Promise<AxiosResponse<string>> => {
    return BaseAPI.post('/auth/confirm-password', payload);
  },

  // GET /auth/get-all-subdomain
  getAllSubdomain: (): Promise<AxiosResponse<SubdomainResponse[]>> => {
    return BaseAPI.get('/auth/get-all-subdomain');
  },

  // POST /auth/accesstoken-twitter
  getTwitterToken: (): Promise<AxiosResponse<string>> => {
    return BaseAPI.get('/auth/accesstoken-twitter');
  },
};
