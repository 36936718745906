/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { TextInput } from 'components/shared/TextInput';
import { useToggle } from 'react-use';
import { Ripple } from '@rmwc/ripple';
import { AdvancedFilterType } from 'utils/enum';
import { useDispatch } from 'react-redux';
import { setSearchParams } from 'slices/advanced-search.slice';
import { isEmpty } from 'lodash';
import { customToast } from 'utils/toast.util';

type FilterBoxItemsProps = {
  displayText: string;
  typeEnum: AdvancedFilterType;
  placeholder?: string;
};

export const FilterInput = (props: FilterBoxItemsProps) => {
  const dispatch = useDispatch();

  const [isEditing, toggleEditing] = useToggle(false);

  const handleEnter = (textResult: string) => {
    if (isEmpty(textResult?.trim())) {
      customToast.error('Input cannot be blank. Please try again.');
      return;
    }

    exitEditing();

    const payload = {
      type: props.typeEnum,
      data: textResult,
    };

    dispatch(setSearchParams(payload));
  };

  const handleBlur = (textResult: string) => {
    if (!textResult?.trim()) {
      exitEditing();
    } else {
      handleEnter(textResult);
    }
  }

  const handleEsc = () => {
    exitEditing();
  };

  const exitEditing = () => {
    toggleEditing(false);
  };

  return isEditing ? (
    <div tw="px-0.5">
      <div tw="flex justify-between border-width[2px] border-solid border-sonnant-blue rounded-xl mt-1.5">
        <TextInput
          tw="(border-none focus:(border-none) height[2.5rem] py-1 px-1.5 pl-2 text-13 placeholder:(text-12 font-normal) !bg-transparent font-medium)!"
          onPressEnter={handleEnter}
          onPressEsc={handleEsc}
          // onBlur={exitEditing}
          onBlur={handleBlur}
          placeholder={props?.placeholder ?? ''}
        />
      </div>
    </div>
  ) : (
    <Ripple onClick={() => toggleEditing(true)}>
      <div css={[textCss]}>{props?.displayText}</div>
    </Ripple>
  );
};

const textCss = css`
  ${tw`flex justify-between items-center text-13 font-medium opacity[.75] w-full cursor-pointer py-1 pl-1.5 pr-1 margin-top[0.3rem] rounded-md`}

  &:hover {
    ${tw`bg-sonnant-grey-light`}
  }
`;
