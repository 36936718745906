/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { SimpleMenuSurface } from '@rmwc/menu';
import { ReactElement } from 'react';

import { Option } from '../CustomSelectSearch';
import { scrollbarWidthCss } from '../twin.styles';
import { InsightAdvancedSelectionItem } from './InsightAdvancedSelectionItem';

type Props = {
  menuIsOpen: boolean;
  anchorElement: ReactElement;

  fixed?: boolean;
  showReset?: boolean;
  optionList: Option[];

  onSelectionChange: (option: Option) => void;
};

export const SelectionWrapper = ({
  menuIsOpen,
  anchorElement,
  fixed = false,
  optionList,
  ...props
}: Props) => {
  const handleSelectItem = (selectedOption: Option) => {
    props.onSelectionChange(selectedOption);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <SimpleMenuSurface
        open={menuIsOpen}
        handle={anchorElement}
        anchorCorner="bottomLeft"
        fixed={fixed}
        tw="my-[4px] min-w-[23rem] rounded-lg overflow-hidden z-[21]"
      >
        <div tw="py-3">
          <div tw="text-16 pl-4 font-medium opacity-90">Time range</div>

          <div tw="flex flex-col gap-y-2 pt-2.5">
            <div
              tw="flex flex-col rounded max-h-[20rem] overflow-y-auto"
              css={[scrollbarWidthCss(4)]}
            >
              {optionList.map((option, index) => (
                <InsightAdvancedSelectionItem
                  key={index}
                  option={option}
                  onClick={handleSelectItem}
                />
              ))}
            </div>
          </div>
        </div>
      </SimpleMenuSurface>
    </div>
  );
};
