/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Keyword } from 'components/VideoPlayer/Transcription/Keyword/Keyword';
import { first, isEmpty, isNil, isNumber } from 'lodash';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { match } from 'ts-pattern';
import { Layers } from 'utils/enum';
import { IKeyItem } from 'utils/models';
import { getLayerEntity } from 'utils/utils';
import { EmbedLayerEmpty } from '../../shared/EmbedLayerEmpty';
import { setEmbedFocusTerm } from 'slices/embed.slice';
import { ensureArray, extract } from 'utils/generic.util';
import { useEmbedContext } from '../../utils/useEmbedContext';

type Props = {};

export const EmbedMetaViewer = (props: Props) => {
  const dispatch = useDispatch();

  const { controls } = useEmbedContext();

  const player = useSelector((state: RootState) => state.player);
  const layers = useSelector((state: RootState) => state.layers);

  const entityItems = useMemo<IKeyItem[]>(() => {
    return match(layers.currentLayer)
      .with(Layers.KEY_TERM, () => player.keywords)
      .otherwise(() => {
        const entityKey = getLayerEntity(layers.currentLayer);

        return player.entities[entityKey] ?? [];
      });
  }, [layers.currentLayer, player.entities]);

  const handleClickTerm = (term: IKeyItem) => {
    const occurrences = ensureArray(first(term.mentions)?.occurrences);
    const firstTermSecond = first(occurrences)?.s;

    if (isNil(firstTermSecond) || !isNumber(firstTermSecond)) return;

    controls.seek(firstTermSecond);

    dispatch(
      setEmbedFocusTerm({
        name: term.keyword,
        occurrences: extract(occurrences, 's') as number[],
      }),
    );
  };

  return (
    <div tw="flex flex-wrap">
      {entityItems.map((item, index) => (
        <Keyword key={index} val={item} setCurrentKeyword={handleClickTerm} />
      ))}

      {isEmpty(entityItems) && <EmbedLayerEmpty />}
    </div>
  );
};
