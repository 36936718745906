import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RevivalItemModel } from 'utils/models';

const revivalSlice = createSlice({
  name: 'revival',
  initialState: {
    focusRevival: null as null | RevivalItemModel,
  },
  reducers: {
    setFocusRevival: (state, { payload }: PayloadAction<RevivalItemModel>) => {
      state.focusRevival = payload;
    },
  },
});

export const { setFocusRevival } = revivalSlice.actions;

export const revivalReducer = revivalSlice.reducer;
