import React from 'react';
import { Route } from 'react-router-dom';
import { getDomainWithoutSubdomain, getToken } from '../../utils/utils';

export const PrivateRoute = ({ component: Component, path, ...rest }: any) => (
  <Route
    {...rest}
    render={(props) => {
      if (!getToken()) {
        window.location.replace(
          `${
            window.location.protocol
          }//${getDomainWithoutSubdomain()}/signin?next=${encodeURIComponent(
            window.location.href,
          )}`,
        );
      }
      return <Component {...props} />;
    }}
  />
);
