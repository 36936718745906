/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Info } from 'components/shared/Info';
import { TextItem } from 'components/shared/MonetisationTextSearch/MonetisationTextSearch';
import { TextBox } from 'components/shared/TextBox';
import { isEmpty } from 'lodash';

type Props<T> = {
  items: T[];
  onRemove: (item: T) => void;
  message?: string;
};

export const ListBox = <T extends TextItem>({
  items,
  message = 'Nothing to show.',
  ...props
}: Props<T>) => {
  const handleRemove = (item: TextItem) => {
    props.onRemove(item as T);
  };

  return (
    <div tw="flex flex-wrap content-start w-full p-4 mt-4 mb-6 overflow-y-auto height[40rem] border[1px solid lightgrey] rounded-lg">
      {items.map((text) => (
        <TextBox
          key={text.id}
          textItem={text}
          onRemove={handleRemove}
          onChange={() => {}}
          canEdit={false}
          canRemove={text?.canRemove}
        />
      ))}

      {isEmpty(items) && <Info text={message} />}
    </div>
  );
};
