import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import { isEmpty } from 'lodash';
import {
  SpecialSegment,
  SpecialSegmentRaw,
} from 'utils/models/transcript.model';

export type MediaSegmentState = {
  items: SpecialSegment[] | null;
  currentActiveId: string | null;
};

const initialState: MediaSegmentState = {
  items: null,
  currentActiveId: null,
};

const mediaSegmentSlice = createSlice({
  name: 'mediaSegment',
  initialState,
  reducers: {
    setMediaSegments: (
      state,
      { payload }: PayloadAction<SpecialSegmentRaw[]>,
    ) => {
      if (isEmpty(payload)) return;

      state.items = payload.map((segment) => ({
        id: v4(),
        ...segment,
      }));
    },
    setActiveSegmentId: (
      state,
      action: PayloadAction<MediaSegmentState['currentActiveId']>,
    ) => {
      state.currentActiveId = action.payload;
    },
    resetMediaSegmentItems: (state) => {
      state = initialState;

      return state;
    },
  },
});

export const { setMediaSegments, setActiveSegmentId, resetMediaSegmentItems } =
  mediaSegmentSlice.actions;

export const mediaSegmentReducer = mediaSegmentSlice.reducer;
