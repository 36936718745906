/** @jsxImportSource @emotion/react */
import 'twin.macro';
import tw from 'twin.macro';

import { ReactComponent as EditSvg } from 'assets/Icons/edit_black_18dp.svg';
import { ReactComponent as DelSvg } from 'assets/Icons/x-cancel-red_28dp.svg';
import { Hint } from 'components/shared/Hint';
import { Switcher } from 'components/shared/Switcher';
import { hoverDropShadow } from 'components/shared/twin.styles';
import { isEqual, toNumber } from 'lodash';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  toggleApplyTerm,
  toggleInsightTerm,
  toggleTerm,
  triggerSaveCustomVocab,
} from 'slices/global.slice';
import { CustomTerm } from 'utils/models';
import { MAX_TERMS_IN_VOCAB } from 'utils/constants';
import { customToast } from 'utils/toast.util';

interface Props {
  index: number;
  termList: CustomTerm;
  handleEditList: (item: CustomTerm) => void;
  handleDeleteList: (item?: CustomTerm) => void;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const CustomVocabItem = ({ termList, index, ...props }: Props) => {
  const dispatch = useDispatch();

  const global = useSelector((state: RootState) => state.global);

  const isFocus = isEqual(global.focusCustomList?.id, termList.id);

  const handleToggleEnable = () => {
    dispatch(toggleTerm(termList));
    dispatch(triggerSaveCustomVocab({ runBackground: false }));
  };

  const handleToggleApply = () => {
    dispatch(toggleApplyTerm(termList));
    dispatch(triggerSaveCustomVocab({ runBackground: false }));
  };

  const handleToggleInsight = () => {
    // Not allow to turn ON with more than MAX_TERMS
    if (
      !termList.insightVisible &&
      toNumber(termList.terms?.length) > MAX_TERMS_IN_VOCAB
    ) {
      customToast.maximumTermInsight(MAX_TERMS_IN_VOCAB);

      return;
    }

    dispatch(toggleInsightTerm(termList));
    dispatch(triggerSaveCustomVocab({ runBackground: false }));
  };

  const handleEditList = () => {
    props.handleEditList(termList);
  };

  const handleDeleteList = () => {
    props.handleDeleteList(termList);
  };

  return (
    <Draggable key={termList.id} draggableId={termList.name} index={index}>
      {(provided) => (
        <div
          key={termList.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          tw="flex w-full justify-between py-2 rounded -ml-3 pl-3 pr-2"
          onClick={props?.onClick}
          css={
            isFocus ? tw`background[#DAD9FE]` : tw`hover:(bg-sonnant-grey-2)`
          }
        >
          <div tw="flex items-center opacity[0.85] font-weight[500]">
            <span tw="mr-6">{index + 1}.</span>
            <span tw="break-all">{termList.name}</span>
          </div>
          <div tw="flex items-center cursor-pointer space-x-6">
            {/* ENABLE/DISABLE */}
            <Hint
              text={`Use this Custom Vocab for all new content items`}
              enterDelay={300}
              leaveDelay={0}
            >
              <div tw="cursor-pointer">
                <Switcher
                  hasLabel={true}
                  checked={termList.active}
                  color="#00AF7A"
                  onCheckedChange={handleToggleEnable}
                />
              </div>
            </Hint>

            {/* APPLY */}
            <Hint
              text={
                !termList?.active
                  ? 'Currently disabled'
                  : 'Create an Applied List for new content items'
              }
              enterDelay={300}
              leaveDelay={0}
            >
              <div tw="cursor-pointer">
                <Switcher
                  hasLabel={true}
                  checked={!!termList?.canApply}
                  disabled={!termList?.active}
                  color="#00AF7A"
                  onCheckedChange={handleToggleApply}
                />
              </div>
            </Hint>

            {/* INSIGHTS */}
            <Hint
              text="Allow this list to be visible on Insight statistics"
              enterDelay={300}
              leaveDelay={0}
            >
              <div tw="cursor-pointer">
                <Switcher
                  hasLabel={true}
                  checked={!!termList.insightVisible}
                  color="#00AF7A"
                  onCheckedChange={handleToggleInsight}
                />
              </div>
            </Hint>

            {/* EDIT */}
            <Hint text={`Edit list`} enterDelay={300} leaveDelay={0}>
              <div
                tw="border-radius[50%] bg-white hover:bg-sonnant-grey-2 flex items-center justify-center border[2px solid black] width[2.4rem] height[2.4rem] mr-2 cursor-pointer"
                css={hoverDropShadow(0.1)}
                onClick={handleEditList}
              >
                <EditSvg />
              </div>
            </Hint>

            {/* DELETE */}
            <Hint text="Delete list" enterDelay={300} leaveDelay={0}>
              <div
                tw="flex cursor-pointer"
                css={hoverDropShadow(0.2)}
                onClick={handleDeleteList}
              >
                <DelSvg />
              </div>
            </Hint>
          </div>
        </div>
      )}
    </Draggable>
  );
};
