import { BaseAPI } from 'apis/amazon';

export type SharePayload = {
  mediaid: string;
  versioncount: string;
  endtime?: string;
};

export type RevokePayload = {
  mediaid: string;
  versioncount: string;
};

export type ShareSocialPayload = {
  oauth_token?: string;
  oauth_verifier?: string;
  access_token_page?: string;
  page_id?: string;
  code?: string;
  mediaKey?: string;
  content?: string;
  provider?: string;
  access_token?: string;
  clipCategory?: string;
  privacyStatus?: string;
  title?: string;
};

export const ShareService = {
  // POST /share
  share: (payload: SharePayload) => {
    return BaseAPI.post('/share', payload);
  },

  // POST /share/revoke
  revokeShare: (payload: RevokePayload) => {
    return BaseAPI.post('/share/revoke', payload);
  },

  // POST /share/social
  shareSocial: (provider: string, payload: ShareSocialPayload) => {
    return BaseAPI.post('/share/social', payload, { params: { provider } });
  },
};
