/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { useDispatch, useSelector } from 'react-redux';
import 'twin.macro';
import { ReactComponent as BusinessSvg } from 'assets/Icons/business.svg';
import { ReactComponent as Event } from 'assets/Icons/Event.svg';
import { ReactComponent as FlagSvg } from 'assets/Icons/flag.svg';
import { ReactComponent as KeyTermSvg } from 'assets/Icons/keyterms.svg';
import { ReactComponent as MetadataSvg } from 'assets/Icons/metadata.svg';
import { ReactComponent as PeopleSvg } from 'assets/Icons/people.svg';
import { ReactComponent as SpeakerSvg } from 'assets/Icons/people-speaker.svg';
import { ReactComponent as PlaceSvg } from 'assets/Icons/place.svg';
import { ReactComponent as ShoppingSvg } from 'assets/Icons/shopping.svg';
import { ReactComponent as ChapterSvg } from 'assets/Icons/table-of-contents.svg';
import { ReactComponent as IABSvg } from 'assets/Icons/workspaces_black_24dp.svg';
import { ReactComponent as AdMarkerSvg } from 'assets/Icons/ad-marker.svg';
import { ReactComponent as AdBreakSvg } from 'assets/Icons/bolt.svg';
import { ReactComponent as SegmentSvg } from 'assets/Icons/segment-layer.svg';
import { setCurrentLayer } from '../../../../slices/layers.slice';
import { Layers } from '../../../../utils/enum';
import { getLayerName } from '../../../../utils/utils';
import './LayerDropdown.scss';
import { RootState } from 'reducers';
import { isPublicShared } from '../MediaUtilities';
import { isArray } from 'lodash';

interface IProps {
  hasSummary?: boolean;
  hasIAB?: boolean;
  hasGlobalList?: boolean;
  hasChapter?: boolean;
  hasKeyTerm?: boolean;
  hasPeople?: boolean;
  hasProducts?: boolean;
  hasOrgs?: boolean;
  hasLocations?: boolean;
  hasNationalities?: boolean;
  hasAdMarkers?: boolean;
  hasSpeakers?: boolean;
  hasAdBreaks?: boolean;
  hasMediaSegments?: boolean;
}

// Default show on if NOT PASSING any options
export const LayerDropdown = ({
  hasKeyTerm = true,
  hasGlobalList = true,
  hasChapter = true,
  hasSummary = true,
  hasIAB = true,
  hasPeople = true,
  hasProducts = true,
  hasOrgs = true,
  hasLocations = true,
  hasNationalities = true,
  hasAdMarkers = true,
  hasSpeakers = true,
  hasAdBreaks = true,
  hasMediaSegments = true,
}: IProps) => {
  const layersSelector = useSelector((state: RootState) => state.layers);
  const monetisation = useSelector(
    (state: RootState) => state.global.monetisation,
  );

  const adBreaks = useSelector((state: RootState) => state.adBreaks);
  hasAdBreaks = isArray(adBreaks.items);

  const mediaSegment = useSelector((state: RootState) => state.mediaSegment);
  hasMediaSegments = isArray(mediaSegment.items);

  const dispatch = useDispatch();

  const activeClass = (layer: Layers): string => {
    return layer === layersSelector.currentLayer ? 'active-layer' : '';
  };

  const layerOptions = [
    {
      layerEnum: Layers.SUMMARY,
      enabled: hasSummary,
      clickable: !hasSummary,
      icon: <MetadataSvg className="layer-icon" />,
    },
    {
      layerEnum: Layers.CUSTOM_TERM,
      enabled: hasGlobalList,
      clickable: !hasGlobalList,
      icon: <Event className="layer-icon" tw="w-9" />,
    },
    {
      layerEnum: Layers.AD_MARKER,
      enabled: isPublicShared()
        ? hasAdMarkers
        : hasAdMarkers && monetisation?.monetisationEnabled,
      clickable: !hasAdMarkers,
      icon: (
        <AdMarkerSvg
          tw="fill[#54566c] relative top[-1px]"
          className={`entity-icon ${!hasAdMarkers && 'disabled'}`}
        />
      ),
    },
    {
      layerEnum: Layers.AD_BREAK,
      enabled: hasAdBreaks,
      clickable: !hasAdBreaks,
      icon: (
        <AdBreakSvg
          tw="fill[#54566c] relative top[-1px]"
          className={`entity-icon ${!hasAdBreaks && 'disabled'}`}
        />
      ),
    },
    {
      layerEnum: Layers.SEGMENT,
      enabled: hasMediaSegments,
      clickable: !hasMediaSegments,
      icon: (
        <SegmentSvg
          tw="fill[#54566c] relative top[-1px]"
          className={`entity-icon ${!hasMediaSegments && 'disabled'}`}
        />
      ),
    },
    {
      layerEnum: Layers.CHAPTER,
      enabled: hasChapter,
      clickable: !hasChapter,
      icon: <ChapterSvg className="layer-icon" />,
    },
    {
      layerEnum: Layers.IAB,
      enabled: hasIAB,
      clickable: !hasIAB,
      icon: <IABSvg className="layer-icon" />,
    },
    {
      layerEnum: Layers.SPEAKER,
      enabled: hasSpeakers,
      clickable: !hasSpeakers,
      icon: <SpeakerSvg className="layer-icon" />,
    },
    {
      layerEnum: Layers.KEY_TERM,
      enabled: hasKeyTerm,
      clickable: !hasKeyTerm,
      icon: <KeyTermSvg className="layer-icon" />,
    },
    {
      layerEnum: Layers.PEOPLE,
      enabled: hasPeople,
      clickable: !hasPeople,
      icon: <PeopleSvg className={`entity-icon ${!hasPeople && 'disabled'}`} />,
    },
    {
      layerEnum: Layers.PRODUCT,
      enabled: hasProducts,
      clickable: !hasProducts,
      icon: (
        <ShoppingSvg className={`entity-icon ${!hasProducts && 'disabled'}`} />
      ),
    },
    {
      layerEnum: Layers.ORG,
      enabled: hasOrgs,
      clickable: !hasOrgs,
      icon: <BusinessSvg className={`entity-icon ${!hasOrgs && 'disabled'}`} />,
    },
    {
      layerEnum: Layers.LOCATION,
      enabled: hasLocations,
      clickable: !hasLocations,
      icon: (
        <PlaceSvg className={`entity-icon ${!hasLocations && 'disabled'}`} />
      ),
    },
    {
      layerEnum: Layers.NATIONALITY,
      enabled: hasNationalities,
      clickable: !hasNationalities,
      icon: (
        <FlagSvg className={`entity-icon ${!hasNationalities && 'disabled'}`} />
      ),
    },
  ];

  return (
    <ul
      id="layers-dropdown"
      className="dropdown-pane"
      data-dropdown
      data-hover="true"
      data-hover-pane="true"
      data-close-on-click="true"
    >
      <div className="grid-x flex-column">
        {layerOptions
          ?.filter((l) => l.enabled)
          .map((layer, index) => (
            <li
              key={index}
              className={`grid-x cell large-12 ${activeClass(
                layer.layerEnum,
              )} ${layer.clickable && 'disabled'}`}
              onClick={() => dispatch(setCurrentLayer(layer.layerEnum))}
            >
              {layer.icon}
              <span>{getLayerName(layer.layerEnum)}</span>
            </li>
          ))}
      </div>
    </ul>
  );
};
