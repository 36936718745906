import { SagaAction } from 'utils/models';
import * as types from '../actions/types';

interface InitialStateType {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  success: boolean;
}

const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
  success: false,
};

export const forgetPasswordReducer = (
  state: InitialStateType = initialState,
  action: SagaAction,
) => {
  switch (action.type) {
    case types.INIT_EMAIL_CHECK: {
      return { ...state, loading: true, error: false };
    }
    case types.SET_EMAIL_FINAL_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        success: true,
      };
    }
    case types.SET_FAILED_EMAIL_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };
    }
    case types.SET_EMAIL_MESSAGE_FALSE: {
      return { ...state, error: false, errorMessage: '', success: false };
    }
    default:
      return state;
  }
};
