import { CollectionType } from './../utils/enum';
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { CollectionSidebarItem, FilterMenuOptions } from 'utils/models';
import {
  CollectionApiResponse,
  CollectionResponse,
  CollectionService,
  CollectionSettingPayload,
  CollectionSettingResponse,
  CollectionState,
} from 'services/collection.service';
import {
  setCollectionIdLocal,
  toCollectionItem,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { customToast } from 'utils/toast.util';
import { RootState } from 'reducers';
import { isEmpty, isNil } from 'lodash';
import { defaultSetting } from 'utils/default/defaultSetting';
import { extract } from 'utils/generic.util';

type CollectionSettingParams = {
  data?: CollectionState;
  isResetDefault?: boolean;
};

type FetchCollectionPayload = {
  collection: CollectionResponse;
  visible_collection: CollectionApiResponse[];
  default_collection: string | null;
};

export const collectionSelector = createSelector(
  (state: RootState) => state.collection,
  (collection) => ({
    hasFilters: !isEmpty(collection.filters.collections),
    isValidDefaultCollection: extract(collection.items, 'id').includes(
      collection.defaultCollectionId as string,
    ),
    filteredIds: collection.filters.collections?.map(
      (collection) => collection.id,
    ),
    currentCollection: collection.items.find(
      (item) => item.id === collection.selectedId,
    ),
  }),
);

export const handleSaveCollectionSetting = createAsyncThunk<
  void,
  CollectionSettingParams | undefined,
  { state: RootState }
>('post/saveCollectionSettings', async (payload, thunkAPI) => {
  thunkAPI.dispatch(toggleLoading(true));

  const { isResetDefault, data } = { ...payload };

  const collectionState = thunkAPI.getState().collection;

  let payloadCollection: CollectionSettingPayload = {
    default_collection: null,
    visible_collections: [],
  };

  if (!isResetDefault) {
    // Overwrite state by input data
    if (!isNil(data)) {
      payloadCollection = {
        default_collection: data?.defaultCollection.collectionId,
        visible_collections:
          data?.visibleCollections.map((collection) => collection.value) ?? [],
      };
    } else {
      payloadCollection = {
        default_collection: collectionState.defaultCollectionId,
        visible_collections:
          collectionState.visibleCollection.map(
            (collection) => collection.id,
          ) ?? [],
      };
    }
  } else {
    // Default data from slice
    payloadCollection = {
      default_collection:
        defaultSetting.collectionSetting.defaultCollection.collectionId,
      visible_collections: [],
    };
  }

  try {
    const saveCollectionAsync =
      CollectionService.saveCollectionSettings(payloadCollection);

    customToast.promise(saveCollectionAsync, {
      loading: 'Saving personalized collection settings...',
      success: 'Saved successfully',
      error: 'Something went wrong',
    });

    await saveCollectionAsync;

    thunkAPI.dispatch(fetchCollections());
  } catch (err: any) {
    console.log('error');

    thunkAPI.dispatch(toggleLoading(false));
  }
});

export const fetchCollections = createAsyncThunk<
  FetchCollectionPayload,
  void,
  { state: RootState }
>('get/fetchCollections', async () => {
  try {
    const collection: CollectionResponse = await (
      await CollectionService.getCollections()
    ).data;

    const collectionSetting: CollectionSettingResponse = await (
      await CollectionService.getCollectionSettings()
    ).data;

    const visibleCollectionId = collectionSetting.visible_collections;

    const visibleCollection = collection.collections.filter((item: any) =>
      visibleCollectionId.includes(item.collection_id),
    );

    return {
      collection,
      visible_collection: visibleCollection,
      default_collection: collection.default_collection,
    };
  } catch (err: any) {
    console.log('err :>> ', err);

    return {} as FetchCollectionPayload;
  }
});

export type CollectionStateSlice = {
  showSidebar: boolean;
  showPropertiesDrawer: boolean;
  isLoading: boolean;
  searchTerm: string;
  items: CollectionSidebarItem[];
  visibleCollection: CollectionSidebarItem[];
  defaultCollectionId: string | null;
  selectedId: string | null;
  uploadSelectedId: string | null;
  newCollectionType: CollectionType | null;
  filters: CollectionFilters;
};

export type CollectionFilters = FilterMenuOptions & {
  collections: CollectionSidebarItem[];
};

const collectionSlice = createSlice({
  name: 'collection',
  initialState: {
    showSidebar: false,
    isLoading: false,
    showPropertiesDrawer: false,
    searchTerm: '',
    items: [
      // { id: v4(), type: CollectionType.SMART, name: 'LiSTNR' },
      // { id: v4(), type: CollectionType.SMART, name: 'Sam Smith' },
      // { id: v4(), type: CollectionType.SMART, name: 'Bob Jones' },
      // { id: v4(), type: CollectionType.STANDARD, name: 'Simon Jones' },
      // { id: v4(), type: CollectionType.STANDARD, name: 'Clips' },
      // { id: v4(), type: CollectionType.STANDARD, name: 'PTN Global' },
      // { id: v4(), type: CollectionType.STANDARD, name: 'Archived' },
    ],
    visibleCollection: [],
    defaultCollectionId: null,
    selectedId: null,
    uploadSelectedId: null,
    newCollectionType: null,
    filters: {
      tagIds: [],
      orgIds: [],
      collectionIds: [],

      collections: [],
    },
  } as CollectionStateSlice,
  reducers: {
    setNewVisibleCollection: (
      state,
      action: PayloadAction<CollectionSidebarItem[]>,
    ) => {
      state.visibleCollection = action.payload;
    },
    setVisibleCollection: (state, { payload }: PayloadAction<any>) => {
      state.visibleCollection =
        payload?.collections
          ?.filter((item: CollectionApiResponse) => item?.visible_collections)
          .map(toCollectionItem) ?? [];

      if (payload?.default_collection) {
        state.defaultCollectionId = payload.default_collection;
      }
    },
    toggleCollectionSidebar: (state) => {
      state.showSidebar = !state.showSidebar;
    },
    setShowCollectionSidebar: (state, { payload }: PayloadAction<boolean>) => {
      state.showSidebar = payload;
    },
    setCollectionSearchTerm: (state, { payload }: PayloadAction<string>) => {
      state.searchTerm = payload;
    },
    setSelectedCollectionId: (
      state,
      { payload }: PayloadAction<string | null>,
    ) => {
      state.selectedId = payload;
    },
    setDefaultCollectionId: (state, { payload }: PayloadAction<string>) => {
      state.defaultCollectionId = payload;
    },
    setUploadSelectedCollectionId: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.uploadSelectedId = payload;
    },
    resetUploadSelectedCollectionId: (state) => {
      state.uploadSelectedId = null;
    },
    resetSelectedCollectionId: (state) => {
      state.selectedId = '';
    },
    showAddingInput: (state, { payload }: PayloadAction<CollectionType>) => {
      state.newCollectionType = payload;
    },
    hideAddingInput: (state) => {
      state.newCollectionType = null;
    },
    setCollectionItems: (
      state,
      { payload }: PayloadAction<CollectionSidebarItem[]>,
    ) => {
      state.items = payload;
    },

    addNewCollection: (
      state,
      { payload }: PayloadAction<CollectionSidebarItem>,
    ) => {
      state.items.push(payload);

      if (isEmpty(state.visibleCollection)) return;

      state.visibleCollection.push(payload);
    },
    addToVisibleCollections: (state, { payload }: PayloadAction<string>) => {
      const foundIndex = state.items.findIndex((item) => item.id === payload);

      if (foundIndex < 0) return;

      state.visibleCollection.push(state.items[foundIndex]);
    },
    updateNewAddedCollectionId: (
      state,
      { payload }: PayloadAction<{ oldId: string; newId: string }>,
    ) => {
      const foundIndex = state.items.findIndex(
        (item) => item.id === payload.oldId,
      );

      if (foundIndex > -1 && foundIndex < state.items.length) {
        state.items[foundIndex].id = payload.newId;
      }

      const foundVisibleIndex = state.visibleCollection.findIndex(
        (item) => item.id === payload.oldId,
      );

      if (
        foundVisibleIndex > -1 &&
        foundVisibleIndex < state.visibleCollection.length
      ) {
        state.visibleCollection[foundVisibleIndex].id = payload.newId;
      }
    },
    renameCollectionName: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        newName: string;
      }>,
    ) => {
      const foundIndex = state.items.findIndex(
        (item) => item?.id === payload?.id,
      );
      const foundVisibleIndex = state.visibleCollection.findIndex(
        (item) => item?.id === payload?.id,
      );
      if (foundIndex >= 0) {
        state.items[foundIndex].name = payload.newName;
      }
      if (foundVisibleIndex >= 0) {
        state.visibleCollection[foundVisibleIndex].name = payload.newName;
      }
    },
    deleteCollection: (state, { payload }: PayloadAction<string>) => {
      state.items = state.items.filter((item) => item?.id !== payload);
    },
    toggleLoading: (state, { payload }: PayloadAction<boolean | null>) => {
      if (typeof payload !== 'boolean') {
        state.isLoading = !state.isLoading;
      } else {
        state.isLoading = payload;
      }
    },
    toggleShowPropertiesDrawer: (
      state,
      { payload }: PayloadAction<boolean | null>,
    ) => {
      if (typeof payload !== 'boolean') {
        state.showPropertiesDrawer = !state.showPropertiesDrawer;
      } else {
        state.showPropertiesDrawer = payload;
      }
    },
    setCollectionFilters: (
      state,
      { payload }: PayloadAction<CollectionStateSlice['filters']>,
    ) => {
      state.filters = payload;
    },
    setPartialCollectionFilters: (
      state,
      { payload }: PayloadAction<Partial<CollectionStateSlice['filters']>>,
    ) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    resetCollectionFilters: (state) => {
      state.filters = {
        tagIds: [],
        orgIds: [],
        collectionIds: [],

        collections: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCollections.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchCollections.fulfilled, (state, action) => {
      const data = action.payload;

      if (isNil(data)) return;

      state.visibleCollection = data.visible_collection.map(toCollectionItem);
      state.items = data?.collection.collections.map(toCollectionItem);

      const serverDefaultId = data?.default_collection;

      const hasValidDefault = extract(state.items, 'id').includes(
        serverDefaultId as string,
      );

      console.log('DefaultCollection in visible list:', hasValidDefault);

      const validDefaultId = hasValidDefault ? serverDefaultId : null;

      state.defaultCollectionId = validDefaultId;
      setCollectionIdLocal(validDefaultId);

      state.isLoading = false;
    });

    builder.addCase(fetchCollections.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const {
  setNewVisibleCollection,
  addToVisibleCollections,
  setVisibleCollection,
  toggleCollectionSidebar,
  toggleShowPropertiesDrawer,
  setDefaultCollectionId,
  setShowCollectionSidebar,
  setCollectionSearchTerm,
  setSelectedCollectionId,
  resetSelectedCollectionId,
  showAddingInput,
  hideAddingInput,
  setCollectionItems,
  addNewCollection,
  updateNewAddedCollectionId,
  renameCollectionName,
  deleteCollection,
  setUploadSelectedCollectionId,
  resetUploadSelectedCollectionId,
  toggleLoading,
  setCollectionFilters,
  setPartialCollectionFilters,
  resetCollectionFilters,
} = collectionSlice.actions;

export const collectionReducer = collectionSlice.reducer;
