/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import ItemRevokedImg from 'assets/Icons/system-down.png';

type Props = {
  errorMessage: string;
  hasTranscript: boolean;
};

export const EmbedError = ({ errorMessage, hasTranscript }: Props) => {
  return (
    <div tw="h-screen w-screen flex justify-center py-6 px-5 bg-gray-50">
      <div
        tw="h-full flex flex-col gap-y-3 justify-center"
        css={[hasTranscript && tw`gap-y-8`]}
      >
        <img
          tw="h-[80%] max-h-[30rem] object-contain"
          src={ItemRevokedImg}
          alt="Item has been revoked sharing"
        />
        <div
          tw="font-medium text-14 text-center text-sonnant-red"
          css={[hasTranscript && tw`text-18`]}
        >
          {errorMessage}
        </div>
      </div>
    </div>
  );
};
