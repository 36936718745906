/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { first, isEmpty, isEqual, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { clearSelectedClips } from 'slices/clips.slice';
import { IClips, ILibraryItem, IPublishVersion } from 'utils/models';
import { ThumbnailClipsItem } from './ThumbnailClipsItem';
import './thumbnailView.scss';

interface IProps {
  data: ILibraryItem;
  versionData: IClips[];
  suggestedClips: IClips[];
  trendingClips: IClips[];
  handleShareContentItem?: () => void;
  handleExportContentItem?: () => void;
  handlePublishContentItem?: () => void;
  handleShowPreviewPublish?: (startTime: number, endTime: number) => unknown;
  handleShowPreview?: (item: IClips) => unknown;
  handleDeleteVersionMedias: (singleItem: IClips) => void;
  setShareViewLoading: (isLoading: boolean) => unknown;
  handleRedirectClick: (item: any) => void;
  onRegenerateSuggested: () => void;
  handleIntegrate?: () => void;
}

export const ClipThumbnailView = (props: IProps) => {
  const publishedVersion: IPublishVersion = useSelector(
    (state: RootState) => state.getPublishedVersion.data,
  );

  const libraryItem = props.data;
  const [versionItem, setVersionItem] = useState(props.versionData);

  const [originalClip, setOriginalClip] = useState<IClips | null | undefined>(
    null,
  );

  const clips = useSelector((state: RootState) => state.clips);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isNil(props?.versionData)) return;
    const versions = props.versionData;

    dispatch(clearSelectedClips());

    const originalIndex = versions.findIndex((v) =>
      isEqual(v?.versioncount, '0'),
    );

    const originalArray = [...versions].splice(originalIndex, 1);
    setOriginalClip(first(originalArray));
    setVersionItem(versions);
  }, [props.versionData]);

  useEffect(() => {
    if (clips.isSelectMode) return;

    dispatch(clearSelectedClips());
  }, [clips.isSelectMode]);

  const excludeOriginal = (fullVersions: IClips[]) => {
    return (
      fullVersions?.filter((version) => !isEqual(version?.versioncount, '0')) ??
      []
    );
  };

  return (
    <div className="grid-scroll-container" tw="z-index[0]">
      <div className="grid-container fluid custom_grid_container">
        <div tw="ml-auto xs:(relative z-index[1]) sm-up:width[74%] 2xl-up:width[83%]! flex justify-between text-sonnant-grey-5 font-size[1.6rem] font-medium mt-3 -mb-2!">
          <div>My Clips</div>
          <div>
            <button
              className="button btn-primary large"
              tw="(w-auto mt-0 p-2 px-4)!"
              onClick={props.handlePublishContentItem}
              disabled={clips.selectedClips.length > 1}
            >
              Create clip
            </button>
          </div>
        </div>
        <div className="grid-x thumbNails_block" tw="mt-0">
          {!isEmpty(originalClip) && (
            <ThumbnailClipsItem
              isOriginal
              item={{
                ...originalClip!,
                versionname: clips?.title,
              }}
              libraryItem={libraryItem}
              handleRedirectClick={props.handleRedirectClick}
              handleShare={props.handleShareContentItem}
              handleExport={props.handleExportContentItem}
              handlePublish={props.handlePublishContentItem}
              handleShowPreviewPublish={props.handleShowPreviewPublish}
              handleShowPreview={props?.handleShowPreview}
              handleIntegrate={props?.handleIntegrate}
            />
          )}

          {excludeOriginal(versionItem).map((item, index) => {
            return (
              <ThumbnailClipsItem
                key={item.versioncount}
                item={item}
                libraryItem={libraryItem}
                handleRedirectClick={props.handleRedirectClick}
                handleShare={props.handleShareContentItem}
                handleExport={props.handleExportContentItem}
                handlePublish={props.handlePublishContentItem}
                handleShowPreviewPublish={props.handleShowPreviewPublish}
                handleDeleteVersionMedias={props.handleDeleteVersionMedias}
                handleShowPreview={props.handleShowPreview}
              />
            );
          })}
        </div>
        <div
          tw="w-full p-0!"
          className="grid-container fluid custom_grid_container"
        >
          <div tw="flex justify-between border-top[2px solid] border-sonnant-grey-5 text-sonnant-grey-5 font-size[1.6rem] font-medium pt-3!">
            <div>Suggested Clips</div>
            <div>
              <button
                className="button btn-primary large"
                tw="-mb-4 height[3.5rem]"
                onClick={props.onRegenerateSuggested}
              >
                Regenerate
              </button>
            </div>
          </div>
          <div className="grid-x thumbNails_block" tw="flex mt-4">
            {props?.suggestedClips?.map((item: IClips, index: number) => (
              <ThumbnailClipsItem
                key={index}
                item={item as any}
                isSuggested={true}
                libraryItem={libraryItem}
                handleRedirectClick={props.handleRedirectClick}
                handleShare={props.handleShareContentItem}
                handleExport={props.handleExportContentItem}
                handlePublish={props.handlePublishContentItem}
                handleShowPreviewPublish={props.handleShowPreviewPublish}
                handleDeleteVersionMedias={props.handleDeleteVersionMedias}
                handleShowPreview={props.handleShowPreview}
              />
            ))}
            {isEmpty(props.suggestedClips) && (
              <div tw="flex items-center ml-6">
                <div tw="text-sonnant-grey-6 text-2xl font-medium my-6">
                  {publishedVersion?.snippetCompleted
                    ? `No suggested clips could be found for this content due to limited text or short length.`
                    : `Suggested clips are being created (they may take up to 10 minutes after uploading).`}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* TRENDING */}
        <div
          tw="w-full p-0!"
          className="grid-container fluid custom_grid_container"
        >
          <div tw="border-top[2px solid] border-sonnant-grey-5 text-sonnant-grey-5 font-size[1.6rem] font-medium pt-3!">
            Trending Clips
          </div>
          <div className="grid-x thumbNails_block" tw="flex mt-4">
            {props.trendingClips?.map((item: IClips, index: number) => (
              <ThumbnailClipsItem
                key={index}
                item={item as any}
                isSuggested={true}
                libraryItem={libraryItem}
                handleRedirectClick={props.handleRedirectClick}
                handleShare={props.handleShareContentItem}
                handleExport={props.handleExportContentItem}
                handlePublish={props.handlePublishContentItem}
                handleShowPreviewPublish={props.handleShowPreviewPublish}
                handleDeleteVersionMedias={props.handleDeleteVersionMedias}
                handleShowPreview={props.handleShowPreview}
                isTrending
              />
            ))}
            {isEmpty(props.trendingClips) && (
              <div tw="flex items-center ml-6">
                <div tw="text-sonnant-grey-6 text-2xl font-medium my-6">
                  No trending clips matched.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
