/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { scrollbarWidthCss } from 'components/shared/twin.styles';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { match } from 'ts-pattern';
import { Layers } from 'utils/enum';
import { EmbedCaptionViewer } from './EmbedCaptionViewer';
import { EmbedChapterViewer } from './EmbedChapterViewer';
import { EmbedIabViewer } from './EmbedIabViewer';
import { EmbedLayer } from './EmbedLayer';
import { EmbedMetaViewer } from './EmbedMetaViewer';
import { EmbedSummaryViewer } from './EmbedSummaryViewer';

type Props = {};

export const EmbedTranscript = (props: Props) => {
  const layers = useSelector((state: RootState) => state.layers);

  return (
    <div tw="h-full flex flex-col gap-y-3 px-5 py-3">
      <EmbedLayer />

      <div
        tw="text-sonnant-dark opacity-95 overflow-y-auto"
        css={[scrollbarWidthCss(4)]}
      >
        {match(layers.currentLayer)
          .with(Layers.TRANSCRIPT, () => <EmbedCaptionViewer />)
          .with(Layers.CHAPTER, () => <EmbedChapterViewer />)
          .with(Layers.IAB, () => <EmbedIabViewer />)
          .with(Layers.SUMMARY, () => <EmbedSummaryViewer />)
          .otherwise(() => (
            <EmbedMetaViewer />
          ))}
      </div>
    </div>
  );
};
