import { isNil, isEmpty } from 'lodash';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IClips } from 'utils/models';
import { ViewMode } from 'utils/enum';
import { MediaService } from 'services';
import { customToast } from 'utils/toast.util';

export const putRenamePublishTitle = createAsyncThunk(
  'put/renametitlepublish',
  async (request: {
    mediaid: string;
    versioncount: string;
    newTitle: string;
  }) => {
    const renamingRequest = MediaService.renameTitlePublic({
      mediaid: request.mediaid,
      versioncount: request.versioncount,
      versionname: request.newTitle,
    });
    customToast.promise(renamingRequest, {
      loading: 'Updating...',
      success: 'Title updated',
      error: 'Title update failed',
    });
    const response = await renamingRequest;
    return response.data;
  },
);

const clipsSlice = createSlice({
  name: 'clip',
  initialState: {
    isSelectMode: false,
    selectedClips: [] as IClips[],
    focusClip: null as IClips | null,
    currentView: ViewMode.THUMBNAIL as ViewMode,
    mediaid: '' as string,
    title: '',
  },
  reducers: {
    toggleSelectMode: (
      state,
      action?: PayloadAction<boolean | null | undefined>,
    ) => {
      if (isNil(action?.payload)) {
        state.isSelectMode = !state.isSelectMode;
      } else {
        state.isSelectMode = action!.payload;
      }
    },
    setSelectedClips: (state, action: PayloadAction<IClips[]>) => {
      console.log('action.payload', action.payload)
      state.selectedClips = action.payload;
      if (!isEmpty(action.payload)) {
        state.isSelectMode = true;
      } else {
        state.isSelectMode = false;
      }

    },
    clearSelectedClips: (state) => {
      state.selectedClips = [];
    },
    setCurrentView: (state, action: PayloadAction<ViewMode>) => {
      state.currentView = action.payload;
    },
    setParentMediaid: (state, action: PayloadAction<string>) => {
      state.mediaid = action.payload;
    },
    setFocusClip: (state, action: PayloadAction<IClips | null>) => {
      state.focusClip = action.payload;
    },
    setClipTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
  },
});

export const {
  toggleSelectMode,
  setSelectedClips,
  clearSelectedClips,
  setCurrentView,
  setParentMediaid,
  setFocusClip,
  setClipTitle,
} = clipsSlice.actions;

export const clipsReducer = clipsSlice.reducer;
