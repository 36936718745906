/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { SimpleMenuSurface } from '@rmwc/menu';
import { Ripple } from '@rmwc/ripple';
import React, { useRef, useState } from 'react';
import { useClickAway, useToggle } from 'react-use';
import { Flatten } from 'types/utils';
import { isEmpty } from 'lodash';

type Props = {
  playbackSpeed: number | undefined;
  onChangePlaybackRate: (playbackRate: number) => void;
};

const SPEED_ITEMS = [
  { value: 2, label: '2x' },
  { value: 1.75, label: '1.75x' },
  { value: 1.5, label: '1.5x' },
  { value: 1.25, label: '1.25x' },
  { value: 1, label: '1x' },
  { value: 0.75, label: '0.75x' },
  { value: 0.5, label: '0.5x' },
];

type SpeedRate = Flatten<typeof SPEED_ITEMS>;

export const EmbedMediaSpeedMenu = ({ ...props }: Props) => {
  const speedMenuRef = useRef<HTMLDivElement>(null);

  const [speedRate, setSpeedRate] = useState<SpeedRate>(
    SPEED_ITEMS.find((item) => item.value === 1) as SpeedRate,
  );

  useClickAway(speedMenuRef, () => {
    if (!show) return;

    toggleShow(false);
  });

  const [show, toggleShow] = useToggle(false);

  const handleClickShow = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    toggleShow();
  };

  const handleSelectOption = async (speedItem: SpeedRate) => {
    if (isEmpty(speedItem)) return;

    setSpeedRate(speedItem);

    props.onChangePlaybackRate(speedItem.value);

    toggleShow(false);
  };

  return (
    <div ref={speedMenuRef} css={[wrapperCss]}>
      <SimpleMenuSurface
        open={show}
        fixed
        handle={
          <Ripple onClick={handleClickShow}>
            <div tw="flex rounded-lg px-3 border[2px solid] border-sonnant-grey-5 h-[2.4rem] cursor-pointer select-none">
              <span tw="flex font-medium items-center line-height[1.2]">
                {speedRate.label}
              </span>
            </div>
          </Ripple>
        }
        anchorCorner="bottomStart"
      >
        <div>
          {SPEED_ITEMS.map((speedItem, index) => (
            <div key={index}>
              <Ripple
                onClick={() => handleSelectOption(speedItem)}
                tw="px-3 text-12 font-medium text-center cursor-pointer select-none text-sonnant-grey-6 opacity-95"
              >
                <div>{speedItem.label}</div>
              </Ripple>
            </div>
          ))}
        </div>
      </SimpleMenuSurface>
    </div>
  );
};

const wrapperCss = css`
  ${tw`min-w-[5.4rem] flex justify-center`}

  .mdc-menu-surface--anchor {
    ${tw`flex`}
  }
`;
