import { BaseAPI } from 'apis/amazon';
import {
  IntegrationOptions,
  MegaphoneIntegrationOption,
  PreferenceResponse,
} from 'utils/models';
import {
  IBalanceInfo,
  IGlobalInfo,
  UserList,
} from 'utils/models/payment.model';

export type PostCreateUserParams = {
  email: string;
  firstname: string;
  lastname: string;
  role: string;
  subdomain: string;
};

export type PutEditUserParams = {
  email: string;
  firstname: string;
  lastname: string;
  role: string;
  subdomain: string;
};

export type PutDeleteUserParams = {
  email: string;
};

export type PutUpdateUserDetailParams = {
  firstname: string;
  lastname: string;
  location: string;
};

export type PutUpdateTenantDetailParams = {
  companyname: string;
  description: string;
  extension: string;
  image: string;
};

export type PutAccountStatusParams = {
  email: string;
  newStatus: string;
};

export type GetUsersParams = {
  loading: boolean;
  error: boolean;
  data: {};
};

type UserQueryParams = {
  user?: boolean;
  tenant?: boolean;
  preferences?: boolean;
  balance?: boolean;
  subscription?: boolean;
  notification?: boolean;
  'user-group'?: boolean;
  all?: boolean;
};

type SavePreferenceParams = {
  tenantid?: string;
  setting_alias_terms?: string;
  customterms?: string;
  watch_lists?: string;
  monetisation?: string;
  emailsettings?: string;
  settingsjson?: string;
  speakers?: string;
  setting_ignore_terms?: string;
  omny_integrations?: IntegrationOptions[];
  megaphone_integrations?: MegaphoneIntegrationOption[];
};

export const UserService = {
  // GET /subscription
  getSubscription: async () => {
    return BaseAPI.get<IBalanceInfo>('/user/subscription');
  },
  // GET /user
  getUser: (params?: UserQueryParams) => {
    return BaseAPI.get<Partial<IGlobalInfo>>('/user', { params });
  },

  // POST /user
  createUser: (body: PostCreateUserParams) => {
    return BaseAPI.post<string>('/user', body);
  },

  // PUT /user
  editUser: (body: PutEditUserParams) => {
    return BaseAPI.put<string>('/user', body);
  },

  // PUT /user/delete
  deleteUser: (body: PutDeleteUserParams) => {
    return BaseAPI.put<string>('/user/delete', body);
  },

  // PUT /user/details
  updateUserDetails: (body: PutUpdateUserDetailParams) => {
    return BaseAPI.put<string>('/user/details', body);
  },

  // PUT /user/tenant-details
  updateTenantDetails: (body: PutUpdateTenantDetailParams) => {
    return BaseAPI.put<string>('/user/tenant-details', body);
  },

  // PUT /user/status
  updateAccountStatus: (params: PutAccountStatusParams) => {
    return BaseAPI.put<string>('/user/status', params);
  },

  // GET /user/users
  getUsers: () => {
    return BaseAPI.get<UserList>('/user/users');
  },

  // POST /user/preference
  savePreference: (body: SavePreferenceParams) => {
    return BaseAPI.post<PreferenceResponse>('/user/preference', body);
  },
};
