/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as TagSvg } from 'assets/Icons/folder-tags.svg';
import { ReactComponent as AddTermSvg } from 'assets/Icons/library_add_black_24dp.svg';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { Info } from 'components/shared/Info';
import { SearchInput } from 'components/shared/SearchInput';
import {
  SingleNameCreationModal,
  SingleNameCreationModalRef,
} from 'components/shared/SingleNameCreationModal/SingleNameCreationModal';
import { first, isEmpty, isNil, orderBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle, useUnmount } from 'react-use';
import { RootState } from 'reducers';
import { TagResponse, TagService } from 'services/tag.service';
import { userSelector } from 'slices/payment.slice';
import {
  appendNewTag,
  deleteTag,
  fetchAllTags,
  setSelectedTagId,
} from 'slices/tag.slice';
import { ensureValidArchiveTag } from 'utils/adapter.util';
import { ModalOptions } from 'utils/enum';
import { BasicConfirmModal } from 'utils/models/modal.model';
import { customToast } from 'utils/toast.util';
import { TagDetailPane } from './TagDetailPane';
import { TagListItem } from './TagListItem';
import { isArchiveTag } from 'components/VideoPlayer/Transcription/MediaUtilities';

type Props = {};

export const TagSettings = ({ ...props }: Props) => {
  const [isLoading, toggleLoading] = useToggle(false);

  const dispatch = useDispatch();

  const user = useSelector(userSelector);

  const singleNameCreationModal = useRef<SingleNameCreationModalRef>(null);
  const confirmModalRef = useRef<BasicConfirmModal>(null);
  const confirmArchiveTagCreationModalRef = useRef<BasicConfirmModal>(null);

  const tag = useSelector((state: RootState) => state.tags);
  const activeTags = orderBy(
    tag.activeTags,
    [({ tagName }) => isArchiveTag(tagName), 'createdAt'],
    ['desc', 'asc'],
  );

  const selectedTag = tag.activeTags.find(
    ({ tagId }) => tagId === tag.selectedTagId,
  );

  const [shouldReloadTags, setShouldReloadTags] = useToggle(false);

  const [searchText, setSearchText] = useState<string>('');

  const searchedTags: TagResponse[] =
    activeTags.filter(({ tagName }) =>
      tagName?.toLowerCase().includes(searchText?.trim().toLowerCase()),
    ) ?? [];

  const isEmptyTagList = isEmpty(activeTags) && !searchText;
  const isEmptySearchResult = isEmpty(searchedTags) && searchText;

  useUnmount(() => {
    if (shouldReloadTags) {
      dispatch(fetchAllTags());
    }
  });

  useEffect(() => {
    if (isEmpty(activeTags)) return;

    // Set default selected first tag
    if (isNil(selectedTag)) {
      dispatch(setSelectedTagId(first(activeTags)?.tagId as string));
    }
  }, [activeTags]);

  const handleSelectTagId = (tagId: string) => {
    dispatch(setSelectedTagId(tagId));
  };

  const handleClickAddTag = async () => {
    const result = await singleNameCreationModal.current?.show();

    if (result?.option !== ModalOptions.YES) return;

    const tagName = result?.singleName?.trim() ?? '';

    const existingTag = activeTags.filter(
      (tag) => tag.tagName.toLowerCase() === tagName.toLowerCase(),
    );

    if (!isEmpty(existingTag)) {
      customToast.warning(
        <div>
          <strong>{first(existingTag)?.tagName}</strong> is already existing.
          <div>Please check and try again!</div>
        </div>,
      );

      return;
    }

    const ensuredTagName = ensureValidArchiveTag(tagName);

    if (isArchiveTag(ensuredTagName)) {
      const result = await confirmArchiveTagCreationModalRef.current?.show();

      if (result !== ModalOptions.YES) return;
    }

    addNewTagAsync(ensuredTagName);
  };

  const addNewTagAsync = async (newTagName: string) => {
    const newTagAsync = TagService.saveNewTag({ tagName: newTagName });

    customToast.promise(newTagAsync, {
      loading: (
        <span>
          Creating <strong>{newTagName}</strong>
        </span>
      ),
      success: (
        <span>
          <strong>{newTagName}</strong> created!
        </span>
      ),
      error: 'Creation failed or existed tag. Please check and try again.',
    });

    try {
      toggleLoading(true);

      const { data: newTagId } = await newTagAsync;

      const newTag: TagResponse = {
        tagId: newTagId,
        tagName: newTagName,
        createdAt: new Date(),
        modifiedAt: new Date(),
        collectionIds: [],
      };

      dispatch(appendNewTag(newTag));
      dispatch(fetchAllTags());
    } catch (error) {
      console.log('error :>> ', error);
    } finally {
      toggleLoading(false);
    }
  };

  const handleDeleteList = async (item: TagResponse) => {
    const result = await confirmModalRef.current?.show();

    if (result !== ModalOptions.YES) return;

    const deleteTagAsync = TagService.deleteTag({ tagId: item.tagId });

    customToast.promise(deleteTagAsync, {
      loading: 'Deleting tag...',
      success: 'Deleted tag successfully',
      error: 'Something went wrong',
    });

    try {
      dispatch(deleteTag(item));

      await deleteTagAsync;
      dispatch(fetchAllTags());
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  // Restricted to Admin only
  if (!user.isAdministrator) return null;

  return (
    <div>
      <hr tw="max-width[unset] ml-[1.6rem] mr-[0.8rem]" />

      <div tw="pl-10 mb-20">
        <div tw="flex">
          <div tw="flex-1">
            <div tw="flex w-full justify-between items-center mt-3 mb-6 text-sonnant-dark md-down:(max-width[40rem])">
              <div tw="font-extrabold text-18 flex items-center space-x-4 text-sonnant-dark">
                <span tw="flex">
                  <TagSvg tw="relative top[-1px]" />
                </span>
                <div>Tags</div>
              </div>

              <div tw="flex gap-x-5 w-full mr-3 justify-end">
                <SearchInput
                  setTerm={setSearchText}
                  placeholder="Search Tags"
                />

                <div>
                  <button
                    className="button large btn-primary"
                    tw="max-width[unset]! width[auto]! flex items-center px-5! whitespace-nowrap mb-0"
                    onClick={handleClickAddTag}
                    disabled={isLoading}
                  >
                    <AddTermSvg tw="mr-3" /> Add Tag
                  </button>
                </div>
              </div>
            </div>

            <div>
              <div tw="flex w-full justify-between uppercase font-black font-size[12px]">
                <div tw="flex">
                  <div tw="mr-6">#</div>
                  <div>Custom tag</div>
                </div>
                <div tw="flex mr-6 text-sonnant-dark">
                  <div tw="mr-[1.6rem]">EDIT</div>
                  <div>DEL</div>
                </div>
              </div>
              <DragDropContext onDragEnd={() => {}}>
                <Droppable droppableId="tagDnd">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {searchedTags.map((activeTag, index) => (
                        <TagListItem
                          key={activeTag.tagId}
                          index={index}
                          tagItem={activeTag}
                          onDeleteList={handleDeleteList}
                          onClick={() => handleSelectTagId(activeTag.tagId)}
                          isArchiveTag={isArchiveTag(activeTag.tagName)}
                        />
                      ))}

                      {isEmptyTagList && (
                        <div tw="mt-5">
                          <Info text="There are no tags created yet." />
                        </div>
                      )}
                      {isEmptySearchResult && <Info text="No results found" />}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>

          <div tw="border-left[2px solid] border-sonnant-purple-1 mx-8 rounded"></div>

          {selectedTag && (
            <div tw="flex-1">
              <TagDetailPane
                title={selectedTag.tagName}
                setShouldReloadTags={setShouldReloadTags}
                isArchived={isArchiveTag(selectedTag.tagName)}
              />
            </div>
          )}
        </div>

        <SingleNameCreationModal
          ref={singleNameCreationModal}
          title="Create new Custom tag"
          label="Custom tag"
          placeHolder="Please enter your new tag..."
          confirmText="Create tag"
        />

        <ConfirmModal
          ref={confirmModalRef}
          title="Delete confirm"
          message="Deleting this list will delete every instance and
        application of this list including published and shared
        items, do you wish to proceed?"
        />

        <ConfirmModal
          ref={confirmArchiveTagCreationModalRef}
          title="Create Archive tag"
          message={
            <div>
              You're about to create an Archive tag that will be uploaded as
              archived items into Sonnant library.
              <br />
              <strong>Would you like to proceed?</strong>
            </div>
          }
        />
      </div>
    </div>
  );
};
