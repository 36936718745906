import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import { resetFinder, resetPlayer } from './player.slice';

const toggleSlice = createSlice({
  name: 'toggle',
  initialState: {
    isEditMode: false as boolean,
    isEditTimeCodeMode: false as boolean,
    isFollowing: true as boolean,
    isShowHumanEdit: false as boolean,
    isShowFinder: false as boolean,
    isShowReplaceModal: false as boolean,
    isMinimise: false as boolean,
    isEditCaption: false as boolean,
    isShowWarning: false as boolean,
    isShowExportModal: false as boolean,
    isReloadLibrary: false as boolean,
    isOpenAddEditModal: false as boolean,
    isLastManualSave: false as boolean,
    isApplyToAllSpeaker: true as boolean,

    isShowDeleteModal: false as boolean,
    isShowPublishModal: false as boolean,
    isShowShareModal: false as boolean,
    isShowIntegrateModal: false as boolean,
    isShowPreviewModal: false as boolean,
    isShowUploadModal: false as boolean,
    isShowMentionReportModal: false as boolean,
    isShowRerunModal: false as boolean,
  },
  reducers: {
    toggleShowRerunModal: (state, action: PayloadAction<boolean>) => {
      state.isShowRerunModal = action.payload;
    },
    toggleShowPreviewModal: (state, action: PayloadAction<boolean>) => {
      state.isShowPreviewModal = action.payload;
    },
    toggleShowIntegrateModal: (state, action: PayloadAction<boolean>) => {
      state.isShowIntegrateModal = action.payload;
    },
    toggleShowShareModal: (state, action: PayloadAction<boolean>) => {
      state.isShowShareModal = action.payload;
    },
    toggleShowPublishModal: (state, action: PayloadAction<boolean>) => {
      state.isShowPublishModal = action.payload;
    },
    toggleShowMentionReportModal: (state, action: PayloadAction<boolean>) => {
      state.isShowMentionReportModal = action.payload;
    },
    toggleShowDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.isShowDeleteModal = action.payload;
    },
    toggleApplyToAllSpeaker: (state, action: PayloadAction<boolean>) => {
      state.isApplyToAllSpeaker = action.payload;
    },
    toggleLastManualSave: (state, action: PayloadAction<boolean>) => {
      state.isLastManualSave = action.payload;
    },
    toggleAddEditModal: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenAddEditModal = payload;
    },
    toggleReloadLibrary: (state) => {
      state.isReloadLibrary = !state.isReloadLibrary;
    },
    toggleShowExportModal: (state, action: PayloadAction<boolean>) => {
      state.isShowExportModal = action.payload;
    },
    toggleWarning: (state, action) => {
      state.isShowWarning = action.payload;
    },
    toggleEditCaption: (state, action) => {
      state.isEditCaption = action.payload;
    },
    toggleEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
    toggleEditTimecodeMode: (state, action) => {
      state.isEditTimeCodeMode = action.payload;
    },
    toggleFollowing: (state, action?: PayloadAction<boolean | undefined>) => {
      if (typeof action?.payload !== 'boolean') {
        state.isFollowing = !state.isFollowing;
      } else {
        state.isFollowing = action?.payload;
      }
    },
    toggleHumanEdit: (state, action) => {
      state.isShowHumanEdit = action.payload;
    },
    toggleFinder: (state, { payload }: PayloadAction<boolean | null>) => {
      if (isNil(payload)) {
        state.isShowFinder = !state.isShowFinder;
      } else {
        state.isShowFinder = payload;
      }
    },

    toggleFinderModal: (
      state,
      { payload }: PayloadAction<boolean | undefined>,
    ) => {
      if (isNil(payload)) {
        state.isShowReplaceModal = !state.isShowReplaceModal;
      } else {
        state.isShowReplaceModal = payload;
      }
    },
    toggleUploadModal: (state, action) => {
      if (isNil(action) || isNil(action.payload)) {
        state.isShowUploadModal = !state.isShowUploadModal;
      } else {
        state.isShowUploadModal = action.payload;
      }
    },
    toggleMinimise: (state, { payload }: PayloadAction<boolean>) => {
      state.isMinimise = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPlayer, (state) => {
        state.isShowHumanEdit = false;
        state.isFollowing = true;
        state.isShowFinder = false;
        state.isShowReplaceModal = false;
        state.isMinimise = false;
      })
      .addCase(resetFinder, (state) => {
        state.isShowFinder = false;
        state.isShowReplaceModal = false;
      });
  },
});

export const {
  toggleShowPreviewModal,
  toggleShowIntegrateModal,
  toggleShowShareModal,
  toggleShowPublishModal,
  toggleShowMentionReportModal,
  toggleShowDeleteModal,
  toggleApplyToAllSpeaker,
  toggleReloadLibrary,
  toggleWarning,
  toggleEditCaption,
  toggleEditMode,
  toggleEditTimecodeMode,
  toggleFollowing,
  toggleFinder,
  toggleFinderModal,
  toggleHumanEdit,
  toggleMinimise,
  toggleShowExportModal,
  toggleAddEditModal,
  toggleLastManualSave,
  toggleUploadModal,
  toggleShowRerunModal,
} = toggleSlice.actions;

export const toggleReducer = toggleSlice.reducer;
