import { BaseAPI } from 'apis/amazon';
import { AxiosResponse } from 'axios';

export type SaveTranscriptParams = {
  media_id: string;
  auto_save?: boolean;
  is_speaker_changed?: boolean;
};

export type SearchTranscriptPayload = {
    mediaid: string;
    terms: string[];
}

export type SaveTranscriptResponse = {
    url: string;
}

export const TranscriptService = {
  // GET /transcript/save
  saveTranscript: (
    params: SaveTranscriptParams,
  ): Promise<AxiosResponse<SaveTranscriptResponse>> => {
    return BaseAPI.get('/transcription', { params });
  },

  // POST /transcript/save
  generateSubtitle: (
    params: SaveTranscriptParams,
    key: string,

  ): Promise<AxiosResponse<any>> => {
    return BaseAPI.post('/transcription', { key }, { params });
  },

  // POST /transcript/applied-list/search
  searchTranscript: (payload: SearchTranscriptPayload): Promise<AxiosResponse<any>> => {
    return BaseAPI.post('/transcription/applied-list-mentions/search', payload);
  },
};
