/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

export const sectionTitle = css`
  ${tw`font-semibold uppercase`}
`;

export const headerTitle = css`
  ${tw`font-semibold`}
`;

export const tableStyle = css`
  th,
  td {
    ${tw`text-center font-size[1.7rem] text-sonnant-dark pt-2`}

    &:not(:last-child) {
      ${tw`border-right[1px solid] border-sonnant-grey-4`}
    }
  }

  th {
    ${tw`font-size[1.9rem] py-5 bg-sonnant-grey-1`}
  }

  tr td:first-of-type {
    ${tw`whitespace-nowrap text-left pr-4 pl-2`}
  }

  tr:hover {
    ${tw`bg-sonnant-grey-1`}
  }
`;

export const descriptionStyle = css`
  ${tw`(text-sonnant-grey-5 font-size[1.7rem] vertical-align[text-top] py-6 px-3)!`}
`;

export const emptyRow = css`
  ${tw`h-10`}
`;

export const notHover = css`
  ${tw`background[unset]!`}
`;

export const iconStyle = css`
  path {
    fill: white !important;
  }
`;