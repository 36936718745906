import { IMediaDetail, Waveform } from './../utils/models/media.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNil } from 'lodash';

const mediaSlice = createSlice({
  name: 'media',
  initialState: {} as IMediaDetail,
  reducers: {
    setMediaItem: (state, action) => {
      state = action.payload;
      return state;
    },
    resetMedia: (state) => {
      state = {} as IMediaDetail | any;
      return state;
    },
    setVideoRatio: (state, action: PayloadAction<number>) => {
      if (!state?.metadata) return;

      state.metadata.ratio = action.payload;
    },
    setMediaTitle: (state, action: PayloadAction<string>) => {
      state.metadata.title = action.payload;
    },
    setWaveform: (state, { payload }: PayloadAction<Waveform | undefined>) => {
      if (!payload) return;

      state.waveform = payload as any;
    },
    setNewMediaVtt: (state, { payload }: PayloadAction<string>) => {
      if (!payload) return;

      state.subtitleurl = payload;
    },
    clearMediaUrl: (state) => {
      if (state?.url) {
        state.url = '';
      }
    },
    setMediaLength: (state, { payload }: PayloadAction<number>) => {
      if (isNil(payload)) return;

      state.itemLengthMs = payload;
    }
  },
});

export const {
  setMediaItem,
  resetMedia,
  setVideoRatio,
  setMediaTitle,
  setWaveform,
  setNewMediaVtt,
  clearMediaUrl,
  setMediaLength
} = mediaSlice.actions;

export const mediaReducer = mediaSlice.reducer;
