/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Hint } from '../Hint';
import { ReactComponent as AddIconSvg } from 'assets/Icons/library_add_black_24dp.svg';
import { SimpleMenuSurface } from '@rmwc/menu';
import { Ripple } from '@rmwc/ripple';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useClickAway, useToggle } from 'react-use';
import { useRef } from 'react';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { CustomTerm, LayerCustomTerm, Term } from 'utils/models';
import { ModalOptions } from 'utils/enum';
import {
  cloneNameRecursive,
  createListKeyword,
  getCaptionsSlate,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { appendLayerCustomerTerm } from 'slices/player.slice';
import { v4 } from 'uuid';
import { isEmpty } from 'lodash';

interface Props {
  disabled?: boolean;
  onSelectItem?: (item: CustomTerm) => void;
}

export const CreateFromCustomVocabButton = (props: Props) => {
  const dispatch = useDispatch();

  const global = useSelector((state: RootState) => state.global);
  const player = useSelector((state: RootState) => state.player);

  const [show, toggleShow] = useToggle(false);

  const dropdownRef = useRef(null);
  const confirmModalRef = useRef<any>(null);

  useClickAway(dropdownRef, () => {
    if (!show) return;

    toggleShow(false);
  });

  const handleClickDropdownItem = async (vocab: CustomTerm) => {
    toggleShow(false);
    if (!vocab) return;

    // CUSTOM CALLBACK FUNCTION, SKIP LOGIC DOWN HERE
    if (typeof props?.onSelectItem === 'function') {
      props.onSelectItem(vocab);
      return;
    }

    const result = await confirmModalRef.current?.show();

    // Clone from Custom Vocab List
    if (result === ModalOptions.YES) {
      const newAppliedList: LayerCustomTerm = {
        listId: v4(), // should be separated from custom vocab
        active: true,
        listName: cloneNameRecursive(
          player.layerCustomTerms?.map(({ listName }) => listName),
          vocab?.name,
        ),
        matched: createListKeyword(
          vocab?.terms?.map((term: Term) => term.name) ?? [],
          getCaptionsSlate(player),
        ).filter((k) => !isEmpty(k?.mentions?.[0]?.occurrences)),
      };

      dispatch(appendLayerCustomerTerm(newAppliedList));
    }
  };

  return (
    <div ref={dropdownRef}>
      <SimpleMenuSurface
        open={show}
        anchorCorner="bottomStart"
        tw="min-width[100%] py-1"
        handle={
          <Hint
            disabled={global?.customTerms?.length > 0}
            enterDelay={100}
            leaveDelay={100}
            text="There are no existing Custom Vocab lists"
          >
            <div>
              <button
                className="button large btn-primary"
                tw="max-width[unset]! width[auto]! flex items-center px-5! whitespace-nowrap mb-0"
                onClick={toggleShow}
                disabled={props?.disabled}
              >
                <AddIconSvg tw="mr-3" /> Add from Custom Vocab
              </button>
            </div>
          </Hint>
        }
      >
        <div>
          {global.customTerms.map((vocab, index) => (
            <Ripple
              key={vocab.id}
              onClick={() => handleClickDropdownItem(vocab)}
            >
              <div tw="cursor-pointer px-3 py-1.5 text-sonnant-dark font-medium">
                <Hint
                  text={`Total items: ${vocab.terms?.length}`}
                  enterDelay={200}
                  leaveDelay={50}
                >
                  <span tw="block w-full">
                    {index + 1}. {vocab.name}
                  </span>
                </Hint>
              </div>
            </Ripple>
          ))}
        </div>
      </SimpleMenuSurface>

      <ConfirmModal
        ref={confirmModalRef}
        title="Add from Custom Vocab"
        message={
          <div>
            <p>
              You are about to create a new Applied List. Any terms that have an
              occurrence in the transcript will be copied over (including
              alternatives) into the new Applied List.
            </p>
            <b>Would you like to continue?</b>
          </div>
        }
      />
    </div>
  );
};
