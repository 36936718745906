import { BaseAPI } from 'apis/amazon';
import { isLocalMode } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { isNil } from 'lodash';
import {
  AvailableUser,
  UserGroupByIdResponse,
  UserGroupPermission,
  UserGroupResponse,
} from 'utils/models';

export type SaveNewUserGroupBody = {
  groupName: string;
};

export type UpdateUserGroupBody = {
  userIds: string[];
  collectionIds: string[];
  permission: UserGroupPermission;
  groupName: string;
};

const ENDPOINT = isLocalMode() ? '' : '/admin-portal';

export const AdminPortalService = {
  // GET /user-groups
  getAllUserGroups: () => {
    return BaseAPI.get<UserGroupResponse[]>(ENDPOINT + '/user-groups');
  },

  // GET /user-groups?group_id=<string>
  getUserGroupById: (groupId: string) => {
    return BaseAPI.get<UserGroupByIdResponse>(ENDPOINT + '/user-groups', {
      params: { group_id: groupId },
    });
  },

  // GET /user-groups?user_available=true
  getAvailableUsers: () => {
    return BaseAPI.get<AvailableUser[]>(ENDPOINT + '/user-groups', {
      params: { user_available: true },
    });
  },

  // POST /user-groups
  saveNewUserGroup: (body: SaveNewUserGroupBody) => {
    return BaseAPI.post<string>(ENDPOINT + '/user-groups', {
      group_name: body.groupName,
    });
  },

  // PUT /user-groups?group_id=<string>
  updateUserGroup: (groupId: string, body: Partial<UpdateUserGroupBody>) => {
    return BaseAPI.put<string>(
      ENDPOINT + '/user-groups',
      {
        user_ids: body?.userIds ?? undefined,
        collection_ids: body?.collectionIds ?? undefined,
        permission: ensureGroupPermissionBody(body.permission),
        group_name: body?.groupName ?? undefined,
      },
      {
        params: {
          group_id: groupId,
        },
      },
    );
  },

  // DELETE /user-groups
  deleteUserGroup: (groupId: string) => {
    return BaseAPI.delete(ENDPOINT + '/user-groups', {
      params: { group_id: groupId },
    });
  },
};

const ensureGroupPermissionBody = (
  permission: UserGroupPermission | undefined,
) => {
  if (isNil(permission)) return undefined;

  return {
    is_admin: permission.isAdmin,
    enable_edit: permission.isEnabledEdit,
    enable_publish: permission.isEnabledPublish,
  };
};
