/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import React, { useEffect, useState } from 'react';
import { AuthSocialButtonV2 } from 'components/shared/AuthSocialButtons/AuthSocialButtonV2';
import {
  AppleIdResponse,
  AuthService,
  FbSdkResponse,
  GoogleIdResponse,
} from 'services';
import { AppleAuthError, Social } from 'utils/enum';
import { customToast } from 'utils/toast.util';
import { loginFacebookSDKAsync } from 'components/VideoPlayer/Transcription/MediaUtilities';
import jwt_decode from 'jwt-decode';
import { ErrorForm } from 'components/shared/ErrorForm/ErrorForm';
import { get, isEmpty, isNil } from 'lodash';
import { useToggle } from 'react-use';
import {
  getDomainWithoutSubdomain,
  getEmailErrorMessage,
  invalidEmail,
  validateDomain,
  ValidityCheck,
} from 'utils/utils';
import Loader from 'components/loader/loader';
import { Link, useHistory } from 'react-router-dom';
import {
  sign_up_check_message,
  sign_up_fail_message,
  sign_up_success_message,
} from 'utils/constants';
import { TextField } from 'components/shared/TextField/TextField';
import { textLink } from 'components/shared/twin.styles';

interface SocialAuthForm {
  companyname: string;
  subdomain: string;
  firstname: string;
  lastname: string;
  email: string;
  authToken?: string;
  profilePicture?: string;
  provider?: Social;
}

export const SignUpV2 = () => {
  const [socialCredential, setSocialCredential] = useState<
    | GoogleIdResponse
    | gapi.auth2.AuthResponse
    | AppleIdResponse
    | FbSdkResponse
    | any
  >(null);
  const [provider, setProvider] = useState<Social | null>(null);
  const [isLoading, toggleLoading] = useToggle(false);
  const [isChecked, toggleChecked] = useToggle(false);

  const [form, setForm] = useState<SocialAuthForm>({
    companyname: '',
    subdomain: '',
    firstname: '',
    lastname: '',
    email: '',
    authToken: '',
    profilePicture: undefined,
  });

  const [error, setError] = useState<any>({
    companynameError: false,
    subdomainError: false,
    inValidDomain: false,
    firstnameError: false,
    lastnameError: false,
    emailError: false,
    information: true,
    success: true,
  });

  const [warning, setWarning] = useState<any>({
    companynameWarning: false,
    domainWarning: false,
    firstnameWarning: false,
    lastnameWarning: false,
    emailWarning: false,
    message: 'Maximum number of characters entered',
  });

  const history = useHistory();

  const isSocialSignUp = !isNil(provider) && !isNil(socialCredential);

  const isInvalidForm = () =>
    ValidityCheck(
      form.companyname,
      form.subdomain,
      form.lastname,
      form.firstname,
      form.email,
      isChecked,
    ) ||
    invalidEmail(form.email) ||
    validateDomain(form.subdomain);

  useEffect(() => {
    const newForm = { ...form };
    if (provider === Social.GOOGLE) {
      const idToken = socialCredential?.id_token;
      const decodedToken: any = jwt_decode(idToken);

      newForm.firstname = decodedToken?.given_name ?? '';
      newForm.lastname = decodedToken?.family_name ?? '';
      newForm.email = decodedToken?.email ?? '';
      newForm.authToken = idToken;
    } else if (provider === Social.APPLE) {
      const rawIdToken = socialCredential?.authorization?.id_token;
      const idTokenDecoded: any = jwt_decode(rawIdToken);

      newForm.email = idTokenDecoded?.email ?? '';
      newForm.authToken = socialCredential?.authorization?.id_token;
    } else if (provider === Social.FACEBOOK) {
      const user = socialCredential as FbSdkResponse;

      newForm.firstname = user?.first_name;
      newForm.lastname = user?.last_name;
      newForm.email = user?.email;
      newForm.authToken = user?.id;
      newForm.profilePicture = user?.picture?.data?.url;
    }
    setForm(newForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, socialCredential]);

  const handleSignUpGoogleButton = async () => {
    try {
      const user = await window?.gapi?.auth2?.getAuthInstance()?.signIn();

      const credential = await user?.getAuthResponse();
      setSocialCredential(credential as any);
      setProvider(Social.GOOGLE);
    } catch (err: any) {
      console.log('err :>> ', err);
    }
  };

  const handleSignUpAppleButton = async (
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    if (typeof (window as any)?.AppleID?.auth?.signIn !== 'function') {
      customToast.error('Apple signin is not ready. Please try again later');
      return;
    }

    try {
      const appleIdResponse: AppleIdResponse = await (
        window as any
      ).AppleID.auth.signIn();
      // console.log(`appleIdResponse`, appleIdResponse);

      setSocialCredential(appleIdResponse);
      setProvider(Social.APPLE);
    } catch (error: any) {
      console.log('error :>> ', error);
      let message = 'Apple Authorization cannot be completed';
      switch (error?.error) {
        case AppleAuthError.POPUP_BLOCKED:
          message =
            'Popup blocked by browser. Please allow popups for this site';
          break;
        default:
          break;
      }
      customToast.error(message);
    }
  };

  const handleSignUpFacebookButton = async () => {
    try {
      const user = await loginFacebookSDKAsync();

      if (!user?.email) {
        return customToast.error(
          'Cannot read email from your Facebook account. Please try again.',
        );
      }

      setSocialCredential(user);
      setProvider(Social.FACEBOOK);
    } catch (error: any) {
      console.log('Facebook Exception :>> ', error);
    }
  };

  const handleChange = (property: any) => (e: any) => {
    let letters = /^[A-Za-z0-9]+$/;
    let value = e.target.value;
    if (property !== 'domain' && property !== 'email' && value.length === 50) {
      setWarning((preValue: any) => ({
        ...preValue,
        [property + 'Warning']: true,
      }));
    } else if (
      (property === 'domain' || property === 'email') &&
      value.length === 100
    ) {
      setWarning((preValue: any) => ({
        ...preValue,
        [property + 'Warning']: true,
      }));
    } else {
      setWarning((preValue: any) => ({
        ...preValue,
        [property + 'Warning']: false,
      }));
    }
    if (property === 'companyname' || property === 'domain') {
      let validateDomain = /^(?:(w)(?!\1{3})|[^w])+$/;
      let subdomain: any;
      if (value.match(letters)) {
        subdomain = value.toLowerCase();
      } else {
        setError((preValue: any) => ({ ...preValue, subdomainWarning: true }));
        subdomain = value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
      }
      subdomain = subdomain.trim();
      if (validateDomain.test(value)) {
        if (value.match('www')) {
          setError((preValue: any) => ({ ...preValue, inValidDomain: true }));
        } else {
          setError((preValue: any) => ({ ...preValue, inValidDomain: false }));
        }
      } else {
        setError((preValue: any) => ({ ...preValue, inValidDomain: false }));
      }
      setForm((preValue: any) => ({
        ...preValue,
        [property]: value,
        subdomain,
      }));
    } else if (property === 'firstname' || property === 'lastname') {
      let alphaNumeric = /^[a-zA-Z0-9\-\s]+$/;
      if (!value || value.match(alphaNumeric)) {
        setForm((preValue: any) => ({ ...preValue, [property]: value }));
      }
    } else if (property === 'email') {
      setForm((preValue: any) => ({
        ...preValue,
        [property]: value.toLowerCase(),
      }));
    } else {
      setForm((preValue: any) => ({ ...preValue, [property]: value }));
    }
  };

  const handleOnBlur = (property: string) => {
    if (get(form, property) === '') {
      setError((preValue: any) => ({
        ...preValue,
        [property + 'Error']: true,
      }));
    } else {
      if (property === 'email') {
        const emailMessage = getEmailErrorMessage(form.email);
        if (!isEmpty(emailMessage) && !error.emailError) {
          setError((preValue: any) => ({
            ...preValue,
            emailError: true,
            emailMessage,
          }));
        }
      }
    }
  };

  const handleSubmit = () => {
    if (isInvalidForm()) return;

    toggleLoading(true);
    let { subdomain } = form;
    subdomain = subdomain
      .replace('.voice-intellect.com', '')
      .replace('.sonnant.ai', '')
      .replace('tharunagarwal.com', '');
    if (isSocialSignUp) {
      AuthService.registerSocial({
        companyname: form.companyname,
        email: form.email,
        firstname: form.firstname,
        lastname: form.lastname,
        subdomain,
        token: form?.authToken,
        profilePicture: form?.profilePicture,
        provider,
      })
        .then((signInResult) => {
          setError((preValue: any) => ({
            ...preValue,
            apiError: false,
            apiErrorMessage: '',
            apiSuccess: true,
            apiSuccessMessage: signInResult.data,
          }));
          toggleLoading(false);
          customToast.loading(
            'Account registered successfully. Redirecting...',
          );

          setForm((preValue: any) => ({
            ...preValue,
            companyname: '',
            subdomain: '',
            firstname: '',
            lastname: '',
            email: '',
          }));

          const domainUrl = `${
            window.location.protocol
          }//${subdomain}.${getDomainWithoutSubdomain()}`;
          window.location.replace(
            `${domainUrl}?access_token=${signInResult.data.AccessToken}&id_token=${signInResult.data.IdToken}&refresh_token=${signInResult.data.RefreshToken}`,
          );
        })
        .catch((err: any) => {
          if (err.response && err.response.status === 500) {
            customToast.error(
              'A Sonnant Library of the same name already exists. Please ensure you select a unique Sonnant Domain.',
            );
          } else if (err.response) {
            customToast.error(err.response.data);
          }
          toggleLoading(false);
        });
    } else {
      const newForm = { ...form, authToken: undefined, subdomain };
      AuthService.register(newForm)
        .then((res) => {
          setError((preValue: any) => ({
            ...preValue,
            apiError: false,
            apiErrorMessage: '',
            apiSuccess: true,
            apiSuccessMessage: res.data,
          }));
          toggleLoading(false);
          setForm((preValue: any) => ({
            ...preValue,
            companyname: '',
            subdomain: '',
            firstname: '',
            lastname: '',
            email: '',
          }));
          history.push('/message', {
            name: 'Sign up',
            type: 'successful',
            message: sign_up_success_message,
            checkmessage: sign_up_check_message,
          });
        })
        .catch((err) => {
          if (err.response && err.response.status === 500) {
            customToast.error(
              'A Sonnant Library of the same name already exists. Please ensure you select a unique Sonnant Domain.',
            );
          } else if (err.response) {
            customToast.error(err.response.data);
          } else {
            history.push('/message', {
              name: 'Sign up',
              type: 'unsuccessful',
              message: sign_up_fail_message,
              route: '/signup',
            });
          }
          toggleLoading(false);
        });
    }
  };

  const handleClickAgree = (e: React.ChangeEvent<HTMLInputElement>) => {
    toggleChecked(e.target.checked);
  };

  return (
    <div tw="text-black">
      {isLoading && (
        <div className="loader__component" tw="(fixed bg-white)!">
          <Loader />
        </div>
      )}
      <div tw="font-semibold text-[2.8rem] mb-4">Sign up</div>

      <div tw="mt-5 text-13">
        Start your <span tw="font-medium">FREE TRIAL</span> now
      </div>

      {!socialCredential && !provider && (
        <div tw="flex flex-nowrap space-x-6 my-7 items-center">
          <div tw="text-13 min-width[9rem] whitespace-nowrap">Sign up with</div>

          <AuthSocialButtonV2
            handleLoginGoogleButton={handleSignUpGoogleButton}
            handleLoginAppleButton={handleSignUpAppleButton}
            handleLoginFacebookButton={handleSignUpFacebookButton}
          />
        </div>
      )}

      <div tw="mb-2">
        <div tw="text-13 mb-5">Or with details</div>

        <div tw="space-y-9">
          <div tw="flex space-x-5">
            <div tw="flex-1">
              <TextField
                name="First Name"
                onBlur={() => handleOnBlur('firstname')}
                onChange={handleChange('firstname')}
                onInput={() => setError({ ...error, firstnameError: false })}
                value={form.firstname}
                maxLength={50}
              />
            </div>
            <div tw="flex-1">
              <TextField
                name="Surname"
                onBlur={() => handleOnBlur('lastname')}
                onChange={handleChange('lastname')}
                onInput={() => setError({ ...error, lastnameError: false })}
                value={form.lastname}
                hasError={error.firstnameError || error.lastnameError}
                hasWarning={error.firstnameWarning || error.lastnameWarning}
                maxLength={50}
              />
            </div>
          </div>

          {(error.firstnameError || error.lastnameError) && (
            <ErrorForm
              type="error"
              msg="First Name and Last Name is a mandatory field"
            />
          )}
          {(warning.firstnameWarning || warning.lastnameWarning) && (
            <ErrorForm type="warning" msg={warning.message} />
          )}

          <div>
            <TextField
              name="Email"
              onBlur={() => handleOnBlur('email')}
              onChange={handleChange('email')}
              onInput={() => setError({ ...error, emailError: false })}
              value={form.email}
              maxLength={100}
              disabled={isSocialSignUp}
              hasError={error.emailError}
            />
            {error.emailError && (
              <ErrorForm
                type="error"
                msg={error.emailMessage ?? 'Email is a mandatory field'}
              />
            )}
          </div>

          <div>
            <TextField
              name="Company Name"
              onBlur={() => handleOnBlur('companyname')}
              onChange={handleChange('companyname')}
              onInput={() => setError({ ...error, companynameError: false })}
              value={form.companyname}
              hasError={error.companynameError}
              hasWarning={error.companynameWarning}
              maxLength={50}
            />

            {form?.subdomain?.trim() && (
              <div tw="w-full text-right -mb-4 text-sonnant-blue text-13 font-medium">
                {form.subdomain}.
                {getDomainWithoutSubdomain(window.location.origin)}
              </div>
            )}

            {error.companynameError && (
              <ErrorForm type="error" msg="Company Name is a mandatory field" />
            )}
            {warning.companynameWarning && (
              <ErrorForm type="warning" msg={warning.message} />
            )}
          </div>
        </div>
      </div>

      <div tw="flex items-center mb-5 mt-7">
        <label tw="flex">
          <input
            onChange={handleClickAgree}
            checked={isChecked}
            tw="cursor-pointer mr-6! mb-0"
            type="checkbox"
          />
          <span className="checkmark" tw="top[2px] cursor-pointer"></span>

          <div tw="text-12">
            I agree to the{' '}
            <span tw="color[#5279b4]">
              <a
                href="https://www.sonnant.com/terms"
                target="_blank"
                rel="noreferrer"
                css={[textLink, tw`font-medium`]}
              >
                Terms and Conditions
              </a>
            </span>{' '}
            and{' '}
            <span tw="color[#5279b4]">
              <a
                href="https://www.sonnant.com/privacy"
                target="_blank"
                rel="noreferrer"
                css={[textLink, tw`font-medium`]}
              >
                Privacy
              </a>
            </span>
          </div>
        </label>
      </div>

      <div
        onClick={handleSubmit}
        tw="w-full py-3.5 text-16 font-medium cursor-pointer text-white flex items-center justify-center rounded-2xl bg-[#26165f] hover:bg-[#110a2b] active:bg-[#1f1252] hover:shadow"
        css={[isInvalidForm() && tw`(cursor-not-allowed)!`]}
      >
        Create my account
      </div>

      <div tw="flex justify-center">
        <div tw="text-13 text-center text-sonnant-purple-3 mt-10 cursor-pointer mb-5">
          Have an account?{' '}
          <Link css={[textLink]} to="/signin">
            Sign in now
          </Link>
        </div>
      </div>
    </div>
  );
};
