/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { formatLibraryTitle } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { useSelector } from 'react-redux';
import Loader from '../loader/loader';
import { LibraryPagination } from './LibraryPagination';
import './ThumbNails.scss';
import { ThumbNailItem } from './ThumbNailsItem/ThumbNailItem';
import { RootState } from 'reducers';
import { isEmpty } from 'lodash';

export const ThumbNails = () => {
  // console.count('---- ThumbNails');

  const { loading } = useSelector((state: RootState) => state.library);
  const rawLibrary = useSelector(
    (state: RootState) => state.library?.data?.library,
  );
  const library = formatLibraryTitle(rawLibrary);

  if (!loading && isEmpty(library)) {
    return null;
  }

  if (loading) {
    return (
      <div className="loader__component">
        <Loader />
      </div>
    );
  }

  return (
    <div
      className="grid-container fluid custom_grid_container"
      tw="-mt-4 flex flex-col h-full"
    >
      <div tw="flex-1">
        <div className="grid-x thumbNails_block">
          {library &&
            library.map((item, index) => (
              <ThumbNailItem key={item.mediaid} item={item} index={index} />
            ))}

          {loading && (
            <div
              className="cell grid-padding-x small-12 medium-3 large-3 xlarge-2 videoThumbnail"
              tw="text-center mt-14"
            >
              <Loader tw="object-fit[none]!" />
              <div tw="font-medium mt-8">Loading...</div>
            </div>
          )}
        </div>
      </div>

      <div tw="w-full flex justify-center items-center">
        <LibraryPagination />
      </div>
    </div>
  );
};
