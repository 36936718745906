/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { useStateReset } from 'hooks/useStateReset';
import { isEmpty, isNil, uniqBy } from 'lodash';
import {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useToggle } from 'react-use';

import Loader from 'components/loader/loader';
import { Modal } from 'components/UiControls/modal/modal';
import {
  extractApplePodcast,
  validateRssFeeds,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { RootState } from 'reducers';
import { RssUploadPayload, UploadService } from 'services';
import { endOfDayISO, startOfDayISO } from 'utils/date.util';
import { ModalOptions, SelectionMode } from 'utils/enum';
import { extract, withId } from 'utils/generic.util';
import { CustomDateParams, RssFeedDisplay, RssMeta } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { PreviewPaginationWrapper } from '../PreviewPaginationWrapper/PreviewPaginationWrapper';
import { RssPreviewPagination } from '../PreviewPaginationWrapper/RssPreviewPagination';

type RssPaginationPreviewModalProps = {
  rssURL: string;
  isSubscribeRss: boolean;
};

type RssPaginationPreviewModalPayload = {
  option: ModalOptions;
};

type RssPreviewProgramPayload = {
  rssURL: string | null;
  cursor: number;
  isSubscribeRss: boolean;

  startDate?: Date | string | null;
  endDate?: Date | string | null;
};

type PromiseParams = {
  resolve: (payload: RssPaginationPreviewModalPayload) => void;
  reject: (err: any) => void;
};

type Props = {};

export const RssPaginationPreviewModal = forwardRef(
  (props: Props, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));
    const collection = useSelector((state: RootState) => state.collection);

    const [isOpen, toggleIsOpen] = useToggle(false);
    const [isLoading, toggleIsLoading] = useToggle(false);

    const [menuSelectionMode, setMenuSelectionMode] = useState<SelectionMode>(
      SelectionMode.PAGE,
    );

    const [apiPayload, setApiPayload, resetStateApiPayload] =
      useStateReset<RssPreviewProgramPayload>({
        rssURL: null,
        cursor: 1,
        isSubscribeRss: false,
      });

    const [rssPreviewList, setRssPreviewList] = useState<RssFeedDisplay[]>([]);
    const [rssMeta, setRssMeta] = useState<RssMeta | null>(null);
    const [totalItems, setTotalItems] = useState<number>(1);
    const [selectedRssFeeds, setSelectedRssFeeds] = useState<RssFeedDisplay[]>(
      [],
    );

    const isSelectAll = menuSelectionMode === SelectionMode.ALL;
    const isEmptyItems = totalItems === 0;

    const promiseInfo = useRef<PromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const currentPageIndex = apiPayload.cursor;

    useEffect(() => {
      if (isEmpty(apiPayload.rssURL)) return;

      fetchRssPreviewAsync();
    }, [apiPayload]);

    // AUTO select mode when update items
    useEffect(() => {
      if (isEmptyItems) {
        setMenuSelectionMode(SelectionMode.NONE);
        return;
      }

      const allIdList = extract(rssPreviewList, 'id');
      const selectedIdList = extract(selectedRssFeeds, 'id');

      const isAllItemsSelected = allIdList.every((value) =>
        selectedIdList.includes(value),
      );

      if (isAllItemsSelected && menuSelectionMode !== SelectionMode.ALL) {
        setMenuSelectionMode(SelectionMode.PAGE);
      }
    }, [selectedRssFeeds, rssPreviewList]);

    // AUTO check all items when select mode
    useEffect(() => {
      if ([SelectionMode.PAGE, SelectionMode.ALL].includes(menuSelectionMode)) {
        const newSelectedList = selectedRssFeeds.concat(rssPreviewList);

        const uniqNewSelectedList = uniqBy(newSelectedList, 'id');

        setSelectedRssFeeds(uniqNewSelectedList);
      }
    }, [menuSelectionMode, rssPreviewList]);

    const show = async (
      customProps: RssPaginationPreviewModalProps,
    ): Promise<unknown> => {
      const { rssURL, isSubscribeRss } = customProps;

      toggleIsOpen(true);

      setApiPayload({
        rssURL,
        cursor: 1,
        isSubscribeRss,
      });

      customToast.success('Parsing RSS completed');

      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };
      });
    };

    const handleReset = () => {
      setSelectedRssFeeds([]);

      resetStateApiPayload();
    };

    const hideModal = () => {
      handleReset();
      toggleIsOpen(false);
    };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current?.resolve({
        option: ModalOptions.CANCEL,
      });
    };

    const handleCloseModal = () => {
      hideModal();
      promiseInfo.current?.resolve({ option: ModalOptions.CLOSE });
    };

    const fetchRssPreviewAsync = async () => {
      try {
        toggleIsLoading(true);

        const extractedURL = await extractApplePodcast(
          apiPayload.rssURL as string,
        );
        const { data: rssFeeds } = await UploadService.previewRss({
          url: extractedURL,
          page: apiPayload.cursor,
          startDate: startOfDayISO(apiPayload.startDate),
          endDate: endOfDayISO(apiPayload.endDate),
        });

        const feedsWithId = rssFeeds?.data?.episodes.map(withId);

        setRssMeta(rssFeeds?.data?.meta);

        const isValidFeeds = validateRssFeeds(feedsWithId);

        if (!isValidFeeds) {
          throw new Error(
            'No links to the media files could be found in the RSS feed.',
          );
        }

        setRssPreviewList(feedsWithId);

        setTotalItems(rssFeeds?.total_count);
      } catch (err) {
        console.log('err: >>', err);
      } finally {
        toggleIsLoading(false);
      }
    };

    const uploadRssSelectedItems = async (payload: RssUploadPayload) => {
      const collectionDestId = collection.uploadSelectedId || undefined;

      const uploadFeedAsync = UploadService.uploadRss({
        ...payload,
        collection_id: collectionDestId,
      });

      customToast.promise(uploadFeedAsync, {
        loading: 'Uploading',
        success: 'RSS episodes uploaded successfully',
        error: 'Error while uploading',
      });

      try {
        await uploadFeedAsync;

        hideModal();
      } catch (err: any) {
        console.log('err :>> ', err);
        return;
      }
    };

    const setCurrentPageIndex = (pageIndex: number) => {
      setApiPayload({ ...apiPayload, cursor: pageIndex });
    };

    const handleChangePage =
      (step: number) => (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const pageNumber = currentPageIndex + step;
        setCurrentPageIndex(pageNumber);

        if (menuSelectionMode !== SelectionMode.ALL) {
          setMenuSelectionMode(SelectionMode.NONE);
        }
      };

    const handleCheckSelected = (selectedFeed: RssFeedDisplay) => {
      const newSelectedRssFeeds: RssFeedDisplay[] = [...selectedRssFeeds];

      const foundIndex = newSelectedRssFeeds.findIndex(
        ({ id }) => id === selectedFeed.id,
      );

      // uncheck
      if (foundIndex >= 0) {
        newSelectedRssFeeds.splice(foundIndex, 1);

        setMenuSelectionMode(SelectionMode.NONE);
      } else {
        newSelectedRssFeeds.push(selectedFeed);
      }

      setSelectedRssFeeds(newSelectedRssFeeds);
    };

    const handleUploadRssFeeds = async () => {
      if (isNil(rssMeta) || isNil(apiPayload.rssURL)) return;

      await uploadRssSelectedItems({
        feeds: selectedRssFeeds,
        meta: rssMeta,
        rawURL: apiPayload.rssURL, // Rss by Apple
        isSubscribed: apiPayload.isSubscribeRss,
        is_all: isSelectAll,
        startDate: startOfDayISO(apiPayload.startDate),
        endDate: endOfDayISO(apiPayload.endDate),
      });
    };

    const handleChangeMenuSelectionMode = (selectedMode: SelectionMode) => {
      if (selectedMode === menuSelectionMode) return;

      if (selectedMode !== SelectionMode.NONE) {
        setMenuSelectionMode(selectedMode);
        return;
      }

      if (menuSelectionMode === SelectionMode.ALL) {
        // Uncheck all
        setSelectedRssFeeds([]);
      } else {
        // Uncheck page items
        const rssPreviewIds = extract(rssPreviewList, 'id');
        const newSelectedRssFeeds = selectedRssFeeds.filter(
          ({ id }) => !rssPreviewIds.includes(id),
        );

        setSelectedRssFeeds(newSelectedRssFeeds);
      }

      setMenuSelectionMode(selectedMode);
    };

    const handleDatesChange = ({ startDate, endDate }: CustomDateParams) => {
      setSelectedRssFeeds([]);
      setApiPayload({ ...apiPayload, cursor: 1, startDate, endDate });
    };

    const getUploadButtonText = () => {
      if (menuSelectionMode === SelectionMode.ALL)
        return `Upload all (${totalItems})`;

      return selectedRssFeeds?.length > 0
        ? `Upload (${selectedRssFeeds.length})`
        : 'Upload';
    };

    return (
      <Modal show={isOpen} modalClosed={handleCloseModal}>
        <div className="userModal_Popus">
          <h2>RSS Feed Preview</h2>

          {isLoading && (
            <div tw="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-white z-[20] opacity[0.95] rounded-md">
              <Loader />
            </div>
          )}

          <PreviewPaginationWrapper
            isLoading={isLoading}
            menuSelectionMode={menuSelectionMode}
            totalItems={totalItems}
            currentPageIndex={currentPageIndex}
            onSelectModeChange={handleChangeMenuSelectionMode}
            onDateRangeChange={handleDatesChange}
            onPageChange={handleChangePage}
          >
            <RssPreviewPagination
              previewList={rssPreviewList}
              selectedRssFeeds={selectedRssFeeds}
              handleCheckSelected={handleCheckSelected}
            />
          </PreviewPaginationWrapper>

          <div tw="flex justify-end mt-6">
            <div className="btn_section">
              <button
                type="button"
                className="button cancel large"
                tw="flex justify-center items-center"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                type="button"
                className="button btn-primary large"
                tw="flex justify-center items-center (w-auto px-5)!"
                disabled={isEmpty(selectedRssFeeds)}
                onClick={handleUploadRssFeeds}
              >
                {getUploadButtonText()}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);
