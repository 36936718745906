type EnvConfig = {
  apiGateway: {
    X_API_KEY: string;
    URL: string;
  };
  WEBSOCKET_URL: string;
  LIVE_ENV_URL: `https://${string}`;
  LINKEDIN_REDIRECT_URL: string;
  LINKEDIN_CLIENT_ID: string;
  DOMAIN_NAME: string;
  STRIPE_P_KEY: string;
  TWITTER_REDIRECT_URL: string;
  TWITTER_CONSUMER_KEY: string;
  TWITTER_CONSUMER_SECRET: string;
  UPPY_COMPANION_URL: string;
  FB_CLIENT_ID: string;
  FB_CLIENT_SECRET: string;
  FB_REDIRECT_URL: string;
  YT_CLIENT_ID: string;
  YT_REDIRECT_URL: string;
  APPLE_CLIENT_ID: string;
  APPLE_REDIRECT_URI: string;
  TIKTOK_CLIENT_ID: string;
  TIKTOK_CLIENT_SECRET: string;
  TIKTOK_REDIRECT_URL: string;
  INSTAGRAM_REDIRECT_URL: string;
};

// DEV LOCAL - sonnant.local
const devLocal: EnvConfig = {
  apiGateway: {
    X_API_KEY: 'S8LKRkhXTK34q8fDL3RClKaIr0S5Kxj81zag94l0',
    URL: 'https://nkdjm1tgmj.execute-api.us-east-2.amazonaws.com/dev/api/v2.1',
  },
  // WEBSOCKET_URL: 'wss://sfkakxd1oe.execute-api.us-east-2.amazonaws.com/dev',
  WEBSOCKET_URL: 'wss://ws.sonnant.xyz/v2',
  LIVE_ENV_URL: 'https://sonnant.xyz',
  LINKEDIN_REDIRECT_URL: 'https://sonnant.cf/linkedin',
  LINKEDIN_CLIENT_ID: '865va5wg7qq0mw',
  DOMAIN_NAME: 'sonnant.cf',
  STRIPE_P_KEY:
    'pk_test_51I3VwJHecKqeF9LIKU5aiG1TXFEDb2mVx93o5n4mHTIvazPqqSEZTfm7oj3vr5gIQUtuEJ3kg1BH5wpyKb1tXBd700mTlGUgcP',
  TWITTER_REDIRECT_URL: 'http://localhost:3000/twitter',
  TWITTER_CONSUMER_KEY: 'VDyXhA5MQ8SQT5r2pP8c90VON',
  TWITTER_CONSUMER_SECRET: 'Hhi5yGInGaqFZRiPjnbsEJGKA1oXo5Hn8x2NBohaNFXPFcdwSs',
  UPPY_COMPANION_URL: 'https://companion.sonnant.xyz',
  FB_CLIENT_ID: '378938290607567',
  FB_CLIENT_SECRET: 'b45937280e994b8297a88cf17d8fdc1d',
  FB_REDIRECT_URL: 'https://sonnant.cf/facebook',
  YT_CLIENT_ID:
    '692875910345-3k2lghbp06t7jq7hi41djmr30hs155gc.apps.googleusercontent.com',
  YT_REDIRECT_URL: 'https://sonnant.cf/youtube',
  APPLE_CLIENT_ID: 'ai.sonnant.applesignin',
  APPLE_REDIRECT_URI: 'https://sonnant.cf',
  TIKTOK_CLIENT_ID: 'awifw6pdx9enj385',
  TIKTOK_CLIENT_SECRET: 'f401a482ccb9a3d388e05042aa51cac2',
  TIKTOK_REDIRECT_URL: 'https://sonnant.cf/tiktok',
  INSTAGRAM_REDIRECT_URL: 'https://sonnant.cf/instagram',
};

// STAGING LOCAL - sonnant.local
const stageLocal: EnvConfig = {
  apiGateway: {
    X_API_KEY: 'S8LKRkhXTK34q8fDL3RClKaIr0S5Kxj81zag94l0',
    URL: 'https://jch5i1b9j7.execute-api.ap-south-1.amazonaws.com/dev/api/v2.1',
  },
  WEBSOCKET_URL: 'wss://ws.voice-intellect.com/v2',
  LIVE_ENV_URL: 'https://voice-intellect.com',
  LINKEDIN_REDIRECT_URL: 'http://localhost:3000/linkedin',
  LINKEDIN_CLIENT_ID: '865va5wg7qq0mw',
  DOMAIN_NAME: 'sonnant.cf',
  STRIPE_P_KEY:
    'pk_test_51I3VwJHecKqeF9LIKU5aiG1TXFEDb2mVx93o5n4mHTIvazPqqSEZTfm7oj3vr5gIQUtuEJ3kg1BH5wpyKb1tXBd700mTlGUgcP',
  TWITTER_REDIRECT_URL: 'http://localhost:3000/twitter',
  TWITTER_CONSUMER_KEY: 'VDyXhA5MQ8SQT5r2pP8c90VON',
  TWITTER_CONSUMER_SECRET: 'Hhi5yGInGaqFZRiPjnbsEJGKA1oXo5Hn8x2NBohaNFXPFcdwSs',
  UPPY_COMPANION_URL: 'https://companion.sonnant.xyz',
  FB_CLIENT_ID: '378938290607567',
  FB_CLIENT_SECRET: 'b45937280e994b8297a88cf17d8fdc1d',
  FB_REDIRECT_URL: 'https://sonnant.cf/facebook',
  YT_CLIENT_ID:
    '692875910345-3k2lghbp06t7jq7hi41djmr30hs155gc.apps.googleusercontent.com',
  YT_REDIRECT_URL: 'https://sonnant.cf/youtube',
  APPLE_CLIENT_ID: 'ai.sonnant.applesignin',
  APPLE_REDIRECT_URI: 'https://sonnant.cf',
  TIKTOK_CLIENT_ID: 'awifw6pdx9enj385',
  TIKTOK_CLIENT_SECRET: 'f401a482ccb9a3d388e05042aa51cac2',
  TIKTOK_REDIRECT_URL: 'https://sonnant.cf/tiktok',
  INSTAGRAM_REDIRECT_URL: 'https://sonnant.cf/instagram',
};

// DEV - sonnant.xyz
const dev: EnvConfig = {
  apiGateway: {
    X_API_KEY: 'S8LKRkhXTK34q8fDL3RClKaIr0S5Kxj81zag94l0',
    URL: `https://${window.location.hostname}/api/v2.1`,
  },
  // WEBSOCKET_URL: 'wss://sfkakxd1oe.execute-api.us-east-2.amazonaws.com/dev',
  WEBSOCKET_URL: 'wss://ws.sonnant.xyz/v2',
  LIVE_ENV_URL: 'https://sonnant.xyz',
  LINKEDIN_REDIRECT_URL: 'https://sonnant.xyz/linkedin',
  LINKEDIN_CLIENT_ID: '865va5wg7qq0mw',
  DOMAIN_NAME: 'sonnant.xyz',
  STRIPE_P_KEY:
    'pk_test_51I3VwJHecKqeF9LIKU5aiG1TXFEDb2mVx93o5n4mHTIvazPqqSEZTfm7oj3vr5gIQUtuEJ3kg1BH5wpyKb1tXBd700mTlGUgcP',
  TWITTER_REDIRECT_URL: 'https://sonnant.xyz/twitter',
  TWITTER_CONSUMER_KEY: 'VDyXhA5MQ8SQT5r2pP8c90VON',
  TWITTER_CONSUMER_SECRET: 'Hhi5yGInGaqFZRiPjnbsEJGKA1oXo5Hn8x2NBohaNFXPFcdwSs',
  UPPY_COMPANION_URL: 'https://companion.sonnant.xyz',
  FB_CLIENT_ID: '378938290607567',
  FB_CLIENT_SECRET: 'b45937280e994b8297a88cf17d8fdc1d',
  FB_REDIRECT_URL: 'https://sonnant.xyz/facebook',
  YT_CLIENT_ID:
    '295889444000-a473i54f97mbsacj9fr2qgnri6t2nbc8.apps.googleusercontent.com',
  YT_REDIRECT_URL: 'https://sonnant.xyz/youtube',
  APPLE_CLIENT_ID: 'ai.sonnant.applesignin',
  APPLE_REDIRECT_URI: window.location.origin, // prevent www. domain redirect error
  TIKTOK_CLIENT_ID: 'awifw6pdx9enj385',
  TIKTOK_CLIENT_SECRET: 'f401a482ccb9a3d388e05042aa51cac2',
  TIKTOK_REDIRECT_URL: 'https://sonnant.xyz/tiktok',
  INSTAGRAM_REDIRECT_URL: 'https://sonnant.xyz/instagram',
};

// STAGING - voice-intellect.com
const stage: EnvConfig = {
  apiGateway: {
    X_API_KEY: 'S8LKRkhXTK34q8fDL3RClKaIr0S5Kxj81zag94l0',
    URL: `https://${window.location.hostname}/api/v2.1`,
  },
  // WEBSOCKET_URL: `wss://${window.location.hostname}/ws/v2`,
  WEBSOCKET_URL: 'wss://ws.voice-intellect.com/v2',
  LIVE_ENV_URL: 'https://voice-intellect.com',
  LINKEDIN_REDIRECT_URL: 'https://voice-intellect.com/linkedin',
  LINKEDIN_CLIENT_ID: '865va5wg7qq0mw',
  DOMAIN_NAME: 'voice-intellect.com',
  STRIPE_P_KEY:
    'pk_test_51I3VwJHecKqeF9LIKU5aiG1TXFEDb2mVx93o5n4mHTIvazPqqSEZTfm7oj3vr5gIQUtuEJ3kg1BH5wpyKb1tXBd700mTlGUgcP',
  TWITTER_REDIRECT_URL: 'https://voice-intellect.com/twitter',
  TWITTER_CONSUMER_KEY: 'VDyXhA5MQ8SQT5r2pP8c90VON',
  TWITTER_CONSUMER_SECRET: 'Hhi5yGInGaqFZRiPjnbsEJGKA1oXo5Hn8x2NBohaNFXPFcdwSs',
  UPPY_COMPANION_URL: 'https://companion.voice-intellect.com',
  FB_CLIENT_ID: '378938290607567',
  FB_CLIENT_SECRET: 'b45937280e994b8297a88cf17d8fdc1d',
  FB_REDIRECT_URL: 'https://voice-intellect.com/facebook',
  YT_CLIENT_ID:
    '692875910345-3k2lghbp06t7jq7hi41djmr30hs155gc.apps.googleusercontent.com',
  YT_REDIRECT_URL: 'https://voice-intellect.com/youtube',
  APPLE_CLIENT_ID: 'ai.sonnant.applesignin',
  APPLE_REDIRECT_URI: window.location.origin, // prevent www. domain redirect error
  TIKTOK_CLIENT_ID: 'awifw6pdx9enj385',
  TIKTOK_CLIENT_SECRET: 'f401a482ccb9a3d388e05042aa51cac2',
  TIKTOK_REDIRECT_URL: 'https://voice-intellect.com/tiktok',
  INSTAGRAM_REDIRECT_URL: 'https://voice-intellect.com/instagram',
};

// PROD - sonnant.ai
const prod: EnvConfig = {
  apiGateway: {
    X_API_KEY: 'S8LKRkhXTK34q8fDL3RClKaIr0S5Kxj81zag94l0',
    URL: `https://${window.location.hostname}/api/v2.1`,
    // URL: 'https://5wgg9ag2w0.execute-api.ap-southeast-2.amazonaws.com/dev/api/v2.1',
  },
  WEBSOCKET_URL: 'wss://ws.sonnant.ai/v2',
  LIVE_ENV_URL: 'https://sonnant.ai',
  LINKEDIN_REDIRECT_URL: 'https://sonnant.ai/linkedin',
  LINKEDIN_CLIENT_ID: '865va5wg7qq0mw',
  DOMAIN_NAME: 'sonnant.ai',
  STRIPE_P_KEY:
    'pk_live_51I3VwJHecKqeF9LIn1jIqeAZWye6yho7QZSOBb2pUUJCAmbJNx3RNRm0bQEXgIosYcNdlKrQaMZnJkgV0C9hEf3r00BSYMH11A',
  TWITTER_REDIRECT_URL: 'https://sonnant.ai/twitter',
  TWITTER_CONSUMER_KEY: 'VDyXhA5MQ8SQT5r2pP8c90VON',
  TWITTER_CONSUMER_SECRET: 'Hhi5yGInGaqFZRiPjnbsEJGKA1oXo5Hn8x2NBohaNFXPFcdwSs',
  UPPY_COMPANION_URL: 'https://companion.sonnant.ai',
  FB_CLIENT_ID: '378938290607567',
  FB_CLIENT_SECRET: 'b45937280e994b8297a88cf17d8fdc1d',
  FB_REDIRECT_URL: 'https://sonnant.ai/facebook',
  YT_CLIENT_ID:
    '295889444000-a473i54f97mbsacj9fr2qgnri6t2nbc8.apps.googleusercontent.com',
  YT_REDIRECT_URL: 'https://sonnant.ai/youtube',
  APPLE_CLIENT_ID: 'ai.sonnant.applesignin',
  APPLE_REDIRECT_URI: window.location.origin, // prevent www. domain redirect error
  TIKTOK_CLIENT_ID: 'awifw6pdx9enj385',
  TIKTOK_CLIENT_SECRET: 'f401a482ccb9a3d388e05042aa51cac2',
  TIKTOK_REDIRECT_URL: 'https://sonnant.ai/tiktok',
  INSTAGRAM_REDIRECT_URL: 'https://sonnant.ai/instagram',
};

let chooseConfig;

if (process.env.REACT_APP_STAGE === 'devLocal') {
  chooseConfig = devLocal;
} else if (process.env.REACT_APP_STAGE === 'stageLocal') {
  chooseConfig = stageLocal;
} else if (process.env.REACT_APP_STAGE === 'dev') {
  chooseConfig = dev;
} else if (process.env.REACT_APP_STAGE === 'stage') {
  chooseConfig = stage;
} else if (process.env.REACT_APP_STAGE === 'prod') {
  chooseConfig = prod;
} else {
  chooseConfig = devLocal;
}

export const config = {
  MAX_ATTACHMENT_SIZE: 5000000,
  ...chooseConfig,
};

export const IS_USE_SONNANT_V2 = true;
