import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GeneratedChartRaw } from 'utils/models';

export type TermExplorationState = {
  generatedCharts: GeneratedChartRaw[];
};

const initialState: TermExplorationState = {
  generatedCharts: [],
};

const termExplorationSlice = createSlice({
  name: 'termExploration',
  initialState,
  reducers: {
    setGeneratedChartsData: (
      state,
      { payload }: PayloadAction<TermExplorationState['generatedCharts']>,
    ) => {
      state.generatedCharts = payload;
    },
    appendNewGeneratedChart: (
      state,
      { payload }: PayloadAction<GeneratedChartRaw>,
    ) => {
      state.generatedCharts.push(payload);
    },
    removeGeneratedChart: (state, { payload }: PayloadAction<string>) => {
      const filteredGeneratedCharts = state.generatedCharts.filter(
        ({ id }) => id !== payload,
      );

      state.generatedCharts = filteredGeneratedCharts;
    },
  },
});

export const {
  setGeneratedChartsData,
  appendNewGeneratedChart,
  removeGeneratedChart,
} = termExplorationSlice.actions;

export const termExplorationReducer = termExplorationSlice.reducer;
