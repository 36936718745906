/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-mixed-operators */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { ReactComponent as QuestionSvg } from 'assets/Icons/Vector_question.svg';
import { ReactComponent as AutoSave } from 'assets/Icons/autosave.svg';
import { ReactComponent as Memory } from 'assets/Icons/memory.svg';
import { ReactComponent as Movie } from 'assets/Icons/movieBlack.svg';
import { ReactComponent as Source } from 'assets/Icons/sourceBlack.svg';
import { ReactComponent as Visibility } from 'assets/Icons/visibility.svg';
import { Loader } from 'components/loader/loader';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { Hint } from 'components/shared/Hint';
import { ResetBottomBar } from 'components/shared/ResetBottomBar/ResetBottomBar';
import { isEmpty, isEqual, isNil } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-reflex/styles.css';
import { RootState } from 'reducers';
import { UserService } from 'services';
import { setPreference, setPreferenceSetting } from 'slices/global.slice';
import { tierSelector } from 'slices/payment.slice';
import { defaultSetting } from 'utils/default/defaultSetting';
import { ModalOptions } from 'utils/enum';
import {
  ConfirmModalProps,
  PreferenceOption,
  SuggestedTotal,
} from 'utils/models';
import { customToast } from 'utils/toast.util';
import { getTenantidFromIdToken } from 'utils/utils';
import './Preferences-Setting.scss';
import { SharingOptions } from './SharingOptions';
import { SuggestedItem } from './SuggestedItem';

interface IProps {}

export const PreferencesSetting = (props: IProps) => {
  const dispatch = useDispatch();
  const global = useSelector((state: RootState) => state.global);
  const tier = useSelector(tierSelector);
  const tenantReducer = useSelector(
    (state: RootState) => state.payment.subscription,
  );

  const initialState = global.preference;

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<PreferenceOption>(initialState);

  const shouldDisabledButton =
    isEmpty(initialState) || isEqual(initialState, state);

  const confirmModalRef = useRef<any>();

  const shouldDisableReset = isEqual(defaultSetting.preference, state);

  useEffect(() => {
    if (isEmpty(initialState)) return;
    setState(initialState);
  }, [initialState]);

  const handleCheckboxClick = (evt: any) => {
    const value = evt.target.checked;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const handleBlurNumChange = () => {
    const newState = { ...state };

    if (isNaN(newState.clips) || newState.clips === 0) {
      newState.clips = 1;
    }
    if (newState.clips <= 0) {
      newState.clips = 1;
    }

    const isValidSuggestedTotal = validateSuggestedTotal(newState);
    if (!isValidSuggestedTotal) return;

    setState(newState);
  };

  const handleNumChange = (evt: any) => {
    let value = evt.target.value;
    const name = evt.target.name;

    let valueNum = parseInt(value);

    if (valueNum > 10) {
      valueNum = 10;
    }

    setState({
      ...state,
      [name]: valueNum,
    });
  };

  const handleTimeChange = (evt: any) => {
    let value = evt?.target?.value;

    if (parseInt(value) > 30) {
      value = '30';
    }
    if (parseInt(value) < 1) {
      value = '1';
    }

    setState({
      ...state,
      autoSaveMinutes: value,
    });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const ignoredKeys = ['.', ',', 'E', 'e'];

    if (ignoredKeys.includes(e?.key)) {
      e.preventDefault();
    }
  };

  const handleReset = () => {
    setState(initialState);
  };

  const handleSubmit = async (payload: PreferenceOption = state) => {
    const isValidSuggestedTotal = validateSuggestedTotal(payload);
    if (!isValidSuggestedTotal) return;

    try {
      const result = JSON.stringify({ ...payload });
      const tenantid = getTenantidFromIdToken();
      if (!tenantid) return;

      setLoading(true);

      dispatch(setPreferenceSetting(state));

      const response = await UserService.savePreference({
        tenantid,
        settingsjson: result,
        customterms: global?.customTerms
          ? JSON.stringify(global?.customTerms)
          : undefined,
      });

      if (response.status === 200) {
        const data = response.data;
        dispatch(setPreference(data));
        customToast.success('Settings saved');
      } else {
        customToast.error('Something went wrong');
      }
    } catch (error: any) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const setDefaultAutosave = () => {
    if (!state?.autoSaveMinutes && !isNil(initialState?.autoSaveMinutes)) {
      setState({
        ...state,
        autoSaveMinutes: initialState?.autoSaveMinutes,
      });
    }
  };

  const handleResetDefault = async () => {
    const result = await confirmModalRef.current?.show({
      title: 'Confirm reset to default',
      confirmText: 'Confirm',
      message: (
        <div>
          Are you sure you want to reset preferences configuration to default?
        </div>
      ),
    } as ConfirmModalProps);

    if (result !== ModalOptions.YES) return;

    setState(defaultSetting.preference);
    handleSubmit(defaultSetting.preference);
  };

  const validateSuggestedTotal = (state: PreferenceOption) => {
    const generatedTotal =
      state.suggestedTotalShorts +
      state.suggestedTotalClips +
      state.suggestedTotalSegments;

    if (generatedTotal > 10 || generatedTotal > state.clips) {
      customToast.error(
        'Number of clips selected exceeds total number of suggested clips to be generated.',
      );

      return false;
    }

    return true;
  };

  const handleChangeSuggestedNumber = (newSuggestedTotal: SuggestedTotal) => {
    setState({
      ...state,
      [newSuggestedTotal.inputName]: newSuggestedTotal.number,
    });
  };

  const handleBlurSuggestedNumber = () => {
    const newState = { ...state };

    const isValidSuggestedTotal = validateSuggestedTotal(newState);
    if (!isValidSuggestedTotal) return;

    setState(newState);
  };

  return (
    <>
      {(loading || tenantReducer.isLoading) && (
        <div
          className="loader__component"
          tw="height[calc(100vh - 13rem)] top[13rem] opacity-100"
        >
          <Loader />
        </div>
      )}
      <ResetBottomBar
        isShow={!shouldDisabledButton}
        onReset={handleReset}
        onSubmit={handleSubmit}
      />

      <ConfirmModal
        ref={confirmModalRef}
        title="Confirm"
        classes="disable-enable-user-modal-width"
      />

      <div tw="mb-20">
        <div className="flex-container fluid">
          <form className="preferences" onSubmit={e => e.preventDefault()}>
            <div className="flex-x preferences">
              <div className="btnReset">
                <button
                  type="button"
                  className={` success button apply`}
                  onClick={handleResetDefault}
                  disabled={shouldDisableReset}
                >
                  Reset
                </button>
              </div>
              <div className="cell">
                <div>
                  <div className="prefTitle" tw="flex items-center space-x-2">
                    <Memory css={iconStyle} />
                    <h3 tw="mb-0">Create Sonnant assets on upload</h3>
                  </div>
                  <div tw="flex">
                    <label
                      className="large-10 cell"
                      tw="flex[unset]! ml-20 max-w-full cursor-pointer w-auto"
                    >
                      <input
                        name="summary"
                        checked={state.summary}
                        onChange={handleCheckboxClick}
                        type="checkbox"
                      />
                      <span className="checkmark" style={{ top: '5px' }} />
                      <span>Summary</span>
                    </label>
                  </div>
                  <div tw="flex">
                    <div tw="flex flex-row">
                      <div tw="flex flex-col">
                        <label
                          className="cell"
                          tw="-mt-2 ml-20 max-w-full cursor-pointer"
                        >
                          <div tw="flex flex-row items-center justify-start">
                            <input
                              name="suggest"
                              checked={state.suggest}
                              onChange={handleCheckboxClick}
                              type="checkbox"
                            />
                            <span className="checkmark" tw="top[1rem]" />
                            Suggested clips #
                            <input
                              tw="(width[5rem] height[3rem] m-2)!"
                              name="clips"
                              type="number"
                              min="1"
                              step={1}
                              max="10"
                              value={state.clips}
                              onChange={handleNumChange}
                              onKeyPress={handleKeyPress}
                              onBlur={handleBlurNumChange}
                              className="keywordInput"
                            />
                            <span tw="width[7rem]">(Max. 10)</span>
                          </div>
                        </label>
                        <SuggestedItem
                          title={'Shorts #'}
                          hintText={'30 seconds to 1 minute duration'}
                          value={state.suggestedTotalShorts}
                          inputName={'suggestedTotalShorts'}
                          min={0}
                          step={1}
                          max={10}
                          onChangeNumber={handleChangeSuggestedNumber}
                          onBlur={handleBlurSuggestedNumber}
                        />
                        <SuggestedItem
                          title={'Clips #'}
                          hintText={'1 minute to 3 minutes duration'}
                          value={state.suggestedTotalClips}
                          inputName={'suggestedTotalClips'}
                          min={0}
                          step={1}
                          max={10}
                          onChangeNumber={handleChangeSuggestedNumber}
                          onBlur={handleBlurSuggestedNumber}
                        />
                        <SuggestedItem
                          title={'Segments #'}
                          hintText={'3+ minutes duration'}
                          value={state.suggestedTotalSegments}
                          inputName={'suggestedTotalSegments'}
                          min={0}
                          step={1}
                          max={10}
                          onChangeNumber={handleChangeSuggestedNumber}
                          onBlur={handleBlurSuggestedNumber}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cell">
                <div>
                  <div className="prefTitle" tw="flex items-center space-x-2">
                    <Movie css={iconStyle} />
                    <h3 tw="mb-0">Caption format for videos</h3>
                  </div>

                  <div tw="flex">
                    <label
                      className={`large-10 cell ${tier.isTrial && 'disabled'}`}
                      tw="ml-20 max-w-full flex[unset]! w-auto cursor-pointer"
                    >
                      <input
                        name="caption"
                        checked={state.caption}
                        onChange={handleCheckboxClick}
                        type="checkbox"
                      />
                      <span className="checkmark" style={{ top: '5px' }}></span>
                      Open captions (caption permanently burned into video)
                    </label>
                    {tier.isTrial && (
                      <Hint
                        text="Not available on free trial. Please upgrade to enable."
                        enterDelay={0}
                        arrow
                      >
                        <span
                          tw="cursor-pointer ml-4 mt-0.5"
                          className="questionsvg"
                        >
                          <QuestionSvg tw="width[1.6rem] height[1.6rem] relative top[2px]" />
                        </span>
                      </Hint>
                    )}
                  </div>
                </div>

                <div className="cell">
                  <div>
                    <div className="prefTitle" tw="flex items-center space-x-2">
                      <AutoSave css={iconStyle} />
                      <h3 tw="mb-0">Autosave</h3>
                    </div>

                    <div>
                      <label
                        className="large-7 cell"
                        tw="ml-20 max-w-full cursor-pointer"
                      >
                        <div tw="flex items-center">
                          <input
                            name="autoSave"
                            checked={state.autoSave}
                            onChange={handleCheckboxClick}
                            type="checkbox"
                          />
                          <span className="checkmark" tw="top[1rem]" />
                          Transcript will be automatically saved (After
                          <Hint text={'Number must be between 1 and 30'}>
                            <input
                              tw="(width[4rem] height[3rem] m-2 p-3 border-width[1px]! border[2px solid transparent])!"
                              css={[!state?.autoSave && tw`opacity-75`]}
                              name="autoSaveMinutes"
                              type="number"
                              min={1}
                              step={1}
                              max={30}
                              value={state.autoSaveMinutes}
                              onChange={handleTimeChange}
                              onKeyPress={handleKeyPress}
                              onBlur={setDefaultAutosave}
                              className="keywordInput"
                              required
                              disabled={!state?.autoSave}
                            />
                          </Hint>
                          minutes)
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="cell">
                  <div>
                    <div className="prefTitle" tw="flex items-center space-x-2">
                      <Visibility tw="mx-[8px]" />
                      <h3 tw="mb-0">Default transcript view mode</h3>
                    </div>

                    <div tw="flex">
                      <label
                        className={`large-10 cell ${
                          tier.isTrial && 'disabled'
                        }`}
                        tw="ml-20 max-w-full flex[unset]! w-auto cursor-pointer"
                      >
                        <input
                          name="paragraphMode"
                          checked={state?.paragraphMode}
                          onChange={handleCheckboxClick}
                          type="checkbox"
                        />
                        <span
                          className="checkmark"
                          style={{ top: '5px' }}
                        ></span>
                        Open in Paragraph mode
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <hr tw="max-w-full" />
              <div tw="pb-10">
                <SharingOptions
                  onStateChange={setState}
                  checkboxState={state}
                />

                <div>
                  <div className="prefTitle" tw="flex items-center space-x-2">
                    <Source css={iconStyle} />
                    <h3 tw="mb-0">Review / export keyword mentions</h3>
                  </div>

                  <label
                    className="large-4 cell"
                    tw="ml-20 max-w-full cursor-pointer"
                  >
                    <input
                      name="includeUrlCsv"
                      checked={state.includeUrlCsv}
                      onChange={handleCheckboxClick}
                      type="checkbox"
                    />
                    <span className="checkmark" style={{ top: '5px' }}></span>
                    Include clip URL in Excel export file
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const iconStyle = css`
  width: 40px;
`;
