/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { css } from '@emotion/react';

import {
  formatLibraryTitle,
  hasLockedByStatusText,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { isEmpty, isNil, toNumber } from 'lodash';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ColumnResizeMode,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { RootState } from 'reducers';
import { SortField, VideoStatusText } from 'utils/enum';
import { ILibraryItem } from 'utils/models';
import Loader from '../loader/loader';
import './Listview.scss';
import { ListViewRow } from './ListViewRow/ListViewRow';
import { useVirtual } from 'react-virtual';
import { ListViewHeader } from './ListViewHeader';

export const ListView = memo(function ListViewMemorized() {
  // #region SELECTORS
  const { loading } = useSelector((state: RootState) => state.library);
  const rawLibrary = useSelector(
    (state: RootState) => state.library?.data?.library,
  );
  const library = formatLibraryTitle(rawLibrary);
  const [sorting] = useState<SortingState>([]);
  const [columnResizeMode] = useState<ColumnResizeMode>('onChange');

  // Change selected status in the LIBRARY

  const columns: any = useMemo(
    () => [
      {
        header: 'TYPE',
        accessor: 'mediacontenttype',
        width: '3%',
        size: 70,
        minSize: 60,
        hint: 'Type of file',
        id: SortField.TYPE,
      },
      {
        header: 'TITLE',
        accessor: 'title',
        hint: 'File name',
        width: '5%',
        size: 480,
        minSize: 300,
        id: SortField.TITLE,
      },
      {
        header: 'DURATION',
        accessor: 'length',
        style: {
          textAlign: 'center',
        },
        size: 150,
        minSize: 100,
        hint: 'Length of file in format hh:mm:ss',
        id: SortField.DURATION,
      },
      {
        header: 'SPEAKERS',
        accessor: 'speakers',
        style: {
          textAlign: 'center',
        },
        size: 100,
        minSize: 100,
        hint: 'Number of identified speaker on file',
        id: SortField.SPEAKER,
      },
      {
        header: 'LAST MODIFIED',
        accessor: 'modifieddatetime',
        style: {
          textAlign: 'center',
        },
        hint: 'Date / Time file was last modified by user',
        size: 200,
        minSize: 150,
        id: SortField.MODIFIED,
      },
      {
        header: 'DATE ADDED',
        accessor: 'createddatetime',
        style: {
          textAlign: 'center',
        },
        size: 200,
        minSize: 150,
        hint: 'Date added to your Sonnant library',
        id: SortField.DATE_ADDED,
      },
      {
        header: 'STATUS',
        style: {
          textAlign: 'center',
        },
        size: 100,
        minSize: 100,
        accessor: (item: ILibraryItem) => {
          if (
            item.status === VideoStatusText.NOT_EDITED &&
            !isNil(item.publishcount) &&
            toNumber(item.publishcount) > 0
          ) {
            return '';
          }
          return item.status;
        },
        hint: 'What is currently happening with file in library',
        id: SortField.STATUS,
      },
      {
        header: 'LOCKED',
        style: {
          textAlign: 'center',
        },
        size: 100,
        minSize: 90,
        accessor: (item: ILibraryItem) => {
          if (hasLockedByStatusText(item?.status)) {
            return -1;
          } else return 0;
        },
        hint: 'Will be locked if being edited by another user',
        id: SortField.LOCKED,
      },
      {
        header: 'WORDS',
        accessor: 'words',
        style: {
          textAlign: 'center',
        },
        minSize: 90,
        hint: 'Number of spoken words recorded in file',
        id: SortField.WORD,
      },
      {
        header: 'CLIPS',
        accessor: 'publishcount',
        width: '5%',
        size: 100,
        minSize: 90,
        style: {
          textAlign: 'center',
        },
        hint: 'Number of shared clips that have been created from the original file',
        id: SortField.CLIP,
      },
      {
        header: 'VIEWS',
        accessor: 'viewed',
        width: '5%',
        style: {
          textAlign: 'center',
        },
        size: 100,
        minSize: 90,
        hint: 'Total number of views for all shared items',
        id: SortField.VIEWS,
      },
      {
        header: 'CREATED BY',
        style: {
          textAlign: 'center',
        },
        accessor: 'userid',
        size: 160,
        minSize: 110,
        hint: 'Name of user who uploaded the file',
        id: SortField.CREATED_BY,
      },
    ],
    [],
  );

  const [libraryMemo, setLibraryMemo] = useState(
    useMemo(() => library, [library]),
  );

  useEffect(() => {
    setLibraryMemo(formatLibraryTitle(rawLibrary));
  }, [rawLibrary]);

  let table = useReactTable({
    columns,
    data: libraryMemo as ILibraryItem[],
    state: {
      sorting,
    },
    columnResizeMode,

    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // debugTable: true,
  });

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 0, // number of items renders above and below the visible area
  });

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  if (!loading && isEmpty(library)) {
    return null;
  }

  return loading ? (
    <div className="loader__component">
      <Loader />
    </div>
  ) : (
    <div className="listViewTable">
      <div
        ref={tableContainerRef}
        tw="min-height[calc(100vh - 230px)] height[200px] overflow-auto md-down:overflow-x-auto scrollbar-width[thin]"
        css={[listViewTableStyle]}
      >
        <table
          style={{ width: table.getCenterTotalSize() }}
          tw="leading-9 md-down:min-width[1440px]"
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup, groupIndex: number) => (
              <tr key={groupIndex} className="action-item">
                <th className="action-item" tw="min-width[40px] width[2%]"></th>
                {headerGroup.headers.map((header, headerIndex: number) => (
                  <ListViewHeader
                    key={headerIndex}
                    header={header}
                    index={headerIndex}
                  />
                ))}

                <th tw="min-width[50px] width[5%]"></th>
              </tr>
            ))}
          </thead>

          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}

            {virtualRows.map((virtualRow, index) => {
              const row = rows[virtualRow.index] as Row<ILibraryItem>;

              return (
                <ListViewRow
                  index={virtualRow.index}
                  key={virtualRow.index}
                  itemRow={row}
                />
              );
            })}

            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
});

const listViewTableStyle = css`
  ::-webkit-scrollbar {
    width: 0px;
  }
`;
