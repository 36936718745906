import axios from 'axios';
import { getCurrentSubdomain } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { getToken, setIsLoggedIn, setToken } from '../utils/utils';

const PublicAPIConfig = () => {
  const defaultOptions = {
    baseURL: `${window.location.origin}/api/v1`,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': window.location.origin,
      'Domain-Name': getCurrentSubdomain(),
    },
    timeout: 5 * 60 * 1000, // 5 min
  };

  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use(function (config) {
    const token = getToken();
    config.headers.Authorization = `Bearer ${token?.AccessToken ?? ''}`;

    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (
        [401, 402, 403].includes(error?.response?.status) &&
        error?.response?.config?.url !== '/auth/refresh-token' &&
        !originalRequest?._retry
      ) {
        originalRequest._retry = true;
        const token = JSON.parse(localStorage.getItem('token') as string);

        try {
          const response = await instance.post('/auth/refresh-token', {
            refreshToken: token?.RefreshToken,
            idToken: token?.IdToken,
          });

          setToken(response.data);
          setIsLoggedIn();

          originalRequest.headers.Authorization = response.data.AccessToken;
          return instance(originalRequest);
        } catch (err: any) {
          localStorage.removeItem('token');
          localStorage.removeItem('isLoggedIn');
          window.location.assign('/signin');
        }
      } else {
        error.message = 'Something went wrong! Please try again later.';
      }
      return Promise.reject(error);
    },
  );

  return instance;
};

export const PublicAPI = PublicAPIConfig();
