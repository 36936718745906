/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Modal } from 'components/UiControls/modal/modal';
import { CustomTermType, Keys, ModalOptions } from 'utils/enum';
import CloseSVG from 'components/UiControls/closeButtonSVG/closeSVG';
import { ReactComponent as Search } from 'assets/Icons/search.svg';
import { ReactComponent as TagSvg } from 'assets/Icons/tag_24dp.svg';
import { noneBorder, hoverDropShadow } from '../twin.styles';
import { CustomTerm, Term } from 'utils/models';
import { TermItem } from './TermItem';
import {
  appendMultipleAppliedList,
  appendMultipleTerms,
  exportCSV,
  extractValidEmails,
  processTerms,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { Hint } from '../Hint';
import { cloneDeep, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFocusCustomList,
  setTermByListId,
  updateItemCustomList,
} from 'slices/global.slice';
import { getDateExport, toDateTz, toDateWithHours } from 'utils/date.util';
import { RootState } from 'reducers';
import { useToggle } from 'react-use';
import { tierSelector } from 'slices/payment.slice';
import { QuestionTooltipHint } from '../Tooltip/QuestionTooltipHint';
import { customToast } from 'utils/toast.util';
import { RecipientsSettings } from '../RecipientsSettings/RecipientsSettings';
import { toggleAddEditModal } from 'slices/toggle.slice';
import { VocabEditablePayload } from 'utils/models/modal.model';

interface IPromiseParams {
  resolve: (option?: any) => void;
  reject: (err: any) => void;
}

export interface TermModalResult {
  option: ModalOptions;
  payload: CustomTerm;
}

interface IProps {
  isAppliedList?: boolean;
}

export const AddEditTermModal = forwardRef(
  (props: IProps, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const customTerms = useSelector(
      (state: RootState) => state.global.customTerms,
    );
    const location = useSelector(
      (state: RootState) => state.userProfile.location,
    );
    const layerCustomTerms = useSelector(
      (state: RootState) => state.player.layerCustomTerms,
    );
    const watchListReducer = useSelector((state: RootState) => state.watchList);
    const { currentEditWatchList } = watchListReducer;

    const tier = useSelector(tierSelector);
    const player = useSelector((state: RootState) => state.player);

    const [searchTerm, setSearchTerm] = useState('');
    const [payload, setPayload] = useState<VocabEditablePayload>();
    const [termList, setTermList] = useState<Term[]>([]);
    const [newTerms, setNewTerms] = useState('');
    const [newTitle, setNewTitle] = useState('');
    const [isEditingTitle, setEditingTitle] = useToggle(false);

    const isEdit = payload?.mode === 'edit';

    const dispatch = useDispatch();

    const searchedTerms = termList.filter((term) =>
      term.name.toLowerCase().includes(searchTerm.trim().toLowerCase()),
    );

    const setNewAlter = (newAlter: Term[], id: string) => {
      const newTerm = cloneDeep(termList);
      const foundIndex = newTerm.findIndex((i) => i.id === id);
      if (foundIndex >= 0) {
        newTerm[foundIndex].alternatives = newAlter;
        setTermList(newTerm);
        dispatch(setTermByListId({ newTerm, id: payload?.item?.id! }));
      }
    };

    const [isOpen, setIsOpen] = useState(false);
    const email =
      currentEditWatchList?.recipients?.emailAddress
        ?.filter((i) => i)
        ?.join(', ') ?? '';

    const isWatchList = payload?.type === CustomTermType.WATCHLIST;

    useEffect(() => {
      dispatch(toggleAddEditModal(isOpen));
    }, [isOpen]);

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const placeHolder =
      payload?.type === CustomTermType.APPLIED
        ? 'Applied List'
        : isWatchList
        ? 'Watch List'
        : 'Custom Vocab';

    const show = async (payload: VocabEditablePayload): Promise<unknown> => {
      if (payload) {
        setPayload(payload);

        if (payload?.focusWatchList) {
          setTermList(payload?.focusWatchList?.terms ?? []);
          setNewTitle(payload?.focusWatchList?.name ?? '');
        } else {
          setTermList(payload?.item?.terms ?? []);
          setNewTitle(payload?.item?.name ?? '');
        }

        if (payload?.mode === 'add') {
          setEditingTitle(true);
        }
      }

      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const hideModal = (shouldTriggerCancel?: boolean) => {
      setIsOpen(false);
      if (shouldTriggerCancel) {
        promiseInfo.current?.resolve({
          option: ModalOptions.CANCEL,
        });
      }
    };

    const hasExistedName = (type: CustomTermType | undefined): boolean => {
      if (type === CustomTermType.APPLIED) {
        return player?.layerCustomTerms?.some((t) =>
          checkExisted(t.listName, t.listId),
        );
      }

      if (type === CustomTermType.WATCHLIST) {
        return watchListReducer.watchLists.some((t) =>
          checkExisted(t.name, t.id),
        );
      }

      return customTerms?.some((t) => checkExisted(t.name, t.id));
    };

    const checkExisted = (name: string, id: string): boolean => {
      if (isWatchList)
        return name === newTitle && id !== payload?.focusWatchList?.id;

      return name === newTitle && id !== payload?.item?.id;
    };

    const handleYes = async () => {
      let newPayload = {};

      if (['add', 'edit'].includes(payload?.mode ?? '')) {
        const existedName = hasExistedName(payload?.type);

        if (existedName) {
          customToast.error(
            <span>
              List named <b tw="break-all">{newTitle}</b> already exists. Please
              check and try again.
            </span>,
          );
          return;
        }
      }

      if (!newTitle) {
        customToast.error('Please enter a title');
        return;
      }

      let newTermList = termList;

      if (newTerms) {
        newTermList = addMultipleTerms();
        setNewTerms('');
      }

      if (isWatchList) {
        const validEmails: string[] = extractValidEmails(email);

        if (
          currentEditWatchList?.recipients?.hasEmailAddress &&
          isEmpty(validEmails)
        ) {
          customToast.error(
            'At least one valid email address is required. Please check and try again.',
          );
          return;
        }

        newPayload = {
          ...currentEditWatchList,
          recipients: {
            ...currentEditWatchList?.recipients,
            emailAddress: validEmails,
          },
          name: newTitle,
          terms: [...newTermList],
        };
      } else {
        newPayload = {
          ...payload?.item,
          name: newTitle,
          terms: newTermList,
        } as CustomTerm;
      }

      hideModal();
      promiseInfo.current?.resolve({
        option: ModalOptions.YES,
        payload: newPayload,
      });

      if (payload?.type !== CustomTermType.APPLIED) {
        dispatch(updateItemCustomList(newPayload as CustomTerm));
        dispatch(setFocusCustomList(newPayload as CustomTerm));
      }
    };

    const handleCancel = () => {
      hideModal(true);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
        addMultipleTerms();
        setNewTerms('');
      }
      if (e.key === Keys.ESC) {
        setNewTerms('');
      }
    };

    const addMultipleTerms = (terms?: string): Term[] => {
      let newTermsList;
      if (props?.isAppliedList) {
        newTermsList = appendMultipleAppliedList(
          termList,
          terms ?? newTerms,
          layerCustomTerms,
        );
      } else {
        newTermsList = appendMultipleTerms(
          //limit 5 terms
          termList,
          terms ?? newTerms,
          customTerms,
        );
        if (tier.isTrial && newTermsList.length > 5) {
          customToast.error(
            'Free Tier Accounts limited to 2 custom terms lists each of a maximum of 5 custom terms.',
          );
          return [];
        }
      }

      setTermList(newTermsList);

      return newTermsList;
    };

    const handleRemoveTerm = (term: Term) => {
      setTermList(termList.filter((t) => t.id !== term.id));
    };

    const handleUpdateTerm = (term: Term) => {
      const existedTerm = termList?.find(
        (t) => t?.name?.trim() === term?.name?.trim(),
      );

      if (existedTerm) {
        customToast.error(
          <>
            Term <strong>{term.name}</strong> already exists.
          </>,
        );
        return;
      }

      const foundIndex = termList.findIndex((t) => t?.id === term?.id);
      if (foundIndex < 0) return;

      const cloneTermList = [...termList];
      cloneTermList[foundIndex] = term;
      setTermList(cloneTermList);
    };

    const uploadCSV = (e: React.ChangeEvent<HTMLInputElement>) => {
      const uploadedFile = e.target.files?.[0];

      if (!uploadedFile || !uploadedFile.name.endsWith('.csv')) {
        customToast.error('Not supported file. Please try again.');
        return;
      }

      const reader = new FileReader();
      reader.onload = (event: any) => {
        const data = processTerms(event.target.result, termList);

        if (
          !props.isAppliedList &&
          !isWatchList &&
          tier.isTrial &&
          data.length > 5
        ) {
          const getAmountData = data.slice(0, 5);

          setTermList(getAmountData);

          e.target.value = '';
          customToast.error(
            'Free Tier Accounts limited to 2 custom terms lists each of a maximum of 5 custom terms.',
          );
          return;
        }

        if (!isWatchList && data.length > 1000) {
          const getAmountData = data.slice(0, 1000);

          setTermList(getAmountData);

          e.target.value = '';
          customToast.error('Reached limit of 1000 custom terms');
          return;
        }

        setTermList(data);
        e.target.value = '';
      };
      try {
        reader.readAsText(uploadedFile);
      } catch (error: any) {
        customToast.error('Invalid CSV file');
      }
    };

    const handleExportCSV = () => {
      exportCSV(termList, newTitle + `_${getDateExport(location)}.csv`);
    };

    const handleTitleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if ([Keys.ENTER, Keys.ESC, Keys.ENTER_NUMPAD].includes(e.key as Keys)) {
        if (!newTitle) return;
        setEditingTitle(false);
      }
    };

    // const shouldDisableSave = (): boolean => {
    //   if (payload?.type !== CustomTermType.WATCHLIST) return false;

    //   // if (payload?.mode === Mode.ADD) return false;

    //   const { me, allUsers, hasEmailAddress } = {
    //     ...currentEditWatchList?.recipients,
    //   };
    //   const validEmails: string[] = extractValidEmails(email);

    //   // At least one option checked
    //   const hasCheckedOption: boolean = Boolean(
    //     me || allUsers || hasEmailAddress,
    //   );

    //   return (
    //     !hasCheckedOption ||
    //     !!(hasCheckedOption && hasEmailAddress && isEmpty(validEmails))
    //   );
    // };

    return (
      <Modal
        show={isOpen}
        modalClosed={() => hideModal(true)}
        preventEsc={true}
      >
        <div className="userModal_Popus">
          <h1>
            {isEdit ? 'Edit' : 'Create'} {placeHolder}
          </h1>

          <div>
            <div tw="mt-4 mb-6 flex items-center line-height[1.5]">
              <div tw="width[70%]">
                {isEditingTitle ? (
                  <input
                    type="text"
                    tw="(text-15 font-medium mb-0 placeholder:(font-light text-14))!"
                    placeholder={`Give the ${placeHolder} a name`}
                    value={newTitle}
                    onChange={(e) => setNewTitle(e.target.value)}
                    onKeyDown={handleTitleKeyDown}
                    // onBlur={() => setEditingTitle(false)}
                    autoFocus
                  />
                ) : (
                  <Hint text="Double click to edit list name">
                    <div
                      tw="text-16 font-medium border[1px dotted lightgrey] py-2 px-3 rounded-lg"
                      onDoubleClick={() => setEditingTitle(true)}
                    >
                      {newTitle ?? ''}
                    </div>
                  </Hint>
                )}
              </div>
              <div tw="text-13 font-medium ml-6 text-sonnant-dark">
                {payload?.type !== CustomTermType.APPLIED && (
                  <div tw="flex w-full">
                    <div tw="whitespace-nowrap">
                      Terms: {termList.length ?? 0}
                    </div>
                    <div tw="mx-2.5">|</div>
                    <div tw="whitespace-nowrap">
                      Saved version: {payload?.item?.versionNo ?? 1}
                    </div>
                  </div>
                )}
                <div>
                  Date created:{' '}
                  {location &&
                    toDateWithHours(
                      toDateTz(
                        payload?.item?.dateCreated
                          ? new Date(payload.item.dateCreated)
                          : new Date(),
                        location,
                      ),
                    )}
                </div>
              </div>
            </div>
            <div tw="flex w-full justify-between">
              <div tw="flex-1 relative">
                <TagSvg tw="absolute top[1.2rem] left[1rem]" />
                <input
                  title={`Add terms to ${placeHolder} (use comma for multiple items)`}
                  type="text"
                  placeholder={`Add terms to ${placeHolder} (use comma for multiple items)`}
                  value={newTerms}
                  tw="(text-14 padding-left[3.2rem] padding-right[2.8rem] placeholder:(text-12) focus:(border-transparent))!"
                  onChange={(e) => setNewTerms(e.target.value)}
                  onKeyDown={handleKeyDown}
                  // autoFocus
                />
                <QuestionTooltipHint
                  customStyle={toolTipStyle}
                  contentWidth={isWatchList ? '32rem' : '50rem'}
                  message={
                    isWatchList ? (
                      <div>Watch list terms can be words or phrases.</div>
                    ) : payload?.type === CustomTermType.APPLIED ? (
                      <div>
                        Applied list terms can be words or phrases. The
                        transcript will be searched for these terms or
                        alternatives on save. If any occurrences are found the
                        term will be displayed in the layer.
                      </div>
                    ) : (
                      <div>
                        <p>
                          Custom vocab terms can be words or phrases. The
                          transcription engine will increase the probability of
                          these terms being found in the audio. The spelling and
                          capitalisation from the term will be used in the
                          transcript.
                        </p>

                        <p>
                          Sounds-like alternatives will be automatically added
                          to new terms where needed to improve accuracy.
                          Additional alternatives can also be manually added.
                          When an alternative is found in the transcript it will
                          be replaced with the term.
                        </p>

                        <div>
                          Phases over 64 characters will not be used by the
                          transcription engine, but will be used for spelling
                          and capitalisation and will also be used in an Applied
                          List if created for this Custom Vocab.
                        </div>
                      </div>
                    )
                  }
                />
              </div>
              <div>
                <span>
                  <input
                    id="Csv"
                    type="file"
                    accept=".csv"
                    tw="hidden"
                    onChange={uploadCSV}
                  />
                  <label htmlFor="Csv">
                    <Hint
                      text={
                        isWatchList
                          ? 'CSV must have no headings, use column A for term name.'
                          : 'CSV must have no headings, use column A for main item, column B as type identifier for your own uses and columns C-G for alternatives and have no more than 1000 terms'
                      }
                      align="bottom"
                    >
                      <div className="button btn-primary large" tw="mx-4">
                        Upload CSV
                      </div>
                    </Hint>
                  </label>
                </span>
              </div>
              <div className="search-container" tw="flex -mr-1">
                <div tw="flex relative">
                  <input
                    className="input-group-field input-search"
                    tw="(height[4rem] rounded-r-none rounded-l pl-4 pr-2 font-size[1.5rem] caret-color[#7F8090] placeholder:(text-13))!"
                    css={[noneBorder]}
                    type="input"
                    placeholder={`Search in ${placeHolder}`}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <button
                  tw="(rounded-l-none height[4rem] width[4rem] mb-0 rounded-r focus:(bg-white) hover:(border[2px solid #F0F3F6]) box-shadow[none])!"
                  className="button btn-secondary"
                >
                  {searchTerm ? (
                    <CloseSVG
                      close={() => setSearchTerm('')}
                      color="#7F8090"
                      css={hoverDropShadow(0.2)}
                    />
                  ) : (
                    <Search css={hoverDropShadow(0.2)} />
                  )}
                </button>
              </div>
            </div>
            <div tw="flex flex-wrap content-start w-full p-4 mt-4 mb-6 overflow-y-auto height[40rem] border[1px solid lightgrey] rounded-lg">
              {searchedTerms.map((term, index) => (
                <TermItem
                  key={term.id}
                  id={index}
                  term={term}
                  handleRemoveTerm={handleRemoveTerm}
                  handleUpdateTerm={handleUpdateTerm}
                  setNewAlter={setNewAlter}
                  shouldShowAlter={payload?.type === CustomTermType.APPLIED}
                />
              ))}
              {termList?.length === 0 && (
                <div tw="color[#54566C] text-2xl font-medium mb-6">
                  {`There are no terms in ${placeHolder}`}
                </div>
              )}
              {searchedTerms.length === 0 && termList?.length > 0 && (
                <div tw="color[#54566C] text-2xl font-medium mb-6">
                  No results found
                </div>
              )}
            </div>

            {isWatchList && <RecipientsSettings fromPanel={false} />}
          </div>

          <div className="grid-x" tw="mt-7">
            <div className="cell small-12">
              <div className="btn_section" tw="flex w-full justify-end">
                {isEdit && (
                  <button
                    type="button"
                    className="button btn-primary delete ml-0"
                    onClick={handleExportCSV}
                    disabled={!termList?.length}
                  >
                    Export CSV
                  </button>
                )}

                <div tw="flex flex-1 justify-end">
                  <button
                    type="button"
                    className="button cancel"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="button delete"
                    onClick={handleYes}
                    disabled={!newTitle?.trim()}
                  >
                    {isEdit ? 'Save' : 'Create'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);

const toolTipStyle = css`
  ${tw`absolute top[1rem] right[1rem]`}
`;
