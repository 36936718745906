/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { ReactComponent as TagSvg } from 'assets/Icons/tag_24dp.svg';
import { Modal } from 'components/UiControls/modal/modal';
import { processTerms } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { intersection, isEmpty, trim, uniqBy } from 'lodash';
import React, {
  forwardRef,
  KeyboardEvent,
  ReactElement,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { Keys, ModalOptions } from 'utils/enum';
import { CustomTerm, Term } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { v4 } from 'uuid';
import { CreateFromCustomVocabButton } from '../AppliedListModal/CreateFromCustomVocabButton';
import { TermItem } from '../CutomTermsModal/TermItem';
import { Hint } from '../Hint';
import { SearchInput } from '../SearchInput';
import { QuestionTooltipHint } from '../Tooltip/QuestionTooltipHint';

interface IPromiseParams {
  resolve: ({
    option,
    terms,
    reportName,
    isCombined,
  }: {
    option: ModalOptions;
    terms?: Term[];
    reportName?: string;
    isCombined?: boolean;
  }) => void;
  reject: (err: any) => void;
}

interface Props {
  isCollectionMentionExport?: boolean;
}

export const MultiMentionReportModal = forwardRef(
  (props: Props, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const global = useSelector((state: RootState) => state.global);
    const payment = useSelector((state: RootState) => state.payment);

    const [termsList, setTermsList] = useState<Term[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [newTerm, setNewTerm] = useState('');
    const [reportName, setReportName] = useState('Multi-item Mentions Report');

    const searchedTerms =
      termsList.filter(({ name }) =>
        name?.toLowerCase().includes(searchTerm?.trim().toLowerCase()),
      ) ?? [];

    const [isOpen, setIsOpen] = useState(false);

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const createTermByName = (termName: string) => ({
      id: v4(),
      name: termName.trim(),
      dateCreated: new Date(),
      fromCSV: false,
    });

    const show = async ({
      librarySearchTerm,
      defaultTitle,
      termArray,
      isMultiTerm,
    }: {
      librarySearchTerm: string;
      defaultTitle?: string;
      termArray?: string[];
      isMultiTerm?: boolean;
    }): Promise<unknown> => {
      // Auto added searchTerm in case of searching
      if (isMultiTerm) {
        const termSearchArray = termArray?.map(createTermByName) ?? [];

        setTermsList([...termsList, ...termSearchArray]);
      } else {
        const termSearch = createTermByName(librarySearchTerm);

        const newTermsList = [...termsList];

        if (!isEmpty(termSearch.name)) {
          newTermsList.push(termSearch);
        }

        setTermsList(newTermsList);
      }

      if (defaultTitle && !isEmpty(defaultTitle)) {
        setReportName(defaultTitle);
      }

      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const hideModal = () => {
      setIsOpen(false);
      resetState();
    };

    const resetState = () => {
      setTermsList([]);
      setSearchTerm('');
      setNewTerm('');
      setReportName('Multi-item Mentions Report');
    };

    const handleExportExcel = async () => {
      hideModal();
      promiseInfo.current?.resolve({
        option: ModalOptions.YES,
        terms: termsList,
        reportName: reportName,
      });
    };

    const handleCombineMultipleItems = (
      e: React.MouseEvent<HTMLButtonElement>,
    ) => {
      hideModal();
      if (payment.isExpiredTrial) {
        customToast.trialExpired();
        return;
      }
      promiseInfo.current?.resolve({
        option: ModalOptions.YES,
        terms: termsList,
        reportName: reportName,
        isCombined: true,
      });
    };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current?.resolve({ option: ModalOptions.CANCEL });
    };

    const handleCloseModal = () => {
      hideModal();
      promiseInfo.current?.resolve({ option: ModalOptions.CLOSE });
    };

    const handleSelectItem = (customVocab: CustomTerm) => {
      appendUniqueTerms(customVocab?.terms ?? []);
    };

    const appendUniqueTerms = (terms: Term[], hasToast = false) => {
      if (isEmpty(terms)) return;

      // Default OFF
      if (hasToast) {
        const duplicatedTerms = intersection(
          termsList.map((t) => t?.name),
          terms.map((t) => t?.name),
        );

        if (!isEmpty(duplicatedTerms)) {
          customToast.error(
            <div>
              <b>{duplicatedTerms.join(', ')}</b> already existed
            </div>,
          );
        }
      }

      setTermsList((oldTerms) => uniqBy([...oldTerms, ...terms], 'name'));
    };

    const handleInputAddTerm = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
        addMultipleTerms();
        setNewTerm('');
      }
      if (e.key === Keys.ESC) {
        setNewTerm('');
      }
    };

    const addMultipleTerms = () => {
      // Pre-process terms into array of Term
      const termsByComma = newTerm.trim().split(',').map(trim);

      const manualTerms: Term[] = termsByComma
        .filter((t) => !!t?.trim())
        .map((term) => ({
          id: v4(),
          name: term,
          dateCreated: new Date(),
          fromCSV: false,
        }));

      appendUniqueTerms(manualTerms, true);
    };

    const uploadCSV = (e: React.ChangeEvent<HTMLInputElement>) => {
      const uploadedFile = e.target.files?.[0];

      if (!uploadedFile || !uploadedFile.name.endsWith('.csv')) {
        customToast.error(
          'Not supported file type. Please check and try again.',
        );
        return;
      }

      const reader = new FileReader();
      reader.onload = (event: any) => {
        const csvTerms = processTerms(event.target.result, []);

        appendUniqueTerms(csvTerms);
        e.target.value = '';
      };

      try {
        reader.readAsText(uploadedFile);
      } catch (error: any) {
        customToast.error(
          'Invalid CSV file format. Please check and try again.',
        );
      }
    };

    const handleRemoveTerm = (term: Term) => {
      if (!term) return;

      setTermsList((oldTerms) => oldTerms.filter((t) => t?.id !== term?.id));
    };

    return (
      <Modal show={isOpen} modalClosed={handleCloseModal}>
        <div>
          <h1 tw="font-semibold text-sonnant-dark font-size[3.5rem]">
            Create Mentions Report
          </h1>

          {/* SEARCH BOX */}
          <div>
            <div tw="mt-4 mb-6 flex items-center line-height[1.5]">
              <div tw="width[70%]">
                <input
                  type="text"
                  tw="(text-15 font-medium mb-0 placeholder:(font-light text-14))!"
                  css={[!reportName?.trim() && reportNameCSS]}
                  placeholder="Give the report a name"
                  value={reportName}
                  onChange={(e) => setReportName(e.target.value)}
                  tabIndex={1}
                  autoFocus
                />
              </div>
              <div tw="ml-5 flex-1">
                <SearchInput
                  noShrink
                  setTerm={setSearchTerm}
                  placeholder="Search in List"
                />
              </div>
            </div>

            {/* ADD TERMS INPUT */}
            <div tw="flex w-full justify-between">
              <div tw="flex-1 relative">
                <TagSvg tw="absolute top[1.2rem] left[1rem]" />
                <input
                  type="text"
                  placeholder="Add terms to List (use comma for multiple items)"
                  tw="(text-14 padding-left[3.2rem] padding-right[3.2rem] placeholder:(text-12) focus:(border-transparent))!"
                  value={newTerm}
                  onChange={(e) => setNewTerm(e.target.value)}
                  onKeyDown={handleInputAddTerm}
                  tabIndex={2}
                />
                <QuestionTooltipHint
                  customStyle={toolTipStyle}
                  contentWidth="50rem"
                  message={
                    <div>
                      <ul>
                        <li>
                          A report name is required. This will be used as the
                          name of the Excel file created.
                        </li>
                        <li>
                          Mentions Report terms can be words or phrases
                          separated by commas.
                        </li>
                        <li>Duplicated terms will be ignored.</li>
                      </ul>
                    </div>
                  }
                />
              </div>

              {/* UPLOAD BUTTON */}
              <div>
                <span>
                  <input
                    id="Csv"
                    type="file"
                    accept=".csv"
                    tw="hidden"
                    onChange={uploadCSV}
                  />
                  <label htmlFor="Csv">
                    <Hint
                      text="CSV must have no headings, use column A for main item, column B as type identifier for your own uses"
                      align="bottom"
                    >
                      <div className="button btn-primary large" tw="mr-4 ml-8">
                        Upload CSV
                      </div>
                    </Hint>
                  </label>
                </span>
              </div>

              {/* ADD FROM CUSTOM VOCAB BUTTON */}
              <div tw="ml-1">
                <CreateFromCustomVocabButton
                  onSelectItem={handleSelectItem}
                  disabled={!global?.customTerms?.length}
                />
              </div>
            </div>

            {/* <div tw="whitespace-nowrap mt-0">
              {searchedTerms?.length > 0 && (
                <Info text={`Terms in List: ${searchedTerms?.length}`} />
              )}
            </div> */}

            <div tw="flex flex-wrap content-start w-full p-4 mt-4 mb-6 overflow-y-auto height[40rem] border[1px solid lightgrey] rounded-lg">
              {searchedTerms.map((term, index) => (
                <TermItem
                  key={term.id}
                  id={index}
                  term={term}
                  handleRemoveTerm={handleRemoveTerm}
                  shouldShowAlter={false}
                />
              ))}
              {termsList?.length === 0 && (
                <div tw="color[#54566C] text-2xl font-medium mb-6">
                  There are no terms in List
                </div>
              )}
              {searchedTerms.length === 0 && termsList?.length > 0 && (
                <div tw="color[#54566C] text-2xl font-medium mb-6">
                  No results found
                </div>
              )}
            </div>
          </div>

          {/* ACTION BUTTONS */}
          <div className="grid-x">
            <div className="cell small-12" tw="flex">
              <button
                type="button"
                className="button btn-secondary large"
                tw="mr-6 hover:(border-sonnant-grey-3!)"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <div className="btn_section" tw="w-full flex justify-end">
                {!props?.isCollectionMentionExport && (
                  <button
                    type="button"
                    className="button btn-primary large"
                    onClick={handleCombineMultipleItems}
                    tw="(whitespace-nowrap width[unset] mr-4)!"
                    disabled={!reportName?.trim() || termsList?.length === 0}
                  >
                    Combined Clip
                  </button>
                )}
                <button
                  type="button"
                  className="button btn-primary large"
                  onClick={handleExportExcel}
                  disabled={!reportName?.trim() || termsList?.length === 0}
                >
                  Export to Excel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);

const toolTipStyle = css`
  ${tw`absolute top[1rem] right[1rem]`}
`;

const reportNameCSS = css`
  :not(:focus) {
    ${tw`(border-red-500)!`}
  }
`;
