import { BaseAPI } from 'apis/amazon';
import { AxiosResponse } from 'axios';
import {
  PreviewRawResponse,
  RssFeed,
  RSSResponse,
  UploadS3Response,
  YoutubeChannelExtractor,
  YoutubePreviewResponse,
} from 'utils/models';
import { RssMeta } from './../utils/models/media.model';

export type UploadLocalPayload = {
  filename: string;
  mediatype: string;
  title: string;
  extension: string;
  size: number;
  collection_id?: string;
};

export type UploadUrlPayload = {
  media_url?: string;
  media_urls?: string[];
  collection_id?: string;
  is_from_channel?: boolean;
};

export type UploadThumbnailPayload = {
  mediaid: string;
  versioncount: string;
  timethumbnail: number;
  type: 'CLIP' | 'LIBRARY';
  imageBase64?: string;
};

export type RssUploadPayload = {
  feeds: RssFeed[];
  meta: RssMeta;
  rawURL: string;
  isSubscribed: boolean;
  collection_id?: string;
  is_all?: boolean;

  startDate?: string;
  endDate?: string;
};

export type OmnyPreviewProgramResponse = {
  artwork_url: string;
  categories: string[];
  description: string;
  id: string;
  name: string;
  slug: string;
};

export type OmnyPreviewPlaylistResponse = {
  id: string;
  title: string;
  image_public_url: string;
  program_id: string;
};

export type GetOmnyPreviewResponse = {
  programs: OmnyPreviewProgramResponse[];
  playlists: OmnyPreviewPlaylistResponse[];
};

export type OmnyPreviewResponse = {
  clips: PreviewRawResponse[];
  cursor: number;
  total_count: number;
};

export type OmnyUploadPayload = {
  apiKey: string;
  programId: string;
  playlistId: string | null;
  collectionId: string | null;
  clipIds: string[];
  isUploadAll?: boolean;

  startDate?: string;
  endDate?: string;
};

export type MegaphoneUploadPayload = {
  apiKey: string;
  networkId: string;
  programId: string;
  collectionId: string | null;
  clipIds: string[];
  isUploadAll?: boolean;
};

export type OmnyPreviewProgramPayload = {
  apiKey: string;
  programId: string | null;
  playlistId: string | null;
  cursor: number;

  startDate?: Date | string | null;
  endDate?: Date | string | null;
};

export type MegaphonePreviewProgramPayload = {
  apiKey: string;
  networkId: string;
  programId: string;
  cursor: number;
};

export type RssPreviewProgramPayload = {
  url: string;
  page?: number;

  startDate?: Date | string | null;
  endDate?: Date | string | null;
};

export const UploadService = {
  // POST /upload
  uploadLocalFile: (
    payload: UploadLocalPayload,
  ): Promise<AxiosResponse<UploadS3Response>> => {
    return BaseAPI.post('/upload', payload);
  },

  // POST /upload/url
  uploadUrl: (payload: UploadUrlPayload): Promise<AxiosResponse<string>> => {
    return BaseAPI.post('/upload/url', payload);
  },

  // GET /upload/rss
  previewRss: (
    payload: RssPreviewProgramPayload,
  ): Promise<AxiosResponse<RSSResponse>> => {
    return BaseAPI.get('/upload/rss', {
      params: {
        url: payload.url,
        page: payload.page,
        startDate: payload.startDate,
        endDate: payload.endDate,
      },
    });
  },

  // GET /upload/url?channel_id=:channelId
  previewYoutubeChannel: (
    channelInfo: YoutubeChannelExtractor,
  ): Promise<AxiosResponse<YoutubePreviewResponse>> => {
    return BaseAPI.get('/upload/url', {
      params: {
        channel_id: channelInfo?.channelId || undefined,
        channel_name: channelInfo?.channelName || undefined,
      },
    });
  },

  // POST /upload/rss
  uploadRss: ({
    feeds,
    meta,
    rawURL,
    isSubscribed,
    collection_id,
    is_all,
    startDate,
    endDate,
  }: RssUploadPayload): Promise<AxiosResponse<void>> => {
    return BaseAPI.post('/upload/rss', {
      episodes: feeds,
      meta,
      rawURL,
      isSubscribed: isSubscribed ? 1 : 0,
      collection_id,
      is_all: is_all || undefined,
      startDate,
      endDate,
    });
  },

  // POST /upload/thumbnail
  uploadThumbnail: (
    payload: UploadThumbnailPayload,
  ): Promise<AxiosResponse<string>> => {
    return BaseAPI.post('/upload/thumbnail', payload);
  },

  // GET /upload/omny/preview
  getProgramIdOmny: (
    orgid: string,
  ): Promise<AxiosResponse<GetOmnyPreviewResponse>> => {
    return BaseAPI.get('/upload/omny/preview', {
      params: {
        orgid,
      },
    });
  },

  // POST /upload/omny/preview
  previewOmnyProgram: (
    payload: OmnyPreviewProgramPayload,
  ): Promise<AxiosResponse<OmnyPreviewResponse>> => {
    return BaseAPI.post('/upload/omny/preview', {
      programId: payload.programId ?? undefined,
      playlistId: payload.playlistId ?? undefined,
      apiKey: payload.apiKey,
      cursor: payload.cursor,

      startDate: payload.startDate,
      endDate: payload.endDate,
    });
  },

  // POST /upload/megaphone/preview
  previewMegaphoneProgram: (
    payload: MegaphonePreviewProgramPayload,
  ): Promise<AxiosResponse<OmnyPreviewResponse>> => {
    return BaseAPI.post('/upload/megaphone/preview', {
      programId: payload.programId,
      networkId: payload.networkId,
      apiKey: payload.apiKey,
      cursor: payload.cursor,
    });
  },

  postUploadOmny: (
    payload: OmnyUploadPayload,
  ): Promise<AxiosResponse<string>> => {
    return BaseAPI.post('/upload/omny', {
      api_key: payload.apiKey,
      program_id: payload.programId,
      playlist_id: payload.playlistId ?? undefined,
      collection_id: payload.collectionId,
      clip_ids: payload.clipIds,
      // Ignore field if not TRUE
      is_all: payload.isUploadAll || undefined,

      startDate: payload.startDate,
      endDate: payload.endDate,
    });
  },

  // POST /upload/megaphone/url
  postUploadMegaphone: (
    payload: MegaphoneUploadPayload,
  ): Promise<AxiosResponse<string>> => {
    return BaseAPI.post('/upload/megaphone/url', {
      api_key: payload.apiKey,
      program_id: payload.programId,
      network_id: payload.networkId,
      collection_id: payload.collectionId,
      clip_ids: payload.clipIds,
      // Ignore field if not TRUE
      is_all: payload.isUploadAll || undefined,
    });
  },
};
