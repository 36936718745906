/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { HTMLAttributes, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';

import { HINT_NO_EDITING } from 'utils/constants';
import { Hint } from './Hint';

type Props = {
  children: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const HintRestrictedEdit = ({ children, ...props }: Props) => {
  const global = useSelector((state: RootState) => state.global);

  const hasPermissionEdit = !!global.currentUserGroup?.isEnabledEdit;

  if (hasPermissionEdit) return <>{children}</>;

  return (
    <Hint
      text={HINT_NO_EDITING}
      enterDelay={100}
      arrow={false}
      notTransparent={false}
    >
      <div
        css={[
          tw`flex cursor-not-allowed text-disabled bg-transparent opacity-40`,
        ]}
      >
        <div
          css={[
            tw`w-full opacity[0.9] pointer-events-none filter[grayscale(1)]`,
          ]}
          {...props}
        >
          {children}
        </div>
      </div>
    </Hint>
  );
};
