/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { loadStripe } from '@stripe/stripe-js/pure';
import { ReactComponent as CheckSvg } from 'assets/Icons/check-circle-24.svg';
import { ReactComponent as HistorySvg } from 'assets/Icons/Time.svg';
import { ReactComponent as UncheckSvg } from 'assets/Icons/x-cancel-red.svg';
import { config } from 'components/config';
import { Loader } from 'components/loader/loader';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { BuyUserModal } from 'components/shared/PaymentModal/BuyUserModal';
import {
  getDaysRemaining,
  isSingular,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { CustomMessage } from 'hooks/usePaymentMessage';
import { capitalize, toNumber } from 'lodash';
import React, { useRef } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { RootState } from 'reducers';
import { balanceSelector, tierSelector } from 'slices/payment.slice';
import { isInFuture, timestampToDate } from 'utils/date.util';
import { ModalOptions, SubscriptionEnum } from 'utils/enum';
import { IBuyUserResult, ISubscription } from 'utils/models/payment.model';
import '../Billing/Billing.scss';
import {
  descriptionStyle,
  emptyRow,
  headerTitle,
  iconStyle,
  notHover,
  sectionTitle,
  tableStyle,
} from './billing.twin';
import { HistoryDetailsModal } from './HistoryDetailsModal';
import { BillingService, CheckoutResponse } from 'services';
import { AxiosResponse } from 'axios';
import { customToast } from 'utils/toast.util';
import { ConfirmModalProps } from 'utils/models';

export const Billing = () => {
  const { remainMinutes } = useSelector(balanceSelector);
  const tier = useSelector(tierSelector);
  const { subscription, balance } = useSelector(
    (state: RootState) => state.payment,
  );
  const location = useSelector(
    (state: RootState) => state.userProfile.location,
  );
  const dispatch = useDispatch();

  const buyUserRef = useRef<any>(null);
  const cancelPlanRef = useRef<any>();
  const historyModalRef = useRef<any>();

  const [isCallingStripe, toggleCallingStripe] = useToggle(false);

  const onBuyClick = async (planUpgrade: string, options?: IBuyUserResult) => {
    try {
      toggleCallingStripe(true);

      const body: any = { plan: planUpgrade };
      if (planUpgrade === 'Standard') {
        body.quantity = options?.userQuantity ?? 1;
        body.isAnnual = options?.isAnnual ?? false;
      }

      let response: AxiosResponse<CheckoutResponse>;

      if (planUpgrade === 'PAYG') {
        response = await BillingService.checkoutPAYG(body);
      } else if (planUpgrade === 'Standard') {
        response = await BillingService.checkoutStandard(body);
      } else {
        return;
      }

      toggleCallingStripe(false);
      const data = response.data;
      if (data?.upgrade_success) {
        toast(() => (
          <CustomMessage
            type="update"
            plan="standard"
            users={options?.userQuantity}
          />
        ));
        dispatch({ type: 'GET_PROFILES' });
      } else {
        const stripeE = await loadStripe(config.STRIPE_P_KEY);
        await stripeE?.redirectToCheckout({
          sessionId: data.sessionId,
        });
      }
    } catch (error: any) {
      toggleCallingStripe(false);
      customToast.error('Something went wrong');
    }
  };

  const redirectPaymentHistory = async () => {
    try {
      toggleCallingStripe(true);
      const { data: stripeHistory } =
        await BillingService.getTransactionHistory();
      toggleCallingStripe(false);

      if (stripeHistory.success === true) {
        window.open(stripeHistory?.url);
      }
    } catch {
      toggleCallingStripe(false);
      customToast.error('No history records found!');
    }
  };

  const clickPlanPAYG = async () => {
    const isDowngrade = tier.isEnterprise || tier.isStandard;

    if (isDowngrade) {
      const result = await cancelPlanRef.current?.show({
        title: 'Cancel plan',
        confirmText: 'Confirm',
        message: (
          <>
            <div>
              Are you sure to cancel current plan{' '}
              <b>({capitalize(tier.currentPlan)})</b>?
            </div>
            <div>
              Your plan will remain active until{' '}
              <span tw="font-medium">
                {timestampToDate(subscription?.current_period_end, location)}
              </span>
            </div>
          </>
        ),
      } as ConfirmModalProps);

      if (result === ModalOptions.YES) {
        // Calling downgrade API here
        toggleCallingStripe(true);
        try {
          const res = await BillingService.cancelSubscription();
          if (res.data.success === true) {
            customToast.success(
              `Your plan will be canceled on  ${res.data.period_end}`,
            );
            dispatch({ type: 'GET_PROFILES' });
          }
        } catch {
          customToast.error('Something went wrong');
        } finally {
          toggleCallingStripe(false);
        }
      }
      return;
    }

    onBuyClick('PAYG');
  };

  const clickPlanStandard = async () => {
    const result: IBuyUserResult = await buyUserRef.current?.show();

    // const isUpgrade = tier.isTrial || tier.isPAYG;
    // const isDowngrade = tier.isEnterprise;
    // Upgrade / Downgrade

    if (result.option === ModalOptions.YES) {
      onBuyClick('Standard', {
        userQuantity: result.userQuantity,
        isAnnual: result.isAnnual,
      });
    }
  };

  const clickPlanEnterprise = () => {
    window.open('https://sonnant.com/contact');
  };

  const getTextPAYG = () => {
    if (tier.isStandard || tier.isEnterprise) {
      return 'Downgrade';
    }

    return 'Buy hours';
  };

  const getTextStandard = () => {
    if (tier.isEnterprise) {
      return 'Downgrade';
    } else if (tier.isStandard && !isPlanCancelled()) {
      return 'Add users';
    } else if (isPlanCancelled()) {
      return 'Pending';
    }

    return 'Subscribe';
  };

  const getTextEnterprise = () => {
    return 'Contact us';
  };

  const isPlanCancelled = (): boolean => {
    const { cancel_at_period_end, tenantplan, current_period_end } = {
      ...subscription,
    } as ISubscription;

    return (
      tenantplan === SubscriptionEnum.STANDARD &&
      cancel_at_period_end === true &&
      isInFuture(current_period_end ?? 0)
    );
  };

  const handleViewHistory = () => {
    historyModalRef.current?.show();
  };

  const getUsageToBilled = () => {
    if (tier.isTrial || tier.isPAYG) return 0;

    const remainAmount = toNumber(subscription?.total_usage);
    return remainAmount < 0 ? 0 : remainAmount;
  };

  return (
    <div className="grid-container fluid billing-wrapper">
      {isCallingStripe && (
        <div className="loader__component">
          <Loader />
        </div>
      )}

      <div tw="mt-8">
        <div tw="w-full flex justify-between items-center">
          <h1 tw="font-bold pl-6">Transcription time</h1>
        </div>
        {(tier.isStandard || tier.isEnterprise) && !subscription.isLoading ? (
          <div>
            <div tw="mt-8 flex justify-around">
              <div>
                Archive Minutes Remaining:{' '}
                <span tw="font-semibold text-sonnant-dark">
                  {toNumber(subscription?.balanceminutes)}{' '}
                  {isSingular(toNumber(subscription?.balanceminutes))
                    ? 'minute'
                    : 'minutes'}
                </span>
              </div>

              <div>
                Monthly Minutes Remaining:{' '}
                <span tw="font-semibold text-sonnant-dark">
                  {subscription?.free_tier_minutes}{' '}
                  {isSingular(subscription?.free_tier_minutes)
                    ? 'minute'
                    : 'minutes'}
                </span>
              </div>

              {!tier.isEnterpriseUnlimited && (
                <div>
                  User licenses:{' '}
                  <span tw="font-semibold text-sonnant-dark">
                    {subscription?.licensedusers}{' '}
                    {isSingular(subscription?.licensedusers) ? 'user' : 'users'}{' '}
                  </span>
                </div>
              )}

              <div>
                This month’s usage (to be billed):{' '}
                <span tw="font-semibold text-sonnant-dark">
                  {getUsageToBilled()}{' '}
                  {isSingular(getUsageToBilled()) ? 'minute' : 'minutes'}
                </span>{' '}
                <span
                  tw="text-sonnant-purple-2 font-medium text-2xl cursor-pointer hover:(underline)"
                  onClick={handleViewHistory}
                >
                  (View details)
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div tw="mt-8 flex justify-around">
            {subscription.isLoading ? (
              <div tw="width[5rem]">
                <Loader />
              </div>
            ) : (
              <>
                <div>
                  Included time remaining:{' '}
                  <span tw="font-semibold text-sonnant-dark">
                    {remainMinutes} minutes
                  </span>
                </div>
                <div>
                  This month’s usage (to be billed):{' '}
                  <span tw="font-semibold text-sonnant-dark">{0} minutes</span>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {tier.isEnterpriseTrial && (
        <div tw="(margin-top[2rem] margin-bottom[3rem])!">
          <h1 tw="font-bold pl-6">Enterprise Trial</h1>
          <div tw="flex justify-around">
            <div>
              Included days remaining:{' '}
              <span tw="font-semibold text-sonnant-dark">
                {getDaysRemaining(balance.remaining_days ?? 0)} days
              </span>
            </div>
            <div tw="width[37rem]" />
          </div>
        </div>
      )}

      <div tw="mt-14 mb-8">
        <div tw="w-full flex justify-between items-center">
          <h1 tw="font-bold pl-6">Billing</h1>
          <button
            className="button btn-primary large"
            tw="ml-6 flex justify-between items-center whitespace-nowrap width[unset]! px-4!"
            onClick={redirectPaymentHistory}
          >
            <HistorySvg css={iconStyle} tw="mr-3" />
            Payment history
          </button>
        </div>

        <table css={tableStyle}>
          <tr tw="sticky top-0 z-index[1]">
            <th></th>
            <th>
              Free Trial
              {tier.isTrial && <ActiveBadge />}
            </th>
            <th>
              PAYG
              {tier.isPAYG && <ActiveBadge />}
            </th>
            <th>
              Standard
              {tier.isStandard && <ActiveBadge />}
            </th>
            <th>
              Enterprise
              {tier.isEnterprise && <ActiveBadge />}
            </th>
          </tr>

          <tr css={notHover}>
            <td></td>
            <td tw="width[calc(25vw - 103px)]!" css={descriptionStyle}>
              Give it a try, save time, be amazed
            </td>
            <td tw="width[calc(25vw - 103px)]!" css={descriptionStyle}>
              Pay as you go, occasional use
            </td>
            <td tw="width[calc(25vw - 103px)]!" css={descriptionStyle}>
              {isPlanCancelled() ? (
                <span tw="text-sonnant-red font-medium">
                  <div>Your plan will remain active until</div>
                  {timestampToDate(subscription?.current_period_end, location)}
                </span>
              ) : (
                'Multiple users, regular use or Professional'
              )}
            </td>
            <td tw="width[calc(25vw - 103px)]!" css={descriptionStyle}>
              Large volumes, API access, custom billing
            </td>
          </tr>
          <tr css={notHover}>
            <td></td>
            <td></td>
            <td tw="pb-8">
              <BillingButton
                onClick={clickPlanPAYG}
                disabled={
                  isPlanCancelled() ||
                  tier.isEnterprise ||
                  !subscription?.tenantplan
                }
              >
                {getTextPAYG()}
              </BillingButton>
            </td>
            <td tw="pb-8">
              <BillingButton
                onClick={clickPlanStandard}
                disabled={
                  isPlanCancelled() ||
                  tier.isEnterprise ||
                  !subscription?.tenantplan
                }
              >
                {getTextStandard()}
              </BillingButton>
            </td>
            <td tw="pb-8">
              <BillingButton tw="pb-6!" onClick={clickPlanEnterprise}>
                {getTextEnterprise()}
              </BillingButton>
            </td>
          </tr>
          <tr>
            <td>Transcription Pricing ($AUD, ex-GST)</td>
            <td tw="font-semibold">N/A</td>
            <td tw="font-semibold">$10 / hour</td>
            <td tw="font-semibold">$0.12 / minute ($7.20 / hour)</td>
            <td tw="font-semibold">Contact us</td>
          </tr>
          <tr>
            <td>User Pricing ($AUD, ex-GST)</td>
            <td></td>
            <td></td>
            <td tw="font-semibold">$20 / user / month*</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td tw="font-semibold text-sonnant-dark">
              *Save 25% if billed annually
            </td>
            <td></td>
          </tr>
          <tr css={emptyRow}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td css={sectionTitle}>INCLUDED</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Included transcription hours</td>
            <td>1 hour</td>
            <td>As purchased</td>
            <td>1 hour / user / month</td>
            <td>Based on requirement</td>
          </tr>
          <tr>
            <td>Extra transcription hours</td>
            <td>No</td>
            <td>PAYG</td>
            <td>End of month billing</td>
            <td>Invoiced</td>
          </tr>
          <tr>
            <td>Users</td>
            <td>1</td>
            <td>1</td>
            <td>As purchased</td>
            <td>Based on requirement</td>
          </tr>

          <tr css={emptyRow}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td css={sectionTitle}>FEATURES</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td css={headerTitle}>Transcription</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>All driven automated transcription</td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
          </tr>
          <tr>
            <td>Full feature editor</td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
          </tr>
          <tr>
            <td>Export (.txt, .pdf, .docx)</td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
          </tr>
          <tr>
            <td>Automatic English models (US, UK, AUS)</td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
          </tr>
          <tr css={emptyRow}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td css={headerTitle}>Subtitles / Captions</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Subtitle exports (srt, vtt)</td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
          </tr>
          <tr>
            <td>Burn in subtitles</td>
            <td>
              <UncheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
          </tr>

          <tr css={emptyRow}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td css={headerTitle}>Share and Publish</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Instant shareable clips</td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
          </tr>
          <tr>
            <td>Sharable transcript links</td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
          </tr>
          <tr>
            <td>Automatic social post creation</td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
          </tr>
          <tr>
            <td>Watermark free</td>
            <td>
              <UncheckSvg />
            </td>
            <td>
              <UncheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
          </tr>

          <tr css={emptyRow}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td css={headerTitle}>Content Insights</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Summary Creation</td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
          </tr>
          <tr>
            <td>Entity and key term extraction</td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
          </tr>

          <tr css={emptyRow}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td css={headerTitle}>Teams</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Shared team library</td>
            <td>
              <UncheckSvg />
            </td>
            <td>
              <UncheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
          </tr>
          <tr>
            <td>Share & Edit transcript</td>
            <td>
              <UncheckSvg />
            </td>
            <td>
              <UncheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
          </tr>
          <tr>
            <td>Multi user</td>
            <td>
              <UncheckSvg />
            </td>
            <td>
              <UncheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
          </tr>
          <tr>
            <td>User permissions management</td>
            <td>
              <UncheckSvg />
            </td>
            <td>
              <UncheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
            <td>
              <CheckSvg />
            </td>
          </tr>
        </table>
      </div>

      <BuyUserModal ref={buyUserRef} />

      <ConfirmModal ref={cancelPlanRef} />

      <HistoryDetailsModal ref={historyModalRef} title="Transaction Log" />
    </div>
  );
};

const BillingButton = (props: any) => (
  <button {...props} className="button btn-primary large" tw="mb-0 my-2 px-6!">
    {props.children}
  </button>
);

const ActiveBadge = () => (
  <div
    className="button btn-primary"
    tw="rounded-full block width[9em] font-size[1.4rem] height[3rem] py-3 m-auto mt-1 pointer-events-none user-select[none] shadow-md"
  >
    Current Plan
  </div>
);
