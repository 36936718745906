import { useQuery } from 'react-query';
import { AdminPortalService } from 'services/admin-portal.service';
import { Duration } from 'utils/constants';
import { AvailableUser } from 'utils/models';
import { getTenantidFromIdToken } from 'utils/utils';

const tenantid = getTenantidFromIdToken();

export const useAvailableUsersQuery = () => {
  const queryFnAsync = async (): Promise<AvailableUser[]> => {
    const { data } = await AdminPortalService.getAvailableUsers();

    return data;
  };

  const queryResult = useQuery({
    queryKey: ['admin-portal/user-groups/available', tenantid],
    queryFn: queryFnAsync,
    staleTime: Duration.minutes(1),
    cacheTime: Duration.seconds(30),
  });

  return queryResult;
};
