/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import React, {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Modal } from 'components/UiControls/modal/modal';
import { ModalOptions, ReplaceMode } from 'utils/enum';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setFinderOption } from 'slices/player.slice';
import { isEmpty } from 'lodash';
import { toggleFinderModal } from 'slices/toggle.slice';

const modalStyles = css`
  & div.Modal {
    width: 40rem;
    left: calc(50% - 20rem);
  }
`;

interface IPromiseParams {
  resolve: (result: {
    option: ModalOptions;
    textToFind: string;
    textToReplace: string;
    mode: ReplaceMode
  }) => void;
  reject: (err: any) => void;
}

interface IProps {
  textToFind: string;
}

export const FindAndReplaceModal = forwardRef(
  (props: IProps, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const [isOpen, setIsOpen] = useState(false);
    const [textToReplace, setTextToReplace] = useState('');

    const dispatch = useDispatch();
    const inpReplaceRef = useRef<HTMLInputElement>(null);
    const { finderOptions } = useSelector((state: RootState) => state.player);

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const [checkedState, setCheckedState] = useState({
      isHighConfidenceOnly: false,
    });

    const handleToggleOptions = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.checked;

      if (e.target.name === 'isMatchCase') {
        dispatch(
          setFinderOption({
            ...finderOptions,
            [e.target.name]: value,
          }),
        );
        return;
      }

      setCheckedState({
        ...checkedState,
        [e.target.name]: value,
      });
    };

    const show = async (): Promise<unknown> => {
      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
        inpReplaceRef.current?.focus();
      });
    };

    const hideModal = () => {
      setTextToReplace('');
      setIsOpen(false);
      dispatch(toggleFinderModal());
    };

    const handleReplace = (mode: ReplaceMode) => {
      hideModal();
      promiseInfo.current?.resolve({
        option: ModalOptions.YES,
        textToFind: props.textToFind,
        textToReplace: textToReplace,
        mode
      });
    };

    const canReplace = (): boolean => {
      return (
        !isEmpty(props.textToFind?.trim()) && !isEmpty(textToReplace?.trim())
      );
    };

    return (
      <div css={modalStyles}>
        <Modal show={isOpen} modalClosed={hideModal}>
          <div>
            <div tw="flex">
              <div tw="w-full">
                <h2>Find and Replace</h2>
              </div>
            </div>

            <div>
              <div>
                <label>
                  Find
                  <input
                    type="text"
                    value={props.textToFind}
                    disabled={true}
                    placeholder="Find"
                  />
                </label>
              </div>

              <div>
                <label>
                  Replace
                  <input
                    type="text"
                    value={textToReplace}
                    ref={inpReplaceRef}
                    onChange={(e) => setTextToReplace(e.target.value)}
                    placeholder="Replace"
                  />
                </label>
              </div>

              <div tw="mt-6">
                <label tw="flex items-center cursor-pointer">
                  <div>
                    <input
                      name="isMatchCase"
                      checked={finderOptions.isMatchCase ?? false}
                      onChange={handleToggleOptions}
                      type="checkbox"
                      readOnly
                    />
                    <span className="checkmark" tw="margin-top[-.3rem]"></span>
                  </div>
                  <div>Match case</div>
                </label>

                <label
                  className="disabled"
                  tw="flex items-center cursor-pointer"
                >
                  <div>
                    <input
                      name="isHighConfidenceOnly"
                      checked={checkedState.isHighConfidenceOnly}
                      // onChange={handleToggleOptions}
                      type="checkbox"
                      readOnly
                    />
                    <span className="checkmark" tw="margin-top[-.3rem]"></span>
                  </div>
                  <div>Only replace high confidence words</div>
                </label>
              </div>
            </div>

            <div tw="flex justify-end mt-8">
              <button
                type="button"
                className="button btn-secondary large cancel small-3"
                css={[!canReplace() && tw`opacity-50 pointer-events-none`]}
                onClick={() => handleReplace(ReplaceMode.ALL)}
              >
                Replace all
              </button>
              <button
                type="button"
                tw="ml-4"
                className="button btn-primary large cancel small-3"
                css={[!canReplace() && tw`opacity-50 pointer-events-none`]}
                onClick={() => handleReplace(ReplaceMode.SINGLE)}
              >
                Replace
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  },
);
