/** @jsxImportSource @emotion/react */
import 'twin.macro';

import Loader from 'components/loader/loader';
import {
  CustomSelectSearch,
  Option,
} from 'components/shared/CustomSelectSearch';
import { DropdownSelectionWrapper } from 'components/shared/HintDropdownWrapper/HintDropdownWrapper';
import { TextItem } from 'components/shared/MonetisationTextSearch/MonetisationTextSearch';
import { SearchInput } from 'components/shared/SearchInput';
import { useGroupIdQuery } from 'hooks/queries/useGroupIdQuery';
import { differenceBy, isEmpty, isNil, uniqBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { AdminPortalService } from 'services/admin-portal.service';
import { RestrictionMode } from 'utils/enum';
import { extract, remove } from 'utils/generic.util';
import { customToast } from 'utils/toast.util';
import { ListBox } from '../shared/ListBox';
import { PortalHeading } from '../shared/PortalHeading';
import {
  CollectionApiResponse,
  CollectionService,
} from 'services/collection.service';
import { useEffectOnce, useToggle } from 'react-use';
import { toggleRefetchCollections } from 'slices/admin-portal.slice';
import { QuestionTooltipHint } from 'components/shared/Tooltip/QuestionTooltipHint';
import { TagService } from 'services/tag.service';

type Props = {};

const restrictionOptions: Option[] = [
  { label: 'Collection', value: RestrictionMode.COLLECTION },
  { label: 'Tag', value: RestrictionMode.TAG },
];

export const PortalAccessPane = (props: Props) => {
  const [isLoadingTagDetail, toggleLoadingTagDetail] = useToggle(false);

  const dispatch = useDispatch();

  const adminPortal = useSelector((state: RootState) => state.adminPortal);
  const groupId = adminPortal.selectedUserGroupId;

  const tags = useSelector((state: RootState) => state.tags);
  const activeTags = tags.activeTags;

  const [searchTextItem, setSearchTextItem] = useState<string>();
  const [restrictionMode, setRestrictionMode] = useState<Option>(
    restrictionOptions[0],
  );
  const [groupCollections, setGroupCollections] = useState<TextItem[]>([]);
  const [availableCollections, setAvailableCollections] = useState<
    CollectionApiResponse[]
  >([]);

  const isCollectionMode = restrictionMode.value === RestrictionMode.COLLECTION;

  const { isLoading: isLoadingCollections } = useGroupIdQuery({
    groupId,
    options: {
      onSuccess: (groupInfo) => {
        if (isNil(groupInfo)) return;

        setGroupCollections(
          groupInfo.collections.map((collection) => ({
            id: collection.collectionId,
            text: collection.collectionName,
          })),
        );
      },
    },
  });

  const collectionHintOptions = useMemo<Option[]>(() => {
    if (isEmpty(availableCollections)) return [];

    const allCollections: Option[] = availableCollections.map((collection) => ({
      label: collection.collection_name,
      value: collection.collection_id,
    }));

    return differenceBy(
      allCollections,
      groupCollections.map((collection) => ({ label: collection.text })),
      'label',
    );
  }, [availableCollections, groupCollections]);

  const tagHintOptions = useMemo<Option[]>(() => {
    if (isNil(activeTags)) return [];

    const tagOptions: Option[] = activeTags.map((tag) => ({
      label: `${tag.tagName}`,
      value: tag.tagId,
    }));

    return tagOptions;
  }, [activeTags]);

  useEffectOnce(() => {
    getAdminPortalCollectionsAsync();
  });

  const handleSelectCollectionHintOption = (option: Option) => {
    const collectionList = groupCollections.concat({
      id: option.value,
      text: option.label,
    });

    const uniqCollectionList = uniqBy(collectionList, 'id');

    setGroupCollections(uniqCollectionList);
    saveChangesApiAsync(uniqCollectionList);
  };

  const getTagDetailByIdAsync = async (tagId: string) => {
    try {
      toggleLoadingTagDetail(true);
      const { data } = await TagService.getTagById(tagId);

      return data;
    } catch (err) {
      console.log('err :>> ', err);
    } finally {
      toggleLoadingTagDetail(false);
    }
  };

  const handleSelectTagHintOption = async (option: Option) => {
    const selectedTagId = option.value;
    const selectedTag = activeTags.find(({ tagId }) => tagId === selectedTagId);

    if (isNil(selectedTag)) return;

    const tagDetail = await getTagDetailByIdAsync(selectedTag.tagId);

    if (isNil(tagDetail)) return;

    const tagCollectionData = tagDetail.collectionData;

    const termList = tagCollectionData.map((collection) => ({
      id: collection.collectionId,
      text: collection.collectionName,
    }));

    const collectionList = groupCollections.concat(termList);
    const uniqCollectionList = uniqBy(collectionList, 'id');

    setGroupCollections(uniqCollectionList);
    saveChangesApiAsync(uniqCollectionList);
  };

  const handleRemoveCollection = (collection: TextItem) => {
    const collectionList = remove(groupCollections, 'id', collection.id);

    setGroupCollections(collectionList);
    saveChangesApiAsync(collectionList);
  };

  const saveChangesApiAsync = async (groupCollectionList: TextItem[]) => {
    if (!groupId) {
      customToast.error('Missing group id. Please check and try again.');
      return;
    }

    const updateAsync = AdminPortalService.updateUserGroup(groupId, {
      collectionIds: extract(groupCollectionList, 'id'),
    });

    customToast.promise(updateAsync, {
      loading: 'Updating',
      success: 'Done',
      error: 'Failed to update user group',
    });

    try {
      await updateAsync;

      dispatch(toggleRefetchCollections(true));
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const handleSelectOption = (option: Option) => {
    if (isCollectionMode) {
      handleSelectCollectionHintOption(option);
    } else {
      handleSelectTagHintOption(option);
    }
  };

  const getAdminPortalCollectionsAsync = async () => {
    try {
      const { data } = await CollectionService.getAdminPortalCollections();
      setAvailableCollections(data);
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  return (
    <div tw="relative w-full flex-1">
      {(isLoadingTagDetail || isLoadingCollections) && (
        <div tw="absolute w-full h-full flex justify-center items-center bg-white z-[21] opacity-90">
          <Loader />
        </div>
      )}

      <div tw="flex items-center gap-x-3">
        <PortalHeading header="Advanced Restrictions" />
        <QuestionTooltipHint
          contentWidth={'40rem'}
          message={
            <div>
              <p>
                <strong>Advanced Restrictions</strong> allows Administrator to
                limit accessibility of users to a list of collections.
              </p>
              <p>
                Only those items part of the assigned list of collections would
                be visible to the users on Library and Insights page.
              </p>

              <div>
                <b>Dropdown options:</b>
              </div>

              <ul tw="space-y-3 [li]:(ml-5)">
                <li>
                  <strong>Collection</strong> - Assign specific collection(s) to
                  a user group by performing Search and select.
                </li>
                <li>
                  <strong>Tags</strong> - Assign list of collections in bulk
                  based on Custom tags feature in Collections Preferences.
                </li>
              </ul>
              <div>Leave as blank to show all collections.</div>
            </div>
          }
        />
      </div>

      <div tw="flex flex-row justify-start space-x-5">
        <div tw="flex z-[1] text-14">
          <CustomSelectSearch
            options={restrictionOptions}
            onChange={setRestrictionMode}
            defaultValue={restrictionMode.label}
            canCreate={false}
            isSearchable={false}
          />
        </div>

        <div tw="w-full">
          <DropdownSelectionWrapper
            options={isCollectionMode ? collectionHintOptions : tagHintOptions}
            searchValue={searchTextItem}
            onSelect={handleSelectOption}
            isLoading={false}
          >
            <SearchInput
              setTerm={setSearchTextItem}
              placeholder="Search and select"
            />
          </DropdownSelectionWrapper>
        </div>
      </div>

      <ListBox
        items={groupCollections}
        onRemove={handleRemoveCollection}
        message="Leave empty to show all collections."
      />
    </div>
  );
};
