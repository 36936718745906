import { between } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { isNil, toNumber } from 'lodash';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { toggleLastManualSave } from 'slices/toggle.slice';
import { getTenantidFromIdToken } from 'utils/utils';

interface IParams {
  handleAutoSave: () => unknown;
  dependOn: object[];
  isEditMode: boolean;
}

export const useAutoSave = ({
  handleAutoSave,
  dependOn,
  isEditMode,
}: IParams) => {
  const global = useSelector((state: RootState) => state.global);
  const toggle = useSelector((state: RootState) => state.toggle);

  const dispatch = useDispatch();

  const isAutoSave = global?.preference?.autoSave ?? true;
  const minutes = global?.preference?.autoSaveMinutes;

  // MINUTES BETWEEN 1 and 30
  const autoSaveMinutes =
    !isNil(minutes) && between(toNumber(minutes), 1, 30)
      ? toNumber(minutes)
      : 10;

  const MS_IN_MINUTE = 60 * 1000;
  const AUTO_SAVE_DURATION = autoSaveMinutes * MS_IN_MINUTE; // 10 MINUTES DEFAULT

  const timerRef = useRef<any>(null);

  useEffect(() => {
    // ONLY AUTO SAVE ON EDIT MODE
    if (toggle?.isLastManualSave) {
      clearInterval(timerRef.current);
      dispatch(toggleLastManualSave(false));
    }

    if (isEditMode === true && isAutoSave === true) {
      timerRef.current = setInterval(() => {
        const tenantid = getTenantidFromIdToken();
        // Block auto save for some tenants
        if (
          ![
            'zxwkaqan6', // Listnr - production
          ].includes(tenantid)
        ) {
          handleAutoSave(); // Perform outer callback function
        }
      }, AUTO_SAVE_DURATION);
    } else {
      clearInterval(timerRef.current);
    }

    return () => {
      if (timerRef.current === null) return;

      clearInterval(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependOn);
};
