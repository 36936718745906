/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as ExpandedSvg } from 'assets/Icons/expand_more.svg';

import { SimpleMenuSurface } from '@rmwc/menu';
import { Ripple } from '@rmwc/ripple';
import { useEffect, useRef, useState } from 'react';

import { useClickAway, useToggle } from 'react-use';
import { SelectionMode } from 'utils/enum';
import { hoverDropShadow } from '../twin.styles';

type SelectionOption = {
  displayName: string;
  enum: SelectionMode;
};

const options: SelectionOption[] = [
  {
    displayName: 'Select page',
    enum: SelectionMode.PAGE,
  },
  {
    displayName: 'Select all',
    enum: SelectionMode.ALL,
  },
];

type Props = {
  mode: SelectionMode;

  onSelectMode: (option: SelectionMode) => void;
};

export const MenuSelectWrapper = ({ mode, ...props }: Props) => {
  const menuWrapperRef = useRef<HTMLDivElement>(null);

  const [isOpen, toggleOpen] = useToggle(false);

  const [selectedOption, setSelectedOption] = useState<SelectionOption>(
    options[0],
  );

  const isMenuChecked = [SelectionMode.ALL, SelectionMode.PAGE].includes(mode);

  useClickAway(menuWrapperRef, () => {
    if (!isOpen) return;

    const timer = setTimeout(() => {
      handleClose();

      clearTimeout(timer);
    }, 100);
  });

  useEffect(() => {
    if (mode === SelectionMode.NONE) return;

    const foundMode = options.find((modeOption) => modeOption.enum === mode);

    if (!foundMode) return;

    setSelectedOption(foundMode);
  }, [mode]);

  const handleOpen = () => {
    if (isOpen) return;

    toggleOpen(true);
  };

  const handleClose = () => {
    if (!isOpen) return;

    toggleOpen(false);
  };

  const handleSelectMode =
    (mode: SelectionOption) => (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();

      setSelectedOption(mode);

      props.onSelectMode(mode.enum);

      handleClose();
    };

  const handleCheckMenu = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      props.onSelectMode(selectedOption.enum);
    } else {
      props.onSelectMode(SelectionMode.NONE);
    }
  };

  return (
    <div ref={menuWrapperRef} tw="py-2" onClick={handleOpen}>
      <SimpleMenuSurface
        open={isOpen}
        handle={
          <div tw="flex flex-row gap-x-1 justify-center items-center cursor-pointer">
            <label
              tw="flex-[unset]! select-none cursor-pointer ml-[8px]"
              onClick={(e) => e.stopPropagation()}
            >
              <input
                type="checkbox"
                checked={isMenuChecked}
                onChange={handleCheckMenu}
              />
              <span className="checkmark" style={{ top: '6px' }}></span>
            </label>

            <span tw="min-w-[8.2rem] mt-[1px] text-14 font-medium text-sonnant-grey-6 opacity-95">
              {selectedOption.displayName}
            </span>

            <Ripple>
              <span tw="flex justify-center items-center rounded-lg">
                <ExpandedSvg
                  tw="cursor-pointer h-[2.7rem] w-[2.7rem]"
                  css={[hoverDropShadow(0.15)]}
                />
              </span>
            </Ripple>
          </div>
        }
        anchorCorner="bottomLeft"
        fixed
        tw="mt-3 min-w-[16rem]"
      >
        <div tw="py-2">
          {options.map((mode, index) => (
            <Ripple key={index} onClick={handleSelectMode(mode)}>
              <span tw="flex px-5 py-2 cursor-pointer text-14 font-medium text-sonnant-grey-6 opacity-95 select-none">
                {mode.displayName}
              </span>
            </Ripple>
          ))}
        </div>
      </SimpleMenuSurface>
    </div>
  );
};
