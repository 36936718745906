import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { UserService } from 'services';
import { WatchList } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { getTenantidFromIdToken } from 'utils/utils';
import { v4 } from 'uuid';

const getCurrentWatchList = (watchLists: WatchList[], id: string) => {
  const currentWatchList = watchLists.find((i) => i.id === id);

  return currentWatchList;
};

const watchListSlice = createSlice({
  name: 'watchList',
  initialState: {
    watchLists: [
      // {
      //   id: v4(),
      //   name: 'test',
      //   active: true,
      //   terms: [] as Term[],
      //   versionNo: 1,
      //   dateCreated: '2021-12-20T02:43:57.905Z',
      //   lastModified: '2021-12-20T02:43:57.905Z',
      //   recipients: {
      //     me: true as boolean,
      //     allUsers: false as boolean,
      //     hasEmailAddress: false as boolean,
      //     emailAddress: [] as string[],
      //   } as Recipients,
      // },
    ] as WatchList[],
    focusWatchListId: null as string | null,
    currentEditWatchList: {} as WatchList,
  },
  reducers: {
    setCurrentEditWatchList: (state, action: PayloadAction<WatchList>) => {
      const emailAddress = uniq(action.payload.recipients.emailAddress);

      const newRecipients = action.payload.recipients;
      state.currentEditWatchList = {
        ...action.payload,
        recipients: { ...newRecipients, emailAddress },
      };
    },
    setCurrentEditWatchListEmail: (state, action: PayloadAction<string[]>) => {
      state.currentEditWatchList.recipients.emailAddress = uniq(action.payload);
    },
    setFocusWatchListId: (state, action: PayloadAction<string | null>) => {
      if (action.payload) {
        state.focusWatchListId = action.payload;
        const currentWatchList = getCurrentWatchList(
          state.watchLists,
          action.payload,
        );
        if (currentWatchList) {
          state.currentEditWatchList = currentWatchList;
        }
      }
    },
    setWatchList: (state, action: PayloadAction<WatchList[]>) => {
      state.watchLists = action.payload;
    },
    appendNewWatchList: (
      state,
      {
        payload,
      }: PayloadAction<Pick<WatchList, 'active' | 'name' | 'terms' | 'email'>>,
    ) => {
      const newId = v4();

      const newWatchList: WatchList = {
        id: newId,
        active: payload.active,
        name: payload.name,
        terms: payload?.terms ?? [],
        recipients: {
          me: true,
          allUsers: false,
          hasEmailAddress: false,
          emailAddress: [payload?.email ?? ''],
        },
        dateCreated: new Date(),
        lastModified: new Date(),
        versionNo: 1,
      };
      state.watchLists.push(newWatchList);
      state.focusWatchListId = newId;
      state.currentEditWatchList = newWatchList;
    },
    appendWatchList: (state, { payload }: PayloadAction<WatchList>) => {
      const newWatchList = {
        ...payload,
        id: v4(),
      };

      state.watchLists.push(newWatchList);
      const currentWatchList = getCurrentWatchList(
        state.watchLists,
        newWatchList.id,
      );
      if (currentWatchList) {
        state.currentEditWatchList = currentWatchList;
      }
      state.focusWatchListId = newWatchList.id;
    },
    updateCurrentWatchList: (
      state,
      { payload }: PayloadAction<Pick<WatchList, any>>,
    ) => {
      const foundIndex = state?.watchLists?.findIndex(
        (watchList) => watchList.id === (payload.id ?? state.focusWatchListId),
      );

      // Check valid id
      if (foundIndex > -1 && foundIndex < state.watchLists?.length) {
        const newWatchList = { ...state.watchLists[foundIndex], ...payload };
        state.watchLists[foundIndex] = {
          ...newWatchList,
        };
        state.currentEditWatchList = newWatchList;
      }
    },
    triggerSaveWatchList: (
      state,
      {
        payload,
      }: PayloadAction<{
        runBackground?: boolean;
      }>,
    ) => {
      (async () => {
        try {
          const tenantid = getTenantidFromIdToken();
          const saveAsync = UserService.savePreference({
            tenantid,
            watch_lists: state?.watchLists
              ? JSON.stringify(state.watchLists)
              : undefined,
          });

          if (!payload?.runBackground) {
            customToast.promise(saveAsync, {
              loading: 'Saving changes...',
              success: 'Saved successfully',
              error: 'Save failed',
            });
          }

          await saveAsync;
        } catch (error: any) {
          console.log('error :>> ', error);
        }
      })();
    },
  },
});

export const {
  setCurrentEditWatchListEmail,
  setCurrentEditWatchList,
  setFocusWatchListId,
  setWatchList,
  appendNewWatchList,
  updateCurrentWatchList,
  triggerSaveWatchList,
  appendWatchList,
} = watchListSlice.actions;

export const watchListReducer = watchListSlice.reducer;
