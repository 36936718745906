/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { Drawer, DrawerAppContent } from '@rmwc/drawer';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { CollectionSidebar } from '../CollectionSidebar/CollectionSidebar';
import { DragNDrop } from 'utils/enum';
import { ILibraryItem } from 'utils/models';
import {
  addItemsToCollectionAsync,
  removeItemsFromCollectionAsync,
} from 'utils/utils';
import { initLibrary, setSelectedRows, setSelectMode } from 'actions';
import { changePage } from 'slices/pagination.slice';

interface Props {
  children: React.ReactNode;
}

export const CollectionDnDWrapper = ({ children, ...props }: Props) => {
  const dispatch = useDispatch();

  const collection = useSelector((state: RootState) => state.collection);
  const library = useSelector((state: RootState) => state.library);
  const pagination = useSelector((state: RootState) => state.pagination);

  let queryPagingParam = {
    page_number: pagination.page_number,
    page_size: pagination.page_size,
  };

  const handleDragEnd = async (result: DropResult) => {
    const dropId = result?.destination?.droppableId as DragNDrop;
    const validDestination = ![
      DragNDrop.NO_DROP,
      DragNDrop.MY_COLLECTION,
    ].includes(dropId);

    const isMultiDrag = library?.selectedRows
      ?.map((item: ILibraryItem) => item?.mediaid)
      ?.includes(result.draggableId);

    // Drop to collection
    if (result?.reason === 'DROP' && validDestination) {
      if (library.isSelectMode && isMultiDrag) {
        handleAddMultiItems(result);
      } else {
        handleAddSingleItem(result);
      }
    }
    let isLastPage = false;
    // Drop to my collection
    if (result?.reason === 'DROP' && dropId === DragNDrop.MY_COLLECTION) {
      // Refresh items after deleting
      if (library.isSelectMode && isMultiDrag) {
        await handleRemoveMultiItems(result);

        if (library?.selectedRows?.length === library?.data?.library?.length) {
          isLastPage = true;
        }
      } else {
        await handleRemoveSingleItem(result);

        if (library?.data?.library.length === 1) {
          isLastPage = true;
        }
      }

      // Jump to last page if remove all items in current page
      if (isLastPage) {
        queryPagingParam = {
          ...pagination,
          page_number:
            pagination?.page_number - 1 > 0 ? pagination?.page_number - 1 : 1,
        };
        dispatch(changePage(queryPagingParam));
      }

      dispatch(setSelectedRows([])); // Reset selection after deleting
      dispatch(setSelectMode(false));

      dispatch(
        initLibrary({
          ...queryPagingParam,
          collection_id: collection.selectedId,
        }),
      );
    }
  };

  const handleAddSingleItem = async (result: DropResult) => {
    const mediaid = result.draggableId;
    const collectionId = result.destination?.droppableId;

    if (!collectionId || !mediaid) return;

    addItemsToCollectionAsync({ collectionId, ids: [mediaid] });
  };

  const handleAddMultiItems = (result: DropResult) => {
    const collectionId = result.destination?.droppableId;
    const mediaIds: string[] =
      library.selectedRows.map((row: ILibraryItem) => row?.mediaid) ?? [];

    if (!collectionId || !mediaIds) return;

    addItemsToCollectionAsync({ collectionId, ids: mediaIds });
  };

  const handleRemoveSingleItem = async (result: DropResult) => {
    await removeItemsFromCollectionAsync({
      collectionId: collection?.selectedId!,
      ids: [result.draggableId],
    });
  };

  const handleRemoveMultiItems = async (result: DropResult) => {
    const collectionId = result.destination?.droppableId;
    const mediaIds: string[] =
      library.selectedRows.map((row: ILibraryItem) => row?.mediaid) ?? [];

    if (!collectionId || !mediaIds) return;

    await removeItemsFromCollectionAsync({
      collectionId: collection?.selectedId!,
      ids: mediaIds,
    });
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={DragNDrop.NO_DROP} direction={'horizontal'}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            css={[customCss]}
          >
            <Drawer dismissible open={collection.showSidebar}>
              <CollectionSidebar />
            </Drawer>
            <DrawerAppContent>{children}</DrawerAppContent>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export const customCss = css`
  .mdc-drawer {
    ${tw`z-index[unset]`}
  }

  .mdc-drawer-app-content {
    ${tw`transition-all ease-in-out duration-300`}
  }
`;
