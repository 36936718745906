/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { FilterWrapper } from 'components/shared/AdvancedFilterMenu/FilterWrapper';
import { useRef, useState } from 'react';
import { useClickAway, useToggle } from 'react-use';
import { len } from 'utils/generic.util';
import { FilterMenuOptions } from 'utils/models';
import { InsightFilterButton } from './AdvancedFilterButton';

type Props = {
  selectedFilters: FilterMenuOptions;
  onMenuClose: (filters: FilterMenuOptions) => void;
};

export const defaultAdvancedFilters: FilterMenuOptions = {
  tagIds: [],
  collectionIds: [],
  orgIds: [],
};

export const AdvancedFilterMenu = ({ selectedFilters, ...props }: Props) => {
  const filterWrapperRef = useRef<HTMLDivElement>(null);

  const [currentFilters, setCurrentFilters] =
    useState<FilterMenuOptions>(selectedFilters);

  const [showMenu, toggleShowMenu] = useToggle(false);

  useClickAway(filterWrapperRef, () => {
    if (!showMenu) return;

    const timer = setTimeout(() => {
      toggleShowMenu(false);

      clearTimeout(timer);
    }, 100);
  });

  const filtersCount =
    len(currentFilters.tagIds) +
    len(currentFilters.collectionIds) +
    len(currentFilters.orgIds);

  const handleApplyFilters = (filters: FilterMenuOptions) => {
    setCurrentFilters(filters);

    if (typeof props.onMenuClose !== 'function') return;

    props.onMenuClose(filters);
  };

  const handleResetFilters = () => {
    setCurrentFilters(defaultAdvancedFilters);
  };

  return (
    <div ref={filterWrapperRef} css={wrapperCss}>
      <FilterWrapper
        menuIsOpen={showMenu}
        onCloseMenu={handleApplyFilters}
        onClearFilters={handleResetFilters}
        onApplyFilters={() => {}}
        selectionFilters={currentFilters}
        showReset={filtersCount > 0}
        showApply={false}
        fixed={false}
        anchorElement={
          <InsightFilterButton
            onClick={toggleShowMenu}
            filtersCount={filtersCount}
          />
        }
      />
    </div>
  );
};

const wrapperCss = css`
  .mdc-menu-surface {
    ${tw`max-h-[unset]!`}
  }
`;
