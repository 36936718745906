import { isEmpty } from 'lodash';
import { GroupNotify, UpdateTitle } from 'utils/models';
import { RawNotification } from 'utils/models/payment.model';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { groupSingleRawNotiByTime } from '../components/VideoPlayer/Transcription/MediaUtilities';
import { NotifyList } from '../utils/models/media.model';

const notificationSlice = createSlice({
  name: 'socket',
  initialState: {
    notifications: [] as GroupNotify[],
    focusNotification: null as null | NotifyList,
    isLoaded: false as boolean,
  },
  reducers: {
    updateNotifications: (state, { payload }: PayloadAction<UpdateTitle>) => {
      if (!payload) return;

      state.notifications = state.notifications.map((noti) => {
        noti.items = noti.items.map((item) => {
          if (item.mediaid === payload.mediaid) {
            const newItem = { ...item, fileName: payload.title };
            return newItem;
          }
          return item;
        });
        return { ...noti };
      });

      state.isLoaded = true;
    },
    setNotifications: (state, { payload }: PayloadAction<GroupNotify[]>) => {
      if (!payload) return;

      // Prevent disappeared items
      if (!state.isLoaded) {
        state.notifications = [...payload];
        // state.notifications = [...payload, ...groupNotifyMock];
      }

      state.isLoaded = true;
    },
    deleteSingleNotify: (state, { payload }: PayloadAction<string>) => {
      if (!payload) return;

      state.notifications = state.notifications
        .map((noti) => {
          noti.items = noti.items.filter((i) => i.id !== payload);
          return noti;
        })
        .filter((group) => !isEmpty(group?.items));
    },
    clearAllNotify: (state, { payload }: PayloadAction<string[]>) => {
      if (isEmpty(payload)) {
        state.notifications = [];
        return;
      }

      payload.forEach((filteredStatus) => {
        state.notifications = state.notifications
          .map((noti) => ({
            ...noti,
            items: noti.items.filter((i) => i?.filter !== filteredStatus),
          }))
          .filter((group) => !isEmpty(group?.items));
      });
    },
    setFocusNotification: (
      state,
      { payload }: PayloadAction<NotifyList | null>,
    ) => {
      state.focusNotification = payload ?? null;
    },
    insertSocketMessage: (
      state,
      {
        payload: { payload, timeZone },
      }: PayloadAction<{ payload: RawNotification; timeZone: string }>,
    ) => {
      if (!payload) return;

      const notiGroup = groupSingleRawNotiByTime(payload, timeZone);

      const foundDay = state.notifications.findIndex(
        (n) => n.date === notiGroup.date,
      );

      state.notifications.forEach((day) => {
        day.items = day.items.filter((found) => payload.id !== found.id);
      });

      // IF Found: Add new day group ELSE append the found group
      if (foundDay < 0) {
        state.notifications = [notiGroup, ...state.notifications];
      } else {
        const foundFiltered = state.notifications[foundDay].items.filter(
          (found) => notiGroup.items.some((item) => item.id !== found.id),
        );
        state.notifications[foundDay].items = [
          ...notiGroup.items,
          ...foundFiltered,
        ];
      }
    },
  },
});

export const {
  setNotifications,
  deleteSingleNotify,
  clearAllNotify,
  setFocusNotification,
  insertSocketMessage,
  updateNotifications,
} = notificationSlice.actions;

export const notificationReducer = notificationSlice.reducer;
