/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as AdmarkerIcon } from 'assets/Icons/admarker-black.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setMidRollItems, setMonetisation } from 'slices/global.slice';
import { getTenantidFromIdToken } from 'utils/utils';
import { UserService } from 'services';
import { customToast } from 'utils/toast.util';
import { Loader } from 'components/loader/loader';
import { ConfirmModalProps, MidRollSetting, Monetisation } from 'utils/models';
import { isEmpty, isEqual, toNumber } from 'lodash';
import { Hint } from 'components/shared/Hint';
import { QuestionHint } from 'components/shared/QuestionHint';
import { ResetBottomBar } from 'components/shared/ResetBottomBar/ResetBottomBar';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { defaultSetting } from 'utils/default/defaultSetting';
import { ModalOptions } from 'utils/enum';
import { AdMarkerReprocessSetting } from './AdMarkerReprocessSetting';
import { mediumTooltipCss } from 'components/shared/twin.styles';
import { NumberInput } from 'components/shared/NumberInput';

export const MonetisationSetting = () => {
  const dispatch = useDispatch();

  const monetisation = useSelector(
    (state: RootState) => state.global.monetisation,
  );
  const isLoading = useSelector(
    (state: RootState) => state.payment.subscription.isLoading,
  );

  const [initialState, setInitialState] = useState<Monetisation>(monetisation);

  const hasMidRolls = !!monetisation?.midRolls;
  // const isMidRollEvery = monetisation?.midRollsConfigs.isEverySelected;
  const isMidRollMaximum = monetisation?.midRollsConfigs?.isMaximumSelected;

  const hasDisableReset = isEqual(defaultSetting.monetisation, monetisation);

  const confirmModalRef = useRef<any>(null);

  useEffect(() => {
    setInitialState(monetisation);
  }, [isLoading]);

  const handleChangeAdsNumber = (value: number) => {
    const newMonetisation = { ...monetisation, adsNumber: value };
    dispatch(setMonetisation(newMonetisation));
  };

  const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const checked = e.target.checked;

    let newMonetisation = { ...monetisation };

    if (name === 'monetisationEnabled') {
      newMonetisation = {
        ...monetisation,
        monetisationEnabled: checked,
        preRoll: checked,
        postRoll: checked,
        midRolls: checked,
      };
    } else {
      newMonetisation = {
        ...monetisation,
        [name]: checked,
      };
    }

    dispatch(setMonetisation(newMonetisation));
  };

  const handleRadioClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;

    const newMidRollItems = {
      ...monetisation.midRollsConfigs,
      isEverySelected: true,
      isMaximumSelected: true,
    };

    if (name === 'maximum') {
      newMidRollItems.isEverySelected = false;
    } else {
      newMidRollItems.isMaximumSelected = false;
    }

    dispatch(setMidRollItems(newMidRollItems));
  };

  const hasChanges = isEqual(monetisation, initialState);

  const handleApply = async (payload: Monetisation = monetisation) => {
    try {
      const tenantid = getTenantidFromIdToken();

      const saveAsync = UserService.savePreference({
        tenantid,
        monetisation: JSON.stringify(payload),
      });

      customToast.promise(saveAsync, {
        loading: 'Saving changes...',
        success: 'Saved successfully',
        error: 'Save failed',
      });

      await saveAsync;
      setInitialState(payload);
    } catch (error: any) {
      console.log('error :>> ', error);
    }
  };

  const handleReset = () => {
    dispatch(setMonetisation(initialState));
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const ignoredKeys = ['.', ',', 'E', 'e'];

    if (ignoredKeys.includes(e?.key)) {
      e.preventDefault();
    }
  };

  const handleTimeChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
    item: keyof MidRollSetting,
  ) => {
    let value = evt?.target?.value;

    if (value?.trim() && parseInt(value) > 60) {
      value = '60';
    }

    if (value?.trim() && parseInt(value) < 1) {
      value = '1';
    }

    handleSaveMidRollItems(value, item);
  };

  const handleResetDefault = async () => {
    const result = await confirmModalRef.current?.show({
      title: 'Confirm reset to default',
      confirmText: 'Confirm',
      message: (
        <div>
          Are you sure you want to reset monetisation configuration to default?
        </div>
      ),
    } as ConfirmModalProps);

    if (result !== ModalOptions.YES) return;

    handleApply(defaultSetting.monetisation);
    setInitialState(defaultSetting.monetisation);
    dispatch(setMonetisation(defaultSetting.monetisation));
  };

  const setDefaultValue = (item: keyof MidRollSetting) => {
    if (isEmpty(monetisation?.midRollsConfigs?.[item]?.toString())) {
      dispatch(
        setMidRollItems({
          ...monetisation.midRollsConfigs,
          [item]: initialState.midRollsConfigs?.[item],
        }),
      );
    }
  };

  const handleSaveMidRollItems = (
    value: number | string,
    item: keyof MidRollSetting,
  ) => {
    dispatch(
      setMidRollItems({
        ...monetisation.midRollsConfigs,
        [item]: !isEmpty(value) ? toNumber(value) : value,
      }),
    );
  };

  return (
    <div>
      {isLoading && (
        <div
          className="loader__component"
          tw="height[calc(100vh - 13rem)] top[13rem] opacity-100"
        >
          <Loader />
        </div>
      )}

      <ResetBottomBar
        isShow={!hasChanges}
        onReset={handleReset}
        onSubmit={handleApply}
      />

      <ConfirmModal
        ref={confirmModalRef}
        message="Please upgrade to enable additional users"
        title="Confirm"
        confirmText="Upgrade"
        classes="disable-enable-user-modal-width"
      />

      <div tw="flex justify-between items-center mt-6">
        <div tw="flex items-center space-x-4 ml-4">
          <AdmarkerIcon />
          <div tw="font-bold text-4xl">Ad markers</div>
          <QuestionHint
            notTransparent
            hideCloseButton
            type={'information'}
            align={'bottomLeft'}
            action={'click'}
            text={
              <div tw="py-1">
                <p>
                  Sonnant will create suggested Ad Markers based on the average
                  time between Ad Markers and the best place within the content,
                  taking into account chapters, flow of conversation and pauses.
                  These suggested Ad Markers can be modified, removed or added
                  in the Edit Transcript page.
                </p>
              </div>
            }
            customStyle={mediumTooltipCss}
          />
        </div>

        <div className="btnReset">
          <button
            type="button"
            className={`success button apply`}
            tw="mt-0! focus:(border[2px solid #f0f0f0])! border[2px solid #f0f0f0]!"
            onClick={handleResetDefault}
            disabled={hasDisableReset}
          >
            Reset
          </button>
        </div>
      </div>

      <div tw="mt-5">
        <label
          className={`large-10 cell`}
          tw="ml-20 max-w-full flex[unset]! w-auto cursor-pointer"
        >
          <input
            name="monetisationEnabled"
            checked={monetisation.monetisationEnabled}
            onChange={handleCheckboxClick}
            type="checkbox"
          />
          <span className="checkmark" style={{ top: '5px' }}></span>
          Suggest ad markers
        </label>
      </div>
      <div tw="ml-14">
        <div>
          <label
            className={`large-10 cell`}
            tw="ml-20 max-w-full flex[unset]! w-auto cursor-pointer"
          >
            <input
              name="preRoll"
              checked={monetisation.preRoll}
              onChange={handleCheckboxClick}
              type="checkbox"
            />
            <span className="checkmark" style={{ top: '5px' }}></span>
            Pre-roll
          </label>
        </div>
        <div tw="flex space-x-10">
          <div>
            <label tw="ml-20 flex items-center my-2 w-auto cursor-pointer">
              <input
                name="midRolls"
                checked={!!monetisation?.midRolls}
                onChange={handleCheckboxClick}
                type="checkbox"
              />
              <span className="checkmark" style={{ top: '5px' }}></span>
              Mid-roll:
            </label>
          </div>
          <div tw="flex items-center margin-left[0.6rem]!">
            <label tw="flex items-center h-8">
              <input
                name="every"
                tw="(width[2rem] m-2 accent-color[#5451f6] cursor-pointer)!"
                checked={monetisation?.midRollsConfigs?.isEverySelected}
                onChange={handleRadioClick}
                disabled={!hasMidRolls}
                type="radio"
              />
              <span>every</span>
              <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                <Hint text={'Number must be between 1 and 60'}>
                  <input
                    tw="(width[5rem] height[3rem] m-2)!"
                    type="number"
                    min={1}
                    step={1}
                    max={60}
                    placeholder="10"
                    onBlur={() => setDefaultValue('everyMinutes')}
                    value={monetisation?.midRollsConfigs?.everyMinutes}
                    onKeyDown={handleKeyPress}
                    onChange={(e) => handleTimeChange(e, 'everyMinutes')}
                    className="keywordInput"
                    disabled={!hasMidRolls || isMidRollMaximum}
                    css={[(!hasMidRolls || isMidRollMaximum) && tw`opacity-75`]}
                  />
                </Hint>
              </form>
              <span>minutes or</span>
            </label>
            <label tw="flex items-center h-8 space-x-1 cursor-pointer">
              <input
                name="maximum"
                style={{ border: '10px solid #EFE5CE' }}
                tw="(width[2rem] m-2 ml-3 accent-color[#5451f6])!"
                checked={monetisation?.midRollsConfigs?.isMaximumSelected}
                onChange={handleRadioClick}
                type="radio"
                disabled={!hasMidRolls}
              />
              <span>maximum of</span>
              <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                <Hint text={'Number must be between 1 and 60'}>
                  <input
                    tw="(width[5rem] height[3rem] m-2)!"
                    type="number"
                    min={1}
                    step={1}
                    max={60}
                    placeholder="10"
                    value={monetisation?.midRollsConfigs?.maximumMinutes}
                    onKeyDown={handleKeyPress}
                    onBlur={() => setDefaultValue('maximumMinutes')}
                    onChange={(e) => handleTimeChange(e, 'maximumMinutes')}
                    className="keywordInput"
                    disabled={!hasMidRolls || !isMidRollMaximum}
                    css={[
                      (!hasMidRolls || !isMidRollMaximum) && tw`opacity-75`,
                    ]}
                  />
                </Hint>
              </form>
              <span>ad markers, with a minimum gap of</span>
              <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                <Hint text={'Number must be between 1 and 60'}>
                  <input
                    tw="(width[5rem] height[3rem] m-2)!"
                    type="number"
                    min={1}
                    step={1}
                    max={60}
                    placeholder="10"
                    value={monetisation?.midRollsConfigs?.gapMinutes}
                    onKeyDown={handleKeyPress}
                    onBlur={() => setDefaultValue('gapMinutes')}
                    onChange={(e) => handleTimeChange(e, 'gapMinutes')}
                    className="keywordInput"
                    disabled={!hasMidRolls || !isMidRollMaximum}
                    css={[
                      (!hasMidRolls || !isMidRollMaximum) && tw`opacity-75`,
                    ]}
                  />
                </Hint>
              </form>
              <span>minutes</span>
            </label>
          </div>
        </div>
        <div>
          <label
            className={`large-10 cell`}
            tw="ml-20 max-w-full flex[unset]! w-auto cursor-pointer"
          >
            <input
              name="postRoll"
              checked={monetisation?.postRoll}
              onChange={handleCheckboxClick}
              type="checkbox"
            />
            <span className="checkmark" style={{ top: '5px' }}></span>
            Post-roll
          </label>
        </div>
        <div tw="flex items-center">
          <div>
            <label
              className={`large-10 cell`}
              tw="ml-20 max-w-full flex[unset]! w-auto cursor-pointer"
            >
              <input
                name="isCheckAdsNumber"
                checked={monetisation?.isCheckAdsNumber}
                onChange={handleCheckboxClick}
                type="checkbox"
              />
              <span className="checkmark" style={{ top: '5px' }}></span>
            </label>
          </div>
          <div tw="flex items-center">
            <label>Add</label>
            <NumberInput
              tw="(width[5.7rem] height[3rem] m-2)!"
              min={1}
              step={1}
              max={100}
              placeholder="0"
              value={monetisation?.adsNumber}
              onChangeNumber={handleChangeAdsNumber}
              className="keywordInput"
              disabled={!monetisation?.isCheckAdsNumber}
              css={[!monetisation?.isCheckAdsNumber && tw`opacity-75`]}
            />
            <label>number of ads per ad marker slot</label>
          </div>
        </div>
      </div>

      <AdMarkerReprocessSetting />
    </div>
  );
};
