import { fillHierarchyString } from './../components/VideoPlayer/Transcription/MediaUtilities';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { iabMock } from 'mocks/iab.mock';
import { IABItemModel } from 'utils/models';
import { IABCategoryS3 } from 'utils/models/transcript.model';
import { customToast } from 'utils/toast.util';

const iabSlice = createSlice({
  name: 'iab',
  initialState: {
    lockedList: [] as IABItemModel[],
    recommendedList: iabMock.recommendedCategories as IABItemModel[],
    selectedId: '' as string,
  },
  reducers: {
    addConfirmedItem: (state, { payload }: PayloadAction<IABItemModel>) => {
      state.lockedList = [...state.lockedList, payload];
      customToast.success('Added', 'IAB Category');
    },
    removeIABItem: (state, { payload }: PayloadAction<IABItemModel>) => {
      if (payload?.isRecommended) {
        state.recommendedList = state.recommendedList.filter(
          (i) => i?.id !== payload?.id,
        );

        const foundConfirmed = state.lockedList.find(
          (i) => i?.id === payload?.id,
        );
        if (foundConfirmed) {
          state.lockedList = state.lockedList.filter(
            (i) => i.id !== payload.id,
          );
          state.recommendedList = [payload, ...state.recommendedList];
        }
      } else {
        state.lockedList = state.lockedList.filter(
          (i) => i?.id !== payload?.id,
        );
      }
      customToast.success('Removed', 'IAB Category');
    },
    setIABCategoriesS3: (state, { payload }: PayloadAction<IABCategoryS3>) => {
      if (isEmpty(payload)) return;

      state.lockedList = fillHierarchyString(payload.lockedList);
      state.recommendedList = payload.recommendedList;
    },
    resetIABLists: (state) => {
      state.lockedList = [];
    },
  },
});

export const {
  addConfirmedItem,
  removeIABItem,
  setIABCategoriesS3,
  resetIABLists,
} = iabSlice.actions;

export const iabReducer = iabSlice.reducer;
