/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { Modal } from 'components/UiControls/modal/modal';
import { isEmpty } from 'lodash';
import React, {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useToggle } from 'react-use';
import { ModalOptions } from 'utils/enum';
import { ConfirmWithCheckboxModalProps } from 'utils/models';
import { SimpleCheckbox } from '../SimpleCheckbox';

interface IPromiseParams {
  resolve: (result: ConfirmWithCheckboxModalResult) => void;
  reject: (err: any) => void;
}

export type ConfirmWithCheckboxModalResult = {
  option: ModalOptions;
  isIgnoredConfirm?: boolean;
};

export const ConfirmWithCheckboxModal = forwardRef(
  (
    parentProps: ConfirmWithCheckboxModalProps,
    ref: React.Ref<unknown>,
  ): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const [modalProps, setModalProps] =
      useState<ConfirmWithCheckboxModalProps>(parentProps);

    const [isOpen, setIsOpen] = useState(false);
    const [isIgnoredConfirm, toggleIsIgnoredConfirm] = useToggle(false);

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (
      customProps: ConfirmWithCheckboxModalProps,
    ): Promise<unknown> => {
      if (!isEmpty(customProps)) {
        setModalProps({ ...modalProps, ...customProps });
      }

      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const hideModal = () => {
      setIsOpen(false);
    };

    const handleYes = () => {
      hideModal();

      promiseInfo.current?.resolve({
        option: ModalOptions.YES,
        isIgnoredConfirm,
      });
    };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current?.resolve({
        option: ModalOptions.CANCEL,
      });
    };

    const handleClickCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      toggleIsIgnoredConfirm(checked);
    };

    return (
      <Modal
        classes={parentProps.classes}
        show={isOpen}
        modalClosed={() => {
          hideModal();
          promiseInfo.current?.resolve({
            option: ModalOptions.CLOSE,
          });
        }}
      >
        <div tw="[p]:(mb-0)">
          <h1 tw="font-semibold text-sonnant-dark font-size[3.5rem]">
            {modalProps.title || 'Confirm'}
          </h1>
          <div className="grid-x">
            <div className="cell small-12">
              <div
                tw="rounded text-center bg-sonnant-orange text-white p-6 mt-4 mb-10 text-16"
                css={[
                  modalProps?.type === 'info' && tw`bg-sonnant-green!`,
                  modalProps?.type === 'warning' && tw`bg-sonnant-orange!`,
                  modalProps?.type === 'error' && tw`bg-sonnant-red!`,
                ]}
              >
                <div>{modalProps.message || '-- Missing Message --'}</div>
              </div>
            </div>
          </div>

          <div tw="flex items-start relative mb-[2rem]">
            <div tw="absolute top-[-1.7rem]">
              <SimpleCheckbox
                checked={isIgnoredConfirm}
                onChange={handleClickCheckbox}
                label="Don't show this message again"
              />
            </div>
          </div>

          <div className="grid-x">
            <div className="cell small-12">
              <div tw="w-full flex justify-end">
                {!modalProps?.hideCancel && (
                  <button
                    type="button"
                    className="button btn-secondary large"
                    tw="mr-6 hover:(border-sonnant-grey-3!)"
                    onClick={handleCancel}
                  >
                    {modalProps?.cancelText || 'Cancel'}
                  </button>
                )}
                {!modalProps?.hideConfirm && (
                  <button
                    type="button"
                    className="button btn-primary large"
                    onClick={handleYes}
                  >
                    {modalProps?.confirmText || 'Yes'}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);
