/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';
import React, { useRef, useState } from 'react';
import {
  disableButton,
  hoverDropShadow,
  noneBorder,
} from 'components/shared/twin.styles';
import CloseSVG from 'components/UiControls/closeButtonSVG/closeSVG';
import { ReactComponent as Search } from 'assets/Icons/search.svg';
import { ReactComponent as AddTermSvg } from 'assets/Icons/library_add_black_24dp.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  DragDropContext,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import {
  deleteCustomList,
  setCustomTerms,
  setFocusCustomList,
} from 'slices/global.slice';
import {
  AddEditTermModal,
  TermModalResult,
} from 'components/shared/CutomTermsModal/AddEditTermModal';
import { CustomTerm } from 'utils/models';
import { ModalOptions } from 'utils/enum';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { getTenantidFromIdToken } from 'utils/utils';
import Loader from 'components/loader/loader';
import { tierSelector } from 'slices/payment.slice';
import { Hint } from 'components/shared/Hint';
import { ListDetailPane } from '../ListDetailPane';
import { useEffectOnce, useUnmount } from 'react-use';
import { getTermsCount } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { AxiosResponse } from 'axios';
import { isEmpty, isNil } from 'lodash';
import { v4 } from 'uuid';
import { UserService } from 'services';
import { customToast } from 'utils/toast.util';
import { CustomVocabItem } from './CustomVocabItem';

interface Props {
  inModal?: boolean;
  onCloseModal?: () => void;
}

export const CustomVocabsTab = ({ inModal, ...props }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');

  const global = useSelector((state: RootState) => state.global);
  const profile = useSelector((state: RootState) => state.payment.subscription);
  const tier = useSelector(tierSelector);
  const searchedTerms =
    global.customTerms?.filter((term) =>
      term?.name.toLowerCase().includes(searchTerm.trim().toLowerCase()),
    ) ?? [];

  const dispatch = useDispatch();
  const termModalRef = useRef<any>(null);
  const confirmModalRef = useRef<any>(null);

  useEffectOnce(() => {
    // SELF INVOKED
    (async () => {
      if (isEmpty(global?.customTerms)) return;
      const { data }: AxiosResponse = await UserService.getUser();
      if (!isEmpty(data?.preferences?.customterms)) {
        dispatch(setCustomTerms(data?.preferences?.customterms));
      }
    })();
  });

  useUnmount(() => {
    dispatch(setFocusCustomList(null));
  });

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (
      result?.destination?.index == null ||
      result.source.index === result?.destination.index
    )
      return;

    const newList = [...global.customTerms];
    const [orderedItem] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, orderedItem);

    dispatch(setCustomTerms(newList));
  };

  const handleAddList = async () => {
    const result: TermModalResult = await termModalRef.current?.show({
      mode: 'add',
    });
    if (result?.option === ModalOptions.YES) {
      const newTerm = result.payload;
      const newList = {
        ...newTerm,
        id: v4(),
        versionNo: 1,
        dateCreated: new Date(),
        lastModified: new Date(),
        active: true,
        canApply: true,
      };

      const newLists = global.customTerms.concat(newList);
      dispatch(setFocusCustomList(newList));

      dispatch(setCustomTerms(newLists));
      saveChanges(newLists, true);
    }
  };

  const handleEditList = async (item: CustomTerm) => {
    const result = await termModalRef.current?.show({
      mode: 'edit',
      item,
    });

    if (result?.option === ModalOptions.YES) {
      const editedTerm: CustomTerm = result.payload;
      const foundIndex = global.customTerms.findIndex(
        (term) => term?.id === editedTerm?.id,
      );

      const cloneTerms = [...global.customTerms];
      cloneTerms[foundIndex] = {
        ...editedTerm,
        versionNo: (editedTerm?.versionNo ?? 0) + 1,
        lastModified: new Date(),
      };
      dispatch(setCustomTerms(cloneTerms));
      saveChanges(cloneTerms, true);
    }
  };

  const handleDeleteList = async (item?: CustomTerm) => {
    const result = await confirmModalRef.current?.show();
    if (isNil(item)) return;
    if (result === ModalOptions.YES) {
      const newTerms = global.customTerms.filter((term) => term.id !== item.id);
      saveChanges(newTerms, true);
      dispatch(
        deleteCustomList({
          newCustomTerm: newTerms,
          focusTerm: newTerms[newTerms.length - 1],
        }),
      );
    }
  };

  const saveChanges = async (newTermList: CustomTerm[], hasToast = false) => {
    try {
      const tenantid = getTenantidFromIdToken();
      const saveAsync = UserService.savePreference({
        tenantid,
        customterms: newTermList ? JSON.stringify(newTermList) : undefined,
      });

      if (hasToast) {
        customToast.promise(saveAsync, {
          loading: 'Saving changes...',
          success: 'Saved successfully',
          error: 'Save failed',
        });
      }

      await saveAsync;
    } catch (error: any) {
      console.log('error :>> ', error);
    }
  };

  const hasReachLimit = (): boolean => {
    const isSubscription = tier.isTrial || tier.isPAYG;

    return isSubscription && global?.customTerms?.length >= 2;
  };

  return (
    <div css={!inModal && tw`ml-4 mr-1`} tw="text-sonnant-dark">
      {profile.isLoading && (
        <div
          className="loader__component"
          tw="height[calc(100vh - 13rem)] top[13rem] opacity-100"
        >
          <Loader />
        </div>
      )}

      {!inModal && (
        <div tw="w-full text-right pr-3 font-medium text-15 text-sonnant-dark">
          Terms used {getTermsCount(global.customTerms)} of 1000
        </div>
      )}

      <div tw="flex">
        <div tw="flex-1">
          <div tw="flex w-full justify-between items-center mt-3 mb-6 text-sonnant-dark md-down:(max-width[40rem])">
            <div tw="flex w-full mr-3 justify-end">
              <div tw="mr-6">
                <div className="search-container" tw="sm:hidden! flex -mr-1">
                  <div tw="flex relative">
                    <input
                      className="input-group-field input-search"
                      tw="(height[4rem] rounded-r-none rounded-l pl-4 pr-2 font-size[1.5rem] caret-color[#7F8090] placeholder:(text-14))! md-down:(max-width[12rem] pl-2)!"
                      css={[noneBorder]}
                      type="input"
                      placeholder="Search Custom Vocabs"
                      value={searchTerm}
                      maxLength={128}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <button
                    tw="(rounded-l-none width[4rem] height[4rem] mb-0 rounded-r focus:(bg-white) hover:(border[2px solid #F0F3F6]) box-shadow[none])! md-down:(width[4rem])!"
                    className="button btn-secondary"
                  >
                    {searchTerm ? (
                      <CloseSVG
                        close={() => setSearchTerm('')}
                        color="#7F8090"
                        css={hoverDropShadow(0.2)}
                      />
                    ) : (
                      <Search css={hoverDropShadow(0.2)} />
                    )}
                  </button>
                </div>
              </div>

              <Hint
                disabled={!hasReachLimit()}
                enterDelay={200}
                leaveDelay={100}
                text="Subscribe to get unlimited access to Custom Vocabs"
              >
                <div
                  css={
                    (hasReachLimit() ||
                      !(getTermsCount(global.customTerms) < 1000)) && [
                      pointerCss,
                    ]
                  }
                >
                  <button
                    className="button large btn-primary"
                    tw="max-width[unset]! width[auto]! flex items-center px-5! whitespace-nowrap mb-0"
                    onClick={handleAddList}
                    css={
                      (hasReachLimit() ||
                        !(getTermsCount(global.customTerms) < 1000)) && [
                        disableButton,
                      ]
                    }
                  >
                    <AddTermSvg tw="mr-3" /> Add Custom Vocab
                  </button>
                </div>
              </Hint>
            </div>
          </div>

          {/* <div tw="text-sonnant-blue mb-3 flex justify-between items-center mr-3">
            {!inModal && (
              <div tw="w-full flex justify-end">
                <button
                  className="button btn-secondary large"
                  tw="mb-0 line-height[unset]!"
                  onClick={() => saveChanges(global.customTerms, true)}
                >
                  Save changes
                </button>
              </div>
            )}
          </div> */}

          {/* LIST OF CUSTOM TERMS */}
          <div>
            <div tw="flex w-full justify-between uppercase font-black font-size[12px]">
              <div tw="flex">
                <div tw="mr-6">#</div>
                <div>Custom vocab name</div>
              </div>
              <div tw="flex mr-6 text-sonnant-dark">
                <div tw="mr-[2.4rem]">ENABLE</div>
                <div tw="mr-[1.5rem]">APPLY</div>
                <div tw="mr-[0.7rem]">INSIGHTS</div>
                <div tw="mr-[1.1rem]">EDIT</div>
                <div>DEL</div>
              </div>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="termDnd">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {searchedTerms.map((termList, index) => (
                      <CustomVocabItem
                        key={termList.id}
                        index={index}
                        termList={termList}
                        handleEditList={handleEditList}
                        handleDeleteList={handleDeleteList}
                        onClick={() => {
                          dispatch(setFocusCustomList(termList));
                        }}
                      />
                    ))}
                    {searchedTerms.length === 0 && (
                      <div tw="color[#54566C] text-2xl font-medium mb-6 mt-3">
                        There are no existed Custom Vocab lists
                      </div>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>

        {global.focusCustomList && global.customTerms?.length && (
          <>
            <div tw="border-left[2px solid] border-sonnant-purple-1 mx-8 rounded"></div>

            <div tw="flex-1">
              <ListDetailPane
                onSaveTermsChanges={(newList) => saveChanges(newList, true)}
                isWatchList={false}
              />
            </div>
          </>
        )}
      </div>

      <AddEditTermModal ref={termModalRef} />

      <ConfirmModal
        ref={confirmModalRef}
        title="Delete confirm"
        message="Deleting this list will delete every instance and
        application of this list including published and shared
        items, do you wish to proceed?"
      />
    </div>
  );
};

const pointerCss = css`
  ${tw`
    cursor-not-allowed
  `}
`;
