/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { SortTable } from 'components/shared/SortTable/SortTable';
import { IABItemModel } from 'utils/models';
import { isEmpty, toNumber } from 'lodash';
import { Info } from 'components/shared/Info';
import { iabConfidenceColor } from 'components/shared/twin.styles';

type Props = {
  iabItems: IABItemModel[];
  tableLabel?: string;
  isShowRelevance?: boolean;
};

export const IabReportTable = ({
  tableLabel = 'Default Label',
  iabItems,
  isShowRelevance = false,
}: Props) => {
  const toStringConfidence = (relevance: number): string => {
    if (relevance <= 0.5 && relevance > 0) return 'Low';

    if (relevance > 0.5 && relevance <= 0.94) return 'Medium';

    if (relevance >= 0.95 && relevance <= 1) return 'High';

    return 'N/A';
  };

  return (
    <div tw="text-15">
      <label tw="font-medium text-18 text-sonnant-blue-dark opacity-90">
        {tableLabel}
      </label>

      <SortTable
        columns={[
          { Header: '#', accessor: undefined, styles: { maxWidth: 20 } },
          { Header: 'Category Name', accessor: 'name' },
          {
            Header: isShowRelevance ? 'Relevance' : 'Severity',
            accessor: 'severityLevel',
            styles: { textAlign: 'center', width: '12.5rem' },
          },
        ]}
        data={iabItems}
      >
        {({ original: row, index }) => {
          return (
            <tr>
              <td tw="font-medium">{index + 1}</td>
              <td>
                {isShowRelevance
                  ? `${row?.name} (${row?.id})`
                  : `${row?.name}`}
              </td>
              <td
                css={[
                  tw`uppercase text-center text-white font-medium`,
                  isShowRelevance
                    ? iabConfidenceColor(row.relevance)
                    : severityLevelColor(row.severityLevel),
                ]}
              >
                {isShowRelevance
                  ? toStringConfidence(toNumber(row.relevance))
                  : row?.severityLevel}
              </td>
            </tr>
          );
        }}
      </SortTable>

      {isEmpty(iabItems) && (
        <div tw="margin-top[-4px]">
          <Info text="Nothing to show" />
        </div>
      )}
    </div>
  );
};

type SeverityLevel = IABItemModel['severityLevel'];

export const severityLevelColor = (level: SeverityLevel) => css`
  ${level === 'low severity'
    ? tw`bg-sonnant-green`
    : level === 'medium severity'
    ? tw`bg-sonnant-orange`
    : level === 'high severity'
    ? tw`bg-sonnant-red`
    : // Default N/A severity
      tw`bg-sonnant-purple-1`}
`;
