/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { SimpleMenu } from '@rmwc/menu';
import { Hint } from 'components/shared/Hint';
import { ReactComponent as HorizDotsSvg } from 'assets/Icons/horiz-three-dots.svg';
import { useToggle } from 'react-use';
import { Ripple } from '@rmwc/ripple';
import { ReactComponent as RenameSvg } from 'assets/Icons/EditAttributes.svg';
import { ReactComponent as DeleteSvg } from 'assets/Icons/delete.svg';
import { ReactComponent as MoveUpSvg } from 'assets/Icons/move-up.svg';
import { ReactComponent as MoveDownSvg } from 'assets/Icons/move-down.svg';
import { ReactComponent as MentionSvg } from 'assets/Icons/voice.svg';
import { ReactComponent as AddToCollectionSvg } from 'assets/Icons/folder_add_to.svg';
import { ReactComponent as IntegrateSvg } from 'assets/Icons/power.svg';
import { ReactComponent as AdmarkerIcon } from 'assets/Icons/admarker-black.svg';
import { ReactComponent as InfoIcon } from 'assets/Icons/property.svg';

import {
  hoverDropShadow,
  simpleMenuDisabled,
} from 'components/shared/twin.styles';
import { MoveDirection } from 'utils/enum';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  collectionSelector,
  setSelectedCollectionId,
  toggleShowPropertiesDrawer,
} from 'slices/collection.slice';
import { CollectionSidebarItem } from 'utils/models';
import { HintRestrictedPublish } from 'components/shared/HintRestrictedPublish';

interface Props {
  collectionItem: CollectionSidebarItem;
  onClickRename: () => void;
  onClickDelete: () => void;
  onClickMentionReport: () => void;
  onClickIntegrate: () => void;
  onClickAddVisible: () => void;
  onClickMonetisation: () => void;
  onClickMove: (direction: MoveDirection) => void;
  isFirstCollectionItem?: boolean;
  isLastCollectionItem?: boolean;
  isVisibleCollectionItem?: boolean;
}

export const CollectionItemMenu = (props: Props) => {
  const dispatch = useDispatch();

  const collection = useSelector((state: RootState) => state.collection);
  const collectionStore = useSelector(collectionSelector);

  const [show, toggleShow] = useToggle(false);

  const shouldShowArrowMoveUpDown =
    isEmpty(collection.searchTerm) && !collectionStore.hasFilters;

  const handleCloseMenu = () => {
    const timer = setTimeout(() => {
      toggleShow(false);

      clearTimeout(timer);
    }, 200);
  };

  return (
    <div onMouseLeave={handleCloseMenu}>
      <SimpleMenu
        open={show}
        fixed
        handle={
          <span tw="flex items-center" css={[dotsCss]}>
            <HorizDotsSvg
              css={[hoverDropShadow(0.2)]}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                toggleShow();
              }}
            />
          </span>
        }
        anchorCorner="bottomLeft"
        // @ts-ignore
        css={[tw`min-height[100px] py-1`]}
      >
        {shouldShowArrowMoveUpDown && (
          <Hint text="Move up" leaveDelay={0}>
            <Ripple
              onClick={(e) => {
                e.stopPropagation();
                toggleShow(false);

                props?.onClickMove(MoveDirection.UP);
              }}
            >
              <div
                css={[
                  itemCss,
                  props.isFirstCollectionItem && simpleMenuDisabled,
                ]}
              >
                <MoveUpSvg height={20} width={24} />
                Move Up
              </div>
            </Ripple>
          </Hint>
        )}

        {shouldShowArrowMoveUpDown && (
          <Hint text="Move down" leaveDelay={0}>
            <Ripple
              onClick={(e) => {
                e.stopPropagation();
                toggleShow(false);

                props?.onClickMove(MoveDirection.DOWN);
              }}
            >
              <div
                css={[
                  itemCss,
                  props.isLastCollectionItem && simpleMenuDisabled,
                ]}
              >
                <MoveDownSvg height={20} width={24} />
                Move Down
              </div>
            </Ripple>
          </Hint>
        )}

        <Hint text="Rename collection" leaveDelay={0}>
          <Ripple
            onClick={(e) => {
              e.stopPropagation();
              toggleShow(false);

              props?.onClickRename();
            }}
          >
            <div css={[itemCss]}>
              <RenameSvg />
              Rename
            </div>
          </Ripple>
        </Hint>

        {!props?.isVisibleCollectionItem && (
          <Hint text="Add to Visible Collections" leaveDelay={0}>
            <Ripple
              onClick={(e) => {
                e.stopPropagation();
                toggleShow(false);

                props?.onClickAddVisible();
              }}
            >
              <div css={[itemCss]}>
                <AddToCollectionSvg height={24} width={24} fill="black" />
                Add to Visible Collections
              </div>
            </Ripple>
          </Hint>
        )}

        <Hint text="Mention report" leaveDelay={0}>
          <Ripple
            onClick={(e) => {
              e.stopPropagation();
              toggleShow(false);

              props?.onClickMentionReport();
            }}
          >
            <div css={[itemCss]}>
              <MentionSvg css={[tw`[> path:nth-of-type(2)]:fill[#000]`]} />
              Mentions report
            </div>
          </Ripple>
        </Hint>

        <HintRestrictedPublish>
          <Hint text="Integrate" leaveDelay={0}>
            <Ripple
              onClick={(e) => {
                e.stopPropagation();
                toggleShow(false);

                props?.onClickIntegrate();
              }}
            >
              <div css={[itemCss]}>
                <IntegrateSvg css={[tw`[> path:nth-of-type(2)]:fill[#000]`]} />
                Integrate
              </div>
            </Ripple>
          </Hint>
        </HintRestrictedPublish>

        <Hint text="Monetisation configuration" leaveDelay={0}>
          <Ripple
            onClick={(e) => {
              e.stopPropagation();
              toggleShow(false);

              props?.onClickMonetisation();
            }}
          >
            <div css={[itemCss]}>
              <AdmarkerIcon css={[tw`[> path:nth-of-type(2)]:fill[#000]`]} />
              Monetisation configuration
            </div>
          </Ripple>
        </Hint>

        <Hint text="Collection properties" leaveDelay={0}>
          <Ripple
            onClick={(e) => {
              e.stopPropagation();
              toggleShow(false);

              dispatch(setSelectedCollectionId(props.collectionItem.id));
              dispatch(toggleShowPropertiesDrawer(true));
            }}
          >
            <div css={[itemCss]}>
              <InfoIcon css={[tw`[> path:nth-of-type(2)]:fill[#000]`]} />
              Properties
            </div>
          </Ripple>
        </Hint>

        <Hint
          text="Deleting a collection is irreversible. This will just delete the collection. Any content items in this collection will still remain in your library."
          leaveDelay={0}
        >
          <Ripple
            onClick={(e) => {
              e.stopPropagation();
              toggleShow(false);

              props?.onClickDelete();
            }}
          >
            <div css={[itemCss]}>
              <DeleteSvg />
              Delete
            </div>
          </Ripple>
        </Hint>
      </SimpleMenu>
    </div>
  );
};

const itemCss = css`
  ${tw`flex items-center font-normal padding[.8rem] px-5 text-15 text-sonnant-dark`}

  svg {
    ${tw`mr-4`}
  }
`;

const dotsCss = css`
  :hover {
    /* ${tw`rounded border[1px solid #4c48ff]`} */

    svg {
      ${tw`fill[#4c48ff] cursor-pointer`}
    }
  }
`;
