/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { PinnedChartItem } from './PinnedChartItem';
import { useMount } from 'react-use';
import { InsightService } from 'services/insight.service';
import { setPinnedCharts } from 'slices/insight.slice';

type Props = {};

export const PinnedCharts = (props: Props) => {
  const dispatch = useDispatch();

  const insight = useSelector((state: RootState) => state.insight);

  const pinnedCharts = insight.pinnedCharts;

  useMount(async () => {
    try {
      const { data } = await InsightService.getPinnedCharts();

      dispatch(setPinnedCharts(data));
    } catch (error) {
      console.log('error :>> ', error);
    }
  });

  if (isEmpty(pinnedCharts)) return null;

  return (
    <div tw="mx-3 mt-2 w-full">
      {pinnedCharts?.map((pinnedInsight) => (
        <PinnedChartItem key={pinnedInsight.id} pinnedInsight={pinnedInsight} />
      ))}
    </div>
  );
};
