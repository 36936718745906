import { Dispatch, SetStateAction, useState } from 'react';

type ResponseType<TValues> = [
  TValues,
  Dispatch<SetStateAction<TValues>>,
  () => void,
];

export const useStateReset = <TValues>(
  defaultState: TValues | (() => TValues),
): ResponseType<TValues> => {
  const [state, setState] = useState<TValues>(defaultState);

  const resetState = () => {
    setState(defaultState);
  };

  return [state, setState, resetState];
};
