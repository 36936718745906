import { BaseAPI } from 'apis/amazon';
import { AxiosResponse } from 'axios';
import { CreatedReportsPayload } from 'utils/models';

export interface downloadReportPayload {
  report_url: string;
}

export const ReportService = {
  // GET /mention-reports
  getCreatedReports: (): Promise<AxiosResponse<CreatedReportsPayload[]>> => {
    return BaseAPI.get('/media/mention-reports');
  },

  // DELETE /mention-reports
  deleteCreatedReports: (reportIds: string[]): Promise<AxiosResponse> => {
    return BaseAPI.delete('/media/mention-reports', {
      data: {
        report_ids: reportIds,
      },
    });
  },

  // PUT /mention-reports
  putCreatedReports: (
    reportId: string,
    reportTitle: string,
  ): Promise<AxiosResponse> => {
    return BaseAPI.put(`/media/mention-reports?reportid=${reportId}`, {
      report_title: reportTitle,
    });
  },

  // POST /mention-reports
  downloadCreatedReports: (
    reportId: string,
  ): Promise<AxiosResponse<downloadReportPayload>> => {
    return BaseAPI.post('/media/mention-reports', {
      report_id: reportId,
    });
  },
};
