/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { Ripple } from '@rmwc/ripple';
import { ReactComponent as BookmarkSvg } from 'assets/Icons/bookmark.svg';
import { secToTime } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { isEmpty, isNil, orderBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setCurrentChapterId } from 'slices/chapter.slice';
import { IChapterRaw } from 'utils/models/transcript.model';
import { EmbedLayerEmpty } from '../../shared/EmbedLayerEmpty';
import { useEmbedContext } from '../../utils/useEmbedContext';

interface IProps {}

export const EmbedChapterViewer = (props: IProps) => {
  const chapterSlice = useSelector((state: RootState) => state.chapter);

  const chapters = orderBy(chapterSlice.chapter, (c) => c.startTime, ['asc']);

  return (
    <div tw="flex flex-col">
      {chapters?.map((chapter) => (
        <ChapterItem key={chapter.id} {...chapter} />
      ))}

      {isEmpty(chapters) && <EmbedLayerEmpty />}
    </div>
  );
};

const ChapterItem = (chapter: IChapterRaw) => {
  const dispatch = useDispatch();
  const { controls } = useEmbedContext();

  const { currentChapterId } = useSelector((state: RootState) => state.chapter);

  const isSelected = chapter.id === currentChapterId;

  const handleClickChapter = () => {
    if (isNil(chapter) || isNil(chapter.id)) return;

    dispatch(setCurrentChapterId(chapter.id));

    controls.seek(chapter.startTime);
  };

  return (
    <Ripple css={isSelected && tw`bg-sonnant-grey-3`}>
      <div
        tw="flex gap-x-2.5 text-16 py-2 rounded cursor-pointer"
        onClick={handleClickChapter}
      >
        <span tw="flex items-center">
          <BookmarkSvg />
        </span>

        <span tw="font-mono!">{secToTime(chapter.startTime)}</span>

        <span>-</span>

        <span className="line-clamp-1" title={chapter.headline}>
          {chapter.headline}
        </span>
      </div>
    </Ripple>
  );
};
