/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { RevivalItem } from './RevivalItem/RevivalItem';
import { useEffectOnce, useToggle } from 'react-use';
import { useEffect, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import {
  GroupRevival,
  ILibraryItem,
  RevivalPagination,
  ShareSocialTrending,
} from 'utils/models';
import {
  getShareUrlAsync,
  groupRawRevivalsByTime,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import Loader from 'components/loader/loader';
import { cloneDeep, isEmpty, toNumber } from 'lodash';
import { Modal } from 'components/UiControls/modal/modal';
import { Publish } from 'components/Publish/Publish';
import { Share } from 'components/Share/Share';
import { Export } from 'components/Export/Export';
import { PreviewClipModal } from 'components/shared/PreviewClipModal/PreviewClipModal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { SortTable } from 'components/shared/SortTable/SortTable';
import { RevivalListItems } from './RevivalItem/RevivalListItem';
import { setFocusNotification } from 'slices/notification.slice';
import { Social } from 'utils/enum';
import { ReviveService } from 'services';
import { setSelectMode } from 'actions';
import { toggleAdvancedFilter } from 'slices/advanced-search.slice';

export const RevivalTab = () => {
  const libraryItem = useSelector(
    (state: RootState) => state.revival.focusRevival?.libraryItem,
  );
  const focusNotification = useSelector(
    (state: RootState) => state.notification.focusNotification,
  );
  const location = useSelector(
    (state: RootState) => state.userProfile.location,
  );
  const advancedSearch = useSelector(
    (state: RootState) => state.advancedSearch,
  );

  const paginationReducer = useSelector((state: RootState) => state.pagination);

  const [revivalList, setRevivalList] = useState<GroupRevival[]>([]);
  const [filteredList, setFilteredList] = useState<GroupRevival[]>([]);

  const [apiLoading, toggleApiLoading] = useToggle(false);
  const [isOpenPublishModal, toggleOpenPublishModal] = useToggle(false);
  const [isOpenShareModal, toggleOpenShareModal] = useToggle(false);
  const [isOpenExportModal, toggleOpenExportModal] = useToggle(false);
  const [shareItem, setShareItem] = useState<any>(null);

  const previewModalRef = useRef<any>(null);
  const dispatch = useDispatch();

  const isListView = paginationReducer.isListView;

  useEffect(() => {
    if (advancedSearch.isShowAdvancedFilter) {
      dispatch(toggleAdvancedFilter(false));
    }
  }, []);

  useEffect(() => {
    if (!focusNotification || isEmpty(revivalList)) return;

    const cloneRevivals = cloneDeep(revivalList)
      .map((group) => {
        group.items = group.items.filter(
          (i) => i?.libraryItem?.mediaid === focusNotification?.mediaid,
        );
        return group;
      })
      .filter((group) => !isEmpty(group.items));

    setFilteredList(cloneRevivals);
  }, [focusNotification, revivalList]);

  useEffectOnce(() => {
    fetchRevivalItems();
    dispatch(setSelectMode(false));

    return () => {
      dispatch(setFocusNotification(null));
    };
  });

  const fetchRevivalItems = async () => {
    try {
      toggleApiLoading(true);
      const { data }: AxiosResponse<RevivalPagination> =
        await ReviveService.getReviveItems();

      const revivalItems = groupRawRevivalsByTime(data, location);

      setRevivalList(revivalItems);
    } catch (err: any) {
      console.log('err :>> ', err);
    } finally {
      toggleApiLoading(false);
    }
  };

  const handleCloseModal = () => {
    toggleOpenPublishModal(false);
    toggleOpenShareModal(false);
    toggleOpenExportModal(false);

    setShareItem(null);
  };

  const handleShowPreviewPublish = (startTime: number, endTime: number) => {
    previewModalRef?.current?.show({ startTime, endTime });
  };

  const handleShowPreviewShare = (item: any) => {
    if (!libraryItem) return;

    const options = {
      startTime: item.starttime,
      endTime: item.endtime,
      mediaid: libraryItem.mediaid,
      title: item.versionname,
      versioncount: item.versioncount,
    };
    previewModalRef?.current?.show(options);
  };

  const handleShareSocial = async (
    item: Partial<ILibraryItem>,
    social: Social,
  ) => {
    if (!item?.mediaid) return;

    const shareURL = await getShareUrlAsync(item.mediaid);
    if (!shareURL) return;

    const sharePayload: ShareSocialTrending = {
      mediaid: item.mediaid,
      shareURL,
      shareId: shareURL?.split('-').splice(-1)[0],
      provider: social,
    };

    setShareItem(sharePayload);
    toggleOpenShareModal(true);
  };

  return (
    <>
      <div tw="py-6 px-2 height[calc(100vh - 12rem)] overflow-y-auto">
        {apiLoading && (
          <div
            className="loader__component"
            tw="top[13rem] opacity-100 flex justify-center items-center"
          >
            <Loader tw="position[unset]! transform[unset]!" />
          </div>
        )}
        {!isListView ? (
          <>
            {(focusNotification ? filteredList : revivalList).map((list) => (
              <div>
                <div tw="text-16 ml-8! mb-3 font-medium text-sonnant-grey-6">
                  {list.type}
                </div>
                <div tw="flex flex-wrap">
                  {list.items.map((revival) => (
                    <RevivalItem
                      revival={revival}
                      isToday={list.type?.startsWith('Today')}
                      listId={list.id}
                      toggleOpenExportModal={toggleOpenExportModal}
                      toggleOpenShareModal={toggleOpenShareModal}
                      toggleOpenPublishModal={toggleOpenPublishModal}
                      onOpenShareModal={handleShareSocial}
                    />
                  ))}
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            {(focusNotification ? filteredList : revivalList).map((list) => (
              <div
                className="listViewTable"
                tw="(position[unset] z-index[unset])"
              >
                <div tw="mb-3 ml-8 -mt-1 border-sonnant-grey-5 text-sonnant-grey-6 font-size[1.6rem] font-medium pt-3! mx-5">
                  {list.type}
                </div>
                <div css={revivalTable}>
                  <SortTable
                    columns={
                      [
                        { Header: ' ', width: '1%' },
                        {
                          Header: 'Type',
                        },
                        {
                          Header: 'Title',
                          accessor: (item: any) => item.libraryItem.title,
                          width: '100%',
                        },
                        {
                          Header: 'Headline',
                          accessor: 'headline',
                          width: '100%',
                        },
                        {
                          Header: 'Trending on',
                          accessor: 'trendingOn',
                        },
                        {
                          Header: 'Top tags',
                          accessor: 'topTags',
                        },
                        {
                          Header: 'Trending tags',
                          accessor: 'twitterTrendingTags',
                        },
                        { Header: '  ' },
                      ] as any
                    }
                    data={list.items}
                  >
                    {({ original: revival }) => (
                      <RevivalListItems
                        revival={revival}
                        toggleOpenExportModal={toggleOpenExportModal}
                        toggleOpenShareModal={toggleOpenShareModal}
                        toggleOpenPublishModal={toggleOpenPublishModal}
                        onOpenShareModal={handleShareSocial}
                      />
                    )}
                  </SortTable>
                </div>
              </div>
            ))}
          </>
        )}

        <Modal
          classes="publish-modal-width"
          show={isOpenPublishModal}
          modalClosed={handleCloseModal}
        >
          <Publish
            mediaid={libraryItem?.mediaid!}
            filename={libraryItem?.title!}
            duration={toNumber(libraryItem?.length)}
            closeShareModal={handleCloseModal}
            isOpen={isOpenPublishModal}
            handleShowPreview={handleShowPreviewPublish}
            isRevival={true}
          />
        </Modal>
        <Modal
          show={isOpenShareModal}
          modalClosed={handleCloseModal}
          classes="share-modal-width"
        >
          <Share
            mediaid={libraryItem?.mediaid!}
            shareItem={shareItem}
          />
        </Modal>
        <Modal show={isOpenExportModal} modalClosed={handleCloseModal}>
          <Export
            mediaid={libraryItem?.mediaid!}
            filename={libraryItem?.title!}
            closeExportModal={handleCloseModal}
            handleShowPreview={handleShowPreviewShare}
          />
        </Modal>
        <PreviewClipModal ref={previewModalRef} />
      </div>
    </>
  );
};

const revivalTable = css`
  th,
  td {
    :nth-of-type(2) {
      ${tw`text-left`}
    }

    :nth-of-type(4) {
      ${tw`2xl-up:min-width[30rem]! min-width[15rem]`}
    }
    :nth-of-type(5) {
      ${tw`text-center 2xl-up:min-width[20rem]! min-width[13rem]`}
    }
    :nth-of-type(6) {
      ${tw`2xl-up:min-width[30rem]! min-width[15rem]`}
    }
    :nth-of-type(7) {
      ${tw`min-width[15rem] 2xl-up:min-width[25rem]`}
    }
    :nth-of-type(8) {
      ${tw`min-width[6.5rem]`}
    }
  }
  th {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 14px;
    white-space: nowrap;
  }
  td {
    font-size: 1.4rem;
  }
  table thead tr {
    position: static;
    z-index: unset !important;
    th {
      ${tw`text-sonnant-dark! font-medium opacity-90`}
    }
  }
  tbody tr {
    :hover {
      ${tw`bg-sonnant-grey-light cursor-pointer`}
    }
  }
`;
