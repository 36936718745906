import { useEffect, useState } from 'react';

export const useCountdown = (currentTime: string) => {

  const [timeLeft, setTimeLeft] = useState(
    () => new Date(currentTime).getTime() - new Date().getTime(),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((current) => {
        if (current <= 0) {
          clearInterval(interval);

          return 0;
        }
        return current - 1000;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return timeLeft;
};
