/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { CollapsibleList } from '@rmwc/list';
import { Ripple } from '@rmwc/ripple';
import { first, isEmpty, uniqBy } from 'lodash';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';

import { ReactComponent as OpenNewTabSvg } from 'assets/Icons/open_in_new.svg';

import { Hint } from 'components/shared/Hint';
import { scrollbarWidthCss, textLink } from 'components/shared/twin.styles';
import { TrendingBucketKeyValue, TrendingTermSearchInsight } from 'services';

type Props = {
  index: number;
  trendingTerm: TrendingTermSearchInsight;
  isSelected: boolean;

  onClick: (selectedTrendingTerm: TrendingTermSearchInsight) => void;
};

export const TrendingTermItem = ({
  index,
  trendingTerm,
  isSelected,
  ...props
}: Props) => {
  const displayTermName = trendingTerm.alias ?? trendingTerm.term;

  const [isExpanded, toggleExpanded] = useToggle(false);

  const mediaTrendingList: TrendingBucketKeyValue[] = useMemo(() => {
    const results = trendingTerm?.term_count_over_time?.buckets?.flatMap(
      (bucket) => bucket?.media_ids?.buckets,
    );

    return uniqBy(results, 'key');
  }, [trendingTerm]);

  const firstMediaTrending = first(mediaTrendingList);

  const handleClickTrendingTerm = () => {
    toggleExpanded(true);

    props.onClick(trendingTerm);
  };

  return (
    <div
      tw="flex w-full bg-sonnant-grey-1 rounded"
      css={[childrenAnimationCss(isExpanded)]}
      onClick={(e) => e.stopPropagation()}
    >
      <CollapsibleList
        handle={
          <Ripple onClick={handleClickTrendingTerm}>
            <div
              tw="flex w-full min-h-[8rem] justify-between items-center gap-x-8 px-10 py-4 cursor-pointer"
              css={[isSelected && tw`bg-sonnant-lavender`]}
            >
              <div tw="flex flex-1 flex-row text-16 gap-x-8 w-full text-sonnant-dark">
                <span tw="opacity-90 font-medium">{index}.</span>

                <div tw="flex flex-col gap-y-1.5 w-full">
                  <div tw="w-1/3">
                    <Hint
                      text={`Alias of "${trendingTerm.term}"`}
                      disabled={isEmpty(trendingTerm.alias)}
                      enterDelay={200}
                      leaveDelay={100}
                      notTransparent
                      arrow
                    >
                      <span
                        css={[!isEmpty(trendingTerm?.alias) && tw`font-medium`]}
                      >
                        {displayTermName}
                      </span>
                    </Hint>
                  </div>

                  <div tw="flex flex-row w-full gap-x-8">
                    <div
                      className="line-clamp-3"
                      title={trendingTerm.tagline}
                      tw="text-14 font-normal text-sonnant-blue w-1/3"
                    >
                      {trendingTerm.tagline}
                    </div>

                    <div tw="flex justify-start items-end text-14 font-normal text-sonnant-dark opacity-80 w-2/3">
                      <span
                        className="line-clamp-3"
                        title={firstMediaTrending?.title}
                      >
                        {firstMediaTrending?.title}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <img
                src={firstMediaTrending?.thumbnail}
                alt={`trending-item-thumbnail-${trendingTerm.term}`}
                tw="w-24 h-24 rounded-xl object-cover shadow-md"
              />
            </div>
          </Ripple>
        }
      >
        {isExpanded && (
          <div tw="flex flex-col gap-y-2 py-2 pb-2 bg-white pr-2">
            <div
              tw="flex flex-col max-h-[30rem] overflow-y-auto"
              css={[scrollbarWidthCss(4)]}
            >
              {mediaTrendingList.map((item, index) => (
                <div
                  key={index}
                  tw="flex pl-10 pr-10 py-2 justify-between items-center flex-row w-full select-none [label]:(font-medium) gap-x-5 hover:bg-sonnant-grey-2"
                >
                  <Link
                    to={`/transcription/${item.key}?search=${trendingTerm.term}`}
                    css={[
                      textLink,
                      tw`px-10 text-14 font-normal text-sonnant-grey-6 opacity-95 flex items-center`,
                    ]}
                    target="_blank"
                  >
                    <OpenNewTabSvg tw="mr-2" fill="grey" />
                    <span tw="max-w-[40rem] truncate">{item.title}</span>
                  </Link>

                  <img
                    src={item.thumbnail}
                    alt={`media-bucket-thumbnail-${item.key}`}
                    tw="w-20 h-20 rounded-xl object-cover shadow"
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </CollapsibleList>
    </div>
  );
};

const childrenAnimationCss = (isExpanded: boolean) => css`
  .rmwc-collapsible-list__children {
  }
`;
