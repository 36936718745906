/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Drawer, DrawerContent } from '@rmwc/drawer';
import { ReactComponent as CloseSvg } from 'assets/Icons/close.svg';
import { msToDayFormat } from 'components/VideoPlayer/Transcription/MediaUtilities';
import Loader from 'components/loader/loader';
import { Option } from 'components/shared/CustomSelectSearch';
import { LabelInput } from 'components/shared/LabelInput';
import { TagSelectionDropdown } from 'components/shared/TagSelectionDropdown';
import { VocabSelectionDropdown } from 'components/shared/VocabSelectionDropdown';
import { hoverDropShadow, svgShadow } from 'components/shared/twin.styles';
import { useQueryCollectionById } from 'hooks/queries/useQueryCollectionById';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  CollectionService,
  UpdateCollectionVocabsPayload,
} from 'services/collection.service';
import {
  fetchCollections,
  toggleShowPropertiesDrawer,
} from 'slices/collection.slice';
import { fetchAllTags } from 'slices/tag.slice';
import { toDateWithHours } from 'utils/date.util';
import { CustomTerm } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { extract } from 'utils/generic.util';
import { useCancelQuery } from 'hooks/useCancelQuery';
import { useToggle } from 'react-use';

type Props = {};

export const CollectionInfoDrawer = (props: Props) => {
  const dispatch = useDispatch();

  const [shouldFetchCollections, toggleFetchCollections] = useToggle(false);

  const updateVocabsAPI = useCancelQuery({
    queryFn: CollectionService.updateCollectionVocab,
  });

  const collection = useSelector((state: RootState) => state.collection);
  const global = useSelector((state: RootState) => state.global);

  const globalVocabs = global.customTerms;
  const globalVocabIds = extract(globalVocabs, 'id');

  const currentCollection = collection.items.find(
    (item) => item.id === collection.selectedId,
  );

  const { data: properties, isLoading } = useQueryCollectionById({
    collectionId: currentCollection?.id,
  });

  const statistics = properties?.statistics;
  const vocabList = properties?.vocabList;

  const collectionVocabs = useMemo(() => {
    if (isNil(vocabList)) return [];

    const collectionGlobalVocabIds = vocabList?.globalList ?? [];
    const collectionCustomVocabs = vocabList?.customList ?? [];

    const selectedGlobalVocabs = globalVocabs.filter(({ id }) =>
      collectionGlobalVocabIds.includes(id),
    );

    return selectedGlobalVocabs.concat(collectionCustomVocabs);
  }, [globalVocabs, vocabList]);

  const handleCloseDrawer = () => {
    dispatch(toggleShowPropertiesDrawer(false));

    if (shouldFetchCollections) {
      dispatch(fetchCollections());
    }

    toggleFetchCollections(false);
  };

  const handleSaveTags = async (tags: Option[], isFetchCollection: boolean) => {
    if (!currentCollection?.id) return;

    try {
      await CollectionService.addTagsToCollectionId({
        collectionId: currentCollection?.id,
        tagIds: tags.map((tag) => tag.value),
      });

      dispatch(fetchAllTags());

      if (isFetchCollection) {
        toggleFetchCollections(true);
      }
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const handleSaveVocabs = (selectedVocabs: CustomTerm[]) => {
    if (!currentCollection?.id) return;

    const globalVocabsPayload = selectedVocabs.filter(isGlobalVocab(true));
    const customVocabsPayload = selectedVocabs.filter(isGlobalVocab(false));

    updateCollectionVocabsAsync({
      collectionId: currentCollection.id,
      globalVocabIds: extract(globalVocabsPayload, 'id'),
      customVocabs: customVocabsPayload,
    });
  };

  const isGlobalVocab = (isGlobal: boolean) => (vocabList: CustomTerm) => {
    return globalVocabIds.includes(vocabList.id) === isGlobal;
  };

  const updateCollectionVocabsAsync = async (
    params: UpdateCollectionVocabsPayload,
  ) => {
    const updateAsync = updateVocabsAPI.queryAsync(params);

    try {
      customToast.loading('Updating...');
      await updateAsync;
      customToast.success('Done');
    } catch (error) {
      console.log('error :>> ', error);
      customToast.dismiss();
    }
  };

  return (
    <Drawer
      dir="rtl"
      modal
      open={collection.showPropertiesDrawer}
      onClose={handleCloseDrawer}
      tw="(z-[6] h-[calc(100vh - 7rem)] w-[30rem])!"
    >
      <DrawerContent dir="ltr">
        <div tw="flex flex-col gap-y-3 bg-sonnant-grey-1 h-full py-5 px-6 text-15">
          {isLoading && (
            <div tw="absolute top[0] left[0] w-full h-full flex items-center justify-center z-[1] bg-white opacity[0.96]">
              <Loader />
            </div>
          )}

          <div tw="opacity-[.95]">
            <div tw="flex justify-between items-center text-18 font-medium">
              <span>Collection Properties</span>
              <span onClick={handleCloseDrawer}>
                <CloseSvg
                  tw="[> path]:(fill[#54566c])"
                  css={[svgShadow(0.3), hoverDropShadow(0.5)]}
                />
              </span>
            </div>

            <small>Modified: {toDateWithHours(properties?.modifiedAt)}</small>
          </div>

          <div>
            <LabelInput
              label={'Name'}
              value={currentCollection?.name}
              readOnly
            />
          </div>

          <div>
            <label>Collection statistics</label>
            <div tw="text-14 text-sonnant-grey-6 ml-5 mt-2">
              <div>
                Files: <strong>{statistics?.totalFiles}</strong>
              </div>
              <div>
                Audio Files: <strong>{statistics?.totalAudio}</strong>
              </div>
              <div>
                Video Files: <strong>{statistics?.totalVideo}</strong>
              </div>
              <div>
                <div>
                  Duration:{' '}
                  <strong>
                    {msToDayFormat(statistics?.totalDurations ?? 0)}
                  </strong>
                </div>
              </div>
            </div>
          </div>

          <div>
            <label>Tags</label>
            <TagSelectionDropdown
              defaultTags={properties?.tagItems ?? []}
              onSaveTags={handleSaveTags}
            />
          </div>

          <div tw="z-0">
            <label>Custom Vocabs</label>
            <VocabSelectionDropdown
              defaultVocabs={collectionVocabs}
              onSaveVocabs={handleSaveVocabs}
              isLoading={isLoading}
            />
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
