/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import React, {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Modal } from 'components/UiControls/modal/modal';
import { Keys, ModalOptions } from 'utils/enum';
import { isEmpty } from 'lodash';

type IPromiseParams = {
  resolve: (payload: SingleNamePayload) => void;
  reject: (err: any) => void;
};

type SingleNamePayload = {
  option: ModalOptions;
  singleName?: string;
};

export type SingleNameCreationModalRef = {
  show: () => SingleNamePayload;
};

type Props = {
  title: string;
  label: string;
  placeHolder: string;
  confirmText: string;
};

export const SingleNameCreationModal = forwardRef(
  (
    { title, label, placeHolder, confirmText, ...props }: Props,
    ref: React.Ref<unknown>,
  ): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const [isOpen, setIsOpen] = useState(false);

    const [singleName, setSingleName] = useState('');

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (): Promise<unknown> => {
      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const hideModal = () => {
      setSingleName('');
      setIsOpen(false);
    };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current?.resolve({ option: ModalOptions.CANCEL });
    };

    const handleYes = () => {
      if (isEmpty(singleName.trim())) return;

      hideModal();
      promiseInfo.current?.resolve({
        option: ModalOptions.YES,
        singleName: singleName,
      });
    };

    return (
      <div css={[customModalCss]}>
        <Modal
          // classes={props?.classes}
          show={isOpen}
          modalClosed={() => {
            hideModal();
            promiseInfo.current?.resolve({ option: ModalOptions.CLOSE });
          }}
        >
          <div>
            <h2 tw="font-semibold text-sonnant-dark">{title}</h2>

            <form
              tw="mt-4"
              autoComplete="off"
              onSubmit={(e) => e.preventDefault()}
            >
              <label>{label}</label>
              <input
                type="text"
                placeholder={placeHolder}
                value={singleName}
                onChange={(e) => setSingleName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
                    handleYes();
                  }
                }}
                autoFocus
              />
            </form>

            <div tw="mt-9">
              <div tw="w-full flex justify-end">
                <button
                  type="button"
                  className="button btn-secondary large"
                  tw="flex items-center justify-center mr-6 hover:(border-sonnant-grey-3!)"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="button btn-primary large"
                  tw="flex items-center justify-center"
                  disabled={isEmpty(singleName.trim())}
                  onClick={handleYes}
                >
                  {confirmText}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  },
);

const customModalCss = css`
  .Modal {
    ${tw`(width[52rem] left[calc(50% - 26rem)])!`}
  }
`;
