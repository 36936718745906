import React from 'react';

import { isNil } from 'lodash';
import { MediaPlayerHook } from './embed.model';

type State = MediaPlayerHook;

export const EmbedContext = React.createContext<State | undefined>(undefined);

export const useEmbedContext = () => {
  const context = React.useContext(EmbedContext);

  if (isNil(context)) {
    throw new Error(
      'useEmbedContext must be included within EmbedContextProvider',
    );
  }

  return context;
};
