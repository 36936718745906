/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

type Props = {
  onClickApply: () => void;
  onClickReset: () => void;
  onClickCancel: () => void;
};

export const DemographicsButtons = ({ ...props }: Props) => {
  return (
    <div tw="flex flex-1 flex-row justify-between items-center pt-4">
      <button
        type="button"
        className="button btn-secondary large apply"
        css={[demographicsButtonCss]}
        onClick={() => props.onClickReset()}
      >
        Reset
      </button>

      <div tw="flex flex-row gap-x-5">
        <button
          type="button"
          className="button btn-secondary large apply"
          css={[demographicsButtonCss]}
          onClick={() => props.onClickCancel()}
        >
          Cancel
        </button>

        <button
          type="button"
          className="button btn-primary large"
          tw="(max-w-[unset] w-auto px-3.5)!"
          css={[demographicsButtonCss]}
          onClick={() => props.onClickApply()}
        >
          Apply Changes
        </button>
      </div>
    </div>
  );
};

const demographicsButtonCss = css`
  ${tw`flex justify-center items-center mb-0`}
`;
