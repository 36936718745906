import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { defaultMegaphoneSetting } from 'utils/default/defaultSetting';
import { MegaphoneIntegrationOption } from 'utils/models';

const megaphoneSlice = createSlice({
  name: 'megaphone',
  initialState: {
    settings: [] as MegaphoneIntegrationOption[],
  },
  reducers: {
    setMegaphoneSettings: (
      state,
      { payload }: PayloadAction<MegaphoneIntegrationOption[]>,
    ) => {
      state.settings = payload;
    },
    updateSingleMegaphoneIntegration: (
      state,
      { payload }: PayloadAction<MegaphoneIntegrationOption>,
    ) => {
      const foundIndex = state.settings.findIndex(
        ({ id }) => id === payload.id,
      );

      if (foundIndex >= 0) {
        state.settings[foundIndex] = payload;
      }
    },
    addMegaphoneIntegration: (state) => {
      state.settings.push(defaultMegaphoneSetting);
    },
    addMegaphoneIntegrationFilter: (
      state,
      { payload }: PayloadAction<MegaphoneIntegrationOption>,
    ) => {
      const foundIndex = state.settings.findIndex(
        ({ id }) => id === payload.id,
      );

      if (foundIndex >= 0) {
        const newFilter = { ...state.settings[foundIndex].filter };

        newFilter.programFilters.push({
          networkId: '',
          programId: '',
        });

        state.settings[foundIndex].filter = newFilter;
      }
    },
    removeMegaphoneIntegrationFilter: (
      state,
      {
        payload,
      }: PayloadAction<{
        integration: MegaphoneIntegrationOption;
        index: number;
      }>,
    ) => {
      const { integration, index } = payload;

      const foundIndex = state.settings.findIndex(
        ({ id }) => id === integration.id,
      );

      if (foundIndex >= 0) {
        const newFilter = { ...state.settings[foundIndex].filter };

        if (newFilter.programFilters.length === 1) {
          const defaultFilter = {
            networkId: '',
            programId: '',
          };

          newFilter.programFilters[0] = defaultFilter;

          state.settings[foundIndex].filter = newFilter;

          return;
        }

        newFilter.programFilters.splice(index, 1);
        state.settings[foundIndex].filter = newFilter;
      }
    },
    removeMegaphoneIntegration: (
      state,
      { payload }: PayloadAction<{ integrationId: string }>,
    ) => {
      if (isEmpty(payload?.integrationId)) return;

      state.settings = state.settings.filter(
        (i) => i.id !== payload.integrationId,
      );
    },
  },
});

export const {
  setMegaphoneSettings,
  updateSingleMegaphoneIntegration,
  addMegaphoneIntegration,
  addMegaphoneIntegrationFilter,
  removeMegaphoneIntegrationFilter,
  removeMegaphoneIntegration,
} = megaphoneSlice.actions;

export const megaphoneReducer = megaphoneSlice.reducer;
