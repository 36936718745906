import { INodeData } from 'components/shared/RichTextEditor/rich-text.model';
import { first, last, isNil } from 'lodash';
import {
  Ancestor,
  Editor,
  InsertNodeOperation,
  Node,
  NodeEntry,
  Path,
  Transforms,
} from 'slate';
import { ReactEditor } from 'slate-react';
import { CustomElement } from 'types/slate-custom-types';
import { customToast } from './toast.util';

export const breakCaption = (parentNode: NodeEntry<Ancestor> | any, isBreakChapter?: boolean) => {
  const editor = window?.Editor;
  const cursor: CustomElement = editor.getFragment()[0];
  const node = Node.get(window.Editor, parentNode);

  const cursorWord = cursor?.children?.[0];

  // Break caption at the beginning
  if (!isBreakChapter && cursorWord?.data?.dataStart <= cursor?.data?.start) {
    customToast.error('Not enough space for new line');
    return;
  }

  // Break caption at the end
  if (!isBreakChapter && cursorWord?.data?.dataEnd >= cursor?.data?.end) {
    if (!canAdd(parentNode)) {
      customToast.error('Not enough space for new line');
    } else {
      handleAddTranscript(node);
    }
    return;
  }

  editor.insertBreak();
  const curNode: any = Editor.node(editor, parentNode);
  const nextNode: any = Editor.next(editor, { at: parentNode });
  if (!curNode || !nextNode) return;

  // Update current NODE end
  const lastWordCur: INodeData = last(curNode?.[0]?.children)!;
  Transforms.setNodes(
    editor,
    {
      data: {
        ...curNode[0].data,
        end: lastWordCur?.data?.dataStart,
      },
    } as Partial<Node>,
    { at: curNode[1] },
  );

  // Update new NODE timeline
  const firstWordNext: INodeData = first(nextNode?.[0]?.children)!;
  const lastWordNext: INodeData = last(nextNode?.[0]?.children)!;
  Transforms.setNodes(
    editor,
    {
      data: {
        ...nextNode[0].data,
        start: firstWordNext?.data?.dataStart,
        end: lastWordNext?.data?.dataEnd,
      },
      children: [
        {
          text: '',
          data: {
            confidence: 1,
            dataStart: firstWordNext.data.dataStart,
            dataEnd: lastWordNext.data.dataEnd,
            entities: null,
            isKeyword: false,
          },
        },
      ],
    } as Partial<Node>,
    { at: nextNode[1] },
  );
};

const canAdd = (path: Path) => {
  try {
    const current: any = Node.get(window.Editor, path);
    const next: any = Editor.next(window.Editor, { at: path })?.[0];

    if (isNil(next)) {
      return current?.data?.end < window.Video.duration;
    }

    const hasSpace = current?.data?.end < next?.data?.start;

    return hasSpace;
  } catch (e: any) {
    return false;
  }
};

export const handleAddTranscript = (element: CustomElement) => {
  const path = ReactEditor.findPath(window.Editor, element);
  const nextEntry: any = Editor.next(window.Editor, { at: path });
  const newStart = element?.data?.end;
  const newEnd = nextEntry?.[0]?.data?.start ?? window.Video.duration;

  // END of Transcript
  if (isNil(nextEntry)) {
    Transforms.insertNodes(window.Editor, {
      ...element,
      data: { ...element.data, start: newStart, end: newEnd },
      children: [
        {
          text: '',
          data: {
            confidence: 1,
            dataStart: newStart,
            dataEnd: newEnd,
            entities: null,
            isKeyword: false,
          },
        },
      ],
    });

    return;
  }

  const op: InsertNodeOperation = {
    type: 'insert_node',
    path: nextEntry[1],
    node: {
      ...element,
      data: { ...element.data, start: newStart, end: newEnd },
      children: [
        {
          text: '',
          data: {
            confidence: 1,
            dataStart: newStart,
            dataEnd: newEnd,
            entities: null,
            isKeyword: false,
          },
        },
      ],
    },
  };
  window.Editor.apply(op);
};
