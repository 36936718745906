/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { useEffect, useMemo, useRef, useState } from 'react';

import { ReactComponent as DonutChartSvg } from 'assets/Icons/donut_chart.svg';
import { ReactComponent as WordChartSvg } from 'assets/Icons/word_chart.svg';

import {
  convertBucketToTermList,
  convertPieBucket,
  processAliasDisplayTerms,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { ButtonGroup } from 'components/shared/ButtonGroup';
import { ChartGeneratingResult } from 'components/shared/ChartGeneratingResult';
import { InsightEditTermsModal } from 'components/shared/ContentExplorationChartsModal/InsightEditTermsModal';
import { useQueryGeneratedTermInsight } from 'hooks/queries/useQueryGeneratedTermInsight';
import { useAdvancedAutofill } from 'hooks/useAdvancedAutofill';
import { defaultTo, isEmpty, isNil } from 'lodash';
import { ExportAliasTerm, InsightService } from 'services/insight.service';
import { AdvertisingChartType, GeneratedChartStatus } from 'utils/enum';
import { AliasTerm, GeneratedChartRaw, Insights } from 'utils/models';
import { TermEditModalRef } from 'utils/models/modal.model';
import { customToast } from 'utils/toast.util';
import { getLayerFromKeyInsights } from 'utils/utils';
import { ExplorationChartViewer } from '../ContentExploration/ExplorationChartViewer';

type Props = {
  generatedTerm: GeneratedChartRaw;
};

export const TermExplorationChartItem = ({ generatedTerm }: Props) => {
  const [chartType, setChartType] = useState<AdvertisingChartType>('donut');
  const [termList, setTermList] = useState<ExportAliasTerm[]>([]);

  const { refetch } = useQueryGeneratedTermInsight();
  const autofillAdvancedSearchHook = useAdvancedAutofill();

  const insightEditTermsModalRef = useRef<TermEditModalRef>(null);

  const isIabLayers = ['iab', 'sensitive'].includes(
    generatedTerm?.related_layer as keyof Insights,
  );
  const termExplorationLayer = getLayerFromKeyInsights(
    generatedTerm?.related_layer ?? 'keywords',
  );

  const seriesBucket = useMemo(() => {
    return convertPieBucket(generatedTerm);
  }, [generatedTerm]);

  useEffect(() => {
    if (isEmpty(generatedTerm.buckets)) return;

    const termList = convertBucketToTermList(generatedTerm.buckets);

    setTermList(processAliasDisplayTerms(termList));
  }, [generatedTerm]);

  if (generatedTerm.status === GeneratedChartStatus.GENERATING) {
    return <ChartGeneratingResult />;
  }

  const handleClickPie = (searchTerm: string, searchField: keyof Insights) => {
    autofillAdvancedSearchHook({
      searchTerms: [searchTerm],
      searchBy: defaultTo(generatedTerm.related_layer, 'keywords'),
    });
  };

  const handleClickEdit = async () => {
    if (!insightEditTermsModalRef.current) return;

    const result = await insightEditTermsModalRef.current.show({});

    if (result.shouldRefresh) {
      refetch();
    }
  };

  const handleSaveEditedTerms = async (aliasEditedTerms: AliasTerm[]) => {
    const filteredAliasTerms = aliasEditedTerms.filter(
      ({ isEnabled, alias }) => !(isEnabled && isNil(alias)),
    );

    const updateTermExplorationAsync = InsightService.updateGeneratedTermChart({
      chartId: generatedTerm.id,
      disabledTerms: filteredAliasTerms,
    });

    customToast.promise(updateTermExplorationAsync, {
      loading: 'Saving changes...',
      success: 'Saved successfully',
      error: 'Save failed',
    });

    try {
      await updateTermExplorationAsync;
    } catch (error: any) {
      console.log('error :>> ', error);
    }
  };

  return (
    <div tw="w-full h-full flex flex-col">
      <div>
        <div>
          <label>
            <span tw="text-15">Chart Type</span>
          </label>

          <ButtonGroup
            items={[
              { icon: <DonutChartSvg />, label: 'Donut', value: 'donut' },
              {
                icon: <WordChartSvg />,
                label: 'Word Cloud',
                value: 'wordcloud',
                disabled: isIabLayers,
              },
            ]}
            defaultValue="donut"
            onChange={(item) =>
              setChartType(item.value as AdvertisingChartType)
            }
          />
        </div>
      </div>

      <div tw="flex-1">
        <ExplorationChartViewer
          seriesKeyList={seriesBucket}
          chartType={chartType}
          currentLayer={termExplorationLayer}
          onClickPie={handleClickPie}
          onClickEdit={handleClickEdit}
          editable={true}
          isTermExploration
        />
      </div>

      <InsightEditTermsModal
        ref={insightEditTermsModalRef}
        isLoading={false}
        currentLayer={termExplorationLayer}
        termList={termList}
        onSaveEditedTerms={handleSaveEditedTerms}
        onLayerChange={() => {}}
        isTermExploration
      />
    </div>
  );
};
