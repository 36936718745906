/** @jsxImportSource @emotion/react */
import React from 'react';
import tw from 'twin.macro';

import { Ripple } from '@rmwc/ripple';

import { ReactComponent as CheckSvg } from 'assets/Icons/check.svg';
import { ReactComponent as CloseIcon } from 'assets/Icons/close.svg';
import { ReactComponent as NoCheckSvg } from 'assets/Icons/nocheck.svg';
import { Hint } from 'components/shared/Hint';
import { HoverTooltip } from 'components/shared/HoverTooltip';
import { useSelector } from 'react-redux';
import { insightSelector } from 'slices/insight.slice';
import { MAX_INSIGHT_TERM } from 'utils/constants';
import { InsightTerm } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { ReactComponent as MentionIcon } from 'assets/Icons/voice.svg';

type Props = {
  term: InsightTerm;
  onRemove: (term: InsightTerm) => unknown;
  handleClickTag: (term: InsightTerm) => void;
  showMentionModal?: () => void;
  hasMentionPopover?: boolean;
  canRemove?: boolean;
  isPinnedChart?: boolean;
};

export const InsightTag = ({
  term,
  canRemove = false,
  hasMentionPopover,
  isPinnedChart = false,
  ...props
}: Props) => {
  const insight = useSelector(insightSelector);

  const handleClickToggle = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (isPinnedChart) return;

    e.stopPropagation();

    const newState = !term.selected;
    const selectedTerms = insight.combinedTerms.filter((term) => term.selected);

    if (newState === true && selectedTerms.length >= MAX_INSIGHT_TERM) {
      customToast.warning(
        <div>
          <div>
            Maximum selection of <strong>{MAX_INSIGHT_TERM}</strong> terms
            reached
          </div>
          <div>Please check and try again.</div>
        </div>,
      );

      return;
    }

    props.handleClickTag(term);
  };

  const handleClickRemove = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();

    if (typeof props.onRemove === 'function') {
      props.onRemove(term);
    }
  };

  return (
    <HoverTooltip
      disabled={!term.selected || !hasMentionPopover}
      className="tooltip-voice"
      popupElem={
        <div tw="flex items-center p-2">
          <Hint text="Mentions report" enterDelay={200} leaveDelay={100}>
            <span
              tw="w-full h-full flex"
              onClick={() => {
                if (typeof props?.showMentionModal !== 'function') return;

                props.showMentionModal();
              }}
            >
              <MentionIcon />
            </span>
          </Hint>
        </div>
      }
    >
      <Ripple onClick={handleClickToggle}>
        <div
          tw="flex items-center justify-around gap-x-1.5 border[1px solid] text-14 text-sonnant-dark py-0 pl-[6px] pr-[8px] rounded-full border-sonnant-grey-light cursor-pointer transition[all 200ms ease-in-out]"
          css={[
            term.selected
              ? tw`text-green-900 bg-green-100 border[1px solid] border-green-300`
              : tw`text-gray-800 bg-gray-100`,
          ]}
        >
          {term.selected ? (
            <CheckSvg tw="w-[1.8rem] h-[1.8rem] text-green-600" />
          ) : (
            <NoCheckSvg tw="w-[1.8rem] h-[1.6rem]" />
          )}

          {term.name}

          {canRemove && (
            <Hint text="Remove term" enterDelay={300} arrow>
              <span
                tw="flex justify-center items-center rounded-full h-[2rem] w-[2rem] border[1px solid] border-sonnant-grey-light hover:bg-red-300"
                onClick={handleClickRemove}
              >
                <CloseIcon tw="h-[0.8rem] w-[0.8rem]" />
              </span>
            </Hint>
          )}
        </div>
      </Ripple>
    </HoverTooltip>
  );
};
