import * as actionType from '../actions/types';
import { put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { MediaService } from 'services';
import { addClip } from 'actions';
import { IPublishPayload } from 'utils/models';

export const initPublishLibrary = function* (action: any) {
  try {
    const payload: IPublishPayload = {
      mediaid: action.mediaid,
      versionname: action.versionname,
      starttime: action.starttime,
      endtime: action.endtime,
      captionstype: action.captionstype,
      keyterms: action.keyterms,
      summaries: action.summaries,
      names: action.names,

      products: action.products,
      orgs: action.orgs,
      locations: action.locations,
      nationalities: action.nationalities,
      chapters: action.chapters,
      globalList: action.globalList,
      includeUrlCsv: action.includeUrlCsv,
      logo: action?.logo,
      layer: action?.layer,
      isSubscriber: action?.isSubscriber,
      iab: action?.iab,
      visualplaybar: action?.visualplaybar,
      paraheader: action?.paraheader,
      hasAdMarkersLayer: action?.hasAdMarkersLayer,
      dar: action?.dar,

      download: action.download,
      captiondownload: action.captiondownload,
      transcriptsearch: action.transcriptsearch,
      librarysearch: action.librarysearch,
      speakers: action.speakers,

      topicTerm: action.topicTerm,
      isTopicClip: action.isTopicClip,
    };

    const response: AxiosResponse = yield MediaService.publish(payload);

    yield put({ type: actionType.SET_PUBLISH_LIBRARY, data: response });
    yield put(addClip(action?.mediaid));
  } catch (error: any) {
    yield put({
      type: actionType.FAILED_PUBLISH_LIBRARY,
      payload: error.response,
    });
  }
};

export default function* WatchInitPublishLibrary() {
  yield takeLatest(actionType.INIT_PUBLISH_LIBRARY_WATCH, initPublishLibrary);
}
