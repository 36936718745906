/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { AdvertisingCharts } from './AdvertisingCharts';

import {
  getWindowScrollY,
  scrollToY,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { isEmpty, isNumber } from 'lodash';
import { useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setInsightScrollY } from 'slices/global.slice';
import { ContentExplorationChart } from './ContentExploration/ContentExplorationChart';
import { PinnedCharts } from './PinnedCharts/PinnedCharts';
import { SearchableLayerCharts } from './SearchableLayerCharts';
import { TrendingCharts } from './TrendingCharts';
import { SimilarityShowCharts } from './SimilarityShowCharts';
import { TermExplorationChartList } from './TermExploration/TermExplorationChartList';

type Props = {};

export const InsightsTab = (props: Props) => {
  const dispatch = useDispatch();

  const insightWrapperRef = useRef<HTMLDivElement>(null);

  const global = useSelector((state: RootState) => state.global);

  const termExploration = useSelector(
    (state: RootState) => state.termExploration,
  );

  const hasGeneratedTermCharts = !isEmpty(termExploration.generatedCharts);

  useLayoutEffect(() => {
    const targetElement = insightWrapperRef.current;
    const lastScrollY = global.insightScrollY;

    if (isNumber(lastScrollY) && lastScrollY > 0) {
      scrollToY(targetElement, lastScrollY);
    }

    // Remember last position once UNMOUNT
    return () => {
      const scrollY = getWindowScrollY(targetElement);

      dispatch(setInsightScrollY(scrollY));
    };
  }, []);

  return (
    <div
      ref={insightWrapperRef}
      tw="p-6 flex flex-wrap height[calc(100vh - 7rem)] overflow-y-auto"
    >
      <SearchableLayerCharts />
      <PinnedCharts />

      <div tw="w-full flex flex-wrap gap-6 px-3 mt-10">
        <div tw="w-1/2 order-[1] flex-1 min-w-[75rem]">
          <ContentExplorationChart />
        </div>

        <div tw="w-1/2 order-[2] xl-down:(order-[3]) flex-1 min-w-[85rem]">
          <AdvertisingCharts />
        </div>

        {hasGeneratedTermCharts && (
          <div tw="w-1/2 order-[3] xl-down:(order-[2]) flex-1 min-w-[75rem]">
            <TermExplorationChartList />
          </div>
        )}
      </div>

      <TrendingCharts />

      <SimilarityShowCharts />
    </div>
  );
};
