/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { ReactComponent as AscendingArrow } from 'assets/Icons/Ascending_Arrow.svg';
import { ReactComponent as DescendingArrow } from 'assets/Icons/Descending_Arrow.svg';
import { ReactComponent as Search } from 'assets/Icons/search.svg';
import { ReactComponent as TagSvg } from 'assets/Icons/tag_24dp.svg';
import { TermItem } from 'components/shared/CutomTermsModal/TermItem';
import {
  arrowActive,
  arrowCss,
  dropdownCss,
  hoverDropShadow,
  noneBorder,
} from 'components/shared/twin.styles';
import CloseSVG from 'components/UiControls/closeButtonSVG/closeSVG';
import {
  appendMultipleTerms,
  extractValidEmails,
  splitStringByComma,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { cloneDeep, isEmpty, isNil, orderBy, toNumber } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { RootState } from 'reducers';
import {
  setCustomTerms,
  setFocusCustomList,
  setTermByListId,
} from 'slices/global.slice';
import { tierSelector } from 'slices/payment.slice';
import { Keys } from 'utils/enum';
import { CustomTerm, Term, WatchList } from 'utils/models';
import { QuestionTooltipHint } from 'components/shared/Tooltip/QuestionTooltipHint';
import { customToast } from 'utils/toast.util';
import {
  setCurrentEditWatchList,
  setWatchList,
  updateCurrentWatchList,
} from 'slices/watchlist.slice';
import { v4 } from 'uuid';
import { RecipientsSettings } from 'components/shared/RecipientsSettings/RecipientsSettings';
import { MAX_TERMS_IN_VOCAB } from 'utils/constants';

interface Props {
  onSaveTermsChanges?: (newList: CustomTerm[]) => void;
  onSaveWatchListChanges?: (newWatchList: WatchList[]) => void;
  isWatchList: boolean;
}

export const ListDetailPane = (props: Props) => {
  const { isWatchList } = props;

  const [newTerms, setNewTerms] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [termList, setTermList] = useState<Term[]>([]);

  const [sortList] = useState([
    { id: 'modified', name: 'modified' },
    { id: 'alphabet', name: 'A-Z' },
  ]);
  const [isSortAsc, toggleIsSortAsc] = useToggle(true);
  const [currentSort, setCurrentSort] = useState<{ id: string; name: string }>(
    sortList[0],
  );

  const dispatch = useDispatch();

  const global = useSelector((state: RootState) => state.global);
  const watchListReducer = useSelector((state: RootState) => state.watchList);
  const { currentEditWatchList } = watchListReducer;
  const rawEmail =
    currentEditWatchList?.recipients?.emailAddress?.join(',') ?? '';

  const tier = useSelector(tierSelector);

  const focusList = isWatchList ? currentEditWatchList : global.focusCustomList;

  // const shouldDisableSave = (): boolean => {
  //   if (!isWatchList) return false;

  //   const { me, allUsers, hasEmailAddress } = {
  //     ...currentEditWatchList.recipients,
  //   };

  //   const validEmails: string[] = extractValidEmails(rawEmail);

  //   // At least one option checked
  //   const hasCheckedOption: boolean = Boolean(
  //     me || allUsers || hasEmailAddress,
  //   );

  //   return (
  //     !hasCheckedOption ||
  //     !!(hasCheckedOption && hasEmailAddress && isEmpty(validEmails))
  //   );
  // };

  const searchedTerms =
    orderBy(
      termList?.filter((term) =>
        term?.name.toLowerCase().includes(searchTerm.trim().toLowerCase()),
      ),
      (s) => {
        const by = currentSort.id;
        if (by === 'modified') return s?.dateCreated;
        if (by === 'alphabet') return s?.name;
      },
      [isSortAsc ? 'asc' : 'desc'],
    ) ?? [];

  useEffect(() => {
    if (isWatchList) {
      if (!currentEditWatchList?.terms) return;

      setTermList(currentEditWatchList?.terms);
      return;
    }

    if (!global?.focusCustomList?.terms) return;

    setTermList(global.focusCustomList.terms);
  }, [
    global?.focusCustomList,
    watchListReducer.focusWatchListId,
    currentEditWatchList,
  ]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
      if (isWatchList && !isNil(currentEditWatchList)) {
        const addedTerms = splitStringByComma(newTerms);

        const nonExistingTerms = addedTerms.filter(
          (term) =>
            !currentEditWatchList.terms?.map(({ name }) => name).includes(term),
        );

        const newWatchTerms = nonExistingTerms.map((term) => ({
          id: v4(),
          dateCreated: new Date(),
          name: term,
        }));

        const newCurrentWatchList = {
          ...currentEditWatchList,
          terms: [...(currentEditWatchList?.terms ?? []), ...newWatchTerms],
        };

        dispatch(setCurrentEditWatchList(newCurrentWatchList));
      } else {
        addMultipleTerms();
      }
      setNewTerms('');
    }
    if (e.key === Keys.ESC) {
      setNewTerms('');
    }
  };

  const addMultipleTerms = () => {
    if (
      !isWatchList &&
      global.focusCustomList?.insightVisible === true &&
      toNumber(global.focusCustomList.terms?.length) > MAX_TERMS_IN_VOCAB
    ) {
      customToast.maximumTermInsight(MAX_TERMS_IN_VOCAB);

      return;
    }

    const newTermsList = appendMultipleTerms(
      termList,
      newTerms,
      global.customTerms,
    );

    if (tier.isTrial && newTermsList.length > 5) {
      customToast.error(
        'Free Tier Accounts limited to 2 custom terms lists each of a maximum of 5 custom terms.',
      );
      return;
    }

    dispatch(setFocusCustomList({ ...focusList!, terms: newTermsList }));
    setTermList(newTermsList);

    const latestList = getLatestTermList(newTermsList);
    dispatch(setCustomTerms(latestList));
  };

  const handleRemoveTerm = (term: Term) => {
    const newTermsList = termList?.filter((t) => t.id !== term.id) ?? [];

    setTermList(newTermsList);

    if (isWatchList) {
      removeItemWatchList(newTermsList);
    } else {
      removeItemCustomTerm(newTermsList);
    }
  };

  const removeItemWatchList = (removedTerms: Term[]) => {
    if (!currentEditWatchList) return;

    dispatch(
      updateCurrentWatchList({
        ...currentEditWatchList,
        terms: removedTerms,
      }),
    );
  };

  const removeItemCustomTerm = (removedTerms: Term[]) => {
    const latestList = getLatestTermList(removedTerms);
    dispatch(setCustomTerms(latestList));

    const latestFocus = latestList.find((t) => t.id === focusList?.id);

    if (latestFocus) {
      dispatch(setFocusCustomList(latestFocus));
    }
  };

  const handleUpdateTerm = (term: Term) => {
    const existedTerm = termList?.find(
      (t) => t?.name?.trim() === term?.name?.trim(),
    );

    if (existedTerm) {
      customToast.error(
        <>
          Term <strong>{term.name}</strong> already exists.
        </>,
      );
      return;
    }

    const foundIndex = termList.findIndex((t) => t?.id === term?.id);
    if (foundIndex < 0) return;

    const cloneTermList = [...termList];
    cloneTermList[foundIndex] = term;
    setTermList(cloneTermList);

    if (isWatchList) {
      if (currentEditWatchList) {
        dispatch(
          updateCurrentWatchList({
            ...currentEditWatchList,
            terms: cloneTermList,
          }),
        );
      }
    } else {
      const latestList = getLatestTermList(cloneTermList);
      dispatch(setCustomTerms(latestList));
    }
  };

  const handleSave = () => {
    if (isWatchList && !isNil(currentEditWatchList)) {
      let cloneWatchList = [...watchListReducer.watchLists];

      const validEmails: string[] = extractValidEmails(rawEmail);

      if (
        currentEditWatchList?.recipients?.hasEmailAddress &&
        isEmpty(validEmails)
      ) {
        customToast.error(
          'At least one valid email address is required. Please check and try again.',
        );
        return;
      }

      const newCurrentWatchList = {
        ...currentEditWatchList,
        recipients: {
          ...currentEditWatchList.recipients,
          emailAddress: validEmails,
        },
      };

      cloneWatchList = updateWatchList(newCurrentWatchList);

      props?.onSaveWatchListChanges?.(cloneWatchList);
      return;
    }

    const newList = getLatestTermList(undefined, true);
    dispatch(setCustomTerms(newList));
    props?.onSaveTermsChanges?.(newList);
  };

  const updateWatchList = (newCurrentWatchList: WatchList) => {
    const cloneWatchList = [...watchListReducer.watchLists];

    const foundIndex = cloneWatchList.findIndex(
      (item) => item.id === currentEditWatchList?.id,
    );

    if (foundIndex >= 0) {
      cloneWatchList[foundIndex] = newCurrentWatchList;
      dispatch(setWatchList(cloneWatchList));
    }

    return cloneWatchList;
  };

  const getLatestTermList = (termsParam?: Term[], isAdding = false) => {
    const newList = [...global.customTerms];
    const foundIndex = global.customTerms.findIndex(
      (t) => t.id === focusList?.id,
    );
    const foundList = newList[foundIndex];

    newList[foundIndex] = {
      ...focusList,
      ...foundList,
      terms: termsParam ?? termList,
      versionNo: isAdding
        ? foundList?.versionNo ?? 0 + 1
        : foundList?.versionNo ?? 0,
    } as CustomTerm;

    return newList;
  };

  const setNewAlter = (newAlter: Term[], id: string) => {
    const newTerm = cloneDeep(termList);
    const foundIndex = newTerm.findIndex((item) => item.id === id);
    if (foundIndex >= 0) {
      newTerm[foundIndex].alternatives = newAlter;
      setTermList(newTerm);
      dispatch(setTermByListId({ newTerm, id: focusList?.id! }));
    }
  };

  return (
    <>
      <div tw="text-20 font-medium my-5">
        {isWatchList
          ? currentEditWatchList?.name
          : global.focusCustomList?.name}
      </div>
      <div tw="flex w-full justify-between md-down:(flex-wrap)">
        <div tw="flex-1 relative mr-5">
          <TagSvg tw="absolute top[1.2rem] left[1rem]" />
          <input
            type="text"
            placeholder={`Add terms to ${
              isWatchList ? 'Watch List' : 'Custom Vocab'
            } (use comma for multiple items)`}
            value={newTerms}
            tw="(text-14 padding-left[3.1rem] padding-right[3rem] placeholder:(text-11) focus:(border-transparent))!"
            onChange={(e) => setNewTerms(e.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus
          />
          <QuestionTooltipHint
            customStyle={toolTipStyle}
            contentWidth="40rem"
            message={
              isWatchList ? (
                <div>Watch list terms can be words or phrases.</div>
              ) : (
                <div>
                  <p>
                    Custom Vocab terms can be words or phrases. The
                    transcription engine will increase the probability of these
                    terms being found in the audio. The spelling and
                    capitalisation from the term will be used in the transcript.
                  </p>

                  <p>
                    Sounds-like alternatives will be automatically added to new
                    terms where needed to improve accuracy. Additional
                    alternatives can also be manually added. When an alternative
                    is found in the transcript it will be replaced with the
                    term.
                  </p>

                  <div>
                    Phases over 64 characters will not be used by the
                    transcription engine, but will be used for spelling and
                    capitalisation and will also be used in an Applied List if
                    created for this Custom Vocab.
                  </div>
                </div>
              )
            }
          />
        </div>
        <div className="search-container" tw="flex -mr-1">
          <div tw="flex relative">
            <input
              className="input-group-field input-search"
              tw="(height[4rem] width[15rem]! rounded-r-none rounded-l pl-4 pr-2 font-size[1.5rem] caret-color[#7F8090] placeholder:(text-12) md-down:max-width[10rem])!"
              css={[noneBorder]}
              type="input"
              placeholder={`Search in ${
                isWatchList ? 'Watch List' : 'Custom Vocab'
              } `}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button
            tw="(rounded-l-none height[4rem] width[4rem] mb-0 rounded-r focus:(bg-white) hover:(border[2px solid #F0F3F6]) box-shadow[none])!"
            className="button btn-secondary"
          >
            {searchTerm ? (
              <CloseSVG
                close={() => setSearchTerm('')}
                color="#7F8090"
                css={hoverDropShadow(0.2)}
              />
            ) : (
              <Search css={hoverDropShadow(0.2)} />
            )}
          </button>
        </div>

        <div
          className="button-group sort_modify"
          tw="flex items-center flex-nowrap height[3.9rem] bg-sonnant-grey-5 m-0 ml-3 rounded flex[unset] pr-1.5 select-none"
        >
          <div className="sort-icon" tw="pl-0 ml-2">
            <div
              tw="flex flex-col mr-2 ml-1 cursor-pointer"
              onClick={toggleIsSortAsc}
            >
              <DescendingArrow
                css={[arrowCss, !isSortAsc && arrowActive]}
                tw="(mb-0.5 width[unset] height[unset])!"
              />
              <AscendingArrow
                css={[arrowCss, isSortAsc && arrowActive]}
                tw="(mt-0.5 width[unset] height[unset])!"
              />
            </div>
          </div>
          <div className="dropdown" css={dropdownCss} tw="width[13.5rem]">
            <a
              className="button"
              tw="(width[unset] height[3rem] py-0 px-3 flex items-center text-13 whitespace-nowrap)!"
            >
              <span tw="flex-1 text-left">Sort by {currentSort.name}</span>
              <span className="arrow-down" tw="-mt-1"></span>
            </a>
            <ul className="dropdown-sort-content manageDropDown">
              {sortList.map((s) => (
                <li key={s.id} onClick={() => setCurrentSort(s)}>
                  <a>Sort by {s.name}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div tw="flex flex-wrap content-start w-full p-4 mt-4 mb-6 overflow-y-auto height[40rem] border[1px solid lightgrey] rounded-lg">
        {searchedTerms.map((term, index) => (
          <TermItem
            key={term.id}
            id={index}
            term={term}
            handleRemoveTerm={handleRemoveTerm}
            handleUpdateTerm={handleUpdateTerm}
            setNewAlter={setNewAlter}
            shouldShowAlter={!isWatchList}
          />
        ))}
        {focusList?.terms?.length === 0 && (
          <div tw="color[#54566C] text-2xl font-medium mb-6">
            There are no terms in {isWatchList ? 'Watch List' : 'Custom Vocab'}
          </div>
        )}
        {searchedTerms.length === 0 && focusList?.terms?.length! > 0 && (
          <div tw="color[#54566C] text-2xl font-medium mb-6">
            No results found
          </div>
        )}
      </div>

      {isWatchList && (
        <div tw="mb-5">
          <RecipientsSettings fromPanel={true} />
        </div>
      )}

      <div tw="w-full text-right">
        <button className="button btn-primary large" onClick={handleSave}>
          Save
        </button>
      </div>
    </>
  );
};

const toolTipStyle = css`
  ${tw`absolute top[1.1rem] right[.9rem]`}
`;
