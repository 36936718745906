import { AdBreakItem } from './../utils/models/media.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 } from 'uuid';
import { isNil } from 'lodash';

const adBreakSlice = createSlice({
  name: 'adBreak',
  initialState: {
    items: null as AdBreakItem[] | null,
    currentActiveId: null as string | null,
  },
  reducers: {
    setAdBreak: (state, action: PayloadAction<AdBreakItem[] | null>) => {
      if (isNil(action.payload)) {
        return;
      }
      state.items = action.payload.map((item) => {
        return {
          id: v4(),
          ...item,
        };
      });
    },
    setActiveAdBreakId: (state, action: PayloadAction<string | null>) => {
      state.currentActiveId = action.payload;
    },
    resetAdBreakItems: (state) => {
      state.items = null;
      state.currentActiveId = null;
    },
  },
});

export const { setAdBreak, setActiveAdBreakId, resetAdBreakItems } =
  adBreakSlice.actions;

export const adBreakReducer = adBreakSlice.reducer;
