import React from 'react';

interface IProps {
  handleConfirm: () => void;
  modalClosed: () => void;
}

export const UpdateKeywordConfirm = (props: IProps) => {
  const { handleConfirm, modalClosed } = props;

  return (
    <>
      <div className="userModal_Popus">
        <h1>Confirm to update</h1>
        <>
          <div className="grid-x">
            <div className="cell small-12">
              <div className="deleteInfo">
                <p>
                  Do you want this change to be performed to all occurrences of
                  this Key Term in the transcript?
                </p>
              </div>
            </div>
          </div>
          <div className="grid-x">
            <div className="cell small-12">
              <div className="btn_section">
                <button
                  type="button"
                  className="button cancel"
                  onClick={modalClosed}
                >
                  No
                </button>
                <button
                  type="button"
                  className="button delete"
                  onClick={handleConfirm}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};
