/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { Ripple } from '@rmwc/ripple';
import { ReactComponent as FilterToolSvg } from 'assets/Icons/filter-tool.svg';
import { ReactComponent as FilterOffSvg } from 'assets/Icons/filter_off.svg';
import { FilterWrapper } from 'components/shared/AdvancedFilterMenu/FilterWrapper';
import { Hint } from 'components/shared/Hint';
import { hoverDropShadow } from 'components/shared/twin.styles';
import { first, isEmpty, keys } from 'lodash';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useClickAway, useToggle } from 'react-use';
import { CollectionService } from 'services/collection.service';
import {
  CollectionFilters,
  collectionSelector,
  resetCollectionFilters,
  setPartialCollectionFilters,
  setSelectedCollectionId,
} from 'slices/collection.slice';
import { CollectionType, FilterLayer } from 'utils/enum';
import { customToast } from 'utils/toast.util';
import { RootState } from 'reducers';
import { extract } from 'utils/generic.util';
import { setSelectMode, setSelectedRows } from 'actions';
import { useHistory } from 'react-router-dom';
import { toggleReloadLibrary } from 'slices/toggle.slice';

type Props = {};

export const CollectionFilterToolbar = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const filterToolbarRef = useRef<HTMLDivElement>(null);

  const collectionSlice = useSelector(collectionSelector);
  const collection = useSelector((state: RootState) => state.collection);

  useClickAway(filterToolbarRef, () => {
    if (!show) return;

    const timer = setTimeout(() => {
      toggleShow(false);
      clearTimeout(timer);
    }, 200);
  });

  const [show, toggleShow] = useToggle(false);

  const handleClickShow = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    toggleShow();
  };

  const handleApplyFilters = async (filters: Partial<CollectionFilters>) => {
    const isEmptyFilters = keys(filters).every((key) =>
      isEmpty(filters[key as keyof CollectionFilters]),
    );

    if (isEmptyFilters && !collectionSlice.hasFilters) return;

    toggleShow(false);

    const filterAsync = CollectionService.getCollectionsWithFilters({
      orgIds: filters?.orgIds ?? [],
      tagIds: filters?.tagIds ?? [],
    });

    customToast.promise(filterAsync, {
      loading: 'Applying filter(s)...',
      success: 'Done',
      error: 'Something went wrong. Please try again later!',
    });

    try {
      const { data: response } = await filterAsync;

      if (isEmpty(response.collections)) {
        customToast.warning(
          <div>
            <div>
              There are no collections associated to this filter conditions.
            </div>
            <div>Please check and try again.</div>
          </div>,
        );

        return;
      }

      dispatch(
        setPartialCollectionFilters({
          orgIds: filters?.orgIds ?? [],
          tagIds: filters?.tagIds ?? [],
          collections: response.collections.map((item) => ({
            id: item.collection_id,
            name: item.collection_name,
            type: CollectionType.STANDARD,
          })),
        }),
      );

      const hasExistedCollection = extract(
        response.collections,
        'collection_id',
      ).includes(collection.selectedId as string);

      if (!hasExistedCollection && response.collections?.length > 0) {
        const newSelectedId =
          first(response.collections)?.collection_id ?? null;

        dispatch(setSelectedRows([]));
        dispatch(setSelectMode(false));
        dispatch(setSelectedCollectionId(newSelectedId));

        history.push(`/collections/${newSelectedId}`);

        dispatch(toggleReloadLibrary());
      }
    } catch (error) {}
  };

  const handleClearFilters = () => {
    dispatch(resetCollectionFilters());

    toggleShow(false);
  };

  return (
    <div ref={filterToolbarRef}>
      <FilterWrapper
        menuIsOpen={show}
        filterLayers={[FilterLayer.TAG, FilterLayer.ORG]}
        onApplyFilters={handleApplyFilters}
        onClearFilters={handleClearFilters}
        selectionFilters={collection.filters}
        showReset={collectionSlice.hasFilters}
        anchorElement={
          <Hint text="Collection filters" enterDelay={200}>
            <Ripple onClick={handleClickShow}>
              <span tw="flex rounded-lg">
                {collectionSlice.hasFilters ? (
                  <FilterOffSvg tw="fill[#E7483D]" css={[iconCss]} />
                ) : (
                  <FilterToolSvg tw="fill[#7f8090]" css={[iconCss]} />
                )}
              </span>
            </Ripple>
          </Hint>
        }
      />
    </div>
  );
};

const iconCss = css`
  ${hoverDropShadow(0.15)}
  ${tw`cursor-pointer h-[2.7rem] w-[2.7rem] mt-[-2px]`}
`;
