import { BaseAPI } from 'apis/amazon';

export type TagResponse = {
  tagId: string;
  tagName: string;
  createdAt: Date;
  modifiedAt: Date;
  collectionIds: string[];
};

export type TagDetailResponse = TagResponse & {
  collectionData: TagCollectionResponse[];
};

export type TagCollectionResponse = {
  collectionId: string;
  collectionName: string;
};

export type SaveTagBody = {
  tagName: string;
};

export type DeleteTagBody = {
  tagId: string;
};

export type UpdateTagBody = {
  tagId: string;
  collectionIds?: string[];
  tagName?: string;
};

export const TagService = {
  // GET /tags
  getAllTags: () => {
    return BaseAPI.get<TagResponse[]>('/tags');
  },

  // GET /tags?tag_id=<string>
  getTagById: (tagId: string) => {
    return BaseAPI.get<TagDetailResponse>('/tags', {
      params: {
        tag_id: tagId,
      },
    });
  },

  // POST /tags
  saveNewTag: (body: SaveTagBody) => {
    return BaseAPI.post<'success'>('/tags', {
      tag_name: body.tagName,
    });
  },

  // PUT /tags
  updateTag: (body: UpdateTagBody) => {
    return BaseAPI.put(
      '/tags',
      {
        collection_ids: body?.collectionIds ?? undefined,
        tag_name: body?.tagName ?? undefined,
      },
      {
        params: {
          tag_id: body.tagId,
        },
      },
    );
  },

  // DELETE /tags
  deleteTag: (body: DeleteTagBody) => {
    return BaseAPI.delete('/tags', { params: { tag_id: body.tagId } });
  },
};
