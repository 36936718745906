/** @jsxImportSource @emotion/react */
import 'twin.macro';
import './IdleTimerContainer.scss';

/* eslint-disable react-hooks/exhaustive-deps */
import {
  isPublicShared,
  isTranscriptionPage,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import $ from 'jquery';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';

import {
  getDomainWithoutSubdomain,
  getToken,
  parseMilliseconds,
} from '../../utils/utils';
import { Modal } from '../UiControls/modal/modal';
import { CountDownComponent } from './CountDownComponent';
import { clearSession } from 'utils/utils';

export const IdleTimerContainer = () => {
  // console.count('IdleTimerContainer');
  const uppy: any = useSelector((state: RootState) => state.uppy);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [timeLeftTrigger, setTimeLeftTrigger] = useState<number | undefined>(
    undefined,
  );

  // const isIdle = useIdle(10 * 60 * 1000); // 10 min
  const isIdle = false; // Disable Inactivity Modal Feature

  useEffect(() => {
    const isUploading = !isEmpty(uppy?.uppyStore?.currentUploads);
    const isPlaying = isTranscriptionPage() && !window?.Video?.paused;

    if (isUploading || isPlaying) return;

    if (isIdle && !!getToken() && !isPublicShared()) {
      setModalOpen(true);
    }
  }, [isIdle]);

  useEffect(() => {
    if (isModalOpen) {
      $('#main-container').addClass('is-blurring');
    } else {
      $('#main-container').removeClass('is-blurring');
    }
  }, [isModalOpen]);

  const handleStayActive = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (timeLeftTrigger) {
      const { seconds } = parseMilliseconds(timeLeftTrigger);
      if (seconds <= 1) {
        handleLogout();
      }
    }
  }, [timeLeftTrigger]);

  function handleLogout() {
    clearSession();

    // redirect to sign in page
    window.location.replace(
      `${
        window.location.protocol
      }//${getDomainWithoutSubdomain()}/signin?next=${encodeURIComponent(
        window.location.href,
      )}`,
    );
  }

  return (
    <div>
      {isModalOpen && (
        <Modal show={isModalOpen} modalClosed={() => setModalOpen(false)}>
          <div className="sessionexpiry">
            <>
              <CountDownComponent setTimeLeftTrigger={setTimeLeftTrigger} />
              <p>Do you want to renew your session ?</p>
              <div className="btn_section flex">
                <button
                  className="button btn-primary large"
                  onClick={handleStayActive}
                >
                  Stay Signed In
                </button>
                <button
                  className="button btn-primary large"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            </>
          </div>
        </Modal>
      )}
    </div>
  );
};
