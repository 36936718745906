/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { InputTimeCode } from 'components/shared/modals/InputTimeCode';
import {
  isDisplayClips,
  shouldUsePlyr,
  isLibraryPage,
  isSuggestedClips,
  msToSec,
  secToTime,
  secToTimeCode,
  isRevivalPage,
  isTranscriptionPage,
  isTrendingClips,
  isAudioByMIME,
  isVideoByMIME,
  ratioToCssString,
  ratioFontSize,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { ReactComponent as Pause } from 'assets/Icons/fPause.svg';
import { ReactComponent as Play } from 'assets/Icons/fPlay.svg';
import { ReactComponent as ForwardSvg } from 'assets/Icons/fast_forward_24dp.svg';
import { ReactComponent as RewindSvg } from 'assets/Icons/fast_rewind_24dp.svg';
import { ReactComponent as ZoomSvg } from 'assets/Icons/zoom_24dp.svg';

import { floor, isEqual, isNaN, isNil, round } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useSlider,
  useToggle,
  useUnmount,
  useUpdate,
  useVideo,
} from 'react-use';
import * as actionTypes from '../../actions/types';
import Loader from '../loader/loader';
import './Publish.scss';
import { tierSelector } from 'slices/payment.slice';
import { RootState } from 'reducers';
import { WaveSurferVideo } from 'components/shared/Wavesurfer/WaveSurferVideo';
import { debounce } from 'lodash';
import { isEmpty } from 'lodash';
import {
  ClipStatusCode,
  Dropdown,
  Layers,
  MediaReadyState,
  Ratio,
} from 'utils/enum';
import axios, { AxiosResponse } from 'axios';
import {
  IClips,
  IKeyItem,
  ILibraryItem,
  IPublishPayload,
  IStartEnd,
  PublishRequest,
} from 'utils/models';
import {
  audioThumbStyles,
  navbarTabActiveCss,
  playPauseStyle,
  plyrResetStyle,
  svgHoverShadow,
  topicTagCustomStyles,
} from 'components/shared/twin.styles';
import { Hint } from 'components/shared/Hint';
import Plyr from 'plyr';
import { setWaveform } from 'slices/media.slice';
import { MediaService, PreviewResponse } from 'services';
import { customToast } from 'utils/toast.util';
import { PublishRatioPicker } from './PublishRatioPicker';
import { CustomSelectSearch } from 'components/shared/CustomSelectSearch';
import { TopicLayerDropDown } from './TopicLayerDropDown';
import { getLayerEntity, getTopicLayerName } from 'utils/utils';
import { Notification } from 'components/UiControls/notification/Notification';
import { IMediaEntities } from 'components/shared/RichTextEditor/rich-text.model';
import { LayerCustomTerm } from './../../utils/models/media.model';
import { QuestionHint } from 'components/shared/QuestionHint';

interface IProps {
  filename: string;
  caption?: string;
  isPopper?: boolean;
  startTime?: number;
  endTime?: number;
  snippetText?: string;
  isOpen?: boolean;
  duration?: number;
  onSuccess?: ({ isRecreate }: { isRecreate: boolean }) => unknown;
  mediaid: string;
  closeShareModal: () => unknown;
  handleShowPreview?: (startTime: number, endTime: number) => unknown;
  existedData?: any;
  parentItem?: ILibraryItem | IClips | null | undefined;
  isRevival?: boolean;
  isDisplayClips?: boolean;
  isTopic?: boolean;
  keywordSelected?: string;
}

export const Publish = (props: IProps) => {
  const dispatch = useDispatch();
  const zoomClipRef = React.useRef(null);
  const zoomSlider = useSlider(zoomClipRef);
  const forceUpdate = useUpdate();

  const publishLibrary = useSelector(
    (state: RootState) => state.publishLibrary,
  );
  const clips = useSelector((state: RootState) => state.clips);
  const media = useSelector((state: RootState) => state.media);
  const layers = useSelector((state: RootState) => state.layers);
  const player = useSelector((state: RootState) => state.player);
  const payment = useSelector((state: RootState) => state.payment);

  const tier = useSelector(tierSelector);
  const preferences = useSelector(
    (state: RootState) => state.global.preference,
  );
  const duration = msToSec(props?.duration ?? media?.metadata?.length);
  // const [duration] = useState(msToSec(props?.duration ?? media?.metadata?.length));

  const [previewMedia, setPreviewMedia] = useState<any>(null);

  const [shouldReset, toggleReset] = useToggle(false);
  const [shouldZoomFocus, toggleZoomFocus] = useToggle(false);

  const [currentTime, setCurrentTime] = useState<IStartEnd | null>(null);
  const [defaultTime, setDefaultTime] = useState<IStartEnd | null>(null);

  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);

  // const startSecond = clips?.focusClip?.starttime ?? startTime;
  // const endSecond = clips?.focusClip?.endtime ?? endTime;
  // const durationSec = endSecond - startSecond;

  const durationSec = endTime - startTime;

  const [topicKeyword, setTopicKeyword] = useState<IKeyItem[]>([]);
  const [topicEntities, setTopicEntities] = useState<IMediaEntities[]>([]);
  const [topicCustomTerms, setTopicCustomTerms] = useState<LayerCustomTerm[]>(
    [],
  );

  const [titleError, toggleTitleError] = useToggle(false);
  const [hasStartError, toggleStartError] = useToggle(false);
  const [hasEndError, toggleEndError] = useToggle(false);

  const [isManualTab, toggleManualTab] = useToggle(!props.isTopic);
  const isTopicTab = !isManualTab;
  const [currentTopicLayer, setCurrentTopicLayer] = useState<Layers | null>(
    layers.currentLayer,
  );
  const [currentTopicTag, setCurrentTopicTag] = useState<string>(
    props?.keywordSelected ?? '',
  );

  const isRecreateMode =
    isDisplayClips() &&
    !isEmpty(clips?.focusClip) &&
    !isEqual(clips.focusClip?.versioncount, '0');

  const canRecreateClip = isRecreateMode && !(isTopicTab && duration < 120);

  const hasSelectedTag = !isNil(currentTopicLayer) && !isEmpty(currentTopicTag);

  const [errors, setErrors] = useState<any>({});
  const [name, setName] = useState(
    isRecreateMode || isManualTab ? props?.filename : '',
  );

  const [topicThemeText, setTopicThemeText] = useState('');
  // No need to wait on transcription page
  const [isLoadingTopicTags, toggleLoadingTopicTags] = useState(
    isTranscriptionPage() ? false : true,
  );

  const [showCaption, toggleShowCaption] = useState(false);
  const { loading, statusMessage, statusCode } = publishLibrary;
  const [isRecreating, setIsRecreating] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(0);

  const [rawMediaSrc, setRawMediaSrc] = useState<string>();
  const [rawVttPath, setRawVttPath] = useState<string>();
  const [isMediaLoading, toggleMediaLoading] = useToggle(true);
  const [plyrInstance, setPlyrInstance] = useState<Plyr | null>(null);

  const [publishRatio, setPublishRatio] = useState<Ratio>(Ratio.DEFAULT);

  const initialRender = React.useRef(false);

  const [state, setState] = useState<PublishRequest>({
    download: false,
    captiondownload: false,
    keyterms: false,
    summaries: false,
    products: false,
    orgs: false,
    locations: false,
    nationalities: false,
    people: false,
    hasBurnedIn: false,
    chapters: false,
    globalList: false,
    includeUrlCsv: false,
    logo: false,
    isSubscriber: false,
    iab: true,
    visualplaybar: false,
    paraheader: true,
    hasAdMarkersLayer: true,
    speakers: true,
  });

  useEffect(() => {
    setName(isRecreateMode || isManualTab ? props?.filename : '');
    toggleTitleError(false);
  }, [isManualTab]);

  const handleChangeTag = (newValue: any) => {
    setCurrentTopicTag(newValue?.value);
    setTopicThemeText('');
  };

  const handleChangeThemeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;
    setTopicThemeText(value);

    if (value) {
      // set default value if theme was choose
      setCurrentTopicTag('');
      setCurrentTopicLayer(Layers.KEY_TERM);
    }
  };

  const mimeType = isTranscriptionPage()
    ? media?.metadata?.mediatype ?? media?.metadata?.mediacontenttype
    : previewMedia?.mediatype;

  const isAudio = isAudioByMIME(mimeType);
  const isVideo = isVideoByMIME(mimeType);

  const canChangeDAR = isVideo || (isAudio && state?.hasBurnedIn);

  // const shouldDisableBurnedIn = tier?.isTrial || clips?.focusClip?.hasBurnIn;
  const shouldDisableBurnedIn = tier?.isTrial; // Temporary turn on for non-trial

  const getCurrentLayerName = () => {
    const layerEntity = getLayerEntity(currentTopicLayer);
    return isEmpty(layerEntity) ? 'keywords' : layerEntity;
  };

  const [video, videoState, controls, videoRef] = useVideo(
    <video
      // id="media_player"
      tw="w-full! rounded-md shadow"
      css={[
        audioThumbStyles(
          media?.metadata?.thumbnail ||
            previewMedia?.thumbnail_url ||
            clips.focusClip?.thumbnail ||
            props?.parentItem?.thumbnail,
        ),
        tw`block`,
      ]}
      src={media?.url || rawMediaSrc}
      poster={
        previewMedia?.thumbnail_url ||
        clips.focusClip?.thumbnail ||
        props?.parentItem?.thumbnail
      }
      controls={false}
      autoPlay={false}
      preload="auto"
      crossOrigin="anonymous"
      controlsList="nodownload"
      playsInline
      onClick={() => (videoState.paused ? controls.play() : controls.pause())}
      onContextMenu={(e) => e.preventDefault()}
    >
      <track
        label="English"
        kind="subtitles"
        srcLang="en"
        src={media.subtitleurl || rawVttPath}
        default
      ></track>
    </video>,
  );

  useUnmount(() => {
    if (!isTranscriptionPage()) {
      dispatch(setWaveform(undefined));
      dispatch({ type: actionTypes.CLOSE_PUBLISH_LIBRARY });
    }
  });

  useEffect(() => {
    if (
      !videoRef?.current ||
      !shouldUsePlyr() ||
      !(media.subtitleurl || rawVttPath)
    )
      return;

    const videoPlyr = new Plyr(videoRef.current, {
      captions: { active: false, update: true },
      ratio: '16:9',
      controls: [
        // 'play',
        // 'progress',
        // 'current-time',
        'mute',
        'volume',
        'captions',
        'settings',
        'fullscreen',
      ],
      storage: { enabled: false },
    });

    if (videoPlyr) {
      toggleMediaLoading(false);

      videoPlyr.on('ready', () => {
        setPlyrInstance(videoPlyr);
        videoPlyr.toggleCaptions(false);
        videoPlyr.toggleControls(false);

        videoPlyr.on('captionsenabled', () => toggleShowCaption(true));
        videoPlyr.on('captionsdisabled', () => toggleShowCaption(false));
      });
    }
  }, [videoRef?.current, rawVttPath]);

  useEffect(() => {
    setName(props.filename);
    // Default state setup
    setState({
      download: preferences.transcripts,
      captiondownload: preferences.captionfile,
      keyterms: preferences.keyterm,
      summaries: preferences.datasummary,
      products: preferences.products,
      orgs: preferences.orgs,
      locations: preferences.locations,
      nationalities: preferences.nationalities,
      people: preferences.people,
      hasBurnedIn:
        // Default ON if editing exited burned-in
        canRecreateClip && clips?.focusClip?.hasBurnIn
          ? true
          : preferences.caption,
      chapters: preferences.chapters,
      globalList: preferences.globalList,
      includeUrlCsv: preferences.includeUrlCsv,
      logo: preferences.logo,
      isSubscriber: preferences.isSubscriber,
      iab: preferences.iab,
      visualplaybar: preferences.visualplaybar,
      paraheader: preferences.paraheader,
      hasAdMarkersLayer: !!preferences?.hasAdMarkersLayer,
      speakers: preferences.speakers,
    });

    const propsStartTime = round(props?.startTime || 0, 3);
    const propsEndTime = round(props?.endTime || duration, 3);

    setStartTime(propsStartTime);
    setEndTime(propsEndTime);

    setCurrentTime({ startTime: propsStartTime, endTime: propsEndTime });
    setDefaultTime({ startTime: propsStartTime, endTime: propsEndTime });

    toggleZoomFocus();

    if (!props.isOpen) {
      setPreviewMedia(null);
    }
  }, [props.isOpen]);

  useEffect(() => {
    // Revival items do not have LENGTH in the metadata
    if (!previewMedia?.length || props?.duration || !props?.isRevival) return;

    const startTime = 0;
    const endTime = msToSec(previewMedia.length);

    setStartTime(startTime);
    setEndTime(endTime);

    setCurrentTime({ startTime, endTime });
    setDefaultTime({ startTime, endTime });
  }, [previewMedia]);

  useEffect(() => {
    zoomSlider.value = round(zoomSlider.value, 2);
    // if (zoomSlider.isSliding) return;
    setZoomLevel(zoomSlider.value * 100);
  }, [zoomSlider.value, zoomSlider.isSliding]);

  useEffect(() => {
    setName(props.filename);
  }, [props.filename]);

  useEffect(() => {
    if (isNil(duration) || props?.isPopper || isNaN(duration) || duration === 0)
      return;
    setEndTime(duration);
  }, [duration]);

  useEffect(() => {
    if (isNil(videoRef.current?.duration) || isNaN(videoRef.current?.duration))
      return;

    if (initialRender.current) {
      setEndTime(round(videoRef.current?.duration!, 3));
    } else {
      initialRender.current = true;
    }
  }, [videoRef.current?.duration]);

  useEffect(() => {
    validateTime();
    // setDurationSec(endTime - startTime);

    setCurrentTime({ startTime, endTime });
  }, [startTime, endTime]);

  useEffect(() => {
    if (isNil(props.startTime) || isNil(props.endTime)) return;

    setStartTime(props.startTime);
    setEndTime(props.endTime);
  }, [props.startTime, props.endTime]);

  useEffect(() => {
    if (statusCode === 200) {
      props?.onSuccess?.({ isRecreate: false });
      if (!props.existedData) {
        dispatch({
          type: actionTypes.INIT_PUBLISHED_VERSION_LIBRARY_WATCH,
          mediaid: props?.mediaid,
        });
      }
    }
  }, [loading]);

  useEffect(() => {
    if (!videoRef?.current) return;

    // VIDEO can play IMMEDIATELY
    if (
      [
        MediaReadyState.HAVE_ENOUGH_DATA,
        MediaReadyState.HAVE_METADATA,
      ].includes(videoRef.current.readyState)
    ) {
      toggleMediaLoading(false);
      videoRef.current.textTracks[0].mode = showCaption ? 'showing' : 'hidden';
      // videoRef.current.currentTime = toNumber(defaultTime?.startTime);
    }
  }, [videoRef.current?.readyState]);

  useEffect(() => {
    if (isEmpty(media) || isEmpty(props?.mediaid)) {
      fetchMediaAsync(props.mediaid);
    }
  }, [media]);

  useEffect(() => {
    // Transcription page no need to fetch media
    const shouldCallAPI =
      isLibraryPage() || isDisplayClips() || isRevivalPage();

    if (shouldCallAPI && !isEmpty(props?.mediaid) && !isEmpty(media)) {
      fetchMediaAsync(props.mediaid);
    }
  }, [props?.mediaid]);

  const handleCloseError = () => {
    toggleTitleError(null);
  };

  const fetchMediaAsync = async (mediaid: string) => {
    try {
      toggleMediaLoading(true);
      const params: any = { mediaid };
      // if (props?.isDisplayClips) {
      //   params.versioncount = clips.focusClip?.versioncount;
      // }
      const { data: mediaResponse }: AxiosResponse<PreviewResponse> =
        await MediaService.getPreview(params);

      setPreviewMedia(mediaResponse);
      setRawMediaSrc(mediaResponse?.url);
      setRawVttPath(mediaResponse?.subtitleurl);

      toggleLoadingTopicTags(true);
      if (mediaResponse?.jsonfileurl) {
        axios
          .get(mediaResponse?.jsonfileurl)
          .then(({ data: transcriptData }) => {
            if (transcriptData?.waveform) {
              dispatch(setWaveform(transcriptData.waveform));
            }
            if (transcriptData?.keywords) {
              setTopicKeyword(transcriptData.keywords);
            }
            if (transcriptData?.entities) {
              setTopicEntities(transcriptData.entities);
            }
            if (transcriptData?.customTerms) {
              setTopicCustomTerms(transcriptData.customTerms);
            }
          })
          .finally(() => {
            toggleLoadingTopicTags(false);
          });
      }
    } catch (err: any) {
      console.log('HTTP ERROR', err);
      customToast.error('Something went wrong');
    }
  };

  const getMetadataEntity = () => {
    // Be careful of infinite loop of setMetadataEntity()
    if (currentTopicLayer === Layers.KEY_TERM) {
      return isTranscriptionPage() ? player.keywords : topicKeyword;
    }

    if (currentTopicLayer === Layers.CUSTOM_TERM) {
      return isTranscriptionPage()
        ? player.layerCustomTerms?.find(
            (t) => t?.listId === player?.focusLayerCustomTermId,
          )?.matched ?? []
        : topicCustomTerms;
    }

    if (isTranscriptionPage()) {
      return (player.entities as any)?.[getLayerEntity(currentTopicLayer)];
    }

    return (topicEntities as any)?.[getLayerEntity(currentTopicLayer)];
  };

  const handleDownloadCheckboxClick = (
    evt: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = evt.target.checked;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const handleToggleCaption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isShowCaptions = e.target.checked;
    toggleShowCaption(isShowCaptions);

    if (shouldUsePlyr() && plyrInstance) {
      plyrInstance.toggleCaptions?.(isShowCaptions);
      return;
    }

    if (videoRef?.current) {
      videoRef.current.textTracks[0].mode = isShowCaptions
        ? 'showing'
        : 'hidden';
    }
  };

  const validateTime = () => {
    const errors: any = {};
    toggleStartError(false);
    toggleEndError(false);

    if (endTime <= startTime) {
      errors.difference = 'End time is before or equal start time!';
      toggleStartError(true);
      toggleEndError(true);
    }

    if (floor(endTime) > (duration || round(videoRef.current?.duration!, 3))) {
      errors.invalidEndTime =
        'End time cannot be more than the end time of the item!';
      toggleEndError(true);
    }

    setErrors(errors);
  };

  const shouldDisableRecreateButton = (): boolean => {
    if (!isRecreateMode) return true;

    return isValidClipCreationParams();
  };

  const shouldDisableCreateButton = (): boolean => {
    if (isRecreateMode) return true;

    return isValidClipCreationParams();
  };

  const isValidClipCreationParams = (): boolean => {
    if (isManualTab) {
      return hasStartError || hasEndError || !endTime;
    }

    if (isTopicTab) {
      return (
        // a Tag or a themText has to be selected
        (!hasSelectedTag && isEmpty(topicThemeText?.trim())) ||
        durationSec <= 120
      );
    }

    return false;
  };

  const handlePublish = (
    evt: React.FormEvent<HTMLFormElement> | null,
    isRecreate: boolean = false,
  ) => {
    evt?.preventDefault();

    if (payment.isExpiredTrial) {
      customToast.trialExpired();
      return;
    }

    if (isEmpty(name)) {
      toggleTitleError(true);
      return;
    }

    const payload: IPublishPayload = {
      mediaid: props.mediaid,
      versionname: name,
      starttime: isTopicTab ? secToTimeCode(0) : secToTimeCode(startTime),
      endtime: isTopicTab ? secToTimeCode(0) : secToTimeCode(endTime),
      captionstype: state.hasBurnedIn ? 'OPEN' : 'CLOSED',
      download: state.download ? 1 : 0,
      captiondownload: state.captiondownload ? 1 : 0,
      librarysearch: true ? 1 : 0,
      transcriptsearch: true ? 1 : 0,
      keyterms: state.keyterms ? 1 : 0,
      summaries: state.summaries ? 1 : 0,
      names: state.people ? 1 : 0,
      products: state.products ? 1 : 0,
      orgs: state.orgs ? 1 : 0,
      locations: state.locations ? 1 : 0,
      nationalities: state.nationalities ? 1 : 0,
      chapters: state.chapters ? 1 : 0,
      globalList: state.globalList ? 1 : 0,
      includeUrlCsv: state.includeUrlCsv ? 1 : 0,
      logo: state.logo ? 1 : 0,
      isSubscriber: !tier.isPAYG && !tier.isTrial ? 1 : 0,
      iab: state.iab ? 1 : 0,
      visualplaybar: state.visualplaybar ? 1 : 0,
      paraheader: state.paraheader ? 1 : 0,
      hasAdMarkersLayer: state.hasAdMarkersLayer ? 1 : 0,
      speakers: state.speakers ? 1 : 0,
      dar: publishRatio,
      topicTerm: isTopicTab ? topicThemeText || currentTopicTag : undefined,
      layer: !isEmpty(topicThemeText)
        ? 'theme'
        : (getCurrentLayerName() as string),
      isTopicClip: isTopicTab,
    };

    if (!isRecreate) {
      dispatch({
        type: actionTypes.INIT_PUBLISH_LIBRARY_WATCH,
        ...payload,
      });
    } else {
      const clip = clips.focusClip;
      payload.versioncount = clip?.versioncount;
      payload.isSuggested =
        (clip?.versioncount?.startsWith('suggessted') ||
          clip?.versioncount?.startsWith('trending')) &&
        clip?.statuscode === ClipStatusCode.SHARED;

      if (isSuggestedClips(clip) || isTrendingClips(clip)) {
        payload.isSuggested = true;
      }

      setIsRecreating(true);
      MediaService.rePublish(payload)
        .then(() => {
          props.closeShareModal();
          props?.onSuccess?.({ isRecreate: true });
        })
        .catch((e: any) => {
          customToast.error('Something went wrong');
        })
        .finally(() => {
          setIsRecreating(false);
        });
    }
  };

  const handleRegionUpdate = (start: number, end: number) => {
    setStartTime(round(start, 3));
    setEndTime(round(end, 3));
  };

  const handleChangeTopicLayer = (layer: Layers) => {
    setCurrentTopicLayer(layer);
    setTopicThemeText('');
    setCurrentTopicTag('');
  };

  const handleInputTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
    if (isEmpty(value)) {
      toggleTitleError(true);
    } else {
      toggleTitleError(false);
    }
  };

  const handleCancel = () => {
    props?.closeShareModal?.();
    setPreviewMedia(null);
  };

  return (
    <>
      {(loading || isRecreating) && (
        <div className="loader__component">
          <Loader />
        </div>
      )}
      {statusCode != null ? (
        <>
          <div className="grid-x">
            <div className="cell large-12">
              <h2>Create clip</h2>
            </div>
          </div>
          <div className="grid-x">
            <div className="cell small-12">
              <div className="deleteInfo" tw="bg-sonnant-green! mt-5 rounded">
                <p>
                  {statusMessage === 'Published successfully'
                    ? 'A new clip is being created. This might take some time if open captions are being burned into the video. You can see the clip on the View clips page for the content item. It will also be available to select for sharing and exporting.'
                    : statusMessage}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <form className="publishmodal" onSubmit={handlePublish}>
          <div className="grid-x">
            <div className="cell large-12">
              <h2 tw="mb-0" title={props?.snippetText}>
                {isRecreateMode ? 'Edit' : 'Create'} clip
              </h2>
            </div>
          </div>
          <ul
            className="menu margin-left-2"
            tw="flex-nowrap flex-1 border-bottom[1px solid] border-sonnant-grey-3 mb-4"
          >
            <li
              onClick={() => toggleManualTab(true)}
              css={[tabItemCss, isManualTab && navbarTabActiveCss]}
            >
              Time
            </li>
            <li
              onClick={() => toggleManualTab(false)}
              css={[tabItemCss, isTopicTab && navbarTabActiveCss]}
            >
              <div className="active underline">Topic</div>
            </li>
          </ul>

          <div>
            <div>
              <label tw="text-15!">
                Title
                <input
                  type="text"
                  placeholder="Give Clip a name"
                  value={name}
                  onChange={handleInputTitle}
                  autoFocus
                />
                <Notification
                  close={handleCloseError}
                  type={titleError ? 'error' : null}
                  message={'Title is required'}
                />
              </label>
            </div>

            {isManualTab && (
              <>
                <div css={plyrResetStyle} tw="rounded shadow">
                  {!plyrInstance && (
                    <div tw="flex justify-center items-center height[36rem]">
                      <Loader />
                    </div>
                  )}
                  <span
                    css={[
                      !plyrInstance && tw`hidden`,
                      videoRatioCss(publishRatio),
                    ]}
                  >
                    {video}
                  </span>
                </div>

                <div tw="bg-sonnant-purple-4 text-white p-5 rounded-xl my-3">
                  <div tw="font-size[1.4rem] flex justify-between items-center user-select[none]">
                    <div tw="flex items-center flex-1">
                      <div
                        className="button"
                        tw="(bg-sonnant-grey-5 rounded-lg mb-0 font-size[1.3rem] py-2 px-3)!"
                        onClick={toggleReset}
                      >
                        Reset
                      </div>
                      <label tw="cursor-pointer ml-6 text-white font-size[1.3rem]! font-light">
                        <input
                          tw="mr-2!"
                          checked={showCaption}
                          // defaultChecked={true}
                          onChange={handleToggleCaption}
                          type="checkbox"
                        />
                        <span className="checkmark" tw="-mt-1"></span>
                        Preview captions
                      </label>
                    </div>
                    <div tw="flex items-center mb-2">
                      <RewindSvg
                        tw="height[2.4rem] cursor-pointer"
                        onClick={() => controls.seek(videoState.time - 5)}
                      />
                      <div
                        tw="mx-3 flex"
                        className={isMediaLoading ? 'disabled' : ''}
                      >
                        {videoState.paused ? (
                          <Play
                            css={[playPauseStyle]}
                            onClick={controls.play}
                          />
                        ) : (
                          <Pause
                            css={[playPauseStyle]}
                            onClick={controls.pause}
                          />
                        )}
                      </div>
                      <ForwardSvg
                        tw="height[2.4rem] cursor-pointer"
                        onClick={() => controls.seek(videoState.time + 5)}
                      />
                    </div>
                    <div tw="flex-1 text-right text-13">
                      <div>
                        Total time:{' '}
                        {secToTime(videoRef?.current?.duration || 0)}
                      </div>
                      <div>Clip duration: {secToTime(durationSec)}</div>
                    </div>
                  </div>
                  <div
                    className="waveform-bar"
                    tw="w-full text-center bg-sonnant-grey-5 rounded-md mt-2.5 mb-4 min-height[3.5rem]"
                  >
                    <WaveSurferVideo
                      src={media?.url || rawMediaSrc!}
                      ref={videoRef}
                      currentTime={currentTime}
                      defaultTime={defaultTime}
                      shouldReset={shouldReset}
                      shouldZoomFocus={shouldZoomFocus}
                      zoomLevel={zoomLevel}
                      setZoomLevel={(zoom) => {
                        zoomSlider.value = zoom / 100;
                        forceUpdate();
                      }}
                      onRegionUpdate={debounce(handleRegionUpdate, 20)}
                    />
                  </div>
                  <div tw="flex justify-between font-size[1.4rem] mt-3">
                    <div tw="flex items-center text-13 width[17.8rem] cursor-pointer hover:(text-sonnant-grey-3)! select-none">
                      <span tw="flex" onClick={toggleZoomFocus}>
                        <ZoomSvg tw="mr-3" css={svgHoverShadow(0.8)} /> Zoom
                        clip
                      </span>
                    </div>

                    <div tw="flex items-center font-medium">
                      <span
                        tw="font-size[1.8rem] cursor-pointer select-none"
                        onClick={() => {
                          if (round(zoomSlider.value, 1) < 0.1) return;
                          zoomSlider.value -= 0.1;
                          forceUpdate();
                        }}
                      >
                        -
                      </span>
                      <div
                        ref={zoomClipRef}
                        tw="width[14rem] height[4px] rounded mx-6 bg-disabled relative"
                      >
                        <div
                          tw="absolute border[1px solid] border-disabled width[14px] height[14px] rounded-full bg-white top[-5px] margin-left[-7px] cursor-pointer hover:shadow"
                          style={{ left: zoomSlider.value * 100 + '%' }}
                        ></div>
                      </div>
                      <span
                        tw="font-size[1.8rem] cursor-pointer select-none"
                        onClick={() => {
                          if (zoomSlider.value >= 1) return;
                          zoomSlider.value += 0.1;
                          forceUpdate();
                        }}
                      >
                        +
                      </span>
                    </div>

                    <div tw="flex space-x-6 text-13">
                      <div>Start: {secToTime(startTime)}</div>
                      <div>End: {secToTime(endTime)}</div>
                    </div>
                  </div>
                </div>

                <div tw="(flex flex-wrap)!">
                  <div className="large-6 cell">
                    <label tw="text-15!">
                      Start Timecode
                      <InputTimeCode
                        size="sm"
                        time={startTime}
                        handleTime={setStartTime}
                        hasError={hasStartError}
                      />
                    </label>
                    {errors.startTime && (
                      <small className="danger">{errors.startTime}</small>
                    )}
                  </div>

                  <div className="large-6 cell">
                    <label tw="text-15!">
                      End Timecode
                      <InputTimeCode
                        size="sm"
                        time={endTime}
                        handleTime={setEndTime}
                        hasError={hasEndError}
                      />
                    </label>
                    {errors.endTime && (
                      <small className="danger">{errors.endTime}</small>
                    )}
                  </div>
                  <div className="large-12 cell">
                    <div>
                      {errors.difference && (
                        <small className="danger">{errors.difference}</small>
                      )}
                    </div>
                    <div>
                      {errors.invalidEndTime && (
                        <small className="danger">
                          {errors.invalidEndTime}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {isTopicTab && (
              <div tw="mb-3">
                <label tw="line-height[unset]!">
                  <span tw="text-15">Select a Tag</span>
                </label>
                <div tw="ml-10">
                  <label>
                    <span tw="text-15">Layer</span>
                  </label>
                  <div tw="w-1/2">
                    <TopicLayerDropDown
                      defaultValue={getTopicLayerName(currentTopicLayer)}
                      onChange={handleChangeTopicLayer}
                    />
                  </div>
                  <div className="w-1/2">
                    <label>
                      Tag
                      <CustomSelectSearch
                        mode={Dropdown.TOPIC_CLIP}
                        options={getMetadataEntity()?.map((item: any) => ({
                          label: item?.keyword,
                          value: item?.keyword,
                        }))}
                        defaultValue={currentTopicTag}
                        isTopicTag
                        isLoading={isLoadingTopicTags}
                        customStyles={topicTagCustomStyles}
                        onChange={handleChangeTag}
                        canCreate={false}
                      />
                    </label>
                  </div>
                </div>
                <label tw="mt-3.5">
                  <span tw="text-15">or Theme</span>
                  <div tw="ml-10 flex space-x-10">
                    <input
                      type="text"
                      tw="width[70%]"
                      value={topicThemeText}
                      onChange={handleChangeThemeText}
                    />
                    <div tw="mt-4">
                      <QuestionHint
                        type={'information'}
                        hideCloseButton
                        text={
                          <p>
                            Sonnant will attempt to create a clip on the entered
                            theme. No clip will be created if nothing can be
                            found that relates to this theme.
                          </p>
                        }
                        align="bottomRight"
                        action="hover"
                        customStyle={questionCss}
                      />
                    </div>
                  </div>
                </label>
              </div>
            )}

            <div tw="w-full text-right flex items-end pr-3 cursor-pointer justify-between">
              <div tw="flex justify-between w-full mb-1">
                <label>
                  <span tw="text-15">Display aspect ratio</span>
                </label>

                <Hint
                  disabled={!shouldDisableBurnedIn}
                  text={
                    'Not available on free trial. Please upgrade to enable.'
                  }
                  enterDelay={100}
                  arrow
                >
                  <label
                    tw="(cursor-pointer whitespace-nowrap relative top[-2px])!"
                    css={shouldDisableBurnedIn && tw`opacity-50`}
                  >
                    <input
                      name="hasBurnedIn"
                      checked={state.hasBurnedIn}
                      onChange={handleDownloadCheckboxClick}
                      type="checkbox"
                      readOnly={shouldDisableBurnedIn}
                      disabled={shouldDisableBurnedIn}
                      value="OPEN"
                    />
                    <span className="checkmark"></span>
                    <span tw="text-15">
                      Create clip with captions burned-in (open captions)
                    </span>
                  </label>
                </Hint>
              </div>
            </div>

            <div tw="flex">
              <div>
                <Hint
                  text={
                    isMediaLoading
                      ? 'Loading metadata. Please wait...'
                      : 'Only audio with burned in captions can be resized.'
                  }
                  disabled={canChangeDAR}
                  enterDelay={200}
                  notTransparent
                  arrow
                >
                  <div
                    css={!canChangeDAR && [tw`opacity-70 cursor-not-allowed`]}
                  >
                    <span css={!canChangeDAR && [tw`pointer-events-none`]}>
                      <PublishRatioPicker
                        onChange={setPublishRatio}
                        defaultValue={publishRatio}
                        disabled={!canChangeDAR}
                        tabIndex={!canChangeDAR ? -1 : undefined}
                      />
                    </span>
                  </div>
                </Hint>
              </div>
              <div tw="flex space-x-2">
                <button
                  type="button"
                  className="button btn-secondary large cancel small-3 cell"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <Hint
                  disabled={!(isTopicTab && durationSec <= 120)}
                  open={isTopicTab && durationSec <= 120}
                  text="The content item duration should be at least 2 minutes to create a topic clip."
                  enterDelay={100}
                  notTransparent
                >
                  <div tw="space-x-2">
                    <button
                      // disabled={!canRecreateClip}
                      disabled={shouldDisableRecreateButton()}
                      type="button"
                      className={`button large cancel small-3 cell ${
                        canRecreateClip ? 'btn-primary' : 'btn-secondary'
                      }`}
                      onClick={() => handlePublish(null, true)}
                    >
                      Recreate
                    </button>

                    <button
                      type="submit"
                      className={`button btn-primary  large apply small-3 cell`}
                      disabled={shouldDisableCreateButton()}
                    >
                      Create
                    </button>
                  </div>
                </Hint>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

const tabItemCss = css`
  ${tw`text-sonnant-purple-3 relative bottom[-2px] h-full flex items-center box-border px-3.5 py-2! cursor-pointer hover:(bg-sonnant-grey-1!)`}

  :not(:first-of-type) {
    ${tw`ml-3`}
  }
`;

const questionCss = css`
  .rmwc-tooltip {
    margin-top: 6px !important;
  }

  .notificationWrapper {
    ${tw`width[30rem] mt-2`};
  }

  .custom-tooltip-text-container {
    ${tw`left[-2.3rem]`};
  }
`;

const videoRatioCss = (ratio: Ratio) => css`
  .plyr--video {
    aspect-ratio: ${ratioToCssString(ratio)};
    max-height: 360px;
    margin: auto;
  }
  .plyr__poster,
  .plyr__video-wrapper {
    background-color: #000;
  }
  .plyr__captions {
    font-size: ${ratioFontSize(ratio)};
  }
`;
