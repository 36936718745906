import axios from 'axios';
import { clearSession } from 'utils/utils';
import { config } from '../components/config';
import { getToken, setIsLoggedIn, setToken } from '../utils/utils';

const { apiGateway } = config;

const Amazon = () => {
  const defaultOptions = {
    baseURL: apiGateway.URL,
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 5 * 60 * 1000, // 5 min
  };

  let instance = axios.create(defaultOptions);

  instance.interceptors.request.use(function (config) {
    const token = getToken();
    config.headers.Authorization = token ? token.AccessToken : '';
    config.headers['X-Api-Key'] = apiGateway.X_API_KEY;
    if (!process.env.REACT_APP_SUBDOMAIN) {
      config.headers['Access-Control-Allow-Origin'] = window.location.origin;
    }
    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (
        [
          '/auth/signin',
          '/auth',
          '/auth/signin-social',
          '/auth/change-password',
          '/auth/confirm-password',
        ].includes(originalRequest.url)
      ) {
        return Promise.reject(error.response);
      }

      if (
        [401, 402, 403].includes(error?.response?.status) &&
        error?.response?.config?.url !== '/auth/refresh-token' &&
        !originalRequest?._retry
      ) {
        originalRequest._retry = true;
        const token = JSON.parse(localStorage.getItem('token') as string);

        try {
          const response = await instance.post('/auth/refresh-token', {
            refreshToken: token?.RefreshToken,
            idToken: token?.IdToken,
          });

          setToken(response.data);
          setIsLoggedIn();

          originalRequest.headers.Authorization = response.data.AccessToken;
          return instance(originalRequest);
        } catch (err: any) {
          clearSession();

          window.location.assign(
            `/signin${window.location.search}${window.location.hash}`,
          );
        }
      } else {
        error.message = 'Something went wrong! Please try again later.';
      }
      return Promise.reject(error);
    },
  );

  return instance;
};

export default Amazon();

export const BaseAPI = Amazon();
