import { config } from 'components/config';

export const loadAppleJsScript = () => {
  // Prevent existed script from loading
  if ((window as any)?.AppleID) {
    initAppleIDAuthClientAsync();
    return;
  }

  const id = 'apple-id-js';
  const src =
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
  const firstJs: any = document.getElementsByTagName('script')[0];

  if (document.getElementById(id)) {
    return;
  }
  const js = document.createElement('script');
  js.id = id;
  js.src = src;
  // js.async = false;
  js.defer = true; // Wait until the page has loaded.
  firstJs.parentNode.insertBefore(js, firstJs);

  js.onload = () => {
    initAppleIDAuthClientAsync();
  };
};

export const initAppleIDAuthClientAsync = async () => {
  const response = await (window as any).AppleID.auth.init({
    clientId: config.APPLE_CLIENT_ID,
    redirectURI: config.APPLE_REDIRECT_URI,
    scope: 'name email',
    state: 'sonnant:web',
    // nonce : '[NONCE]',
    usePopup: true, //or false defaults to false
  });

  return response;
};
