/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { useSearchParam } from 'react-use';
import { Social } from 'utils/enum';

export const SharePostWithTwitter = () => {
  const oauth_token = useSearchParam('oauth_token');
  const oauth_verifier = useSearchParam('oauth_verifier');
  const provider = Social.TWITTER;

  useEffect(() => {
    window.document.cookie = `oauth_token=${oauth_token};domain=.${window.location.host}; path=/`;
    window.document.cookie = `oauth_verifier=${oauth_verifier};domain=.${window.location.host}; path=/`;
    window.document.cookie = `state=true;domain=.${window.location.host}; path=/`;
    window.document.cookie = `provider=${provider};domain=.${window.location.host}; path=/`;
    window.close();
  });

  return (
    <div className="App">
      <header className="App-header"></header>
    </div>
  );
};
