/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import React, { useEffect, useState } from 'react';
import {
  layerSelector,
  nextLayer,
  prevLayer,
  setSearchTerm,
  setSortBy,
  toggleSortOrder,
} from 'slices/layers.slice';
import { ReactComponent as Back } from '../../../../../../assets/Icons/Back.svg';
import { ReactComponent as Next } from '../../../../../../assets/Icons/Right.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getLayerName } from '../../../../../../utils/utils';
import { ReactComponent as AscendingArrow } from 'assets/Icons/Ascending_Arrow.svg';
import { ReactComponent as DescendingArrow } from 'assets/Icons/Descending_Arrow.svg';

import cls from './LayerSwitcher.module.scss';
import { RootState } from 'reducers';
import { Hint } from 'components/shared/Hint';
import {
  arrowActive,
  arrowCss,
  dropdownCss,
} from 'components/shared/twin.styles';
import { useUnmount } from 'react-use';
import { Layers } from 'utils/enum';
import { LayerCustomTerm, SortKey, SortList } from 'utils/models';
import { isNil } from 'lodash';
import { setFocusLayerCustomTerm } from 'slices/player.slice';
import { SearchInput } from 'components/shared/SearchInput';
import { isPublicShared } from 'components/VideoPlayer/Transcription/MediaUtilities';

interface Props {
  showArrow?: boolean;
  showTermListModal?: () => void;
}

export const LayerSwitcher = ({ showArrow = true }: Props) => {
  const layers = useSelector((state: RootState) => state.layers);
  const player = useSelector((state: RootState) => state.player);
  const currentLayer = useSelector(layerSelector);

  const [appliedGlobalList, setAppliedGlobalList] = useState<LayerCustomTerm[]>(
    [],
  );
  const focusList = appliedGlobalList.find(
    (list) => list?.listId === player?.focusLayerCustomTermId,
  );

  const sortList: SortList[] = [
    { id: 'relevance', name: 'relevance', hidden: currentLayer.isSpeaker },
    { id: 'modified', name: 'modified', hidden: currentLayer.isSpeaker },
    { id: 'frequent', name: 'most used', hidden: currentLayer.isSpeaker },
    { id: 'alphabet', name: 'A-Z', hidden: currentLayer.isSpeaker },
    {
      id: 'appearance',
      name: 'order of appearance',
      hidden: currentLayer.isSpeaker,
    },
    // SPEAKER LAYER ONLY
    { id: 'order', name: 'Order', hidden: !currentLayer.isSpeaker },
    { id: 'time', name: 'Time', hidden: !currentLayer.isSpeaker },
    { id: 'words', name: 'Words', hidden: !currentLayer.isSpeaker },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    const availableSorts = sortList.filter((list) => !list.hidden);

    dispatch(
      setSortBy({ by: availableSorts[0].id, name: availableSorts[0].name }),
    );
  }, [currentLayer.enum]);

  useUnmount(() => {
    dispatch(setSearchTerm(''));
  });

  useEffect(() => {
    if (isNil(player.layerCustomTerms)) return;

    setAppliedGlobalList(player.layerCustomTerms.filter((l) => l?.active));
  }, [player.layerCustomTerms]);

  const filterIABSort = (list: SortList[]): SortList[] => {
    const iabSorts: SortKey[] = ['relevance', 'alphabet'];

    return list
      .filter((i) => !i.hidden)
      .filter((item) => {
        return currentLayer.isIAB ? iabSorts.includes(item.id) : true;
      });
  };

  const canChangeLayer = () => {
    return layers.layerLength > 1;
  };

  const canShowNameLayer = () => {
    return layers.layerLength > 0;
  };

  const handleInputSearch = (term: string) => {
    dispatch(setSearchTerm(term));
  };

  const shouldHideSearch = [
    Layers.SUMMARY,
    Layers.CHAPTER,
    Layers.AD_MARKER,
    ...(!isPublicShared() ? [Layers.CUSTOM_TERM] : []),
  ].includes(layers.currentLayer);

  const isCustomTermsLayer = [Layers.CUSTOM_TERM].includes(layers.currentLayer);

  const hasCustomTerms = appliedGlobalList?.length > 0;

  return (
    <div className={cls.layerNavigator} tw="flex items-center">
      <div
        className="keyterms"
        tw="(h-[4rem] w-full flex flex-1 px-3.5 mt-0 md-down:flex-col md-down:height[auto] md-down:pt-4 md-down:pb-3 padding-right[6px])!"
        css={showArrow ? tw`justify-between!` : tw`justify-center!`}
      >
        <div
          tw="flex items-center md-down:w-full"
          css={shouldHideSearch && tw`flex-1`}
        >
          {showArrow && (
            <div className={canChangeLayer() ? '' : 'disabled'}>
              <button
                className={cls.navigatorIcon}
                onClick={() => {
                  if (!canChangeLayer()) return;
                  dispatch(prevLayer());
                }}
              >
                <Back />
              </button>
            </div>
          )}
          <Hint
            text={`${getLayerName(layers.currentLayer)} spoken during file`}
          >
            <span
              tw="md-down:text-14 text-16 mx-3 whitespace-nowrap flex-1 max-width[10rem] select-none md-down:flex-1 md-down:max-width[unset]"
              css={[
                currentLayer.isSegment
                  ? tw`min-w-[15.4rem]`
                  : tw`min-w-[12.4rem]`,
              ]}
              className="line-clamp-1"
            >
              {canShowNameLayer() && getLayerName(layers.currentLayer)}
            </span>
          </Hint>
          {showArrow && (
            <div className={canChangeLayer() ? '' : 'disabled'}>
              <button
                className={cls.navigatorIcon}
                onClick={() => {
                  if (!canChangeLayer()) return;
                  dispatch(nextLayer());
                }}
              >
                <Next />
              </button>
            </div>
          )}
        </div>
        <div
          tw="items-center hidden ml-2 margin-right[-1.4rem] md-down:w-full"
          css={[
            isCustomTermsLayer && tw`hidden`,
            isCustomTermsLayer && isPublicShared() && tw`flex`,
          ]}
        >
          <div
            className="button-group sort_modify"
            tw="flex items-center flex-nowrap"
          >
            <div className="dropdown" css={dropdownCss}>
              {hasCustomTerms && (
                <a
                  className="button"
                  tw="(width[unset] height[3rem] py-0 px-3 flex items-center text-13 whitespace-nowrap)!"
                >
                  {focusList?.listName}
                  <span className="arrow-down" tw="-mt-1"></span>
                </a>
              )}
              <ul className="dropdown-sort-content manageDropDown">
                {appliedGlobalList.map((list) => (
                  <li
                    key={list.listId}
                    onClick={() => dispatch(setFocusLayerCustomTerm(list))}
                  >
                    <a>{list.listName}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div
          tw="flex items-center md-down:w-full"
          css={shouldHideSearch && tw`hidden`}
        >
          <div
            className="button-group sort_modify"
            tw="flex items-center flex-nowrap flex-grow-0"
          >
            <div className="sort-icon " tw="md-down:pl-0">
              <div
                tw="flex flex-col mr-2 cursor-pointer"
                onClick={() => dispatch(toggleSortOrder())}
              >
                <DescendingArrow
                  css={[arrowCss, !layers.sort.isAsc && arrowActive]}
                />
                <AscendingArrow
                  css={[arrowCss, layers.sort.isAsc && arrowActive]}
                />
              </div>
            </div>
            <div className="dropdown" css={dropdownCss}>
              <a
                className="button"
                tw="(width[unset] height[3rem] py-0 px-3 flex items-center text-13 whitespace-nowrap)!"
              >
                Sort by {layers.sort.name}
                <span className="arrow-down" tw="-mt-1"></span>
              </a>
              <ul className="dropdown-sort-content manageDropDown">
                {filterIABSort(sortList).map((s) => (
                  <li
                    key={s.id}
                    onClick={() =>
                      dispatch(setSortBy({ by: s.id, name: s.name }))
                    }
                  >
                    <a>Sort by {s.name}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div css={[customSearchInputStyle, tw`(flex-1 right[0%])!`]}>
            <SearchInput placeholder="Search" setTerm={handleInputSearch} />
          </div>
        </div>
      </div>
    </div>
  );
};

const customSearchInputStyle = css`
  input:not([type='checkbox']) {
    ${tw`(height[3rem] width[12rem] text-12 font-normal font-family[inherit])!`}
  }

  .search-container button {
    ${tw`(height[3rem] width[3rem])!`}
  }
`;
