/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import 'twin.macro';
import tw from 'twin.macro';

import { Tooltip } from '@rmwc/tooltip';
import { ReactComponent as Export } from 'assets/Icons/Export.svg';
import { ReactComponent as QuestionSvg } from 'assets/Icons/Vector_question.svg';
import { ReactComponent as CloseSvg } from 'assets/Icons/close.svg';
import { Notification } from 'components/UiControls/notification/Notification';
import Loader from 'components/loader/loader';
import { hoverDropShadow } from 'components/shared/twin.styles';
import { defaultTo, values } from 'lodash';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { toggleOpenDownload } from 'slices/embed.slice';
import { MediaService } from 'services';
import { customToast } from 'utils/toast.util';
import { EmbedDownloadForm, ExportEmbedType } from 'utils/models';
import { useToggle } from 'react-use';
import { EmbedBackgroundBlur } from '../EmbedPlayer/EmbedBackgroundBlur';
import { CheckboxHookForm } from 'components/shared/CheckboxHookForm';

type Props = {};

export const EmbedDownload = ({ ...props }: Props) => {
  const dispatch = useDispatch();

  const { register, watch, handleSubmit } = useForm<EmbedDownloadForm>();

  const embed = useSelector((state: RootState) => state.embed);
  const embedExportOption = embed.exportOption;

  const media = useSelector((state: RootState) => state.media);

  const [isExportLoading, toggleIsExportLoading] = useToggle(false);

  const backgroundImage = defaultTo(media.metadata?.thumbnail, '');

  const isInvalidForm = values(watch()).every((value) => value === false);

  const canDownloadMp3 =
    embedExportOption.allowMediaDownload &&
    embedExportOption.haveMp3 &&
    !embedExportOption.isBurnedIn;

  const canDownloadMp4 =
    (embedExportOption.allowMediaDownload && embedExportOption.haveMp4) ||
    embedExportOption.isBurnedIn;

  const downloadURI = (uri: string) => {
    var element = document.createElement('a');

    element.setAttribute('href', uri);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();

    setTimeout(function () {
      document.body.removeChild(element);
    }, 5000);
  };

  const handleSubmitDownload = async (data: EmbedDownloadForm) => {
    // At least one option to be selected
    if (isInvalidForm) return;

    try {
      toggleIsExportLoading(true);

      const payload: ExportEmbedType = {
        media: media,
        exportOption: data,
        isBurnedIn: embedExportOption.isBurnedIn,
      };

      const { data: exportedDataResponse } = await MediaService.exportEmbed(
        payload,
      );

      if (Object.keys(exportedDataResponse).length) {
        let lstUrl: string[] = [];

        if (exportedDataResponse.mp3) {
          lstUrl.push(exportedDataResponse.mp3);
        }

        if (exportedDataResponse.mp4) {
          lstUrl.push(exportedDataResponse.mp4);
        }

        if (exportedDataResponse.textData.notZipUrl) {
          lstUrl.push(exportedDataResponse.textData.notZipUrl);
        } else if (exportedDataResponse.textData.zipUrl) {
          lstUrl.push(exportedDataResponse.textData.zipUrl);
        }

        let timeOut = 500;
        for (let index in lstUrl) {
          setTimeout(function () {
            downloadURI(lstUrl[index]);
          }, timeOut);

          timeOut += 2000;
        }
      }
    } catch (err) {
      customToast.error('Something went wrong');
    } finally {
      toggleIsExportLoading(false);
    }
  };

  const handleClickClose = () => {
    dispatch(toggleOpenDownload(false));
  };

  return (
    <div css={[embed.isOpenDownload ? tw`flex!` : tw`hidden!`]}>
      <EmbedBackgroundBlur bgImage={backgroundImage} zIndex={4} />

      {isExportLoading && (
        <div className="loader__component">
          <Loader />
        </div>
      )}

      <form css={[contentCss]} onSubmit={handleSubmit(handleSubmitDownload)}>
        <div tw="flex flex-row justify-between">
          <div tw="flex flex-row justify-center items-center gap-x-3">
            <div>Download</div>
            <Tooltip
              align="bottomLeft"
              tw="z-[100]"
              leaveDelay={300}
              content={
                <div tw="top-0 left-[-1.5rem] text-16 rounded absolute">
                  <Notification
                    hideCloseButton
                    type="information"
                    message={
                      <div tw="py-1 whitespace-nowrap">
                        Multiple items will be exported as a ZIP file.
                      </div>
                    }
                  />
                </div>
              }
              css={customTooltipStyles}
            >
              <QuestionSvg css={[hoverDropShadow(0.2), questionIconCss]} />
            </Tooltip>
          </div>

          <div tw="h-[2.2rem]" onClick={handleClickClose}>
            <CloseSvg css={[hoverDropShadow(0.2), whiteIconCss]} />
          </div>
        </div>

        <div tw="flex flex-row justify-between flex-wrap max-w-[120rem]">
          <div css={downloadItemContainerCss}>
            <div css={downloadItemHeadingCss}>Media File</div>
            <div tw="flex flex-col">
              {canDownloadMp3 && (
                <div css={downloadItemContentCss}>
                  <CheckboxHookForm<EmbedDownloadForm>
                    register={register}
                    name={'mediaFileMp3'}
                    label={'MPEG-3 (.mp3)'}
                  />
                </div>
              )}

              {canDownloadMp4 && (
                <div css={downloadItemContentCss}>
                  <CheckboxHookForm<EmbedDownloadForm>
                    register={register}
                    name={'mediaFileMp4'}
                    label={'MPEG-4 (.mp4)'}
                  />
                </div>
              )}
            </div>
          </div>

          {embedExportOption.allowCaptionDownload && (
            <div css={downloadItemContainerCss}>
              <div css={downloadItemHeadingCss}>Captions File</div>
              <div tw="flex flex-col">
                <div css={downloadItemContentCss}>
                  <CheckboxHookForm<EmbedDownloadForm>
                    register={register}
                    name={'captionsFileVtt'}
                    label={'WebVTT (.vtt)'}
                  />
                </div>

                <div css={downloadItemContentCss}>
                  <CheckboxHookForm<EmbedDownloadForm>
                    register={register}
                    name={'captionsFileSrt'}
                    label={'SubRip (.srt)'}
                  />
                </div>
              </div>
            </div>
          )}

          {embedExportOption.allowTranscriptDownload && (
            <>
              <div css={downloadItemContainerCss} tw="min-w-[23rem]">
                <div css={downloadItemHeadingCss}>
                  Transcript - Timed Captions
                </div>
                <div tw="flex flex-col">
                  <div css={downloadItemContentCss}>
                    <CheckboxHookForm<EmbedDownloadForm>
                      register={register}
                      name={'transcriptTimedCaptionsPdf'}
                      label={'Adobe PDF (.pdf)'}
                    />
                  </div>

                  <div css={downloadItemContentCss}>
                    <CheckboxHookForm<EmbedDownloadForm>
                      register={register}
                      name={'transcriptTimedCaptionsDocx'}
                      label={'Word (.docx)'}
                    />
                  </div>

                  <div css={downloadItemContentCss}>
                    <CheckboxHookForm<EmbedDownloadForm>
                      register={register}
                      name={'transcriptTimedCaptionsTxt'}
                      label={'Plain Text (.txt)'}
                    />
                  </div>
                </div>
              </div>
              <div css={downloadItemContainerCss} tw="min-w-[24.2rem]">
                <div css={downloadItemHeadingCss}>
                  Transcript - Speaker Paragraphs
                </div>
                <div tw="flex flex-col">
                  <div css={downloadItemContentCss}>
                    <CheckboxHookForm<EmbedDownloadForm>
                      register={register}
                      name={'transcriptSpeakerParagraphsPdf'}
                      label={'Adobe PDF (.pdf)'}
                    />
                  </div>

                  <div css={downloadItemContentCss}>
                    <CheckboxHookForm<EmbedDownloadForm>
                      register={register}
                      name={'transcriptSpeakerParagraphsDocx'}
                      label={'Word (.docx)'}
                    />
                  </div>

                  <div css={downloadItemContentCss}>
                    <CheckboxHookForm<EmbedDownloadForm>
                      register={register}
                      name={'transcriptSpeakerParagraphsTxt'}
                      label={'Plain Text (.txt)'}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div tw="flex justify-start mt-[-15px]">
          <button
            type="submit"
            disabled={isInvalidForm}
            className="button btn-primary large"
            tw="mb-0 flex justify-center items-center h-[3.6rem]! shadow"
          >
            <Export tw="mr-3" css={whiteIconCss} />
            Download
          </button>
        </div>
      </form>
    </div>
  );
};

const contentCss = css`
  transform: translate(-50%, -50%);

  ${tw`flex flex-col text-white font-bold absolute top-[50%] left-[50%] z-[10] w-full h-full py-4 px-6 text-center`}
`;

const whiteIconCss = css`
  path {
    ${tw`fill[white]`}
  }
`;

const questionIconCss = css`
  path {
    ${tw`fill[color-grey-5]`}
  }
  &:hover path {
    ${tw`fill[color-purple-2]`}
  }
`;

const downloadItemContainerCss = css`
  ${tw`flex flex-col justify-start flex-1 overflow-hidden`}
`;

const downloadItemHeadingCss = css`
  ${tw`text-left text-15 text-sonnant-blue-light font-medium truncate`}
`;

const downloadItemContentCss = css`
  ${tw`text-left font-normal`}

  label {
    ${tw`text-14 whitespace-nowrap cursor-pointer text-white`}
  }
`;

const customTooltipStyles = css`
  ${tw`font-size[1rem]`}

  .rmwc-tooltip-inner {
    background: none;
  }
`;
