/** @jsxImportSource @emotion/react */
import 'twin.macro';
import './SignUp.scss';

import { loginFacebookSDKAsync } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { useState } from 'react';
import { AppleIdResponse, FbSdkResponse, GoogleIdResponse } from 'services';
import { AppleAuthError, Social } from 'utils/enum';
import { customToast } from 'utils/toast.util';

import { SignUpEmailAndPassword } from './SignUpEmailAndPassword';
import { SignUpSocial } from './SignUpSocial';
import { AuthSocialButtons } from 'components/shared/AuthSocialButtons/AuthSocialButtons';

export const SignUp = () => {
  const [socialCredential, setSocialCredential] = useState<
    | GoogleIdResponse
    | gapi.auth2.AuthResponse
    | AppleIdResponse
    | FbSdkResponse
    | null
  >(null);
  const [provider, setProvider] = useState<Social | null>(null);

  const handleLoginGoogleButton = async () => {
    try {
      const user = await window?.gapi?.auth2?.getAuthInstance()?.signIn();

      const credential = await user?.getAuthResponse();
      setSocialCredential(credential as any);
      setProvider(Social.GOOGLE);
    } catch (err: any) {
      console.log('err :>> ', err);
    }
  };

  const handleLoginAppleButton = async (
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    if (typeof (window as any)?.AppleID?.auth?.signIn !== 'function') {
      customToast.error('Apple signin is not ready. Please try again later');
      return;
    }

    try {
      const appleIdResponse: AppleIdResponse = await (
        window as any
      ).AppleID.auth.signIn();
      // console.log(`appleIdResponse`, appleIdResponse);

      setSocialCredential(appleIdResponse);
      setProvider(Social.APPLE);
    } catch (error: any) {
      console.log('error :>> ', error);
      let message = 'Apple Authorization cannot be completed';
      switch (error?.error) {
        case AppleAuthError.POPUP_BLOCKED:
          message =
            'Popup blocked by browser. Please allow popups for this site';
          break;
        default:
          break;
      }
      customToast.error(message);
    }
  };

  const handleLoginFacebookButton = async () => {
    try {
      const user = await loginFacebookSDKAsync();

      if (!user?.email) {
        return customToast.error(
          'Cannot read email from your Facebook account. Please try again.',
        );
      }

      setSocialCredential(user);
      setProvider(Social.FACEBOOK);
    } catch (error: any) {
      console.log('Facebook Exception :>> ', error);
    }
  };

  return (
    <div tw="(height[calc(100vh - 7rem)] sm-down:(height[calc(100vh - 6rem)]) overflow-auto)!">
      <div
        className="container-signup"
        tw="max-width[1100px] font-size[100%] min-height[calc(100vh - 13rem)] sm-down:(pt-0 min-height[calc(100vh - 12rem)])! flex mx-auto space-x-16 px-8 py-16 justify-center"
      >
        <div tw="flex-1 max-width[70rem] border[1px dotted #ccc] xs:(border-none px-0) rounded-xl p-6 px-20 shadow-sm sm-down:(shadow-none)">
          <div tw="text-center items-center flex justify-center mb-10 mt-8 font-size[3.3rem] font-medium text-sonnant-purple-3">
            Sign up
          </div>
          <div tw="text-center font-size[2.5rem] color[#965af7] mb-12 line-height[1.2] sm-down:(mb-6)">
            Start transforming your content today for FREE.
          </div>
          <div tw="text-center text-14 text-sonnant-grey-5 mb-6 opacity-90">
            Your free trial includes 60 minutes of free uploads to access the
            incredible features. <br />
            No Credit Card required.
          </div>

          {socialCredential && provider ? (
            <SignUpSocial provider={provider} credential={socialCredential} />
          ) : (
            <div tw="flex flex-col items-center">
              <AuthSocialButtons
                text="Sign up"
                handleLoginGoogleButton={handleLoginGoogleButton}
                handleLoginAppleButton={handleLoginAppleButton}
                handleLoginFacebookButton={handleLoginFacebookButton}
              />

              <SignUpEmailAndPassword />
            </div>
          )}
        </div>

        {/* <SignUpContent /> */}
      </div>
      <div tw="height[6rem] bg-sonnant-indigo py-4 flex justify-end items-center overflow-hidden"></div>
    </div>
  );
};
