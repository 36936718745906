/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import {
  ReactElement,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useToggle } from 'react-use';

import MegaphoneLogoPNG from 'assets/Images/integrate-megaphone.jpg';
import OmnyLogoPNG from 'assets/Images/integrate-omny.png';

import { Modal } from 'components/UiControls/modal/modal';
import { defaultTo } from 'lodash';
import { IntegrationEnum, ModalOptions } from 'utils/enum';
import { HintDisallowed } from '../HintDisallowed';

type IPromiseParams = {
  resolve: (payload: AddIntegrationModalResult) => void;
  reject: (err: any) => void;
};

type Props = {};

type AddIntegrationModalResult = {
  option: ModalOptions;
  selectedIntegration?: IntegrationEnum;
};

type AddIntegrationModalParams = {
  title?: string;
};

export type AddIntegrationModalRef = {
  show: (
    params: AddIntegrationModalParams,
  ) => Promise<AddIntegrationModalResult>;
};

export const AddIntegrationModal = forwardRef(
  (props: Props, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const promiseInfo = useRef<IPromiseParams>({
      resolve: (payload: AddIntegrationModalResult) => {},
      reject: () => {},
    });

    const [title, setTitle] = useState<string>('');
    const [isOpen, toggleIsOpen] = useToggle(false);

    const show = async (
      params: AddIntegrationModalParams,
    ): Promise<unknown> => {
      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setTitle(defaultTo(params.title, 'Add Integration'));

        toggleIsOpen(true);
      });
    };

    const hideModal = () => {
      toggleIsOpen(false);
    };

    const handleModalClose = () => {
      hideModal();

      promiseInfo.current?.resolve({ option: ModalOptions.CLOSE });
    };

    const handleSelectIntegration = (selectedIntegration: IntegrationEnum) => {
      hideModal();

      promiseInfo.current?.resolve({
        option: ModalOptions.YES,
        selectedIntegration,
      });
    };

    return (
      <Modal show={isOpen} modalClosed={handleModalClose}>
        <div>
          <h1 tw="font-semibold text-sonnant-dark text-[3.5rem]">{title}</h1>

          <div tw="flex justify-between items-end mt-6 mb-1">
            <HintDisallowed
              disabled={false}
              hintEnabled="To enable Omny Studio add the API Key and enable on the integration preferences page. Integration is only available for content items that were uploaded directly from Omny Studio."
              hintDisabled="This is a feature in development. It will be coming soon."
              notTransparent
              arrow
              align="bottom"
            >
              <div tw="inline-block">
                <button
                  tw="min-h-[4.8rem] min-w-[18rem]"
                  css={[omnyButtonCss]}
                  onClick={() => handleSelectIntegration(IntegrationEnum.OMNY)}
                >
                  <img src={OmnyLogoPNG} alt="Omny Integration" />
                </button>
              </div>
            </HintDisallowed>

            <HintDisallowed
              disabled={false}
              hintEnabled="To enable Megaphone add the API Key and enable on the integration preferences page. Integration is only available for content items that were uploaded directly from Megaphone."
              hintDisabled="This is a feature in development. It will be coming soon."
              notTransparent
              arrow
              align="bottom"
            >
              <div tw="inline-block">
                <button
                  tw="min-h-[4.8rem] min-w-[18rem]"
                  css={[megaphoneButtonCss]}
                  onClick={() =>
                    handleSelectIntegration(IntegrationEnum.MEGAPHONE)
                  }
                >
                  <img src={MegaphoneLogoPNG} alt="Megaphone Integration" />
                </button>
              </div>
            </HintDisallowed>

            <div tw="min-h-[4.8rem] min-w-[18rem]"></div>
          </div>
        </div>
      </Modal>
    );
  },
);

const integrationButtonCss = tw`max-w-[18rem] rounded-lg overflow-hidden py-3 px-5 shadow-lg hover:(shadow-xl cursor-pointer)`;

const omnyButtonCss = css`
  ${integrationButtonCss}

  &, :active, :disabled {
    ${tw`(bg-[#8242ca])!`}
  }
`;

const megaphoneButtonCss = css`
  ${integrationButtonCss}

  &, :active, :disabled {
    ${tw`(bg-[#5825fe])!`}
  }
`;
