/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import 'twin.macro';

import { secToTime } from 'components/VideoPlayer/Transcription/MediaUtilities';
import React from 'react';
import { MentionReport } from 'utils/models';
import { Hint } from '../Hint';
import { Switcher } from '../Switcher';
import { hoverDropShadow } from '../twin.styles';
import { ReactComponent as DelSvg } from 'assets/Icons/x-cancel-red_28dp.svg';
import { ReactComponent as EditSvg } from 'assets/Icons/edit_black_18dp.svg';
import { ReactComponent as PlaySvg } from 'assets/Icons/fPlay.svg';
import { ReactComponent as PlayPurpleSvg } from 'assets/Icons/fPlay-purple.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useAudio, useToggle } from 'react-use';

type Props = {
  mention: MentionReport;
  toggleOnOF: (mention: MentionReport) => void;
  handleTimecode: (mention: MentionReport) => void;
  removeMention: (mention: MentionReport) => void;
  handleShowPreview: (mention: MentionReport) => void;
};

export const MentionReportItem = (props: Props) => {
  const {
    mention,
    toggleOnOF,
    handleTimecode,
    removeMention,
    handleShowPreview,
  } = props;

  const [isHovering, toggleHover] = useToggle(false);

  const toggle = useSelector((state: RootState) => state.toggle);
  const media = useSelector((state: RootState) => state.media);

  const caption = mention.caption.data;
  const transcript = mention.caption.children
    .map((children) => children.text)
    .join('');

  const [audioThumb, , controls, audioThumbRef] = useAudio({
    src: media.url,
    autoPlay: false,
    preload: 'all',
  });

  const handleHoverPlayButton = (start: number, end: number) => {
    if (!audioThumbRef.current) return;
    toggleHover(true);
    audioThumbRef.current.src = media.url + `#t=${start + 0.01},${end}`;

    controls.play();
  };

  const handlePause = () => {
    if (!isHovering) return;
    toggleHover(false);
    if (!audioThumbRef.current) return;
    audioThumbRef.current.src = media.url;
    controls.pause();
  };
  return (
    <tr tw="color[#0a0a0a]" onMouseLeave={handlePause}>
      <td>{mention?.term}</td>
      <td tw="text-center">
        {mention?.occNo.join(', ')} of {mention?.totalOcc}
      </td>
      <td>{secToTime(caption?.start)}</td>
      <td>{secToTime(caption?.end)}</td>
      <td>{secToTime(caption?.end - caption?.start)}</td>
      <td>
        <div className="line-clamp-3" title={transcript}>
          {transcript}
        </div>
      </td>
      <td>
        <div tw="flex items-center cursor-pointer">
          <Hint text="Include mention" enterDelay={300} leaveDelay={0}>
            <div tw="mr-3 cursor-pointer">
              <Switcher
                hasLabel={true}
                color="#00AF7A"
                checked={mention?.enabled}
                onCheckedChange={() => toggleOnOF(mention)}
              />
            </div>
          </Hint>
          <Hint text="Edit mention timing" enterDelay={300} leaveDelay={0}>
            <div
              className={!toggle.isEditMode ? 'disabled' : ''}
              tw="border-radius[50%] bg-white hover:bg-sonnant-grey-2 flex items-center justify-center border[2px solid black] width[2.4rem] height[2.4rem] mr-2 cursor-pointer"
              css={hoverDropShadow(0.1)}
              onClick={() => {
                if (!toggle.isEditMode) return;
                handleTimecode(mention);
              }}
            >
              <EditSvg />
            </div>
          </Hint>
          <Hint text="Delete link to mention" enterDelay={300} leaveDelay={0}>
            <div
              className={!toggle.isEditMode ? 'disabled' : ''}
              tw="flex cursor-pointer"
              css={hoverDropShadow(0.2)}
              onClick={() => {
                if (!toggle.isEditMode) return;
                removeMention(mention);
              }}
            >
              <DelSvg />
            </div>
          </Hint>
          <Hint text="Preview mention">
            <div
              onClick={() => {
                handleShowPreview?.(mention);
              }}
              onMouseEnter={() =>
                handleHoverPlayButton(
                  mention?.caption?.data?.start,
                  mention?.caption?.data?.end,
                )
              }
              onMouseLeave={handlePause}
              
            >
              {!isHovering ? (
                <PlaySvg
                  tw="flex items-center max-height[2.4rem] w-auto ml-2"
                  className={`svg-shadow-hover`}
                />
              ) : (
                <div tw="relative">
                  <span css={pingAnimation} tw="animate-ping absolute z-0 inline-flex rounded-full bg-blue-400 opacity-75 height[2.4rem] width[2.4rem] margin-left[5px]"></span>
                  <PlayPurpleSvg
                    tw="border border-solid border-sonnant-purple-2 rounded-full flex items-center max-height[2.4rem] w-auto ml-2 relative z-index[1]"
                  />
                </div>
              )}
              {audioThumb}
            </div>
          </Hint>
        </div>
      </td>
    </tr>
  );
};

const pingAnimation = css`
animation: ping 2s cubic-bezier(0, 0, 0.2, 1) infinite;

@keyframes ping {
  75%, 100% {
    transform: scale(1.4);
    opacity: 0;
  }
}
`;
