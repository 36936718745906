/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { HTMLAttributes, ReactNode } from 'react';
import { TooltipProps } from '@rmwc/tooltip';

import { Hint } from './Hint';

type Props = {
  children: ReactNode;
  disabled: boolean;
  hintDisabled?: string;
  hintEnabled?: string;
  arrow?: boolean;
  notTransparent?: boolean;
  align?: TooltipProps['align'];
} & HTMLAttributes<HTMLDivElement>;

export const HintDisallowed = ({
  disabled,
  hintDisabled = '',
  hintEnabled = '',
  arrow = false,
  notTransparent = false,
  align = 'top',
  children,
  ...props
}: Props) => {
  return (
    <Hint
      text={disabled ? hintDisabled : hintEnabled}
      enterDelay={100}
      arrow={arrow}
      notTransparent={notTransparent}
      align={align}
    >
      <div css={[disabled && tw`cursor-not-allowed`]} tw="flex">
        <div
          css={[
            disabled &&
              tw`opacity[0.9] pointer-events-none filter[grayscale(1)]`,
          ]}
          {...props}
        >
          {children}
        </div>
      </div>
    </Hint>
  );
};
