/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { Switcher } from 'components/shared/Switcher';
import { ReactComponent as EditSvg } from 'assets/Icons/edit_black_18dp.svg';
import { ReactComponent as DelSvg } from 'assets/Icons/x-cancel-red_28dp.svg';

import React, { useRef } from 'react';
import { hoverDropShadow } from 'components/shared/twin.styles';
import { SpeakerDiarisation } from 'utils/models';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpeakerIdentificationItem } from 'slices/speaker-identification.slice';
import { TextInput } from 'components/shared/TextInput';
import { useClickAway, useToggle } from 'react-use';
import { Ripple } from '@rmwc/ripple';
import { isValidSpeaker } from 'utils/utils';
import { RootState } from 'reducers';
import { trimAllSpaces } from 'components/VideoPlayer/Transcription/MediaUtilities';

type Props = {
  index: number;
  speaker: SpeakerDiarisation;
  handleClickDelete: (id: string) => void;
};

export const SpeakerTabItem = ({
  speaker,
  handleClickDelete,
  index,
}: Props) => {
  const speakerIdentification = useSelector(
    (state: RootState) => state.speakerIdentification,
  );

  const [isEditing, toggleEditing] = useToggle(false);

  const dispatch = useDispatch();

  const inputRef = useRef<any>(null);

  const toggleSettings = (checked: boolean, name: 'prompt' | 'identify') => {
    let newItem = { ...speaker };

    if (name === 'prompt') {
      newItem = { ...speaker, hasPrompt: checked };
    } else {
      newItem = { ...speaker, hasIdentify: checked };
    }

    dispatch(updateSpeakerIdentificationItem(newItem));
  };

  const handleEnter = (value: string) => {
    const formattedName = trimAllSpaces(value);
    // is name before change
    if (formattedName === speaker.speakerName) {
      toggleEditing(false);
      return;
    }

    if (
      !isValidSpeaker({
        speakerName: formattedName,
        speakerList: speakerIdentification.speakers.map((s) => s.speakerName),
      })
    ) {
      return;
    }

    dispatch(
      updateSpeakerIdentificationItem({
        ...speaker,
        speakerName: formattedName,
      }),
    );

    toggleEditing(false);
  };

  const handleEsc = () => {
    toggleEditing(false);
  };

  const handleClickEdit = () => {
    toggleEditing(true);
  };

  useClickAway(inputRef, () => {
    toggleEditing(false);
  });

  return (
    <Ripple disabled={isEditing}>
      <div tw="flex w-full justify-between font-medium items-center py-1.5 text-17 px-2 opacity-90 rounded hover:(cursor-pointer)">
        <div tw="flex items-center">
          <div tw="mr-5">{index + 1}.</div>
          <div tw='word-break[break-all]'>
            {!isEditing ? (
              <div tw="pr-5">{speaker.speakerName}</div>
            ) : (
              <div ref={inputRef}>
                <TextInput
                  defaultText={speaker.speakerName}
                  placeholder="Give speaker a name..."
                  onPressEnter={handleEnter}
                  onPressEsc={handleEsc}
                  tw="(text-15 padding-left[6px] height[3.5rem] font-medium placeholder:(font-light text-14))!"
                />
              </div>
            )}
          </div>
        </div>
        <div tw="flex space-x-3.5 mr-6 text-sonnant-dark items-center">
          <div>
            <Switcher
              hasLabel={true}
              color="#00AF7A"
              checked={speaker.hasPrompt}
              onCheckedChange={(checked) => toggleSettings(checked, 'prompt')}
            />
          </div>
          <div tw="relative right[-4px]">
            <Switcher
              hasLabel={true}
              color="#00AF7A"
              checked={speaker.hasIdentify}
              disabled={!speakerIdentification.hasSpeakerIdentification}
              onCheckedChange={(checked) => toggleSettings(checked, 'identify')}
            />
          </div>
          <div
            tw="relative right[-6px] border-radius[50%] bg-white hover:bg-sonnant-grey-2 flex items-center justify-center border[2px solid black] width[2.4rem] height[2.4rem] mr-2 cursor-pointer"
            onClick={handleClickEdit}
          >
            <EditSvg css={[hoverDropShadow(0.2)]} />
          </div>
          <div
            onClick={() => handleClickDelete(speaker.id)}
            tw="relative right[-4px] flex cursor-pointer"
          >
            <DelSvg css={[hoverDropShadow(0.2)]} />
          </div>
        </div>
      </div>
    </Ripple>
  );
};
