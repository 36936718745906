/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Info } from 'components/shared/Info';
import { isEmpty } from 'lodash';
import { replaceEmbedLocalEnv } from 'utils/utils';

type Props = {
  embedCode: string;
};

export const EmbedPreview = ({ embedCode }: Props) => {
  const fallbackEmbedCode = replaceEmbedLocalEnv(embedCode);

  if (isEmpty(fallbackEmbedCode.trim())) {
    return (
      <div tw="flex justify-center items-center w-full h-[6rem] border[1px solid] border-sonnant-grey-light rounded">
        <Info hideSpacingBottom text="Embed code is empty. Nothing to show." />
      </div>
    );
  }

  return (
    <div
      tw="flex shadow"
      dangerouslySetInnerHTML={{
        __html: fallbackEmbedCode,
      }}
    ></div>
  );
};
