/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as AddCollectionSvg } from 'assets/Icons/add-collection.svg';
import { ReactComponent as NoneCollectionSvg } from 'assets/Icons/cross.svg';
import { ReactComponent as SmartCollectionSvg } from 'assets/Icons/smart-collection.svg';
import { ReactComponent as StandardCollectionSvg } from 'assets/Icons/standard-collection.svg';
import { CollectionType } from 'utils/enum';
import { CollectionDropdownOption } from 'utils/models';

type Props = {
  option: CollectionDropdownOption;
};

export const CollectionSingleItem = ({ option }: Props) => {
  return (
    <div tw="flex flex-row items-center h-[34px] p-[2rem 1rem]">
      <div tw="max-width[3.5rem] mr-4 flex">
        {option.type === CollectionType.NONE ? (
          <NoneCollectionSvg />
        ) : option.type === CollectionType.SMART ? (
          <SmartCollectionSvg />
        ) : option?.__isNew__ ? (
          <AddCollectionSvg fill="#7f8090" />
        ) : (
          <StandardCollectionSvg />
        )}
      </div>

      <div tw="flex flex-col w-full">
        <div
          tw="text-15 text-left text-sonnant-dark break-words line-height[1.4]"
          className="line-clamp-2"
        >
          {option.label}
        </div>
      </div>
    </div>
  );
};
