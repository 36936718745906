import {
  cloneDeep,
  differenceWith,
  first,
  get,
  isArray,
  isEmpty,
  isEqual,
  isNil,
  keys,
  omit,
  uniq,
} from 'lodash';
import { NullableStringKeyOf } from 'types/utils';
import { v4 } from 'uuid';
import { StringEnum } from './enum';

export const ensureArray = <T>(input: T | T[] | null | undefined) => {
  if (isNil(input)) return [];
  if (isEmpty(input)) return [];

  const output = isArray(input) ? input : [input];

  return output;
};

export const appendOrDelete = <T extends number | string>(
  originalArray: T[],
  item: T,
): T[] => {
  if (originalArray.includes(item)) {
    return originalArray.filter((i) => i !== item);
  } else {
    return uniq([...originalArray, item]);
  }
};

export const singleOrNull = <T>(rawArray: T[]): T | null => {
  if (!isArray(rawArray)) return null;

  if (rawArray.length === 1) {
    return first(rawArray) as T;
  }

  return null;
};

export const buildUrl = <T>(
  hostUrl: string,
  options?: { params: Record<keyof T, boolean | string> },
): string => {
  const url = new URL(hostUrl);

  if (isEmpty(options?.params) || !options?.params) return url.toString();

  keys(options?.params).forEach((param) => {
    url.searchParams.set(param, get(options.params, param, StringEnum.EMPTY));
  });

  return url.toString();
};

export const searchBy =
  <T>(searchText: string, searchFields: NullableStringKeyOf<T>[]) =>
  (item: T): boolean => {
    if (isEmpty(searchFields) || isEmpty(searchText?.trim())) {
      return true;
    }

    return searchFields.some((field) => {
      if (!item?.[field]) return false;

      const value = String(item[field]);

      return value.toLowerCase().includes(searchText.trim().toLowerCase());
    });
  };

export const extract = <T, K extends keyof T>(items: T[], key: K): T[K][] => {
  return items.map((item) => item[key]);
};

export const remove = <T, K extends keyof T>(
  items: T[],
  key: K,
  comparedValue: T[K],
): T[] => {
  return items.filter((item) => item[key] !== comparedValue);
};

export const len = (items: any): number => {
  if (!isArray(items)) return 0;

  return items.length;
};

export const pushOrUpdate = <T>(array: T[], item: T, field: keyof T): T[] => {
  const results = cloneDeep(array);

  const foundIndex = results.findIndex(
    (arrayItem) => arrayItem[field] === item[field],
  );

  if (foundIndex >= 0) {
    results[foundIndex] = item;
  } else {
    results.push(item);
  }

  return results;
};

export const withId = <T>(object: T): T & { id: string } => {
  return {
    id: v4(),
    ...object,
  };
};

export const repeatArray = <T>(array: T[], times: number): T[] => {
  return Array.from({ length: times }, () => array).flat();
};

export const hasOneOf = <T>(...args: T[]): boolean => {
  return args.filter(Boolean).length === 1;
};

export const hasLeastOneOf = <T>(...args: T[]): boolean => {
  return args.filter(Boolean).length >= 1;
};

export const isEveryFalsy = <T>(...args: T[]): boolean => {
  return args.filter(Boolean).length === 0;
};

export const isEveryTruly = <T>(...args: T[]): boolean => {
  return args.filter(Boolean).length === args.length;
};

export const excludeId = <T extends object>(object: T): Omit<T, 'id'> => {
  return omit(object, 'id');
};

export const differenceSettingsWithoutId = <T extends object>(
  currentSettings: T[],
  initialSettings: T[],
): Omit<T, 'id'>[] => {
  const currentSettingsWithoutId = currentSettings.map(excludeId);
  const initialSettingsWithoutId = initialSettings.map(excludeId);

  return differenceWith(
    currentSettingsWithoutId,
    initialSettingsWithoutId,
    isEqual,
  );
};
