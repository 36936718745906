/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { ReactComponent as DirectionSvg } from 'assets/Icons/direction.svg';
import { Modal } from 'components/UiControls/modal/modal';
import { isEqual, min, toNumber } from 'lodash';
import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useSlider } from 'react-use';
import { RootState } from 'reducers';
import { tierSelector } from 'slices/payment.slice';
import { ModalOptions, StandardTypeEnum } from 'utils/enum';
import { IBuyUserResult } from 'utils/models/payment.model';
import { toMoneyFormat } from 'utils/utils';
import { hoverDropShadow, svgShadow } from '../twin.styles';

interface IPromiseParams {
  resolve: (result: IBuyUserResult | ModalOptions) => void;
  reject: (err: any) => void;
}

interface IProps {
  title?: string;
  cancelText?: string;
  confirmText?: string;
}

const AUD_PER_USER = 20;
const MAX_LIMIT_USER = 10;

export const BuyUserModal = forwardRef(
  (props: IProps, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const { subscription } = useSelector((state: RootState) => state.payment);
    const tier = useSelector(tierSelector);
    const [isOpen, setIsOpen] = useState(false);
    const [userQuantity, setUserQuantity] = useState(1);
    const [mode, setMode] = useState<StandardTypeEnum>(
      StandardTypeEnum.MONTHLY,
    );
    const [isAnnual, setIsAnnual] = useState(false);
    const [isMonthly, setIsMonthly] = useState(false);

    const isCurrentStandardUsers =
      tier.isStandard && toNumber(subscription?.licensedusers) === userQuantity;

    useEffect(() => {
      const allowUserCount = toNumber(subscription?.licensedusers);
      if (!allowUserCount) return;
      setUserQuantity(allowUserCount);
    }, [subscription?.licensedusers, isOpen]);

    useEffect(() => {
      if (!subscription?.interval_bill) return;

      const isAnnual = isEqual(
        subscription?.interval_bill.toUpperCase(),
        StandardTypeEnum.ANNUAL,
      );
      const isMonthly = isEqual(
        subscription?.interval_bill.toUpperCase(),
        StandardTypeEnum.MONTHLY,
      );

      if (isAnnual) {
        setMode(StandardTypeEnum.ANNUAL);
        setIsAnnual(true);
      }
      if (isMonthly) {
        setMode(StandardTypeEnum.MONTHLY);
        setIsMonthly(true);
      }
    }, [subscription?.interval_bill]);

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (): Promise<unknown> => {
      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const hideModal = () => setIsOpen(false);

    const handleYes = () => {
      hideModal();

      if (exceedLimit()) {
        window.open('https://sonnant.com/contact');
        return;
      }

      promiseInfo.current?.resolve({
        userQuantity: userQuantity,
        option: ModalOptions.YES,
        isAnnual: mode === StandardTypeEnum.ANNUAL,
      } as IBuyUserResult);
    };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current?.resolve(ModalOptions.CANCEL);
    };

    const exceedLimit = () => userQuantity > MAX_LIMIT_USER;

    const getAnnualPrice = () => {
      const DISCOUNT = 1 - 0.25; // 25% discount
      return userQuantity * AUD_PER_USER * DISCOUNT;
    };

    return (
      <Modal show={isOpen} modalClosed={hideModal}>
        <div className="userModal_Popus">
          <h1>{props.title || 'Standard Plan Subscription'}</h1>
          <div tw="mr-3">
            <div tw="flex justify-start w-full mt-6">
              {!isAnnual && (
                <div
                  className="button"
                  css={[
                    modeButton,
                    mode === StandardTypeEnum.MONTHLY
                      ? activeMode
                      : tw`hover:(bg-disabled opacity-90)`,
                  ]}
                  onClick={() => setMode(StandardTypeEnum.MONTHLY)}
                >
                  <div tw="flex leading-5">Monthly</div>
                </div>
              )}
              {!isMonthly && (
                <div
                  className="button"
                  css={[
                    modeButton,
                    mode === StandardTypeEnum.ANNUAL
                      ? activeMode
                      : tw`hover:(bg-disabled opacity-90)`,
                  ]}
                  onClick={() => setMode(StandardTypeEnum.ANNUAL)}
                >
                  <div tw="flex leading-5">Annual</div>
                </div>
              )}
            </div>
          </div>

          <Slider
            userQuantity={userQuantity}
            setUserQuantity={setUserQuantity}
          />

          {exceedLimit() ? (
            <div tw="font-bold text-5xl text-sonnant-dark mt-8">
              Based on requirements
            </div>
          ) : (
            <div tw="mt-3 text-sonnant-dark">
              <div tw="py-6">
                {mode === StandardTypeEnum.ANNUAL ? (
                  <span>
                    <span tw="font-bold text-5xl mr-4">{`${toMoneyFormat(
                      getAnnualPrice() * 12,
                    )} / year`}</span>
                    <span tw="font-bold text-5xl text-gray-600">(</span>
                    <span
                      tw="font-bold text-5xl text-gray-600 relative"
                      css={lineThough}
                    >
                      ${userQuantity * AUD_PER_USER}
                    </span>
                    <span tw="font-bold text-5xl ml-4">
                      {toMoneyFormat(getAnnualPrice())}
                    </span>{' '}
                    / month
                    <span tw="font-bold text-5xl text-gray-600">)</span>
                  </span>
                ) : (
                  <>
                    <span tw="font-bold text-5xl">
                      ${userQuantity * AUD_PER_USER}
                    </span>{' '}
                    / month
                  </>
                )}{' '}
              </div>
              <div tw="py-3">
                <span tw="font-medium">
                  {userQuantity * 60} minutes included per month, then
                  $0.12/minute
                </span>{' '}
              </div>
            </div>
          )}

          <div className="grid-x">
            <div className="cell small-12">
              <div className="btn_section">
                <button
                  type="button"
                  className="button cancel"
                  onClick={handleCancel}
                >
                  {props?.cancelText || 'Cancel'}
                </button>
                <button
                  type="button"
                  className="button delete"
                  // disabled={allowUpgrade()}
                  onClick={handleYes}
                  disabled={isCurrentStandardUsers}
                >
                  {exceedLimit()
                    ? 'Contact us'
                    : isCurrentStandardUsers
                    ? 'Current Plan'
                    : 'Confirm'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);

const Slider = (props: any) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const userSlider = useSlider(sliderRef);
  const tier = useSelector(tierSelector);
  const { subscription } = useSelector((state: RootState) => state.payment);

  useEffect(() => {
    if (userSlider.value === 0) return;
    const currentAmount = toNumber(subscription?.licensedusers);
    const newAmount = Math.ceil(userSlider.value * 11);

    if ((tier.isStandard || tier.isEnterprise) && newAmount < currentAmount)
      return;

    props.setUserQuantity(newAmount);
  }, [userSlider.value]);

  return (
    <div tw="background[rgb(224, 236, 255, .8)] w-full h-40 py-10 px-14 flex justify-center items-center rounded-lg">
      <div
        ref={sliderRef}
        tw="w-full h-3 bg-sonnant-purple-2 rounded-lg relative cursor-pointer"
      >
        <div
          tw="absolute -top-3 flex justify-center"
          style={{
            left: `calc(${min([
              ((props.userQuantity - 1) / 10) * 100,
              100,
            ])}% - 1rem)`,
          }}
        >
          <DirectionSvg
            tw="transform[rotate(90deg)]!"
            css={[svgShadow(0.2), hoverDropShadow(0.3)]}
          />
          <div
            tw="absolute top-full whitespace-nowrap"
            css={props.userQuantity > 10 && tw`pr-8`}
          >
            {props.userQuantity <= 10 ? props.userQuantity : '10+'}{' '}
            {props.userQuantity > 1 ? 'users' : 'user'}
          </div>
        </div>
      </div>
    </div>
  );
};

const modeButton = css`
  ${tw`bg-disabled text-xl`}
  :first-of-type {
    ${tw`rounded-l-lg`}
  }
  :last-child {
    ${tw`rounded-r-lg`}
  }
`;

const activeMode = css`
  ${tw`bg-sonnant-purple-2 hover:(bg-sonnant-purple-2)`}
`;

const lineThough = css`
  ::before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: 0;
    border-top: 3px solid;
    border-color: #050a38;

    -webkit-transform: rotate(-12deg);
    -moz-transform: rotate(-12deg);
    -ms-transform: rotate(-12deg);
    -o-transform: rotate(-12deg);
    transform: rotate(12deg);
  }
`;
