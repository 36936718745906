/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';
import { forwardRef, useCallback, useEffect } from 'react';

import { ReactComponent as PauseSvg } from 'assets/Icons/fPause.svg';
import { ReactComponent as PlaySvg } from 'assets/Icons/fPlay.svg';
import { ReactComponent as FullScreenSvg } from 'assets/Icons/fullScreen.svg';
import { ReactComponent as MuteSvg } from 'assets/Icons/player_mute.svg';
import { ReactComponent as VolumeSvg } from 'assets/Icons/player_volume.svg';
import { useFullscreen, useSlider, useToggle } from 'react-use';
import { playPauseStyle } from './twin.styles';
import { HTMLMediaState } from 'react-use/lib/factory/createHTMLMediaHook';
import { secToMin } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { useRef } from 'react';
import useEventListener from 'components/CustomHooks/UseEventListener';
import { isNil } from 'lodash';
import { MediaReadyState } from 'utils/enum';
import { Hint } from 'components/shared/Hint';
import { ReactComponent as Caption } from 'assets/Icons/caption.svg';
import { ReactComponent as CaptionOff } from 'assets/Icons/captions-off.svg';

interface HTMLMediaControl {
  play: () => Promise<void> | undefined;
  pause: () => void;
  seek: (time: number) => void;
  volume: (volume: number) => void;
  mute: () => void;
  unmute: () => void;
}

interface IProps {
  state: HTMLMediaState;
  controls: HTMLMediaControl;
}

export const VideoControlBar = forwardRef(
  ({ state, controls }: IProps, ref: any) => {
    const timeTrackRef = useRef(null);
    const timeSlider = useSlider(timeTrackRef);
    const [isReady, toggleReady] = useToggle(false);
    const [showCaption, toggleShowCaption] = useToggle(true);

    const [isFullScreen, toggleFullScreen] = useToggle(false);
    useFullscreen(ref, isFullScreen, {
      onClose: () => toggleFullScreen(false),
    });

    const handleVideoTimeUpdate = useCallback(() => {
      if (!ref?.current) return;

      const playPercent = ref.current.currentTime / state.duration || 0;
      timeSlider.value = playPercent;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current?.currentTime]);

    const mediaCtrl = ref.current;

    const checkShowHideCaption = () => {
      if (mediaCtrl?.textTracks[0].mode === 'showing') {
        toggleShowCaption(true);
      } else {
        toggleShowCaption(false);
      }
    };

    const handleCaption = () => {
      for (let i = 0; i < mediaCtrl?.textTracks.length; i++) {
        if (mediaCtrl?.textTracks[i].mode === 'showing') {
          mediaCtrl.textTracks[i].mode = 'hidden';
        } else {
          mediaCtrl.textTracks[i].mode = 'showing';
        }
      }

      checkShowHideCaption();
    };

    useEventListener('timeupdate', handleVideoTimeUpdate, ref.current);

    useEffect(() => {
      const readyState = ref?.current?.readyState;
      if (isNil(readyState)) return;

      if (
        [
          MediaReadyState.HAVE_ENOUGH_DATA,
          MediaReadyState.HAVE_METADATA,
        ].includes(readyState)
      ) {
        toggleReady(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref?.current?.readyState]);

    useEffect(() => {
      if (!ref.current || !timeSlider.isSliding) return;

      ref.current.currentTime = (timeSlider.value || 0) * state.duration;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeSlider.value]);

    return (
      <div tw="background[#F0F0F4] rounded-b-md">
        <div tw="flex justify-between items-center px-3 mb-1.5 pt-1">
          <div tw="flex items-center">
            <div tw="flex mr-2" className={!isReady ? 'disabled' : ''}>
              {state.paused ? (
                <PlaySvg
                  css={[playPauseStyle, tw`height[2rem] width[2rem]`]}
                  onClick={controls.play}
                />
              ) : (
                <PauseSvg
                  css={[playPauseStyle, tw`height[2rem] width[2rem]`]}
                  onClick={controls.pause}
                />
              )}
            </div>
            <div tw="font-size[1.2rem] font-medium select-none padding-top[2px]">
              {secToMin(isReady ? state.time : 0)} /{' '}
              {secToMin(isReady ? state.duration : 0)}
            </div>
          </div>
          <div tw="flex items-center">
            <Hint text="Mute / Unmute">
              {state.muted ? (
                <MuteSvg css={volStyle} onClick={controls.unmute} />
              ) : (
                <VolumeSvg css={volStyle} onClick={controls.mute} />
              )}
            </Hint>
            <Hint text="Closed captions - turn on / off">
              {showCaption ? <Caption css={volStyle} onClick={handleCaption}/> : <CaptionOff css={volStyle} onClick={handleCaption}/>}
            </Hint>
            <Hint text="Full screen mode">
              <FullScreenSvg
                tw="cursor-pointer"
                onClick={() => toggleFullScreen(true)}
              />
            </Hint>
          </div>
        </div>

        <div tw="px-3 pb-3">
          <div
            className="progress"
            tw="mb-0 rounded height[unset]!"
            ref={timeTrackRef}
          >
            <div
              className={`progress-meter ${!isReady && 'disabled'}`}
              style={{ width: `${(timeSlider.value || 0) * 100}%` }}
              tw="position[unset] rounded height[4px] after:content[none]"
            ></div>
          </div>
        </div>
      </div>
    );
  },
);

const volStyle = css`
  width: 1.6rem;
  height: 1.6rem;
  cursor: pointer;
  margin-right: 1.5rem;
  path {
    fill: #7f8090;
  }
`;
