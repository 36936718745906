/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { isEmpty, round } from 'lodash';
import React from 'react';
import ReactWordcloud, { Word } from 'react-wordcloud';
import { Info } from '../Info';

type Props = {
  words: Word[];
  onWordClick: (original: string) => void;
};

export const WordCloudChart = React.memo(({ words, onWordClick }: Props) => {
  if (isEmpty(words)) {
    return (
      <div tw="w-full h-full min-h-[16rem] flex justify-center items-center">
        <Info text="Nothing to show" />
      </div>
    );
  }

  return (
    <div tw="w-full h-full">
      <ReactWordcloud
        words={words}
        callbacks={{
          getWordTooltip: (word) => {
            const total = words.reduce((total, word) => total + word.value, 0);
            const percent = (word.value / total) * 100;

            return `<span style="background-color: #7f8090;color: white; padding: 4px 8px;font-size: 15px;border-radius: 3px;">${
              word.text
            }: <b>${word.value} (${round(percent, 2)}%)</b></span>`;
          },
          onWordClick: (event) => {
            onWordClick(event.original);
          },
        }}
        options={{
          deterministic: true,
          enableTooltip: true,
          rotationAngles: [0, 90],
          fontStyle: 'normal',
          fontWeight: '500',
          scale: 'sqrt',
          spiral: 'archimedean',
          transitionDuration: 500,
          rotations: 0,
          padding: 6,
          fontSizes: [20, 65],
          tooltipOptions: {
            allowHTML: true,
          },
          fontFamily:
            "'Lexend', 'Roboto', 'Arial', 'Helvetica', 'Verdana', sans-serif",
        }}
      />
    </div>
  );
});
