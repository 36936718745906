/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { ReactComponent as Pause } from 'assets/Icons/fPause.svg';
import { ReactComponent as Play } from 'assets/Icons/fPlay.svg';
import { ReactComponent as ForwardSvg } from 'assets/Icons/fast_forward_24dp.svg';
import { ReactComponent as RewindSvg } from 'assets/Icons/fast_rewind_24dp.svg';
import axios from 'axios';
import { Modal } from 'components/UiControls/modal/modal';
import {
  msToSec,
  secToTime,
  shouldUsePlyr,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import Loader from 'components/loader/loader';
import { debounce, isEmpty, isNil } from 'lodash';
import Plyr from 'plyr';
import React, {
  ReactElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle, useVideo } from 'react-use';
import { RootState } from 'reducers';
import { MediaService } from 'services';
import { setWaveform } from 'slices/media.slice';
import { MediaReadyState, ModalOptions } from 'utils/enum';
import { customToast } from 'utils/toast.util';
import { WaveSurferVideo } from '../Wavesurfer/WaveSurferVideo';
import { playPauseStyle, plyrResetStyle } from '../twin.styles';
import { InputTimeCode } from './InputTimeCode';

interface IPromiseParams {
  resolve: (option?: any) => void;
  reject: (err: any) => void;
}

interface IProps {
  title?: string;
  message?: string | ReactElement;
  cancelText?: string;
  confirmText?: string;
}

const uniqueTimestamp = new Date().getTime();

export const AdMarkerModal = forwardRef(
  (props: IProps, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [currentAdMarker, setCurrentAdMarker] = useState<any>(null);
    const [currentTimecode, setCurrentTimecode] = useState<number>(0);

    const media = useSelector((state: RootState) => state.media);

    const [rawMediaSrc, setRawMediaSrc] = useState<string | undefined>();
    const [rawVttPath, setRawVttPath] = useState<string>();
    const [, toggleMediaLoading] = useToggle(true);

    const [plyrInstance, setPlyrInstance] = useState<Plyr | null>(null);

    const durationSec = msToSec(media?.metadata?.length);
    const isGreaterDuration = currentTimecode > durationSec;

    const thumbUrl = media?.metadata?.thumbnail;

    const [video, videoState, controls, videoRef] = useVideo(
      <video
        id="media_player"
        tw="w-full! rounded-md shadow!"
        src={media?.url + `#t=${currentAdMarker?.startTime || '0.01'}`}
        poster={thumbUrl ? `${thumbUrl}?q=${uniqueTimestamp}` : undefined}
        controls={false}
        autoPlay={false}
        controlsList="nodownload"
        crossOrigin="anonymous"
        preload="auto"
        playsInline
        onClick={() => (videoState.paused ? controls.play() : controls.pause())}
        onContextMenu={(e) => e.preventDefault()}
      >
        <track
          label="English"
          kind="subtitles"
          srcLang="en"
          src={media.subtitleurl || rawVttPath}
          default
        ></track>
      </video>,
    );

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (params: any): Promise<unknown> => {
      if (!isEmpty(params)) {
        const markerItem = params?.adMarkerItem;

        setCurrentAdMarker(markerItem);

        fetchMediaAsync(params?.mediaid, params?.versioncount);
      }

      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const hideModal = () => {
      setRawMediaSrc(undefined);
      setIsOpen(false);
      setIsVideoLoaded(false);
      setPlyrInstance(null);

      if (!videoState.paused) {
        videoState.paused = true;
      }
    };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current?.resolve(ModalOptions.CANCEL);
    };

    const fetchMediaAsync = async (mediaid: string, versioncount?: string) => {
      try {
        toggleMediaLoading(true);
        const mediaResponse = await MediaService.getPreview({
          mediaid,
          versioncount,
        });

        setRawMediaSrc(mediaResponse.data?.url);
        setRawVttPath(mediaResponse.data?.subtitleurl);
        setIsVideoLoaded(true);

        if (mediaResponse.data?.jsonfileurl) {
          axios
            .get(mediaResponse.data?.jsonfileurl)
            .then(({ data: transcriptData }) => {
              if (transcriptData?.waveform) {
                dispatch(setWaveform(transcriptData.waveform));
              }
            });
        }
      } catch (err: any) {
        console.log('HTTP ERROR', err);
        customToast.error('Something went wrong');
      } finally {
        toggleMediaLoading(false);
      }
    };

    useEffect(() => {
      if (!videoRef?.current || !shouldUsePlyr()) return;

      const videoPlyr = new Plyr(videoRef.current, {
        captions: { active: false, update: true },
        ratio: '16:9',
        controls: ['mute', 'volume', 'captions', 'settings', 'fullscreen'],
        storage: { enabled: false },
      });

      if (videoPlyr) {
        videoPlyr.on('ready', () => {
          setPlyrInstance(videoPlyr);
        });
      }
    }, [videoRef?.current]);

    useEffect(() => {
      const state = videoRef.current?.readyState;
      if (isNil(state)) return;

      if (
        [
          MediaReadyState.HAVE_ENOUGH_DATA,
          MediaReadyState.HAVE_METADATA,
        ].includes(state as MediaReadyState)
      ) {
        setIsVideoLoaded(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoRef.current?.readyState]);

    const handleUpdate = () => {
      hideModal();
      promiseInfo.current?.resolve({
        option: ModalOptions.YES,
        newTime: currentTimecode,
      });
    };

    const handleTimecodeChange = (timeSec: number) => {
      setCurrentTimecode(timeSec);
    };

    return (
      <Modal show={isOpen} modalClosed={hideModal}>
        <div className="userModal_Popus">
          <div tw="flex items-center">
            <h1 tw="mr-4! mb-4!">Edit time marker</h1>
          </div>

          <div className="publishmodal">
            <div>
              <>
                <div css={[plyrResetStyle]}>
                  {!plyrInstance && (
                    <div tw="flex justify-center items-center height[40rem] shadow rounded">
                      <Loader />
                    </div>
                  )}
                  <div css={[!plyrInstance && tw`hidden!`]}>{video}</div>
                </div>
                <div tw="bg-sonnant-purple-4 text-white p-5 rounded-xl my-3">
                  <div tw="font-size[1.4rem] flex justify-between items-center user-select[none]">
                    <div tw="flex items-center flex-1">
                      <div tw="flex-1 text-left">
                        Current frame: {secToTime(videoState.time)}
                      </div>
                    </div>
                    <div tw="flex items-center mb-2">
                      <RewindSvg
                        tw="height[2.4rem] cursor-pointer"
                        onClick={() => controls.seek(videoState.time - 5)}
                      />
                      <div
                        tw="mx-3 flex"
                        className={!isVideoLoaded ? 'disabled' : ''}
                      >
                        {videoState.paused ? (
                          <Play
                            css={[playPauseStyle]}
                            onClick={controls.play}
                          />
                        ) : (
                          <Pause
                            css={[playPauseStyle]}
                            onClick={controls.pause}
                          />
                        )}
                      </div>
                      <ForwardSvg
                        tw="height[2.4rem] cursor-pointer"
                        onClick={() => controls.seek(videoState.time + 5)}
                      />
                    </div>
                    <div tw="flex-1 text-right">
                      Clip duration: {secToTime(videoState.duration)}
                    </div>
                  </div>
                  <div
                    className="waveform-bar"
                    tw="w-full text-center bg-sonnant-grey-5 rounded-md mt-2"
                  >
                    {isVideoLoaded && (
                      <WaveSurferVideo
                        src={media?.url || rawMediaSrc!}
                        ref={videoRef}
                        currentTime={null}
                        defaultTime={null}
                        shouldHideRegion={true}
                        // onRegionUpdate={debounce(handleRegionUpdate, 20)}
                      />
                    )}
                  </div>
                </div>
                <div tw="text-3xl font-medium opacity-90 mt-5">Timecode</div>
                <InputTimeCode
                  time={videoState.time}
                  handleTime={debounce(handleTimecodeChange, 100)}
                  hasError={false}
                />
                {isGreaterDuration && (
                  <div tw="text-red-500 text-12 ml-2.5">
                    Timecode cannot be greater than video duration.
                  </div>
                )}
              </>
            </div>

            <div className="grid-x grid-margin-x rightAlign" tw="mt-6">
              <button
                type="button"
                className="button btn-secondary large cancel small-3 cell"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`button btn-primary  large apply small-3 cell ${
                  !isVideoLoaded ? 'disabled' : ''
                }`}
                onClick={handleUpdate}
                disabled={isGreaterDuration}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);
