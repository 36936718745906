/** @jsxImportSource @emotion/react */
import 'twin.macro';

import {
  formatBytes,
  secToTime,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { toDateWithHours } from 'utils/date.util';
import { RssFeed } from 'utils/models';
import { AUDIO_THUMBNAIL } from '../twin.styles';
import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSelectedRssId } from 'slices/rss.slice';
import { RootState } from 'reducers';

interface Props {
  feed: RssFeed;
  fromYoutube: boolean;
}

export const RssPreviewItem = ({ feed, fromYoutube, ...props }: Props) => {
  const dispatch = useDispatch();

  const rss = useSelector((state: RootState) => state.rss);
  const location = useSelector(
    (state: RootState) => state.userProfile.location,
  );

  const selected = !rss.notSelectedIds.includes(feed?.id!);

  const handleToggleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(toggleSelectedRssId(feed?.id));
  };

  return (
    <div tw="flex items-center text-sonnant-dark hover:(bg-sonnant-grey-light)! cursor-pointer p-3">
      <label tw="flex[unset]! cursor-pointer">
        <div>
          <input
            name="caption"
            checked={selected}
            onChange={handleToggleCheckbox}
            type="checkbox"
          />
          <span className="checkmark" style={{ top: '5px' }}></span>
        </div>
      </label>

      <div tw="mr-6 max-width[15rem]!">
        <img
          tw="max-width[15rem]! shadow-sm"
          src={feed?.imageURL || AUDIO_THUMBNAIL}
          alt={feed?.title}
        />
      </div>

      <div>
        <div tw="font-semibold text-sonnant-dark">{feed?.title}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: feed?.summary ?? feed?.description,
          }}
          tw="text-14 text-sonnant-grey-4"
          className="line-clamp-3"
        >
          {/* {feed?.summary ?? feed?.description} */}
        </div>

        {fromYoutube && feed?.enclosure?.url && (
          <div>
            <a
              tw="text-14 hover:(underline)"
              href={feed?.enclosure.url ?? ''}
              target="_blank"
              rel="noreferrer"
              title={feed?.title}
            >
              {feed?.enclosure?.url}
            </a>
          </div>
        )}

        {!fromYoutube && (
          <div tw="text-18 mt-2 font-medium opacity-95">
            {feed?.duration && secToTime(feed?.duration)}
            {feed?.enclosure?.length && <>- {formatBytes(+feed.enclosure.length)}</>}
          </div>
        )}

        <div tw="text-15 opacity-75">
          {fromYoutube
            ? `${feed.duration} - ${feed?.pubDate}`
            : toDateWithHours(feed?.pubDate, location)}
        </div>
      </div>
    </div>
  );
};
