/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as SpinningAnimatedSvg } from 'assets/Icons/cog-spinning-animated.svg';

type Props = {};

export const ChartGeneratingResult = (props: Props) => {
  return (
    <div tw="flex flex-col w-full h-full gap-y-6 items-center justify-center text-sonnant-dark py-12">
      <div tw="text-center">
        <div tw="font-medium font-size[2.4rem] mt-3">Result loading...</div>
        <div tw="flex flex-col gap-y-3 mt-8 mb-5 text-16">
          <div>Your chart is being generated.</div>
          <div>We'll notify you once it is completed.</div>
        </div>
      </div>

      <SpinningAnimatedSvg tw="w-[18rem] h-[18rem] m-[unset]!" />
    </div>
  );
};
