/** @jsxImportSource @emotion/react */
import { HTMLProps, ReactElement } from 'react';
import 'twin.macro';

type Props = {
  iconComponent: ReactElement;
  name: string;
} & HTMLProps<HTMLDivElement>;

export const EmbedSharingItem = ({ iconComponent, name, ...props }: Props) => {
  return (
    <div
      tw="flex flex-row gap-x-3 justify-center items-center font-medium text-15 min-w-[15rem] mb-2 cursor-pointer"
      {...props}
    >
      <div tw="flex items-center">{iconComponent}</div>
      <div>{name}</div>
    </div>
  );
};
