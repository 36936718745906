import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false as boolean,
    firstname: '' as string,
    lastname: '' as string,
    email: '' as string,
    location: '' as string,
    image: '',
  },
  reducers: {
    setUserProfile: (state, { payload }: PayloadAction<any>) => {
      return {
        ...state,
        firstname: payload.FirstName,
        lastname: payload.LastName,
        email: payload.Email,
        location: payload.Location,
        image: payload.Image,
        loading: false,
      };
    },
  },
});

export const { setUserProfile } = userSlice.actions;

export const userReducer = userSlice.reducer;
