/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { Modal } from 'components/UiControls/modal/modal';
import { generateIframe } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { isEmpty } from 'lodash';
import React, {
  ReactElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useCopyToClipboard } from 'react-use';
import { ModalOptions } from 'utils/enum';
import { buildUrl } from 'utils/generic.util';
import { EmbedOption, EmbedStyle } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { EmbedPreview } from './EmbedPreview';
import { EmbedStyleSelect } from './EmbedStyleSelect';
import { replaceEmbedLocalEnv } from 'utils/utils';

interface Props {
  shareUrl: string;
  embedTitle: string;
}

interface IPromiseParams {
  resolve: (option: ModalOptions) => void;
  reject: (err: any) => void;
}

export const EmbedModal = React.memo(
  forwardRef((props: Props, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const publishedUrl = props.shareUrl;
    const embedTitle = props.embedTitle;

    const [isOpen, setIsOpen] = useState(false);
    const [, copyToClipboard] = useCopyToClipboard();

    const [embedOptions, setEmbedOptions] = useState<EmbedOption>({
      hasTranscript: false,
      hasSummary: true,
      hasVideo: true,
      hasEntities: true,
      allowedExport: true,
      allowedShare: true,
      logoRemove: false,
    });

    const [embeddedCode, setEmbeddedCode] = useState('');
    const [embedHeight, setEmbedHeight] = useState<number>();

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    useEffect(() => {
      if (isEmpty(publishedUrl) || !isOpen) return;

      const hostUrl = publishedUrl.split('/shared/').slice(0)[0];
      const sharedUrl = publishedUrl.split('/shared/').slice(-1)[0];

      const embedUrl = buildUrl(`${hostUrl}/embed/${sharedUrl}`, {
        params: {
          transcript: embedOptions.hasTranscript,
        },
      });

      const iframeCode = generateIframe({
        embedUrl,
        embedTitle,
        height: embedHeight,
      });

      setEmbeddedCode(replaceEmbedLocalEnv(iframeCode));
    }, [embedOptions, publishedUrl, embedTitle, embedHeight, isOpen]);

    const show = async (): Promise<unknown> => {
      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };
        setIsOpen(true);
      });
    };

    const hideModal = () => {
      setIsOpen(false);
      setEmbeddedCode('');
    };

    const handleSelectStyle = (style: EmbedStyle) => {
      setEmbedHeight(style.height);
      setEmbedOptions({ ...embedOptions, hasTranscript: style.hasTranscript });
    };

    if (!isOpen) return <></>;

    return (
      <div css={[customModalCss]}>
        <Modal show={isOpen} modalClosed={hideModal}>
          <h3>Embed clip</h3>

          <div tw="flex flex-col gap-y-5 mt-3">
            <div>
              <p css={textSubtitle}>Style</p>
              <div>
                <EmbedStyleSelect onSelect={handleSelectStyle} />
              </div>
            </div>

            <div tw="mt-3">
              <p css={textSubtitle}>Embed code</p>
              <textarea
                css={embedArea}
                tw="border[2px solid white]!"
                readOnly={true}
                value={embeddedCode || ''}
                cols={50}
                rows={4}
              ></textarea>

              <div tw="flex justify-end">
                <button
                  type="button"
                  className="button btn-primary large"
                  tw="mb-0"
                  disabled={isEmpty(embeddedCode)}
                  onClick={() => {
                    if (!embeddedCode) return;

                    copyToClipboard(embeddedCode);
                    customToast.success('Copied to clipboard');
                  }}
                >
                  Copy code
                </button>
              </div>
            </div>

            <div tw="mb-3">
              <p css={[textSubtitle]}>Preview</p>
              <EmbedPreview embedCode={embeddedCode} />
            </div>
          </div>
        </Modal>
      </div>
    );
  }),
);

const textSubtitle = css`
  ${tw`mb-2 text-15 font-bold`}
`;

const embedArea = css`
  & {
    overflow-y: auto;
    font-size: 1.4rem !important;
    resize: none; /* Remove this if you want the user to resize the textarea */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &[rows] {
    height: auto;
    background: #f0f3f6;
    border-radius: 5px;
    border: none;
  }
`;

const customModalCss = css`
  .Modal {
    ${tw`(w-[90rem] left-[calc(50% - 45rem)])!`}
  }
`;
