/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { DateRangeInput } from '@datepicker-react/styled';
import { ReactComponent as AreaChartSvg } from 'assets/Icons/area_chart.svg';
import { ReactComponent as BarChartSvg } from 'assets/Icons/bar_chart.svg';
import { ReactComponent as CalendarDaySvg } from 'assets/Icons/calendar_day.svg';
import { ReactComponent as CalendarYearSvg } from 'assets/Icons/calendar_month.svg';
import { ReactComponent as CalendarWeekSvg } from 'assets/Icons/calendar_week.svg';
import { ReactComponent as CalendarMonthSvg } from 'assets/Icons/calendar_year.svg';
import { ReactComponent as PreviewSvg } from 'assets/Icons/find_white.svg';
import { ReactComponent as LineChartSvg } from 'assets/Icons/line_chart.svg';
import { TopicLayerDropDown } from 'components/Publish/TopicLayerDropDown';
import Loader from 'components/loader/loader';
import { CustomSelectSearch } from 'components/shared/CustomSelectSearch';
import { HintDisallowed } from 'components/shared/HintDisallowed';
import { InsightChart } from 'components/shared/InsightChart/InsightChart';
import { LabelCheckbox } from 'components/shared/LabelCheckbox';
import { MultiMentionReportModal } from 'components/shared/MultiMentionReportModal/MultiMentionReportModal';
import { NoFilterInsights } from 'components/shared/NoFilterInsights';
import { NoResultInsights } from 'components/shared/NoResultInsights';
import {
  PinnedChartCreationModal,
  PinnedChartCreationRef,
} from 'components/shared/PinnedChartCreationModal/PinnedChartCreationModal';
import { QuestionHint } from 'components/shared/QuestionHint';
import {
  customDateRangInputStyle,
  resetReactRangeCss,
} from 'components/shared/twin.styles';
import { debounce, first, isEmpty, isNil, uniq, uniqBy } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLatest, useToggle } from 'react-use';
import { RootState } from 'reducers';
import { MediaService, TermSearchInsight } from 'services';
import { InsightService } from 'services/insight.service';
import {
  insightSelector,
  pinCurrentChart,
  removeSingleVocabTerm,
  setInputTerms,
  setInsightCalendarMode,
  setInsightChartType,
  setInsightDateRange,
  setInsightLayer,
  setSearchInputTerm,
  setVocabListId,
  setVocabTerms,
  toggleCollectionGroupBy,
  toggleDataLabels,
  toggleQuarterMarkers,
  toggleSelectInsightTerm,
} from 'slices/insight.slice';
import { userSelector } from 'slices/payment.slice';
import { rawStringToTerm, setSelected } from 'utils/adapter.util';
import { MAX_INSIGHT_TERM, MAX_PINNABLE_CHART } from 'utils/constants';
import { endOfDayISO, getTodayShort, startOfDayISO } from 'utils/date.util';
import {
  ApexChartType,
  CalendarFilter,
  Dropdown,
  HTTPStatus,
  Keys,
  Layers,
  ModalOptions,
  SortField,
} from 'utils/enum';
import { ButtonItem, InsightTerm, Term, VocabDropdown } from 'utils/models';
import { InsightMentionModalRef } from 'utils/models/modal.model';
import { customToast } from 'utils/toast.util';
import { getTopicLayerName, typeMediaEntities, waitAsync } from 'utils/utils';
import { ButtonGroup } from '../shared/ButtonGroup';
import { InsightTag } from './shared/InsightTag';
import { InsightAdvancedFilterMenu } from 'components/shared/AdvancedFilterMenu/InsightAdvancedFilterMenu';
import { extract } from 'utils/generic.util';
import { AxiosError } from 'axios';

export const SearchableLayerCharts = () => {
  const dispatch = useDispatch();

  const global = useSelector((state: RootState) => state.global);
  const insight = useSelector((state: RootState) => state.insight);
  const latestInsight = useLatest(insight);

  const { combinedTerms } = useSelector(insightSelector);

  const user = useSelector(userSelector);

  const multiMentionReportModalRef = useRef<InsightMentionModalRef>(null);
  const pinnedChartCreationModalRef = useRef<PinnedChartCreationRef>(null);

  const [isFetching, toggleFetching] = useToggle(false);

  const [chartData, setChartData] = useState<TermSearchInsight[]>([]);
  const [selectVocabList, setSelectedVocabList] =
    useState<VocabDropdown | null>(null);

  const [isEmptyList, toggleEmptyList] = useToggle(false);

  const insightVocabs = useMemo<VocabDropdown[]>(() => {
    const vocabsDropdown = global.customTerms
      .filter((vocabList) => vocabList.insightVisible)
      .map((vocabList) => ({
        label: vocabList.name,
        value: vocabList.id,
        terms: vocabList.terms,
      })) as VocabDropdown[];

    const defaultOption: VocabDropdown = {
      label: '-- No custom list --',
      value: '',
      terms: [],
    };

    vocabsDropdown.unshift(defaultOption);

    return vocabsDropdown;
  }, [global.customTerms]);

  const selectedVocabLabel = insightVocabs.find(
    (vocabDropdown) => vocabDropdown.value === insight.vocabListId,
  )?.label;

  // useUnmount(() => {
  //   dispatch(resetInsightTerms());
  //   dispatch(resetInsight());
  // });

  useEffect(() => {
    if (isEmpty(combinedTerms)) return;

    handleGenerateAsync(insight.calendarMode);
  }, [
    combinedTerms?.filter((term) => term.selected).length,
    insight.vocabListId,
  ]);

  const handleChangeLayer = (layer: Layers) => {
    dispatch(setInsightLayer(layer));
  };

  const handleGenerateAsync = async (calendarMode: CalendarFilter) => {
    if (isNil(insight.selectedLayer)) return;

    const termsInput = getTermsInputString();
    dispatch(setInputTerms(uniqBy(termsInput, 'name')));

    try {
      toggleFetching(true);

      const activeTerms = uniqBy([...insight.vocabTerms, ...termsInput], 'name')
        .filter((term) => term.selected)
        .map((term) => term.name);

      if (isEmpty(insight.searchInputTerm?.trim())) {
        dispatch(setInputTerms([]));
      }

      if (isEmpty(uniq(activeTerms))) {
        customToast.warning('There is no term(s) entered or selected');
      }

      const { data } = await InsightService.postInsightsAsync<
        TermSearchInsight[]
      >({
        category: typeMediaEntities(insight.selectedLayer),
        terms: uniq(activeTerms),
        fromDate: startOfDayISO(insight.dateRange.startDate),
        toDate: endOfDayISO(insight.dateRange.endDate),
        calendarInterval: calendarMode,
        tagIds: insight.selectedTagIds,
        collectionIds: insight.selectedCollectionIds,
        orgIds: insight.selectedOrgIds,
      });

      const isEmptyResult = data?.every((item) =>
        isEmpty(item?.term_count_over_time.buckets),
      );
      toggleEmptyList(isEmptyResult);

      setChartData(data);
    } catch (error) {
      console.log('err :>> ', error);

      const status = (error as AxiosError).response?.status;

      if (status === HTTPStatus.RESTRICTED) {
        customToast.warning(
          'There are no items associated to this filter conditions.',
        );
      }
    } finally {
      toggleFetching(false);
    }
  };

  const handleCheckboxDataLabels = () => {
    dispatch(toggleDataLabels());
  };

  const handleCheckboxQuarterMarkers = () => {
    dispatch(toggleQuarterMarkers());
  };

  const handleCheckboxCollectionGroupBy = () => {
    dispatch(toggleCollectionGroupBy());
  };

  const handleRemoveVocabTerm = (term: InsightTerm) => {
    dispatch(removeSingleVocabTerm(term));

    const deductedData = chartData.filter((data) => data.term !== term.name);
    setChartData(deductedData);
  };

  const getTermsInputString = (): InsightTerm[] => {
    const termsVocab = insight.vocabTerms;
    const termsVocabStringList = termsVocab.map((term) => term.name);

    const termsInput = uniq(
      insight.searchInputTerm
        .split(',')
        .map((term) => term?.trim())
        .filter(Boolean)
        .filter((term) => !termsVocabStringList.includes(term)),
    );

    if (isEmpty(insight.inputTerms)) {
      validateInsightTerms(termsInput);
    }

    const limitTerm =
      MAX_INSIGHT_TERM - termsVocab.filter((term) => term.selected).length;

    const insightInputTerms = [
      ...termsInput.slice(0, limitTerm).map(rawStringToTerm(true)),
      ...termsInput.slice(limitTerm).map(rawStringToTerm(false)),
    ];

    return insightInputTerms.map((term) => {
      const existed = insight.inputTerms.find(({ name }) => name === term.name);

      return existed ? { ...term, selected: existed.selected } : term;
    });
  };

  const handleDropdownInsightList = (vocabList: VocabDropdown) => {
    setSelectedVocabList(isEmpty(vocabList.value) ? null : vocabList);
    const terms = vocabList.terms ?? [];

    const activeInputTerms = insight.inputTerms.filter((term) => term.selected);

    validateInsightTerms(terms);

    const limitTerm = MAX_INSIGHT_TERM - activeInputTerms.length;

    const activeInputNames = extract(activeInputTerms, 'name');

    const filteredTerms = terms.filter(
      ({ name }) => !activeInputNames.includes(name),
    );

    const termList = [
      ...filteredTerms.slice(0, limitTerm).map(setSelected(true)),
      ...filteredTerms.slice(limitTerm).map(setSelected(false)),
    ];

    dispatch(setVocabListId(vocabList.value));
    dispatch(setVocabTerms(termList));
    dispatch(setInsightLayer(Layers.TRANSCRIPT));
  };

  const validateInsightTerms = (terms: (InsightTerm | Term | string)[]) => {
    if (terms.length > MAX_INSIGHT_TERM) {
      customToast.warning(
        <div>
          <div>Reached limitation of maximum items</div>
          <div>
            Only first <strong>{MAX_INSIGHT_TERM}</strong> terms would be
            selected!
          </div>
        </div>,
      );
    }
  };

  const handleChangeChartType = (itemGroup: ButtonItem) => {
    dispatch(setInsightChartType(itemGroup.value as ApexChartType));

    if (
      itemGroup.value === 'bar' &&
      [CalendarFilter.MONTH, CalendarFilter.YEAR].includes(insight.calendarMode)
    ) {
      dispatch(toggleDataLabels(true));
      dispatch(toggleQuarterMarkers(false));
    } else {
      dispatch(toggleDataLabels(false));
      dispatch(toggleQuarterMarkers(true));
    }

    if (
      !isEmpty(insight.searchInputTerm?.trim()) ||
      !isEmpty(insight.vocabTerms)
    ) {
      handleGenerateAsync(insight.calendarMode);
    }
  };

  const getAllMediaIds = (chartItem: TermSearchInsight) => {
    const resultMediaIds: string[] = [];

    chartItem.term_count_over_time?.buckets?.forEach((insightKey) => {
      const selectedIds: string[] =
        insightKey?.media_ids?.buckets?.map(({ key: mediaid }) => mediaid) ??
        [];

      resultMediaIds.push(...selectedIds);
    });

    return uniq(resultMediaIds);
  };

  const handleMentionModal = async () => {
    const payloadMediaIds: string[] = [];

    const activeTerms = chartData.map(({ term }) => term);

    if (isEmpty(activeTerms)) return;

    const result = await multiMentionReportModalRef.current?.show({
      termArray: activeTerms,
      isMultiTerm: true,
      defaultTitle: `Mentions Analytics${
        activeTerms.length === 1 ? ` ${first(activeTerms)}` : ''
      } - Combined Terms Clip_${getTodayShort()}`,
    });

    if (result?.option !== ModalOptions.YES) return;

    chartData.forEach((chartItem) => {
      const chartItemMediaIds = getAllMediaIds(chartItem);

      payloadMediaIds.push(...chartItemMediaIds);
    });

    const getMultiMentionsAsync = MediaService.getMentionReport({
      mediaIds: uniq(payloadMediaIds),
      terms: result?.terms ?? [],
      reportName: result?.reportName ?? '',
      isCombined: result?.isCombined,
      collectionIds: insight.selectedCollectionIds ?? undefined,
      fromDate: startOfDayISO(insight.dateRange.startDate),
      toDate: endOfDayISO(insight.dateRange.endDate),
      order: 'asc',
      sortBy: SortField.MODIFIED,
    });

    customToast.promise(waitAsync(2000), {
      loading: 'Running in progress',
      success: (
        <div>
          <div>Submitted for background processing.</div>
          <div>It may take a few minutes to be ready.</div>
        </div>
      ),
      error: 'Generating Mention Report failed. Please check and try again.',
    });

    try {
      await getMultiMentionsAsync;
    } catch (err) {
      console.log('err :>> ', err);
    }
  };

  const handlePinChart = async () => {
    const chartNo = latestInsight.current.pinnedCharts.length + 1;
    const isReachedMaxPinnable = chartNo > MAX_PINNABLE_CHART;

    if (isReachedMaxPinnable) {
      customToast.warning(
        <div>
          <div>
            Maximum limit of <strong>{MAX_PINNABLE_CHART}</strong> charts
            reached. Please unpin chart(s) and try again.
          </div>
        </div>,
      );

      return;
    }

    const result = await pinnedChartCreationModalRef.current?.show();

    if (result?.option !== ModalOptions.YES) return;

    dispatch(pinCurrentChart({ title: result.chartName }));
  };

  return (
    <div tw="mx-3 mt-2 w-full">
      <div tw="text-20 font-semibold">{insight.title}</div>

      <div tw="w-full mt-3 rounded border[1px solid] border-sonnant-grey-light shadow px-5 pb-5">
        <div tw="py-6 flex flex-wrap gap-4 relative z-index[2]">
          <div tw="flex flex-wrap flex-1 gap-x-5 gap-y-3">
            {/* TERM SEARCH */}
            <div tw="min-w-[20rem]">
              <label tw="flex items-center gap-x-3">
                <span tw="text-15">Term</span>
                <span tw="flex [svg]:(h-[1.8rem] w-[1.8rem])">
                  <QuestionHint
                    type="information"
                    align="bottomLeft"
                    action="hover"
                    customStyle={customTooltipCss}
                    hideCloseButton
                    text={
                      <div>Multiple terms can be separated by comma (,)</div>
                    }
                  />
                </span>
              </label>

              <input
                tw="(w-full pr-11 border-width[2px] right[-2px] mb-0 placeholder:text-14)!"
                type="text"
                placeholder="Term Search"
                value={insight.searchInputTerm}
                onChange={(e) => {
                  const rawSearchTerm = e.target.value;
                  dispatch(setSearchInputTerm(rawSearchTerm));
                }}
                onKeyDown={(e) => {
                  if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
                    const termsInput = uniqBy(getTermsInputString(), 'name');
                    dispatch(setInputTerms(termsInput));

                    // Manual trigger, since useEffect cannot detect same length array changed
                    if (insight.inputTerms.length === termsInput.length) {
                      handleGenerateAsync(insight.calendarMode);
                    }
                  }
                }}
              />
            </div>

            <div tw="text-sonnant-grey-6 flex items-end text-14 font-medium mb-3 -mx-1">
              OR
            </div>

            {/* CUSTOM LIST */}
            <div tw="min-w-[15rem] text-14 z-[22]">
              <label>
                <span tw="text-15">List</span>
              </label>

              <CustomSelectSearch
                options={insightVocabs}
                onChange={handleDropdownInsightList}
                canCreate={false}
                isLoading={user.isLoading}
                mode={Dropdown.SIMPLE_TEXT}
                defaultValue={selectedVocabLabel ?? 'Select from a custom list'}
              />
            </div>

            {/* FILTER BUTTON */}
            <div tw="flex items-end">
              <InsightAdvancedFilterMenu />
            </div>

            {/* LAYERS */}
            <div tw="min-w-[20rem]">
              <label>
                <span tw="text-15">Layer</span>
              </label>

              <div
                css={[
                  !isEmpty(selectVocabList) &&
                    tw`opacity[0.65] cursor-not-allowed select-none`,
                ]}
              >
                <TopicLayerDropDown
                  defaultValue={getTopicLayerName(insight.selectedLayer)}
                  onChange={handleChangeLayer}
                  hasIABLayer
                  hasTranscripts
                  disabled={!isEmpty(selectVocabList)}
                />
              </div>
            </div>

            {/* DATE RANGE */}
            <div tw="relative z-[21]">
              <div css={[customDateRangInputStyle]}>
                <label>
                  <span tw="text-15">Date Range</span>
                </label>
                <div tw="flex items-center space-x-10 relative z-[1]">
                  <span css={[resetReactRangeCss]}>
                    <DateRangeInput
                      placement="bottom"
                      onDatesChange={debounce((dateRange) => {
                        dispatch(setInsightDateRange(dateRange));
                      }, 0)}
                      onFocusChange={(focusedInput) => {
                        dispatch(
                          setInsightDateRange({
                            ...insight.dateRange,
                            focusedInput: focusedInput as any,
                          }),
                        );
                      }}
                      startDate={insight.dateRange.startDate} // Date or null
                      endDate={insight.dateRange.endDate} // Date or null
                      focusedInput={insight.dateRange.focusedInput} // START_DATE, END_DATE or null
                      maxBookingDate={new Date()}
                      displayFormat="dd/MM/yyyy"
                    />
                  </span>
                </div>
              </div>
            </div>

            {/* COLLECTION DROPDOWN */}
            {/* <div tw="min-w-[20rem]">
              <label tw="flex items-center gap-x-3">
                <span tw="text-15">Collection</span>
                <span tw="flex [svg]:(h-[1.8rem] w-[1.8rem])">
                  <QuestionHint
                    type="information"
                    align="bottomLeft"
                    action="hover"
                    customStyle={customTooltipCss}
                    hideCloseButton
                    text={
                      <div tw="line-height[1.5]">
                        Collection refers to the folder for podcasts or shows
                      </div>
                    }
                  />
                </span>
              </label>
              <CollectionDropdown
                selectedCollections={insight.selectedCollectionIds}
                onMultiSelectChange={handleChangeCollection}
                isMultiSelect
                canCreate={false}
              />
            </div> */}

            {/* GENERATE BUTTON */}
            <div tw="flex items-end">
              <button
                className="button large btn-primary"
                onClick={() => handleGenerateAsync(insight.calendarMode)}
                tw="mb-0 ml-0 flex justify-center items-center height[4rem]! shadow"
                disabled={isFetching}
              >
                <span tw="flex">
                  <PreviewSvg tw="mr-3" />
                </span>
                Generate
              </button>
            </div>
          </div>

          <div tw="flex flex-wrap gap-3 w-full">
            {uniqBy(combinedTerms, 'name').map((term) => (
              <InsightTag
                key={term.id}
                term={term}
                handleClickTag={(clickedTerm) => {
                  dispatch(toggleSelectInsightTerm(clickedTerm));
                }}
                onRemove={handleRemoveVocabTerm}
                showMentionModal={handleMentionModal}
                hasMentionPopover={!isEmpty(chartData)}
              />
            ))}
          </div>

          <div tw="w-full flex justify-end gap-x-5">
            <div tw="text-14 flex flex-1 gap-x-5">
              <LabelCheckbox
                label="Show data labels"
                checked={insight.showDataLabels}
                onChange={handleCheckboxDataLabels}
              />

              <LabelCheckbox
                label="Show quarter markers"
                checked={insight.showQuarterMarkers}
                onChange={handleCheckboxQuarterMarkers}
              />

              <HintDisallowed
                disabled={!isEmpty(insight.selectedCollectionIds)}
                hintEnabled={`Show result by the number of collection: ${
                  insight.groupByCollection ? 'ON' : 'OFF'
                }`}
                hintDisabled="Grouping by collection is only available for none-collection selected mode"
                tw="flex items-center"
                notTransparent
                arrow
              >
                <LabelCheckbox
                  label="Group by collection"
                  checked={insight.groupByCollection}
                  onChange={handleCheckboxCollectionGroupBy}
                  disabled={!isEmpty(insight.selectedCollectionIds)}
                />
              </HintDisallowed>
            </div>

            <ButtonGroup
              items={[
                { icon: <LineChartSvg />, label: 'Line', value: 'line' },
                { icon: <BarChartSvg />, label: 'Bar', value: 'bar' },
                { icon: <AreaChartSvg />, label: 'Area', value: 'area' },
              ]}
              defaultValue={insight.chartType}
              onChange={handleChangeChartType}
            />

            <ButtonGroup
              items={[
                {
                  icon: <CalendarDaySvg />,
                  label: 'Day',
                  value: CalendarFilter.DAY,
                },
                {
                  icon: <CalendarWeekSvg />,
                  label: 'Week',
                  value: CalendarFilter.WEEK,
                },
                {
                  icon: <CalendarMonthSvg />,
                  label: 'Month',
                  value: CalendarFilter.MONTH,
                },
                {
                  icon: <CalendarYearSvg />,
                  label: 'Year',
                  value: CalendarFilter.YEAR,
                },
              ]}
              defaultValue={insight.calendarMode}
              onChange={(data) => {
                const value = data.value as CalendarFilter;

                if ([CalendarFilter.DAY, CalendarFilter.WEEK].includes(value)) {
                  dispatch(toggleDataLabels(false));
                }

                dispatch(setInsightCalendarMode(value));

                handleGenerateAsync(value);
              }}
            />
          </div>
        </div>

        {isFetching && (
          <div tw="min-h-[40rem] flex justify-center my-3 items-center shadow rounded">
            <Loader />
          </div>
        )}

        {!isFetching &&
          (!isEmpty(chartData) && !isEmptyList ? (
            <div tw="px-10 min-h-[40rem] pt-6 shadow rounded border border-solid border-sonnant-grey-1 relative z-0">
              <InsightChart
                insightOptions={insight}
                height={500}
                chartData={chartData}
                handlePinChart={handlePinChart}
              />
            </div>
          ) : (
            <div tw="min-h-[40rem] flex items-center shadow border[1px solid] border-sonnant-grey-2">
              {isEmpty(insight.vocabTerms) && isEmpty(insight.inputTerms) ? (
                <NoFilterInsights />
              ) : (
                <NoResultInsights />
              )}
            </div>
          ))}
      </div>

      <MultiMentionReportModal ref={multiMentionReportModalRef} />
      <PinnedChartCreationModal ref={pinnedChartCreationModalRef} />
    </div>
  );
};

const customTooltipCss = css`
  ${tw`font-size[1rem]`}

  .notification {
    ${tw`lg-up:width[40rem]! md-down:(width[20rem] break-words)`}
  }
  .notificationWrapper {
    ${tw`margin-top[1rem]!`}
  }

  svg {
    ${tw`(height[1.5rem] width[1.5rem])!`}
  }
`;
