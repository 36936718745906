/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { useClickAway, useToggle } from 'react-use';
import { FilterWrapper } from './FilterWrapper';
import { useRef } from 'react';
import { FilterMenuOptions } from 'utils/models';
import { useDispatch, useSelector } from 'react-redux';
import {
  insightSelector,
  resetInsightFilters,
  setInsightCollectionIds,
  setInsightOrgIds,
  setInsightTagIds,
  toggleCollectionGroupBy,
} from 'slices/insight.slice';
import { isEmpty } from 'lodash';
import { RootState } from 'reducers';
import { InsightFilterButton } from 'components/InsightsTab/shared/AdvancedFilterButton';

export const InsightAdvancedFilterMenu = () => {
  const dispatch = useDispatch();

  const insightSlice = useSelector(insightSelector);
  const insight = useSelector((state: RootState) => state.insight);

  const [showMenu, toggleShowMenu] = useToggle(false);

  const filterWrapperRef = useRef<HTMLDivElement>(null);

  useClickAway(filterWrapperRef, () => {
    if (!showMenu) return;

    const timer = setTimeout(() => {
      toggleShowMenu(false);

      clearTimeout(timer);
    }, 100);
  });

  const handleApplyFilters = (filters: FilterMenuOptions) => {
    const { collectionIds, tagIds, orgIds } = filters;

    dispatch(setInsightCollectionIds(collectionIds));
    dispatch(setInsightTagIds(tagIds));
    dispatch(setInsightOrgIds(orgIds));

    if (!isEmpty(collectionIds)) {
      // Group by collection is not allowed on collection selected
      dispatch(toggleCollectionGroupBy(false));
    }

    toggleShowMenu(false);
  };

  const handleResetFilters = () => {
    dispatch(resetInsightFilters());
  };

  return (
    <div ref={filterWrapperRef}>
      <FilterWrapper
        menuIsOpen={showMenu}
        onCloseMenu={handleApplyFilters}
        onClearFilters={handleResetFilters}
        onApplyFilters={() => {}}
        selectionFilters={{
          tagIds: insight.selectedTagIds,
          collectionIds: insight.selectedCollectionIds,
          orgIds: insight.selectedOrgIds,
        }}
        showReset={insightSlice.filtersCount > 0}
        showApply={false}
        fixed={false}
        anchorElement={
          <InsightFilterButton
            onClick={toggleShowMenu}
            filtersCount={insightSlice.filtersCount}
          />
        }
      />
    </div>
  );
};
