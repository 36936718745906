/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import 'twin.macro';
import tw from 'twin.macro';

import { ReactComponent as ShareEmbed } from 'assets/Icons/ShareEmbed.svg';
import { ReactComponent as ShareFacebook } from 'assets/Icons/ShareFacebook.svg';
import { ReactComponent as ShareLinkedin } from 'assets/Icons/ShareLinkedin.svg';
import { ReactComponent as ShareTwitter } from 'assets/Icons/x-twitter-logo.svg';
import { ReactComponent as CloseSvg } from 'assets/Icons/close.svg';
import { ReactComponent as ShareLink } from 'assets/Icons/link_rounded.svg';

import { hoverDropShadow } from 'components/shared/twin.styles';
import { defaultTo, isEmpty, snakeCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { toggleOpenShare } from 'slices/embed.slice';
import { Social } from 'utils/enum';
import { EmbedBackgroundBlur } from '../EmbedPlayer/EmbedBackgroundBlur';
import { EmbedSharingItem } from './EmbedSharingItem';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import { useCopyToClipboard, useWindowSize } from 'react-use';
import { customToast } from 'utils/toast.util';
import { generateIframe } from 'components/VideoPlayer/Transcription/MediaUtilities';

type Props = {};

export const EmbedSharing = ({ ...props }: Props) => {
  const dispatch = useDispatch();

  const [, copyToClipboard] = useCopyToClipboard();
  const { height: iframeHeight } = useWindowSize();

  const media = useSelector((state: RootState) => state.media);
  const embed = useSelector((state: RootState) => state.embed);

  const shareUrl = embed.publicShareUrl;
  const backgroundImage = defaultTo(media.metadata?.thumbnail, '');

  const hashTags =
    media.metadata?.keywords?.map(({ keyword }) => snakeCase(keyword)) ?? [];

  const handleClickClose = () => {
    dispatch(toggleOpenShare(false));
  };

  const handleCopyEmbedLink = () => {
    const embedUrl = window.location.href;

    const iframeUrl = generateIframe({
      embedUrl,
      embedTitle: media.versionname,
      height: iframeHeight,
    });

    handleCopyToClipboard(iframeUrl);
  };

  const handleCopyToClipboard = (url: string) => {
    if (isEmpty(url)) return;

    copyToClipboard(url);
    customToast.success('Copied to clipboard');
  };

  return (
    <div css={[embed.isOpenShare ? tw`flex` : tw`hidden`]}>
      <EmbedBackgroundBlur bgImage={backgroundImage} zIndex={4} />

      <div css={[contentCss]}>
        <div tw="flex flex-row justify-between">
          <div>Share</div>
          <div tw="h-[2.2rem]" onClick={handleClickClose}>
            <CloseSvg css={[hoverDropShadow(0.2), xIconCss]} />
          </div>
        </div>

        <div tw="flex flex-row flex-1 justify-evenly flex-wrap py-5">
          <LinkedinShareButton
            url={shareUrl}
            title={media.versionname}
            summary={media.metadata?.summary}
            className="sonnant-custom-share"
          >
            <EmbedSharingItem
              iconComponent={<ShareLinkedin />}
              name={Social.LINKEDIN}
            />
          </LinkedinShareButton>

          <FacebookShareButton
            url={shareUrl}
            quote={media.versionname}
            className="sonnant-custom-share"
          >
            <EmbedSharingItem
              iconComponent={<ShareFacebook />}
              name={Social.FACEBOOK}
              className="sonnant-custom-share"
            />
          </FacebookShareButton>

          <TwitterShareButton
            url={shareUrl}
            title={media.versionname}
            hashtags={hashTags}
            className="sonnant-custom-share"
          >
            <EmbedSharingItem
              iconComponent={
                <ShareTwitter tw="w-[4.8rem] h-[4.8rem] bg-black rounded-full p-2" />
              }
              name={Social.TWITTER}
            />
          </TwitterShareButton>

          <EmbedSharingItem
            iconComponent={<ShareEmbed />}
            name="Embed"
            onClick={handleCopyEmbedLink}
          />

          <EmbedSharingItem
            iconComponent={<ShareLink css={shareIconCss} />}
            name="Copy link"
            onClick={() => handleCopyToClipboard(shareUrl)}
          />
        </div>
      </div>
    </div>
  );
};

const contentCss = css`
  transform: translate(-50%, -50%);

  ${tw`flex flex-col text-white font-bold absolute top-[50%] left-[50%] z-[10] w-full h-full py-8 px-10 text-center`}

  .sonnant-custom-share {
    ${tw`(hover:shadow-none bg-transparent)!`}
  }
`;

const xIconCss = css`
  path {
    ${tw`fill[white]`}
  }
`;

const shareIconCss = css`
  ${tw`w-[4.8rem] h-[4.8rem] bg-sonnant-blue-login rounded-full p-4`}
`;
