/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import '@emotion/react';

import { SearchInput } from 'components/shared/SearchInput';
import { ReactComponent as CollectionSvg } from 'assets/Icons/collections_bookmark.svg';
import { ReactComponent as RemoveFromCollectionSvg } from 'assets/Icons/folder_delete.svg';
// import { ReactComponent as AddNewSvg } from 'assets/Icons/library_add_black_24dp.svg';
import { CollectionItem } from './CollectionItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  collectionSelector,
  deleteCollection,
  fetchCollections,
  resetSelectedCollectionId,
  setCollectionSearchTerm,
  setSelectedCollectionId,
  setShowCollectionSidebar,
} from 'slices/collection.slice';
import { RootState } from 'reducers';
import { MouseEvent, useEffect, useMemo, useRef } from 'react';
import { AddCollectionButton } from './AddCollectionButton';
import { AddCollectionInput } from './AddCollectionInput';
import { isEmpty, isNil } from 'lodash';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { CollectionSidebarItem, ConfirmModalProps, Term } from 'utils/models';
import { DragNDrop, MediaProvider, ModalOptions, StringEnum } from 'utils/enum';
import { customToast } from 'utils/toast.util';
import { Info } from 'components/shared/Info';
import { useHistory } from 'react-router-dom';
import { CollectionService } from 'services/collection.service';
import { initLibrary, setSelectedRows, setSelectMode } from 'actions';
import { Ripple } from '@rmwc/ripple';
import { changePage } from 'slices/pagination.slice';
import { Droppable } from 'react-beautiful-dnd';
import {
  isLibraryPage,
  isRevivalPage,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import Loader from 'components/loader/loader';
import {
  resetAdvancedSearch,
  toggleAdvancedFilter,
} from 'slices/advanced-search.slice';
import { SEARCH_CLEAR } from 'actions/types';
import { MultiMentionReportModal } from 'components/shared/MultiMentionReportModal/MultiMentionReportModal';
import { LibraryService, MediaService } from 'services';
import toast from 'react-hot-toast';
import { IntegrationModal } from 'components/shared/IntegrationModal/IntegrationModal';
import { MonetisationModal } from 'components/shared/MonetisationModal/MonetisationModal';
import { CollectionFilterToolbar } from './CollectionFilterToolbar';
import * as actions from 'actions';
import { Routes } from 'utils/routes';
import { CollectionInfoDrawer } from './CollectionInfoDrawer';
import { ensureArray } from 'utils/generic.util';
import { useUnmount } from 'react-use';
import { ARCHIVE_TAG_NAME } from 'utils/constants';

interface Props {}

export const CollectionSidebar = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const confirmModalRef = useRef<any>(null);

  const collection = useSelector((state: RootState) => state.collection);
  const tag = useSelector((state: RootState) => state.tags);
  const library = useSelector((state: RootState) => state.library);
  const pagination = useSelector((state: RootState) => state.pagination);
  const integrationModalRef = useRef<any>(null);

  const advancedSearch = useSelector(
    (state: RootState) => state.advancedSearch,
  );
  const collectionStore = useSelector(collectionSelector);

  const term = collection.searchTerm?.trim();

  const multiMentionReportModalRef = useRef<any>(null);
  const monetisationRef = useRef<any>(null);

  // Hide collection sidebar for search clips mode
  useEffect(() => {
    if (advancedSearch.isShowClipsOnly === true || isRevivalPage()) {
      dispatch(setShowCollectionSidebar(false));
    }
  }, [advancedSearch.isShowClipsOnly, isRevivalPage()]);

  useUnmount(() => {
    dispatch(setCollectionSearchTerm(StringEnum.EMPTY));
  });

  const currentCollection = useMemo(() => {
    const tagArchive = tag.activeTags.find(
      (tag) => tag.tagName === ARCHIVE_TAG_NAME,
    );

    const isFilterArchiveTag = collection.filters.tagIds.includes(
      tagArchive?.tagId ?? '',
    );

    const visibleCollection = isFilterArchiveTag
      ? collection.visibleCollection
      : collection.visibleCollection.filter((tag) => !tag.isArchived);

    const collectionItems = isFilterArchiveTag
      ? collection.items
      : collection.items.filter((tag) => !tag.isArchived);

    if (!isEmpty(collection.visibleCollection)) {
      return visibleCollection;
    }

    return collectionItems;
  }, [
    collection.visibleCollection,
    collection.items,
    collection.filters.tagIds,
  ]);

  const filteredCollection = collectionStore.hasFilters
    ? currentCollection.filter((collection) =>
        collectionStore.filteredIds.includes(collection.id),
      )
    : currentCollection;

  const searchedCollections = isEmpty(term)
    ? filteredCollection
    : filteredCollection.filter((i) =>
        i.name.toLowerCase().includes(term.toLowerCase()),
      );

  const arrayVisibleCollectionId = collection.visibleCollection.map(
    (item) => item.id,
  );

  const handleSearch = (raw: string) => {
    const term = raw?.trim() ?? '';
    dispatch(setCollectionSearchTerm(term));
  };

  const handleClickMyCollections = (e: MouseEvent<HTMLDivElement>) => {
    if (isLibraryPage() && !collection.selectedId) return;

    dispatch(setSelectedCollectionId('')); // Empty string as My Collections
    dispatch(changePage({ page_number: 1, page_size: 30 }));
    dispatch(initLibrary());
    dispatch(setSelectedRows([])); // Reset selection after deleting
    dispatch(setSelectMode(false));

    // reset filter
    dispatch(resetAdvancedSearch());
    dispatch(toggleAdvancedFilter(false));
    dispatch({ type: SEARCH_CLEAR });

    history.push('/library');
  };

  const isConfirmedExport = async (item: CollectionSidebarItem) => {
    try {
      const response = await LibraryService.getLibraryItems({
        // default value for faster requesting
        page_number: 1,
        page_size: 0,
        collection_id: item.id,
      });

      const MAX_MENTION_ITEMS = 1000;

      if (response.data?.total?.totalFiles > MAX_MENTION_ITEMS) {
        toast.dismiss();
        const confirm = await confirmModalRef.current.show({
          title: 'Mention reports',
          confirmText: 'Confirm',
          message: (
            <>
              Mentions Report export is limited to 1000 content items. Should
              you proceed, only the first 1000 items will be processed. Would
              you like to continue?
            </>
          ),
        } as ConfirmModalProps);

        return confirm === ModalOptions.YES;
      }
      return true;
    } catch (e) {
      console.log('error', e);
      return false;
    }
  };

  const handleMentionReport = async (item: CollectionSidebarItem) => {
    const result: {
      option: ModalOptions;
      terms: Term[];
      reportName: string;
      isCombined?: boolean;
    } = await multiMentionReportModalRef.current?.show({
      librarySearchTerm: library?.searchTerm,
    });

    if (result?.option === ModalOptions.YES) {
      customToast.loading('Validating...');

      const isConfirmed = await isConfirmedExport(item);
      if (!isConfirmed) return;

      const getMultiMentionsAsync = MediaService.getMentionReport({
        mediaIds: [],
        terms: result?.terms ?? [],
        reportName: result?.reportName ?? '',
        isCombined: result?.isCombined,
        collectionIds: ensureArray<string>(item?.id),
        order: pagination.isAsc ? 'asc' : 'desc',
        sortBy: pagination.sortBy,
      });

      customToast.promise(getMultiMentionsAsync, {
        loading: 'Running in progress',
        success: (
          <div>
            <div>Submitted for background processing.</div>
            <div>It may take a few minutes to be ready.</div>
          </div>
        ),
        error: 'Generating Mention Report failed. Please check and try again.',
      });

      await getMultiMentionsAsync;
    }
  };

  const handleClickMonetisation = async (item: CollectionSidebarItem) => {
    await monetisationRef.current?.show({
      collectionId: item.id,
    });
  };

  const handleDeleteCollection = async (collection: CollectionSidebarItem) => {
    const result = await confirmModalRef.current.show({
      title: 'Delete collection',
      message:
        'Deleting a collection is irreversible. This will just delete the collection. Any content items in this collection will still remain in your library.',
      confirmText: 'Delete',
    } as ConfirmModalProps);

    if (result !== ModalOptions.YES) return;

    dispatch(deleteCollection(collection?.id));

    const deleteAsync = CollectionService.deleteCollection(collection?.id);

    customToast.promise(deleteAsync, {
      loading: 'Deleting collection',
      success: 'Collection deleted',
      error: 'Failed to delete collection',
    });

    try {
      await deleteAsync;
      dispatch(fetchCollections());

      history.push(Routes.LIBRARY);

      dispatch(resetSelectedCollectionId());
      dispatch(actions.initLibrary());
      dispatch(actions.setSelectMode(false));
    } catch (err: any) {
      console.log('err', err);
    }
  };

  const handleIntegrateCollection = async (
    collection: CollectionSidebarItem,
  ) => {
    const result = await integrationModalRef.current.show({
      collectionIds: [collection?.id],
    });

    if (result === ModalOptions.YES) {
      dispatch(fetchCollections());
    }
  };

  const isVisibleCollectionItem = (id: string) => {
    return arrayVisibleCollectionId.includes(id);
  };

  return (
    <div tw="flex flex-col text-sonnant-dark height[calc(100vh - 7rem)]">
      {/* {advancedSearch.isShowAdvancedFilter && (
        <div tw="px-6">
          <button
            className="btn btn-primary large"
            tw="(flex items-center justify-center space-x-3 w-full max-width[unset] mt-3 -mb-2)!"
          >
            <AddNewSvg
              tw="cursor-pointer"
              css={[hoverDropShadow(0.15)]}
              onClick={() => {}}
            />
            <span>Add to collection</span>
          </button>
        </div>
      )} */}

      <div tw="w-full px-6 flex justify-center my-5">
        <SearchInput
          setTerm={handleSearch}
          placeholder="Collections Search"
          noShrink
        />
      </div>

      <Ripple>
        <Droppable
          droppableId={DragNDrop.MY_COLLECTION}
          isDropDisabled={!collection?.selectedId}
          direction={'vertical'}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              tw="flex justify-between items-center pl-6 py-1 mb-0 mt-2 rounded hover:(cursor-pointer)"
              css={[
                snapshot.isDraggingOver &&
                  tw`border[2px solid] border-sonnant-red rounded`,
              ]}
              onClick={handleClickMyCollections}
            >
              <div tw="flex items-center">
                {snapshot.isDraggingOver ? (
                  <RemoveFromCollectionSvg />
                ) : (
                  <CollectionSvg tw="fill[#000]" />
                )}
                <span tw="font-medium ml-4">
                  {snapshot.isDraggingOver ? (
                    <span tw="text-16">Remove from collection</span>
                  ) : (
                    'My collections'
                  )}
                </span>
              </div>
              <div tw="flex gap-x-1.5 mr-1.5">
                {!snapshot.isDraggingOver && <AddCollectionButton />}

                <CollectionFilterToolbar />
              </div>
            </div>
          )}
        </Droppable>
      </Ripple>

      <div tw="mt-3 px-3 pb-3 overflow-y-auto">
        {collection.isLoading && (
          <div tw="w-full text-center mt-12">
            <Loader />
          </div>
        )}

        {!collection.isLoading &&
          searchedCollections.map((item) => (
            <CollectionItem
              key={item.id}
              item={item}
              integrateCollection={handleIntegrateCollection}
              onDelete={handleDeleteCollection}
              onExport={handleMentionReport}
              onClickMonetisation={handleClickMonetisation}
              isVisibleCollectionItem={isVisibleCollectionItem(item.id)}
            />
          ))}

        {term && searchedCollections.length === 0 && (
          <div tw="ml-3">
            <Info text="No results found." />
          </div>
        )}
      </div>

      <div>
        {!isNil(collection.newCollectionType) && <AddCollectionInput />}
      </div>

      <ConfirmModal ref={confirmModalRef} />
      <MultiMentionReportModal
        ref={multiMentionReportModalRef}
        isCollectionMentionExport
      />
      <IntegrationModal
        providers={[MediaProvider.OMNY, MediaProvider.MEGAPHONE]}
        mediaids={[]}
        ref={integrationModalRef}
      />
      <MonetisationModal ref={monetisationRef} />

      <CollectionInfoDrawer />
    </div>
  );
};
