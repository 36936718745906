/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { numberNoArrow } from 'components/shared/twin.styles';
import { ceil, toNumber } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCounter } from 'react-use';
import { RootState } from 'reducers';
import { changePage } from 'slices/pagination.slice';
import { Keys } from 'utils/enum';
import { setSelectedRows } from 'actions';
import { setSelectedClips } from 'slices/clips.slice';

const PAGE_SIZE_OPTIONS = [12, 30, 60, 120];

const DEFAULT_MIN_PAGE_NUMBER = 1;

interface IProps {}

export const LibraryPagination = (props: IProps) => {
  const paginationReducer = useSelector((state: RootState) => state.pagination);
  const library = useSelector((state: RootState) => state.library);
  const collection = useSelector((state: RootState) => state.collection);

  const pageSize = paginationReducer.page_size;

  const [totalPage, setTotalPage] = useState(
    ceil(library.data.count / pageSize) || 1,
  );

  const [pageNumber, { inc: nextPage, dec: prevPage, set: setPageNumber }] =
    useCounter(
      paginationReducer.page_number,
      totalPage,
      DEFAULT_MIN_PAGE_NUMBER,
    );
  const [pageInput, setPageInput] = useState<string>(
    paginationReducer.page_number.toString(),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setTotalPage(ceil(library.data.count / pageSize) || 1);
  }, [library.data.count, pageSize]);

  useEffect(() => {
    setPageInput(paginationReducer.page_number.toString());
  }, [paginationReducer.page_number]);

  const dispatchPrevPage = () => {
    prevPage();

    dispatch(
      changePage({
        page_number: paginationReducer.page_number - 1,
        page_size: pageSize,
      }),
    );
    resetSelection();
  };

  const dispatchNextPage = () => {
    nextPage();

    dispatch(
      changePage({
        page_number: paginationReducer.page_number + 1,
        page_size: pageSize,
      }),
    );
    resetSelection();
  };

  const dispatchGotoPage = () => {
    if (pageNumber === toNumber(pageInput)) return;

    dispatch(
      changePage({
        page_number: getPossiblePage(pageInput),
        page_size: pageSize,
      }),
    );
    setPageNumber(getPossiblePage(pageInput));
    resetSelection();
  };

  const resetSelection = () => {
    dispatch(setSelectedRows([]));
    dispatch(setSelectedClips([]))
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
      dispatchGotoPage();
    }
  };

  const getPossiblePage = (num: number | string) => {
    const page = toNumber(num);

    if (page >= totalPage) {
      return totalPage;
    }
    if (page < 1) {
      return 1;
    }
    return page;
  };

  const handleChangePageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newPageSize = +e.target.value;

    const { count } = library.data;

    const newTotalPage = ceil(count / newPageSize);
    setTotalPage(newTotalPage);
    let newPageNumber = +pageInput;

    if (newPageNumber > newTotalPage) {
      newPageNumber = newTotalPage;
    }
    if (newPageNumber < 1) {
      newPageNumber = 1;
    }

    setPageInput(String(newPageNumber));
    dispatch(
      changePage({ page_number: newPageNumber, page_size: newPageSize }),
    );

    resetSelection();
  };

  return !!library?.data?.library && library?.data?.library?.length > 0 ? (
    <div tw="grid grid-flow-col space-x-8 justify-between items-center">
      <>
        <div>
          <button
            tw="border-0 h-16 w-44 rounded-md my-12 background[#5551ff] text-xl hover:background[#5551ff]"
            className="button"
            onClick={dispatchPrevPage}
            disabled={paginationReducer.page_number <= 1}
          >
            Previous
          </button>
        </div>
        <div tw="flex items-center">
          <input
            tw="width[5.5rem]! text-center outline-none mr-2 mb-0!"
            css={numberNoArrow}
            type="number"
            value={pageInput || 1}
            onKeyDown={handleKeyDown}
            // onBlur={dispatchGotoPage}
            onChange={(e) => setPageInput(e.target.value)}
            min={1}
            max={totalPage}
          />
          <div tw="whitespace-nowrap">of {totalPage}</div>
        </div>
        <div>
          <button
            tw="border-0 h-16 w-44 rounded-md my-12 background[#5551ff] text-xl hover:background[#5551ff]"
            className="button"
            onClick={dispatchNextPage}
            disabled={paginationReducer.page_number >= totalPage}
          >
            Next
          </button>
        </div>
      </>
      <div tw="">
        <span tw="mr-2.5">Show</span>
        <select
          tw="h-16 w-32 my-12"
          css={[collection.showSidebar && tw`(my-0)!`]}
          onChange={handleChangePageSize}
          value={pageSize}
        >
          {PAGE_SIZE_OPTIONS.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  ) : (
    <></>
  );
};
