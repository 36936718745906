import { useQuery } from 'react-query';
import { UserService } from 'services';
import { Duration } from 'utils/constants';
import { Roles } from 'utils/enum';
import { UserList } from 'utils/models/payment.model';
import { getTenantidFromIdToken, getUserRole } from 'utils/utils';

const tenantid = getTenantidFromIdToken();

export const useUsersQuery = () => {
  const queryFnAsync = async (): Promise<UserList> => {
    const { data } = await UserService.getUsers();

    return data;
  };

  const isRoleAdmin = getUserRole() === Roles.ADMIN;

  const queryResult = useQuery({
    queryKey: ['tenant/users', tenantid],
    queryFn: queryFnAsync,
    staleTime: Duration.minutes(1),
    cacheTime: Duration.seconds(30),
    enabled: isRoleAdmin,
    retry: 3,
  });

  return queryResult;
};
