import { UseQueryOptions, useQuery } from 'react-query';
import { CollectionService } from 'services/collection.service';
import { Duration } from 'utils/constants';

type Props = {
  collectionId: string | undefined;
  options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>;
};

export const useQueryCollectionById = ({ collectionId, options }: Props) => {
  const queryFnAsync = async () => {
    if (!collectionId) return;

    const { data } = await CollectionService.getCollectionById(collectionId);

    return data;
  };

  const queryResult = useQuery({
    queryKey: ['collection/tags', collectionId],
    queryFn: queryFnAsync,
    staleTime: Duration.seconds(5),
    cacheTime: 0,
  });

  return queryResult;
};
