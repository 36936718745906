/** @jsxImportSource @emotion/react */
import 'twin.macro';

type Props = {};

export const Separator = (props: Props) => {
  return (
    <div tw="border-left[2px solid] border-sonnant-purple-1 mx-8 rounded"></div>
  );
};
