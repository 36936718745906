/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { CustomToast } from 'components/shared/CustomToast';
import { ToastMessage } from 'components/shared/ToastMessage/ToastMessage';
import { MouseEvent, ReactElement } from 'react';
import toast, { Toast } from 'react-hot-toast';
import { ToastNotiEnum } from './enum';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';

export const customToast = {
  success: (message: string | ReactElement, title?: string) => {
    toast.dismiss();
    toast(
      <CustomToast
        type="success"
        title={title || 'Success'}
        message={message}
      />,
    );
  },
  error: (
    message: string | ReactElement,
    options?:
      | Partial<
          Pick<
            Toast,
            | 'id'
            | 'icon'
            | 'duration'
            | 'ariaProps'
            | 'className'
            | 'style'
            | 'position'
            | 'iconTheme'
          >
        >
      | undefined,
  ) => {
    toast.dismiss();
    toast(
      <CustomToast type="error" title="Error" message={message} />,
      options,
    );
  },
  loading: (
    message: string | ReactElement,
    options?:
      | Partial<
          Pick<
            Toast,
            | 'id'
            | 'icon'
            | 'duration'
            | 'ariaProps'
            | 'className'
            | 'style'
            | 'position'
            | 'iconTheme'
          >
        >
      | undefined,
  ) => {
    toast.dismiss();
    toast(
      <CustomToast type="loading" title="Please wait" message={message} />,
      options,
    );
  },
  promise: (
    promise: Promise<any>,
    options: {
      loading?: string | ReactElement;
      titleSuccess?: string;
      titleError?: string;
      success: string | ReactElement;
      error?: string | ReactElement;
      useServerErrorMsg?: boolean;
    },
  ) => {
    toast.dismiss();
    toast.promise(promise, {
      loading: !options?.loading ? (
        'Please wait...'
      ) : (
        <CustomToast title="Please wait" message={options.loading} />
      ),
      success: (
        <CustomToast
          title={options?.titleSuccess ?? 'Success'}
          message={options.success}
        />
      ),
      error: (error) => {
        return (
          <CustomToast
            title="Error"
            message={
              options.useServerErrorMsg
                ? (error as AxiosError).response?.data
                : options.error
            }
          />
        );
      },
    });
  },
  downloadable: ({
    reportName,
    toastType,
    clickCallbackFn,
    isSingleItem = false,
  }: {
    reportName: string;
    toastType: ToastNotiEnum;
    clickCallbackFn: (e: MouseEvent<HTMLSpanElement>) => unknown;
    isSingleItem?: boolean;
  }) => {
    const getToastMessage = (type: ToastNotiEnum) => {
      switch (type) {
        case ToastNotiEnum.MULTI_EXPORT:
          return 'export file(s)';
        case ToastNotiEnum.MULTI_COMBINED:
          return 'combined clip';
        case ToastNotiEnum.MULTI_MENTION:
          return 'mentions report';
        default:
          return '';
      }
    };

    toast.custom(
      (t) =>
        ToastMessage({
          title: reportName || 'Success',
          message: (
            <div>
              <div>
                Your {!isSingleItem && 'multi-item'}{' '}
                {getToastMessage(toastType)}{' '}
                {toastType === ToastNotiEnum.MULTI_EXPORT ? 'are' : 'is'} ready
              </div>
              <div tw="mt-3">
                Click here to{' '}
                <span
                  css={clickableLinkCss}
                  onClick={(e) => {
                    toast.remove(t.id);

                    clickCallbackFn(e);
                  }}
                >
                  download
                </span>
              </div>
            </div>
          ),
          type: 'success',
          close: () => {
            toast.remove(t.id);
          },
        }),
      {
        position: 'bottom-right',
        duration: 5000 * 60, // 5 min
      },
    );
  },
  showGeneratedTermComplete: (params: {
    clickCallbackFn: (e: MouseEvent<HTMLSpanElement>) => unknown;
  }) => {
    toast.custom(
      (t) =>
        ToastMessage({
          title: 'Pinned Content Exploration chart related to Term or IAB',
          message: (
            <div>
              <div>Your pinned chart is ready</div>
              <div tw="mt-3">
                Click here to{' '}
                <span
                  css={clickableLinkCss}
                  onClick={(e) => {
                    toast.remove(t.id);

                    params.clickCallbackFn(e);
                  }}
                >
                  view result
                </span>
              </div>
            </div>
          ),
          type: 'success',
          close: () => {
            toast.remove(t.id);
          },
        }),
      {
        position: 'bottom-right',
        duration: 5000 * 60, // 5 min
      },
    );
  },
  trialExpired: () => {
    toast.dismiss();
    toast(
      <CustomToast
        type="error"
        title="Trial Expired"
        message={
          <div tw="text-15">
            <Link
              to="/account-settings#billing"
              tw="pt-3 text-black! opacity-80 underline hover:(text-black opacity-100)!"
            >
              <b>Upgrade now</b>
            </Link>{' '}
            to access this feature
          </div>
        }
      />,
      {
        duration: 2000,
      },
    );
  },
  maximumTermInsight: function (numberMaxTerms: number) {
    this.error(
      <div>
        <div>
          Custom Vocab insight is only allowed for maximum of{' '}
          <strong>{numberMaxTerms}</strong> terms in list
        </div>
        <div>Please check and try again.</div>
      </div>,
    );
  },
  warning: (message: string | ReactElement) => {
    toast.dismiss();
    toast(<CustomToast type="warning" message={message} title="Warning" />);
  },
  dismiss: () => {
    toast.dismiss();
  },
};

const clickableLinkCss = css`
  ${tw`underline text-sonnant-purple-2 cursor-pointer hover:(font-medium)`}
`;
