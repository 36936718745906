import { SagaAction } from 'utils/models';
import * as types from '../actions/types';

interface InitialStateType {
  loading: boolean;
  companyName: string;
  description: string;
  domainName: string;
}

const initialState = {
  loading: false,
  companyName: '',
  description: '',
  domainName: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export const updatePasswordReducer = (
  state: InitialStateType = initialState,
  action: SagaAction,
) => {
  switch (action.type) {
    case types.INITIATE_PASSWORD_UPDATE: {
      return { ...state, loading: true };
    }
    case types.UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case types.UPDATE_PASSWORD_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};
