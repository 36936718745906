/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import * as actionTypes from 'actions/types';
import { ReactComponent as ForwardSvg } from 'assets/Icons/fast_forward_24dp.svg';
import { ReactComponent as RewindSvg } from 'assets/Icons/fast_rewind_24dp.svg';
import { ReactComponent as Pause } from 'assets/Icons/fPause.svg';
import { ReactComponent as Play } from 'assets/Icons/fPlay.svg';
import { ReactComponent as ZoomSvg } from 'assets/Icons/zoom_24dp.svg';
import { Loader } from 'components/loader/loader';
import { InputTimeCode } from 'components/shared/modals/InputTimeCode';
import {
  audioThumbStyles,
  playPauseStyle,
  plyrResetStyle,
  svgHoverShadow,
} from 'components/shared/twin.styles';
import { WaveSurferVideo } from 'components/shared/Wavesurfer/WaveSurferVideo';
import { Modal } from 'components/UiControls/modal/modal';
import {
  isDisplayClips,
  isTranscriptionPage,
  msToSec,
  secToTime,
  shouldUsePlyr,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { debounce, floor, isEmpty, isEqual, round } from 'lodash';
import Plyr from 'plyr';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLatest,
  useSlider,
  useToggle,
  useUnmount,
  useUpdate,
  useVideo,
} from 'react-use';
import { RootState } from 'reducers';
import { setWaveform } from 'slices/media.slice';
import tw from 'twin.macro';
import { MediaReadyState } from 'utils/enum';
import { IStartEnd } from 'utils/models';
import { customToast } from 'utils/toast.util';

interface IPromiseParams {
  resolve: (params: any) => void;
  reject: (err: any) => void;
}

interface IProps {}

export const TimeCodeChapterModal = forwardRef(
  (props: IProps, ref: React.Ref<unknown>) => {
    useImperativeHandle(ref, () => ({ show, hide }));

    const modalPromise = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const dispatch = useDispatch();
    const zoomClipRef = React.useRef(null);
    const zoomSlider = useSlider(zoomClipRef);
    const forceUpdate = useUpdate();

    const publishLibrary = useSelector(
      (state: RootState) => state.publishLibrary,
    );
    const clips = useSelector((state: RootState) => state.clips);
    const media = useSelector((state: RootState) => state.media);

    const [shouldReset, toggleReset] = useToggle(false);
    const [shouldZoomFocus, toggleZoomFocus] = useToggle(false);

    const [currentTime, setCurrentTime] = useState<IStartEnd | null>(null);
    const [defaultTime] = useState<IStartEnd | null>(null);

    const [clipOption, setClipOption] = useState({
      title: '',
      chapterId: -1,
      startTime: 0,
      endTime: 0,
    });

    const clipOptionLatest = useLatest(clipOption);
    const [durationSec, setDurationSec] = useState(0);

    const [hasStartError, toggleStartError] = useToggle(false);
    const [hasEndError, toggleEndError] = useToggle(false);
    const [errors, setErrors] = useState<any>({});

    const [showCaption, toggleShowCaption] = useState(false);
    const { loading } = publishLibrary;
    const [zoomLevel, setZoomLevel] = useState(0);
    const [isOpen, toggleOpen] = useToggle(false);
    const [limitEndTime, setLimitEndTime] = useState(0);
    const [limitStartTime, setLimitStartTime] = useState(0);

    const [isMediaLoading, toggleMediaLoading] = useToggle(true);

    const show = async (params: any): Promise<unknown> => {
      // setParams(params);
      return new Promise((resolve, reject) => {
        modalPromise.current = {
          resolve,
          reject,
        };
        const { start, end, headline, chapterId } = params;
        toggleOpen(true);
        const foundIndex = chapter.findIndex((item) => item.id === chapterId);
        if (foundIndex !== -1 && foundIndex < chapter.length - 1) {
          setLimitEndTime(chapter[foundIndex + 1].startTime);
        } else {
          setLimitEndTime(msToSec(media?.metadata?.length));
        }

        if (foundIndex !== -1 && foundIndex > 0) {
          setLimitStartTime(chapter[foundIndex - 1].endTime);
        }

        setClipOption({
          startTime: start,
          endTime: end,
          title: headline,
          chapterId: chapterId,
        });
      });
    };

    const hide = () => {
      toggleOpen(false);
    };

    const [video, videoState, controls, videoRef] = useVideo(
      <video
        tw="w-full! rounded-md shadow"
        css={[
          !isMediaLoading && audioThumbStyles(media?.metadata?.thumbnail),
          isMediaLoading ? tw`hidden` : tw`block`,
        ]}
        src={media?.url}
        controls={false}
        autoPlay={false}
        preload="auto"
        crossOrigin="anonymous"
        controlsList="nodownload"
        playsInline
        onClick={() => (videoState.paused ? controls.play() : controls.pause())}
        onContextMenu={(e) => e.preventDefault()}
      >
        <track
          label="English"
          kind="subtitles"
          srcLang="en"
          src={media.subtitleurl}
          default
        ></track>
      </video>,
    );

    useUnmount(() => {
      if (!isTranscriptionPage()) {
        dispatch(setWaveform(undefined));
        dispatch({ type: actionTypes.CLOSE_PUBLISH_LIBRARY });
      }
    });

    useEffect(() => {
      if (!videoRef?.current || !shouldUsePlyr() || !media.subtitleurl) return;

      const videoPlyr = new Plyr(videoRef.current, {
        captions: { active: false, update: true },
        ratio: '16:9',
        controls: [
          'play',
          'progress',
          'current-time',
          'mute',
          'volume',
          'captions',
          'settings',
          'fullscreen',
        ],
        storage: { enabled: false },
      });

      if (videoPlyr) {
        toggleMediaLoading(false);

        videoPlyr.on('ready', () => {
          videoPlyr.toggleCaptions(false);
          videoPlyr.toggleControls(false);

          videoPlyr.on('captionsenabled', () => toggleShowCaption(true));
          videoPlyr.on('captionsdisabled', () => toggleShowCaption(false));
        });
      }
    }, [videoRef?.current]);

    useEffect(() => {
      zoomSlider.value = round(zoomSlider.value, 2);
      // if (zoomSlider.isSliding) return;
      setZoomLevel(zoomSlider.value * 100);
    }, [zoomSlider.value, zoomSlider.isSliding]);

    useEffect(() => {
      validateTime();
      setDurationSec(clipOption.endTime - clipOption.startTime);

      setCurrentTime({
        startTime: clipOption.startTime,
        endTime: clipOption.endTime,
      });
    }, [clipOption.startTime, clipOption.endTime]);

    useEffect(() => {
      if (!videoRef?.current) return;

      // VIDEO can play IMMEDIATELY
      if (
        [
          MediaReadyState.HAVE_ENOUGH_DATA,
          MediaReadyState.HAVE_METADATA,
        ].includes(videoRef.current.readyState)
      ) {
        toggleMediaLoading(false);
        videoRef.current.textTracks[0].mode = showCaption
          ? 'showing'
          : 'hidden';
      }
    }, [videoRef.current?.readyState]);

    const validateTime = () => {
      const errors: any = {};
      toggleStartError(false);
      toggleEndError(false);

      if (clipOption.endTime < clipOption.startTime) {
        errors.difference = 'End time is before start time!';
        toggleStartError(true);
        toggleEndError(true);
      }

      if (floor(clipOption.endTime) > round(videoRef.current?.duration!, 3)) {
        errors.invalidEndTime =
          'End time cannot be more than the end time of the item!';
        toggleEndError(true);
      }

      if (clipOption.startTime > limitEndTime || clipOption.endTime > limitEndTime) {
        errors.invalidEndTime = 'End time cannot be more than the start time of the next item!';
        toggleEndError(true);
      }

      if (clipOption.startTime < limitStartTime || clipOption.endTime < limitStartTime) {
        errors.startTime = 'Start time cannot be less than the end time of the previous item!';
        toggleStartError(true);
      }

      setErrors(errors);
    };

    const hasErrors = () => {
      return Object.keys(errors)?.length > 0;
    };

    const handleRegionUpdate = (start: number, end: number) => {
      setClipOption({
        ...clipOptionLatest.current,
        startTime: round(start, 3),
        endTime: round(end, 3),
      });
    };

    const handleUpdateTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
      setClipOption({ ...clipOption, title: e.target.value });
    };

    const handleStartTime = (time: number) => {
      setClipOption({ ...clipOption, startTime: time });
    };

    const canRecreateClip =
      isDisplayClips() &&
      !isEmpty(clips?.focusClip) &&
      !isEqual(clips.focusClip?.versioncount, '0');

    const resetClipModal = () => {
      toggleOpen(false);
      setClipOption({
        title: '',
        chapterId: -1,
        startTime: 0,
        endTime: 0,
      });
    };

    const { chapter } = useSelector((state: RootState) => state.chapter);

    const handleChange = () => {
      const existed = chapter?.some(
        (chap) =>
          chap?.id !== clipOption.chapterId &&
          secToTime(chap?.startTime) === secToTime(clipOption.startTime),
      );
      if (existed) {
        customToast.error('Cannot create chapter with the same start time');
        return;
      }

      const shouldUpdateScript: any = {
        start: clipOption.startTime,
        end: clipOption.endTime,
        headline: clipOption.title,
      };
      hide();
      modalPromise.current?.resolve(shouldUpdateScript);
    };

    return (
      <>
        <Modal
          classes="publish-modal-width"
          show={isOpen}
          modalClosed={resetClipModal}
        >
          {loading && (
            <div className="loader__component">
              <Loader />
            </div>
          )}

          <form className="publishmodal" onSubmit={e => e.preventDefault()}>
            <div className="grid-x">
              <div className="cell large-12">
                <h2>Edit time code</h2>
              </div>
            </div>

            <div>
              <div>
                <label>
                  Title
                  <input
                    type="text"
                    placeholder="Chapter"
                    value={clipOption.title}
                    onChange={handleUpdateTitle}
                    autoFocus
                    required
                  />
                </label>
              </div>

              <div css={plyrResetStyle}>
                {isMediaLoading && (
                  <div tw="w-full flex justify-center py-36 rounded-lg border[1px solid] border-sonnant-grey-1 shadow">
                    <Loader />
                  </div>
                )}
                {video}
              </div>

              <div tw="bg-sonnant-purple-4 text-white p-5 rounded-xl my-3">
                <div tw="font-size[1.4rem] flex justify-between items-center user-select[none]">
                  <div tw="flex items-center flex-1">
                    <div
                      className="button"
                      tw="(bg-sonnant-grey-5 rounded-lg mb-0 font-size[1.3rem] py-2 px-3)!"
                      onClick={toggleReset}
                    >
                      Reset
                    </div>
                  </div>
                  <div tw="flex items-center mb-2">
                    <RewindSvg
                      tw="height[2.4rem] cursor-pointer"
                      onClick={() => controls.seek(videoState.time - 5)}
                    />
                    <div
                      tw="mx-3 flex"
                      className={isMediaLoading ? 'disabled' : ''}
                    >
                      {videoState.paused ? (
                        <Play css={[playPauseStyle]} onClick={controls.play} />
                      ) : (
                        <Pause
                          css={[playPauseStyle]}
                          onClick={controls.pause}
                        />
                      )}
                    </div>
                    <ForwardSvg
                      tw="height[2.4rem] cursor-pointer"
                      onClick={() => controls.seek(videoState.time + 5)}
                    />
                  </div>
                  <div tw="flex-1 text-right text-13">
                    <div>
                      Total time: {secToTime(videoRef?.current?.duration || 0)}
                    </div>
                    <div>Clip duration: {secToTime(durationSec)}</div>
                  </div>
                </div>
                <div
                  className="waveform-bar"
                  tw="w-full text-center bg-sonnant-grey-5 rounded-md mt-2.5 mb-4 min-height[3.5rem]"
                >
                  <WaveSurferVideo
                    src={media?.url}
                    ref={videoRef}
                    currentTime={currentTime}
                    defaultTime={defaultTime}
                    shouldReset={shouldReset}
                    shouldZoomFocus={shouldZoomFocus}
                    zoomLevel={zoomLevel}
                    setZoomLevel={(zoom) => {
                      zoomSlider.value = zoom / 100;
                      forceUpdate();
                    }}
                    onRegionUpdate={debounce(handleRegionUpdate, 20)}
                  />
                </div>
                <div tw="flex justify-between font-size[1.4rem] mt-3">
                  <div tw="flex items-center text-13 width[17.8rem] cursor-pointer hover:(text-sonnant-grey-3)! select-none">
                    <span tw="flex" onClick={toggleZoomFocus}>
                      <ZoomSvg tw="mr-3" css={svgHoverShadow(0.8)} /> Zoom clip
                    </span>
                  </div>

                  <div tw="flex items-center font-medium">
                    <span
                      tw="font-size[1.8rem] cursor-pointer select-none"
                      onClick={() => {
                        if (round(zoomSlider.value, 1) < 0.1) return;
                        zoomSlider.value -= 0.1;
                        forceUpdate();
                      }}
                    >
                      -
                    </span>
                    <div
                      ref={zoomClipRef}
                      tw="width[14rem] height[4px] rounded mx-6 bg-disabled relative"
                    >
                      <div
                        tw="absolute border[1px solid] border-disabled width[14px] height[14px] rounded-full bg-white top[-5px] margin-left[-7px] cursor-pointer hover:shadow"
                        style={{ left: zoomSlider.value * 100 + '%' }}
                      ></div>
                    </div>
                    <span
                      tw="font-size[1.8rem] cursor-pointer select-none"
                      onClick={() => {
                        if (zoomSlider.value >= 1) return;
                        zoomSlider.value += 0.1;
                        forceUpdate();
                      }}
                    >
                      +
                    </span>
                  </div>

                  <div tw="flex space-x-6 text-13">
                    <div>Start: {secToTime(clipOption.startTime)}</div>
                    <div>End: {secToTime(clipOption.endTime)}</div>
                  </div>
                </div>
              </div>

              <div tw="(flex flex-wrap)!">
                <div className="large-6 cell">
                  <label>
                    Start Timecode
                    <InputTimeCode
                      size="sm"
                      time={clipOption.startTime}
                      handleTime={handleStartTime}
                      // handleTime={(time) => setClipOption({...clipOption, startTime: time})}
                      hasError={hasStartError}
                    />
                  </label>
                  {errors.startTime && (
                    <small className="danger">{errors.startTime}</small>
                  )}
                </div>

                <div className="large-6 cell">
                  <label>
                    End Timecode
                    <InputTimeCode
                      size="sm"
                      time={clipOption.endTime}
                      handleTime={(time) =>
                        setClipOption({ ...clipOption, endTime: time })
                      }
                      hasError={hasEndError}
                    />
                  </label>
                  {errors.endTime && (
                    <small className="danger">{errors.endTime}</small>
                  )}
                </div>
                <div className="large-12 cell">
                  <div>
                    {errors.difference && (
                      <small className="danger">{errors.difference}</small>
                    )}
                  </div>
                  <div>
                    {errors.invalidEndTime && (
                      <small className="danger">{errors.invalidEndTime}</small>
                    )}
                  </div>
                </div>
              </div>

              <div className="grid-x grid-margin-x rightAlign">
                <button
                  type="button"
                  className="button btn-secondary large cancel small-3 cell"
                  onClick={resetClipModal}
                >
                  Cancel
                </button>
                <button
                  className={`button btn-primary  large apply small-3 cell ${
                    hasErrors() && 'disabled'
                  }`}
                  disabled={canRecreateClip || !clipOption.endTime}
                  onClick={(e) => {
                    e.preventDefault();
                    handleChange();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </Modal>
      </>
    );
  },
);
