/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import 'foundation-sites';
import $ from 'jquery';
import { useEffect } from 'react';
import './Preferences.scss';
import { PreferencesSetting } from './PreferencesSetting';
import { NotificationSetting } from '../Notifications/NotificationSetting';
import { RssSubscriptions } from '../RssSubscriptions/RssSubscriptions';
import { IntegrationSettings } from '../Integrations/IntegrationSettings';
import { MonetisationSetting } from '../Monetisation/MonetisationSetting';
import { ApiTab } from '../ApiTab/ApiTab';
import { CollectionSetting } from '../Collection/CollectionSetting';
import { SpeakersTab } from '../SpeakersTab/SpeakersTab';
import { TagSettings } from '../Collection/TagSettings';

export const Preferences = () => {
  useEffect(() => {
    $(document).foundation();
  }, []);

  return (
    <div className="preferences">
      <div className="tab_section">
        <ul
          className="tabs"
          data-tabs
          id="collapsing-tabs"
          data-deep-link="true"
          data-update-history="true"
          data-deep-link-smudge="true"
          data-deep-link-smudge-delay="500"
        >
          <li className="tabs-title is-active">
            <a href="#preferences">Preferences</a>
          </li>
          <li className="tabs-title">
            <a href="#notifications">Notifications</a>
          </li>
          <li className="tabs-title">
            <a href="#speakers">Speakers</a>
          </li>
          <li className="tabs-title">
            <a href="#collections">Collections</a>
          </li>
          <li className="tabs-title">
            <a href="#monetisation">Monetisation</a>
          </li>
          <li className="tabs-title">
            <a href="#integrations">Integrations</a>
          </li>
          <li className="tabs-title">
            <a href="#subscriptions">RSS Subscriptions</a>
          </li>
          <li className="tabs-title">
            <a href="#api">API</a>
          </li>
        </ul>
      </div>

      <div
        className="tabs-content"
        data-tabs-content="collapsing-tabs"
        tw="border-none"
      >
        <div className={`tabs-panel is-active`} id="preferences" css={[tabCss]}>
          <PreferencesSetting />
        </div>
        <div className={`tabs-panel`} id="notifications" css={[tabCss]}>
          <NotificationSetting />
        </div>
        <div className={`tabs-panel`} id="speakers" css={[tabCss]}>
          <SpeakersTab />
        </div>
        <div className={`tabs-panel`} id="collections" css={[tabCss]}>
          <CollectionSetting />
          <TagSettings />
        </div>
        <div className={`tabs-panel`} id="monetisation" css={[tabCss]}>
          <MonetisationSetting />
        </div>
        <div className={`tabs-panel`} id="integrations" css={[tabCss]}>
          <IntegrationSettings />
        </div>
        <div className={`tabs-panel`} id="subscriptions" css={[tabCss]}>
          <RssSubscriptions />
        </div>
        <div className="tabs-panel" id="api" css={[tabCss, tw`p-0`]}>
          <ApiTab />
        </div>
      </div>
    </div>
  );
};

const tabCss = css`
  ${tw`height[calc(100vh - 130px)] overflow-y-auto overflow-x-hidden`}
`;
