/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as PlusSvg } from 'assets/Icons/AddKeyterm.svg';
import { v4 } from 'uuid';
import { Hint } from '../Hint';
import { TextBox } from '../TextBox';
import { useEffect, useState } from 'react';
import { cloneDeep, isEmpty, uniqBy } from 'lodash';
import { extract } from 'utils/generic.util';
import { appendId } from 'components/VideoPlayer/Transcription/MediaUtilities';

type Props = {
  textList: string[];
  onChange: (newTextList: string[]) => void;
};

const MAX_LENGTH_SINGLE_TERM = 64;
const ALLOWED_TERM_COUNT = 5;

export type TextItem = {
  id: string;
  text: string;
  canRemove?: boolean;
};

export const MonetisationTextSearch = ({ textList, ...props }: Props) => {
  const [termList, setTermList] = useState<TextItem[]>([]);

  const shouldShowAddButton = termList.length < ALLOWED_TERM_COUNT;

  useEffect(() => {
    setTermList(appendId(textList));
  }, [textList]);

  const handleClickAddButton = () => {
    setTermList([
      ...termList,
      {
        id: v4(),
        text: '',
      },
    ]);
  };

  const handleChangeText = (item: TextItem) => {
    if (!isValidTerm(item)) return;

    const termListClone = cloneDeep(termList);

    const foundIndex = termListClone.findIndex(({ id }) => id === item.id);
    if (foundIndex < 0) return;

    termListClone[foundIndex] = item;

    const uniqTermList = uniqBy(termListClone, 'text');

    setTermList(uniqTermList);
    props.onChange(extract(uniqTermList, 'text'));
  };

  const isValidTerm = (item: TextItem) => {
    if (isEmpty(item.text.trim())) return false;

    if (item.text.length > MAX_LENGTH_SINGLE_TERM) return false;

    return true;
  };

  const handleRemoveText = (item: TextItem) => {
    const newTermList = termList.filter(({ id }) => id !== item.id);

    setTermList(newTermList);
    props.onChange(extract(newTermList, 'text'));
  };

  return (
    <div tw="flex flex-wrap content-start w-full">
      {termList.map((term) => (
        <TextBox
          key={term.id}
          textItem={term}
          onChange={handleChangeText}
          onRemove={handleRemoveText}
          defaultEditing={isEmpty(term.text)}
        />
      ))}

      {shouldShowAddButton && (
        <Hint text="Add new text search" enterDelay={100} leaveDelay={0}>
          <button
            onClick={handleClickAddButton}
            tw="flex justify-center self-end pt-0 mb-[1.2rem] items-center cursor-pointer rounded-lg h-[4rem] pl-2 pr-4 gap-x-1"
            className="btn btn-secondary"
          >
            <PlusSvg tw="[path]:fill-[#7f8090]" />
            <span tw="text-14 font-medium">Add text</span>
          </button>
        </Hint>
      )}
    </div>
  );
};
