/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { ReactComponent as PinSvg } from 'assets/Icons/pin.svg';
import { ReactComponent as DelSvg } from 'assets/Icons/x-cancel-red_28dp.svg';

import { Hint } from 'components/shared/Hint';
import {
  hoverDropShadow,
  simpleMenuDisabled,
} from 'components/shared/twin.styles';

type Props = {
  title: string;
  onClickUnpin: () => void;
  disabledUnpin?: boolean;
};

export const ChartPinTitle = ({
  title,
  disabledUnpin = false,
  ...props
}: Props) => {
  return (
    <div tw="flex items-center gap-x-2 text-18 font-semibold flex-row w-full">
      <span tw="flex">
        <PinSvg tw="color[inherit]" />
      </span>

      <span>{title}</span>

      <span
        css={[tw`flex`, disabledUnpin && simpleMenuDisabled]}
        onClick={props.onClickUnpin}
        data-html2canvas-ignore
      >
        <Hint text="Unpin chart" enterDelay={200} arrow>
          <DelSvg css={[hoverDropShadow(0.2)]} tw="h-[2.2rem]" />
        </Hint>
      </span>
    </div>
  );
};
