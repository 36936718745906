/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import React, { KeyboardEvent, useState } from 'react';
import { Keys } from 'utils/enum';

interface Props {
  placeholder?: string;
  defaultText?: string;
  maxLength?: number;
  onBlur?: (value: string) => unknown;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => unknown;
  onPressEnter: (value: string) => unknown;
  onPressEsc?: () => unknown;
}

export const TextInput = ({
  placeholder,
  defaultText,
  onBlur,
  onPressEnter,
  onPressEsc,
  onChange,
  ...props
}: Props) => {
  const [value, setValue] = useState(defaultText || '');

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
      onPressEnter(value);
    }

    if (e.key === Keys.ESC) {
      onPressEsc?.();
    }
  };

  return (
    <input
      type="text"
      placeholder={placeholder}
      css={[tw`mb-0`]}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);

        if (typeof onChange === 'function') {
          onChange(e);
        }
      }}
      onKeyDown={handleKeyDown}
      onBlur={(e) => onBlur?.(value)}
      maxLength={props?.maxLength ?? 128}
      autoFocus
      {...props}
    />
  );
};
