import { AxiosResponse } from 'axios';
import { BaseAPI } from 'apis/amazon';
import { IUsageRecord } from 'utils/models/payment.model';
import { LambdaAPI } from 'apis/lambda-url.api';

export type UsageReportPayload = {
  startDate: DateObject;
  endDate: DateObject;
  domainName?: string;
};

export type DateObject = {
  day: number;
  month: number;
  year: number;
};

export type UsageReportResponse = { url: string; data: TableRow[] };

export type TableRow = {
  customer_id: string;
  customer_name: string;
  domain_name: string;
  plan: string;
  subscription_id: string;
  subscription_item_id: string;
  usage: number;
};

export type CheckoutPayload = {
  plan: string;
  quantity?: number;
  isAnnual?: boolean;
};

export type CheckoutResponse = {
  upgrade_success?: boolean;
  sessionId: string;
};

export type CancelResponse = {
  success: boolean;
  period_end: string;
};

export type StripeHistoryResponse = {
  success: boolean;
  url: string;
};

export const BillingService = {
  // GET /billing/consumption-history
  getConsumptionHistory: (): Promise<
    AxiosResponse<{ data: IUsageRecord[] }>
  > => {
    return BaseAPI.get('/billing/consumption-history');
  },

  // GET /billing/usage-report
  getUsageReport: (
    params: UsageReportPayload,
  ): Promise<AxiosResponse<UsageReportResponse>> => {
    // return BaseAPI.get('/billing/usage-report', { params });
    return LambdaAPI.get('/usage-report', { params });
  },

  // GET /billing/checkout-payg
  checkoutPAYG: (
    payload: CheckoutPayload,
  ): Promise<AxiosResponse<CheckoutResponse>> => {
    return BaseAPI.post('/billing/checkout-payg', payload);
  },

  // GET /billing/checkout-standard
  checkoutStandard: (
    payload: CheckoutPayload,
  ): Promise<AxiosResponse<CheckoutResponse>> => {
    return BaseAPI.post('/billing/checkout-standard', payload);
  },

  // GET /billing/subscription-cancel
  cancelSubscription: (): Promise<AxiosResponse<CancelResponse>> => {
    return BaseAPI.post('/billing/cancel-subscription');
  },

  // GET /billing/customer-portal
  getTransactionHistory: (): Promise<AxiosResponse<StripeHistoryResponse>> => {
    return BaseAPI.post('/billing/customer-portal');
  },
};
