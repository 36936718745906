/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ReactElement } from 'react';
import tw from 'twin.macro';

import React, { useRef } from 'react';
import { useClickAway, useToggle } from 'react-use';
import { Tooltip } from '@rmwc/tooltip';
import { ReactComponent as QuestionSvg } from '../../assets/Icons/Vector_question.svg';
import { Notification } from '../UiControls/notification/Notification';
import { isEqual } from 'lodash';

interface IProps {
  type:
    | 'notification'
    | 'left'
    | 'warning'
    | 'information'
    | 'popup-manage-user'
    | 'error'
    | any
    | null;
  text: string | ReactElement;
  align:
    | 'left'
    | 'right'
    | 'top'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight';
  action: 'hover' | 'click';
  customStyle?: any;
  notTransparent?: boolean;
  hideCloseButton?: boolean;
}

export const QuestionHint = React.memo((props: IProps) => {
  const [isShowHint, toggleShowHint] = useToggle(false);
  const tooltipWrapperRef = useRef(null);

  useClickAway(tooltipWrapperRef, () => {
    toggleShowHint(false);
  });

  const handleMouseEnter = () => {
    if (isEqual(props.action, 'hover')) {
      toggleShowHint(true);
    }
  };

  const handleMouseLeave = () => {
    if (isEqual(props.action, 'hover')) {
      toggleShowHint(false);
    }
  };

  const handleWheel = () => {
    if (isEqual(props.action, 'hover')) {
      toggleShowHint(false);
    }
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onWheel={handleWheel}>
      <Tooltip
        className="tooltip-manage-user"
        align={props.align}
        open={isShowHint}
        css={[
          props?.customStyle,
          overriddenCss({
            notTransparent: props?.notTransparent,
          }),
        ]}
        content={
          <div
            ref={tooltipWrapperRef}
            tw="top-0 left[-1.5rem] font-size[1.6rem] line-height[1.9rem] border-radius[3px] absolute"
            className="custom-tooltip-text-container"
          >
            <Notification
              close={() => toggleShowHint(false)}
              type={props.type}
              message={props.text}
              hideCloseButton={props?.hideCloseButton}
              direction={null}
            />
          </div>
        }
      >
        <div
          tw="flex justify-center"
          className="questionsvg"
          onClick={toggleShowHint}
        >
          <QuestionSvg />
        </div>
      </Tooltip>
    </div>
  );
});

const overriddenCss = ({ notTransparent }: { notTransparent?: boolean }) => css`
  &.rmwc-tooltip {
    opacity: ${notTransparent ? 1 : 0.9} !important;
  }

  a {
    ${tw`text-blue-600 hover:(underline)`}
  }
`;
