/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import 'foundation-sites';
import 'react-reflex/styles.css';
import './publicShare.scss';

import axios from 'axios';
import { SharePublishModal } from 'components/shared/modals/SharePublishModal';
import { Switcher } from 'components/shared/Switcher';
import {
  splitterCss,
  splitterVerticalCss,
} from 'components/shared/twin.styles';
import { CaptionEditor } from 'components/VideoPlayer/Caption/CaptionEditor';
import { ParagraphEditor } from 'components/VideoPlayer/Paragraph/ParagraphEditor';
import { Chapter } from 'components/VideoPlayer/Transcription/MediaPlayer/Chapter/Chapter';
import { FindAndReplaceBar } from 'components/VideoPlayer/Transcription/MediaPlayer/Player/components/FindAndReplaceBar';
import { MediaControl } from 'components/VideoPlayer/Transcription/MediaPlayer/Player/components/MediaControl';
import { useReflex } from 'hooks/useReflex';
import $ from 'jquery';
import { isArray, isEmpty, isNil, toNumber } from 'lodash';
import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { useMeasure, useMedia, useSearchParam, useToggle } from 'react-use';
import { RootState } from 'reducers';
import { setChapter } from 'slices/chapter.slice';
import {
  setCaption,
  setInitialValue,
  setLayerCustomTerms,
  setLoadCompleted,
  setSummary,
  toggleParagraphMode,
} from 'slices/player.slice';
import { toggleFollowing } from 'slices/toggle.slice';
import {
  IMetadata,
  PublicExportOptions,
  PublicShareOptions,
} from 'utils/models';
import { SEO } from 'utils/SEO';

import { ReactComponent as Back } from '../../../assets/Icons/Back.svg';
import { ReactComponent as Replace } from '../../../assets/Icons/Find_Replace.svg';
import { ReactComponent as Right } from '../../../assets/Icons/Right.svg';
import { ReactComponent as Search } from '../../../assets/Icons/search.svg';
import {
  addLayerToLayerList,
  layerSelector,
  setLayerList,
} from '../../../slices/layers.slice';
import {
  setMediaItem,
  setMediaLength,
  setWaveform,
} from '../../../slices/media.slice';
import {
  CaptionStatus,
  HTTPStatus,
  Layers,
  MobileTabs,
} from '../../../utils/enum';
import useEventListener from '../../CustomHooks/UseEventListener';
import Loader from '../../loader/loader';
import CloseSVG from '../../UiControls/closeButtonSVG/closeSVG';
import { LayerNavigator } from '../../VideoPlayer/Transcription/MediaPlayer/Player/components/LayerNavigator';
import { SummaryEditor } from '../../VideoPlayer/Transcription/MediaPlayer/Summary/SummaryEditor';
import PublicMediaToolkit from '../../VideoPlayer/Transcription/MediaToolkitBar/PublicMediaToolKit';
import {
  cutNegativeTime,
  shouldUsePlyr,
  isPublicShared,
  mergeSameTerm,
  mergeSameTermEntities,
  normalizeSummary,
  processChapters,
  msToSec,
} from '../../VideoPlayer/Transcription/MediaUtilities';
import { setIABCategoriesS3 } from 'slices/iab.slice';
import { IABLayer } from 'components/VideoPlayer/Transcription/MediaPlayer/IABLayer/IABLayer';
import { TranscriptSkeleton } from 'components/shared/Skeleton/TranscriptSkeleton';
import { MediaService } from 'services';
import { hasTranscript } from '../../../utils/utils';
import { AdMarkerLayer } from 'components/VideoPlayer/Transcription/MediaPlayer/AdMarkerLayer/AdMarkerLayer';
import { setMidRollsBySeconds } from 'slices/admarker.slice';
import { setAdBreak } from 'slices/ad-break.slice';
import { AdBreakLayer } from 'components/VideoPlayer/Transcription/MediaPlayer/AdBreakLayer/AdBreakLayer';
import { MediaError } from 'components/shared/MediaError';
import { ITranscript } from 'utils/models/transcript.model';
import { CaptionElement } from 'types/slate-custom-types';
import { setMediaSegments } from 'slices/media-segment.slice';
import { MediaSegmentLayer } from 'components/VideoPlayer/Transcription/MediaPlayer/MediaSegmentLayer/MediaSegmentLayer';

const MediaTimeTrack = lazy(() =>
  import(
    '../../VideoPlayer/Transcription/MediaPlayer/Player/components/MediaTimeTrack'
  ).then(({ MediaTimeTrack }) => ({
    default: MediaTimeTrack,
  })),
);

export const PublicShare = (props: any) => {
  const { reflexRef, flexLeft, flexRight } = useReflex();
  const [verticalRef, { width: rightSectionWidth }] = useMeasure();

  const [mediaDetails, setMediaDetails] = useState<any>(null);
  const [jsonSrt, updateJsonSrt] = useState<any>(null);
  const mediaElement = useRef<any>(null);
  const controlTabs = useRef<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filename, setFilename] = useState<any>();
  const [openShareModal, setopenShareModal] = useState(false);
  const [, toggleFetching] = useToggle(false);

  const [currentTabs, updateCurrentTabs] = useState('Transcription');
  const [isMediaLoading, updateIsMediaLoading] = useState(true);
  const [occurrence, updateOccurrence] = useState<any>(null);
  const [occCount, updateOccCount] = useState(0);
  const [metadata, setMetadata] = useState<any>(null);
  const [isWaterMark, setIsWaterMark] = useState<boolean | undefined>();
  const [isHaveLayer, toggleLayer] = useToggle(false);

  const [isShow404, toggleShow404] = useToggle(false);

  const [shareOptions, setShareOptions] = useState<PublicShareOptions>({
    hasKeyTerm: true,
    hasSummary: true,
    hasPeople: true,
    hasProducts: true,
    hasOrgs: true,
    hasLocations: true,
    hasNationalities: true,
    hasChapter: true,
    hasGlobalList: true,
    hasAdMarkers: true,
    hasSpeakers: true,
    hasAdBreaks: false,
  });

  const [exportOptions, setExportOptions] = useState<PublicExportOptions>({
    allowMediaDownload: true,
    allowTranscriptDownload: true,
    allowCaptionDownload: false,
    haveMp3: false,
    haveMp4: false,
    isBurnedIn: false,
  });

  const layers = useSelector((state: RootState) => state.layers);
  const currentLayer = useSelector(layerSelector);
  const player = useSelector((state: RootState) => state.player);
  const toggle = useSelector((state: RootState) => state.toggle);
  const media = useSelector((state: RootState) => state.media);

  const mode = useSearchParam('mode');

  useEffect(() => {
    if (mode === 'paragraph') {
      dispatch(toggleParagraphMode(true));
    }
  }, [mode]);

  const dispatch = useDispatch();
  const latestJsonSrt = useRef(null);

  useEffect(() => {
    if (!jsonSrt) return;

    latestJsonSrt.current = jsonSrt;
  }, [jsonSrt]);

  useEffect(() => {
    const data = media.metadata;
    if (
      data?.chapters ||
      data?.entities ||
      data?.iab ||
      data?.globalList ||
      data?.keyterms ||
      data?.summaries ||
      data?.names ||
      data?.products ||
      data?.orgs ||
      data?.locations ||
      data?.nationalities
    ) {
      toggleLayer(true);
    } else {
      toggleLayer(false);
    }
  }, [media.metadata]);

  //Detect device
  const isMobilePortrait = useMedia('(max-width: 576px)');
  const isTablet = useMedia('(min-width: 640px) and (max-width: 1400px)');
  const isDesktop = useMedia('(min-width: 1200px)');
  const isPortrait = useMedia('(orientation: portrait)');

  let shareId: any;

  useEffect(() => {
    async function fetchData() {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      shareId = window.location.pathname.split('-').splice(-1);
      shareId = shareId[shareId.length - 1];
      getMediaDetails();
      setError(null);
      window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });
    }
    fetchData();
  }, [shareId]);

  const getMediaDetails = async () => {
    try {
      toggleFetching(true);
      dispatch(setLoadCompleted(false));
      const mediaData = await MediaService.getPublicDetails(shareId, true);
      setMediaDetails(mediaData);
      patchResponseAPI(mediaData.data);

      const mediaSrt = mediaData.data?.webvtt;
      updateJsonSrt(mediaSrt);

      const mEntities = mediaData?.data?.metadata?.entities ?? [];
      setMetadata({ ...mediaData.data?.metadata, entities: mEntities });

      const metadata: IMetadata = mediaData?.data.metadata;

      const options: any = {
        hasSummary: !!metadata?.summaries,
        hasChapter: !!metadata?.chapters,
        hasKeyTerm: !!metadata?.keyterms,
        hasPeople: !!metadata?.names,
        hasProducts: !!metadata?.products,
        hasOrgs: !!metadata?.orgs,
        hasLocations: !!metadata?.locations,
        hasNationalities: !!metadata?.nationalities,
        hasDownload: !!metadata?.download,
        hasGlobalList: !!metadata?.globalList,
        hasIAB: !!metadata?.iab,
        mediaid: metadata?.mediaid,
        mpx: metadata?.mediacontenttype,
        filename: metadata?.filename,
        versioncount: metadata?.versioncount,
        hasAdMarkersLayer: !!metadata?.hasAdMarkersLayer,
        hasSpeakers: !!metadata?.speakers,
      };

      setShareOptions(options);
      dispatch(setLayerList(options));

      if (!isNil(metadata?.watermark)) {
        setIsWaterMark(metadata?.watermark);
      }

      setExportOptions({
        allowMediaDownload: true,
        allowCaptionDownload: !!metadata?.captiondownload,
        allowTranscriptDownload: !!metadata?.download,
        haveMp3: metadata?.haveMp3 === true,
        haveMp4: metadata?.haveMp4 === true,
        isBurnedIn: metadata?.captionstype === 'OPEN',
      });

      mediaData &&
        mediaData.data &&
        mediaData.data.versionname &&
        setFilename(mediaData.data.versionname);
      if (mediaData) {
        setLoading(false);
      }

      showHideCaption(metadata?.captionstype);
    } catch (err: any) {
      setLoading(false);
      if (err?.response?.status === HTTPStatus.REVOKED) {
        setError('This item is no longer available for sharing.');
      } else if (err?.response?.status === HTTPStatus.SUBSCRIPTION_EXPIRED) {
        setError(
          'This URL is currently inactive. Please upgrade to activate it.',
        );
      } else {
        setError('something went wrong!');
      }
    } finally {
      toggleFetching(false);
    }
  };

  const patchResponseAPI = async (mediaData: any) => {
    dispatch(setMediaItem(mediaData));

    const jsonUrl = mediaData?.jsonfileurl;
    if (!jsonUrl) {
      toggleShow404(true);
      return;
    }

    try {
      const { data: transcriptData } = await axios.get<ITranscript>(jsonUrl);

      dispatch(setLayerCustomTerms(transcriptData?.customTerms ?? []));
      dispatch(setMediaLength(toNumber(transcriptData?.length)));

      if (!isNil(transcriptData?.ad_breaks)) {
        dispatch(addLayerToLayerList(Layers.AD_BREAK));
        dispatch(setAdBreak(transcriptData?.ad_breaks));
      }

      if (!isNil(transcriptData?.detectedSegments)) {
        dispatch(addLayerToLayerList(Layers.SEGMENT));
        dispatch(setMediaSegments(transcriptData?.detectedSegments));
      }

      const uniqKeywords = mergeSameTerm(transcriptData.keywords);
      const uniqEntities = mergeSameTermEntities(transcriptData.entities);

      dispatch(
        setInitialValue({
          keywords: uniqKeywords,
          entities: uniqEntities,
        }),
      );

      dispatch(
        setCaption({
          captions: cutNegativeTime(
            transcriptData.captions as CaptionElement[],
          ),
          rawPara: transcriptData.paragraphs,
          speakers: transcriptData?.speakers,
        }),
      );
      dispatch(
        setChapter(
          processChapters(transcriptData.chapters, transcriptData.captions),
        ),
      );
      dispatch(
        setSummary({
          summary: normalizeSummary(transcriptData.summary),
          mediaid: null,
        }),
      );

      if (transcriptData?.waveform) {
        dispatch(setWaveform(transcriptData.waveform));
      }

      if (!isEmpty(transcriptData?.iabCategories)) {
        dispatch(setIABCategoriesS3(transcriptData.iabCategories));
      }

      if (!isNil(transcriptData?.monetisation)) {
        dispatch(
          setMidRollsBySeconds({
            ...transcriptData.monetisation,
            videoDuration: msToSec(transcriptData.length),
          }),
        );
      }

      dispatch(setLoadCompleted(true));
    } catch (err: any) {
      if ([404].includes(err?.response?.status)) {
        toggleShow404(true);
      }
    }
  };

  useEffect(() => {
    showHideCaption(metadata?.captionstype);
  }, [mediaElement?.current?.textTracks]);

  const showHideCaption = (captionStatus: any) => {
    if (shouldUsePlyr()) return;

    for (let i = 0; i < mediaElement?.current?.textTracks.length; i++) {
      if (captionStatus === CaptionStatus.OPEN_CAPTION) {
        mediaElement.current.textTracks[i].mode = 'hidden';
      }

      if (captionStatus === CaptionStatus.CLOSED_CAPTION) {
        mediaElement.current.textTracks[i].mode = 'showing';
      }
    }
  };

  const currentMediaElement = mediaElement && mediaElement.current;

  const playOrPauseVideo = () => {
    if (!mediaElement.current) return;

    if (mediaElement.current.paused) {
      mediaElement.current.play();
    } else {
      mediaElement.current.pause();
    }
  };

  const mediaStartHandler = () => {
    updateIsMediaLoading(true);
  };

  const mediaCanPlayHenler = () => {
    updateIsMediaLoading(false);
  };

  const controlTabsHandler = useCallback((event) => {
    const selectedTab = event?.target?.innerText;
    updateCurrentTabs(selectedTab);

    $('.btn-tab-control').trigger('blur');
    let subs = $('.sub-menu');

    $('.sub-menu-parent').each(function () {
      const subMenu = $(this).children();
      $(this).find('.sub-menu').remove();

      setTimeout(() => {
        $(this).append(subMenu);
      }, 500);
    });

    subs.each(function (index, value: any) {
      value.style.display = 'none';

      setTimeout(() => {
        value.style.display = null;
      }, 500);
    });
  }, []);

  useEventListener('loadstart', mediaStartHandler, currentMediaElement);
  useEventListener('canplay', mediaCanPlayHenler, currentMediaElement);
  useEventListener('click', controlTabsHandler, controlTabs.current);
  // useEv

  const updateKeywordsOccurrence = (val: any, count: any) => {
    if (!val?.length) return;

    const currentKeywordTime = val[count]?.s;

    if (mediaElement?.current) {
      mediaElement.current.currentTime = currentKeywordTime;
    }

    updateOccurrence(val);
    updateOccCount(count);
  };

  const setKeywordOccurrences = (way: any) => {
    if (way === 'forward' && occCount < occurrence.length - 1) {
      updateKeywordsOccurrence(occurrence, occCount + 1);
    } else {
      if (occCount > 0) {
        updateKeywordsOccurrence(occurrence, occCount - 1);
      }
    }
  };

  const handleClose = () => {
    setError(null);
  };

  const handleCloseModal = () => {
    setopenShareModal(false);
  };

  const getVideoFlexRatio = () => {
    // Default height
    if (!rightSectionWidth || isMediaLoading) return 400;
    const ratio = media?.metadata?.ratio;

    // HEIGHT by RATIO + 42px of control bar
    return rightSectionWidth * (ratio || 0.5625) + 42;
  };

  const SummaryMetadata = () => {
    return (
      <div className="flex-container align-center pt20 disabled">
        <button className="btn-primary large w-unset">
          Regenerate Summary
        </button>
      </div>
    );
  };

  const handleBackToHome = () => {
    window.open('https://sonnant.com');
  };

  return (
    <>
      {filename && (
        <SEO
          title={filename?.concat(' | Sonnant AI')}
          description={filename}
          thumbnail={media?.metadata?.thumbnail!}
        />
      )}
      {loading && (
        <div className="loader__component">{loading ? <Loader /> : ''}</div>
      )}
      {error && (
        <p className="topMessage error">
          {error} <CloseSVG name={'error'} close={handleClose} />
        </p>
      )}
      {/* {(isTabletPortrait || isTabletLandscape || isDesktop) && (
        <> */}
      <div tw="sm-down:hidden">
        <PublicMediaToolkit
          shareOptions={shareOptions}
          exportOptions={exportOptions}
          editMode={false}
          jsonSrt={jsonSrt}
          filename={props.filename}
          PlayOrPauseVideo={playOrPauseVideo}
        />
      </div>

      {isShow404 && (
        <MediaError
          statusCode={HTTPStatus.NOT_FOUND}
          onClickReturn={handleBackToHome}
          returnButtonText="Back to homepage"
        />
      )}

      {!isShow404 && (
        <div className="container fluid publicPlayer mediaPlayer_grid_aign public_media">
          <div className="tabs-content" data-tabs-content="collapsing-tabs">
            <div className="tabs-panel is-active" id="transcription" tw="p-0">
              <div ref={reflexRef as any}>
                <ReflexContainer
                  orientation="vertical"
                  windowResizeAware={true}
                  tw="sm-down:flex-col-reverse! sm-down:height[calc(100vh - 5rem)]"
                >
                  <ReflexElement
                    className="left-pane"
                    flex={!isMobilePortrait ? flexLeft.grow : 0}
                    minSize={!isMobilePortrait ? flexLeft.minWidth : undefined}
                    tw="sm-down:flex-1! sm-down:overflow-hidden!"
                  >
                    <div className="pane-content">
                      <div
                        className="video_mgn"
                        tw="mt-0 pt-0! ml-3! sm-down:(pb-0)!"
                      >
                        {currentTabs === MobileTabs.KEY_TERM && (
                          <div
                            tw="flex-1 sm-up:display[none]"
                            className="share-container"
                          >
                            <LayerNavigator
                              isPublicPlayer={true}
                              isEditing={false}
                            />
                          </div>
                        )}

                        <div
                          className="transcript-auto-scroll"
                          tw="sm-down:(height[calc(100vh - 45rem)])"
                          css={[currentLayer.isSummary && tw`overflow-hidden!`]}
                        >
                          <div
                            tw="sticky! w-full! top-0! z-index[2]"
                            css={['transition: all 0.5s ease']}
                          >
                            <FindAndReplaceBar />
                          </div>

                          {(hasTranscript(layers.currentLayer) ||
                            // hard code for chapter layer
                            [Layers.CHAPTER, Layers.CUSTOM_TERM].includes(
                              layers.currentLayer,
                            )) &&
                            !player.isParagraphMode &&
                            !isEmpty(player?.caption) &&
                            (currentTabs === MobileTabs.TRANSCRIPT ||
                              currentTabs === MobileTabs.KEY_TERM) && (
                              <CaptionEditor />
                            )}

                          {hasTranscript(layers.currentLayer) &&
                            player.isParagraphMode &&
                            !isEmpty(player.paragraph) &&
                            (currentTabs === MobileTabs.TRANSCRIPT ||
                              currentTabs === MobileTabs.KEY_TERM) && (
                              <ParagraphEditor />
                            )}

                          {/* SUMMARY METADATA LAYER */}
                          {layers.currentLayer === Layers.SUMMARY &&
                            mediaDetails && (
                              <SummaryEditor baseMediaid={shareId} />
                            )}

                          {currentTabs === MobileTabs.SUMMARY && (
                            <div tw="overflow-y-auto text-16 px-10">
                              <SummaryEditor baseMediaid={shareId} />
                            </div>
                          )}

                          {player.isProcessingData &&
                            !currentLayer.isSummary && (
                              <TranscriptSkeleton count={10} tw="relative" />
                            )}

                          {!player.isProcessingData &&
                            isArray(player?.caption) &&
                            !currentLayer.isSummary &&
                            player.caption.length === 0 && (
                              <div tw="font-medium text-sonnant-grey-6 text-16 mt-5">
                                There are no transcripts for this content item
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </ReflexElement>

                  <ReflexSplitter
                    tw="(height[calc(100vh - 20rem)] sm-down:hidden border-2 border-white bg-transparent z-index[unset] border-r-0 border-width[3.5px] hover:border-sonnant-purple-2 active:border-sonnant-purple-2)!"
                    css={splitterVerticalCss}
                  />
                  <div
                    tw="sm-up:hidden"
                    className={
                      isPortrait
                        ? 'flex-container share-container flex-dir-column'
                        : 'flex-container share-container'
                    }
                  >
                    <div className="flex-container flex-dir-column">
                      <div
                        className="btn-group"
                        tw="sm-down:(padding[1rem 2px])!"
                      >
                        <a className="button disabled">
                          <Search />
                        </a>
                        <a className="button disabled">
                          <Replace />
                        </a>
                        <div className="btn-group" tw="flex flex-nowrap">
                          <a
                            className="button disabled"
                            onClick={() => setKeywordOccurrences('backword')}
                          >
                            <Back />
                          </a>
                          <a
                            className="button disabled"
                            onClick={() => setKeywordOccurrences('forward')}
                          >
                            <Right />
                          </a>
                        </div>

                        <Switcher
                          label="Follow"
                          title="Scroll transcript with play"
                          checked={toggle.isFollowing}
                          onCheckedChange={(isOn) =>
                            dispatch(toggleFollowing(isOn))
                          }
                        />

                        <div className="dropdown-tabs btn-tab-control sub-menu-parent">
                          {currentTabs} <span className="arrow-down"></span>
                          <ul className="sub-menu" ref={controlTabs}>
                            <li
                              value="transcription"
                              onClick={() =>
                                dispatch(toggleParagraphMode(false))
                              }
                            >
                              Transcription
                            </li>
                            <li
                              value="paragraph"
                              onClick={() =>
                                dispatch(toggleParagraphMode(true))
                              }
                            >
                              Paragraph
                            </li>
                            <li value="key-terms">Key Terms</li>
                            <li value="summary">Summary</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ReflexElement
                    className="right-pane"
                    tw="overflow-visible!"
                    flex={!isMobilePortrait ? flexRight.grow : 0}
                    minSize={!isMobilePortrait ? flexRight.minWidth : undefined}
                  >
                    <div
                      className="video-container"
                      tw="flex flex-col pt-3 px-1"
                      ref={verticalRef as any}
                    >
                      <ReflexContainer>
                        <ReflexElement
                          // 42 is height of control bar
                          minSize={
                            isMediaLoading ? 350 : toggle.isMinimise ? 42 : 100
                          }
                          maxSize={
                            !isMediaLoading && toggle.isMinimise
                              ? 42
                              : undefined
                          }
                          size={toggle.isMinimise ? 42 : getVideoFlexRatio()}
                        >
                          {/* ENSURE PASS CORRECT TYPE OF Watermark */}
                          {!isNil(isWaterMark) && (
                            // Height on phone: 288px
                            <div css={customMediaControlStyle}>
                              <MediaControl
                                ref={mediaElement}
                                hasWatermark={isWaterMark}
                                isMediaLoading={isMediaLoading}
                              />
                            </div>
                          )}
                        </ReflexElement>

                        <ReflexSplitter
                          tw="(my-1 border-white bg-transparent z-index[unset] margin-top[3px] rounded-lg border-width[4px] border-bottom[none] hover:(border-sonnant-purple-2 shadow) active:border-sonnant-purple-2)! sm-down:hidden"
                          css={splitterCss}
                          style={{ height: '0 !important' }}
                          // @ts-ignore
                          id="splitter-css"
                        />

                        <ReflexElement
                          minSize={150}
                          flex={1}
                          tw="flex flex-col overflow-hidden!"
                        >
                          {/* height on phone: 68px */}
                          <Suspense fallback={null}>
                            <div css={customMediaTimeTrackStyle}>
                              <MediaTimeTrack isDisabled={isMediaLoading} />
                            </div>
                          </Suspense>

                          {isHaveLayer && (isDesktop || isTablet) && (
                            <LayerNavigator
                              isPublicPlayer={true}
                              isEditing={false}
                            />
                          )}

                          {currentLayer.isChapter && (
                            <Chapter isReadOnly={true} />
                          )}

                          {currentLayer.isAdMarker && <AdMarkerLayer />}

                          {currentLayer.isAdBreak && <AdBreakLayer />}

                          {currentLayer.isSegment && <MediaSegmentLayer />}

                          {currentLayer.isSummary && !isPublicShared() && (
                            <SummaryMetadata />
                          )}

                          {currentLayer.isIAB && <IABLayer />}
                        </ReflexElement>
                      </ReflexContainer>
                    </div>
                  </ReflexElement>
                </ReflexContainer>
              </div>
            </div>
          </div>
        </div>
      )}

      <SharePublishModal
        openModal={openShareModal}
        handleModalClose={handleCloseModal}
        shareURL={window.location.href}
      />
    </>
  );
  // }
};

const customMediaTimeTrackStyle = css`
  /* ${tw`sm-down:overflow-y-auto!`} */

  .controlBlock {
    ${tw`sm-down:block! sm-down:relative overflow-hidden`}
  }

  .controlBlock > div {
    ${tw`sm-down:flex-col-reverse!`}
  }

  .controlBlock .progress_section {
    ${tw`sm-down:mb-4!`}
  }

  .controlBlock .progress_section wave {
    ${tw`sm-down:h-12!`}
  }

  .controlBlock .btn-follow {
    ${tw`sm-down:display[none]`}
  }

  .bnt-share {
    ${tw`sm-up:display[none]`}

    svg {
      path {
        fill: #ffffff;
      }
    }
  }
`;

const customMediaControlStyle = css`
  height: 100%;
  .external_ctrols {
    ${tw`sm-down:static`}
  }
`;
