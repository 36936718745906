/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import MegaphoneLogoPNG from 'assets/Images/integrate-megaphone.jpg';
import OmnyLogoPNG from 'assets/Images/integrate-omny.png';

import { AxiosError, AxiosResponse } from 'axios';
import { first, isEmpty } from 'lodash';
import React, {
  ReactElement,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useToggle } from 'react-use';

import { Modal } from 'components/UiControls/modal/modal';
import { isCollectionPage } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { RootState } from 'reducers';
import { LibraryService } from 'services';
import { IntegrationService } from 'services/integration.service';
import { HTTPStatus, MediaProvider, ModalOptions } from 'utils/enum';
import { LibraryStatistics } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { Hint } from '../Hint';
import { AdvancedIntegrationSettings } from './AdvancedIntegrationSettings';
import * as actions from '../../../actions';

interface IPromiseParams {
  resolve: (option: ModalOptions) => void;
  reject: (err: any) => void;
}

interface Props {
  mediaids: string[];
  providers: MediaProvider[] | undefined;
}

export const IntegrationModal = forwardRef(
  (props: Props, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const dispatch = useDispatch();

    const params = useParams<{ id: string }>();
    const omnySettings = useSelector((state: RootState) => state.omny.settings);
    const megaphoneSettings = useSelector(
      (state: RootState) => state.megaphone.settings,
    );

    const [isOpen, setIsOpen] = useState(false);
    const [collectionIdList, setCollectionIdList] = useState<string[]>([]);
    const [isValidating, toggleValidating] = useToggle(false);

    const isDisabled =
      !props?.providers?.includes(MediaProvider.OMNY) || // returned from Omny
      megaphoneSettings.every((i) => isEmpty(i.apiKey)) || // configured API Key`
      omnySettings.every((i) => isEmpty(i.webhookSecret)); // configured Webhook Secret

    const isDisabledMegaphone =
      !props?.providers?.includes(MediaProvider.MEGAPHONE) || // returned from Megaphone
      megaphoneSettings.every((i) => isEmpty(i.apiKey)); // configured API Key`

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (payload: {
      collectionIds: string[];
    }): Promise<unknown> => {
      if (payload?.collectionIds && !isEmpty(payload.collectionIds)) {
        setCollectionIdList(payload.collectionIds);
      }

      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const handleReset = () => {
      setCollectionIdList([]);
    };

    const hideModal = () => {
      setIsOpen(false);

      dispatch(actions.setFocusRow(null));

      handleReset();
    };

    // const handleYes = () => {
    //   hideModal();
    //   promiseInfo.current?.resolve(ModalOptions.YES);
    // };

    // const handleCancel = () => {
    //   hideModal();
    //   promiseInfo.current?.resolve(ModalOptions.CANCEL);
    // };

    const getTotalCollectionItemAsync = async (
      collectionId: string | undefined,
    ) => {
      try {
        toggleValidating(true);
        customToast.loading('Validating...');

        const { data }: AxiosResponse<{ total: LibraryStatistics }> =
          await LibraryService.getLibraryItems({
            page_size: 0,
            collection_id: collectionId,
          });

        return data?.total?.totalFiles;
      } catch (error) {
        console.log('error :>> ', error);

        return Promise.reject(error);
      } finally {
        toggleValidating(false);
        customToast.dismiss();
      }
    };

    const handleClickCms = async (cmsProvider: MediaProvider) => {
      const collectionId = first(collectionIdList);

      const totalItemsCollection = await getTotalCollectionItemAsync(
        collectionId,
      );

      if (
        (isEmpty(props.mediaids) && isEmpty(collectionIdList)) ||
        totalItemsCollection === 0
      ) {
        customToast.error(
          'Empty selected items for integration. Please check and try again!',
        );
        return;
      }

      hideModal();

      customToast.loading('Sending request');

      try {
        const response = await IntegrationService.integrate({
          media_ids: props.mediaids,
          service: cmsProvider,
          collection_ids:
            isCollectionPage() && isEmpty(collectionIdList)
              ? [params.id]
              : collectionIdList,
        });
        switch (response?.status) {
          case HTTPStatus.SUCCESS:
            customToast.success('Submitted to background processing');
            break;
          default:
            customToast.error(response?.data);
            break;
        }
      } catch (error: any) {
        const statusCode = (error as AxiosError).response?.status;

        if (statusCode === HTTPStatus.BAD_REQUEST) {
          customToast.error(
            isEmpty(collectionIdList)
              ? 'Invalid integration items. Please check and try again.'
              : 'Collection does not contains any item from CMS. Please check and try again.',
          );
        } else {
          customToast.error(
            'A valid integration link could not be found for this content item.',
          );
        }
      }
    };

    return (
      <Modal
        show={isOpen}
        modalClosed={() => {
          hideModal();
          promiseInfo.current?.resolve(ModalOptions.CLOSE);
        }}
      >
        <div>
          <h1 tw="font-semibold text-sonnant-dark font-size[3.5rem]">
            Integrate
          </h1>

          <div tw="flex justify-between items-end mt-6 mb-1">
            <div>
              <Hint
                text={
                  isDisabled
                    ? 'To enable Omny Studio add the API Key and enable on the integration preferences page.  Integration is only available for content items that were uploaded directly from Omny Studio.'
                    : 'Pressing the Omny Studio Integration button will update the content item in Omny Studio with the metadata from Sonnant for the latest saved version of this item.  This will include the Summary, Chapters and Ad Markers.'
                }
                notTransparent
                arrow
                align="bottom"
              >
                <div tw="inline-block">
                  <button
                    tw="min-height[4.8rem] min-width[18rem]"
                    css={[omnyButtonCss]}
                    onClick={() => handleClickCms(MediaProvider.OMNY)}
                    disabled={isDisabled || isValidating}
                  >
                    <img src={OmnyLogoPNG} alt="Omny Integration" />
                  </button>
                </div>
              </Hint>
            </div>

            <div>
              <Hint
                text={
                  isDisabled
                    ? 'To enable Megaphone, add the API Key and enable on the integration preferences page. Integration is only available for content items that were uploaded directly from Megaphone.'
                    : 'Pressing the Megaphone Integration button will update the content item in Megaphone with the metadata from Sonnant for the latest saved version of this item.'
                }
                notTransparent
                arrow
                align="bottom"
              >
                <div tw="inline-block">
                  <button
                    tw="min-h-[4.8rem] min-w-[18rem]"
                    css={[megaphoneButtonCss]}
                    onClick={() => handleClickCms(MediaProvider.MEGAPHONE)}
                    disabled={isDisabledMegaphone || isValidating}
                  >
                    <img src={MegaphoneLogoPNG} alt="Megaphone Integration" />
                  </button>
                </div>
              </Hint>
            </div>

            <div tw="min-h-[4.8rem] min-w-[18rem]"></div>
          </div>

          {!isEmpty(collectionIdList) && (
            <AdvancedIntegrationSettings
              collectionIds={collectionIdList}
              hideModal={hideModal}
            />
          )}
        </div>
      </Modal>
    );
  },
);

const integrationButtonCss = tw`max-width[18rem] rounded-lg overflow-hidden py-3 px-5 shadow-lg hover:(shadow-xl cursor-pointer)`;

const omnyButtonCss = css`
  ${integrationButtonCss}

  &, :active, :disabled {
    ${tw`(background-color[#8242ca])!`}
  }
`;

const megaphoneButtonCss = css`
  ${integrationButtonCss}

  &, :active, :disabled {
    ${tw`(bg-[#5825fe])!`}
  }
`;
