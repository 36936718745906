/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { theme } from 'twin.macro';

import { isNil } from 'lodash';
import { ReactElement } from 'react';
import Select, {
  GroupBase,
  SingleValue,
  StylesConfig,
  ThemeConfig,
} from 'react-select';
import { useToggle } from 'react-use';
import { Option, defaultStyles } from '../CustomSelectSearch';

type Props = {
  children: ReactElement;
  options: Option[];
  onSelect: (option: Option) => void;

  noOptionsMessage?: string;
  searchValue?: string;
  isLoading?: boolean;
  hidden?: boolean;
};

export const DropdownSelectionWrapper = ({
  options,
  searchValue = '',
  noOptionsMessage = 'No results found',
  isLoading,
  hidden = false,
  ...props
}: Props) => {
  const [isOpen, toggleOpen] = useToggle(false);

  const handleChangeOption = (selectedOption: SingleValue<Option>) => {
    if (isNil(selectedOption)) return;

    props.onSelect(selectedOption);

    toggleOpen(false);
  };

  const openMenu = () => {
    if (isOpen) return;

    toggleOpen(true);
  };

  const closeMenu = () => {
    if (!isOpen) return;

    toggleOpen(false);
  };

  if (hidden) return <>{props.children}</>;

  return (
    <div onClick={openMenu} onFocus={openMenu} onBlur={closeMenu}>
      {props.children}

      <div tw="text-14">
        <Select
          onMenuOpen={() => {}}
          onMenuClose={() => {}}
          menuIsOpen={isOpen}
          isLoading={isLoading}
          isSearchable={true}
          closeMenuOnSelect
          theme={themeFn}
          styles={customStyles}
          options={options}
          onChange={handleChangeOption}
          inputValue={searchValue}
          noOptionsMessage={() => noOptionsMessage}
        />
      </div>
    </div>
  );
};

const themeFn: ThemeConfig = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#DEEBFF',
  },
});

const customStyles: StylesConfig<Option, false, GroupBase<Option>> = {
  ...defaultStyles,
  container: (base) => ({
    ...base,
    height: '0',
  }),
  menu: (base) => ({
    ...base,
    marginTop: '4px',
  }),
  control: (base) => ({
    ...base,
    display: 'none',
  }),
  option: (base) => ({
    ...base,
    cursor: 'pointer',
    padding: '7px 12px',
    color: theme`colors.sonnant-dark`,
    ':nth-child(even)': {
      backgroundColor: 'rgb(240, 243, 246)',
    },
    ':hover': {
      backgroundColor: '#DEEBFF',
    },
  }),
};
