/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { ReactComponent as FindSvg } from 'assets/Icons/find-and-replace-find.svg';
import { ReactComponent as FindNextSvg } from 'assets/Icons/find-and-replace-next.svg';
import { ReactComponent as FindPrevSvg } from 'assets/Icons/find-and-replace-previous.svg';
import { ReactComponent as FindCancelSvg } from 'assets/Icons/find-cancel.svg';
import { ReactComponent as FindOptionsSvg } from 'assets/Icons/find-overflow.svg';
import { FindAndReplaceModal } from 'components/shared/FindAndReplaceModal/FindAndReplaceModal';
import {
  findRangeByText,
  isPublicShared,
  jumpToRangeNth,
  removeSearchQuery,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { debounce, isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearReplaceAll,
  replaceSingleRange,
  resetFinder,
  resetPlayer,
  setFinderOption,
  setPresentOccId,
  setSearchRanges,
  setTextSearch,
} from 'slices/player.slice';
import { toggleFinder, toggleFinderModal } from 'slices/toggle.slice';
import {
  Keys,
  Layers,
  ModalOptions,
  ReplaceMode,
  SearchType,
} from 'utils/enum';

import { CollapsibleList } from '@rmwc/list';

import { RootState } from '../../../../../../reducers';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { useSearchParam, useUnmount } from 'react-use';
import { useHistory } from 'react-router';
import { Transforms } from 'slate';
import { customToast } from 'utils/toast.util';
import { HintRestrictedEdit } from 'components/shared/HintRestrictedEdit';

const wrapperStyles = css`
  ${tw`w-full text-2xl background[#7F8090] height[7rem] text-white rounded-b-2xl px-10`}

  svg {
    :hover,
    :active,
    :focus {
      ${tw`cursor-pointer`}
      -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
      filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.7));
    }
  }
`;

const finderInputStyles = css`
  &,
  &:hover,
  &:active,
  &:focus {
    ${tw`(flex-1 border-none outline-none text-white px-3 bg-transparent cursor-pointer lg:max-width[12rem] md-down:(width[6rem]))!`}
  }
  &::placeholder {
    ${tw`color[#bfbfc7]!`}
  }
`;

interface IProps {
  enableEditMode?: () => unknown;
}

export const FindAndReplaceBar = (props: IProps) => {
  const [searchUrl, setSearchUrl] = useState(useSearchParam('search'));

  const player = useSelector((state: RootState) => state.player);
  const toggle = useSelector((state: RootState) => state.toggle);
  const advancedSearch = useSelector(
    (state: RootState) => state.advancedSearch,
  );
  const layers = useSelector((state: RootState) => state.layers);

  const { finderOptions, isParagraphMode } = player;
  const { presentOccId } = finderOptions;
  const totalFound = finderOptions?.searchRanges?.length ?? 0;

  const isEditing = toggle.isEditMode;

  const dispatch = useDispatch();
  const history = useHistory();

  const replaceModalRef = useRef<any>(null);
  const confirmModalRef = useRef<any>(null);

  useEffect(() => {
    return () => {
      if (!isPublicShared()) {
        dispatch(resetPlayer());
      }
    };
  }, []);

  useEffect(() => {
    if (
      [
        Layers.SUMMARY,
        Layers.CUSTOM_TERM,
        Layers.CHAPTER,
        Layers.AD_MARKER,
      ].includes(layers.currentLayer)
    ) {
      resetSearchBar();
    }
  }, [layers.currentLayer]);

  // CLEAN UP DATA
  useUnmount(() => {
    resetSearchBar();
  });

  useEffect(() => {
    const { textSearch, isMatchCase, isWholeWords } = { ...finderOptions };
    const ranges = findRangeByText(textSearch, {
      isMatchCase: !!isMatchCase,
      isWholeWords: !!isWholeWords,
    });
    dispatch(setSearchRanges(ranges));

    if (ranges.length > 0) {
      dispatch(setPresentOccId(1));
      jumpToRangeNth(ranges, 0);
    } else {
      dispatch(setPresentOccId(0));
    }
  }, [
    finderOptions?.textSearch,
    finderOptions.isMatchCase,
    finderOptions.isWholeWords,
    isParagraphMode,
  ]);

  useEffect(() => {
    if (!presentOccId || presentOccId > totalFound) return;

    jumpToRangeNth(finderOptions.searchRanges, presentOccId);
  }, [presentOccId, totalFound]);

  // REPLACE LOGIC HERE
  const handleReplaceModal = async () => {
    if (!isEditing) {
      const result = await confirmModalRef.current?.show();

      if (result === ModalOptions.YES) {
        props?.enableEditMode?.();
      } else {
        return;
      }
    }

    dispatch(toggleFinderModal());
    const modalResult = await replaceModalRef.current?.show();
    if (modalResult.option === ModalOptions.YES) {
      let textReplace = modalResult.textToReplace;
      if (modalResult.mode === ReplaceMode.SINGLE) {
        const currentRange = finderOptions.searchRanges[presentOccId - 1];
        if (currentRange) {
          Transforms.insertText(window.Editor, textReplace, {
            at: currentRange,
          });
          dispatch(replaceSingleRange(currentRange));
        }
      } else {
        // replace all
        finderOptions?.searchRanges?.forEach((range) => {
          Transforms.insertText(window.Editor, textReplace, {
            at: range,
          });
        });
        dispatch(clearReplaceAll());
      }
      customToast.success('Replaced successfully');
    }
  };

  const handleFindKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
      handleFindNext();
    }

    if (e.key === Keys.ESC) {
      closeInput();
    }
  };

  const closeInput = () => {
    const url = removeSearchQuery('search');
    history.replace(url);
    dispatch(toggleFinder(null));
  };

  const hasPrev = (): boolean => presentOccId > 1;

  const hasNext = (): boolean => presentOccId < totalFound;

  const handleFindPrev = () => {
    if (!hasPrev()) return;

    dispatch(setPresentOccId(presentOccId - 1));
  };

  const handleFindNext = () => {
    if (!hasNext()) return;

    dispatch(setPresentOccId(presentOccId + 1));
  };

  const inputRef = useRef<any>(null);

  const handleCloseFindBar = () => {
    inputRef.current.value = '';
    dispatch(resetFinder());
  };

  const handleToggleOptions = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;

    dispatch(
      setFinderOption({
        ...finderOptions,
        [e.target.name]: value,
      }),
    );
  };

  const handleTextChange = (
    e: React.ChangeEvent<HTMLInputElement> | string,
  ) => {
    let value = e;
    if (typeof e !== 'string') {
      value = e.target.value.trim();
    }
    if (!value || value !== finderOptions.textSearch) {
      dispatch(setPresentOccId(0));
    }
    dispatch(setTextSearch(value));
  };

  useEffect(() => {
    if (
      !searchUrl ||
      isEmpty(searchUrl?.trim()) ||
      advancedSearch.searchParams.searchBy === SearchType.TITLE
    )
      return;

    inputRef.current.value = searchUrl;
    dispatch(toggleFinder(true));

    // Check transcript is loaded
    if (isEmpty(window?.Editor?.children) || !player.loadCompleted) return;

    const timer = setTimeout(() => {
      handleTextChange(searchUrl);
      setSearchUrl(null);
      timer && clearTimeout(timer);
    }, 300);
  }, [searchUrl, window?.Editor?.children, player.loadCompleted]);

  const resetSearchBar = () => {
    dispatch(clearReplaceAll());
    dispatch(toggleFinder(false));
    dispatch(setTextSearch(''));
  };

  return (
    <>
      <CollapsibleList
        handle={<></>}
        open={toggle.isShowFinder}
        onOpen={() => inputRef.current.focus()}
        onClose={handleCloseFindBar}
      >
        <div
          css={wrapperStyles}
          tw="flex lg-up:justify-between items-center md-down:(py-0 px-1.5)"
        >
          <div tw="flex items-center flex-1 lg-up:gap-1 lg:max-width[12.5rem] md-down:(width[7rem]) ">
            <FindSvg tw="min-width[2rem]" />
            <input
              ref={inputRef}
              onChange={debounce(handleTextChange, 500)}
              onKeyDown={handleFindKeyDown}
              css={finderInputStyles}
              placeholder="Find..."
            />
          </div>
          <div tw="flex items-center gap-3 select-none">
            <div tw="flex lg-down:flex-col">
              <label tw="flex items-center cursor-pointer mr-6">
                <input
                  name="isMatchCase"
                  checked={finderOptions.isMatchCase ?? false}
                  onChange={handleToggleOptions}
                  type="checkbox"
                />
                <span className="checkmark" tw="margin-top[-0.175rem]"></span>

                <div tw="text-white opacity-70 font-normal whitespace-nowrap">
                  Match Case
                </div>
              </label>
              <label tw="flex items-center cursor-pointer text-white">
                <input
                  name="isWholeWords"
                  checked={finderOptions.isWholeWords ?? false}
                  onChange={handleToggleOptions}
                  type="checkbox"
                />
                <span className="checkmark" tw="margin-top[-0.175rem]"></span>

                <div tw="text-white opacity-70 font-normal whitespace-nowrap">
                  Whole Words
                </div>
              </label>
            </div>
            <div tw="flex flex-nowrap items-center">
              <div tw="flex md-down:flex-col md-up:gap-2 items-center">
                <div tw="whitespace-nowrap md-down:font-size[15px] margin-bottom[-1px]">
                  {presentOccId} of {totalFound}
                </div>
                <div tw="flex sm-down:flex-row ">
                  <FindPrevSvg
                    onClick={handleFindPrev}
                    css={!hasPrev() && tw`opacity-50`}
                  />
                  <FindNextSvg
                    onClick={handleFindNext}
                    css={!hasNext() && tw`opacity-50`}
                  />
                </div>
              </div>
              {!isPublicShared() && (
                <HintRestrictedEdit>
                  <FindOptionsSvg
                    onClick={handleReplaceModal}
                    css={!isEditing && tw`opacity-50`}
                  />
                </HintRestrictedEdit>
              )}
            </div>
            <FindCancelSvg onClick={closeInput} />
          </div>
        </div>
      </CollapsibleList>

      <FindAndReplaceModal
        ref={replaceModalRef}
        textToFind={finderOptions.textSearch}
      />

      <ConfirmModal
        ref={confirmModalRef}
        title="Confirm"
        message="Edit mode is off, would you like to enter edit mode?"
        confirmText="Confirm"
      />
    </>
  );
};
