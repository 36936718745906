/** @jsxImportSource @emotion/react */
import 'twin.macro';

import {
  ComputedDatum,
  ResponsiveCirclePacking,
} from '@nivo/circle-packing';
import { isEmpty, round, toNumber } from 'lodash';
import { useState } from 'react';

export type NestedCircleChartData = {
  name: string;

  color?: string;
  percentage?: number;

  numberEpisodes?: number;
  top5PrimaryTerms?: string[];
  top5SecondaryTerms?: string[];

  children?: NestedCircleChartData[];
};

type Props = {
  chartData: NestedCircleChartData;
};

const NORMAL_FONT_SIZE = 13;
const ZOOMED_FONT_SIZE = 24;

export const CircleParkingChart = ({ chartData }: Props) => {
  const [zoomedId, setZoomedId] = useState<string | null>(null);

  const dynamicFontSize = isEmpty(zoomedId)
    ? NORMAL_FONT_SIZE
    : ZOOMED_FONT_SIZE;

  const tooltipComponentFn = (item: ComputedDatum<NestedCircleChartData>) => {
    if (item.depth === 0) return <></>;

    return (
      <div tw="text-sonnant-grey-6 bg-sonnant-grey-2 shadow-2xl rounded px-3 py-1.5 text-15">
        <div>
          {item.id}:{' '}
          <strong>{round(toNumber(item.data.percentage), 2)}%</strong>
        </div>
        <div>
          Number of episodes: <strong>{item.data.numberEpisodes}</strong>
        </div>
        {/* <div>
          Top 5 primary layer terms:{' '}
          <strong>{item.data.top5PrimaryTerms.join(', ')}</strong>
        </div>
        <div>
          Top 5 primary secondary terms:{' '}
          <strong>{item.data.top5SecondaryTerms.join(', ')}</strong>
        </div> */}
      </div>
    );
  };

  const truncateLabelFn = (node: ComputedDatum<NestedCircleChartData>) => {
    // Width of each char ~35% of font-size
    const charCount = node.radius / (0.35 * dynamicFontSize);
    const truncatedName = node.id.slice(0, charCount).trim();

    return `${truncatedName}${node.id.length > charCount ? '...' : ''} `;
  };

  return (
    <ResponsiveCirclePacking
      data={chartData}
      value="percentage"
      id="name"
      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
      zoomedId={zoomedId}
      leavesOnly={true}
      onClick={(node) => {
        setZoomedId(zoomedId === node.id ? null : node.id);
      }}
      colors={{ scheme: 'nivo' }}
      childColor={{
        from: 'color',
        modifiers: [['brighter', 0.4]],
      }}
      colorBy="id"
      padding={20}
      motionConfig="default"
      enableLabels={true}
      tooltip={tooltipComponentFn}
      label={truncateLabelFn}
      labelsFilter={(n) => n.node.depth === 1}
      labelsSkipRadius={10}
      theme={{
        fontSize: dynamicFontSize,
        labels: {
          text: {
            fontWeight: 500,
          },
        },
      }}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 2.8]],
      }}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.5]],
      }}
      defs={[
        {
          id: 'lines',
          type: 'patternLines',
          background: 'none',
          color: 'inherit',
          rotation: -45,
          lineWidth: 5,
          spacing: 8,
        },
      ]}
    />
  );
};
