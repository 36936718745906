/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { hoverDropShadow, noneBorder } from './twin.styles';
import CloseSVG from 'components/UiControls/closeButtonSVG/closeSVG';
import { ReactComponent as SearchSvg } from 'assets/Icons/search.svg';
import { debounce } from 'lodash';

interface Props {
  placeholder?: string;
  setTerm: (term: string) => unknown;
  initialValue?: string;
  debounceTime?: number;
  noShrink?: boolean;
  autoFocus?: boolean;
  isSearchTenantLogin?: boolean;
}

export const SearchInput = (props: Props) => {
  const [searchTerm, setSearchTerm] = useState(props?.initialValue ?? '');

  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (props?.initialValue) {
      inputRef.current.value = props?.initialValue;
    }
  }, [props?.initialValue]);

  const handleChange = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      const term = e.target.value;

      setSearchTerm(term);

      // Parent state
      props?.setTerm?.(term);
    }, props?.debounceTime ?? 200),
    [],
  );

  const handleReset = () => {
    inputRef.current.value = '';
    props?.setTerm?.('');
    setSearchTerm('');
  };

  return (
    <div
      className="search-container"
      tw="flex"
      css={[props.noShrink && tw`w-full`]}
    >
      <form tw="flex flex-1 relative" autoComplete={'off'} onSubmit={e => e.preventDefault()}>
        <input
          ref={inputRef}
          className="input-group-field input-search"
          tw="(min-width[12.5rem] height[4rem] rounded-r-none rounded-l pl-4 pr-2 font-size[1.5rem] caret-color[#7F8090] placeholder:(text-13))!"
          css={[
            noneBorder,
            // !props?.noShrink && tw`md-down:(max-width[10rem])!`,
            props?.isSearchTenantLogin && tw`placeholder:(text-11)!`,
          ]}
          type="input"
          placeholder={props?.placeholder}
          onChange={handleChange}
          autoFocus={!!props?.autoFocus}
        />
      </form>

      <button
        type="button"
        css={[
          props?.isSearchTenantLogin
            ? tw`(p-2 rounded-l-none mb-0 rounded-r focus:(bg-white) bg-white  hover:(border[2px solid #e3dcdc] background-color[#e3dcdc]) box-shadow[none])!`
            : tw`(rounded-l-none height[4rem] width[4rem] mb-0 rounded-r focus:(bg-white) hover:(border[2px solid #F0F3F6]) box-shadow[none])!`,
        ]}
        className="button btn-secondary"
      >
        {searchTerm ? (
          <CloseSVG
            close={handleReset}
            color="#7F8090"
            css={[
              hoverDropShadow(0.2),
              props?.isSearchTenantLogin && tw`(width[1.3rem] height[1.3rem])`,
            ]}
          />
        ) : (
          <SearchSvg
            css={[
              hoverDropShadow(0.2),
              props?.isSearchTenantLogin && tw`(width[1.3rem] height[1.3rem])`,
            ]}
          />
        )}
      </button>
    </div>
  );
};
