import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getDomainWithoutSubdomain, hasSubdomain } from 'utils/utils';

export const PublicRoute = ({ component: Component, path, ...rest }: any) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) => {
        if (hasSubdomain()) {
          if (['/signin', '/confirmpassword'].includes(path)) {
            let url = window.location.origin;

            // Contain unsubscribe params from URL
            if (window.location.search.includes('unsubscribe-revival')) {
              url += `/preferences${window.location.search}${window.location.hash}`;
            }

            window.location.replace(
              `${
                window.location.protocol
              }//${getDomainWithoutSubdomain()}${path}?next=${encodeURIComponent(
                url,
              )}`,
            );
          }
        }

        return path === path.toLowerCase() ? (
          <Component {...props} />
        ) : (
          <>
            <Redirect to={path.toLowerCase()} />
            <Component {...props} />
          </>
        );
      }}
    />
  );
};
