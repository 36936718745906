/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

// import { ReactComponent as QueryNone } from 'assets/Icons/chart-query.svg';
import ChartEmptyPng from 'assets/Images/chart-empty.png';

type Props = {
  title?: string;
  message?: string;
};

export const NoFilterInsights = ({
  title = 'No data selected',
  message = 'Please enter a term or select a list to generate your graph.',
}: Props) => {
  return (
    <div tw="w-full h-full flex justify-center items-center">
      <div tw="flex gap-y-3 justify-center flex-col text-sonnant-dark items-center">
        {/* <QueryNone tw="height[12rem] width[12rem] fill[#7f8090]" /> */}

        <div tw="font-medium font-size[2.4rem] mt-3">{title}</div>
        <div tw="mt-5 mb-8 text-16">{message}</div>
        <img src={ChartEmptyPng} alt={title} tw="w-1/2" />
      </div>
    </div>
  );
};
