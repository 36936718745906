export const loadGoogleScriptV1 = () => {
  // Prevent existed script from loading
  if ((window as any)?.gapi) {
    initGoogleAuthClientAsync();
    return;
  }

  const id = 'google-api-js';
  const src = 'https://apis.google.com/js/platform.js';
  // const src = 'https://apis.google.com/js/api:client.js';
  const firstJs: any = document.getElementsByTagName('script')[0];

  if (document.getElementById(id)) {
    return;
  }
  const js = document.createElement('script');
  js.id = id;
  js.src = src;
  js.async = true;
  js.defer = true;
  // js.crossOrigin = 'anonymous';
  firstJs.parentNode.insertBefore(js, firstJs);

  js.onload = () => {
    initGoogleAuthClientAsync();
  };

};

export const initGoogleAuthClientAsync = async () => {
  if (!window?.gapi) {
    console.log('Google API Script not fully loaded');
    return;
  }

  (window as any).gapi?.load('auth2', function(){
    // Retrieve the singleton for the GoogleAuth library and set up the client.
    window?.gapi?.auth2?.init({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
      // cookiepolicy: 'single_host_origin',
      // Request scopes in addition to 'profile' and 'email'
      //scope: 'additional_scope'
    });
  });
};
