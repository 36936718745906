export enum Roles {
  ADMIN = 'Administrator',
  USER = 'User',
}

export enum DefaultGroups {
  ADMIN = 'Administrators',
  USER = 'Users',
}

export enum Layers {
  DISABLE,
  MANAGE,
  EXPORT,

  TRANSCRIPT,
  CONFIDENCE,
  KEY_TERM,
  CHAPTER,
  SUMMARY,
  CUSTOM_TERM,
  PROFANITY,

  // METADATA
  ORG,
  PEOPLE,
  LOCATION,
  PRODUCT,
  NATIONALITY,
  IAB,
  AD_MARKER,
  AD_BREAK,
  SPEAKER,
  SEGMENT,

  // NOT METADATA
  HUMAN_EDIT,
  GARM_REPORT,
}

export enum HistoryTypes {
  VB,
  MANUAL,
  AUTOSAVE,
}

export enum Keys {
  ENTER = 'Enter',
  ENTER_NUMPAD = 'NumpadEnter',
  ESC = 'Escape',
  SPACE = 'Space',
}

export enum ModalOptions {
  YES,
  NO,
  CANCEL,
  CLOSE,
}

export enum CreatedReportEnum {
  CREATING = '0',
  DRAFT = '1',
  ERROR = '2',
}

export enum CreatedReportText {
  CREATING = 'Creating',
  DRAFT = 'Draft',
}

export enum VideoStatusCode {
  UPLOADING = 0,
  TRANSCRIBING = 1,
  PAYMENT_REQUIRED = 1.5,
  AI_PROCESSING = 2,
  THUMBNAIL_UPDATING = 2.5,
  NOT_EDITED = 3,
  ERROR = 4,
  EDITING = 5,
  DRAFT = 6,
  DELETE_SUSPENDED = 7,
  TRENDING = 999,
}

export enum FavoriteEnum {
  SHARE,
  INTEGRATE,
  VIEW_SHARED_ITEM,
  EXPORT,
  CREATE_CLIP,
  VIEW_CLIPS,
  VIEW,
  UPDATE_THUMBNAIL,
  VIEW_TRANSCRIPT,
  EDIT_CAPTIONS,
  EDIT_TRANSCRIPT,
  MENTIONS_REPORT,
  COPY_LINK,
  RERUN_AI,
  RENAME,
  DELETE,
}

export enum DatePeriodFilter {
  TODAY = 'Today',
  THIS_WEEK = 'This week',
  THIS_MONTH = 'This month',
  THIS_YEAR = 'This year',
  CUSTOM = 'Custom',
}

export enum DurationFilter {
  LESS_10 = 'Under 10 minutes',
  GREATER_10 = '10-30 minutes',
  GREATER_30 = '30-60 minutes',
  GREATER_60 = 'Over 60 minutes',
}

export enum VideoStatusText {
  UPLOADING = 'Uploading',
  TRANSCRIBING = 'Transcribing',
  AI_PROCESSING = 'AI Processing',
  THUMBNAIL_UPDATING = 'Thumbnail updating',
  NOT_EDITED = 'Draft',
  ERROR = 'Error',
  EDITING = 'Editing',
  DRAFT = 'Draft',
  DELETE_SUSPENDED = '',
  PAYMENT_REQUIRED = 'Payment Required',
  TRENDING = 'Trending',
}

export enum TimeEnum {
  SEC,
  MS,
  TIMECODE,
  TIME_PLAY,
}

export enum CaptionStatus {
  OPEN_CAPTION = 'OPEN',
  CLOSED_CAPTION = 'CLOSED',
}

export enum SearchType {
  TITLE = 'title',
}

export enum MediaType {
  VIDEO = 'video',
  AUDIO = 'audio',
}

export enum ReplaceMode {
  SINGLE,
  ALL,
}

export enum MediaReadyState {
  HAVE_NOTHING = 0,
  HAVE_METADATA = 1,
  HAVE_CURRENT_DATA = 2,
  HAVE_FUTURE_DATA = 3,
  HAVE_ENOUGH_DATA = 4,
}

export enum SubscriptionEnum {
  TRIAL = 'TRIAL',
  PAYG = 'PAYG',
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
  ENTERPRISE = 'ENTERPRISE',
  ENTERPRISE_TRIAL = 'ENTERPRISE_TRIAL',
  INTERNAL = 'INTERNAL',
}

export enum AccountStatus {
  ACTIVE = 'Active',
  DISABLED = 'Disabled',
  DELETED = 'Deleted',
}

export enum StandardTypeEnum {
  ANNUAL = 'ANNUALLY',
  MONTHLY = 'MONTHLY',
}

export enum RoleEnum {
  ADMIN = 'Administrator',
  USER = 'User',
  EDITOR = 'Editor',
}

export enum ClipStatus {
  ORIGINAL = 'Original',
  CREATING = 'Creating',
  CREATED = 'Created',
  SHARED = 'Shared',
  SUGGESTED = 'Suggested',
  CREATION_FAILED = 'Creation Failed',
}

export enum ClipStatusCode {
  CREATING = '0',
  CREATED = '1',
  SHARED = '2',
  THUMBNAIL_UPDATING = '2.5',
  DELETED = '3',
  CREATION_FAILED = '4',
}

export enum ClipVersionCount {
  ORIGINAL = '0',
}

export enum ViewMode {
  THUMBNAIL = 'THUMBNAIL',
  LIST_VIEW = 'LIST_VIEW',
}

export enum HTTPStatus {
  SUCCESS = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  RESTRICTED = 406,
  SUBSCRIPTION_EXPIRED = 410,
  PAYLOAD_TOO_LARGE = 413,
  REVOKED = 412,
  NOT_SUPPORTED = 415,
  INVALID_CODE = 498,
  SERVER_ERROR = 500,
  FAILED = 502,
  TIMEOUT = 504,
}

export enum Tabs {
  LIBRARY = 'library',
  REVIVE = 'revive',
}

export enum Social {
  FACEBOOK = 'Facebook',
  TWITTER = 'Twitter',
  GOOGLE = 'Google',
  LINKEDIN = 'LinkedIn',
  YOUTUBE = 'YouTube',
  APPLE = 'Apple',
  TIKTOK = 'TikTok',
  WE_CHAT = 'WeChat',
  INSTAGRAM = 'Instagram',
}

export enum NotificationType {
  MEDIA_PROGRESS = 'media/progress',
  MEDIA_ERROR = 'media/error',
  MEDIA_COMPLETE = 'media/complete',

  TRENDING = 'trending/complete',

  CLIP_PROGRESS = 'clip/progress',
  CLIP_COMPLETE = 'clip/completed',
  MENTION_DOWNLOAD = 'mentions/completed',

  EXPORT_DOWNLOAD = 'exports/completed',
  WATCH_LIST_DETECTED = 'watchlist/detected',
  MENTION_PROCESSING = 'mentions/processing',

  INTEGRATE_OMNY_PROGRESSING = 'integrate-omny/processing',
  INTEGRATE_OMNY_COMPLETED = 'integrate-omny/completed',
  INTEGRATE_OMNY_ERROR = 'integrate-omny/error',

  INTEGRATE_MEGAPHONE_COMPLETED = 'integrate-megaphone/completed',
  INTEGRATE_MEGAPHONE_ERROR = 'integrate-megaphone/error',

  GENERATED_TERM_PROGRESSING = 'insight/term-mention-processing',
  GENERATED_TERM_COMPLETED = 'insight/term-mention-completed',
  GENERATED_TERM_ERROR = 'insight/term-mention-error',
}

export enum NotificationFilter {
  TRENDING = 'trending',
  PROGRESS = 'progress',
  COMPLETE = 'complete',
  ERROR = 'error',
  WATCH = 'watch',
  UNKNOWN = 'unknown',
}

export enum TrendingLevel {
  NONE = 'None',
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
  VIRAL = 'Viral',
}

export enum UnsubscribeParams {
  REVIVAL = 'unsubscribe-revival',
  PROCESSING = 'unsubscribe-processing',
  REPORTING = 'unsubscribe-reporting',
}

export enum RegenerateEnum {
  SUGGESTED_CLIP = 'regenerate/suggested-clip',
}

export enum CaptionEnum {
  DISABLED = 'disabled',
  SHOWING = 'showing',
  HIDDEN = 'hidden',
}

export enum CollectionType {
  STANDARD = 'standard',
  SMART = 'smart',
  NONE = 'none',
}

export enum DemographicsType {
  NONE = 'none',
  ALL = 'all',
}

export enum Mode {
  ADD = 'add',
  EDIT = 'edit',
}

export enum CustomTermType {
  APPLIED = 'applied',
  GLOBAL = 'global',
  WATCHLIST = 'watchlist',
}

export enum AppleAuthError {
  POPUP_BLOCKED = 'popup_blocked_by_browser',
  POPUP_CLOSED = 'popup_closed_by_user',
}

export enum Ratio {
  DEFAULT = 'N/A',
  EMPTY = '',
  '4_3' = '4:3',
  '1_1' = '1:1',
  '16_9' = '16:9',
  '9_16' = '9:16',
}

export enum MobileTabs {
  SUMMARY = 'Summary',
  PARAGRAPH = 'Paragraph',
  KEY_TERM = 'Key Terms',
  TRANSCRIPT = 'Transcription',
}

export enum DragNDrop {
  NO_DROP = 'no-drop',
  MY_COLLECTION = 'my-collection',
}

export enum Dropdown {
  COLLECTION = 'Collection',
  RATIO = 'Ratio',
  COUNTRY = 'Country',
  TOPIC_CLIP = 'Topic',
  SIMPLE_TEXT = 'SimpleText',
}

export enum MediaProvider {
  YOUTUBE = 'yt',
  LOCAL = 'local',
  RSS = 'rss',
  OMNY = 'omny',
  MEGAPHONE = 'megaphone',
}

export enum ToastNotiEnum {
  MULTI_COMBINED = 'MULTI_COMBINED',
  MULTI_EXPORT = 'MULTI_EXPORT',
  MULTI_MENTION = 'MULTI_MENTION',
}

export enum AdvancedFilterType {
  DATE = 'date',
  TYPE = 'type',
  DURATION = 'duration',
  SPEAKERS = 'speakers',
  KEYWORDS = 'keywords',
  CUSTOM = 'custom',
  TITLE = 'title',
}

export enum CalendarFilter {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export enum MoveDirection {
  UP = 'up',
  DOWN = 'down',
}

export enum YoutubeChannel {
  ID = 'channel/',
  NAME = 'c/',
  USER = 'user/',
}

export enum SortField {
  MODIFIED = 'updated_at',
  TITLE = 'title',
  TYPE = 'media_type',
  DURATION = 'length',
  SPEAKER = 'speakers',
  DATE_ADDED = 'created_at',
  STATUS = 'status_code',
  LOCKED = 'locked',
  WORD = 'words',
  // CLIP = 'clips_count',
  CLIP = 'publishcount',
  CREATED_BY = 'userid',
  VIEWS = 'viewed',
}

export enum AdmarkerField {
  TYPE = 'type',
  TITLE = 'title',
  START_TIME = 'start_time',
  ADS_SER = 'ads_served',
  TAGS = 'tags',
  IAB = 'iab_categories',
  TRANSCRIPT = 'transcript_prior',
  TRANSCRIPT_AFTER = 'transcript_after',
  URL = 'url',
}

export enum MentionReportField {
  TYPE = 'type',
  ID = 'id',
  START_TIME = 'start_time',
  END_TIME = 'end_time',
  DURATION = 'duration',
  TOTAL = 'total',
  TERM = 'term',
  IAB = 'iab_categories',
  TRANSCRIPT = 'transcript',
  URL = 'url',
}

export enum SearchField {
  TRANSCRIPTS = 'transcripts',
  KEYWORD = 'keywords',
  IAB = 'iab_categories',
  PEOPLE = 'entities.person',
  PRODUCT = 'entities.product',
  ORG = 'entities.organisation',
  LOCATION = 'entities.location',
  NATIONALITY = 'entities.nationality',
}

export enum IntegrationEnum {
  OMNY = 'omny',
  MEGAPHONE = 'megaphone',
  SPREAKER = 'spreaker',
}

export enum CollectionOptionEnum {
  OMNY = 'omny',
  MEGAPHONE = 'megaphone',
  SPREAKER = 'spreaker',
  COLLECTION = 'collection',
  NONE = 'none',
}

export enum MentionReportTypeEnum {
  SINGLE_REPORT = 'report/single',
  MULTIPLE_REPORT = 'report/multi',
  SINGLE_COMBINED = 'combined/single',
  MULTIPLE_COMBINED = 'combined/multi',
}

export enum AdMarkerEnum {
  PRE_ROLL = 'Pre-roll',
  MID_ROLL = 'Mid-roll',
  POST_ROLL = 'Post-roll',
}

export type ApexChartType = 'donut' | 'line' | 'bar' | 'area' | 'radialBar';

export type AdvertisingChartType = ApexChartType | 'wordcloud';

export enum IabBrandSafe {
  NAME = 'Brand Safe',
  COLOR = '#00E396',
}

export enum PinnedChartType {
  ORIGINAL = '00000000-0000-0000-0000-000000000000',
}

export enum StringEnum {
  EMPTY = '',
}

export enum FilterLayer {
  TAG = 'Tags',
  COLLECTION = 'Collections',
  ORG = 'Organisation Name',
}

export enum GeneratedChartStatus {
  GENERATING = 0,
  GENERATED = 1,
  ERROR = 2,
}

export enum GeneratedChartRelated {
  TERM = 'term',
  IAB = 'iab',
}

export enum RestrictionMode {
  COLLECTION = 'collection',
  TAG = 'tag',
}

export enum SelectionMode {
  NONE = 'none',
  PAGE = 'page',
  ALL = 'all',
}
