/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import 'twin.macro';

import { AxiosError } from 'axios';
import { borderGradientBg } from 'components/shared/twin.styles';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useToggle } from 'react-use';
import { AuthService, ConfirmPasswordPayload } from 'services';
import { REGEX } from 'utils/regex';
import '../../sass/base/_input.scss';
import {
  AlertBlock,
  inValidErrorMsg,
  invalidEmailError,
  noMatchErrorMsg,
  requiredErrorMsg,
} from '../../utils/utils';
import { Modal } from '../UiControls/modal/modal';
import Loader from '../loader/loader';
import './ResetPassword.scss';
import { InputHookForm } from 'components/shared/InputHookForm';
import { useStateReset } from 'hooks/useStateReset';

type ResetPasswordForm = {
  email: string;
  verificationcode: string;
  newpassword: string;
  confirmpassword: string;
};

type ResponseData = {
  isSuccess: boolean | null;
  message: string | null;
};

export const ResetPassword = () => {
  const search = useLocation().search;
  const history = useHistory();

  const nextQuery = new URLSearchParams(search).get('next');
  const code = new URLSearchParams(search).get('code') || '';
  const email = new URLSearchParams(search).get('email') || '';

  const [isFetching, toggleFetching] = useToggle(false);

  const [responseData, setResponseData, resetResponseData] =
    useStateReset<ResponseData>({
      isSuccess: null,
      message: null,
    });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm<ResetPasswordForm>({
    mode: 'onTouched',
    defaultValues: {
      email: email,
      verificationcode: code,
    },
  });

  const [initSelectAccount] = useState<any>(() => {
    if (nextQuery) {
      const domain = new URL(nextQuery);

      let hostnameArr = domain.hostname.replace('www.', '').split('.');
      if (hostnameArr.length > 2) {
        return hostnameArr[0];
      }
    }
    return null;
  });

  const handleSave = async (formValues: ResetPasswordForm) => {
    const payload: ConfirmPasswordPayload = {
      subdomain: initSelectAccount,
      username: formValues.email,
      verificationcode: formValues.verificationcode,
      newpassword: formValues.newpassword,
    };

    try {
      toggleFetching(true);
      const { data: responseMsg } = await AuthService.confirmPassword(payload);

      setResponseData({ isSuccess: true, message: responseMsg });

      reset();
    } catch (error: any) {
      const response = (error as AxiosError)?.response;

      setResponseData({
        isSuccess: false,
        message: error?.data?.message || response?.data || 'Something went wrong',
      });
    } finally {
      toggleFetching(false);
    }
  };

  const handleAlertClose = () => {
    resetResponseData();
  };

  const handleModalClose = () => {
    const encodeString = '' + nextQuery;
    const tenantUrl =
      '/signin?next=' +
      encodeURIComponent(encodeString.toString() + '&reset=true');

    history.push(tenantUrl);
  };

  return (
    <>
      {/* {!hasSubdomain() && !initSelectAccount && <Redirect to="/signin" />} */}

      <form
        className="resetPasswordForm"
        tw="h-screen overflow-y-auto pt-[7rem]"
        onSubmit={handleSubmit(handleSave)}
      >
        {isFetching && (
          <div className="loader__component">
            <Loader />
          </div>
        )}

        {responseData.message && responseData.isSuccess && (
          <Modal show={true} modalClosed={handleModalClose}>
            <h1>Reset Password</h1>
            <p className="topMessage success" tw="bg-sonnant-green!">
              {responseData.message}
            </p>
          </Modal>
        )}

        {responseData.message && responseData.isSuccess === false && (
          <AlertBlock
            isSuccess={false}
            statusMessage={responseData?.message}
            handleClose={handleAlertClose}
          />
        )}

        <div className="grid-x">
          <h1 className="small-12 medium-8 large-4 grid-container cell text-center margin-bottom-3 changeHeader">
            Reset Password
          </h1>
        </div>

        <div className="grid-x">
          <label className="small-12 medium-8 large-4 grid-container cell ">
            Email Address (Username)
            <InputHookForm<ResetPasswordForm>
              name="email"
              type="text"
              placeholder="Email Address"
              register={register}
              rules={{
                required: requiredErrorMsg('Email Address'),
                maxLength: 50,
                pattern: {
                  value: REGEX.EMAIL_ADDRESS,
                  message: invalidEmailError,
                },
              }}
              errors={errors}
            />
          </label>
        </div>

        <div className="grid-x">
          <label className="small-12 medium-8 large-4 grid-container cell ">
            Verification PIN
            <InputHookForm<ResetPasswordForm>
              name="verificationcode"
              type="password"
              placeholder="Verification PIN"
              register={register}
              rules={{
                required: requiredErrorMsg('Verification PIN'),
                maxLength: 50,
              }}
              errors={errors}
            />
          </label>
        </div>

        <div className="grid-x">
          <label className="small-12 medium-8 large-4 grid-container cell ">
            New Password
            <InputHookForm<ResetPasswordForm>
              name="newpassword"
              type="password"
              placeholder="New Password"
              register={register}
              rules={{
                required: requiredErrorMsg('New Password'),
                maxLength: 50,
                pattern: {
                  message: inValidErrorMsg,
                  value: REGEX.PASSWORD_CHECK,
                },
              }}
              errors={errors}
            />
          </label>
        </div>
        <div className="grid-x">
          <label
            className="small-12 medium-8 large-4 grid-container cell"
            tw="md-down:(w-full)!"
          >
            Confirm New Password
            <InputHookForm<ResetPasswordForm>
              name="confirmpassword"
              type="password"
              placeholder="Confirm New Password"
              register={register}
              rules={{
                required: requiredErrorMsg('Confirm New Password'),
                maxLength: 50,
                validate: {
                  matchedPassword: (val) =>
                    watch('newpassword') !== val ? noMatchErrorMsg : undefined,
                },
              }}
              errors={errors}
            />
          </label>
        </div>

        <div className="grid-x applywrapper" tw="mt-8! px-6">
          <div className="small-12 medium-8 large-4 grid-container cell">
            <button
              type="submit"
              tw="relative flex justify-center items-center font-size[1.8rem]! (rounded-full bg-sonnant-purple-4 w-full height[4rem])!"
              css={[borderGradientBg('2px', '#201F66')]}
              disabled={!isValid}
            >
              <div tw="relative flex items-center">
                <span tw="(line-height[initial])!">Apply</span>
              </div>
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
