/* eslint-disable react-hooks/rules-of-hooks */
import axios from 'axios';
import React, { useEffect } from 'react';
import { useSearchParam } from 'react-use';
import  { config } from 'components/config';
import { Social } from 'utils/enum';

export const SharePostWithFacebook = () => {
  const code = useSearchParam('code');
  const provider = Social.FACEBOOK;

  useEffect(() => {
    handleGetAccessToken();
  });

  const handleGetAccessToken = async () => {
    const result = await axios.get(
      `https://graph.facebook.com/v12.0/oauth/access_token?redirect_uri=${config.FB_REDIRECT_URL}&client_id=${config.FB_CLIENT_ID}&client_secret=${config.FB_CLIENT_SECRET}&code=${code}`,
    );
    const GetAccessTokenPage = await axios.get(
      `https://graph.facebook.com/me/accounts?access_token=${result.data.access_token}`,
    );
    window.document.cookie = `state=true;domain=.${window.location.host}; path=/`;
    window.document.cookie = `provider=${provider};domain=.${window.location.host}; path=/`;
    window.document.cookie = `page_data=${JSON.stringify(GetAccessTokenPage.data.data)};domain=.${window.location.host}; path=/`;
    window.close();
  };

  return (
    <div className="App">
      <header className="App-header"></header>
    </div>
  );
};
