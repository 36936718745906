/** @jsxImportSource @emotion/react */
import React from 'react';
import tw from 'twin.macro';

type Props = {
  msg: string;
  type: 'error' | 'warning';
};

export const ErrorForm = (props: Props) => {
  const { msg, type } = props;
  return (
    <div
      css={[type === 'error' ? tw`text-red-600` : tw`text-yellow-500`]}
      tw="(my-0 text-11 line-height[1.2] mt-2)!"
    >
      {msg}
    </div>
  );
};
