/** @jsxImportSource @emotion/react */
import 'twin.macro';

import Skeleton from 'react-loading-skeleton';

interface Props {}

export const KeywordSkeleton = (props: Props) => {
  return (
    <>
      {[...Array(20)].map((_, i) => (
        <Skeleton key={i} width={125} height={36} tw="mr-4 mb-4" />
      ))}
    </>
  );
};
