/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { Ripple } from '@rmwc/ripple';
import { Hint } from 'components/shared/Hint';
import { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { SegmentType, SpecialSegment } from 'utils/models/transcript.model';
import { isPublicShared, secToTime } from '../../MediaUtilities';

import { ReactComponent as AdBreakSvg } from 'assets/Icons/bolt.svg';
import { ReactComponent as TrafficSvg } from 'assets/Icons/car-segment.svg';
import { ReactComponent as MusicNoteSvg } from 'assets/Icons/music-note-segment.svg';
import { ReactComponent as UnknownSvg } from 'assets/Icons/question-mark.svg';
import { ReactComponent as UserDefinedSvg } from 'assets/Icons/user-defined-segment.svg';
import { fillSegment } from 'components/shared/twin.styles';
import { getNameSegment } from 'utils/transcript.util';

type Props = {
  item: SpecialSegment;
  onClickSegment: (item: SpecialSegment) => void;
};

export const MediaSegmentLayerItem = ({ item, ...props }: Props) => {
  const toggle = useSelector((state: RootState) => state.toggle);
  const mediaSegment = useSelector((state: RootState) => state.mediaSegment);

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    props.onClickSegment(item);
  };

  return (
    <Ripple>
      <div
        tw="py-1.5 font-size[1.7rem] cursor-pointer px-2 rounded display[inline-flex] items-center"
        css={mediaSegment?.currentActiveId === item?.id && tw`bg-gray-200`}
        onClick={handleClick}
      >
        <Hint
          text={getNameSegment(item.type)}
          enterDelay={200}
          leaveDelay={0}
          arrow
        >
          <span tw="flex items-center" css={[fillSegment(item.type)]}>
            <SegmentIcon type={item.type} />
          </span>
        </Hint>

        <Hint
          disabled={isPublicShared() || !toggle.isEditMode}
          text="Double click to edit"
          enterDelay={200}
          leaveDelay={0}
        >
          <span
            tw="px-1"
            css={[
              toggle.isEditMode &&
                tw`border[1px dashed] border-disabled rounded select-none`,
            ]}
          >
            {`${secToTime(item.start)} - ${secToTime(item.end)}`}
          </span>
        </Hint>
      </div>
    </Ripple>
  );
};

const SegmentIcon = ({ type }: { type: SegmentType }) => {
  switch (type) {
    case SegmentType.AD_BREAK:
      return <AdBreakSvg />;
    case SegmentType.TRAFFIC:
      return <TrafficSvg />;
    case SegmentType.MUSIC:
      return <MusicNoteSvg />;
    case SegmentType.USER_DEFINED:
      return <UserDefinedSvg />;
    default:
      return <UnknownSvg />;
  }
};
