import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep, isEmpty, omit, uniqBy } from 'lodash';
import { RootState } from 'reducers';
import { InsightService } from 'services/insight.service';
import { Flatten } from 'types/utils';
import { ensurePinnedChartFormat } from 'utils/adapter.util';
import {
  ApexChartType,
  CalendarFilter,
  Layers,
  PinnedChartType,
} from 'utils/enum';
import { len } from 'utils/generic.util';
import { CustomDateParams, InsightTerm } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { v4 } from 'uuid';

export const insightSelector = createSelector(
  (state: RootState) => state.insight,
  (insight) => ({
    combinedTerms: [...insight.vocabTerms, ...insight.inputTerms],
    filtersCount:
      len(insight.selectedTagIds) +
      len(insight.selectedCollectionIds) +
      len(insight.selectedOrgIds),
  }),
);

export type InsightState = {
  id: string;
  title: string;

  searchInputTerm: string;
  chartType: ApexChartType;
  calendarMode: CalendarFilter;

  vocabListId: string | null;
  vocabTerms: InsightTerm[];
  inputTerms: InsightTerm[];

  showDataLabels: boolean;
  showQuarterMarkers: boolean;
  groupByCollection: boolean;

  selectedTagIds: string[];
  selectedCollectionIds: string[];
  selectedOrgIds: string[];

  selectedLayer: Layers;
  selectedMetadataLayer: Layers;
  dateRange: CustomDateParams;

  pinnedCharts: PinnableChart[];
};

export type PinnableChart = Omit<InsightState, 'pinnedCharts'>;

const insightSlice = createSlice({
  name: 'insight',
  initialState: {
    id: PinnedChartType.ORIGINAL,
    title: 'Mentions Analytics',

    searchInputTerm: '',
    chartType: 'bar',
    calendarMode: CalendarFilter.YEAR,

    vocabListId: null,
    vocabTerms: [],
    inputTerms: [],

    showDataLabels: true,
    showQuarterMarkers: false,
    groupByCollection: false,

    selectedTagIds: [],
    selectedCollectionIds: [],
    selectedOrgIds: [],

    selectedLayer: Layers.TRANSCRIPT,
    selectedMetadataLayer: Layers.TRANSCRIPT,
    dateRange: {
      startDate: null,
      endDate: null,
      focusedInput: null,
    },

    pinnedCharts: [],
  } as InsightState,
  reducers: {
    setSearchInputTerm: (
      state,
      { payload }: PayloadAction<InsightState['searchInputTerm']>,
    ) => {
      state.searchInputTerm = payload;
    },
    setInsightChartType: (
      state,
      { payload }: PayloadAction<InsightState['chartType']>,
    ) => {
      state.chartType = payload;
    },
    setInsightCalendarMode: (
      state,
      { payload }: PayloadAction<InsightState['calendarMode']>,
    ) => {
      state.calendarMode = payload;
    },
    setVocabTerms: (
      state,
      { payload }: PayloadAction<InsightState['vocabTerms']>,
    ) => {
      state.vocabTerms = payload;
    },
    resetInsightTerms: (state) => {
      state.vocabTerms = [];
      state.inputTerms = [];
    },
    setInputTerms: (
      state,
      { payload }: PayloadAction<InsightState['inputTerms']>,
    ) => {
      state.inputTerms = uniqBy([...payload], 'name');
    },
    toggleDataLabels: (state, action?: PayloadAction<boolean | undefined>) => {
      if (typeof action?.payload === 'boolean') {
        state.showDataLabels = action.payload;
      } else {
        state.showDataLabels = !state.showDataLabels;
      }
    },
    toggleQuarterMarkers: (
      state,
      action?: PayloadAction<boolean | undefined>,
    ) => {
      if (typeof action?.payload === 'boolean') {
        state.showQuarterMarkers = action.payload;
      } else {
        state.showQuarterMarkers = !state.showQuarterMarkers;
      }
    },
    toggleCollectionGroupBy: (
      state,
      action?: PayloadAction<boolean | undefined>,
    ) => {
      if (typeof action?.payload === 'boolean') {
        state.groupByCollection = action.payload;
      } else {
        state.groupByCollection = !state.groupByCollection;
      }
    },
    removeSingleVocabTerm: (
      state,
      { payload }: PayloadAction<Flatten<InsightState['vocabTerms']>>,
    ) => {
      state.vocabTerms = state.vocabTerms.filter(
        (term) => term.id !== payload.id,
      );
    },
    setInsightTagIds: (
      state,
      { payload }: PayloadAction<InsightState['selectedTagIds']>,
    ) => {
      state.selectedTagIds = payload;
    },
    setInsightCollectionIds: (
      state,
      { payload }: PayloadAction<InsightState['selectedCollectionIds']>,
    ) => {
      state.selectedCollectionIds = payload;
    },
    setInsightOrgIds: (
      state,
      { payload }: PayloadAction<InsightState['selectedOrgIds']>,
    ) => {
      state.selectedOrgIds = payload;
    },
    resetInsightFilters: (state) => {
      state.selectedTagIds = [];
      state.selectedCollectionIds = [];
      state.selectedOrgIds = [];
    },
    setInsightLayer: (
      state,
      { payload }: PayloadAction<InsightState['selectedLayer']>,
    ) => {
      state.selectedLayer = payload;
    },
    setInsightMetadataLayer: (
      state,
      { payload }: PayloadAction<InsightState['selectedMetadataLayer']>,
    ) => {
      state.selectedMetadataLayer = payload;
    },
    setInsightDateRange: (
      state,
      { payload }: PayloadAction<InsightState['dateRange']>,
    ) => {
      state.dateRange = payload;
    },
    toggleSelectInsightTerm: (
      state,
      { payload }: PayloadAction<InsightTerm>,
    ) => {
      const termList = payload.isInputTerm
        ? state.inputTerms
        : state.vocabTerms;

      const foundIndex = termList.findIndex((term) => term.id === payload.id);

      if (foundIndex < 0) return; // Not found

      const newStateSelected = !termList[foundIndex].selected;

      if (payload.isInputTerm) {
        state.inputTerms[foundIndex].selected = newStateSelected;
      } else {
        state.vocabTerms[foundIndex].selected = newStateSelected;
      }
    },
    resetInsight: (state) => {
      state.selectedCollectionIds = [];
      state.showDataLabels = true;
      state.showQuarterMarkers = false;
    },
    setPinnedCharts: (
      state,
      { payload }: PayloadAction<InsightState['pinnedCharts']>,
    ) => {
      state.pinnedCharts = payload;
    },
    pinCurrentChart: (state, { payload }: PayloadAction<{ title: string }>) => {
      const currentState = {
        ...omit(state, ['pinnedCharts']),
        id: v4(), // unique id for each child pinned chart
        title: payload.title,
      };

      state.pinnedCharts.unshift(currentState);

      savePinnedChartsAsync(state.pinnedCharts);
    },
    unpinChartId: (state, { payload }: PayloadAction<InsightState['id']>) => {
      if (isEmpty(payload)) return;

      state.pinnedCharts = state.pinnedCharts.filter(
        (chart) => chart.id !== payload,
      );

      savePinnedChartsAsync(state.pinnedCharts);
    },
    patchPinnedChart: (
      state,
      {
        payload,
      }: PayloadAction<Pick<PinnableChart, 'id'> & Partial<PinnableChart>>,
    ) => {
      const foundIndex = state.pinnedCharts.findIndex(
        (chart) => chart.id === payload.id,
      );

      if (foundIndex < 0) return; // Not found

      state.pinnedCharts[foundIndex] = {
        ...state.pinnedCharts[foundIndex],
        ...payload,
      };
    },
    setVocabListId: (
      state,
      { payload }: PayloadAction<InsightState['vocabListId']>,
    ) => {
      state.vocabListId = payload;
    },
  },
});

const savePinnedChartsAsync = async (pinnedChartBody: PinnableChart[]) => {
  const body = ensurePinnedChartFormat(cloneDeep(pinnedChartBody));

  const saveAsync = InsightService.savePinnedCharts(body);

  customToast.promise(saveAsync, {
    loading: 'Saving',
    success: 'Done',
    error: 'Something went wrong. Save failed!',
  });

  try {
    await saveAsync;
  } catch (error) {
    console.log('error :>> ', error);
  }
};

export const {
  setSearchInputTerm,
  setInsightChartType,
  setInsightCalendarMode,
  setVocabTerms,
  resetInsightTerms,
  setInputTerms,
  toggleDataLabels,
  toggleQuarterMarkers,
  toggleCollectionGroupBy,
  removeSingleVocabTerm,
  setInsightTagIds,
  setInsightCollectionIds,
  setInsightOrgIds,
  resetInsightFilters,
  setInsightLayer,
  setInsightMetadataLayer,
  setInsightDateRange,
  toggleSelectInsightTerm,
  resetInsight,
  setPinnedCharts,
  pinCurrentChart,
  unpinChartId,
  patchPinnedChart,
  setVocabListId,
} = insightSlice.actions;

export const insightReducer = insightSlice.reducer;
