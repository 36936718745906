/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';
import React, { useEffect, useState } from 'react';
import { isNumber, last, padStart, startsWith } from 'lodash';
import {
  secToTimeCode,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { numberNoArrow } from '../twin.styles';

const colonStyles = css`
  ${tw`self-end mb-6 mx-2 font-medium`}
`;

const labelStyles = (size: 'sm' | 'md' | 'lg' | undefined) =>
  css`
    ${size === 'sm' && tw`font-size[1.2rem]!`}
  `;

const invalidStyles = css`
  input {
    ${tw`(border[2px] border-red-500 border-solid)!`}
  }
`;

type InputTimeCodeProps = {
  time: string | number;
  handleTime: (e: number) => void;
  size?: 'sm' | 'md' | 'lg';
  hasError?: boolean;
};

export const InputTimeCode = (props: InputTimeCodeProps) => {
  const [initialHr, setInitialHr] = useState<number>(0);
  const [initialMin, setInitialMin] = useState<number>(0);
  const [initialSec, setInitialSec] = useState<number>(0);
  const [initialMs, setInitialMs] = useState<number>(0);

  useEffect(() => {
    let cloneTime = props.time;

    if (isNumber(cloneTime)) {
      cloneTime = secToTimeCode(cloneTime);
    }
    
    const [hr, min, sec] = cloneTime.split(':');
    const ms = parseInt(last(cloneTime.split('.')) ?? '0');

    setInitialHr(parseInt(hr));
    setInitialMin(parseInt(min));
    setInitialSec(parseInt(sec));
    setInitialMs(ms);

    const time = getTimeInSec(parseInt(hr), parseInt(min), parseInt(sec), ms);
  
    props.handleTime(time);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.time]);

  const getTimeInSec = (hr = 0, min = 0, sec = 0, ms = 0) => {
    const time = hr * 3600 + min * 60 + sec + ms / 1000;
    return time;
  };

  const getLastNumber = (input: string, num: number): string => {
    if (!startsWith(input, '0') && input.length > num) {
      return input.slice(1, input.length)
    }
    return input
  }

  const changeHr = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value) || 0;
    setInitialHr(value);

    const time = getTimeInSec(value, initialMin, initialSec, initialMs);
    props.handleTime(time);
  };

  const changeMin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = getLastNumber(e.target.value, 2);
    const value = parseInt(input) || 0;
    setInitialMin(value);

    const time = getTimeInSec(initialHr, value, initialSec, initialMs);
    props.handleTime(time);
  };

  const changeSec = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = getLastNumber(e.target.value, 2);
    const value = parseInt(input) || 0;
    setInitialSec(value);

    const time = getTimeInSec(initialHr, initialMin, value, initialMs);
    props.handleTime(time);
  };

  const changeMs = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = getLastNumber(e.target.value, 3);
    const value = parseInt(input) || 0;
    setInitialMs(value);

    const time = getTimeInSec(initialHr, initialMin, initialSec, value);
    props.handleTime(time);
  };

  const handleMaxLength = (e: React.KeyboardEvent<HTMLInputElement>, value: number, maxLength: number) => {
    if (String(value)?.length > maxLength) {
      e.preventDefault();
    };
  }

  return (
    <div tw="flex mx-3" css={props?.hasError && invalidStyles}>
      <div tw="w-full">
        <label css={[labelStyles(props?.size)]}>HR.</label>
        <input
          css={[numberNoArrow]}
          type="number"
          value={padStart(String(initialHr), 2, '0')}
          min={0}
          onChange={changeHr}
          placeholder={'00'}
          onKeyPress={e => handleMaxLength(e, initialHr, 3)}
        />
      </div>
      <div css={[colonStyles]}>:</div>
      <div tw="w-full">
        <label css={[labelStyles(props?.size)]}>MIN.</label>
        <input
          css={[numberNoArrow]}
          type="number"
          value={padStart(String(initialMin), 2, '0')}
          onChange={changeMin}
          min={0}
          max={59}
          placeholder={'00'}
          onKeyPress={e => handleMaxLength(e, initialMin, 2)}
        />
      </div>
      <div css={[colonStyles]}>:</div>
      <div tw="w-full">
        <label css={[labelStyles(props?.size)]}>SEC.</label>
        <input
          css={[numberNoArrow]}
          type="number"
          value={padStart(String(initialSec), 2, '0')}
          onChange={changeSec}
          min={0}
          max={59}
          placeholder={'00'}
          onKeyPress={e => handleMaxLength(e, initialSec, 2)}
        />
      </div>
      <div css={[colonStyles]}>.</div>
      <div tw="w-full">
        <label css={[labelStyles(props?.size)]}>MS.</label>
        <input
          css={[numberNoArrow]}
          type="number"
          value={padStart(String(initialMs), 3, '0')}
          onChange={changeMs}
          min={0}
          max={999}
          placeholder={'000'}
          onKeyPress={e => handleMaxLength(e, initialMs, 3)}
        />
      </div>
    </div>
  );
};
