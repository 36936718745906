/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { SimpleCheckbox } from 'components/shared/SimpleCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { toggleFollowing } from 'slices/toggle.slice';

type Props = {};

export const EmbedTranscriptFollowToggle = (props: Props) => {
  const dispatch = useDispatch();

  const toggle = useSelector((state: RootState) => state.toggle);

  const handleToggleAutoScroll = () => {
    dispatch(toggleFollowing());
  };

  return (
    <div tw="flex items-center leading-none">
      <SimpleCheckbox
        label="AUTO-SCROLL"
        checked={toggle.isFollowing}
        onChange={handleToggleAutoScroll}
      />
    </div>
  );
};
