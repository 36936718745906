/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Ripple } from '@rmwc/ripple';
import { useState } from 'react';
import 'twin.macro';
import tw from 'twin.macro';
import { AdvancedFilterType } from 'utils/enum';

import {
  DateRangeInput,
  FocusedInput,
  OnDateChangeProps,
} from '@datepicker-react/styled';
import { resetReactRangeCss } from 'components/shared/twin.styles';
import { format } from 'date-fns';
import { debounce, isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setSearchParams } from 'slices/advanced-search.slice';
import { endOfDayISO, startOfDayISO } from 'utils/date.util';
import { CustomDateParams } from 'utils/models';

type Props = {};

export const FilterCustomDate = (props: Props) => {
  const dispatch = useDispatch();

  const advancedSearch = useSelector(
    (state: RootState) => state.advancedSearch,
  );
  const { searchParams } = advancedSearch;

  const [dateRange, setDateRange] = useState<CustomDateParams>({
    startDate: searchParams?.customDate?.startDate || new Date(),
    endDate: searchParams?.customDate?.endDate || new Date(),
    focusedInput: null,
  });

  const handleClickItem = () => {
    setDateRange({
      ...dateRange,
      focusedInput: 'startDate' as FocusedInput,
    });

    const payload = {
      type: AdvancedFilterType.DATE,
      data: null,
    };

    dispatch(setSearchParams(payload));
  };

  const checkSelected = (): boolean => {
    const customDate = advancedSearch?.searchParams?.customDate;

    // Has selected other options
    if (!isNil(searchParams?.uploadDate)) return false;

    // Not completed select range
    if (!customDate?.startDate || !customDate?.endDate) {
      return false;
    }

    return true;
  };

  const getTextCustomDate = (): string => {
    const customDate = advancedSearch?.searchParams?.customDate;

    if (!customDate?.startDate || !customDate?.endDate) {
      return 'Custom';
    }

    const start = format(new Date(customDate.startDate), 'dd/MM/yyyy');
    const end = format(new Date(customDate.endDate), 'dd/MM/yyyy');

    return `${start} - ${end}`;
  };

  const handleDatesChange = (data: OnDateChangeProps & any) => {
    setDateRange(data);

    if (isNil(data?.focusedInput)) {
      const payload = {
        type: AdvancedFilterType.DATE,
        isCustomDate: true,
        data: {
          startDate: new Date(
            startOfDayISO(data?.startDate || new Date()) as string,
          ),
          endDate: new Date(endOfDayISO(data?.endDate || new Date()) as string),
        } as CustomDateParams,
      };

      dispatch(setSearchParams(payload));
    }
  };

  return (
    <>
      <Ripple
        tw="relative block rounded-md px-1.5"
        onClick={handleClickItem}
        css={checkSelected() && backgroundItem}
      >
        <span tw="whitespace-nowrap">{getTextCustomDate()}</span>
      </Ripple>

      <div tw="absolute z-index[3]" css={[pickerCustomCss, resetReactRangeCss]}>
        <DateRangeInput
          showStartDateCalendarIcon={false}
          showEndDateCalendarIcon={false}
          onDatesChange={debounce(handleDatesChange, 0)}
          onFocusChange={(focusedInput) =>
            setDateRange({
              ...dateRange,
              focusedInput: focusedInput as FocusedInput,
            })
          }
          startDate={dateRange.startDate as Date} // Date or null
          endDate={dateRange.endDate as Date} // Date or null
          focusedInput={dateRange.focusedInput} // START_DATE, END_DATE or null
          maxBookingDate={new Date()}
          displayFormat="dd/MM/yyyy"
        />
      </div>
    </>
  );
};

const pickerCustomCss = css`
  div[data-testid='DateRangeInputGrid'] {
    ${tw`hidden`}
  }
`;

const backgroundItem = css`
  ${tw`bg-sonnant-keyword cursor-pointer! font-medium`}
`;
