/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { EmbedLayerEmpty } from '../../shared/EmbedLayerEmpty';

type Props = {};

export const EmbedSummaryViewer = (props: Props) => {
  const player = useSelector((state: RootState) => state.player);

  return (
    <div tw="px-3">
      {isEmpty(player.summary) ? (
        <EmbedLayerEmpty />
      ) : (
        <div tw="text-sonnant-dark opacity-95 text-15">{player.summary}</div>
      )}
    </div>
  );
};
