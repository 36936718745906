/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { CustomVocabsTab } from 'components/Preferences/CustomVocabs/CustomVocabsTab';
import 'foundation-sites';
import $ from 'jquery';
import { useEffect } from 'react';
import { WatchListsTab } from 'components/Preferences/WatchListsTab';
import { CreatedReportsTab } from 'components/Preferences/CreatedReports/CreatedReportsTab';
import { BlacklistInsightsTab } from 'components/Preferences/BlacklistInsights/BlacklistInsightsTab';

interface Props {}

export const Reporting = (props: Props) => {
  useEffect(() => {
    $(document).foundation();
  }, []);

  return (
    <div className="preferences">
      <div className="tab_section">
        <ul
          className="tabs"
          data-tabs
          id="collapsing-tabs"
          data-deep-link="true"
          data-update-history="true"
          data-deep-link-smudge="true"
          data-deep-link-smudge-delay="500"
        >
          <li className="tabs-title is-active">
            <a href="#terms">Custom Vocabs</a>
          </li>
          <li className="tabs-title">
            <a href="#watch-list">Watch Lists</a>
          </li>
          <li className="tabs-title">
            <a href="#created-reports">Created Reports</a>
          </li>
          <li className="tabs-title">
            <a href="#blacklist-insights">Blacklist</a>
          </li>
        </ul>
      </div>

      <div
        className="tabs-content"
        data-tabs-content="collapsing-tabs"
        tw="border[none]"
      >
        <div
          className={`tabs-panel is-active`}
          id="terms"
          tw="height[calc(100vh - 130px)] overflow-y-auto overflow-x-hidden"
        >
          <CustomVocabsTab />
        </div>
      </div>
      <div
        className="tabs-content"
        data-tabs-content="collapsing-tabs"
        tw="border[none]"
      >
        <div
          className={`tabs-panel`}
          id="watch-list"
          tw="height[calc(100vh - 130px)] overflow-y-auto overflow-x-hidden"
        >
          <WatchListsTab />
        </div>
      </div>
      <div
        className="tabs-content"
        data-tabs-content="collapsing-tabs"
        tw="border[none]"
      >
        <div
          className={`tabs-panel`}
          id="created-reports"
          tw="height[calc(100vh - 130px)] overflow-y-auto overflow-x-hidden"
        >
          <CreatedReportsTab />
        </div>
        <div
          className="tabs-content"
          data-tabs-content="collapsing-tabs"
          tw="border[none]"
        >
          <div
            className={`tabs-panel`}
            id="blacklist-insights"
            tw="height[calc(100vh - 130px)] overflow-y-auto overflow-x-hidden"
          >
            <BlacklistInsightsTab />
          </div>
        </div>
      </div>
    </div>
  );
};
