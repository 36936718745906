import { BaseAPI } from 'apis/amazon';
import { CancelTokenSource } from 'axios';
import { CollectionFilters } from 'slices/collection.slice';
import { CollectionType } from 'utils/enum';
import {
  CollectionMonetisation,
  CollectionProperties,
  CustomTerm,
  IntegrationSettingsPayload,
} from 'utils/models';

export type CollectionApiResponse = {
  collection_child: CollectionApiResponse[];
  collection_id: string;
  collection_name: string;
  collection_parent_id: string | null;
  collection_order: string;
  visible_collections?: boolean;
  isArchived: boolean;
};

export type CollectionOption = {
  label: string;
  value: string;
  color?: string;
  type: CollectionType;
};

export type CollectionResponse = {
  collections: CollectionApiResponse[];
  default_collection: string | null;
};

export type CollectionSettingResponse = {
  default_collection: string | null;
  visible_collections: string[];
};

export type AddCollectionPayload = {
  collection_name: string;
};

export type UpdateCollectionPayload = {
  collection_id: string;
  collection_name: string;
};

export type UpdateCollectionVocabsPayload = {
  collectionId: string;
  globalVocabIds: string[];
  customVocabs: CustomTerm[];
};

export type CollectionOrder = {
  collection_id: string;
  collection_order: string;
};

export type MoveCollectionPayload = {
  collection_id: string;
  next_collection: CollectionOrder;
};

export type CollectionSettingPayload = {
  default_collection: string | null;
  visible_collections: string[];
};

interface Option {
  label: string;
  value: string;
  color?: string;
  type: CollectionType;
}

interface CollectionInfo {
  collectionId: string | null;
  collectionName: string | null;
}

export interface CollectionState {
  defaultCollection: CollectionInfo;
  visibleCollections: Option[];
}

export interface MoneCollectionPayload {
  collection_ids: string[];
  monetisation_cfg: CollectionMonetisation;
}

export interface CollectionIntegrationPayload {
  collectionIds: string[];
  integrationSettings: IntegrationSettingsPayload;
  isApplyAll: boolean;
}

export type AddTagsToCollectionBody = {
  collectionId: string;
  tagIds: string[];
};

export type GetCollectionWithFiltersPayload = Pick<
  CollectionFilters,
  'orgIds' | 'tagIds'
>;

export const CollectionService = {
  // GET /collections
  getCollections: () => {
    return BaseAPI.get('/collection');
  },

  // GET /collections?collection_id=<string>
  getCollectionById: (collectionId: string) => {
    return BaseAPI.get<CollectionProperties>('/collection', {
      params: {
        collection_id: collectionId,
      },
    });
  },

  // GET /collections?integration_id=<string>
  getCollectionsWithFilters: (payload: GetCollectionWithFiltersPayload) => {
    return BaseAPI.get<CollectionResponse>('/collection', {
      params: {
        integration_ids: payload.orgIds,
        tag_ids: payload.tagIds,
      },
    });
  },

  // GET /collections?is_admin_portal=<boolean>
  getAdminPortalCollections: () => {
    return BaseAPI.get<CollectionApiResponse[]>('/collection', {
      params: {
        is_admin_portal: true,
      },
    });
  },

  // POST /collections
  createCollection: (payload: AddCollectionPayload) => {
    return BaseAPI.post('/collection', payload);
  },

  // POST /collections
  addItemsToCollection: (collectionId: string, itemIds: string[]) => {
    return BaseAPI.post(`/collection?collectionid=${collectionId}`, {
      collection_items: itemIds.map((id: string) => ({
        media_id: id,
      })),
    });
  },

  // PUT /collections?collectionid=:collectionId
  updateCollection: (payload: UpdateCollectionPayload) => {
    return BaseAPI.put(`/collection?collectionid=${payload.collection_id}`, {
      collection_name: payload.collection_name,
    });
  },

  // PUT /collection?collectionid=<string>&vocab=true
  updateCollectionVocab: (
    payload: UpdateCollectionVocabsPayload,
    cancelToken?: CancelTokenSource,
  ) => {
    return BaseAPI.put(
      '/collection',
      {
        vocabList: {
          globalList: payload.globalVocabIds,
          customList: payload.customVocabs,
        },
      },
      {
        params: {
          collectionid: payload.collectionId,
          vocab: true,
        },
        cancelToken: cancelToken?.token,
      },
    );
  },

  // DELETE /collections/:id
  deleteCollection: (id: string) => {
    return BaseAPI.delete(`/collection?collectionid=${id}`);
  },

  // DELETE /collections/:id
  removeItemsFromCollection: (id: string, itemIds: string[]) => {
    return BaseAPI.delete(`/collection?collectionid=${id}`, {
      data: {
        collection_items: itemIds.map((id: string) => ({
          media_id: id,
        })),
      },
    });
  },

  // PUT /collections?collectionid=:collectionId
  reorderCollection: (payload: MoveCollectionPayload) => {
    return BaseAPI.put(`/collection?collectionid=${payload.collection_id}`, {
      next_collection: payload.next_collection,
    });
  },

  // POST /collections/settings
  saveCollectionSettings: (payload: CollectionSettingPayload) => {
    return BaseAPI.post('/collection/settings', payload);
  },

  // GET /collections/settings
  getCollectionSettings: () => {
    return BaseAPI.get('/collection/settings');
  },

  getCollectionMonetisation: (collectionId: string) => {
    return BaseAPI.get(`/collection/settings/monetisation`, {
      params: {
        collectionid: collectionId,
      },
    });
  },

  getCollectionIntegrationSetting: (collectionId: string) => {
    return BaseAPI.get(`/collection/settings/integration-omny`, {
      params: {
        collectionid: collectionId,
      },
    });
  },

  saveCollectionIntegrationSetting: (payload: CollectionIntegrationPayload) => {
    return BaseAPI.post('/collection/settings/integration-omny', payload);
  },

  saveCollectionMonetisation: (payload: MoneCollectionPayload) => {
    return BaseAPI.post('/collection/settings/monetisation', payload);
  },

  addTagsToCollectionId: (payload: AddTagsToCollectionBody) => {
    return BaseAPI.post<string>(
      '/collection',
      { tag_ids: payload.tagIds },
      {
        params: {
          collectionid: payload.collectionId,
        },
      },
    );
  },
};
