import { config } from 'components/config';

export const loadFacebookSdkScript = () => {
  // Prevent existed script from loading
  if ((window as any)?.FB) {
    initFacebookSdkAuthClientAsync();
    return;
  }

  const id = 'facebook-sdk-js';
  const src = 'https://connect.facebook.net/en_US/sdk.js';
  const firstJs: any = document.getElementsByTagName('script')[0];

  if (document.getElementById(id)) {
    return;
  }
  const js = document.createElement('script');
  js.id = id;
  js.src = src;
  js.async = true;
  js.defer = true;
  js.crossOrigin = 'anonymous';
  firstJs.parentNode.insertBefore(js, firstJs);

  // js.onload = () => {
  //   initFacebookSdkAuthClientAsync();
  // };

  (window as any).fbAsyncInit = () => {
    initFacebookSdkAuthClientAsync();
  }

};

export const initFacebookSdkAuthClientAsync = async () => {
  if (!(window as any)?.FB) {
    console.log('Facebook SDK not fully loaded');
    return;
  }

  (window as any).FB.init({
    appId            : config.FB_CLIENT_ID,
    autoLogAppEvents : true,
    xfbml            : true,
    cookie           : true,
    status           : false,
    version          : 'v12.0'
  });

  // window.FB.Event.subscribe('auth.statusChange', (response: any) => {
  //   console.log(`response SDK`, response)
  // })
  
};
