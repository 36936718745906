/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { RichTextEditor } from 'components/shared/RichTextEditor/RichTextEditor';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';


export const CaptionEditor = React.memo(() => {
  const [currentTime, setCurrentTime] = useState(0);

  const player = useSelector((state: RootState) => state.player);


  const handleVideoTimeUpdate: any = useCallback(() => {
    setCurrentTime(window.Video?.currentTime || 0);
  }, []);

  useEffect(() => {
    if (!window?.Video) return;

    window.Video?.addEventListener?.('timeupdate', handleVideoTimeUpdate);

    return () => {
      window.Video?.removeEventListener?.('timeupdate', handleVideoTimeUpdate);
    }
  }, []);

  // const jsonSrt = useLatest(player.caption);

  // const partRef = useRef(0);
  const [partialSection, setPartialSection] = useState<any>(player?.caption);
  // const FIRST_PART_AMOUNT = 500;
  // const currentSection = useRef<any>([]);

  // const getSection = async () => {
  //   const partial = jsonSrt.current.slice(
  //     FIRST_PART_AMOUNT * partRef.current,
  //     FIRST_PART_AMOUNT * (partRef.current + 1),
  //   );

  //   partRef.current += 1;
  //   currentSection.current = currentSection.current.concat(partial);
  //   setPartialSection(currentSection.current);
  //   await waitAsync(0);
  //   if (FIRST_PART_AMOUNT * partRef.current < jsonSrt.current.length) {
  //     getSection();
  //   } else {
  //     dispatch(setIsCompleteLazy(true));
  //   }
  // };

  // useEffect(() => {
  //   if (!jsonSrt.current.length) return;
  //   getSection();
  //   dispatch(setIsCompleteLazy(false));
  // }, [jsonSrt.current]);

  useEffect(() => {
    if (isEmpty(player.caption) || isEmpty(partialSection)) return;

    setPartialSection(player.caption);
  }, [player.caption]);

  return (
    <ErrorBoundary
      fallback={
        <div tw="font-medium text-sonnant-grey-6 text-16 mt-5">
          Cannot load content item's transcripts
        </div>
      }
      onError={(e) => console.log('Caption Error:', e)}
    >
      <RichTextEditor
        document={partialSection}
        // document={jsonSrt.current ?? []}
        videoTimePosition={currentTime}
        setDocument={setPartialSection}
      />
    </ErrorBoundary>
  );
});
