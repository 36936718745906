import { AxiosResponse } from 'axios';
import {
  groupMultiRawNotiByTime,
  toMegaphoneOptions,
  toOmnyOptions,
  toSpeakerConfigs,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { isEmpty, isNil, toNumber } from 'lodash';
import { RootState } from 'reducers';
import { fork, put, select, takeLatest } from 'redux-saga/effects';
import { UserService } from 'services';
import {
  setBlacklistInsight,
  setMonetisation,
  setPreference,
  setPreferenceSetting,
} from 'slices/global.slice';
import { setNotifications } from 'slices/notification.slice';
import { setOmnySettings } from 'slices/omny.slice';
import {
  patchSubscriptionQuickInfo,
  setLoadingInfo,
  setSubLoading,
  setSubscription,
} from 'slices/payment.slice';
import { setSpeakerState } from 'slices/speaker-identification.slice';
import { setUserProfile } from 'slices/user.slice';
import { setFocusWatchListId, setWatchList } from 'slices/watchlist.slice';
import { IGlobalInfo } from 'utils/models/payment.model';

import { setAliasTerms } from 'slices/alias-term.slice';
import { setMegaphoneSettings } from 'slices/megaphone.slice';
import { SubscriptionEnum } from 'utils/enum';
import * as actionType from '../actions/types';

export const getProfile = function* (): any {
  try {
    yield put(setSubLoading(true));

    yield fork(fetchSubscriptionAsync);

    const { data }: AxiosResponse<IGlobalInfo> = yield UserService.getUser();
    // tenant
    const tenantDetails = {
      preferences: data?.preferences,
      users: data?.tenant,
    };

    yield put({
      type: actionType.GET_TENANT_DATA_SUCCESS,
      data: tenantDetails,
    });
    yield put(setPreference(data?.preferences));
    yield put(setSpeakerState(toSpeakerConfigs(data?.preferences?.speakers)));
    yield put(setSubscription(data.subscription));
    yield put(setBlacklistInsight(data?.preferences?.setting_ignore_terms));
    yield put(setAliasTerms(data?.preferences?.setting_alias_terms));

    // user profile
    yield put(setUserProfile(data?.user));

    const location = yield select(
      (state: RootState) => state?.userProfile?.location,
    ) as any;
    yield put({
      type: actionType.SET_LOCATION,
      payload: location,
    });

    // Notification
    if (!isNil(data?.notification)) {
      const groupNoti = groupMultiRawNotiByTime(data?.notification, location);

      yield put(setNotifications(groupNoti));
    }

    // Omny
    const omnyData = data?.preferences?.omny_integrations;
    if (!isEmpty(omnyData) && !isNil(omnyData)) {
      yield put(setOmnySettings(omnyData.map(toOmnyOptions)));
    }

    // Megaphone
    const megaphoneData = data?.preferences?.megaphone_integrations;
    if (!isEmpty(megaphoneData) && !isNil(megaphoneData)) {
      yield put(setMegaphoneSettings(megaphoneData.map(toMegaphoneOptions)));
    }

    // Monetisation
    const monetisationData = data?.preferences?.monetisation;
    if (!isEmpty(monetisationData)) {
      yield put(setMonetisation(monetisationData!));
    }

    // WatchLists
    const watchLists = data?.preferences?.watch_lists;
    if (watchLists && !isEmpty(watchLists)) {
      yield put(setWatchList(watchLists ?? []));

      if (watchLists?.length > 0) {
        yield put(setFocusWatchListId(watchLists[0].id));
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield put(setSubLoading(false));
    yield put(setLoadingInfo(false));
  }
};

export default function* WatchInitTenantsaga() {
  yield takeLatest(actionType.GET_PROFILES, getProfile);
}

function* fetchSubscriptionAsync() {
  yield put(setLoadingInfo(true));

  const {
    data: { subscription, preference_setting },
  } = yield UserService.getSubscription();

  yield put(setLoadingInfo(false));

  // subscription
  yield put(
    patchSubscriptionQuickInfo({
      balanceminutes: toNumber(subscription.balanceminutes),
      remaining_days: toNumber(subscription.remaining_days),
      tenantplan: subscription.tenantplan as SubscriptionEnum,
    }),
  );

  // preference setting
  yield put(setPreferenceSetting(preference_setting));
}
