/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import React, { useEffect, useRef, useState } from 'react';
import { useSlider } from 'react-use';
import { ReactComponent as DirectionSvg } from 'assets/Icons/direction.svg';
import { min } from 'lodash';
import { hoverDropShadow, svgShadow } from '../twin.styles';
import { ReactComponent as ArrowDownSVG } from 'assets/Icons/arrow-down.svg';
import tw from 'twin.macro';

type Props = {
  defaultValue?: number;
  onChangeSlider: (value: number) => void;
  disabled?: boolean;
};

export const Slider = (props: Props) => {
  const { defaultValue, onChangeSlider, disabled } = props;
  const sliderRef = useRef<HTMLDivElement>(null);
  const slider = useSlider(sliderRef);
  const [amount, setAmount] = useState(defaultValue ?? 0);

  useEffect(() => {
    setAmount(defaultValue ?? 0);
  }, [defaultValue]);

  useEffect(() => {
    if (slider.value === 0) return;

    setAmount(slider.value * 100);
    onChangeSlider(slider.value * 100);
  }, [slider.value]);

  return (
    <div
      css={[disabled && tw`cursor-not-allowed`]}
      tw="background[rgb(224, 236, 255, .8)] w-full h-28 py-10 px-14 flex justify-center items-center rounded-lg"
    >
      <div
        css={[disabled && tw`opacity-60 pointer-events-none`]}
        ref={sliderRef}
        tw="w-full h-3 bg-sonnant-purple-2 rounded-lg relative cursor-pointer"
      >
        <div tw="flex flex-col items-center absolute left[85%] margin-left[-3.9rem] top[-8rem]">
          <div tw="text-12">Recommended</div>
          <ArrowDownSVG />
        </div>
        <div
          tw="absolute -top-3 flex justify-center"
          style={{
            left: `calc(${min([amount, 100])}% - 1rem)`,
          }}
        >
          <DirectionSvg
            tw="transform[rotate(90deg)]!"
            css={[svgShadow(0.2), hoverDropShadow(0.3)]}
          />
        </div>
        <div
          tw="absolute top-6 flex justify-center"
          style={{
            left: `calc(${min([amount, 100])}% - 1rem)`,
          }}
        >
          {amount.toFixed(0)}%
        </div>
      </div>
    </div>
  );
};
