import { MentionReportField } from 'utils/enum';

export const MENTION_COLUMN_HEADERS = [
  {
    header: ' ',
    accessor: '',
    id: 'Select',
    size: 40,
  },
  {
    header: ' ',
    accessor: '',
    id: MentionReportField.TYPE,
    size: 30,
    style: {
      width: '30px',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  {
    header: "#'s",
    accessor: 'id',
    id: MentionReportField.ID,
    size: 30,
    style: {
      width: '30px',
      textAlign: 'center',
    },
  },
  {
    header: 'Start Time',
    accessor: 'startTime',
    style: {
      textAlign: 'center',
      width: '100px',
    },
    id: MentionReportField.START_TIME,
    size: 100,
  },
  {
    header: 'End Time',
    accessor: 'endTime',
    style: {
      textAlign: 'center',
      width: '100px',
    },
    id: MentionReportField.END_TIME,
    size: 100,
  },
  {
    header: 'Duration',
    accessor: 'duration',
    style: {
      textAlign: 'center',
      width: '100px',
    },
    id: MentionReportField.DURATION,
    size: 100,
  },
  {
    header: 'Total #s',
    accessor: 'totalMentions',
    style: {
      textAlign: 'center',
      width: '80px',
    },
    id: MentionReportField.TOTAL,
    size: 80,
  },
  {
    header: 'Term',
    accessor: 'term',
    style: {
      textAlign: 'center',
      width: '100px',
    },
    id: MentionReportField.TERM,
    size: 100,
  },
  {
    header: 'IAB Categories',
    accessor: 'iab',
    style: {
      textAlign: 'center',
    },
    id: MentionReportField.IAB,
    size: 215,
  },
  {
    header: 'Transcript',
    accessor: 'transcript',
    style: {
      textAlign: 'center',
    },
    id: MentionReportField.TRANSCRIPT,
    size: 215,
  },
  {
    header: 'URL',
    style: {
      textAlign: 'center',
    },
    accessor: 'url',
    id: MentionReportField.URL,
    size: 50,
  },
];
