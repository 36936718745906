/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import React from 'react';
import { useDispatch } from 'react-redux';

import { updatePartialDemographics } from 'slices/demographics.slice';
import { defaultCategories } from 'utils/default/defaultSetting';
import { DemographicsCategory, DemographicsFieldName } from 'utils/models';
import { DemographicsDropdown } from './DemographicsDropdown';
import { scrollbarWidthChildCss } from 'components/shared/twin.styles';

type Props = {};

type SelectionProps = {
  categories: DemographicsCategory[];
};

export const DemographicsContainer = ({ ...props }: Props) => {
  const dispatch = useDispatch();

  const firstColumnItems = defaultCategories.filter(
    ({ column }) => column === 1,
  );

  const secondColumnItems = defaultCategories.filter(
    ({ column }) => column === 2,
  );

  const thirdColumnItems = defaultCategories.filter(
    ({ column }) => column === 3,
  );

  const handleSelectOption = (
    fieldName: DemographicsFieldName,
    options: string[],
  ) => {
    dispatch(
      updatePartialDemographics({
        fieldName,
        selectedValues: options,
      }),
    );
  };

  const DemographicsSelection = React.useCallback(
    ({ categories }: SelectionProps) => (
      <div css={[demographicsColumnCss, scrollbarWidthChildCss(4)]}>
        {categories.map((category, index) => (
          <DemographicsDropdown
            key={index}
            category={category}
            onSelectOptions={handleSelectOption}
          />
        ))}
      </div>
    ),
    [],
  );

  return (
    <div tw="flex flex-1 justify-between items-start flex-row gap-x-12">
      <DemographicsSelection categories={firstColumnItems} />
      <DemographicsSelection categories={secondColumnItems} />
      <DemographicsSelection categories={thirdColumnItems} />
    </div>
  );
};

const demographicsColumnCss = css`
  ${tw`flex flex-1 justify-center items-center flex-col space-y-4`}
`;
