/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Modal } from 'components/UiControls/modal/modal';
import { Keys, ModalOptions } from 'utils/enum';
import {
  getLatestTitle,
  mergeSameTerm,
  processCSV,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { ReactComponent as TagSvg } from 'assets/Icons/tag_24dp.svg';
import CloseSVG from 'components/UiControls/closeButtonSVG/closeSVG';
import { ReactComponent as Search } from 'assets/Icons/search.svg';
import { hoverDropShadow, noneBorder } from '../twin.styles';
import { IKeyItem } from 'utils/models';
import { Keyword } from 'components/VideoPlayer/Transcription/Keyword/Keyword';
import { isEmpty } from 'lodash';
import { IMediaEntities } from '../RichTextEditor/rich-text.model';
import { createManualKeyword, toSlateLineText } from 'utils/transcript.util';
import { CustomElement } from 'types/slate-custom-types';
import { customToast } from 'utils/toast.util';

interface IPromiseParams {
  resolve: (data: any) => void;
  reject: (err: any) => void;
}

interface Props {}

export const MentionAddTermModal = forwardRef(
  (props: Props, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const media = useSelector((state: RootState) => state.media);
    const player = useSelector((state: RootState) => state.player);

    const [termList, setTermList] = useState<IKeyItem[]>([]);
    const [newTerms, setNewTerms] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const searchedList = termList.filter((t) =>
      t?.keyword
        ?.trim()
        .toLowerCase()
        .includes(searchTerm.trim().toLowerCase()),
    );

    const [currentTerm, setCurrentTerm] = useState<IKeyItem>();

    const [isOpen, setIsOpen] = useState(false);
    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    useEffect(() => {
      if (isEmpty(player?.keywords) || isEmpty(player?.entities)) return;

      let terms: IKeyItem[] = [];

      player.layerCustomTerms.forEach((list) => {
        terms.push(...(list?.matched ?? []));
      });

      const entities: IMediaEntities = player?.entities;

      terms.push(...player.keywords);
      for (let entity in entities) {
        if (!isEmpty(entities?.[entity])) {
          terms.push(...entities[entity]!);
        }
      }

      terms = mergeSameTerm(terms).filter(
        (t) => !player.selectedTerms.some((s) => s?.keyword === t.keyword),
      );

      setTermList(terms.filter((t) => t?.mentions?.[0]?.occurrences?.length > 0));
    }, [player.keywords, player.entities, player.selectedTerms]);

    const show = async (): Promise<unknown> => {
      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const hideModal = () => {
      setCurrentTerm(undefined);
      setIsOpen(false);
    };

    const handleYes = () => {
      if (!currentTerm?.mentions?.[0]?.occurrences?.length) {
        customToast.error('There are no occurrences found');
        return;
      }

      hideModal();
      promiseInfo.current?.resolve({
        option: ModalOptions.YES,
        term: currentTerm,
      });
    };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current?.resolve(ModalOptions.CANCEL);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === Keys.ENTER) {
        addMultipleTerms();
        setNewTerms('');
      }
      if (e.key === Keys.ESC) {
        setNewTerms('');
      }
    };

    const addMultipleTerms = () => {
      if (isEmpty(newTerms.trim())) return;

      const splitTerms = newTerms.trim().split(',');

      if (isEmpty(splitTerms)) return;
      const isExisted = termList.filter(
        (term) => term.keyword.toLowerCase() === newTerms.trim().toLowerCase(),
      );
      if (!isEmpty(isExisted)) {
        customToast.error('Terms already existed');
        return;
      }

      const newKeywords = splitTerms.map((term) => {
        const transcripts: CustomElement = window.Editor.children;
        return createManualKeyword(
          term,
          toSlateLineText(transcripts),
          transcripts,
        );
      });

      setTermList(termList.concat(newKeywords));
    };

    const uploadCSV = (e: React.ChangeEvent<HTMLInputElement>) => {
      const uploadedFile = e.target.files?.[0];

      if (!uploadedFile || !uploadedFile.name.endsWith('.csv')) {
        customToast.error('Not supported file. Please try again.');
        return;
      }

      const reader = new FileReader();
      reader.onload = (event: any) => {
        const listData = processCSV(event.target.result);
        const newKeyList = listData.map((item: any) => {
          const transcripts: CustomElement = window.Editor.children;
          const newItem = item[0].split('\r')[0];
          return createManualKeyword(
            newItem,
            toSlateLineText(transcripts),
            transcripts,
          );
        });
        let checkExisted = false;

        newKeyList.forEach((newTerm: any) => {
          const isExisted = termList.filter(
            (term) =>
              term.keyword.toLowerCase() === newTerm.keyword.toLowerCase(),
          );
          if (!isEmpty(isExisted) && !checkExisted) {
            customToast.error('Terms already existed');
            checkExisted = true;
            return;
          }
        });
        if (checkExisted) {
          return;
        }

        setTermList(termList.concat(newKeyList));
        e.target.value = '';
      };
      try {
        reader.readAsText(uploadedFile);
      } catch (error: any) {
        customToast.error('Invalid CSV file');
      }
    };

    return (
      <Modal show={isOpen} modalClosed={hideModal}>
        <div className="userModal_Popus">
          <h1>Add term</h1>

          <div>
            <div tw="mt-4 mb-6 flex items-center line-height[1.5]">
              <div tw="w-full">
                <div tw="text-16 font-medium border[1px dotted lightgrey] py-2 px-3 rounded-lg">
                  {getLatestTitle(media.metadata)}
                </div>
              </div>
              <div tw="text-13 font-medium ml-6 text-sonnant-dark">
                <div tw="flex w-full whitespace-nowrap">
                  <div>Terms in list: {termList?.length ?? 0}</div>
                </div>
              </div>
            </div>
            <div tw="flex w-full justify-between">
              <div tw="flex-1 relative">
                <TagSvg tw="absolute top[1.2rem] left[1rem]" />
                <input
                  type="text"
                  placeholder="Add custom terms (use comma to separate multiple items)"
                  value={newTerms}
                  tw="(text-14 padding-left[3.2rem] placeholder:(text-12) focus:(border-transparent))!"
                  onChange={(e) => setNewTerms(e.target.value)}
                  onKeyDown={handleKeyDown}
                  autoFocus
                />
              </div>
              <div>
                <span>
                  <input
                    id="Csv"
                    type="file"
                    accept=".csv"
                    tw="hidden"
                    onChange={uploadCSV}
                  />
                  <label htmlFor="Csv">
                    <div className="button btn-primary large" tw="mr-4 ml-8">
                      Upload CSV (?)
                    </div>
                  </label>
                </span>
              </div>
              <div className="search-container" tw="flex -mr-1">
                <div tw="flex relative">
                  <input
                    className="input-group-field input-search"
                    tw="(height[4rem] rounded-r-none rounded-l pl-4 pr-2 font-size[1.5rem] caret-color[#7F8090])!"
                    css={[noneBorder]}
                    type="input"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <button
                  tw="(rounded-l-none height[4rem] width[4rem] mb-0 rounded-r focus:(bg-white) hover:(border[2px solid #F0F3F6]) box-shadow[none])!"
                  className="button btn-secondary"
                >
                  {searchTerm ? (
                    <CloseSVG
                      close={() => setSearchTerm('')}
                      color="#7F8090"
                      css={hoverDropShadow(0.2)}
                    />
                  ) : (
                    <Search css={hoverDropShadow(0.2)} />
                  )}
                </button>
              </div>
            </div>

            <div tw="flex flex-wrap content-start w-full p-4 mt-4 mb-6 overflow-y-auto height[40rem] border[1px solid lightgrey] rounded-lg">
              {searchedList.map((term, index) => (
                <Keyword
                  val={term}
                  currentKeyword={currentTerm}
                  isEditingTranscript={false}
                  handleRemoveKeyword={() => {}}
                  setCurrentKeyword={(term) => setCurrentTerm(term)}
                  disableEdit
                  showTooltip
                />
              ))}
              {termList?.length === 0 && (
                <div tw="color[#54566C] text-2xl font-medium mb-6">
                  There are no terms in list
                </div>
              )}
              {searchedList.length === 0 && termList?.length > 0 && (
                <div tw="color[#54566C] text-2xl font-medium mb-6">
                  No results found
                </div>
              )}
            </div>
          </div>

          <div className="grid-x">
            <div className="cell small-12">
              <div className="btn_section">
                <button
                  type="button"
                  className="button cancel"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="button delete"
                  onClick={handleYes}
                  disabled={!currentTerm}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);
