/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import tw from 'twin.macro';

import { Row } from '@tanstack/react-table';
import * as actions from 'actions';
import { renameLibraryItem } from 'actions';
import { ReactComponent as Audio } from 'assets/Icons/audio_list.svg';
import { ReactComponent as Lock } from 'assets/Icons/lock_list.svg';
import { ReactComponent as Video } from 'assets/Icons/video_type.svg';
import { Hint } from 'components/shared/Hint';
import { PrettyCheckbox } from 'components/shared/PrettyCheckbox';
import { ThreeDotMenu } from 'components/shared/ThreeDotMenu/ThreeDotMenu';
import {
  addQueryToUrl,
  canRedirect,
  hasLockedByStatusText,
  isAudioByMIME,
  isPaymentRequired,
  msToTime,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { memo, useEffect, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useClickAway, useToggle } from 'react-use';
import { RootState } from 'reducers';
import { toDateWithHours } from 'utils/date.util';
import { Keys } from 'utils/enum';
import { ILibraryItem } from 'utils/models';
import { Notification } from '../../UiControls/notification/Notification';
import { DragCounter } from '../ThumbNailsItem/DragCounter';

import { useItemFunction } from 'hooks/useItemFunction';
import { ListViewTitle } from './ListViewTitle';
import { isEmpty, toNumber } from 'lodash';

interface IProps {
  itemRow: Row<ILibraryItem>;
  setLibraryItem?: any;

  index: number;
}

export const ListViewRow = memo(function ListViewRowMemorized({
  index,
  itemRow,
}: IProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const libraryItem = itemRow.original;

  const { selectedRows, isSelectMode } = useSelector(
    (state: RootState) => state.library,
  );
  const collection = useSelector((state: RootState) => state.collection);
  const global = useSelector((state: RootState) => state.global);

  const [itemTitle, setItemTitle] = useState<string | null>(
    libraryItem?.title ?? '',
  );
  const [isRenaming, toggleRenaming] = useToggle(false);

  const [isHovering, toggleHovering] = useToggle(false);

  const [error, setError] = useState({
    isInvalidTitle: false,
  });

  const {
    handleShare,
    handleDeleteSingleItem,
    handlePublish,
    handleExport,
    handleIntegrate,
    handleCopyLink,
    handleMentionReport,
    handleViewClip,
  } = useItemFunction({
    item: libraryItem,
  });

  const cells = itemRow.getVisibleCells();

  useEffect(() => {
    setItemTitle(libraryItem?.title ?? '');
  }, [libraryItem]);

  useEffect(() => {
    if (isRenaming === false && error.isInvalidTitle) {
      setItemTitle(null); // set to default value
    }
  }, [isRenaming]);

  const library = useSelector((state: RootState) => state.library);

  const handleRedirectClick = () => {
    if (isPaymentRequired(libraryItem)) {
      history.push('/account-settings#billing');
      return;
    }
    if (!canRedirect(libraryItem)) return;

    const selection = window.getSelection();
    if (selection?.type === 'Range') return; // Has selection

    let transcriptHistory = `/transcription/${libraryItem.mediaid}`;

    if (library.searchTerm) {
      transcriptHistory = addQueryToUrl(
        transcriptHistory,
        `search=${library.searchTerm}`,
      );
    }

    if (global.preference.paragraphMode) {
      transcriptHistory = addQueryToUrl(transcriptHistory, 'mode=paragraph');
    }

    history.push(transcriptHistory, {
      libraryItem: libraryItem,
      mediaid: libraryItem.mediaid,
      filename: libraryItem.title,
    });
  };

  const handleKeyDownTitle = (event: any) => {
    if (event.key === Keys.ESC) {
      toggleRenaming(false);
      setError({ isInvalidTitle: false });
      setItemTitle(libraryItem?.title);
    } else if (event.key === Keys.ENTER || event.key === Keys.ENTER_NUMPAD) {
      const newTitle = itemTitle?.trim();

      if (!newTitle) {
        return setError({ isInvalidTitle: true });
      }

      const payload = { mediaid: libraryItem.mediaid, title: newTitle };

      setItemTitle(newTitle);
      dispatch(renameLibraryItem(payload));
      toggleRenaming(false);
    }
  };

  const [hiddenSimpleMenu, setHiddenSimpleMenu] = useState(false);

  const ref = useRef(null);

  const closeInput = () => {
    if (error.isInvalidTitle) setItemTitle(null);
    setError({ isInvalidTitle: false });
    toggleRenaming(false);
  };
  const clickThreeDot = () => {
    dispatch(actions.setSelectedRows([libraryItem]));
  };

  useClickAway(ref, () => {
    setHiddenSimpleMenu(false);

    if (!itemTitle?.trim()) {
      setError({ isInvalidTitle: true });
    }

    if (error.isInvalidTitle) {
      setItemTitle(null);
      closeInput();
    }
  });

  const isSelected = () => {
    return selectedRows.some(
      (row: ILibraryItem) => row.mediaid === libraryItem.mediaid,
    );
  };

  const handleItemClicked = (clickedItem: ILibraryItem) => {
    let cloneSelectedRows = [...selectedRows];
    if (
      selectedRows.some(
        (row: ILibraryItem) => row.mediaid === clickedItem.mediaid,
      )
    ) {
      cloneSelectedRows = selectedRows.filter(
        (row: ILibraryItem) => row.mediaid !== clickedItem.mediaid,
      );
    } else {
      cloneSelectedRows.push(clickedItem);
    }

    if (isEmpty(cloneSelectedRows) && isSelectMode) {
      dispatch(actions.setSelectMode(false));
    } else {
      dispatch(actions.setSelectMode(true));
    }
    dispatch(actions.setSelectedRows(cloneSelectedRows));
  };

  return (
    <Draggable
      isDragDisabled={!collection?.showSidebar}
      draggableId={libraryItem.mediaid}
      index={index}
      shouldRespectForcePress={false}
      disableInteractiveElementBlocking
      key={itemRow.id}
    >
      {(provided, snapshot) => (
        <tr
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          css={[
            snapshot.isDragging &&
              tw`(border[2px solid] border-sonnant-purple-2 background[#f1f1f1] shadow-lg rounded-md pl-4 pb-1)!`,
            !snapshot.isDragging && tw`transform[none]!`,
          ]}
          tw="hover:(bg-sonnant-grey-light) cursor-pointer"
          className={`list-container ${
            isSelectMode && isSelected() && 'active'
          }`}
          onClick={(e) => {
            if (isSelectMode) {
              //e.stopPropagation();
              handleItemClicked(libraryItem);
            } else {
              handleRedirectClick();
            }
          }}
          onMouseEnter={() => toggleHovering(true)}
          onMouseLeave={() => toggleHovering(false)}
          key={itemRow.id}
        >
          <td key={cells[0]?.id} style={{ width: cells[0]?.column?.getSize() }}>
            {(isHovering || isSelected()) && (
              <div className="select_button">
                <PrettyCheckbox
                  checked={isSelected()}
                  isListView
                  onChange={() => handleItemClicked(libraryItem)}
                />
              </div>
            )}
            {snapshot.isDragging && library.isSelectMode && (
              <DragCounter isListView mediaid={libraryItem?.mediaid} />
            )}
          </td>
          <td
            key={cells[1]?.id}
            width="5%"
            className="bottom"
            tabIndex={1}
            tw="min-width[52px]"
            style={{
              width: cells[1]?.column?.getSize(),
              textAlign: 'center',
            }}
          >
            <div className="overlay_lock_icon" tw="(flex items-center)!">
              {isAudioByMIME(
                libraryItem?.mediacontenttype ?? libraryItem?.mediatype,
              ) ? (
                <Hint text="Audio file">
                  <Audio />
                </Hint>
              ) : (
                <Hint text="Video file">
                  <Video />
                </Hint>
              )}
            </div>
          </td>
          <td
            key={cells[2]?.id}
            width={'20%'}
            style={{ width: cells[2]?.column?.getSize() }}
            tw="min-width[300px]!"
            className="bottom"
            tabIndex={1}
            onClick={(e) => {
              isRenaming && e.stopPropagation();
            }}
          >
            {isRenaming ? (
              <div tw="relative">
                <input
                  type="text"
                  name="title"
                  onKeyDown={handleKeyDownTitle}
                  value={itemTitle ?? libraryItem.title}
                  onChange={(e) => setItemTitle(e.target.value)}
                  //onBlur={()=>{toggleRenaming(false)}}
                  onBlur={closeInput}
                  placeholder="Title"
                  maxLength={128}
                  autoFocus={true}
                />
                <Notification
                  direction=""
                  name={'emailError'}
                  customStyle={{ top: '3.5rem', left: 0 }}
                  close={closeInput}
                  type={error.isInvalidTitle ? 'error' : null}
                  message={'Invalid Title.'}
                />
              </div>
            ) : (
              <ListViewTitle
                title={itemTitle ?? libraryItem?.title}
                summary={libraryItem?.summary}
                isSensitive={!!libraryItem?.is_sensitive}
                sensitiveJsonRaw={libraryItem?.sensitive_items}
                mediaid={libraryItem?.mediaid}
              />
            )}
          </td>
          <td
            key={cells[3]?.id}
            width="5%"
            tw="min-width[144px]!"
            style={{
              width: cells[3]?.column?.getSize(),
              textAlign: 'center',
            }}
          >{`${msToTime(parseInt(libraryItem.length))}`}</td>
          <td
            key={cells[4]?.id}
            style={{ width: cells[4]?.column?.getSize() }}
            width="7%"
            tw="min-width[144px]! text-center"
            css={[snapshot.isDragging && tw`md-down:display[none]!`]}
          >
            {libraryItem.speakers}
          </td>
          <td
            key={cells[5]?.id}
            style={{
              width: cells[5]?.column?.getSize(),
              textAlign: 'center',
            }}
            width="10%"
            tw="min-width[164px]"
            css={[snapshot.isDragging && tw`md-down:display[none]!`]}
            className="bottom"
            tabIndex={1}
          >
            {toDateWithHours(libraryItem.modifieddatetime)}
          </td>
          <td
            key={cells[6]?.id}
            style={{
              width: cells[6]?.column?.getSize(),
              textAlign: 'center',
            }}
            width="10%"
            tw="min-width[164px]"
            css={[snapshot.isDragging && tw`md-down:display[none]!`]}
            className="bottom"
            tabIndex={1}
          >
            {toDateWithHours(libraryItem.createddatetime)}
          </td>
          <td
            key={cells[7]?.id}
            style={{
              width: cells[7]?.column?.getSize(),
              textAlign: 'center',
            }}
            css={[
              snapshot.isDragging && tw`md-down:display[none]!`,
              isPaymentRequired(libraryItem) &&
                tw`hover:(underline cursor-pointer)`,
            ]}
            width="5%"
            tw="min-width[80px]"
            tabIndex={1}
            className="bottom"
          >
            {libraryItem.status}
          </td>
          <td
            key={cells[8]?.id}
            style={{
              width: cells[8]?.column?.getSize(),
            }}
            width="5%"
            tw="min-width[80px]"
            css={[snapshot.isDragging && tw`md-down:display[none]!`]}
          >
            {hasLockedByStatusText(libraryItem?.status) && (
              <Hint text="Locked for editing">
                <div
                  className="overlay_lock_icon"
                  tw="flex justify-center items-center"
                >
                  <Lock />
                </div>
              </Hint>
            )}
          </td>
          <td
            key={cells[9]?.id}
            style={{ width: cells[9]?.column?.getSize() }}
            width="5%"
            tw="min-width[80px] text-center"
            css={[snapshot.isDragging && tw`md-down:display[none]!`]}
          >
            {libraryItem.words}
          </td>
          {!isSelectMode ? (
            <td
              key={cells[10]?.id}
              style={{ width: cells[10]?.column?.getSize() }}
              width="5%"
              css={[snapshot.isDragging && tw`md-down:display[none]!`]}
              className={`${
                toNumber(libraryItem.publishcount) > 0 ? '' : 'disabled'
              }`}
              tw="cursor-pointer text-center hover:(color[#5551ff] underline) min-width[52px]"
              onClick={(e) => {
                e.stopPropagation();
                if (toNumber(libraryItem.publishcount) > 0) {
                  history.push({
                    pathname: `/clips/${libraryItem?.mediaid}`,
                    state: libraryItem,
                  });
                }
              }}
            >
              {libraryItem.publishcount}
            </td>
          ) : (
            <td
              key={cells[10]?.id}
              style={{
                width: cells[10]?.column?.getSize(),
                textAlign: 'center',
              }}
              width="5%"
              css={[snapshot.isDragging && tw`md-down:display[none]!`]}
              tw="text-center min-width[52px]"
            >
              {libraryItem.publishcount}
            </td>
          )}
          <td
            key={cells[11]?.id}
            style={{ width: cells[11]?.column?.getSize() }}
            width="5%"
            tw="text-center min-width[52px]"
            css={[
              !libraryItem?.viewed && tw`opacity-30`,
              snapshot.isDragging && tw`md-down:display[none]!`,
            ]}
          >
            {libraryItem?.viewed}
          </td>
          <td
            key={cells[12]?.id}
            style={{
              width: cells[12]?.column?.getSize(),
              textAlign: 'center',
            }}
            width="11%"
            tw="overflow-hidden text-overflow[ellipsis] padding[1px] min-width[164px]"
            css={[snapshot.isDragging && tw`md-down:display[none]!`]}
          >
            {libraryItem.userid}
          </td>
          <td
            key={cells[13]?.id}
            style={{ width: cells[13]?.column?.getSize() }}
            width="5%"
            css={[snapshot.isDragging && tw`display[none]!`]}
            className="customThumbnail"
            onClick={(e) => {
              if (!isSelectMode) {
                e.stopPropagation();
              }
            }}
          >
            <div
              ref={ref}
              className="dropdown show"
              onClick={(e) => {
                setHiddenSimpleMenu(!hiddenSimpleMenu);
              }}
            >
              <ThreeDotMenu
                openMenu={isSelectMode ? false : hiddenSimpleMenu}
                clickThreeDot={clickThreeDot}
                itemListView={itemRow}
                handleRename={() => toggleRenaming(true)}
                handlePublish={handlePublish}
                handleShare={handleShare}
                handleDelete={handleDeleteSingleItem}
                handleExport={handleExport}
                handleIntegrate={handleIntegrate}
                handleMentionReport={handleMentionReport}
                handleCopyLink={handleCopyLink}
                handleViewClip={handleViewClip}
              />
            </div>
          </td>
        </tr>
      )}
    </Draggable>
  );
});
