/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import 'twin.macro';
import tw from 'twin.macro';

import { Ripple } from '@rmwc/ripple';
import { ReactComponent as VisibilityOff } from 'assets/Icons/VisibilityOff.svg';
import { ReactComponent as VisibilityOn } from 'assets/Icons/VisibilityOn.svg';
import { ReactComponent as AddCircle } from 'assets/Icons/add_circle.svg';
import { ReactComponent as ExpandCircle } from 'assets/Icons/expand_circle.svg';
import { ReactComponent as VerifiedSvg } from 'assets/Icons/verified.svg';
import omnyLogo from 'assets/Images/OmnyLogo.png';
import { QuestionHint } from 'components/shared/QuestionHint';
import { Switcher } from 'components/shared/Switcher';
import React, { ReactElement, useEffect, useRef } from 'react';
import { useToggle } from 'react-use';

import { useDispatch, useSelector } from 'react-redux';
import { tierSelector } from 'slices/payment.slice';

import { CollapsibleList } from '@rmwc/list';
import { CollectionDropdown } from 'components/shared/CollectionDropdown/CollectionDropdown';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { Hint } from 'components/shared/Hint';
import { debounce, isEmpty, isNil } from 'lodash';
import { useCallback, useState } from 'react';
import { OmnyService } from 'services/omny.service';
import { removeIntegration, updateSingleIntegration } from 'slices/omny.slice';
import { toOmnyProgramOptions } from 'utils/adapter.util';
import { CollectionOptionEnum, HTTPStatus, ModalOptions } from 'utils/enum';
import {
  IntegrationOptions as IntegrationOption,
  ProgramIdOption,
} from 'utils/models';
import { customToast } from 'utils/toast.util';
import { IntegrationFilter } from './IntegrationFilter';

type Props = {
  integration: IntegrationOption;
};

export const IntegrationItem = ({ integration }: Props) => {
  const dispatch = useDispatch();
  const confirmRemoveModalRef = useRef<any>(null);

  const tier = useSelector(tierSelector);
  const notSubscriber = !tier.isSubscriber;

  const [shouldShowApiKey, toggleShowApiKey] = useToggle(false);
  const [shouldShowWebhook, toggleShowWebhook] = useToggle(false);

  const [isOpenFilter, toggleOpenFilter] = useToggle(false);
  const [isFetchingPing, toggleFetchingPing] = useToggle(false);
  const [isValidApiKey, toggleValidApiKey] = useToggle(false);

  const [listProgramId, setListProgramId] = useState<ProgramIdOption[]>([]);
  const [checkedSavedApiKey, toggleCheckedSavedApiKey] = useToggle(false);

  useEffect(() => {
    // Validate first fetching
    const savedApiKey = integration?.apiKey?.trim();

    if (isNil(savedApiKey) || isEmpty(savedApiKey) || checkedSavedApiKey)
      return;

    validateKey(savedApiKey);
    toggleCheckedSavedApiKey(true);
  }, [integration?.apiKey]);

  const handleGetProgramId = async (apiKey: string) => {
    try {
      const res = await OmnyService.getProgramId(apiKey);

      if (res.status === HTTPStatus.SUCCESS) {
        toggleValidApiKey(true);

        const { programs } = res.data;

        if (!isEmpty(programs)) {
          const programIdOptions = toOmnyProgramOptions(programs);

          setListProgramId(programIdOptions);
        }
      }
    } catch (err) {
      console.log(err);
      setListProgramId([]);
      toggleValidApiKey(false);
    } finally {
      toggleFetchingPing(false);
    }
  };

  const validateKey = async (key: string) => {
    toggleFetchingPing(true);

    await handleGetProgramId(key);
  };

  const handleToggleOptions = (optionLabel: keyof IntegrationOption) => {
    const isChecked = !integration[optionLabel];

    dispatch(
      updateSingleIntegration({ ...integration, [optionLabel]: isChecked }),
    );
  };

  const validateApiKeyAsync = async (value: string) => {
    toggleFetchingPing(true);
    toggleValidApiKey(false);

    await handleGetProgramId(value);
  };

  const debounceAuthorizeKey = useCallback(
    debounce(validateApiKeyAsync, 300),
    [],
  );

  const handleInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: keyof IntegrationOption,
  ) => {
    const value = e?.target?.value;

    dispatch(
      updateSingleIntegration({
        ...integration,
        [fieldName]: value,
      }),
    );

    if (fieldName === 'apiKey') {
      debounceAuthorizeKey(value);
    }
  };

  const handleRemoveIntegration = async () => {
    const result = await confirmRemoveModalRef.current?.show();

    if (result !== ModalOptions.YES) return;

    dispatch(removeIntegration({ integrationId: integration?.id }));
    customToast.success('Removed');
  };

  const renderValidationMsg = (): ReactElement => {
    if (isEmpty(integration?.apiKey)) return <></>;

    if (isFetchingPing)
      return (
        <small tw="text-10 text-sonnant-orange">Validating API Key...</small>
      );

    if (isValidApiKey)
      return (
        <small tw="flex gap-1.5 items-center text-10 text-sonnant-green font-medium">
          Valid API Key
          <VerifiedSvg tw="zoom[0.9]" />
        </small>
      );

    return (
      <small tw="text-10 text-sonnant-red">
        Invalid API Key. Please check and try again!
      </small>
    );
  };

  return (
    <div tw="flex flex-row py-6 w-full">
      <div tw="min-width[20rem] width[50rem] padding[5rem]">
        <img tw="w-[95%]" src={omnyLogo} alt="omny-logo" />
      </div>

      <div tw="flex flex-col w-full pl-[3rem]">
        <div tw="flex w-full items-center">
          <div tw="min-w-[50%]">
            <div tw="flex flex-col pb-0!">
              <div tw="flex flex-row items-center">
                <label tw="flex flex-nowrap mr-3 text-16">API Key</label>
                <QuestionHint
                  notTransparent
                  hideCloseButton
                  type={'information'}
                  align={'bottomLeft'}
                  action={'click'}
                  text={
                    <div tw="py-1">
                      <p>
                        To enable Sonnant to write metadata back to Omny Studio
                        an API Key needs to be generated in Omny Studio. This
                        can be done via the “New API” button on the
                        “Organization settings {'>'} API Keys” page -{' '}
                        <a
                          href="https://omnystudio.com/settings/apikeys"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://omnystudio.com/settings/apikeys
                        </a>
                        . The Omny Studio export option will only be available
                        for content items that were uploaded from Omny Studio.
                        This option is only available to subscription and
                        enterprise customers.
                      </p>
                    </div>
                  }
                  customStyle={customTooltipCss}
                />
              </div>
              <div tw="flex flex-row max-width[75rem] md-down:(width[40rem])">
                <div tw="flex-1 relative ">
                  <input
                    tw="flex padding-right[8rem]! mb-0"
                    css={[
                      !isFetchingPing &&
                        !isValidApiKey &&
                        tw`(border-red-500 border-width[1px])!`,
                    ]}
                    type={!shouldShowApiKey ? 'password' : 'text'}
                    value={integration.apiKey}
                    onChange={(e) => handleInputChange(e, 'apiKey')}
                    placeholder="**********************************"
                  />

                  {renderValidationMsg()}

                  {isFetchingPing && (
                    <Hint text={'Validating API Key'}>
                      <div css={customLoading} />
                    </Hint>
                  )}

                  <span onClick={toggleShowApiKey}>
                    {shouldShowApiKey ? (
                      <VisibilityOn tw="margin-top[1px]" css={customIconCss} />
                    ) : (
                      <VisibilityOff css={customIconCss} />
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div tw="flex flex-col pb-2 pt-0!">
              <div tw="flex flex-row items-center">
                <label tw="flex flex-nowrap text-16 mr-3">
                  Webhook Signing Secret{' '}
                </label>
                <QuestionHint
                  notTransparent
                  hideCloseButton
                  type={'information'}
                  align={'bottomLeft'}
                  action={'click'}
                  text={
                    <div tw="py-1">
                      <p>
                        To automatically upload items from Omny Studio into your
                        Sonnant Library, a webhook needs to be configured in
                        Omny Studio. This can be done via the “Create webhook”
                        button on the “Organization settings {'> '}
                        Webhooks” page -{' '}
                        <a
                          href="https://omnystudio.com/settings/webhooks"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://omnystudio.com/settings/webhooks
                        </a>
                        . When creating the webhook use the following URL:{' '}
                        <a
                          href="https://yourdomain.sonnant.ai/api/v2.1/omnystudio"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://yourdomain.sonnant.ai/api/v2.1/omnystudio
                        </a>
                        . Where “yourdomain” is replaced by your subdomain. A
                        Webhook Signing Secret is required. This is just a
                        password or phrase that will be used to create a hash to
                        ensure only valid notifications are processed. The “Clip
                        created” specific event should be checked in Omny
                        Studio. This option is only available to subscription
                        and enterprise customers.
                      </p>
                    </div>
                  }
                  customStyle={customTooltipCss}
                />
              </div>

              <div tw="flex flex-row max-width[75rem]">
                <div tw="flex-1 relative ">
                  <input
                    tw="flex padding-right[4rem]!"
                    type={!shouldShowWebhook ? 'password' : 'text'}
                    value={integration.webhookSecret}
                    onChange={(e) => handleInputChange(e, 'webhookSecret')}
                    placeholder="**********************************"
                  />
                  <span onClick={toggleShowWebhook}>
                    {shouldShowWebhook ? (
                      <VisibilityOn css={[customIconCss]} />
                    ) : (
                      <VisibilityOff css={[customIconCss]} />
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div tw="flex flex-col pb-6">
              <div tw="flex flex-row items-center">
                <label tw="flex flex-nowrap text-16 mr-3">
                  Organisation/Integration Name
                </label>
                <QuestionHint
                  notTransparent
                  hideCloseButton
                  type={'information'}
                  align={'bottomLeft'}
                  action={'click'}
                  text={
                    <div>
                      Organisation name will be used to identify the Omny
                      organization from which the clip was populated into
                      Sonnant.
                    </div>
                  }
                  customStyle={customTooltipCss}
                />
              </div>

              <div tw="flex flex-row max-width[75rem]">
                <div tw="flex-1 relative ">
                  <input
                    tw="flex padding-right[4rem]!"
                    type="text"
                    value={integration.integrationName}
                    onChange={(e) => handleInputChange(e, 'integrationName')}
                    placeholder="Please enter a name to uniquely identify the integration"
                  />
                </div>
              </div>
            </div>
          </div>
          <Hint
            text="Not available on free trial and PAYG. Please upgrade to enable."
            enterDelay={0}
            arrow
            disabled={!notSubscriber}
            align={'bottom'}
          >
            <div css={customEnableSwitcherLabelCss}>
              <Switcher
                hasLabel={true}
                checked={integration.isEnabled}
                disabled={notSubscriber}
                color="#00AF7A"
                label={'ENABLED'}
                onCheckedChange={() => handleToggleOptions('isEnabled')}
              />
            </div>
          </Hint>
        </div>

        {/* <div css={collapsibleCustomCss}> */}
        <div>
          <CollapsibleList
            handle={
              <Ripple tw="py-3 pl-2.5 pr-3 rounded" onClick={toggleOpenFilter}>
                <div tw="inline-flex space-x-2.5 items-center cursor-pointer">
                  {isOpenFilter ? (
                    <ExpandCircle fill="#7f8090" />
                  ) : (
                    <AddCircle fill="#7f8090" />
                  )}
                  <div tw="flex flex-nowrap text-16 font-medium text-sonnant-grey-6 opacity-95">
                    Program filters
                  </div>
                </div>
              </Ripple>
            }
            open={isOpenFilter}
          >
            <IntegrationFilter
              programIds={listProgramId}
              integration={integration}
              isLoading={isFetchingPing}
            />
          </CollapsibleList>
        </div>
        <div tw="flex mt-2.5 mb-5 [> div]:(flex items-center text-15 font-medium text-sonnant-grey-6 space-x-3)!">
          <div>
            <span>Add to collection</span>
            <span
              css={[
                integration.filter.isUseProgramName &&
                  tw`pointer-events-none opacity-70`,
              ]}
            >
              <CollectionDropdown
                defaultValue={integration.collectionId ?? ''}
                onChange={(value) => {
                  dispatch(
                    updateSingleIntegration({
                      ...integration,
                      collectionOption: CollectionOptionEnum.COLLECTION,
                      collectionId: value,
                    }),
                  );
                }}
                menuPlacement="top"
                isMultiSelect={false}
              />
            </span>
          </div>
        </div>
      </div>
      {tier.isSubscriber && (
        <div
          className="btnReset"
          tw="w-full flex justify-end md-down:(flex-col)! width[1rem]!"
        >
          <div>
            <Hint text="Remove integration" enterDelay={200}>
              <button
                type="button"
                className={`success button apply`}
                tw="bg-sonnant-red!"
                onClick={handleRemoveIntegration}
              >
                Remove
              </button>
            </Hint>
          </div>
        </div>
      )}

      <ConfirmModal
        ref={confirmRemoveModalRef}
        message={
          <div>
            <div>
              Removing an integration will stop any linked content items from
              sending updated data back to the source system. This is
              irreversible. <b>Would you like to proceed?</b>
            </div>
          </div>
        }
        title="Remove integration"
        confirmText="Confirm"
      />
    </div>
  );
};

export const customLoading = css`
  ${tw`absolute top[0rem] right[6.5rem] inline-block w-[1rem] h-[1rem]`}

  :after {
    content: ' ';
    display: block;
    width: 20px;
    height: 20px;
    margin: 11px;
    border-radius: 50%;
    border: 2px solid #7f8090;
    border-color: #7f8090 transparent #7f8090 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const customTooltipCss = css`
  ${tw`font-size[1rem]`}

  .notification {
    ${tw`lg-up:width[60rem]! md-down:(width[40rem] break-words)`}
  }
  .notificationWrapper {
    ${tw`margin-top[1rem]!`}
  }
`;

export const customIconCss = css`
  ${tw`absolute top[1.2rem] right[1rem] cursor-pointer`}
`;

export const customEnableSwitcherLabelCss = css`
  ${tw`width[7rem] flex mb-2 margin-left[3rem]`}

  .btn-group {
    ${tw`flex flex-col items-center`}
  }

  span {
    :first-of-type {
      ${tw`font-bold uppercase font-size[15px]`}
    }
  }
`;

// const collapsibleCustomCss = css`
//   .rmwc-collapsible-list__children-inner,
//   .rmwc-collapsible-list__children {
//     ${tw`overflow-visible!`}
//   }
// `;
