import { Option } from 'components/shared/CustomSelectSearch';
import { defaultTo, isEmpty, uniqBy } from 'lodash';
import { v4 } from 'uuid';
import {
  CalendarFilter,
  Layers,
  PinnedChartType,
  SearchField,
  SortField,
} from './enum';
import {
  AdMarkerAdvertising,
  AdvertisingCollection,
  Bucket,
  CollectionMonetisation,
  IABItemModel,
  IabTagsAdvertising,
  Insights,
  InsightTerm,
  IntegrationSettingsPayload,
  PinnedChartBackend,
  PlaylistIdOption,
  ProgramIdOption,
  SimilarShowsRaw,
  Term,
  TopShow,
} from './models';
import { PinnableChart } from 'slices/insight.slice';
import { TagCollectionResponse } from 'services/tag.service';
import { NestedCircleChartData } from 'components/shared/InsightChart/CircleParkingChart';
import randomColor from 'randomcolor';
import {
  OmnyPreviewPlaylistResponse,
  OmnyPreviewProgramResponse,
} from 'services';
import { ARCHIVE_TAG_NAME } from './constants';

export const getSortName = (sortField: SortField) => {
  switch (sortField) {
    case SortField.MODIFIED:
      return 'Modified';
    case SortField.TITLE:
      return 'Title';
    case SortField.TYPE:
      return 'Type';
    case SortField.DURATION:
      return 'Duration';
    case SortField.SPEAKER:
      return 'Speakers';
    case SortField.DATE_ADDED:
      return 'Date Added';
    case SortField.STATUS:
      return 'Status';
    case SortField.LOCKED:
      return 'Locked';
    case SortField.WORD:
      return 'Words';
    case SortField.CLIP:
      return 'Clips';
    case SortField.CREATED_BY:
      return 'Created By';
    case SortField.VIEWS:
      return 'Views';
    default:
      return '';
  }
};

export const getInsightLayerName = (key: keyof Insights): string => {
  switch (key) {
    case 'keywords':
      return 'Key Terms';
    case 'iab':
      return 'IAB Categories';
    case 'people':
      return 'People';
    case 'products':
      return 'Products';
    case 'orgs':
      return 'Organisations';
    case 'locations':
      return 'Locations';
    case 'nationalities':
      return 'Nationalities';
    case 'speakers':
      return 'Speakers by Content Items';
    case 'sensitive':
      return 'Sensitive Topics';
    case 'transcripts':
      return 'Transcripts';
    default:
      return 'Terms';
  }
};

export const getSearchFieldByInsight = (key: keyof Insights): SearchField => {
  switch (key) {
    case 'keywords':
      return SearchField.KEYWORD;
    case 'iab':
      return SearchField.IAB;
    case 'people':
      return SearchField.PEOPLE;
    case 'products':
      return SearchField.PRODUCT;
    case 'orgs':
      return SearchField.ORG;
    case 'locations':
      return SearchField.LOCATION;
    case 'nationalities':
      return SearchField.NATIONALITY;
    case 'sensitive':
      return SearchField.IAB;
    default:
      return SearchField.KEYWORD;
  }
};

export const rawStringToTerm =
  (selected: boolean) =>
  (rawString: string): InsightTerm => {
    return {
      id: v4(),
      name: rawString,
      selected: selected,
      isInputTerm: true,
    } as InsightTerm;
  };

export const setSelected = (selected: boolean) => (term: Term) => {
  return {
    ...term,
    selected: selected,
    isInputTerm: false,
  } as InsightTerm;
};

export const ensureAdvanceIntegrationFormat = ({
  hasMonetisation,
  hasChapters,
  hasTags,
  hasSummary,
  isAutomatePushBack,
}: Partial<IntegrationSettingsPayload>): IntegrationSettingsPayload => ({
  hasMonetisation: defaultTo(hasMonetisation, false),
  hasChapters: defaultTo(hasChapters, false),
  hasTags: defaultTo(hasTags, false),
  hasSummary: defaultTo(hasSummary, false),
  isAutomatePushBack: defaultTo(isAutomatePushBack, false),
});

export const ensureCollectionMoneFormat = ({
  bakedInConfig,
  silenceConfig,
  jingleConfig,
  omnyMidrollsConfig,
  multipleAdsConfig,
  textSearchConfig,
}: Partial<CollectionMonetisation>): CollectionMonetisation => {
  return {
    bakedInConfig: {
      isEnabled: defaultTo(bakedInConfig?.isEnabled, false),
      countAsMidroll: defaultTo(bakedInConfig?.countAsMidroll, false),
      placement: defaultTo(bakedInConfig?.placement, 'after'),
      jingleIdentification: defaultTo(
        bakedInConfig?.jingleIdentification,
        false,
      ),
    },
    silenceConfig: {
      isEnabled: defaultTo(silenceConfig?.isEnabled, false),
      alwaysPlaceMidroll: defaultTo(silenceConfig?.alwaysPlaceMidroll, false),
      silenceDuration: defaultTo(silenceConfig?.silenceDuration, 3),
    },
    jingleConfig: {
      isEnabled: defaultTo(jingleConfig?.isEnabled, false),
      alwaysPlaceMidroll: defaultTo(jingleConfig?.alwaysPlaceMidroll, false),
      placement: defaultTo(jingleConfig?.placement, 'after'),
      isPrerollCheck: defaultTo(jingleConfig?.isPrerollCheck, false),
      isPostrollCheck: defaultTo(jingleConfig?.isPostrollCheck, false),
      detector: defaultTo(jingleConfig?.detector, 'music'),
    },
    omnyMidrollsConfig: {
      isEnabled: defaultTo(omnyMidrollsConfig?.isEnabled, false),
      countAsMidroll: defaultTo(omnyMidrollsConfig?.countAsMidroll, false),
      placement: defaultTo(omnyMidrollsConfig?.placement, 'anywhere'),
    },
    multipleAdsConfig: {
      isEnabled: defaultTo(multipleAdsConfig?.isEnabled, false),
      other: defaultTo(multipleAdsConfig?.other, 1),
      bakedIn: defaultTo(multipleAdsConfig?.bakedIn, 1),
      topicChanged: defaultTo(multipleAdsConfig?.topicChanged, false),
    },
    textSearchConfig: {
      isEnabled: defaultTo(textSearchConfig?.isEnabled, false),
      terms: defaultTo(textSearchConfig?.terms, []),
      placement: defaultTo(textSearchConfig?.placement, 'after'),
    },
  };
};

export type ColorBucket = Omit<Bucket, 'is_enabled' | 'alias'> & {
  color: string;
};

export const toColumChartAdSlots = (
  adMarkers: AdMarkerAdvertising,
): ColorBucket[] => {
  return [
    {
      key: 'PreRolls',
      doc_count: adMarkers['pre_rolls'].total_count,
      color: '#2983FF',
    },
    {
      key: 'MidRolls',
      doc_count: adMarkers['mid_rolls'].total_count,
      color: '#5551FF',
    },
    {
      key: 'PostRolls',
      doc_count: adMarkers['post_rolls'].total_count,
      color: '#2983FF',
    },
  ];
};

export const toColumChartIabTags = (
  iabTags: IabTagsAdvertising,
): ColorBucket[] => {
  return [
    {
      key: 'High Relevance',
      doc_count: iabTags['high_relevance'].total_count,
      color: '#00E396',
    },
    {
      key: 'Medium Relevance',
      doc_count: iabTags['medium_relevance'].total_count,
      color: '#FEB019',
    },
    {
      key: 'Low Relevance',
      doc_count: iabTags['low_relevance'].total_count,
      color: '#FF4560',
    },
  ];
};

export const getOptionNestedIab = (items: IABItemModel[]): Option[] => {
  const result: Option[] = [];

  const traverse = (items: IABItemModel[]): void => {
    for (const item of items) {
      if (item?.id) {
        result.push({ label: `${item.name}`, value: item.name });
      }

      if (item.subItems && !isEmpty(item.subItems)) {
        traverse(item.subItems);
      }
    }
  };

  traverse(items);

  return uniqBy(result, 'label');
};

export const ensurePinnedChartFormat = (
  pinnedCharts: PinnableChart[],
): PinnedChartBackend[] => {
  if (isEmpty(pinnedCharts)) return [];

  return pinnedCharts.map((chart) => ({
    id: defaultTo(chart.id, PinnedChartType.ORIGINAL),
    title: defaultTo(chart.title, 'Pinned Mentions Analytics Chart'),

    searchInputTerm: defaultTo(chart.searchInputTerm, ''),
    chartType: defaultTo(chart.chartType, 'bar'),
    calendarMode: defaultTo(chart.calendarMode, CalendarFilter.YEAR),

    vocabListId: defaultTo(chart.vocabListId, null),
    vocabTerms: defaultTo(chart.vocabTerms, []),
    inputTerms: defaultTo(chart.inputTerms, []),

    showDataLabels: defaultTo(chart.showDataLabels, true),
    showQuarterMarkers: defaultTo(chart.showQuarterMarkers, false),
    groupByCollection: defaultTo(chart.groupByCollection, false),

    selectedTagIds: defaultTo(chart.selectedTagIds, []),
    selectedCollectionIds: defaultTo(chart.selectedCollectionIds, []),
    selectedOrgIds: defaultTo(chart.selectedOrgIds, []),

    selectedLayer: defaultTo(chart.selectedLayer, Layers.TRANSCRIPT),
    selectedMetadataLayer: defaultTo(
      chart.selectedMetadataLayer,
      Layers.KEY_TERM,
    ),
    dateRange: defaultTo(chart.dateRange, {
      startDate: null,
      endDate: null,
      focusedInput: null,
    }),
  }));
};

export const collectionTagsToTerms = (
  tags: TagCollectionResponse[] | null | undefined,
): Term[] => {
  if (!tags || isEmpty(tags)) return [];

  return tags.map((tag) => ({
    id: tag.collectionId,
    name: tag.collectionName,
    dateCreated: new Date().toISOString(),
  }));
};

export const toCircleData = (
  rawResponse: SimilarShowsRaw[],
  maxItemsCount = 10,
): NestedCircleChartData['children'] => {
  return rawResponse
    .map((item) => ({
      name: defaultTo(item.collection_name, ''),
      percentage: defaultTo(item.magnitude_percentage, 0),
      numberEpisodes: defaultTo(item.number_episodes, 0),
      top5PrimaryTerms: defaultTo(item.top_5_agg1, []),
      top5SecondaryTerms: defaultTo(item.top_5_agg2, []),
      color: randomColor({ format: 'hsl' }),
    }))
    .filter((item) => item.percentage >= 5) // If lesser than 5%, the circle is too small
    .slice(0, maxItemsCount);
};

export const toOmnyProgramOptions = (
  programList: OmnyPreviewProgramResponse[],
): ProgramIdOption[] => {
  return programList.map((item) => ({
    value: defaultTo(item.id, ''),
    label: defaultTo(item.name, ''),
    imageURL: defaultTo(item.artwork_url, ''),
  }));
};

export const toOmnyPlaylistOptions = (
  playlists: OmnyPreviewPlaylistResponse[],
): PlaylistIdOption[] => {
  return playlists.map((item) => ({
    value: defaultTo(item.id, ''),
    label: defaultTo(item.title, ''),
    imageURL: defaultTo(item.image_public_url, ''),
    programId: defaultTo(item.program_id, ''),
  }));
};

export const ensureValidArchiveTag = (tagName: string): string => {
  return tagName.toLowerCase() === ARCHIVE_TAG_NAME.toLowerCase()
    ? ARCHIVE_TAG_NAME
    : tagName;
};

export const convertTopShows = (
  collections: AdvertisingCollection[],
): TopShow[] => {
  return collections.map((item) => ({
    showName: item.collection_name,
    showId: item.collection_id,
    score: item.ad_markers,
  }));
};
