/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as AscendingArrow } from 'assets/Icons/Ascending_Arrow.svg';
import { ReactComponent as DescendingArrow } from 'assets/Icons/Descending_Arrow.svg';
import { CollectionDropdown } from 'components/shared/CollectionDropdown/CollectionDropdown';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { TermItem } from 'components/shared/CutomTermsModal/TermItem';
import { Info } from 'components/shared/Info';
import {
  arrowActive,
  arrowCss,
  dropdownCss,
} from 'components/shared/twin.styles';
import { cloneDeep, difference, isEmpty, orderBy } from 'lodash';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle, useUnmount } from 'react-use';
import { RootState } from 'reducers';
import {
  TagCollectionResponse,
  TagDetailResponse,
  TagService,
} from 'services/tag.service';
import { ModalOptions } from 'utils/enum';
import { Term } from 'utils/models';
import { BasicConfirmModal } from 'utils/models/modal.model';
import { customToast } from 'utils/toast.util';
import { extract } from 'utils/generic.util';
import { useTagIdQuery } from 'hooks/queries/useTagIdQuery';
import Loader from 'components/loader/loader';
import { collectionTagsToTerms } from 'utils/adapter.util';
import { fetchCollections } from 'slices/collection.slice';

type Props = {
  title: string;
  isArchived?: boolean;

  setShouldReloadTags: (shouldReload: boolean) => void;
};

export const TagDetailPane = ({
  title,
  isArchived = false,
  ...props
}: Props) => {
  const dispatch = useDispatch();
  const tag = useSelector((state: RootState) => state.tags);
  const collection = useSelector((state: RootState) => state.collection);

  const {
    data: selectedTag,
    isLoading: isLoadingTagDetail,
    refetch,
  } = useTagIdQuery({
    tagId: tag.selectedTagId,
    options: {
      onSuccess: (selectedTag) => {
        setTagList(collectionTagsToTerms(selectedTag?.collectionData));
      },
    },
  });

  const [tagList, setTagList] = useState<Term[]>([]);

  const confirmModalRef = useRef<BasicConfirmModal>(null);

  const [sortList] = useState([
    { id: 'modified', name: 'modified' },
    { id: 'alphabet', name: 'A-Z' },
  ]);

  const [isSortAsc, toggleIsSortAsc] = useToggle(true);
  const [currentSort, setCurrentSort] = useState<{ id: string; name: string }>(
    sortList[0],
  );

  const searchedTerms =
    orderBy(
      tagList,
      (s) => {
        const by = currentSort.id;
        if (by === 'modified') return s?.dateCreated;
        if (by === 'alphabet') return s?.name;
      },
      [isSortAsc ? 'asc' : 'desc'],
    ) ?? [];

  const isEmptyTagList = isEmpty(tagList);

  useUnmount(() => {
    if (isArchived) {
      dispatch(fetchCollections());
    }
  });

  const handleRemoveTerm = async (term: Term) => {
    if (!selectedTag) return;

    const result = await confirmModalRef.current?.show();

    if (result !== ModalOptions.YES) return;

    const newCollectionData: TagCollectionResponse[] =
      selectedTag?.collectionData.filter(
        ({ collectionId }) => collectionId !== term.id,
      ) ?? [];

    const newSelectedTag: TagDetailResponse = {
      ...selectedTag,
      collectionData: newCollectionData,
    };

    handleUpdateTagAsync(newSelectedTag);
  };

  const handleUpdateTagAsync = async (tag: TagDetailResponse) => {
    setTagList(collectionTagsToTerms(tag.collectionData));

    const newTagAsync = TagService.updateTag({
      tagId: tag.tagId,
      collectionIds: tag.collectionData.map(({ collectionId }) => collectionId),
    });

    customToast.promise(newTagAsync, {
      loading: (
        <span>
          Updating <strong>{title}</strong>
        </span>
      ),
      success: (
        <span>
          <strong>{title}</strong> updated!
        </span>
      ),
      error: 'Failed to update tag',
    });

    try {
      await newTagAsync;

      refetch();
      props.setShouldReloadTags(true);
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const handleMultiSelectChange = (selectedCollectionIds: string[]) => {
    if (!selectedTag) return;

    const tagClone = cloneDeep(selectedTag);

    const oldIds = extract(tagClone.collectionData, 'collectionId');
    const diffIds = difference(selectedCollectionIds, oldIds);

    // Nothing changed
    if (isEmpty(diffIds)) return;

    const diffCollections: TagCollectionResponse[] = collection.items
      .filter((item) => diffIds.includes(item.id))
      .map((item) => ({ collectionId: item.id, collectionName: item.name }));

    tagClone.collectionData.push(...diffCollections);

    handleUpdateTagAsync(tagClone);
  };

  return (
    <div tw="sticky top-0">
      <div tw="text-20 font-medium my-5">{title}</div>

      <div tw="flex w-full justify-between md-down:(flex-wrap)">
        <div tw="w-full">
          <form>
            <CollectionDropdown
              selectedCollections={extract(tagList, 'id')}
              onMultiSelectChange={handleMultiSelectChange}
              isMultiSelect
              canCreate={false}
              hiddenSelectedOptions
              placeholder="Search and select collections to add tag as a property"
            />
          </form>
        </div>

        <div
          className="button-group sort_modify"
          tw="flex items-center flex-nowrap height[3.9rem] bg-sonnant-grey-5 m-0 ml-3 rounded flex[unset] pr-1.5 select-none"
        >
          <div className="sort-icon" tw="pl-0 ml-2">
            <div
              tw="flex flex-col mr-2 ml-1 cursor-pointer"
              onClick={toggleIsSortAsc}
            >
              <DescendingArrow
                css={[arrowCss, !isSortAsc && arrowActive]}
                tw="(mb-0.5 width[unset] height[unset])!"
              />
              <AscendingArrow
                css={[arrowCss, isSortAsc && arrowActive]}
                tw="(mt-0.5 width[unset] height[unset])!"
              />
            </div>
          </div>

          <div className="dropdown" css={dropdownCss} tw="width[13.5rem]">
            <a
              className="button"
              tw="(width[unset] height[3rem] py-0 px-3 flex items-center text-13 whitespace-nowrap)!"
            >
              <span tw="flex-1 text-left">Sort by {currentSort.name}</span>
              <span className="arrow-down" tw="-mt-1 margin-left[4px]"></span>
            </a>
            <ul className="dropdown-sort-content manageDropDown">
              {sortList.map((sort) => (
                <li key={sort.id} onClick={() => setCurrentSort(sort)}>
                  <a>Sort by {sort.name}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div tw="flex flex-wrap content-start w-full p-4 mt-4 mb-6 overflow-y-auto height[40rem] border[1px solid lightgrey] rounded-lg relative">
        {isLoadingTagDetail && (
          <div tw="absolute w-full h-full flex justify-center items-center bg-white z-[1] opacity-90 top-0 left-0">
            <Loader />
          </div>
        )}
        {searchedTerms.map((term, index) => (
          <TermItem
            key={term.id}
            id={index}
            term={term}
            handleRemoveTerm={handleRemoveTerm}
            handleUpdateTerm={() => {}}
            shouldShowAlter={false}
          />
        ))}

        {isEmptyTagList && (
          <Info text="There are no collections associated with this Tag." />
        )}
      </div>

      <ConfirmModal
        ref={confirmModalRef}
        title="Delete confirm"
        message={
          <div>
            <div>
              You are about to remove the associated tag from the selected
              collection.
            </div>
            <div tw="font-medium">Would you like to continue?</div>
          </div>
        }
      />
    </div>
  );
};
