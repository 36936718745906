import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { ApiKey } from 'utils/models';

const apiKeySlice = createSlice({
  name: 'apiKey',
  initialState: {
    items: [
      // {
      //   id: '7f73bf37-f8a9-4b5f-9b4e-824db6db757e',
      //   created_at: '1653362293137',
      //   created_by: 'phong_to_ptnglobalcorp_com',
      //   api_key: '0DjQRayC4du-keQipGucZQCTc6bF7o8On7S6-tYUgXv-yKNX',
      //   name: 'phongto-api-key-2022-05-24_10:18 AM',
      // },
    ] as ApiKey[],
  },
  reducers: {
    setApiKeyList: (state, { payload }) => {
      state.items = payload;
    },
    appendApiKey: (state, { payload }: PayloadAction<ApiKey>) => {
      if (isEmpty(payload)) return;

      state.items.push(payload);
    },
    removeApiKey: (state, { payload }: PayloadAction<string>) => {
      state.items = state.items.filter((item) => item!.api_key !== payload);
    },
  },
});

export const { setApiKeyList, appendApiKey, removeApiKey } =
  apiKeySlice.actions;

export const apiKeyReducer = apiKeySlice.reducer;
