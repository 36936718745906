/* eslint-disable react-hooks/rules-of-hooks */
import axios from 'axios';
import React, { useEffect } from 'react';
import { useSearchParam } from 'react-use';
import { config } from 'components/config';
import { Social } from 'utils/enum';

export const SharePostWithInstagram = () => {
  const code = useSearchParam('code');
  const provider = Social.INSTAGRAM;

  useEffect(() => {
    handleGetAccessToken();
  });

  const handleGetAccessToken = async () => {
    const result = await axios.get(
      `https://graph.facebook.com/v12.0/oauth/access_token?redirect_uri=${config.INSTAGRAM_REDIRECT_URL}&client_id=${config.FB_CLIENT_ID}&client_secret=${config.FB_CLIENT_SECRET}&code=${code}`,
    );

    const GetAccessTokenPage = await axios.get(
      `https://graph.facebook.com/me/accounts?access_token=${result.data.access_token}`,
    );

    const pages = GetAccessTokenPage.data.data || [];

    window.document.cookie = `provider=${provider};domain=.${window.location.host}; path=/`;
    window.document.cookie = `page_data=${JSON.stringify({})};domain=.${window.location.host}; path=/`;

    for await (const items of pages) {
      const getInstagramId = await axios.get(
        `https://graph.facebook.com/v12.0/${items?.id}?fields=instagram_business_account&access_token=${items?.access_token}`,
      );

      const id = getInstagramId?.data?.instagram_business_account?.id || '';

      if (id) {
        const instagramToken = {
          access_token: items?.access_token,
          id_instagram: id,
        };

        window.document.cookie = `provider=${provider};domain=.${window.location.host}; path=/`;
        window.document.cookie = `page_data=${JSON.stringify(
          instagramToken,
        )};domain=.${window.location.host}; path=/`;
      }
    }
    window.document.cookie = `state=true;domain=.${window.location.host}; path=/`;

    window.close();
  };

  return (
    <div className="App">
      <header className="App-header"></header>
    </div>
  );
};
