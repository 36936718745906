/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { ReactComponent as FolderOpenSvg } from 'assets/Icons/folder_open.svg';
import { ReactComponent as FolderCloseSvg } from 'assets/Icons/standard-collection.svg';
// import { ReactComponent as SmartCollectionSvg } from 'assets/Icons/smart-collection.svg';
import {
  fetchLibrary,
  initLibrary,
  setSelectedRows,
  setSelectMode,
} from 'actions';
import { SEARCH_CLEAR } from 'actions/types';
import { TextInput } from 'components/shared/TextInput';
import { debounce, isEmpty, isEqual } from 'lodash';
import { MouseEvent } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToggle } from 'react-use';
import { RootState } from 'reducers';
import { CollectionService } from 'services/collection.service';
import {
  resetAdvancedSearch,
  toggleAdvancedFilter,
} from 'slices/advanced-search.slice';
import {
  addToVisibleCollections,
  fetchCollections,
  handleSaveCollectionSetting,
  renameCollectionName,
  setCollectionItems,
  setNewVisibleCollection,
  setSelectedCollectionId,
} from 'slices/collection.slice';
import { changePage } from 'slices/pagination.slice';
import { MoveDirection } from 'utils/enum';
import { CollectionSidebarItem } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { hasExistedName } from 'utils/utils';
import { CollectionItemMenu } from './CollectionItemMenu';

interface Props {
  item: CollectionSidebarItem;
  onDelete: (id: CollectionSidebarItem) => void;
  onExport: (item: CollectionSidebarItem) => void;
  integrateCollection: (item: CollectionSidebarItem) => void;
  onClickMonetisation: (item: CollectionSidebarItem) => void;
  isVisibleCollectionItem?: boolean;
}

export const CollectionItem = ({ item, ...props }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const collection = useSelector((state: RootState) => state.collection);
  const pagination = useSelector((state: RootState) => state.pagination);

  const [isEditing, toggleEditing] = useToggle(false);
  const [hovering, toggleHovering] = useToggle(false);

  const isSelected = isEqual(collection.selectedId, item.id);

  const handleClickItem = async (e: MouseEvent<HTMLDivElement>) => {
    if (e.detail === 1) {
      // Single click
      if (!item.id || isEditing) return;

      dispatch(fetchLibrary()); // loading library
      dispatch(setSelectedCollectionId(item?.id));
      dispatch(setSelectedRows([]));
      dispatch(setSelectMode(false));

      // reset filter
      dispatch({ type: SEARCH_CLEAR });
      dispatch(resetAdvancedSearch());
      dispatch(toggleAdvancedFilter(false));

      history.push(`/collections/${item.id}`);

      // default pagination
      const params = {
        page_number: 1,
        page_size: 30,
        collection_id: item.id,
        sortBy: pagination.sortBy,
        order: pagination.isAsc ? 'asc' : 'desc',
      };
      dispatch(initLibrary(params));
      dispatch(changePage(params));
    } else {
      // Double click
      toggleEditing(true);
    }
  };

  const enableEditMode = () => {
    toggleEditing(true);
  };

  const handleDeleteCollection = () => {
    props?.onDelete?.(item);
  };

  const handleMoveCollection = async (direction: MoveDirection) => {
    const isVisibleCollection = !isEmpty(collection.visibleCollection);

    const currentCollectionItems = isVisibleCollection
      ? collection.visibleCollection
      : collection.items;

    const foundIndex = currentCollectionItems.findIndex(
      (i) => i.id === item.id,
    );

    let nextCollection = currentCollectionItems?.[foundIndex - 1];

    if (direction === MoveDirection.DOWN) {
      nextCollection = currentCollectionItems?.[foundIndex + 1];
    }

    // Check valid position
    if (!nextCollection?.order) return;

    const moveAsync = CollectionService.reorderCollection({
      collection_id: item.id,
      next_collection: {
        collection_id: nextCollection?.id,
        collection_order: nextCollection?.order,
      },
    });

    customToast.promise(moveAsync, {
      loading: 'Saving changes',
      success: 'Saved',
      error: 'Failed to save changes. Please try again.',
    });

    try {
      // swap order
      const newCollections = isVisibleCollection
        ? [...collection.visibleCollection]
        : [...collection.items];

      const currentCollection = newCollections[foundIndex];

      // swap and reorder index
      newCollections[foundIndex] = {
        ...nextCollection,
        order: currentCollection.order,
      };

      if (direction === MoveDirection.DOWN) {
        newCollections[foundIndex + 1] = {
          ...currentCollection,
          order: nextCollection.order,
        };
      } else {
        newCollections[foundIndex - 1] = {
          ...currentCollection,
          order: nextCollection.order,
        };
      }

      if (isVisibleCollection) {
        dispatch(setNewVisibleCollection(newCollections));
      } else {
        dispatch(setCollectionItems(newCollections));
      }

      await moveAsync;
    } catch (err) {
      console.log('err :>> ', err);
      // Rollback collection's order once failed
      dispatch(fetchCollections());
    }
  };

  const handleMentionReport = async () => {
    props?.onExport(item);
  };

  const handleInputEnter = async (newCollectionName: string) => {
    const newName = newCollectionName?.trim();

    if (!newName) {
      customToast.error('Collection name is required');
      return;
    }

    // Collection name must be unique
    if (hasExistedName(collection.items, newName)) {
      return;
    }

    exitEditing();

    dispatch(renameCollectionName({ id: item.id, newName }));

    const renameAsync = CollectionService.updateCollection({
      collection_id: item.id,
      collection_name: newName,
    });

    customToast.promise(renameAsync, {
      loading: 'Renaming collection',
      success: 'Collection renamed successfully',
      error: 'Failed to rename collection',
    });

    try {
      await renameAsync;
    } catch (err: any) {
      console.log('err', err);
    }
  };

  const handleInputEsc = () => {
    exitEditing();
  };

  const exitEditing = () => {
    toggleEditing(false);
  };

  const handleAddVisibleCollection = () => {
    dispatch(addToVisibleCollections(item.id));
    dispatch(handleSaveCollectionSetting());
  };

  const handleIntegrateCollection = async () => {
    props?.integrateCollection(item);
  };

  const handleMonetisation = async () => {
    if (typeof props?.onClickMonetisation === 'function') {
      props.onClickMonetisation(item);
    }
  };

  const currentCollection = !isEmpty(collection.visibleCollection)
    ? collection.visibleCollection
    : collection.items;

  const isFirstCollectionItem =
    currentCollection?.findIndex((i) => i.id === item.id) === 0;

  const isLastCollectionItem =
    currentCollection?.findIndex((i) => i.id === item.id) ===
    currentCollection?.length - 1;

  return (
    <>
      <Droppable droppableId={item.id} direction="vertical">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            tw="ml-1 px-3 py-1.5 rounded flex justify-between items-center cursor-pointer"
            css={[
              snapshot.isDraggingOver &&
                tw`border[2px solid] border-sonnant-purple-2 shadow rounded bg-sonnant-grey-light`,
              isSelected
                ? tw`font-medium background-color[#d3d2fa]`
                : tw`hover:(bg-sonnant-grey-3)`,
            ]}
            onClick={debounce(handleClickItem, 200)}
            onMouseOver={() => toggleHovering(true)}
            onMouseLeave={() => {
              const timer = setTimeout(() => {
                toggleHovering(false);

                clearTimeout(timer);
              }, 100);
            }}
          >
            <div tw="flex items-center justify-between w-full relative">
              <span tw="flex flex-1">
                <span tw="flex items-center">
                  {item.id === collection.selectedId ? (
                    <FolderOpenSvg />
                  ) : (
                    <FolderCloseSvg />
                  )}
                </span>
                {isEditing ? (
                  <TextInput
                    tw="(height[3rem] ml-2 padding-left[5px] text-15 font-medium placeholder:(font-light text-14))!"
                    defaultText={item?.name}
                    placeholder={item?.name}
                    onPressEnter={handleInputEnter}
                    onPressEsc={handleInputEsc}
                    onBlur={exitEditing}
                  />
                ) : (
                  <span
                    tw="font-weight[inherit] word-break[break-word] ml-4 text-15 select-none relative top[1px] padding-right[2rem]"
                    // onDoubleClick={enableEditMode}
                    className="line-clamp-2"
                    title={item?.name?.length > 20 ? item?.name : ''}
                  >
                    {item.name ?? 'My collections'}
                  </span>
                )}
              </span>
              {hovering && !isEditing && (
                <span
                  tw="items-center absolute right[0]"
                  onClick={(e) => e.stopPropagation()}
                >
                  <CollectionItemMenu
                    collectionItem={item}
                    onClickRename={enableEditMode}
                    onClickIntegrate={handleIntegrateCollection}
                    onClickMentionReport={handleMentionReport}
                    onClickDelete={handleDeleteCollection}
                    onClickMove={handleMoveCollection}
                    onClickMonetisation={handleMonetisation}
                    onClickAddVisible={handleAddVisibleCollection}
                    isFirstCollectionItem={isFirstCollectionItem}
                    isLastCollectionItem={isLastCollectionItem}
                    isVisibleCollectionItem={props?.isVisibleCollectionItem}
                  />
                </span>
              )}
            </div>
          </div>
        )}
      </Droppable>
    </>
  );
};
