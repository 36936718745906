import { useRef } from 'react';
import { useClickAway, useToggle } from 'react-use';

import { useDispatch, useSelector } from 'react-redux';
import { setCalendarMode } from 'slices/trending-insight.slice';
import { CalendarFilter } from 'utils/enum';
import { Option } from '../CustomSelectSearch';
import { InsightAdvancedSelectionButton } from './InsightAdvancedSelectionButton';
import { SelectionWrapper } from './SelectionWrapper';
import { RootState } from 'reducers';

export const trendingCalendarOptions: Option[] = [
  {
    label: 'Previous 7 days',
    value: CalendarFilter.DAY,
  },
  {
    label: 'Previous 4 weeks',
    value: CalendarFilter.WEEK,
  },
  {
    label: 'Previous 3 months',
    value: CalendarFilter.MONTH,
  },
];

type Props = {
  isDisabled?: boolean;
};

export const InsightAdvancedSelectionMenu = ({
  isDisabled = false,
  ...props
}: Props) => {
  const dispatch = useDispatch();

  const insightTrending = useSelector(
    (state: RootState) => state.insightTrending,
  );
  const selectedCalendarMode = insightTrending.calendarMode;

  const buttonLabel = trendingCalendarOptions.find(
    (option) => option.value === selectedCalendarMode,
  )?.label;

  const selectionWrapperRef = useRef<HTMLDivElement>(null);

  const [showMenu, toggleShowMenu] = useToggle(false);

  useClickAway(selectionWrapperRef, () => {
    if (!showMenu) return;

    const timer = setTimeout(() => {
      toggleShowMenu(false);

      clearTimeout(timer);
    }, 100);
  });

  const handleSelectionChange = (option: Option) => {
    dispatch(setCalendarMode(option.value as CalendarFilter));

    toggleShowMenu(false);
  };

  return (
    <div ref={selectionWrapperRef}>
      <SelectionWrapper
        menuIsOpen={showMenu}
        optionList={trendingCalendarOptions}
        anchorElement={
          <InsightAdvancedSelectionButton
            label={buttonLabel}
            disabled={isDisabled}
            onClick={toggleShowMenu}
          />
        }
        onSelectionChange={handleSelectionChange}
      />
    </div>
  );
};
