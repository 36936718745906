/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { css } from '@emotion/react';

import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  getTierName,
  isSingular,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { useHistory } from 'react-router-dom';
import { tierSelector } from 'slices/payment.slice';
import tw from 'twin.macro';
import { Routes } from 'utils/routes';
import { defaultTo } from 'lodash';

export const SubscriptionNavbar = () => {
  const subscription = useSelector(
    (state: RootState) => state.payment.subscription,
  );
  const payment = useSelector((state: RootState) => state.payment);
  const tier = useSelector(tierSelector);

  const remainMinutes = defaultTo(subscription?.balanceminutes, 0);

  const history = useHistory();

  const canShow = (): boolean =>
    !payment.isLoadingInfo &&
    // Only show on TRIAL or PAYG
    (tier.isTrial || tier.isPAYG || tier.isEnterpriseTrial) &&
    [
      Routes.CLIPS,
      Routes.COLLECTIONS,
      Routes.REVIVE,
      Routes.INSIGHTS,
      Routes.LIBRARY,
      Routes.SETTINGS,
      Routes.PREFERENCES,
      Routes.REPORTING,
    ].some((route) => window.location.pathname.startsWith(route));

  const handleClickUpgrade = () => {
    history.push('/account-settings#billing');
  };
  return (
    <>
      {canShow() && (
        <div
          tw="bg-sonnant-green text-14 height[4.4rem] flex items-center px-5 text-sonnant-grey-1 rounded-lg"
          css={[
            responsiveCss,
            payment.isExpiredTrial ? tw`bg-sonnant-red` : tw`bg-sonnant-green`,
          ]}
        >
          {payment.isExpiredTrial ? (
            <div>
              <strong>Trial expired.</strong> Click here to{' '}
              <span
                tw="font-semibold underline cursor-pointer hover:(text-shadow[0 0 1px white]) sm-down:hidden"
                onClick={handleClickUpgrade}
              >
                Upgrade
              </span>
            </div>
          ) : (
            <div>
              {getTierName(subscription)}:{' '}
              <span tw="font-semibold whitespace-nowrap">
                {remainMinutes}{' '}
                {isSingular(remainMinutes) ? 'minute' : 'minutes'}{' '}
              </span>
              <span tw="xs:hidden">remaining. </span>
              <span
                tw="font-semibold underline cursor-pointer hover:(text-shadow[0 0 1px white]) sm-down:hidden"
                onClick={handleClickUpgrade}
              >
                Upgrade
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const responsiveCss = css`
  @media (max-width: 855px) {
    line-height: 1.3;
    font-size: 1.3rem;
    padding: 0 8px;
  }
`;
