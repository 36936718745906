/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Tooltip } from '@rmwc/tooltip';
import { ReactComponent as QuestionSvg } from 'assets/Icons/Vector_question.svg';
import { Notification } from 'components/UiControls/notification/Notification';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';
import * as actionTypes from '../../actions/types';
import Loader from '../loader/loader';
import './export.scss';
import { RootState } from 'reducers';

interface IProps {
  closeExportModal: () => void;
  mediaid: string | undefined;
  filename: string | undefined;
  mpx: string | undefined;
  versioncount: string | undefined;
  exportOptions: IExportOptions;
}

interface IExportOptions {
  allowMediaDownload: boolean;
  allowCaptionDownload: boolean;
  allowTranscriptDownload: boolean;
  haveMp3: boolean;
  haveMp4: boolean;
  isBurnedIn: boolean;
}

const PublicExport = (props: IProps) => {
  const dispatch = useDispatch();
  const [isAdobePdfTC, setIsAdobePdfTC] = useState(false);
  const [isDocxTC, setIsDocxTC] = useState(false);
  const [isTxtTC, setIsTxtTC] = useState(false);
  const [isAdobePdfSP, setIsAdobePdfSP] = useState(false);
  const [isDocxSP, setIsDocxSP] = useState(false);
  const [isTxtSP, setIsTxtSP] = useState(false);

  const [isWebVtt, setIsWebVtt] = useState(false);
  const [isMp4, setIsMp4] = useState(false);
  const [isMp3, setIsMp3] = useState(false);

  const [isSrt, setIsSrt] = useState(false);
  const getExportedPdf = useSelector(
    (state: RootState) => state.publicExportContentItem,
  );
  const { exportLoading, exportedData } = getExportedPdf;

  const {
    allowMediaDownload,
    allowCaptionDownload,
    allowTranscriptDownload,
    haveMp3,
    haveMp4,
    isBurnedIn,
  } = {
    ...props.exportOptions,
  };
  useEffect(() => {
    if (Object.keys(exportedData).length) {
      let lstUrl: string[] = [];
      if (exportedData.mp3) {
        lstUrl.push(exportedData.mp3);
      }
      if (exportedData.mp4) {
        lstUrl.push(exportedData.mp4);
      }
      if (exportedData.textData.notZipUrl) {
        lstUrl.push(exportedData.textData.notZipUrl);
      } else if (exportedData.textData.zipUrl) {
        lstUrl.push(exportedData.textData.zipUrl);
      }
      let timeOut = 500;
      for (let index in lstUrl) {
        setTimeout(function () {
          downloadURI(lstUrl[index]);
        }, timeOut);
        timeOut += 2000;
      }
    }
  }, [exportedData]);

  const downloadURI = (uri: string) => {
    var element = document.createElement('a');
    element.setAttribute('href', uri);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    setTimeout(function () {
      document.body.removeChild(element);
    }, 5000);
  };

  const toGeneratePdf = async () => {
    dispatch({
      type: actionTypes.INIT_PUBLIC_EXPORT_CONTENT_ITEM_WATCH,
      versioncount: props.versioncount,
      mediaid: props.mediaid,
      filename: props.filename,
      mp4: isMp4 ? 1 : 0,
      mp3: isMp3 ? 1 : 0,
      vtt: isWebVtt ? 1 : 0,
      srt: isSrt ? 1 : 0,
      pdfTC: isAdobePdfTC ? 1 : 0,
      docxTC: isDocxTC ? 1 : 0,
      txtTC: isTxtTC ? 1 : 0,
      pdfSP: isAdobePdfSP ? 1 : 0,
      docxSP: isDocxSP ? 1 : 0,
      txtSP: isTxtSP ? 1 : 0,
      isBurnedIn: isBurnedIn,
    });
  };

  const handlePdfchangeTC = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAdobePdfTC(e.target.checked);
  };
  const handleDocxchangeTC = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDocxTC(e.target.checked);
  };
  const handlePlaintTextTC = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsTxtTC(e.target.checked);
  };

  const handlePdfchangeSP = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAdobePdfSP(e.target.checked);
  };
  const handleDocxchangeSP = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDocxSP(e.target.checked);
  };
  const handlePlaintTextSP = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsTxtSP(e.target.checked);
  };

  const handleVttchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsWebVtt(e.target.checked);
  };

  const handleMp3change = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsMp3(e.target.checked);
  };

  const handleMp4change = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsMp4(e.target.checked);
  };

  const handleSrtchange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSrt(e.target.checked);
  };

  return (
    <>
      {exportLoading && (
        <div className="loader__component">
          <Loader />
        </div>
      )}
      <>
        <form className="exportmodal" onSubmit={e => e.preventDefault()}>
          <div className="grid-x">
            <div className="cell large-12">
              <h2>Export</h2>
            </div>
          </div>
          <hr></hr>

          <div tw="flex items-center">
            <h3 tw="mt-0! mr-4">Select items for export</h3>
            <Tooltip
              align="bottomLeft"
              tw="z-index[100]"
              leaveDelay={300}
              content={
                <div tw="width[30rem] top-0 left[-1.5rem] font-size[1.6rem] line-height[1.9rem] border-radius[3px] absolute">
                  <Notification
                    hideCloseButton
                    name="share-tooltip"
                    type="information"
                    message={
                      <div tw="py-1">
                        <p>Multiple items will be exported as a ZIP file.</p>
                      </div>
                    }
                  />
                </div>
              }
              css={customTooltipStyles}
            >
              <div className="questionsvg">
                <QuestionSvg />
              </div>
            </Tooltip>
          </div>

          <div className="grid">
            <fieldset className="group-legend1">
              <legend tw="font-weight[500]">Media File</legend>
              {allowMediaDownload && haveMp3 && !isBurnedIn && (
                <label
                  htmlFor="checkbox11"
                  className="cell cus-inline-block cus-large-5 cus-left-10"
                  tw="whitespace-nowrap cursor-pointer"
                >
                  <input
                    id="checkbox11"
                    type="checkbox"
                    checked={isMp3}
                    onChange={handleMp3change}
                  />
                  <span className="checkmark"></span>
                  <span className="checkmark"></span>
                  MPEG-3 (.mp3)
                </label>
              )}
              {((allowMediaDownload && haveMp4) || isBurnedIn) && (
                <label
                  htmlFor="checkbox12"
                  className="cell cus-inline-block cus-large-5 cus-left-10"
                  tw="whitespace-nowrap cursor-pointer"
                >
                  <input
                    id="checkbox12"
                    type="checkbox"
                    checked={isMp4}
                    onChange={handleMp4change}
                  />
                  <span className="checkmark"></span>
                  MPEG-4 (.mp4)
                </label>
              )}
            </fieldset>
            {allowCaptionDownload && (
              <fieldset className="group-legend1">
                <legend tw="font-weight[500]">Captions File</legend>
                <label
                  htmlFor="checkbox21"
                  className="cell cus-inline-block cus-large-5 cus-left-10"
                  tw="whitespace-nowrap cursor-pointer"
                >
                  <input
                    id="checkbox21"
                    type="checkbox"
                    checked={isWebVtt}
                    onChange={handleVttchange}
                  />
                  <span className="checkmark"></span>
                  WebVTT (.vtt)
                </label>
                <label
                  htmlFor="checkbox22"
                  className="cell cus-inline-block cus-large-5 cus-left-10"
                  tw="whitespace-nowrap cursor-pointer"
                >
                  <input
                    id="checkbox22"
                    type="checkbox"
                    checked={isSrt}
                    onChange={handleSrtchange}
                  />
                  <span className="checkmark"></span>
                  SubRip (.srt)
                </label>
              </fieldset>
            )}
          </div>
          {allowTranscriptDownload && (
            <>
              <fieldset className="group-legend2">
                <legend tw="font-weight[500]">
                  Transcript Text - Timed Captions
                </legend>
                <label
                  htmlFor="checkbox31"
                  className="cell cus-inline-block medium-3 cus-left-5"
                  tw="cursor-pointer"
                >
                  <input
                    id="checkbox31"
                    type="checkbox"
                    checked={isAdobePdfTC}
                    onChange={handlePdfchangeTC}
                  />
                  <span className="checkmark"></span>
                  Adobe PDF (.pdf)
                </label>
                <label
                  htmlFor="checkbox32"
                  className="cell cus-inline-block medium-3"
                  tw="whitespace-nowrap cursor-pointer"
                >
                  <input
                    id="checkbox32"
                    type="checkbox"
                    checked={isDocxTC}
                    onChange={handleDocxchangeTC}
                  />
                  <span className="checkmark"></span>
                  Word (.docx)
                </label>
                <label
                  htmlFor="checkbox33"
                  className="cell cus-inline-block medium-3"
                  tw="whitespace-nowrap cursor-pointer"
                >
                  <input
                    id="checkbox33"
                    type="checkbox"
                    checked={isTxtTC}
                    onChange={handlePlaintTextTC}
                  />
                  <span className="checkmark"></span>
                  Plain Text (.txt)
                </label>
              </fieldset>

              <fieldset className="group-legend2">
                <legend tw="font-weight[500]">
                  Transcript Text - Speaker Paragraphs
                </legend>
                <label
                  htmlFor="checkbox41"
                  className="cell cus-inline-block medium-3 cus-left-5"
                  tw="cursor-pointer"
                >
                  <input
                    id="checkbox41"
                    type="checkbox"
                    checked={isAdobePdfSP}
                    onChange={handlePdfchangeSP}
                  />
                  <span className="checkmark"></span>
                  Adobe PDF (.pdf)
                </label>
                <label
                  htmlFor="checkbox42"
                  className="cell cus-inline-block medium-3"
                  tw="whitespace-nowrap cursor-pointer"
                >
                  <input
                    id="checkbox42"
                    type="checkbox"
                    checked={isDocxSP}
                    onChange={handleDocxchangeSP}
                  />
                  <span className="checkmark"></span>
                  Word (.docx)
                </label>
                <label
                  htmlFor="checkbox43"
                  className="cell cus-inline-block medium-3"
                  tw="whitespace-nowrap cursor-pointer"
                >
                  <input
                    id="checkbox43"
                    type="checkbox"
                    checked={isTxtSP}
                    onChange={handlePlaintTextSP}
                  />
                  <span className="checkmark"></span>
                  Plain Text (.txt)
                </label>
              </fieldset>
            </>
          )}

          <div className="grid-x grid-margin-x rightAlign">
            <button
              type="button"
              className="button btn-secondary large cancel small-3 cell"
              onClick={props.closeExportModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="button btn-primary  large apply small-3 cell"
              onClick={
                isAdobePdfTC ||
                isDocxTC ||
                isTxtTC ||
                isAdobePdfSP ||
                isDocxSP ||
                isTxtSP ||
                isWebVtt ||
                isMp4 ||
                isMp3 ||
                isSrt
                  ? () => toGeneratePdf()
                  : (null as any)
              }
              disabled={
                !isAdobePdfTC &&
                !isDocxTC &&
                !isTxtTC &&
                !isAdobePdfSP &&
                !isDocxSP &&
                !isTxtSP &&
                !isWebVtt &&
                !isMp4 &&
                !isMp3 &&
                !isSrt
              }
            >
              Download
            </button>
          </div>
        </form>
      </>
    </>
  );
};
const customTooltipStyles = css`
  ${tw`font-size[1rem]`}

  .rmwc-tooltip-inner {
    background: none;
  }
`;

export default PublicExport;
