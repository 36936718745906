import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getSearchParamUrl,
  isCollectionPage,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { isEmpty, isNil } from 'lodash';
import {
  AdvancedFilterType,
  DatePeriodFilter,
  DurationFilter,
  MediaType,
  SearchField,
} from 'utils/enum';
import { AdvancedSearchParams, CustomDateParams, Insights } from 'utils/models';
import { readLocal } from './pagination.slice';
import { getSearchFieldByInsight } from 'utils/adapter.util';

const getSearchFieldFromUrl = (): SearchField | null => {
  const layerParam = getSearchParamUrl('layer') as keyof Insights;
  const searchField = getSearchFieldByInsight(layerParam);

  return searchField;
};

const advancedSearchSlice = createSlice({
  name: 'advanced-search',
  initialState: {
    isShowAdvancedFilter: false,
    // isShowClipsOnly: true,
    isShowClipsOnly:
      typeof readLocal('isShowClipsOnly') === 'boolean' && !isCollectionPage()
        ? (readLocal('isShowClipsOnly') as boolean)
        : false,
    searchParams: {
      uploadDate: null as DatePeriodFilter | null,
      customDate: null as {
        startDate: string | Date;
        endDate: string | Date;
      } | null,
      type: null as MediaType | null,
      duration: null as DurationFilter | null,
      speakerNames: [
        // 'Tony Simmons', 'Anton de Weger'
      ] as string[],
      searchValues: [] as string[],
      searchField: getSearchFieldFromUrl() as SearchField | null,
      searchBy: null as string | null,
    } as AdvancedSearchParams,
  },
  reducers: {
    resetCustomData: (state) => {
      state.searchParams.customDate = null;
    },
    resetAdvancedSearch: (state) => {
      // state.isShowAdvancedFilter = false;
      state.isShowClipsOnly = false;
      const newParams = {
        uploadDate: null,
        customDate: null,
        type: null,
        duration: null,
        speakerNames: [],
        searchValues: [],
        searchField: SearchField.KEYWORD,
      } as any;
      state.searchParams = newParams;
    },
    toggleAdvancedFilter: (
      state,
      { payload }: PayloadAction<boolean | undefined>,
    ) => {
      if (typeof payload === 'boolean') {
        state.isShowAdvancedFilter = payload;
      } else {
        state.isShowAdvancedFilter = !state.isShowAdvancedFilter;
      }
    },
    setSearchParams: (
      state,
      {
        payload,
      }: PayloadAction<{
        type: AdvancedFilterType;
        data: string | object | null;
        isCustomDate?: boolean;
      }>,
    ) => {
      if (isNil(payload)) return;

      // Select / Unselect existed option
      const select = (field: string | null): any =>
        field !== payload.data ? (payload.data as string) : null;

      const clonedParams = { ...state.searchParams };

      if (payload.type === AdvancedFilterType.DATE) {
        if (!payload?.isCustomDate) {
          clonedParams.uploadDate = select(clonedParams.uploadDate);

          clonedParams.customDate = null;
        } else {
          clonedParams.customDate = payload.data as CustomDateParams;
        }
      } else if (payload.type === AdvancedFilterType.TYPE) {
        clonedParams.type = select(clonedParams.type);
      } else if (payload.type === AdvancedFilterType.DURATION) {
        clonedParams.duration = select(clonedParams.duration);
      } else if (payload.type === AdvancedFilterType.SPEAKERS) {
        // CHECK validation
        if (clonedParams.speakerNames.includes(payload.data as string)) return;

        clonedParams.speakerNames.unshift(payload.data as string);
      } else if (payload.type === AdvancedFilterType.CUSTOM) {
        // CHECK validation
        if (clonedParams.searchValues.includes(payload.data as string)) return;

        clonedParams.searchValues.unshift(payload.data as string);
      } else if (payload.type === AdvancedFilterType.TITLE) {
        clonedParams.searchBy = select(clonedParams.searchBy);
      }

      state.searchParams = clonedParams;
    },
    removeSearchTextBox: (
      state,
      { payload }: PayloadAction<{ type: AdvancedFilterType; text: string }>,
    ) => {
      if (isNil(payload)) return;

      const clonedParams = { ...state.searchParams };

      if (payload.type === AdvancedFilterType.SPEAKERS) {
        clonedParams.speakerNames = clonedParams.speakerNames.filter(
          (name) => name !== payload.text,
        );
      } else if (payload.type === AdvancedFilterType.CUSTOM) {
        clonedParams.searchValues = clonedParams.searchValues.filter(
          (value) => value !== payload.text,
        );
      }

      state.searchParams = clonedParams;
    },
    setSearchField: (state, { payload }: PayloadAction<SearchField>) => {
      if (isNil(payload)) return;

      state.searchParams.searchField = payload;
    },
    toggleSearchClipsMode: (state) => {
      state.isShowClipsOnly = !state.isShowClipsOnly;
    },
    setSearchValues: (state, { payload }: PayloadAction<string[]>) => {
      if (isEmpty(payload)) return;

      state.searchParams.searchValues = payload;
    },
    setSpeakerNames: (state, { payload }: PayloadAction<string[]>) => {
      if (isEmpty(payload)) return;

      state.searchParams.speakerNames = payload;
    },
  },
});

export const {
  toggleAdvancedFilter,
  setSearchParams,
  removeSearchTextBox,
  setSearchField,
  toggleSearchClipsMode,
  resetAdvancedSearch,
  setSearchValues,
  setSpeakerNames,
  resetCustomData,
} = advancedSearchSlice.actions;

export const advancedSearchReducer = advancedSearchSlice.reducer;
