/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Hint } from 'components/shared/Hint';
import { memo, ReactElement } from 'react';

interface Props {
  children: ReactElement;
  disabled?: boolean;
}

export const YoutubeHelper = memo((props: Props) => {
  return (
    <Hint
      disabled={props?.disabled}
      arrow
      notTransparent
      enterDelay={200}
      leaveDelay={200}
      text={
        <div>
          To be able to send this clip to your YouTube Channel:
          <ul tw="mb-0">
            <li>
              Selected clip should be in video format to be available on
              YouTube.
            </li>
            <li>Audio clip should be burned in first.</li>
            <li>
              Vertical videos that are less than 60 seconds in length will be
              uploaded as Shorts.
            </li>
          </ul>
        </div>
      }
    >
      {props.children}
    </Hint>
  );
});
