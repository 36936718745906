/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { Ripple } from '@rmwc/ripple';
import { ceil } from 'lodash';
import { ReactElement } from 'react';

import { SelectionMode } from 'utils/enum';
import { CustomDateParams } from 'utils/models';
import { MenuSelectWrapper } from '../PaginationPreviewModal/MenuSelectWrapper';
import { OmnyPreviewFilterMenu } from '../PaginationPreviewModal/OmnyPreviewFilterMenu';
import { simpleMenuDisabled, twBaseClickable } from '../twin.styles';

type Props = {
  children: ReactElement;
  isLoading: boolean;
  isDateRangeDisabled?: boolean;

  menuSelectionMode: SelectionMode;
  totalItems: number;
  currentPageIndex: number;

  onSelectModeChange: (mode: SelectionMode) => void;
  onDateRangeChange: (dates: CustomDateParams) => void;
  onPageChange: (
    page: number,
  ) => (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const ITEMS_PER_PAGE = 50;

export const PreviewPaginationWrapper = ({
  isLoading,
  isDateRangeDisabled = false,
  menuSelectionMode,
  totalItems,
  currentPageIndex,
  ...props
}: Props) => {
  const isEmptyItems = totalItems === 0;
  const totalPageIndex = Math.max(ceil(totalItems / ITEMS_PER_PAGE), 1);

  const canPreviousPage = currentPageIndex > 1;
  const canNextPage = currentPageIndex < totalPageIndex;

  const startItemIndex = isEmptyItems
    ? 0
    : (currentPageIndex - 1) * ITEMS_PER_PAGE + 1;

  const endItemIndex =
    totalItems < ITEMS_PER_PAGE
      ? totalItems
      : Math.min(currentPageIndex * ITEMS_PER_PAGE, totalItems);

  return (
    <div>
      <div tw="flex justify-between items-baseline">
        <div tw="flex items-center justify-center">
          <Ripple>
            <div
              tw="flex flex-row justify-center items-center rounded-lg pr-2 mb-2"
              css={[isEmptyItems && simpleMenuDisabled]}
            >
              <MenuSelectWrapper
                mode={menuSelectionMode}
                onSelectMode={props.onSelectModeChange}
              />
            </div>
          </Ripple>

          <OmnyPreviewFilterMenu
            disabled={isDateRangeDisabled}
            onDatesChange={props.onDateRangeChange}
          />
        </div>

        <div tw="font-medium text-16 flex">
          {`${startItemIndex} - ${endItemIndex} of ${totalItems}`}
        </div>
      </div>

      {props.children}

      <div tw="font-size[1.4rem] flex flex-col">
        <div
          tw="flex w-full justify-center items-center"
          css={paginationStyles}
        >
          <button onClick={props.onPageChange(-1)} disabled={!canPreviousPage}>
            {'<'}
          </button>
          <span tw="px-2">
            Page{' '}
            <strong>
              {currentPageIndex} of {totalPageIndex}
            </strong>
          </span>
          <button onClick={props.onPageChange(1)} disabled={!canNextPage}>
            {'>'}
          </button>
        </div>
      </div>
    </div>
  );
};

const paginationStyles = css`
  &:focus,
  button {
    ${tw`(p-2 w-12 h-12 mx-1)!`}
    ${twBaseClickable}
  }
`;
