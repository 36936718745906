import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toNumber } from 'lodash';
import { RootState } from 'reducers';
import { UNLIMITED } from 'utils/constants';
import { Roles, SubscriptionEnum } from 'utils/enum';
import {
  IBalance,
  ISubscription,
  ISubscriptionInfo,
} from 'utils/models/payment.model';
import { getUserRole } from 'utils/utils';

export const balanceSelector = createSelector(
  (state: RootState) => state.payment.balance,
  (balance) => ({
    remainMinutes: toNumber(balance.remainingminutes),
    thisMonthUsage:
      toNumber(balance.transcriptionminutes) +
      toNumber(balance.humaneditminutes),
    billedThisMonthUsage:
      toNumber(balance.billed_transcription_minutes) +
      toNumber(balance.billed_humanedit_minutes),
  }),
);

export const userSelector = createSelector(
  (state: RootState) => state.payment,
  (user) => ({
    isLoading: user.subscription.isLoading,
    isAdministrator: getUserRole() === Roles.ADMIN,
  }),
);

export const tierSelector = createSelector(
  (state: RootState) => state.payment.subscription,
  (subscription) => ({
    isTrial: subscription?.tenantplan === SubscriptionEnum.TRIAL,
    isPAYG: subscription?.tenantplan === SubscriptionEnum.PAYG,
    isStandard: subscription?.tenantplan === SubscriptionEnum.STANDARD,
    isPremium: subscription?.tenantplan === SubscriptionEnum.PREMIUM,
    isEnterprise: subscription?.tenantplan === SubscriptionEnum.ENTERPRISE,
    isEnterpriseTrial:
      subscription?.tenantplan === SubscriptionEnum.ENTERPRISE_TRIAL,
    isInternal: subscription?.tenantplan === SubscriptionEnum.INTERNAL,
    isEnterpriseUnlimited:
      subscription?.tenantplan === SubscriptionEnum.ENTERPRISE &&
      toNumber(subscription?.licensedusers) === UNLIMITED,
    currentPlan: subscription?.tenantplan,
    isSubscriber: [
      SubscriptionEnum.STANDARD,
      SubscriptionEnum.PREMIUM,
      SubscriptionEnum.ENTERPRISE,
      SubscriptionEnum.INTERNAL,
      SubscriptionEnum.ENTERPRISE_TRIAL,
    ].includes(subscription?.tenantplan as SubscriptionEnum),
  }),
);

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    subscription: {} as ISubscription,
    balance: {
      balanceminutes: 0,
      humaneditminutes: 0,
      remainingminutes: 0,
      remaining_days: 0,
      transcriptionminutes: 0,
    } as IBalance,
    isExpiredTrial: false,
    isLoadingInfo: false,
  },
  reducers: {
    setSubscription: (state, action: PayloadAction<ISubscription>) => {
      state.subscription = action.payload;
    },
    setSubLoading: (state, action) => {
      state.subscription.isLoading = action.payload;
    },
    setLoadingInfo: (state, action) => {
      state.isLoadingInfo = action.payload;
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
      if (
        state.subscription.tenantplan === SubscriptionEnum.ENTERPRISE_TRIAL &&
        toNumber(state.balance?.remaining_days) <= 0
      ) {
        state.isExpiredTrial = true;
      }
    },
    patchSubscriptionQuickInfo: (
      state,
      { payload }: PayloadAction<ISubscriptionInfo>,
    ) => {
      state.subscription.tenantplan = payload.tenantplan;

      state.balance.remainingminutes = payload.balanceminutes;
      state.balance.remaining_days = payload.remaining_days;

      if (
        payload.tenantplan === SubscriptionEnum.ENTERPRISE_TRIAL &&
        toNumber(payload.remaining_days) <= 0
      ) {
        state.isExpiredTrial = true;
      }
    },
  },
});

export const {
  setSubscription,
  setSubLoading,
  setBalance,
  setLoadingInfo,
  patchSubscriptionQuickInfo,
} = paymentSlice.actions;

export const paymentReducer = paymentSlice.reducer;
