/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { TopicLayerDropDown } from 'components/Publish/TopicLayerDropDown';
import Loader from 'components/loader/loader';
import { CollectionDropdown } from 'components/shared/CollectionDropdown/CollectionDropdown';
import {
  CircleParkingChart,
  NestedCircleChartData,
} from 'components/shared/InsightChart/CircleParkingChart';
import { NoFilterInsights } from 'components/shared/NoFilterInsights';
import { QuestionHint } from 'components/shared/QuestionHint';
import { first, isEmpty, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { InsightService } from 'services/insight.service';
import { toCircleData } from 'utils/adapter.util';
import { Layers } from 'utils/enum';
import { getTopicLayerName } from 'utils/utils';
import { customToast } from 'utils/toast.util';
import axios, { AxiosError } from 'axios';
import { NoResultInsights } from 'components/shared/NoResultInsights';
import { useCancelQuery } from 'hooks/useCancelQuery';

export const SimilarityShowCharts = () => {
  const [collectionId, setCollectionId] = useState<string>();

  const [primaryLayer, setPrimaryLayer] = useState<Layers>(Layers.IAB);
  const [secondaryLayer, setSecondaryLayer] = useState<Layers>(Layers.KEY_TERM);

  const [circleChartData, setCircleChartData] = useState<
    NestedCircleChartData['children']
  >([]);

  const similarShowsAPI = useCancelQuery({
    queryFn: InsightService.postSimilarShowsAsync,
  });

  useEffect(() => {
    if (isNil(collectionId) || isNil(primaryLayer) || isNil(secondaryLayer)) {
      return;
    }

    handleGenerateAsync();
  }, [collectionId, primaryLayer, secondaryLayer]);

  const getNonDuplicatedLayer = (selectedLayer: Layers): Layers => {
    const canSelectLayers = [
      Layers.KEY_TERM,
      Layers.PEOPLE,
      Layers.PRODUCT,
      Layers.ORG,
      Layers.LOCATION,
      Layers.NATIONALITY,
      Layers.IAB,
    ];

    const filteredLayers = canSelectLayers.filter(
      (layer) => layer !== selectedLayer,
    );

    return first(filteredLayers)!;
  };

  const handleChangePrimaryLayer = (layer: Layers) => {
    if (layer === secondaryLayer) {
      const anotherSecondaryLayer = getNonDuplicatedLayer(layer);

      setSecondaryLayer(anotherSecondaryLayer);
    }

    setPrimaryLayer(layer);
  };

  const handleChangeSecondaryLayer = (layer: Layers) => {
    if (layer === primaryLayer) {
      const anotherPrimaryLayer = getNonDuplicatedLayer(layer);

      setPrimaryLayer(anotherPrimaryLayer);
    }

    setSecondaryLayer(layer);
  };

  const handleGenerateAsync = async () => {
    if (!collectionId || isEmpty(collectionId?.trim())) {
      setCircleChartData([]);

      return;
    }

    if (isNil(primaryLayer) || isNil(secondaryLayer)) return;

    try {
      const data = await similarShowsAPI.queryAsync({
        collectionId,
        primaryLayer,
        secondaryLayer,
      });

      setCircleChartData(toCircleData(data));
    } catch (error: any) {
      const isAxiosError = axios.isAxiosError(error);

      if (isAxiosError) {
        const msg = (error as AxiosError)?.response?.data;

        customToast.error(
          typeof msg === 'string' ? msg : 'Something went wrong!',
        );
      }
    }
  };

  return (
    <div tw="w-full flex flex-wrap px-3 mt-10">
      <div tw="text-20 font-semibold">Similar Shows</div>

      <div tw="w-full mt-3 rounded border[1px solid] border-sonnant-grey-light shadow px-5 pb-5">
        <div tw="pt-6 flex flex-wrap gap-4 relative z-index[1]">
          <div tw="flex flex-row flex-1 gap-x-10 gap-y-3 justify-between">
            <div tw="flex flex-col flex-wrap gap-x-6 gap-y-5">
              <div tw="min-w-[20rem]">
                <label tw="flex items-center gap-x-3">
                  <span tw="text-15">Collection</span>
                  <span tw="flex [svg]:(h-[1.8rem] w-[1.8rem])">
                    <QuestionHint
                      type="information"
                      align="bottomLeft"
                      action="hover"
                      customStyle={customTooltipCss}
                      hideCloseButton
                      text={
                        <div tw="line-height[1.5]">
                          Collection refers to the folder for podcasts or shows
                        </div>
                      }
                    />
                  </span>
                </label>
                <CollectionDropdown
                  defaultValue={''}
                  onChange={setCollectionId}
                  isMultiSelect={false}
                />
              </div>

              <div tw="min-w-[20rem] z-[1]">
                <label tw="flex items-center gap-x-3">
                  <span tw="text-15">Primary Filter Category Layer</span>
                </label>
                <TopicLayerDropDown
                  defaultValue={getTopicLayerName(primaryLayer)}
                  onChange={handleChangePrimaryLayer}
                  hasIABLayer
                  disabled={false}
                />
              </div>

              <div tw="min-w-[20rem] z-[0]">
                <label tw="flex items-center gap-x-3">
                  <span tw="text-15">Secondary Filter Category Layer</span>
                </label>
                <TopicLayerDropDown
                  defaultValue={getTopicLayerName(secondaryLayer)}
                  onChange={handleChangeSecondaryLayer}
                  hasIABLayer
                  disabled={false}
                />
              </div>
            </div>

            {similarShowsAPI.isLoading ? (
              <div tw="min-h-[40rem] flex flex-1 justify-center my-3 items-center shadow rounded">
                <Loader />
              </div>
            ) : (
              <div tw="flex-1 min-h-[40rem] max-h-[65rem] w-[calc(100vw - 60rem)]! shadow rounded border border-solid border-sonnant-grey-1 relative z-0">
                {!isEmpty(circleChartData) ? (
                  <CircleParkingChart
                    chartData={{
                      name: 'Similar Shows',
                      children: circleChartData,
                    }}
                  />
                ) : isEmpty(collectionId) ? (
                  <div tw="w-full h-full pr-[270px]">
                    <NoFilterInsights message="Please selected primary collection and filter category layers to generate your graph." />
                  </div>
                ) : (
                  <div tw="w-full h-full pr-[270px]">
                    <NoResultInsights />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const customTooltipCss = css`
  ${tw`font-size[1rem]`}

  .notification {
    ${tw`lg-up:width[40rem]! md-down:(width[20rem] break-words)`}
  }
  .notificationWrapper {
    ${tw`margin-top[1rem]!`}
  }

  svg {
    ${tw`(height[1.5rem] width[1.5rem])!`}
  }
`;
