import { useDispatch } from 'react-redux';
import {
  toggleShowDeleteModal,
  toggleShowExportModal,
  toggleShowIntegrateModal,
  toggleShowMentionReportModal,
  toggleShowPublishModal,
  toggleShowRerunModal,
  toggleShowShareModal,
} from 'slices/toggle.slice';
import { HistoryItem, ILibraryItem } from 'utils/models';
import * as actions from 'actions';
import { useCopyToClipboard } from 'react-use';
import { customToast } from 'utils/toast.util';
import { useHistory } from 'react-router-dom';
import { toggleThumbnailModal } from 'slices/global.slice';
import {
  canRedirect,
  getLatestTitle,
  isAudioByMIME,
  msToTime,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { filenameFormatter, isEmptyOrSpaces } from 'utils/utils';
import { toNumber } from 'lodash';

type Props = {
  item: ILibraryItem;
};

export const useItemFunction = (props: Props) => {
  const { item } = props;

  const dispatch = useDispatch();
  const [, copyToClipboard] = useCopyToClipboard();
  const history = useHistory();

  const handleDeleteSingleItem = () => {
    dispatch(toggleShowDeleteModal(true));
    dispatch(actions.setFocusRow(item));
  };

  const handleShare = () => {
    dispatch(toggleShowShareModal(true));
    dispatch(actions.setFocusRow(item));
  };

  const handleExport = () => {
    dispatch(toggleShowExportModal(true));
    dispatch(actions.setFocusRow(item));
  };

  const handleIntegrate = () => {
    dispatch(toggleShowIntegrateModal(true));
  };

  const handlePublish = () => {
    dispatch(actions.setFocusRow(item));
    dispatch(toggleShowPublishModal(true));
  };

  const handleMentionReport = () => {
    dispatch(actions.setFocusRow(item));
    dispatch(toggleShowMentionReportModal(true));
  };

  const handleCopyLink = () => {
    copyToClipboard(window.location.origin + `/transcription/${item.mediaid}`);
    customToast.success('Copied to clipboard');
  };

  const handleViewClip = () => {
    history.push({
      pathname: `/clips/${item?.mediaid}`,
      state: item,
    });
  };

  const handleUpdateThumbnail = () => {
    dispatch(
      toggleThumbnailModal({
        mediaid: item?.mediaid,
        title: getLatestTitle(item as any),
        isAudio: isAudioByMIME(item?.mediacontenttype),
        thumbnail: item?.thumbnail,
      }),
    );
  };

  const handleRedirectClick = (
    item: ILibraryItem,
    historyItem?: HistoryItem,
    isParagraph?: boolean,
  ) => {
    if (!canRedirect(item)) return;

    history.push(
      `/transcription/${item?.mediaid}?mode=${
        isParagraph ? 'paragraph' : 'caption'
      }`,
      historyItem,
    );
  };

  const historyItem = {
    mediaid: item?.mediaid,
    filename: isEmptyOrSpaces(item?.title)
      ? filenameFormatter(item?.filename)
      : item?.title,
    videoLength: msToTime(toNumber(item?.length)),
  };

  const handleEditCaption = (item: ILibraryItem) => {
    const newHistoryItem = {
      ...historyItem,
      editMode: true,
      paragraphMode: false,
    };

    handleRedirectClick(item, newHistoryItem);
  };

  const handleEditTranscript = (item: ILibraryItem) => {
    const newHistoryItem = {
      ...historyItem,
      editMode: true,
      paragraphMode: true,
    };
    handleRedirectClick(item, newHistoryItem, true);
  };

  const handleRerun = async () => {
    dispatch(actions.setFocusRow(item));
    dispatch(toggleShowRerunModal(true));
  };

  return {
    handleDeleteSingleItem,
    handleShare,
    handleExport,
    handleIntegrate,
    handlePublish,
    handleMentionReport,
    handleCopyLink,
    handleViewClip,
    handleUpdateThumbnail,
    handleEditCaption,
    handleEditTranscript,
    handleRerun,
    handleRedirectClick,
  };
};
