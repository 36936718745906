/** @jsxImportSource @emotion/react */
import 'twin.macro';

import React, {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Modal } from 'components/UiControls/modal/modal';
import { ModalOptions } from 'utils/enum';
import { ConfirmModalProps } from 'utils/models';
import { iabMock } from 'mocks/iab.mock';
import { IABModalItem } from './IABModalItem';
import { SearchInput } from '../SearchInput';
import { applyIABSearchChildren } from 'components/VideoPlayer/Transcription/MediaUtilities';

interface IPromiseParams {
  resolve: (option: ModalOptions) => void;
  reject: (err: any) => void;
}

interface Props {}

export const AddIABCategoryModal = forwardRef(
  (props: Props, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const searchedIAB = searchTerm?.trim() ? applyIABSearchChildren(iabMock.allCategories, searchTerm) : [];
    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (customProps: ConfirmModalProps): Promise<unknown> => {
      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const hideModal = () => {
      setSearchTerm('');
      setIsOpen(false);
    };

    // const handleYes = () => {
    //   hideModal();
    //   promiseInfo.current?.resolve(ModalOptions.YES);
    // };

    // const handleCancel = () => {
    //   hideModal();
    //   promiseInfo.current?.resolve(ModalOptions.CANCEL);
    // };

    return (
      <Modal show={isOpen} modalClosed={hideModal} classes="iab-modal">
        <div tw="w-full flex my-6">
          <div tw="w-1/2 border[1px solid] border-disabled-lighter rounded-md shadow overflow-hidden!">
            <div tw="px-6 py-2 bg-sonnant-grey-3 font-medium">
              IAB categories
            </div>
            <div tw="height[60rem] overflow-y-auto">
              {iabMock.allCategories.map((item) => (
                <IABModalItem key={item.id} item={item} />
              ))}
            </div>
          </div>

          <div tw="w-1/2 flex flex-col justify-start ml-6">
            <div tw="mb-6 flex justify-center">
              <SearchInput
                placeholder="Search IAB categories"
                setTerm={setSearchTerm}
              />
            </div>

            <div tw="max-height[58rem] overflow-y-auto">
              {searchedIAB.map((iab) => (
                <IABModalItem item={iab} autoExpanded={!!searchTerm} />
              ))}
              {searchedIAB?.length === 0 && (
                <div tw="color[#54566C] text-2xl font-medium mb-6">
                  No results found
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);
