/** @jsxImportSource @emotion/react */
import 'twin.macro';

// import CompanyIcon from 'assets/Icons/company_name.png';
// import NameIcon from 'assets/Icons/name-signup-icon.png';
// import MailImg from 'assets/Icons/username-login-icon.png';

import ArrowIcon from 'assets/Icons/start_free_trial_arrow.png';
import Loader from 'components/loader/loader';
import { textLink } from 'components/shared/twin.styles';
import jwt_decode from 'jwt-decode';
import { useState } from 'react';
import { getDomainWithoutSubdomain, ValidityCheck } from '../../utils/utils';
import { Notification } from '../UiControls/notification/Notification';
import { AuthService, FbSdkResponse } from 'services';
import { Social } from 'utils/enum';
import { AppleIdResponse, GoogleIdResponse } from 'services';
import { borderGradientBg } from 'components/shared/twin.styles';
import { customToast } from 'utils/toast.util';

interface SocialAuthForm {
  companyname: string;
  subdomain: string;
  firstname: string;
  lastname: string;
  email: string;
  authToken?: string;
  profilePicture?: string;
  provider?: Social;
}

interface Props {
  provider: Social;
  credential: GoogleIdResponse | AppleIdResponse | any;
}

export const SignUpSocial = ({ provider, credential }: Props) => {
  const [form, setForm] = useState<SocialAuthForm>(() => {
    let firstname = '';
    let lastname = '';
    let email = '';
    let authToken = '';
    let profilePicture = undefined;

    if (provider === Social.GOOGLE) {
      const idToken = credential?.id_token;
      const decodedToken: any = jwt_decode(idToken);

      firstname = decodedToken?.given_name ?? '';
      lastname = decodedToken?.family_name ?? '';
      email = decodedToken?.email ?? '';
      authToken = idToken;
    } else if (provider === Social.APPLE) {
      const rawIdToken = credential?.authorization?.id_token;
      const idTokenDecoded: any = jwt_decode(rawIdToken);

      email = idTokenDecoded?.email ?? '';
      authToken = credential?.authorization?.id_token;
    } else if (provider === Social.FACEBOOK) {
      const user = credential as FbSdkResponse;

      firstname = user?.first_name;
      lastname = user?.last_name;
      email = user?.email;
      authToken = user?.id;
      profilePicture = user?.picture?.data?.url;
    }

    return {
      companyname: '',
      subdomain: '',
      firstname,
      lastname,
      email,
      authToken,
      profilePicture,
    };
  });

  const [error, setError] = useState<any>({
    companynameError: false,
    subdomainError: false,
    inValidDomain: false,
    firstnameError: false,
    lastnameError: false,
    emailError: false,
    information: true,
    success: true,
  });

  const [warning, setWarning] = useState<any>({
    companynameWarning: false,
    domainWarning: false,
    firstnameWarning: false,
    lastnameWarning: false,
    emailWarning: false,
    message: 'Maximum number of characters entered',
  });
  const [loading, setLoading] = useState<any>(false);
  const [check, setCheck] = useState<any>(false);
  const [focusFirst, setfocusFirst] = useState<any>(false);
  const [focusLast, setfocusLast] = useState<any>(false);
  const [focusCom, setfocusCom] = useState<any>(false);

  const handleChange = (property: any) => (e: any) => {
    let letters = /^[A-Za-z0-9]+$/;
    let value = e.target.value;
    if (property !== 'domain' && property !== 'email' && value.length === 50) {
      setWarning((preValue: any) => ({
        ...preValue,
        [property + 'Warning']: true,
      }));
    } else if (
      (property === 'domain' || property === 'email') &&
      value.length === 100
    ) {
      setWarning((preValue: any) => ({
        ...preValue,
        [property + 'Warning']: true,
      }));
    } else {
      setWarning((preValue: any) => ({
        ...preValue,
        [property + 'Warning']: false,
      }));
    }
    if (property === 'companyname' || property === 'domain') {
      let validateDomain = /^(?:(w)(?!\1{3})|[^w])+$/;
      let subdomain: any;
      if (value.match(letters)) {
        subdomain = value.toLowerCase();
      } else {
        setError((preValue: any) => ({ ...preValue, subdomainWarning: true }));
        subdomain = value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
      }
      subdomain = subdomain.trim();
      if (validateDomain.test(value)) {
        if (value.match('www')) {
          setError((preValue: any) => ({ ...preValue, inValidDomain: true }));
        } else {
          setError((preValue: any) => ({ ...preValue, inValidDomain: false }));
        }
      } else {
        setError((preValue: any) => ({ ...preValue, inValidDomain: false }));
      }
      setForm((preValue: any) => ({
        ...preValue,
        [property]: value,
        subdomain,
      }));
    } else if (property === 'firstname' || property === 'lastname') {
      let alphaNumeric = /^[a-zA-Z0-9\-\s]+$/;
      if (!value || value.match(alphaNumeric)) {
        setForm((preValue: any) => ({ ...preValue, [property]: value }));
      }
    } else if (property === 'email') {
      setForm((preValue: any) => ({
        ...preValue,
        [property]: value.toLowerCase(),
      }));
    } else {
      setForm((preValue: any) => ({ ...preValue, [property]: value }));
    }
  };

  const onFocusFirst = (e: any) => {
    setfocusFirst(false);
  };
  const onFocusLast = (e: any) => {
    setfocusLast(false);
  };
  const onBlurFirst = (e: any) => {
    if (form.firstname) setfocusFirst(false);
    else setfocusFirst(true);
  };
  const onBlurLast = (e: any) => {
    if (form.lastname) setfocusLast(false);
    else setfocusLast(true);
  };

  const onFocusCom = (e: any) => {
    setfocusCom(false);
  };
  const onBlurCom = (e: any) => {
    if (form.companyname) setfocusCom(false);
    else setfocusCom(true);
  };

  const handleClose = (property: any) => {
    setError((preValue: any) => ({ ...preValue, [property]: false }));
  };
  const handleWarningClose = (property: any) => {
    setWarning((preValue: any) => ({ ...preValue, [property]: false }));
  };
  const toggleCheck = () => {
    setCheck((preValue: any) => !preValue);
  };

  const Valid = () =>
    ValidityCheck(
      form.companyname,
      form.subdomain,
      form.lastname,
      form.firstname,
      form.email,
      check,
    );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // if (hasFormErrors()) return;

    setLoading(true);
    let { subdomain } = form;
    subdomain = subdomain
      .replace('.voice-intellect.com', '')
      .replace('.sonnant.ai', '')
      .replace('tharunagarwal.com', '');

    AuthService.registerSocial({
      companyname: form.companyname,
      email: form.email,
      firstname: form.firstname,
      lastname: form.lastname,
      subdomain,
      token: form?.authToken,
      profilePicture: form?.profilePicture,
      provider,
    })
      .then((signInResult) => {
        setError((preValue: any) => ({
          ...preValue,
          apiError: false,
          apiErrorMessage: '',
          apiSuccess: true,
          apiSuccessMessage: signInResult.data,
        }));
        setLoading(false);
        customToast.loading('Account registered successfully. Redirecting...');
        
        setForm((preValue: any) => ({
          ...preValue,
          companyname: '',
          subdomain: '',
          firstname: '',
          lastname: '',
          email: '',
        }));

        const domainUrl = `${
          window.location.protocol
        }//${subdomain}.${getDomainWithoutSubdomain()}`;
        window.location.replace(
          `${domainUrl}?access_token=${signInResult.data.AccessToken}&id_token=${signInResult.data.IdToken}&refresh_token=${signInResult.data.RefreshToken}`,
        );

        // history.push('/message', {
        //   name: 'Sign up',
        //   type: 'successful',
        //   message: sign_up_success_message,
        //   checkmessage: sign_up_check_message,
        // });
      })
      .catch((err: any) => {
        if (err.response && err.response.status === 500) {
          setError((preValue: any) => ({
            ...preValue,
            apiError: true,
            apiErrorMessage:
              'A Sonnant Library of the same name already exists. Please ensure you select a unique Sonnant Domain.',
          }));
        } else if (err.response) {
          setError((preValue: any) => ({
            ...preValue,
            apiError: true,
            apiErrorMessage: err.response.data,
          }));
        } else {
          // history.push('/message', {
          //   name: 'Sign up',
          //   type: 'unsuccessful',
          //   message: sign_up_fail_message,
          //   route: '/signup',
          // });
        }
        setLoading(false);
      });
  };
  return (
    <>
      {loading && (
        <div className="loader__component" tw="(fixed bg-white)!">
          <Loader />
        </div>
      )}

      {error.apiError && (
        <p className="topMessage error" tw="relative pr-10! shadow rounded mb-6">
          {/* <CloseSVG tw="absolute top[0.6rem] right[1rem] cursor-pointer" name={'apiError'} close={handleClose} /> */}
          {error.apiErrorMessage}
        </p>
      )}

      <form
        className="signupForm grid-container full"
        id="signupForm"
        onSubmit={handleSubmit}
      >
        <div tw="flex space-x-8 mb-2">
          <div tw="w-1/2">
            <label tw="text-sonnant-blue font-medium font-size[1.5rem]! relative">
              First Name
              <input
                type="text"
                placeholder="John"
                className={`${error.firstnameError ? 'error' : ''}`}
                onChange={handleChange('firstname')}
                value={form.firstname}
                maxLength={50}
                onInput={() => setError({ ...error, firstnameError: false })}
                onFocus={onFocusFirst}
                onBlur={onBlurFirst}
              />
              <Notification
                name={'firstnameError'}
                close={handleClose}
                type={focusFirst || error.firstnameError ? 'error' : null}
                message={'First Name is a mandatory field'}
              />
              <Notification
                name={'firstnameWarning'}
                close={handleWarningClose}
                type={warning.firstnameWarning ? 'warning' : null}
                message={warning.message}
              />
              {/* <img
                src={NameIcon}
                alt="Username"
                tw="absolute width[3.4rem] top[3rem] right-2.5 "
              /> */}
            </label>
          </div>
          <div tw="w-1/2">
            <label tw="text-sonnant-blue font-medium font-size[1.5rem]! relative">
              Last Name
              <input
                type="text"
                onChange={handleChange('lastname')}
                value={form.lastname}
                placeholder="Smith"
                maxLength={50}
                onInput={() => setError({ ...error, lastnameError: false })}
                onFocus={onFocusLast}
                onBlur={onBlurLast}
              />
              <Notification
                name={'lastnameError'}
                close={handleClose}
                type={focusLast || error.lastnameError ? 'error' : null}
                message={'Last Name is a mandatory field'}
              />
              {/* <img
                src={NameIcon}
                alt="Username"
                tw="absolute width[3.4rem] top[3rem] right-2.5 "
              /> */}
            </label>
          </div>
        </div>

        <div tw="w-full mb-2">
          <label tw="text-sonnant-blue font-medium font-size[1.5rem]! relative">
            Email address
            <input
              disabled
              type="text"
              className={`${error.emailError ? 'error' : ''}`}
              onChange={handleChange('email')}
              value={form.email}
              placeholder="John@example.com"
              maxLength={100}
              onInput={() => setError({ ...error, emailError: false })}
            />
            {/* <img
              src={MailImg}
              alt="Username"
              tw="absolute width[3.4rem] top[4rem] right-2.5"
            /> */}
          </label>
        </div>

        <div tw="w-full mb-2">
          <label tw="text-sonnant-blue font-medium font-size[1.5rem]! relative">
            Library / Company Name
            <input
              type="text"
              className={`${error.companynameError ? 'error' : ''}`}
              onChange={handleChange('companyname')}
              value={form.companyname}
              placeholder="Company Name"
              maxLength={50}
              onInput={() => setError({ ...error, companynameError: false })}
              onFocus={onFocusCom}
              onBlur={onBlurCom}
            />
            {form?.subdomain?.trim() && (
              <div tw="w-full text-right -mt-2 -mb-4">
                {form.subdomain}.
                {getDomainWithoutSubdomain(window.location.origin)}
              </div>
            )}
            <Notification
              name={'companynameError'}
              close={handleClose}
              type={focusCom || error.companynameError ? 'error' : null}
              message={'Company Name is a mandatory field'}
            />
            <Notification
              name={'inValidDomain'}
              close={handleClose}
              type={error.inValidDomain ? 'error' : null}
              message={"'www' is not an acceptable Sub Domain Value."}
            />
            <Notification
              name={'companynameWarning'}
              close={handleWarningClose}
              type={warning.companynameWarning ? 'warning' : null}
              message={warning.message}
            />
            {/* <img
              src={CompanyIcon}
              alt="Username"
              tw="absolute width[3.4rem] top[3.2rem] right-2.5"
            /> */}
          </label>
        </div>
        <label htmlFor="terms" tw="margin-bottom[-3rem] sm-down:(margin-bottom[-1rem])">
          <div tw="w-full mb-2 flex ">
            <div tw="mr-2 mt-6 mb-8 justify-center">
              <input
                id="terms"
                onChange={toggleCheck}
                checked={check}
                type="checkbox"
                tw="block height[20px] width[20px] border[2px solid #7f8090]! rounded visible! cursor-pointer"
              />
            </div>
            <div tw="font-size[13px] line-height[16px] mt-6 text-sonnant-grey-5 font-normal">
              <div>
                By creating an account you agree to our{' '}
                <a
                  css={[textLink]}
                  href="https://www.sonnant.com/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions{' '}
                </a>
              </div>
              <div>
                and have read and agree to our
                <a
                  css={[textLink]}
                  href="https://www.sonnant.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  Privacy Policy.
                </a>
              </div>
            </div>
          </div>
        </label>
        <div tw="w-full mb-6">
          {/* <button
            type="submit"
            tw="flex justify-center items-center font-size[1.8rem]! mt-0!"
            disabled={Valid()}
          >
            <img src={ArrowIcon} tw="width[40px]" alt="" />
            <div tw="relative flex items-center font-medium">
              START FREE TRIAL
            </div>
          </button> */}
          <button
            type="submit"
            tw="relative flex justify-center items-center font-size[1.8rem]! (rounded-full bg-sonnant-purple-4)!"
            css={[borderGradientBg('2px', '#201F66')]}
            disabled={Valid()}
          >
            <div tw="relative flex items-center">
              <img src={ArrowIcon} tw="width[40px]" alt="" />
              <span tw="(line-height[initial] margin-top[1px])!">
                START FREE TRIAL
              </span>
            </div>
          </button>
        </div>
      </form>
    </>
  );
};
