/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ListViewClipsItem } from 'components/DisplayVersions/ListViewClipsItem';
import { ThumbnailClipsItem } from 'components/DisplayVersions/ThumbnailClipsItem';
import { ReactComponent as AscendingArrow } from 'assets/Icons/Ascending_Arrow.svg';
import { ReactComponent as DescendingArrow } from 'assets/Icons/Descending_Arrow.svg';
import {
  isSuggestedClips,
  isTrendingClips,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useHistory } from 'react-router-dom';
import { IClips } from 'utils/models';
import { HeaderGroup, useSortBy, useTable } from 'react-table';
import { isEmpty, isEqual, isNil, toNumber } from 'lodash';
import { ClipStatusCode, ModalOptions, SortField } from 'utils/enum';
import { changeOrder, changeSortBy } from 'slices/pagination.slice';
import { setSelectedRows } from 'actions';
import { useToggle } from 'react-use';
import { Modal } from 'components/UiControls/modal/modal';
import { Share } from 'components/Share/Share';
import { PreviewClipModal } from 'components/shared/PreviewClipModal/PreviewClipModal';
import { useState } from 'react';
import { Export } from 'components/Export/Export';
import { Publish } from 'components/Publish/Publish';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { MediaService } from 'services';
import { customToast } from 'utils/toast.util';
import { toggleReloadLibrary } from 'slices/toggle.slice';

type Props = {};

export const ClipSearch = (props: Props) => {
  const history = useHistory();

  const library = useSelector((state: RootState) => state.library);
  const pagination = useSelector((state: RootState) => state.pagination);
  const clips = useSelector((state: RootState) => state.clips);

  const dispatch = useDispatch();

  const previewModalRef = useRef<any>();
  const confirmModalRef = useRef<any>();

  const [openShareModal, toggleOpenShareModal] = useToggle(false);
  const [openExportModal, toggleOpenExportModal] = useToggle(false);
  const [openPublishModal, toggleOpenPublishModal] = useToggle(false);

  const [focusClip, setFocusClip] = useState<IClips | null>(null);

  const handleSingleClickClip = (item: IClips) => {
    const mediaid = item?.media_id ?? item?.publishpath?.split('/')?.[4];

    if (
      toNumber(ClipStatusCode.CREATION_FAILED) === toNumber(item?.statuscode)
    ) {
      return;
    }
    if (
      [toNumber(ClipStatusCode.CREATING)].includes(toNumber(item?.statuscode))
    ) {
      history.push(`/clips/${mediaid}`);
    } else {
      history.push(
        `/clips/${mediaid}?versioncount=${item?.versioncount ?? ''}`,
      );
    }
    window.location.reload();
  };

  const handleSetFocusClipStage = () => {
    if (!isNil(clips.focusClip)) {
      setFocusClip(clips.focusClip);
    }
  };

  const handleShare = () => {
    toggleOpenShareModal(true);
    handleSetFocusClipStage();
  };

  const handleExport = () => {
    toggleOpenExportModal(true);
    handleSetFocusClipStage();
  };

  const handlePublish = () => {
    toggleOpenPublishModal(true);
    handleSetFocusClipStage();
  };

  const handleShareModalClosed = () => {
    toggleOpenShareModal(false);
  };

  const handleExportModalClosed = () => {
    toggleOpenExportModal(false);
  };

  const handlepublishmodalclosed = () => {
    toggleOpenPublishModal(false);
  };

  const handleShowPreview = (item: any) => {
    const options = {
      startTime: item.starttime,
      endTime: item.endtime,
      mediaid: clips.focusClip?.media_id,
      title: item.versionname,
      versioncount: item.versioncount,
      thumbnail: item.thumbnail,
    };
    previewModalRef?.current?.show(options);
  };

  const handleShowPreviewPublish = async (
    startTime: number,
    endTime: number,
  ) => {
    if (isEqual(clips.focusClip?.statuscode, ClipStatusCode.CREATION_FAILED))
      return;

    const options = {
      startTime,
      endTime,
      mediaid: clips?.focusClip?.media_id!,
      title: clips.focusClip?.versionname,
      versioncount: clips?.focusClip?.versioncount,
      thumbnail: clips.focusClip?.thumbnail,
    };

    showPreviewCLip(options);
  };

  const showPreviewCLip = async (options: any) => {
    const result = await previewModalRef?.current?.show(options);
    if (result.option === ModalOptions.YES) {
      handleOpenModels(result.type);
    }
  };

  const handleOpenModels = (type: string) => {
    switch (type) {
      case 'Share':
        handleShare();
        break;
      case 'Export':
        handleExport();
        break;
      case 'Edit':
        handlePublish();
        break;
      default:
        break;
    }
  };

  const handleDeleteVersionMedias = async (singleItem: IClips) => {
    const result = await confirmModalRef.current?.show();
    if (result !== ModalOptions.YES) {
      return;
    }
    const clipsToDelete = isEmpty(singleItem)
      ? clips.selectedClips
      : [singleItem];
    const deleteItems = clipsToDelete.map((clip) => ({
      mediaid: clip.media_id ?? '',
      versioncount: clip.versioncount,
    }));
    const deleteVersionRequest = MediaService.deleteVersion(deleteItems);

    customToast.promise(deleteVersionRequest, {
      loading: 'Deleting clip(s)',
      success: 'Clip(s) deleted successfully',
      error: 'Clip(s) delete failed',
    });
    try {
      await deleteVersionRequest;
      dispatch(toggleReloadLibrary());
    } catch (error: any) {
      console.log('HTTP Error :>> ', error);
    }
  };

  return (
    <div className="grid-x thumbNails_block" tw="mx-3 mt-6">
      {!pagination.isListView ? (
        library.data?.library?.map((item: IClips, index: number) => (
          <ThumbnailClipsItem
            item={item}
            handleRedirectClick={handleSingleClickClip}
            isSuggested={isSuggestedClips(item)}
            isTrending={isTrendingClips(item)}
            fromSearchClips={true}
            handleShare={handleShare}
            handleExport={handleExport}
            handlePublish={handlePublish}
            handleShowPreviewPublish={handleShowPreviewPublish}
            handleDeleteVersionMedias={handleDeleteVersionMedias}
          />
        ))
      ) : (
        <ListItems
          items={library?.data?.library ?? []}
          handleRedirectClick={handleSingleClickClip}
          handleShare={handleShare}
          handleExport={handleExport}
          handlePublish={handlePublish}
          handleShowPreviewPublish={handleShowPreviewPublish}
          handleDeleteVersionMedias={handleDeleteVersionMedias}
        />
      )}
      <Modal
        show={openShareModal}
        modalClosed={handleShareModalClosed}
        classes="share-modal-width"
      >
        <Share
          mediaid={focusClip?.media_id ?? ''}
          focusVersioncount={focusClip?.versioncount}
        />
      </Modal>

      <Modal show={openExportModal} modalClosed={handleExportModalClosed}>
        <Export
          mediaid={focusClip?.media_id ?? ''}
          filename={focusClip?.versionname}
          closeExportModal={handleExportModalClosed}
          handleShowPreview={handleShowPreview}
          focusVersioncount={focusClip?.versioncount}
        />
      </Modal>
      <Modal
        classes="publish-modal-width"
        show={openPublishModal}
        modalClosed={handlepublishmodalclosed}
      >
        <Publish
          mediaid={focusClip?.media_id!}
          filename={focusClip?.versionname ?? ''}
          duration={toNumber(focusClip?.length)}
          closeShareModal={handlepublishmodalclosed}
          isOpen={openPublishModal}
          handleShowPreview={handleShowPreviewPublish}
          parentItem={focusClip}
        />
      </Modal>

      <ConfirmModal
        ref={confirmModalRef}
        title="Delete from clips"
        message={
          <div>
            <div>
              You are about to delete the selected clip(s) from your library.
            </div>
            <div>
              Should you proceed, this item will be permanently deleted and can
              not be recovered.
            </div>
            <div tw="font-medium">Would you like to continue?</div>
          </div>
        }
      />

      <PreviewClipModal ref={previewModalRef} />
    </div>
  );
};

const ListItems = ({
  items,
  handleRedirectClick,
  handleShare,
  handleExport,
  handlePublish,
  handleShowPreviewPublish,
  handleDeleteVersionMedias,
}: {
  items: IClips[];
  handleRedirectClick: (item: IClips) => unknown;
  handleShare?: () => void;
  handleExport?: () => void;
  handlePublish?: () => void;
  handleShowPreviewPublish?: (starTime: number, endTime: number) => void;
  handleDeleteVersionMedias?: (item: IClips) => void;
}) => {
  const dispatch = useDispatch();
  const pagination = useSelector((state: RootState) => state.pagination);

  const versionItemColumns = useMemo(
    (): any[] => [
      { Header: ' ', style: { width: '4rem' } },
      {
        Header: 'TYPE',
        accessor: 'mediatype',
        style: { textAlign: 'center', width: '4rem' },
        id: SortField.TYPE,
      },
      {
        Header: 'TITLE',
        accessor: 'versionname',
        width: '52%',
        id: SortField.TITLE,
      },
      {
        Header: 'DURATION',
        accessor: 'length',
        width: '8%',
        id: SortField.DURATION,
      },
      {
        Header: 'UPLOAD DATE',
        accessor: 'datetime',
        width: '12%',
        id: SortField.DATE_ADDED,
      },
      {
        Header: 'VIEWS',
        accessor: 'viewed',
        width: '8%',
        style: { textAlign: 'center' },
        id: SortField.VIEWS,
      },
      {
        Header: 'STATUS',
        accessor: 'status',
        width: '8%',
        id: SortField.STATUS,
      },
      { Header: '   ', width: '5%' },
    ],
    [],
  );

  const { getTableBodyProps, getTableProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: versionItemColumns,
        data: items ?? [],
        disableSortRemove: true,
        manualSortBy: true,
        initialState: {
          sortBy: [
            {
              id: pagination.sortBy,
              desc: !pagination.isAsc,
            },
          ],
        },
      },
      useSortBy,
    );

  const handleSortOrder = (type: SortField, isAsc?: boolean) => {
    if (isNil(isAsc)) {
      isAsc = true;
    }

    dispatch(changeSortBy(type));
    dispatch(changeOrder(isAsc));

    dispatch(setSelectedRows([]));
  };

  return (
    <div className="display_version_list_view" tw="w-full">
      <div className="list_display_block">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: HeaderGroup<IClips>) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="action-item"
              >
                {headerGroup.headers.map((column: any) => (
                  <th
                    className="action-item"
                    width={column.width}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={column.style}
                  >
                    <div
                      tw="relative cursor-pointer"
                      onClick={() =>
                        handleSortOrder(column.id, column.isSortedDesc)
                      }
                    >
                      {column.isSorted &&
                        (column.isSortedDesc ? (
                          <div tw="absolute -left-6 -top-3 flex flex-col">
                            <div tw="-mb-1 opacity-40">
                              <DescendingArrow />
                            </div>
                            <div tw="-mt-3 ">
                              <AscendingArrow />
                            </div>
                          </div>
                        ) : (
                          <div tw="absolute -left-6 -top-3 flex flex-col">
                            <div tw="-mb-1">
                              <DescendingArrow />
                            </div>
                            <div tw="-mt-3 opacity-40">
                              <AscendingArrow />
                            </div>
                          </div>
                        ))}
                      <div>{column.render('Header')}</div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((item, index) => {
              prepareRow(item);
              return (
                <ListViewClipsItem
                  key={index}
                  item={item.original}
                  handleRedirectClick={handleRedirectClick}
                  handleShare={handleShare}
                  handleExport={handleExport}
                  handlePublish={handlePublish}
                  handleShowPreviewPublish={handleShowPreviewPublish}
                  handleDeleteVersionMedias={handleDeleteVersionMedias}
                  fromSearchClips
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
