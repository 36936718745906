/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as Back } from 'assets/Icons/Back.svg';
import { ReactComponent as Next } from 'assets/Icons/Right.svg';

import { defaultTo, isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  disabledCss,
  roundedRippleHoverCss,
} from 'components/shared/twin.styles';
import { len } from 'utils/generic.util';
import { setEmbedFocusTermIndex } from 'slices/embed.slice';
import { useEmbedContext } from '../utils/useEmbedContext';

type Props = {};

export const EmbedTermSwitcher = (props: Props) => {
  const dispatch = useDispatch();

  const { controls } = useEmbedContext();

  const { focusTerm } = useSelector((state: RootState) => state.embed);

  const index = defaultTo(focusTerm?.currentIndex, 0);

  const hasPrev = index > 0;
  const hasNext = index < len(focusTerm?.occurrences) - 1;

  // +1 or -1 step
  const jumpToOccurrence = (step: number) => {
    if (!focusTerm) return;

    const { occurrences, currentIndex } = focusTerm;

    const nextIndex = currentIndex + step;

    dispatch(setEmbedFocusTermIndex(nextIndex));

    const jumpSecond = occurrences[nextIndex];
    controls.seek(jumpSecond);
  };

  if (isNil(focusTerm)) return null;

  return (
    <div
      tw="flex items-center justify-between gap-x-1 text-15 h-[4rem] border[2px solid] border-sonnant-grey-3 line-height[2.5rem] rounded-full p-[2px] pt-[3px] active:(background-color[none])!"
      title={focusTerm.name}
    >
      <span
        tw="flex"
        css={[roundedRippleHoverCss, !hasPrev && disabledCss]}
        onClick={() => jumpToOccurrence(-1)}
      >
        <Back />
      </span>

      <div tw="flex items-center">
        <span className="keywordval line-clamp-1" title={focusTerm.name}>
          {focusTerm.name}
        </span>

        <div tw="flex">
          <span tw="border[1px solid] text-13 border-sonnant-grey-4 rounded-full min-width[2.5rem] h-10 inline-flex justify-center items-center text-center ml-2 px-2 whitespace-nowrap">
            {focusTerm.currentIndex + 1} of {focusTerm.occurrences.length}
          </span>
        </div>
      </div>

      <span
        tw="flex"
        css={[roundedRippleHoverCss, !hasNext && disabledCss]}
        onClick={() => jumpToOccurrence(+1)}
      >
        <Next />
      </span>
    </div>
  );
};
