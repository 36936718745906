/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import Loader from 'components/loader/loader';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { ReactComponent as EmailSvg } from 'assets/Icons/email_settings.svg';
import { getTenantidFromIdToken } from 'utils/utils';
import { isEqual } from 'lodash';
import { setEmailSettings } from 'slices/global.slice';
import { UserService } from 'services';
import { customToast } from 'utils/toast.util';
import { useSearchParam } from 'react-use';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { ModalOptions, UnsubscribeParams } from 'utils/enum';
import { ConfirmModalProps, EmailSetting } from 'utils/models';
import { removeSearchQuery } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { useHistory } from 'react-router-dom';
import { tierSelector } from 'slices/payment.slice';
import { ResetBottomBar } from 'components/shared/ResetBottomBar/ResetBottomBar';
import { defaultSetting } from 'utils/default/defaultSetting';

interface Props {}

export const NotificationSetting = (props: Props) => {
  const shouldUnsubscribeRevival =
    useSearchParam(UnsubscribeParams.REVIVAL) === 'true';
  const shouldUnsubscribeProcessing =
    useSearchParam(UnsubscribeParams.PROCESSING) === 'true';
  const shouldUnsubscribeReporting =
    useSearchParam(UnsubscribeParams.REPORTING) === 'true';

  const emailSettings = useSelector(
    (state: RootState) => state.global.emailSettings,
  );
  const tier = useSelector(tierSelector);
  const payment = useSelector((state: RootState) => state.payment);
  const user = useSelector((state: RootState) => state.userProfile);

  const dispatch = useDispatch();
  const history = useHistory();
  const unsubscribeRevivalModal = useRef<any>();

  const [state, setState] = useState({
    ...emailSettings,
  });

  const confirmModalRef = useRef<any>();

  useEffect(() => {
    // Including true and undefined, to ensure it will be called only once
    if ([true, undefined].includes(payment.subscription?.isLoading)) return;

    if (shouldUnsubscribeRevival) {
      if (state.hasRevivalNotification === false) {
        customToast.success(
          'You have already unsubscribed from archival revival notifications.',
        );
        return;
      }
      confirmUnsubscribeRevivalAsync(
        UnsubscribeParams.REVIVAL,
        'Archival Revival',
        'Unsubscribing from the Archival Revival email will stop sending emails when trending items are detected. The notifications and trending items can still be found in the Sonnant App.',
      );
    }

    if (shouldUnsubscribeProcessing) {
      if (state.hasProcessingNotification === false) {
        customToast.success(
          'You have already unsubscribed from processing notifications.',
        );
        return;
      }
      confirmUnsubscribeRevivalAsync(
        UnsubscribeParams.PROCESSING,
        'Processing Notifications',
        'Unsubscribing from the Processing Notifications email will stop sending emails for incoming status updates for your items and clips. The notifications can still be found in the Sonnant App.',
      );
    }

    if (shouldUnsubscribeReporting) {
      if (state.hasWatchListNotification === false) {
        customToast.success(
          'You have already unsubscribed from reporting notifications.',
        );
        return;
      }
      confirmUnsubscribeRevivalAsync(
        UnsubscribeParams.REPORTING,
        'Reporting Notifications',
        'Unsubscribing from the Reporting Notifications email will stop sending emails for your incoming processing reports. The notifications can still be found in the Sonnant App.',
      );
    }
  }, [
    payment.subscription?.isLoading,
    shouldUnsubscribeRevival,
    shouldUnsubscribeProcessing,
    shouldUnsubscribeReporting,
  ]);

  const hasDisableReset = isEqual(defaultSetting.emailSettings, state);

  const hasChanges = !isEqual(emailSettings, state);

  const confirmUnsubscribeRevivalAsync = async (
    urlParams: string,
    title: string,
    message: string,
  ) => {
    if (tier.isEnterpriseTrial && !payment.isExpiredTrial) {
      customToast.error(
        'Notifications cannot be turned off during TRIAL period',
      );
      return;
    }

    const result = await unsubscribeRevivalModal.current?.show({
      title: `Unsubscribe ${title}`,
      confirmText: 'Confirm',
      message: (
        <div>
          <div>{message}</div>
          <div>
            <b>Would you like to proceed?</b>
          </div>
        </div>
      ),
    } as ConfirmModalProps);

    if (result !== ModalOptions.YES) return;

    // Override the hasRevivalNotification to 'false'
    const newSetting = {
      ...state,
    };

    if (urlParams === UnsubscribeParams.REVIVAL) {
      newSetting.hasRevivalNotification = false;
    }
    if (urlParams === UnsubscribeParams.PROCESSING) {
      newSetting.hasProcessingNotification = false;
    }
    if (urlParams === UnsubscribeParams.REPORTING) {
      newSetting.hasWatchListNotification = false;
    }

    handleApplySettings(newSetting);

    // Cleanup url to normal link
    const url = removeSearchQuery(urlParams);
    history.replace(url);
  };

  useEffect(() => {
    if (payment.subscription.isLoading) return;

    setState(emailSettings);
  }, [payment.subscription.isLoading, emailSettings]);

  const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    // in trial period
    const isSonnantTenant =
      user.email?.endsWith('@sonnant.com') ||
      user.email?.endsWith('@ptnglobalcorp.com');

    if (!isSonnantTenant && tier.isEnterpriseTrial && !payment.isExpiredTrial) {
      customToast.error(
        'Notifications cannot be turned off during ENTERPRISE FREE TRIAL period',
      );
      return;
    }
    const checked = e.target.checked;

    setState({
      ...state,
      [e.target.name]: checked,
    });
  };

  const handleReset = () => {
    setState({ ...emailSettings });
  };

  const handleApplySettings = async (newSettings: EmailSetting = state) => {
    try {
      const tenantid = getTenantidFromIdToken();
      const saveAsync = UserService.savePreference({
        tenantid,
        emailsettings: JSON.stringify(newSettings),
      });

      customToast.promise(saveAsync, {
        loading: 'Saving changes...',
        success: 'Saved successfully',
        error: 'Save failed',
      });

      await saveAsync;

      dispatch(setEmailSettings(newSettings));
    } catch (error: any) {
      console.log('error :>> ', error);
    }
  };

  const handleResetDefault = async () => {
    const result = await confirmModalRef.current?.show({
      title: 'Confirm reset to default',
      confirmText: 'Confirm',
      message: (
        <div>
          Are you sure you want to reset notifications configuration to default?
        </div>
      ),
    } as ConfirmModalProps);

    if (result !== ModalOptions.YES) return;

    setState(defaultSetting.emailSettings);
    handleApplySettings(defaultSetting.emailSettings);
  };

  return (
    <>
      {payment.subscription.isLoading && (
        <div
          className="loader__component"
          tw="height[calc(100vh - 13rem)] top[13rem] opacity-100 z-[1]"
        >
          <Loader />
        </div>
      )}

      <ResetBottomBar
        isShow={hasChanges}
        onReset={handleReset}
        onSubmit={handleApplySettings}
      />

      <ConfirmModal
        ref={confirmModalRef}
        message="Please upgrade to enable additional users"
        title="Confirm"
        confirmText="Upgrade"
        classes="disable-enable-user-modal-width"
      />

      <div tw="ml-3">
        <form onSubmit={() => handleApplySettings()}>
          <div tw="flex flex-row">
            <div className="btnReset" tw="flex flex-nowrap order-1">
              <button
                type="button"
                className={` success button apply`}
                onClick={handleResetDefault}
                disabled={hasDisableReset}
              >
                Reset
              </button>
            </div>
            <div tw="flex-1">
              <div className="prefTitle" tw="flex items-center">
                <EmailSvg />
                <h3 tw="ml-6 mb-0">Email notifications</h3>
              </div>
              <div tw="flex">
                <label
                  className="cell cus-inline-block"
                  tw="ml-14 max-w-full mb-2 w-auto cursor-pointer"
                >
                  <input
                    name="hasProcessingNotification"
                    checked={state.hasProcessingNotification}
                    onChange={handleCheckboxClick}
                    type="checkbox"
                  />
                  <span className="checkmark" style={{ top: '4px' }} />
                  <span>Processing notifications</span>
                </label>
              </div>
              <div tw="flex">
                <label
                  className="cell cus-inline-block"
                  tw="ml-14 mb-2 w-auto cursor-pointer"
                >
                  <input
                    name="hasRevivalNotification"
                    checked={state.hasRevivalNotification}
                    onChange={handleCheckboxClick}
                    type="checkbox"
                  />
                  <span className="checkmark" style={{ top: '4px' }} />
                  <span>Archival revival</span>
                </label>
              </div>
              <div tw="flex">
                <label
                  className="cell cus-inline-block"
                  tw="ml-14 w-auto cursor-pointer"
                >
                  <input
                    name="hasWatchListNotification"
                    checked={state.hasWatchListNotification}
                    onChange={handleCheckboxClick}
                    type="checkbox"
                  />
                  <span className="checkmark" style={{ top: '4px' }} />
                  <span>Reporting notifications</span>
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>

      <ConfirmModal ref={unsubscribeRevivalModal} />
    </>
  );
};
