/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { CustomSelectSearch } from 'components/shared/CustomSelectSearch';
import { ReactComponent as FlagSvg } from 'assets/Icons/flag.svg';
import { ReactComponent as KeyTermSvg } from 'assets/Icons/keyterms.svg';
import { ReactComponent as PeopleSvg } from 'assets/Icons/people.svg';
import { ReactComponent as PlaceSvg } from 'assets/Icons/place.svg';
import { ReactComponent as ShoppingSvg } from 'assets/Icons/shopping.svg';
import { ReactComponent as IABSvg } from 'assets/Icons/workspaces_black_24dp.svg';
import { ReactComponent as BusinessSvg } from 'assets/Icons/business.svg';
import { ReactComponent as TranscriptSvg } from 'assets/Icons/Event.svg';
import { ReactComponent as GarmSvg } from 'assets/Icons/garm.svg';

import {
  GroupBase,
  MenuPlacement,
  SingleValue,
  StylesConfig,
} from 'react-select';
import { Layers } from 'utils/enum';

import { isNil } from 'lodash';

interface IProps {
  onChange: (type: Layers) => void;
  disabled?: boolean;
  tabIndex?: number;
  defaultValue?: string;
  menuPlacement?: MenuPlacement;
  hasIABLayer?: boolean;
  hasTranscripts?: boolean;
  hasSensitive?: boolean;
}

type Option = {
  label: string;
  type: Layers;
};

export const TopicLayerDropDown = (props: IProps) => {
  const layerOptions: Option[] = [
    {
      label: 'Key terms',
      type: Layers.KEY_TERM,
    },
    {
      label: 'People',
      type: Layers.PEOPLE,
    },
    {
      label: 'Products',
      type: Layers.PRODUCT,
    },
    {
      label: 'Organisations',
      type: Layers.ORG,
    },
    {
      label: 'Locations',
      type: Layers.LOCATION,
    },
    {
      label: 'Nationalities',
      type: Layers.NATIONALITY,
    },
  ];

  // Insert at first
  if (props.hasTranscripts) {
    layerOptions.unshift({
      label: 'Transcripts',
      type: Layers.TRANSCRIPT,
    });
  }

  // Insert at last
  if (props.hasIABLayer) {
    layerOptions.push({
      label: 'IAB Categories',
      type: Layers.IAB,
    });
  }

  if (props.hasSensitive) {
    layerOptions.push({
      label: 'Sensitive Topics',
      type: Layers.GARM_REPORT,
    });
  }

  const handleChangeOption = (e: SingleValue<Option>) => {
    if (isNil(e?.type)) return;

    let type = e?.type;

    props.onChange(type as Layers);
  };

  return (
    <div>
      <CustomSelectSearch
        layerOptions={[...layerOptions]}
        formatOptionLabel={renderCustomOption}
        onChange={handleChangeOption}
        isSearchable={false}
        disabled={props?.disabled}
        customStyles={customSelectStyles}
        tabIndex={props?.tabIndex}
        defaultValue={props?.defaultValue || ''}
        menuPlacement={props?.menuPlacement}
        canCreate={false}
      />
    </div>
  );
};

const renderCustomOption = (option: Option) => (
  <div tw="flex flex-row">
    <div tw="max-width[3.5rem] mr-4 flex items-center justify-center">
      {option.type === Layers.KEY_TERM ? (
        <KeyTermSvg css={[blackIconCss]} />
      ) : option.type === Layers.PEOPLE ? (
        <PeopleSvg className="entity-icon" />
      ) : option.type === Layers.PRODUCT ? (
        <ShoppingSvg className="entity-icon" />
      ) : option.type === Layers.ORG ? (
        <BusinessSvg className="entity-icon" />
      ) : option.type === Layers.LOCATION ? (
        <PlaceSvg className="entity-icon" />
      ) : option.type === Layers.NATIONALITY ? (
        <FlagSvg className="entity-icon" />
      ) : option.type === Layers.TRANSCRIPT ? (
        <TranscriptSvg css={[blackIconCss, tw`ml-1.5`]} />
      ) : option.type === Layers.GARM_REPORT ? (
        <GarmSvg className="entity-icon" />
      ) : (
        <IABSvg className="layer-icon" />
      )}
    </div>

    <div tw="flex flex-col w-full">
      <div tw="text-15 text-left text-sonnant-dark">{option.label}</div>
    </div>
  </div>
);

const customSelectStyles: StylesConfig<any, false, GroupBase<any>> = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  container: (base) => ({
    ...base,
    height: '4rem',
    minWidth: '5rem',
    zIndex: 1000,
  }),
  menu: (base) => ({
    ...base,
    marginBottom: '4px',
    marginTop: '1px',
    width: '20rem',
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '340px',
  }),
  control: (base) => ({
    ...base,
    backgroundColor: '#f0f3f6',
    borderColor: 'transparent',
    cursor: 'pointer',
    marginRight: 0,
    height: '4rem',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    paddingLeft: '0.6rem',
    display: 'flex',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: '38px',
  }),
  placeholder: (base) => ({
    ...base,
    color: 'black',
    fontWeight: 'normal',
    fontSize: '14px',
    width: '15rem',
    height: '4rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    lineHeight: '36px',
    marginTop: '1px',
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    cursor: 'pointer',
    padding: '.5rem 1rem',
    backgroundColor: isSelected || isFocused ? '#DEEBFF' : 'unset',
  }),
  noOptionsMessage: (base) => ({
    ...base,
    padding: '0.3rem',
    fontSize: '14px',
  }),
};

const blackIconCss = css`
  & path {
    fill: #000;
  }
`;
