/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { CollapsibleList } from '@rmwc/list';
import { Ripple } from '@rmwc/ripple';
import { cloneDeep } from 'lodash';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';

import { ReactComponent as VisibilityOff } from 'assets/Icons/VisibilityOff.svg';
import { ReactComponent as VisibilityOn } from 'assets/Icons/VisibilityOn.svg';
import { ReactComponent as AddCircle } from 'assets/Icons/add_circle.svg';
import { ReactComponent as ExpandCircle } from 'assets/Icons/expand_circle.svg';
import megaphoneLogo from 'assets/Images/megaphone-logo.png';

import { CollectionDropdown } from 'components/shared/CollectionDropdown/CollectionDropdown';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { Hint } from 'components/shared/Hint';
import { QuestionHint } from 'components/shared/QuestionHint';
import { Switcher } from 'components/shared/Switcher';
import {
  removeMegaphoneIntegration,
  updateSingleMegaphoneIntegration,
} from 'slices/megaphone.slice';
import { tierSelector } from 'slices/payment.slice';
import { CollectionOptionEnum, ModalOptions } from 'utils/enum';
import { MegaphoneIntegrationOption } from 'utils/models';
import { customToast } from 'utils/toast.util';
import {
  customEnableSwitcherLabelCss,
  customIconCss,
  customTooltipCss,
} from '../Integrations/IntegrationItem';
import { MegaphoneIntegrationFilter } from './MegaphoneIntegrationFilter';

type Props = {
  integration: MegaphoneIntegrationOption;
};

export const MegaphoneIntegrationItem = ({ integration, ...props }: Props) => {
  const dispatch = useDispatch();

  const confirmRemoveModalRef = useRef<any>(null);

  const tier = useSelector(tierSelector);
  const notSubscriber = !tier.isSubscriber;

  const [isShowApiKey, toggleIsShowApiKey] = useToggle(false);
  const [isOpenFilter, toggleIsOpenFilter] = useToggle(false);

  const handleChangeApiKey = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;

    const clonedIntegration = cloneDeep(integration);

    clonedIntegration.apiKey = value;

    const updatedProgramFilters = clonedIntegration.filter.programFilters.map(
      (programFilter) => ({
        ...programFilter,
        programId: '',
      }),
    );

    clonedIntegration.filter.programFilters = updatedProgramFilters;

    dispatch(updateSingleMegaphoneIntegration(clonedIntegration));
  };

  const handleToggleOptions = (
    optionLabel: keyof MegaphoneIntegrationOption,
  ) => {
    const isChecked = !integration[optionLabel];

    dispatch(
      updateSingleMegaphoneIntegration({
        ...integration,
        [optionLabel]: isChecked,
      }),
    );
  };

  const handleRemoveIntegration = async () => {
    const result = await confirmRemoveModalRef.current?.show();

    if (result !== ModalOptions.YES) return;

    dispatch(removeMegaphoneIntegration({ integrationId: integration?.id }));

    customToast.success('Removed');
  };

  return (
    <div tw="flex flex-row py-6 w-full">
      <div tw="min-w-[20rem] w-[50rem] p-[5rem]">
        <img tw="w-full" src={megaphoneLogo} alt="megaphone-logo" />
      </div>

      <div tw="flex flex-col w-full pl-[3rem]">
        <div tw="flex w-full items-center">
          <div tw="min-w-[50%]">
            <div tw="flex flex-col pb-6">
              <div tw="flex flex-row items-center">
                <label tw="flex flex-nowrap mr-3 text-16">API Key</label>
                <QuestionHint
                  notTransparent
                  hideCloseButton
                  type="information"
                  align="bottomLeft"
                  action="click"
                  text={
                    <div tw="py-1">
                      <p>
                        To enable Sonnant to write metadata back to Megaphone an
                        API Key needs to be generated in Megaphone. This can be
                        done via token generation in the Megaphone platform. Log
                        in to Megaphone, hover over your initials in the
                        lower-left corner and select "Settings". Click "Generate
                        New Token". The Megaphone integrate option will only be
                        available for content items that were uploaded from
                        Megaphone. This option is only available to subscription
                        and enterprise customers.
                      </p>
                    </div>
                  }
                  customStyle={customTooltipCss}
                />
              </div>
              <div tw="flex flex-row max-w-[75rem] md-down:(w-[40rem])">
                <div tw="flex-1 relative ">
                  <input
                    tw="flex pr-[8rem]! mb-0"
                    type={isShowApiKey ? 'text' : 'password'}
                    value={integration.apiKey}
                    onChange={handleChangeApiKey}
                    placeholder="**********************************"
                  />

                  <span onClick={toggleIsShowApiKey}>
                    {isShowApiKey ? (
                      <VisibilityOn tw="mt-[1px]" css={customIconCss} />
                    ) : (
                      <VisibilityOff css={customIconCss} />
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <Hint
            text="Not available on free trial and PAYG. Please upgrade to enable."
            enterDelay={0}
            arrow
            disabled={!notSubscriber}
            align="bottom"
          >
            <div css={customEnableSwitcherLabelCss}>
              <Switcher
                hasLabel={true}
                checked={integration.isEnabled}
                disabled={notSubscriber}
                color="#00AF7A"
                label="ENABLED"
                onCheckedChange={() => handleToggleOptions('isEnabled')}
              />
            </div>
          </Hint>
        </div>

        <CollapsibleList
          handle={
            <Ripple tw="py-3 pl-2.5 pr-3 rounded" onClick={toggleIsOpenFilter}>
              <div tw="inline-flex space-x-2.5 items-center cursor-pointer">
                {isOpenFilter ? (
                  <ExpandCircle fill="#7f8090" />
                ) : (
                  <AddCircle fill="#7f8090" />
                )}
                <div tw="flex flex-nowrap text-16 font-medium text-sonnant-grey-6 opacity-95">
                  Program filters
                </div>
              </div>
            </Ripple>
          }
          open={isOpenFilter}
        >
          <MegaphoneIntegrationFilter integration={integration} />
        </CollapsibleList>

        <div tw="flex mt-2.5 mb-5 [> div]:(flex items-center text-15 font-medium text-sonnant-grey-6 space-x-3)!">
          <div>
            <span>Add to collection</span>
            <span
              css={[
                integration.filter.isUseProgramName &&
                  tw`pointer-events-none opacity-70`,
              ]}
            >
              <CollectionDropdown
                defaultValue={integration.collectionId ?? ''}
                onChange={(value) => {
                  dispatch(
                    updateSingleMegaphoneIntegration({
                      ...integration,
                      collectionOption: CollectionOptionEnum.COLLECTION,
                      collectionId: value,
                    }),
                  );
                }}
                menuPlacement="top"
                isMultiSelect={false}
              />
            </span>
          </div>
        </div>
      </div>
      {tier.isSubscriber && (
        <div
          className="btnReset"
          tw="w-full flex justify-end md-down:(flex-col)! w-[1rem]!"
        >
          <div>
            <Hint text="Remove integration" enterDelay={200}>
              <button
                type="button"
                className={`success button apply`}
                tw="bg-sonnant-red!"
                onClick={handleRemoveIntegration}
              >
                Remove
              </button>
            </Hint>
          </div>
        </div>
      )}

      <ConfirmModal
        ref={confirmRemoveModalRef}
        message={
          <div>
            <div>
              Removing an integration will stop any linked content items from
              sending updated data back to the source system. This is
              irreversible. <b>Would you like to proceed?</b>
            </div>
          </div>
        }
        title="Remove integration"
        confirmText="Confirm"
      />
    </div>
  );
};
