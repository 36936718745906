/* eslint-disable no-useless-escape */
export const REGEX = {
  // SPLIT_WORD: /(\b[^\s]+\b)((?<=\.\w).)?/gm, // NOT WORKING ON Safari
  //SPLIT_WORD: /(\b[^\s]+\b)/gm,
  SPLIT_WORD: /(\b[^\s]+\b)((\.\w).)?/gm,
  // eslint-disable-next-line
  YOUTUBE_URL: /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/,
  FILE_EXTENSION: /\.[0-9a-z]+$/i,
  NON_ALPHA: /[^A-Za-z0-9]+/,
  // URL_CHECK: new RegExp('(http|ftp|https)://([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?')
  URL_CHECK:
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  HASHTAG_COUNT: /(^|\W)(#[a-z\d][\w-]*)/g,
  MENTION_COUNT: /(^|\W)(@[a-z\d][\w-]*)/g,
  YOUTUBE_CHANNEL: /(https?:\/\/)?(www\.)?youtube\.com\/(channel|user)\/[\w-]+/,
  // YOUTUBE_CHANNEL_EXTRACTOR:
  //   /^(?:https?:\/\/)?(?:(?:www|gaming)\.)?youtube\.com\/(?:(channel\/|(?:c\/))?)([a-z\-%_0-9]+)\/?(?:[\?#]?.*)/i,
  // with user/
  YOUTUBE_CHANNEL_EXTRACTOR:
    /^(?:https?:\/\/)?(?:(?:www|gaming)\.)?youtube\.com\/(?:((channel|user|c)\/)?)([a-z\-%_0-9]+)\/?(?:[\?#]?.*)/i,
  EMAIL_ADDRESS: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
  SPEAKER_NUMBER: /(Speaker\s\d+\.?\d*$)/i,
  PASSWORD_CHECK:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!`~^%&|}{+=*_\[|\])(.,?><;:'"-])[A-Za-z\d#$@^~`!%&|*}{+=_)(.,?;:\[|\]'"><-]{8,50}$/,
};
