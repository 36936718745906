/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmptyOrSpaces, filenameFormatter } from '../../../utils/utils';
import { ReactComponent as ClipViewSvg } from '../../../assets/Icons/versions.svg';
import { ReactComponent as ViewTranscriptSvg } from 'assets/Icons/view-transcript-overlay.svg';
import { ReactComponent as Lock } from 'assets/Icons/Lock.svg';
import { ReactComponent as AudioSvg } from 'assets/Icons/mic-audio.svg';
import * as actions from 'actions';
import {
  canRedirect,
  isAudioByMIME,
  hasChangeThumb,
  msToTime,
  isRevivalPage,
  isLibraryPage,
  hasLockedByStatusText,
  addQueryToUrl,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { SearchField, VideoStatusCode, VideoStatusText } from 'utils/enum';
import { ILibraryItem } from 'utils/models';
import { first, isEmpty, isEqual, isNil, toNumber } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { Tooltip } from '@rmwc/tooltip';
import VideoFallback from 'assets/Images/video-fallback.jpg';
import { useState } from 'react';
import { useVideo } from 'react-use';
import {
  bgTrending,
  svgShadow,
  thumbnailStyles,
} from 'components/shared/twin.styles';
import { Hint } from 'components/shared/Hint';
import { SensitiveBadge } from 'components/shared/SensitiveBadge';
import { PrettyCheckbox } from 'components/shared/PrettyCheckbox';

interface IProps {
  libraryItem: ILibraryItem;
  isTrending?: boolean;
  isDragging?: boolean;
}

export const CardCell = (props: IProps) => {
  const { libraryItem } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const [isHovering, setIsHovering] = useState(false);

  const imgThumbRef = useRef<any>();

  const [isImgLoaded, setIsImgLoaded] = useState(false);

  const { selectedRows, isSelectMode } = useSelector(
    (state: RootState) => state.library,
  );
  const collection = useSelector((state: RootState) => state.collection);
  const global = useSelector((state: RootState) => state.global);
  const advancedSearch = useSelector(
    (state: RootState) => state.advancedSearch,
  );

  const [isVideoReady, setIsVideoReady] = useState(false);

  const hasClips =
    isNil(libraryItem?.clips_count) ||
    ((isLibraryPage() || collection.selectedId) &&
      toNumber(libraryItem?.clips_count) > 0);

  const [videoThumb, videoState, controls, videoThumbRef] = useVideo(
    <video
      src={undefined}
      css={[
        isVideoReady ? tw`block` : tw`hidden`,
        thumbnailStyles,
        tw`pointer-events-none`, // prevent right-click hack
      ]}
      autoPlay={true}
      controls={false}
      preload="auto"
      controlsList="nodownload"
      muted={true}
      loop={true}
      playsInline
      // onContextMenu={(e) => e.preventDefault()}
    />,
  );

  const isSensitive = libraryItem?.is_sensitive ?? false;
  const sensitiveItems = isSensitive
    ? JSON.parse(libraryItem?.sensitive_items || '[]')
    : [];

  useEffect(() => {
    if (!videoThumbRef.current) return;

    if (isHovering) {
      videoThumbRef.current.src = libraryItem.video_thumbnail ?? '';
      controls.play();
    } else {
      videoThumbRef.current.src = '';
      setIsVideoReady(false);
    }
  }, [isHovering]);

  useEffect(() => {
    if (videoState.time !== 0) {
      setIsVideoReady(true);
    }
  }, [videoState.time]);

  const isSelected = selectedRows.some(
    (row: ILibraryItem) => row.mediaid === libraryItem.mediaid,
  );

  const handleClickShortcut = () => {
    if (isRevivalPage()) {
      history.push(`/transcription/${libraryItem.mediaid}`);
    } else {
      const selectedMediaDetails = [];
      selectedMediaDetails.push(libraryItem);
      history.push({
        pathname: `/clips/${selectedMediaDetails[0]?.mediaid}`,
        state: selectedMediaDetails[0],
      });
    }
  };
  const library = useSelector((state: RootState) => state.library);

  const handleRedirectClick = (item: ILibraryItem) => {
    if (!canRedirect(item)) return;

    if (isRevivalPage()) {
      history.push(`/clips/${item.mediaid}`);
      return;
    }

    let transcriptHistory = `/transcription/${item.mediaid}`;

    // Only take into highlight, if has one term inserted
    const hasAdvancedSearch =
      advancedSearch.isShowAdvancedFilter &&
      !isEmpty(advancedSearch.searchParams.searchField) &&
      advancedSearch.searchParams.searchValues?.length === 1;

    if (
      (library.searchTerm || hasAdvancedSearch) &&
      advancedSearch.searchParams.searchField !== SearchField.IAB
    ) {
      const term = library.searchTerm
        ? library.searchTerm
        : first(advancedSearch.searchParams.searchValues);

      transcriptHistory = addQueryToUrl(transcriptHistory, `search=${term}`);
    }

    if (global.preference.paragraphMode) {
      transcriptHistory = addQueryToUrl(transcriptHistory, 'mode=paragraph');
    }

    history.push(transcriptHistory, {
      libraryItem: item,
      mediaid: item.mediaid,
      filename: isEmptyOrSpaces(item.title)
        ? filenameFormatter(item.filename)
        : item.title,
      videoLength: msToTime(toNumber(item.length)),
    });
  };

  const toggleSelection = () => {
    let cloneSelectedRows = [...selectedRows];

    if (selectedRows.some((row: any) => row.mediaid === libraryItem.mediaid)) {
      cloneSelectedRows = selectedRows.filter(
        (row: ILibraryItem) => row.mediaid !== libraryItem.mediaid,
      );
    } else {
      cloneSelectedRows.push(libraryItem);
    }
    if (isEmpty(cloneSelectedRows) && isSelectMode) {
      dispatch(actions.setSelectMode(false));
    } else {
      dispatch(actions.setSelectMode(true));
    }
    dispatch(actions.setSelectedRows(cloneSelectedRows));
  };

  return (
    <Hint
      text="Failed to process file. Please re-upload or contact Support if the problem persists."
      arrow
      notTransparent
      disabled={
        ![VideoStatusText.ERROR].includes(
          libraryItem?.status as VideoStatusText,
        )
      }
      enterDelay={300}
    >
      <div
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        className={`image-container ${isSelected && 'active'}`}
        tw="w-full h-auto overflow-hidden padding-top[56.3%] border-radius[.5rem] shadow-md"
        css={[isSelected && tw`(outline[3px solid #19AFA7])!`]}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          isSelectMode ? toggleSelection() : handleRedirectClick(libraryItem);
        }}
      >
        {![
          VideoStatusText.UPLOADING,
          VideoStatusText.TRANSCRIBING,
          VideoStatusText.AI_PROCESSING,
          VideoStatusText.ERROR,
          VideoStatusText.PAYMENT_REQUIRED,
        ].includes(libraryItem?.status as VideoStatusText) &&
          hasClips && (
            <Tooltip
              className="tooltip-view-clips"
              content={
                <div tw="font-size[1.6rem] p-1 z-30">
                  {isRevivalPage() ? 'View transcript' : 'View clips'}
                </div>
              }
              align="right"
              activateOn="hover"
              showArrow={false}
            >
              <div
                className="overlay_collection_icon"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleClickShortcut();
                }}
                tw="mx-4 (bottom[8px] right[5px])!"
              >
                {isRevivalPage() ? (
                  <ViewTranscriptSvg
                    className="svg-shadow"
                    tw="(-mb-1.5 -mr-1.5)!"
                  />
                ) : (
                  <ClipViewSvg className="svg-shadow" />
                )}
              </div>
            </Tooltip>
          )}

        <ItemStatus
          isTrending={!!props?.isTrending}
          item={libraryItem}
          showLock={!isHovering && !isSelected}
        />

        {isSensitive && (
          <SensitiveBadge
            sensitiveItems={sensitiveItems}
            mediaid={libraryItem.mediaid}
          />
        )}

        {isAudioByMIME(libraryItem?.mediacontenttype) &&
          hasChangeThumb(libraryItem) && (
            <Hint text="Audio file only" enterDelay={100}>
              <AudioSvg
                tw="absolute z-index[1] bottom[1.2rem] left[1.2rem] width[2.7rem] height[2.7rem]"
                css={svgShadow(0.3)}
              />
            </Hint>
          )}

        {(isHovering || isSelected) && !isRevivalPage() && (
          <div className="content-overlay">
            <PrettyCheckbox
              checked={isSelected}
              onChange={() => toggleSelection()}
            />
          </div>
        )}

        <img
          src={libraryItem.thumbnail || VideoFallback}
          className="thumbnailimg"
          css={[
            isVideoReady ? tw`hidden` : tw`block`,
            thumbnailStyles,
            props?.isDragging && tw`opacity[.7]`,
          ]}
          style={{ background: isImgLoaded ? 'none' : `url(${VideoFallback})` }}
          onLoad={() => setIsImgLoaded(true)}
          ref={imgThumbRef}
        />
        {videoThumb}
      </div>
    </Hint>
  );
};

export const ItemStatus = ({
  item,
  isTrending,
  showLock,
}: {
  item: ILibraryItem;
  isTrending: boolean;
  showLock: boolean;
}) => {
  const history = useHistory();
  const payment = useSelector((state: RootState) => state.payment);

  const { isSelectMode } = useSelector((state: RootState) => state.library);

  const hasRedBadge = () =>
    [VideoStatusText.PAYMENT_REQUIRED, VideoStatusText.ERROR].includes(
      item?.status as VideoStatusText,
    );

  const hasUnderline = () =>
    [VideoStatusText.PAYMENT_REQUIRED].includes(
      item?.status as VideoStatusText,
    );

  const handleClickBadge = () => {
    if (!isEqual(item?.status, VideoStatusText.PAYMENT_REQUIRED)) return;

    history.push('/account-settings#billing');
  };

  const hasAiProcessing = () => {
    return toNumber(item?.statuscode) === VideoStatusCode.AI_PROCESSING;
  };

  const isDraftLabel = [
    VideoStatusCode.NOT_EDITED,
    VideoStatusCode.DRAFT,
  ].includes(toNumber(item?.statuscode));

  const getStatusText = (status: string) => {
    if (status === VideoStatusText.PAYMENT_REQUIRED && payment.isExpiredTrial) {
      return 'Upgrade Now';
    }
    return status;
  };

  return (
    <>
      {/* TOP-LEFT BADGE */}
      {(((isNil(item?.publishcount) ||
        toNumber(item?.publishcount) === 0 ||
        [VideoStatusCode.THUMBNAIL_UPDATING, VideoStatusCode.EDITING].includes(
          toNumber(item?.statuscode),
        )) &&
        !isEmpty(item?.status?.trim())) ||
        isTrending ||
        hasAiProcessing()) &&
        !isDraftLabel && (
          <div
            className="overlay__status"
            tw="(z-index[2] shadow)!"
            css={[
              hasRedBadge() &&
                tw`(bg-sonnant-red hover:(shadow-lg text-shadow[0 0 1px white]))!`,
              hasUnderline() && tw`underline`,
              isTrending && bgTrending,
            ]}
            onClick={handleClickBadge}
          >
            {isTrending ? 'Trending' : getStatusText(item?.status)}
          </div>
        )}

      {/* TOP-RIGHT ICON */}
      {!isSelectMode && showLock && hasLockedByStatusText(item?.status) && (
        <div className="overlay_lock_icon">
          <Lock className="svg-shadow" />
        </div>
      )}
    </>
  );
};
