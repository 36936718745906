/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { IABItemModel } from 'utils/models';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as WarningSvg } from 'assets/Icons/warning.svg';
import { RootState } from 'reducers';
import { addConfirmedItem } from 'slices/iab.slice';
import { isEmpty, isNil } from 'lodash';
import { useToggle } from 'react-use';
import { useEffect } from 'react';
import { isSensitiveIabId } from 'components/VideoPlayer/Transcription/MediaUtilities';

interface Props {
  item: IABItemModel;
  parents?: IABItemModel[];
  autoExpanded?: boolean;
}

export const IABModalItem = ({ item, ...props }: Props) => {
  const dispatch = useDispatch();

  const iab = useSelector((state: RootState) => state.iab);

  const isSensitiveItem =
    isSensitiveIabId(item?.id) || isSensitiveIabId(item?.parentId);

  const [showSubItems, toggleShowSubItems] = useToggle(
    props?.autoExpanded ?? false,
  );

  useEffect(() => {
    if (isNil(props?.autoExpanded)) return;

    toggleShowSubItems(props.autoExpanded);
  }, [props?.autoExpanded]);

  const isAdded =
    iab?.lockedList?.some((i) => i?.id === item?.id) ||
    iab?.recommendedList?.some((recommended) => recommended?.id === item?.id);

  const handleAddItem = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(
      addConfirmedItem({
        ...item,
        hierarchy: getHierarchy(),
        isManual: true,
        relevance: 1,
      }),
    );
  };

  const getHierarchy = (): string => {
    if (!props?.parents) return item.name;

    return props.parents.map((p) => `${p.name} (${p.id})`).join(' > ');
  };

  return (
    <>
      <div
        tw="flex items-center justify-between text-16 w-full py-3 px-6 cursor-pointer hover:(bg-sonnant-grey-1)"
        css={[listScrollCss]}
        onClick={toggleShowSubItems}
        title={getHierarchy()}
      >
        <div tw="mr-3 font-bold min-width[8px]">
          {!isEmpty(item.subItems) && (showSubItems ? '-' : '+')}
        </div>

        {isSensitiveItem && (
          <div tw="flex mr-2.5">
            <WarningSvg />
          </div>
        )}

        <div tw="flex-1">
          {item.name} ({item.id})
        </div>
        <div>
          <button
            tw="(min-width[7.5rem] px-6 py-4 text-15 background[initial] border[1px solid] border-disabled text-sonnant-dark background[#E8E8E8] cursor-pointer active:(bg-sonnant-green text-white))!"
            onClick={handleAddItem}
            disabled={isAdded}
          >
            {isAdded ? 'Added' : '+ Add'}
          </button>
        </div>
      </div>

      {showSubItems &&
        item?.subItems?.map((subItem) => (
          <div tw="pl-6" css={listScrollCss}>
            <IABModalItem
              item={subItem}
              autoExpanded={props?.autoExpanded}
              // Append to parent hierarchy array
              parents={[...(props?.parents ? props.parents : [item]), subItem]}
            />
          </div>
        ))}
    </>
  );
};

const listScrollCss = css`
  :nth-of-type(even) {
    ${tw`bg-sonnant-grey-2`}
  }
  /* :hover {
    ${tw`bg-sonnant-grey-1`}
  } */
`;
