import { createSlice } from '@reduxjs/toolkit';

const captionSlice = createSlice({
  name: 'caption',
  initialState: {
    textKeyword: '',
    currentPath: [],
  },
  reducers: {
    setCurrentPath: (state, action) => {
      state.currentPath = action.payload;
    },
    updateTextKeyword: (state, action) => {
      state.textKeyword = action.payload;
    },
    resetCaption: (state) => {
      state.textKeyword = '';
      state.currentPath = [];
    }
  },
});

export const { 
  setCurrentPath,
  updateTextKeyword,
  resetCaption
} = captionSlice.actions;

export const captionReducer = captionSlice.reducer;
