import { SagaAction } from 'utils/models';
import * as types from '../actions/types';

interface InitialStateType {
  statusMessage: string;
  loading: boolean;
  isSuccess: null;
}

const initialState = {
  statusMessage: '',
  loading: false,
  isSuccess: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export const alertReducer = (
  state: InitialStateType = initialState,
  action: SagaAction,
) => {
  switch (action.type) {
    case types.HANDLE_ALERT_DISPLAY: {
      return {
        ...state,
        statusMessage: action.statusMsg,
        loading: false,
        isSuccess: action.isSuccess,
      };
    }
    case types.HANDLE_ALERT_CLOSE: {
      return { ...state, statusMessage: '', isSuccess: action.isSuccess };
    }
    default:
      return state;
  }
};
