/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import './explore.scss';
import {
  getDomainWithoutSubdomain,
  getToken,
  setIsLoggedIn,
  setToken,
} from '../../utils/utils';
import { useHistory } from 'react-router-dom';
import Loader from '../loader/loader';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as actionType from '../../actions/types';
import * as actions from '../../actions';

export const Explore = () => {
  const history = useHistory();

  const search = useLocation().search;
  const dispatch = useDispatch();

  useEffect(() => {
    let hostname = window.location.hostname.replace('www.', '').split('.');

    if (hostname.length > 2) {
      const nextUrl = new URLSearchParams(search).get('next');
      const accessToken = new URLSearchParams(search).get('access_token');
      const idToken = new URLSearchParams(search).get('id_token');
      const refreshToken = new URLSearchParams(search).get('refresh_token');

      if (accessToken && idToken && refreshToken) {
        dispatch({ type: actionType.LOGIN_SUCCESS });

        setToken({
          AccessToken: accessToken,
          IdToken: idToken,
          RefreshToken: refreshToken,
        });
        setIsLoggedIn();

        dispatch(
          actions.setToken(
            JSON.stringify({
              AccessToken: accessToken,
              IdToken: idToken,
              // Image: profileImage,
              RefreshToken: refreshToken,
            }),
            'email@email.com',
          ),
        );

        if (nextUrl) {
          const domainUrlNext = new URL(nextUrl);
          history.push(`${domainUrlNext.pathname}${domainUrlNext.search}${domainUrlNext.hash}`);
        } else {
          history.push('/library');
        }
      } else {
        if (getToken()) {
          history.replace('/library');
        } else {
          window.location.replace(
            `${window.location.protocol}//${getDomainWithoutSubdomain()}/signin?next=${encodeURIComponent(
              window.location.href,
            )}`,
          );
        }
      }
    } else {
      history.push(`/signin`);
    }
  }, [search]);

  return (
    <div className="loader__component">
      <Loader />
    </div>
  );
};
