/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { ReactComponent as ReprocessSvg } from 'assets/Icons/cycle.svg';
import { CollectionDropdown } from 'components/shared/CollectionDropdown/CollectionDropdown';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { QuestionHint } from 'components/shared/QuestionHint';
import { mediumTooltipCss } from 'components/shared/twin.styles';
import { isNil, keys } from 'lodash';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  IntegrationService,
  ReprocessPayload,
} from 'services/integration.service';
import { IntegrationEnum, ModalOptions } from 'utils/enum';
import { customToast } from 'utils/toast.util';
import { BasicConfirmModal } from 'utils/models/modal.model';
import { LibraryService } from 'services';
import { LibraryStatistics } from 'utils/models';
import { AxiosResponse } from 'axios';
import { useToggle } from 'react-use';
import toast from 'react-hot-toast';
import { NumberInput } from 'components/shared/NumberInput';
import { ensureArray } from 'utils/generic.util';

type Props = {};

export const AdMarkerReprocessSetting = (props: Props) => {
  const global = useSelector((state: RootState) => state.global);
  const collection = useSelector((state: RootState) => state.collection);

  const [isFetching, toggleFetching] = useToggle(false);

  const [settings, setSettings] = useState<ReprocessPayload>({
    collectionId: undefined,
    numberOfAds: global.monetisation.adsNumber ?? 2,
  });

  const confirmModalRef = useRef<BasicConfirmModal>(null);

  const setCollectionId = (collectionId: string) => {
    setSettings((prevSettings) => ({ ...prevSettings, collectionId }));
  };

  const setNumberOfAds = (numberOfAds: number) => {
    setSettings((prevSettings) => ({ ...prevSettings, numberOfAds }));
  };

  const isValidForm = keys(settings).every(
    (key: string) => !isNil(settings[key as keyof ReprocessPayload]),
  );

  const handleSubmitReprocessAsync = async () => {
    const collectionName = collection.items.find(
      (item) => item.id === settings.collectionId,
    )?.name;

    const totalItemsCollection = await getTotalCollecitonItemAsync(
      settings.collectionId,
    );

    if (totalItemsCollection === 0) {
      customToast.error(
        'Your collection is currently empty. Please check and try again!',
      );
      return;
    }

    const result = await confirmModalRef.current?.show({
      title: 'Reprocess collection',
      message: (
        <div>
          <div>
            You are about to update the Ad marker information for{' '}
            <strong>{totalItemsCollection} item(s)</strong>
          </div>
          <div>
            from your collection <strong>{collectionName}</strong> to Omny
            Studio.
          </div>
          <div>Would you like to continue?</div>
        </div>
      ),
      confirmText: 'Confirm',
    });

    if (result !== ModalOptions.YES) return;

    const reprocessAsync = IntegrationService.integrate({
      collection_ids: ensureArray<string>(settings.collectionId),
      number_ads: settings.numberOfAds,
      service: IntegrationEnum.OMNY,
    });

    customToast.promise(reprocessAsync, {
      loading: 'Reprocessing collection items',
      success: 'Request submitted. Running in background',
      error: 'Request submitted failed. Please check and try again',
      useServerErrorMsg: true,
    });

    try {
      await reprocessAsync;
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  const getTotalCollecitonItemAsync = async (
    collectionId: string | undefined,
  ): Promise<number | undefined> => {
    try {
      toggleFetching(true);
      customToast.loading('Validating...');

      const res: AxiosResponse<{ total: LibraryStatistics }> =
        await LibraryService.getLibraryItems({
          page_size: 0,
          collection_id: collectionId,
        });

      return res.data?.total?.totalFiles;
    } catch (error) {
      console.log('error :>> ', error);
      return Promise.reject(error);
    } finally {
      toggleFetching(false);
      toast.dismiss();
    }
  };

  return (
    <div tw="mt-10 inline-block">
      <div tw="flex items-center space-x-4 ml-4">
        <ReprocessSvg />

        <div tw="font-bold text-4xl">Reprocess collection</div>
        <QuestionHint
          notTransparent
          hideCloseButton
          type={'information'}
          align={'bottomLeft'}
          action={'click'}
          text={
            <div tw="py-1">
              Sonnant will update the Ad marker information for all items from
              your collection to Omny Studio.
            </div>
          }
          customStyle={mediumTooltipCss}
        />
      </div>

      <div tw="ml-20 mt-6">
        <div tw="space-y-6">
          <div tw="flex items-center">
            <div css={[itemNameCss]}>Collection</div>
            <div>
              <CollectionDropdown
                defaultValue=""
                defaultLabel="Select library collection"
                onChange={setCollectionId}
                menuPlacement="top"
                canCreate={false}
                isMultiSelect={false}
              />
            </div>
          </div>

          <div tw="flex items-center">
            <div css={[itemNameCss]}>Number of ads</div>
            <NumberInput
              tw="w-[5rem] h-[3rem] mb-0"
              min={1}
              step={1}
              max={60}
              value={settings.numberOfAds}
              onChangeNumber={setNumberOfAds}
              disabled={false}
            />
          </div>

          <button
            type="button"
            className="btn large btn-primary"
            tw="mt-8!"
            disabled={!isValidForm || isFetching}
            onClick={handleSubmitReprocessAsync}
          >
            Reprocess
          </button>
        </div>
      </div>

      <ConfirmModal ref={confirmModalRef} />
    </div>
  );
};

const itemNameCss = css`
  ${tw`text-15 font-medium min-width[15rem]`}
`;
