/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import 'twin.macro';
import tw from 'twin.macro';

import { MenuSurface, MenuSurfaceAnchor } from '@rmwc/menu';
import { Ripple } from '@rmwc/ripple';
import {
  handleSearchClear,
  handleSearchClick,
  initLibrary,
  setSelectMode,
  setSelectedRows,
} from 'actions';
import { SEARCH_CLEAR } from 'actions/types';
import { ReactComponent as Export } from 'assets/Icons/Export.svg';
import { ReactComponent as Share } from 'assets/Icons/Share.svg';
import { ReactComponent as CollectionSvg } from 'assets/Icons/collections_bookmark.svg';
import { ReactComponent as Delete } from 'assets/Icons/delete.svg';
import { ReactComponent as EditNote } from 'assets/Icons/editNote.svg';
import { ReactComponent as RemoveFromCollectionSvg } from 'assets/Icons/folder_delete.svg';
import { ReactComponent as Link } from 'assets/Icons/link.svg';
import { ReactComponent as Movie } from 'assets/Icons/movie.svg';
import { ReactComponent as IntegrateSvg } from 'assets/Icons/power.svg';
import { ReactComponent as Preview } from 'assets/Icons/preview_black.svg';
import { ReactComponent as Rerun } from 'assets/Icons/rerun.svg';
import { ReactComponent as Subtitles } from 'assets/Icons/subtitles.svg';
import { ReactComponent as TextSnippet } from 'assets/Icons/textSnippet.svg';
import { ReactComponent as Filter } from 'assets/Icons/tune.svg';
import { ReactComponent as Version } from 'assets/Icons/versions.svg';
import { ReactComponent as MentionSvg } from 'assets/Icons/voice.svg';
import {
  canRedirect,
  isCollectionPage,
  isLibraryPage,
  isRevivalPage,
  msToDayFormat,
  msToTime,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { ExpiredHint } from 'components/shared/ExpiredHint/ExpiredHint';
import { Hint } from 'components/shared/Hint';
import { MultiMentionReportModal } from 'components/shared/MultiMentionReportModal/MultiMentionReportModal';
import { hoverDropShadow, menuDivider } from 'components/shared/twin.styles';
import $ from 'jquery';
import { isEmpty, isNil, toNumber } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useClickAway, useMount, useSearchParam, useToggle } from 'react-use';
import { RootState } from 'reducers';
import { MediaService } from 'services';
import {
  resetAdvancedSearch,
  toggleAdvancedFilter,
} from 'slices/advanced-search.slice';
import {
  setFocusClip,
  setSelectedClips,
  toggleSelectMode,
} from 'slices/clips.slice';
import {
  resetSelectedCollectionId,
  setSelectedCollectionId,
  toggleCollectionSidebar,
} from 'slices/collection.slice';
import { setFocusNotification } from 'slices/notification.slice';
import {
  changePage,
  changeSortBy,
  setIsListView,
  setPageNumber,
  toggleSortOrder,
} from 'slices/pagination.slice';
import { tierSelector } from 'slices/payment.slice';
import {
  toggleFinderModal,
  toggleReloadLibrary,
  toggleShowDeleteModal,
  toggleShowExportModal,
  toggleShowIntegrateModal,
  toggleShowPreviewModal,
  toggleShowPublishModal,
  toggleShowShareModal,
} from 'slices/toggle.slice';
import { getSortName } from 'utils/adapter.util';
import {
  ClipStatusCode,
  Keys,
  ModalOptions,
  SortField,
  VideoStatusCode,
} from 'utils/enum';
import {
  IClips,
  ILibraryItem,
  Insights,
  RerunPayload,
  Term,
} from 'utils/models';
import { customToast } from 'utils/toast.util';
import {
  filenameFormatter,
  isEmptyOrSpaces,
  removeItemsFromCollectionAsync,
  waitAsync,
} from 'utils/utils';
import * as actions from '../../actions';
import { ReactComponent as ListViewActive } from '../../assets/Icons/List View.svg';
import { ReactComponent as ListViewIcon } from '../../assets/Icons/ListView.svg';
import { ReactComponent as ThumbnailViewActive } from '../../assets/Icons/Thumbnail View.svg';
import { ReactComponent as ThumbnailViewIcon } from '../../assets/Icons/ThumbnailView.svg';
import { ReactComponent as Search } from '../../assets/Icons/search.svg';
import { ReactComponent as SortAscButton } from '../../assets/Icons/sortAsc.svg';
import { ReactComponent as SortButton } from '../../assets/Icons/sortDsc.svg';
import CloseSVG from '../UiControls/closeButtonSVG/closeSVG';
import { AddToCollectionMenu } from './CollectionSidebar/AddToCollectionMenu';
import './Sort.scss';
import { HintRestrictedPublish } from 'components/shared/HintRestrictedPublish';
import { HintRestrictedEdit } from 'components/shared/HintRestrictedEdit';
import { useAdvancedAutofill } from 'hooks/useAdvancedAutofill';
import { ensureArray } from 'utils/generic.util';

interface SortOption {
  sortField: SortField;
  sortName: string;
  hasDivider?: boolean;
  hidden?: boolean;
}

export const Sort = () => {
  const searchParam = useSearchParam('search');
  const layerParam = useSearchParam('layer') as keyof Insights;

  const autofillAdvancedSearchHook = useAdvancedAutofill();

  const collection = useSelector((state: RootState) => state.collection);
  const advancedSearch = useSelector(
    (state: RootState) => state.advancedSearch,
  );
  const focusNotification = useSelector(
    (state: RootState) => state.notification.focusNotification,
  );
  const { selectedRows, statistics } = useSelector(
    (state: RootState) => state.library,
  );
  const tier = useSelector(tierSelector);
  const library = useSelector((state: RootState) => state.library);

  const pagination = useSelector((state: RootState) => state.pagination);
  const clips = useSelector((state: RootState) => state.clips);
  const payment = useSelector((state: RootState) => state.payment);

  const isItemSelect =
    (library.isSelectMode === true || clips.isSelectMode) &&
    (!isEmpty(selectedRows) || !isEmpty(clips.selectedClips));

  const isMultiSelect =
    (library.isSelectMode === true || clips.isSelectMode) &&
    (selectedRows.length > 1 || clips.selectedClips.length > 1);

  const currentSelectedRows = advancedSearch.isShowClipsOnly
    ? clips.selectedClips
    : selectedRows;

  let queryPagingParam = {
    page_number: pagination.page_number,
    page_size: pagination.page_size,
  };

  const shouldDisable = (): boolean => {
    if (
      [
        VideoStatusCode.DRAFT,
        VideoStatusCode.EDITING,
        VideoStatusCode.NOT_EDITED,
        VideoStatusCode.THUMBNAIL_UPDATING,
      ].includes(toNumber(selectedRows[0]?.statuscode)) ||
      isNil(selectedRows[0]?.statuscode)
    ) {
      return false;
    }

    return true;
  };

  // const isLoaded = pagination.isLoaded;
  const isListView = pagination.isListView;

  const shouldDisabledSubscription =
    payment.subscription?.isLoading || payment.isExpiredTrial;

  const [searchTerm, setSearchTerm] = useState(library.searchTerm);
  const noTranscript = selectedRows?.some(
    (row: ILibraryItem) => row?.noTranscript,
  );
  const isSelectedAll =
    currentSelectedRows?.length === library?.data?.library?.length;
  const isSearchClips = advancedSearch.isShowClipsOnly;

  const history = useHistory();
  const dispatch = useDispatch();

  const multiMentionReportModalRef = useRef<any>(null);
  const [isSortOpen, setSortOpen] = useToggle(false);

  const sortOptions: SortOption[] = [
    {
      sortField: SortField.MODIFIED,
      sortName: 'Modified',
    },
    {
      sortField: SortField.TITLE,
      sortName: 'Title',
    },
    {
      sortField: SortField.TYPE,
      sortName: 'Type',
      hasDivider: true,
    },
    {
      sortField: SortField.DURATION,
      sortName: 'Duration',
    },
    {
      sortField: SortField.SPEAKER,
      sortName: 'Speakers',
      hidden: advancedSearch.isShowClipsOnly,
    },
    {
      sortField: SortField.DATE_ADDED,
      sortName: 'Date Added',
      hidden: advancedSearch.isShowClipsOnly,
    },
    {
      sortField: SortField.VIEWS,
      sortName: 'Views',
      hidden: !advancedSearch.isShowClipsOnly,
    },
    {
      sortField: SortField.STATUS,
      sortName: 'Status',
    },
    {
      sortField: SortField.LOCKED,
      sortName: 'Locked',
      hidden: advancedSearch.isShowClipsOnly,
    },
    {
      sortField: SortField.WORD,
      sortName: 'Words',
      hidden: advancedSearch.isShowClipsOnly,
    },
    {
      sortField: SortField.CLIP,
      sortName: 'Clips',
      hidden: advancedSearch.isShowClipsOnly,
    },
    {
      sortField: SortField.VIEWS,
      sortName: 'Views',
      hidden: advancedSearch.isShowClipsOnly,
    },
    {
      sortField: SortField.CREATED_BY,
      sortName: 'Created By',
      hidden: advancedSearch.isShowClipsOnly,
    },
  ];

  useEffect(() => {
    $(document).foundation();
  }, []);

  useMount(() => {
    if (isEmpty(searchParam) && isEmpty(layerParam)) return;

    if (layerParam) {
      dispatch(toggleAdvancedFilter(true));
    }

    autofillAdvancedSearchHook({
      searchTerms: ensureArray(searchParam),
      searchBy: layerParam,
    });

    // Remove search queries
    history.replace(window.location.pathname);
  });

  useEffect(() => {
    if (isRevivalPage()) {
      setSearchTerm('');
    }

    if (!focusNotification || !isRevivalPage()) return;

    setSearchTerm(focusNotification.fileName!);
  }, [focusNotification, isRevivalPage()]);

  useEffect(() => {
    if (isEmpty(library.searchTerm)) return;

    setSearchTerm(library.searchTerm);

    if (!isEmpty(library.searchTerm)) {
      dispatch(toggleFinderModal(true));
    }
  }, [library.searchTerm]);

  const handleRouteToDisplay = () => {
    if (selectedRows.length === 1) {
      history.push({
        pathname: `/clips/${selectedRows[0]?.mediaid}`,
        state: selectedRows[0],
      });
    }
  };

  const selectedDeleteItemFromLibrary = () => {
    dispatch(toggleShowDeleteModal(true));
  };

  const handleModalShare = () => {
    dispatch(toggleShowShareModal(true));
  };

  const handleShowPreviewPublish = () => {
    dispatch(toggleShowPreviewModal(true));
  };

  const handleModalPublish = () => {
    if (advancedSearch.isShowClipsOnly) {
      dispatch(setFocusClip(clips.selectedClips[0]));
    } else {
      dispatch(actions.setFocusRow(selectedRows[0]));
    }
    dispatch(toggleShowPublishModal(true));
  };

  const handleExportContentItem = (item: any) => {
    dispatch(toggleShowExportModal(true));
  };

  const handleDropdownClick = async (sortField: SortField) => {
    dispatch(changeSortBy(sortField));

    dispatch(setSelectedRows([]));
  };

  const hasSelected = () => {
    return !isEmpty(currentSelectedRows);
  };

  const historyItem = {
    mediaid: selectedRows[0]?.mediaid,
    filename: isEmptyOrSpaces(selectedRows[0]?.title)
      ? filenameFormatter(selectedRows[0]?.filename)
      : selectedRows[0]?.title,
    videoLength: msToTime(toNumber(selectedRows[0]?.length)),
  };

  const handleRedirectClick = (
    item: ILibraryItem,
    historyItem: any,
    isParagraph: boolean,
  ) => {
    if (!canRedirect(item)) return;

    history.push(
      `/transcription/${item?.mediaid}?mode=${
        isParagraph ? 'paragraph' : 'caption'
      }`,
      historyItem,
    );
  };

  const handleViewTranscript = (item: ILibraryItem) => {
    const newHistoryItem = {
      ...historyItem,
      editMode: true,
      paragraphMode: true,
    };
    handleRedirectClick(item, newHistoryItem, true);
  };

  const handleEditCaption = (item: ILibraryItem) => {
    const newHistoryItem = { ...historyItem, editMode: true };
    handleRedirectClick(item, newHistoryItem, false);
  };

  const shouldDisableItem = () => {
    return currentSelectedRows?.some((row: ILibraryItem) => {
      return [
        VideoStatusCode.UPLOADING,
        VideoStatusCode.TRANSCRIBING,
        VideoStatusCode.AI_PROCESSING,
        VideoStatusCode.ERROR,
        VideoStatusCode.PAYMENT_REQUIRED,
      ].includes(toNumber(row?.statuscode));
    });
  };

  const shouldDisableRerun = () => {
    const isEditing = selectedRows?.some(
      (row: ILibraryItem) =>
        toNumber(row?.statuscode) === VideoStatusCode.EDITING,
    );
    return (
      tier.isPAYG ||
      tier.isTrial ||
      shouldDisableItem() ||
      isEditing ||
      payment.isExpiredTrial
    );
  };

  const isAIProcessing = () => {
    return selectedRows?.every(
      (row: ILibraryItem) =>
        toNumber(row?.statuscode) === VideoStatusCode.AI_PROCESSING,
    );
  };

  const shouldDisableClips = () => {
    return currentSelectedRows?.some((row: IClips) => {
      return [ClipStatusCode.CREATION_FAILED, ClipStatusCode.CREATING].includes(
        row?.statuscode.toString() as ClipStatusCode,
      );
    });
  };

  const shouldDisabled = advancedSearch.isShowClipsOnly
    ? shouldDisableClips()
    : shouldDisableItem();

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
      if (!searchTerm) {
        resetSearch();
        return;
      }

      triggerSearch();
    }

    if (e.key === Keys.ESC) {
      resetSearch();
    }
  };

  const confirmModalRef = useRef<any>(null);

  const handleRequestRerun = async (mediaid: string, statuscode: string) => {
    const { data: response } = await MediaService.regenarate({
      action: 're/run-ai-processing',
      payload: {
        media_id: mediaid,
        status: statuscode,
      },
    });
    return response?.succeed;
  };

  const handleRerun = async () => {
    try {
      const selectedIds: RerunPayload[] = selectedRows.map(
        (row: ILibraryItem) => {
          return {
            mediaid: row.mediaid,
            status: row.statuscode,
          };
        },
      );

      const modalOption = await confirmModalRef.current?.show({
        title: 'Rerun AI',
        message: (
          <div>
            You are about to rerun the AI processing for the selected item(s)
            from your library. This is a destructive process. Should you
            proceed, this might impact updates made to speaker diarisation,
            chapterisation and entities. <b>Would you like to continue?</b>
          </div>
        ),
        confirmText: 'Confirm',
      });
      if (modalOption === ModalOptions.YES) {
        customToast.loading('Sending request');

        for (const item of selectedIds) {
          await handleRequestRerun(item.mediaid, item.status);
        }

        toast.dismiss();

        customToast.success('Rerun AI request submitted');
      }
    } catch (err: any) {
      customToast.error('Something went wrong');
      console.log('err :>> ', err);
    }
  };

  const handleIntegrate = () => {
    dispatch(toggleShowIntegrateModal(true));
  };

  const triggerSearch = () => {
    dispatch(handleSearchClick(searchTerm));
    dispatch(
      changePage({
        page_number: 1,
        page_size: 30,
      }),
    );
    dispatch(setSelectedRows([]));
    dispatch(setSelectMode(false));
    dispatch(toggleReloadLibrary());
  };

  const resetSearch = () => {
    setSearchTerm('');
    // dispatch({ type: types.SEARCH_CLEAR });
    // dispatch(setFocusNotification(null));
    // dispatch(
    //   changePage({
    //     page_number: 1,
    //     page_size: 30,
    //   }),
    // );
    dispatch(handleSearchClear());
    // dispatch(resetAdvancedSearch());
    dispatch(toggleReloadLibrary());
    dispatch(setFocusNotification(null));
    dispatch(setPageNumber(1));
    dispatch(setSelectedRows([]));
  };

  const handleMentionReport = async () => {
    const result: {
      option: ModalOptions;
      terms: Term[];
      reportName: string;
      isCombined?: boolean;
    } = await multiMentionReportModalRef.current?.show({
      librarySearchTerm: library?.searchTerm,
    });

    if (result?.option === ModalOptions.YES) {
      const selectedIds = selectedRows.map((row: ILibraryItem) => row.mediaid);

      const getMultiMentionsAsync = MediaService.getMentionReport({
        mediaIds: selectedIds,
        terms: result?.terms ?? [],
        reportName: result?.reportName ?? '',
        isCombined: result?.isCombined,
      });

      customToast.promise(waitAsync(2000), {
        loading: 'Running in progress',
        success: (
          <div>
            <div>Submitted for background processing.</div>
            <div>It may take a few minutes to be ready.</div>
          </div>
        ),
        error: 'Generating Mention Report failed. Please check and try again.',
      });

      await getMultiMentionsAsync;
      // const { data } = await getMultiMentionsAsync;
      // console.log('data :>> ', data);

      // exportExcel(
      //   data.map((item: any) => ({
      //     'File Name': item['File Name'],
      //     Keyword: item['Keyword'],
      //     'Type of Mention': item['Type of Mention'] ?? 'Empty',
      //     'Mention #': item['Mention #'],
      //     'Total mentions': item['Total mentions'],
      //     'Start Time': secToTime(item['Start Time']),
      //     'End Time': secToTime(item['End Time']),
      //     Duration: secToTime(ceil(item['Duration'])),
      //     Transcript: item?.['Transcript'] ?? 'Empty Transcript',
      //     'Clip URL': `${window.location.origin}/transcription/${item?.['MediaID']}`,
      //   })),
      //   'Multi-item Mentions Report',
      // );
    }
  };

  const handleRemoveFromCollection = async (
    e: React.MouseEvent<HTMLLIElement>,
  ) => {
    e.preventDefault();
    const mediaIds: string[] =
      library.selectedRows.map((row: ILibraryItem) => row?.mediaid) ?? [];

    if (!collection.selectedId || !mediaIds?.length) return;

    const modalOption = await confirmModalRef.current?.show({
      title: 'Remove from collection',
      message: (
        <div>
          <div>
            This will just delete selected items from your collection, they will
            still remain in your library.
          </div>
          <b>Would you like to continue?</b>
        </div>
      ),
      confirmText: 'Delete',
    });

    if (modalOption !== ModalOptions.YES) return;

    await removeItemsFromCollectionAsync({
      collectionId: collection.selectedId,
      ids: mediaIds,
    });

    // Jump to last page if remove all items in current page
    if (mediaIds?.length === library?.data?.library?.length) {
      queryPagingParam = {
        ...pagination,
        page_number:
          pagination?.page_number - 1 > 0 ? pagination?.page_number - 1 : 1,
      };
    }
    // Refresh items after deleting

    dispatch(changePage(queryPagingParam));
    dispatch(
      initLibrary({
        ...queryPagingParam,
        collection_id: collection.selectedId,
      }),
    );
    dispatch(setSelectedRows([]));
    dispatch(setSelectMode(false));
  };

  const handleSelectAll = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    dispatch(setSelectMode(true));

    if (isSelectedAll) {
      dispatch(setSelectedRows([]));
      dispatch(setSelectedClips([]));
    } else {
      if (advancedSearch.isShowClipsOnly) {
        dispatch(setSelectedClips(library?.data?.library));
      } else {
        dispatch(setSelectedRows(library?.data?.library));
      }
    }
  };

  const handleToggleSort = () => {
    dispatch(toggleSortOrder());
  };

  const [isOpenActionMenu, toggleOpenActionMenu] = useToggle(false);
  const menuWrapperRef = useRef<HTMLDivElement>(null);

  useClickAway(menuWrapperRef, () => {
    toggleOpenActionMenu(false);
  });

  const toggleSelect = () => {
    // Turn of select mode
    dispatch(actions.setSelectedRows([]));
    dispatch(setSelectedClips([]));

    if (advancedSearch.isShowClipsOnly) {
      dispatch(toggleSelectMode(!clips.isSelectMode));
    } else {
      dispatch(actions.setSelectMode(!library.isSelectMode));
    }
  };

  const handleLoadCollection = () => {
    const collectionId = collection.defaultCollectionId;
    if (!collectionId) return;

    dispatch(actions.fetchLibrary()); // loading library
    dispatch(setSelectedCollectionId(collectionId));
    dispatch(setSelectedRows([]));
    dispatch(setSelectMode(false));

    // reset filter
    dispatch({ type: SEARCH_CLEAR });
    dispatch(resetAdvancedSearch());
    dispatch(toggleAdvancedFilter(false));

    history.push(`/collections/${collectionId}`);

    // default pagination
    const params = {
      page_number: 1,
      page_size: 30,
      collection_id: collectionId,
    };
    dispatch(initLibrary(params));
    dispatch(changePage(params));
  };

  return (
    <div
      className="row_bg"
      tw="flex justify-between flex-nowrap"
      css={[!isSortOpen && customSortCss]}
    >
      <div className="menu sor_group" tw="flex flex-nowrap items-center">
        {(isLibraryPage() || isCollectionPage()) && (
          <Hint
            disabled={!isSearchClips}
            text="Collection is not applicable for clip items."
          >
            <div css={[tw`cursor-not-allowed!`]}>
              <Hint
                text={
                  collection.showSidebar
                    ? 'Hide collections'
                    : 'View collections'
                }
              >
                <button
                  type="button"
                  tw="my-2! p-0!"
                  css={[isSearchClips && tw`opacity[0.5] pointer-events-none`]}
                  className={`button ${collection.showSidebar && 'active'}`}
                  onClick={(e) => {
                    e.currentTarget.blur();
                    // Reset all if closed Collection Sidebar
                    if (
                      collection.showSidebar === true &&
                      !isNil(collection.selectedId)
                    ) {
                      dispatch(resetSelectedCollectionId());
                      dispatch(initLibrary());
                      history.push('/library');
                    }
                    // load default collection
                    if (!collection.showSidebar) {
                      handleLoadCollection();
                    }

                    dispatch(toggleCollectionSidebar());
                  }}
                >
                  <CollectionSvg tw="(width[22px] height[22px])!" />
                </button>
              </Hint>
            </div>
          </Hint>
        )}

        <Hint text="List view">
          <button
            type="button"
            tw="my-2!"
            className={'button ' + (isListView ? 'active' : ' list ')}
            onClick={(e) => {
              e.currentTarget.blur();

              dispatch(setIsListView(true));
            }}
          >
            {isListView ? <ListViewActive /> : <ListViewIcon />}
          </button>
        </Hint>
        <Hint text="Thumbnail view">
          <button
            type="button"
            tw="my-2!"
            className={'button ' + (isListView ? 'thumb' : 'active')}
            onClick={(e) => {
              e.currentTarget.blur();

              dispatch(setIsListView(false));
            }}
          >
            {isListView ? <ThumbnailViewActive /> : <ThumbnailViewIcon />}
          </button>
        </Hint>
        {!isRevivalPage() && (
          <>
            {!isListView ? (
              <div
                className="button-group sort_modify"
                tw="flex items-center flex-nowrap"
              >
                <div
                  className={`${isSortOpen ? 'dropdown' : ''}`}
                  onMouseLeave={() => setSortOpen(false)}
                  onClick={() => setSortOpen(!isSortOpen)}
                >
                  <a className="button">
                    Sort by {getSortName(pagination.sortBy)}
                    <span className="arrow-down"></span>
                  </a>

                  <ul className="dropdown-sort-content manageDropDown">
                    {sortOptions
                      .filter((sort) => !sort?.hidden)
                      .map((sort, index) => (
                        <li
                          key={index}
                          onClick={() => handleDropdownClick(sort.sortField)}
                          css={[
                            sort?.hasDivider &&
                              tw`border-top[1px solid #f0f0f0]`,
                          ]}
                        >
                          <a>Sort by {sort.sortName}</a>
                        </li>
                      ))}
                  </ul>
                </div>
                <Hint text="Toggle sort order">
                  <div className="sort-icon" onClick={handleToggleSort}>
                    {pagination.isAsc ? <SortAscButton /> : <SortButton />}
                  </div>
                </Hint>
              </div>
            ) : (
              <div tw="flex flex-col w-full">
                <div tw="flex flex-row">
                  <div
                    tw="inline-block whitespace-nowrap"
                    css={[
                      !collection.selectedId
                        ? tw`width[12rem]`
                        : tw`width[14.5rem]`,
                    ]}
                  >
                    <span tw="font-size[14px] color[#5551FF] font-medium ">
                      {collection.showSidebar && !isEmpty(collection.selectedId)
                        ? 'Collection'
                        : 'Library'}{' '}
                      statistics:
                    </span>
                  </div>
                  <div tw="inline whitespace-nowrap width[13rem] mr-3">
                    <span tw="font-size[14px] color[#5551FF]">
                      Files: {statistics?.totalFiles ?? 0}
                    </span>
                  </div>
                  <div tw="inline whitespace-nowrap">
                    <span tw="font-size[14px] color[#5551FF] w-full">
                      Duration: {msToDayFormat(statistics?.totalDurations ?? 0)}
                    </span>
                  </div>
                </div>

                <div tw="flex flex-row">
                  <div
                    tw="inline whitespace-nowrap"
                    css={[
                      !collection.selectedId
                        ? tw`width[12rem]`
                        : tw`width[14.5rem]`,
                    ]}
                  >
                    <span tw="font-size[14px] color[#5551FF]">
                      Shared: {statistics?.sharedItems ?? 0}
                    </span>
                  </div>
                  <div tw="inline whitespace-nowrap width[13rem] mr-3">
                    <span tw="font-size[14px] color[#5551FF]  ">
                      Audio Files: {statistics?.totalAudio ?? 0}
                    </span>
                  </div>
                  <div tw="inline width[13rem]">
                    <span tw="font-size[14px] color[#5551FF]  ">
                      Video Files: {statistics?.totalVideo ?? 0}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {!isRevivalPage() && (
        <div
          tw="h-full flex xl-up:width[33%] xl-down:ml-12 justify-center items-center"
          css={[isRevivalPage() && tw`width[55%]`]}
        >
          <Hint text="Search content library">
            <div tw="flex lg-down:width[25rem] width[40rem] ">
              <div tw="flex relative width[100%]">
                <input
                  tw="(w-full pr-11 rounded-r-none border-width[2px] right[-2px] mb-0 placeholder:text-14)!"
                  type="text"
                  placeholder="Library Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={handleSearchKeyDown}
                />
                {searchTerm && (
                  <CloseSVG
                    name={'apiError'}
                    tw="absolute right[8px] top[1rem] cursor-pointer"
                    color={'#7f7f7f'}
                    close={resetSearch}
                    css={hoverDropShadow(0.3)}
                  />
                )}
              </div>

              <button
                onClick={triggerSearch}
                className="button btn-secondary tiny searchSpan"
                tw="rounded-l-none! mb-0 "
              >
                <Search />
              </button>
            </div>
          </Hint>

          <Ripple
            tw="flex justify-center items-center ml-2 px-2 py-1.5 rounded space-x-2 cursor-pointer"
            onClick={() => dispatch(toggleAdvancedFilter())}
          >
            <span tw="min-width[11rem]">
              <Filter fill={'#7f7f7f'} width={28} height={28} />
              <div tw="text-15! font-medium text-sonnant-blue">
                <span tw="relative top[1px]">FILTERS</span>
              </div>
            </span>
          </Ripple>
        </div>
      )}

      <div>
        {!hasSelected() && isLibraryPage() && (
          <div tw="flex justify-end w-[100%]!" css={[customMenuCss]}>
            <Hint
              disabled={isSelectedAll}
              text={
                isSelectedAll
                  ? 'Deselect all selected items.'
                  : 'Select all current items.'
              }
            >
              <button
                className={`btn-select ${isSelectedAll && 'cancel'}`}
                onClick={handleSelectAll}
                disabled={isSelectedAll}
              >
                Select all
              </button>
            </Hint>
          </div>
        )}

        <div
          tw="flex white-space[nowrap]!"
          css={[
            customMenuCss,
            (!hasSelected() || isRevivalPage()) && tw`hidden`,
          ]}
        >
          <Hint text="Select multiple library items to manage">
            <button
              disabled={isEmpty(library?.data?.library)}
              className={`button btn-select btn-secondary cancel`}
              onClick={toggleSelect}
              tw="max-w-[13rem]!"
            >
              Cancel
            </button>
          </Hint>

          <MenuSurfaceAnchor ref={menuWrapperRef}>
            <button
              className="btn-manage-library"
              tw="(w-auto max-width[unset])!"
              onClick={toggleOpenActionMenu}
              css={[!hasSelected() && tw`hidden!`]}
            >
              <span>
                Manage library items ({currentSelectedRows?.length || 0}){' '}
              </span>
              <span className="library-arrow-down"></span>
            </button>

            <MenuSurface className="multi-menu" fixed open={isOpenActionMenu}>
              <ul className="ul-menu-item">
                {isItemSelect &&
                  !isMultiSelect &&
                  canRedirect(selectedRows?.[0]) && (
                    <>
                      <ExpiredHint>
                        <HintRestrictedPublish>
                          <li
                            css={[
                              (shouldDisabledSubscription || shouldDisabled) &&
                                customLiDisabled,
                            ]}
                            onClick={handleModalShare}
                          >
                            <div tw="flex space-x-2 items-center">
                              <Share css={iconStyle} />
                              <a>Share</a>
                            </div>
                          </li>
                        </HintRestrictedPublish>
                      </ExpiredHint>

                      <ExpiredHint>
                        <li
                          css={[
                            (shouldDisabledSubscription || shouldDisabled) &&
                              customLiDisabled,
                          ]}
                          onClick={() =>
                            handleExportContentItem(currentSelectedRows[0])
                          }
                        >
                          <div tw="flex space-x-2 items-center">
                            <Export css={iconStyle} />
                            <a>Export</a>
                          </div>
                        </li>
                      </ExpiredHint>

                      <hr css={[menuDivider]} />

                      <ExpiredHint>
                        <li
                          css={[
                            (shouldDisabledSubscription || shouldDisabled) &&
                              customLiDisabled,
                          ]}
                          onClick={handleModalPublish}
                        >
                          <div tw="flex space-x-2 items-center">
                            <Movie css={iconStyle} />
                            <a>
                              {advancedSearch.isShowClipsOnly
                                ? 'Edit'
                                : 'Create clips'}
                            </a>
                          </div>
                        </li>
                      </ExpiredHint>

                      {advancedSearch.isShowClipsOnly ? (
                        <li
                          onClick={handleShowPreviewPublish}
                          css={[shouldDisableClips() && customLiDisabled]}
                        >
                          <div tw="flex space-x-2 items-center">
                            <Preview css={iconStyle} />
                            <a>View</a>
                          </div>
                        </li>
                      ) : (
                        <>
                          <div css={[shouldDisableItem() && tw`hidden`]}>
                            <li
                              css={[isAIProcessing() && customLiDisabled]}
                              onClick={handleRouteToDisplay}
                            >
                              <div tw="flex space-x-2 items-center">
                                <Version css={backgroundIcon} />
                                <a>View clips</a>
                              </div>
                            </li>
                          </div>

                          <hr css={[menuDivider]} />

                          <li
                            onClick={() =>
                              handleRedirectClick(
                                selectedRows[0],
                                historyItem,
                                true,
                              )
                            }
                            css={[shouldDisable() && customLiDisabled]}
                          >
                            <div tw="flex space-x-2 items-center">
                              <TextSnippet css={iconStyle} />
                              <a>View transcript</a>
                            </div>
                          </li>

                          <ExpiredHint>
                            <HintRestrictedEdit>
                              <li
                                css={[
                                  (shouldDisabledSubscription ||
                                    noTranscript) &&
                                    customLiDisabled,
                                ]}
                                onClick={() =>
                                  handleEditCaption(selectedRows[0])
                                }
                              >
                                <div tw="flex space-x-2 items-center">
                                  <Subtitles css={iconStyle} />
                                  <a>Edit (captions)</a>
                                </div>
                              </li>
                            </HintRestrictedEdit>
                          </ExpiredHint>

                          <ExpiredHint>
                            <HintRestrictedEdit>
                              <li
                                css={[
                                  (shouldDisabledSubscription ||
                                    noTranscript) &&
                                    customLiDisabled,
                                ]}
                                onClick={() =>
                                  handleViewTranscript(selectedRows[0])
                                }
                              >
                                <div tw="flex space-x-2 items-center">
                                  <EditNote css={iconStyle} />
                                  <a>Edit (transcript)</a>
                                </div>
                              </li>
                            </HintRestrictedEdit>
                          </ExpiredHint>

                          <hr css={[menuDivider]} />

                          <ExpiredHint>
                            <li
                              css={[
                                shouldDisabledSubscription && customLiDisabled,
                              ]}
                              onClick={() => {
                                navigator.clipboard?.writeText(
                                  window.location.origin +
                                    `/transcription/${selectedRows[0]?.mediaid}`,
                                );
                                customToast.success('Copied to clipboard');
                              }}
                            >
                              <div tw="flex space-x-2 items-center">
                                <Link css={iconStyle} />
                                <a>Copy link</a>
                              </div>
                            </li>
                          </ExpiredHint>
                        </>
                      )}
                    </>
                  )}
                <div css={[shouldDisableItem() && tw`hidden`]}>
                  <li onClick={handleMentionReport}>
                    <div tw="flex space-x-2 items-center">
                      <MentionSvg
                        css={[
                          iconStyle,
                          tw`[> path:nth-of-type(2)]:fill[#7f8090]`,
                        ]}
                      />
                      <a>Mentions report</a>
                    </div>
                  </li>
                </div>

                {isMultiSelect && (
                  <div
                    css={[
                      (shouldDisabledSubscription || shouldDisabled) &&
                        tw`hidden`,
                    ]}
                  >
                    <li onClick={handleExportContentItem}>
                      <div tw={'flex space-x-2 items-center'}>
                        <Export
                          css={[
                            iconStyle,
                            tw`[> path:nth-of-type(2)]:fill[#7f8090]`,
                          ]}
                        />
                        <a>Export</a>
                      </div>
                    </li>
                  </div>
                )}

                <div
                  css={[
                    (shouldDisabledSubscription || shouldDisableRerun()) &&
                      tw`hidden`,
                  ]}
                >
                  <li onClick={handleRerun}>
                    <div tw="flex space-x-2 items-center">
                      <Rerun css={iconStyle} />
                      <a>Rerun AI</a>
                    </div>
                  </li>
                </div>

                <HintRestrictedPublish>
                  <div css={[shouldDisableItem() && tw`hidden`]}>
                    <li onClick={handleIntegrate}>
                      <div tw="flex space-x-2 items-center">
                        <IntegrateSvg css={iconStyle} />
                        <a>Integrate</a>
                      </div>
                    </li>
                  </div>
                </HintRestrictedPublish>

                {isItemSelect && (
                  <li onClick={selectedDeleteItemFromLibrary}>
                    <div tw="flex space-x-2 items-center">
                      <Delete css={iconStyle} />
                      <a>Delete</a>
                    </div>
                  </li>
                )}

                {!advancedSearch.isShowClipsOnly &&
                  isLibraryPage() &&
                  !collection?.selectedId &&
                  !isEmpty(collection.items) && (
                    <>
                      <hr css={[menuDivider]} />

                      <AddToCollectionMenu />
                    </>
                  )}

                {collection.showSidebar && !isEmpty(collection?.selectedId) && (
                  <>
                    <hr css={[menuDivider]} />

                    <li onClick={handleRemoveFromCollection}>
                      <div tw={'flex space-x-2 items-center'}>
                        <RemoveFromCollectionSvg css={iconStyle} />
                        <a>Remove from collection</a>
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </MenuSurface>
          </MenuSurfaceAnchor>
          <Hint
            disabled={isSelectedAll}
            text={
              isSelectedAll
                ? 'Deselect all selected items.'
                : 'Select all current items.'
            }
          >
            <button
              className={`btn-select ${isSelectedAll && 'cancel'}`}
              onClick={handleSelectAll}
              disabled={isSelectedAll}
            >
              Select all
            </button>
          </Hint>
        </div>
      </div>

      <MultiMentionReportModal ref={multiMentionReportModalRef} />
      <ConfirmModal ref={confirmModalRef} type="warning" />
    </div>
  );
};

const backgroundIcon = css`
  width: 4.5rem;
  padding-left: 1rem;
  path {
    fill: #7f8090 !important;
  }
`;

export const iconStyle = css`
  fill: #7f8090 !important;
  width: 4.5rem;

  padding-left: 1rem;
`;

const customLiDisabled = css`
  ${tw`(pointer-events-none font-size[medium]  [a]:(text-sonnant-grey-4))!`}
`;

const customSortCss = css`
  ${tw`overflow-y-auto`}
  ::-webkit-scrollbar {
    height: 4px;
  }
`;

const customMenuCss = css`
  ${tw`(width[fit-content] [li]:(hover:(bg-sonnant-grey-light)))`}

  .btn-select {
    ${tw`(margin[5px 8px] h-16 w-40 rounded bg-sonnant-purple-2 text-white text-14)`}
  }

  .cancel {
    ${tw`(bg-sonnant-grey-5)!`}
  }

  .btn-manage-library {
    ${tw`(margin[5px 0px] padding[1px 10px] line-height[1.5] h-16 width[20.5rem] rounded bg-sonnant-purple-2 text-white text-14)`}

    .library-arrow-down {
      ${tw`(w-0 h-0 top-4  border-width[5px 5px 0 5px] border-solid border-white border-l-transparent border-r-transparent relative align-text-top ml-2.5)!`}
    }
  }

  .ul-menu-item {
    ${tw`(list-style-type[none] margin-bottom[unset] margin-left[unset])!`}
  }

  .ul-menu-item li {
    ${tw`(flex relative items-center content-start overflow-hidden white-space[initial])`}
  }

  .ul-menu-item li a {
    ${tw`(text-14 letter-spacing[.01rem] line-height[2.1rem] text-sonnant-grey-7 padding[0.7rem 1rem 0.7rem 1rem])`}
  }

  .multi-menu.mdc-menu-surface {
    ${tw`(margin-top[4.5rem] margin-left[2.3rem] width[19rem] overflow-visible)!`}
  }
`;
