/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import 'twin.macro';

import { isEmpty, isEqual } from 'lodash';

import { ReactComponent as EditSvg } from 'assets/Icons/edit_insight.svg';

import { HintDisallowed } from 'components/shared/HintDisallowed';
import { Info } from 'components/shared/Info';
import {
  roundedRippleHoverCss,
  scrollbarWidthCss,
  simpleMenuDisabled,
} from 'components/shared/twin.styles';
import { TrendingTermSearchInsight } from 'services';
import { TrendingTermItem } from './TrendingTermItem';
import { MAX_TRENDING_TERM } from 'utils/constants';

type Props = {
  selectedTerm: TrendingTermSearchInsight | null;
  trendingTerms: TrendingTermSearchInsight[];

  onChangeTrendingTerm: (trendingTerm: TrendingTermSearchInsight) => void;
  onClickEditTerm: () => void;
};

export const TrendingTermList = ({
  selectedTerm,
  trendingTerms,
  ...props
}: Props) => {
  const enabledTrendingTerms = trendingTerms
    .filter((trendingTerm) => trendingTerm?.is_enabled)
    .slice(0, MAX_TRENDING_TERM);

  const isEmptyTrendingTerms = isEmpty(enabledTrendingTerms);

  const validateIsSelectedTerm = (
    trendingTerm: TrendingTermSearchInsight,
  ): boolean => {
    return isEqual(selectedTerm?.term, trendingTerm?.term);
  };

  const handleClickTrendingTerm = (trendingTerm: TrendingTermSearchInsight) => {
    props.onChangeTrendingTerm(trendingTerm);
  };

  return (
    <div tw="flex flex-col flex-1 justify-start items-start shadow rounded border border-solid border-sonnant-grey-1">
      <div tw="flex w-full justify-end items-center p-5">
        <HintDisallowed
          disabled={false}
          hintEnabled="Edit term and alias"
          hintDisabled="IAB layers are not allowed for editing"
        >
          <div
            css={[roundedRippleHoverCss, false && simpleMenuDisabled]}
            tw="outline[2px solid #7f8090] rounded-full flex items-center justify-center w-[2.6rem] h-[2.6rem]"
            onClick={() => props.onClickEditTerm()}
          >
            <EditSvg css={editIconStyle} />
          </div>
        </HintDisallowed>
      </div>

      <div tw="w-full h-[55rem] overflow-y-auto" css={[scrollbarWidthCss(4)]}>
        {!isEmptyTrendingTerms &&
          enabledTrendingTerms.map((trendingTerm, index) => (
            <TrendingTermItem
              key={index}
              index={index + 1}
              trendingTerm={trendingTerm}
              isSelected={validateIsSelectedTerm(trendingTerm)}
              onClick={handleClickTrendingTerm}
            />
          ))}

        {isEmptyTrendingTerms && (
          <div tw="flex justify-center items-center h-full">
            <Info text="No trending terms available. Please check back later or adjust your filters." />
          </div>
        )}
      </div>
    </div>
  );
};

const editIconStyle = css`
  fill: #7f8090;
  width: 2rem;
  height: 2rem;
`;
