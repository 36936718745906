import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { values } from 'lodash';
import { SortField } from 'utils/enum';
import { Pagination } from 'utils/models';
import { getLocalStorageFilter, IFilterConditioner } from 'utils/utils';

export const readLocal = (field: keyof IFilterConditioner) => {
  return getLocalStorageFilter()?.[field];
};

export const validateSortField = (value: any): SortField => {
  if (!values(SortField).includes(value as SortField)) {
    return SortField.MODIFIED;
  }

  return value as SortField;
};

const paginationSlice = createSlice({
  name: 'layers',
  initialState: {
    isListView: readLocal('isListView') ?? false,
    page_number: readLocal('page_number') ?? 1,
    page_size: readLocal('page_size') ?? 30,
    sortBy: validateSortField(readLocal('sortBy')) ?? SortField.MODIFIED,
    isAsc: readLocal('isAsc') ?? (false as boolean),
    isLoaded: true as boolean,
  } as Pagination,
  reducers: {
    setPagination: (state, action: PayloadAction<Pagination>) => {
      return { ...state, ...action.payload };
    },
    changePage: (state, action) => {
      state.page_number = action.payload.page_number;
      state.page_size = action.payload.page_size;
    },
    setPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.page_number = payload;
    },
    setPageSize: (state, { payload }: PayloadAction<number>) => {
      state.page_size = payload;
    },
    changeLoading: (state, action) => {
      state.isLoaded = action.payload;
    },
    changeSortBy: (state, action: PayloadAction<string>) => {
      state.sortBy = validateSortField(action.payload);
      state.page_number = 1;
    },
    changeOrder: (state, action: PayloadAction<boolean>) => {
      state.isAsc = action.payload;
    },
    toggleSortOrder: (state) => {
      state.isAsc = !state.isAsc;
    },
    setIsListView: (state, action: PayloadAction<boolean>) => {
      state.isListView = action.payload;
    },
  },
});

export const {
  setPagination,
  changePage,
  changeLoading,
  changeSortBy,
  changeOrder,
  toggleSortOrder,
  setIsListView,
  setPageNumber,
  setPageSize,
} = paginationSlice.actions;

export const paginationReducer = paginationSlice.reducer;
