/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import 'twin.macro';
import tw from 'twin.macro';

import { SimpleMenuSurface } from '@rmwc/menu';
import { Ripple } from '@rmwc/ripple';
import { ReactComponent as ArrowDownSvg } from 'assets/Icons/speaker-arrow-down.svg';
import { AlterItem } from 'components/shared/CutomTermsModal/AlterItem';
import { Hint } from 'components/shared/Hint';
import { HoverTooltip } from 'components/shared/HoverTooltip';
import { diffChars } from 'diff';
import { cloneDeep, isEmpty, isEqual, isNil, toNumber } from 'lodash';
import React, { memo, ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { RootState } from 'reducers';
import { updateTextKeyword } from 'slices/caption.slice';
import { layerSelector } from 'slices/layers.slice';
import { ReactComponent as Movie } from 'assets/Icons/movie_white.svg';
import { ReactComponent as CancelIcon } from 'assets/Icons/cancel_circle.svg';
import {
  editTermInCustomTerms,
  updateAlter,
  updateCurrentSpeaker,
  updateEntities,
  updateKeyword,
} from 'slices/player.slice';
import { Keys, Layers, VideoStatusCode } from 'utils/enum';
import { IKeyItem } from 'utils/models';
import { createManualKeyword, toSlateLineText } from 'utils/transcript.util';
import { ReactComponent as CloseIcon } from '../../../../assets/Icons/close.svg';
import { ReactComponent as VoiceIcon } from '../../../../assets/Icons/voice.svg';
import { humanEditStyles } from '../../../shared/twin.styles';
import { isEmbedPage, isPublicShared } from '../MediaUtilities';
import './Keyword.scss';
import { v4 } from 'uuid';
import { customToast } from 'utils/toast.util';
import { IRichTextInput } from 'components/shared/RichTextEditor/rich-text.model';

const HumanEdit = ({ text = '' }) => {
  const toggle = useSelector((state: RootState) => state.toggle);

  return (
    <span css={toggle.isShowHumanEdit && !isPublicShared() && humanEditStyles}>
      {text}
    </span>
  );
};

interface IProps {
  val: IKeyItem;
  index?: number;
  isEditingTranscript?: boolean;
  currentKeyword?: IKeyItem;
  setCurrentKeyword?: (keyword: IKeyItem) => unknown;
  handleRemoveKeyword?: Function;
  showTooltip?: boolean;
  enterKeyword?: any;
  disableEdit?: boolean;
  showMentionModal?: () => unknown;
  focusItem?: any;
  hideCount?: boolean;
  handlePublishModal?: () => void;
}

export const Keyword = memo(
  ({
    val,
    index,
    isEditingTranscript = false,
    currentKeyword,
    setCurrentKeyword,
    handleRemoveKeyword,
    enterKeyword,
    focusItem,
    ...props
  }: IProps) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [newAlter, setNewAlter] = useState('');
    const [editingKeyword, setEditingKeyword] = useState(val?.keyword ?? '');
    const keywordLatest = useRef<any>([]);
    const mentionLatest = useRef<any>([]);

    const [isShowAlter, toggleShowAlter] = useToggle(false);
    const [isShowInput, toggleShowInput] = useToggle(false);

    const keywordRef = useRef<HTMLInputElement>(null);
    const player = useSelector((state: RootState) => state.player);

    const layers = useSelector((state: RootState) => state.layers);
    const global = useSelector((state: RootState) => state.global);
    const toggle = useSelector((state: RootState) => state.toggle);
    const media = useSelector((state: RootState) => state.media);

    const isAIProcessing = isEqual(
      toNumber(media?.statuscode),
      VideoStatusCode.AI_PROCESSING,
    );

    // const currentLayer = useSelector(layerSelector);
    const isSelected = currentKeyword?.keyword === val?.keyword;

    const occurrences = val?.mentions?.[0]?.occurrences ?? [];

    const hoverRef = useRef<any>(null);

    const dispatch = useDispatch();

    useEffect(() => {
      setEditingKeyword(val?.keyword);
    }, [isEditMode, layers.currentLayer]);

    useEffect(() => {
      if (toggle.isEditCaption && keywordLatest.current.length) {
        // confirm yes
        const newItem = cloneDeep(keywordLatest.current);
        const id = player.selectedTagId;
        if (id !== null) {
          newItem[id] = { ...newItem[id], mentions: mentionLatest.current };
          dispatch(updateKeyword(newItem));
        }
      }
    }, [toggle.isEditCaption]);

    const checkExistKeyterm = (keyterm: IKeyItem[]) => {
      const foundIndex = keyterm.findIndex((i) => i.keyword === editingKeyword);
      if (foundIndex! >= 0) {
        customToast.error('This term has already existed');
        return true;
      }
      return false;
    };

    const currentLayer = useSelector(layerSelector);

    const handleInputKeyword = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const editingKeywordTrim = editingKeyword?.replace(/\s+/g, ' ').trim();

      if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
        if (isEmpty(editingKeywordTrim)) {
          handleCancelEditing();
          return;
        }
        setEditingKeyword(editingKeywordTrim);
        setIsEditMode(false);

        if (val?.keyword !== editingKeywordTrim) {
          if (currentLayer.isCustomTerms) {
            const newVal: IKeyItem = { ...val, custom: editingKeywordTrim };
            dispatch(editTermInCustomTerms(newVal));
            return;
          }

          if (currentLayer.isSpeaker) {
            dispatch(
              updateCurrentSpeaker({
                oldName: val?.keyword,
                newName: editingKeywordTrim,
                isOverWrite: true,
              }),
            );

            // IMPORTANT: Speakers do not updated instantly here

            return; // ignore unrelated logic
          }

          const id = player.selectedTagId;
          dispatch(updateTextKeyword(editingKeywordTrim));

          const sentences = toSlateLineText(window.Editor.children);

          const newItem = createManualKeyword(
            editingKeywordTrim,
            sentences,
            window.Editor.children as IRichTextInput[],
          );
          const newUpdate = {
            keyword: editingKeyword,
            mentions: newItem.mentions,
            relevance: newItem?.relevance ?? 1,
          };

          if (layers.currentLayer === Layers.KEY_TERM) {
            const keywords = player.keywords;
            if (id !== null) {
              const newKeywords = cloneDeep(keywords);
              if (checkExistKeyterm(newKeywords)) return;
              newKeywords[id] = newUpdate;
              dispatch(updateKeyword(newKeywords));
              keywordLatest.current = newKeywords;
              mentionLatest.current = val?.mentions;
            }
          } else {
            const entities = player.entities;
            if (id !== null) {
              const newEntities = cloneDeep(entities);

              if (layers.currentLayer === Layers.ORG) {
                if (checkExistKeyterm(newEntities?.organisation ?? [])) return;
                if (newEntities.organisation?.[id].keyword) {
                  newEntities.organisation[id] = newUpdate;
                }
              }
              if (layers.currentLayer === Layers.PEOPLE) {
                if (checkExistKeyterm(newEntities?.person ?? [])) return;
                if (newEntities.person?.[id].keyword) {
                  newEntities.person[id] = newUpdate;
                }
              }
              if (layers.currentLayer === Layers.PRODUCT) {
                if (checkExistKeyterm(newEntities?.product ?? [])) return;
                if (newEntities.product?.[id].keyword) {
                  newEntities.product[id] = newUpdate;
                }
              }
              if (layers.currentLayer === Layers.NATIONALITY) {
                if (checkExistKeyterm(newEntities?.nationality ?? [])) return;
                if (newEntities.nationality?.[id].keyword) {
                  newEntities.nationality[id] = newUpdate;
                }
              }
              if (layers.currentLayer === Layers.LOCATION) {
                if (checkExistKeyterm(newEntities?.location ?? [])) return;
                if (newEntities.location?.[id].keyword) {
                  newEntities.location[id] = newUpdate;
                }
              }
              dispatch(updateEntities(newEntities));
            }
          }
          enterKeyword();
          focusItem();
        }
      }

      if (e.key === Keys.ESC) {
        handleCancelEditing();
      }
    };

    const handleCancelEditing = () => {
      setIsEditMode(false);
      setEditingKeyword(val?.keyword ?? '');
    };

    const handleTriggerClick = () => {
      // Ignore on PARAGRAPH MODE
      if (typeof setCurrentKeyword === 'function') {
        // click similar with keyword before
        if (val?.keyword === player.selectedTerms[0]?.keyword) {
          return;
        }
        setCurrentKeyword(val);
      }
    };

    const handleTriggerDoubleClick = () => {
      if (!isEditingTranscript || props?.disableEdit) return;
      setIsEditMode(true);
      setTimeout(() => {
        keywordRef.current?.focus();
      }, 0);
    };

    const resetInput = () => {};

    const handleRemoveAlter = (id: string) => {
      const newKeyword = { ...val };
      newKeyword.alternatives = val?.alternatives?.filter((t) => {
        return t.id !== id;
      });

      dispatch(
        updateAlter({
          newKeyword,
        }),
      );
    };

    const enterEdit = (termId: string, alterText: string) => {
      const newKeyword = { ...val };

      newKeyword.alternatives = val?.alternatives?.map((t) => {
        if (t.id === termId) {
          return { ...t, name: alterText };
        }
        return t;
      });

      dispatch(
        updateAlter({
          newKeyword,
        }),
      );
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
        const newAlterNatives = cloneDeep(val?.alternatives) ?? [];

        newAlterNatives.push({
          id: v4(),
          name: newAlter,
          dateCreated: new Date(),
        });

        const newKeyword = { ...val, alternatives: newAlterNatives };
        dispatch(
          updateAlter({
            newKeyword,
          }),
        );
        setNewAlter('');
        toggleShowInput(false);
      }
      if (e.key === Keys.ESC) {
        setNewAlter('');
      }
    };

    return isEditMode ? (
      // INPUT MODE
      <div className="button keywordBtn keywordEdit" tw="p-0">
        <input
          type="text"
          ref={keywordRef}
          value={editingKeyword}
          onChange={(e) => setEditingKeyword(e.target.value)}
          onKeyDown={handleInputKeyword}
          onBlur={handleCancelEditing}
          className="small-10 keywordInput"
          tw="rounded-l-full!"
        />
        <button
          onClick={handleCancelEditing}
          className="small-2 cancelBtn"
          tw="(flex justify-center items-center rounded-l-none rounded-r-full)!"
        >
          <CloseIcon tw="width[1.3rem] -ml-2" className="whiteIcon" />
        </button>
      </div>
    ) : (
      // VIEW MODE
      <>
        <HoverTooltip
          disabled={
            global?.showMentions ||
            props?.showTooltip ||
            isPublicShared() ||
            isEmbedPage() ||
            isAIProcessing ||
            currentLayer.isSpeaker
          }
          className="tooltip-voice"
          popupElem={
            <div tw="flex items-center padding[3px]">
              <Hint text="Mentions report" enterDelay={200} leaveDelay={100}>
                <span
                  tw="w-full h-full"
                  css={[!currentLayer.isCustomTerms && tw`mr-5`]}
                  onClick={() => {
                    handleTriggerClick();
                    props?.showMentionModal?.();
                  }}
                >
                  <VoiceIcon />
                </span>
              </Hint>

              {!currentLayer.isCustomTerms && (
                <Hint text="Create clip" enterDelay={200} leaveDelay={100}>
                  <span
                    tw="w-full h-full flex"
                    onClick={() => {
                      handleTriggerClick();
                      props?.handlePublishModal?.();
                    }}
                  >
                    <Movie />
                  </span>
                </Hint>
              )}
            </div>
          }
        >
          <button
            type="button"
            className={`button keywordBtn btn-secondary ${
              isSelected && 'selectedKeyword'
            }`}
            onClick={handleTriggerClick}
            onDoubleClick={handleTriggerDoubleClick}
            onMouseEnter={() =>
              (hoverRef.current = setTimeout(() => toggleShowAlter(true), 1000))
            }
            onMouseLeave={() => {
              clearTimeout(hoverRef.current);
              toggleShowAlter(false);
            }}
            tw="md-down:font-size[1.4rem] flex items-center hover:(z-index[1]) rounded-full!"
            title={props?.showTooltip ? val?.keyword : ''}
          >
            <div
              tw="flex items-center"
              css={
                props?.showTooltip &&
                tw`(min-width[auto] width[90%] justify-center)!`
              }
            >
              <span className="keywordval line-clamp-1" title={val.keyword}>
                <DiffableTag tag={val} />
              </span>

              {!props?.hideCount && (
                <div tw="flex ">
                  <span tw="border[1px solid] border-sonnant-grey-4 rounded-full min-width[2.5rem] h-10 inline-flex justify-center items-center text-center ml-2 px-1.5">
                    {occurrences?.length ?? val.count ?? 0}
                  </span>
                </div>
              )}

              {isEditingTranscript && !val?.inUsed && (
                <div
                  className="closeButton"
                  tw="flex items-center pt-0!"
                  onClick={(e) => handleRemoveKeyword?.(e, index, val)}
                >
                  <CancelIcon width={16} />
                </div>
              )}
            </div>
            {/* {isEditingTranscript &&
            !props?.showTooltip &&
            currentLayer.isCustomTerms && ( */}

            {/* TEMPORARILY DISABLED */}
            {false && (
              <SimpleMenuSurface
                open={isShowAlter}
                anchorCorner={'bottomStart'}
                onClose={resetInput}
                handle={
                  <>
                    {isShowAlter && (
                      <ArrowDownSvg
                        width={12}
                        tw="ml-4"
                        css={isSelected && arrowDownFocus}
                      />
                    )}
                  </>
                }
              >
                <div tw="width[15.5rem] p-4 top-5">
                  {
                    <AlterItem
                      term={{ id: v4(), name: '', dateCreated: new Date() }}
                      isDefault
                      enterEdit={enterEdit}
                      handleRemoveAlter={handleRemoveAlter}
                    />
                  }
                  {val.alternatives?.map((term) => (
                    <AlterItem
                      term={term}
                      key={term.id}
                      enterEdit={enterEdit}
                      handleRemoveAlter={handleRemoveAlter}
                    />
                  ))}

                  {isShowInput ? (
                    <div>
                      <input
                        tw="(text-2xl height[3rem] pl-3 mb-0 text-14 placeholder:(text-13))!"
                        type="text"
                        value={newAlter}
                        onChange={(e) => setNewAlter(e.target.value)}
                        placeholder="Add alternative"
                        onKeyDown={handleKeyDown}
                        autoFocus
                      />
                    </div>
                  ) : (
                    <Ripple>
                      <div
                        css={[itemStyles, tw`cursor-pointer`]}
                        tw="text-13 -mt-2"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          toggleShowInput(true);
                        }}
                      >
                        + Add Alternative
                      </div>
                    </Ripple>
                  )}
                </div>
              </SimpleMenuSurface>
            )}
          </button>
        </HoverTooltip>
      </>
    );
  },
);

const itemStyles = css`
  ${tw`flex justify-between items-center text-2xl font-medium opacity[.75] w-full cursor-pointer py-1 px-2 rounded`}
  &:hover {
    ${tw`bg-sonnant-grey-light`}
  }
`;

const DiffableTag = ({ tag }: { tag: IKeyItem }): ReactElement => {
  const currentLayer = useSelector(layerSelector);

  const highlightDiff = () => {
    const diffs = diffChars(tag?.original ?? '', tag.keyword);

    return diffs.map(({ value: text, added, removed }) => {
      // Hide Removed and Highlight Added
      return !removed && (added ? <HumanEdit text={text} /> : text);
    });
  };

  return (
    <>
      {isNil(tag?.original)
        ? currentLayer.isCustomTerms
          ? tag?.custom || tag?.keyword
          : tag?.keyword
        : highlightDiff()}
    </>
  );
};

const arrowDownFocus = css`
  path {
    fill: white;
  }
`;
