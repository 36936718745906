import { BaseAPI } from 'apis/amazon';
import { AxiosResponse } from 'axios';
import { Ratio, SortField } from 'utils/enum';
import {
  APISharingOptionPayload,
  CombinedClipRequest,
  IMediaDetail,
  IPublishPayload,
  IPublishVersion,
  Term,
  ExportEmbedResponse,
  ExportEmbedType,
} from 'utils/models';

export type MediaIdParams = {
  mediaid: string;
  versioncount?: string;
};

export type HistoryParams = {
  mediaid: string;
  type: any;
  versioncount: number;
};

export type BoolNumber = 1 | 0;

export type ExportParams = {
  mediaid?: string;
  versioncount?: number;
  mediaItems: [{ mediaid: string; versioncount: string }];
  isOrigin?: BoolNumber;
  filename?: string;
  isBurnedIn?: boolean;
  hasMedia?: BoolNumber;
  pdfTC: BoolNumber;
  docxTC: BoolNumber;
  txtTC: BoolNumber;
  pdfSP: BoolNumber;
  docxSP: BoolNumber;
  txtSP: BoolNumber;
  vtt: BoolNumber;
  mp3?: BoolNumber;
  mp4?: BoolNumber;
  srt: BoolNumber;
  isSingleExport?: boolean;
};

export type LockEditingPayload = {
  mediaid: string;
  editingId: string;
  isEditing?: boolean;
  isForceEdit?: boolean;
  isSaved?: boolean;
};

export type RenameTitlePayload = {
  mediaid: string;
  tenantid: string;
  title: string;
};

export type DeleteVersion = {
  mediaid: string;
  versioncount: string;
};

export type RenameTitlePublicPayload = {
  mediaid: string;
  versioncount: string;
  versionname: string;
};

export type VersionOptionPayload = {
  mediaid: string;
  versioncount?: string;
  options: APISharingOptionPayload;
};

export type SummaryPayload = {
  object_name: string;
};

export type RegeneratePayload = {
  action: string;
  payload: {
    media_id: string;
    subtitle_url?: string;
    suggested_clip?: string[];
    status?: string;
  };
};

export type PreviewResponse = {
  jsonfileurl: string;
  length: string;
  mediatype: string;
  subtitleurl: string;
  thumbnail_url: string;
  hasBurnIn?: boolean;
  url: string;
  dar?: Ratio;
};

export type RenameTitlePublicResponse = {
  mediaid: string;
  modifieddatetime: string;
  versioncount: string;
  versionname: string;
};

export type LockEditingResponse = {
  status: string;
  isOverridden: boolean;
  userName: string;
};

export type ExportResponse = {
  mp4?: string;
  mp3?: string;
  textData: any;
};

export type MultiMentionPayload = {
  mediaIds: string[];
  terms: Term[];
  reportName: string;
  isCombined?: boolean;
  collectionIds?: string[];
  order?: 'asc' | 'desc';
  sortBy?: SortField;
  fromDate?: string | number;
  toDate?: string | number;
};

export type AdMarkerDownloadPayload = {
  mediaId: string;
  jsonV2Path: string;
};

export type AdMarkerLayerResponse = {
  iab: string;
  marker: string;
  marker_url: string;
  start_time: number;
  tags: string;
  transcript_prior: string;
  transcript_after: string;
  ads_served?: number;
};

export type IabSensitiveDownloadPayload = {
  mediaId: string;
  jsonV2Path: string;
};

export const MediaService = {
  // GET /media
  getMediaDetail: (
    params: MediaIdParams,
  ): Promise<AxiosResponse<IMediaDetail>> => {
    return BaseAPI.get('/media', { params });
  },

  // GET /media/public-details
  getPublicDetails: (
    shareid: string,
    shared?: boolean | undefined,
  ): Promise<AxiosResponse<IMediaDetail>> => {
    return BaseAPI.get('/media/public-details/share', {
      params: { shareid, shared },
    });
  },

  // DELETE /media
  deleteMediaItem: (mediaid: string[]): Promise<AxiosResponse<void>> => {
    return BaseAPI.delete('/media', { data: { mediaid } });
  },

  // GET /media/preview
  getPreview: (
    params: MediaIdParams,
  ): Promise<AxiosResponse<PreviewResponse>> => {
    return BaseAPI.get('/media/preview', {
      params,
    });
  },

  // GET /media/history
  getHistory: (params: HistoryParams): Promise<AxiosResponse<IMediaDetail>> => {
    return BaseAPI.get('/media/history', {
      params,
    });
  },

  // PUT /media/republish
  rePublish: (payload: IPublishPayload): Promise<AxiosResponse<string>> => {
    return BaseAPI.put('/media/republish', payload);
  },

  // GET /media/export
  // getExport: (params: ExportParams): Promise<AxiosResponse<ExportResponse>> => {
  //   return BaseAPI.get('/media/export', {
  //     params,
  //   });
  // },
  getExport: (params: ExportParams): Promise<AxiosResponse<ExportResponse>> => {
    if (params.isSingleExport) {
      return BaseAPI.get('/media/export', { params });
    } else {
      return BaseAPI.post('/media/export', params);
    }
  },

  // PUT /media/update-title
  updateTitle: (payload: RenameTitlePayload): Promise<AxiosResponse<any>> => {
    return BaseAPI.put('/media/update-title', payload);
  },

  // PUT /media/lock-editing
  lockEditing: (
    payload: LockEditingPayload,
  ): Promise<AxiosResponse<LockEditingResponse>> => {
    return BaseAPI.put('/media/lock-editing', payload);
  },

  // GET /media/version
  getPublishVersion: (
    params: MediaIdParams,
  ): Promise<AxiosResponse<IPublishVersion>> => {
    return BaseAPI.get(`/media/version/`, {
      params,
    });
  },

  // POST /media/publish
  publish: (payload: IPublishPayload): Promise<AxiosResponse<string>> => {
    return BaseAPI.put('/media/publish', payload);
  },

  // PUT /media/version
  deleteVersion: (payload: DeleteVersion[]): Promise<AxiosResponse<string>> => {
    return BaseAPI.put('/media/version', {
      data: payload,
    });
  },

  // PUT /media/version-title
  renameTitlePublic: (
    payload: RenameTitlePublicPayload,
  ): Promise<AxiosResponse<RenameTitlePublicResponse>> => {
    return BaseAPI.put('/media/version-title', payload);
  },

  // PUT /media/version-option
  versionOption: (
    payload: VersionOptionPayload,
  ): Promise<AxiosResponse<any>> => {
    return BaseAPI.put('/media/version-option', payload);
  },

  // GET /media/summary
  getSummary: (payload: SummaryPayload): Promise<AxiosResponse<any>> => {
    return BaseAPI.post('/media/summary', payload);
  },

  // POST /media/combine-clip
  combineClip: (
    payload: CombinedClipRequest,
  ): Promise<AxiosResponse<string>> => {
    return BaseAPI.post('/media/combine-clip', payload);
  },

  // POST /media/regenerate
  regenarate: (
    payload: RegeneratePayload,
  ): Promise<AxiosResponse<{ succeed: boolean; message: string }>> => {
    return BaseAPI.post('/media/regenerate', payload);
  },

  // GET /media/public-export
  publicExport: (params: ExportParams): Promise<AxiosResponse<string>> => {
    return BaseAPI.get('/media/public-export', { params });
  },

  // GET /media/public-export
  exportEmbed: (
    payload: ExportEmbedType,
  ): Promise<AxiosResponse<ExportEmbedResponse>> => {
    return BaseAPI.get('/media/public-export', {
      params: {
        versioncount: payload.media?.metadata?.versioncount,
        mediaid: payload.media?.metadata?.mediaid,
        filename: payload.media?.metadata?.filename,
        mp4: payload.exportOption.mediaFileMp4 ? 1 : 0,
        mp3: payload.exportOption.mediaFileMp3 ? 1 : 0,
        vtt: payload.exportOption.captionsFileVtt ? 1 : 0,
        srt: payload.exportOption.captionsFileSrt ? 1 : 0,
        pdfTC: payload.exportOption.transcriptTimedCaptionsPdf ? 1 : 0,
        docxTC: payload.exportOption.transcriptTimedCaptionsDocx ? 1 : 0,
        txtTC: payload.exportOption.transcriptTimedCaptionsTxt ? 1 : 0,
        pdfSP: payload.exportOption.transcriptSpeakerParagraphsPdf ? 1 : 0,
        docxSP: payload.exportOption.transcriptSpeakerParagraphsDocx ? 1 : 0,
        txtSP: payload.exportOption.transcriptSpeakerParagraphsTxt ? 1 : 0,
        isBurnedIn: payload.isBurnedIn,
      },
    });
  },

  // POST /media/mentions
  getMentionReport: (payload: MultiMentionPayload) => {
    return BaseAPI.post('/media/mentions', {
      media_items: payload.mediaIds,
      terms: payload.terms,
      reportName: payload?.reportName,
      isCombined: payload?.isCombined ?? undefined,
      collection_ids: payload?.collectionIds ?? undefined,
      order: payload?.order ?? undefined,
      sortBy: payload?.sortBy ?? undefined,
      fromDate: payload?.fromDate,
      toDate: payload?.toDate,
    });
  },

  // POST /media/export/ad-marker
  downloadAdMarkerReport: (payload: AdMarkerDownloadPayload) => {
    return BaseAPI.post('/media/export/ad-marker', {
      media_id: payload.mediaId,
      json_v2_path: payload.jsonV2Path,
    });
  },

  // GET /media/export/ad-marker
  getAdMarkerReport: (
    payload: AdMarkerDownloadPayload,
  ): Promise<AxiosResponse<AdMarkerLayerResponse[]>> => {
    return BaseAPI.get('/media/export/ad-marker', {
      params: {
        media_id: payload.mediaId,
        json_v2_path: payload.jsonV2Path,
      },
    });
  },

  // POST /media/export/iab
  downloadIabSensitiveReport: (payload: IabSensitiveDownloadPayload) => {
    return BaseAPI.post('/media/export/iab', {
      media_id: payload.mediaId,
      json_v2_path: payload.jsonV2Path,
    });
  },
};
