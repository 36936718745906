/** @jsxImportSource @emotion/react */
import 'twin.macro';
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { Tooltip } from '@rmwc/tooltip';
import { RMWCProvider } from '@rmwc/provider';
import { removeHTMLTag } from '../../../utils/utils';

import { SensitiveBadge } from 'components/shared/SensitiveBadge';
import { useMemo } from 'react';

type Props = {
  summary?: string;
  title: string;
  isSensitive: boolean;
  sensitiveJsonRaw?: string;
  mediaid?: string;
};

export const ListViewTitle = ({
  summary,
  title,
  isSensitive,
  sensitiveJsonRaw,
  mediaid,
}: Props) => {
  const sensitiveItems = isSensitive
    ? JSON.parse(sensitiveJsonRaw || '[]')
    : [];

  const titleMemo = useMemo(
    () => (
      <div
        title={title}
        tw="pl-10 font-size[1.5rem] text-sonnant-darker! md-down:mr-4 overflow-hidden overflow-ellipsis word-break[break-word]!"
        css={[isSensitive && customTitleCss]}
        className="line-clamp-1"
      >
        {title}
      </div>
    ),
    [title, isSensitive],
  );

  return (
    <>
      {summary ? (
        <RMWCProvider
          tooltip={{
            align: 'topLeft',
            activateOn: 'hover',
            showArrow: true,
            leaveDelay: 500,
            enterDelay: 2000,
          }}
        >
          <Tooltip
            className="listview-tooltip"
            content={
              <div tw="max-h-40 max-w-2xl overflow-auto m-0 p-4 border-0 break-words">
                {summary && <h2 tw="text-3xl font-bold">Summary</h2>}
                <p tw="text-2xl">{removeHTMLTag(summary) || 'Not Found'}</p>
              </div>
            }
          >
            <>{titleMemo}</>
          </Tooltip>
        </RMWCProvider>
      ) : (
        <>{titleMemo}</>
      )}

      {isSensitive && (
        <div>
          <SensitiveBadge
            mediaid={mediaid}
            sensitiveItems={sensitiveItems}
            useZIndexDefault
          />
        </div>
      )}
    </>
  );
};

const customTitleCss = css`
  ${tw`margin-right[3rem]! overflow-ellipsis overflow-hidden`}
`;
