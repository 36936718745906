/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { ReactComponent as CloseSVG } from 'assets/Icons/speaker-remove.svg';
import { hoverDropShadow } from 'components/shared/twin.styles';
import { Hint } from 'components/shared/Hint';
import { useDispatch } from 'react-redux';
import { removeSearchTextBox } from 'slices/advanced-search.slice';
import { AdvancedFilterType } from 'utils/enum';

type FilterBoxItemsProps = {
  typeEnum: AdvancedFilterType;
  text: string;
};

export const FilterTextBox = (props: FilterBoxItemsProps) => {
  const dispatch = useDispatch();

  const handleRemove = () => {
    const payload = {
      type: props?.typeEnum,
      text: props?.text,
    };

    dispatch(removeSearchTextBox(payload));
  };

  return (
    <div tw="flex justify-between border-width[2px] border-solid border-sonnant-blue rounded-xl pl-1.5 mt-1.5 pr-1.5 max-width[25rem]">
      <div tw="text-12 font-medium mr-2 word-break[break-all]" className="line-clamp-1">{props?.text}</div>

      <span tw="mr-0.5">
        <Hint text="Remove" notTransparent arrow>
          <CloseSVG
            css={[hoverDropShadow(0.4), xIconCss]}
            onClick={handleRemove}
          />
        </Hint>
      </span>
    </div>
  );
};

const xIconCss = css`
  ${tw`relative top[1px]`}

  &:hover path {
    ${tw`fill[blue]`}
  }
`;
