/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { EmbedPreview } from 'components/Share/EmbedModal/EmbedPreview';
import { TextInput } from 'components/shared/TextInput';
import {
  greyScrollbar,
  scrollbarWidthCss,
} from 'components/shared/twin.styles';
import { useState } from 'react';
import { replaceEmbedLocalEnv } from 'utils/utils';

type Props = {};

export const IframeRunner = (props: Props) => {
  const defaultCode = '';

  const [embedCode, setEmbedCode] = useState<string>(
    replaceEmbedLocalEnv(defaultCode),
  );

  return (
    <div
      tw="py-10 px-20 h-[calc(100vh - 7rem)] overflow-y-auto"
      css={[scrollbarWidthCss(4), greyScrollbar]}
    >
      <div>
        <label>Embed code</label>
        <TextInput
          onPressEnter={(code) => {
            setEmbedCode(replaceEmbedLocalEnv(code));
          }}
          maxLength={Infinity}
          placeholder="Enter embed code"
          defaultText={embedCode}
        />
      </div>

      <div tw="mt-3">
        <label>Result</label>
        <EmbedPreview embedCode={embedCode} />
      </div>
    </div>
  );
};
