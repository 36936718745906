/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { Ripple } from '@rmwc/ripple';
import { useSelector } from 'react-redux';

import { RootState } from 'reducers';
import { Option } from '../CustomSelectSearch';

type Props = {
  option: Option;

  onClick: (selectedOption: Option) => void;
};

export const InsightAdvancedSelectionItem = ({ option, ...props }: Props) => {
  const insightTrending = useSelector(
    (state: RootState) => state.insightTrending,
  );

  const isSelected = insightTrending.calendarMode === option.value;

  const handleClickItem = () => {
    props.onClick(option);
  };

  return (
    <Ripple
      tw="flex w-full justify-start items-center px-4 py-2 cursor-pointer"
      onClick={handleClickItem}
      css={[isSelected && tw`bg-sonnant-grey-2`]}
    >
      <div tw="flex-nowrap text-14 text-sonnant-dark opacity-90 select-none">
        {option.label}
      </div>
    </Ripple>
  );
};
