/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { css } from '@emotion/react';

import React, {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Modal } from 'components/UiControls/modal/modal';
import { ModalOptions } from 'utils/enum';
import { SharingOptions } from 'components/Preferences/Settings/SharingOptions';
import { APISharingOptionPayload, IClips, SharingOption } from 'utils/models';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setFocusClip } from 'slices/clips.slice';
import { MediaService } from 'services';
import { customToast } from 'utils/toast.util';

interface Props {}

interface IPromiseParams {
  resolve: (option: ModalOptions) => void;
  reject: (err: any) => void;
}

export const SharingOptionModal = forwardRef(
  (props: Props, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const dispatch = useDispatch();

    const clips = useSelector((state: RootState) => state.clips);

    const [isOpen, setIsOpen] = useState(false);
    const [sharingOptions, setSharingOptions] = useState<SharingOption | null>(
      null,
    );

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (): Promise<unknown> => {
      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const handleYes = async () => {
      if (!sharingOptions) return;

      const updateOptionsAsync = MediaService.versionOption({
        mediaid: clips.mediaid,
        versioncount: clips.focusClip?.versioncount,
        options: {
          logo: sharingOptions.logo,
          captiondownload: sharingOptions.captionfile,
          download: sharingOptions.transcripts,
          paraheader: sharingOptions.paraheader,
          keyterms: sharingOptions.keyterm,
          products: sharingOptions.products,
          nationalities: sharingOptions.nationalities,
          summaries: sharingOptions.datasummary,
          orgs: sharingOptions.orgs,
          names: sharingOptions.people,
          locations: sharingOptions.locations,
          globalList: sharingOptions.globalList,
          chapters: sharingOptions.chapters,
          iab: sharingOptions.iab,
          visualplaybar: sharingOptions.visualplaybar,
          speakers: sharingOptions.speakers,
          hasAdMarkersLayer: sharingOptions.hasAdMarkersLayer,
        } as APISharingOptionPayload,
      });

      customToast.promise(updateOptionsAsync, {
        loading: 'Updating...',
        success: 'Updated sharing options successfully',
        error: 'Update sharing options failed',
      });

      updateOptionsAsync.then(() => {
        const currentFocusClips = {
          ...clips.focusClip,
          options: { ...sharingOptions },
        } as IClips;

        dispatch(setFocusClip(currentFocusClips));
      });

      hideModal();
      promiseInfo.current?.resolve(ModalOptions.YES);
    };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current?.resolve(ModalOptions.CANCEL);
    };

    const hideModal = () => setIsOpen(false);

    return (
      <Modal show={isOpen} modalClosed={hideModal}>
        <div css={customCss}>
          <h2 tw="font-semibold font-size[3.5rem]">Sharing options</h2>

          <div tw="my-6">
            <SharingOptions
              checkboxState={clips.focusClip?.options}
              inModal
              onStateChange={setSharingOptions}
            />
          </div>

          <div className="grid-x">
            <div className="cell small-12">
              <div tw="w-full flex justify-end">
                <button
                  type="button"
                  className="button btn-secondary large"
                  tw="mr-6 hover:(border-sonnant-grey-3!)"
                  onClick={handleCancel}
                >
                  {'Cancel'}
                </button>
                <button
                  type="button"
                  className="button btn-primary large"
                  onClick={handleYes}
                >
                  {'Apply'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);

const customCss = css``;
