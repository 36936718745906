import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import { IChapterRaw } from 'utils/models/transcript.model';
import { resetPlayer } from './player.slice';

const chapterSlice = createSlice({
  name: 'chapter',
  initialState: {
    chapter: [] as IChapterRaw[],
    historyChapter: {
      undo: [] as IChapterRaw[][],
      redo: [] as IChapterRaw[][]
    },
    isSplitCaption: false as boolean,
    isSplitParagraph: false as boolean,
    newTimeCode: -1 as number,
    currentChapterId: 0 as number,
  },
  reducers: {
    setChapterInitial: (state, action: PayloadAction<IChapterRaw[]>) => {
      state.chapter = action.payload?.map((c, id) => ({ id, ...c }));
    },
    setChapter: (state, action: PayloadAction<IChapterRaw[]>) => {
      state.historyChapter.undo.push(state.chapter);
      state.chapter = action.payload?.map((chapter, id) => ({ id, ...chapter }));
    },
    undoChapter: (state) => {
      if (state.historyChapter.undo.length > 0) {
        state.historyChapter.redo.push(state.chapter);
        state.chapter = state.historyChapter.undo.pop()!;
      }
    },
    redoChapter: (state) => {
      if (state.historyChapter.redo.length > 0) {
        state.historyChapter.undo.push(state.chapter);
        state.chapter = state.historyChapter.redo.pop()!;
      }
    },
    resetHistoryChapter: (state) => {
      state.historyChapter.redo = [];
      state.historyChapter.undo = [];
    },
    setNewTimeCode: (state, action) => {
      state.newTimeCode = action.payload;
      state.isSplitCaption = true;
      state.isSplitParagraph = true;
    },
    setSplitCaption: (state, action) => {
      state.isSplitCaption = action.payload;
    },
    setSplitParagraph: (state, action) => {
      state.isSplitParagraph = action.payload;
    },
    setCurrentChapterTime: (state, { payload }: PayloadAction<number>) => {
      const nearestChap = orderBy(state.chapter, (c) => c.startTime, [
        'desc',
      ]).find((chap) => payload >= chap.startTime);

      if (nearestChap?.id !== state.currentChapterId) {
        state.currentChapterId = nearestChap?.id ?? 0;
      }
    },
    setCurrentChapterId: (state, { payload }: PayloadAction<number>) => {
      state.currentChapterId = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetPlayer, (state) => {
      state.chapter = [];
    });
  },
});

export const {
  resetHistoryChapter,
  setChapterInitial,
  undoChapter,
  redoChapter,
  setChapter,
  setSplitCaption,
  setSplitParagraph,
  setNewTimeCode,
  setCurrentChapterTime,
  setCurrentChapterId
} = chapterSlice.actions;

export const chapterReducer = chapterSlice.reducer;
