/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { CollapsibleList } from '@rmwc/list';
import { Ripple } from '@rmwc/ripple';
import { ReactComponent as ExpandMore } from 'assets/Icons/expand_circle.svg';
import { ReactComponent as ExpandLess } from 'assets/Icons/expand_circle_up.svg';
import Loader from 'components/loader/loader';
import { first, isNil } from 'lodash';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffectOnce, useToggle } from 'react-use';
import { RootState } from 'reducers';
import { CollectionService } from 'services/collection.service';
import { ensureAdvanceIntegrationFormat } from 'utils/adapter.util';
import { ModalOptions } from 'utils/enum';
import { IntegrationSettingsPayload } from 'utils/models';
import { BasicConfirmModal } from 'utils/models/modal.model';
import { customToast } from 'utils/toast.util';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { LabelCheckbox } from '../LabelCheckbox';
import { SimpleCheckbox } from '../SimpleCheckbox';

type Props = {
  collectionIds: string[];
  hideModal: () => void;
};

export const AdvancedIntegrationSettings = ({
  collectionIds,
  hideModal,
}: Props) => {
  const collection = useSelector((state: RootState) => state.collection);
  const confirmModalRef = useRef<BasicConfirmModal>(null);

  const defaultConfigs = ensureAdvanceIntegrationFormat({});

  const [isOpenFilter, toggleOpenFilter] = useToggle(false);
  const [isLoading, toggleLoading] = useToggle(false);
  const [isApplyAll, toggleApplyAll] = useToggle(false);

  const [integrationSettings, setIntegrationSettings] =
    useState<IntegrationSettingsPayload>(defaultConfigs);
  const [currentIntegrationSettings, setCurrentIntegrationSettings] =
    useState<IntegrationSettingsPayload>(defaultConfigs);

  useEffectOnce(() => {
    handleGetIntegrationSetting(first(collectionIds));
  });

  const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    setIntegrationSettings((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleSaveIntegrationSetting = async () => {
    if (isApplyAll) {
      const result = await confirmModalRef.current?.show();

      if (result !== ModalOptions.YES) return;
    }

    try {
      toggleLoading(true);

      const listCollectionIds = isApplyAll
        ? collection.items.map((item) => item.id)
        : collectionIds;

      const saveConfigsAsync =
        CollectionService.saveCollectionIntegrationSetting({
          collectionIds: listCollectionIds,
          integrationSettings: {
            ...integrationSettings,
            isAutomatePushBack: currentIntegrationSettings.isAutomatePushBack,
          },
          isApplyAll,
        });

      customToast.promise(saveConfigsAsync, {
        loading: 'Saving...',
        success: 'Saved',
        error: 'Failed to save integration configuration',
      });

      await saveConfigsAsync;
    } catch (err) {
      console.log(err);
    } finally {
      toggleLoading(false);
    }
  };

  const handleGetIntegrationSetting = async (collectionId?: string) => {
    if (isNil(collectionId)) return;

    try {
      toggleLoading(true);
      const { data } = await CollectionService.getCollectionIntegrationSetting(
        collectionId,
      );

      setIntegrationSettings(data);
      setCurrentIntegrationSettings(data);
    } catch (err) {
      console.log(err);
    } finally {
      toggleLoading(false);
    }
  };

  const handleSaveAutomatePushBack = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, checked } = e.target;

    setCurrentIntegrationSettings((prev) => ({
      ...prev,
      [name]: checked,
    }));

    try {
      toggleLoading(true);

      const saveConfigsAsync =
        CollectionService.saveCollectionIntegrationSetting({
          collectionIds: collectionIds,
          integrationSettings: {
            ...currentIntegrationSettings,
            isAutomatePushBack: checked,
          },
          isApplyAll: false,
        });

      customToast.promise(saveConfigsAsync, {
        loading: 'Saving...',
        success: 'Saved',
        error: 'Failed to save integration configuration',
      });

      await saveConfigsAsync;
    } catch (err) {
      console.log(err);
    } finally {
      toggleLoading(false);
    }
  };

  return (
    <div tw="flex items-center text-16 gap-x-2 cursor-pointer justify-end py-1.5 rounded overflow-hidden">
      {isLoading && (
        <div tw="flex justify-center absolute top[0] left[0] w-full items-center h-full opacity-[0.95] z-[1] bg-white rounded">
          <Loader />
        </div>
      )}

      <CollapsibleList
        handle={
          <div tw="flex justify-between items-center">
            <div tw="flex">
              <SimpleCheckbox
                name="isAutomatePushBack"
                checked={currentIntegrationSettings?.isAutomatePushBack}
                onChange={handleSaveAutomatePushBack}
                label="Automate integration back to CMS"
              />
            </div>

            <Ripple
              tw="py-3 pl-2.5 pr-3 rounded w-[190px]"
              onClick={toggleOpenFilter}
            >
              <div tw="inline-flex space-x-2.5 items-center cursor-pointer">
                {isOpenFilter ? (
                  <ExpandLess fill="#7f8090" />
                ) : (
                  <ExpandMore fill="#7f8090" />
                )}
                <div tw="flex flex-nowrap text-16 font-medium text-sonnant-grey-6 opacity-95 select-none">
                  Advanced settings
                </div>
              </div>
            </Ripple>
          </div>
        }
        open={isOpenFilter}
      >
        <div>
          <h1 tw="font-semibold text-sonnant-dark font-size[2.4rem]">
            Advanced Integrate Settings
          </h1>

          <div tw="flex justify-evenly rounded border[1px solid] border-sonnant-grey-light mt-4 mb-6 py-8">
            <div tw="flex flex-col gap-5">
              <div tw="flex">
                <SimpleCheckbox
                  name="hasMonetisation"
                  checked={integrationSettings?.hasMonetisation}
                  onChange={handleCheckboxClick}
                  label="Monetisation"
                />
              </div>
              <div tw="flex">
                <SimpleCheckbox
                  name="hasTags"
                  checked={integrationSettings?.hasTags}
                  onChange={handleCheckboxClick}
                  label="Tags"
                />
              </div>
            </div>

            <div tw="flex flex-col gap-5">
              <div tw="flex">
                <SimpleCheckbox
                  name="hasChapters"
                  checked={integrationSettings?.hasChapters}
                  onChange={handleCheckboxClick}
                  label="Chapters"
                />
              </div>
              <div tw="flex">
                <SimpleCheckbox
                  name="hasSummary"
                  checked={integrationSettings?.hasSummary}
                  onChange={handleCheckboxClick}
                  label="Description/Summary"
                />
              </div>
            </div>
          </div>

          <div tw="mt-5 flex justify-between">
            <div tw="flex gap-x-4">
              <button
                type="button"
                className="button btn-secondary large apply"
                tw="mb-0"
                onClick={() => {
                  hideModal();
                  setIntegrationSettings(defaultConfigs);
                }}
              >
                Cancel
              </button>
            </div>

            <div tw="flex items-center gap-x-5">
              <div>
                <LabelCheckbox
                  checked={isApplyAll}
                  onChange={toggleApplyAll}
                  label="Apply to all collections"
                />
              </div>

              <button
                type="button"
                className="button btn-primary large cancel"
                onClick={handleSaveIntegrationSetting}
                tw="mb-0"
                disabled={false}
              >
                Save
              </button>
            </div>
          </div>

          <ConfirmModal
            ref={confirmModalRef}
            title="Apply to all collections"
            message={
              <div>
                <div>
                  This action will override any configuration setup done for
                  other collections.
                </div>
                <strong>Do you wish to continue?</strong>
              </div>
            }
          />
        </div>
      </CollapsibleList>
    </div>
  );
};
