/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { Info } from 'components/shared/Info';
import { SortTable } from 'components/shared/SortTable/SortTable';
import { ReactComponent as RevokeKeySvg } from 'assets/Icons/revoke.svg';
import { ReactComponent as ApiKeySvg } from 'assets/Icons/key.svg';
import { ReactComponent as CopySvg } from 'assets/Icons/copy.svg';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { toDateApiKey } from 'utils/date.util';
import { hoverDropShadow } from 'components/shared/twin.styles';
import { Hint } from 'components/shared/Hint';
import {
  appendApiKey,
  removeApiKey,
  setApiKeyList,
} from 'slices/api-key.slice';
import {
  SingleNameCreationModalRef,
  SingleNameCreationModal,
} from 'components/shared/SingleNameCreationModal/SingleNameCreationModal';
import { useRef } from 'react';
import { customToast } from 'utils/toast.util';
import { ApiKeyService } from 'services/api-keys.service';
import { useCopyToClipboard, useMount, useToggle } from 'react-use';
import Loader from 'components/loader/loader';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { ModalOptions } from 'utils/enum';
import { Column } from 'react-table';
import { ApiKey } from 'utils/models';
import { BasicConfirmModal } from 'utils/models/modal.model';

type Props = {};

export const ApiTab = (props: Props) => {
  const dispatch = useDispatch();

  const [, copyToClipboard] = useCopyToClipboard();

  const [isLoading, toggleLoading] = useToggle(true);

  const apiKey = useSelector((state: RootState) => state.apiKey);

  const confirmDeleteModalRef = useRef<BasicConfirmModal>(null);
  const singleNameCreationModal = useRef<SingleNameCreationModalRef>(null);

  useMount(async () => {
    try {
      toggleLoading(true);
      const { data: response } = await ApiKeyService.getApiKeys();

      dispatch(setApiKeyList(response.data));
    } catch (err: any) {
      console.log('err :>> ', err);
    } finally {
      toggleLoading(false);
    }
  });

  const handleAdd = async () => {
    const result = await singleNameCreationModal.current?.show();

    if (result?.option !== ModalOptions.YES) return;

    const apiKeyName = result?.singleName?.trim() ?? '';

    const createApiKeyAsync = ApiKeyService.createNewApiKey(apiKeyName);

    customToast.promise(createApiKeyAsync, {
      loading: 'Generating API key...',
      success: 'API key generated',
      error: 'Failed to generate API key',
    });

    try {
      const { data: newApiKey } = await createApiKeyAsync;

      // Sync data with redux slice
      dispatch(appendApiKey(newApiKey.data));
    } catch (err: any) {
      console.log('err :>> ', err);
    }
  };

  const handleRemove = async (apiKey: string) => {
    const result = await confirmDeleteModalRef.current?.show();

    if (result !== ModalOptions.YES) return;

    try {
      const removeApiKeyAsync = ApiKeyService.revokeApiKey(apiKey);

      customToast.promise(removeApiKeyAsync, {
        loading: 'Deleting API key...',
        success: 'API key deleted',
        error: 'Failed to delete API key',
      });

      await removeApiKeyAsync;

      dispatch(removeApiKey(apiKey));
    } catch (err: any) {
      console.log('Revoking API Key error :>> ', err);
    }
  };

  const handleCopyKey = (apiKey: string) => {
    if (!apiKey) return;

    copyToClipboard(apiKey);
    customToast.success('Copied to clipboard');
  };

  return (
    <div tw="p-7">
      {isLoading && (
        <div
          className="loader__component"
          tw="height[calc(100vh - 13rem)] top[13rem] opacity-100"
        >
          <Loader />
        </div>
      )}

      <button
        className="button btn-primary large"
        tw="flex items-center (max-width[unset] w-auto px-3.5)!"
        onClick={handleAdd}
      >
        <span tw="flex mr-2">
          <ApiKeySvg />
        </span>
        Add API key
      </button>

      <div tw="mt-6" css={[customTableStyle]}>
        <SortTable
          columns={[
            { Header: 'Name', accessor: 'name' },
            { Header: 'Truncated Key', accessor: 'api_key' },
            { Header: 'Created Date', accessor: 'created_at' },
            {
              Header: 'Delete',
              id: '',
              styles: {
                textAlign: 'center',
                maxWidth: '2rem',
              },
            } as Column<ApiKey>,
          ]}
          data={apiKey.items}
        >
          {({ original: row }) => (
            <tr>
              <td tw="font-medium">{row?.name}</td>
              <td tw="font-medium">
                <span tw="flex">
                  <Hint text={row?.api_key} arrow notTransparent>
                    <span tw="cursor-pointer font-mono">
                      ...{row?.api_key?.slice(-8)}
                    </span>
                  </Hint>
                  <Hint text="Copy to clipboard" arrow leaveDelay={0}>
                    <span
                      tw="(flex items-center space-x-1 text-13 color[#1779ba] ml-6 cursor-pointer hover:underline)!"
                      onClick={() => handleCopyKey(row?.api_key)}
                    >
                      <CopySvg />
                      <span>copy</span>
                    </span>
                  </Hint>
                </span>
              </td>
              <td>{toDateApiKey(row.created_at)}</td>
              <td>
                <div tw="flex w-full justify-center">
                  <Hint text="Remove API key">
                    <RevokeKeySvg
                      css={[hoverDropShadow(0.2)]}
                      onClick={() => handleRemove(row.api_key)}
                    />
                  </Hint>
                </div>
              </td>
            </tr>
          )}
        </SortTable>

        {isEmpty(apiKey.items) && (
          <div tw="ml-4">
            <Info text="Nothing to show" />
          </div>
        )}
      </div>

      <SingleNameCreationModal
        ref={singleNameCreationModal}
        title="New API key"
        label="Name"
        placeHolder="Give API key a name..."
        confirmText="Create API key"
      />

      <ConfirmModal
        ref={confirmDeleteModalRef}
        message={
          <div>
            Removing an API Key will stop any programmatic access to the{' '}
            <b>Sonnant App</b> that uses this key.
          </div>
        }
        title="Remove API key"
        confirmText="Confirm"
      />
    </div>
  );
};

const customTableStyle = css`
  table tr th {
    ${tw`(text-sonnant-grey-6 font-medium)!`}
  }

  table tr th,
  td {
    ${tw`(text-15)!`}
  }
`;
