/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as PlaySvg } from 'assets/Icons/fPlay.svg';
import { ReactComponent as PauseSvg } from 'assets/Icons/fPause.svg';

import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { getLatestTitle } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { hoverDropShadow, lineClamp } from 'components/shared/twin.styles';
import SonnantLogo from 'components/UiControls/homeSVG/sonnantlogo';
import { useEmbedContext } from '../../utils/useEmbedContext';
import { formateImageURL } from 'utils/utils';
import { isNil } from 'lodash';

type Props = {};

export const EmbedTitleBar = (props: Props) => {
  const { controls, videoState } = useEmbedContext();

  const media = useSelector((state: RootState) => state.media);

  const togglePlay = () => {
    videoState.playing ? controls.pause() : controls.play();
  };

  const getEmbedLogo = (image: string | undefined) => {
    const isValidImage = !isNil(image) && image !== 'NULL' && image !== '';
    const shouldUseCustomLogo = Boolean(media?.metadata?.logo) && isValidImage;

    return shouldUseCustomLogo ? (
      <img
        alt="Uploaded Logo"
        tw="h-[5rem] w-[5rem] min-w-[5rem] rounded-full shadow-lg object-contain"
        src={formateImageURL(image)}
      />
    ) : (
      <a tw="flex" href="https://sonnant.com" target="_blank" rel="noreferrer">
        <SonnantLogo />
      </a>
    );
  };

  return (
    <div tw="flex items-center justify-between">
      <div tw="w-full flex items-center gap-x-3">
        <div tw="flex overflow-hidden rounded-full border[1px solid] border-sonnant-grey-light hover:(animate-pulse) transition[all 0.3s ease-in-out] cursor-pointer min-w-[5rem]">
          {videoState.playing ? (
            <PauseSvg
              onClick={togglePlay}
              tw="w-[5rem] h-[5rem] bg-sonnant-purple-2 fill-[white]"
            />
          ) : (
            <PlaySvg onClick={togglePlay} />
          )}
        </div>

        <div tw="flex-1">
          <div
            tw="text-15 font-normal opacity-90"
            css={[lineClamp(1)]}
            title={getLatestTitle(media.metadata)}
          >
            {getLatestTitle(media.metadata)}
          </div>

          <div
            tw="text-16 font-medium"
            css={[lineClamp(1)]}
            title={media.versionname}
          >
            {media.versionname}
          </div>
        </div>

        <div tw="cursor-pointer ml-2" css={hoverDropShadow(0.2)}>
          {getEmbedLogo(media?.metadata?.tenantLogo)}
        </div>
      </div>
    </div>
  );
};
