/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { IabReportTable } from './IabReportTable';
import { RootState } from 'reducers';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isSensitiveIabId } from '../../MediaUtilities';

type Props = {};

export const GarmReportLayer = (props: Props) => {
  const iab = useSelector((state: RootState) => state.iab);

  const garmItems = useMemo(
    () => iab.lockedList?.filter((item) => !isEmpty(item?.severityLevel)),
    [iab],
  );

  const sensitiveItems = useMemo(
    () =>
      iab.lockedList?.filter(
        (item) => isSensitiveIabId(item.id) || isSensitiveIabId(item?.parentId),
      ),
    [iab],
  );

  return (
    <div>
      <div tw="py-5 px-3">
        <div tw="font-medium mb-5 text-sonnant-blue font-size[2.2rem]">
          Summary
        </div>

        <IabReportTable tableLabel="GARM Report" iabItems={garmItems} />

        <IabReportTable
          tableLabel="IAB Sensitive Topic Report"
          iabItems={sensitiveItems}
          isShowRelevance
        />
      </div>
    </div>
  );
};
