import * as actionType from '../actions/types';
import { put, takeLatest } from 'redux-saga/effects';
import { toggleInPageLogin } from 'slices/global.slice';
import { isTranscriptionPage } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { AxiosResponse } from 'axios';
import { MediaService } from 'services';

export const initPublishedVersionLibrary = function* (action: any) {
  try {
    const response: AxiosResponse = yield MediaService.getPublishVersion({
      mediaid: action.mediaid,
    });
    yield put({
      type: actionType.SET_PUBLISHED_VERSION_LIBRARY,
      data: response,
    });
  } catch (error: any) {
    yield put({
      type: actionType.FAILED_PUBLISHED_VERSION_LIBRARY,
      payload: error.response,
    });

    if ([401, 403].includes(error?.response?.status) && isTranscriptionPage()) {
      yield put(toggleInPageLogin(action?.payload));
    }
  }
};

export default function* WatchInitPublishedVersionLibrary() {
  yield takeLatest(
    actionType.INIT_PUBLISHED_VERSION_LIBRARY_WATCH,
    initPublishedVersionLibrary,
  );
}
