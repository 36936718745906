/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { useQueryGeneratedTermInsight } from 'hooks/queries/useQueryGeneratedTermInsight';
import { useRef } from 'react';
import { InsightService } from 'services/insight.service';
import { ModalOptions } from 'utils/enum';
import { BasicConfirmModal } from 'utils/models/modal.model';
import { customToast } from 'utils/toast.util';
import { ChartPinTitle } from '../shared/ChartPinTitle';
import { TermExplorationChartItem } from './TermExplorationChartItem';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { removeGeneratedChart } from 'slices/term-exploration.slice';
import { useToggle } from 'react-use';
import { getInsightLayerName } from 'utils/adapter.util';
import { getTermExplorationChartTitle } from 'components/VideoPlayer/Transcription/MediaUtilities';

type Props = {};

export const TermExplorationChartList = (props: Props) => {
  const dispatch = useDispatch();

  const termExploration = useSelector(
    (state: RootState) => state.termExploration,
  );
  const generatedCharts = termExploration.generatedCharts;

  const { refetch, isFetching: isFetchingGeneratedTerms } =
    useQueryGeneratedTermInsight();

  const [isUnpinning, toggleUnpinning] = useToggle(false);

  const shouldBlockUnpin = isUnpinning || isFetchingGeneratedTerms;

  const confirmModalRef = useRef<BasicConfirmModal>();

  const handleUnpin = async (generatedChartId: string) => {
    if (isEmpty(generatedChartId)) return;

    const result = await confirmModalRef.current?.show();

    if (result !== ModalOptions.YES) return;

    const deleteChartAsync = InsightService.deleteContentGeneratedChart([
      generatedChartId,
    ]);

    customToast.promise(deleteChartAsync, {
      loading: 'Deleting',
      success: 'Done',
      error: 'Failed to remove pinned item',
    });

    try {
      // Temporarily remove from UI
      dispatch(removeGeneratedChart(generatedChartId));
      toggleUnpinning(true);

      await deleteChartAsync;

      refetch();
    } catch (error: any) {
      console.log('error :>> ', error);
    } finally {
      toggleUnpinning(false);
    }
  };

  return (
    <div tw="flex flex-wrap gap-6">
      {generatedCharts.map((generatedTerm) => {
        const chartLayer =
          generatedTerm?.related_layer &&
          getInsightLayerName(generatedTerm.related_layer);

        const chartTitle = getTermExplorationChartTitle(
          generatedTerm.key_term,
          chartLayer,
        );

        return (
          <div key={generatedTerm.id} tw="flex-1 flex flex-col">
            <ChartPinTitle
              title={chartTitle}
              onClickUnpin={() => handleUnpin(generatedTerm.id)}
              disabledUnpin={generatedTerm.isPlaceholder || shouldBlockUnpin}
            />

            <div tw="flex items-start justify-center flex-1 mt-3 relative w-full rounded border[1px solid] border-sonnant-grey-light shadow p-5">
              <TermExplorationChartItem generatedTerm={generatedTerm} />
            </div>
          </div>
        );
      })}

      <ConfirmModal
        title="Unpin this chart"
        message={
          <div>
            This action cannot be undone. <b>Would you like to proceed?</b>
          </div>
        }
        confirmText="Unpin"
        ref={confirmModalRef}
      />
    </div>
  );
};
