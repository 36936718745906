/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { ReactComponent as DownloadSvg } from 'assets/Icons/download_insight.svg';
import { ReactComponent as EditSvg } from 'assets/Icons/edit_insight.svg';

import {
  getTermExplorationChartTitle,
  saveHtmlToPngAsync,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { HintDisallowed } from 'components/shared/HintDisallowed';
import { DonutChart } from 'components/shared/InsightChart/DonutChart';
import { WordCloudChart } from 'components/shared/InsightChart/WordCloudChart';
import { NoResultInsights } from 'components/shared/NoResultInsights';
import {
  roundedRippleHoverCss,
  simpleMenuDisabled,
} from 'components/shared/twin.styles';
import { isEmpty, isNil, keys, take } from 'lodash';
import React, { useRef } from 'react';
import { CHART_ITEM_COUNT } from 'utils/constants';
import { getDateExport } from 'utils/date.util';
import { Insights, SeriesKeyValue } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { getLayerName, getSubdomainFromURL } from 'utils/utils';
import { AdvertisingChartType, Layers } from 'utils/enum';
import { useDispatch } from 'react-redux';
import { handleSearchClear, setSelectedRows } from 'actions';
import { resetAdvancedSearch } from 'slices/advanced-search.slice';
import { ChartPinTitle } from '../shared/ChartPinTitle';

type Props = {
  seriesKeyList: SeriesKeyValue | undefined;
  chartType: AdvertisingChartType;
  currentLayer: Layers;
  onClickPie: (searchTerm: string, searchField: keyof Insights) => void;

  editable?: boolean;
  onClickEdit?: () => void;
  isTermExploration?: boolean;
};

export const ExplorationChartViewer = ({
  seriesKeyList,
  chartType,
  currentLayer,
  isTermExploration = false,
  ...props
}: Props) => {
  const dispatch = useDispatch();

  const isWordCloudChart = chartType === 'wordcloud';

  const isIabLayers = [Layers.IAB, Layers.GARM_REPORT].includes(currentLayer);

  const canvasRef = useRef<HTMLDivElement>(null);

  const chartLayer = getLayerName(currentLayer);

  const chartTitle = getTermExplorationChartTitle(
    keys(seriesKeyList)?.[0] ?? '',
    chartLayer,
  );

  const handleClickDownload = async () => {
    const chartName = isTermExploration
      ? chartTitle
      : `Content Exploration - ${chartLayer}`;

    customToast.loading('Exporting...');

    const chartWrapperElement =
      canvasRef.current?.querySelector<HTMLElement>('div:first-child');

    if (!chartWrapperElement) return;

    try {
      const fileName = `[${getSubdomainFromURL()}] ${chartName} - ${getDateExport()}`;

      await saveHtmlToPngAsync(
        chartWrapperElement,
        fileName,
        preprocessCanvasExport,
      );

      customToast.success('Image exported successfully');
    } catch (error) {
      customToast.error('Failed to export');
    }
  };

  const preprocessTermAlias = (
    buckets: [string, number, string][],
  ): [string, number, string][] => {
    if (isEmpty(buckets)) return [];

    const visibleTerms = take(buckets, CHART_ITEM_COUNT);

    return visibleTerms;
  };

  if (isEmpty(seriesKeyList) || isNil(seriesKeyList))
    return (
      <div tw="min-h-[40rem] w-full flex items-center my-3 bg-white">
        <NoResultInsights />
      </div>
    );

  const handleClickPie = (searchTerm: string, searchField: keyof Insights) => {
    // Default actions each clicking pie
    dispatch(handleSearchClear());
    dispatch(resetAdvancedSearch());
    dispatch(setSelectedRows([]));

    props.onClickPie(searchTerm, searchField);
  };

  const preprocessCanvasExport = (document: Document) => {
    if (!isTermExploration) return;

    const hiddenTitles = document.querySelectorAll<HTMLElement>(
      '.chart-title-hidden-html2canvas',
    );

    if (isEmpty(hiddenTitles)) return;

    hiddenTitles.forEach((hiddenTitle) => {
      hiddenTitle.style.display = 'block';
    });

    // Donut chart only
    if (!isWordCloudChart) {
      const chartExplorationContainers = document.querySelectorAll<HTMLElement>(
        '.chart-exploration-html2canvas-container',
      );

      if (isEmpty(chartExplorationContainers)) return;

      chartExplorationContainers.forEach((chartExplorationContainer) => {
        chartExplorationContainer.style.width = '80rem';
      });
    }
  };

  return (
    <div tw="h-full">
      {Object.entries(seriesKeyList).map(([key, buckets]) => {
        const values = preprocessTermAlias(buckets);

        return (
          <div
            ref={canvasRef}
            key={key}
            tw="h-full flex-1 flex items-center justify-center pt-8 cursor-pointer relative"
          >
            <div
              tw="w-full h-full flex justify-center items-center flex-col"
              className="chart-exploration-html2canvas-container"
            >
              <div
                tw="w-full pl-8 py-4 hidden"
                className="chart-title-hidden-html2canvas"
              >
                <ChartPinTitle title={chartTitle} onClickUnpin={() => {}} />
              </div>

              {isWordCloudChart ? (
                <div tw="h-full w-full flex justify-center items-center rounded border[1px solid] border-sonnant-grey-light">
                  <WordCloudChart
                    words={values.map(([text, value, original]) => ({
                      text,
                      value,
                      original,
                    }))}
                    onWordClick={(original) =>
                      handleClickPie(original, key as keyof Insights)
                    }
                  />
                </div>
              ) : (
                <DonutChart
                  series={values.map(([key, value]) => value)}
                  type="donut"
                  width={800}
                  optionKey={key}
                  optionValue={values}
                  handleClickPie={handleClickPie}
                  hideTitle={isTermExploration}
                />
              )}
            </div>

            <div
              tw="absolute right-0 top-0 flex flex-row gap-x-5 pt-[1.6rem] pr-[1rem] items-center"
              css={[isWordCloudChart && tw`pt-[2.5rem]`]}
            >
              <HintDisallowed
                disabled={isEmpty(values)}
                hintEnabled="Download as PNG"
                hintDisabled="No data to export"
              >
                <div
                  tw="flex items-center justify-center"
                  css={[
                    roundedRippleHoverCss,
                    isEmpty(values) && simpleMenuDisabled,
                  ]}
                  onClick={handleClickDownload}
                >
                  <DownloadSvg css={downloadIconStyle} />
                </div>
              </HintDisallowed>

              {props.editable && (
                <HintDisallowed
                  disabled={isIabLayers}
                  hintEnabled="Edit term and alias"
                  hintDisabled="IAB layers are not allowed for editing"
                >
                  <div
                    css={[
                      roundedRippleHoverCss,
                      isIabLayers && simpleMenuDisabled,
                    ]}
                    tw="outline[2px solid #7f8090] rounded-full flex items-center justify-center w-[2.6rem] h-[2.6rem]"
                    onClick={props.onClickEdit}
                  >
                    <EditSvg css={editIconStyle} />
                  </div>
                </HintDisallowed>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const downloadIconStyle = css`
  fill: #7f8090;
  width: 3rem;
  height: 3rem;
`;

const editIconStyle = css`
  fill: #7f8090;
  width: 2rem;
  height: 2rem;
`;
