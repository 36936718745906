/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import {
  createStore,
  applyMiddleware,
  compose,
  AnyAction,
  Dispatch,
} from 'redux';
import { Provider, useDispatch } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import RootSaga from '../sagas';
import { rootReducer } from '../reducers';
import { ThunkDispatch, getDefaultMiddleware } from '@reduxjs/toolkit';

const sagaMiddleware = createSagaMiddleware();

// Disable log for Production build
const allowedENVs = ['dev', 'devLocal', 'stage', 'stageLocal'];

const composeEnhancers =
  (allowedENVs.includes(process.env.REACT_APP_STAGE!) &&
    typeof window !== 'undefined' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const enhancer = composeEnhancers(
  applyMiddleware(
    ...getDefaultMiddleware({
      thunk: true,
      immutableCheck: false,
      serializableCheck: false,
    }).concat(sagaMiddleware),
  ),
);

export const rootStore = createStore(rootReducer, enhancer);

export default function ({ children, initialState = {} }: any) {
  const store = <Provider store={rootStore}>{children}</Provider>;

  sagaMiddleware.run(RootSaga);
  return store;
}

type AppDispatch = ThunkDispatch<any, undefined, AnyAction> & Dispatch<any>;

export const useAppDispatch: () => AppDispatch = useDispatch;
