import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import { EmbedParams } from 'utils/models';

type EmbedExportOption = {
  allowMediaDownload: boolean;
  allowCaptionDownload: boolean;
  allowTranscriptDownload: boolean;
  haveMp3: boolean;
  haveMp4: boolean;
  isBurnedIn: boolean;
};

type EmbedState = {
  isOpenShare: boolean;
  isOpenDownload: boolean;
  exportOption: EmbedExportOption;
  embedParams: EmbedParams;
  publicShareUrl: string;
  focusTerm: EmbedFocusTerm | null;
};

export type EmbedFocusTerm = {
  name: string;
  occurrences: number[];
  currentIndex: number;
};

const initialState: EmbedState = {
  isOpenShare: false,
  isOpenDownload: false,

  exportOption: {
    allowMediaDownload: true,
    allowTranscriptDownload: true,
    allowCaptionDownload: false,
    haveMp3: false,
    haveMp4: false,
    isBurnedIn: false,
  },

  embedParams: {
    hasSummary: false,
    hasTranscript: false,
    hasPlayer: false,
    hasKeyTerms: false,
    hasExport: false,
    hasShare: false,
    hasLogo: false,
  },
  publicShareUrl: '',
  focusTerm: null,
};

export const embedSlice = createSlice({
  name: 'embed',
  initialState,
  reducers: {
    toggleOpenShare: (state, action: PayloadAction<boolean | undefined>) => {
      if (typeof action?.payload !== 'boolean') {
        state.isOpenShare = !state.isOpenShare;
      } else {
        state.isOpenShare = action.payload;
      }
    },
    toggleOpenDownload: (state, action: PayloadAction<boolean | undefined>) => {
      if (typeof action?.payload !== 'boolean') {
        state.isOpenDownload = !state.isOpenDownload;
      } else {
        state.isOpenDownload = action.payload;
      }
    },
    setEmbedExportOptions: (
      state,
      action: PayloadAction<EmbedExportOption>,
    ) => {
      state.exportOption = action.payload;
    },
    setEmbedParams: (state, action: PayloadAction<EmbedParams>) => {
      state.embedParams = action.payload;
    },
    setPublicShareUrl: (
      state,
      { payload }: PayloadAction<EmbedState['publicShareUrl']>,
    ) => {
      state.publicShareUrl = payload;
    },
    setEmbedFocusTerm: (
      state,
      { payload }: PayloadAction<Omit<EmbedFocusTerm, 'currentIndex'>>,
    ) => {
      state.focusTerm = { ...payload, currentIndex: 0 };
    },
    setEmbedFocusTermIndex: (
      state,
      { payload: index }: PayloadAction<number>,
    ) => {
      if (!state.focusTerm || isNil(index)) return;

      state.focusTerm.currentIndex = index;
    },
    resetEmbedFocusTerm: (state) => {
      state.focusTerm = null;
    },
  },
});

export const {
  toggleOpenShare,
  toggleOpenDownload,
  setEmbedExportOptions,
  setEmbedParams,
  setPublicShareUrl,
  setEmbedFocusTerm,
  resetEmbedFocusTerm,
  setEmbedFocusTermIndex,
} = embedSlice.actions;

export const embedReducer = embedSlice.reducer;
