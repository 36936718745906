/** @jsxImportSource @emotion/react */
import 'twin.macro';

import React from 'react';
import {
  DeepMap,
  FieldError,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';
import { v4 } from 'uuid';

type Props<TFormValues extends FieldValues> = {
  label: string;
  name: Path<TFormValues>;
  rules?: RegisterOptions;
  register?: UseFormRegister<TFormValues>;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'name'>;

export const CheckboxHookForm = <TFormValues extends Record<string, unknown>>({
  label,
  name,
  rules,
  register,
  ...props
}: Props<TFormValues>) => {
  const uuid = v4();

  return (
    <label htmlFor={uuid}>
      <input
        id={uuid}
        type="checkbox"
        {...props}
        {...(register && register(name, rules))}
      />
      <span className="checkmark" tw="top-[0.5rem]"></span>
      {label}
    </label>
  );
};
