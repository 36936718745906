import { BaseAPI } from 'apis/amazon';
import { AxiosResponse } from 'axios';
import { GetOmnyPreviewResponse } from './upload.service';

export type IntegrateOmnyPayload = {
  mediaid?: string;
};

export const OmnyService = {
  getProgramId: (
    apiKey: string,
  ): Promise<AxiosResponse<GetOmnyPreviewResponse>> => {
    return BaseAPI.get(`/upload/omny/preview`, {
      params: { apikey: apiKey },
    });
  },
};
