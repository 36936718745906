/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { ReactComponent as CancelIcon } from 'assets/Icons/cancel.svg';
import { ReactComponent as CopySvg } from 'assets/Icons/copy.svg';
import { hoverDropShadow } from 'components/shared/twin.styles';
import { RssSubscription } from 'utils/models';
import { toDateWithHours } from 'utils/date.util';
import { Hint } from 'components/shared/Hint';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { Switcher } from 'components/shared/Switcher';
import { toggleSubscribedRss } from 'slices/rss.slice';
import { CollectionDropdown } from 'components/shared/CollectionDropdown/CollectionDropdown';
import { RssService } from 'services/rss.service';
import { customToast } from 'utils/toast.util';
import { useCopyToClipboard } from 'react-use';

type SubscriptionItemProps = {
  order: number;
  item: RssSubscription;
  onDelete: (id?: string) => void;
};

export const RssSubscriptionItem = (props: SubscriptionItemProps) => {
  const { order, item, onDelete } = props;

  const dispatch = useDispatch();
  const [, copyToClipboard] = useCopyToClipboard();

  const location = useSelector(
    (state: RootState) => state.userProfile.location,
  );

  const rssStyles = {
    row: () => tw`[> td]:(px-10 py-6 font-medium text-sonnant-dark opacity-90)`,
  };

  const toggleSubscribed = () => {
    dispatch(toggleSubscribedRss(item.id));
  };

  const updateDestinationCollection = async (collectionId: string) => {
    try {
      const updateAsync = RssService.updateRssSubscription({
        subscription_id: item.id,
        subscribed: item?.subscribed ? '1' : '0',
        collection_id: collectionId || undefined,
      });

      customToast.promise(updateAsync, {
        loading: 'Updating...',
        success: 'RSS subscription updated successfully',
        error: 'RSS subscription update failed',
      });

      await updateAsync;
    } catch {
      customToast.error('Failed to update subscription');
    }
  };

  const handleCopyToClipboard = (text: string) => {
    if (!text) return;

    copyToClipboard(text);
    customToast.success('Copied to clipboard');
  };

  return (
    <tr css={rssStyles.row}>
      <td tw="(p-0 pl-2 text-center)!">{order}</td>
      <td title={item?.title}>{item?.title}</td>
      <td title={item?.rss_url}>
        <span
          tw="(relative top-[-2px] mr-2 items-center justify-center cursor-pointer [svg]:(m-0) [svg path]:(fill-[#7f8090]))!"
          onClick={() => handleCopyToClipboard(item?.rss_url)}
        >
          <Hint text="Copy to clipboard" enterDelay={100} leaveDelay={0}>
            <CopySvg css={[hoverDropShadow(0.2)]} />
          </Hint>
        </span>
        <span>{item?.rss_url}</span>
      </td>
      <td>{toDateWithHours(item?.updated_at, location)}</td>
      <td tw="text-center overflow-visible!">
        <CollectionDropdown
          defaultValue={item?.collection_id}
          onChange={updateDestinationCollection}
          isRssSubscription
          isMultiSelect={false}
        />
      </td>
      <td tw="text-center">
        <Switcher
          hasLabel={true}
          checked={!!item?.subscribed}
          color="#00AF7A"
          onCheckedChange={toggleSubscribed}
          centered
        />
      </td>
      <td>
        <Hint text="Delete subscription">
          <CancelIcon
            onClick={() => onDelete(item?.id)}
            tw="cursor-pointer"
            css={hoverDropShadow(0.2)}
            width={25}
            height={25}
            fill="red"
          />
        </Hint>
      </td>
    </tr>
  );
};
