/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import React, { useEffect } from 'react';
import { Notification } from '../../UiControls/notification/Notification';
import { ValidityCheck, invalidEmail, AlertBlock } from '../../../utils/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import Loader from '../../loader/loader';
import '../User/addEditUser.scss';
import { isEqual } from 'lodash';

interface IProps {
  handleSubmit: (e: React.FormEvent) => void;
  handleChange: (
    target:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => void;
  handleSupportSubmit: (e: React.FormEvent) => void;
  handleError: (property: string) => (e: React.FocusEvent) => void;
  handleClose: (property?: string) => unknown;
  handleAlert: () => void;
  handleWarningClose: (property?: string) => unknown;
  type: string;
  modalClosed: () => void;
  editMode: boolean;
  supportMode: boolean;
  error: Error;
  warning: Warning | any;
  userData: UserData;
  isSaving: boolean;
}

interface Error {
  firstnameError: boolean;
  lastnameError: boolean;
  emailError: boolean;
  userroleError: boolean;
  emailMessage: string;
}

interface UserData {
  firstname: string;
  lastname: string;
  email: string;
  role: string;
}

interface Warning {
  firstnameWarning: boolean;
  lastnameWarning: boolean;
  emailWarning: boolean;
}

const AddEditUser = ({
  handleChange,
  handleSubmit,
  handleSupportSubmit,
  handleError,
  handleClose,
  handleAlert,
  handleWarningClose,
  type,
  modalClosed,
  editMode,
  supportMode,
  error,
  warning,
  userData,
  isSaving,
}: IProps) => {
  // const loginReducer = useSelector((state: RootState) => state.login);
  const alerts = useSelector((state: RootState) => state.alerts);
  // const tier = useSelector(tierSelector);
  const userProfile = useSelector((state: RootState) => state.userProfile);

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        modalClosed();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const isCurrentUser = (user: any) => {
    return isEqual(user?.email, userProfile.email);
  };

  return (
    <div className="small addUserPopup" id="addUserPopup">
      {isSaving && (
        <div className="loader__component">
          <Loader />
        </div>
      )}
      <div className="grid-x grid-margin-x">
        <div className="cell grid-padding-x small-12">
          <h2>{type} User</h2>
          <div tw="my-6">
            {alerts.statusMessage && (
              <AlertBlock
                statusMessage={alerts.statusMessage}
                isSuccess={alerts.isSuccess}
                handleClose={handleAlert}
                hideClose={editMode || supportMode}
              />
            )}
          </div>
        </div>
      </div>
      {(editMode && alerts.statusMessage) ||
      (supportMode && alerts.statusMessage) ? (
        ''
      ) : (
        <div className="userInfo padding-2">
          <form onSubmit={supportMode ? handleSupportSubmit : handleSubmit}>
            <div className="grid-x grid-margin-x">
              <div className="cell grid-padding-x small-6">
                <label>
                  First Name
                  <input
                    type="text"
                    onChange={handleChange}
                    onBlur={handleError('firstnameError')}
                    name="firstname"
                    value={userData.firstname}
                    placeholder="First Name"
                    maxLength={50}
                  />
                  <Notification
                    name={'firstnameError'}
                    close={handleClose}
                    type={error.firstnameError ? 'error' : null}
                    message={'First Name is a mandatory field'}
                  />
                  <Notification
                    name={'firstnameWarning'}
                    close={handleWarningClose}
                    type={warning.firstnameWarning ? 'warning' : null}
                    message={'Maximum characters Entered'}
                  />
                </label>
              </div>
              <div className="cell grid-padding-x small-6">
                <label>
                  Last Name
                  <input
                    type="text"
                    onChange={handleChange}
                    onBlur={handleError('lastnameError')}
                    name="lastname"
                    value={userData.lastname}
                    placeholder="Last Name"
                    maxLength={50}
                  />
                  <Notification
                    name={'lastnameError'}
                    close={handleClose}
                    type={error.lastnameError ? 'error' : null}
                    message={'Last Name is a mandatory field'}
                  />
                  <Notification
                    name={'lastnameWarning'}
                    close={handleWarningClose}
                    type={warning.lastnameWarning ? 'warning' : null}
                    message={'Maximum characters Entered'}
                  />
                </label>
              </div>
            </div>
            <div className="grid-x grid-margin-x">
              <div className="cell grid-padding-x small-12">
                <label>
                  Email Address
                  <input
                    type="text"
                    name="email"
                    value={userData.email}
                    onBlur={handleError('emailError')}
                    onChange={handleChange}
                    placeholder="Email Address"
                    maxLength={100}
                    disabled={editMode || supportMode}
                  />
                  <Notification
                    name={'emailError'}
                    close={handleClose}
                    type={error.emailError ? 'error' : null}
                    message={error.emailMessage}
                  />
                  <Notification
                    name={'emailWarning'}
                    close={handleWarningClose}
                    type={warning.emailWarning ? 'warning' : null}
                    message={'Maximum characters Entered'}
                  />
                </label>
              </div>
            </div>
            <div className="grid-x grid-margin-x">
              <div className="cell grid-padding-x small-6">
                <label>
                  User Role
                  <select
                    value={userData.role}
                    name="role"
                    onChange={handleChange}
                    onBlur={handleError('userroleError')}
                    className="userrole"
                    disabled={isCurrentUser(userData)}
                    tw="border[2px solid transparent]"
                  >
                    {/* <option value="">Select</option> */}
                    <option value="Administrator">Administrator</option>
                    {!supportMode && <option value="User">User</option>}
                  </select>
                  <Notification
                    name={'userroleError'}
                    close={handleClose}
                    type={error.userroleError ? 'error' : null}
                    message="User Role is a mandatory field"
                  />
                </label>
              </div>
            </div>
            <div className="grid-x grid-margin-x rightAlign">
              <button
                type="button"
                className="button btn-secondary large cancel small-3 cell"
                onClick={modalClosed}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="button btn-primary  large apply small-3 cell"
                disabled={
                  ValidityCheck(
                    userData.firstname,
                    userData.lastname,
                    userData.email,
                    userData.role,
                  ) || invalidEmail(userData.email)
                }
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
export default AddEditUser;
