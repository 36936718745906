import { Waveform } from './media.model';
import { Descendant } from 'slate';
import { IKeyItem, IOccurrence } from './layers.model';
import { AdBreakItem, IABItemModel, LayerCustomTerm } from '.';
import { IMediaEntities } from 'components/shared/RichTextEditor/rich-text.model';

export interface ITranscript {
  formatVersion: string;
  language: string;
  companyName: 'Sonnant';
  mediaId: string;
  length: string;
  dateCreated: string | Date;
  captions: Descendant[];
  chapters: IChapterRaw[];
  entities: IMediaEntities;
  keywords: IKeyItem[];
  paragraphs: IParagraphRaw[];
  speakers?: ISpeakerRaw[];
  summary: string;
  waveform?: Waveform;
  customTerms?: LayerCustomTerm[];
  iabCategories: IABCategoryS3;
  ad_breaks?: AdBreakItem[] | null;
  detectedSegments?: SpecialSegmentRaw[] | null;
  monetisation?: {
    preRoll: boolean;
    postRoll: boolean;
    midRolls: number[];
  };
}

export interface IABCategoryS3 {
  lockedList: IABItemModel[];
  recommendedList: IABItemModel[];
}

export interface ISpeaker {
  speakerID: number | string;
  start: number;
  end: number;
  speaker: string | undefined;
  isSelected?: boolean;
}

export interface ISpeakerRaw {
  id: number;
  name: string;
  inUsed?: boolean;
  occurrences?: IOccurrence[];
}

export interface IParagraphRaw {
  startTime: number;
  endTime: number;
  speaker?: string;
  [type: string]: any;
}

export interface IChapterRaw {
  id?: number;
  startTime: number;
  endTime: number;
  headline: string;
}

export interface IParagraph {
  start: number;
  end: number;
}

export interface IStartEnd {
  start: number;
  end: number;
}

export enum SegmentType {
  AD_BREAK = 'bakedIn',
  TRAFFIC = 'traffic',
  MUSIC = 'music',
  USER_DEFINED = 'userDefined',
}

export type SpecialSegmentRaw = {
  start: number;
  end: number;
  type: SegmentType;
};

export type WithID = {
  id: string;
}

export type SpecialSegment = SpecialSegmentRaw & WithID;
