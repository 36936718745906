/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { ReactComponent as ShareSvg } from 'assets/Icons/embed/share.svg';
import { ReactComponent as DownloadSvg } from 'assets/Icons/embed/download.svg';
import { ReactComponent as BackSvg } from 'assets/Icons/embed/back10.svg';
import { ReactComponent as ForwardSvg } from 'assets/Icons/embed/forward10.svg';
import { roundedRippleHoverCss } from 'components/shared/twin.styles';
import { EmbedSharing } from '../EmbedSharing/EmbedSharing';
import { secToMin } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { EmbedDownload } from '../EmbedDownload/EmbedDownload';

import { EmbedMediaSpeedMenu } from './EmbedMediaSpeedMenu';
import { useDispatch } from 'react-redux';
import { toggleOpenDownload, toggleOpenShare } from 'slices/embed.slice';
import { useEmbedContext } from '../../utils/useEmbedContext';

const TIME_SKIP_SECOND = 10;

type Props = {};

export const EmbedToolkit = (props: Props) => {
  const dispatch = useDispatch();

  const { controls, videoState, videoRef } = useEmbedContext();

  const seekForward = () => {
    controls.seek(videoState.time + TIME_SKIP_SECOND);
  };

  const seekBackward = () => {
    controls.seek(videoState.time - TIME_SKIP_SECOND);
  };

  const handleChangePlaybackRate = (playbackRate: number) => {
    if (!videoRef.current?.playbackRate) return;

    videoRef.current.playbackRate = playbackRate;
  };

  const handleClickOpenShare = () => {
    dispatch(toggleOpenShare(true));
  };

  const handleClickOpenDownload = () => {
    dispatch(toggleOpenDownload(true));
  };

  return (
    <div>
      <div
        tw="flex items-end text-14 text-sonnant-grey-5 opacity[.95]"
        css={[embedCss]}
      >
        <div tw="flex justify-start font-medium w-[10rem]">
          {secToMin(videoState.time)} | {secToMin(videoState.duration)}
        </div>

        <div tw="flex-1 py-0.5 flex items-center justify-center gap-x-8 mb-[-4px]">
          <span
            css={[roundedRippleHoverCss]}
            tw="flex cursor-pointer"
            onClick={seekBackward}
          >
            <BackSvg />
          </span>

          <EmbedMediaSpeedMenu
            playbackSpeed={videoRef.current?.playbackRate}
            onChangePlaybackRate={handleChangePlaybackRate}
          />

          <span
            css={[roundedRippleHoverCss]}
            tw="flex cursor-pointer"
            onClick={seekForward}
          >
            <ForwardSvg />
          </span>
        </div>

        <div tw="flex justify-end gap-x-6 w-[10rem]">
          <span
            css={[roundedRippleHoverCss]}
            tw="flex cursor-pointer"
            onClick={handleClickOpenShare}
          >
            <ShareSvg tw="w-[3rem] h-[3rem]" />
          </span>
          <span
            css={[roundedRippleHoverCss]}
            tw="flex cursor-pointer"
            onClick={handleClickOpenDownload}
          >
            <DownloadSvg tw="w-[3rem] h-[3rem]" />
          </span>
        </div>
      </div>

      <EmbedSharing />

      <EmbedDownload />
    </div>
  );
};

const embedCss = css`
  svg {
    ${tw`fill[#7f8090]`}
  }
`;
