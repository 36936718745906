import { SagaAction } from 'utils/models';
import * as types from '../actions/types';

interface InitialStateType {
  loading: boolean;
}

const initialState = {
  loading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export const changePasswordReducer = (
  state: InitialStateType = initialState,
  action: SagaAction,
) => {
  switch (action.type) {
    case types.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case types.CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};
