import { UseQueryOptions, useQuery } from 'react-query';
import {
  GetAdvertisingProfileParams,
  InsightService,
} from 'services/insight.service';
import { Duration } from 'utils/constants';
import { AdvertisingProfile } from 'utils/models';
import { getTenantidFromIdToken } from 'utils/utils';

type Props = {
  params?: GetAdvertisingProfileParams;
  options?: UseQueryOptions<AdvertisingProfile>;
};

const tenantid = getTenantidFromIdToken();

const defaultParams: GetAdvertisingProfileParams = {
  collectionIds: [],
  tagIds: [],
  integrationIds: [],
};

export const useAdvertisingIabQuery = ({
  params = defaultParams,
  options,
}: Props) => {
  const queryFnAsync = async () => {
    const { data } = await InsightService.getAdvertisingProfileAsync(params);

    return data;
  };

  const queryResult = useQuery({
    queryKey: ['insight/advertising-profile', tenantid, params],
    queryFn: queryFnAsync,
    staleTime: Duration.minutes(5),
    cacheTime: Duration.seconds(3),
    retry: 3,
    ...options,
  });

  return queryResult;
};
