/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import React from 'react';

interface Props {
  label?: string;
  title?: string;
  hasLabel?: boolean;
  disabled?: boolean;
  color?: string;
  colorOff?: string;
  onLabel?: string;
  offLabel?: string;
  centered?: boolean;
  checked: boolean;
  onCheckedChange: (checked: boolean) => unknown;
  isSmall?: boolean;
}

export const Switcher = (props: Props) => {
  return (
    <div
      className={`btn-group btn-switch ${props?.disabled && 'disabled'}`}
      css={[
        switcherStyles(
          props?.isSmall,
          props?.color ?? '#19AFA7',
          props?.colorOff,
        ),
        props?.centered && tw`flex justify-center`,
        props?.disabled && tw`background[none]`,
      ]}
      title={props?.title ?? ''}
    >
      {props?.label && <span>{props.label}</span>}
      <label className="switch" tw="color[red] mb-0">
        <input
          type="checkbox"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const isOn = e.target.checked;
            props.onCheckedChange(isOn);
          }}
          checked={!!props?.checked}
          tw='min-w-[20rem]!'
        />
        <span className="slider round"></span>

        {props?.hasLabel && (
          <span
            tw="absolute top[4px] text-11 text-white font-light transition-all cursor-pointer"
            css={
              !!props?.checked === true
                ? tw`(left[9px] right[50%])!`
                : tw`(right[7px] left[50%])!`
            }
          >
            {!!props?.checked === true
              ? props.onLabel ?? 'ON'
              : props.offLabel ?? 'OFF'}
          </span>
        )}
      </label>
    </div>
  );
};

const switcherStyles = (
  isSmall?: boolean,
  colorOn?: string,
  colorOff?: string,
) => css`
  &.btn-switch {
    > span {
      font-size: 12px;
      text-align: center;
      margin-top: -12px;
      margin-bottom: -2px;
      display: block;
    }

    .switch {
      position: relative;
      display: block;
      width: ${isSmall ? '37px' : '54px'};
      height: ${!isSmall && '28px'};
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      /* background-color: rgba(127, 128, 144, 0.4); */
      -webkit-transition: 0.4s;
      transition: 0.4s;
      min-height: ${!isSmall && '28px'};
      height: ${isSmall && '21px'};
      margin: 0;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: ${isSmall ? '13px' : '16px'};
      width: ${isSmall ? '13px' : '16px'};
      left: ${isSmall ? '6px' : '8px'};
      bottom: ${isSmall ? '4px' : '6px'};
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: ${colorOn || 'rgba(85, 81, 255, 1)'};
    }

    .slider {
      background-color: ${colorOff || 'rgba(127, 128, 144, 0.4)'};
    }

    input:focus + .slider {
      box-shadow: 0 0 1px rgba(85, 81, 255, 0.4);
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(22px);
      -ms-transform: translateX(22px);
      transform: translateX(22px);
      left: ${isSmall && '-4px'};
    }

    .slider.round {
      border-radius: 28px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
`;
