import { AdMarkerItem } from './../utils/models/media.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 } from 'uuid';

const adMarkerSlice = createSlice({
  name: 'adMarker',
  initialState: {
    preRoll: false,
    midRolls: [] as AdMarkerItem[],
    postRoll: false,
  },
  reducers: {
    togglePreRoll: (state, action: PayloadAction<boolean>) => {
      state.preRoll = action.payload;
    },
    togglePostRoll: (state, action: PayloadAction<boolean>) => {
      state.postRoll = action.payload;
    },
    setAdMarker: (state, action: PayloadAction<any>) => {
      state.midRolls = action.payload;
    },
    setMidRollsBySeconds: (
      state,
      action: PayloadAction<{
        midRolls: number[];
        preRoll: boolean;
        postRoll: boolean;
        videoDuration: number;
      }>,
    ) => {
      const { midRolls, preRoll, postRoll, videoDuration } = action.payload;

      const newMidRolls = [...(midRolls ?? [])];

      if (preRoll) {
        newMidRolls.unshift(0);
      }

      if (postRoll) {
        newMidRolls.push(videoDuration);
      }

      state.preRoll = preRoll;
      state.midRolls = newMidRolls.map((second) => ({ id: v4(), second }));
      state.postRoll = postRoll;
    },
  },
});

export const {
  setAdMarker,
  setMidRollsBySeconds,
  togglePreRoll,
  togglePostRoll,
} = adMarkerSlice.actions;

export const adMarkerReducer = adMarkerSlice.reducer;
