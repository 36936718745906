/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

// import CompanyIcon from 'assets/Icons/company_name.png';
// import NameIcon from 'assets/Icons/name-signup-icon.png';
// import ArrowIcon from 'assets/Icons/start_free_trial_arrow.png';
// import MailImg from 'assets/Icons/username-login-icon.png';

import StartTrialPNG from 'assets/auth/start-trial.png';
import { textLink } from 'components/shared/twin.styles';
import { useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  sign_up_check_message,
  sign_up_fail_message,
  sign_up_success_message,
} from '../../utils/constants';
import {
  getDomainWithoutSubdomain,
  getEmailErrorMessage,
  invalidEmail,
  isEmptyOrSpaces,
  validateDomain,
  ValidityCheck,
} from '../../utils/utils';
import Loader from '../loader/loader';
import CloseSVG from '../UiControls/closeButtonSVG/closeSVG';
import { Notification } from '../UiControls/notification/Notification';
import { AuthService } from 'services';
import ReCaptchaComponent from 'utils/scripts/recaptchaComponent';

export const SignUpEmailAndPassword = () => {
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    ReCaptchaComponent.init();
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
  const [form, setForm] = useState<any>({
    companyname: '',
    subdomain: '',
    firstname: '',
    lastname: '',
    email: '',
  });
  const [error, setError] = useState<any>({
    companynameError: false,
    subdomainError: false,
    inValidDomain: false,
    firstnameError: false,
    lastnameError: false,
    emailError: false,
    information: true,
    success: true,
  });
  const [warning, setWarning] = useState<any>({
    companynameWarning: false,
    domainWarning: false,
    firstnameWarning: false,
    lastnameWarning: false,
    emailWarning: false,
    message: 'Maximum number of characters entered',
  });
  const [loading, setLoading] = useState<any>(false);
  const [check, setCheck] = useState<any>(false);

  const [focusCom, setfocusCom] = useState<any>(false);
  const [focusFirst, setfocusFirst] = useState<any>(false);
  const [focusLast, setfocusLast] = useState<any>(false);
  const [focusEmail, setfocusEmail] = useState<any>(false);
  const [focusEmailValid, setfocusEmailValid] = useState<any>(false);

  const hasFilledValue = [
    form?.companyname,
    form?.subdomain,
    form?.lastname,
    form?.firstname,
    form?.email,
  ].some((value) => !!value?.trim());

  const isInvalidForm = () =>
    ValidityCheck(
      form.companyname,
      form.subdomain,
      form.lastname,
      form.firstname,
      form.email,
      check,
    ) ||
    invalidEmail(form.email) ||
    validateDomain(form.subdomain);

  const onFocusCom = (e: any) => {
    setfocusCom(false);
  };

  const onFocusFirst = (e: any) => {
    setfocusFirst(false);
  };
  const onFocusLast = (e: any) => {
    setfocusLast(false);
  };
  const onFocusEmail = (e: any) => {
    setfocusEmail(false);
    setfocusEmailValid(false);
  };

  const onBlurCom = (e: any) => {
    if (form.companyname) setfocusCom(false);
    else setfocusCom(true);
  };
  const onBlurFirst = (e: any) => {
    if (form.firstname) setfocusFirst(false);
    else setfocusFirst(true);
  };
  const onBlurLast = (e: any) => {
    if (form.lastname) setfocusLast(false);
    else setfocusLast(true);
  };
  const onBlurEmail = (e: any) => {
    if (form.email) {
      const emailMessage = getEmailErrorMessage(form.email);
      if (!error.emailError) {
        setError({ emailMessage: emailMessage });
        setfocusEmailValid(true);
        setfocusEmail(false);
      } else {
        setfocusEmailValid(false);
        setfocusEmail(false);
      }
    } else {
      setfocusEmail(true);
    }
  };

  const onFinish = (e: any) => {
    e.preventDefault();
    if (hasFormErrors()) return;

    setLoading(true);
    let { subdomain } = form;
    subdomain = subdomain
      .replace('.voice-intellect.com', '')
      .replace('.sonnant.ai', '')
      .replace('tharunagarwal.com', '');

    AuthService.register({ ...form, subdomain })
      .then((res) => {
        setError((preValue: any) => ({
          ...preValue,
          apiError: false,
          apiErrorMessage: '',
          apiSuccess: true,
          apiSuccessMessage: res.data,
        }));
        setLoading(false);
        setForm((preValue: any) => ({
          ...preValue,
          companyname: '',
          subdomain: '',
          firstname: '',
          lastname: '',
          email: '',
        }));
        history.push('/message', {
          name: 'Sign up',
          type: 'successful',
          message: sign_up_success_message,
          checkmessage: sign_up_check_message,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 500) {
          setError((preValue: any) => ({
            ...preValue,
            apiError: true,
            apiErrorMessage:
              'A Sonnant Library of the same name already exists. Please ensure you select a unique Sonnant Domain.',
          }));
        } else if (err.response) {
          setError((preValue: any) => ({
            ...preValue,
            apiError: true,
            apiErrorMessage: err.response.data,
          }));
        } else {
          history.push('/message', {
            name: 'Sign up',
            type: 'unsuccessful',
            message: sign_up_fail_message,
            route: '/signup',
          });
        }
        setLoading(false);
      });
  };

  const handleChange = (property: any) => (e: any) => {
    let letters = /^[A-Za-z0-9]+$/;
    let value = e.target.value;
    if (property !== 'domain' && property !== 'email' && value.length === 50) {
      setWarning((preValue: any) => ({
        ...preValue,
        [property + 'Warning']: true,
      }));
    } else if (
      (property === 'domain' || property === 'email') &&
      value.length === 100
    ) {
      setWarning((preValue: any) => ({
        ...preValue,
        [property + 'Warning']: true,
      }));
    } else {
      setWarning((preValue: any) => ({
        ...preValue,
        [property + 'Warning']: false,
      }));
    }
    if (property === 'companyname' || property === 'domain') {
      let validateDomain = /^(?:(w)(?!\1{3})|[^w])+$/;
      let subdomain: any;
      if (value.match(letters)) {
        subdomain = value.toLowerCase();
      } else {
        setError((preValue: any) => ({ ...preValue, subdomainWarning: true }));
        subdomain = value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
      }
      subdomain = subdomain.trim();
      if (validateDomain.test(value)) {
        if (value.match('www')) {
          setError((preValue: any) => ({ ...preValue, inValidDomain: true }));
        } else {
          setError((preValue: any) => ({ ...preValue, inValidDomain: false }));
        }
      } else {
        setError((preValue: any) => ({ ...preValue, inValidDomain: false }));
      }
      setForm((preValue: any) => ({
        ...preValue,
        [property]: value,
        subdomain,
      }));
    } else if (property === 'firstname' || property === 'lastname') {
      let alphaNumeric = /^[a-zA-Z0-9\-\s]+$/;
      if (!value || value.match(alphaNumeric)) {
        setForm((preValue: any) => ({ ...preValue, [property]: value }));
      }
    } else if (property === 'email') {
      setForm((preValue: any) => ({
        ...preValue,
        [property]: value.toLowerCase(),
      }));
    } else {
      setForm((preValue: any) => ({ ...preValue, [property]: value }));
    }
  };
  const toggleCheck = () => {
    setCheck((preValue: any) => !preValue);
  };

  const hasFormErrors = () => {
    const { companyname, subdomain, email, firstname, lastname }: any = {
      ...form,
    };

    const companynameError = isEmptyOrSpaces(companyname);
    const subdomainError = isEmptyOrSpaces(subdomain);
    const inValidDomain = validateDomain(subdomain);
    const firstnameError = isEmptyOrSpaces(firstname);
    const lastnameError = isEmptyOrSpaces(lastname);
    const emailError = invalidEmail(email);
    const emailMessage = getEmailErrorMessage(email);

    setError((preValue: any) => ({
      ...preValue,
      companynameError,
      subdomainError,
      inValidDomain,
      firstnameError,
      lastnameError,
      emailError,
      emailMessage,
    }));

    return (
      companynameError ||
      subdomainError ||
      inValidDomain ||
      firstnameError ||
      lastnameError ||
      emailError
    );
  };

  const handleClose = (property: any) => {
    setError((preValue: any) => ({ ...preValue, [property]: false }));
  };
  const handleWarningClose = (property: any) => {
    setWarning((preValue: any) => ({ ...preValue, [property]: false }));
  };
  return (
    <>
      {loading && (
        <div className="loader__component" tw="(fixed bg-white)!">
          <Loader />
        </div>
      )}

      <form
        className="signupForm grid-container full"
        id="signupForm"
        ref={formRef}
        onSubmit={onFinish}
      >
        <div tw="text-sonnant-blue mb-10 font-medium mt-20 text-center">
          Sign up with your email address
        </div>

        {error.apiError && (
          <p className="topMessage error" tw="relative pr-10! shadow rounded">
            <CloseSVG
              tw="absolute top[0.6rem] right[1rem] cursor-pointer"
              name={'apiError'}
              close={handleClose}
            />
            {error.apiErrorMessage}
          </p>
        )}

        <div tw="flex space-x-8 mb-2 mt-4">
          <div tw="w-1/2">
            <label tw="text-sonnant-blue font-medium font-size[1.5rem]! relative">
              First Name
              <input
                type="text"
                placeholder="John"
                className={`${error.firstnameError ? 'error' : ''}`}
                onChange={handleChange('firstname')}
                value={form.firstname}
                maxLength={50}
                onInput={() => setError({ ...error, firstnameError: false })}
                onFocus={onFocusFirst}
                onBlur={onBlurFirst}
              />
              <Notification
                name={'firstnameError'}
                close={handleClose}
                type={focusFirst || error.firstnameError ? 'error' : null}
                message={'First Name is a mandatory field'}
              />
              <Notification
                name={'firstnameWarning'}
                close={handleWarningClose}
                type={warning.firstnameWarning ? 'warning' : null}
                message={warning.message}
              />
              {/* <img
                src={NameIcon}
                alt="Username"
                tw="absolute width[3.4rem] top[3rem] right-2.5 "
              /> */}
            </label>
          </div>
          <div tw="w-1/2">
            <label tw="text-sonnant-blue font-medium font-size[1.5rem]! relative">
              Last Name
              <input
                type="text"
                onChange={handleChange('lastname')}
                value={form.lastname}
                placeholder="Smith"
                maxLength={50}
                onInput={() => setError({ ...error, lastnameError: false })}
                onFocus={onFocusLast}
                onBlur={onBlurLast}
              />
              <Notification
                name={'lastnameError'}
                close={handleClose}
                type={focusLast || error.lastnameError ? 'error' : null}
                message={'Last Name is a mandatory field'}
              />
              {/* <img
                src={NameIcon}
                alt="Username"
                tw="absolute width[3.4rem] top[3rem] right-2.5 "
              /> */}
            </label>
          </div>
        </div>
        <div tw="w-full mb-2">
          <label tw="text-sonnant-blue font-medium font-size[1.5rem]! relative">
            Email Address
            <input
              type="text"
              className={`${error.emailError ? 'error' : ''}`}
              onChange={handleChange('email')}
              value={form.email}
              placeholder="john@example.com"
              maxLength={100}
              onInput={() => setError({ ...error, emailError: false })}
              onFocus={onFocusEmail}
              onBlur={onBlurEmail}
            />
            <Notification
              name={'emailError'}
              close={handleClose}
              type={focusEmailValid || error.emailError || focusEmail ? 'error' : null}
              message={focusEmail ? 'Email Address is a mandatory field' : error.emailMessage}
            />
            <Notification
              name={'emailWarning'}
              close={handleWarningClose}
              type={warning.emailWarning ? 'warning' : null}
              message={warning.message}
            />
            {/* <img
              src={MailImg}
              alt="Username"
              tw="absolute width[3.4rem] top[4rem] right-2.5"
            /> */}
          </label>
        </div>
        <div tw="w-full mb-2">
          <label tw="text-sonnant-blue font-medium font-size[1.5rem]! relative">
            Library / Company Name
            <input
              type="text"
              className={`${error.companynameError ? 'error' : ''}`}
              onChange={handleChange('companyname')}
              value={form.companyname}
              placeholder="Company Name"
              maxLength={50}
              onInput={() => setError({ ...error, companynameError: false })}
              onFocus={onFocusCom}
              onBlur={onBlurCom}
            />
            {form?.subdomain?.trim() && (
              <div tw="w-full text-right -mt-2 -mb-4">
                {form.subdomain}.
                {getDomainWithoutSubdomain(window.location.origin)}
              </div>
            )}
            <Notification
              name={'companynameError'}
              close={handleClose}
              type={focusCom || error.companynameError ? 'error' : null}
              message={'Company Name is a mandatory field'}
            />
            <Notification
              name={'inValidDomain'}
              close={handleClose}
              type={error.inValidDomain ? 'error' : null}
              message={"'www' is not an acceptable Sub Domain Value."}
            />
            <Notification
              name={'companynameWarning'}
              close={handleWarningClose}
              type={warning.companynameWarning ? 'warning' : null}
              message={warning.message}
            />
            {/* <img
              src={CompanyIcon}
              alt="Username"
              tw="absolute width[3.4rem] top[3.2rem] right-2.5"
            /> */}
          </label>
        </div>
        <label htmlFor="terms">
          <div tw="w-full mb-2 flex ">
            <div tw="mr-2 mt-6 mb-8 justify-center">
              <input
                id="terms"
                onChange={toggleCheck}
                checked={check}
                type="checkbox"
                tw="block height[20px] width[20px] border[2px solid #7f8090]! rounded visible! cursor-pointer"
              />
            </div>
            <div tw="font-size[13px] line-height[16px] mt-6 mb-8 text-sonnant-grey-5 font-normal">
              <div>
                By creating an account you agree to our{' '}
                <a
                  css={[textLink]}
                  href="https://www.sonnant.com/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditions{' '}
                </a>
              </div>
              <div>
                and have read and agree to our
                <a
                  css={[textLink]}
                  href="https://www.sonnant.com/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  Privacy Policy.
                </a>
              </div>
            </div>
          </div>
        </label>
        <div tw="w-full mb-6">
          {/* <button
            type="submit"
            tw="flex justify-center items-center font-size[1.8rem]! mt-0!"
            disabled={Valid()}
          >
            <img src={ArrowIcon} tw="width[40px]" alt="" />
            <div tw="relative flex items-center font-medium">
              START FREE TRIAL
            </div>
          </button> */}
          <div
            tw="w-full flex justify-center hover:(opacity-95)"
            css={[isInvalidForm() && hasFilledValue && tw`cursor-not-allowed`]}
          >
            <img
              tw="max-height[5rem] cursor-pointer"
              css={[
                isInvalidForm() &&
                  hasFilledValue &&
                  tw`opacity-75 hover:(opacity-80)! pointer-events-none`,
              ]}
              src={StartTrialPNG}
              alt="Start free trial"
              onClick={() => {
                formRef.current?.requestSubmit();
              }}
            />
          </div>
          <div tw="font-size[14px] line-height[16px] mt-12 -mb-2 text-sonnant-blue font-normal text-center">
            <div>
              Already have an account?{' '}
              <Link css={[textLink]} to="/signin">
                Sign in now
              </Link>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
