import { UseQueryOptions, useQuery } from 'react-query';
import { AdminPortalService } from 'services/admin-portal.service';
import { Duration } from 'utils/constants';
import { UserGroupByIdResponse } from 'utils/models';
import { getTenantidFromIdToken } from 'utils/utils';

type Props = {
  groupId: string | null;
  options?: UseQueryOptions<UserGroupByIdResponse | undefined>;
};

const tenantid = getTenantidFromIdToken();

export const useGroupIdQuery = ({ groupId, options }: Props) => {
  const queryFnAsync = async () => {
    if (!groupId) return;

    const { data } = await AdminPortalService.getUserGroupById(groupId);

    return data;
  };

  const queryResult = useQuery({
    queryKey: ['admin-portal/user-groups', tenantid, groupId],
    queryFn: queryFnAsync,
    staleTime: Duration.seconds(30),
    cacheTime: 0,
    retry: 3,
    ...options,
  });

  return queryResult;
};
