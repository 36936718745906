/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ApexOptions } from 'apexcharts';
import { isEmpty, round, sum, sumBy, toNumber } from 'lodash';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Bucket } from 'utils/models';
import { Info } from '../Info';

type Props = {
  buckets: Bucket[];
  width: number;
  handleClickPie: (value: string) => void;
};

export const RadialBarChart = React.memo(
  ({ buckets, width, handleClickPie }: Props) => {
    const bucketsSlice = buckets.slice(0, 5);

    const total = sumBy(buckets, 'doc_count' as keyof Bucket);
    const totalSlice = sumBy(bucketsSlice, 'doc_count' as keyof Bucket);

    const otherCount = total - totalSlice;

    // const percentSeries = useMemo(() => {
    //   return bucketsSlice.map(({ doc_count }) =>
    //     round((doc_count / total) * 100, 1),
    //   );
    // }, [bucketsSlice]);

    const getChartOptions = (): ApexOptions => ({
      chart: {
        width: '100%',
        height: '100%',
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 1500,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const value = config.w.config.labels[config.dataPointIndex];

            handleClickPie(value);
          },
        },
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 10,
            size:
              bucketsSlice.length > 1 ? `${100 / bucketsSlice.length}%` : '75%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            value: {
              show: true,
              formatter(val) {
                return String(toNumber(val));
              },
            },
            total: {
              show: otherCount > 0,
              label: 'Other',
              formatter() {
                return String(otherCount);
              },
            },
          },
        },
      },
      labels: bucketsSlice?.map(({ key }) => key),
      colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
      dataLabels: {
        enabled: true,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45,
        },
        formatter: function (val: number, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];

          if (name.length > 12) {
            return [name.slice(0, 12) + '...', val.toFixed(1) + '%'] as any;
          }
          return [name, val.toFixed(1) + '%'];
        },

        style: {
          fontSize: '12px',
          fontFamily:
            "'Lexend', 'Roboto', 'Arial', 'Helvetica', 'Verdana', sans-serif",
        },
      },
      title: {
        text: 'Top tagged IAB Categories',
        align: 'left',
        offsetX: 73,
        style: {
          fontSize: '15px',
        },
      },
      tooltip: {
        y: {
          formatter: function (value, opts) {
            const total = sum(opts.config.series);

            const percent = round((value / total) * 100, 2);

            return `${value} (${percent}%)`;
          },
        },
      },
      legend: {
        show: true,
        floating: true,
        position: 'left',
        fontSize: '14px',
        offsetX: 54,
        offsetY: 29,
        markers: {
          width: 4,
        },
        itemMargin: {
          horizontal: 0,
          vertical: 0,
        },
        horizontalAlign: 'right',
        labels: {
          useSeriesColors: true,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
        formatter(legendName) {
          if (legendName.length > 12) {
            return legendName.slice(0, 12) + '...';
          }
          return legendName;
        },
      },
      stroke: {
        lineCap: 'round',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    });

    if (isEmpty(bucketsSlice)) {
      return <Info text="Nothing to show" />;
    }

    return (
      <ReactApexChart
        options={getChartOptions()}
        series={bucketsSlice.map(({ doc_count }) => doc_count)}
        type="radialBar"
        width={width}
      />
    );
  },
);
