/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import 'twin.macro';
import tw from 'twin.macro';

import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as QuestionSvg } from '../../../assets/Icons/Vector_question.svg';
import '../User/User.scss';
import { Subdomain } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../../UiControls/modal/modal';
import UserModal from './addEditUser';
import Loader from '../../loader/loader';
import { isEqual, map, toNumber } from 'lodash';
import { Tooltip } from '@rmwc/tooltip';
import { Notification } from '../../UiControls/notification/Notification';
import { handleStatusAlert, handleStatusAlertClose } from 'actions';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { isUncountedUser } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { notClickableText, userUncounted } from 'components/shared/twin.styles';
import { tierSelector } from 'slices/payment.slice';
import { RootState } from 'reducers';
import { UserResponse } from 'utils/models/payment.model';
import { ConfirmModalProps } from 'utils/models';
import { AccountStatus, ModalOptions } from 'utils/enum';
import { UserService } from 'services';
import { customToast } from 'utils/toast.util';
import { useToggle } from 'react-use';
import { useUsersQuery } from 'hooks/queries/useUsersQuery';

interface IUserData {
  firstname: string;
  lastname: string;
  email: string;
  role: string;
  subdomain: string;
}

export const User = () => {
  const { subscription } = useSelector((state: RootState) => state.payment);
  const userProfile = useSelector((state: RootState) => state.userProfile);
  const tier = useSelector(tierSelector);

  const dispatch = useDispatch();
  const confirmModalRef = useRef<any>();

  const [openModal, setOpenModal] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [supportMode, setSupportMode] = useState(false);
  const [type, setType] = useState('Add');
  const [focusIndex, setFocusIndex] = useState(-1);
  const [userData, setUserData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    role: 'User',
    subdomain: Subdomain()
      .replace('.voice-intellect.com', '')
      .replace('.sonnant.ai', ''),
  });

  const [error, setError] = useState<any>({
    firstnameError: false,
    lastnameError: false,
    emailerror: false,
    userroleError: false,
    emailMessage: '',
    apiError: false,
    apiErrorMessage: false,
  });

  const [warning, setWarning] = useState<{ [type: string]: boolean }>({
    firstnameWarning: false,
    lastnameWarning: false,
    emailWarning: false,
  });

  const [isSaving, toggleSaving] = useToggle(false);

  const {
    isLoading,
    data: userList,
    refetch: refetchUserList,
  } = useUsersQuery();

  const handleChange = ({
    target: { name, value },
  }:
    | React.ChangeEvent<HTMLSelectElement>
    | React.ChangeEvent<HTMLInputElement>) => {
    if (name !== 'email' && value.length === 50) {
      setWarning({ ...warning, [name + 'Warning']: true });
    } else if (name === 'email' && value.length === 100) {
      setWarning({ [name + 'Warning']: true });
    } else {
      setWarning({ [name + 'Warning']: false });
    }

    let alphaNumeric = /^[a-zA-Z0-9\-\s]+$/;
    if (name === 'firstname' || name === 'lastname') {
      if (!value || value.match(alphaNumeric)) {
        setUserData({ ...userData, [name]: value });
      }
    } else if (name === 'email') {
      setUserData({ ...userData, [name]: value.toLowerCase() });
    } else {
      setUserData({ ...userData, [name]: value });
    }
  };

  const handleAlert = () => {
    dispatch(handleStatusAlertClose());
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const emailArray = map(userList?.users, 'Email');

    if (emailArray.includes(userData.email)) {
      const foundIndex = emailArray.indexOf(userData.email);
      // not change email
      if (foundIndex === focusIndex) {
        const user = userList?.users[foundIndex];
        const cloneUser = {
          email: user?.Email,
          firstname: user?.FirstName,
          lastname: user?.LastName,
          role: user?.Role,
          subdomain: Subdomain()
            .replace('.voice-intellect.com', '')
            .replace('.sonnant.ai', ''),
        };
        const hasEqual = isEqual(userData, cloneUser);
        if (hasEqual) {
          setOpenModal(false);
          return;
        }
      } else {
        customToast.error('This username already existed');
        return;
      }
    }

    handleAlert();

    editMode ? handleInitEditUser(userData) : handleInitSaveUser(userData);

    setUserData({
      firstname: '',
      lastname: '',
      email: '',
      role: 'User',
      subdomain: '',
    });

    setError({
      firstnameError: false,
      lastnameError: false,
      emailerror: false,
      userroleError: false,
      emailMessage: '',
      apiError: false,
    });

    setWarning({
      firstnameWarning: false,
      lastnameWarning: false,
      emailWarning: false,
    });
  };

  const handleInitEditUser = async (user: IUserData) => {
    try {
      toggleSaving(true);
      const dataUser = await UserService.editUser({ ...user });

      dispatch(handleStatusAlert(dataUser.data, true));

      refetchUserList();
    } catch (error: any) {
      dispatch(handleStatusAlert(error.response.data, false));
    } finally {
      toggleSaving(false);
    }
  };

  const handleInitSaveUser = async (user: IUserData) => {
    try {
      toggleSaving(true);

      const dataUser = await UserService.createUser({ ...user });

      dispatch(handleStatusAlert(dataUser.data, true));

      refetchUserList();
    } catch (error: any) {
      dispatch(handleStatusAlert(error.response.data, false));
    } finally {
      toggleSaving(false);
    }
  };

  const handleSupportSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleAlert();
    handleInitSaveUser(userData);
  };

  const modalClosed = () => {
    setOpenModal(false);
    setSupportMode(false);
    setEditMode(false);

    setUserData({
      firstname: '',
      lastname: '',
      email: '',
      role: 'User',
      subdomain: '',
    });

    handleAlert();
    handleClose(null);
  };

  const handleError = (property: string) => (e: React.FocusEvent) => {
    let value = !(e.target as HTMLInputElement).value ? true : false;
    if (property === 'emailError') {
      let emailMessage = '';
      if (value) {
        emailMessage = 'Email Address is a mandatory field';
      } else if (
        !/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/.test(
          (e.target as HTMLInputElement).value,
        )
      ) {
        value = true;
        emailMessage = 'This email address is considered invalid.';
      }
      setError({ [property]: value, emailMessage });
    } else {
      setError({ [property]: value });
    }
    ///^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/
  };
  const handleClose = (property: any) => {
    setError({ [property]: false });
  };

  const handleWarningClose = (property: any) => {
    setWarning({ [property]: false });
  };

  const handleEditUser = (data: any, index: number) => {
    setOpenModal(true);
    setEditMode(true);
    setSupportMode(false);
    setType('Edit');
    setFocusIndex(index);
    setUserData({
      ...userData,
      firstname: data.FirstName,
      lastname: data.LastName,
      email: data.Email,
      role: data.Role,
    });
  };

  const handleClickAddUser = async () => {
    // const allowToAdd = countActiveUser(data?.users) < toNumber(subscription.licensedusers);
    // if (tier.isTrial || tier.isPAYG) {
    // const result = await addUserConfirmRef.current?.show();

    // if (result === ModalOptions.CANCEL) return;

    // history.push('/account-settings#billing');
    // window.location.reload();
    // return;

    setOpenModal(true);
    setEditMode(false);
    setSupportMode(false);
    setType('Add');
  };

  const handleClickAddSupport = async () => {
    setUserData({
      ...userData,
      firstname: 'Sonnant',
      lastname: 'Support',
      email: 'support@sonnant.com',
      role: 'Administrator',
    });
    setOpenModal(true);
    setEditMode(false);
    setSupportMode(true);
    setType('Add Support');
  };

  const isCurrentUser = (user: any) => {
    return isEqual(user?.Email, userProfile.email);
  };

  const countLicensedUser = () => {
    const numberOfUser = toNumber(subscription?.licensedusers);

    if (tier.isTrial || tier.isPAYG) {
      if (numberOfUser === 0) {
        return 1;
      }
    }

    if (tier.isEnterpriseUnlimited) {
      return 'Unlimited';
    }

    return toNumber(subscription?.licensedusers);
  };

  const handleChangeStatusUser = async (user: UserResponse) => {
    const isActive = user?.Status === AccountStatus.ACTIVE;
    const fullName = `${user.FirstName} ${user.LastName}`;

    const modalOption = await confirmModalRef.current?.show({
      title: isActive ? 'Disable user' : 'Enable user',
      message: isActive ? (
        <>
          You have chosen to disable user account: <b>{fullName}</b>
          <p>
            Choosing to disable will remove all account access to your Sonnant
            Library,
          </p>
          <p>until an administrator enables access to this user account.</p>
        </>
      ) : (
        <>
          You have chosen to enable user account : <b>{fullName}</b>
          <p>
            User will have full permissions to your Sonnant Library – as defined
            by their associated <b>{user?.Role || 'User'} Role</b>.
          </p>
          <p>Do you want to proceed?</p>
        </>
      ),
      confirmText: 'Confirm',
    } as ConfirmModalProps);

    if (modalOption === ModalOptions.YES) {
      const changeStatusAsync = UserService.updateAccountStatus({
        email: user.Email,
        newStatus: isActive ? 'Disabled' : 'Active',
      });

      customToast.promise(changeStatusAsync, {
        loading: 'Disabling user...',
        success: isActive
          ? 'Disable user successfully'
          : 'Enable user successfully',
        error: 'Something went wrong',
      });

      try {
        await changeStatusAsync;

        refetchUserList();
      } catch (err: any) {
        console.log('err :>> ', err);
      }
    }
  };

  const handleDeleteUser = async (user: UserResponse) => {
    const fullName = `${user.FirstName} ${user.LastName}`;

    const modalOption = await confirmModalRef.current?.show({
      title: 'Delete user',
      message: (
        <>
          You have chosen to delete user account: <b>{fullName}</b>
          <p>
            Choosing to delete will remove all account access to your Sonnant
            Library.
          </p>
        </>
      ),
      confirmText: 'Confirm',
    } as ConfirmModalProps);

    if (modalOption === ModalOptions.YES) {
      const deleteUserAsync = UserService.deleteUser({
        email: user?.Email,
      });

      customToast.promise(deleteUserAsync, {
        loading: 'Deleting user...',
        success: 'Delete user successfully',
        error: 'Something went wrong',
      });

      try {
        await deleteUserAsync;

        refetchUserList();
      } catch (err: any) {
        console.log('err :>> ', err);
      }
    }
  };

  const hintUserMessage = useRef<any>();

  useEffect(() => {
    const listener = (event: any) => {
      if (
        !hintUserMessage.current ||
        hintUserMessage.current.contains(event.target)
      ) {
        return;
      }

      setOpenPopup(false);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [hintUserMessage, openPopup]);

  return (
    <div>
      {isLoading && (
        <div className="loader__component" css={!openModal && tw`top[13rem]`}>
          <Loader />
        </div>
      )}

      <div
        className="grid-container fluid"
        tw="overflow-y-auto height[calc(100vh - 13rem)]"
      >
        <div className="grid-x grid-margin-x tenantProfile">
          <div className="cell grid-padding-x small-6 medium-6 large-6">
            <div className="tenantTitle">
              <h2>Users</h2>
            </div>
          </div>
          <div className="cell grid-padding-x small-6 medium-6 large-6">
            <div className="btnReset" tw="flex items-center">
              {!isNaN(toNumber(subscription?.licensedusers)) &&
                !tier.isInternal && (
                  <div tw="mr-8">
                    User licenses:{' '}
                    <span tw="font-medium ml-4">{countLicensedUser()}</span>
                  </div>
                )}

              <button
                type="button"
                onClick={handleClickAddUser}
                className="success button apply primary-btn"
                tw="my-0!"
              >
                Add User
              </button>

              <button
                type="button"
                onClick={handleClickAddSupport}
                className="success button apply primary-btn"
                tw="my-0! w-[12.6rem]!"
              >
                Add Support
              </button>
            </div>
          </div>
        </div>

        <div className="grid-x grid-margin-x">
          <div className="cell grid-padding-x medium-12">
            <div className="userData_table">
              <table>
                <thead>
                  <tr>
                    <th tw="width[4%]">#</th>
                    <th tw="width[10%]">First Name</th>
                    <th tw="width[8%]">Last Name</th>
                    <th tw="width[18%]">Email Address (username)</th>
                    <th tw="width[10%]">Role</th>
                    <th tw="width[6%]">Status</th>
                    <th tw="width[13%]">
                      <div className="date">Date Created</div>
                    </th>
                    <th tw="width[13%]">
                      <div tw="text-center" className="date">
                        Last Login
                      </div>
                    </th>
                    <th tw="flex space-x-4">
                      <div>Manage Users</div>
                      <div>
                        <Tooltip
                          className="tooltip-manage-user"
                          align="bottomLeft"
                          open={openPopup}
                          css={customTooltipStyles}
                          content={
                            <div
                              ref={hintUserMessage}
                              tw="width[40rem] top-0 left[-1.5rem] font-size[1.6rem] line-height[1.9rem] border-radius[3px] absolute"
                            >
                              <Notification
                                close={() => {
                                  setOpenPopup(false);
                                }}
                                hideCloseButton
                                type={'popup-manage-user'}
                                // tw="relative!"
                                message={
                                  <div tw="py-1">
                                    <p>
                                      “Active” users have full permissions for
                                      your Sonnant Library – as defined by their
                                      associated User Role.
                                    </p>
                                    <p>
                                      “Inactive” users have no permissions for
                                      your Sonnant Library.
                                    </p>
                                    <p>
                                      “Edit” command allows you to update the
                                      details for the selected user.
                                    </p>
                                    <p>
                                      “Delete” command allows you to immediately
                                      and permanently delete the selected user
                                      from Sonnant including all of their
                                      settings. Any Content Items, Collections,
                                      and Comments associated with the deleted
                                      user remain in your Sonnant Library.
                                    </p>
                                    <p>
                                      “Disable” command allows you to
                                      immediately revoke access for the selected
                                      user. Disabled users are marked as
                                      “Inactive” and are unable to Sign in to
                                      access your Sonnant Library. A disabled
                                      user’s account and settings and retained
                                      and can be enabled again if required.
                                    </p>
                                    <p>
                                      “Enable” command allows you to immediately
                                      grant access for the selected user.
                                      Enabled users are again marked as “Active”
                                      and are able to access your Sonnant
                                      Library.
                                    </p>
                                  </div>
                                }
                                direction={null}
                              />
                            </div>
                          }
                        >
                          <div
                            onClick={() => setOpenPopup(!openPopup)}
                            className="questionsvg"
                          >
                            <QuestionSvg />
                          </div>
                        </Tooltip>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userList?.users?.map((user: UserResponse, index: number) => (
                    <tr
                      key={index}
                      css={[isUncountedUser(user) && userUncounted]}
                    >
                      <td>{index + 1}</td>
                      <td>{user.FirstName}</td>
                      <td>{user.LastName}</td>
                      <td>{user.Email}</td>
                      <td>{user.Role}</td>
                      <td tw="font-medium">{user.Status}</td>
                      <td tw="font-medium">{user.CreatedDate}</td>
                      <td tw="font-medium text-center">
                        {user.LastLogin ?? '-'}
                      </td>
                      <td>
                        <button
                          type="button"
                          className="button padding-left-0"
                          data-open="editUserPopup"
                          onClick={() => handleEditUser(user, index)}
                        >
                          Edit User
                        </button>
                        <button
                          type="button"
                          className="button"
                          onClick={() => handleDeleteUser(user)}
                          css={isCurrentUser(user) && notClickableText}
                        >
                          Delete User
                        </button>

                        <button
                          type="button"
                          className="button"
                          onClick={() => handleChangeStatusUser(user)}
                          css={isCurrentUser(user) && notClickableText}
                        >
                          {user.Status === AccountStatus.DISABLED
                            ? 'Enable User'
                            : 'Disable User'}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={openModal} modalClosed={modalClosed}>
        <UserModal
          // @ts-ignore
          type={type}
          userData={userData}
          error={error}
          warning={warning}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleSupportSubmit={handleSupportSubmit}
          handleError={handleError}
          handleClose={handleClose}
          handleWarningClose={handleWarningClose}
          modalClosed={modalClosed}
          handleAlert={handleAlert}
          editMode={editMode}
          supportMode={supportMode}
          isSaving={isSaving}
        />
      </Modal>

      <ConfirmModal
        ref={confirmModalRef}
        message="Please upgrade to enable additional users"
        title="Confirm"
        confirmText="Upgrade"
        classes="disable-enable-user-modal-width"
      />
    </div>
  );
};

const customTooltipStyles = css`
  ${tw`font-size[1rem]`}

  .rmwc-tooltip-inner {
  }
`;
