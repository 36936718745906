import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { defaultOmnySetting } from 'utils/default/defaultSetting';
import { IntegrationEnum } from 'utils/enum';
import { IntegrationOptions as IntegrationOption } from 'utils/models';

export const omnySlice = createSlice({
  name: 'omny',
  initialState: {
    settings: [defaultOmnySetting],
  },
  reducers: {
    setOmnySettings: (
      state,
      { payload }: PayloadAction<IntegrationOption[]>,
    ) => {
      state.settings = payload;
    },
    updateSingleIntegration: (
      state,
      { payload }: PayloadAction<IntegrationOption>,
    ) => {
      const foundIndex = state.settings.findIndex(
        ({ id }) => id === payload.id,
      );

      if (foundIndex >= 0) {
        state.settings[foundIndex] = payload;
      }
    },
    addIntegration: (
      state,
      { payload }: PayloadAction<{ type: IntegrationEnum }>,
    ) => {
      if (payload.type === IntegrationEnum.OMNY) {
        state.settings.push(defaultOmnySetting);
      }
    },
    addIntegrationFilter: (
      state,
      { payload }: PayloadAction<IntegrationOption>,
    ) => {
      const foundIndex = state.settings.findIndex(
        ({ id }) => id === payload.id,
      );

      if (foundIndex >= 0) {
        const newFilter = { ...state.settings[foundIndex].filter };
        newFilter.programFilters.push({
          programId: '',
          programName: '',
        });
        state.settings[foundIndex].filter = newFilter;
      }
    },
    removeIntegrationFilter: (
      state,
      {
        payload,
      }: PayloadAction<{ integration: IntegrationOption; index: number }>,
    ) => {
      const { integration, index } = payload;
      const foundIndex = state.settings.findIndex(
        ({ id }) => id === integration.id,
      );

      if (foundIndex >= 0) {
        const newFilter = { ...state.settings[foundIndex].filter };

        if (newFilter.programFilters.length === 1) {
          const defaultFilter = {
            programId: '',
            programName: '',
          };

          newFilter.programFilters[0] = defaultFilter;

          state.settings[foundIndex].filter = newFilter;

          return;
        }

        newFilter.programFilters.splice(index, 1);
        state.settings[foundIndex].filter = newFilter;
      }
    },
    removeIntegration: (
      state,
      { payload }: PayloadAction<{ integrationId: string }>,
    ) => {
      if (isEmpty(payload?.integrationId)) return;

      state.settings = state.settings.filter(
        (i) => i.id !== payload.integrationId,
      );
    },
  },
});

export const {
  addIntegrationFilter,
  setOmnySettings,
  updateSingleIntegration,
  addIntegration,
  removeIntegration,
  removeIntegrationFilter,
} = omnySlice.actions;

export const omnyReducer = omnySlice.reducer;
