/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { saveAs } from 'file-saver';
import { cloneDeep, isEqual, orderBy, pick } from 'lodash';
import {
  ReactElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLatest, useToggle } from 'react-use';
import { RootState } from 'reducers';
import { v4 } from 'uuid';

import { TopicLayerDropDown } from 'components/Publish/TopicLayerDropDown';
import { Modal } from 'components/UiControls/modal/modal';
import {
  getIsIgnoredConfirmAddBlacklistStorage,
  setIsIgnoredConfirmAddBlacklistStorage,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import Loader from 'components/loader/loader';
import { UserService } from 'services';
import { ExportAliasTerm, InsightService } from 'services/insight.service';
import { setAliasTerms } from 'slices/alias-term.slice';
import { setBlacklistInsight } from 'slices/global.slice';
import { userSelector } from 'slices/payment.slice';
import {
  CHART_ITEM_COUNT,
  MAX_INSIGHT_EDIT_COUNT,
  MAX_TERM_EDIT_COUNT,
} from 'utils/constants';
import { Layers, ModalOptions } from 'utils/enum';
import { len, pushOrUpdate, searchBy } from 'utils/generic.util';
import { AliasTerm, Term } from 'utils/models';
import {
  AddBlacklistConfirmModal,
  BasicConfirmModal,
  TermEditModalResult,
} from 'utils/models/modal.model';
import { customToast } from 'utils/toast.util';
import { getTenantidFromIdToken, getTopicLayerName } from 'utils/utils';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { ConfirmWithCheckboxModal } from '../ConfirmWithCheckboxModal/ConfirmWithCheckboxModal';
import { HintDisallowed } from '../HintDisallowed';
import { Info } from '../Info';
import { SearchInput } from '../SearchInput';
import { simpleMenuDisabled } from '../twin.styles';
import { AliasTermItem } from './AliasTermItem';

type Props = {
  isTermExploration?: boolean;
  isLoading?: boolean;
  isTrendingInsight?: boolean;
  currentLayer: Layers;
  termList: ExportAliasTerm[];

  onSaveEditedTerms: (editedTerms: AliasTerm[]) => void;
  onLayerChange: (selectedLayer: Layers) => void;
};

type IPromiseParams = {
  resolve: (result: TermEditModalResult) => void;
  reject: (err: any) => void;
};

export const InsightEditTermsModal = forwardRef(
  (
    {
      isTermExploration = false,
      isLoading = false,
      isTrendingInsight = false,
      currentLayer,
      termList,
      ...props
    }: Props,
    ref: React.Ref<unknown>,
  ): ReactElement => {
    const MAX_TERM_EDITABLE_COUNT = isTermExploration
      ? MAX_TERM_EDIT_COUNT
      : MAX_INSIGHT_EDIT_COUNT;

    useImperativeHandle(ref, () => ({ show }));

    const dispatch = useDispatch();

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });
    const confirmModalRef = useRef<BasicConfirmModal>(null);
    const confirmWithCheckboxModalRef = useRef<AddBlacklistConfirmModal>(null);

    const aliasTerm = useSelector((state: RootState) => state.aliasTerm);
    const latestAliasTerm = useLatest(aliasTerm);

    const global = useSelector((state: RootState) => state.global);
    const blacklistInsight = global.blacklistInsight;

    const { isLoading: isLoadingUser } = useSelector(userSelector);

    const [isExporting, toggleExporting] = useToggle(false);
    const [shouldRefresh, toggleShouldRefresh] = useToggle(false);

    const [isOpen, setIsOpen] = useState(false);
    const [initialTerms, setInitialTerms] = useState<ExportAliasTerm[]>([]);
    const [editingTerms, setEditingTerms] = useState<ExportAliasTerm[]>([]);
    const [searchTerm, setSearchTerm] = useState('');

    const hasChanges = !isEqual(editingTerms, initialTerms);
    const enabledTermsCounter = len(
      editingTerms.filter(({ isEnabled }) => isEnabled),
    );
    const searchedTerms: AliasTerm[] = editingTerms.filter(
      searchBy(searchTerm, ['original', 'alias']),
    );

    useEffect(() => {
      const orderedTermList = orderBy(
        termList,
        ['isEnabled', 'relevance_score', 'doc_count'],
        ['desc', 'desc', 'desc'],
      );

      setEditingTerms(orderedTermList);
      setInitialTerms(orderedTermList);
    }, [termList]);

    const show = async (): Promise<TermEditModalResult> => {
      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
        toggleShouldRefresh(false);
      });
    };

    const hideModal = () => {
      setIsOpen(false);

      // Reset state
      setSearchTerm('');
      toggleShouldRefresh(false);
    };

    const handleCloseModal = async () => {
      if (hasChanges) {
        const result = await showUnsavedChangesConfirmModal();

        if (result === ModalOptions.CANCEL) return;

        // Reset to last saved version
        setEditingTerms(initialTerms);
      }

      promiseInfo.current?.resolve({
        option: ModalOptions.CLOSE,
        shouldRefresh,
      });

      hideModal();
    };

    const showUnsavedChangesConfirmModal = async () => {
      const result = await confirmModalRef.current?.show({
        title: 'Unsaved Changes',
        message: (
          <div>
            Changes you made may not be saved. <b>Would you like to proceed?</b>
          </div>
        ),
        confirmText: 'Yes',
      });

      return result;
    };

    const handleChangeLayer = async (selectedLayer: Layers) => {
      if (hasChanges) {
        const result = await showUnsavedChangesConfirmModal();

        if (result === ModalOptions.CANCEL) return;
      }
      props.onLayerChange(selectedLayer);
    };

    const handleToggleTerm = (editedTerm: AliasTerm) => {
      const isValidNumberIsEnabled = validateNumberOfEnabledTerms(editedTerm);

      if (!isValidNumberIsEnabled) return;

      handleUpdateTerm(editedTerm);
    };

    const handleUpdateTerm = (editedTerm: AliasTerm) => {
      const editingTermsClone = cloneDeep(editingTerms);

      const foundIndex = editingTermsClone.findIndex(
        (term) => term.original === editedTerm.original,
      );

      if (foundIndex < 0) return;

      editingTermsClone[foundIndex] = {
        ...editingTermsClone[foundIndex],
        ...editedTerm,
      };

      setEditingTerms(editingTermsClone);
    };

    const validateNumberOfEnabledTerms = (editedTerm: AliasTerm): boolean => {
      if (!editedTerm.isEnabled) return true;

      if (enabledTermsCounter < CHART_ITEM_COUNT) return true;

      customToast.warning(
        `Reached the maximum of ${CHART_ITEM_COUNT} terms enabled.`,
      );

      return false;
    };

    const handleRemoveTerm = async (removedTerm: AliasTerm) => {
      const tagName = removedTerm.original;

      const isValidConfirmed = await validateConfirmAddToBlacklist();

      if (!isValidConfirmed) return;

      const newEditTermTags = editingTerms.filter(
        (editTermTag) => editTermTag.original !== removedTerm.original,
      );

      setEditingTerms(newEditTermTags);

      const newInitialTermTags = initialTerms.filter(
        (initialTerm) => initialTerm.original !== removedTerm.original,
      );

      setInitialTerms(newInitialTermTags);

      handleMoveTermToBlacklist(tagName);
    };

    const checkIsChartItem = (term: AliasTerm): boolean => {
      return editingTerms
        .filter((term) => term.isEnabled)
        .slice(0, CHART_ITEM_COUNT)
        .some(({ original }) => original === term.original);
    };

    const handleExportExcel = async () => {
      const exportAsync = InsightService.exportAliasTermsInsight({
        layer: currentLayer,
        isDownloadMetaChart: true,
        activeTerms: editingTerms,
        isTrendingChart: isTrendingInsight,
      });

      customToast.promise(exportAsync, {
        loading: 'Exporting Excel report...',
        success: 'Done',
        error: 'Failed to export Excel report',
      });

      try {
        toggleExporting(true);
        const { data: s3DownloadLink } = await exportAsync;

        saveAs(s3DownloadLink);
      } catch (error) {
        console.log('error :>> ', error);
        customToast.error('Something went wrong.');
      } finally {
        toggleExporting(false);
      }
    };

    const validateConfirmAddToBlacklist = async () => {
      if (getIsIgnoredConfirmAddBlacklistStorage()) return true;

      const result = await confirmWithCheckboxModalRef.current?.show({
        title: 'Add to blacklist',
        message: (
          <div>
            This term will be moved to Blacklist.{' '}
            <b>Would you like to proceed?</b>
          </div>
        ),
        confirmText: 'Yes',
      });

      if (result?.option !== ModalOptions.YES) return false;

      if (result?.isIgnoredConfirm === true) {
        setIsIgnoredConfirmAddBlacklistStorage(true);
      }

      return true;
    };

    const handleSaveBlacklistAsync = async (
      termList: Term[],
      addedBlacklistTerm: Term,
    ) => {
      const tenantid = getTenantidFromIdToken();

      const aliasTermWithoutBacklist = pushOrUpdate(
        latestAliasTerm.current.aliasTerms,
        {
          original: addedBlacklistTerm.name,
          alias: null,
          isEnabled: false,
        },
        'original',
      );

      const saveAsync = UserService.savePreference({
        tenantid,
        setting_ignore_terms: termList ? JSON.stringify(termList) : undefined,
        setting_alias_terms: JSON.stringify(aliasTermWithoutBacklist),
      });

      customToast.promise(saveAsync, {
        loading: 'Saving changes...',
        success: 'Saved successfully',
        error: 'Save failed',
      });

      try {
        dispatch(setBlacklistInsight(termList));
        dispatch(setAliasTerms(aliasTermWithoutBacklist));

        await saveAsync;

        toggleShouldRefresh(true);
      } catch (error: any) {
        console.log('error :>> ', error);
        toggleShouldRefresh(false);
      }
    };

    const handleMoveTermToBlacklist = (termName: string) => {
      const validTermName = termName.trim();

      const newBlacklistTerm = {
        id: v4(),
        dateCreated: new Date(),
        name: validTermName,
      };

      const updatedBlacklistTerms = [...blacklistInsight, newBlacklistTerm];

      handleSaveBlacklistAsync(updatedBlacklistTerms, newBlacklistTerm);
    };

    const handleSaveEditedTerm = () => {
      const aliasEditedTerms: AliasTerm[] = editingTerms.map((term) =>
        pick(term, ['original', 'alias', 'isEnabled']),
      );

      props.onSaveEditedTerms(aliasEditedTerms);

      setInitialTerms(editingTerms);

      toggleShouldRefresh(true);
    };

    return (
      <Modal show={isOpen} modalClosed={handleCloseModal}>
        <div>
          <h1 tw="font-semibold text-sonnant-dark font-size[3rem]">
            Edit Terms
          </h1>

          <div tw="flex flex-row justify-between items-end">
            <div tw="flex items-center gap-5">
              {!isTrendingInsight && (
                <div tw="w-[20rem] relative z-[1]">
                  <label>
                    <span tw="text-15">Metadata Layer</span>
                  </label>

                  <HintDisallowed
                    disabled={isTermExploration}
                    hintEnabled="Metadata layer to display"
                    hintDisabled="Metadata layer cannot be changed for term exploration"
                  >
                    <div css={[isTermExploration && simpleMenuDisabled]}>
                      <TopicLayerDropDown
                        defaultValue={getTopicLayerName(currentLayer)}
                        onChange={handleChangeLayer}
                        hasIABLayer={false}
                        hasSensitive={false}
                        hasTranscripts={true}
                        disabled={isTermExploration}
                      />
                    </div>
                  </HintDisallowed>
                </div>
              )}
            </div>

            <div tw="ml-5">
              <label tw="text-right">
                Selected {enabledTermsCounter} of {CHART_ITEM_COUNT} terms
              </label>
              <SearchInput
                noShrink
                setTerm={setSearchTerm}
                placeholder="Search in List"
              />
            </div>
          </div>

          <div tw="relative mt-4 mb-6 border[1px solid lightgrey] rounded-lg overflow-hidden">
            {(isLoadingUser || isLoading) && (
              <div tw="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-white z-[20] opacity[0.95]">
                <Loader />
              </div>
            )}

            <div tw="flex flex-wrap w-full overflow-y-auto p-4 h-[40rem]">
              <div tw="flex flex-wrap content-start gap-3 w-full">
                {searchedTerms.map((term, index) => (
                  <AliasTermItem
                    key={index}
                    aliasTerm={term}
                    onToggleEnabled={handleToggleTerm}
                    onRemoveTag={handleRemoveTerm}
                    onUpdateTerm={handleUpdateTerm}
                    isHighlightTerm={checkIsChartItem(term)}
                    editingTerms={editingTerms}
                    isEditable={true}
                  />
                ))}

                {searchedTerms.length === 0 && (
                  <Info
                    text={`Searched in term is not part of the top ${MAX_TERM_EDITABLE_COUNT} list.`}
                  />
                )}

                {editingTerms.length === 0 && (
                  <Info text="There are no terms in List" />
                )}
              </div>
            </div>
          </div>

          {/* <div tw="flex items-center justify-center mb-6">
            <TermsPagination<AliasTerm>
              items={searchTerm ? searchedTerms : editingTerms}
              onPrevPage={setCurrentPage}
              onNextPage={setCurrentPage}
            />
          </div> */}

          <div tw="flex flex-row justify-between items-center">
            <div>
              <button
                className="button btn-primary large"
                tw="mb-0"
                onClick={handleExportExcel}
                disabled={isExporting}
              >
                Export Excel
              </button>
            </div>

            {/* ACTION BUTTONS */}
            <div tw="flex justify-between items-center gap-x-6">
              <div tw="flex">
                <button
                  type="button"
                  className="button btn-secondary large apply"
                  tw="mb-0"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
              </div>

              <div tw="flex items-center gap-x-5">
                <button
                  type="button"
                  className="button btn-primary large cancel"
                  onClick={handleSaveEditedTerm}
                  tw="mb-0"
                  disabled={!hasChanges || isLoading}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <ConfirmModal ref={confirmModalRef} />

        <ConfirmWithCheckboxModal ref={confirmWithCheckboxModalRef} />
      </Modal>
    );
  },
);
