export const INIT_LIBRARY = "INIT_LIBRARY";
export const INIT_LIBRARY_NO_LOADING = "INIT_LIBRARY_NO_LOADING";
export const SET_LIBRARY = "SET_LIBRARY";
export const SET_LOCATION = "SET_LOCATION";
export const SET_SELECTED_ROWS = "SET_SELECTED_ROWS";
export const SET_SELECT_MODE = "SET_SELECT_MODE";
export const FETCH_LIBRARY = "FETCH_LIBRARY";
export const FETCH_LIBRARY_NO_LOADING = "FETCH_LIBRARY_NO_LOADING";
export const FAILED_LIBRARY = "FAILED_LIBRARY";
export const TRIGGER_MEDIA_ID = "TRIGGER_MEDIA_ID";
export const UPDATE_TITLE = "UPDATE_TITLE";
export const UPDATE_TITLE_FAIL = "UPDATE_TITLE_FAIL";
export const UPDATE_TITLE_SAGA = "UPDATE_TITLE_SAGA";
export const ADD_CLIP = "ADD_CLIP";
export const REMOVE_CLIP = "REMOVE_CLIP";
export const SET_LIBRARY_LOADING = "SET_LIBRARY_LOADING";
export const REMOVE_CLIP_FROM_COLLECTION = "REMOVE_CLIP_FROM_COLLECTION"; 

//login data
export const INIT_LOGIN = "INIT_LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SET_TOKEN = "SET_TOKEN";
export const SET_TOKEN_FAILED = "SET_TOKEN_FAILED";

//Forget Password
export const INIT_EMAIL_CHECK = "INIT_EMAIL_CHECK";
export const SET_EMAIL_MESSAGE_FALSE = "SET_EMAIL_MESSAGE_FALSE";
export const SET_EMAIL_FINAL_MESSAGE = "SET_EMAIL_FINAL_MESSAGE";
export const SET_FAILED_EMAIL_MESSAGE = "SET_FAILED_EMAIL_MESSAGE";
//Forget Password Ends

// change password
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";


//user profile data ends

//Upload Files
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const CLOSE_STATUS_LIBRARY = "CLOSE_STATUS_LIBRARY"
export const SET_FOCUS_ROW = "SET_FOCUS_ROW";
//Alerts
export const HANDLE_ALERT_CLOSE = "HANDLE_ALERT_CLOSE";
export const HANDLE_ALERT_DISPLAY = "HANDLE_ALERT_DISPLAY";

//Check for media Elements status
export const HANDLE_MEDIA_EVENTS = "HANDLE_MEDIA_EVENTS";

export const HANDLE_PROFILE_PIC = "HANDLE_PROFILE_PIC";

//Tenant Data
export const GET_TENANT_DATA_SUCCESS = "GET_TENANT_DATA_SUCCESS";
export const GET_TENANT_DATA_FAILED = "GET_TENANT_DATA_FAILED";

//update password
export const INITIATE_PASSWORD_UPDATE = "INITIATE_PASSWORD_UPDATE";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS"
export const UPDATE_PASSWORD_FAILED = "UPDATE_PASSWORD_FAILED"

//search
export const TOGGLE_SEARCH_BAR = "TOGGLE_SEARCH_BAR";
export const SEARCH_CLICK = "SEARCH_CLICK";
export const SEARCH_CLEAR = "SEARCH_CLEAR";

//publish
export const SET_PUBLISH_LIBRARY = "SET_PUBLISH_LIBRARY";
export const FAILED_PUBLISH_LIBRARY = "FAILED_PUBLISH_LIBRARY";
export const INIT_PUBLISH_LIBRARY_WATCH = "INIT_PUBLISH_LIBRARY_WATCH";
export const CLOSE_PUBLISH_LIBRARY = "CLOSE_PUBLISH_LIBRARY";

//published_version
export const SET_PUBLISHED_VERSION_LIBRARY = "SET_PUBLISHED_VERSION_LIBRARY";
export const FAILED_PUBLISHED_VERSION_LIBRARY = "FAILED_PUBLISHED_VERSION_LIBRARY";
export const INIT_PUBLISHED_VERSION_LIBRARY_WATCH = "INIT_PUBLISHED_VERSION_LIBRARY_WATCH";
export const CLOSE_PUBLISHED_VERSION_LIBRARY = "CLOSE_PUBLISHED_VERSION_LIBRARY";
export const RESET_PUBLISHED_VERSION = "RESET_PUBLISHED_VERSION";

//public export
export const INIT_PUBLIC_EXPORT_CONTENT_ITEM_WATCH = "INIT_PUBLIC_EXPORT_CONTENT_ITEM_WATCH";
export const SET_PUBLIC_EXPORT_CONTENT_ITEM = "SET_PUBLIC_EXPORT_CONTENT_ITEM";
export const FAILED_PUBLIC_EXPORT_CONTENT_ITEM = "FAILED_PUBLIC_EXPORT_CONTENT_ITEM";
export const UNSET_PUBLIC_EXPORT_CONTENT_ITEM = "UNSET_PUBLIC_EXPORT_CONTENT_ITEM";

// user details
export const GET_PROFILES = "GET_PROFILES"; 