/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as FilterSvg } from 'assets/Icons/filter.svg';

import { Ripple } from '@rmwc/ripple';

type Props = {
  filtersCount: number;
  onClick: () => void;

  disabled?: boolean;
};

export const InsightFilterButton = ({
  filtersCount,
  disabled = false,
  ...props
}: Props) => {
  return (
    <button
      className="button large btn-primary"
      tw="(mb-0 flex justify-center items-center gap-x-2.5 shadow w-[unset] min-w-[unset] pl-[1rem] pr-[1.2rem])!"
      onClick={() => props.onClick()}
      disabled={disabled}
    >
      <span tw="flex">
        <FilterSvg />
      </span>

      <span>Filters</span>

      <div tw="flex items-center justify-center ml-1.5">
        <Ripple>
          <div tw="min-w-[2.2rem] h-[2.2rem] shadow-sm rounded-full flex justify-center items-center bg-white text-sonnant-purple-3">
            <span tw="px-1.5">{filtersCount}</span>
          </div>
        </Ripple>
      </div>
    </button>
  );
};
