/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { css } from '@emotion/react';
import tw from 'twin.macro';
import { Loader } from 'components/loader/loader';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actionsTypes from '../../actions/types';
import { invalidEmail } from '../../utils/utils';
import CloseSVG from '../UiControls/closeButtonSVG/closeSVG';
import { Notification } from '../UiControls/notification/Notification';
import MailImg from 'assets/Icons/username-login-icon.png';
import { Modal } from 'components/UiControls/modal/modal';
import { AuthService } from 'services';

interface ForgotPasswordProps {
  setModalOpen: () => void;
  show: boolean;
}
export const ForgotPassword = ({ setModalOpen, show }: ForgotPasswordProps) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subdomain, setSubdomain] = useState<any>([]);
  const [selectAccount, setSelectAccount] = useState<any>(null);
  const [disableResetInput, setDisableResetInput] = useState<boolean>(false);
  const [disableResetButton, setDisableResetButton] = useState<boolean>(false);

  const [forgetEmail, setForgetEmail] = useState<string>('');
  const [error, setError] = useState<{
    emailError: boolean;
    passwordError: boolean;
    apiError: boolean;
    apiErrorMessage: string;
    emailMessage: string;
  }>({
    emailError: false,
    passwordError: false,
    apiError: false,
    apiErrorMessage: '',
    emailMessage: '',
  });
  const forgetEmailData = useSelector((state: any) => state.forgetPassword);
  const [errorForget, setErrorForget] = useState<{
    error: boolean;
    errorMessage: string;
  }>({
    error: false,
    errorMessage: '',
  });

  const handleForgetEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForgetEmail(e.target.value.toLowerCase());
  };

  const forgetPasswordAsync = async (username: string, subdomain: string) => {
    dispatch({
      type: actionsTypes.INIT_EMAIL_CHECK,
      username: username,
      subdomain: subdomain,
    });

    try {
      await AuthService.forgetPassword({ subdomain, username });

      dispatch({
        type: actionsTypes.SET_EMAIL_FINAL_MESSAGE,
        payload: { success: true },
      });
    } catch (error: any) {
      if (error?.response) {
        dispatch({
          type: actionsTypes.SET_FAILED_EMAIL_MESSAGE,
          payload: { errorMessage: error?.response?.data },
        });
      } else if (error?.message) {
        dispatch({
          type: actionsTypes.SET_FAILED_EMAIL_MESSAGE,
          payload: { errorMessage: error?.message },
        });
      } else {
        dispatch({
          type: actionsTypes.SET_FAILED_EMAIL_MESSAGE,
          payload: { errorMessage: 'Something went wrong' },
        });
      }
    }
  };

  useEffect(() => {
    if (forgetEmail?.length === 0) {
      setDisableResetButton(true);
    }

    if (forgetEmail?.length > 0) {
      setDisableResetButton(false);
    }
  }, [forgetEmail]);

  useEffect(() => {
    if (subdomain.length === 1) {
      forgetPasswordAsync(forgetEmail, subdomain[0]);
    }

    if (subdomain.length > 1) {
      setSelectAccount(subdomain[0]);
    }
  }, [subdomain]);

  const handleForgetPass = (e: any) => {
    e.preventDefault();

    if (subdomain.length === 0) {
      getSubdomainByEmail();
      return;
    }

    if (subdomain.length >= 1) {
      setErrorForget((preValue) => ({
        ...preValue,
        error: false,
        errorMessage: '',
      }));
      forgetPasswordAsync(forgetEmail, selectAccount);
    }
  };

  const handleCloseForget = (property: any) => {
    setErrorForget((preValue) => ({ ...preValue, [property]: false }));
    setDisableResetInput(false);
  };

  const handleModalClose = () => {
    setModalOpen();
    setForgetEmail('');
    setSubdomain([]);
    dispatch({ type: actionsTypes.SET_EMAIL_MESSAGE_FALSE });
    setDisableResetInput(false);
    setErrorForget((preValue) => ({
      ...preValue,
      error: false,
      errorMessage: '',
    }));
  };

  const getSubdomainByEmail = () => {
    setIsLoading(true);
    AuthService.getSubdomain({ email: forgetEmail })
      .then(({ data: result }) => {
        setIsLoading(false);
        setSubdomain(result);
        setSelectAccount('');
        setDisableResetInput(true);
        if (result.length === 0) {
          setError({
            ...error,
            emailMessage: 'User email not found.',
            emailError: true,
          });

          setDisableResetInput(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log('error', error);
      });
  };

  const handleClose = (property: any) => {
    setError((preValue: any) => ({ ...preValue, [property]: false }));
  };

  return (
    <>
      <Modal show={show} modalClosed={handleModalClose}>
        <div className="small resetPasswordPopup" id="resetPasswordPopup">
          {(isLoading || forgetEmailData.loading) && (
            <div className="loader__component">
              <Loader />
            </div>
          )}
          <div className="grid-x grid-margin-x">
            <div className="cell grid-padding-x small-12">
              <h2 className="resetHeader">Reset Password</h2>
              {errorForget.error && (
                <p className="topMessage error">
                  {' '}
                  {errorForget.errorMessage}
                  <CloseSVG name={'error'} close={handleCloseForget} />
                </p>
              )}
              {forgetEmailData.error && (
                <p className="topMessage error">
                  {forgetEmailData.errorMessage}
                  <CloseSVG
                    name={'error'}
                    close={() =>
                      dispatch({ type: actionsTypes.SET_EMAIL_MESSAGE_FALSE })
                    }
                  />
                </p>
              )}
              {forgetEmailData.success && (
                <p className="topMessage success" tw="bg-sonnant-green!">
                  {`A reset password email was sent to: ${forgetEmail}`}
                </p>
              )}
            </div>
          </div>
          {!forgetEmailData.success && (
            <div className="userInfo" tw="mt-5">
              <form onSubmit={handleForgetPass}>
                <div className="grid-x grid-margin-x">
                  <div className="cell grid-padding-x small-12">
                    <label>
                      Enter your Email Address
                      <input
                        type="text"
                        name="username"
                        className={error.emailError ? 'error' : ''}
                        value={forgetEmail}
                        disabled={disableResetInput}
                        onChange={handleForgetEmail}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            if (forgetEmail === '') {
                              setError({
                                ...error,
                                emailMessage:
                                  'Email Address is a mandatory field.',
                                emailError: true,
                              });
                              return;
                            }

                            const isInvalidEmail = invalidEmail(
                              forgetEmail?.trim(),
                            );

                            if (isInvalidEmail) {
                              setError({
                                ...error,
                                emailMessage:
                                  'This email address is considered invalid.',
                                emailError: true,
                              });
                              return;
                            }

                            setIsLoading(true);

                            getSubdomainByEmail();
                          }
                        }}
                        onBlur={() => {
                          if (forgetEmail === '') {
                            setError({
                              ...error,
                              emailMessage:
                                'Email Address is a mandatory field.',
                              emailError: true,
                            });
                            return;
                          }

                          const isInvalidEmail = invalidEmail(
                            forgetEmail?.trim(),
                          );
                          if (isInvalidEmail) {
                            setError({
                              ...error,
                              emailMessage:
                                'This email address is considered invalid.',
                              emailError: true,
                            });
                            return;
                          }
                        }}
                        placeholder="John@example.com"
                        onInput={() =>
                          setError({ ...error, emailError: false })
                        }
                      />
                      <Notification
                        name={'emailError'}
                        close={handleClose}
                        type={error.emailError ? 'error' : null}
                        message={error.emailMessage}
                      />
                      <img
                        src={MailImg}
                        alt="Username"
                        tw="absolute width[3.4rem] top[4.4rem] right-5"
                      />
                    </label>
                  </div>
                </div>

                {forgetEmail && subdomain && subdomain.length > 1 && (
                  <div className="grid-x grid-margin-x">
                    <div className="cell grid-padding-x small-12">
                      <label tw="text-sonnant-blue font-medium font-size[1.5rem]! relative">
                        Select your account
                        <select
                          id="account"
                          required
                          name="account"
                          size={10}
                          css={selectAccountCss}
                          onChange={(e) => {
                            setSelectAccount(e.target.value);
                          }}
                          value={selectAccount}
                        >
                          {subdomain.map((item: any, i: any) => (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </label>
                    </div>
                  </div>
                )}

                <div className="grid-x grid-margin-x rightAlign">
                  <button
                    type="button"
                    className="button btn-secondary large cancel cell small-3"
                    onClick={handleModalClose}
                    data-close=""
                  >
                    Cancel
                  </button>
                  <button
                    disabled={disableResetButton}
                    type="submit"
                    className="button btn-primary  apply large cell small-3 auto"
                  >
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
const selectAccountCss = css`
  ${tw`height[12rem] p-0 border[1px solid transparent]! overflow-y-auto`}

  option {
    ${tw`cursor-pointer padding[2px 4px] rounded-sm`}
  }
`;
