/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { secToTime } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { OmnyFeed } from 'utils/models';
import { AUDIO_THUMBNAIL } from '../twin.styles';

interface Props {
  omny: OmnyFeed;
  handleCheckSelected: (id: string) => void;
  selectedIds: string[];
}

export const PaginationPreviewItem = ({
  omny,
  handleCheckSelected,
  selectedIds,
  ...props
}: Props) => {
  const handleToggleCheckbox = () => {
    handleCheckSelected(omny.id);
  };

  return (
    <div tw="flex items-center text-sonnant-dark hover:(bg-sonnant-grey-light)! cursor-pointer p-3">
      <label tw="flex[unset]! cursor-pointer">
        <div>
          <input
            name="caption"
            checked={selectedIds.includes(omny.id)}
            onChange={handleToggleCheckbox}
            type="checkbox"
          />
          <span className="checkmark" style={{ top: '5px' }}></span>
        </div>
      </label>

      <div tw="mr-6 border[1xps solid red]">
        <img
          tw="max-width[15rem] w-[15rem] min-h-[10rem] shadow-sm"
          src={omny?.imageURL || AUDIO_THUMBNAIL}
          alt={omny?.title}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = AUDIO_THUMBNAIL;
          }}
        />
      </div>

      <div>
        <div
          tw="font-semibold text-sonnant-dark"
          className="line-clamp-3"
          title={omny?.title}
        >
          {omny?.title}
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: omny?.description ?? omny?.summary,
          }}
          tw="text-14 text-sonnant-grey-4"
          className="line-clamp-3"
        >
          {/* {feed?.summary ?? feed?.description} */}
        </div>

        <div tw="text-18 mt-2 font-medium opacity-95">
          {omny?.duration && <>{secToTime(omny?.duration)}</>}
        </div>
      </div>
    </div>
  );
};
