import { SagaAction } from 'utils/models';
import * as types from '../actions/types';

interface InitialStateType {
  resetTimer: boolean;
  isActive: boolean;
}

const initialState = {
  resetTimer: false,
  isActive: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export const idleTimerReducer = (
  state: InitialStateType = initialState,
  action: SagaAction,
) => {
  switch (action.type) {
    case types.HANDLE_MEDIA_EVENTS: {
      return { ...state, isActive: action.isActive };
    }
    default:
      return { ...state };
  }
};
