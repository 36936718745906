import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserService } from 'services';
import { SpeakerDiarisation, SpeakerState } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { getTenantidFromIdToken } from 'utils/utils';
import { v4 } from 'uuid';

export const setSpeakerDiarisationAction = createAction(
  'setSpeakerDiarisation',
);
export const toggleSpeakerDiarisationAction = createAction(
  'toggleSpeakerDiarisation',
);

const speakerIdentificationSlice = createSlice({
  name: 'speaker',
  initialState: {
    hasSpeakerDiarisation: true,
    hasSpeakerIdentification: false,

    recommendedSensitivity: 85, // percent

    speakers: [] as SpeakerDiarisation[],
  } as SpeakerState,
  reducers: {
    setSpeakerIdentification: (
      state,
      action: PayloadAction<SpeakerDiarisation[]>,
    ) => {
      state.speakers = action.payload;
    },
    setSpeakerState: (state, action: PayloadAction<SpeakerState>) => {
      state = action.payload;
      return state;
    },
    toggleSpeakerDiarisation: (state, action: PayloadAction<boolean>) => {
      state.hasSpeakerDiarisation = action.payload;
      // turn off speaker identification
      if (!action.payload) {
        state.hasSpeakerIdentification = false;
      }
    },
    toggleSpeakerIdentification: (state, action: PayloadAction<boolean>) => {
      state.hasSpeakerIdentification = action.payload;
    },
    setSpeakerSensitivity: (state, action: PayloadAction<number>) => {
      state.recommendedSensitivity = action.payload;
    },
    updateSpeakerIdentificationItem: (
      state,
      action: PayloadAction<SpeakerDiarisation>,
    ) => {
      const foundIndex = state.speakers.findIndex(
        (item) => item.id === action.payload.id,
      );

      if (foundIndex >= 0) {
        state.speakers[foundIndex] = { ...action.payload };
      }
    },
    deleteSpeakerIdentificationItem: (state, action: PayloadAction<string>) => {
      const foundIndex = state.speakers.findIndex(
        (item) => item.id === action.payload,
      );

      if (foundIndex >= 0) {
        state.speakers.splice(foundIndex, 1);
      }
    },
    addSpeakerIdentificationItem: (state, action: PayloadAction<string>) => {
      const hasDuplicate = state.speakers.some(
        (speaker) =>
          speaker.speakerName.toLowerCase() === action.payload.toLowerCase(),
      );

      if (hasDuplicate) {
        customToast.error('This speaker already existed');
      } else {
        let newData = {
          id: v4(),
          speakerName: action.payload,
          hasPrompt: true,
          hasIdentify: true,
          hasDisableIdentify: false,
        };

        // Identity turn off if diarisation or identification is off
        if (!state.hasSpeakerDiarisation || !state.hasSpeakerIdentification) {
          newData = {
            ...newData,
            hasIdentify: false,
            hasDisableIdentify: true,
          };
        }

        state.speakers.push(newData);
      }
    },
    triggerSaveSpeakers: (
      state,
      {
        payload,
      }: PayloadAction<{
        newSpeakers: SpeakerDiarisation[];
        runBackground: boolean;
      }>,
    ) => {
      (async () => {
        try {
          const tenantid = getTenantidFromIdToken();

          const saveAsync = UserService.savePreference({
            tenantid,
            speakers: JSON.stringify({
              speaker_config: {
                speaker_diarisation:
                  state.hasSpeakerDiarisation,
                speaker_diarisation_sensitivity:
                  state.recommendedSensitivity,
                speaker_identification:
                  state.hasSpeakerIdentification,
              },
              speaker_items: payload.newSpeakers,
            }),
          });

          if (!payload?.runBackground) {
            customToast.promise(saveAsync, {
              loading: 'Saving changes...',
              success: 'Saved successfully',
              error: 'Save failed',
            });
          }

          await saveAsync;
        } catch (error: any) {
          console.log('error :>> ', error);
        }
      })();
    },
  },
});

export const {
  setSpeakerIdentification,
  setSpeakerState,
  addSpeakerIdentificationItem,
  toggleSpeakerDiarisation,
  toggleSpeakerIdentification,
  setSpeakerSensitivity,
  updateSpeakerIdentificationItem,
  deleteSpeakerIdentificationItem,
  triggerSaveSpeakers,
} = speakerIdentificationSlice.actions;

export const speakerIdentificationReducer = speakerIdentificationSlice.reducer;
