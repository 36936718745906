/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Tooltip, TooltipAlignT } from '@rmwc/tooltip';
import { ReactComponent as QuestionSvg } from 'assets/Icons/Vector_question.svg';
import { Notification } from 'components/UiControls/notification/Notification';
import React, { memo, ReactElement } from 'react';
import tw from 'twin.macro';

type Props = {
  message: string | ReactElement;
  customStyle?: SerializedStyles;
  contentWidth?: number | string;
  heightSvg?: number;
  widthSvg?: number;
  align?: TooltipAlignT;
  open?: boolean;
};

export const QuestionTooltipHint = memo((props: Props) => (
  <div>
    <Tooltip
      align={props?.align ?? 'bottomLeft'}
      tw="z-index[100]"
      open={props?.open}
      content={
        <div
          style={{ width: props?.contentWidth || '30rem' }}
          tw="font-size[1.6rem] line-height[1.9rem] border-radius[3px]"
          css={[
            !props?.align && tw`top-[4px] left-[-1.7rem] absolute`,
            props?.align === 'top' && customArrowCss,
          ]}
        >
          <Notification
            hideCloseButton
            name="share-tooltip"
            type="information"
            message={
              <div tw="py-1">
                <p tw="mb-0">{props.message}</p>
              </div>
            }
          />
        </div>
      }
      css={customTooltipStyles}
    >
      <div css={[props.customStyle, tw`flex`]} className="questionsvg">
        <QuestionSvg
          width={props.heightSvg ?? 16}
          height={props.widthSvg ?? 16}
        />
      </div>
    </Tooltip>
  </div>
));

const customTooltipStyles = css`
  ${tw`font-size[1rem]`}

  .rmwc-tooltip-inner {
    background: none;
  }
`;

const customArrowCss = css`
  .notificationWrapper::before {
    ${tw`(m-0 bottom[2rem] right[6rem] absolute transform[rotate(135deg)])!`}
  }
`;
