/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { HTMLProps } from 'react';

type Props = { label: string } & HTMLProps<HTMLInputElement>;

export const SimpleRadio = ({ label, checked, onChange, ...props }: Props) => {
  return (
    <label tw="flex items-center gap-x-1 hover:cursor-pointer">
      <input
        type="radio"
        tw="w-[2rem] accent-color[#5451f6] m-0"
        checked={checked}
        onChange={onChange}
        {...props}
      />
      <span tw="text-15 mt-[2px]">{label}</span>
    </label>
  );
};
