/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import {
  cloneDeep,
  defaultTo,
  isEmpty,
  isEqual,
  isNil,
  map,
  omit,
  set,
  toNumber,
  uniqWith,
} from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { RootState } from 'reducers';
import { match } from 'ts-pattern';

import { ReactComponent as IntegrateSvg } from 'assets/Icons/power.svg';
import { ReactComponent as Upload } from 'assets/Icons/upload_normal.svg';

import {
  getDetailCompareIntegration,
  toOmnyPreview,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import Loader from 'components/loader/loader';
import {
  AddIntegrationModal,
  AddIntegrationModalRef,
} from 'components/shared/AddIntegrationModal/AddIntegrationModal';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { Hint } from 'components/shared/Hint';
import { HintDisallowed } from 'components/shared/HintDisallowed';
import { Info } from 'components/shared/Info';
import {
  MegaphoneBulkUploadModal,
  MegaphoneBulkUploadModalRef,
} from 'components/shared/MegaphoneBulkUploadModal/MegaphoneBulkUploadModal';
import {
  BulkUploadRef,
  OmnyBulkUploadModal,
} from 'components/shared/OmnyBulkUploadModal/BulkUploadModal';
import {
  PaginationPreviewModal,
  PaginationPreviewModalRef,
} from 'components/shared/PaginationPreviewModal/PaginationPreviewModal';
import { ResetBottomBar } from 'components/shared/ResetBottomBar/ResetBottomBar';
import {
  MegaphonePreviewProgramPayload,
  MegaphoneUploadPayload,
  OmnyPreviewProgramPayload,
  OmnyUploadPayload,
  UploadService,
  UserService,
} from 'services';
import {
  addMegaphoneIntegration,
  setMegaphoneSettings,
} from 'slices/megaphone.slice';
import { addIntegration, setOmnySettings } from 'slices/omny.slice';
import { tierSelector } from 'slices/payment.slice';
import { endOfDayISO, startOfDayISO } from 'utils/date.util';
import { defaultSetting } from 'utils/default/defaultSetting';
import {
  CollectionOptionEnum,
  IntegrationEnum,
  ModalOptions,
} from 'utils/enum';
import { differenceSettingsWithoutId, isEveryFalsy } from 'utils/generic.util';
import {
  ConfirmModalProps,
  IntegrationOptions,
  MegaphoneIntegrationOption,
  OmnyFeed,
} from 'utils/models';
import { customToast } from 'utils/toast.util';
import { getTenantidFromIdToken } from 'utils/utils';
import { MegaphoneIntegrationItem } from '../MegaphoneIntegration/MegaphoneIntegrationItem';
import { IntegrationItem } from './IntegrationItem';

export type IntegrationSetting = {
  omnyIntegrations: IntegrationOptions[];
  megaphoneIntegrations: MegaphoneIntegrationOption[];
};

export type PreviewState = {
  previewList: OmnyFeed[];
  totalItemCount: number;
};

export const defaultPreviewState: PreviewState = {
  previewList: [],
  totalItemCount: 0,
};

export const IntegrationSettings = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(
    (state: RootState) => state.payment.subscription.isLoading,
  );

  const tier = useSelector(tierSelector);
  const notSubscriber = tier.isTrial || tier.isPAYG;

  const omny = useSelector((state: RootState) => state.omny);

  const omnySettings = omny.settings;

  const megaphone = useSelector((state: RootState) => state.megaphone);
  const megaphoneSettings = megaphone.settings;

  const confirmModalRef = useRef<any>();

  const bulkModalRef = useRef<BulkUploadRef>();
  const megaphoneBulkModalRef = useRef<MegaphoneBulkUploadModalRef>();

  const omnyBulkPreviewModalRef = useRef<PaginationPreviewModalRef>();
  const megaphoneBulkPreviewModalRef = useRef<PaginationPreviewModalRef>();

  const addIntegrationModalRef = useRef<AddIntegrationModalRef>();

  const [initialState, setInitialState] = useState<IntegrationSetting>({
    omnyIntegrations: omnySettings,
    megaphoneIntegrations: megaphoneSettings,
  });

  // Omny states
  const [isPreviewLoading, toggleIsPreviewLoading] = useToggle(false);
  const [omnyPreviewState, setOmnyPreviewState] =
    useState<PreviewState>(defaultPreviewState);
  const [apiOmnyPayload, setApiOmnyPayload] =
    useState<OmnyPreviewProgramPayload>({
      apiKey: '',
      programId: null,
      playlistId: null,
      cursor: 1,
    });

  const isOmnyUploadByPlaylist = !isNil(apiOmnyPayload.playlistId);

  // Megaphone states
  const [isPreviewMegaphoneLoading, toggleIsPreviewMegaphoneLoading] =
    useToggle(false);
  const [megaphonePreviewState, setMegaphonePreviewState] =
    useState<PreviewState>(defaultPreviewState);
  const [apiMegaphonePayload, setApiMegaphonePayload] =
    useState<MegaphonePreviewProgramPayload>({
      apiKey: '',
      networkId: '',
      programId: '',
      cursor: 1,
    });

  const shouldDisableReset = isEqual(
    map(defaultSetting.integrationSettings, (item) => omit(item, ['id'])),
    map(initialState, (item) => omit(item, 'id')),
  );

  const shouldEnableUpload =
    tier.isEnterprise || tier.isEnterpriseTrial || tier.isInternal;

  const isEmptyIntegrations =
    isEmpty(omnySettings) && isEmpty(megaphoneSettings);

  useEffect(() => {
    if (!apiOmnyPayload.apiKey) return;

    if (isEveryFalsy(apiOmnyPayload.programId, apiOmnyPayload.playlistId))
      return;

    fetchOmnyPreviewAsync();
  }, [apiOmnyPayload]);

  useEffect(() => {
    if (
      isEmpty(apiMegaphonePayload.apiKey) ||
      isEmpty(apiMegaphonePayload.networkId) ||
      isEmpty(apiMegaphonePayload.programId)
    )
      return;

    fetchMegaphonePreviewAsync();
  }, [apiMegaphonePayload]);

  useEffect(() => {
    setInitialState({
      omnyIntegrations: omnySettings,
      megaphoneIntegrations: megaphoneSettings,
    });
  }, [isLoading]);

  const addOmnyIntegration = () => {
    dispatch(addIntegration({ type: IntegrationEnum.OMNY }));
  };

  const addNewMegaphoneIntegration = () => {
    dispatch(addMegaphoneIntegration());
  };

  const shouldDisable = () => {
    const isRemovedOmnySettings =
      omnySettings.length < initialState.omnyIntegrations.length;

    const isRemovedMegaphoneSettings =
      megaphoneSettings.length < initialState.megaphoneIntegrations.length;

    if (isRemovedOmnySettings || isRemovedMegaphoneSettings) return false;

    const omnyDifference = differenceSettingsWithoutId(
      omnySettings,
      initialState.omnyIntegrations,
    );

    const megaphoneDifference = differenceSettingsWithoutId(
      megaphoneSettings,
      initialState.megaphoneIntegrations,
    );

    const isSettingsUnchanged =
      isEmpty(omnyDifference) && isEmpty(megaphoneDifference);

    return isSettingsUnchanged;
  };

  const handleReset = () => {
    dispatch(setOmnySettings(initialState.omnyIntegrations));
    dispatch(setMegaphoneSettings(initialState.megaphoneIntegrations));
  };

  const handleResetDefault = async () => {
    const result = await confirmModalRef.current?.show({
      title: 'Confirm reset to default',
      confirmText: 'Confirm',
      message: (
        <div>
          Are you sure you want to reset preferences configuration to default?
        </div>
      ),
    } as ConfirmModalProps);

    if (result !== ModalOptions.YES) return;

    setInitialState({
      omnyIntegrations: defaultSetting.integrationSettings,
      megaphoneIntegrations: [],
    });

    dispatch(setOmnySettings(defaultSetting.integrationSettings));
    dispatch(setMegaphoneSettings([]));

    handleApply({
      omnyIntegrations: defaultSetting.integrationSettings,
      megaphoneIntegrations: [],
    });
  };

  const handleApply = async (
    data: IntegrationSetting = {
      omnyIntegrations: omnySettings,
      megaphoneIntegrations: megaphoneSettings,
    },
  ) => {
    try {
      const processedOmnyIntegrations = data.omnyIntegrations?.map((item) => {
        // Default null for none valid collectionOption
        const newItem: IntegrationOptions = { ...item };
        if (item.collectionOption !== CollectionOptionEnum.COLLECTION) {
          newItem.collectionId = null;
        }

        const newFilter = { ...newItem.filter };
        newFilter.programFilters = newFilter.programFilters?.filter(
          (item) => !isEmpty(item.programId),
        );

        newFilter.titleContains = newFilter.titleContains
          ?.map((title) => title?.trim())
          .filter((title) => !isEmpty(title));

        return { ...newItem, filter: newFilter };
      });

      const processedMegaphoneIntegrations = data.megaphoneIntegrations?.map(
        (item) => {
          // Default null for none valid collectionOption
          const newItem: MegaphoneIntegrationOption = { ...item };
          if (item.collectionOption !== CollectionOptionEnum.COLLECTION) {
            newItem.collectionId = null;
          }

          const newFilter = { ...newItem.filter };
          newFilter.programFilters = newFilter.programFilters?.filter(
            (item) => !isEmpty(item.programId) || !isEmpty(item.networkId),
          );

          return { ...newItem, filter: newFilter };
        },
      );

      // Array of integrations with same API Key and Secret
      const uniqIntegration = uniqWith(
        getDetailCompareIntegration(processedOmnyIntegrations),
        isEqual,
      );

      const uniqMegaphoneIntegration = uniqWith(
        processedMegaphoneIntegrations.map((item) => ({ apiKey: item.apiKey })),
        isEqual,
      );

      const isDuplicatedOmnySettings =
        uniqIntegration?.length !== processedOmnyIntegrations.length;
      const isDuplicatedMegaphoneSettings =
        uniqMegaphoneIntegration?.length !==
        processedMegaphoneIntegrations.length;

      if (isDuplicatedOmnySettings || isDuplicatedMegaphoneSettings) {
        customToast.error(
          'Integration settings cannot be duplicated. Please check and try again.',
        );

        return;
      }
      const tenantid = getTenantidFromIdToken();

      const updateAsync = UserService.savePreference({
        tenantid,
        omny_integrations: processedOmnyIntegrations,
        megaphone_integrations: processedMegaphoneIntegrations,
      });

      customToast.promise(updateAsync, {
        loading: 'Updating...',
        success: 'Integration settings updated',
        error: 'Integration settings update failed',
      });

      await updateAsync;

      dispatch(setOmnySettings(data.omnyIntegrations));
      dispatch(setMegaphoneSettings(data.megaphoneIntegrations));

      setInitialState(data);
    } catch (err: any) {
      console.log('err :>> ', err);
    }
  };

  const handleClickAddIntegration = async () => {
    const result = await addIntegrationModalRef.current?.show({
      title: 'Add integration',
    });

    if (result?.option !== ModalOptions.YES) return;

    if (isNil(result.selectedIntegration)) return;

    match(result.selectedIntegration)
      .with(IntegrationEnum.OMNY, () => {
        addOmnyIntegration();
      })
      .with(IntegrationEnum.MEGAPHONE, () => {
        addNewMegaphoneIntegration();
      })
      .otherwise(() => {});
  };

  const handleClickPreview = async () => {
    const result = await addIntegrationModalRef.current?.show({
      title: 'Bulk upload',
    });

    if (result?.option !== ModalOptions.YES) return;

    if (isNil(result.selectedIntegration)) return;

    match(result.selectedIntegration)
      .with(IntegrationEnum.OMNY, () => {
        handleOmnyPreview();
      })
      .with(IntegrationEnum.MEGAPHONE, () => {
        handleMegaphonePreview();
      })
      .otherwise(() => {});
  };

  // Omny preview
  const handleOmnyPreview = async () => {
    const result = await bulkModalRef.current?.show();

    if (result?.option !== ModalOptions.YES) return;

    if (isNil(result.programId) || isNil(result.apiKey)) return;

    setApiOmnyPayload({
      apiKey: result.apiKey,
      programId: result.programId,
      playlistId: result.playlistId ?? null,
      cursor: 1,
    });

    const previewUploadResult = await omnyBulkPreviewModalRef.current?.show({
      title: 'Omny preview',
    });

    if (previewUploadResult?.option !== ModalOptions.YES) return;

    const payload: OmnyUploadPayload = {
      apiKey: result.apiKey,
      programId: result.programId,
      playlistId: result.playlistId ?? null,
      collectionId: result.collectionId ?? null,

      clipIds: defaultTo(previewUploadResult.selectedIds, []),
      isUploadAll: previewUploadResult.isUploadAll,
      startDate: previewUploadResult.startDate,
      endDate: previewUploadResult.endDate,
    };

    handleUploadOmnyItemsAsync(payload);
  };

  const fetchOmnyPreviewAsync = async () => {
    toggleIsPreviewLoading(true);

    try {
      const { data } = await UploadService.previewOmnyProgram({
        ...apiOmnyPayload,
        startDate: startOfDayISO(apiOmnyPayload.startDate),
        endDate: endOfDayISO(apiOmnyPayload.endDate),
      });

      const omnyPreviewList = data.clips.map(toOmnyPreview);

      const clonedOmnyPreviewState = cloneDeep(omnyPreviewState);

      set(clonedOmnyPreviewState, 'previewList', omnyPreviewList);
      set(clonedOmnyPreviewState, 'totalItemCount', toNumber(data.total_count));

      setOmnyPreviewState(clonedOmnyPreviewState);
    } catch (err) {
      console.log('err', err);
    } finally {
      toggleIsPreviewLoading(false);
    }
  };

  const handleChangeDateOmnyPreview = (
    startDate: Date | null,
    endDate: Date | null,
  ) => {
    setApiOmnyPayload({
      ...apiOmnyPayload,
      startDate,
      endDate,
    });
  };

  const handleChangePageOmnyPreview = (selectedPage: number) => {
    setApiOmnyPayload({
      ...apiOmnyPayload,
      cursor: selectedPage,
    });
  };

  // Megaphone preview
  const handleMegaphonePreview = async () => {
    const result = await megaphoneBulkModalRef.current?.show();

    if (result?.option !== ModalOptions.YES) return;

    if (!result.apiKey || !result.networkId || !result.programId) return;

    setApiMegaphonePayload({
      apiKey: result.apiKey,
      programId: result.programId,
      networkId: result.networkId,
      cursor: 1,
    });

    const previewUploadResult =
      await megaphoneBulkPreviewModalRef.current?.show({
        title: 'Megaphone preview',
      });

    if (previewUploadResult?.option !== ModalOptions.YES) return;

    const payload: MegaphoneUploadPayload = {
      apiKey: result.apiKey,
      programId: result.programId,
      networkId: result.networkId,
      collectionId: result.collectionId ?? null,

      clipIds: defaultTo(previewUploadResult.selectedIds, []),
      isUploadAll: previewUploadResult.isUploadAll,
    };

    handleUploadMegaphoneItemsAsync(payload);
  };

  const fetchMegaphonePreviewAsync = async () => {
    toggleIsPreviewMegaphoneLoading(true);

    try {
      const { data } = await UploadService.previewMegaphoneProgram(
        apiMegaphonePayload,
      );

      const omnyPreviewList = data.clips.map(toOmnyPreview);

      const clonedOmnyPreviewState = cloneDeep(omnyPreviewState);

      set(clonedOmnyPreviewState, 'previewList', omnyPreviewList);
      set(clonedOmnyPreviewState, 'totalItemCount', toNumber(data.total_count));

      setMegaphonePreviewState(clonedOmnyPreviewState);
    } catch (err) {
      console.log('err', err);
    } finally {
      toggleIsPreviewMegaphoneLoading(false);
    }
  };

  const handleChangePageMegaphonePreview = (selectedPage: number) => {
    setApiMegaphonePayload({
      ...apiMegaphonePayload,
      cursor: selectedPage,
    });
  };

  // Upload preview items
  const handleUploadOmnyItemsAsync = async (payload: OmnyUploadPayload) => {
    try {
      const uploadAsync = UploadService.postUploadOmny(payload);

      customToast.promise(uploadAsync, {
        loading: 'Uploading...',
        success: (
          <div>
            <div>Submitted for background processing.</div>
            <div>It may take a few minutes to be ready.</div>
          </div>
        ),
        error: 'Upload failed',
      });

      await uploadAsync;
    } catch (err) {
      console.log('err', err);
    }
  };

  const handleUploadMegaphoneItemsAsync = async (
    payload: MegaphoneUploadPayload,
  ) => {
    try {
      const uploadAsync = UploadService.postUploadMegaphone(payload);

      customToast.promise(uploadAsync, {
        loading: 'Uploading...',
        success: (
          <div>
            <div>Submitted for background processing.</div>
            <div>It may take a few minutes to be ready.</div>
          </div>
        ),
        error: 'Upload failed',
      });

      await uploadAsync;
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <div tw="w-full height[calc(100vh - 13rem)]">
      {isLoading && (
        <div
          className="loader__component"
          tw="height[calc(100vh - 13rem)] top[13rem] opacity-100"
        >
          <Loader />
        </div>
      )}

      <ResetBottomBar
        isShow={!shouldDisable()}
        onReset={handleReset}
        onSubmit={handleApply}
      />

      <ConfirmModal
        ref={confirmModalRef}
        title="Confirm"
        confirmText="Upgrade"
        classes="disable-enable-user-modal-width"
      />

      <OmnyBulkUploadModal ref={bulkModalRef} />
      <MegaphoneBulkUploadModal ref={megaphoneBulkModalRef} />
      <AddIntegrationModal ref={addIntegrationModalRef} />

      <PaginationPreviewModal
        ref={omnyBulkPreviewModalRef}
        isLoading={isPreviewLoading}
        isDateRangeDisabled={isOmnyUploadByPlaylist}
        cursor={apiOmnyPayload.cursor}
        totalItemCount={omnyPreviewState.totalItemCount}
        previewList={omnyPreviewState.previewList}
        onChangeDate={handleChangeDateOmnyPreview}
        onChangePage={handleChangePageOmnyPreview}
      />

      <PaginationPreviewModal
        ref={megaphoneBulkPreviewModalRef}
        isLoading={isPreviewMegaphoneLoading}
        isDateRangeDisabled
        cursor={apiMegaphonePayload.cursor}
        totalItemCount={megaphonePreviewState.totalItemCount}
        previewList={megaphonePreviewState.previewList}
        onChangeDate={() => {}}
        onChangePage={handleChangePageMegaphonePreview}
      />

      <div className="btnReset" tw="flex mt-3">
        <HintDisallowed
          disabled={!shouldEnableUpload}
          hintEnabled="CMS bulk upload"
          hintDisabled="Available on Enterprise Trial and Enterprise. Please upgrade to enable."
        >
          <button
            onClick={handleClickPreview}
            disabled={!shouldEnableUpload}
            type="button"
            className="button btn-primary primary-btn"
            tw="w-[15rem]! min-width[auto] flex justify-around items-center mt-0!"
            css={uploadCss}
          >
            <Upload fill="white" />
            <div tw="text-16">Bulk upload</div>
          </button>
        </HintDisallowed>
        <Hint
          text="Not available on free trial and PAYG. Please upgrade to enable."
          enterDelay={0}
          arrow
          disabled={!notSubscriber}
          align={'top'}
        >
          <div>
            <button
              type="button"
              disabled={shouldDisableReset}
              className={`success button apply`}
              tw="focus:(border[2px solid #f0f0f0])! border[2px solid #f0f0f0]! mt-0!"
              onClick={handleResetDefault}
            >
              Reset
            </button>
          </div>
        </Hint>
      </div>
      <div tw="grid grid-cols-1 divide-solid divide-x-0 divide-y divide-sonnant-grey-light w-full">
        {megaphoneSettings.map((integration, index) => (
          <MegaphoneIntegrationItem key={index} integration={integration} />
        ))}

        {omnySettings.map((integration, index) => (
          <IntegrationItem key={index} integration={integration} />
        ))}

        {/* Empty integrations placeholder */}
        {isEmptyIntegrations && (
          <div tw="margin-left[5rem] my-5">
            <Info text="There are no configured integrations. Try to add a new one." />
          </div>
        )}
      </div>

      {tier.isSubscriber && (
        <div tw="min-width[20rem] width[50rem] padding[5rem] pt-0">
          <button
            type="button"
            className="button btn-primary large"
            tw="(flex items-center max-width[unset] w-auto pr-3)!"
            onClick={handleClickAddIntegration}
          >
            <span tw="flex items-center mr-1">
              <IntegrateSvg tw="fill[#fff]" />
            </span>
            Add integration
          </button>
        </div>
      )}
    </div>
  );
};

const uploadCss = css`
  svg {
    ${tw`sm-down:(m-0)!`}
  }
`;
