/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { RichTextEditor } from 'components/shared/RichTextEditor/RichTextEditor';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';

export const ParagraphEditor = () => {
  const player = useSelector((state: RootState) => state.player);
  const [currentTime, setCurrentTime] = useState(0);

  const handleVideoTimeUpdate: any = useCallback(() => {
    setCurrentTime(window.Video?.currentTime || 0);
  }, []);

  useEffect(() => {
    if (!window?.Video) return;

    window.Video.addEventListener('timeupdate', handleVideoTimeUpdate);

    return () => {
      window.Video.removeEventListener('timeupdate', handleVideoTimeUpdate);
    }
  }, []);

  // const paragraphRef = useLatest(paragraph);
  // const partRef = useRef(0);
  // const currentSection = useRef<any>([]);
  const [partialSection, setPartialSection] = useState<any>(player?.paragraph);
  // const FIRST_PART_AMOUNT = 100;
  
  // const getSection = async () => {

  //   const partial = paragraphRef.current.slice(
  //     FIRST_PART_AMOUNT * partRef.current,
  //     FIRST_PART_AMOUNT * (partRef.current + 1),
  //   );

  //   partRef.current += 1;
  //   currentSection.current = currentSection.current.concat(partial);
  //   setPartialSection(currentSection.current);
  //   await waitAsync(1000);
  //   if (FIRST_PART_AMOUNT * partRef.current < paragraphRef.current.length) {
  //     getSection();
  //   } else {
  //     dispatch(setIsCompleteLazy(true));
  //   }
  // };

  // useEffect(() => {
  //   if (!paragraphRef.current.length) return;
  //   getSection();
  //   dispatch(setIsCompleteLazy(false));
  // }, [paragraph]);

  useEffect(() => {
    if (isEmpty(player.paragraph) || isEmpty(partialSection)) return;

    setPartialSection(player.paragraph);
  }, [player.paragraph]);

  return (
    <div>
      <ErrorBoundary
        fallback={
          <div tw="font-medium text-sonnant-grey-6 text-16 mt-5">
            Cannot load content item's transcripts
          </div>
        }
        onError={(e) => console.log('Paragraph Error:', e)}
      >
        <RichTextEditor
          // document={paragraph}
          document={partialSection ?? []}
          videoTimePosition={currentTime}
          setDocument={setPartialSection}
        />
      </ErrorBoundary>
    </div>
  );
};
