/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Modal } from 'components/UiControls/modal/modal';
import { MediaReadyState, ModalOptions } from 'utils/enum';
import { useDispatch, useSelector } from 'react-redux';
import { toggleThumbnailModal } from 'slices/global.slice';
import { playPauseStyle } from '../twin.styles';
import Loader from 'components/loader/loader';
import { ReactComponent as Pause } from 'assets/Icons/fPause.svg';
import { ReactComponent as Play } from 'assets/Icons/fPlay.svg';
import { ReactComponent as ForwardSvg } from 'assets/Icons/fast_forward_24dp.svg';
import { ReactComponent as RewindSvg } from 'assets/Icons/fast_rewind_24dp.svg';
import { ReactComponent as CameraSvg } from 'assets/Icons/photo-camera.svg';
import {
  isDisplayClips,
  secToTime,
  shouldUsePlyr,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { useToggle, useVideo } from 'react-use';
import { WaveSurferVideo } from '../Wavesurfer/WaveSurferVideo';
import { RootState } from 'reducers';
import { isEmpty, isNil } from 'lodash';
import axios from 'axios';
import { setWaveform } from 'slices/media.slice';
import { QuestionTooltipHint } from '../Tooltip/QuestionTooltipHint';
import { UploadService, MediaService } from 'services';
import Plyr from 'plyr';
import { customToast } from 'utils/toast.util';

interface IPromiseParams {
  resolve: (option: ModalOptions) => void;
  reject: (err: any) => void;
}

interface IProps {
  title?: string;
  message?: string | ReactElement;
  cancelText?: string;
  confirmText?: string;
}

export const ThumbnailModal = forwardRef(
  (props: IProps, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [isAudio, setIsAudio] = useState(false);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    const advancedSearch = useSelector(
      (state: RootState) => state.advancedSearch,
    );

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (params: any): Promise<unknown> => {
      if (!isEmpty(params)) {
        setMediaid(params?.mediaid);
        setTitle(params?.title);
        setVersioncount(params?.versioncount);
        setIsAudio(!!params?.isAudio);
        setThumb(params?.thumbnail);

        fetchMediaAsync(params?.mediaid, params?.versioncount);
      }

      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const hideModal = () => {
      dispatch(toggleThumbnailModal({}));
      setRawMediaSrc(undefined);
      setIsOpen(false);
      setIsVideoLoaded(false);
      setThumbnailBase64(undefined);
      setThumbnailPoster(undefined);
      setPlyrInstance(null);
    };

    const handleCreate = async () => {
      hideModal();
      const payload = {
        mediaid,
        versioncount,
        timethumbnail: videoState.time,
        type:
          isDisplayClips() || advancedSearch.isShowClipsOnly
            ? 'CLIP'
            : 'LIBRARY',
      } as any;

      if (!isEmpty(thumbnailBase64)) {
        payload.imageBase64 = thumbnailBase64?.split(',')[1];
      }

      const thumbnailAsync = UploadService.uploadThumbnail(payload);

      customToast.promise(thumbnailAsync, {
        loading: 'Sending request...',
        success: 'Request submitted',
        error: 'Thumbnail generation request failed',
      });

      try {
        await thumbnailAsync;
      } catch (error: any) {
        console.log('error :>> ', error);
      }

      promiseInfo.current?.resolve(ModalOptions.YES);
    };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current?.resolve(ModalOptions.CANCEL);
    };

    const fetchMediaAsync = async (mediaid: string, versioncount?: string) => {
      try {
        toggleMediaLoading(true);
        const mediaResponse = await MediaService.getPreview({
          mediaid,
          versioncount,
        });

        setRawMediaSrc(mediaResponse.data.url);
        setRawVttPath(mediaResponse.data.subtitleurl);
        setThumbnailPoster(mediaResponse.data?.thumbnail_url);
        setIsVideoLoaded(true);

        if (mediaResponse.data?.jsonfileurl) {
          axios
            .get(mediaResponse.data?.jsonfileurl)
            .then(({ data: transcriptData }) => {
              if (transcriptData?.waveform) {
                dispatch(setWaveform(transcriptData.waveform));
              }
            });
        }
      } catch (err: any) {
        console.log('HTTP ERROR', err);
        customToast.error('Something went wrong');
      } finally {
        toggleMediaLoading(false);
      }
    };

    const media = useSelector((state: RootState) => state.media);

    const [rawMediaSrc, setRawMediaSrc] = useState<string | undefined>();
    const [, setRawVttPath] = useState<string>();
    const [, toggleMediaLoading] = useToggle(true);
    const [thumbnailPoster, setThumbnailPoster] = useState<
      string | undefined
    >();

    const [thumbnailBase64, setThumbnailBase64] = useState<
      string | null | undefined
    >();
    const thumbnailFileRef = useRef<any>();

    const [mediaid, setMediaid] = useState('');
    const [title, setTitle] = useState('');
    const [versioncount, setVersioncount] = useState('');
    const [thumb, setThumb] = useState<string | undefined>(undefined);
    const [plyrInstance, setPlyrInstance] = useState<Plyr | null>(null);

    const [video, videoState, controls, videoRef] = useVideo(
      <video
        id="media_player"
        tw="w-full! rounded-md shadow!"
        src={media?.url || rawMediaSrc + '#t=0.01'}
        poster={thumbnailPoster || thumb}
        controls={false}
        autoPlay={false}
        controlsList="nodownload"
        crossOrigin="anonymous"
        preload="auto"
        playsInline
        onClick={() => (videoState.paused ? controls.play() : controls.pause())}
        onContextMenu={(e) => e.preventDefault()}
      >
        {/* <track
          label="English"
          kind="subtitles"
          srcLang="en"
          src={media.subtitleurl || rawVttPath}
          default
        ></track> */}
      </video>,
    );

    useEffect(() => {
      if (!videoRef?.current || !shouldUsePlyr()) return;

      const videoPlyr = new Plyr(videoRef.current, {
        captions: { active: false, update: true },
        ratio: '16:9',
        controls: [],
        storage: { enabled: false },
      });

      if (videoPlyr) {
        videoPlyr.on('ready', () => {
          setPlyrInstance(videoPlyr);
          videoPlyr.toggleCaptions(false);
          videoPlyr.toggleControls(false);
        });
      }
    }, [videoRef?.current]);

    useEffect(() => {
      const state = videoRef.current?.readyState;
      if (isNil(state)) return;

      if (
        [
          MediaReadyState.HAVE_ENOUGH_DATA,
          MediaReadyState.HAVE_METADATA,
        ].includes(state as MediaReadyState)
      ) {
        setIsVideoLoaded(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoRef.current?.readyState]);

    const handleThumbnailUpload = (
      e: React.ChangeEvent<HTMLInputElement> | undefined,
    ) => {
      if (!e?.target) return;

      let file = e?.target?.files?.[0];

      if (!file?.type?.startsWith('image/')) {
        customToast.error('Invalid file format! Please try again');
        return;
      }

      if (file && file.size <= 4 * 1024 * 1024) {
        var reader = new FileReader();
        reader.onloadend = function () {
          thumbnailFileRef.current.src = reader.result;
          setThumbnailBase64(reader.result as any);
        };
        reader.readAsDataURL(file);
      } else {
        customToast.error('Picture should be less than 4MB');
      }
    };

    return (
      <Modal show={isOpen} modalClosed={hideModal}>
        <div className="userModal_Popus">
          <div tw="flex items-center">
            <h1 tw="mr-4!">{props.title || 'Update thumbnail'}</h1>
            <div tw="mt-2">
              <QuestionTooltipHint
                contentWidth="33rem"
                heightSvg={25}
                widthSvg={25}
                message={`                
                  Thumbnail should be in 16:9 aspect ratio,
                  Maximum file size 4MB
                    `}
              />
            </div>
          </div>

          <div className="publishmodal">
            <div>
              <div tw="mt-4 mb-2">
                <label tw="text-16!">
                  {/* Title */}
                  {/* <input
                    type="text"
                    placeholder="Search"
                    value={title}
                    autoFocus
                    readOnly
                    required
                  /> */}
                  {title}
                </label>
              </div>

              <div
                css={[
                  isEmpty(thumbnailBase64) && tw`hidden!`,
                  // tw`w-3/4! relative h-auto overflow-hidden padding-top[56.3%] border-radius[.5rem] shadow-lg border[1px solid] border-sonnant-grey-3`,
                ]}
                tw="w-full h-auto relative overflow-hidden padding-top[56.25%] rounded shadow-sm border[1px solid] border-sonnant-grey-3"
              >
                <img
                  ref={thumbnailFileRef}
                  src={undefined}
                  alt="Thumbnail"
                  tw="rounded w-full h-full block max-w-full max-h-full absolute top-0 left-0 right-0 bottom[0] object-fit[contain]"
                  // css={[thumbnailStyles]}
                />
              </div>

              {isEmpty(thumbnailBase64) && !isAudio && (
                <>
                  <div>
                    {!plyrInstance && (
                      <div tw="flex justify-center items-center height[40rem] shadow rounded">
                        <Loader />
                      </div>
                    )}
                    <div css={[!plyrInstance && tw`hidden!`]}>{video}</div>
                  </div>
                  <div tw="bg-sonnant-purple-4 text-white p-5 rounded-xl my-3">
                    <div tw="font-size[1.4rem] flex justify-between items-center user-select[none]">
                      <div tw="flex items-center flex-1">
                        <div tw="flex-1 text-left">
                          Current frame: {secToTime(videoState.time)}
                        </div>
                      </div>
                      <div tw="flex items-center mb-2">
                        <RewindSvg
                          tw="height[2.4rem] cursor-pointer"
                          onClick={() => controls.seek(videoState.time - 5)}
                        />
                        <div
                          tw="mx-3 flex"
                          className={!isVideoLoaded ? 'disabled' : ''}
                        >
                          {videoState.paused ? (
                            <Play
                              css={[playPauseStyle]}
                              onClick={controls.play}
                            />
                          ) : (
                            <Pause
                              css={[playPauseStyle]}
                              onClick={controls.pause}
                            />
                          )}
                        </div>
                        <ForwardSvg
                          tw="height[2.4rem] cursor-pointer"
                          onClick={() => controls.seek(videoState.time + 5)}
                        />
                      </div>
                      <div tw="flex-1 text-right">
                        Clip duration: {secToTime(videoState.duration)}
                      </div>
                    </div>
                    <div
                      className="waveform-bar"
                      tw="w-full text-center bg-sonnant-grey-5 rounded-md mt-2"
                    >
                      {isVideoLoaded && (
                        <WaveSurferVideo
                          src={media?.url || rawMediaSrc!}
                          ref={videoRef}
                          currentTime={null}
                          defaultTime={null}
                          shouldHideRegion={true}
                          // onRegionUpdate={debounce(handleRegionUpdate, 20)}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div>
              <input
                type="file"
                accept="image/*"
                name="image"
                id="thumbnailFile"
                onChange={handleThumbnailUpload}
                tw="hidden"
              />
              <label
                htmlFor="thumbnailFile"
                className="button btn-secondary large"
                tw="mt-3 width[unset]! px-4!"
              >
                <span tw="relative flex margin-top[2px]">
                  <CameraSvg tw="align-content[center] mr-2" />
                  <div tw="items-center flex">
                    {isEmpty(thumbnailBase64)
                      ? 'Upload from file'
                      : 'Chose another file'}
                  </div>
                </span>
              </label>
              <div tw="text-14 -mt-1 text-sonnant-grey-6 font-medium">
                660px x 372px recommended
              </div>
            </div>

            <div className="grid-x grid-margin-x rightAlign">
              <button
                type="button"
                className="button btn-secondary large cancel small-3 cell"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`button btn-primary  large apply small-3 cell ${
                  (!isVideoLoaded || isAudio) && isEmpty(thumbnailBase64)
                    ? 'disabled'
                    : ''
                }`}
                onClick={handleCreate}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);
