/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ReactComponent as AscendingArrow } from 'assets/Icons/Ascending_Arrow.svg';
import { ReactComponent as DescendingArrow } from 'assets/Icons/Descending_Arrow.svg';
import { ReactComponent as MatchWordSvg } from 'assets/Icons/match_word.svg';
import { ReactComponent as Search } from 'assets/Icons/search.svg';
import { ReactComponent as TagSvg } from 'assets/Icons/tag_24dp.svg';
import CloseSVG from 'components/UiControls/closeButtonSVG/closeSVG';
import {
  processTerms,
  splitStringByComma,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import Loader from 'components/loader/loader';
import { TermItem } from 'components/shared/CutomTermsModal/TermItem';
import { Hint } from 'components/shared/Hint';
import { ResetBottomBar } from 'components/shared/ResetBottomBar/ResetBottomBar';
import { QuestionTooltipHint } from 'components/shared/Tooltip/QuestionTooltipHint';
import {
  arrowActive,
  arrowCss,
  dropdownCss,
  hoverDropShadow,
  noneBorder,
} from 'components/shared/twin.styles';
import { useQueryGeneratedTermInsight } from 'hooks/queries/useQueryGeneratedTermInsight';
import {
  intersection,
  isEmpty,
  isEqual,
  isNil,
  orderBy,
  uniqBy,
  uniqWith,
} from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { RootState } from 'reducers';
import { UserService } from 'services';
import { setBlacklistInsight } from 'slices/global.slice';
import tw from 'twin.macro';
import { Keys } from 'utils/enum';
import { Term } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { getTenantidFromIdToken } from 'utils/utils';
import { v4 } from 'uuid';

export const BlacklistInsightsTab = () => {
  const dispatch = useDispatch();

  const { refetch: refetchGeneratedTermInsight } =
    useQueryGeneratedTermInsight();

  const global = useSelector((state: RootState) => state.global);
  const isLoading = useSelector(
    (state: RootState) => state.payment.subscription.isLoading,
  );

  const [newBlacklistTerm, setNewBlacklistTerm] = useState<string>('');
  const [searchBlacklistTerm, setSearchBlacklistTerm] = useState<string>('');
  const [blacklistTermList, setBlacklistTermList] = useState<Term[]>([]);

  const [isSortAsc, toggleIsSortAsc] = useToggle(true);
  const [sortList] = useState([
    { id: 'modified', name: 'modified' },
    { id: 'alphabet', name: 'A-Z' },
  ]);
  const [currentSort, setCurrentSort] = useState<{ id: string; name: string }>(
    sortList[0],
  );

  const [initialState, setInitialState] = useState<Term[]>(
    global.blacklistInsight,
  );

  const hasChanges = !isEqual(blacklistTermList, initialState);

  useEffect(() => {
    if (isLoading === true || isNil(isLoading)) return;

    setInitialState(global.blacklistInsight);
  }, [isLoading]);

  useEffect(() => {
    setBlacklistTermList(global.blacklistInsight);
  }, [global.blacklistInsight]);

  const searchedBlacklistTerms =
    orderBy(
      blacklistTermList.filter((term) =>
        term?.name
          .toLowerCase()
          .includes(searchBlacklistTerm.trim().toLowerCase()),
      ),
      (orderBy) => {
        const by = currentSort.id;

        if (by === 'modified') return orderBy.dateCreated;
        if (by === 'alphabet') return orderBy.name;
      },
      [isSortAsc ? 'asc' : 'desc'],
    ) ?? [];

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Keys.ESC) {
      setNewBlacklistTerm('');

      return;
    }

    if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
      const addedBlacklistTerms = splitStringByComma(newBlacklistTerm);

      const duplicatedTermNames = getDuplicatedTermNames(addedBlacklistTerms);

      if (!isEmpty(duplicatedTermNames)) {
        customToast.error(
          <div>
            <b>{duplicatedTermNames.join(', ')}</b> already existed
          </div>,
        );
      }

      const nonExistingBlacklistTerms = addedBlacklistTerms.filter(
        (term) =>
          !blacklistTermList
            ?.map(({ name }) => name.toLowerCase())
            .includes(term.toLowerCase()),
      );

      const newBlacklistTerms = nonExistingBlacklistTerms.map((term) => ({
        id: v4(),
        dateCreated: new Date(),
        name: term,
      }));

      setBlacklistTermList([...blacklistTermList, ...newBlacklistTerms]);
      setNewBlacklistTerm('');
    }
  };

  const handleRemoveTerm = (term: Term) => {
    const newBlacklistTermList =
      blacklistTermList?.filter(
        (blacklistTerm) => blacklistTerm.id !== term.id,
      ) ?? [];

    setBlacklistTermList(newBlacklistTermList);
  };

  const handleUpdateTerm = (term: Term) => {
    const existedTerm = blacklistTermList?.find(
      (blacklistTerm) => blacklistTerm.name === term.name,
    );

    if (existedTerm) {
      customToast.error(
        <>
          Term <strong>{term.name}</strong> already exists.
        </>,
      );

      return;
    }

    const termIndex = blacklistTermList?.findIndex(
      (blacklistTerm) => blacklistTerm?.id === term?.id,
    );
    if (termIndex < 0) return;

    const cloneTermList = [...blacklistTermList];
    cloneTermList[termIndex] = term;

    setBlacklistTermList(cloneTermList);
  };

  const handleSaveAsync = async (termList: Term[]) => {
    try {
      const tenantid = getTenantidFromIdToken();

      const saveAsync = UserService.savePreference({
        tenantid,
        setting_ignore_terms: termList ? JSON.stringify(termList) : undefined,
      });

      customToast.promise(saveAsync, {
        loading: 'Saving changes...',
        success: 'Saved successfully',
        error: 'Save failed',
      });

      await saveAsync;

      dispatch(setBlacklistInsight(termList));
      setInitialState(termList);

      refetchGeneratedTermInsight();
    } catch (error: any) {
      console.log('error :>> ', error);
    }
  };

  const handleReset = () => {
    setBlacklistTermList(initialState);
  };

  const getDuplicatedTermNames = (termNames: string[]) => {
    const duplicatedTermNames = intersection(
      blacklistTermList.map((blacklistTerm) =>
        blacklistTerm?.name.toLowerCase(),
      ),
      termNames.map((termName) => termName.toLowerCase()),
    );

    return duplicatedTermNames;
  };

  const appendUniqueTerms = (terms: Term[], hasToast = false) => {
    if (isEmpty(terms)) return;

    // Default OFF
    if (hasToast) {
      const duplicatedTermsLower = intersection(
        blacklistTermList.map((blacklistTerm) =>
          blacklistTerm?.name.toLowerCase(),
        ),
        terms.map((term) => term?.name.toLowerCase()),
      );

      const duplicatedTermNames = terms
        .filter((term) =>
          duplicatedTermsLower.includes(term?.name.toLowerCase()),
        )
        .map((filteredTerm) => filteredTerm?.name);

      if (!isEmpty(duplicatedTermNames)) {
        customToast.error(
          <div>
            <b>{duplicatedTermNames.join(', ')}</b> already existed
          </div>,
        );
      }

      terms = terms.filter((term) => !duplicatedTermNames.includes(term?.name));
    }

    setBlacklistTermList((oldBlacklistTerms) =>
      uniqBy([...oldBlacklistTerms, ...terms], 'name'),
    );
  };

  const uploadCSV = (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.[0];

    if (!uploadedFile || !uploadedFile.name.endsWith('.csv')) {
      customToast.error('Not supported file type. Please check and try again.');
      return;
    }

    const reader = new FileReader();
    reader.onload = (event: any) => {
      const csvTerms = processTerms(event.target.result, []);

      let uniqCsvTerms = uniqWith(
        csvTerms,
        (a, b) => a?.name.toLowerCase() === b?.name.toLowerCase(),
      );

      appendUniqueTerms(uniqCsvTerms, true);
      e.target.value = '';
    };

    try {
      reader.readAsText(uploadedFile);
    } catch (error: any) {
      customToast.error('Invalid CSV file format. Please check and try again.');
    }
  };

  return (
    <div tw="mx-12 mt-10">
      {isLoading && (
        <div
          className="loader__component"
          tw="height[calc(100vh - 13rem)] top[13rem] opacity-[1]"
        >
          <Loader />
        </div>
      )}

      <div tw="font-extrabold font-size[2.2rem] mb-7 flex items-center space-x-4 text-sonnant-dark">
        <span tw="flex">
          <MatchWordSvg tw="relative top[-1px]" />
        </span>
        <div>Blacklist Insights</div>
      </div>

      <div tw="flex w-full justify-end md-down:(flex-wrap)">
        <div tw="flex-1 relative mr-5">
          <TagSvg tw="absolute top[1.2rem] left[1rem]" />
          <input
            type="text"
            placeholder={`Add terms to Blacklist (use comma for multiple items)`}
            value={newBlacklistTerm}
            tw="(text-14 padding-left[3.1rem] padding-right[3rem] placeholder:(text-11) focus:(border-transparent))!"
            onChange={(e) => setNewBlacklistTerm(e.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus
          />
          <QuestionTooltipHint
            customStyle={toolTipStyle}
            contentWidth="40rem"
            message={<div>Blacklist terms can be words or phrases.</div>}
          />
        </div>

        <div tw="mr-5">
          <input
            id="blacklistCsv"
            type="file"
            accept=".csv"
            tw="hidden"
            onChange={uploadCSV}
          />
          <label htmlFor="blacklistCsv">
            <Hint
              text="CSV must have no headings, use column A for main item"
              align="bottom"
            >
              <div
                className="button btn-primary large"
                tw="flex justify-center items-center"
              >
                Upload CSV
              </div>
            </Hint>
          </label>
        </div>

        <div className="search-container" tw="flex -mr-1 flex-1">
          <div tw="w-full flex relative">
            <input
              className="input-group-field input-search"
              tw="(height[4rem] width[15rem]! rounded-r-none rounded-l pl-4 pr-2 font-size[1.5rem] caret-color[#7F8090] placeholder:(text-12))!"
              css={[noneBorder]}
              type="input"
              placeholder={`Search in Blacklist`}
              value={searchBlacklistTerm}
              onChange={(e) => setSearchBlacklistTerm(e.target.value)}
            />
          </div>
          <button
            tw="(rounded-l-none height[4rem] width[4rem] mb-0 rounded-r focus:(bg-white) hover:(border[2px solid #F0F3F6]) box-shadow[none])!"
            className="button btn-secondary"
          >
            {searchBlacklistTerm ? (
              <CloseSVG
                close={() => setSearchBlacklistTerm('')}
                color="#7F8090"
                css={hoverDropShadow(0.2)}
              />
            ) : (
              <Search css={hoverDropShadow(0.2)} />
            )}
          </button>
        </div>

        <div
          className="button-group sort_modify"
          tw="flex items-center flex-nowrap height[3.9rem] bg-sonnant-grey-5 m-0 ml-5 rounded flex[unset] pr-1.5 select-none w-1/6 minWidth[18rem]"
        >
          <div className="sort-icon" tw="pl-0 ml-2">
            <div
              tw="flex flex-col mr-2 ml-1 cursor-pointer"
              onClick={toggleIsSortAsc}
            >
              <DescendingArrow
                css={[arrowCss, !isSortAsc && arrowActive]}
                tw="(mb-0.5 width[unset] height[unset])!"
              />
              <AscendingArrow
                css={[arrowCss, isSortAsc && arrowActive]}
                tw="(mt-0.5 width[unset] height[unset])!"
              />
            </div>
          </div>
          <div className="dropdown" css={dropdownCss} tw="width[100%]">
            <a
              className="button"
              tw="(w-full minWidth[14.5rem] height[3rem] py-0 px-3 flex items-center text-13 whitespace-nowrap)!"
            >
              <span tw="flex-1 text-left">Sort by {currentSort.name}</span>
              <span className="arrow-down" tw="-mt-1"></span>
            </a>
            <ul
              className="dropdown-sort-content manageDropDown"
              tw="w-full minWidth[14.5rem]"
            >
              {sortList.map((s) => (
                <li key={s.id} onClick={() => setCurrentSort(s)}>
                  <a>Sort by {s.name}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div tw="flex flex-wrap content-start w-full p-4 mt-4 mb-6 overflow-y-auto height[40rem] border[1px solid lightgrey] rounded-lg">
        {searchedBlacklistTerms.map((term, index) => (
          <TermItem
            key={term.id}
            id={index}
            term={term}
            handleRemoveTerm={handleRemoveTerm}
            handleUpdateTerm={handleUpdateTerm}
            shouldShowAlter={false}
          />
        ))}
        {searchedBlacklistTerms.length === 0 && (
          <div tw="color[#54566C] text-2xl font-medium mb-6">
            There are no terms in Blacklist.
          </div>
        )}
      </div>

      <ResetBottomBar
        isShow={hasChanges}
        onReset={handleReset}
        onSubmit={() => handleSaveAsync(blacklistTermList)}
      />
    </div>
  );
};

const toolTipStyle = css`
  ${tw`absolute top[1.1rem] right[.9rem]`}
`;
