import { BaseAPI } from 'apis/amazon';
import { AxiosResponse } from 'axios';
import { RssSubscription } from 'utils/models';

export type UpdateSubscriptionPayload = {
  subscription_id: string;
  subscribed?: string;
  collection_id?: string;
};

export const RssService = {
  // GET /rss
  getRssSubscriptions: (): Promise<
    AxiosResponse<{ data: RssSubscription[]; ok: boolean }>
  > => {
    return BaseAPI.get('/rss');
  },

  // POST /rss
  updateRssSubscription: (
    payload: UpdateSubscriptionPayload,
  ): Promise<AxiosResponse> => {
    return BaseAPI.post('/rss', payload);
  },

  // DELETE /rss
  deleteRssSubscriptions: (id: string) => {
    return BaseAPI.delete('/rss', { params: { id } });
  },
};
