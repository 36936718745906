/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as EmptyLibrarySvg } from 'assets/Icons/empty_library.svg';
// import { ReactComponent as UploadSvg } from 'assets/Icons/cloud_upload.svg';
import { ReactComponent as EmptySvg } from 'assets/Icons/empty-folder.svg';
import { useDispatch, useSelector } from 'react-redux';
import { toggleUploadModal } from 'slices/toggle.slice';
import { isCollectionPage } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { RootState } from 'reducers';
import { isEmpty } from 'lodash';

type Props = {};

export const EmptyLibraryBanner = (props: Props) => {
  const dispatch = useDispatch();
  const collection = useSelector((state: RootState) => state.collection);

  const isCollection =
    isCollectionPage() ||
    (collection.showSidebar && !isEmpty(collection.selectedId));

  return (
    <div tw="w-full h-full flex justify-center items-center">
      <div tw="flex justify-center flex-col text-sonnant-dark items-center">
        {isCollection ? (
          <EmptySvg tw="zoom[0.4] fill[#7f8090]" />
        ) : (
          <EmptyLibrarySvg tw="zoom[0.3] fill[#7f8090] mb-[9.5rem]" />
          // <CloudUploadSvg tw="zoom[3] fill[#7f8090]" />
        )}

        <div tw="font-medium font-size[2.4rem] my-3 text-sonnant-dark">
          Your {isCollection ? 'collection' : 'library'} is currently empty!
        </div>

        <div tw="mt-5 mb-8 text-16 text-center">
          {isCollection
            ? 'Drag and drop media files from your library to fill your own collection'
            : 'Fill your library with media files from any source by our upload helper.'}
        </div>

        {!isCollection && (
          <a
            tw="text-16 hover:(underline)"
            onClick={(e) => dispatch(toggleUploadModal(true))}
          >
            Upload now
          </a>
        )}
      </div>
    </div>
  );
};
