/** @jsxImportSource @emotion/react */
import 'twin.macro';

import Skeleton from 'react-loading-skeleton';

interface Props {}

export const ToolkitSkeleton = (props: Props) => {
  return (
    <div tw="space-x-4 py-4 px-6! flex justify-start">
      {[...Array(14)].map((_, index) => (
        <Skeleton key={index} height={40} width={40} />
      ))}
    </div>
  );
};
