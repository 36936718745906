/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { debounce, isEmpty, isEqual, trim } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useToggle } from 'react-use';

import { ReactComponent as BinSvg } from 'assets/Icons/bin.svg';
import { ReactComponent as AddSvg } from 'assets/Icons/plus_icon.svg';

import { toProgramIdOptions } from 'components/VideoPlayer/Transcription/MediaUtilities';
import {
  CustomSelectSearch,
  Option,
} from 'components/shared/CustomSelectSearch';
import { Hint } from 'components/shared/Hint';
import { MegaphoneService } from 'services/megaphone.service';
import { MegaphoneProgramFilterResponse, ProgramIdOption } from 'utils/models';
import { defaultStyles } from '../Integrations/IntegrationFilter';

type Props = {
  apiKey?: string;
  filter: MegaphoneProgramFilterResponse;
  index: number;
  lastProgramFilterIndex: number;

  onChangeNetworkId: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void;
  onSelectProgramId: (newValue: Option, index: number) => void;
  onAddNewFilter: () => void;
  onRemoveFilter: (index: number) => void;
};

export const MegaphoneIntegrationFilterItem = ({
  apiKey,
  filter,
  index,
  lastProgramFilterIndex,
  ...props
}: Props) => {
  const [isLoading, toggleIsLoading] = useToggle(false);
  const [programIdOptions, setProgramIdOptions] = useState<ProgramIdOption[]>(
    [],
  );

  const programLabel = useMemo(() => {
    if (isEmpty(programIdOptions) || isEmpty(filter.programId)) return '';

    return programIdOptions.find(({ value }) => value === filter.programId)
      ?.label;
  }, [programIdOptions, filter.programId]);

  useEffect(() => {
    const trimmedApiKey = trim(apiKey || '');
    const trimmedNetworkId = trim(filter.networkId);

    if (!trimmedApiKey || !trimmedNetworkId) {
      setProgramIdOptions([]);

      return;
    }

    debounceGetProgramIds(trimmedApiKey, trimmedNetworkId);
  }, [apiKey, filter.networkId]);

  const handleGetProgramIdsAsync = async (
    apiKey: string,
    networkId: string,
  ) => {
    try {
      toggleIsLoading(true);

      const res = await MegaphoneService.getProgramIds({
        apiKey,
        networkId,
      });

      setProgramIdOptions(toProgramIdOptions(res.data));
    } catch (err) {
      console.log('[LOG] err: >>>', err);

      setProgramIdOptions([]);
    } finally {
      toggleIsLoading(false);
    }
  };

  const debounceGetProgramIds = useCallback(
    debounce(handleGetProgramIdsAsync, 400),
    [],
  );

  const renderCustomOption = (option: ProgramIdOption) => (
    <div tw="flex flex-row">
      <div tw="max-w-[5.5rem] mr-4 flex">
        <img alt="" src={option.imageURL} />
      </div>

      <div tw="flex flex-col w-full">
        <div
          tw="text-15 font-black text-left text-sonnant-dark break-words"
          className="line-clamp-2"
        >
          {option.label}
        </div>
        <div tw="text-12">{option.value}</div>
      </div>
    </div>
  );

  const handleChangeNetworkId = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangeNetworkId(e, index);
  };

  const handleSelectProgramId = (newValue: Option) => {
    props.onSelectProgramId(newValue, index);
  };

  return (
    <div tw="flex space-x-8">
      <div tw="flex flex-col pt-0! flex-1 min-w-[27rem]">
        <div tw="flex flex-row items-center">
          <label tw="flex flex-nowrap text-16 mr-3">Network ID</label>
        </div>

        <div tw="flex flex-row max-w-[75rem]">
          <div tw="flex-1 relative">
            <input
              tw="flex"
              type="text"
              name="networkId"
              value={filter.networkId}
              onChange={handleChangeNetworkId}
            />
          </div>
        </div>
      </div>

      <div tw="flex flex-col pt-0! flex-1 min-w-[27rem]">
        <div tw="flex flex-row items-center">
          <label tw="flex flex-nowrap text-16 mr-3">Program</label>
        </div>

        <div tw="flex flex-row max-w-[75rem]">
          <div tw="flex-1 relative w-full ">
            <CustomSelectSearch
              options={programIdOptions}
              defaultValue={programLabel}
              isLoading={isLoading}
              customStyles={defaultStyles}
              formatOptionLabel={renderCustomOption}
              onChange={handleSelectProgramId}
              menuPlacement="bottom"
              canCreate={false}
            />
          </div>
        </div>
      </div>

      <div tw="flex flex-1 space-x-5">
        {isEqual(index, lastProgramFilterIndex) && (
          <Hint text="Add new program filter" enterDelay={100} leaveDelay={0}>
            <button
              onClick={() => props.onAddNewFilter()}
              tw="flex justify-center self-end mb-[1rem] pt-0! items-center cursor-pointer rounded-lg h-[4rem] w-[4rem]"
              className="btn btn-secondary"
            >
              <AddSvg tw="w-[3rem]" />
            </button>
          </Hint>
        )}
        <Hint text="Remove program filter" enterDelay={100} leaveDelay={0}>
          <button
            onClick={() => props.onRemoveFilter(index)}
            tw="flex justify-center self-end mb-[1rem] pt-0! items-center cursor-pointer h-[4rem] w-[4rem]"
            className="btn btn-secondary"
          >
            <BinSvg fill="red" />
          </button>
        </Hint>
      </div>
    </div>
  );
};
