/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { CLOSE_PUBLISH_LIBRARY } from 'actions/types';
import { ReactComponent as Back } from 'assets/Icons/Back.svg';
import { ReactComponent as Next } from 'assets/Icons/Right.svg';
import { Publish } from 'components/Publish/Publish';
import { MentionReportModal } from 'components/shared/MentionReport/MentionReportModal';
import { IRichTextInput } from 'components/shared/RichTextEditor/rich-text.model';
import { KeywordSkeleton } from 'components/shared/Skeleton/KeywordSkeleton';
import { Modal } from 'components/UiControls/modal/modal';
import {
  isPublicShared,
  scrollToNearestNode,
  searchTranscriptionV2Async,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { first, isEmpty, isNil, orderBy, toNumber } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { RootState } from 'reducers';
import { layerSelector } from 'slices/layers.slice';
import {
  addTermIntoCustomTerms,
  removeLayerCustomerTerm,
  removeSpeakerByName,
  resetFinder,
  setEntitiesSlice,
  setKeywordsSlice,
  setSelectedTagId,
  setSingleSelectedTerms,
} from 'slices/player.slice';
import { toggleEditCaption, toggleWarning } from 'slices/toggle.slice';
import { Layers, VideoStatusCode } from 'utils/enum';
import { IKeyItem, SortKey } from 'utils/models';
import { ISpeakerRaw } from 'utils/models/transcript.model';
import { customToast } from 'utils/toast.util';
import {
  createCustomLayer,
  createManualKeyword,
  getOccList,
  toSlateLineText,
} from 'utils/transcript.util';
import { getLayerEntity } from 'utils/utils';
import { Keyword } from '../../../Keyword/Keyword';
import { UpdateKeywordConfirm } from '../../../UpdateKeywordConfirm/UpdateKeywordConfirm';
import { AddItemInput } from './AddItemInput';
import { LayerSwitcher } from './LayerSwitcher';

interface IProps {
  isPublicPlayer?: boolean;
  isEditing: boolean;
  children?: unknown;
  isFirstItem?: boolean;
  isLastItem?: boolean;
  selectedItem?: IKeyItem;
  hideCurrentItem?: boolean;
}

export const LayerNavigator = (props: IProps): any => {
  // console.count('==== LayerNavigator');

  const [currentItem, setCurrentItem] = useState<IKeyItem | null>(null);
  const [items, setItems] = useState<IKeyItem[]>([]);
  const [occNumber, setOccNumber] = useState(0); // Start from 0
  const [showItemUpdateConfirm, setShowItemUpdateConfirm] = useState(false);
  const [isInputting, setIsInputting] = useState(false);
  const [searchedKeywords, setSearchedKeywords] = useState<IKeyItem[]>([]);
  const [openPublishModal, toggleOpenPublishModal] = useToggle(false);
  const [currentKeyword, setCurrentKeyword] = useState('');

  const layers = useSelector((state: RootState) => state.layers);
  const currentLayer = useSelector(layerSelector);
  const player = useSelector((state: RootState) => state.player);
  const toggle = useSelector((state: RootState) => state.toggle);
  const media = useSelector((state: RootState) => state.media);
  // MAP CURRENT ITEM TO SLICE
  const keyword = useSelector((state: RootState) => state.player.keywords);
  const entities = useSelector((state: RootState) => state.player.entities);

  const dispatch = useDispatch();
  const mentionReportModalRef = useRef<any>(null);
  // PROCESS DATA FROM PARENT

  const isEmptyAppliedList =
    currentLayer.isCustomTerms && isEmpty(player?.layerCustomTerms);

  const isAIProcessing =
    toNumber(media?.statuscode) === VideoStatusCode.AI_PROCESSING;

  useEffect(() => {
    const itemByLayer = getMetadataEntity();
    // const processedItems = preprocessItems(itemByLayer);
    setItems(itemByLayer);
  }, [
    player?.keywords,
    player?.entities,
    player.layerCustomTerms,
    player.focusLayerCustomTermId,
    layers.currentLayer,
    player.speakers,
  ]);

  // Reset selected on change list dropdown
  useEffect(() => {
    setCurrentItem(null);
  }, [player.focusLayerCustomTermId]);

  const getMetadataEntity = (): IKeyItem[] => {
    // Be careful of infinite loop of setMetadataEntity()
    if (currentLayer.isKeyTerm) {
      return player.keywords;
    }

    if (currentLayer.isSpeaker) {
      // Convert player.slice/speakers to Key terms format
      return player?.speakers
        ?.map((speaker: ISpeakerRaw) => ({
          keyword: speaker?.name,
          mentions: [
            {
              speakerName: speaker?.name,
              occurrences: speaker?.occurrences ?? [],
            },
          ],
          inUsed: speaker?.inUsed,
        }))
        .filter((speaker) => speaker?.inUsed);
    }

    if (currentLayer.isCustomTerms) {
      const customList =
        player.layerCustomTerms?.find(
          (t) => t?.listId === player?.focusLayerCustomTermId,
        )?.matched ?? [];
      // return uniqBy(customList, 'keyword');
      return customList;
    }

    return (player.entities as any)?.[getLayerEntity(currentLayer.enum)] ?? [];
  };

  // PASS OUT DATA TO PARENT
  useEffect(() => {
    setMetadataEntity(items);
  }, [items]);

  const setMetadataEntity = (items: any) => {
    if (isEmpty(items)) return;

    const entityObjName = getLayerEntity(layers.currentLayer);
    if (currentLayer.isKeyTerm) {
      dispatch(setKeywordsSlice(items));
      return;
    }

    if (currentLayer.isSpeaker) {
      // TODO: Code to implement logic to update back into slice
      return;
    }

    const cloneMediaEntities: any = { ...player.entities };
    if (cloneMediaEntities.hasOwnProperty(entityObjName)) {
      cloneMediaEntities[entityObjName] = items;
    }
    dispatch(setEntitiesSlice(cloneMediaEntities));
  };

  useEffect(() => {
    let foundIndex = -1;

    if (layers.currentLayer === Layers.CUSTOM_TERM) {
      const foundTerms =
        player.layerCustomTerms?.find(
          (list) => list?.listId === player?.focusLayerCustomTermId,
        )?.matched ?? [];
      if (foundTerms) {
        foundIndex = foundTerms?.findIndex(
          (t) => t?.keyword === currentItem?.keyword,
        );
      }
    }

    if (layers.currentLayer === Layers.KEY_TERM) {
      foundIndex = keyword.findIndex((k) => k.keyword === currentItem?.keyword);
    } else {
      if (layers.currentLayer === Layers.PEOPLE && entities.person) {
        foundIndex = entities?.person?.findIndex(
          (k) => k.keyword === currentItem?.keyword,
        );
      }
      if (layers.currentLayer === Layers.PRODUCT && entities.product) {
        foundIndex = entities?.product?.findIndex(
          (k) => k.keyword === currentItem?.keyword,
        );
      }
      if (layers.currentLayer === Layers.ORG && entities.organisation) {
        foundIndex = entities?.organisation?.findIndex(
          (k) => k.keyword === currentItem?.keyword,
        );
      }
      if (layers.currentLayer === Layers.LOCATION && entities.location) {
        foundIndex = entities?.location?.findIndex(
          (k) => k.keyword === currentItem?.keyword,
        );
      }
      if (layers.currentLayer === Layers.NATIONALITY && entities.nationality) {
        foundIndex = entities?.nationality?.findIndex(
          (k) => k.keyword === currentItem?.keyword,
        );
      }
    }
    if (foundIndex !== -1) {
      dispatch(setSelectedTagId(foundIndex));
    }

    if (!currentItem) return;

    const keywordStart = currentItem.mentions?.[0]?.occurrences?.[0]?.s ?? 0;
    scrollToNearestNode(keywordStart);

    if (window?.Video && !isNil(keywordStart) && !isNaN(keywordStart)) {
      window.Video.currentTime = keywordStart;
    }
  }, [currentItem]);

  // RESET CURRENT SELECT TAG
  useEffect(() => {
    if (toggle.isShowFinder) {
      resetSelectedItem();
    }
  }, [toggle.isShowFinder]);

  useEffect(() => {
    const term = layers?.searchTerm?.trim() ?? '';

    const searched = items.filter((k) =>
      k?.keyword?.toLowerCase().includes(term?.toLowerCase()),
    );

    const by: SortKey = layers.sort.by as SortKey;
    const isAsc = layers.sort.isAsc;

    const sorted = orderBy(
      searched,
      (s) => {
        if (!currentLayer.isSpeaker) {
          if (by === 'modified') return s?.modified;
          if (by === 'frequent') return s?.mentions?.[0]?.occurrences?.length;
          if (by === 'alphabet') return s?.keyword;
          if (by === 'relevance') return s?.relevance;
          if (by === 'appearance')
            return first(s.mentions)?.occurrences?.[0]?.s;
        } else {
          // First occurrence of speaker
          if (by === 'order') return s?.mentions?.[0]?.occurrences?.[0]?.s;
          // Total time of mentions
          if (by === 'time')
            return s?.mentions?.[0]?.occurrences.reduce(
              (total, occ) => total + ((occ?.e ?? 0) - (occ?.s ?? 0)),
              0,
            );
          // Total words of mentions
          if (by === 'words')
            return s?.mentions?.[0]?.occurrences.reduce(
              (total, occ) => total + (occ?.wordCount ?? 0),
              0,
            );
        }
      },
      [
        ['frequent', 'relevance'].includes(by)
          ? isAsc
            ? 'desc'
            : 'asc' // Reverse order
          : isAsc
          ? 'asc'
          : 'desc',
      ],
    );
    setSearchedKeywords(sorted);
  }, [items, layers.searchTerm, layers.sort.by, layers.sort.isAsc]);

  // SELECT SELECTED ITEM ON CHANGING LAYER
  useEffect(() => {
    resetSelectedItem();
  }, [layers.currentLayer, media.history]);

  const getDisplayItems = () => {
    return (
      searchedKeywords?.filter(
        (term) =>
          !(
            term?.fromCSV === true &&
            term?.mentions?.[0]?.occurrences?.length === 0
          ),
      ) ?? []
    );
  };

  const resetSelectedItem = () => {
    setCurrentItem(null);
    setOccNumber(0);
  };

  const handleRemoveItem = (keyItem: IKeyItem) => {
    let newItem = items.filter((item) => item.keyword !== keyItem.keyword);

    setItems(newItem);

    if (currentLayer.isCustomTerms) {
      dispatch(removeLayerCustomerTerm(keyItem));
    }
    if (currentLayer.isSpeaker) {
      dispatch(removeSpeakerByName(keyItem.keyword));
    }

    if (keyItem === currentItem) {
      setCurrentItem(null);
    }
  };

  const handlepublishmodalclosed = () => {
    toggleOpenPublishModal(false);
    setCurrentKeyword('');
    dispatch({ type: CLOSE_PUBLISH_LIBRARY });
  };
  const handlePublishModal = (keyword: string) => {
    toggleOpenPublishModal(true);
    setCurrentKeyword(keyword);
  };

  const handleClickBack = (): void => {
    if (occNumber > 0) {
      setOccNumber((number) => number - 1);
      jumpToCurrentOcc(occNumber - 1);
    }
  };

  const handleClickNext = (): void => {
    setOccNumber((number) => number + 1);
    jumpToCurrentOcc(occNumber + 1);
  };

  const isFirstOcc = (): boolean => {
    return getOccList(currentItem)?.length <= 1 || occNumber === 0;
  };

  const isLastOcc = (): boolean => {
    if (getOccList(currentItem)?.length <= 1) return true;

    return getOccList(currentItem)?.length - 1 === occNumber;
  };

  const jumpToCurrentOcc = (num: number): void => {
    const occurrences = getOccList(currentItem);

    if (!occurrences?.length) return;

    // Prevent duplicated caption

    const currentKeywordTime = occurrences?.[num]?.s ?? 0;

    if (window?.Video && !isNaN(currentKeywordTime)) {
      scrollToNearestNode(currentKeywordTime);
      window.Video.currentTime = +currentKeywordTime + 0.001;
    }
  };

  const shouldHidePanel = (): boolean => {
    return [
      Layers.SUMMARY,
      Layers.CHAPTER,
      Layers.IAB,
      Layers.AD_MARKER,
      Layers.AD_BREAK,
      Layers.SEGMENT,
      Layers.GARM_REPORT,
      ...(!isPublicShared() ? [Layers.CUSTOM_TERM] : []),
    ].includes(layers.currentLayer);
  };

  const isExisted = (itemStr: string): boolean => {
    return items.some(
      (k: IKeyItem) =>
        k?.keyword.trim().toLowerCase() === itemStr.trim().toLowerCase(),
    );
  };

  const focusItem = (item?: IKeyItem) => {
    if (!item) {
      setCurrentItem(null);
    } else {
      setCurrentItem(item);
    }
  };
  const handleAddItem = async (newItemStr: string) => {
    if (currentLayer.isCustomTerms) {
      let termsArray = newItemStr.split(',');

      const currentAppliedList = player.layerCustomTerms.find(
        (l) => l?.listId === player?.focusLayerCustomTermId,
      );

      if (currentAppliedList) {
        termsArray = termsArray.filter((term) => {
          const existed = currentAppliedList?.matched
            .map((m) => m?.custom?.toLowerCase())
            .includes(term?.toLowerCase());

          if (existed) {
            customToast.error(
              <span>
                <b>{term}</b> already existed.
              </span>,
            );
          }

          return !existed;
        });
      }

      if (isEmpty(termsArray)) return;

      let foundTerms = await searchTranscriptionV2Async({
        mediaid: media?.metadata?.mediaid,
        terms: termsArray,
      });

      if (isEmpty(foundTerms)) {
        const sourceText = toSlateLineText(window.Editor.children);
        foundTerms = createCustomLayer(
          termsArray,
          sourceText,
          window.Editor.children as IRichTextInput[],
          items,
        );
      }

      dispatch(addTermIntoCustomTerms(foundTerms));
      return;
    }

    const sourceText = toSlateLineText(window.Editor.children);
    const newItem = createManualKeyword(
      newItemStr,
      sourceText,
      window.Editor.children as IRichTextInput[],
    );
    newItem.original = '';
    if (!isExisted(newItemStr)) {
      const cloneItems = [...items, newItem];

      setItems(cloneItems);
      focusItem(newItem);
      dispatch(setSelectedTagId(cloneItems.length - 1));
    } else {
      customToast.error('This term has already existed');
    }
  };

  const handleUpdateConfirmNo = () => {
    setShowItemUpdateConfirm(false);
    dispatch(toggleWarning(true));
  };

  const handleUpdateConfirmYes = () => {
    setShowItemUpdateConfirm(false);
    dispatch(toggleEditCaption(true));
    dispatch(toggleWarning(true));
  };

  const handleMentionModal = async () => {
    await mentionReportModalRef.current?.show();
  };

  return (
    <>
      <LayerSwitcher />

      {!shouldHidePanel() && (
        <>
          <div
            className="keywords navigationRow"
            tw="flex! flex-col! flex-1 mb-0! h-0"
          >
            <div
              className="left_blcok"
              tw="flex flex-wrap! justify-between pr-0!"
            >
              <div tw="flex mb-6 flex-1">
                <div tw="flex-1 flex justify-start">
                  <div className="arrow_block" tw="my-0!">
                    <a
                      className={`button ${isFirstOcc() && 'disabled'}`}
                      onClick={handleClickBack}
                    >
                      <Back />
                    </a>
                    {!isNil(currentItem) && (
                      <span tw="ml-2 -mr-2 -mb-12">
                        <Keyword
                          val={currentItem}
                          isEditingTranscript={false}
                          hideCount={currentLayer.isSpeaker}
                          showTooltip
                        />
                      </span>
                    )}
                    <a
                      className={`button ${isLastOcc() && 'disabled'}`}
                      onClick={handleClickNext}
                    >
                      <Next />
                    </a>
                  </div>
                </div>

                <div tw="flex">
                  {!props?.isPublicPlayer &&
                    !isEmptyAppliedList &&
                    !currentLayer.isSpeaker &&
                    !currentLayer.isCustomTerms &&
                    media?.statuscode &&
                    !isAIProcessing &&
                    !isEmpty(player?.caption) && (
                      <AddItemInput
                        isEditMode={props.isEditing}
                        onAddItem={handleAddItem}
                        setIsInputting={setIsInputting}
                      />
                    )}
                </div>
              </div>
            </div>
            <div
              className="key-scroll-wrapper"
              tw="flex flex-wrap justify-start content-start -mt-2 overflow-y-auto flex-1 pt-0"
            >
              {!player.loadCompleted && <KeywordSkeleton />}

              {getDisplayItems().map((item, key) => (
                <Keyword
                  val={item}
                  key={key}
                  index={key}
                  isEditingTranscript={props.isEditing}
                  currentKeyword={currentItem!}
                  setCurrentKeyword={() => {
                    // Close Finder if Clicking on a keyword
                    dispatch(resetFinder());
                    dispatch(setSingleSelectedTerms(item));
                    setTimeout(() => {
                      setOccNumber(0);
                      setCurrentItem(item);
                    }, 0);
                  }}
                  handleRemoveKeyword={(e: Event) => {
                    e.stopPropagation();
                    handleRemoveItem(item);
                  }}
                  enterKeyword={() => setShowItemUpdateConfirm(true)}
                  showMentionModal={handleMentionModal}
                  focusItem={focusItem}
                  handlePublishModal={() => handlePublishModal(item.keyword)}
                  hideCount={currentLayer.isSpeaker}
                />
              ))}

              {player.loadCompleted &&
                isEmpty(getDisplayItems()) &&
                !isInputting && (
                  <div tw="color[#54566C] text-2xl font-medium mb-6">
                    Nothing to show
                  </div>
                )}
            </div>
          </div>

          {/* MODAL SECTION */}
          <Modal
            show={showItemUpdateConfirm}
            modalClosed={() => setShowItemUpdateConfirm(false)}
          >
            <UpdateKeywordConfirm
              handleConfirm={handleUpdateConfirmYes}
              modalClosed={handleUpdateConfirmNo}
            />
          </Modal>

          <MentionReportModal ref={mentionReportModalRef} isAppliedList={false} />

          <Modal
            classes="publish-modal-width"
            show={openPublishModal}
            modalClosed={handlepublishmodalclosed}
          >
            <Publish
              mediaid={media?.metadata?.mediaid}
              filename={''}
              closeShareModal={handlepublishmodalclosed}
              isOpen={openPublishModal}
              isTopic
              keywordSelected={currentKeyword}
            />
          </Modal>
        </>
      )}
    </>
  );
};
