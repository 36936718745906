const init = () => {
  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.src = 'https://www.google.com/recaptcha/api.js';
  document.body.appendChild(script);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  init
};