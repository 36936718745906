/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';
import Plyr from 'plyr';

import React, { forwardRef, useEffect, useState } from 'react';
import { ReactComponent as Caption } from 'assets/Icons/caption.svg';
import { ReactComponent as CaptionOff } from 'assets/Icons/captions-off.svg';
import { ReactComponent as Minimise } from 'assets/Icons/minimize.svg';
import { ReactComponent as Maximise } from 'assets/Icons/maximise.svg';
import { ReactComponent as FullScreen } from 'assets/Icons/fullScreen.svg';
import { ReactComponent as LogoSonnant } from 'assets/Icons/LogoSonnant.svg';
import Loader from 'components/loader/loader';
import {
  audioThumbStyles,
  plyrResetStyle,
} from '../../../../../shared/twin.styles';
import {
  useToggle,
  useFullscreen,
  useMeasure,
  useMount,
  useUnmount,
  useSearchParam,
} from 'react-use';
import { MediaReadyState, CaptionStatus, CaptionEnum } from 'utils/enum';
import { isEmpty, isNaN, isNil, toNumber } from 'lodash';
import { Hint } from 'components/shared/Hint';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { useRef } from 'react';
import { setVideoRatio } from 'slices/media.slice';
import { toggleMinimise } from 'slices/toggle.slice';
import {
  isEmbedPage,
  msToSec,
  shouldUsePlyr,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { useLocation } from 'react-router';
// import { ReactComponent as Video } from 'assets/Icons/video.svg';
// import { ReactComponent as Hd } from 'assets/Icons/hd.svg';

interface IProps {
  isMediaLoading: boolean;
  hasWatermark?: boolean;
  onMediaReadyStateChange?: (readyState: MediaReadyState) => unknown;
}

export const MediaControl = forwardRef(
  (
    { isMediaLoading, onMediaReadyStateChange, hasWatermark }: IProps,
    mediaRef: any,
  ) => {
    const dispatch = useDispatch();
    const routerState: any = useLocation().state;
    const timeParam = toNumber(useSearchParam('time'));
    const shouldAutoplay =
      useSearchParam('autoplay')?.toLocaleLowerCase() === 'true';

    const mediaCtrl = mediaRef.current;

    const media = useSelector((state: RootState) => state.media);
    const toggle = useSelector((state: RootState) => state.toggle);

    const mediaSrc = media?.url ?? '';
    const mediaType =
      media?.metadata?.mediatype || media?.metadata?.mediacontenttype;
    const vttPath = media?.subtitleurl ?? '';

    const [isAudio, setIsAudio] = useState(false);

    const [showCaption, toggleShowCaption] = useToggle(true);

    const fullscreenRef = useRef<any>(null);
    const [containerRef, { height }] = useMeasure();

    const [isRequestFullscreen, setIsRequestFullscreen] = useState(false);
    const [isFullscreenVideo, setIsFullscreenVideo] = useState(false);
    const [readyState, setReadyState] = useState();
    const [plyrInstance, setPlyrInstance] = useState<Plyr | null>(null);

    // hide default and remove after then > trick
    useEffect(() => {
      if (mediaCtrl) {
        for (let i = 0; i < mediaCtrl?.textTracks.length; i++) {
          mediaCtrl.textTracks[i].mode = 'hidden';
          mediaCtrl.textTracks[i].default = false;
        }
        mediaCtrl.removeChild(mediaCtrl.firstChild);

        var element = document.createElement('track');
        element.setAttribute('label', 'English');
        element.setAttribute('kind', 'subtitles');
        element.setAttribute('srcLang', 'en');
        element.setAttribute('src', vttPath);
        element.setAttribute('default', '');
        mediaCtrl.appendChild(element);
      }
    }, [vttPath]);

    useMount(() => {
      if (!mediaRef?.current) return;

      if (shouldUsePlyr()) {
        const instance = new Plyr(mediaRef.current, {
          captions: { active: true, update: true },
          // ratio: '16:9',
          hideControls: false,
          autoplay: shouldAutoplay,
          storage: { enabled: false },
          // fullscreen: { enabled: !hasWatermark },
          // fullscreen: { enabled: !hasWatermark, container: '#player-wrapper' },
          fullscreen: { container: '#player-wrapper' },
        });
        setPlyrInstance(instance);
        window.Video = (instance as any)?.media;

        instance.toggleControls(false);
        instance.on('enterfullscreen', () => instance.toggleControls(true));
        instance.on('exitfullscreen', () => instance.toggleControls(false));
        instance.on('captionsenabled', () => toggleShowCaption(true));
        instance.on('captionsdisabled', () => toggleShowCaption(false));
      }
    });

    useUnmount(() => {
      if (shouldUsePlyr() && plyrInstance) {
        window.Video = null as any;
        plyrInstance.destroy();
      }
    });

    useEffect(() => {
      if (isEmpty(media?.metadata?.captionstype)) return;

      if (media.metadata.captionstype === CaptionStatus.OPEN_CAPTION) {
        toggleShowCaption(false);
        if (shouldUsePlyr() && plyrInstance) {
          plyrInstance.toggleCaptions(false);
        }
      }
    }, [media]);

    const isFullscreenWatermark = useFullscreen(mediaRef, isRequestFullscreen, {
      onClose: () => {},
    });

    useFullscreen(mediaRef, isFullscreenVideo, {
      onClose: () => setIsFullscreenVideo(false),
    });

    useEffect(() => {
      if (!mediaCtrl) return;

      window.Video = mediaCtrl;
    }, [mediaCtrl]);

    useEffect(() => {
      if (isNil(height) || height === 0) return;

      // Auto shrink on small size
      if (
        height <= 101 &&
        !toggle.isMinimise &&
        readyState === MediaReadyState.HAVE_ENOUGH_DATA
      ) {
        dispatch(toggleMinimise(true));
      }
    }, [height]);

    const getValidTimeParam = () => {
      const duration = msToSec(media?.metadata?.length);

      if (isNaN(timeParam) || timeParam < 0 || timeParam > duration) {
        return 0.001; // Default for iOS devices
      }

      return timeParam;
    };

    const checkShowHideCaption = () => {
      const captionMode = mediaCtrl?.textTracks?.[0]?.mode;
      if (!captionMode) return;

      if (captionMode === CaptionEnum.SHOWING) {
        toggleShowCaption(true);
      } else if (captionMode === CaptionEnum.HIDDEN) {
        toggleShowCaption(false);
      }
    };

    useEffect(() => {
      const state = mediaRef.current?.readyState;
      if (!mediaRef.current) return;
      setReadyState(state);

      if (typeof onMediaReadyStateChange === 'function') {
        onMediaReadyStateChange(state);
      }

      if (!shouldUsePlyr()) {
        checkShowHideCaption();
      }

      if (
        [
          MediaReadyState.HAVE_METADATA,
          MediaReadyState.HAVE_ENOUGH_DATA,
        ].includes(state)
      ) {
        const ratio = window.Video.videoHeight / window.Video.videoWidth;
        dispatch(setVideoRatio(ratio));
      }
    }, [mediaRef?.current?.readyState]);

    useEffect(() => {
      setIsRequestFullscreen(isFullscreenWatermark);
    }, [isFullscreenWatermark]);

    useEffect(() => {
      if (!mediaType || typeof mediaType !== 'string') return;

      if (
        mediaType?.trim()?.startsWith('audio') ||
        mediaType?.trim()?.startsWith('video/mp3') ||
        media?.metadata?.haveMp3 ||
        media?.metadata?.filename?.endsWith('.mp3')
      ) {
        setIsAudio(true);
      }
    }, [mediaType]);

    const handleCaption = () => {
      if (shouldUsePlyr()) {
        plyrInstance?.toggleCaptions?.();
        return;
      }

      for (let i = 0; i < mediaCtrl?.textTracks.length; i++) {
        if (mediaCtrl?.textTracks[i].mode === 'showing') {
          mediaCtrl.textTracks[i].mode = 'hidden';
        } else {
          mediaCtrl.textTracks[i].mode = 'showing';
        }
      }

      checkShowHideCaption();
    };

    const handleClickMedia = () => {
      if (!mediaCtrl || isRequestFullscreen || isFullscreenVideo) return;

      if (mediaCtrl?.paused) {
        mediaCtrl?.play();
      } else {
        mediaCtrl?.pause();
      }
    };

    const handleDoubleClickMedia = (e: React.MouseEvent<HTMLVideoElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (isRequestFullscreen) return;

      if (hasWatermark) {
        requestFullscreen();
      } else {
        setIsFullscreenVideo(true);
      }
    };

    const requestFullscreen = () => {
      const document = window.document;
      if (!document?.fullscreenElement) {
        fullscreenRef?.current?.requestFullscreen();
        setIsRequestFullscreen(true);
      } else if (document?.exitFullscreen) {
        document?.exitFullscreen();
        setIsRequestFullscreen(false);
      }
    };

    const triggerFullscreenMode = () => {
      plyrInstance?.fullscreen.enter();
      setIsFullscreenVideo(true);
      // if (hasWatermark) {
      //   plyrInstance?.toggleControls(true);
      //   requestFullscreen();
      // } else {
      //   plyrInstance?.fullscreen.enter();
      //   setIsFullscreenVideo(true);
      // }
    };

    return (
      <>
        <div
          css={[
            isRequestFullscreen && mediaControlCss,
            !toggle?.isMinimise && tw`h-full!`,
            shouldUsePlyr() && plyrFirefoxCss(plyrInstance?.fullscreen?.active),
          ]}
          tw="rounded-lg"
          ref={containerRef as any}
        >
          <div
            tw="h-full rounded"
            css={[
              toggle.isMinimise && tw`max-h-0`,
              tw`overflow-hidden transition[all .4s ease-in-out] flex flex-col`,
              isMediaLoading && tw`hidden!`, // Prevent blank video loading
            ]}
          >
            <div
              id="player-wrapper"
              ref={fullscreenRef}
              tw="relative w-full h-full shadow-lg!"
            >
              <video
                ref={mediaRef}
                // id="media_player"
                css={[
                  isAudio && audioThumbStyles(media?.metadata?.thumbnail),
                  isRequestFullscreen && tw`max-height[unset]!`,
                  isFullscreenVideo && vttStyle,
                ]}
                tw="w-full! cursor-pointer background-color[#f0f0f4]! rounded height[calc(100% - 4rem)]"
                src={mediaSrc + `#t=${getValidTimeParam()}`}
                poster={
                  routerState?.libraryItem?.thumbnail ||
                  media?.metadata?.thumbnail
                }
                // autoPlay={false}
                preload="auto"
                crossOrigin="anonymous"
                controlsList="nodownload"
                playsInline
                onClick={handleClickMedia}
                onDoubleClick={handleDoubleClickMedia}
                onContextMenu={(e) => e.preventDefault()}
              >
                {vttPath && (
                  <track
                    label="English"
                    kind="subtitles"
                    srcLang="en"
                    src={vttPath}
                    default
                  ></track>
                )}
              </video>

              {hasWatermark && (
                <div
                  tw="absolute z-40 right[3%] top[3.5%] max-width[50%] background[rgb(48, 36, 74, 0.85)] rounded flex items-center py-2 px-4 shadow-lg hover:(shadow-xl) cursor-pointer"
                  title="Visit Sonnant.com"
                >
                  <LogoSonnant
                    onClick={() => window.open('https://www.sonnant.com')}
                  />
                </div>
              )}
            </div>

            {!isEmbedPage() && (
              <div
                className="external_ctrols"
                tw="absolute w-full bottom-0 sm-down:relative"
                css={[toggle.isMinimise && tw`hidden`]}
              >
                <div tw="flex justify-between items-center">
                  <div tw="flex flex-nowrap">
                    {/* <button className="button disabled">
                    <Video />
                  </button> */}
                    <Hint text="Closed captions - turn on / off">
                      <button className="button" onClick={handleCaption}>
                        {showCaption ? <Caption /> : <CaptionOff />}
                      </button>
                    </Hint>
                  </div>
                  <div tw="flex flex-nowrap">
                    {/* <button className="button disabled">
                    <Hd />
                  </button> */}
                    <Hint text="Minimize video">
                      <button
                        className="button"
                        onClick={() => {
                          dispatch(toggleMinimise(true));
                        }}
                      >
                        <Minimise />
                      </button>
                    </Hint>
                    <Hint text="Full screen mode">
                      <button
                        className="button"
                        onClick={triggerFullscreenMode}
                      >
                        <FullScreen />
                      </button>
                    </Hint>
                  </div>
                </div>
              </div>
            )}
          </div>

          {isMediaLoading && !toggle.isMinimise && (
            <div
              className="mediaProgressLoader"
              tw="h-full flex justify-center items-center md-down:min-height[40vh]"
            >
              <Loader />
            </div>
          )}
        </div>
        {toggle.isMinimise && (
          <div
            className="maximize-main"
            tw="rounded-lg"
            onClick={() => {
              dispatch(toggleMinimise(false));
            }}
          >
            <div className="maximize-main-player">
              <button className="button" tw="mr-1.5!">
                <Maximise />
              </button>
              <span className="maximize-text">Maximise Player</span>
            </div>
          </div>
        )}
      </>
    );
  },
);

const vttStyle = css`
  ::cue {
    font-size: xxx-large !important;
  }
`;

export const mediaControlCss = css`
  video::-webkit-media-controls-fullscreen-button {
    display: none !important;
  }
`;

export const plyrFirefoxCss = (hasWatermark?: boolean) => css`
  .plyr.plyr--full-ui.plyr--video.plyr--html5 {
    ${hasWatermark ? tw`height[calc(100%)]!` : tw`height[calc(100% - 4rem)]!`}
    background: #f0f0f4;
  }

  .plyr__captions {
    bottom: -1rem;
    
  }

  .plyr__video-wrapper {
    ${tw`h-full`}
  }

  ${plyrResetStyle}

  @media (min-width: 768px) {
    .plyr__captions {
      font-size: ${hasWatermark ? '18px' : '15px'} !important;
    }
  }

  @media (max-width: 767px) {
    .plyr__captions {
      font-size: 0.65em;
    }
  }
`;
