/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactElement, forwardRef, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { RootState } from 'reducers';

import { Modal } from 'components/UiControls/modal/modal';
import { resetDemographics } from 'slices/demographics.slice';
import { ModalOptions } from 'utils/enum';
import { DemographicsButtons } from './components/DemographicsButtons';
import { DemographicsContainer } from './components/DemographicsContainer';
import { DemographicsTitle } from './components/DemographicsTitle';
import { DemographicsModalResult } from 'utils/models/modal.model';

type IProps = {};

type IPromiseParams = {
  resolve: (option?: DemographicsModalResult) => void;
  reject: () => void;
};

export const DemographicsModal = forwardRef(
  (props: IProps, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));
    const dispatch = useDispatch();

    const demographics = useSelector((state: RootState) => state.demographics);

    const [isOpen, toggleIsOpen] = useToggle(false);

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async () => {
      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        toggleIsOpen(true);
      });
    };

    const hideModal = () => {
      toggleIsOpen(false);
    };

    const handleCloseModal = async () => {
      promiseInfo.current?.resolve({
        option: ModalOptions.CLOSE,
      });

      hideModal();
    };

    const handleClickApply = async () => {
      hideModal();

      promiseInfo.current?.resolve({
        option: ModalOptions.YES,
        payload: demographics.items,
      });
    };

    const handleResetDemographics = () => {
      dispatch(resetDemographics());
    };

    return (
      <Modal show={isOpen} modalClosed={handleCloseModal}>
        <div tw="w-full flex flex-col space-y-4">
          <DemographicsTitle title="Demographics Filters" />

          <DemographicsContainer />

          <DemographicsButtons
            onClickApply={handleClickApply}
            onClickReset={handleResetDemographics}
            onClickCancel={handleCloseModal}
          />
        </div>
      </Modal>
    );
  },
);
