/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from 'twin.macro';

import { ReactComponent as WarningSvg } from 'assets/Icons/warning.svg';
import { ReactComponent as OpenSvg } from 'assets/Icons/open_in_new.svg';
import { Tooltip } from '@rmwc/tooltip';
import { IABItemModel } from 'utils/models';
import { hoverTextShadow, svgShadow } from './twin.styles';
import { useHistory } from 'react-router-dom';

interface SensitiveBadgeProps {
  sensitiveItems: IABItemModel[];
  mediaid?: string;
  useZIndexDefault?: boolean;
}

export const SensitiveBadge = (props: SensitiveBadgeProps) => {
  const { mediaid } = props;

  const history = useHistory();

  const handleClickId = (id: string) => {
    history.push(`/transcription/${mediaid}?iab-category=${id}`);
  };


  return (
    <div
      onClick={(e) => e.stopPropagation()}
      tw="(flex items-center mr-2 absolute top[calc(50% - 1.3rem)] right[0.7rem])!"
      css={[!props?.useZIndexDefault && tw`z-index[2]!`]}
    >
      <Tooltip
        className="tooltip-view-clips"
        leaveDelay={200}
        content={
          <div tw="font-size[1.6rem] p-2 z-30 max-width[28rem] overflow-auto mt-2 selection:(background[#706eb0])!">
            A Sensitive Topic IAB Category has been identified with this content
            item:
            <div tw="ml-4!">
              <ul>
                {props.sensitiveItems.map((item) => (
                  <li key={item.id} tw="selection:(background[#706eb0])!">
                    {item.name}{' '}
                    <div
                      tw="cursor-pointer hover:underline flex items-center space-x-2"
                      css={[hoverTextShadow()]}
                      onClick={() => handleClickId(item.id)}
                    >
                      <div>({item.id})</div>
                      <div tw="flex items-center ">
                        <OpenSvg fill="white" tw="zoom[75%]" />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        }
        align="right"
        showArrow={false}
      >
        <WarningSvg onClick={e => e.stopPropagation()} css={svgShadow(1.2)} />
      </Tooltip>
    </div>
  );
};
