import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { AliasTerm } from 'utils/models';

type AliasTermState = {
  aliasTerms: AliasTerm[];
};

const initialState: AliasTermState = {
  aliasTerms: [],
};

export const aliasTermSlice = createSlice({
  name: 'aliasTerm',
  initialState,
  reducers: {
    setAliasTerms: (state, action: PayloadAction<AliasTerm[]>) => {
      if (isEmpty(action.payload)) return;

      state.aliasTerms = action.payload;
    },
  },
});

export const { setAliasTerms } = aliasTermSlice.actions;

export const aliasTermReducer = aliasTermSlice.reducer;
