/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { ReactComponent as SharePublic } from 'assets/Icons/shareBlack.svg';
import { ReactComponent as Download } from 'assets/Icons/downloadBlack.svg';
import { ReactComponent as QuestionSvg } from 'assets/Icons/Vector_question.svg';
import { ReactComponent as Source } from 'assets/Icons/sourceBlack.svg';
import { ReactComponent as SmartPlaySvg } from 'assets/Icons/smart_play.svg';
import { useSelector } from 'react-redux';
import { tierSelector } from 'slices/payment.slice';
import { Hint } from 'components/shared/Hint';
import { useEffect, useState } from 'react';
import { RootState } from 'reducers';
import { PreferenceOption, SharingOption } from 'utils/models';
import { isEmpty } from 'lodash';

interface Props {
  onStateChange: (state: PreferenceOption) => void;
  checkboxState?: SharingOption;
  inModal?: boolean;
}

export const SharingOptions = ({ inModal, checkboxState, ...props }: Props) => {
  const tier = useSelector(tierSelector);
  const global = useSelector((state: RootState) => state.global);

  const initialState = inModal ? checkboxState : global.preference;

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (isEmpty(initialState)) return;

    setState(initialState);
    props?.onStateChange?.(initialState as PreferenceOption);
  }, [initialState]);

  useEffect(() => {
    if (isEmpty(checkboxState)) return;

    setState(checkboxState);
  }, [checkboxState]);

  const handleCheckboxClick = (evt: any) => {
    const value = evt.target.checked;

    const newState = {
      ...state,
      [evt.target.name]: value,
    } as PreferenceOption;

    setState(newState);
    props?.onStateChange?.(newState);
  };

  return (
    <>
      <div className="prefTitle" tw="flex items-center space-x-2">
        <SharePublic css={iconStyle} />
        <h3 tw="mb-0">Public player options for shared clips</h3>
      </div>

      <div tw="flex">
        <label
          className={`large-10 cell ${
            (tier.isTrial || tier.isPAYG) && 'disabled'
          }`}
          tw="flex[unset]! w-auto"
          css={[checkboxCss]}
        >
          <input
            name="logo"
            checked={state?.logo}
            onChange={handleCheckboxClick}
            type="checkbox"
          />
          <span className="checkmark"></span>
          Use uploaded logo on shared content
        </label>
        {(tier.isTrial || tier.isPAYG) && (
          <Hint
            text="Not available on free trial and PAYG. Please upgrade to enable."
            enterDelay={0}
            arrow
          >
            <span tw="cursor-pointer ml-4 mt-1" className="questionsvg">
              <QuestionSvg tw="width[1.6rem] height[1.6rem] relative top[2px]" />
            </span>
          </Hint>
        )}
      </div>

      <div>
        <div
          className="prefTitle"
          tw="flex items-center space-x-2"
          css={[inModal && tw`mt-5`]}
        >
          <Download css={iconStyle} />
          <h3 tw="mb-0">Downloadable assets</h3>
        </div>
        <label className="large-4 cell" css={[checkboxCss]}>
          <input
            name="captionfile"
            checked={state?.captionfile}
            onChange={handleCheckboxClick}
            type="checkbox"
          />
          <span className="checkmark"></span>
          Caption files (SRT, VTT)
        </label>
        <label className="large-5 cell" css={[checkboxCss]}>
          <input
            name="transcripts"
            checked={state?.transcripts}
            onChange={handleCheckboxClick}
            type="checkbox"
          />
          <span className="checkmark"></span>
          Transcripts (PDF, DOC, TXT)
        </label>
        <label className="large-5 cell" css={[checkboxCss]}>
          <input
            name="paraheader"
            checked={state?.paraheader}
            onChange={handleCheckboxClick}
            type="checkbox"
          />
          <span className="checkmark"></span>
          Include paragraph headers (Time, Speaker)
        </label>
      </div>

      <div>
        <div
          className="prefTitle"
          tw="flex items-center space-x-2"
          css={[inModal && tw`mt-5`]}
        >
          <Source css={iconStyle} />
          <h3 tw="mb-0">Included assets and data</h3>
        </div>

        <div tw="flex">
          <div>
            <label className="large-4 cell" css={[checkboxCss]}>
              <input
                name="keyterm"
                checked={state?.keyterm}
                onChange={handleCheckboxClick}
                type="checkbox"
              />
              <span className="checkmark"></span>
              Key terms
            </label>
            <label className="large-4 cell" css={[checkboxCss]}>
              <input
                name="products"
                checked={state?.products}
                onChange={handleCheckboxClick}
                type="checkbox"
              />
              <span className="checkmark"></span>
              Products
            </label>
            <label className="large-4 cell" css={[checkboxCss]}>
              <input
                name="nationalities"
                checked={state?.nationalities}
                onChange={handleCheckboxClick}
                type="checkbox"
              />
              <span className="checkmark"></span>
              Nationalities
            </label>
            <label className="large-4 cell" css={[checkboxCss]}>
              <input
                name="iab"
                checked={state?.iab}
                onChange={handleCheckboxClick}
                type="checkbox"
              />
              <span className="checkmark"></span>
              IAB categories
            </label>
          </div>
          <div>
            <label className="large-4 cell" css={[checkboxCss]}>
              <input
                name="datasummary"
                checked={state?.datasummary}
                onChange={handleCheckboxClick}
                type="checkbox"
              />
              <span className="checkmark"></span>
              Summary
            </label>
            <label className="large-4 cell" css={[checkboxCss]}>
              <input
                name="orgs"
                checked={state?.orgs}
                onChange={handleCheckboxClick}
                type="checkbox"
              />
              <span className="checkmark"></span>
              Organisations
            </label>
            <label
              className={`large-4 cell ${inModal && 'disabled'}`}
              css={[checkboxCss]}
            >
              <input
                name="globalList"
                checked={state?.globalList}
                onChange={handleCheckboxClick}
                type="checkbox"
              />
              <span className="checkmark"></span>
              Applied lists
            </label>

            <label
              className={`large-4 cell ${
                (!global.monetisation.monetisationEnabled || inModal) &&
                'disabled'
              }`}
              css={[checkboxCss]}
            >
              <input
                name="hasAdMarkersLayer"
                checked={state?.hasAdMarkersLayer}
                onChange={handleCheckboxClick}
                type="checkbox"
              />
              <span className="checkmark"></span>
              Ad markers
            </label>
          </div>

          <div>
            <label className="large-4 cell" css={[checkboxCss]}>
              <input
                name="people"
                checked={state?.people}
                onChange={handleCheckboxClick}
                type="checkbox"
              />
              <span className="checkmark"></span>
              People
            </label>
            <label className="large-4 cell" css={[checkboxCss]}>
              <input
                name="locations"
                checked={state?.locations}
                onChange={handleCheckboxClick}
                type="checkbox"
              />
              <span className="checkmark"></span>
              Locations
            </label>
            <label className="large-4 cell" css={[checkboxCss]}>
              <input
                name="chapters"
                checked={state?.chapters}
                onChange={handleCheckboxClick}
                type="checkbox"
              />
              <span className="checkmark"></span>
              Chapters
            </label>
            <label className="large-4 cell" css={[checkboxCss]}>
              <input
                name="speakers"
                checked={state?.speakers}
                onChange={handleCheckboxClick}
                type="checkbox"
              />
              <span className="checkmark"></span>
              Speakers
            </label>
          </div>
        </div>
      </div>

      <div tw="mt-4">
        <div className="prefTitle" tw="flex items-center space-x-2">
          <SmartPlaySvg css={iconStyle} fill="black" />
          <h3 tw="mb-0">Playbar visuals</h3>
        </div>

        <label className="large-4 cell" tw="ml-20 max-w-full cursor-pointer">
          <input
            name="visualplaybar"
            checked={state?.visualplaybar}
            onChange={handleCheckboxClick}
            type="checkbox"
          />
          <span className="checkmark" style={{ top: '5px' }}></span>
          Allow user interaction
        </label>
      </div>
    </>
  );
};

const iconStyle = css`
  width: 40px;
`;

const checkboxCss = css`
  ${tw`ml-20 max-w-full cursor-pointer`}

  span {
    ${tw`top[5px]`}
  }
`;
