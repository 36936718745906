/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react';
import tw from 'twin.macro';

import React, { useMemo, useState } from 'react';

import { Ripple } from '@rmwc/ripple';
import { isNumber, orderBy } from 'lodash';
import Select, { SingleValue } from 'react-select';
import { Option, defaultStyles } from '../CustomSelectSearch';
import { Hint } from '../Hint';
import { getOptionNestedIab } from 'utils/adapter.util';
import { iabMock } from 'mocks/iab.mock';
import { IabBucket } from 'utils/models';

export type IabOptionDropdown = Option & {
  totalItems?: number;
};

type Props = {
  iabSortedBuckets: IabBucket[];
  isLoading: boolean;
  onClickTagNumber: (option: IabOptionDropdown) => void;
  onChangeOption: (option: IabOptionDropdown) => void;
  selectedOption: Option;
  isDisabled?: boolean;

  shouldShowTagNumber?: boolean;
  overriddenCss?: SerializedStyles[];
};

export const iabDefaultOption: Option = {
  label: '-- All categories --',
  value: '',
};

export const InsightIabDropdown = ({
  iabSortedBuckets,
  isLoading,
  overriddenCss = [],
  shouldShowTagNumber = true,
  selectedOption,
  isDisabled = false,
  ...props
}: Props) => {
  const [inputValue, setInputValue] = useState<string>();

  const iabSearchDataSource = useMemo<IabOptionDropdown[]>(() => {
    const mostFrequentCategories =
      iabSortedBuckets?.map((item) => ({
        key: item.key,
        doc_count: item.doc_count,
        media_items: item.media_ids?.buckets.map((bucket) => bucket.metadata),
      })) ?? [];

    const top20Categories = mostFrequentCategories.map(({ key }) => key);

    const iabSearchData = getOptionNestedIab(iabMock.allCategories);

    const mostUsedIabItems: IabOptionDropdown[] = orderBy(
      iabSearchData,
      [
        (obj) => {
          const index = top20Categories.indexOf(obj.value);

          return index === -1 ? Infinity : index; // Not found items to bottom
        },
      ],
      ['asc'],
    ).map((iabOption) => {
      const foundOption = mostFrequentCategories.find(
        (item) => item.key === iabOption.label,
      );

      return foundOption
        ? { ...iabOption, totalItems: foundOption.doc_count }
        : iabOption;
    });

    mostUsedIabItems.unshift(iabDefaultOption);

    return mostUsedIabItems;
  }, [iabSortedBuckets]);

  const handleClickTagNumber =
    (option: IabOptionDropdown) => (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (typeof props.onClickTagNumber !== 'function') return;

      props.onClickTagNumber(option);
    };

  const handleChange = (newValue: SingleValue<IabOptionDropdown>) => {
    setInputValue(newValue?.label);

    if (typeof props.onChangeOption !== 'function') return;

    props.onChangeOption(newValue as IabOptionDropdown);
  };

  const renderFormatOption = (option: IabOptionDropdown) => (
    <div tw="flex items-center gap-x-2.5 justify-between text-14">
      <div tw="flex gap-x-3.5" className="optionLabel">
        {option.label}
      </div>

      {shouldShowTagNumber &&
        isNumber(option.totalItems) &&
        option.totalItems > 0 && (
          <Hint
            text="Show associated media items"
            enterDelay={200}
            arrow
            notTransparent
          >
            <div tw="flex items-center justify-center">
              <Ripple onClick={handleClickTagNumber(option)}>
                <span tw="min-w-[2.6rem] h-[2.6rem] line-height[1.2] border[1px solid] border-sonnant-grey-4 bg-sonnant-grey-1 shadow-sm rounded-full flex justify-center items-center px-1.5">
                  {option.totalItems}
                </span>
              </Ripple>
            </div>
          </Hint>
        )}
    </div>
  );

  return (
    <div css={[customCss, ...overriddenCss]}>
      <Select
        // menuIsOpen={true}
        isSearchable
        isLoading={isLoading}
        escapeClearsValue={true}
        styles={defaultStyles}
        options={iabSearchDataSource}
        closeMenuOnSelect
        onChange={handleChange}
        classNamePrefix={'iab-dropdown'}
        formatOptionLabel={renderFormatOption}
        isDisabled={isDisabled}
        value={selectedOption}
        placeholder={inputValue as any}
      />
    </div>
  );
};

const customCss = css`
  ${tw`[> div]:w-[unset]`}

  input.iab-dropdown__input:focus {
    ${tw`(p-0 border[none] outline[none] bg-transparent)!`}
  }

  .iab-dropdown__control {
    ${tw`w-[24.5rem]`}
  }

  .iab-dropdown__menu {
    ${tw`mt-[2px]`}
  }

  .iab-dropdown__option {
    &:nth-child(even) {
      ${tw`bg-sonnant-grey-2`}
    }

    &:nth-child(odd) {
      ${tw`bg-white`}
    }
  }

  .iab-dropdown__single-value .optionLabel {
    ${tw`block w-full truncate`}
  }
`;
