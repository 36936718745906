/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { floor, isEmpty, isNaN, isNil, round, toNumber } from 'lodash';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { RootState } from 'reducers';
import { IStartEnd } from 'utils/models';
import Wavesurfer from 'wavesurfer.js';
// @ts-ignore
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor';
// @ts-ignore
import RegionPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions';
import { Region, RegionsPluginParams } from 'wavesurfer.js/src/plugin/regions';
import { WaveSurferParams } from 'wavesurfer.js/types/params';

interface IProps {
  src: string;
  currentTime?: IStartEnd | null;
  defaultTime?: IStartEnd | null;
  progressColor?: string;
  cursorColor?: string;
  shouldReset?: boolean;
  shouldZoomFocus?: boolean;
  zoomLevel?: number;
  setZoomLevel?: (zoom: number) => unknown;
  shouldHideRegion?: boolean;
  onRegionUpdate?: (start: number, end: number) => unknown;
  customParams?: Partial<WaveSurferParams>;
}

export const WaveSurferVideo = forwardRef((props: IProps, videoRef: any) => {
  // console.count('==== WaveSurferVideo');

  const [isLoaded, toggleLoaded] = useToggle(false);
  const [isReady, toggleReady] = useToggle(false);
  const [videoDuration, setVideoDuration] = useState<number>();

  const media = useSelector((state: RootState) => state.media);

  const wavesurferRef = useRef(null);
  const wavesurferInstanceRef = useRef<any>(null);

  useEffect(() => {
    if (
      isNil(videoRef?.current?.duration) ||
      isNaN(videoRef?.current?.duration)
    )
      return;

    const totalDuration = floor(videoRef?.current?.duration, 3);
    setVideoDuration(totalDuration);

    if (!props?.currentTime) return;
    const { startTime, endTime } = props?.currentTime;

    const regionTime = endTime - startTime;
    const TOTAL_PIXELS = 615;
    if (regionTime / TOTAL_PIXELS > 0.9 || regionTime >= totalDuration) return;

    let needToZoom = (TOTAL_PIXELS / regionTime) * 0.9;
    if (needToZoom > 100) {
      needToZoom = 100;
    }

    wavesurferInstanceRef.current.zoom(round(needToZoom));
    setTimeout(() => {
      window.document
        ?.querySelector('region.wavesurfer-region')
        ?.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
        });
    }, 2000);
    props?.setZoomLevel?.(round(needToZoom));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef?.current?.duration]);

  useEffect(() => {
    if (!props?.src) return;

    if (wavesurferRef.current && videoRef?.current) {
      const wavesurfer = Wavesurfer.create({
        container: wavesurferRef.current as any,
        backend: 'MediaElement',
        height: 40,
        barWidth: 2,
        barRadius: 2,
        cursorWidth: 2,
        // barGap: 1,
        barGap: 0,
        cursorColor: props?.cursorColor ?? '#E7483D',
        waveColor: 'white',
        // progressColor: props?.progressColor ?? '#555',
        progressColor: props?.progressColor ?? '#bd181c',
        // normalize: true,
        responsive: true,
        pixelRatio: 1,
        autoCenterRate: 10,
        minPxPerSec: 1,
        plugins: [
          RegionPlugin.create({
            regions: [
              {
                id: '1',
                start: 0,
                // end: 60,
                color: 'transparent',
                preventContextMenu: true,
                // @ts-ignore
                minLength: 1,
              },
            ],
          } as RegionsPluginParams),
          CursorPlugin.create({
            showTime: true,
            opacity: 1,
            customShowTimeStyle: {
              'font-size': '1.1rem',
              color: '#FEB446',
              padding: '4px',
              'font-weight': 500,
              'margin-top': '-22px',
            },
          }),
        ],
        ...props?.customParams,
      });

      // Load from v2.json otherwise load default by WaveSurfer
      if (videoRef?.current) {
        wavesurfer.load(videoRef?.current, media?.waveform?.data || undefined);
      }

      wavesurferInstanceRef.current = wavesurfer;

      wavesurfer.on('ready', () => {
        (window as any).WaveSurfer = wavesurfer;

        if (
          !isNil(videoRef?.current?.currentTime) &&
          !isNil(props.currentTime)
        ) {
          rePositioningRegion();
        }

        toggleLoaded(true);
        toggleReady(true);

        wavesurfer.on('region-updated', (region: Region) => {
          props.onRegionUpdate?.(region?.start, region?.end);

          if (wavesurfer?.cursor.params.showTime === true)
            wavesurfer.cursor.hideCursor();
        });

        wavesurfer.on('region-out', () => {
          if (wavesurfer.isPlaying()) {
            wavesurfer.pause();
          }
        });

        wavesurfer.on('region-mouseleave', () => {
          wavesurfer.cursor.showCursor();
        });
      });

      // IMPORTANT: Turn on destroy() may break MediaControl.tsx after changing version
      // return () => wavesurfer.destroy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.src]);

  useEffect(() => {
    if (!wavesurferInstanceRef.current || isEmpty(media?.waveform?.data))
      return;

    wavesurferInstanceRef.current.backend.setPeaks(media.waveform!.data);
  }, [media?.waveform?.data]);

  useEffect(() => {
    if (!wavesurferInstanceRef?.current?.regions) return;

    wavesurferInstanceRef.current.regions.list[1].update({
      start: props?.defaultTime?.startTime,
      end: props?.defaultTime?.endTime,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.shouldReset]);

  useEffect(() => {
    if (
      !wavesurferInstanceRef?.current?.regions ||
      !videoRef?.current ||
      !props?.currentTime ||
      !videoDuration
    )
      return;

    const { startTime, endTime } = props?.currentTime;

    const regionTime = endTime - startTime;
    const TOTAL_PIXELS = 615;
    let needToZoom = (TOTAL_PIXELS / regionTime) * 0.9;
    if (needToZoom > 100) {
      needToZoom = 100;
    }

    wavesurferInstanceRef.current.zoom(round(needToZoom));
    window.document?.querySelector('region.wavesurfer-region')?.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
    });
    props?.setZoomLevel?.(round(needToZoom));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.shouldZoomFocus]);

  useEffect(() => {
    if (isEmpty(props.currentTime)) return;

    if (!wavesurferInstanceRef.current?.regions.list) return;

    // Reset to INITIAL TIME RANGE
    rePositioningRegion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.currentTime?.startTime, props?.currentTime?.endTime]);

  const rePositioningRegion = () => {
    const currentTime = toNumber(videoRef?.current?.currentTime);
    const startTime = toNumber(props?.currentTime?.startTime);

    if (!videoRef?.current || !props.currentTime) return;

    if (currentTime > startTime) return;

    wavesurferInstanceRef.current.regions.list[1].update({
      start: props?.currentTime?.startTime,
      end: props?.currentTime?.endTime,
    });

    if (!isNil(videoRef?.current?.currentTime) && !isNil(props.currentTime)) {
      videoRef.current.currentTime = props.currentTime?.startTime;
    }
  };

  useEffect(() => {
    if (isNil(props.zoomLevel) || !wavesurferInstanceRef.current) return;

    wavesurferInstanceRef.current.zoom(round(props.zoomLevel));
  }, [props?.zoomLevel]);

  return (
    <div
      css={[
        wavesurferStyles,
        (!isLoaded ||
          !isReady ||
          props?.shouldHideRegion ||
          isEmpty(videoRef?.current) ||
          isEmpty(wavesurferRef?.current)) &&
          hiddenRegion,
        toNumber(props?.zoomLevel) > 0 && cursorShorter,
      ]}
      ref={wavesurferRef}
    ></div>
  );
});

const hiddenRegion = css`
  region.wavesurfer-region {
    ${tw`hidden`}
  }
`;

const wavesurferStyles = css`
  ${tw`relative cursor-pointer`}

  region.wavesurfer-region {
    ${tw`
      relative
      border-solid
      // border-sonnant-red
      border-sonnant-orange
      border-width[3px 1rem]
      rounded
      z-index[10]!
    `}

    handle.wavesurfer-handle {
      ${tw`(background[unset] width[1rem])!`}

      &::before, &::after {
        content: '';
        position: absolute;
        border: 1px solid black;
        border-top: none;
        border-bottom: none;
        width: 3px;
        height: 60%;
        top: 20%;
        left: 4px;
      }

      &.wavesurfer-handle-start {
        left: -1rem !important;
      }
      &.wavesurfer-handle-end {
        right: -1rem !important;
      }
    }
  }

  wave {
    scrollbar-width: thin;
    @-moz-document url-prefix() {
      height: 35px !important;
    }

    /* wave:nth-of-type(1) {
      &::before {
        position: absolute;
        width: 8px;
        height: 8px;
        border: 1px solid white !important;
        border-radius: 50%;
        background-color: #5551ff;
        content: '';
        right: -2px;
        top: 0;
      }
    } */
  }
`;

const cursorShorter = css`
  cursor {
    bottom: 5px !important;
  }
`;
