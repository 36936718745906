/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Info } from 'components/shared/Info';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import Loader from 'components/loader/loader';

type Props = {};

export const EmbedLayerEmpty = (props: Props) => {
  const player = useSelector((state: RootState) => state.player);

  if (!player.loadCompleted) {
    return (
      <div tw="h-[calc(100vh - 25rem)] w-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <div tw="px-3 py-3">
      <Info text="Nothing to show." />
    </div>
  );
};
