/** @jsxImportSource @emotion/react */
import { Ripple } from '@rmwc/ripple';
import { CLOSE_PUBLISH_LIBRARY } from 'actions/types';
import { ReactComponent as PublishSvg } from 'assets/Icons/movie_black_24dp.svg';
import { Publish } from 'components/Publish/Publish';
import { Hint } from 'components/shared/Hint';
import { Modal } from 'components/UiControls/modal/modal';
import {
  cloneDeep,
  isEmpty,
  isEqual,
  isNil,
  last,
  max,
  orderBy,
  round,
} from 'lodash';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { RootState } from 'reducers';
import { setChapter, setNewTimeCode } from 'slices/chapter.slice';
import tw from 'twin.macro';
import { Keys } from 'utils/enum';
import { IChapterRaw } from 'utils/models/transcript.model';
import {
  getLatestTitle,
  isPublicShared,
  msToSec,
  scrollToNearestNode,
  secToTime,
} from '../../MediaUtilities';
import { ReactComponent as CloseIcon } from 'assets/Icons/close.svg';
import { ReactComponent as BookmarkSvg } from 'assets/Icons/bookmark.svg';
import { TimeCodeChapterModal } from 'components/shared/TimeCodeModal/TimeCodeChapterModal';
import { customToast } from 'utils/toast.util';

interface IProps {
  isReadOnly?: boolean;
}

interface ClipOption {
  show: boolean;
  start: number;
  end: number;
  headline: string;
}

export const Chapter = ({ isReadOnly = false, ...props }: IProps) => {
  const chapters = orderBy(
    useSelector((state: RootState) => state.chapter.chapter),
    (c) => c.startTime,
    ['asc'],
  );
  const media = useSelector((state: RootState) => state.media);
  const toggle = useSelector((state: RootState) => state.toggle);
  const currentChapterId = useSelector(
    (state: RootState) => state.chapter.currentChapterId,
  );
  const [clipOptions, setClipOptions] = useState<ClipOption>({
    show: false,
    start: 0,
    end: 0,
    headline: '',
  });

  const [idEditing, setIdEditing] = useState<number>(-1);

  const timecodeRef = useRef<any>(null);

  const dispatch = useDispatch();

  const handleTimecode = async (chapter: IChapterRaw) => {
    if (isReadOnly) return;
    if (!toggle.isEditMode) {
      customToast.error('Edit mode is off');
      return;
    }

    const newTimecode: { start: number; end: number; headline: string } =
      await timecodeRef.current?.show({
        start: chapter.startTime,
        end: chapter.endTime,
        prevEnd: 0,
        nextStart: msToSec(media?.metadata?.length),
        mode: 'chapter',
        chapterId: chapter?.id,
        headline: chapter?.headline,
      });
    if (!newTimecode) return;

    let nextChapterTimeCode: null | number = null;

    const newChapters = chapters.map((c) => {
      const cloneChap = { ...c };
      if (nextChapterTimeCode) {
        cloneChap.startTime = nextChapterTimeCode;
        nextChapterTimeCode = null;
      }
      if (chapter?.id === cloneChap?.id) {
        cloneChap.startTime = newTimecode?.start;
        cloneChap.endTime = newTimecode?.end;
        cloneChap.headline = newTimecode?.headline;
        nextChapterTimeCode = newTimecode?.end;
      }
      return cloneChap;
    });
    dispatch(setChapter(newChapters));
    dispatch(setNewTimeCode(newTimecode.start));
  };

  const ChapterItem = (chapter: IChapterRaw) => {
    const [isUpdating, toggleUpdating] = useToggle(idEditing === chapter.id);
    const isEditMode = toggle.isEditMode;

    const [headline, setHeadline] = useState<string>(chapter?.headline ?? '');

    const handleClickChapter = () => {
      const transcripts: any[] = window?.Editor?.children;

      if (!isNil(window?.Video?.currentTime) && !isEmpty(transcripts)) {
        window.Video.currentTime = chapter.startTime + 0.001;
        scrollToNearestNode(chapter.startTime);
      }
    };

    const handleInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
        saveChange();
      }
      if (e.key === Keys.ESC) {
        deleteChapter();
        toggleUpdating(false);
        setIdEditing(-1);
      }
    };

    const saveChange = () => {
      if (headline) {
        const newChapters = chapters.map((c) => ({
          ...c,
          headline: isEqual(c.id, chapter.id) ? headline : c.headline,
        }));
        dispatch(setChapter(newChapters));
      } else {
        deleteChapter();
      }
      toggleUpdating(false);
      setIdEditing(-1);
    };

    const deleteChapter = () => {
      const newChapters = chapters.filter((c) => c?.id !== chapter?.id);
      dispatch(setChapter(newChapters));
    };

    const handleBlur = () => {
      // if (chapter.id === idEditing) return;

      if (!chapter.headline && !headline) {
        deleteChapter();
      } else {
        saveChange();
      }
      toggleUpdating();
    };

    return (
      <Ripple css={currentChapterId === chapter.id && tw`bg-sonnant-grey-3`}>
        <div
          tw="py-1.5 font-size[1.7rem] cursor-pointer px-2 rounded flex items-center"
          onClick={handleClickChapter}
        >
          <span tw="flex items-center">
            <BookmarkSvg />
          </span>
          <Hint text="Double click to edit" enterDelay={200} leaveDelay={0}>
            <span
              onDoubleClick={() => handleTimecode(chapter)}
              tw="px-1"
              css={[
                toggle.isEditMode &&
                  tw`border[1px dashed] border-disabled rounded select-none`,
              ]}
            >
              {secToTime(chapter.startTime)}
            </span>
          </Hint>
          <span tw="px-2">-</span>
          {isUpdating && isEditMode ? (
            <input
              type="text"
              tw="(height[3rem] pl-3 margin-bottom[-1px])!"
              value={headline}
              onChange={(e) => setHeadline(e.target.value)}
              onKeyDown={handleInput}
              onBlur={handleBlur}
              autoFocus
            />
          ) : (
            <span
              onDoubleClick={() => {
                if (isReadOnly) return;
                if (!toggle.isEditMode) {
                  customToast.error('Edit mode is off');
                  return;
                }

                toggleUpdating();
              }}
              className="line-clamp-1"
              tw="w-full height[2.5rem]"
              title={headline}
            >
              {headline}
            </span>
          )}
          {!isPublicShared() && (
            <div tw="flex space-x-3 items-center">
              <Hint text="Create clip from chapter">
                <div
                  tw="flex ml-3"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    setClipOptions({
                      show: true,
                      start: chapter.startTime,
                      end: chapter.endTime,
                      headline: chapter.headline,
                    });
                  }}
                >
                  <PublishSvg style={{ fill: '#7F8090' }} />
                </div>
              </Hint>

              {toggle.isEditMode && (
                <div onClick={deleteChapter} className="closeButton">
                  <CloseIcon fill="black" />
                </div>
              )}
            </div>
          )}
        </div>
      </Ripple>
    );
  };

  const handleAddChapter = () => {
    // setIdEditing(null);
    const nextId = (max(chapters?.map((c) => c?.id)) ?? 0) + 1;
    const lastChap = last(chapters);
    const videoDuration = msToSec(media?.metadata?.length);

    if (!videoDuration) return;

    // Add new chapter at the middle of last chapter and end time
    // Upto 2 decimal places
    const newChapterStart = round(
      ((lastChap?.startTime ?? 0) + videoDuration) / 2,
      2,
    );

    const cloneChapters = cloneDeep(chapters) ?? [];
    if (cloneChapters?.length > 0) {
      last(cloneChapters)!.endTime = newChapterStart;
    }

    const newChapters: IChapterRaw[] = [
      ...cloneChapters,
      {
        id: nextId,
        startTime: newChapterStart,
        endTime: videoDuration,
        headline: '',
      },
    ];

    const lastedChapterStartTime =
      newChapters?.[newChapters.length - 1]?.startTime;

    const MIN_CHAPTER_GAP = 2; // in seconds
    if (videoDuration - lastedChapterStartTime < MIN_CHAPTER_GAP) {
      customToast.error(
        <div>
          <div>
            There is not enough time between the last chapter and the end of the
            file to insert a new chapter.
          </div>
          <div tw="mt-2">
            Move the last chapter to make at least{' '}
            <b>{MIN_CHAPTER_GAP} seconds</b> of space before the end.
          </div>
        </div>,
        {
          duration: 3000,
          style: {
            maxWidth: '420px',
          },
        },
      );

      return;
    }

    dispatch(setChapter(newChapters));
    setIdEditing(nextId);
  };

  const resetClipModal = () => {
    setClipOptions({ show: false, start: 0, end: 0, headline: '' });
    dispatch({
      type: CLOSE_PUBLISH_LIBRARY,
    });
  };

  return (
    <div
      tw="mt-6 pb-6 height[100vh - 62rem] overflow-y-auto"
      className="chapter-scroll-wrapper"
    >
      <div>
        {chapters?.map((chap) => (
          <ChapterItem key={chap.id} {...chap} />
        ))}
      </div>

      {!isReadOnly && toggle.isEditMode && !isPublicShared() && (
        <>
          <Ripple>
            <div
              tw="text-16 mt-2 font-medium px-2 text-sonnant-grey-5 cursor-pointer rounded select-none"
              onClick={handleAddChapter}
            >
              + Add chapter
            </div>
          </Ripple>

          <TimeCodeChapterModal ref={timecodeRef} />
        </>
      )}

      <Modal
        classes="publish-modal-width"
        show={clipOptions.show}
        modalClosed={resetClipModal}
      >
        <Publish
          startTime={clipOptions?.start}
          endTime={clipOptions?.end}
          isOpen={clipOptions.show}
          mediaid={media?.metadata?.mediaid}
          filename={
            (clipOptions.headline ?? getLatestTitle(media?.metadata)) +
            ' - Chapter'
          }
          closeShareModal={resetClipModal}
          onSuccess={() => {}}
        />
      </Modal>
    </div>
  );
};
