import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { isEmpty, first } from 'lodash';
import { RootState } from 'reducers';
import { TagResponse, TagService } from 'services/tag.service';
import { extract } from 'utils/generic.util';

export const restrictedTags = createSelector(
  (state: RootState) => state,
  (rootState): TagResponse[] => {
    const tags = rootState.tags.activeTags;
    const allCollectionIds = extract(rootState.collection.items, 'id');

    return tags
      .filter((tag) => !isEmpty(tag.collectionIds))
      .filter((tag) =>
        tag.collectionIds.every((id) => allCollectionIds.includes(id)),
      );
  },
);

type TagState = {
  activeTags: TagResponse[];
  selectedTagId: string | null;
  isLoading: boolean;
};

const initialState: TagState = {
  activeTags: [],
  selectedTagId: null,
  isLoading: false,
};

export const fetchAllTags = createAsyncThunk<TagResponse[]>(
  'tags/fetchAll',
  async () => {
    const { data } = await TagService.getAllTags();
    return data;
  },
);

const tagSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTags: (state, { payload }: PayloadAction<TagState['activeTags']>) => {
      state.activeTags = payload;
    },
    appendNewTag: (state, { payload }: PayloadAction<TagResponse>) => {
      state.activeTags.push(payload);
    },
    updateTag: (state, { payload }: PayloadAction<TagResponse>) => {
      const foundIndex = state.activeTags.findIndex(
        ({ tagId }) => tagId === payload.tagId,
      );

      if (foundIndex < 0) return;

      state.activeTags[foundIndex] = payload;
    },
    deleteTag: (state, { payload }: PayloadAction<TagResponse>) => {
      const filteredTags = state.activeTags.filter(
        ({ tagId }) => tagId !== payload.tagId,
      );

      state.activeTags = filteredTags;

      if (isEmpty(filteredTags)) {
        state.selectedTagId = null;
      } else {
        state.selectedTagId = first(filteredTags)?.tagId as string;
      }
    },
    setSelectedTagId: (
      state,
      { payload }: PayloadAction<TagState['selectedTagId']>,
    ) => {
      state.selectedTagId = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTags.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllTags.fulfilled, (state, { payload }) => {
        state.isLoading = false;

        if (isEmpty(payload)) {
          state.selectedTagId = null;
        }

        state.activeTags = payload;
      })
      .addCase(fetchAllTags.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setTags, setSelectedTagId, appendNewTag, updateTag, deleteTag } =
  tagSlice.actions;

export const tagReducer = tagSlice.reducer;
