/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import tw from 'twin.macro';

import { ReactComponent as DelSvg } from 'assets/Icons/x-cancel-red_28dp.svg';
import { ReactComponent as EditSvg } from 'assets/Icons/edit_black_18dp.svg';

import { Hint } from 'components/shared/Hint';
import { disabledCss, hoverDropShadow } from 'components/shared/twin.styles';
import { Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { AdminPortalService } from 'services/admin-portal.service';
import { Switcher } from 'components/shared/Switcher';
import { useToggle } from 'react-use';
import { TextInput } from 'components/shared/TextInput';
import { updateUserGroupItem } from 'slices/admin-portal.slice';
import { customToast } from 'utils/toast.util';
import { first, isEmpty } from 'lodash';
import { UserGroupResponse } from 'utils/models';
import { HintDisallowed } from 'components/shared/HintDisallowed';

type Props = {
  index: number;
  groupItem: UserGroupResponse;
  handleDeleteGroup: (item: UserGroupResponse) => void;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const PortalGroupItem = ({ groupItem, index, ...props }: Props) => {
  const dispatch = useDispatch();

  const adminPortal = useSelector((state: RootState) => state.adminPortal);

  const userGroups = adminPortal.userGroups;
  const isSelectedGroup = adminPortal.selectedUserGroupId === groupItem.groupId;

  const [isEditing, toggleEditing] = useToggle(false);

  const isAdminGroup = groupItem.isDefault && groupItem.isAdmin;

  const handleUpdateGroupItem = <K extends keyof UserGroupResponse>(
    key: K,
    value: UserGroupResponse[K],
  ) => {
    const updatedUserGroup = {
      ...groupItem,
      [key]: value,
    };

    dispatch(updateUserGroupItem(updatedUserGroup));

    handleUpdateGroupPropertyAsync(updatedUserGroup, key);
  };

  const handleUpdateGroupName = (rawName: string) => {
    const groupName = rawName?.trim() ?? '';

    if (isEmpty(groupName)) return;

    if (groupName === groupItem.groupName) {
      cancelEditing();
      return;
    }

    const existingUserGroup = userGroups.filter(
      (userGroup) => userGroup.groupName === groupName,
    );

    if (!isEmpty(existingUserGroup)) {
      customToast.warning(
        <div>
          <strong>{first(existingUserGroup)?.groupName}</strong> is already
          existing.
          <div>Please check and try again!</div>
        </div>,
      );

      return;
    }

    handleUpdateGroupItem('groupName', groupName);
    cancelEditing();
  };

  const cancelEditing = () => {
    toggleEditing(false);
  };

  const enableEditing = () => {
    if (groupItem.isDefault || isEditing) return;

    toggleEditing(true);
  };

  const handleUpdateGroupPropertyAsync = async (
    userGroup: UserGroupResponse,
    key: keyof UserGroupResponse,
  ) => {
    const body = {
      groupName: key === 'groupName' ? userGroup.groupName : undefined,
      permission: {
        isAdmin: userGroup.isAdmin,
        isEnabledEdit: userGroup.isEnabledEdit,
        isEnabledPublish: userGroup.isEnabledPublish,
      },
    };

    const updateAsync = AdminPortalService.updateUserGroup(
      userGroup.groupId,
      body,
    );

    customToast.promise(updateAsync, {
      loading: 'Updating',
      success: 'Done',
      error: 'Failed to update user group',
    });

    try {
      await updateAsync;
    } catch (error) {
      console.log('error :>> ', error);
    }
  };

  return (
    <Draggable
      index={index}
      key={groupItem.groupId}
      draggableId={groupItem.groupName}
      isDragDisabled={true}
    >
      {(provided) => (
        <div
          key={groupItem.groupId}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          tw="flex w-full justify-between py-2 rounded -ml-3 pl-3 pr-2 cursor-pointer"
          onClick={props.onClick}
          css={
            isSelectedGroup
              ? tw`background[#DAD9FE]`
              : tw`hover:(bg-sonnant-grey-2)`
          }
        >
          <div tw="flex items-center opacity[0.85] font-medium">
            <span tw="mr-6">{index + 1}.</span>

            {isEditing ? (
              <TextInput
                tw="ml-2 pl-2 font-medium placeholder:(font-light)"
                defaultText={groupItem.groupName}
                placeholder={groupItem.groupName}
                onPressEnter={handleUpdateGroupName}
                onPressEsc={cancelEditing}
                onBlur={cancelEditing}
              />
            ) : (
              <span tw="break-all" onDoubleClick={enableEditing}>
                {groupItem.groupName}
              </span>
            )}
          </div>

          {!isAdminGroup && (
            <div
              tw="flex items-center cursor-pointer space-x-6"
              onClick={(e) => e.stopPropagation()}
            >
              {/* EDIT */}
              <Hint text="Edit group" enterDelay={300} leaveDelay={0}>
                <div tw="cursor-pointer">
                  <Switcher
                    hasLabel
                    checked={groupItem.isEnabledEdit}
                    color="#00AF7A"
                    onCheckedChange={(value) =>
                      handleUpdateGroupItem('isEnabledEdit', value)
                    }
                  />
                </div>
              </Hint>

              {/* PUBLISH */}
              <Hint text="Publish group" enterDelay={300} leaveDelay={0}>
                <div tw="cursor-pointer">
                  <Switcher
                    hasLabel
                    checked={groupItem.isEnabledPublish}
                    color="#00AF7A"
                    onCheckedChange={(value) =>
                      handleUpdateGroupItem('isEnabledPublish', value)
                    }
                  />
                </div>
              </Hint>

              {/* RENAME */}
              <HintDisallowed
                disabled={groupItem.isDefault}
                hintEnabled="Rename this group"
                hintDisabled="Default groups cannot be edited or deleted"
              >
                <div
                  tw="w-[2.4rem] h-[2.4rem] flex items-center justify-center bg-white hover:bg-sonnant-grey-2 border[2px solid black] mr-2 cursor-pointer rounded-full"
                  css={[
                    hoverDropShadow(0.1),
                    groupItem.isDefault && disabledCss,
                  ]}
                  onClick={enableEditing}
                >
                  <EditSvg />
                </div>
              </HintDisallowed>

              {/* DELETE */}
              <HintDisallowed
                disabled={groupItem.isDefault}
                hintEnabled="Delete this group"
                hintDisabled="Default groups cannot be edited or deleted"
              >
                <div
                  tw="flex cursor-pointer"
                  css={[
                    hoverDropShadow(0.2),
                    groupItem.isDefault && disabledCss,
                  ]}
                  onClick={() => props?.handleDeleteGroup(groupItem)}
                >
                  <DelSvg />
                </div>
              </HintDisallowed>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};
