/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as Upload } from 'assets/Icons/movie_black_24dp.svg';
import { Publish } from 'components/Publish/Publish';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../UiControls/modal/modal';
import * as types from '../../actions/types';
import './HighlightPopper.scss';
import { Tooltip } from '@rmwc/tooltip';
import { getSlateSelection } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { waitAsync } from 'utils/utils';
import { ReactEditor } from 'slate-react';
import { RootState } from 'reducers';
import { Layers } from 'utils/enum';

export interface HighlightPopperProps {
  children: React.ReactNode;
  // TODO: Improve
  mediaid: string;
  filename: string;
  editMode: boolean;
  disabled?: boolean;
}
export const HighlightPopper = ({
  children,
  mediaid,
  filename,
  editMode,
  disabled,
}: HighlightPopperProps) => {
  const dispatch = useDispatch();
  const highlight = useRef<HTMLDivElement | null>(null);

  const [isOpenPublishModal, setIsOpenPublishModal] = useState<boolean>(false);
  const [popper, setPopper] = useState<{
    showPopover: boolean;
    x: number;
    y: number;
  }>({
    showPopover: false,
    x: 0,
    y: 0,
  });
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [snippetText, setSnippetText] = useState<string>('');

  const layers = useSelector((state: RootState) => state.layers);

  function handleClosePublishModal() {
    dispatch({
      type: types.CLOSE_PUBLISH_LIBRARY,
    });
    setIsOpenPublishModal(false);
  }

  useEffect(() => {
    window.addEventListener('mouseup', onMouseUp);
    return () => window.removeEventListener('mouseup', onMouseUp);
  }, [layers]);

  const hidePopover = () => {
    setPopper({ ...popper, showPopover: false });
  };

  const onMouseUp = async () => {
    if ([Layers.SUMMARY].includes(layers.currentLayer)) {
      hidePopover();
      return;
    }

    let selection = window.getSelection();

    try {
      const slateSelection = ReactEditor.toSlateRange(
        window.Editor,
        window.getSelection()!,
        {
          exactMatch: false,
          // suppressThrow: false
        },
      );
      if (!slateSelection) {
        hidePopover();
        return;
      }
    } catch (error: any) {
      hidePopover();
      return;
    }

    await waitAsync(100);

    if (selection?.isCollapsed || selection?.rangeCount === 0) {
      hidePopover();
      return;
    }

    const selectionRangeStart = selection?.getRangeAt(0);
    const selectionRangeEnd = selection?.getRangeAt(selection.rangeCount - 1);
    const startNode = selectionRangeStart?.startContainer.parentNode;
    const endNode = selectionRangeEnd?.endContainer.parentNode;
    if (!startNode?.firstChild || !endNode?.firstChild) {
      hidePopover();
      return;
    }
    let newRange: any = new Range();
    newRange.setStart(selection?.focusNode?.parentNode?.firstChild, 0);
    newRange.setEnd(
      selection?.focusNode?.parentNode?.lastChild,
      selection?.focusOffset,
    );

    const { y, right } = newRange.getBoundingClientRect();
    // if (!width) {
    //   hidePopover();
    //   return;
    // }

    setPopper({
      x: right,
      y: y + window.scrollY - 10,
      showPopover: true,
    });
  };
  return disabled ? (
    <>{children}</>
  ) : (
    <>
      <div ref={highlight}>
        {popper?.showPopover && (
          <div
            className="h-popover"
            style={{ left: `${popper.x}px`, top: `${popper.y}px` }}
            role="presentation"
            onMouseDown={(e) => e.preventDefault()}
          >
            <span
              role="button"
              className="h-popover-item"
              onClick={() => {
                if (!getSlateSelection()) return;

                const { start, end, text } = getSlateSelection()!;
                setStartTime(start || 0);
                setEndTime(end || 0);
                setSnippetText(text ?? '');
                setIsOpenPublishModal(true);
              }}
            >
              <Tooltip
                className="tooltip-create-clips"
                content={<span tw="margin-top[1px]">Create clip</span>}
                align="right"
                activateOn="hover"
              >
                <Upload />
              </Tooltip>
            </span>
          </div>
        )}
        {children}
      </div>

      <Modal
        classes="publish-modal-width"
        show={isOpenPublishModal}
        modalClosed={handleClosePublishModal}
      >
        <Publish
          isPopper={true}
          startTime={startTime}
          endTime={endTime}
          snippetText={snippetText}
          isOpen={isOpenPublishModal}
          mediaid={mediaid}
          caption={'OPEN'}
          filename={filename + ' - Snippet'}
          closeShareModal={handleClosePublishModal}
        />
      </Modal>
    </>
  );
};
