/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Hint } from 'components/shared/Hint';
import { memo, ReactElement } from 'react';

interface Props {
  children: ReactElement;
  disabled?: boolean;
}

export const InstagramHelper = memo((props: Props) => {
  return (
    <Hint
      disabled={props?.disabled}
      arrow
      notTransparent
      enterDelay={200}
      leaveDelay={200}
      text={
        <div>
          To be able to send this clip to your Instagram Business account:
          <ul tw="mb-0 mt-1">
            <li>
              The size of the uploaded clip must be less than or equal to 100
              MB.
            </li>
            <li>
              Shared clip must be at least 3 seconds and shorter than 60
              seconds.
            </li>
            <li>
              Supported video file formats are MOV or MP4 (MPEG-4 Part 14).
            </li>
            <li>Frame rate: 23-60 FPS.</li>
            <li>
              Picture size:
              <ul>
                <li>Maximum columns (horizontal pixels): 1920</li>
                <li>Minimum aspect ratio [cols / rows]: 4 / 5</li>
                <li>Maximum aspect ratio [cols / rows]: 16 / 9</li>
              </ul>
            </li>
          </ul>
        </div>
      }
    >
      {props.children}
    </Hint>
  );
});
