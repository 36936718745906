/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { useSearchParam } from 'react-use';
import { Social } from 'utils/enum';

export const SharePostWithLinkedIn = () => {
  const accessToken = useSearchParam('code');
  const mediaKey = useSearchParam('state');
  const provider = Social.LINKEDIN;

  useEffect(() => {
    window.document.cookie = `code=${accessToken};domain=.${window.location.host}; path=/`;
    window.document.cookie = `mediaKey=${mediaKey};domain=.${window.location.host}; path=/`;
    window.document.cookie = `state=true;domain=.${window.location.host}; path=/`;
    window.document.cookie = `provider=${provider};domain=.${window.location.host}; path=/`;
    window.close();
  });

  return (
    <div className="App">
      <header className="App-header"></header>
    </div>
  );
};
