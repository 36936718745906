/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { Ripple } from '@rmwc/ripple';
import { ReactComponent as AdBreakSvg } from 'assets/Icons/bolt.svg';
import { Hint } from 'components/shared/Hint';
import { isPublicShared, secToTime } from '../../MediaUtilities';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { AdBreakItem } from 'utils/models';
import { debounce } from 'lodash';
import { MouseEvent } from 'react';

type Props = {
  item: AdBreakItem;
  handleClickAdBreak: (item: AdBreakItem) => void;
};

export const AdBreak = ({ item, handleClickAdBreak }: Props) => {
  const toggle = useSelector((state: RootState) => state.toggle);
  const adBreaks = useSelector((state: RootState) => state.adBreaks);

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.detail === 1) {
      handleClickAdBreak(item);
    }
  };

  return (
    <Ripple>
      <div
        tw="py-1.5 font-size[1.7rem] cursor-pointer px-2 rounded display[inline-flex] items-center"
        css={adBreaks?.currentActiveId === item?.id && tw`bg-gray-200`}
        onClick={debounce(handleClick, 200)}
      >
        <span tw="flex items-center">
          <AdBreakSvg css={tw`fill[#FEB446]`} />
        </span>

        <Hint
          disabled={isPublicShared() || !toggle.isEditMode}
          text="Double click to edit"
          enterDelay={200}
          leaveDelay={0}
        >
          <span
            tw="px-1"
            css={[
              toggle.isEditMode &&
                tw`border[1px dashed] border-disabled rounded select-none`,
            ]}
          >
            {`${secToTime(item.startTime)} - ${secToTime(item.endTime)}`}
          </span>
        </Hint>
      </div>
    </Ripple>
  );
};
