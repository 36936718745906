import { spawn } from 'redux-saga/effects';

import WatchInitLibrary from './librarySaga';
import WatchLoginSaga from './loginSaga';
import WatchUpdatePasswordSaga from './updatePasswordSaga';
import WatchInitPublishLibrary from './publishLibrarySaga';
import WatchInitPublishedVersionLibrary from './getPublishedVersionSaga';
import WatchInitPublicExportContentItem from './publicExportSaga';

import WatchProfile from './profileSaga';

export default function* RootSaga() {
  yield spawn(WatchInitLibrary);
  yield spawn(WatchLoginSaga);
  yield spawn(WatchUpdatePasswordSaga);
  yield spawn(WatchInitPublishLibrary);
  yield spawn(WatchInitPublishedVersionLibrary);
  yield spawn(WatchInitPublicExportContentItem);
  yield spawn(WatchProfile);
}
