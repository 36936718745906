/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { DateRangeInput } from '@datepicker-react/styled';
import { ReactComponent as PreviewSvg } from 'assets/Icons/find_white.svg';
import Loader from 'components/loader/loader';
import { TopicLayerDropDown } from 'components/Publish/TopicLayerDropDown';
import { ButtonGroup } from 'components/shared/ButtonGroup';
import {
  customDateRangInputStyle,
  resetReactRangeCss,
} from 'components/shared/twin.styles';
import {
  convertPieDataList,
  convertTermList,
  processAliasDisplayTerms,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { useAdvancedAutofill } from 'hooks/useAdvancedAutofill';
import {
  debounce,
  defaultTo,
  isEmpty,
  isEqual,
  isNil,
  pick,
  uniqBy,
} from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLatest, useToggle } from 'react-use';
import { RootState } from 'reducers';
import {
  ExportAliasTerm,
  InsightService,
  PostGenerateWordCloudParams,
} from 'services/insight.service';
import { setInsightMetadataLayer } from 'slices/insight.slice';
import { endOfDayISO, startOfDayISO } from 'utils/date.util';
import {
  AdvertisingChartType,
  GeneratedChartRelated,
  Layers,
} from 'utils/enum';
import {
  AliasTerm,
  CustomDateParams,
  FilterMenuOptions,
  Insights,
  SeriesKeyValue,
} from 'utils/models';
import {
  getTenantidFromIdToken,
  getTopicLayerName,
  typeMediaEntities,
} from 'utils/utils';

import { ReactComponent as DonutChartSvg } from 'assets/Icons/donut_chart.svg';
import { ReactComponent as WordChartSvg } from 'assets/Icons/word_chart.svg';
import { InsightEditTermsModal } from 'components/shared/ContentExplorationChartsModal/InsightEditTermsModal';
import { Option } from 'components/shared/CustomSelectSearch';
import { DropdownSelectionWrapper } from 'components/shared/HintDropdownWrapper/HintDropdownWrapper';
import {
  iabDefaultOption,
  InsightIabDropdown,
} from 'components/shared/InsightIabDropdown/InsightIabDropdown';
import { SimpleRadio } from 'components/shared/SimpleRadio';
import { useRef } from 'react';
import { MAX_TERM_EXPLORATION_CHART } from 'utils/constants';
import { ensureArray, singleOrNull } from 'utils/generic.util';
import { TermEditModalRef } from 'utils/models/modal.model';
import { customToast } from 'utils/toast.util';
import {
  AdvancedFilterMenu,
  defaultAdvancedFilters,
} from '../shared/AdvancedFilterMenu';
import { ExplorationChartViewer } from './ExplorationChartViewer';
import { useContentExplorationQuery } from 'hooks/queries/useContentExplorationQuery';
import { useAdvertisingIabQuery } from 'hooks/queries/useAdvertisingIabQuery';
import { appendNewGeneratedChart } from 'slices/term-exploration.slice';
import { useQueryGeneratedTermInsight } from 'hooks/queries/useQueryGeneratedTermInsight';
import { defaultTermExploration } from 'utils/default/defaultSetting';
import { UserService } from 'services';
import { setAliasTerms } from 'slices/alias-term.slice';

export const ContentExplorationChart = () => {
  const dispatch = useDispatch();

  const { refetch: refetchGeneratedTermInsight } =
    useQueryGeneratedTermInsight();

  const termExploration = useSelector(
    (state: RootState) => state.termExploration,
  );
  const generatedCharts = termExploration.generatedCharts;

  const { data: iabInsight } = useAdvertisingIabQuery({});

  useContentExplorationQuery({
    layer: Layers.KEY_TERM,
    options: {
      onSuccess: ({ keywords }) => {
        setTermOptions(
          keywords.buckets.map(({ key }) => ({ label: key, value: key })),
        );
      },
    },
  });

  const insight = useSelector((state: RootState) => state.insight);
  const latestInsight = useLatest(insight);

  const aliasTerm = useSelector((state: RootState) => state.aliasTerm);
  const latestAliasTerm = useLatest(aliasTerm);

  const insightEditTermsModalRef = useRef<TermEditModalRef>(null);

  const [seriesKeyList, setSeriesKeyList] = useState<SeriesKeyValue>();
  const [termOptions, setTermOptions] = useState<Option[]>([]);
  const [isFetching, toggleFetching] = useToggle(true);
  const [dateRangeFilter, setDateRangeFilter] = useState<CustomDateParams>({
    startDate: null,
    endDate: null,
    focusedInput: null,
  });

  const [chartType, setChartType] = useState<AdvertisingChartType>('donut');
  const [chartRelatedLayer, setChartRelatedLayer] =
    useState<GeneratedChartRelated>(GeneratedChartRelated.TERM);

  const [termSearch, setTermSearch] = useState<string>('');
  const [iabOptionItem, setIabOptionItem] = useState<Option>(iabDefaultOption);

  const [advancedFilters, setAdvancedFilters] = useState<FilterMenuOptions>(
    defaultAdvancedFilters,
  );
  const [termList, setTermList] = useState<ExportAliasTerm[]>([]);
  const [tempAliasTerms, setTempAliasTerms] = useState<AliasTerm[]>(
    aliasTerm.aliasTerms,
  );
  const inputTerm = isEqual(chartRelatedLayer, GeneratedChartRelated.TERM)
    ? termSearch
    : iabOptionItem?.value;

  const isValidGenerationTerms = !isEmpty(inputTerm?.trim());
  const isDonutChart = chartType === 'donut';

  const isIabLayers = [Layers.IAB, Layers.GARM_REPORT].includes(
    insight.selectedMetadataLayer,
  );

  const autofillAdvancedSearchHook = useAdvancedAutofill();

  const isSelectLayerTerm = chartRelatedLayer === GeneratedChartRelated.TERM;
  const isSelectLayerIab = chartRelatedLayer === GeneratedChartRelated.IAB;

  useEffect(() => {
    handleGenerateAsync();
  }, [insight.selectedMetadataLayer]);

  const getInsightsDataAsync = async () => {
    try {
      toggleFetching(true);

      const { data } = await InsightService.getInsightsAsync({
        fromDate: startOfDayISO(dateRangeFilter.startDate),
        toDate: endOfDayISO(dateRangeFilter.endDate),
        layer: typeMediaEntities(latestInsight.current.selectedMetadataLayer),

        collectionIds: advancedFilters.collectionIds ?? undefined,
        tagIds: advancedFilters.tagIds ?? undefined,
        integrationIds: advancedFilters.orgIds ?? undefined,
      });

      setSeriesKeyList(convertPieDataList(data));

      const apiTermList = convertTermList(data);
      setTermList(apiTermList);

      const processedTerms = processAliasDisplayTerms(apiTermList);

      const disabledTerms: AliasTerm[] = processedTerms
        .filter(({ isEnabled, alias }) => !(isEnabled && isNil(alias)))
        .map((term) => pick(term, ['original', 'alias', 'isEnabled']));

      // Has to use `latestAliasTerm` because of late state by closure
      const combinedTempAliasTerms = uniqBy(
        disabledTerms.concat(latestAliasTerm.current.aliasTerms),
        'original',
      );

      setTempAliasTerms(combinedTempAliasTerms);
    } catch (error: any) {
      console.log('error :>> ', error);
    } finally {
      toggleFetching(false);
    }
  };

  const handleClickPie = (searchTerm: string, searchField: keyof Insights) => {
    autofillAdvancedSearchHook({
      searchTerms: [searchTerm],
      collectionId: singleOrNull<string>(advancedFilters.collectionIds),
      startDate: dateRangeFilter.startDate,
      endDate: dateRangeFilter.endDate,
      searchBy: searchField,
    });
  };

  const handleGenerateAsync = async (params?: {
    shouldGenWordCloud?: boolean;
  }) => {
    const shouldGenInsight = true;
    const shouldGenWordCloud = Boolean(params?.shouldGenWordCloud);

    setSeriesKeyList({});
    const tasks = [];

    if (shouldGenInsight) {
      const taskLibraryInsight = getInsightsDataAsync();
      tasks.push(taskLibraryInsight);
    }

    if (shouldGenWordCloud) {
      const taskTermInsight = generateTermExplorationAsync();
      tasks.push(taskTermInsight);
    }

    await Promise.all(tasks);
  };

  const handleChangeMetadataLayer = async (layer: Layers) => {
    dispatch(setInsightMetadataLayer(layer));
  };

  const handleClickEdit = async () => {
    if (!insightEditTermsModalRef.current) return;

    const result = await insightEditTermsModalRef.current.show({});

    //  Process result
    if (result.shouldRefresh) {
      handleGenerateAsync();

      refetchGeneratedTermInsight();
    }
  };

  const handleRadioClick =
    (chartRelatedOption: GeneratedChartRelated) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setChartRelatedLayer(chartRelatedOption);
    };

  const handleChangeIabCategory = (iabItem: Option) => {
    setIabOptionItem(iabItem);
  };

  const postGenerateWordCloudAsync = async (
    params: PostGenerateWordCloudParams,
  ) => {
    const generateAsync = InsightService.postGenerateWordCloud(params);

    customToast.promise(generateAsync, {
      loading: (
        <div>
          Requesting <b>{params.keyTerm}</b> term exploration...
        </div>
      ),
      success: (
        <div>
          Pinned term exploration chart requested.
          <br />
          Data is being processed.
        </div>
      ),
      error: <div>Failed to request term exploration</div>,
      useServerErrorMsg: true,
    });

    try {
      await generateAsync;

      resetInputSelection();
    } catch (error) {
      console.log('error :>> ', error);

      refetchGeneratedTermInsight();
    }
  };

  const generateTermExplorationAsync = async () => {
    if (!isValidGenerationTerms) {
      return;
    }

    if (ensureArray(generatedCharts).length >= MAX_TERM_EXPLORATION_CHART) {
      customToast.warning(
        <div>
          Maximum limit of <strong>{MAX_TERM_EXPLORATION_CHART}</strong> term exploration charts reached.
          <br />
          Please unpin chart(s) and try again.
        </div>,
      );
      return;
    }

    const relatedLayer = typeMediaEntities(insight.selectedMetadataLayer);
    const placeholderTermChart = defaultTermExploration(
      inputTerm,
      relatedLayer,
    );

    dispatch(appendNewGeneratedChart(placeholderTermChart));

    await postGenerateWordCloudAsync({
      collectionIds: advancedFilters.collectionIds ?? undefined,
      fromDate: startOfDayISO(dateRangeFilter.startDate),
      toDate: endOfDayISO(dateRangeFilter.endDate),
      layer: chartRelatedLayer,
      relatedLayer: relatedLayer,
      keyTerm: defaultTo(inputTerm?.trim(), ''),
    });
  };

  const resetInputSelection = () => {
    // setTermSearch(StringEnum.EMPTY);
    setIabOptionItem(iabDefaultOption);
  };

  const isChecked = (radioValue: GeneratedChartRelated): boolean => {
    return chartRelatedLayer === radioValue;
  };

  const handleSaveEditedTerms = async (aliasEditedTerms: AliasTerm[]) => {
    const uniqAliasTerms: AliasTerm[] = uniqBy(
      [...aliasEditedTerms, ...tempAliasTerms],
      'original',
    ).filter(({ isEnabled, alias }) => !(isEnabled && isNil(alias)));

    const tenantid = getTenantidFromIdToken();

    const saveAsync = UserService.savePreference({
      tenantid,
      setting_alias_terms: uniqAliasTerms
        ? JSON.stringify(uniqAliasTerms)
        : undefined,
    });

    customToast.promise(saveAsync, {
      loading: 'Saving changes...',
      success: 'Saved successfully',
      error: 'Save failed',
    });

    try {
      await saveAsync;

      dispatch(setAliasTerms(uniqAliasTerms));
    } catch (error: any) {
      console.log('error :>> ', error);
    }
  };

  const handleChangeLayer = async (selectedLayer: Layers) => {
    dispatch(setInsightMetadataLayer(selectedLayer));
  };

  return (
    <div tw="flex flex-col h-full">
      <div tw="text-20 font-semibold mb-3">Content Exploration</div>

      <div tw="flex-1 relative w-full rounded border[1px solid] border-sonnant-grey-light shadow px-5 pt-3 pb-5">
        {isFetching && (
          <div tw="absolute w-full h-full flex justify-center items-center bg-white z-[20] opacity[0.95] -ml-5 -mt-3">
            <Loader />
          </div>
        )}

        <div tw="flex flex-col h-full">
          <div tw="flex items-center gap-5">
            <div tw="width[24.5rem] relative z-[3]">
              <label>
                <span tw="text-15">Metadata Layer</span>
              </label>

              <TopicLayerDropDown
                defaultValue={getTopicLayerName(insight.selectedMetadataLayer)}
                onChange={handleChangeMetadataLayer}
                hasIABLayer={isDonutChart}
                hasSensitive={isDonutChart}
                hasTranscripts={true}
              />
            </div>

            <div>
              <label>
                <span tw="text-15">Chart Type</span>
              </label>

              <ButtonGroup
                items={[
                  { icon: <DonutChartSvg />, label: 'Donut', value: 'donut' },
                  {
                    icon: <WordChartSvg />,
                    label: 'Word Cloud',
                    value: 'wordcloud',
                    disabled: isIabLayers,
                  },
                ]}
                defaultValue="donut"
                onChange={(item) =>
                  setChartType(item.value as AdvertisingChartType)
                }
              />
            </div>

            {/* FILTER BUTTON */}
            <div tw="flex items-end h-full">
              <AdvancedFilterMenu
                selectedFilters={advancedFilters}
                onMenuClose={setAdvancedFilters}
              />
            </div>
          </div>

          <div tw="flex flex-wrap gap-5">
            <div tw="z-[2]" css={[customDateRangInputStyle]}>
              <label>
                <span tw="text-15">Date Range</span>
              </label>
              <div tw="flex items-center space-x-10">
                <span css={[resetReactRangeCss]}>
                  <DateRangeInput
                    onDatesChange={debounce((data) => {
                      setDateRangeFilter(data);
                    }, 0)}
                    onFocusChange={(focusedInput) => {
                      setDateRangeFilter({
                        ...dateRangeFilter,
                        focusedInput: focusedInput as any,
                      });
                    }}
                    startDate={dateRangeFilter.startDate} // Date or null
                    endDate={dateRangeFilter.endDate} // Date or null
                    focusedInput={dateRangeFilter.focusedInput} // START_DATE, END_DATE or null
                    maxBookingDate={new Date()}
                    displayFormat="dd/MM/yyyy"
                    startDateInputId="metadataChartStartDateInputId"
                    endDateInputId="metadataChartEndDateInputId"
                  />
                </span>
              </div>
            </div>
          </div>

          <div tw="flex flex-row w-full flex-wrap gap-4 items-center mt-5">
            <label>
              <span tw="text-15">Related to</span>
            </label>
            <div tw="flex items-center gap-x-4 mr-4">
              <SimpleRadio
                label="Term"
                checked={isChecked(GeneratedChartRelated.TERM)}
                onChange={handleRadioClick(GeneratedChartRelated.TERM)}
              />
              <div css={[isSelectLayerIab && layerDisabled]}>
                <DropdownSelectionWrapper
                  hidden={true}
                  options={termOptions}
                  searchValue={termSearch}
                  onSelect={(option) => setTermSearch(option.label)}
                >
                  <input
                    tw="(w-auto border-width[2px] mb-0 placeholder:text-14)!"
                    type="text"
                    placeholder="Enter term"
                    value={termSearch}
                    onChange={(e) => setTermSearch(e.target.value)}
                    onKeyDown={() => {}}
                    disabled={isSelectLayerIab}
                  />
                </DropdownSelectionWrapper>
              </div>
            </div>

            <div tw="flex items-center gap-x-4">
              <SimpleRadio
                label="IAB"
                checked={isChecked(GeneratedChartRelated.IAB)}
                onChange={handleRadioClick(GeneratedChartRelated.IAB)}
              />

              <div
                tw="relative z-[1] min-w-[15rem] text-14"
                css={[isSelectLayerTerm && layerDisabled]}
              >
                <InsightIabDropdown
                  selectedOption={iabOptionItem}
                  iabSortedBuckets={iabInsight?.iab.buckets ?? []}
                  onClickTagNumber={() => {}}
                  onChangeOption={handleChangeIabCategory}
                  isLoading={isFetching}
                  overriddenCss={[customIabDropdownCss]}
                  shouldShowTagNumber={false}
                  isDisabled={isSelectLayerTerm}
                />
              </div>
            </div>

            <button
              className="button large btn-primary"
              onClick={() => handleGenerateAsync({ shouldGenWordCloud: true })}
              tw="m-0 flex justify-center items-center self-end height[4rem]! shadow ml-0"
              disabled={isFetching}
            >
              <span tw="flex">
                <PreviewSvg tw="mr-3" />
              </span>
              Generate
            </button>
          </div>

          <ExplorationChartViewer
            seriesKeyList={seriesKeyList}
            chartType={chartType}
            currentLayer={insight.selectedMetadataLayer}
            onClickPie={handleClickPie}
            onClickEdit={handleClickEdit}
            editable
          />
        </div>
      </div>

      <InsightEditTermsModal
        ref={insightEditTermsModalRef}
        isLoading={isFetching}
        currentLayer={insight.selectedMetadataLayer}
        onLayerChange={handleChangeLayer}
        termList={termList}
        onSaveEditedTerms={handleSaveEditedTerms}
      />
    </div>
  );
};

const customIabDropdownCss = css`
  .iab-dropdown__control {
    ${tw`w-[22rem]`}
  }
`;

const layerDisabled = css`
  ${tw`cursor-not-allowed opacity-75`}
`;
