/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as CloseIcon } from 'assets/Icons/close.svg';
import { useState } from 'react';
import { useToggle } from 'react-use';
import { Keys } from 'utils/enum';
import { TextItem } from './MonetisationTextSearch/MonetisationTextSearch';
import { isEmpty } from 'lodash';
import { simpleMenuDisabled } from './twin.styles';

type Props = {
  textItem: TextItem;
  onChange: (item: TextItem) => void;
  onRemove: (item: TextItem) => void;

  defaultEditing?: boolean;
  canEdit?: boolean;
  canRemove?: boolean;
};

export const TextBox = ({
  textItem,
  defaultEditing = false,
  canEdit = true,
  canRemove = true,
  ...props
}: Props) => {
  const [isEditing, toggleEditing] = useToggle(defaultEditing);
  const [newText, setNewText] = useState<string>(textItem.text);

  const cancelEditing = () => {
    toggleEditing(false);
  };

  const handleBlurInput = () => {
    if (isEmpty(textItem.text)) {
      props.onRemove(textItem);
    }

    toggleEditing(false);
  };

  const handleInputKeyword = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const newTextTrim = newText?.replace(/\s+/g, ' ').trim();

    if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
      if (newTextTrim !== textItem.text) {
        const updatedTextItem = {
          ...textItem,
          text: newText,
        };

        props.onChange(updatedTextItem);
        cancelEditing();
      }
    }

    if (e.key === Keys.ESC) {
      cancelEditing();
    }
  };

  const handleRemoveTextConfigItem = () => {
    props.onRemove(textItem);
  };

  return isEditing ? (
    // INPUT MODE
    <div className="button keywordBtn keywordEdit" tw="p-0">
      <input
        type="text"
        value={newText}
        onChange={(e) => setNewText(e.target.value)}
        onKeyDown={handleInputKeyword}
        onBlur={handleBlurInput}
        className="small-10 keywordInput"
        tw="text-13 rounded-r-none!"
        maxLength={64}
        autoFocus
      />
      <button
        onClick={cancelEditing}
        className="small-2 cancelBtn"
        tw="rounded-l-none!"
      >
        <CloseIcon className="whiteIcon" />
      </button>
    </div>
  ) : (
    // VIEW MODE
    <div tw="flex mr-0.5" css={[!canRemove && simpleMenuDisabled]}>
      <button
        type="button"
        className={`button keywordBtn btn-secondary`}
        onClick={() => {}}
        onDoubleClick={() => {
          if (!canEdit) return;

          setNewText(textItem.text);
          toggleEditing(true);
        }}
        tw="md-down:font-size[1.4rem] flex items-center"
      >
        <span className="keywordval" title={textItem.text}>
          {textItem.text}
        </span>

        {canRemove && (
          <div className="closeButton" onClick={handleRemoveTextConfigItem}>
            <CloseIcon />
          </div>
        )}
      </button>
    </div>
  );
};
