/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import React, { ChangeEvent } from 'react';
import { Hint } from './Hint';
import { isNaN, isNil } from 'lodash';

type Props = {
  value: string | number;
  disabled?: boolean;
  onChangeNumber: (value: number) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  min: number;
  step: number;
  max?: number;
} & {
  [prop: string]: any;
};

export const NumberInput = ({
  value,
  min,
  max,
  step,
  disabled = false,
  ...props
}: Props) => {
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const ignoredKeys = ['.', ',', 'E', 'e'];

    if (ignoredKeys.includes(e?.key)) {
      e.preventDefault();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(e?.target?.value);

    if (typeof max === 'number' && value > max) {
      value = max;
    }
    if (value < min) {
      value = min;
    }

    props.onChangeNumber(value);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (typeof props.onBlur === 'function') {
      props.onBlur(e);
    }

    let value = parseInt(e?.target?.value);

    if (isNil(value) || isNaN(value)) {
      props.onChangeNumber(min);
    }
  };

  return (
    <Hint text={`Number must be between ${min} and ${max}`}>
      <input
        css={[disabled && tw`opacity-75`]}
        type="number"
        min={min}
        step={1}
        max={max}
        value={value}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        onBlur={handleBlur}
        className="keywordInput"
        required
        disabled={disabled}
        {...props}
      />
    </Hint>
  );
};
