/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as LoaderSvg } from 'assets/Icons/Loader.svg';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';

interface Props {
  handleSaveTranscript: (autosave: any, isRegenerateSummary: any) => void;
}

export const SummaryLayer = (props: Props) => {
  const [isLoadingSummary] = useState(false);
  const toggle = useSelector((state: RootState) => state.toggle);

  const regenerateSummary = () => {
    props.handleSaveTranscript(false, true);
  };

  return (
    <div tw="flex justify-center pt-10">
      <button
        className={`btn-primary large w-unset ${
          (isLoadingSummary || !toggle.isEditMode) && 'disabled'
        }`}
        onClick={regenerateSummary}
        tw="(width[unset] min-width[unset] px-4)!"
      >
        {isLoadingSummary ? (
          <span tw="flex justify-around items-center -mt-1">
            <LoaderSvg tw="mr-1! -ml-2" /> Generating
          </span>
        ) : (
          'Regenerate summary'
        )}
      </button>
    </div>
  );
};
