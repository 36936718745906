/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { css } from '@emotion/react';

import { ApexOptions } from 'apexcharts';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Info } from '../Info';
import { ColorBucket } from 'utils/adapter.util';
import { getTodayShort } from 'utils/date.util';

type Props = {
  buckets: ColorBucket[];
  handleClickPie: (value: string) => void;
  title?: string;
  clickable?: boolean;
};

export const ColumnChart = React.memo(
  ({ title, buckets, clickable = false, handleClickPie }: Props) => {
    const bucketSeries = useMemo<ApexAxisChartSeries>(
      () => [
        { name: 'count', data: buckets.map((bucket) => bucket.doc_count) },
      ],
      [buckets],
    );

    const getChartOptions = (): ApexOptions => ({
      title: { text: title },
      chart: {
        width: '100%',
        height: '100%',
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 1500,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        toolbar: {
          export: {
            csv: {
              filename: `ColumnChart_CSV_${getTodayShort()}`,
            },
            svg: {
              filename: `ColumnChart_SVG_${getTodayShort()}`,
            },
            png: {
              filename: `ColumnChart_PNG_${getTodayShort()}`,
            },
          },
        },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const value = config.w.config.labels[config.dataPointIndex];

            handleClickPie(value);
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        },
      },
      labels: buckets.map(({ key }) => key),
      colors: buckets.map(({ color }) => color),
      dataLabels: {
        enabled: true,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45,
        },
        style: {
          fontSize: '11px',
          fontFamily:
            "'Lexend', 'Roboto', 'Arial', 'Helvetica', 'Verdana', sans-serif",
        },
      },

      // tooltip: {
      //   y: {
      //     formatter: function (value, opts) {
      //       const total = sum(opts.config.series);

      //       const percent = round((value / total) * 100, 2);

      //       return `${value} (${percent}%)`;
      //     },
      //   },
      // },
      yaxis: {
        labels: {
          formatter(val: any) {
            return (parseInt(val) || 0).toString();
          },
        },
      },
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    });

    if (isEmpty(buckets)) {
      return <Info text="Nothing to show" />;
    }

    return (
      <ReactApexChart
        options={getChartOptions()}
        series={bucketSeries}
        type="bar"
        height={'100%'}
        css={[!clickable && customCss]}
      />
    );
  },
);

const customCss = css`
  cursor: default;
`;
