import { defaultTo } from 'lodash';
import { v4 } from 'uuid';

import { CollectionState } from 'services/collection.service';
import { ensureCollectionMoneFormat } from 'utils/adapter.util';
import {
  CollectionOptionEnum,
  GeneratedChartRelated,
  GeneratedChartStatus,
  IntegrationEnum,
  StringEnum,
} from 'utils/enum';
import {
  CollectionMonetisation,
  CustomDateParams,
  Demographics,
  DemographicsCategory,
  EmailSetting,
  GeneratedChartRaw,
  Insights,
  IntegrationOptions,
  MegaphoneIntegrationOption,
  Monetisation,
  PreferenceOption,
  SpeakerDiarisation,
  SpeakerState,
} from 'utils/models';

type DefaultSettings = {
  preference: PreferenceOption;
  emailSettings: EmailSetting;
  collectionSetting: CollectionState;
  monetisation: Monetisation;
  integrationSettings: IntegrationOptions[];
  megaphoneIntegrationSettings: MegaphoneIntegrationOption[];
  speakers: SpeakerState;
};

export const defaultMegaphoneSetting: MegaphoneIntegrationOption = {
  id: v4(),

  isEnabled: false,

  apiKey: '',

  collectionOption: CollectionOptionEnum.MEGAPHONE,
  collectionId: null,

  isAutomatedPushBackMegaphone: false,

  provider: IntegrationEnum.MEGAPHONE,

  filter: {
    programFilters: [
      {
        networkId: '',
        programId: '',
      },
    ],
    isUseProgramName: false,
    isAutomatePushBack: false,
    integrationSettings: {
      hasMonetisation: true,
    },
  },
};

export const defaultOmnySetting: IntegrationOptions = {
  id: v4(),

  isEnabled: false,

  apiKey: '',
  webhookSecret: '',
  integrationName: '',

  collectionOption: CollectionOptionEnum.OMNY,
  collectionId: null,

  isAutomatedPushBackOmny: false,

  provider: IntegrationEnum.OMNY,

  filter: {
    programFilters: [
      {
        programId: '',
        programName: '',
      },
    ],
    titleContains: [],
    isUseProgramName: false,
    isAutomatePushBack: false,
    integrationSettings: {
      hasChapters: true,
      hasMonetisation: true,
      hasSummary: true,
      hasTags: true,
      isAutomatePushBack: true,
    },
  },
};

export const defaultSetting: DefaultSettings = {
  preference: {
    clips: 10,
    suggestedTotalShorts: 0,
    suggestedTotalClips: 10,
    suggestedTotalSegments: 0,
    autoSaveMinutes: 10,
    summary: true,
    suggest: true,
    caption: false,
    captionfile: true,
    transcripts: true,
    keyterm: true,
    products: true,
    nationalities: true,
    datasummary: true,
    orgs: true,
    people: true,
    locations: true,
    globalList: false,
    chapters: true,
    includeUrlCsv: true,
    paraheader: true,
    logo: false,
    isSubscriber: false,
    iab: true,
    visualplaybar: true,
    autoSave: true,
    hasAdMarkersLayer: false,
    speakers: true,
    paragraphMode: false,
  },
  emailSettings: {
    hasProcessingNotification: true,
    hasRevivalNotification: true,
    hasWatchListNotification: true,
  },
  collectionSetting: {
    defaultCollection: {
      collectionId: null,
      collectionName: null,
    },
    visibleCollections: [],
  },
  monetisation: {
    monetisationEnabled: true,
    preRoll: true,
    midRolls: true,
    postRoll: true,
    midRollsConfigs: {
      isEverySelected: true,
      isMaximumSelected: false,
      everyMinutes: 10,
      maximumMinutes: 10,
      gapMinutes: 10,
    },
    isCheckAdsNumber: false,
    adsNumber: 2,
  },
  integrationSettings: [defaultOmnySetting],
  megaphoneIntegrationSettings: [defaultMegaphoneSetting],
  speakers: {
    hasSpeakerDiarisation: true,
    hasSpeakerIdentification: false,

    recommendedSensitivity: 85, // percent

    speakers: [] as SpeakerDiarisation[],
  },
};

export const defaultMonetisationCollection: CollectionMonetisation =
  ensureCollectionMoneFormat({});

export const defaultProfile = {
  account: {
    description: '',
    image: 'NULL',
  },
  user: {
    location: 'Australia/Sydney',
    image: 'NULL',
  },
};

export const defaultTermExploration = (
  term: string | undefined,
  relatedLayer: keyof Insights,
): GeneratedChartRaw => ({
  id: v4(),
  key_term: defaultTo(term?.trim(), ''),
  layer: GeneratedChartRelated.TERM,
  related_layer: relatedLayer,
  status: GeneratedChartStatus.GENERATING,
  insight_json_path: StringEnum.EMPTY,
  buckets: [],
  isPlaceholder: true,
});

export const defaultDatesRange: CustomDateParams = {
  startDate: null,
  endDate: null,
  focusedInput: null,
};

export const defaultDemographics: Demographics = {
  homeType: ['house', 'apartment'],
  buildingAge: ['newer', 'medium', 'old'],
  education: ['basic', 'medium', 'higher'],
  home: ['own', 'rent'],
  wealth: ['80 100', '60 80', '40 60', '20 40', '0 20'],
  lifeStage: [
    'young singles and couples',
    'young couples w children',
    'families w school children',
    'older families mature couples',
    'pensioners',
  ],
  homeSize: ['small', 'medium', 'large'],
  area: ['countryside', 'village', 'suburban', 'city'],
  savings: ['large', 'medium', 'small'],
  techProficiency: ['high', 'medium', 'low'],
  employerSize: ['large', 'medium', 'small'],
  device: [
    'device phone',
    'unknown',
    'tablet',
    'speaker',
    'watch',
    'tv',
    'mp 3 player',
    'radio',
    'games console',
    'pc',
  ],
  source: [
    'apple podcasts',
    'google podcasts',
    'spotify',
    'google chrome',
    'pocket casts',
    'overcast',
    'amazon music podcasts',
    'unknown',
    'podcastaddict',
    'apple core media',
  ],
  country: ['au', 'ca', 'de', 'in', 'nl', 'nz', 'za', 'se', 'gb', 'us'],
  gender: ['male', 'female'],
  iab: [],
};

export const defaultCategories: DemographicsCategory[] = [
  {
    category: 'Home Type',
    fieldName: 'homeType',
    options: [
      {
        label: 'House',
        value: 'house',
      },
      {
        label: 'Apartment',
        value: 'apartment',
      },
    ],
    column: 2,
  },
  {
    category: 'Building Age',
    fieldName: 'buildingAge',
    options: [
      {
        label: 'Newer',
        value: 'newer',
      },
      {
        label: 'Medium',
        value: 'medium',
      },
      {
        label: 'Old',
        value: 'old',
      },
    ],
    column: 3,
  },
  {
    category: 'Education',
    fieldName: 'education',
    options: [
      {
        label: 'Basic',
        value: 'basic',
      },
      {
        label: 'Medium',
        value: 'medium',
      },
      {
        label: 'Higher',
        value: 'higher',
      },
    ],
    column: 1,
  },
  {
    category: 'Home',
    fieldName: 'home',
    options: [
      {
        label: 'Own',
        value: 'own',
      },
      {
        label: 'Rent',
        value: 'rent',
      },
    ],
    column: 2,
  },
  {
    category: 'Wealth',
    fieldName: 'wealth',
    options: [
      {
        label: '80 100',
        value: '80 100',
      },
      {
        label: '60 80',
        value: '60 80',
      },
      {
        label: '40 60',
        value: '40 60',
      },
      {
        label: '20 40',
        value: '20 40',
      },
      {
        label: '0 20',
        value: '0 20',
      },
    ],
    column: 3,
  },
  {
    category: 'Life Stage',
    fieldName: 'lifeStage',
    options: [
      {
        label: 'Young singles and couples',
        value: 'young singles and couples',
      },
      {
        label: 'Young couples with children',
        value: 'young couples w children',
      },
      {
        label: 'Families with school children',
        value: 'families w school children',
      },
      {
        label: 'Older families mature couples',
        value: 'older families mature couples',
      },
      {
        label: 'Pensioners',
        value: 'pensioners',
      },
    ],
    column: 1,
  },
  {
    category: 'Home Size',
    fieldName: 'homeSize',
    options: [
      {
        label: 'Small',
        value: 'small',
      },
      {
        label: 'Medium',
        value: 'medium',
      },
      {
        label: 'Large',
        value: 'large',
      },
    ],
    column: 2,
  },
  {
    category: 'Area',
    fieldName: 'area',
    options: [
      {
        label: 'Countryside',
        value: 'countryside',
      },
      {
        label: 'Village',
        value: 'village',
      },
      {
        label: 'Suburban',
        value: 'suburban',
      },
      {
        label: 'City',
        value: 'city',
      },
    ],
    column: 3,
  },
  {
    category: 'Savings',
    fieldName: 'savings',
    options: [
      {
        label: 'Large',
        value: 'large',
      },
      {
        label: 'Medium',
        value: 'medium',
      },
      {
        label: 'Small',
        value: 'small',
      },
    ],
    column: 1,
  },
  {
    category: 'Tech Proficiency',
    fieldName: 'techProficiency',
    options: [
      {
        label: 'High',
        value: 'high',
      },
      {
        label: 'Medium',
        value: 'medium',
      },
      {
        label: 'Low',
        value: 'low',
      },
    ],
    column: 2,
  },
  {
    category: 'Employer Size',
    fieldName: 'employerSize',
    options: [
      {
        label: 'Large',
        value: 'large',
      },
      {
        label: 'Medium',
        value: 'medium',
      },
      {
        label: 'Small',
        value: 'small',
      },
    ],
    column: 3,
  },
  {
    category: 'Device',
    fieldName: 'device',
    options: [
      {
        label: 'Device phone',
        value: 'device phone',
      },
      {
        label: 'Unknown',
        value: 'unknown',
      },
      {
        label: 'Tablet',
        value: 'tablet',
      },
      {
        label: 'Speaker',
        value: 'speaker',
      },
      {
        label: 'Watch',
        value: 'watch',
      },
      {
        label: 'TV',
        value: 'tv',
      },
      {
        label: 'MP3 player',
        value: 'mp 3 player',
      },
      {
        label: 'Radio',
        value: 'radio',
      },
      {
        label: 'Games console',
        value: 'games console',
      },
      {
        label: 'PC',
        value: 'pc',
      },
    ],
    column: 1,
  },
  {
    category: 'Source',
    fieldName: 'source',
    options: [
      {
        label: 'Apple podcasts',
        value: 'apple podcasts',
      },
      {
        label: 'Google podcasts',
        value: 'google podcasts',
      },
      {
        label: 'Spotify',
        value: 'spotify',
      },
      {
        label: 'Google chrome',
        value: 'google chrome',
      },
      {
        label: 'Pocket casts',
        value: 'pocket casts',
      },
      {
        label: 'Overcast',
        value: 'overcast',
      },
      {
        label: 'Amazon music podcasts',
        value: 'amazon music podcasts',
      },
      {
        label: 'Unknown',
        value: 'unknown',
      },
      {
        label: 'Podcastaddict',
        value: 'podcastaddict',
      },
      {
        label: 'Apple core media',
        value: 'apple core media',
      },
    ],
    column: 2,
  },
  {
    category: 'Country',
    fieldName: 'country',
    options: [
      {
        label: 'AU',
        value: 'au',
      },
      {
        label: 'CA',
        value: 'ca',
      },
      {
        label: 'DE',
        value: 'de',
      },
      {
        label: 'IN',
        value: 'in',
      },
      {
        label: 'NL',
        value: 'nl',
      },
      {
        label: 'NZ',
        value: 'nz',
      },
      {
        label: 'ZA',
        value: 'za',
      },
      {
        label: 'SE',
        value: 'se',
      },
      {
        label: 'GB',
        value: 'gb',
      },
      {
        label: 'US',
        value: 'us',
      },
    ],
    column: 3,
  },
  {
    category: 'Gender',
    fieldName: 'gender',
    options: [
      {
        label: 'Male',
        value: 'male',
      },
      {
        label: 'Female',
        value: 'female',
      },
    ],
    column: 1,
  },
];
