/* eslint-disable import/no-anonymous-default-export */
import * as actionType from '../actions/types';
import * as actions from '../actions';
import { put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from '../actions/types';
import { toggleInPageLogin } from 'slices/global.slice';
import { isNil } from 'lodash';
import { setClipTitle } from 'slices/clips.slice';
import { AxiosResponse } from 'axios';
import { LibraryService, MediaService, RenameTitlePayload } from 'services';
import { customToast } from 'utils/toast.util';
import { SortField } from 'utils/enum';

export const initlibrarysaga = function* (data: any): any {
  try {
    //#region first call with limit number items
    yield put(actions.fetchLibrary());
    const pagination = yield select((state) => state.pagination);
    let {
      page_number = 1,
      page_size = 30,
      collection_id = '',
      sortBy = SortField.MODIFIED,
      order = 'desc',
    } = isNil(data?.data) ? pagination : data.data;


    const libraryResponse: AxiosResponse = yield LibraryService.getLibraryItems(
      {
        page_number,
        page_size,
        collection_id: collection_id ?? '',
        sortBy,
        order
      },
    );
    libraryResponse.data.pageNumber = page_number;
    libraryResponse.data.pageSize = page_size;

    yield put(actions.setLibrary(libraryResponse.data));
    //#endregion
  } catch (error: any) {
    yield put(actions.fetchfailedLibrary());
    if ([401, 403].includes(error?.response?.status)) {
      yield put(toggleInPageLogin(true));
    }
  }
};

export const fetchLibrarySagaNoLoading = function* (data: any): any {
  try {
    yield put(actions.fetchLibraryNoLoading());
    const { page_number = 1, page_size = 30 ,
      sortBy = 'updated_at',
      collection_id = '',
      order = 'asc'} = { ...data.payload };

    const libraryResponse: AxiosResponse = yield LibraryService.getLibraryItems(
      {
        page_number,
        page_size,
        collection_id,
        sortBy,
        order
      },
    );

    libraryResponse.data.pageNumber = page_number;
    libraryResponse.data.pageSize = page_size;

    yield put(actions.setLibrary(libraryResponse.data));
  } catch (error) {
    yield put(actions.fetchfailedLibrary());
  }
};

const updateLibrarySaga = function* (data: any): any {
  let library = yield select((state) => state.library);
  try {
    yield put({ type: actionTypes.UPDATE_TITLE });
    let requestData: RenameTitlePayload = {
      mediaid: '',
      tenantid: '',
      title: '',
    };
    if (data.payload.tenantid) {
      requestData = { ...data.payload };
    } else {
      requestData = {
        ...data.payload,
        tenantid: library.data.tenantid ?? library.data.tenant_id,
      };
    }
    const callRenameAPI = MediaService.updateTitle(requestData);
    customToast.promise(callRenameAPI, {
      loading: 'Updating...',
      success: 'Title updated',
      error: 'Title update failed',
    });
    const response = yield callRenameAPI;
    if (response.status === 200) {
      yield put(setClipTitle(response?.data?.title));

      library.data.library = library.data.library.map((item: any) =>
        item.mediaid === data.payload.mediaid
          ? {
              ...item,
              title: data.payload.title,
              modifieddatetime: response.data?.modifieddatetime,
            }
          : item,
      );
      yield put({ type: actionTypes.SET_LIBRARY, payload: library.data });
      return;
    } else {
      // @TODO: Show Error. Need to confirm the notification behavior
      yield put({ type: actionTypes.UPDATE_TITLE_FAIL });
    }
  } catch (error) {
    // @TODO: Show Error. Need to confirm the notification behavior
    yield put({ type: actionTypes.UPDATE_TITLE_FAIL, payload: library.data });
  }
};

export default function* () {
  yield takeLatest(actionType.INIT_LIBRARY, initlibrarysaga);
  yield takeLatest(
    actionType.INIT_LIBRARY_NO_LOADING,
    fetchLibrarySagaNoLoading,
  );
  yield takeEvery(actionTypes.UPDATE_TITLE_SAGA, updateLibrarySaga);
}
