/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { GroupBase, StylesConfig } from 'react-select';
import { userSelector } from 'slices/payment.slice';
import { CustomSelectSearch, Option } from './CustomSelectSearch';

import { ReactComponent as TagSvg } from 'assets/Icons/tag.svg';
import { differenceBy, isEmpty } from 'lodash';
import { RootState } from 'reducers';
import { TagService } from 'services/tag.service';
import { fetchAllTags } from 'slices/tag.slice';
import { CollectionTag } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { useAppDispatch } from 'store/store';
import { scrollbarWidthChildCss } from './twin.styles';
import { ARCHIVE_TAG_NAME } from 'utils/constants';
import { extract } from 'utils/generic.util';

type Props = {
  defaultTags: CollectionTag[];
  onSaveTags: (tags: Option[], isFetchCollection: boolean) => void;
};

export const TagSelectionDropdown = React.memo(
  ({ defaultTags, ...props }: Props) => {
    const dispatch = useAppDispatch();

    const tags = useSelector((state: RootState) => state.tags);
    const user = useSelector(userSelector);

    const [selectedTags, setSelectedTags] = useState<Option[]>();
    const [initialSelectedTags, setInitialSelectedTags] = useState<Option[]>(
      [],
    );

    useEffect(() => {
      const tags = defaultTags.map((tag) => ({
        label: tag.tagName,
        value: tag.tagId,
      }));

      setSelectedTags(tags);
      setInitialSelectedTags(tags);
    }, [defaultTags]);

    const dropdownOptions = useMemo<Option[]>(() => {
      return tags.activeTags.map((tag) => ({
        label: tag.tagName,
        value: tag.tagId,
      }));
    }, [tags]);

    const handleAddNewTag = async (newTagName: string) => {
      const newTagAsync = TagService.saveNewTag({ tagName: newTagName });

      customToast.promise(newTagAsync, {
        loading: (
          <span>
            Creating <strong>{newTagName}</strong>
          </span>
        ),
        success: (
          <span>
            <strong>{newTagName}</strong> created!
          </span>
        ),
        error: 'Failed to create tag',
      });

      try {
        const { data: responseTagId } = await newTagAsync;

        if (responseTagId && selectedTags) {
          handleSelectTag([
            ...selectedTags,
            { label: newTagName, value: responseTagId },
          ]);
        }

        await dispatch(fetchAllTags()).unwrap();
      } catch (error) {
        console.log('error :>> ', error);
      }
    };

    const handleSelectTag = (selectedTags: Option[]) => {
      let isFetchCollection = false;

      const differenceItems = differenceBy(
        selectedTags,
        initialSelectedTags,
        'label',
      ).concat(differenceBy(initialSelectedTags, selectedTags, 'label'));

      if (extract(differenceItems, 'label').includes(ARCHIVE_TAG_NAME)) {
        isFetchCollection = true;
      }

      setSelectedTags(selectedTags);
      setInitialSelectedTags(selectedTags);

      props.onSaveTags(selectedTags, isFetchCollection);
    };

    const handleMenuClose = async () => {};

    const renderCustomOption = (option: Option) => (
      <div tw="flex flex-row">
        <div tw="max-width[3rem] mr-1 flex items-center">
          <TagSvg fill="#7f8090" />
        </div>

        <div tw="flex flex-col w-full">
          <div
            tw="text-14 text-left text-sonnant-dark break-words opacity-90"
            className="line-clamp-2"
          >
            {option.label}
          </div>
        </div>
      </div>
    );

    return (
      <div css={[scrollbarWidthChildCss(3)]}>
        <CustomSelectSearch
          isMultiSelect
          options={dropdownOptions}
          defaultValue={!isEmpty(selectedTags) ? selectedTags : 'Select tags'}
          customStyles={customStyles}
          onChange={handleSelectTag}
          handleAddNew={handleAddNewTag}
          canCreate={true}
          disabled={!user.isAdministrator}
          formatOptionLabel={renderCustomOption}
          isLoading={tags.isLoading}
          onMenuClose={handleMenuClose}
          isClearable={true}
        />
      </div>
    );
  },
);

const customStyles: StylesConfig<any, false, GroupBase<any>> = {
  input: (base) => ({
    ...base,
    height: '2rem',
    position: 'relative',
    top: '-1.2rem',
    fontSize: '1.4rem',
    marginLeft: '6px',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '4px',
    minHeight: '4.2rem',
    maxHeight: '20.5rem',
    overflowY: 'auto',
  }),
  multiValue: (base) => ({
    ...base,
    cursor: 'pointer',
    background: '#E1EFFE',
  }),
  placeholder: (base) => ({
    ...base,
    marginLeft: '6px',
  }),
};
