import { UseQueryOptions, useQuery } from 'react-query';
import { TagDetailResponse, TagService } from 'services/tag.service';
import { Duration } from 'utils/constants';
import { getTenantidFromIdToken } from 'utils/utils';

type Props = {
  tagId: string | null;
  options?: UseQueryOptions<TagDetailResponse | undefined>;
};

const tenantid = getTenantidFromIdToken();

export const useTagIdQuery = ({ tagId, options }: Props) => {
  const queryFnAsync = async () => {
    if (!tagId) return;

    const { data } = await TagService.getTagById(tagId);

    return data;
  };

  const queryResult = useQuery({
    queryKey: ['/tags', tenantid, tagId],
    queryFn: queryFnAsync,
    staleTime: Duration.seconds(30),
    cacheTime: 0,
    retry: 3,
    optimisticResults: true,
    ...options,
  });

  return queryResult;
};
