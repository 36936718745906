import { HTMLAttributes, ReactNode } from 'react';

import { HintRestrictedEdit } from './HintRestrictedEdit';
import { HintRestrictedPublish } from './HintRestrictedPublish';

type Props = {
  children: ReactNode;
  isRestrictedPublish: boolean;
  isRestrictedEdit: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const HintRestrictedChecker = ({
  children,
  isRestrictedPublish,
  isRestrictedEdit,
  ...props
}: Props) => {
  if (isRestrictedPublish) {
    return <HintRestrictedPublish>{children}</HintRestrictedPublish>;
  }

  if (isRestrictedEdit) {
    return <HintRestrictedEdit>{children}</HintRestrictedEdit>;
  }

  return <>{children}</>;
};
