/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as AscendingArrow } from 'assets/Icons/Ascending_Arrow.svg';
import { ReactComponent as DescendingArrow } from 'assets/Icons/Descending_Arrow.svg';
import { first, isEmpty, isEqual, isNil, toNumber } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderGroup, Row, useSortBy, useTable } from 'react-table';
import { RootState } from 'reducers';
import { clearSelectedClips } from 'slices/clips.slice';
import { IClips, IPublishVersion } from 'utils/models';
import './listView.scss';
import { ListViewClipsItem } from './ListViewClipsItem';

interface Props {
  data: any;
  versionData: IClips[];
  suggestedClips: IClips[];
  trendingClips: IClips[];
  handleShareContentItem?: () => void;
  handleExportContentItem?: () => void;
  handlePublishContentItem?: () => void;
  handleShowPreviewPublish?: (startTime: number, endTime: number) => unknown;
  handleShowPreview?: (item: IClips) => unknown;
  handleDeleteVersionMedias: (singleItem: IClips) => void;
  handleRedirectClick: (item: any) => void;
  handleIntegrate: () => void;
}

export const ClipListView = ({
  data: libraryItem,
  versionData,
  suggestedClips,
  trendingClips,
  handleShareContentItem,
  handleExportContentItem,
  handlePublishContentItem,
  handleShowPreviewPublish,
  handleShowPreview,
  handleDeleteVersionMedias,
  handleRedirectClick,
  handleIntegrate,
}: Props) => {
  const dispatch = useDispatch();
  const publishedVersion: IPublishVersion = useSelector(
    (state: RootState) => state.getPublishedVersion.data,
  );

  const [versionItem, setVersionItem] = useState(versionData);

  const [originalClip, setOriginalClip] = useState<any>();

  const clips = useSelector((state: RootState) => state.clips);

  useEffect(() => {
    if (isNil(versionData)) return;
    const versions = versionData;
    dispatch(clearSelectedClips());
    const originalIndex = versions.findIndex((v: IClips) =>
      isEqual(toNumber(v.versioncount), 0),
    );
    const originalArray = [...versions].splice(originalIndex, 1);

    setOriginalClip(first(originalArray));
    setVersionItem(versions);
    //dispatch(setSelectedClips(versionData));
  }, [versionData]);

  const excludeOriginal = (fullVersions: Row<IClips>[]) => {
    return (
      fullVersions?.filter(
        (version: Row<IClips>) =>
          !isEqual(toNumber(version.original?.versioncount), 0),
      ) ?? []
    );
  };

  useEffect(() => {
    if (clips.isSelectMode) return;

    dispatch(clearSelectedClips());
  }, [clips.isSelectMode]);

  const versionItemColumns = useMemo(
    (): any[] => [
      { Header: ' ', style: { width: '4rem' } },
      {
        Header: 'TYPE',
        accessor: 'mediatype',
        style: { textAlign: 'center', width: '4rem' },
      },
      { Header: 'TITLE', accessor: 'versionname', width: '52%' },
      { Header: 'DURATION', accessor: 'length', width: '8%' },
      { Header: 'LAST MODIFIED', accessor: 'datetime', width: '12%' },
      {
        Header: 'VIEWS',
        accessor: 'viewed',
        width: '8%',
        style: { textAlign: 'center' },
      },
      { Header: 'STATUS', accessor: 'status', width: '8%' },
      { Header: '   ', width: '5%' },
    ],
    [],
  );

  const { getTableBodyProps, getTableProps, headerGroups, rows, prepareRow } =
    useTable(
      { columns: versionItemColumns, data: versionItem ?? [] },
      useSortBy,
    );

  const {
    getTableBodyProps: getSuggestedTableBodyProps,
    getTableProps: getSuggestedTableProps,
    headerGroups: headerSuggestedGroups,
    rows: suggestedRows,
    prepareRow: prepareSuggestedRow,
  } = useTable(
    { columns: versionItemColumns, data: suggestedClips ?? [] },
    useSortBy,
  );

  const {
    getTableBodyProps: getTrendingTableBodyProps,
    getTableProps: getTrendingTableProps,
    headerGroups: headerTrendingGroups,
    rows: trendingRows,
    prepareRow: prepareTrendingRow,
  } = useTable(
    { columns: versionItemColumns, data: trendingClips ?? [] },
    useSortBy,
  );

  return (
    <div
      className="display_version_list_view"
      tw="height[calc(100vh - 130px)] overflow-y-auto"
    >
      <div className="grid-x list_display_block">
        <div className="cell grid-padding-x medium-12">
          <div className="display_version">
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup: HeaderGroup<IClips>) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="action-item"
                  >
                    {headerGroup.headers.map((column: any) => (
                      <th
                        className="action-item"
                        width={column.width}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                        style={column.style}
                      >
                        <div tw="relative">
                          {column.isSorted &&
                            (column.isSortedDesc ? (
                              <div tw="absolute -left-6 -top-3 flex flex-col">
                                <div tw="-mb-1 opacity-40">
                                  <DescendingArrow />
                                </div>
                                <div tw="-mt-3 ">
                                  <AscendingArrow />
                                </div>
                              </div>
                            ) : (
                              <div tw="absolute -left-6 -top-3 flex flex-col">
                                <div tw="-mb-1">
                                  <DescendingArrow />
                                </div>
                                <div tw="-mt-3 opacity-40">
                                  <AscendingArrow />
                                </div>
                              </div>
                            ))}
                          <div>{column.render('Header')}</div>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {!isEmpty(originalClip) && (
                  <ListViewClipsItem
                    item={originalClip}
                    isOriginal
                    libraryItem={libraryItem}
                    handleShare={handleShareContentItem}
                    handleExport={handleExportContentItem}
                    handlePublish={handlePublishContentItem}
                    handleShowPreviewPublish={handleShowPreviewPublish}
                    handleShowPreview={handleShowPreview}
                    handleDeleteVersionMedias={handleDeleteVersionMedias}
                    handleRedirectClick={handleRedirectClick}
                    handleIntegrate={handleIntegrate}
                  />
                )}
                {excludeOriginal(rows).map((item, index) => {
                  prepareRow(item);
                  return (
                    <ListViewClipsItem
                      item={item.original}
                      index={index}
                      libraryItem={libraryItem}
                      handleShare={handleShareContentItem}
                      handleExport={handleExportContentItem}
                      handlePublish={handlePublishContentItem}
                      handleShowPreviewPublish={handleShowPreviewPublish}
                      handleShowPreview={handleShowPreview}
                      handleDeleteVersionMedias={handleDeleteVersionMedias}
                      handleRedirectClick={handleRedirectClick}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="display_version" tw="pt-12">
            <div tw="border-top[2px solid] border-sonnant-grey-5 text-sonnant-grey-5 font-size[1.6rem] font-medium pt-3! mx-5">
              Suggested Clips
            </div>
            <div tw="pt-7">
              {!isEmpty(suggestedClips) ? (
                <table {...getSuggestedTableProps()}>
                  <thead>
                    {headerSuggestedGroups.map(
                      (headerGroup: HeaderGroup<any>) => (
                        <tr
                          {...headerGroup.getHeaderGroupProps()}
                          className="action-item"
                        >
                          {headerGroup.headers.map((column: any) => (
                            <th
                              className="action-item"
                              width={column.width}
                              {...column.getHeaderProps(
                                column.getSortByToggleProps(),
                              )}
                              style={column.style}
                            >
                              <div tw="relative">
                                {column.isSorted &&
                                  (column.isSortedDesc ? (
                                    <div tw="absolute -left-6 -top-3 flex flex-col">
                                      <div tw="-mb-1 opacity-40">
                                        <DescendingArrow />
                                      </div>
                                      <div tw="-mt-3 ">
                                        <AscendingArrow />
                                      </div>
                                    </div>
                                  ) : (
                                    <div tw="absolute -left-6 -top-3 flex flex-col">
                                      <div tw="-mb-1">
                                        <DescendingArrow />
                                      </div>
                                      <div tw="-mt-3 opacity-40">
                                        <AscendingArrow />
                                      </div>
                                    </div>
                                  ))}
                                <div>{column.render('Header')}</div>
                              </div>
                            </th>
                          ))}
                        </tr>
                      ),
                    )}
                  </thead>
                  <tbody {...getSuggestedTableBodyProps()}>
                    {suggestedRows.map((item: any, index) => {
                      prepareSuggestedRow(item);
                      return (
                        <ListViewClipsItem
                          item={item.original}
                          index={index}
                          libraryItem={libraryItem}
                          handleShare={handleShareContentItem}
                          handleExport={handleExportContentItem}
                          handlePublish={handlePublishContentItem}
                          handleShowPreviewPublish={handleShowPreviewPublish}
                          handleShowPreview={handleShowPreview}
                          handleDeleteVersionMedias={handleDeleteVersionMedias}
                          handleRedirectClick={handleRedirectClick}
                        />
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div tw="flex items-center ml-6">
                  {/* <div tw="width[4rem]">
                  <Loader />
                </div> */}
                  <div tw="text-sonnant-grey-6 text-2xl font-medium my-6 mt-6!">
                    {publishedVersion?.snippetCompleted
                      ? `No suggested clips could be found for this content due to limited text or short length.`
                      : `Suggested clips are being created (they may take up to 10 minutes after uploading).`}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="display_trending" tw="pt-12">
            <div tw="border-top[2px solid] border-sonnant-grey-5 text-sonnant-grey-5 font-size[1.6rem] font-medium pt-3! mx-5">
              Trending Clips
            </div>
            <div tw="pt-7">
              {!isEmpty(trendingClips) ? (
                <table {...getTrendingTableProps()}>
                  <thead>
                    {headerTrendingGroups.map(
                      (headerGroup: HeaderGroup<any>) => (
                        <tr
                          {...headerGroup.getHeaderGroupProps()}
                          className="action-item"
                        >
                          {headerGroup.headers.map((column: any) => (
                            <th
                              className="action-item"
                              width={column.width}
                              {...column.getHeaderProps(
                                column.getSortByToggleProps(),
                              )}
                              style={column.style}
                            >
                              <div tw="relative">
                                {column.isSorted &&
                                  (column.isSortedDesc ? (
                                    <div tw="absolute -left-6 -top-3 flex flex-col">
                                      <div tw="-mb-1 opacity-40">
                                        <DescendingArrow />
                                      </div>
                                      <div tw="-mt-3 ">
                                        <AscendingArrow />
                                      </div>
                                    </div>
                                  ) : (
                                    <div tw="absolute -left-6 -top-3 flex flex-col">
                                      <div tw="-mb-1">
                                        <DescendingArrow />
                                      </div>
                                      <div tw="-mt-3 opacity-40">
                                        <AscendingArrow />
                                      </div>
                                    </div>
                                  ))}
                                <div>{column.render('Header')}</div>
                              </div>
                            </th>
                          ))}
                        </tr>
                      ),
                    )}
                  </thead>
                  <tbody {...getTrendingTableBodyProps()}>
                    {trendingRows.map((item: any, index) => {
                      prepareTrendingRow(item);
                      return (
                        <ListViewClipsItem
                          item={item.original}
                          index={index}
                          libraryItem={libraryItem}
                          handleShare={handleShareContentItem}
                          handleExport={handleExportContentItem}
                          handlePublish={handlePublishContentItem}
                          handleShowPreviewPublish={handleShowPreviewPublish}
                          handleShowPreview={handleShowPreview}
                          handleDeleteVersionMedias={handleDeleteVersionMedias}
                          handleRedirectClick={handleRedirectClick}
                        />
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <div tw="flex items-center ml-6">
                  {/* <div tw="width[4rem]">
                  <Loader />
                </div> */}
                  <div tw="text-sonnant-grey-6 text-2xl font-medium my-6 mt-6!">
                    No trending clips matched.
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
