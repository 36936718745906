/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { Modal } from 'components/UiControls/modal/modal';
import { Loader } from 'components/loader/loader';
import { isEmpty, isEqual, toNumber } from 'lodash';
import React, {
  ReactElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { RootState } from 'reducers';
import { CollectionService } from 'services/collection.service';
import { defaultMonetisationCollection } from 'utils/default/defaultSetting';
import { BasicConfirmModal } from 'utils/models/modal.model';
import { HTTPStatus, ModalOptions } from 'utils/enum';
import {
  AdsConfigOptions,
  BakedInOptions,
  CollectionMonetisation,
  JingleOptions,
  OnmnyMidrollOptions,
  SilenceOptions,
  TextSearchOptions,
} from 'utils/models';
import { customToast } from 'utils/toast.util';
import { ButtonGroup } from '../ButtonGroup';
import { LabelCheckbox } from '../LabelCheckbox';
import { Switcher } from '../Switcher';
import { ensureCollectionMoneFormat } from 'utils/adapter.util';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { disabledSubItemsCss } from '../twin.styles';
import { NumberInput } from '../NumberInput';
import { MonetisationTextSearch } from '../MonetisationTextSearch/MonetisationTextSearch';

type IProps = {};
interface IPromiseParams {
  resolve: (option: ModalOptions) => void;
  reject: (err: any) => void;
}
export const MonetisationModal = forwardRef(
  (props: IProps, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const collection = useSelector((state: RootState) => state.collection);

    const [isOpen, toggleOpen] = useToggle(false);
    const [currentCollectionId, setCurrentCollectionId] = useState<string>('');
    const [isLoading, toggleLoading] = useToggle(false);
    const [isApplyAll, toggleApplyAll] = useToggle(false);
    const [monetisationConfig, setMonetisationConfig] =
      useState<CollectionMonetisation>(defaultMonetisationCollection);

    const defaultConfigs = ensureCollectionMoneFormat({});

    const isEqualDefaultConfigs = isEqual(defaultConfigs, monetisationConfig);

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const confirmModalRef = useRef<BasicConfirmModal>(null);

    useEffect(() => {
      if (!isOpen && isEmpty(currentCollectionId)) return;

      fetchMonetisationConfig();
    }, [currentCollectionId, isOpen]);

    const show = async ({
      collectionId,
    }: {
      collectionId: string;
    }): Promise<unknown> => {
      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setCurrentCollectionId(collectionId);
        toggleOpen(true);
      });
    };

    const fetchMonetisationConfig = async () => {
      try {
        toggleLoading(true);
        const response = await CollectionService.getCollectionMonetisation(
          currentCollectionId,
        );

        if (response.status === HTTPStatus.SUCCESS) {
          if (isEmpty(response?.data)) {
            setMonetisationConfig(defaultMonetisationCollection);
            return;
          }
          setMonetisationConfig(ensureCollectionMoneFormat(response?.data));
        }
      } catch (err) {
        console.log(err);
      } finally {
        toggleLoading(false);
      }
    };

    const hideModal = () => {
      toggleApplyAll(false);
      toggleOpen(false);
    };

    const handleChangeCheckbox = (key: keyof CollectionMonetisation) => {
      setMonetisationConfig({
        ...monetisationConfig,
        [key]: {
          ...monetisationConfig[key],
          isEnabled: !monetisationConfig[key].isEnabled,
        },
      });
    };

    const handleChangeOptions = (
      key: keyof CollectionMonetisation,
      optionKey: keyof (BakedInOptions &
        JingleOptions &
        AdsConfigOptions &
        OnmnyMidrollOptions &
        SilenceOptions &
        TextSearchOptions),
      newValue: string | boolean | number | string[],
    ) => {
      setMonetisationConfig({
        ...monetisationConfig,
        [key]: {
          ...monetisationConfig[key],
          [optionKey]: newValue,
        },
      });
    };

    const handleSaveMonetisationConfig = async (isApplyAll?: boolean) => {
      try {
        if (isApplyAll) {
          const result = await confirmModalRef.current?.show({
            title: 'Apply to all collections',
            message: (
              <div>
                <div>
                  This action will override any configuration setup done for
                  other collections.
                </div>
                <strong>Do you wish to continue?</strong>
              </div>
            ),
          });

          if (result !== ModalOptions.YES) return;
        }

        const listCollectionIds = isApplyAll
          ? collection.items.map((item) => item.id)
          : [currentCollectionId];

        const saveConfigsAsync = CollectionService.saveCollectionMonetisation({
          collection_ids: listCollectionIds,
          monetisation_cfg: ensureCollectionMoneFormat(monetisationConfig),
        });

        customToast.promise(saveConfigsAsync, {
          loading: 'Saving monetisation configuration',
          success: 'Saved successfully',
          error: 'Failed to save monetisation configuration',
        });

        toggleLoading(true);
        await saveConfigsAsync;
      } catch (err) {
        console.log(err);
      } finally {
        toggleLoading(false);
      }
    };

    const handleResetDefault = async (
      e: React.MouseEvent<HTMLButtonElement>,
    ) => {
      e.preventDefault();
      e.stopPropagation();

      const result = await confirmModalRef.current?.show({
        title: 'Reset to default',
        message: (
          <div>
            <div>
              This action will override current configurations by its default
              values.
            </div>
            <strong>Do you wish to continue?</strong>
          </div>
        ),
      });

      if (result !== ModalOptions.YES) return;

      setMonetisationConfig(ensureCollectionMoneFormat({}));
      customToast.success('Done reset to default');
    };

    return (
      <div css={customModalCss}>
        <Modal
          classes="library-mone-modal"
          show={isOpen}
          modalClosed={hideModal}
        >
          <div tw="text-14 px-3 pt-5">
            <div tw="font-bold text-[2.5rem]">Monetisation Configuration</div>

            <div tw="mt-6 py-5 px-8 relative border[1px solid lightgrey] rounded-lg shadow-sm">
              {isLoading && (
                <div tw="flex justify-center absolute top[0] left[0] w-full items-center h-full opacity-[0.95] z-[1] bg-white rounded">
                  <Loader />
                </div>
              )}

              <div tw="p-3 flex flex-wrap">
                <div tw="w-1/2 flex flex-auto flex-col space-y-10">
                  <div tw="text-14 font-semibold">
                    <LabelCheckbox
                      checked={monetisationConfig.bakedInConfig.isEnabled}
                      label="Baked in"
                      onChange={() => handleChangeCheckbox('bakedInConfig')}
                    />
                  </div>
                  <div
                    tw="flex flex-col space-y-10 px-10"
                    css={[
                      !monetisationConfig.bakedInConfig.isEnabled &&
                        disabledSubItemsCss,
                    ]}
                  >
                    <div tw="flex max-h-[4rem] justify-between items-center">
                      <div>Count as Midroll</div>
                      <Switcher
                        hasLabel
                        checked={
                          monetisationConfig.bakedInConfig.countAsMidroll
                        }
                        onCheckedChange={(checked) =>
                          handleChangeOptions(
                            'bakedInConfig',
                            'countAsMidroll',
                            checked,
                          )
                        }
                      />
                    </div>
                    <div tw="flex max-h-[4rem] justify-between items-center">
                      <div>Place additional midrolls</div>
                      <ButtonGroup
                        items={[
                          {
                            label: 'Before',
                            value: 'before',
                          },
                          {
                            label: 'After',
                            value: 'after',
                          },
                          {
                            label: 'Anywhere',
                            value: 'anywhere',
                          },
                        ]}
                        onChange={(item) =>
                          handleChangeOptions(
                            'bakedInConfig',
                            'placement',
                            item.value,
                          )
                        }
                        defaultValue={
                          monetisationConfig.bakedInConfig.placement
                        }
                      />
                    </div>
                    <div tw="flex max-h-[4rem] justify-between items-center">
                      <div>Use jingle to identify</div>
                      <Switcher
                        hasLabel
                        checked={
                          monetisationConfig.bakedInConfig.jingleIdentification
                        }
                        onCheckedChange={(checked) =>
                          handleChangeOptions(
                            'bakedInConfig',
                            'jingleIdentification',
                            checked,
                          )
                        }
                      />
                    </div>
                  </div>
                </div>

                <div tw="w-1/2 flex flex-auto flex-col space-y-10">
                  <div tw="text-14 font-semibold">
                    <LabelCheckbox
                      checked={monetisationConfig.jingleConfig.isEnabled}
                      label="Jingle Configuration"
                      onChange={() => handleChangeCheckbox('jingleConfig')}
                    />
                  </div>
                  <div
                    tw="flex flex-col space-y-10 px-10"
                    css={[
                      !monetisationConfig.jingleConfig.isEnabled &&
                        disabledSubItemsCss,
                    ]}
                  >
                    <div tw="flex flex-1 max-h-[4rem] justify-between items-center">
                      <div>Always place Midroll</div>
                      <Switcher
                        hasLabel
                        checked={
                          monetisationConfig.jingleConfig.alwaysPlaceMidroll
                        }
                        onCheckedChange={(checked) =>
                          handleChangeOptions(
                            'jingleConfig',
                            'alwaysPlaceMidroll',
                            checked,
                          )
                        }
                      />
                    </div>
                    <div tw="flex flex-1 max-h-[4rem] justify-between items-center">
                      <div>Placement additional midrolls</div>
                      <ButtonGroup
                        items={[
                          {
                            label: 'Before',
                            value: 'before',
                          },
                          {
                            label: 'Between',
                            value: 'between',
                          },
                          {
                            label: 'After',
                            value: 'after',
                          },
                        ]}
                        onChange={(item) =>
                          handleChangeOptions(
                            'jingleConfig',
                            'placement',
                            item.value,
                          )
                        }
                        defaultValue={monetisationConfig.jingleConfig.placement}
                      />
                    </div>
                    <div tw="flex flex-1 max-h-[4rem] justify-between items-center">
                      <div>PreRoll checked</div>
                      <Switcher
                        hasLabel
                        checked={monetisationConfig.jingleConfig.isPrerollCheck}
                        onCheckedChange={(checked) =>
                          handleChangeOptions(
                            'jingleConfig',
                            'isPrerollCheck',
                            checked,
                          )
                        }
                      />
                    </div>
                    <div tw="flex flex-1 max-h-[4rem] justify-between items-center">
                      <div>Postroll checked</div>
                      <Switcher
                        hasLabel
                        checked={
                          monetisationConfig.jingleConfig.isPostrollCheck
                        }
                        onCheckedChange={(checked) =>
                          handleChangeOptions(
                            'jingleConfig',
                            'isPostrollCheck',
                            checked,
                          )
                        }
                      />
                    </div>
                    <div tw="flex flex-1 max-h-[4rem] justify-between items-center">
                      <div>Detector</div>
                      <ButtonGroup
                        items={[
                          {
                            label: 'Sonic',
                            value: 'sonic',
                          },
                          {
                            label: 'Music',
                            value: 'music',
                          },
                        ]}
                        onChange={(item) =>
                          handleChangeOptions(
                            'jingleConfig',
                            'detector',
                            item.value,
                          )
                        }
                        defaultValue={monetisationConfig.jingleConfig.detector}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <hr css={hrStyle} />

              <div tw="p-3 flex flex-wrap">
                <div tw="w-1/2 flex flex-col flex-1 space-y-10">
                  <div tw="text-14 font-semibold flex">
                    <LabelCheckbox
                      checked={monetisationConfig.silenceConfig.isEnabled}
                      label="Silence Configuration"
                      onChange={() => handleChangeCheckbox('silenceConfig')}
                    />
                  </div>
                  <div
                    tw="flex flex-col space-y-10 px-10"
                    css={[
                      !monetisationConfig.silenceConfig.isEnabled &&
                        disabledSubItemsCss,
                    ]}
                  >
                    <div tw="flex justify-between items-center">
                      <div>Always place Midroll</div>
                      <Switcher
                        hasLabel
                        checked={
                          monetisationConfig.silenceConfig.alwaysPlaceMidroll
                        }
                        onCheckedChange={(checked) =>
                          handleChangeOptions(
                            'silenceConfig',
                            'alwaysPlaceMidroll',
                            checked,
                          )
                        }
                      />
                    </div>
                    <div tw="flex justify-between items-center">
                      <div>Silence duration</div>
                      <div tw="flex items-center">
                        <NumberInput
                          tw="(max-width[5.4rem] height[3rem] m-2)!"
                          value={
                            monetisationConfig.silenceConfig.silenceDuration
                          }
                          onChangeNumber={(value) =>
                            handleChangeOptions(
                              'silenceConfig',
                              'silenceDuration',
                              value,
                            )
                          }
                          onBlur={(e) =>
                            handleChangeOptions(
                              'silenceConfig',
                              'silenceDuration',
                              toNumber(e.target.value) || 3,
                            )
                          }
                          min={0.5}
                          step={0.5}
                          max={600}
                        />
                        seconds
                      </div>
                    </div>
                  </div>
                </div>
                <div tw="w-1/2 flex flex-col flex-1 space-y-10">
                  <div tw="text-14 font-semibold flex flex-1">
                    <LabelCheckbox
                      checked={monetisationConfig.omnyMidrollsConfig.isEnabled}
                      label="CMS Midrolls Configuration"
                      onChange={() =>
                        handleChangeCheckbox('omnyMidrollsConfig')
                      }
                    />
                  </div>
                  <div
                    tw="flex flex-col space-y-10 px-10"
                    css={[
                      !monetisationConfig.omnyMidrollsConfig.isEnabled &&
                        disabledSubItemsCss,
                    ]}
                  >
                    <div tw="flex justify-between">
                      <div>Count as Midroll</div>
                      <Switcher
                        hasLabel
                        checked={
                          monetisationConfig.omnyMidrollsConfig.countAsMidroll
                        }
                        onCheckedChange={(checked) =>
                          handleChangeOptions(
                            'omnyMidrollsConfig',
                            'countAsMidroll',
                            checked,
                          )
                        }
                      />
                    </div>
                    <div tw="flex justify-between items-center">
                      <div>Place additional midrolls</div>
                      <ButtonGroup
                        items={[
                          { label: 'Before', value: 'before' },
                          { label: 'After', value: 'after' },
                          { label: 'Anywhere', value: 'anywhere' },
                        ]}
                        onChange={(item) =>
                          handleChangeOptions(
                            'omnyMidrollsConfig',
                            'placement',
                            item.value,
                          )
                        }
                        defaultValue={
                          monetisationConfig.omnyMidrollsConfig.placement
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <hr css={hrStyle} />

              <div tw="p-3 flex flex-wrap">
                <div tw="w-1/2 flex flex-col flex-1 space-y-10">
                  <div tw="flex font-semibold text-14">
                    Miscellaneous Ads Configuration
                  </div>
                  <div tw="flex flex-col mt-8 space-y-8 mx-10">
                    <div>
                      <span>
                        Insert{' '}
                        <NumberInput
                          tw="(max-width[5.5rem] height[3rem] m-2 inline-block)!"
                          value={monetisationConfig.multipleAdsConfig.bakedIn}
                          onChangeNumber={(value) =>
                            handleChangeOptions(
                              'multipleAdsConfig',
                              'bakedIn',
                              value,
                            )
                          }
                          onBlur={(e) =>
                            handleChangeOptions(
                              'multipleAdsConfig',
                              'bakedIn',
                              parseInt(e.target.value) || 1,
                            )
                          }
                          min={1}
                          step={1}
                          max={100}
                        />
                        ads per slot for each baked in ad identified
                      </span>
                    </div>
                    <div>
                      <span>
                        Insert{' '}
                        <NumberInput
                          tw="(max-width[5.5rem] height[3rem] m-2 inline-block)!"
                          value={monetisationConfig.multipleAdsConfig.other}
                          min={1}
                          step={1}
                          max={100}
                          onChangeNumber={(value) =>
                            handleChangeOptions(
                              'multipleAdsConfig',
                              'other',
                              value,
                            )
                          }
                          onBlur={(e) =>
                            handleChangeOptions(
                              'multipleAdsConfig',
                              'other',
                              parseInt(e.target.value) || 1,
                            )
                          }
                        />
                        ads per slot based on other configurations
                      </span>
                    </div>
                    <div tw="flex justify-between">
                      <div>Topic change</div>
                      <Switcher
                        hasLabel
                        checked={
                          monetisationConfig.multipleAdsConfig.topicChanged
                        }
                        onCheckedChange={(checked) =>
                          handleChangeOptions(
                            'multipleAdsConfig',
                            'topicChanged',
                            checked,
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div tw="w-1/2 flex flex-col flex-1 space-y-10">
                  <div tw="text-14 font-semibold flex">
                    <LabelCheckbox
                      checked={monetisationConfig.textSearchConfig.isEnabled}
                      label="Text Search Configuration"
                      onChange={() => handleChangeCheckbox('textSearchConfig')}
                    />
                  </div>
                  <div
                    tw="flex flex-col space-y-8 px-10"
                    css={[
                      !monetisationConfig.textSearchConfig.isEnabled &&
                        disabledSubItemsCss,
                    ]}
                  >
                    <MonetisationTextSearch
                      textList={monetisationConfig.textSearchConfig.terms}
                      onChange={(termList) =>
                        handleChangeOptions(
                          'textSearchConfig',
                          'terms',
                          termList,
                        )
                      }
                    />
                    <div tw="flex justify-between items-center">
                      <div>Placement additional midrolls</div>
                      <ButtonGroup
                        items={[
                          { label: 'Before', value: 'before' },
                          { label: 'After', value: 'after' },
                        ]}
                        onChange={(item) =>
                          handleChangeOptions(
                            'textSearchConfig',
                            'placement',
                            item.value,
                          )
                        }
                        defaultValue={
                          monetisationConfig.textSearchConfig.placement
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div tw="mt-5 flex justify-between">
              <div tw="flex gap-x-4">
                <button
                  type="button"
                  className="button btn-secondary large apply"
                  tw="mb-0"
                  onClick={hideModal}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="button btn-secondary large apply"
                  tw="(mb-0 max-width[unset] width[unset] px-3.5)!"
                  onClick={handleResetDefault}
                  disabled={isEqualDefaultConfigs}
                >
                  Reset to default
                </button>
              </div>

              <div tw="flex gap-x-5">
                <LabelCheckbox
                  checked={isApplyAll}
                  label="Apply to all collections"
                  onChange={() => toggleApplyAll()}
                />
                <button
                  type="button"
                  className="button btn-primary large cancel"
                  onClick={() => handleSaveMonetisationConfig(isApplyAll)}
                  tw="mb-0"
                  disabled={isLoading}
                >
                  Save
                </button>
              </div>
            </div>

            <ConfirmModal ref={confirmModalRef} />
          </div>
        </Modal>
      </div>
    );
  },
);
const customModalCss = css`
  .Modal.library-mone-modal {
    ${tw`lg-up:(width[100rem] left[calc(50% - 50rem)]) xl-up:(width[120rem] left[calc(50% - 60rem)]) 2xl-up:(width[140rem] left[calc(50% - 70rem)])`}
  }
`;

const hrStyle = css`
  ${tw`mt-3 mb-1 max-w-full border[1px solid] border-sonnant-grey-3`}
`;
