/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { hoverTextShadow, simpleMenuDisabled } from './twin.styles';

import { HTMLProps } from 'react';
import { v4 } from 'uuid';

type Props = {
  label: string;
  value: string | undefined;
  disabled?: boolean;
} & HTMLProps<HTMLInputElement>;

export const LabelInput = ({ label, value, disabled, ...props }: Props) => {
  const uuid = v4();

  return (
    <div css={[disabled && simpleMenuDisabled]} tw="flex items-center text-14">
      <label
        htmlFor={uuid}
        tw="inline-block w-full font-size[inherit] font-weight[inherit] text-sonnant-dark opacity[0.9] hover:cursor-pointer select-none"
        css={[hoverTextShadow()]}
      >
        {label}

        <input tw="mb-0" type="text" value={value} {...props} />
      </label>
    </div>
  );
};
