/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import React, {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Modal } from 'components/UiControls/modal/modal';
import { ModalOptions } from 'utils/enum';
import { isEmpty } from 'lodash';
import { CreateNameModalProps } from 'utils/models';
import { TextInput } from '../TextInput';
import { isValidSpeaker } from 'utils/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';

interface IPromiseParams {
  resolve: (payload: ApiKeyPayload) => void;
  reject: (err: any) => void;
}

interface ApiKeyPayload {
  option: ModalOptions;
  name?: string;
}

export interface ApiKeyCreation {
  show: () => ApiKeyPayload;
}

interface Props {}

export const SpeakerCreationModal = forwardRef(
  (props: Props, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));

    const speakerIdentification = useSelector(
      (state: RootState) => state.speakerIdentification,
    );

    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [headline, setHeadline] = useState('');
    const [btnTitle, setBtnTitle] = useState('');

    const [name, setName] = useState('');

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (props: CreateNameModalProps): Promise<unknown> => {
      if (props) {
        setTitle(props?.title);
        setHeadline(props.headline);
        setBtnTitle(props.btnTitle);
      }
      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const hideModal = () => {
      setName('');
      setIsOpen(false);
    };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current?.resolve({ option: ModalOptions.CANCEL });
    };

    const handleYes = () => {
      const formattedName = name?.trim();

      if (
        !isValidSpeaker({
          speakerName: formattedName,
          speakerList: speakerIdentification.speakers.map((s) => s.speakerName),
        })
      ) {
        return;
      }

      hideModal();
      promiseInfo.current?.resolve({
        option: ModalOptions.YES,
        name: formattedName,
      });
    };

    return (
      <div css={[customModalCss]}>
        <Modal
          // classes={props?.classes}
          show={isOpen}
          modalClosed={() => {
            hideModal();
            promiseInfo.current?.resolve({ option: ModalOptions.CLOSE });
          }}
        >
          <div>
            <h2 tw="font-semibold text-sonnant-dark">{title}</h2>

            <div tw="mt-4">
              <label>{headline}</label>
              <TextInput
                placeholder="Give a speaker name..."
                onChange={(e) => setName(e.target.value)}
                onPressEnter={handleYes}
              />
            </div>

            <div tw="mt-9">
              <div tw="w-full flex justify-end">
                <button
                  type="button"
                  className="button btn-secondary large"
                  tw="mr-6 hover:(border-sonnant-grey-3!)"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="button btn-primary large"
                  disabled={isEmpty(name?.trim())}
                  onClick={handleYes}
                >
                  {btnTitle}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  },
);

const customModalCss = css`
  .Modal {
    ${tw`(width[52rem] left[calc(50% - 26rem)])!`}
  }
`;
