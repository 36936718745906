import { isAuthPage } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { isNil } from 'lodash';
import { UseQueryOptions, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { UserService } from 'services';
import { setCurrentUserGroup } from 'slices/global.slice';
import { Duration } from 'utils/constants';
import { Roles } from 'utils/enum';
import { IGlobalInfo } from 'utils/models/payment.model';
import {
  getTenantidFromIdToken,
  getUserRole,
  isLoggedIn
} from 'utils/utils';

const tenantid = getTenantidFromIdToken();

export const useQueryUserGroup = (
  options?: UseQueryOptions<Partial<IGlobalInfo>>,
) => {
  const dispatch = useDispatch();

  const queryFnAsync = async () => {
    const { data } = await UserService.getUser({ 'user-group': true });

    const isRoleAdminFromAPI = data?.userGroup?.isAdmin;
    const isRoleAdminFromJwt = getUserRole() === Roles.ADMIN;

    const isValidRole =
      !isNil(isRoleAdminFromAPI) &&
      !isNil(isRoleAdminFromJwt) &&
      !isNil(getUserRole());

    if (isValidRole && isRoleAdminFromAPI !== isRoleAdminFromJwt) {
      // clearSession();
      // dispatch(setToken(null, null));
      // history.push(Routes.ROOT);

      // customToast.error('User role has changed. Please signin again.');

      console.log('Mismatched user role back-end side');
    }

    return data;
  };

  const queryResult = useQuery({
    queryKey: ['user', tenantid],
    queryFn: queryFnAsync,
    staleTime: Duration.seconds(5),
    cacheTime: Duration.seconds(10),
    enabled: isLoggedIn() && !isAuthPage(),
    refetchOnWindowFocus: 'always',
    refetchOnReconnect: true,
    onSuccess: (data) => {
      if (!data.userGroup) return;

      dispatch(setCurrentUserGroup(data.userGroup));
    },
    ...options,
  });

  return queryResult;
};
