/** @jsxImportSource @emotion/react */
import 'twin.macro';
import tw from 'twin.macro';

import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Switcher } from 'components/shared/Switcher';
import { ReactComponent as DelSvg } from 'assets/Icons/x-cancel-red_28dp.svg';
import { ReactComponent as EditSvg } from 'assets/Icons/edit_black_18dp.svg';
import { ReactComponent as ApplySvg } from 'assets/Icons/preview_black_24dp.svg';
import { ReactComponent as AddSvg } from 'assets/Icons/add_black_24dp_rounded.svg';
import { hoverDropShadow } from 'components/shared/twin.styles';
import { Hint } from 'components/shared/Hint';
import { CustomTerm, WatchList } from 'utils/models';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFocusCustomList as setFocusCustomListId,
  toggleApplyTerm,
  toggleTerm,
  triggerSaveCustomVocab,
} from 'slices/global.slice';
import { RootState } from 'reducers';
import { isEqual, isNil } from 'lodash';
import {
  triggerSaveWatchList,
  updateCurrentWatchList,
} from 'slices/watchlist.slice';

interface Props {
  termList: CustomTerm;
  index: number;
  state?: 'apply' | 'add';
  handleEditList?: (item: CustomTerm) => void;
  handleDeleteList?: (item?: CustomTerm) => void;
  handleEditWatchList?: (watchList: WatchList) => void;
  handleDeleteWatchList?: (watchList: WatchList) => void;
  onCloseModal?: () => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  isWatchList?: boolean;
}

export const CustomTermItem = ({ termList, index, ...props }: Props) => {
  const dispatch = useDispatch();
  const global = useSelector((state: RootState) => state.global);
  const { watchLists, focusWatchListId } = useSelector(
    (state: RootState) => state.watchList,
  );

  const isFocus = isEqual(
    props.isWatchList ? focusWatchListId : global.focusCustomList?.id,
    termList.id,
  );

  const itemName = props?.isWatchList ? 'Watch List' : 'Custom Vocab';

  const currentWatchList: WatchList | undefined = watchLists?.find(
    (i) => i.id === termList.id,
  );
  return (
    <Draggable key={termList.id} draggableId={termList.name} index={index}>
      {(provided) => (
        <div
          key={termList.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          tw="flex w-full justify-between py-2 rounded -ml-3 pl-3 pr-2"
          onClick={props?.onClick}
          css={
            isFocus ? tw`background[#DAD9FE]` : tw`hover:(bg-sonnant-grey-2)`
          }
          // onDoubleClick={() => props.handleEditList(term)}
        >
          <div tw="flex items-center opacity[0.85] font-weight[500]">
            {props?.state && (
              <div tw="mr-6">
                <button
                  tw="flex items-center bg-sonnant-grey-2 hover:(bg-sonnant-grey-3 shadow-2xl)! active:(bg-sonnant-grey-4)! focus:(bg-sonnant-grey-2) shadow height[3rem] px-6"
                  onClick={() => {
                    dispatch(setFocusCustomListId(termList));
                    props?.onCloseModal?.();
                  }}
                >
                  {props?.state === 'apply' ? <ApplySvg /> : <AddSvg />}
                </button>
              </div>
            )}
            {!props?.state && <span tw="mr-6">{index + 1}.</span>}
            <span tw="break-all">{termList.name}</span>
          </div>
          <div tw="flex items-center cursor-pointer space-x-6">
            <Hint
              text={`Use this ${itemName} for all new content items`}
              enterDelay={300}
              leaveDelay={0}
            >
              <div tw="cursor-pointer">
                <Switcher
                  hasLabel={true}
                  checked={termList.active}
                  color="#00AF7A"
                  onCheckedChange={() => {
                    if (!props.isWatchList) {
                      dispatch(toggleTerm(termList));
                      dispatch(
                        triggerSaveCustomVocab({ runBackground: false }),
                      );
                      return;
                    }

                    if (!currentWatchList) return;

                    // Toggle switcher enabled/disabled
                    const newCurrentWatchList: WatchList = {
                      ...currentWatchList,
                      active: !currentWatchList?.active,
                    };

                    dispatch(updateCurrentWatchList(newCurrentWatchList));
                    dispatch(triggerSaveWatchList({ runBackground: false }));
                  }}
                />
              </div>
            </Hint>
            {!props.isWatchList && (
              <Hint
                text={
                  !termList?.active
                    ? 'Currently disabled'
                    : 'Create an Applied List for new content items'
                }
                enterDelay={300}
                leaveDelay={0}
              >
                <div tw="cursor-pointer">
                  <Switcher
                    hasLabel={true}
                    checked={
                      props.isWatchList
                        ? currentWatchList?.active!
                        : !!termList?.canApply
                    }
                    disabled={!props.isWatchList && !termList?.active}
                    color="#00AF7A"
                    onCheckedChange={() => {
                      if (!props.isWatchList) {
                        dispatch(toggleApplyTerm(termList));
                        dispatch(
                          triggerSaveCustomVocab({ runBackground: false }),
                        );
                      }
                    }}
                  />
                </div>
              </Hint>
            )}
            <Hint text={`Edit list`} enterDelay={300} leaveDelay={0}>
              <div
                tw="border-radius[50%] bg-white hover:bg-sonnant-grey-2 flex items-center justify-center border[2px solid black] width[2.4rem] height[2.4rem] mr-2 cursor-pointer"
                css={hoverDropShadow(0.1)}
                onClick={() => {
                  if (
                    !isNil(currentWatchList) &&
                    typeof props?.handleEditWatchList === 'function'
                  ) {
                    props?.handleEditWatchList(currentWatchList);
                  }

                  if (typeof props?.handleEditList === 'function') {
                    props?.handleEditList(termList);
                  }
                }}
              >
                <EditSvg />
              </div>
            </Hint>
            <Hint text="Delete list" enterDelay={300} leaveDelay={0}>
              <div
                tw="flex cursor-pointer"
                css={hoverDropShadow(0.2)}
                onClick={() => {
                  if (
                    !isNil(currentWatchList) &&
                    typeof props?.handleDeleteWatchList === 'function'
                  ) {
                    props?.handleDeleteWatchList(currentWatchList);
                  }

                  if (typeof props?.handleDeleteList === 'function') {
                    props?.handleDeleteList(termList);
                  }
                }}
              >
                <DelSvg />
              </div>
            </Hint>
          </div>
        </div>
      )}
    </Draggable>
  );
};
