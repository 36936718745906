/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { useRef, useState } from 'react';
import { hoverDropShadow, noneBorder } from 'components/shared/twin.styles';
import CloseSVG from 'components/UiControls/closeButtonSVG/closeSVG';
import { ReactComponent as Search } from 'assets/Icons/search.svg';
import { ReactComponent as AddTermSvg } from 'assets/Icons/library_add_black_24dp.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  DragDropContext,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { CustomTermItem } from './CustomTermItem';
import { setFocusCustomList } from 'slices/global.slice';
import {
  AddEditTermModal,
  TermModalResult,
} from 'components/shared/CutomTermsModal/AddEditTermModal';
import { CustomTerm, Recipients, Term, WatchList } from 'utils/models';
import { CustomTermType, ModalOptions } from 'utils/enum';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { getTenantidFromIdToken } from 'utils/utils';
import Loader from 'components/loader/loader';
import { Hint } from 'components/shared/Hint';
import { ListDetailPane } from './ListDetailPane';
import { useUnmount } from 'react-use';
import { UserService } from 'services';
import { customToast } from 'utils/toast.util';
import {
  appendNewWatchList,
  appendWatchList,
  setCurrentEditWatchList,
  setFocusWatchListId,
  setWatchList,
  triggerSaveWatchList,
} from 'slices/watchlist.slice';
import { CreateFromCustomVocabButton } from 'components/shared/AppliedListModal/CreateFromCustomVocabButton';
import { isEmpty } from 'lodash';
import { cloneNameRecursive } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { v4 } from 'uuid';
import { VocabEditablePayload } from 'utils/models/modal.model';

interface Props {
  inModal?: boolean;
  onCloseModal?: () => void;
}

export const WatchListsTab = ({ inModal, ...props }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');

  const watchListReducer = useSelector((state: RootState) => state.watchList);
  const user = useSelector((state: RootState) => state.userProfile);

  const { watchLists, focusWatchListId } = watchListReducer;

  const profile = useSelector((state: RootState) => state.payment.subscription);
  const global = useSelector((state: RootState) => state.global);

  const searchedWatchLists =
    watchLists?.filter((list) =>
      list?.name.toLowerCase().includes(searchTerm.trim().toLowerCase()),
    ) ?? [];

  const dispatch = useDispatch();
  const termModalRef = useRef<any>(null);
  const confirmModalRef = useRef<any>(null);

  useUnmount(() => {
    dispatch(setFocusCustomList(null));
  });

  const updateAndSaveWatchList = (
    newWatchList: Pick<WatchList, any> | WatchList,
    isCreationMode?: boolean,
  ) => {
    if (isCreationMode) {
      dispatch(appendNewWatchList(newWatchList));
    } else {
      dispatch(appendWatchList(newWatchList as WatchList));
    }

    setTimeout(() => {
      dispatch(triggerSaveWatchList({ runBackground: false }));
    }, 0);
  };

  const handleSelectItem = (customVocab: CustomTerm) => {
    const newWatchList: Pick<WatchList, 'active' | 'name' | 'terms' | 'email'> = {
      active: true,
      name: cloneNameRecursive(
        watchLists.map(({ name }) => name),
        customVocab.name,
      ),
      terms: customVocab.terms,
      email: user.email
    };

    updateAndSaveWatchList(newWatchList, true);
  };

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (
      result?.destination?.index == null ||
      result.source.index === result?.destination.index
    )
      return;

    const newList = [...watchLists];
    const [orderedItem] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, orderedItem);

    dispatch(setWatchList(newList));
    saveChanges(newList, false);
  };

  const handleAddList = async () => {
    // append temp watch list
    const newId = v4();
    const newWatchList = {
      id: newId,
      name: '',
      active: true,
      terms: [] as Term[],
      versionNo: 1,
      dateCreated: new Date(),
      lastModified: new Date(),
      recipients: {
        me: true as boolean,
        allUsers: false as boolean,
        hasEmailAddress: false as boolean,
        emailAddress: [user.email] as string[],
      } as Recipients,
    };
    dispatch(setCurrentEditWatchList(newWatchList));
    dispatch(setFocusWatchListId(newId));

    const result: TermModalResult = await termModalRef.current?.show({
      mode: 'add',
      type: CustomTermType.WATCHLIST,
    });

    if (result?.option === ModalOptions.YES) {
      const newWatchList = result.payload;

      updateAndSaveWatchList(newWatchList);
    }
  };

  const handleEditWatchList = async (item: WatchList) => {
    const result = await termModalRef.current?.show({
      mode: 'edit',
      type: CustomTermType.WATCHLIST,
      focusWatchList: item,
    } as VocabEditablePayload);

    if (result?.option === ModalOptions.YES) {
      const editedWatchList: WatchList = result.payload;

      const foundIndex = watchLists.findIndex(
        (list) => list?.id === editedWatchList?.id,
      );

      const cloneWatchList = [...watchLists];

      cloneWatchList[foundIndex] = {
        ...cloneWatchList[foundIndex],
        ...editedWatchList,
        versionNo: (editedWatchList?.versionNo ?? 0) + 1,
        lastModified: new Date(),
      };

      dispatch(setWatchList(cloneWatchList));
      dispatch(setFocusWatchListId(editedWatchList.id));

      saveChanges(cloneWatchList, true);
    }
  };

  const handleDeleteWatchList = async (item: WatchList) => {
    const result = await confirmModalRef.current?.show();

    if (result === ModalOptions.YES) {
      const newWatchLists = watchLists.filter((list) => list.id !== item.id);
      if (!isEmpty(newWatchLists)) {
        dispatch(
          setFocusWatchListId(
            newWatchLists?.[newWatchLists?.length - 1]?.id || null,
          ),
        );
      } else {
        dispatch(setFocusWatchListId(null));
      }

      dispatch(setWatchList(newWatchLists));
      saveChanges(newWatchLists, true);
    }
  };

  const saveChanges = async (newWatchList: WatchList[], hasToast = false) => {
    try {
      const saveAsync = UserService.savePreference({
        tenantid: getTenantidFromIdToken(),
        watch_lists: newWatchList ? JSON.stringify(newWatchList) : undefined,
      });

      if (hasToast) {
        customToast.promise(saveAsync, {
          loading: 'Saving changes...',
          success: 'Saved successfully',
          error: 'Save failed',
        });
      }

      await saveAsync;
    } catch (error: any) {
      console.log('error :>> ', error);
    }
  };

  const hasReachLimit = (): boolean => {
    // There's no limitation currently
    return false;
  };

  return (
    <div css={!inModal && tw`ml-4 mr-1`} tw="text-sonnant-dark">
      {profile.isLoading && (
        <div
          className="loader__component"
          tw="height[calc(100vh - 13rem)] top[13rem] opacity-100"
        >
          <Loader />
        </div>
      )}

      <div tw="flex">
        <div tw="flex-1">
          <div tw="flex w-full justify-between items-center mt-3 mb-6 text-sonnant-dark md-down:(max-width[40rem])">
            <div tw="flex w-full mr-3 justify-end">
              <div tw="mr-6">
                <div className="search-container" tw="sm:hidden! flex -mr-1">
                  <div tw="flex relative">
                    <input
                      className="input-group-field input-search"
                      tw="(height[4rem] rounded-r-none rounded-l pl-4 pr-2 font-size[1.5rem] caret-color[#7F8090] placeholder:(text-14))! md-down:(max-width[12rem] pl-2)!"
                      css={[noneBorder]}
                      type="input"
                      placeholder="Search Watch Lists"
                      value={searchTerm}
                      maxLength={128}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <button
                    tw="(rounded-l-none width[4rem] height[4rem] mb-0 rounded-r focus:(bg-white) hover:(border[2px solid #F0F3F6]) box-shadow[none])! md-down:(width[4rem])!"
                    className="button btn-secondary"
                  >
                    {searchTerm ? (
                      <CloseSVG
                        close={() => setSearchTerm('')}
                        color="#7F8090"
                        css={hoverDropShadow(0.2)}
                      />
                    ) : (
                      <Search css={hoverDropShadow(0.2)} />
                    )}
                  </button>
                </div>
              </div>

              <Hint
                disabled={!hasReachLimit()}
                enterDelay={200}
                leaveDelay={100}
                text="Subscribe to get unlimited access to Custom Vocabs"
              >
                <div tw="flex space-x-5">
                  <button
                    className="button large btn-primary"
                    tw="max-width[unset]! width[auto]! flex items-center px-5! whitespace-nowrap mb-0"
                    onClick={handleAddList}
                  >
                    <AddTermSvg tw="mr-3" /> Create new list
                  </button>
                  <div tw="ml-1">
                    <CreateFromCustomVocabButton
                      onSelectItem={handleSelectItem}
                      disabled={!global?.customTerms?.length}
                    />
                  </div>
                </div>
              </Hint>
            </div>
          </div>

          <div>
            <div tw="flex w-full justify-between uppercase font-black font-size[12px]">
              <div tw="flex">
                <div tw="mr-6">#</div>
                <div>Watch list name</div>
              </div>
              <div tw="flex space-x-7 mr-6 text-sonnant-dark">
                <div tw="mr-1">enable</div>
                <div tw="relative right[0px]">edit</div>
                <div>del</div>
              </div>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="termDnd">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {searchedWatchLists.map((watchList, index) => (
                      <CustomTermItem
                        index={index}
                        termList={watchList}
                        key={watchList.id}
                        handleEditWatchList={handleEditWatchList}
                        handleDeleteWatchList={handleDeleteWatchList}
                        onClick={() => {
                          dispatch(setFocusWatchListId(watchList.id));
                        }}
                        isWatchList
                      />
                    ))}
                    {searchedWatchLists.length === 0 && (
                      <div tw="color[#54566C] text-2xl font-medium mb-6 mt-3">
                        There are no existed Watch Lists
                      </div>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>

        {focusWatchListId && !isEmpty(watchLists) && (
          <>
            <div tw="border-left[2px solid] border-sonnant-purple-1 mx-8 rounded"></div>

            <div tw="flex-1">
              <ListDetailPane
                onSaveWatchListChanges={(watchList) =>
                  saveChanges(watchList, true)
                }
                isWatchList={true}
              />
            </div>
          </>
        )}
      </div>

      <AddEditTermModal ref={termModalRef} />

      <ConfirmModal
        ref={confirmModalRef}
        title="Delete confirm"
        message="Deleting this list will delete every instance and
        application of this list including published and shared
        items, do you wish to proceed?"
      />
    </div>
  );
};
