/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { ReactComponent as FilterSvg } from 'assets/Icons/filter.svg';
import { ReactComponent as FilterOffSvg } from 'assets/Icons/filter_off.svg';

import { DateRangeInput } from '@datepicker-react/styled';
import { SimpleMenuSurface } from '@rmwc/menu';
import { Ripple } from '@rmwc/ripple';
import { debounce, isNil } from 'lodash';
import { useRef, useState } from 'react';
import { useClickAway, useToggle } from 'react-use';
import { CustomDateParams } from 'utils/models';
import { customDateRangInputStyle, resetReactRangeCss } from '../twin.styles';
import { defaultDatesRange } from 'utils/default/defaultSetting';
import { HintDisallowed } from '../HintDisallowed';

type Props = {
  onDatesChange: (dateRange: CustomDateParams) => void;
  disabled?: boolean;
};

export const OmnyPreviewFilterMenu = ({
  disabled = false,
  ...props
}: Props) => {
  const [isOpen, toggleOpen] = useToggle(false);

  const [dateRange, setDateRange] =
    useState<CustomDateParams>(defaultDatesRange);

  const menuRef = useRef<HTMLDivElement>(null);

  const showReset = !isNil(dateRange.startDate) && !isNil(dateRange.endDate);

  useClickAway(menuRef, () => {
    if (!isOpen) return;

    hideMenu();
  });

  const isValidDateRange = ({
    startDate,
    endDate,
  }: CustomDateParams): boolean => {
    // Invalid just 1 field is selected
    return [startDate, endDate].filter(Boolean).length !== 1;
  };

  const handleDatesChange = (selectedDate: CustomDateParams) => {
    setDateRange(selectedDate);

    if (!isValidDateRange(selectedDate)) return;

    props.onDatesChange(selectedDate);
    hideMenu();
  };

  const openMenu = () => {
    if (isOpen) return;

    toggleOpen(true);
  };

  const hideMenu = () => {
    if (!isOpen) return;

    toggleOpen(false);
  };

  const handleResetFilters = () => {
    setDateRange(defaultDatesRange);
    props.onDatesChange(defaultDatesRange);

    hideMenu();
  };

  return (
    <div ref={menuRef} css={[menuWrapperCss]}>
      <SimpleMenuSurface
        open={isOpen}
        fixed
        anchorCorner="bottomStart"
        handle={
          <HintDisallowed
            disabled={disabled}
            hintDisabled="Supported for program preview mode only"
            hintEnabled="Filter by date range"
            notTransparent
          >
            <Ripple onClick={openMenu}>
              <span tw="flex justify-center items-center rounded cursor-pointer p-1 mb-2 bg-sonnant-grey-1">
                {showReset ? (
                  <FilterOffSvg tw="fill[#E7483D]" />
                ) : (
                  <FilterSvg fill="#7f7f7f" tw="w-[2.8rem] h-[2.8rem]" />
                )}
              </span>
            </Ripple>
          </HintDisallowed>
        }
      >
        <div tw="pb-5 pt-2 px-5" css={[customDateRangInputStyle]}>
          <label>
            <span tw="text-14 font-medium">Date Range</span>
          </label>

          <form
            tw="flex items-center relative z-[1]"
            autoComplete="off"
            onSubmit={(e) => e.preventDefault()}
          >
            <span css={[resetReactRangeCss]}>
              <DateRangeInput
                onDatesChange={debounce(handleDatesChange, 0)}
                onFocusChange={(focusedInput) =>
                  setDateRange({
                    ...dateRange,
                    focusedInput: focusedInput,
                  })
                }
                showSelectedDates={false}
                showClose={false}
                startDate={dateRange.startDate as Date} // Date or null
                endDate={dateRange.endDate as Date} // Date or null
                focusedInput={dateRange.focusedInput} // START_DATE, END_DATE or null
                maxBookingDate={new Date()}
                displayFormat="dd/MM/yyyy"
              />
            </span>
          </form>

          {showReset && (
            <div tw="w-full flex justify-end mt-3.5">
              <button
                className="button btn-primary medium"
                tw="(px-[1rem] mb-0 bg-sonnant-red)!"
                onClick={handleResetFilters}
              >
                Reset
              </button>
            </div>
          )}
        </div>
      </SimpleMenuSurface>
    </div>
  );
};

const menuWrapperCss = css`
  .mdc-menu-surface.mdc-menu-surface--fixed {
    ${tw`overflow-visible`}
  }
`;
