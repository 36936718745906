/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';
import 'twin.macro';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as actions from '../../actions';
import ReCaptchaComponent from '../../utils/scripts/recaptchaComponent';
import {
  getDomainWithoutSubdomain,
  getEmailErrorMessage,
  getSubdomainFromURL,
  invalidEmail,
  isEmptyOrSpaces,
} from '../../utils/utils';
import Loader from '../loader/loader';
import CloseSVG from '../UiControls/closeButtonSVG/closeSVG';
import { Notification } from '../UiControls/notification/Notification';
import './Login.scss';
import LockImg from 'assets/Icons/lock-login-icon.png';
import { borderGradientBg, textLink } from 'components/shared/twin.styles';
import jwt_decode from 'jwt-decode';
import ArrowIcon from 'assets/Icons/start_free_trial_arrow.png';
import { ForgotPassword } from './ForgotPassword';
import { isNil, isEmpty, isNull } from 'lodash';
import { SearchInput } from 'components/shared/SearchInput';
import {
  AppleIdResponse,
  AuthService,
  FbSdkResponse,
  SigninSocialPayload,
  SubdomainPayload,
} from 'services';
import { loadAppleJsScript } from 'utils/scripts/loadAppleAuthScript';
import { customToast } from 'utils/toast.util';
import { AppleAuthError, Social } from 'utils/enum';
import { loadFacebookSdkScript } from 'utils/scripts/loadFacebookAuthScript';
import { loginFacebookSDKAsync } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { useSearchParam } from 'react-use';
import { loadGoogleScriptV1 } from 'utils/scripts/loadGoogleScriptV1';
import { AuthSocialButtons } from 'components/shared/AuthSocialButtons/AuthSocialButtons';
// import LoginButtonPNG from 'assets/auth/button-gradient.png';
// import PassImg from 'assets/Icons/pass-login-icon.png';

export const Login = () => {
  const search = useLocation().search;
  const nextUrl = useSearchParam('next');
  const nextSubdomain = getSubdomainFromURL(nextUrl);
  const nextQuery = new URLSearchParams(search).get('next');

  const decodeUrl = decodeURIComponent(search);
  const replaceQuestionMark = decodeUrl.replace(/\?/g, '&');

  const userName = new URLSearchParams(replaceQuestionMark)
    .get('username')
    ?.toString();
  const password = new URLSearchParams(replaceQuestionMark)
    .get('password')
    ?.toString();
  const reset = new URLSearchParams(replaceQuestionMark)
    .get('reset')
    ?.toString();

  const [selectAccount, setSelectAccount] = useState<any>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subdomain, setSubdomain] = useState<any>([]);

  const [initSelectAccount] = useState<any>(() => {
    if (nextQuery) {
      const domain = new URL(nextQuery);

      let hostnameArr = domain.hostname.replace('www.', '').split('.');
      if (hostnameArr.length > 2) {
        return hostnameArr[0];
      }
    }
    return null;
  });

  const [loginForm, setLoginForm] = useState<{
    email: string;
    password: string;
  }>({ email: '', password: '' });

  const [error, setError] = useState<{
    emailError: boolean;
    passwordError: boolean;
    apiError: boolean;
    apiErrorMessage: string;
    emailMessage: string;
  }>({
    emailError: false,
    passwordError: false,
    apiError: false,
    apiErrorMessage: '',
    emailMessage: '',
  });

  const history = useHistory();
  const loginData = useSelector((state: any) => state.login);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    ReCaptchaComponent.init();

    if (loginData.errorMessage) {
      setError((preValue: any) => ({
        ...preValue,
        apiError: true,
        apiErrorMessage: loginData.errorMessage,
      }));
    }

    const close = (e: any) => {
      if (e.keyCode === 27) {
        handleModalClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, [loginData.errorMessage]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isNull(initSelectAccount)) {
      setSelectAccount(initSelectAccount);
    }
  }, [initSelectAccount]);

  const onFinish = (e: any) => {
    e.preventDefault();
  
    if (hasLoginFormError()) {
      return;
    }

    if (
      selectAccount === '' ||
      loginForm.email === '' ||
      loginForm.password === ''
    ) {
      return;
    }

    dispatch(
      actions.initLogin({
        subdomain: initSelectAccount
          ? initSelectAccount
          : selectAccount || subdomain[0],
        email: loginForm.email.trim(),
        password: loginForm.password,
        history,
        nextQuery,
      }),
    );
  };

  const handleChange = (property: any) => (e: any) => {
    const value = e.target.value;
    setIsDisableLogin(false);
    if (property === 'email') {
      setLoginForm((preValue: any) => ({
        ...preValue,
        [property]: value.toLowerCase(),
        password: '',
      }));
      setSearchTenantTerm('');
      setSubdomain([]);
      return;
    }

    setLoginForm((preValue: any) => ({ ...preValue, [property]: value }));
  };

  const hasLoginFormError = () => {
    const { email, password } = { ...loginForm };

    const isBlankEmail = isEmptyOrSpaces(email?.trim());
    const isBlankPassword = isEmptyOrSpaces(password?.trim());
    const isInvalidEmail = invalidEmail(email?.trim());

    setError((preValue: any) => ({
      ...preValue,
      passwordError: !isEmpty(subdomain) && isBlankPassword,
      emailError: isBlankEmail || isInvalidEmail,
      emailMessage: getEmailErrorMessage(email),
    }));

    return isBlankPassword || isBlankEmail || isInvalidEmail;
  };

  const handleClose = (property: any) => {
    setError((preValue: any) => ({ ...preValue, [property]: false }));
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const getSubdomainByEmail = () => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    AuthService.getSubdomain({ email: loginForm.email })
      .then(({ data: result }) => {
        setIsLoading(false);
        setSubdomain(result);

        if (result.length === 0) {
          setError({
            ...error,
            emailMessage: 'User email not found.',
            emailError: true,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log('error', error);
      });
  };

  const autoLogin = (username: string, password: string) => {
    dispatch(
      actions.initLogin({
        subdomain: initSelectAccount
          ? initSelectAccount
          : selectAccount || subdomain[0],
        email: username?.trim(),
        password: password,
        history,
        nextQuery,
      }),
    );
  };

  useEffect(() => {
    if (isNil(userName) || isNil(password)) {
      return;
    }

    if (userName !== '' && password !== '') {
      setLoginForm((prevValue: any) => ({
        ...prevValue,
        email: userName,
        password: password,
      }));

      autoLogin(userName!, password!);
    }
  }, []);

  const [socialCredential, setSocialCredential] = useState<any>(null);
  const [provider, setProvider] = useState<Social | null>(null);

  useEffect(() => {
    loadFacebookSdkScript();
    loadAppleJsScript();
    loadGoogleScriptV1();
  }, []);

  const loadSocialAccountSubdomainAsync = async ({
    email,
    token = '',
    provider,
  }: SubdomainPayload) => {
    try {
      // check email
      setIsLoading(true);
      const getSubdomainBySocialProvider = await AuthService.getSubdomain({
        email,
        provider,
      });

      if (getSubdomainBySocialProvider.status !== 200) {
        setSocialCredential(null);
        setProvider(null);
        setError({
          ...error,
          apiError: true,
          apiErrorMessage: 'Something went wrong',
        });
        setIsLoading(false);
        return;
      }

      if (getSubdomainBySocialProvider.data.length === 0) {
        setSocialCredential(null);
        setProvider(null);
        setError({
          ...error,
          apiError: true,
          apiErrorMessage: 'User not found.',
        });
        setIsLoading(false);
        return;
      }

      // call signin with on tenant
      if (getSubdomainBySocialProvider.data.length === 1) {
        await loginRedirectAsync({
          email,
          subdomain: nextQuery
            ? nextSubdomain!
            : getSubdomainBySocialProvider.data[0],
          token,
          provider: provider!,
        });
      }

      if (getSubdomainBySocialProvider.data.length > 1) {
        if (initSelectAccount) {
          await loginRedirectAsync({
            email,
            subdomain: initSelectAccount,
            token,
            provider: provider!,
          });
        } else {
          setSubdomain(getSubdomainBySocialProvider.data);
          setIsLoading(false);
        }
      }
    } catch {
      setError({
        ...error,
        apiError: true,
        apiErrorMessage: 'Something went wrong',
      });
      setIsLoading(false);
    }
  };

  const loginRedirectAsync = async (payload: SigninSocialPayload) => {
    try {
      setIsLoading(true);
      const signInResult = await AuthService.signinSocial(payload);

      const { IdToken, AccessToken, RefreshToken } = { ...signInResult.data };

      const domainUrl = `${window.location.protocol}//${
        payload?.subdomain
      }.${getDomainWithoutSubdomain()}`;

      if (nextQuery) {
        window.location.replace(
          `${domainUrl}?next=${encodeURIComponent(
            nextQuery,
          )}&access_token=${AccessToken}&id_token=${IdToken}&refresh_token=${RefreshToken}`,
        );
      } else {
        window.location.replace(
          `${domainUrl}?access_token=${AccessToken}&id_token=${IdToken}&refresh_token=${RefreshToken}`,
        );
      }
    } catch (error: any) {
      setSocialCredential(null);

      let message = '';
      switch (error?.status) {
        case 404:
          if (payload?.provider) {
            message = `Your ${payload.provider} account does not link to this tenant. Please use another login method.`;
          }
          break;
        case 401:
          message = error?.data;
          break;
      }

      setError({
        ...error,
        apiError: true,
        apiErrorMessage:
          message ||
          'Something went wrong. Please contact support for more information.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginGoogleButton = async () => {
    try {
      const user = await window?.gapi?.auth2?.getAuthInstance()?.signIn();

      setIsLoading(true);
      setSocialCredential(user?.getAuthResponse());
      setProvider(Social.GOOGLE);
      const idToken = user?.getAuthResponse()?.id_token;
      const decodedToken: any = jwt_decode(idToken);

      await loadSocialAccountSubdomainAsync({
        email: decodedToken?.email,
        token: idToken,
        provider: Social.GOOGLE,
      });
    } catch (err: any) {
      console.log('err :>> ', err);
    }
  };

  const handleLoginAppleButton = async (
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    if (typeof (window as any)?.AppleID?.auth?.signIn !== 'function') {
      customToast.error('Apple signin is not ready. Please try again later');
      return;
    }

    try {
      setIsLoading(true);
      const appleIdResponse: AppleIdResponse = await (
        window as any
      ).AppleID.auth.signIn();
      setSocialCredential(appleIdResponse);
      setProvider(Social.APPLE);

      const rawIdToken = appleIdResponse?.authorization?.id_token;
      const idTokenDecoded: any = jwt_decode(rawIdToken);
      // console.log('idTokenDecoded :>> ', idTokenDecoded);

      await loadSocialAccountSubdomainAsync({
        email: idTokenDecoded?.email,
        token: rawIdToken,
        provider: Social.APPLE,
      });
    } catch (error: any) {
      console.log('error :>> ', error);
      let message = 'Apple Authorization cannot be completed';
      switch (error?.error) {
        case AppleAuthError.POPUP_BLOCKED:
          message =
            'Popup blocked by browser. Please allow popups for this site';
          break;
        default:
          break;
      }
      customToast.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginSocialSubdomain = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    setIsLoading(true);

    let email = '';
    let token = '';

    switch (provider) {
      case Social.GOOGLE:
        token = socialCredential?.id_token;

        const decodedToken: any = jwt_decode(token);
        email = decodedToken?.email ?? '';
        break;
      case Social.APPLE:
        token = socialCredential?.authorization?.id_token;

        const idTokenDecoded: any = jwt_decode(token);
        email = idTokenDecoded?.email ?? '';
        break;
      case Social.FACEBOOK:
        const user = socialCredential as FbSdkResponse;

        email = user?.email;
        token = user?.id;
        break;
      default:
        customToast.error('Invalid social provider');
        return;
    }

    try {
      await loginRedirectAsync({
        email,
        subdomain: selectAccount,
        token,
        provider: provider!,
      });
    } catch {
      console.log('error :>> ', error);
      setError({
        ...error,
        apiError: true,
        apiErrorMessage: 'Something went wrong',
      });
      setIsLoading(false);
    }
  };

  const handleLoginFacebookButton = async () => {
    try {
      setIsLoading(true);
      const user = await loginFacebookSDKAsync();

      if (!user?.email) {
        return customToast.error(
          'Cannot read email from your Facebook account. Please try again.',
        );
      }

      setSocialCredential(user);
      setProvider(Social.FACEBOOK);

      await loadSocialAccountSubdomainAsync({
        email: user?.email,
        token: user?.id,
        provider: Social.FACEBOOK,
      });
    } catch (error: any) {
      console.log('error :>> ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const triggerGetSubdomain = (e: React.MouseEvent<HTMLButtonElement>) => {
    // e.preventDefault();

    if (loginForm.email === '') {
      setError({
        ...error,
        emailMessage: 'Email Address is a mandatory field.',
        emailError: true,
      });
      return;
    }

    const isInvalidEmail = invalidEmail(loginForm.email?.trim());
    if (isInvalidEmail) {
      setError({
        ...error,
        emailMessage: 'This email address is considered invalid.',
        emailError: true,
      });
      return;
    }

    if (!initSelectAccount) {
      setIsLoading(true);
      getSubdomainByEmail();
    }
  };

  const [searchTenantTerm, setSearchTenantTerm] = useState('');
  const [isDisableLogin, setIsDisableLogin] = useState(false);

  const searchedTenants = subdomain.filter((tenant: any) =>
    tenant.includes(searchTenantTerm.toLowerCase()),
  );

  useEffect(() => {
    if (searchedTenants.length > 0 && isEmpty(selectAccount)) {
      setSelectAccount(searchedTenants[0]);
    }

    if (!isEmpty(subdomain) && isEmpty(searchedTenants)) {
      setIsDisableLogin(true);
    } else {
      setIsDisableLogin(false);
    }
  }, [searchTenantTerm, subdomain]);

  return (
    <div tw="height[calc(100vh - 7rem)] sm-down:(height[calc(100vh - 6rem)]) overflow-y-auto">
      {(isLoading || loginData.loading) && (
        <div className="loader__component">
          <Loader />
        </div>
      )}
      {error.apiError && (
        <p className="topMessage error">
          {error.apiErrorMessage}
          <CloseSVG name={'apiError'} close={handleClose} />
        </p>
      )}
      <form
        tw="h-auto min-height[calc(100vh - 13rem)] sm-down:(pt-0 pb-0 min-height[calc(100vh - 12rem)])!"
        className="signinForm"
        onSubmit={onFinish}
      >
        <div tw="width[60rem] py-8 px-24 sm-down:(w-full px-8 border-none shadow-none) m-auto rounded-lg border[1px dotted #ccc] flex flex-col justify-center items-center text-sonnant-blue shadow-sm">
          <div>
            {/* <SonnantLogo />
            <SonnantLogoText /> */}
          </div>
          <div tw="mt-10 font-medium font-size[2.2rem] text-sonnant-purple-2 sm-down:(mt-0)!">
            WELCOME BACK
          </div>
          <div tw="my-10">Sign in to Sonnant</div>

          {socialCredential && subdomain && subdomain.length > 1 ? (
            <div tw="w-full">
              <label tw="text-sonnant-blue font-medium font-size[1.5rem]! relative">
                Select your account
                <select
                  id="account"
                  name="account"
                  size={10}
                  css={selectAccountCss}
                  onChange={(e) => {
                    setSelectAccount(e.target.value);
                  }}
                  value={selectAccount}
                >
                  {subdomain.map((item: any, i: any) => (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          ) : (
            <div tw="w-full flex justify-center flex-col items-center">
              {reset !== 'true' && (
                <AuthSocialButtons
                  text="Sign in"
                  handleLoginGoogleButton={handleLoginGoogleButton}
                  handleLoginAppleButton={handleLoginAppleButton}
                  handleLoginFacebookButton={handleLoginFacebookButton}
                  hasOrDivider
                />
              )}

              {/* <ReCAPTCHA
            style={{ display: 'none' }}
            sitekey="6LfFxAUaAAAAAK1MmPuZYXtuefPQRczSw5IXNFtK"
            size="invisible"
          /> */}
              <div tw="w-full mt-14">
                <label tw="text-sonnant-blue font-medium font-size[1.5rem]! relative">
                  Email Address
                  <input
                    type="text"
                    name="username"
                    id="username"
                    className={error.emailError ? 'error' : ''}
                    value={loginForm.email}
                    onChange={handleChange('email')}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        if (loginForm.email === '') {
                          setError({
                            ...error,
                            emailMessage: 'Email Address is a mandatory field.',
                            emailError: true,
                          });
                          return;
                        }

                        const isInvalidEmail = invalidEmail(
                          loginForm.email?.trim(),
                        );
                        if (isInvalidEmail) {
                          setError({
                            ...error,
                            emailMessage:
                              'This email address is considered invalid.',
                            emailError: true,
                          });
                          return;
                        }

                        if (!initSelectAccount) {
                          setIsLoading(true);
                          getSubdomainByEmail();
                        }
                        e.preventDefault();
                      }
                    }}
                    // onBlur={triggerGetSubdomain}
                    placeholder="john@example.com"
                    onInput={() => setError({ ...error, emailError: false })}
                  />
                  <Notification
                    name={'emailError'}
                    close={handleClose}
                    type={error.emailError ? 'error' : null}
                    message={error.emailMessage}
                  />
                </label>
              </div>
              {(initSelectAccount || (subdomain && subdomain.length > 0)) && (
                <div tw="w-full">
                  <label
                    className="password"
                    tw="text-sonnant-blue font-medium font-size[1.5rem]! relative"
                  >
                    Password
                    <input
                      type="password"
                      id="password"
                      value={loginForm.password}
                      className={`${error.passwordError ? 'error' : ''}`}
                      onChange={handleChange('password')}
                      placeholder="**********"
                      onInput={() =>
                        setError({ ...error, passwordError: false })
                      }
                      autoFocus
                    />
                    <Notification
                      name={'passwordError'}
                      close={handleClose}
                      type={error.passwordError ? 'error' : null}
                      message={'Password is a mandatory field'}
                    />
                    {/* <img
                      src={PassImg}
                      alt="Password"
                      tw="absolute top[3.5rem] height[2.6rem] right-4"
                    /> */}
                  </label>
                </div>
              )}

              {!initSelectAccount && subdomain && subdomain.length > 1 && (
                <div tw="w-full">
                  <div tw="flex flex-row justify-between items-center mb-1">
                    <label tw="text-sonnant-blue font-medium font-size[1.5rem]! relative">
                      Select your account
                    </label>
                    {subdomain?.length > 10 && (
                      <div css={[customSearchInputStyle]}>
                        <SearchInput
                          placeholder="Search tenant name"
                          setTerm={(term) => {
                            setSelectAccount(undefined);
                            setSearchTenantTerm(term);
                          }}
                          autoFocus
                          isSearchTenantLogin
                        />
                      </div>
                    )}
                  </div>
                  <select
                    id="account"
                    name="account"
                    size={10}
                    css={selectAccountCss}
                    onChange={(e) => {
                      setSelectAccount(e.target.value);
                    }}
                    value={selectAccount}
                  >
                    {!isEmpty(searchedTenants) ? (
                      searchedTenants.map((item: string, i: number) => (
                        <option
                          key={i}
                          value={item}
                          onClick={() => setSelectAccount(item)}
                        >
                          {item}
                        </option>
                      ))
                    ) : (
                      <option> No results found </option>
                    )}
                  </select>
                </div>
              )}
            </div>
          )}

          {socialCredential && !nextQuery ? (
            <div tw="w-full mt-6">
              {/* <button
                onClick={handleLoginSocialSubdomain}
                type="button"
                tw="flex justify-center items-center font-size[1.8rem]!"
                disabled={!selectAccount || !provider}
              >
                <div tw="relative flex items-center">
                  GO TO ACCOUNT
                  <img src={ArrowIcon} tw="ml-4 width[40px]" alt="" />
                </div>
              </button> */}
              <button
                type="button"
                id="password"
                tw="relative flex justify-center items-center font-size[1.8rem]! (rounded-full bg-sonnant-purple-4)!  hover:(bg-sonnant-purple-4! opacity-95 shadow)!"
                css={[borderGradientBg('2px', '#201F66')]}
                disabled={!selectAccount || !provider}
                onClick={handleLoginSocialSubdomain}
              >
                <div tw="relative flex items-center top[2px]">
                  <span>GO TO ACCOUNT</span>
                  <img src={ArrowIcon} tw="ml-4 width[40px]" alt="" />
                </div>
              </button>
            </div>
          ) : (
            <div tw="flex w-full  mt-8 justify-center items-center">
              <button
                tw="relative flex justify-center items-center font-size[1.8rem]! (rounded-full bg-sonnant-purple-4)!  hover:(bg-sonnant-purple-4! opacity-95 shadow)!"
                css={[
                  borderGradientBg('2px', '#201F66'),
                  // tw` active:(bg-blend-overlay)!`,
                ]}
                disabled={isDisableLogin}
                onClick={triggerGetSubdomain}
              >
                <div tw="relative flex items-center">
                  <img
                    src={LockImg}
                    alt="Lock icon"
                    tw="height[2.2rem] mr-10 absolute right-full top[-1px]"
                  />
                  <span tw="(line-height[initial] margin-top[1px])!">
                    SIGN IN
                  </span>
                </div>
              </button>
              {/* <div
                onClick={triggerGetSubdomain}
                tw="flex w-full hover:(opacity-95) items-center justify-center"
              >
                <img tw="w-full h-full" src={LoginButtonPNG} alt="Login" />
                <div tw="absolute flex items-center text-center text-white text-2xl ml-5 mt-1">
                  <img
                    src={LockImg}
                    alt="Lock icon"
                    tw="height[2.2rem] mr-5 absolute right-full"
                  />
                  SIGN IN
                </div>
              </div> */}
            </div>
          )}
          <div tw="py-6">
            <a
              css={[textLink]}
              tw="font-medium font-size[1.6rem] text-sonnant-blue!"
              onClick={() => setModalOpen(true)}
            >
              Forgot Password?
            </a>
          </div>
          <div tw="font-size[1.5rem] text-center">
            <div>
              New to Sonnant?{' '}
              <Link css={[textLink]} to="/signup">
                Create an account
              </Link>
            </div>

            {initSelectAccount && (
              <div>
                <a
                  css={[textLink]}
                  href={`${
                    window.location.protocol
                  }//${getDomainWithoutSubdomain()}/signin`}
                >
                  <span css={[textLink]}>Sign into a different account</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </form>

      <ForgotPassword show={modalOpen} setModalOpen={handleModalClose} />

      <div tw="height[6rem] bg-sonnant-indigo py-4 flex justify-end items-center overflow-hidden sm-down:()!"></div>
    </div>
  );
};

const selectAccountCss = css`
  ${tw`height[12rem] p-0 border[1px solid transparent]! overflow-y-auto`}

  option {
    ${tw`cursor-pointer padding[2px 4px] rounded-sm`}
  }
`;

const customSearchInputStyle = css`
  input:not([type='checkbox']) {
    height: 28px !important;
    width: 12rem !important;
    font-size: 12px !important;
    font-weight: normal;
    font-family: inherit;
  }

  .search-container button {
    height: 28px !important;
    width: 28px !important;
    padding-right: 2px !important;
    padding-bottom: 2px !important;
  }
`;
