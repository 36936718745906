import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

const transcriptSlice = createSlice({
  name: 'transcript',
  initialState: {
    undo: [] as any[],
    present: {},
    redo: [] as any[],
    canUndo: false,
    canRedo: false
  },
  reducers: {
    initialTranscript: (state, action) => {
      if (!isEmpty(state.present))
        // save last value when change box
        state.undo.push(state.present);
      state.present = { ...action.payload };
    },

    presentTranscript: (state, action) => {
      state.undo.push(state.present);
      state.present = { ...action.payload };
    },

    undoTranscript: (state) => {
      if (state.undo.length) {
        state.redo.push(state.present);
        state.present = state.undo?.pop();
      }
    },

    redoTranscript: (state) => {
      if (state.redo.length) {
        state.undo.push(state.present);
        state.present = state.redo?.pop();
      }
    },
    setCanUndo: (state, action) => {
        state.canUndo = action.payload;
    },
    setCanRedo: (state, action) => {
        state.canRedo = action.payload;
    }
  },
});

export const {
  initialTranscript,
  presentTranscript,
  undoTranscript,
  redoTranscript,
  setCanUndo,
  setCanRedo
} = transcriptSlice.actions;

export const transcriptReducer = transcriptSlice.reducer;
