import {
  INodeData,
  IRichTextInput,
} from 'components/shared/RichTextEditor/rich-text.model';
import {
  isArray,
  isEmpty,
  isNil,
  isString,
  last,
  toNumber,
  uniq,
  uniqBy,
  upperFirst,
} from 'lodash';
import { Node, Transforms } from 'slate';
import { CaptionElement, CustomElement } from 'types/slate-custom-types';
import { ISpeakerRaw, SegmentType } from 'utils/models/transcript.model';
import { v4 } from 'uuid';
import {
  between,
  countWords,
  formatRaw,
  getJoinedSegments,
  isPunc,
} from './../components/VideoPlayer/Transcription/MediaUtilities';
import { ICaption, IKeyItem, IOccurrence, IWebVtt } from './models';
import { IParagraphRaw } from './models/transcript.model';
import { REGEX } from './regex';
import { customToast } from './toast.util';
import { removeSpaceChars } from './utils';

export const isExistedTerm = (term: string, currentCustomTerm?: IKeyItem[]) => {
  return currentCustomTerm?.some((item) => item.keyword === term);
};

export const createCustomLayer = (
  itemArray: string[],
  transcriptArray: ICaption[],
  caption?: IRichTextInput[],
  currentCustomTerm?: IKeyItem[],
): IKeyItem[] => {
  const newItem = itemArray
    .filter((item) => !isExistedTerm(item, currentCustomTerm))
    .map((t) => t?.trim());

  if (newItem.length < itemArray.length) {
    customToast.error('This terms has already existed');
  }

  return newItem.map((item) => {
    const occurrences = getOccurrences(item, transcriptArray, caption);
    return {
      id: v4(),
      keyword: item,
      custom: item,
      relevance: 1,
      mentions: [{ speakerName: 'unknown', occurrences }],
    };
  });
};

export const createManualKeyword = (
  keyword: string,
  transcriptArray: ICaption[],
  caption?: IRichTextInput[],
): IKeyItem => {
  let occurrences: any = [];
  occurrences = getOccurrences(keyword, transcriptArray, caption);
  return {
    id: v4(),
    keyword: keyword,
    custom: keyword,
    mentions: [{ speakerName: 'unknown', occurrences }],
    relevance: 1, // Default as Human Edited
    modified: new Date(),
  };
};

export const getOccurrences = (
  newKeyword: string,
  transcriptArray: ICaption[],
  caption?: IRichTextInput[],
): IOccurrence[] => {
  if (!transcriptArray?.length) return [];
  let occurrences: IOccurrence[] = [];
  let occId: any = [];
  let tempId: any = [];
  let compareIndex = 0;
  const keywordArray = newKeyword.split(/\s+/g);
  if (keywordArray.length === 1) {
    caption?.forEach((speech, speechIndex) => {
      // One word only
      speech?.children?.forEach((word, index) => {
        const wordSplit = word.text.split(' ');
        if (wordSplit.length === 1) {
          if (
            word?.text?.toLowerCase().trim() ===
            newKeyword?.toLowerCase().trim()
          ) {
            occId.push([speechIndex, index]);
          }
        }
        // human edited
        else {
          wordSplit.forEach((wordItem, wordIndex) => {
            if (
              wordItem?.toLowerCase().trim() ===
              newKeyword?.toLowerCase().trim()
            ) {
              occId.push([speechIndex, index + wordIndex]);
            }
          });
        }
      });
    });
  } else {
    transcriptArray?.forEach((cap, capIndex) => {
      if (
        RegExp(`\\b${newKeyword.toLowerCase()}\\b`).test(
          cap.children[0].text.toLowerCase(),
        )
      ) {
        let speechArray =
          cap?.children?.[0].text?.toLowerCase()?.split(REGEX.SPLIT_WORD) ?? [];
        speechArray = speechArray.filter((s) => s?.trim());

        speechArray.forEach((speech, index) => {
          if (
            speech.toLowerCase() === keywordArray[compareIndex].toLowerCase()
          ) {
            if (compareIndex === 0) {
              tempId.push([capIndex, index]);
            }
            compareIndex += 1;
            if (compareIndex === keywordArray.length) {
              occId = occId.concat(tempId);
              tempId = [];
              compareIndex = 0;
            }
          } else {
            compareIndex = 0;
            tempId = [];
          }
        });
      }
    });
  }
  if (caption) {
    occId.forEach((occ: any) => {
      occurrences.push({
        s: caption?.[occ[0]]?.children[occ[1]]?.data.dataStart,
        // e: caption?.[occ[0]]?.children[occ[1]]?.data.dataEnd
      });
    });
  }

  return occurrences;
};

export const replaceText = (
  pathFirst: number,
  pathSecond: number,
  textReplace: string,
) => {
  const editor = window.Editor as any;

  if (pathSecond + 1 < editor?.children[pathFirst]?.children?.length) {
    const newPath = [pathFirst, pathSecond + 1];
    const word = (Node.get(window.Editor, newPath) as any).text.trim();
    const check = word.match(REGEX.NON_ALPHA);
    if (!check) {
      textReplace += ' ';
    }
  } else {
    textReplace += ' ';
  }
  Transforms.insertText(window.Editor, `${textReplace}`, {
    at: [pathFirst, pathSecond],
  });
};

export const getSearchOccurrenceArray = (
  speech: IWebVtt,
  newKeyword: string,
  regexOption: string,
) => {
  const wordOccurrences =
    removeSpaceChars(speech?.text)?.match(RegExp(newKeyword, regexOption)) ||
    [];
  return wordOccurrences;
};

export const getOccList = (
  item: IKeyItem | null | undefined,
): IOccurrence[] => {
  return item?.mentions?.[0]?.occurrences ?? [];
};

export const toRawPara = (paragraphs: CustomElement[]): IParagraphRaw[] => {
  return formatRaw(
    paragraphs.map((p) => ({
      startTime: p?.data?.start,
      endTime: p?.data?.end,
      speaker: getSpeaker(p?.data?.speaker),
    })),
  );
};

export const toSlateStructure = (
  sourceList: CustomElement[],
  destList: IParagraphRaw[],
  type: 'paragraph' | 'caption',
  firstRun = false,
) => {
  if (type === 'paragraph' && !firstRun) {
    destList = breakParaByNewSpeaker(sourceList, destList);
  }
  const destLength = destList.length;
  const finalPara: CustomElement[] = destList.map(
    (p: IParagraphRaw, id: number) => {
      let para: CustomElement = {
        id: id + 1,
        type: type,
        data: {
          speaker: p?.speaker ?? 'Unknown',
          start: p.startTime,
          end: p.endTime,
        },
        children: [],
      };

      sourceList.forEach((cap: CustomElement, cIndex) => {
        const c: CustomElement = { ...cap };
        const pStart = p?.startTime;
        const pEnd = p?.endTime;
        // const resultLength = destList.length - 1;

        // Update new Speaker if changed on Paragraph
        if (
          type === 'caption' &&
          c?.data?.speaker !== p?.speaker &&
          c?.data?.start <= p?.startTime &&
          c?.data?.end >= p?.endTime
        ) {
          para.data = { ...para.data, speaker: c.data.speaker };
        }

        const lastCaption = sourceList?.[cIndex - 1];
        const nextCaption = sourceList?.[cIndex + 1];
        c.children.forEach((word: CustomElement) => {
          const wStart = word?.data?.dataStart;
          const wEnd = word?.data?.dataEnd;
          // let lastPara = destList?.[id - 1 < 0 ? 0 : id - 1];
          // let nextPara =
          //   destList?.[id + 1 > resultLength ? resultLength : id + 1];

          /*
            - Has to check if it's the last line of Transcript
           */
          if (wStart >= pStart && (wEnd <= pEnd || id === destLength - 1)) {
            if (wEnd <= lastCaption?.data?.end && !isNil(nextCaption)) {
              return;
            }

            const isPuncWord = wStart === wEnd && isPunc(word?.text);
            const isPuncFirstCaption =
              isPuncWord && wStart === para?.data?.start;

            // Ignore func if it's the first word of the caption
            if (isPuncFirstCaption) {
              return;
            }
            // if (wStart === wEnd) {
            //   // PUNC AT THE END OR START
            //   if (
            //     (wStart === lastPara?.endTime ||
            //     wEnd === nextPara?.startTime) && word?.text.length === 1 // if word not punc
            //   ) {
            //     // if (isEmpty(para?.children)) {
            //     //   return;
            //     // }
            //     return;
            //   }
            // }
            para.children.push(word);
          }
        });

        // INSERT a space on join
        const lastWord: INodeData | undefined = last(para.children);
        if (
          lastWord?.text &&
          !lastWord?.text.endsWith(' ') &&
          !isEmpty(para?.children)
        ) {
          para.children[para.children.length - 1] = {
            ...lastWord,
            text: lastWord.text + ' ',
          };
        }

        // Prevent error of empty children
        if (isEmpty(para?.children)) {
          // return;
          para.children.push({ text: '' });
        }
      });
      return para;
    },
  );
  const isBlankText = (para: CustomElement) =>
    para?.children?.length === 1 && isEmpty(para?.children?.[0]?.text);

  return finalPara.filter((para) => !isBlankText(para)) ?? [];
};

export const extractParaSpeaker = (sourceList: CaptionElement[]) => {
  try {
    const paraBySpeaker: any = [];
    sourceList.forEach((tran, index, orig) => {
      const lastSpeaker = orig?.[index - 1]?.data?.speaker;
      const curSpeaker = tran?.data?.speaker;
      const lastIndex = paraBySpeaker.length - 1;
      if (
        curSpeaker !== lastSpeaker ||
        toNumber(tran?.id) - toNumber(orig?.[index - 1]?.id) > 1
      ) {
        paraBySpeaker.push({
          speaker: curSpeaker,
          startTime: tran?.data?.start,
          endTime: tran?.data?.end,
        });
      } else {
        paraBySpeaker[lastIndex] = {
          ...paraBySpeaker[lastIndex],
          endTime: tran?.data?.end,
        };
      }
    });
    return paraBySpeaker;
  } catch {
    return null;
  }
};

export const getSpeakersFromCaption = (
  captions?: IRichTextInput[] | null,
): any[] => {
  if (!captions || !isArray(captions)) return [];

  const names = captions.map((caption) => {
    const speaker = caption?.data?.speaker ?? '';

    return formatSpeaker(speaker);
  });

  const uniqNames = uniq(names);

  return uniqNames.map((name, id) => ({ id, name }));
};

export const formatSpeaker = (name: string | null | undefined): string => {
  if (!name || !isString(name)) return 'Unknown';

  // if (name.length === 1) {
  //   return `Speaker ${name}`;
  // } else {
  return upperFirst(name) ?? 'Unknown';
  // }
};

export const getSpeaker = (speakerObject: any) => {
  return formatSpeaker(
    isArray(speakerObject) && !isEmpty(speakerObject)
      ? speakerObject[0]
      : speakerObject,
  );
};

export const checkInUsed = (
  speakers: ISpeakerRaw[],
  source: CustomElement[],
): ISpeakerRaw[] => {
  if (!speakers) return [];
  const result = uniqBy(
    speakers.map((sp) => {
      const occurrences = source
        .filter(
          (caption: CustomElement) =>
            caption?.data?.speaker?.toLowerCase() === sp?.name?.toLowerCase(),
        )
        ?.map(
          (caption: CustomElement) =>
            ({
              s: caption?.data?.start,
              e: caption?.data?.end,
              wordCount: countWords(
                caption?.children?.map((c: INodeData) => c?.text).join(''),
              ),
            } as IOccurrence),
        );
      const inUsed = !isEmpty(occurrences);

      return { ...sp, inUsed, occurrences: getJoinedSegments(occurrences) };
    }),
    'name',
  );

  return result;
};

export const normalizeTranscript = (
  transcripts: CustomElement[],
): CustomElement[] => {
  return transcripts?.map((t) => {
    const tran = { ...t };
    tran.children = tran.children?.filter((node: any) => {
      return !isEmpty(node.text) && node?.data;
    });
    return tran;
  });
};

export const toSlateLineText = (trans: CustomElement[]) => {
  return trans.map((tran) => ({
    ...tran,
    children: [
      {
        text: Node.string(tran),
        data: {
          dataStart: tran?.data?.start,
          dataEnd: tran?.data?.end,
        },
      },
    ],
  }));
};

/* IF USER CHANGE SPEAKER OF CAPTION. IT SHOULD BREAD PARAGRAPH ALSO */
export const breakParaByNewSpeaker = (
  fromCaptions: CaptionElement[],
  toParas: IParagraphRaw[],
) => {
  let breakPara: CaptionElement[] = [];
  let finalSplit: any[] = [];
  /* GET LIST OF SPEAKER CHANGE */

  fromCaptions.forEach((fromCaption) => {
    const from = fromCaption.data;
    for (const dest of toParas) {
      if (
        getSpeaker(dest.speaker) !== getSpeaker(from.speaker)
        // between(from.start, dest.startTime, dest.endTime) &&
        // between(from.end, dest.startTime, dest.endTime)
      ) {
        breakPara.push(fromCaption);
        return;
      }
    }
  });

  /* GROUP TOGETHER SPEAKER SHOULD BE SPLIT */
  const shouldSplits: any[] = extractParaSpeaker(breakPara);

  toParas.forEach((item, index) => {
    finalSplit.push({ ...item, speaker: getSpeaker(item.speaker) });
    shouldSplits.forEach((split) => {
      if (
        getSpeaker(split.speaker) !== getSpeaker(item.speaker) &&
        between(item.startTime, split.startTime, split.endTime) &&
        (index === toParas.length - 1 ||
          between(item.endTime, split.startTime, split.endTime))
      ) {
        finalSplit[finalSplit.length - 1].speaker = split.speaker;
      }
      if (
        getSpeaker(split.speaker) !== getSpeaker(item.speaker) &&
        between(split.startTime, item.startTime, item.endTime) &&
        between(split.endTime, item.startTime, item.endTime)
      ) {
        finalSplit[finalSplit.length - 1].endTime = split.startTime;
        finalSplit.push(split);
        finalSplit.push({
          startTime: split.endTime,
          endTime: item.endTime,
          speaker: item.speaker,
        });
      }
      if (
        getSpeaker(split.speaker) !== getSpeaker(item.speaker) &&
        between(item.startTime, split.startTime, split.endTime) &&
        between(split.endTime, item.startTime, item.endTime)
      ) {
        finalSplit[finalSplit.length - 1].endTime = split.endTime;
        finalSplit[finalSplit.length - 1].speaker = split.speaker;
        finalSplit.push({
          startTime: split.endTime,
          endTime: item.endTime,
          speaker: item.speaker,
        });
      }
    });
    finalSplit = finalSplit.filter((s) => s?.startTime !== s?.endTime);
  });

  /* HANDLE RENAMING CASE */
  return finalSplit;
};

export const getNameSegment = (segmentType: SegmentType): string => {
  switch (segmentType) {
    case SegmentType.AD_BREAK:
      return 'Ad breaks';
    case SegmentType.TRAFFIC:
      return 'Traffic';
    case SegmentType.MUSIC:
      return 'Music';
    case SegmentType.USER_DEFINED:
      return 'User defined';
    default:
      return 'Unknown';
  }
};
