/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { Column, Row, useSortBy, useTable } from 'react-table';
import { ReactComponent as AscendingArrow } from 'assets/Icons/Ascending_Arrow.svg';
import { ReactComponent as DescendingArrow } from 'assets/Icons/Descending_Arrow.svg';
import { isNil } from 'lodash';
import Loader from 'components/loader/loader';
import { CSSProperties } from 'react';

interface IProps<T> {
  // @ts-ignore
  columns: (Column<T> & { styles?: CSSProperties })[];
  data: T[];
  // @ts-ignore
  children: (row: Row<T>) => unknown;
  maxRowShown?: number;
  isLoading?: boolean;
  isFixedTable?: boolean;
}

export const SortTable = <T extends {}>({
  columns = [],
  data = [],
  maxRowShown,
  isFixedTable,
  ...props
}: IProps<T>) => {
  const tableInstance = useTable({ columns, data }, useSortBy);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  return (
    <div>
      <table {...getTableProps()} css={[isFixedTable && tw`table-fixed`]}>
        <thead>
          {headerGroups.map((headerGroup, groupIndex) => (
            // Apply the header row props
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={groupIndex}
              css={[
                !isNil(maxRowShown) &&
                  data?.length > maxRowShown &&
                  tw`relative right-2.5`,
              ]}
            >
              {headerGroup.headers.map((column, index) => (
                // Apply the header cell props
                <th
                  // @ts-ignore
                  width={column.width}
                  {...column.getHeaderProps(column?.getSortByToggleProps())}
                  key={index}
                  tw="whitespace-normal cursor-pointer"
                  // @ts-ignore
                  style={column.styles}
                  css={[index === 0 && tw`pl-3!`]}
                >
                  {column.render('Header')}
                  {column.isSorted &&
                    (column.isSortedDesc ? (
                      <DescendingArrow tw="ml-2 relative top[-2px] opacity-60" />
                    ) : (
                      <AscendingArrow tw="ml-2 relative top[-2px] opacity-60" />
                    ))}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {!props?.isLoading &&
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return props.children(row);
              // return (
              //   // Apply the row props
              //   <tr {...row.getRowProps()}>
              //     {row.cells.map((cell) => {
              //       // Apply the cell props
              //       return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              //     })}
              //   </tr>
              // );
            })}
        </tbody>
      </table>

      {props?.isLoading && (
        <div tw="w-full flex justify-center">
          <span tw="width[6rem]">
            <Loader />
          </span>
        </div>
      )}
    </div>
  );
};
