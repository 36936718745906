/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as Back } from 'assets/Icons/Back.svg';
import { ReactComponent as Next } from 'assets/Icons/Right.svg';
import {
  disabledCss,
  hoverDropShadow,
  roundedRippleHoverCss,
} from 'components/shared/twin.styles';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { resetEmbedFocusTerm } from 'slices/embed.slice';
import { layerSelector, nextLayer, prevLayer } from 'slices/layers.slice';
import { getLayerName } from 'utils/utils';
import { EmbedTermSwitcher } from '../../shared/EmbedTermSwitcher';
import { EmbedTranscriptFollowToggle } from '../../shared/EmbedTranscriptFollowToggle';

type Props = {};

export const EmbedLayer = (props: Props) => {
  const dispatch = useDispatch();

  const layers = useSelector((state: RootState) => state.layers);
  const currentLayer = useSelector(layerSelector);

  const canChangeLayer = layers.layerLength > 1;

  useEffect(() => {
    dispatch(resetEmbedFocusTerm());
  }, [layers.currentLayer]);

  const navigatePrevLayer = () => {
    dispatch(prevLayer());
  };

  const navigateNextLayer = () => {
    dispatch(nextLayer());
  };

  return (
    <div tw="h-[4rem] flex justify-between items-center text-15 text-sonnant-grey-6 font-medium select-none">
      <div tw="flex items-center">
        <div css={[!canChangeLayer && disabledCss]}>
          <span
            tw="flex cursor-pointer"
            css={[roundedRippleHoverCss]}
            onClick={navigatePrevLayer}
          >
            <Back css={[hoverDropShadow(0.2)]} />
          </span>
        </div>

        <div tw="uppercase min-w-[13.5rem] text-center">
          {getLayerName(layers.currentLayer)}
        </div>

        <div css={[!canChangeLayer && disabledCss]}>
          <span
            tw="flex cursor-pointer"
            css={[roundedRippleHoverCss]}
            onClick={navigateNextLayer}
          >
            <Next css={[hoverDropShadow(0.2)]} />
          </span>
        </div>
      </div>

      {currentLayer.isTranscript ? (
        <EmbedTranscriptFollowToggle />
      ) : (
        <EmbedTermSwitcher />
      )}
    </div>
  );
};
