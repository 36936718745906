import { get } from 'lodash';
import {
  RegisterOptions,
  DeepMap,
  FieldError,
  UseFormRegister,
  Path,
  FieldValues,
} from 'react-hook-form';
import { Notification } from '../UiControls/notification/Notification';

export type FormInputProps<TFormValues extends FieldValues> = {
  name: Path<TFormValues>;
  rules?: RegisterOptions;
  register?: UseFormRegister<TFormValues>;
  errors?: Partial<DeepMap<TFormValues, FieldError>>;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'name'>;

export const InputHookForm = <TFormValues extends Record<string, unknown>>({
  name,
  register,
  rules,
  errors,
  className,
  ...props
}: FormInputProps<TFormValues>): JSX.Element => {
  // If the name is in a FieldArray, it will be 'fields.index.fieldName' and errors[name] won't return anything, so we are using lodash get
  const errorMsg = get(errors, name)?.message;
  const hasError = !!(errors && errorMsg);

  return (
    <div aria-live="polite">
      <input
        name={name}
        aria-invalid={hasError}
        {...props}
        {...(register && register(name, rules))}
      />
      {hasError && (
        <Notification type={hasError ? 'error' : null} message={errorMsg} />
      )}
    </div>
  );
};
