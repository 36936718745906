/** @jsxImportSource @emotion/react */
import 'twin.macro';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import { AdvancedFilterType } from 'utils/enum';
import { Ripple } from '@rmwc/ripple';

import { capitalize, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setSearchParams } from 'slices/advanced-search.slice';

type filterItemProps = {
  textEnum: string;
  typeEnum: AdvancedFilterType;
};

export const FilterOption = (props: filterItemProps) => {
  const { textEnum } = props;

  const dispatch = useDispatch();

  const advancedSearch = useSelector(
    (state: RootState) => state.advancedSearch,
  );

  const handleClickItem = () => {
    const payload = {
      type: props.typeEnum,
      data: props?.textEnum,
    };
    dispatch(setSearchParams(payload));
  };

  const checkSelected = (): boolean => {
    const { typeEnum, textEnum } = props;
    const { searchParams } = advancedSearch;

    if (typeEnum === AdvancedFilterType.DATE) {
      return isEqual(textEnum, searchParams.uploadDate);
    } else if (typeEnum === AdvancedFilterType.TYPE) {
      return isEqual(textEnum, searchParams.type);
    } else if (typeEnum === AdvancedFilterType.DURATION) {
      return isEqual(textEnum, searchParams.duration);
    } else if (typeEnum === AdvancedFilterType.TITLE) {
      return isEqual(textEnum, searchParams.searchBy);
    }

    return false;
  };

  return (
    <>
      <Ripple
        tw="relative block rounded-md px-1.5"
        onClick={handleClickItem}
        css={checkSelected() && selectedStyles}
      >
        <span tw="whitespace-nowrap">{capitalize(textEnum)}</span>
      </Ripple>
    </>
  );
};

const selectedStyles = css`
  ${tw`bg-sonnant-keyword cursor-pointer! font-medium`}
`;
