/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { isNil, upperCase } from 'lodash';
import { CheckmarkIcon, toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { useEffectOnce, useSearchParam } from 'react-use';

export const usePaymentMessage = () => {
  const type = useSearchParam('type');
  const plan = useSearchParam('plan');
  const quantity = useSearchParam('quantity');
  const users = useSearchParam('users');

  const history = useHistory();

  useEffectOnce(() => {
    if (!type || !plan) return;

    // PAYG Plan Messages
    if (plan === 'payg' && type === 'success' && quantity) {
      toast(() => <CustomMessage type={type} plan={plan} quantity={quantity} />);
    }

    // Standard Plan Messages
    if (plan === 'standard' && type === 'success') {
      toast(() => <CustomMessage type={type} plan={plan} users={users} />);
    }

    // Clear Query String URL
    const { pathname, hash } = window.location;
    history.push(pathname + hash);
  });

  return { type, plan };
};

export const CustomMessage = ({
  type,
  plan,
  quantity,
  users,
}: {
  type: 'success' | 'cancel' | 'update';
  plan: string;
  quantity?: number | string;
  users?: any;
}) => (
  <div tw="flex items-center text-sonnant-dark">
    <div tw="mr-6">
      <CheckmarkIcon />
    </div>
    <div>
      <div tw="font-medium font-size[1.6rem]">
        {type === 'update' ? 'Update successful' : 'Payment successful'}
      </div>
      <div tw="font-size[1.4rem] mt-1">
        {/* {!isNil(quantity) && (
          <div>
            Added <span tw="font-medium">{toNumber(quantity) * 60}</span>{' '}
            minutes
          </div>
        )} */}
        {!isNil(users) && (
          <div>
            Licensed users: <span tw="font-medium">{users}</span>
          </div>
        )}
        <div>
          Current plan: <span tw="font-medium">{upperCase(plan)}</span>
        </div>
      </div>
    </div>
  </div>
);
