import { IABMock } from 'utils/models';

export const iabMock: IABMock = {
  recommendedCategories: [
    {
      id: '280',
      parentId: '274',
      name: 'Home Improvement',
      subItems: [],
    },
    {
      id: '533',
      parentId: '483',
      name: 'Soccer',
      subItems: [],
    },
  ],
  allCategories: [
    {
      id: '1',
      parentId: '',
      name: 'Automotive',
      tier: 1,
      subItems: [
        {
          id: '2',
          parentId: '1',
          name: 'Auto Body Styles',
          tier: 2,
          subItems: [
            {
              id: '3',
              parentId: '2',
              name: 'Commercial Trucks',
              tier: 3,
              subItems: [],
            },
            {
              id: '4',
              parentId: '2',
              name: 'Sedan',
              tier: 3,
              subItems: [],
            },
            {
              id: '5',
              parentId: '2',
              name: 'Station Wagon',
              tier: 3,
              subItems: [],
            },
            {
              id: '6',
              parentId: '2',
              name: 'SUV',
              tier: 3,
              subItems: [],
            },
            {
              id: '7',
              parentId: '2',
              name: 'Van',
              tier: 3,
              subItems: [],
            },
            {
              id: '8',
              parentId: '2',
              name: 'Convertible',
              tier: 3,
              subItems: [],
            },
            {
              id: '9',
              parentId: '2',
              name: 'Coupe',
              tier: 3,
              subItems: [],
            },
            {
              id: '10',
              parentId: '2',
              name: 'Crossover',
              tier: 3,
              subItems: [],
            },
            {
              id: '11',
              parentId: '2',
              name: 'Hatchback',
              tier: 3,
              subItems: [],
            },
            {
              id: '12',
              parentId: '2',
              name: 'Microcar',
              tier: 3,
              subItems: [],
            },
            {
              id: '13',
              parentId: '2',
              name: 'Minivan',
              tier: 3,
              subItems: [],
            },
            {
              id: '14',
              parentId: '2',
              name: 'Off-Road Vehicles',
              tier: 3,
              subItems: [],
            },
            {
              id: '15',
              parentId: '2',
              name: 'Pickup Trucks',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '16',
          parentId: '1',
          name: 'Auto Type',
          tier: 2,
          subItems: [
            {
              id: '17',
              parentId: '16',
              name: 'Budget Cars',
              tier: 3,
              subItems: [],
            },
            {
              id: '18',
              parentId: '16',
              name: 'Certified Pre-Owned Cars',
              tier: 3,
              subItems: [],
            },
            {
              id: '19',
              parentId: '16',
              name: 'Classic Cars',
              tier: 3,
              subItems: [],
            },
            {
              id: '20',
              parentId: '16',
              name: 'Concept Cars',
              tier: 3,
              subItems: [],
            },
            {
              id: '21',
              parentId: '16',
              name: 'Driverless Cars',
              tier: 3,
              subItems: [],
            },
            {
              id: '22',
              parentId: '16',
              name: 'Green Vehicles',
              tier: 3,
              subItems: [],
            },
            {
              id: '23',
              parentId: '16',
              name: 'Luxury Cars',
              tier: 3,
              subItems: [],
            },
            {
              id: '24',
              parentId: '16',
              name: 'Performance Cars',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '25',
          parentId: '1',
          name: 'Car Culture',
          tier: 2,
          subItems: [],
        },
        {
          id: '26',
          parentId: '1',
          name: 'Dash Cam Videos',
          tier: 2,
          subItems: [],
        },
        {
          id: '27',
          parentId: '1',
          name: 'Motorcycles',
          tier: 2,
          subItems: [],
        },
        {
          id: '28',
          parentId: '1',
          name: 'Road-Side Assistance',
          tier: 2,
          subItems: [],
        },
        {
          id: '29',
          parentId: '1',
          name: 'Scooters',
          tier: 2,
          subItems: [],
        },
        {
          id: '30',
          parentId: '1',
          name: 'Auto Buying and Selling',
          tier: 2,
          subItems: [],
        },
        {
          id: '31',
          parentId: '1',
          name: 'Auto Insurance',
          tier: 2,
          subItems: [],
        },
        {
          id: '32',
          parentId: '1',
          name: 'Auto Parts',
          tier: 2,
          subItems: [],
        },
        {
          id: '33',
          parentId: '1',
          name: 'Auto Recalls',
          tier: 2,
          subItems: [],
        },
        {
          id: '34',
          parentId: '1',
          name: 'Auto Repair',
          tier: 2,
          subItems: [],
        },
        {
          id: '35',
          parentId: '1',
          name: 'Auto Safety',
          tier: 2,
          subItems: [],
        },
        {
          id: '36',
          parentId: '1',
          name: 'Auto Shows',
          tier: 2,
          subItems: [],
        },
        {
          id: '37',
          parentId: '1',
          name: 'Auto Technology',
          tier: 2,
          subItems: [
            {
              id: '38',
              parentId: '37',
              name: 'Auto Infotainment Technologies',
              tier: 3,
              subItems: [],
            },
            {
              id: '39',
              parentId: '37',
              name: 'Auto Navigation Systems',
              tier: 3,
              subItems: [],
            },
            {
              id: '40',
              parentId: '37',
              name: 'Auto Safety Technologies',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '41',
          parentId: '1',
          name: 'Auto Rentals',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '42',
      parentId: '',
      name: 'Books and Literature',
      tier: 1,
      subItems: [
        {
          id: '43',
          parentId: '42',
          name: 'Art and Photography',
          tier: 2,
          subItems: [],
        },
        {
          id: '46',
          parentId: '42',
          name: 'Comics and Graphic Novels',
          tier: 2,
          subItems: [],
        },
        {
          id: '48',
          parentId: '42',
          name: 'Fiction',
          tier: 2,
          subItems: [],
        },
        {
          id: '49',
          parentId: '42',
          name: 'Poetry',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '52',
      parentId: '',
      name: 'Business and Finance',
      tier: 1,
      subItems: [
        {
          id: '53',
          parentId: '52',
          name: 'Business',
          tier: 2,
          subItems: [
            {
              id: '54',
              parentId: '53',
              name: 'Business Accounting & Finance',
              tier: 3,
              subItems: [],
            },
            {
              id: '55',
              parentId: '53',
              name: 'Human Resources',
              tier: 3,
              subItems: [],
            },
            {
              id: '56',
              parentId: '53',
              name: 'Large Business',
              tier: 3,
              subItems: [],
            },
            {
              id: '57',
              parentId: '53',
              name: 'Logistics',
              tier: 3,
              subItems: [],
            },
            {
              id: '58',
              parentId: '53',
              name: 'Marketing and Advertising',
              tier: 3,
              subItems: [],
            },
            {
              id: '59',
              parentId: '53',
              name: 'Sales',
              tier: 3,
              subItems: [],
            },
            {
              id: '60',
              parentId: '53',
              name: 'Small and Medium-sized Business',
              tier: 3,
              subItems: [],
            },
            {
              id: '61',
              parentId: '53',
              name: 'Startups',
              tier: 3,
              subItems: [],
            },
            {
              id: '62',
              parentId: '53',
              name: 'Business Administration',
              tier: 3,
              subItems: [],
            },
            {
              id: '63',
              parentId: '53',
              name: 'Business Banking & Finance',
              tier: 3,
              subItems: [
                {
                  id: '64',
                  parentId: '63',
                  name: 'Angel Investment',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '65',
                  parentId: '63',
                  name: 'Bankruptcy',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '66',
                  parentId: '63',
                  name: 'Business Loans',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '67',
                  parentId: '63',
                  name: 'Debt Factoring & Invoice Discounting',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '68',
                  parentId: '63',
                  name: 'Mergers and Acquisitions',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '69',
                  parentId: '63',
                  name: 'Private Equity',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '70',
                  parentId: '63',
                  name: 'Sale & Lease Back',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '71',
                  parentId: '63',
                  name: 'Venture Capital',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '72',
              parentId: '53',
              name: 'Business I.T.',
              tier: 3,
              subItems: [],
            },
            {
              id: '73',
              parentId: '53',
              name: 'Business Operations',
              tier: 3,
              subItems: [],
            },
            {
              id: '74',
              parentId: '53',
              name: 'Consumer Issues',
              tier: 3,
              subItems: [
                {
                  id: '75',
                  parentId: '74',
                  name: 'Recalls',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '76',
              parentId: '53',
              name: 'Executive Leadership & Management',
              tier: 3,
              subItems: [],
            },
            {
              id: '77',
              parentId: '53',
              name: 'Government Business',
              tier: 3,
              subItems: [],
            },
            {
              id: '78',
              parentId: '53',
              name: 'Green Solutions',
              tier: 3,
              subItems: [],
            },
            {
              id: '79',
              parentId: '53',
              name: 'Business Utilities',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '80',
          parentId: '52',
          name: 'Economy',
          tier: 2,
          subItems: [
            {
              id: '81',
              parentId: '80',
              name: 'Commodities',
              tier: 3,
              subItems: [],
            },
            {
              id: '82',
              parentId: '80',
              name: 'Currencies',
              tier: 3,
              subItems: [],
            },
            {
              id: '83',
              parentId: '80',
              name: 'Financial Crisis',
              tier: 3,
              subItems: [],
            },
            {
              id: '84',
              parentId: '80',
              name: 'Financial Reform',
              tier: 3,
              subItems: [],
            },
            {
              id: '85',
              parentId: '80',
              name: 'Financial Regulation',
              tier: 3,
              subItems: [],
            },
            {
              id: '86',
              parentId: '80',
              name: 'Gasoline Prices',
              tier: 3,
              subItems: [],
            },
            {
              id: '87',
              parentId: '80',
              name: 'Housing Market',
              tier: 3,
              subItems: [],
            },
            {
              id: '88',
              parentId: '80',
              name: 'Interest Rates',
              tier: 3,
              subItems: [],
            },
            {
              id: '89',
              parentId: '80',
              name: 'Job Market',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '90',
          parentId: '52',
          name: 'Industries',
          tier: 2,
          subItems: [
            {
              id: '91',
              parentId: '90',
              name: 'Advertising Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '92',
              parentId: '90',
              name: 'Education industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '93',
              parentId: '90',
              name: 'Entertainment Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '94',
              parentId: '90',
              name: 'Environmental Services Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '95',
              parentId: '90',
              name: 'Financial Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '96',
              parentId: '90',
              name: 'Food Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '97',
              parentId: '90',
              name: 'Healthcare Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '98',
              parentId: '90',
              name: 'Hospitality Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '99',
              parentId: '90',
              name: 'Information Services Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '100',
              parentId: '90',
              name: 'Legal Services Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '101',
              parentId: '90',
              name: 'Logistics and Transportation Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '102',
              parentId: '90',
              name: 'Agriculture',
              tier: 3,
              subItems: [],
            },
            {
              id: '103',
              parentId: '90',
              name: 'Management Consulting Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '104',
              parentId: '90',
              name: 'Manufacturing Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '105',
              parentId: '90',
              name: 'Mechanical and Industrial Engineering Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '106',
              parentId: '90',
              name: 'Media Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '107',
              parentId: '90',
              name: 'Metals Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '108',
              parentId: '90',
              name: 'Non-Profit Organizations',
              tier: 3,
              subItems: [],
            },
            {
              id: '109',
              parentId: '90',
              name: 'Pharmaceutical Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '110',
              parentId: '90',
              name: 'Power and Energy Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '111',
              parentId: '90',
              name: 'Publishing Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '112',
              parentId: '90',
              name: 'Real Estate Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '113',
              parentId: '90',
              name: 'Apparel Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '114',
              parentId: '90',
              name: 'Retail Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '115',
              parentId: '90',
              name: 'Technology Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '116',
              parentId: '90',
              name: 'Telecommunications Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '117',
              parentId: '90',
              name: 'Automotive Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '118',
              parentId: '90',
              name: 'Aviation Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '119',
              parentId: '90',
              name: 'Biotech and Biomedical Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '120',
              parentId: '90',
              name: 'Civil Engineering Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '121',
              parentId: '90',
              name: 'Construction Industry',
              tier: 3,
              subItems: [],
            },
            {
              id: '122',
              parentId: '90',
              name: 'Defense Industry',
              tier: 3,
              subItems: [],
            },
          ],
        },
      ],
    },
    {
      id: '123',
      parentId: '',
      name: 'Careers',
      tier: 1,
      subItems: [
        {
          id: '124',
          parentId: '123',
          name: 'Apprenticeships',
          tier: 2,
          subItems: [],
        },
        {
          id: '125',
          parentId: '123',
          name: 'Career Advice',
          tier: 2,
          subItems: [],
        },
        {
          id: '126',
          parentId: '123',
          name: 'Career Planning',
          tier: 2,
          subItems: [],
        },
        {
          id: '127',
          parentId: '123',
          name: 'Job Search',
          tier: 2,
          subItems: [
            {
              id: '128',
              parentId: '127',
              name: 'Job Fairs',
              tier: 3,
              subItems: [],
            },
            {
              id: '129',
              parentId: '127',
              name: 'Resume Writing and Advice',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '130',
          parentId: '123',
          name: 'Remote Working',
          tier: 2,
          subItems: [],
        },
        {
          id: '131',
          parentId: '123',
          name: 'Vocational Training',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '132',
      parentId: '',
      name: 'Education',
      tier: 1,
      subItems: [
        {
          id: '133',
          parentId: '132',
          name: 'Adult Education',
          tier: 2,
          subItems: [],
        },
        {
          id: '134',
          parentId: '132',
          name: 'Private School',
          tier: 2,
          subItems: [],
        },
        {
          id: '135',
          parentId: '132',
          name: 'Secondary Education',
          tier: 2,
          subItems: [],
        },
        {
          id: '136',
          parentId: '132',
          name: 'Special Education',
          tier: 2,
          subItems: [],
        },
        {
          id: '137',
          parentId: '132',
          name: 'College Education',
          tier: 2,
          subItems: [
            {
              id: '138',
              parentId: '137',
              name: 'College Planning',
              tier: 3,
              subItems: [],
            },
            {
              id: '139',
              parentId: '137',
              name: 'Postgraduate Education',
              tier: 3,
              subItems: [
                {
                  id: '140',
                  parentId: '139',
                  name: 'Professional School',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '141',
              parentId: '137',
              name: 'Undergraduate Education',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '142',
          parentId: '132',
          name: 'Early Childhood Education',
          tier: 2,
          subItems: [],
        },
        {
          id: '143',
          parentId: '132',
          name: 'Educational Assessment',
          tier: 2,
          subItems: [
            {
              id: '144',
              parentId: '143',
              name: 'Standardized Testing',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '145',
          parentId: '132',
          name: 'Homeschooling',
          tier: 2,
          subItems: [],
        },
        {
          id: '146',
          parentId: '132',
          name: 'Homework and Study',
          tier: 2,
          subItems: [],
        },
        {
          id: '147',
          parentId: '132',
          name: 'Language Learning',
          tier: 2,
          subItems: [],
        },
        {
          id: '148',
          parentId: '132',
          name: 'Online Education',
          tier: 2,
          subItems: [],
        },
        {
          id: '149',
          parentId: '132',
          name: 'Primary Education',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '8VZQHL',
      parentId: '',
      name: 'Events',
      tier: 1,
      subItems: [
        {
          id: '162',
          parentId: '8VZQHL',
          name: 'Awards Shows',
          tier: 2,
          subItems: [],
        },
        {
          id: '180',
          parentId: '8VZQHL',
          name: 'Business Expos & Conferences',
          tier: 2,
          subItems: [],
        },
        {
          id: '185',
          parentId: '8VZQHL',
          name: 'Fan Conventions',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '1KXCLD',
      parentId: '',
      name: 'Holidays',
      tier: 1,
      subItems: [
        {
          id: '157',
          parentId: '1KXCLD',
          name: 'National & Civic Holidays',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '150',
      parentId: '150',
      name: 'Attractions',
      tier: 1,
      subItems: [
        {
          id: '151',
          parentId: '150',
          name: 'Amusement and Theme Parks',
          tier: 2,
          subItems: [],
        },
        {
          id: '153',
          parentId: '150',
          name: 'Historic Site and Landmark Tours',
          tier: 2,
          subItems: [],
        },
        {
          id: '154',
          parentId: '150',
          name: 'Malls & Shopping Centers',
          tier: 2,
          subItems: [],
        },
        {
          id: '155',
          parentId: '150',
          name: 'Museums & Galleries',
          tier: 2,
          subItems: [],
        },
        {
          id: '158',
          parentId: '150',
          name: 'Nightclubs',
          tier: 2,
          subItems: [],
        },
        {
          id: '159',
          parentId: '150',
          name: 'Outdoor Attractions',
          tier: 2,
          subItems: [],
        },
        { id: '160', parentId: '150', name: 'Parks', tier: 2, subItems: [] },
        {
          id: '177',
          parentId: '150',
          name: 'Theater Venues',
          tier: 2,
          subItems: [],
        },
        {
          id: '178',
          parentId: '150',
          name: 'Zoos & Aquariums',
          tier: 2,
          subItems: [],
        },
        {
          id: '179',
          parentId: '150',
          name: 'Bars & Restaurants',
          tier: 2,
          subItems: [],
        },
        {
          id: '181',
          parentId: '150',
          name: 'Casinos & Gambling',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '163',
      parentId: '163',
      name: 'Personal Celebrations & Life Events',
      tier: 1,
      subItems: [
        {
          id: '164',
          parentId: '163',
          name: 'Anniversary',
          tier: 2,
          subItems: [],
        },
        { id: '165', parentId: '163', name: 'Wedding', tier: 2, subItems: [] },
        {
          id: '166',
          parentId: '163',
          name: 'Baby Shower',
          tier: 2,
          subItems: [],
        },
        {
          id: '167',
          parentId: '163',
          name: 'Bachelor Party',
          tier: 2,
          subItems: [],
        },
        {
          id: '168',
          parentId: '163',
          name: 'Bachelorette Party',
          tier: 2,
          subItems: [],
        },
        { id: '169', parentId: '163', name: 'Birth', tier: 2, subItems: [] },
        { id: '170', parentId: '163', name: 'Birthday', tier: 2, subItems: [] },
        { id: '171', parentId: '163', name: 'Funeral', tier: 2, subItems: [] },
        {
          id: '172',
          parentId: '163',
          name: 'Graduation',
          tier: 2,
          subItems: [],
        },
        { id: '173', parentId: '163', name: 'Prom', tier: 2, subItems: [] },
      ],
    },
    {
      id: '186',
      parentId: '186',
      name: 'Family and Relationships',
      tier: 1,
      subItems: [
        {
          id: '187',
          parentId: '186',
          name: 'Bereavement',
          tier: 2,
          subItems: [],
        },
        { id: '188', parentId: '186', name: 'Dating', tier: 2, subItems: [] },
        { id: '189', parentId: '186', name: 'Divorce', tier: 2, subItems: [] },
        {
          id: '190',
          parentId: '186',
          name: 'Eldercare',
          tier: 2,
          subItems: [],
        },
        {
          id: '191',
          parentId: '186',
          name: 'Marriage and Civil Unions',
          tier: 2,
          subItems: [],
        },
        {
          id: '192',
          parentId: '186',
          name: 'Parenting',
          tier: 2,
          subItems: [
            {
              id: '193',
              parentId: '192',
              name: 'Adoption and Fostering',
              tier: 3,
              subItems: [],
            },
            {
              id: '194',
              parentId: '192',
              name: 'Daycare and Pre-School',
              tier: 3,
              subItems: [],
            },
            {
              id: '195',
              parentId: '192',
              name: 'Internet Safety',
              tier: 3,
              subItems: [],
            },
            {
              id: '196',
              parentId: '192',
              name: 'Parenting Babies and Toddlers',
              tier: 3,
              subItems: [],
            },
            {
              id: '197',
              parentId: '192',
              name: 'Parenting Children Aged 4-11',
              tier: 3,
              subItems: [],
            },
            {
              id: '198',
              parentId: '192',
              name: 'Parenting Teens',
              tier: 3,
              subItems: [],
            },
            {
              id: '199',
              parentId: '192',
              name: 'Special Needs Kids',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '200',
          parentId: '186',
          name: 'Single Life',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '201',
      parentId: '',
      name: 'Fine Art',
      tier: 1,
      subItems: [
        {
          id: '202',
          parentId: '201',
          name: 'Costume',
          tier: 2,
          subItems: [],
        },
        {
          id: '203',
          parentId: '201',
          name: 'Dance',
          tier: 2,
          subItems: [],
        },
        {
          id: '204',
          parentId: '201',
          name: 'Design',
          tier: 2,
          subItems: [],
        },
        {
          id: '205',
          parentId: '201',
          name: 'Digital Arts',
          tier: 2,
          subItems: [],
        },
        {
          id: '206',
          parentId: '201',
          name: 'Fine Art Photography',
          tier: 2,
          subItems: [],
        },
        {
          id: '207',
          parentId: '201',
          name: 'Modern Art',
          tier: 2,
          subItems: [],
        },
        {
          id: '208',
          parentId: '201',
          name: 'Opera',
          tier: 2,
          subItems: [],
        },
        {
          id: '209',
          parentId: '201',
          name: 'Theater',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '210',
      parentId: '',
      name: 'Food & Drink',
      tier: 1,
      subItems: [
        {
          id: '211',
          parentId: '210',
          name: 'Alcoholic Beverages',
          tier: 2,
          subItems: [],
        },
        {
          id: '212',
          parentId: '210',
          name: 'Vegan Diets',
          tier: 2,
          subItems: [],
        },
        {
          id: '213',
          parentId: '210',
          name: 'Vegetarian Diets',
          tier: 2,
          subItems: [],
        },
        {
          id: '214',
          parentId: '210',
          name: 'World Cuisines',
          tier: 2,
          subItems: [],
        },
        {
          id: '215',
          parentId: '210',
          name: 'Barbecues and Grilling',
          tier: 2,
          subItems: [],
        },
        {
          id: '216',
          parentId: '210',
          name: 'Cooking',
          tier: 2,
          subItems: [],
        },
        {
          id: '217',
          parentId: '210',
          name: 'Desserts and Baking',
          tier: 2,
          subItems: [],
        },
        {
          id: '218',
          parentId: '210',
          name: 'Dining Out',
          tier: 2,
          subItems: [],
        },
        {
          id: '219',
          parentId: '210',
          name: 'Food Allergies',
          tier: 2,
          subItems: [],
        },
        {
          id: '220',
          parentId: '210',
          name: 'Food Movements',
          tier: 2,
          subItems: [],
        },
        {
          id: '221',
          parentId: '210',
          name: 'Healthy Cooking and Eating',
          tier: 2,
          subItems: [],
        },
        {
          id: '222',
          parentId: '210',
          name: 'Non-Alcoholic Beverages',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '223',
      parentId: '',
      name: 'Healthy Living',
      tier: 1,
      subItems: [
        {
          id: '224',
          parentId: '223',
          name: "Children's Health",
          tier: 2,
          subItems: [],
        },
        {
          id: '225',
          parentId: '223',
          name: 'Fitness and Exercise',
          tier: 2,
          subItems: [
            {
              id: '226',
              parentId: '225',
              name: 'Participant Sports',
              tier: 3,
              subItems: [],
            },
            {
              id: '227',
              parentId: '225',
              name: 'Running and Jogging',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '228',
          parentId: '223',
          name: "Men's Health",
          tier: 2,
          subItems: [],
        },
        {
          id: '229',
          parentId: '223',
          name: 'Nutrition',
          tier: 2,
          subItems: [],
        },
        {
          id: '230',
          parentId: '223',
          name: 'Senior Health',
          tier: 2,
          subItems: [],
        },
        {
          id: '231',
          parentId: '223',
          name: 'Weight Loss',
          tier: 2,
          subItems: [],
        },
        {
          id: '232',
          parentId: '223',
          name: 'Wellness',
          tier: 2,
          subItems: [
            {
              id: '233',
              parentId: '232',
              name: 'Alternative Medicine',
              tier: 3,
              subItems: [
                {
                  id: '234',
                  parentId: '233',
                  name: 'Herbs and Supplements',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '235',
                  parentId: '233',
                  name: 'Holistic Health',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '236',
              parentId: '232',
              name: 'Physical Therapy',
              tier: 3,
              subItems: [],
            },
            {
              id: '237',
              parentId: '232',
              name: 'Smoking Cessation',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '238',
          parentId: '223',
          name: "Women's Health",
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '239',
      parentId: '',
      name: 'Hobbies & Interests',
      tier: 1,
      subItems: [
        {
          id: '240',
          parentId: '239',
          name: 'Antiquing and Antiques',
          tier: 2,
          subItems: [],
        },
        {
          id: '241',
          parentId: '239',
          name: 'Magic and Illusion',
          tier: 2,
          subItems: [],
        },
        {
          id: '242',
          parentId: '239',
          name: 'Model Toys',
          tier: 2,
          subItems: [],
        },
        {
          id: '243',
          parentId: '239',
          name: 'Musical Instruments',
          tier: 2,
          subItems: [],
        },
        {
          id: '244',
          parentId: '239',
          name: 'Paranormal Phenomena',
          tier: 2,
          subItems: [],
        },
        {
          id: '245',
          parentId: '239',
          name: 'Radio Control',
          tier: 2,
          subItems: [],
        },
        {
          id: '246',
          parentId: '239',
          name: 'Sci-fi and Fantasy',
          tier: 2,
          subItems: [],
        },
        {
          id: '247',
          parentId: '239',
          name: 'Workshops and Classes',
          tier: 2,
          subItems: [],
        },
        {
          id: '248',
          parentId: '239',
          name: 'Arts and Crafts',
          tier: 2,
          subItems: [
            {
              id: '249',
              parentId: '248',
              name: 'Beadwork',
              tier: 3,
              subItems: [],
            },
            {
              id: '250',
              parentId: '248',
              name: 'Candle and Soap Making',
              tier: 3,
              subItems: [],
            },
            {
              id: '251',
              parentId: '248',
              name: 'Drawing and Sketching',
              tier: 3,
              subItems: [],
            },
            {
              id: '252',
              parentId: '248',
              name: 'Jewelry Making',
              tier: 3,
              subItems: [],
            },
            {
              id: '253',
              parentId: '248',
              name: 'Needlework',
              tier: 3,
              subItems: [],
            },
            {
              id: '254',
              parentId: '248',
              name: 'Painting',
              tier: 3,
              subItems: [],
            },
            {
              id: '255',
              parentId: '248',
              name: 'Photography',
              tier: 3,
              subItems: [],
            },
            {
              id: '256',
              parentId: '248',
              name: 'Scrapbooking',
              tier: 3,
              subItems: [],
            },
            {
              id: '257',
              parentId: '248',
              name: 'Woodworking',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '258',
          parentId: '239',
          name: 'Beekeeping',
          tier: 2,
          subItems: [],
        },
        {
          id: '259',
          parentId: '239',
          name: 'Birdwatching',
          tier: 2,
          subItems: [],
        },
        {
          id: '260',
          parentId: '239',
          name: 'Cigars',
          tier: 2,
          subItems: [],
        },
        {
          id: '261',
          parentId: '239',
          name: 'Collecting',
          tier: 2,
          subItems: [
            {
              id: '262',
              parentId: '261',
              name: 'Comic Books',
              tier: 3,
              subItems: [],
            },
            {
              id: '263',
              parentId: '261',
              name: 'Stamps and Coins',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '264',
          parentId: '239',
          name: 'Content Production',
          tier: 2,
          subItems: [
            {
              id: '265',
              parentId: '264',
              name: 'Audio Production',
              tier: 3,
              subItems: [],
            },
            {
              id: '266',
              parentId: '264',
              name: 'Freelance Writing',
              tier: 3,
              subItems: [],
            },
            {
              id: '267',
              parentId: '264',
              name: 'Screenwriting',
              tier: 3,
              subItems: [],
            },
            {
              id: '268',
              parentId: '264',
              name: 'Video Production',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '269',
          parentId: '239',
          name: 'Games and Puzzles',
          tier: 2,
          subItems: [
            {
              id: '270',
              parentId: '269',
              name: 'Board Games and Puzzles',
              tier: 3,
              subItems: [],
            },
            {
              id: '271',
              parentId: '269',
              name: 'Card Games',
              tier: 3,
              subItems: [],
            },
            {
              id: '272',
              parentId: '269',
              name: 'Roleplaying Games',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '273',
          parentId: '239',
          name: 'Genealogy and Ancestry',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '274',
      parentId: '',
      name: 'Home & Garden',
      tier: 1,
      subItems: [
        {
          id: '275',
          parentId: '274',
          name: 'Gardening',
          tier: 2,
          subItems: [],
        },
        {
          id: '276',
          parentId: '274',
          name: 'Remodeling & Construction',
          tier: 2,
          subItems: [],
        },
        {
          id: '277',
          parentId: '274',
          name: 'Smart Home',
          tier: 2,
          subItems: [],
        },
        {
          id: '278',
          parentId: '274',
          name: 'Home Appliances',
          tier: 2,
          subItems: [],
        },
        {
          id: '279',
          parentId: '274',
          name: 'Home Entertaining',
          tier: 2,
          subItems: [],
        },
        {
          id: '280',
          parentId: '274',
          name: 'Home Improvement',
          tier: 2,
          subItems: [],
        },
        {
          id: '281',
          parentId: '274',
          name: 'Home Security',
          tier: 2,
          subItems: [],
        },
        {
          id: '282',
          parentId: '274',
          name: 'Indoor Environmental Quality',
          tier: 2,
          subItems: [],
        },
        {
          id: '283',
          parentId: '274',
          name: 'Interior Decorating',
          tier: 2,
          subItems: [],
        },
        {
          id: '284',
          parentId: '274',
          name: 'Landscaping',
          tier: 2,
          subItems: [],
        },
        {
          id: '285',
          parentId: '274',
          name: 'Outdoor Decorating',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '286',
      parentId: '',
      name: 'Medical Health',
      tier: 1,
      subItems: [
        {
          id: '287',
          parentId: '286',
          name: 'Diseases and Conditions',
          tier: 2,
          subItems: [
            {
              id: '288',
              parentId: '287',
              name: 'Allergies',
              tier: 3,
              subItems: [],
            },
            {
              id: '289',
              parentId: '287',
              name: 'Ear, Nose and Throat Conditions',
              tier: 3,
              subItems: [],
            },
            {
              id: '290',
              parentId: '287',
              name: 'Endocrine and Metabolic Diseases',
              tier: 3,
              subItems: [
                {
                  id: '291',
                  parentId: '290',
                  name: 'Hormonal Disorders',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '292',
                  parentId: '290',
                  name: 'Menopause',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '293',
                  parentId: '290',
                  name: 'Thyroid Disorders',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '294',
              parentId: '287',
              name: 'Eye and Vision Conditions',
              tier: 3,
              subItems: [],
            },
            {
              id: '295',
              parentId: '287',
              name: 'Foot Health',
              tier: 3,
              subItems: [],
            },
            {
              id: '296',
              parentId: '287',
              name: 'Heart and Cardiovascular Diseases',
              tier: 3,
              subItems: [],
            },
            {
              id: '297',
              parentId: '287',
              name: 'Infectious Diseases',
              tier: 3,
              subItems: [],
            },
            {
              id: '298',
              parentId: '287',
              name: 'Injuries',
              tier: 3,
              subItems: [
                {
                  id: '299',
                  parentId: '298',
                  name: 'First Aid',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '300',
              parentId: '287',
              name: 'Lung and Respiratory Health',
              tier: 3,
              subItems: [],
            },
            {
              id: '301',
              parentId: '287',
              name: 'Mental Health',
              tier: 3,
              subItems: [],
            },
            {
              id: '302',
              parentId: '287',
              name: 'Reproductive Health',
              tier: 3,
              subItems: [
                {
                  id: '303',
                  parentId: '302',
                  name: 'Birth Control',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '304',
                  parentId: '302',
                  name: 'Infertility',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '305',
                  parentId: '302',
                  name: 'Pregnancy',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '306',
              parentId: '287',
              name: 'Blood Disorders',
              tier: 3,
              subItems: [],
            },
            {
              id: '307',
              parentId: '287',
              name: 'Sexual Health',
              tier: 3,
              subItems: [
                {
                  id: '308',
                  parentId: '307',
                  name: 'Sexual Conditions',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '309',
              parentId: '287',
              name: 'Skin and Dermatology',
              tier: 3,
              subItems: [],
            },
            {
              id: '310',
              parentId: '287',
              name: 'Sleep Disorders',
              tier: 3,
              subItems: [],
            },
            {
              id: '311',
              parentId: '287',
              name: 'Substance Abuse',
              tier: 3,
              subItems: [],
            },
            {
              id: '312',
              parentId: '287',
              name: 'Bone and Joint Conditions',
              tier: 3,
              subItems: [],
            },
            {
              id: '313',
              parentId: '287',
              name: 'Brain and Nervous System Disorders',
              tier: 3,
              subItems: [],
            },
            {
              id: '314',
              parentId: '287',
              name: 'Cancer',
              tier: 3,
              subItems: [],
            },
            {
              id: '315',
              parentId: '287',
              name: 'Cold and Flu',
              tier: 3,
              subItems: [],
            },
            {
              id: '316',
              parentId: '287',
              name: 'Dental Health',
              tier: 3,
              subItems: [],
            },
            {
              id: '317',
              parentId: '287',
              name: 'Diabetes',
              tier: 3,
              subItems: [],
            },
            {
              id: '318',
              parentId: '287',
              name: 'Digestive Disorders',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '319',
          parentId: '286',
          name: 'Medical Tests',
          tier: 2,
          subItems: [],
        },
        {
          id: '320',
          parentId: '286',
          name: 'Pharmaceutical Drugs',
          tier: 2,
          subItems: [],
        },
        {
          id: '321',
          parentId: '286',
          name: 'Surgery',
          tier: 2,
          subItems: [],
        },
        {
          id: '322',
          parentId: '286',
          name: 'Vaccines',
          tier: 2,
          subItems: [],
        },
        {
          id: '323',
          parentId: '286',
          name: 'Cosmetic Medical Services',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: 'JLBCU7',
      parentId: '',
      name: 'Entertainment',
      tier: 1,
      subItems: [
        {
          id: '324',
          parentId: 'JLBCU7',
          name: 'Movies',
          tier: 2,
          subItems: [],
        },
        {
          id: '338',
          parentId: 'JLBCU7',
          name: 'Music',
          tier: 2,
          subItems: [
            {
              id: '339',
              parentId: '338',
              name: 'Adult Contemporary Music',
              tier: 3,
              subItems: [
                {
                  id: '340',
                  parentId: '339',
                  name: 'Soft AC Music',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '341',
                  parentId: '339',
                  name: 'Urban AC Music',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '342',
              parentId: '338',
              name: 'Adult Album Alternative',
              tier: 3,
              subItems: [],
            },
            {
              id: '343',
              parentId: '338',
              name: 'Alternative Music',
              tier: 3,
              subItems: [],
            },
            {
              id: '344',
              parentId: '338',
              name: "Children's Music",
              tier: 3,
              subItems: [],
            },
            {
              id: '345',
              parentId: '338',
              name: 'Classic Hits',
              tier: 3,
              subItems: [],
            },
            {
              id: '346',
              parentId: '338',
              name: 'Classical Music',
              tier: 3,
              subItems: [],
            },
            {
              id: '347',
              parentId: '338',
              name: 'College Radio',
              tier: 3,
              subItems: [],
            },
            {
              id: '348',
              parentId: '338',
              name: 'Comedy (Music and Audio)',
              tier: 3,
              subItems: [],
            },
            {
              id: '349',
              parentId: '338',
              name: 'Contemporary Hits/Pop/Top 40',
              tier: 3,
              subItems: [],
            },
            {
              id: '350',
              parentId: '338',
              name: 'Country Music',
              tier: 3,
              subItems: [],
            },
            {
              id: '351',
              parentId: '338',
              name: 'Dance and Electronic Music',
              tier: 3,
              subItems: [],
            },
            {
              id: '352',
              parentId: '338',
              name: 'World/International Music',
              tier: 3,
              subItems: [],
            },
            {
              id: '353',
              parentId: '338',
              name: 'Songwriters/Folk',
              tier: 3,
              subItems: [],
            },
            {
              id: '354',
              parentId: '338',
              name: 'Gospel Music',
              tier: 3,
              subItems: [],
            },
            {
              id: '355',
              parentId: '338',
              name: 'Hip Hop Music',
              tier: 3,
              subItems: [],
            },
            {
              id: '356',
              parentId: '338',
              name: 'Inspirational/New Age Music',
              tier: 3,
              subItems: [],
            },
            {
              id: '357',
              parentId: '338',
              name: 'Jazz',
              tier: 3,
              subItems: [],
            },
            {
              id: '358',
              parentId: '338',
              name: 'Oldies/Adult Standards',
              tier: 3,
              subItems: [],
            },
            {
              id: '359',
              parentId: '338',
              name: 'Reggae',
              tier: 3,
              subItems: [],
            },
            {
              id: '360',
              parentId: '338',
              name: 'Blues',
              tier: 3,
              subItems: [],
            },
            {
              id: '361',
              parentId: '338',
              name: 'Religious (Music and Audio)',
              tier: 3,
              subItems: [],
            },
            {
              id: '362',
              parentId: '338',
              name: 'R&B/Soul/Funk',
              tier: 3,
              subItems: [],
            },
            {
              id: '363',
              parentId: '338',
              name: 'Rock Music',
              tier: 3,
              subItems: [
                {
                  id: '364',
                  parentId: '363',
                  name: 'Album-oriented Rock',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '365',
                  parentId: '363',
                  name: 'Alternative Rock',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '366',
                  parentId: '363',
                  name: 'Classic Rock',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '367',
                  parentId: '363',
                  name: 'Hard Rock',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '368',
                  parentId: '363',
                  name: 'Soft Rock',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '369',
              parentId: '338',
              name: 'Soundtracks, TV and Showtunes',
              tier: 3,
              subItems: [],
            },
            {
              id: '377',
              parentId: '338',
              name: 'Urban Contemporary Music',
              tier: 3,
              subItems: [],
            },
            {
              id: '378',
              parentId: '338',
              name: 'Variety (Music and Audio)',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '640',
          parentId: 'JLBCU7',
          name: 'Television',
          tier: 2,
          subItems: [
            {
              id: '650',
              parentId: '640',
              name: 'Music Video',
              tier: 3,
              subItems: [],
            },
          ],
        },
      ],
    },
    {
      id: '380',
      parentId: '',
      name: 'Crime',
      tier: 1,
      subItems: [],
    },
    {
      id: '381',
      parentId: '',
      name: 'Disasters',
      tier: 1,
      subItems: [],
    },
    {
      id: '383',
      parentId: '',
      name: 'Law',
      tier: 1,
      subItems: [],
    },
    {
      id: '386',
      parentId: '',
      name: 'Politics',
      tier: 1,
      subItems: [
        {
          id: '387',
          parentId: '386',
          name: 'Elections',
          tier: 2,
          subItems: [],
        },
        {
          id: '388',
          parentId: '386',
          name: 'Political Issues & Policy',
          tier: 2,
          subItems: [],
        },
        {
          id: '8YPBBL',
          parentId: '386',
          name: 'Civic affairs',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '389',
      parentId: '',
      name: 'War and Conflicts',
      tier: 1,
      subItems: [],
    },
    {
      id: '391',
      parentId: '',
      name: 'Personal Finance',
      tier: 1,
      subItems: [
        {
          id: '392',
          parentId: '391',
          name: 'Consumer Banking',
          tier: 2,
          subItems: [],
        },
        {
          id: '393',
          parentId: '391',
          name: 'Financial Assistance',
          tier: 2,
          subItems: [
            {
              id: '394',
              parentId: '393',
              name: 'Government Support and Welfare',
              tier: 3,
              subItems: [],
            },
            {
              id: '395',
              parentId: '393',
              name: 'Student Financial Aid',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '396',
          parentId: '391',
          name: 'Financial Planning',
          tier: 2,
          subItems: [],
        },
        {
          id: '397',
          parentId: '391',
          name: 'Frugal Living',
          tier: 2,
          subItems: [],
        },
        {
          id: '398',
          parentId: '391',
          name: 'Insurance',
          tier: 2,
          subItems: [
            {
              id: '399',
              parentId: '398',
              name: 'Health Insurance',
              tier: 3,
              subItems: [],
            },
            {
              id: '400',
              parentId: '398',
              name: 'Home Insurance',
              tier: 3,
              subItems: [],
            },
            {
              id: '401',
              parentId: '398',
              name: 'Life Insurance',
              tier: 3,
              subItems: [],
            },
            {
              id: '402',
              parentId: '398',
              name: 'Motor Insurance',
              tier: 3,
              subItems: [],
            },
            {
              id: '403',
              parentId: '398',
              name: 'Pet Insurance',
              tier: 3,
              subItems: [],
            },
            {
              id: '404',
              parentId: '398',
              name: 'Travel Insurance',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '405',
          parentId: '391',
          name: 'Personal Debt',
          tier: 2,
          subItems: [
            {
              id: '406',
              parentId: '405',
              name: 'Credit Cards',
              tier: 3,
              subItems: [],
            },
            {
              id: '407',
              parentId: '405',
              name: 'Home Financing',
              tier: 3,
              subItems: [],
            },
            {
              id: '408',
              parentId: '405',
              name: 'Personal Loans',
              tier: 3,
              subItems: [],
            },
            {
              id: '409',
              parentId: '405',
              name: 'Student Loans',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '410',
          parentId: '391',
          name: 'Personal Investing',
          tier: 2,
          subItems: [
            {
              id: '411',
              parentId: '410',
              name: 'Hedge Funds',
              tier: 3,
              subItems: [],
            },
            {
              id: '412',
              parentId: '410',
              name: 'Mutual Funds',
              tier: 3,
              subItems: [],
            },
            {
              id: '413',
              parentId: '410',
              name: 'Options',
              tier: 3,
              subItems: [],
            },
            {
              id: '414',
              parentId: '410',
              name: 'Stocks and Bonds',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '415',
          parentId: '391',
          name: 'Personal Taxes',
          tier: 2,
          subItems: [],
        },
        {
          id: '416',
          parentId: '391',
          name: 'Retirement Planning',
          tier: 2,
          subItems: [],
        },
        {
          id: '417',
          parentId: '391',
          name: 'Home Utilities',
          tier: 2,
          subItems: [
            {
              id: '418',
              parentId: '417',
              name: 'Gas and Electric',
              tier: 3,
              subItems: [],
            },
            {
              id: '419',
              parentId: '417',
              name: 'Internet Service Providers',
              tier: 3,
              subItems: [],
            },
            {
              id: '420',
              parentId: '417',
              name: 'Phone Services',
              tier: 3,
              subItems: [],
            },
            {
              id: '421',
              parentId: '417',
              name: 'Water Services',
              tier: 3,
              subItems: [],
            },
          ],
        },
      ],
    },
    {
      id: '422',
      parentId: '',
      name: 'Pets',
      tier: 1,
      subItems: [
        {
          id: '423',
          parentId: '422',
          name: 'Birds',
          tier: 2,
          subItems: [],
        },
        {
          id: '424',
          parentId: '422',
          name: 'Cats',
          tier: 2,
          subItems: [],
        },
        {
          id: '425',
          parentId: '422',
          name: 'Dogs',
          tier: 2,
          subItems: [],
        },
        {
          id: '426',
          parentId: '422',
          name: 'Fish and Aquariums',
          tier: 2,
          subItems: [],
        },
        {
          id: '427',
          parentId: '422',
          name: 'Large Animals',
          tier: 2,
          subItems: [],
        },
        {
          id: '428',
          parentId: '422',
          name: 'Pet Adoptions',
          tier: 2,
          subItems: [],
        },
        {
          id: '429',
          parentId: '422',
          name: 'Reptiles',
          tier: 2,
          subItems: [],
        },
        {
          id: '430',
          parentId: '422',
          name: 'Veterinary Medicine',
          tier: 2,
          subItems: [],
        },
        {
          id: '431',
          parentId: '422',
          name: 'Pet Supplies',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '432',
      parentId: '',
      name: 'Pop Culture',
      tier: 1,
      subItems: [
        {
          id: '433',
          parentId: '432',
          name: 'Celebrity Deaths',
          tier: 2,
          subItems: [],
        },
        {
          id: '434',
          parentId: '432',
          name: 'Celebrity Families',
          tier: 2,
          subItems: [],
        },
        {
          id: '435',
          parentId: '432',
          name: 'Celebrity Homes',
          tier: 2,
          subItems: [],
        },
        {
          id: '436',
          parentId: '432',
          name: 'Celebrity Pregnancy',
          tier: 2,
          subItems: [],
        },
        {
          id: '437',
          parentId: '432',
          name: 'Celebrity Relationships',
          tier: 2,
          subItems: [],
        },
        {
          id: '438',
          parentId: '432',
          name: 'Celebrity Scandal',
          tier: 2,
          subItems: [],
        },
        {
          id: '439',
          parentId: '432',
          name: 'Celebrity Style',
          tier: 2,
          subItems: [],
        },
        {
          id: '440',
          parentId: '432',
          name: 'Humor and Satire',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '441',
      parentId: '',
      name: 'Real Estate',
      tier: 1,
      subItems: [
        {
          id: '442',
          parentId: '441',
          name: 'Apartments',
          tier: 2,
          subItems: [],
        },
        {
          id: '443',
          parentId: '441',
          name: 'Retail Property',
          tier: 2,
          subItems: [],
        },
        {
          id: '444',
          parentId: '441',
          name: 'Vacation Properties',
          tier: 2,
          subItems: [],
        },
        {
          id: '445',
          parentId: '441',
          name: 'Developmental Sites',
          tier: 2,
          subItems: [],
        },
        {
          id: '446',
          parentId: '441',
          name: 'Hotel Properties',
          tier: 2,
          subItems: [],
        },
        {
          id: '447',
          parentId: '441',
          name: 'Houses',
          tier: 2,
          subItems: [],
        },
        {
          id: '448',
          parentId: '441',
          name: 'Industrial Property',
          tier: 2,
          subItems: [],
        },
        {
          id: '449',
          parentId: '441',
          name: 'Land and Farms',
          tier: 2,
          subItems: [],
        },
        {
          id: '450',
          parentId: '441',
          name: 'Office Property',
          tier: 2,
          subItems: [],
        },
        {
          id: '451',
          parentId: '441',
          name: 'Real Estate Buying and Selling',
          tier: 2,
          subItems: [],
        },
        {
          id: '452',
          parentId: '441',
          name: 'Real Estate Renting and Leasing',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '453',
      parentId: '',
      name: 'Religion & Spirituality',
      tier: 1,
      subItems: [
        {
          id: '454',
          parentId: '453',
          name: 'Agnosticism',
          tier: 2,
          subItems: [],
        },
        {
          id: '455',
          parentId: '453',
          name: 'Spirituality',
          tier: 2,
          subItems: [],
        },
        {
          id: '456',
          parentId: '453',
          name: 'Astrology',
          tier: 2,
          subItems: [],
        },
        {
          id: '457',
          parentId: '453',
          name: 'Atheism',
          tier: 2,
          subItems: [],
        },
        {
          id: '458',
          parentId: '453',
          name: 'Buddhism',
          tier: 2,
          subItems: [],
        },
        {
          id: '459',
          parentId: '453',
          name: 'Christianity',
          tier: 2,
          subItems: [],
        },
        {
          id: '460',
          parentId: '453',
          name: 'Hinduism',
          tier: 2,
          subItems: [],
        },
        {
          id: '461',
          parentId: '453',
          name: 'Islam',
          tier: 2,
          subItems: [],
        },
        {
          id: '462',
          parentId: '453',
          name: 'Judaism',
          tier: 2,
          subItems: [],
        },
        {
          id: '463',
          parentId: '453',
          name: 'Sikhism',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '464',
      parentId: '',
      name: 'Science',
      tier: 1,
      subItems: [
        {
          id: '465',
          parentId: '464',
          name: 'Biological Sciences',
          tier: 2,
          subItems: [],
        },
        {
          id: '466',
          parentId: '464',
          name: 'Chemistry',
          tier: 2,
          subItems: [],
        },
        {
          id: '467',
          parentId: '464',
          name: 'Environment',
          tier: 2,
          subItems: [],
        },
        {
          id: '468',
          parentId: '464',
          name: 'Genetics',
          tier: 2,
          subItems: [],
        },
        {
          id: '469',
          parentId: '464',
          name: 'Geography',
          tier: 2,
          subItems: [],
        },
        {
          id: '470',
          parentId: '464',
          name: 'Geology',
          tier: 2,
          subItems: [],
        },
        {
          id: '471',
          parentId: '464',
          name: 'Physics',
          tier: 2,
          subItems: [],
        },
        {
          id: '472',
          parentId: '464',
          name: 'Space and Astronomy',
          tier: 2,
          subItems: [],
        },
        {
          id: '390',
          parentId: '464',
          name: 'Weather',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '473',
      parentId: '',
      name: 'Shopping',
      tier: 1,
      subItems: [
        {
          id: '161',
          parentId: '473',
          name: 'Party Supplies and Decorations',
          tier: 2,
          subItems: [],
        },
        {
          id: '474',
          parentId: '473',
          name: 'Coupons and Discounts',
          tier: 2,
          subItems: [],
        },
        {
          id: '475',
          parentId: '473',
          name: 'Flower Shopping',
          tier: 2,
          subItems: [],
        },
        {
          id: '476',
          parentId: '473',
          name: 'Gifts and Greetings Cards',
          tier: 2,
          subItems: [],
        },
        {
          id: '477',
          parentId: '473',
          name: 'Grocery Shopping',
          tier: 2,
          subItems: [],
        },
        {
          id: '478',
          parentId: '473',
          name: 'Holiday Shopping',
          tier: 2,
          subItems: [],
        },
        {
          id: '479',
          parentId: '473',
          name: 'Household Supplies',
          tier: 2,
          subItems: [],
        },
        {
          id: '480',
          parentId: '473',
          name: 'Lotteries and Scratchcards',
          tier: 2,
          subItems: [],
        },
        {
          id: '481',
          parentId: '473',
          name: 'Sales and Promotions',
          tier: 2,
          subItems: [],
        },
        {
          id: '482',
          parentId: '473',
          name: "Children's Games and Toys",
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '483',
      parentId: '',
      name: 'Sports',
      tier: 1,
      subItems: [
        {
          id: '484',
          parentId: '483',
          name: 'American Football',
          tier: 2,
          subItems: [],
        },
        {
          id: '485',
          parentId: '483',
          name: 'Boxing',
          tier: 2,
          subItems: [],
        },
        {
          id: '486',
          parentId: '483',
          name: 'Cheerleading',
          tier: 2,
          subItems: [],
        },
        {
          id: '487',
          parentId: '483',
          name: 'College Sports',
          tier: 2,
          subItems: [
            {
              id: '488',
              parentId: '487',
              name: 'College Football',
              tier: 3,
              subItems: [],
            },
            {
              id: '489',
              parentId: '487',
              name: 'College Basketball',
              tier: 3,
              subItems: [],
            },
            {
              id: '490',
              parentId: '487',
              name: 'College Baseball',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '491',
          parentId: '483',
          name: 'Cricket',
          tier: 2,
          subItems: [],
        },
        {
          id: '492',
          parentId: '483',
          name: 'Cycling',
          tier: 2,
          subItems: [],
        },
        {
          id: '493',
          parentId: '483',
          name: 'Darts',
          tier: 2,
          subItems: [],
        },
        {
          id: '494',
          parentId: '483',
          name: 'Disabled Sports',
          tier: 2,
          subItems: [],
        },
        {
          id: '495',
          parentId: '483',
          name: 'Diving',
          tier: 2,
          subItems: [],
        },
        {
          id: '496',
          parentId: '483',
          name: 'Equine Sports',
          tier: 2,
          subItems: [],
        },
        {
          id: '497',
          parentId: '483',
          name: 'Horse Racing',
          tier: 2,
          subItems: [],
        },
        {
          id: '498',
          parentId: '483',
          name: 'Extreme Sports',
          tier: 2,
          subItems: [
            {
              id: '499',
              parentId: '498',
              name: 'Canoeing and Kayaking',
              tier: 3,
              subItems: [],
            },
            {
              id: '500',
              parentId: '498',
              name: 'Climbing',
              tier: 3,
              subItems: [],
            },
            {
              id: '501',
              parentId: '498',
              name: 'Paintball',
              tier: 3,
              subItems: [],
            },
            {
              id: '502',
              parentId: '498',
              name: 'Scuba Diving',
              tier: 3,
              subItems: [],
            },
            {
              id: '503',
              parentId: '498',
              name: 'Skateboarding',
              tier: 3,
              subItems: [],
            },
            {
              id: '504',
              parentId: '498',
              name: 'Snowboarding',
              tier: 3,
              subItems: [],
            },
            {
              id: '505',
              parentId: '498',
              name: 'Surfing and Bodyboarding',
              tier: 3,
              subItems: [],
            },
            {
              id: '506',
              parentId: '498',
              name: 'Waterskiing and Wakeboarding',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '507',
          parentId: '483',
          name: 'Australian Rules Football',
          tier: 2,
          subItems: [],
        },
        {
          id: '508',
          parentId: '483',
          name: 'Fantasy Sports',
          tier: 2,
          subItems: [],
        },
        {
          id: '509',
          parentId: '483',
          name: 'Field Hockey',
          tier: 2,
          subItems: [],
        },
        {
          id: '510',
          parentId: '483',
          name: 'Figure Skating',
          tier: 2,
          subItems: [],
        },
        {
          id: '511',
          parentId: '483',
          name: 'Fishing Sports',
          tier: 2,
          subItems: [],
        },
        {
          id: '512',
          parentId: '483',
          name: 'Golf',
          tier: 2,
          subItems: [],
        },
        {
          id: '513',
          parentId: '483',
          name: 'Gymnastics',
          tier: 2,
          subItems: [],
        },
        {
          id: '514',
          parentId: '483',
          name: 'Hunting and Shooting',
          tier: 2,
          subItems: [],
        },
        {
          id: '515',
          parentId: '483',
          name: 'Ice Hockey',
          tier: 2,
          subItems: [],
        },
        {
          id: '516',
          parentId: '483',
          name: 'Inline Skating',
          tier: 2,
          subItems: [],
        },
        {
          id: '517',
          parentId: '483',
          name: 'Lacrosse',
          tier: 2,
          subItems: [],
        },
        {
          id: '518',
          parentId: '483',
          name: 'Auto Racing',
          tier: 2,
          subItems: [
            {
              id: '519',
              parentId: '518',
              name: 'Motorcycle Sports',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '520',
          parentId: '483',
          name: 'Martial Arts',
          tier: 2,
          subItems: [],
        },
        {
          id: '521',
          parentId: '483',
          name: 'Olympic Sports',
          tier: 2,
          subItems: [
            {
              id: '522',
              parentId: '521',
              name: 'Summer Olympic Sports',
              tier: 3,
              subItems: [],
            },
            {
              id: '523',
              parentId: '521',
              name: 'Winter Olympic Sports',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '524',
          parentId: '483',
          name: 'Poker and Professional Gambling',
          tier: 2,
          subItems: [],
        },
        {
          id: '525',
          parentId: '483',
          name: 'Rodeo',
          tier: 2,
          subItems: [],
        },
        {
          id: '526',
          parentId: '483',
          name: 'Rowing',
          tier: 2,
          subItems: [],
        },
        {
          id: '527',
          parentId: '483',
          name: 'Rugby',
          tier: 2,
          subItems: [
            {
              id: '528',
              parentId: '527',
              name: 'Rugby League',
              tier: 3,
              subItems: [],
            },
            {
              id: '529',
              parentId: '527',
              name: 'Rugby Union',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '530',
          parentId: '483',
          name: 'Sailing',
          tier: 2,
          subItems: [],
        },
        {
          id: '531',
          parentId: '483',
          name: 'Skiing',
          tier: 2,
          subItems: [],
        },
        {
          id: '532',
          parentId: '483',
          name: 'Snooker/Pool/Billiards',
          tier: 2,
          subItems: [],
        },
        {
          id: '533',
          parentId: '483',
          name: 'Soccer',
          tier: 2,
          subItems: [],
        },
        {
          id: '534',
          parentId: '483',
          name: 'Badminton',
          tier: 2,
          subItems: [],
        },
        {
          id: '535',
          parentId: '483',
          name: 'Softball',
          tier: 2,
          subItems: [],
        },
        {
          id: '536',
          parentId: '483',
          name: 'Squash',
          tier: 2,
          subItems: [],
        },
        {
          id: '537',
          parentId: '483',
          name: 'Swimming',
          tier: 2,
          subItems: [],
        },
        {
          id: '538',
          parentId: '483',
          name: 'Table Tennis',
          tier: 2,
          subItems: [],
        },
        {
          id: '539',
          parentId: '483',
          name: 'Tennis',
          tier: 2,
          subItems: [],
        },
        {
          id: '540',
          parentId: '483',
          name: 'Track and Field',
          tier: 2,
          subItems: [],
        },
        {
          id: '541',
          parentId: '483',
          name: 'Volleyball',
          tier: 2,
          subItems: [],
        },
        {
          id: '542',
          parentId: '483',
          name: 'Walking',
          tier: 2,
          subItems: [],
        },
        {
          id: '543',
          parentId: '483',
          name: 'Water Polo',
          tier: 2,
          subItems: [],
        },
        {
          id: '544',
          parentId: '483',
          name: 'Weightlifting',
          tier: 2,
          subItems: [],
        },
        {
          id: '545',
          parentId: '483',
          name: 'Baseball',
          tier: 2,
          subItems: [],
        },
        {
          id: '546',
          parentId: '483',
          name: 'Wrestling',
          tier: 2,
          subItems: [],
        },
        {
          id: '547',
          parentId: '483',
          name: 'Basketball',
          tier: 2,
          subItems: [],
        },
        {
          id: '548',
          parentId: '483',
          name: 'Beach Volleyball',
          tier: 2,
          subItems: [],
        },
        {
          id: '549',
          parentId: '483',
          name: 'Bodybuilding',
          tier: 2,
          subItems: [],
        },
        {
          id: '550',
          parentId: '483',
          name: 'Bowling',
          tier: 2,
          subItems: [],
        },
        {
          id: '551',
          parentId: '483',
          name: 'Sports Equipment',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '552',
      parentId: '',
      name: 'Style & Fashion',
      tier: 1,
      subItems: [
        {
          id: '553',
          parentId: '552',
          name: 'Beauty',
          tier: 2,
          subItems: [
            {
              id: '554',
              parentId: '553',
              name: 'Hair Care',
              tier: 3,
              subItems: [],
            },
            {
              id: '555',
              parentId: '553',
              name: 'Makeup and Accessories',
              tier: 3,
              subItems: [],
            },
            {
              id: '556',
              parentId: '553',
              name: 'Nail Care',
              tier: 3,
              subItems: [],
            },
            {
              id: '557',
              parentId: '553',
              name: 'Natural and Organic Beauty',
              tier: 3,
              subItems: [],
            },
            {
              id: '558',
              parentId: '553',
              name: 'Perfume and Fragrance',
              tier: 3,
              subItems: [],
            },
            {
              id: '559',
              parentId: '553',
              name: 'Skin Care',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '560',
          parentId: '552',
          name: "Women's Fashion",
          tier: 2,
          subItems: [
            {
              id: '561',
              parentId: '560',
              name: "Women's Accessories",
              tier: 3,
              subItems: [
                {
                  id: '562',
                  parentId: '561',
                  name: "Women's Glasses",
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '563',
                  parentId: '561',
                  name: "Women's Handbags and Wallets",
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '564',
                  parentId: '561',
                  name: "Women's Hats and Scarves",
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '565',
                  parentId: '561',
                  name: "Women's Jewelry and Watches",
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '566',
              parentId: '560',
              name: "Women's Clothing",
              tier: 3,
              subItems: [
                {
                  id: '567',
                  parentId: '566',
                  name: "Women's Business Wear",
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '568',
                  parentId: '566',
                  name: "Women's Casual Wear",
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '569',
                  parentId: '566',
                  name: "Women's Formal Wear",
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '570',
                  parentId: '566',
                  name: "Women's Intimates and Sleepwear",
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '571',
                  parentId: '566',
                  name: "Women's Outerwear",
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '572',
                  parentId: '566',
                  name: "Women's Sportswear",
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '573',
              parentId: '560',
              name: "Women's Shoes and Footwear",
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '574',
          parentId: '552',
          name: 'Body Art',
          tier: 2,
          subItems: [],
        },
        {
          id: '575',
          parentId: '552',
          name: "Children's Clothing",
          tier: 2,
          subItems: [],
        },
        {
          id: '576',
          parentId: '552',
          name: 'Designer Clothing',
          tier: 2,
          subItems: [],
        },
        {
          id: '577',
          parentId: '552',
          name: 'Fashion Trends',
          tier: 2,
          subItems: [],
        },
        {
          id: '578',
          parentId: '552',
          name: 'High Fashion',
          tier: 2,
          subItems: [],
        },
        {
          id: '579',
          parentId: '552',
          name: "Men's Fashion",
          tier: 2,
          subItems: [
            {
              id: '580',
              parentId: '579',
              name: "Men's Accessories",
              tier: 3,
              subItems: [
                {
                  id: '581',
                  parentId: '580',
                  name: "Men's Jewelry and Watches",
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '582',
              parentId: '579',
              name: "Men's Clothing",
              tier: 3,
              subItems: [
                {
                  id: '583',
                  parentId: '582',
                  name: "Men's Business Wear",
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '584',
                  parentId: '582',
                  name: "Men's Casual Wear",
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '585',
                  parentId: '582',
                  name: "Men's Formal Wear",
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '586',
                  parentId: '582',
                  name: "Men's Outerwear",
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '587',
                  parentId: '582',
                  name: "Men's Sportswear",
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '588',
                  parentId: '582',
                  name: "Men's Underwear and Sleepwear",
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '589',
              parentId: '579',
              name: "Men's Shoes and Footwear",
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '590',
          parentId: '552',
          name: 'Personal Care',
          tier: 2,
          subItems: [
            {
              id: '591',
              parentId: '590',
              name: 'Bath and Shower',
              tier: 3,
              subItems: [],
            },
            {
              id: '592',
              parentId: '590',
              name: 'Deodorant and Antiperspirant',
              tier: 3,
              subItems: [],
            },
            {
              id: '593',
              parentId: '590',
              name: 'Oral care',
              tier: 3,
              subItems: [],
            },
            {
              id: '594',
              parentId: '590',
              name: 'Shaving',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '595',
          parentId: '552',
          name: 'Street Style',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: '596',
      parentId: '',
      name: 'Technology & Computing',
      tier: 1,
      subItems: [
        {
          id: '597',
          parentId: '596',
          name: 'Artificial Intelligence',
          tier: 2,
          subItems: [],
        },
        {
          id: '598',
          parentId: '596',
          name: 'Augmented Reality',
          tier: 2,
          subItems: [],
        },
        {
          id: '599',
          parentId: '596',
          name: 'Computing',
          tier: 2,
          subItems: [
            {
              id: '600',
              parentId: '599',
              name: 'Computer Networking',
              tier: 3,
              subItems: [],
            },
            {
              id: '601',
              parentId: '599',
              name: 'Computer Peripherals',
              tier: 3,
              subItems: [],
            },
            {
              id: '602',
              parentId: '599',
              name: 'Computer Software and Applications',
              tier: 3,
              subItems: [
                {
                  id: '603',
                  parentId: '602',
                  name: '3-D Graphics',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '604',
                  parentId: '602',
                  name: 'Photo Editing Software',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '605',
                  parentId: '602',
                  name: 'Shareware and Freeware',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '606',
                  parentId: '602',
                  name: 'Video Software',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '607',
                  parentId: '602',
                  name: 'Web Conferencing',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '608',
                  parentId: '602',
                  name: 'Antivirus Software',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '609',
                  parentId: '602',
                  name: 'Browsers',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '610',
                  parentId: '602',
                  name: 'Computer Animation',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '611',
                  parentId: '602',
                  name: 'Databases',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '612',
                  parentId: '602',
                  name: 'Desktop Publishing',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '613',
                  parentId: '602',
                  name: 'Digital Audio',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '614',
                  parentId: '602',
                  name: 'Graphics Software',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '615',
                  parentId: '602',
                  name: 'Operating Systems',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '80DV8O',
                  parentId: '602',
                  name: 'Communication',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: 'WQC6HR',
                  parentId: '602',
                  name: 'Maps & Navigation',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: 'W3CW2J',
                  parentId: '602',
                  name: 'Productivity',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '616',
              parentId: '599',
              name: 'Data Storage and Warehousing',
              tier: 3,
              subItems: [],
            },
            {
              id: '617',
              parentId: '599',
              name: 'Desktops',
              tier: 3,
              subItems: [],
            },
            {
              id: '618',
              parentId: '599',
              name: 'Information and Network Security',
              tier: 3,
              subItems: [],
            },
            {
              id: '619',
              parentId: '599',
              name: 'Internet',
              tier: 3,
              subItems: [
                {
                  id: '620',
                  parentId: '619',
                  name: 'Cloud Computing',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '621',
                  parentId: '619',
                  name: 'Web Development',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '622',
                  parentId: '619',
                  name: 'Web Hosting',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '623',
                  parentId: '619',
                  name: 'Email',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '624',
                  parentId: '619',
                  name: 'Internet for Beginners',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '625',
                  parentId: '619',
                  name: 'Internet of Things',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '626',
                  parentId: '619',
                  name: 'IT and Internet Support',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '627',
                  parentId: '619',
                  name: 'Search',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '628',
                  parentId: '619',
                  name: 'Social Networking',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '629',
                  parentId: '619',
                  name: 'Web Design and HTML',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '630',
              parentId: '599',
              name: 'Laptops',
              tier: 3,
              subItems: [],
            },
            {
              id: '631',
              parentId: '599',
              name: 'Programming Languages',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '632',
          parentId: '596',
          name: 'Consumer Electronics',
          tier: 2,
          subItems: [
            {
              id: '633',
              parentId: '632',
              name: 'Cameras and Camcorders',
              tier: 3,
              subItems: [],
            },
            {
              id: '634',
              parentId: '632',
              name: 'Home Entertainment Systems',
              tier: 3,
              subItems: [],
            },
            {
              id: '635',
              parentId: '632',
              name: 'Smartphones',
              tier: 3,
              subItems: [],
            },
            {
              id: '636',
              parentId: '632',
              name: 'Tablets and E-readers',
              tier: 3,
              subItems: [],
            },
            {
              id: '637',
              parentId: '632',
              name: 'Wearable Technology',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '638',
          parentId: '596',
          name: 'Robotics',
          tier: 2,
          subItems: [],
        },
        {
          id: '639',
          parentId: '596',
          name: 'Virtual & Augmented Reality',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: 'SPSHQ5',
      parentId: '',
      name: 'Genres',
      tier: 1,
      subItems: [
        {
          id: '641',
          parentId: 'SPSHQ5',
          name: 'Animation & Anime',
          tier: 2,
          subItems: [],
        },
        {
          id: '642',
          parentId: 'SPSHQ5',
          name: 'Soap Opera',
          tier: 2,
          subItems: [],
        },
        {
          id: '643',
          parentId: 'SPSHQ5',
          name: 'Special Interest (Indie/Art House)',
          tier: 2,
          subItems: [],
        },
        {
          id: '645',
          parentId: 'SPSHQ5',
          name: 'Family/Children',
          tier: 2,
          subItems: [],
        },
        {
          id: '646',
          parentId: 'SPSHQ5',
          name: 'Comedy',
          tier: 2,
          subItems: [],
        },
        {
          id: '647',
          parentId: 'SPSHQ5',
          name: 'Drama',
          tier: 2,
          subItems: [],
        },
        {
          id: '648',
          parentId: 'SPSHQ5',
          name: 'Factual',
          tier: 2,
          subItems: [],
        },
        {
          id: '651',
          parentId: 'SPSHQ5',
          name: 'Reality TV',
          tier: 2,
          subItems: [],
        },
        {
          id: '652',
          parentId: 'SPSHQ5',
          name: 'Science Fiction',
          tier: 2,
          subItems: [],
        },
        {
          id: '325',
          parentId: 'SPSHQ5',
          name: 'Action/Adventure',
          tier: 2,
          subItems: [],
        },
        {
          id: '326',
          parentId: 'SPSHQ5',
          name: 'Romance',
          tier: 2,
          subItems: [],
        },
        {
          id: '331',
          parentId: 'SPSHQ5',
          name: 'Mystery',
          tier: 2,
          subItems: [],
        },
        {
          id: '332',
          parentId: 'SPSHQ5',
          name: 'Documentary',
          tier: 2,
          subItems: [],
        },
        {
          id: '336',
          parentId: 'SPSHQ5',
          name: 'Horror',
          tier: 2,
          subItems: [],
        },
        {
          id: 'EZWB7V',
          parentId: 'SPSHQ5',
          name: 'History',
          tier: 2,
          subItems: [],
        },
        {
          id: 'TIFQA5',
          parentId: 'SPSHQ5',
          name: 'Lifestyle',
          tier: 2,
          subItems: [],
        },
        {
          id: 'A0AH3G',
          parentId: 'SPSHQ5',
          name: 'Talk Show',
          tier: 2,
          subItems: [],
        },
        {
          id: '',
          parentId: 'SPSHQ5',
          name: 'Western',
          tier: 2,
          subItems: [
            {
              id: '1',
              parentId: '',
              name: 'Automotive',
              tier: 3,
              subItems: [
                {
                  id: '2',
                  parentId: '1',
                  name: 'Auto Body Styles',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '16',
                  parentId: '1',
                  name: 'Auto Type',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '25',
                  parentId: '1',
                  name: 'Car Culture',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '26',
                  parentId: '1',
                  name: 'Dash Cam Videos',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '27',
                  parentId: '1',
                  name: 'Motorcycles',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '28',
                  parentId: '1',
                  name: 'Road-Side Assistance',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '29',
                  parentId: '1',
                  name: 'Scooters',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '30',
                  parentId: '1',
                  name: 'Auto Buying and Selling',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '31',
                  parentId: '1',
                  name: 'Auto Insurance',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '32',
                  parentId: '1',
                  name: 'Auto Parts',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '33',
                  parentId: '1',
                  name: 'Auto Recalls',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '34',
                  parentId: '1',
                  name: 'Auto Repair',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '35',
                  parentId: '1',
                  name: 'Auto Safety',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '36',
                  parentId: '1',
                  name: 'Auto Shows',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '37',
                  parentId: '1',
                  name: 'Auto Technology',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '41',
                  parentId: '1',
                  name: 'Auto Rentals',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '42',
              parentId: '',
              name: 'Books and Literature',
              tier: 3,
              subItems: [
                {
                  id: '43',
                  parentId: '42',
                  name: 'Art and Photography',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '46',
                  parentId: '42',
                  name: 'Comics and Graphic Novels',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '48',
                  parentId: '42',
                  name: 'Fiction',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '49',
                  parentId: '42',
                  name: 'Poetry',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '52',
              parentId: '',
              name: 'Business and Finance',
              tier: 3,
              subItems: [
                {
                  id: '53',
                  parentId: '52',
                  name: 'Business',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '80',
                  parentId: '52',
                  name: 'Economy',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '90',
                  parentId: '52',
                  name: 'Industries',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '123',
              parentId: '',
              name: 'Careers',
              tier: 3,
              subItems: [
                {
                  id: '124',
                  parentId: '123',
                  name: 'Apprenticeships',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '125',
                  parentId: '123',
                  name: 'Career Advice',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '126',
                  parentId: '123',
                  name: 'Career Planning',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '127',
                  parentId: '123',
                  name: 'Job Search',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '130',
                  parentId: '123',
                  name: 'Remote Working',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '131',
                  parentId: '123',
                  name: 'Vocational Training',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '132',
              parentId: '',
              name: 'Education',
              tier: 3,
              subItems: [
                {
                  id: '133',
                  parentId: '132',
                  name: 'Adult Education',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '134',
                  parentId: '132',
                  name: 'Private School',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '135',
                  parentId: '132',
                  name: 'Secondary Education',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '136',
                  parentId: '132',
                  name: 'Special Education',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '137',
                  parentId: '132',
                  name: 'College Education',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '142',
                  parentId: '132',
                  name: 'Early Childhood Education',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '143',
                  parentId: '132',
                  name: 'Educational Assessment',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '145',
                  parentId: '132',
                  name: 'Homeschooling',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '146',
                  parentId: '132',
                  name: 'Homework and Study',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '147',
                  parentId: '132',
                  name: 'Language Learning',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '148',
                  parentId: '132',
                  name: 'Online Education',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '149',
                  parentId: '132',
                  name: 'Primary Education',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '8VZQHL',
              parentId: '',
              name: 'Events',
              tier: 3,
              subItems: [
                {
                  id: '162',
                  parentId: '8VZQHL',
                  name: 'Awards Shows',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '180',
                  parentId: '8VZQHL',
                  name: 'Business Expos & Conferences',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '185',
                  parentId: '8VZQHL',
                  name: 'Fan Conventions',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '1KXCLD',
              parentId: '',
              name: 'Holidays',
              tier: 3,
              subItems: [
                {
                  id: '157',
                  parentId: '1KXCLD',
                  name: 'National & Civic Holidays',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '201',
              parentId: '',
              name: 'Fine Art',
              tier: 3,
              subItems: [
                {
                  id: '202',
                  parentId: '201',
                  name: 'Costume',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '203',
                  parentId: '201',
                  name: 'Dance',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '204',
                  parentId: '201',
                  name: 'Design',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '205',
                  parentId: '201',
                  name: 'Digital Arts',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '206',
                  parentId: '201',
                  name: 'Fine Art Photography',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '207',
                  parentId: '201',
                  name: 'Modern Art',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '208',
                  parentId: '201',
                  name: 'Opera',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '209',
                  parentId: '201',
                  name: 'Theater',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '210',
              parentId: '',
              name: 'Food & Drink',
              tier: 3,
              subItems: [
                {
                  id: '211',
                  parentId: '210',
                  name: 'Alcoholic Beverages',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '212',
                  parentId: '210',
                  name: 'Vegan Diets',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '213',
                  parentId: '210',
                  name: 'Vegetarian Diets',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '214',
                  parentId: '210',
                  name: 'World Cuisines',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '215',
                  parentId: '210',
                  name: 'Barbecues and Grilling',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '216',
                  parentId: '210',
                  name: 'Cooking',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '217',
                  parentId: '210',
                  name: 'Desserts and Baking',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '218',
                  parentId: '210',
                  name: 'Dining Out',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '219',
                  parentId: '210',
                  name: 'Food Allergies',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '220',
                  parentId: '210',
                  name: 'Food Movements',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '221',
                  parentId: '210',
                  name: 'Healthy Cooking and Eating',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '222',
                  parentId: '210',
                  name: 'Non-Alcoholic Beverages',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '223',
              parentId: '',
              name: 'Healthy Living',
              tier: 3,
              subItems: [
                {
                  id: '224',
                  parentId: '223',
                  name: "Children's Health",
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '225',
                  parentId: '223',
                  name: 'Fitness and Exercise',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '228',
                  parentId: '223',
                  name: "Men's Health",
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '229',
                  parentId: '223',
                  name: 'Nutrition',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '230',
                  parentId: '223',
                  name: 'Senior Health',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '231',
                  parentId: '223',
                  name: 'Weight Loss',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '232',
                  parentId: '223',
                  name: 'Wellness',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '238',
                  parentId: '223',
                  name: "Women's Health",
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '239',
              parentId: '',
              name: 'Hobbies & Interests',
              tier: 3,
              subItems: [
                {
                  id: '240',
                  parentId: '239',
                  name: 'Antiquing and Antiques',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '241',
                  parentId: '239',
                  name: 'Magic and Illusion',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '242',
                  parentId: '239',
                  name: 'Model Toys',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '243',
                  parentId: '239',
                  name: 'Musical Instruments',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '244',
                  parentId: '239',
                  name: 'Paranormal Phenomena',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '245',
                  parentId: '239',
                  name: 'Radio Control',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '246',
                  parentId: '239',
                  name: 'Sci-fi and Fantasy',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '247',
                  parentId: '239',
                  name: 'Workshops and Classes',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '248',
                  parentId: '239',
                  name: 'Arts and Crafts',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '258',
                  parentId: '239',
                  name: 'Beekeeping',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '259',
                  parentId: '239',
                  name: 'Birdwatching',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '260',
                  parentId: '239',
                  name: 'Cigars',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '261',
                  parentId: '239',
                  name: 'Collecting',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '264',
                  parentId: '239',
                  name: 'Content Production',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '269',
                  parentId: '239',
                  name: 'Games and Puzzles',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '273',
                  parentId: '239',
                  name: 'Genealogy and Ancestry',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '274',
              parentId: '',
              name: 'Home & Garden',
              tier: 3,
              subItems: [
                {
                  id: '275',
                  parentId: '274',
                  name: 'Gardening',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '276',
                  parentId: '274',
                  name: 'Remodeling & Construction',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '277',
                  parentId: '274',
                  name: 'Smart Home',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '278',
                  parentId: '274',
                  name: 'Home Appliances',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '279',
                  parentId: '274',
                  name: 'Home Entertaining',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '280',
                  parentId: '274',
                  name: 'Home Improvement',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '281',
                  parentId: '274',
                  name: 'Home Security',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '282',
                  parentId: '274',
                  name: 'Indoor Environmental Quality',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '283',
                  parentId: '274',
                  name: 'Interior Decorating',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '284',
                  parentId: '274',
                  name: 'Landscaping',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '285',
                  parentId: '274',
                  name: 'Outdoor Decorating',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '286',
              parentId: '',
              name: 'Medical Health',
              tier: 3,
              subItems: [
                {
                  id: '287',
                  parentId: '286',
                  name: 'Diseases and Conditions',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '319',
                  parentId: '286',
                  name: 'Medical Tests',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '320',
                  parentId: '286',
                  name: 'Pharmaceutical Drugs',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '321',
                  parentId: '286',
                  name: 'Surgery',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '322',
                  parentId: '286',
                  name: 'Vaccines',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '323',
                  parentId: '286',
                  name: 'Cosmetic Medical Services',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: 'JLBCU7',
              parentId: '',
              name: 'Entertainment',
              tier: 3,
              subItems: [
                {
                  id: '324',
                  parentId: 'JLBCU7',
                  name: 'Movies',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '338',
                  parentId: 'JLBCU7',
                  name: 'Music',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '640',
                  parentId: 'JLBCU7',
                  name: 'Television',
                  tier: 4,
                  subItems: [
                    {
                      id: '641',
                      parentId: '640',
                      name: 'Animation TV',
                      tier: 5,
                      subItems: [],
                    },
                    {
                      id: '642',
                      parentId: '640',
                      name: 'Soap Opera TV',
                      tier: 5,
                      subItems: [],
                    },
                    {
                      id: '643',
                      parentId: '640',
                      name: 'Special Interest TV',
                      tier: 5,
                      subItems: [],
                    },
                    {
                      id: '644',
                      parentId: '640',
                      name: 'Sports TV',
                      tier: 5,
                      subItems: [],
                    },
                    {
                      id: '645',
                      parentId: '640',
                      name: "Children's TV",
                      tier: 5,
                      subItems: [],
                    },
                    {
                      id: '646',
                      parentId: '640',
                      name: 'Comedy TV',
                      tier: 5,
                      subItems: [],
                    },
                    {
                      id: '647',
                      parentId: '640',
                      name: 'Drama TV',
                      tier: 5,
                      subItems: [],
                    },
                    {
                      id: '648',
                      parentId: '640',
                      name: 'Factual TV',
                      tier: 5,
                      subItems: [],
                    },
                    {
                      id: '649',
                      parentId: '640',
                      name: 'Holiday TV',
                      tier: 5,
                      subItems: [],
                    },
                    {
                      id: '650',
                      parentId: '640',
                      name: 'Music TV',
                      tier: 5,
                      subItems: [],
                    },
                    {
                      id: '651',
                      parentId: '640',
                      name: 'Reality TV',
                      tier: 5,
                      subItems: [],
                    },
                    {
                      id: '652',
                      parentId: '640',
                      name: 'Science Fiction TV',
                      tier: 5,
                      subItems: [],
                    },
                  ],
                },
              ],
            },
            {
              id: '380',
              parentId: '',
              name: 'Crime',
              tier: 3,
              subItems: [],
            },
            {
              id: '381',
              parentId: '',
              name: 'Disasters',
              tier: 3,
              subItems: [],
            },
            {
              id: '383',
              parentId: '',
              name: 'Law',
              tier: 3,
              subItems: [],
            },
            {
              id: '386',
              parentId: '',
              name: 'Politics',
              tier: 3,
              subItems: [
                {
                  id: '387',
                  parentId: '386',
                  name: 'Elections',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '388',
                  parentId: '386',
                  name: 'Political Issues & Policy',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '8YPBBL',
                  parentId: '386',
                  name: 'Civic affairs',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '389',
              parentId: '',
              name: 'War and Conflicts',
              tier: 3,
              subItems: [],
            },
            {
              id: '391',
              parentId: '',
              name: 'Personal Finance',
              tier: 3,
              subItems: [
                {
                  id: '392',
                  parentId: '391',
                  name: 'Consumer Banking',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '393',
                  parentId: '391',
                  name: 'Financial Assistance',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '396',
                  parentId: '391',
                  name: 'Financial Planning',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '397',
                  parentId: '391',
                  name: 'Frugal Living',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '398',
                  parentId: '391',
                  name: 'Insurance',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '405',
                  parentId: '391',
                  name: 'Personal Debt',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '410',
                  parentId: '391',
                  name: 'Personal Investing',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '415',
                  parentId: '391',
                  name: 'Personal Taxes',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '416',
                  parentId: '391',
                  name: 'Retirement Planning',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '417',
                  parentId: '391',
                  name: 'Home Utilities',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '422',
              parentId: '',
              name: 'Pets',
              tier: 3,
              subItems: [
                {
                  id: '423',
                  parentId: '422',
                  name: 'Birds',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '424',
                  parentId: '422',
                  name: 'Cats',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '425',
                  parentId: '422',
                  name: 'Dogs',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '426',
                  parentId: '422',
                  name: 'Fish and Aquariums',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '427',
                  parentId: '422',
                  name: 'Large Animals',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '428',
                  parentId: '422',
                  name: 'Pet Adoptions',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '429',
                  parentId: '422',
                  name: 'Reptiles',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '430',
                  parentId: '422',
                  name: 'Veterinary Medicine',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '431',
                  parentId: '422',
                  name: 'Pet Supplies',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '432',
              parentId: '',
              name: 'Pop Culture',
              tier: 3,
              subItems: [
                {
                  id: '433',
                  parentId: '432',
                  name: 'Celebrity Deaths',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '434',
                  parentId: '432',
                  name: 'Celebrity Families',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '435',
                  parentId: '432',
                  name: 'Celebrity Homes',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '436',
                  parentId: '432',
                  name: 'Celebrity Pregnancy',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '437',
                  parentId: '432',
                  name: 'Celebrity Relationships',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '438',
                  parentId: '432',
                  name: 'Celebrity Scandal',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '439',
                  parentId: '432',
                  name: 'Celebrity Style',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '440',
                  parentId: '432',
                  name: 'Humor and Satire',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '441',
              parentId: '',
              name: 'Real Estate',
              tier: 3,
              subItems: [
                {
                  id: '442',
                  parentId: '441',
                  name: 'Apartments',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '443',
                  parentId: '441',
                  name: 'Retail Property',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '444',
                  parentId: '441',
                  name: 'Vacation Properties',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '445',
                  parentId: '441',
                  name: 'Developmental Sites',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '446',
                  parentId: '441',
                  name: 'Hotel Properties',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '447',
                  parentId: '441',
                  name: 'Houses',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '448',
                  parentId: '441',
                  name: 'Industrial Property',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '449',
                  parentId: '441',
                  name: 'Land and Farms',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '450',
                  parentId: '441',
                  name: 'Office Property',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '451',
                  parentId: '441',
                  name: 'Real Estate Buying and Selling',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '452',
                  parentId: '441',
                  name: 'Real Estate Renting and Leasing',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '453',
              parentId: '',
              name: 'Religion & Spirituality',
              tier: 3,
              subItems: [
                {
                  id: '454',
                  parentId: '453',
                  name: 'Agnosticism',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '455',
                  parentId: '453',
                  name: 'Spirituality',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '456',
                  parentId: '453',
                  name: 'Astrology',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '457',
                  parentId: '453',
                  name: 'Atheism',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '458',
                  parentId: '453',
                  name: 'Buddhism',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '459',
                  parentId: '453',
                  name: 'Christianity',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '460',
                  parentId: '453',
                  name: 'Hinduism',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '461',
                  parentId: '453',
                  name: 'Islam',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '462',
                  parentId: '453',
                  name: 'Judaism',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '463',
                  parentId: '453',
                  name: 'Sikhism',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '464',
              parentId: '',
              name: 'Science',
              tier: 3,
              subItems: [
                {
                  id: '465',
                  parentId: '464',
                  name: 'Biological Sciences',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '466',
                  parentId: '464',
                  name: 'Chemistry',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '467',
                  parentId: '464',
                  name: 'Environment',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '468',
                  parentId: '464',
                  name: 'Genetics',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '469',
                  parentId: '464',
                  name: 'Geography',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '470',
                  parentId: '464',
                  name: 'Geology',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '471',
                  parentId: '464',
                  name: 'Physics',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '472',
                  parentId: '464',
                  name: 'Space and Astronomy',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '390',
                  parentId: '464',
                  name: 'Weather',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '473',
              parentId: '',
              name: 'Shopping',
              tier: 3,
              subItems: [
                {
                  id: '474',
                  parentId: '473',
                  name: 'Coupons and Discounts',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '475',
                  parentId: '473',
                  name: 'Flower Shopping',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '476',
                  parentId: '473',
                  name: 'Gifts and Greetings Cards',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '477',
                  parentId: '473',
                  name: 'Grocery Shopping',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '478',
                  parentId: '473',
                  name: 'Holiday Shopping',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '479',
                  parentId: '473',
                  name: 'Household Supplies',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '480',
                  parentId: '473',
                  name: 'Lotteries and Scratchcards',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '481',
                  parentId: '473',
                  name: 'Sales and Promotions',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '482',
                  parentId: '473',
                  name: "Children's Games and Toys",
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '483',
              parentId: '',
              name: 'Sports',
              tier: 3,
              subItems: [
                {
                  id: '484',
                  parentId: '483',
                  name: 'American Football',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '485',
                  parentId: '483',
                  name: 'Boxing',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '486',
                  parentId: '483',
                  name: 'Cheerleading',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '487',
                  parentId: '483',
                  name: 'College Sports',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '491',
                  parentId: '483',
                  name: 'Cricket',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '492',
                  parentId: '483',
                  name: 'Cycling',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '493',
                  parentId: '483',
                  name: 'Darts',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '494',
                  parentId: '483',
                  name: 'Disabled Sports',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '495',
                  parentId: '483',
                  name: 'Diving',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '496',
                  parentId: '483',
                  name: 'Equine Sports',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '497',
                  parentId: '483',
                  name: 'Horse Racing',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '498',
                  parentId: '483',
                  name: 'Extreme Sports',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '507',
                  parentId: '483',
                  name: 'Australian Rules Football',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '508',
                  parentId: '483',
                  name: 'Fantasy Sports',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '509',
                  parentId: '483',
                  name: 'Field Hockey',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '510',
                  parentId: '483',
                  name: 'Figure Skating',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '511',
                  parentId: '483',
                  name: 'Fishing Sports',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '512',
                  parentId: '483',
                  name: 'Golf',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '513',
                  parentId: '483',
                  name: 'Gymnastics',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '514',
                  parentId: '483',
                  name: 'Hunting and Shooting',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '515',
                  parentId: '483',
                  name: 'Ice Hockey',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '516',
                  parentId: '483',
                  name: 'Inline Skating',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '517',
                  parentId: '483',
                  name: 'Lacrosse',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '518',
                  parentId: '483',
                  name: 'Auto Racing',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '520',
                  parentId: '483',
                  name: 'Martial Arts',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '521',
                  parentId: '483',
                  name: 'Olympic Sports',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '524',
                  parentId: '483',
                  name: 'Poker and Professional Gambling',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '525',
                  parentId: '483',
                  name: 'Rodeo',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '526',
                  parentId: '483',
                  name: 'Rowing',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '527',
                  parentId: '483',
                  name: 'Rugby',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '530',
                  parentId: '483',
                  name: 'Sailing',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '531',
                  parentId: '483',
                  name: 'Skiing',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '532',
                  parentId: '483',
                  name: 'Snooker/Pool/Billiards',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '533',
                  parentId: '483',
                  name: 'Soccer',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '534',
                  parentId: '483',
                  name: 'Badminton',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '535',
                  parentId: '483',
                  name: 'Softball',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '536',
                  parentId: '483',
                  name: 'Squash',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '537',
                  parentId: '483',
                  name: 'Swimming',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '538',
                  parentId: '483',
                  name: 'Table Tennis',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '539',
                  parentId: '483',
                  name: 'Tennis',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '540',
                  parentId: '483',
                  name: 'Track and Field',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '541',
                  parentId: '483',
                  name: 'Volleyball',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '542',
                  parentId: '483',
                  name: 'Walking',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '543',
                  parentId: '483',
                  name: 'Water Polo',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '544',
                  parentId: '483',
                  name: 'Weightlifting',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '545',
                  parentId: '483',
                  name: 'Baseball',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '546',
                  parentId: '483',
                  name: 'Wrestling',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '547',
                  parentId: '483',
                  name: 'Basketball',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '548',
                  parentId: '483',
                  name: 'Beach Volleyball',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '549',
                  parentId: '483',
                  name: 'Bodybuilding',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '550',
                  parentId: '483',
                  name: 'Bowling',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '551',
                  parentId: '483',
                  name: 'Sports Equipment',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '552',
              parentId: '',
              name: 'Style & Fashion',
              tier: 3,
              subItems: [
                {
                  id: '553',
                  parentId: '552',
                  name: 'Beauty',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '560',
                  parentId: '552',
                  name: "Women's Fashion",
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '574',
                  parentId: '552',
                  name: 'Body Art',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '575',
                  parentId: '552',
                  name: "Children's Clothing",
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '576',
                  parentId: '552',
                  name: 'Designer Clothing',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '577',
                  parentId: '552',
                  name: 'Fashion Trends',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '578',
                  parentId: '552',
                  name: 'High Fashion',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '579',
                  parentId: '552',
                  name: "Men's Fashion",
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '590',
                  parentId: '552',
                  name: 'Personal Care',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '595',
                  parentId: '552',
                  name: 'Street Style',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '596',
              parentId: '',
              name: 'Technology & Computing',
              tier: 3,
              subItems: [
                {
                  id: '597',
                  parentId: '596',
                  name: 'Artificial Intelligence',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '598',
                  parentId: '596',
                  name: 'Augmented Reality',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '599',
                  parentId: '596',
                  name: 'Computing',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '632',
                  parentId: '596',
                  name: 'Consumer Electronics',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '638',
                  parentId: '596',
                  name: 'Robotics',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '639',
                  parentId: '596',
                  name: 'Virtual & Augmented Reality',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: 'SPSHQ5',
              parentId: '',
              name: 'Genres',
              tier: 3,
              subItems: [
                {
                  id: '641',
                  parentId: 'SPSHQ5',
                  name: 'Animation & Anime',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '642',
                  parentId: 'SPSHQ5',
                  name: 'Soap Opera',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '643',
                  parentId: 'SPSHQ5',
                  name: 'Special Interest (Indie/Art House)',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '645',
                  parentId: 'SPSHQ5',
                  name: 'Family/Children',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '646',
                  parentId: 'SPSHQ5',
                  name: 'Comedy',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '647',
                  parentId: 'SPSHQ5',
                  name: 'Drama',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '648',
                  parentId: 'SPSHQ5',
                  name: 'Factual',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '651',
                  parentId: 'SPSHQ5',
                  name: 'Reality TV',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '652',
                  parentId: 'SPSHQ5',
                  name: 'Science Fiction',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '325',
                  parentId: 'SPSHQ5',
                  name: 'Action/Adventure',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '326',
                  parentId: 'SPSHQ5',
                  name: 'Romance',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '331',
                  parentId: 'SPSHQ5',
                  name: 'Mystery',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '332',
                  parentId: 'SPSHQ5',
                  name: 'Documentary',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '336',
                  parentId: 'SPSHQ5',
                  name: 'Horror',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: 'EZWB7V',
                  parentId: 'SPSHQ5',
                  name: 'History',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: 'TIFQA5',
                  parentId: 'SPSHQ5',
                  name: 'Lifestyle',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: 'A0AH3G',
                  parentId: 'SPSHQ5',
                  name: 'Talk Show',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '',
                  parentId: 'SPSHQ5',
                  name: 'Western',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '335',
                  parentId: 'SPSHQ5',
                  name: 'Fantasy',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '156',
                  parentId: 'SPSHQ5',
                  name: 'Musicals',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '649',
                  parentId: 'SPSHQ5',
                  name: 'Holiday',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: 'VKIV56',
                  parentId: 'SPSHQ5',
                  name: 'Nature',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '370',
                  parentId: 'SPSHQ5',
                  name: 'Sports Radio',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '371',
                  parentId: 'SPSHQ5',
                  name: 'Talk Radio',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '376',
                  parentId: 'SPSHQ5',
                  name: 'Public Radio',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '44',
                  parentId: 'SPSHQ5',
                  name: 'Biographies',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '51',
                  parentId: 'SPSHQ5',
                  name: 'Young Adult',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: 'KHPC5A',
              parentId: '',
              name: 'True Crime',
              tier: 3,
              subItems: [],
            },
            {
              id: '653',
              parentId: '',
              name: 'Travel',
              tier: 3,
              subItems: [
                {
                  id: '654',
                  parentId: '653',
                  name: 'Travel Accessories',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '655',
                  parentId: '653',
                  name: 'Travel Locations',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '663',
                  parentId: '653',
                  name: 'Travel Preparation and Advice',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '664',
                  parentId: '653',
                  name: 'Travel Type',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: '680',
              parentId: '',
              name: 'Video Gaming',
              tier: 3,
              subItems: [
                {
                  id: '681',
                  parentId: '680',
                  name: 'Console Games',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '682',
                  parentId: '680',
                  name: 'eSports',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '683',
                  parentId: '680',
                  name: 'Mobile Games',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '684',
                  parentId: '680',
                  name: 'PC Games',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '685',
                  parentId: '680',
                  name: 'Video Game Genres',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
            {
              id: 'v9i3On',
              parentId: '',
              name: 'Sensitive Topics',
              tier: 3,
              subItems: [
                {
                  id: 'Rm3SiT',
                  parentId: 'v9i3On',
                  name: 'Adult & Explicit Sexual Content',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: 'avbNf2',
                  parentId: 'v9i3On',
                  name: 'Arms & Ammunition',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: 'XtODT3',
                  parentId: 'v9i3On',
                  name: 'Crime & Harmful Acts to Individuals, Society & Human Right Violations',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: 'I4GWl6',
                  parentId: 'v9i3On',
                  name: 'Death, Injury, or Military Conflict',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: 'mm3UXx',
                  parentId: 'v9i3On',
                  name: 'Online piracy ',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: 'HxqYV1',
                  parentId: 'v9i3On',
                  name: 'Hate speech & acts of aggression',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: 'j9PaO9',
                  parentId: 'v9i3On',
                  name: 'Obscenity and Profanity',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: 'pg0WhF',
                  parentId: 'v9i3On',
                  name: 'Illegal Drugs/Tobacco/eCigarettes/ Vaping/Alcohol',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '6i4dB6',
                  parentId: 'v9i3On',
                  name: 'Spam or Harmful Content',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: '8FD8nI',
                  parentId: 'v9i3On',
                  name: 'Terrorism',
                  tier: 4,
                  subItems: [],
                },
                {
                  id: 'Z7rJBM',
                  parentId: 'v9i3On',
                  name: 'Debated Sensitive Social Issues',
                  tier: 4,
                  subItems: [],
                },
              ],
            },
          ],
        },
        {
          id: '335',
          parentId: 'SPSHQ5',
          name: 'Fantasy',
          tier: 2,
          subItems: [],
        },
        {
          id: '156',
          parentId: 'SPSHQ5',
          name: 'Musicals',
          tier: 2,
          subItems: [],
        },
        {
          id: '649',
          parentId: 'SPSHQ5',
          name: 'Holiday',
          tier: 2,
          subItems: [],
        },
        {
          id: 'VKIV56',
          parentId: 'SPSHQ5',
          name: 'Nature',
          tier: 2,
          subItems: [],
        },
        {
          id: '370',
          parentId: 'SPSHQ5',
          name: 'Sports Radio',
          tier: 2,
          subItems: [],
        },
        {
          id: '371',
          parentId: 'SPSHQ5',
          name: 'Talk Radio',
          tier: 2,
          subItems: [],
        },
        {
          id: '376',
          parentId: 'SPSHQ5',
          name: 'Public Radio',
          tier: 2,
          subItems: [],
        },
        {
          id: '44',
          parentId: 'SPSHQ5',
          name: 'Biographies',
          tier: 2,
          subItems: [],
        },
        {
          id: '51',
          parentId: 'SPSHQ5',
          name: 'Young Adult',
          tier: 2,
          subItems: [],
        },
      ],
    },
    {
      id: 'KHPC5A',
      parentId: '',
      name: 'True Crime',
      tier: 1,
      subItems: [],
    },
    {
      id: '653',
      parentId: '',
      name: 'Travel',
      tier: 1,
      subItems: [
        {
          id: '654',
          parentId: '653',
          name: 'Travel Accessories',
          tier: 2,
          subItems: [],
        },
        {
          id: '655',
          parentId: '653',
          name: 'Travel Locations',
          tier: 2,
          subItems: [
            {
              id: '656',
              parentId: '655',
              name: 'Africa Travel',
              tier: 3,
              subItems: [],
            },
            {
              id: '657',
              parentId: '655',
              name: 'Asia Travel',
              tier: 3,
              subItems: [],
            },
            {
              id: '658',
              parentId: '655',
              name: 'Australia and Oceania Travel',
              tier: 3,
              subItems: [],
            },
            {
              id: '659',
              parentId: '655',
              name: 'Europe Travel',
              tier: 3,
              subItems: [],
            },
            {
              id: '660',
              parentId: '655',
              name: 'North America Travel',
              tier: 3,
              subItems: [],
            },
            {
              id: '661',
              parentId: '655',
              name: 'Polar Travel',
              tier: 3,
              subItems: [],
            },
            {
              id: '662',
              parentId: '655',
              name: 'South America Travel',
              tier: 3,
              subItems: [],
            },
          ],
        },
        {
          id: '663',
          parentId: '653',
          name: 'Travel Preparation and Advice',
          tier: 2,
          subItems: [],
        },
        {
          id: '664',
          parentId: '653',
          name: 'Travel Type',
          tier: 2,
          subItems: [
            {
              id: '665',
              parentId: '664',
              name: 'Adventure Travel',
              tier: 3,
              subItems: [],
            },
            {
              id: '666',
              parentId: '664',
              name: 'Family Travel',
              tier: 3,
              subItems: [],
            },
            {
              id: '667',
              parentId: '664',
              name: 'Honeymoons and Getaways',
              tier: 3,
              subItems: [],
            },
            {
              id: '668',
              parentId: '664',
              name: 'Hotels and Motels',
              tier: 3,
              subItems: [],
            },
            {
              id: '669',
              parentId: '664',
              name: 'Rail Travel',
              tier: 3,
              subItems: [],
            },
            {
              id: '670',
              parentId: '664',
              name: 'Road Trips',
              tier: 3,
              subItems: [],
            },
            {
              id: '671',
              parentId: '664',
              name: 'Spas',
              tier: 3,
              subItems: [],
            },
            {
              id: '672',
              parentId: '664',
              name: 'Air Travel',
              tier: 3,
              subItems: [],
            },
            {
              id: '673',
              parentId: '664',
              name: 'Beach Travel',
              tier: 3,
              subItems: [],
            },
            {
              id: '674',
              parentId: '664',
              name: 'Bed & Breakfasts',
              tier: 3,
              subItems: [],
            },
            {
              id: '675',
              parentId: '664',
              name: 'Budget Travel',
              tier: 3,
              subItems: [],
            },
            {
              id: '676',
              parentId: '664',
              name: 'Business Travel',
              tier: 3,
              subItems: [],
            },
            {
              id: '677',
              parentId: '664',
              name: 'Camping',
              tier: 3,
              subItems: [],
            },
            {
              id: '678',
              parentId: '664',
              name: 'Cruises',
              tier: 3,
              subItems: [],
            },
            {
              id: '679',
              parentId: '664',
              name: 'Day Trips',
              tier: 3,
              subItems: [],
            },
          ],
        },
      ],
    },
    {
      id: '680',
      parentId: '',
      name: 'Video Gaming',
      tier: 1,
      subItems: [
        {
          id: '681',
          parentId: '680',
          name: 'Console Games',
          tier: 2,
          subItems: [],
        },
        {
          id: '682',
          parentId: '680',
          name: 'eSports',
          tier: 2,
          subItems: [],
        },
        {
          id: '683',
          parentId: '680',
          name: 'Mobile Games',
          tier: 2,
          subItems: [],
        },
        {
          id: '684',
          parentId: '680',
          name: 'PC Games',
          tier: 2,
          subItems: [],
        },
        {
          id: '685',
          parentId: '680',
          name: 'Video Game Genres',
          tier: 2,
          subItems: [
            {
              id: '686',
              parentId: '685',
              name: 'Action Video Games',
              tier: 3,
              subItems: [],
            },
            {
              id: '687',
              parentId: '685',
              name: 'Role-Playing Video Games',
              tier: 3,
              subItems: [],
            },
            {
              id: '688',
              parentId: '685',
              name: 'Simulation Video Games',
              tier: 3,
              subItems: [],
            },
            {
              id: '689',
              parentId: '685',
              name: 'Sports Video Games',
              tier: 3,
              subItems: [],
            },
            {
              id: '690',
              parentId: '685',
              name: 'Strategy Video Games',
              tier: 3,
              subItems: [],
            },
            {
              id: '691',
              parentId: '685',
              name: 'Action-Adventure Video Games',
              tier: 3,
              subItems: [],
            },
            {
              id: '692',
              parentId: '685',
              name: 'Adventure Video Games',
              tier: 3,
              subItems: [],
            },
            {
              id: '693',
              parentId: '685',
              name: 'Casual Games',
              tier: 3,
              subItems: [],
            },
            {
              id: '694',
              parentId: '685',
              name: 'Educational Video Games',
              tier: 3,
              subItems: [],
            },
            {
              id: '695',
              parentId: '685',
              name: 'Exercise and Fitness Video Games',
              tier: 3,
              subItems: [],
            },
            {
              id: '696',
              parentId: '685',
              name: 'MMOs',
              tier: 3,
              subItems: [],
            },
            {
              id: '697',
              parentId: '685',
              name: 'Music and Party Video Games',
              tier: 3,
              subItems: [],
            },
            {
              id: '698',
              parentId: '685',
              name: 'Puzzle Video Games',
              tier: 3,
              subItems: [],
            },
            {
              id: 'MQ2XML',
              parentId: '685',
              name: 'Adult Video Games',
              tier: 3,
              subItems: [],
            },
            {
              id: 'ZJG29S',
              parentId: '685',
              name: 'Casino and Gambling Video Games',
              tier: 3,
              subItems: [],
            },
            {
              id: 'VWGKS7',
              parentId: '685',
              name: 'Family Video Games',
              tier: 3,
              subItems: [],
            },
            {
              id: 'II436J',
              parentId: '685',
              name: 'Horror Video Games',
              tier: 3,
              subItems: [],
            },
            {
              id: 'VK7KD0',
              parentId: '685',
              name: 'Racing Video Games',
              tier: 3,
              subItems: [],
            },
          ],
        },
      ],
    },
    {
      id: 'v9i3On',
      parentId: '',
      name: 'Sensitive Topics',
      tier: 1,
      subItems: [
        {
          id: 'Rm3SiT',
          parentId: 'v9i3On',
          name: 'Adult & Explicit Sexual Content',
          tier: 2,
          subItems: [],
        },
        {
          id: 'avbNf2',
          parentId: 'v9i3On',
          name: 'Arms & Ammunition',
          tier: 2,
          subItems: [],
        },
        {
          id: 'XtODT3',
          parentId: 'v9i3On',
          name: 'Crime & Harmful Acts to Individuals, Society & Human Right Violationss',
          tier: 2,
          subItems: [],
        },
        {
          id: 'I4GWl6',
          parentId: 'v9i3On',
          name: 'Death, Injury, or Military Conflict',
          tier: 2,
          subItems: [],
        },
        {
          id: 'mm3UXx',
          parentId: 'v9i3On',
          name: 'Online piracy ',
          tier: 2,
          subItems: [],
        },
        {
          id: 'HxqYV1',
          parentId: 'v9i3On',
          name: 'Hate speech & acts of aggression',
          tier: 2,
          subItems: [],
        },
        {
          id: 'j9PaO9',
          parentId: 'v9i3On',
          name: 'Obscenity and Profanity',
          tier: 2,
          subItems: [],
        },
        {
          id: 'pg0WhF',
          parentId: 'v9i3On',
          name: 'Illegal Drugs/Tobacco/eCigarettes/ Vaping/Alcohol',
          tier: 2,
          subItems: [],
        },
        {
          id: '6i4dB6',
          parentId: 'v9i3On',
          name: 'Spam or Harmful Content',
          tier: 2,
          subItems: [],
        },
        {
          id: '8FD8nI',
          parentId: 'v9i3On',
          name: 'Terrorism',
          tier: 2,
          subItems: [],
        },
        {
          id: 'Z7rJBM',
          parentId: 'v9i3On',
          name: 'Debated Sensitive Social Issues',
          tier: 2,
          subItems: [],
        },
      ],
    },
  ],
};
