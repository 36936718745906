/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import React from 'react';
import { ReactComponent as SearchNone } from 'assets/Icons/search_off.svg';
import { useDispatch, useSelector } from 'react-redux';
import { resetAdvancedSearch } from 'slices/advanced-search.slice';
import { handleSearchClear } from 'actions';
import { toggleReloadLibrary } from 'slices/toggle.slice';
import { isEmpty } from 'lodash';
import { RootState } from 'reducers';

type Props = {};

export const NoSearchResults = (props: Props) => {
  const dispatch = useDispatch();

  const library = useSelector((state: RootState) => state.library);
  const advancedSearch = useSelector(
    (state: RootState) => state.advancedSearch,
  );

  const searchParams = advancedSearch.searchParams;

  const isAdvancedSearch =
    library.searchTerm ||
    searchParams.uploadDate ||
    searchParams.customDate ||
    searchParams.type ||
    searchParams.duration ||
    !isEmpty(searchParams.speakerNames) ||
    !isEmpty(searchParams.searchValues);

  const resetSearchFilter = () => {
    if (!isAdvancedSearch) return;
    
    dispatch(handleSearchClear());
    dispatch(resetAdvancedSearch());
    dispatch(toggleReloadLibrary());
  };

  return (
    <div tw="w-full h-full flex justify-center items-center">
      <div tw="flex justify-center flex-col text-sonnant-dark items-center">
        <SearchNone tw="height[12rem] width[12rem] fill[#7f8090]" />
        <div tw="font-medium font-size[2.4rem] mt-3">No results found.</div>
        <div tw="mt-5 mb-8 text-16">
          Try to clear your filters and try again.
        </div>
        <a tw="text-16 hover:(underline)" onClick={resetSearchFilter}>
          Clear filters
        </a>
      </div>
    </div>
  );
};
