/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { RichTextEditor } from 'components/shared/RichTextEditor/RichTextEditor';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { useEvent } from 'react-use';
import { RootState } from 'reducers';
import { useEmbedContext } from '../../utils/useEmbedContext';

type Props = {};

export const EmbedCaptionViewer = (props: Props) => {
  const mediaPlayer = useEmbedContext();

  const player = useSelector((state: RootState) => state.player);

  const [currentTime, setCurrentTime] = useState(0);

  const handleVideoTimeUpdate = () => {
    setCurrentTime(mediaPlayer.videoState.time);
  };

  useEvent('timeupdate', handleVideoTimeUpdate, window.Video);

  const [partialSection, setPartialSection] = useState(player?.caption);

  useEffect(() => {
    if (isEmpty(player.caption)) return;

    setPartialSection(player.caption);
  }, [player.caption]);

  return (
    <ErrorBoundary
      fallback={
        <div tw="font-medium text-sonnant-grey-6 text-16 mt-5">
          Cannot load content item's transcripts
        </div>
      }
      onError={(e) => console.log('Caption Error:', e)}
    >
      <RichTextEditor
        document={partialSection}
        videoTimePosition={currentTime}
        setDocument={setPartialSection}
      />
    </ErrorBoundary>
  );
};
