/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as AreaChartSvg } from 'assets/Icons/area_chart.svg';
import { ReactComponent as BarChartSvg } from 'assets/Icons/bar_chart.svg';
import { ReactComponent as CalendarDaySvg } from 'assets/Icons/calendar_day.svg';
import { ReactComponent as CalendarYearSvg } from 'assets/Icons/calendar_month.svg';
import { ReactComponent as CalendarWeekSvg } from 'assets/Icons/calendar_week.svg';
import { ReactComponent as CalendarMonthSvg } from 'assets/Icons/calendar_year.svg';
import { ReactComponent as LineChartSvg } from 'assets/Icons/line_chart.svg';
import Loader from 'components/loader/loader';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { HintDisallowed } from 'components/shared/HintDisallowed';
import { InsightChart } from 'components/shared/InsightChart/InsightChart';
import { LabelCheckbox } from 'components/shared/LabelCheckbox';
import { NoFilterInsights } from 'components/shared/NoFilterInsights';
import { NoResultInsights } from 'components/shared/NoResultInsights';
import { isEmpty, uniq, uniqBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToggle } from 'react-use';
import { TermSearchInsight } from 'services';
import { InsightService } from 'services/insight.service';
import {
  PinnableChart,
  patchPinnedChart,
  unpinChartId,
} from 'slices/insight.slice';
import { endOfDayISO, startOfDayISO } from 'utils/date.util';
import { ApexChartType, CalendarFilter, ModalOptions } from 'utils/enum';
import { ButtonItem } from 'utils/models';
import { BasicConfirmModal } from 'utils/models/modal.model';
import { typeMediaEntities } from 'utils/utils';
import { ButtonGroup } from '../../shared/ButtonGroup';
import { ChartPinTitle } from '../shared/ChartPinTitle';
import { InsightTag } from '../shared/InsightTag';

type Props = {
  pinnedInsight: PinnableChart;
};

export const PinnedChartItem = React.memo(({ pinnedInsight }: Props) => {
  const dispatch = useDispatch();

  const confirmModalRef = useRef<BasicConfirmModal>();

  const [chartData, setChartData] = useState<TermSearchInsight[]>([]);

  const [isFetching, toggleFetching] = useToggle(false);
  const [isEmptyList, toggleEmptyList] = useToggle(false);

  const combinedTerms = [
    ...pinnedInsight.vocabTerms,
    ...pinnedInsight.inputTerms,
  ];

  useEffect(() => {
    if (isEmpty(pinnedInsight)) return;

    handleGenerateAsync();
  }, [pinnedInsight.chartType, pinnedInsight.calendarMode]);

  const handleGenerateAsync = async (calendarMode?: CalendarFilter) => {
    try {
      toggleFetching(true);

      const activeTerms = uniqBy([...combinedTerms], 'name')
        .filter((term) => term.selected)
        .map((term) => term.name);

      const { data } = await InsightService.postInsightsAsync<
        TermSearchInsight[]
      >({
        category: typeMediaEntities(pinnedInsight.selectedLayer),
        terms: uniq(activeTerms),
        fromDate: startOfDayISO(pinnedInsight.dateRange.startDate),
        toDate: endOfDayISO(pinnedInsight.dateRange.endDate),
        calendarInterval: calendarMode ?? pinnedInsight.calendarMode,

        tagIds: pinnedInsight.selectedTagIds,
        collectionIds: pinnedInsight.selectedCollectionIds,
        orgIds: pinnedInsight.selectedOrgIds,
      });

      const isEmptyResult = data?.every((item) =>
        isEmpty(item?.term_count_over_time.buckets),
      );
      toggleEmptyList(isEmptyResult);

      setChartData(data);
    } catch (err) {
      console.log('err :>> ', err);
    } finally {
      toggleFetching(false);
    }
  };

  const handleChangeChartType = (itemGroup: ButtonItem) => {
    const newChartType = itemGroup.value as ApexChartType;

    setInsightOption('chartType', newChartType);
  };

  const handleChangeCalendarMode = (data: ButtonItem) => {
    const value = data.value as CalendarFilter;

    if ([CalendarFilter.DAY, CalendarFilter.WEEK].includes(value)) {
      setInsightOption('showDataLabels', false);
    }

    setInsightOption('calendarMode', value);
  };

  const handleToggleOption = (key: keyof PinnableChart) => () => {
    const payload = {
      id: pinnedInsight.id,
      [key]: !pinnedInsight[key],
    };

    dispatch(patchPinnedChart(payload));
  };

  const setInsightOption = <T extends keyof PinnableChart>(
    key: T,
    value: PinnableChart[T],
  ) => {
    const payload = {
      id: pinnedInsight.id,
      [key]: value,
    };

    dispatch(patchPinnedChart(payload));
  };

  const handleUnpin = async () => {
    const result = await confirmModalRef.current?.show();

    if (result !== ModalOptions.YES) return;

    const chartId = pinnedInsight.id;

    if (isEmpty(chartId)) return;

    dispatch(unpinChartId(chartId));
  };

  return (
    <div tw="mt-5">
      <ChartPinTitle title={pinnedInsight.title} onClickUnpin={handleUnpin} />

      <div tw="w-full mt-3 rounded border[1px solid] border-sonnant-grey-light shadow px-5 pb-5">
        <div tw="py-6 flex flex-wrap gap-4 relative z-index[1]">
          <div tw="flex flex-wrap gap-3 w-full">
            {uniqBy(combinedTerms, 'name').map((term) => (
              <InsightTag
                key={term.id}
                term={term}
                handleClickTag={() => {}}
                onRemove={() => {}}
                showMentionModal={() => {}}
                hasMentionPopover={false}
                isPinnedChart={true}
              />
            ))}
          </div>

          <div tw="w-full flex justify-end gap-x-5">
            <div tw="text-14 flex flex-1 gap-x-5">
              <LabelCheckbox
                label="Show data labels"
                checked={pinnedInsight.showDataLabels}
                onChange={handleToggleOption('showDataLabels')}
              />

              <LabelCheckbox
                label="Show quarter markers"
                checked={pinnedInsight.showQuarterMarkers}
                onChange={handleToggleOption('showQuarterMarkers')}
              />

              <HintDisallowed
                disabled={!isEmpty(pinnedInsight.selectedCollectionIds)}
                hintEnabled={`Show result by the number of collection: ${
                  pinnedInsight.groupByCollection ? 'ON' : 'OFF'
                }`}
                hintDisabled="Grouping by collection is only available for none-collection selected mode"
                tw="flex items-center"
                notTransparent
                arrow
              >
                <LabelCheckbox
                  label="Group by collection"
                  checked={pinnedInsight.groupByCollection}
                  onChange={handleToggleOption('groupByCollection')}
                  disabled={!isEmpty(pinnedInsight.selectedCollectionIds)}
                />
              </HintDisallowed>
            </div>

            <ButtonGroup
              items={[
                { icon: <LineChartSvg />, label: 'Line', value: 'line' },
                { icon: <BarChartSvg />, label: 'Bar', value: 'bar' },
                { icon: <AreaChartSvg />, label: 'Area', value: 'area' },
              ]}
              defaultValue={pinnedInsight.chartType}
              onChange={handleChangeChartType}
            />

            <ButtonGroup
              items={[
                {
                  icon: <CalendarDaySvg />,
                  label: 'Day',
                  value: CalendarFilter.DAY,
                },
                {
                  icon: <CalendarWeekSvg />,
                  label: 'Week',
                  value: CalendarFilter.WEEK,
                },
                {
                  icon: <CalendarMonthSvg />,
                  label: 'Month',
                  value: CalendarFilter.MONTH,
                },
                {
                  icon: <CalendarYearSvg />,
                  label: 'Year',
                  value: CalendarFilter.YEAR,
                },
              ]}
              defaultValue={pinnedInsight.calendarMode}
              onChange={handleChangeCalendarMode}
            />
          </div>
        </div>
        {isFetching && (
          <div tw="min-h-[40rem] flex justify-center my-3 items-center shadow rounded">
            <Loader />
          </div>
        )}
        {!isFetching &&
          (!isEmpty(chartData) && !isEmptyList ? (
            <div tw="px-10 min-h-[40rem] pt-6 shadow rounded border border-solid border-sonnant-grey-1 relative z-0">
              <InsightChart
                insightOptions={pinnedInsight}
                height={500}
                chartData={chartData}
                hiddenPinner
              />
            </div>
          ) : (
            <div tw="min-h-[40rem] flex items-center shadow border[1px solid] border-sonnant-grey-2">
              {isEmpty(pinnedInsight.vocabTerms) &&
              isEmpty(pinnedInsight.inputTerms) ? (
                <NoFilterInsights />
              ) : (
                <NoResultInsights />
              )}
            </div>
          ))}
      </div>

      <ConfirmModal
        title="Unpin this chart"
        message={
          <div>
            This action cannot be undone. <b>Would you like to proceed?</b>
          </div>
        }
        confirmText="Unpin"
        ref={confirmModalRef}
      />
    </div>
  );
});
