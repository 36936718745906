/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { handleSearchClear, setSelectedRows } from 'actions';
import { ApexOptions } from 'apexcharts';
import { format, getQuarter, getYear, isSameDay, toDate } from 'date-fns';
import { useAdvancedAutofill } from 'hooks/useAdvancedAutofill';
import { first, isEmpty, isNil, last, max, toNumber, uniqBy } from 'lodash';
import randomColor from 'randomcolor';
import React, { useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useDispatch } from 'react-redux';
import { BucketInsight, InsightKey, TermSearchInsight } from 'services';
import { resetAdvancedSearch } from 'slices/advanced-search.slice';
import { InsightState } from 'slices/insight.slice';
import { TrendingInsightState } from 'slices/trending-insight.slice';
import { match } from 'ts-pattern';
import { Duration } from 'utils/constants';
import {
  endOfDayISO,
  getDateBetween,
  getMonthAfter,
  getPreviousDays,
  getPreviousMonths,
  getPreviousWeeks,
  getTodayShort,
  getWeekAfter,
  getYearAfter,
  shortDate,
  shortDateYear,
  startOfDayISO,
} from 'utils/date.util';
import { CalendarFilter } from 'utils/enum';
import { singleOrNull } from 'utils/generic.util';
import { Insights } from 'utils/models';
import { typeMediaEntities } from 'utils/utils';
import { Info } from '../Info';

type Props = {
  insightOptions:
    | Omit<InsightState, 'pinnedCharts' | 'vocabListId'>
    | TrendingInsightState;
  chartData: TermSearchInsight[];
  height?: number;
  width?: number;
  hiddenPinner?: boolean;
  isTrendingChart?: boolean;
  handlePinChart?: () => void;
};

type ApexAxisDataChartSeries = {
  x: string;
  y: number;
};

export const InsightChart = React.memo(
  ({
    insightOptions,
    height,
    width,
    chartData,
    hiddenPinner = false,
    isTrendingChart = false,
    ...props
  }: Props) => {
    const dispatch = useDispatch();

    const autofillAdvancedSearchHook = useAdvancedAutofill();

    const [columnSeries, setColumnSeries] = useState<ApexAxisChartSeries>([]);

    const shouldShowDataLabels = insightOptions.showDataLabels;

    useEffect(() => {
      if (isEmpty(chartData) || isNil(chartData)) return;

      convertToColumnSeries(chartData);
    }, [chartData, insightOptions.groupByCollection]);

    const groupByQuarterMemo = useMemo(() => {
      const groups = uniqBy(columnSeries?.[0]?.data as any, 'group');

      return groups.map((g: any) => {
        const color = randomColor();

        return {
          x: new Date(g.x).getTime(),
          strokeDashArray: 0,
          borderColor: color,
          label: {
            borderColor: color,
            style: {
              color: '#fff',
              background: color,
            },
            text: g?.group,
          },
        };
      });
    }, [columnSeries]);

    const getXAxis = (): ApexXAxis => {
      if (isTrendingChart) {
        return {
          type: 'category',
          tickAmount: 'dataPoints',
        };
      }

      if (insightOptions.calendarMode === CalendarFilter.YEAR) {
        return {
          type: 'datetime',
          tickAmount: 'dataPoints',
          labels: {
            formatter: (value: string) => String(new Date(value).getFullYear()),
          },
        };
      }

      return { type: 'datetime' };
    };

    const sharedOptions: ApexOptions = {
      chart: {
        toolbar: {
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            customIcons: hiddenPinner
              ? []
              : [
                  {
                    icon: '<svg style="margin-top: 2px; margin-left: 2px;" fill="#6e8192" xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20"><path d="m624-480 96 96v72H516v228l-36 36-36-36v-228H240v-72l96-96v-264h-48v-72h384v72h-48v264Zm-282 96h276l-66-66v-294H408v294l-66 66Zm138 0Z"/></svg>',
                    index: 0,
                    title: 'Pin chart',
                    class: 'custom-icon',
                    click: function (chart, options, e) {
                      if (typeof props?.handlePinChart !== 'function') return;

                      props.handlePinChart();
                    },
                  },
                ],
          },
          export: {
            csv: {
              filename: `InsightChart_CSV_${getTodayShort()}`,
            },
            svg: {
              filename: `InsightChart_SVG_${getTodayShort()}`,
            },
            png: {
              filename: `InsightChart_PNG_${getTodayShort()}`,
            },
          },
        },
        events: {
          markerClick(e, chart, { seriesIndex, dataPointIndex, config }) {
            if (seriesIndex < 0) return;

            const clickedData = chartData[seriesIndex];
            const chartLabel = clickedData.term;

            const buckets = clickedData.term_count_over_time.buckets;
            const chartTimestamp = buckets[dataPointIndex].key;

            handleClickChart(chartLabel, chartTimestamp);
          },
          dataPointSelection: function (
            e,
            chartContext,
            { seriesIndex, dataPointIndex },
          ) {
            if (seriesIndex < 0) return;

            if (isTrendingChart) {
              handleClickTrendingChart(
                chartData[seriesIndex].term,
                dataPointIndex,
              );

              return;
            }

            const clickedData = chartData[seriesIndex];
            const chartLabel = clickedData.term;

            const buckets = clickedData.term_count_over_time.buckets;
            const chartTimestamp = buckets[dataPointIndex].key;

            handleClickChart(chartLabel, chartTimestamp);
          },
          beforeZoom: (chart, { xaxis }) => {
            const currentMinAxis = chart.minX;
            const currentMaxAxis = chart.maxX;

            const maxAxis = xaxis.max;
            const minAxis = xaxis.min;

            let limitZoomIn = 0;

            switch (insightOptions.calendarMode) {
              case CalendarFilter.DAY:
                limitZoomIn = Duration.days(2);
                break;
              case CalendarFilter.WEEK:
                limitZoomIn = Duration.weeks(1);
                break;
              case CalendarFilter.MONTH:
                limitZoomIn = Duration.months(1);
                break;
              case CalendarFilter.YEAR:
                limitZoomIn = Duration.years(1);
                break;
              default:
                limitZoomIn = 0;
            }

            if (maxAxis - minAxis < limitZoomIn) {
              return {
                // dont zoom out any further
                xaxis: {
                  min: currentMinAxis,
                  max: currentMaxAxis,
                },
              };
            }

            return {
              // keep on zooming
              xaxis: {
                min: minAxis,
                max: maxAxis,
              },
            };
          },
        },
      },
      annotations: {
        xaxis: insightOptions.showQuarterMarkers ? groupByQuarterMemo : [],
      },
      dataLabels: {
        distributed: false,
        enabled: shouldShowDataLabels,
        formatter: (val) => {
          if (toNumber(val) === 0) return '';

          return String(val);
        },
      },
      xaxis: getXAxis(),
      yaxis: {
        labels: {
          formatter(val: any, opts?) {
            return parseInt(val).toString();
          },
        },
        max: (max) => {
          // Ensure minimum 2 grid rows to have more space above
          if (max <= 1) return 2;

          return max;
        },
      },

      tooltip: !isTrendingChart
        ? {
            shared: false,
            y: {
              formatter(val) {
                return String(toNumber(val) || 0); // To avoid NaN
              },
            },
            x: {
              formatter(timestamp) {
                const startDate = shortDate(timestamp);

                switch (insightOptions.calendarMode) {
                  case CalendarFilter.WEEK: {
                    const endDate = shortDate(getWeekAfter(timestamp));

                    return `${startDate} - ${endDate}`;
                  }
                  case CalendarFilter.MONTH: {
                    const endDate = shortDate(getMonthAfter(timestamp));

                    return `${startDate} - ${endDate}`;
                  }
                  case CalendarFilter.YEAR: {
                    const startYear = shortDateYear(timestamp);
                    const endYear = shortDateYear(getYearAfter(timestamp));

                    return `${startYear} - ${endYear}`;
                  }
                  default:
                    return shortDate(timestamp);
                }
              },
            },
          }
        : {
            shared: false,
          },
      markers: {
        size: 0,
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center',
        showForSingleSeries: true,
      },
    };

    const barChartOption = (): ApexOptions => ({
      ...sharedOptions,
      fill: {
        type: 'solid',
      },
    });

    const lineChartOption = (): ApexOptions => {
      return {
        ...sharedOptions,

        stroke: {
          width: 2,
          curve: 'straight',
        },
        fill: {
          type: 'solid',
        },
      };
    };

    const areaChartOption = (): ApexOptions => {
      return {
        ...sharedOptions,

        stroke: {
          width: 2,
          curve: 'smooth',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            // type: 'vertical',
            shadeIntensity: 1,
            opacityFrom: 0.65,
            opacityTo: 0.95,
            stops: [0, 100],
          },
        },
      };
    };

    const handleClickTrendingChart = (
      label: string,
      dataPointIndex: number,
    ) => {
      const selectedDate = insightOptions.dateRange.dateSelected;
      const selectedCalendarMode = insightOptions.calendarMode;

      if (!selectedDate || !selectedCalendarMode) return;

      const { trendingStartDate, trendingEndDate } = match(selectedCalendarMode)
        .with(CalendarFilter.DAY, () => {
          const dateRange = getPreviousDays(selectedDate, 7).map(
            (day) => day.value,
          );

          return {
            trendingStartDate: dateRange[dataPointIndex] as Date,
            trendingEndDate: dateRange[dataPointIndex] as Date,
          };
        })
        .with(CalendarFilter.WEEK, () => {
          const dateRange = getPreviousWeeks(selectedDate, 4).map(
            (week) => week.values,
          );

          return {
            trendingStartDate: first(dateRange[dataPointIndex] as Date[]),
            trendingEndDate: last(dateRange[dataPointIndex] as Date[]),
          };
        })
        .with(CalendarFilter.MONTH, () => {
          const dateRange = getPreviousMonths(selectedDate, 3).map(
            (month) => month.values,
          );

          return {
            trendingStartDate: first(dateRange[dataPointIndex] as Date[]),
            trendingEndDate: last(dateRange[dataPointIndex] as Date[]),
          };
        })
        .otherwise(() => {
          return { trendingStartDate: null, trendingEndDate: null };
        });

      dispatch(handleSearchClear());
      dispatch(resetAdvancedSearch());
      dispatch(setSelectedRows([]));

      autofillAdvancedSearchHook({
        searchTerms: [label],
        collectionId: singleOrNull(insightOptions.selectedCollectionIds),
        startDate: startOfDayISO(trendingStartDate),
        endDate: endOfDayISO(trendingEndDate),
        searchBy: typeMediaEntities(
          insightOptions.selectedLayer,
        ) as keyof Insights,
      });
    };

    const handleClickChart = (label: string, timestamp: number) => {
      const [startDate, endDate] = getDateBetween(
        timestamp,
        insightOptions.calendarMode,
      );

      dispatch(handleSearchClear());
      dispatch(resetAdvancedSearch());
      dispatch(setSelectedRows([]));

      autofillAdvancedSearchHook({
        searchTerms: [label],
        collectionId: singleOrNull(insightOptions.selectedCollectionIds),
        startDate: startDate,
        endDate: endDate,
        searchBy: typeMediaEntities(
          insightOptions.selectedLayer,
        ) as keyof Insights,
      });
    };

    const convertToColumnSeries = (termBuckets: TermSearchInsight[]) => {
      if (isTrendingChart) {
        const trendingColumnSeries = getTrendingColumnSeries(
          first(termBuckets),
        );

        setColumnSeries(trendingColumnSeries);

        return;
      }

      const columnSeries: ApexAxisChartSeries =
        termBuckets?.map((termBucket) => ({
          name: termBucket.term,
          data: toTimeSeriesGroup(termBucket.term_count_over_time),
        })) ?? [];

      setColumnSeries(columnSeries);
    };

    const getTrendingColumnSeries = (
      termBucket: TermSearchInsight | undefined,
    ): ApexAxisChartSeries => {
      if (isNil(termBucket)) return [];

      const columnSeries: ApexAxisChartSeries = [
        {
          name: termBucket.term,
          data: toTrendingTimeSeriesData(termBucket.term_count_over_time),
        },
      ];

      return columnSeries;
    };

    const toTrendingTimeSeriesData = (termInsight: BucketInsight) => {
      const selectedDate = insightOptions.dateRange.dateSelected;

      const selectedCalendarMode = insightOptions.calendarMode;

      const isNoRequiredInput = !selectedDate || !selectedCalendarMode;

      if (isNoRequiredInput) return [];

      return match(selectedCalendarMode)
        .with(CalendarFilter.DAY, () => {
          const datesOfPreviousDays = getPreviousDays(selectedDate, 7);

          const results: ApexAxisDataChartSeries[] = [];

          datesOfPreviousDays.forEach((previousDate) => {
            let totalDocsCount = 0;

            termInsight.buckets.forEach((bucket: InsightKey) => {
              const bucketDate = new Date(bucket.key);

              const isBucketDateIncluded = isSameDay(
                bucketDate,
                previousDate.value,
              );

              if (isBucketDateIncluded) {
                totalDocsCount += bucket.doc_count;
              }
            });

            results.push({
              x: previousDate.label,
              y: totalDocsCount,
            });
          });

          return results;
        })
        .with(CalendarFilter.WEEK, () => {
          const datesOfPreviousWeeks = getPreviousWeeks(selectedDate, 4);

          const results: ApexAxisDataChartSeries[] = [];

          datesOfPreviousWeeks.forEach((previousWeek) => {
            let totalDocsCount = 0;

            previousWeek.values.forEach((weekDate) => {
              termInsight.buckets.forEach((bucket: InsightKey) => {
                const bucketDate = new Date(bucket.key);

                const isBucketDateIncluded = isSameDay(weekDate, bucketDate);

                if (isBucketDateIncluded) {
                  totalDocsCount += bucket.doc_count;
                }
              });
            });

            results.push({
              x: previousWeek.label,
              y: totalDocsCount,
            });
          });

          return results;
        })
        .with(CalendarFilter.MONTH, () => {
          const datesOfPreviousMonths = getPreviousMonths(selectedDate, 3);

          const results: ApexAxisDataChartSeries[] = [];

          datesOfPreviousMonths.forEach((previousMonth) => {
            let totalDocsCount = 0;

            previousMonth.values.forEach((monthDate) => {
              termInsight.buckets.forEach((bucket: InsightKey) => {
                const bucketDate = new Date(bucket.key);

                const isBucketDateIncluded = isSameDay(monthDate, bucketDate);

                if (isBucketDateIncluded) {
                  totalDocsCount += bucket.doc_count;
                }
              });
            });

            results.push({
              x: previousMonth.label,
              y: totalDocsCount,
            });
          });

          return results;
        })
        .otherwise(() => {
          return [];
        });
    };

    const toTimeSeriesGroup = (termInsight: BucketInsight) => {
      const yearQuarterListMap = new Map();

      return termInsight.buckets.map((bucket: InsightKey) => {
        const myDate = toDate(bucket.key);

        const year = getYear(myDate);
        const quarter = getQuarter(bucket.key);

        const quarterYearString = `Q${quarter} ${year}`;

        if (yearQuarterListMap.has(quarterYearString)) {
          const yearColumn = yearQuarterListMap.get(quarterYearString);
          yearQuarterListMap.set(quarterYearString, yearColumn + 1);
        } else {
          yearQuarterListMap.set(quarterYearString, 1);
        }

        const collectionCount = toNumber(bucket?.collections?.buckets.length);

        return {
          x: format(bucket?.key, 'yyyy-MM-dd'),
          y:
            insightOptions.groupByCollection && bucket.doc_count > 0
              ? max([collectionCount, 1]) // If no collection belonged -> count collection as 1 for My Collection
              : bucket?.doc_count,
          group: quarterYearString,
        };
      });
    };

    const getCurrentOption = (): ApexOptions => {
      switch (insightOptions.chartType) {
        case 'bar':
          return barChartOption();
        case 'line':
          return lineChartOption();
        case 'area':
          return areaChartOption();
        default:
          return lineChartOption();
      }
    };

    if (isEmpty(columnSeries)) {
      return <Info text="Nothing to show" />;
    }

    return (
      <ReactApexChart
        type={insightOptions.chartType}
        options={getCurrentOption()}
        series={columnSeries}
        width={width}
        height={height}
      />
    );
  },
);
