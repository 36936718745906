/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { isEmpty, isEqual, uniqBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { GroupBase, StylesConfig } from 'react-select';
import { RootState } from 'reducers';

import { ReactComponent as TagSvg } from 'assets/Icons/tag_24dp.svg';
import { ReactComponent as GlobalSvg } from 'assets/Icons/global.svg';

import { convertCustomTermToOption } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { CustomTermType, ModalOptions } from 'utils/enum';
import { extract, pushOrUpdate } from 'utils/generic.util';
import { CustomTerm } from 'utils/models';
import { v4 } from 'uuid';
import { CustomSelectSearch, Option } from './CustomSelectSearch';
import { AddEditTermModal } from './CutomTermsModal/AddEditTermModal';
import { Hint } from './Hint';
import { scrollbarWidthChildCss } from './twin.styles';
import { VocabEditableRef } from 'utils/models/modal.model';
import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';

type Props = {
  defaultVocabs: CustomTerm[];
  onSaveVocabs: (vocabs: CustomTerm[]) => void;

  isLoading?: boolean;
};

export const VocabSelectionDropdown = ({
  isLoading = false,
  defaultVocabs,
  ...props
}: Props) => {
  const global = useSelector((state: RootState) => state.global);

  const termEditableModalRef = useRef<VocabEditableRef>(null);

  const [selectedVocabs, setSelectedVocabs] = useState<CustomTerm[]>([]);

  const combinedVocabs = [
    ...global.customTerms,
    ...defaultVocabs,
    ...selectedVocabs,
  ];
  const combinedVocabIds = uniqBy(combinedVocabs, 'id');

  const defaultVocabOptions: Option[] =
    convertCustomTermToOption(selectedVocabs);

  const dropdownVocabOptions = uniqBy(
    convertCustomTermToOption(combinedVocabs),
    'value',
  );

  useEffect(() => {
    setSelectedVocabs(defaultVocabs);
  }, [defaultVocabs]);

  const isGlobalVocab = (id: string) => {
    return global.customTerms.some((vocab) => vocab.id === id);
  };

  const handleEditCustomVocabs = async (option: Option) => {
    const isGlobal = isGlobalVocab(option.value);

    if (isGlobal) return;

    const selectedVocab = selectedVocabs.find(
      (selectedVocab) => selectedVocab.id === option.value,
    );

    const result = await termEditableModalRef.current?.show({
      mode: 'edit',
      type: CustomTermType.APPLIED,
      item: selectedVocab,
    });

    if (result?.option !== ModalOptions.YES) return;
    if (!result.payload) return;

    const editedVocab: CustomTerm = result.payload;
    const editedVocabList = pushOrUpdate(selectedVocabs, editedVocab, 'id');

    notifyLatestVocabs(editedVocabList);
  };

  const handleAddNewCustomVocab = async (customVocabName: string) => {
    const newVocab: CustomTerm = {
      id: v4(),
      name: customVocabName,
      active: true,
    };

    const result = await termEditableModalRef.current?.show({
      mode: 'add',
      type: CustomTermType.APPLIED,
      item: newVocab,
    });

    if (result?.option !== ModalOptions.YES) return;
    if (!result.payload) return;

    const newCustomVocab: CustomTerm = result.payload;
    const newCustomVocabs = pushOrUpdate(selectedVocabs, newCustomVocab, 'id');

    notifyLatestVocabs(newCustomVocabs);
  };

  const handleSelectCustomVocabs = (selectedVocabOptions: Option[]) => {
    const selectedVocabIds = extract(selectedVocabOptions, 'value');

    const selectedCustomVocabs = combinedVocabIds.filter((vocab) =>
      selectedVocabIds.includes(vocab.id),
    );

    notifyLatestVocabs(selectedCustomVocabs);
  };

  const notifyLatestVocabs = (vocabs: CustomTerm[]) => {
    setSelectedVocabs(vocabs);
    props.onSaveVocabs(vocabs);
  };

  const renderCustomOption = (
    option: Option,
    meta: FormatOptionLabelMeta<Option>,
  ) => {
    const isGlobal = isGlobalVocab(option.value);

    return (
      <Hint
        text={
          isGlobal
            ? 'This is a library level defined custom vocabulary. To view or make changes please proceed to the Reporting page.'
            : 'Double click to edit custom vocabs.'
        }
        enterDelay={200}
        leaveDelay={0}
        maxWidth="20rem"
        notTransparent
      >
        <div
          tw="cursor-pointer"
          css={[
            isGlobal &&
              isEqual(meta.context, 'value') &&
              tw`text-disabled bg-transparent opacity-40`,
          ]}
          onDoubleClick={() => handleEditCustomVocabs(option)}
        >
          <div
            tw="flex flex-row space-x-2"
            css={[isGlobal && tw`pointer-events-none`]}
          >
            <div tw="max-w-[3rem] flex items-center">
              {isGlobal ? (
                <GlobalSvg fill="#7f8090" tw="w-[2.2rem]" />
              ) : (
                <TagSvg fill="#7f8090" />
              )}
            </div>

            <div tw="flex flex-col w-full">
              <div
                tw="text-14 text-left text-sonnant-dark break-words opacity-90"
                className="line-clamp-2"
              >
                {option.label}
              </div>
            </div>
          </div>
        </div>
      </Hint>
    );
  };

  return (
    <div css={[scrollbarWidthChildCss(3)]}>
      <CustomSelectSearch
        isMultiSelect
        options={dropdownVocabOptions}
        defaultValue={
          !isEmpty(defaultVocabOptions)
            ? defaultVocabOptions
            : 'Select custom vocabs'
        }
        customStyles={customStyles}
        onChange={handleSelectCustomVocabs}
        handleAddNew={handleAddNewCustomVocab}
        formatOptionLabel={renderCustomOption}
        isClearable={true}
        canCreate={true}
        isLoading={isLoading}
      />

      <AddEditTermModal ref={termEditableModalRef} />
    </div>
  );
};

const customStyles: StylesConfig<any, false, GroupBase<any>> = {
  input: (base) => ({
    ...base,
    height: '2rem',
    position: 'relative',
    top: '-1.2rem',
    fontSize: '1.4rem',
    marginLeft: '6px',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '4px',
    minHeight: '4.2rem',
    maxHeight: '20.5rem',
    overflowY: 'auto',
  }),
  multiValue: (base) => ({
    ...base,
    cursor: 'pointer',
    background: '#E1EFFE',
    zIndex: 2,
  }),
  placeholder: (base) => ({
    ...base,
    marginLeft: '6px',
  }),
};
