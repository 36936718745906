/** @jsxImportSource @emotion/react */
import 'twin.macro';

import React from 'react';
import { useSelector } from 'react-redux';
import { AdBreak } from './AdBreak';
import { RootState } from 'reducers';
import { AdBreakItem } from 'utils/models';
import { scrollToNearestNode } from '../../MediaUtilities';
import { isArray, isNil } from 'lodash';
import { Info } from 'components/shared/Info';

type Props = {};

export const AdBreakLayer = (props: Props) => {

  const handleClickAdBreak = (adMarkerItem: AdBreakItem) => {
    if (window?.Video && !isNil(adMarkerItem?.startTime)) {
      window.Video.currentTime = adMarkerItem.startTime;
      scrollToNearestNode(adMarkerItem.startTime);
    }
  };

  const adBreaks = useSelector((state: RootState) => state.adBreaks);

  return (
    <div tw="mt-6 pb-4 height[100vh - 62rem] overflow-y-auto">
      <div>
        {adBreaks.items?.map((item, index) => (
          <div key={index}>
            <AdBreak item={item} handleClickAdBreak={handleClickAdBreak}/>
          </div>
        ))}

        {isArray(adBreaks.items) && adBreaks.items?.length === 0 && (
          <Info text="There are no ad breaks for this content item." />
        )}
      </div>
    </div>
  );
};
