import { UseQueryOptions, useQuery } from 'react-query';
import { InsightService } from 'services/insight.service';
import { Duration } from 'utils/constants';
import { Layers } from 'utils/enum';
import { Insights } from 'utils/models';
import { getTenantidFromIdToken, typeMediaEntities } from 'utils/utils';

type Props = {
  layer: Layers;
  options?: UseQueryOptions<Insights>;
};

const tenantid = getTenantidFromIdToken();

export const useContentExplorationQuery = ({ layer, options }: Props) => {
  const queryFnAsync = async () => {
    const { data } = await InsightService.getInsightsAsync({
      layer: typeMediaEntities(layer),
    });

    return data;
  };

  const queryResult = useQuery({
    queryKey: ['insight/layer', tenantid, layer],
    queryFn: queryFnAsync,
    staleTime: Duration.minutes(5),
    cacheTime: Duration.seconds(3),
    retry: 3,
    ...options
  });

  return queryResult;
};
