/* eslint-disable react-hooks/exhaustive-deps */

/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { ReactComponent as OpenNewTabSvg } from 'assets/Icons/open_in_new.svg';

import { isEmpty, isNil } from 'lodash';
import { Link } from 'react-router-dom';
import { textLink } from 'components/shared/twin.styles';
import { TopShow } from 'utils/models';
import { Routes } from 'utils/routes';
import { useCallback } from 'react';
import { Hint } from 'components/shared/Hint';

type Props = {
  topShows: TopShow[];
};

export const AdvertisingTopShows = ({ topShows }: Props) => {
  const isTopCollections = topShows.every((show) => !isNil(show.showId));

  const chartName = isTopCollections ? 'Top Collections' : 'Top Shows';

  const renderShowRedirect = useCallback((show: TopShow) => {
    if (!show?.showId) return <span css={[showNameCss]}>{show.showName}</span>;

    return (
      <Link
        to={`${Routes.COLLECTIONS}/${show.showId}`}
        css={[textLink, tw`flex items-center font-normal`]}
        target="_blank"
      >
        <span css={[showNameCss]}>{show.showName}</span>
        <OpenNewTabSvg tw="ml-2" fill="grey" />
      </Link>
    );
  }, []);

  if (isEmpty(topShows)) return null;

  return (
    <div tw="flex justify-center mb-4">
      <div tw="flex flex-col gap-y-2.5 w-[30rem]">
        <div tw="flex font-extrabold text-16 text-sonnant-dark">
          {chartName}
        </div>

        <div tw="flex flex-col gap-y-1.5 ml-3">
          {topShows.map((show, index) => {
            const hintMessage = isTopCollections
              ? `Ad Markers: ${show.score}`
              : `Reach scores: ${show.score}`;

            return (
              <Hint align="left" text={hintMessage} enterDelay={200} leaveDelay={100}>
                <div
                  key={index}
                  tw="flex flex-row gap-x-3.5 text-16 text-sonnant-dark"
                >
                  <span tw="font-bold">{index + 1}.</span>
                  <div tw="opacity-[0.9]">{renderShowRedirect(show)}</div>
                </div>
              </Hint>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const showNameCss = css`
  ${tw`max-w-[24rem] truncate`}
`;
