import { SagaAction } from 'utils/models';
import * as types from '../actions/types';

interface InitialStateType {
  loading: boolean;
  error: boolean;
  statusMessage: string;
  statusCode: null;
}

const initialState = {
  loading: false,
  error: false,
  statusMessage: '',
  statusCode: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export const publishLibraryReducers = (
  state: InitialStateType = initialState,
  action: SagaAction,
) => {
  switch (action.type) {
    case types.INIT_PUBLISH_LIBRARY_WATCH: {
      return { ...state, loading: true };
    }
    case types.SET_PUBLISH_LIBRARY: {
      return {
        ...state,
        loading: false,
        statusMessage: action.data.data,
        statusCode: action.data.status,
      };
    }
    case types.FAILED_PUBLISH_LIBRARY: {
      return {
        ...state,
        loading: false,
        statusMessage: action.payload.data,
        statusCode: action.payload.status,
      };
    }
    case types.CLOSE_PUBLISH_LIBRARY: {
      return { ...state, loading: false, statusMessage: '', statusCode: null };
    }
    default:
      return state;
  }
};
