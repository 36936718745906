/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { ReactComponent as PlusSvg } from 'assets/Icons/AddKeyterm.svg';
import { ReactComponent as TagSvg } from 'assets/Icons/tag_24dp.svg';
import { Modal } from 'components/UiControls/modal/modal';
import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { ModalOptions } from 'utils/enum';

import { Keyword } from 'components/VideoPlayer/Transcription/Keyword/Keyword';
import {
  calTimeCombined,
  getLatestTitle,
  getMentionsByTerms,
  getMentionsOffset,
  msToSec,
  secToTimeCode,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { cloneDeep, isArray, isEmpty, isEqual, isNil } from 'lodash';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { MediaService } from 'services';
import { setIsShowMentions } from 'slices/global.slice';
import {
  addSelectedTerm,
  removeSelectedTerm,
  setSelectedTagId,
  setSelectedTerm,
  updateFocusTermItem,
  updateKeywordAlter,
  updateSelectedTerm
} from 'slices/player.slice';
import { CaptionElement } from 'types/slate-custom-types';
import { CombinedClipRequest, IKeyItem, MentionReport } from 'utils/models';
import { customToast } from 'utils/toast.util';
import { TimecodeModal } from '../modals/TimecodeModal';
import {
  IPreviewOptions,
  PreviewClipModal,
} from '../PreviewClipModal/PreviewClipModal';
import { SortTable } from '../SortTable/SortTable';
import { ConfirmModal } from './../ConfirmModal/ConfirmModal';
import { MentionAddTermModal } from './MentionAddTermModal';
import { MentionReportItem } from './MentionReportItem';

interface IPromiseParams {
  resolve: (option: ModalOptions) => void;
  reject: (err: any) => void;
}

interface IProps {
  isAppliedList: boolean;
}

export const MentionReportModal = forwardRef(
  (props: IProps, ref: React.Ref<unknown>): ReactElement => {
    useImperativeHandle(ref, () => ({ show }));
    const dispatch = useDispatch();

    const player = useSelector((state: RootState) => state.player);
    const media = useSelector((state: RootState) => state.media);
    const payment = useSelector((state: RootState) => state.payment);

    const keywords = player.keywords;

    const previewModalRef = useRef<any>(null);
    const timecodeRef = useRef<any>(null);
    const addTermModalRef = useRef<any>(null);
    const conFirmModalRef = useRef<any>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [mentionList, setMentionList] = useState<MentionReport[]>([]);
    const [newTitle, setNewTitle] = useState('');

    useEffect(() => {
      // if (props?.isAppliedList === true) return;

      if (isOpen === true) {
        const mentions = getMentionsByTerms({
          terms: player?.selectedTerms,
          captions: player.caption,
          isAppliedList: props?.isAppliedList,
        });
        setMentionList(mentions);
      } else {
        setMentionList([]);
      }

      dispatch(setIsShowMentions(isOpen));
    }, [isOpen, player.selectedTerms]);

    const promiseInfo = useRef<IPromiseParams>({
      resolve: () => {},
      reject: () => {},
    });

    const show = async (
      keyTerms: IKeyItem[],
      isDownloadNow?: boolean,
    ): Promise<unknown> => {
      if (!isNil(keyTerms) && isArray(keyTerms)) {
        const mentions = getMentionsByTerms({
          terms: keyTerms ?? [],
          captions: player.caption,
          isAppliedList: props?.isAppliedList,
        });

        if (isDownloadNow) {
          handleExport(mentions);
          return;
        }

        setMentionList(mentions);
        dispatch(setSelectedTerm(keyTerms));
      }

      setNewTitle(getLatestTitle(media?.metadata) + ' - Custom Terms Clip');

      return new Promise((resolve, reject) => {
        promiseInfo.current = {
          resolve,
          reject,
        };

        setIsOpen(true);
      });
    };

    const hideModal = () => {
      setNewTitle('');
      setIsOpen(false);
    };

    const handleYes = () => {
      hideModal();
      promiseInfo.current?.resolve(ModalOptions.YES);
    };

    const handleCancel = () => {
      hideModal();
      promiseInfo.current?.resolve(ModalOptions.CANCEL);
    };

    const toggleOnOF = (mention: MentionReport) => {
      const mentionIndex = mentionList.findIndex((m) => m.id === mention.id);

      const cloneMentions = [...mentionList];
      const foundMention = cloneMentions[mentionIndex];

      cloneMentions[mentionIndex] = {
        ...foundMention,
        enabled: !foundMention.enabled,
      };
      setMentionList(cloneMentions);
    };

    const removeMention = (mention: MentionReport) => {
      const term = mention.term;

      // mention delete
      const deleted = mentionList.find((m) => m?.id === mention?.id);

      // Update occurrences in transcript
      if (props.isAppliedList) {
        const currentTerm = player.focusTerms.find(
          (t) => t.keyword?.toLowerCase() === term.toLowerCase(),
        );

        if (isNil(currentTerm)) {
          customToast.error("Can't find term in current focus terms");
          return;
        }

        const newTerm = cloneDeep(currentTerm);

        // pop occurrences
        deleted?.occNo.forEach((occNo, index) => {
          if (newTerm.mentions[0].occurrences.length === 1) {
            newTerm.mentions[0].occurrences = [];
          } else {
            newTerm.mentions[0].occurrences.splice(occNo - index - 1, 1);
          }
        });

        // update keyword in focus list
        dispatch(updateFocusTermItem(newTerm));

        // update keyword in current list modal
        dispatch(updateSelectedTerm(newTerm));
      } else {
        const indexKeyword = keywords.findIndex(
          (k) => k?.keyword.toLowerCase() === term.toLowerCase(),
        );
        if (indexKeyword >= 0) {
          const newKeyword = cloneDeep(keywords[indexKeyword]);
          // pop occurrences
          deleted?.occNo.forEach((occNo, index) => {
            if (newKeyword.mentions[0].occurrences.length === 1) {
              newKeyword.mentions[0].occurrences = [];
            } else {
              newKeyword.mentions[0].occurrences.splice(occNo - index - 1, 1);
            }
          });

          // update keyword and seleelected terms
          dispatch(
            updateKeywordAlter({
              index: indexKeyword,
              newKeyword,
            }),
          );
        }
      }

      dispatch(setSelectedTagId(-1));
    };

    const handleShowPreview = (mention: MentionReport) => {
      previewModalRef.current?.show({
        startTime: mention?.caption?.data?.start + 0.001 || 0,
        endTime: mention?.caption?.data?.end,
        mediaid: media.metadata?.mediaid,
        title: getLatestTitle(media?.metadata),
        mediaObj: media,
      } as IPreviewOptions);
    };

    const handleTimecode = async (mention: MentionReport) => {
      const caption = mention.caption.data;
      const newTimecode: { start: number; end: number } =
        await timecodeRef.current?.show({
          start: caption?.start,
          end: caption?.end,
          prevEnd: 0,
          nextStart: msToSec(media?.metadata?.length),
        });
      if (
        !newTimecode ||
        (isEqual(caption?.start, newTimecode?.start) &&
          isEqual(caption?.end, newTimecode?.end))
      )
        return;

      const foundIndex = mentionList.findIndex((m) =>
        isEqual(m.id, mention.id),
      );
      const foundMention = cloneDeep(mentionList?.[foundIndex]);

      if (!foundMention) return;

      foundMention.caption.data = {
        ...foundMention.caption.data,
        start: newTimecode.start,
        end: newTimecode.end,
      };

      const cloneMentions = [...mentionList];
      cloneMentions[foundIndex] = foundMention;
      setMentionList(cloneMentions);
    };

    const handleExport = async (mention?: MentionReport[]) => {
      const newMentionList = mention ?? mentionList;
      const fromFile = getLatestTitle(media.metadata);

      const existedTermName: string[] = [];

      const currentTerms = isNil(mention)
        ? player.selectedTerms
        : player?.focusTerms;

      const terms: any = [];
      newMentionList?.forEach((m) => {
        if (m?.enabled) {
          // get start of terms
          let foundTerm = currentTerms.find(
            (i) =>
              (i?.custom ?? i?.keyword)?.toLowerCase() ===
              m?.term.toLowerCase(),
          );

          if (existedTermName?.includes(m?.term)) {
            const foundIndex = terms?.findIndex((i: any) => i?.name === m.term);
            // merge occurrences of same term
            m?.occNo?.forEach((occNo) => {
              terms?.[foundIndex]?.occurrences.push(
                foundTerm?.mentions?.[0].occurrences?.[occNo - 1],
              );
            });
          } else {
            existedTermName.push(m?.term);
            // add new term
            terms.push({
              name: m.term,
              type: m.type ?? '',
              occurrences: m.occNo.map((occNo) => {
                return foundTerm?.mentions[0].occurrences[occNo - 1];
              }),
            });
          }
        }
      });

      const getMultiMentionsAsync = MediaService.getMentionReport({
        mediaIds: [media.metadata.mediaid],
        terms: (terms as any) ?? [],
        reportName: isEmpty(newTitle) ? fromFile : newTitle,
      });

      handleYes();

      customToast.promise(getMultiMentionsAsync, {
        loading: 'Running in progress',
        success: (
          <div>
            <div>Submitted for background processing.</div>
            <div>It may take a few minutes to be ready.</div>
          </div>
        ),
        error: 'Generating Mention Report failed. Please check and try again.',
      });

      await getMultiMentionsAsync;
    };

    const handleCombine = async () => {
      if (payment.isExpiredTrial) {
        customToast.trialExpired();
        return;
      }

      const captions = mentionList
        .filter((m) => m?.enabled)
        .map((mention) => ({
          s: mention.caption?.data?.start,
          e: mention.caption?.data?.end,
        }));

      const combinedCaptions = getMentionsOffset({
        rawMentions: captions,
        captionBefore: 2,
        captionAfter: 1,
        captions: player.isParagraphMode
          ? (player.caption as CaptionElement[])
          : (window.Editor.children as CaptionElement[]),
      });

      if (isEmpty(combinedCaptions)) return;

      const startTime = combinedCaptions[0]?.s;
      const duration = calTimeCombined(combinedCaptions);
      const endTime = startTime + duration;

      const payload: CombinedClipRequest = {
        mediaid: media.metadata.mediaid,
        versionname: newTitle,
        starttime: secToTimeCode(startTime),
        endtime: secToTimeCode(endTime),
        clips: {
          captions: combinedCaptions,
        },
      };

      try {
        toast.loading('Please wait...');
        await MediaService.combineClip(payload);
        toast.dismiss();
        await conFirmModalRef.current?.show();
      } catch {
        customToast.error('Combine clip failed');
      } finally {
        handleYes();
      }
    };

    const handleAddTermModal = async () => {
      const result = await addTermModalRef.current?.show();
      if (result?.option === ModalOptions.YES) {
        dispatch(addSelectedTerm(result.term));
      }
    };

    const canSubmit =
      !isEmpty(newTitle?.trim()) &&
      mentionList?.length > 0 &&
      mentionList?.some((m) => m?.enabled);

    return (
      <div css={customModalCss}>
        <Modal show={isOpen} modalClosed={handleCancel}>
          <div className="userModal_Popus">
            <h1>Mentions Report</h1>

            <div>
              <div tw="mt-4 mb-6 flex items-center line-height[1.5]">
                <input
                  type="text"
                  tw="(text-15 font-medium mb-0 placeholder:(font-light text-14))!"
                  placeholder="Enter custom list name"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  autoFocus
                />
                <div tw="text-15 font-medium ml-6 text-sonnant-dark">
                  <div tw="flex w-full whitespace-nowrap">
                    <div>Number of mentions: {mentionList?.length}</div>
                  </div>
                </div>
              </div>

              <div>
                <div tw="flex relative">
                  <TagSvg tw="absolute top[2rem] left[1rem] z-index[1]" />
                  <div
                    tw="w-full flex flex-wrap items-center h-auto border[1px solid lightgrey] mb-0 p-2 rounded pl-14 mr-4 background[#F9F9F9] space-y-1!"
                    css={[keywordCss, tw`max-height[131px] overflow-y-auto`]}
                  >
                    {player.selectedTerms.map((term) => (
                      <Keyword
                        val={term}
                        isEditingTranscript={true}
                        handleRemoveKeyword={(
                          e: any,
                          index: number,
                          term: IKeyItem,
                        ) => dispatch(removeSelectedTerm(term))}
                        disableEdit
                        showTooltip
                      />
                    ))}
                  </div>
                  <button
                    className="button btn-primary large"
                    tw="flex items-center justify-center height[5.5rem]! mb-0"
                    onClick={handleAddTermModal}
                  >
                    <PlusSvg tw="mr-3" /> Add Term
                  </button>
                </div>
              </div>

              <div
                tw="mt-3 mb-6 border[1px solid lightgrey] rounded-lg overflow-hidden! shadow overflow-y-auto!"
                css={[mentionTableCss]}
              >
                <SortTable
                  columns={
                    [
                      {
                        Header: 'Custom term',
                        accessor: 'keyword',
                        width: '20%',
                      },
                      {
                        Header: 'Mention number',
                        styles: { textAlign: 'center' },
                      },
                      {
                        Header: 'Start time',
                      },
                      { Header: 'End time' },
                      { Header: 'Duration' },
                      { Header: 'Transcript', width: '30%' },
                      { Header: ' ' },
                    ] as any
                  }
                  data={mentionList}
                >
                  {({ original: mention }) => {
                    return (
                      <MentionReportItem
                        mention={mention}
                        toggleOnOF={toggleOnOF}
                        handleTimecode={handleTimecode}
                        removeMention={removeMention}
                        handleShowPreview={handleShowPreview}
                      />
                    );
                  }}
                </SortTable>
                {isEmpty(mentionList) && (
                  <tr>
                    <td
                      colSpan={6}
                      tw="pl-4 text-sonnant-dark text-2xl font-medium mb-6"
                    >
                      There are no selected mentions
                    </td>
                  </tr>
                )}
              </div>
            </div>

            <div className="grid-x">
              <div className="cell small-12">
                <div className="btn_section" tw="flex justify-between w-full">
                  <button
                    type="button"
                    className="button cancel"
                    onClick={handleCancel}
                    tw="ml-0!"
                  >
                    Cancel
                  </button>
                  <div>
                    <button
                      type="button"
                      className="button delete"
                      onClick={handleCombine}
                      tw="(whitespace-nowrap width[unset] mr-4)!"
                      disabled={!canSubmit}
                    >
                      Combined Clip
                    </button>
                    <button
                      type="button"
                      className="button delete"
                      onClick={() => handleExport()}
                      tw="(whitespace-nowrap width[unset])!"
                      disabled={!canSubmit}
                    >
                      Export to Excel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PreviewClipModal ref={previewModalRef} isMentionReport />
          <TimecodeModal ref={timecodeRef} />
          <MentionAddTermModal ref={addTermModalRef} />
          <ConfirmModal
            ref={conFirmModalRef}
            title="Combined clip created"
            message="Your consolidated clip is being created. Once completed this will be able to be seen on the View clips page, the Share, and the Export options for this content item. It will be named with the content item title and “- Custom Terms Clip”"
            hideCancel
            confirmText={'OK'}
            type="info"
          />
        </Modal>
      </div>
    );
  },
);

const customModalCss = css`
  .Modal {
    ${tw`lg-up:(width[100rem] left[calc(50% - 50rem)]) xl-up:(width[120rem] left[calc(50% - 60rem)]) 2xl-up:(width[140rem] left[calc(50% - 70rem)])`}
  }
`;

const mentionTableCss = css`
  ${tw`height[45rem] xl-up:(height[50rem]) 2xl-up:(height[60rem])`}
  th,
  td {
    font-size: 1.4rem;
  }

  th {
    white-space: nowrap;
  }
`;

const keywordCss = css`
  .button {
    height: 3.8rem;
    ${tw`my-0`}
  }
`;
