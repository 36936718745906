/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';
import { css } from '@emotion/react';

import { isNil, some } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  setCurrentEditWatchList,
  setCurrentEditWatchListEmail,
} from 'slices/watchlist.slice';
import { Hint } from '../Hint';
import { CustomSelectSearch } from '../CustomSelectSearch';
import { GroupBase, StylesConfig } from 'react-select';
import { REGEX } from 'utils/regex';
import { customToast } from 'utils/toast.util';
import { ReactComponent as EmailAddressSvg } from 'assets/Icons/email_address.svg';
import { getUserRole } from 'utils/utils';

type Props = {
  fromPanel?: boolean;
};

interface Option {
  label: string;
  value: string;
}

export const RecipientsSettings = (props: Props) => {
  const { currentEditWatchList } = useSelector(
    (state: RootState) => state.watchList,
  );

  const dispatch = useDispatch();

  const currentEmailAddressArray =
    currentEditWatchList?.recipients?.emailAddress ?? [];

  const toggle = useSelector((state: RootState) => state.toggle);
  const user = useSelector((state: RootState) => state.userProfile);

  const isAdmin = getUserRole() === 'Administrator';

  const hasCheckMe = currentEmailAddressArray.includes(user.email);

  const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target?.name;
    const checked = e.target?.checked;

    if (isNil(currentEditWatchList.recipients)) return;

    let newRecipients = { ...currentEditWatchList.recipients, [name]: checked };

    // If allUsers checked -> me auto checked
    if ((name === 'allUsers' || name === 'me') && checked) {
      // add current email to email address
      const newEmailAddressArray = [...currentEmailAddressArray];

      newEmailAddressArray.push(user.email);

      newRecipients = {
        ...newRecipients,
        me: true,
        emailAddress: newEmailAddressArray,
      };
    }

    if (name === 'me' && !checked) {
      // remove current email from email address
      const newEmailAddressArray = [...currentEmailAddressArray];

      const foundIndex = newEmailAddressArray.findIndex(
        (i) => i === user.email,
      );

      if (foundIndex >= 0) {
        newEmailAddressArray.splice(foundIndex, 1);
      }

      newRecipients = {
        ...newRecipients,
        me: false,
        emailAddress: newEmailAddressArray,
      };
    }

    const newEditWatchList = {
      ...currentEditWatchList,
      recipients: newRecipients,
    };

    dispatch(setCurrentEditWatchList(newEditWatchList));
  };

  const handleChangeEmail = (option: Option[]) => {
    const userOption = { label: user.email, value: user.email };
    const isRemovedPersonalEmail = !some(option, userOption);

    if (isRemovedPersonalEmail) {
      const newRecipients = {
        ...currentEditWatchList.recipients,
        me: false,
      };

      const newEditWatchList = {
        ...currentEditWatchList,
        recipients: newRecipients,
      };

      dispatch(setCurrentEditWatchList(newEditWatchList));
    }

    const newEmailAddressArray = option.map((i) => i.value);
    dispatch(setCurrentEditWatchListEmail(newEmailAddressArray));
  };

  const handleAddNew = (value: string) => {
    const formatValue = value.trim().toLowerCase();

    const isEmail = REGEX.EMAIL_ADDRESS.test(formatValue);
    if (!isEmail) {
      customToast.error('Please input a valid email address.');
      return false;
    }

    if (formatValue === user.email.trim().toLowerCase()) {
      const newRecipients = {
        ...currentEditWatchList.recipients,
        me: true,
      };

      const newEditWatchList = {
        ...currentEditWatchList,
        recipients: newRecipients,
      };

      dispatch(setCurrentEditWatchList(newEditWatchList));
    }

    const newEmailAddressArray = [...currentEmailAddressArray, formatValue];

    dispatch(setCurrentEditWatchListEmail(newEmailAddressArray));
  };

  return (
    <Hint
      text="Only Administrators are allowed to edit watch list recipients"
      disabled={isAdmin}
      enterDelay={0}
      leaveDelay={0}
      notTransparent
      arrow
    >
      <div>
        <div tw="text-16 font-medium my-5">Recipients</div>
        <div>
          <label
            className={`large-10 cell ${
              currentEditWatchList.recipients?.allUsers && 'disabled'
            }`}
            tw="ml-10 max-w-full flex[unset]! w-auto cursor-pointer"
          >
            <input
              name="me"
              checked={hasCheckMe}
              onChange={handleCheckboxClick}
              type="checkbox"
            />
            <span className="checkmark" style={{ top: '5px' }}></span>
            Me
          </label>
        </div>
        <div>
          <label
            className={`large-10 cell ${!isAdmin && 'disabled'}`}
            tw="ml-10 max-w-full flex[unset]! w-auto cursor-pointer"
          >
            <input
              name="allUsers"
              checked={currentEditWatchList.recipients?.allUsers}
              onChange={handleCheckboxClick}
              type="checkbox"
              disabled={!isAdmin}
            />
            <span className="checkmark" style={{ top: '5px' }}></span>
            All users
          </label>
        </div>
        <div tw="flex items-center space-x-5" css={[customScrollBar]}>
          <label
            className={`large-10 cell`}
            tw="ml-10 max-w-full flex[unset]! w-auto min-width[12rem] self-start"
          >
            <input
              name="hasEmailAddress"
              checked={true}
              onChange={handleCheckboxClick}
              disabled
              type="checkbox"
            />
            <span className="checkmark disabled" style={{ top: '5px' }}></span>
            Email address
          </label>
          <Hint
            open={
              ((!toggle.isOpenAddEditModal && props?.fromPanel) ||
                (toggle.isOpenAddEditModal && !props?.fromPanel)) &&
              currentEditWatchList.recipients?.hasEmailAddress &&
              currentEmailAddressArray.length < 1
            }
            text={'Required at least one valid email address.'}
            arrow
          >
            <CustomSelectSearch
              isMultiSelect
              customStyles={customStyles}
              onChange={handleChangeEmail}
              handleAddNew={handleAddNew}
              canCreate
              disabled={!isAdmin}
              formatOptionLabel={renderCustomOption}
              formatCreateLabel={(inputValue: string) => inputValue}
              defaultValue={currentEmailAddressArray.map((item) => ({
                label: item,
                value: item,
              }))}
              noOptionsMessage={null}
            />
          </Hint>
        </div>
      </div>
    </Hint>
  );
};

const renderCustomOption = (option: Option) => (
  <div tw="flex flex-row">
    <div tw="max-width[3rem] mr-1 flex items-center">
      <EmailAddressSvg fill="#7f8090" tw="zoom[0.8]" />
    </div>

    <div tw="flex flex-col w-full">
      <div
        tw="text-14 text-left text-sonnant-dark break-words"
        className="line-clamp-2"
      >
        {option.label}
      </div>
    </div>
  </div>
);

const customStyles: StylesConfig<any, false, GroupBase<any>> = {
  indicatorsContainer: (base) => ({
    ...base,
    display: 'none',
  }),
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  control: (base) => ({
    ...base,
    maxWidth: '1000px',
    minWidth: '550px',
  }),
  menu: (base) => ({
    ...base,
    bottom: 0,
    display: 'none',
  }),
  menuList: (base) => ({
    ...base,
    overflowX: 'hidden',
  }),
  input: (base) => ({
    ...base,
    height: '2rem',
    position: 'relative',
    top: '-1.2rem',
    fontSize: '1.4rem',
    maxWidth: '100%',
    wordBreak: 'break-all',
  }),
  valueContainer: (base) => ({
    ...base,
    gap: '6px',
    padding: '6px',
    minHeight: '4rem',
    maxHeight: '12.6rem',
    overflowY: 'auto',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data?.color ?? 'rgba(102, 102, 102, 0.1)',
      color: data?.color ? '#fff' : 'rgb(102, 102, 102)',
    };
  },
  multiValueLabel: (styles, { data }) => {
    return {
      ...styles,
      color: data?.color ? '#fff' : '#000',
    };
  },
  placeholder: (base) => ({
    ...base,
    color: '#777777',
    position: 'relative',
    top: '1px',
    fontWeight: 400,
    opacity: 0.85,
    fontSize: '1.4rem',
    lineHeight: 1,
  }),
  option: (base) => ({
    ...base,
    cursor: 'pointer',
    color: 'rgb(51, 51, 51)',
    height: '3rem',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word',
    padding: '4px 12px',
  }),
  noOptionsMessage: (base) => ({
    ...base,
    display: 'none',
  }),
};

const customScrollBar = css`
  & ::-webkit-scrollbar {
    width: 0.4rem;
  }
`;
