/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { ReactComponent as SearchNone } from 'assets/Icons/search_off.svg';

type Props = {
  message?: string;
};

export const NoResultInsights = ({
  message = 'Please check your input data and try again.',
}: Props) => {
  return (
    <div tw="w-full h-full flex justify-center items-center">
      <div tw="flex justify-center flex-col text-sonnant-dark items-center">
        <SearchNone tw="height[12rem] width[12rem] fill[#7f8090]" />

        <div tw="font-medium font-size[2.4rem] mt-3">No results found</div>
        <div tw="mt-5 mb-8 text-16">{message}</div>
      </div>
    </div>
  );
};
