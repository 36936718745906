/** @jsxImportSource @emotion/react */
import 'twin.macro';

type Props = {
  checked: boolean | undefined;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  [type: string]: any;
};

export const SimpleCheckbox = ({
  checked,
  onChange,
  label,
  ...inputProps
}: Props) => {
  return (
    <label tw="hover:cursor-pointer" className="line-clamp-2">
      <input
        checked={checked}
        onChange={onChange}
        type="checkbox"
        {...inputProps}
      />
      <span className="checkmark" style={{ top: '5px' }}></span>
      {label}
    </label>
  );
};
