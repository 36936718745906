/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import tw from 'twin.macro';

import { Tooltip, TooltipProps } from '@rmwc/tooltip';
import { ReactElement } from 'react';

interface IProps {
  text: string | ReactElement;
  children: ReactElement;
  enterDelay?: number;
  leaveDelay?: number;
  hidden?: boolean;
  arrow?: boolean;
  disabled?: boolean;
  fontSize?: number;
  maxWidth?: string;
  notTransparent?: boolean;
  align?: TooltipProps['align'];
  [type: string]: any;
}

export const Hint = ({
  text,
  enterDelay = 1000,
  leaveDelay = 300,
  arrow = false,
  disabled = false,
  fontSize = 1,
  maxWidth,
  ...props
}: IProps) => {
  return disabled ? (
    <>{props.children}</>
  ) : (
    <Tooltip
      className="hint-tooltip-container"
      content={text}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      showArrow={arrow}
      activateOn="hover"
      css={[
        customTooltipStyles(fontSize),
        customTooltipWidth(maxWidth),
        props.notTransparent && tw`opacity-100!`,
      ]}
      {...props}
    >
      {props.children}
    </Tooltip>
  );
};
const customTooltipWidth = (width = '55rem') => css`
  .rmwc-tooltip-content {
    max-width: ${width};
  }
`;

const customTooltipStyles = (fontSize: number = 1) => css`
  font-size: ${String(fontSize)}rem !important;

  .rmwc-tooltip-inner {
    ${tw`bg-sonnant-grey-5 shadow`}
  }

  .rmwc-tooltip-arrow {
    border-top-color: #6a6b73;
    border-bottom-color: #6a6b73;
  }
`;
