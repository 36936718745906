/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { CLOSE_PUBLISH_LIBRARY } from 'actions/types';
import { ReactComponent as AddSvg } from 'assets/Icons/add_circle.svg';
import { ReactComponent as MinusSvg } from 'assets/Icons/minus_circle.svg';
import { Publish } from 'components/Publish/Publish';
import { Hint } from 'components/shared/Hint';
import { HintDisallowed } from 'components/shared/HintDisallowed';
import { TimeCodeChapterModal } from 'components/shared/TimeCodeModal/TimeCodeChapterModal';
import { greyScrollbar } from 'components/shared/twin.styles';
import { VirtualTable } from 'components/shared/VirtualTable/VirtualTable';
import { Modal } from 'components/UiControls/modal/modal';
import { msToSec } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { cloneDeep, isEmpty, last, max, orderBy, round } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { setChapter, setNewTimeCode } from 'slices/chapter.slice';
import { ChapterLayerData } from 'utils/models';
import { IChapterRaw } from 'utils/models/transcript.model';
import { customToast } from 'utils/toast.util';
import { getLatestTitle, handleGetChapterData } from '../../MediaUtilities';
import { ChapterRow } from './ChapterRow';
import { CHAPTER_COLUMN_HEADERS } from './columns';

type Props = {};

type ClipOptions = {
  show: boolean;
  start: number;
  end: number;
  headline?: string;
};

export const ChapterLayer = (props: Props) => {
  const toggle = useSelector((state: RootState) => state.toggle);
  const player = useSelector((state: RootState) => state.player);
  const iab = useSelector((state: RootState) => state.iab);
  const media = useSelector((state: RootState) => state.media);

  const chapter = useSelector((state: RootState) => state.chapter);
  const chapterTimeList = orderBy(chapter.chapter, (c) => c.startTime, ['asc']);

  const [chapterData, setChapterData] = useState<ChapterLayerData[]>([]);
  const [clipOptions, setClipOptions] = useState<ClipOptions>({
    show: false,
    start: 0,
    end: 0,
  });
  const [selectedChapter, setSelectedChapter] = useState<number[]>([]);

  const dispatch = useDispatch();

  const timecodeRef = useRef<any>(null);

  useEffect(() => {
    const data = handleGetChapterData(
      chapter.chapter,
      player.keywords,
      iab.lockedList,
      media?.metadata?.mediaid,
    );
    setChapterData(data);
  }, [chapter.chapter, player.keywords, iab.lockedList]);

  const resetClipModal = () => {
    setClipOptions({ show: false, start: 0, end: 0, headline: '' });
    dispatch({
      type: CLOSE_PUBLISH_LIBRARY,
    });
  };

  const handleCreateClip = (start: number, end: number, headline: string) => {
    setClipOptions({ show: true, start, end, headline });
  };

  const handleAddChapter = () => {
    const nextId = (max(chapterTimeList?.map((c) => c?.id)) ?? 0) + 1;
    const lastChap = last(chapterTimeList);
    const videoDuration = msToSec(media?.metadata?.length);

    if (!videoDuration) return;

    // Add new chapter at the middle of last chapter and end time
    // Upto 2 decimal places
    const newChapterStart = round(
      ((lastChap?.startTime ?? 0) + videoDuration) / 2,
      2,
    );

    const cloneChapters = cloneDeep(chapterTimeList) ?? [];
    if (cloneChapters?.length > 0) {
      last(cloneChapters)!.endTime = newChapterStart;
    }

    const newChapters: IChapterRaw[] = [
      ...cloneChapters,
      {
        id: nextId,
        startTime: newChapterStart,
        endTime: videoDuration,
        headline: '',
      },
    ];

    const lastedChapterStartTime =
      newChapters?.[newChapters.length - 1]?.startTime;

    const MIN_CHAPTER_GAP = 2; // in seconds
    if (videoDuration - lastedChapterStartTime < MIN_CHAPTER_GAP) {
      customToast.error(
        <div>
          <div>
            There is not enough time between the last chapter and the end of the
            file to insert a new chapter.
          </div>
          <div tw="mt-2">
            Move the last chapter to make at least{' '}
            <b>{MIN_CHAPTER_GAP} seconds</b> of space before the end.
          </div>
        </div>,
        {
          duration: 3000,
          style: {
            maxWidth: '420px',
          },
        },
      );

      return;
    }

    dispatch(setChapter(newChapters));
  };

  const handleSelectChapter = (item: ChapterLayerData) => {
    if (selectedChapter.includes(item.startTime)) {
      setSelectedChapter(selectedChapter.filter((i) => i !== item.startTime));
      return;
    }
    setSelectedChapter([...selectedChapter, item.startTime]);
  };

  const handleRemoveChapter = () => {
    const newChapters = chapterTimeList.filter(
      (c) => !selectedChapter.includes(c.startTime),
    );
    dispatch(setChapter(newChapters));
    setSelectedChapter([]);
  };

  const handleDoubleClickChapter = async (item: ChapterLayerData) => {
    if (!toggle.isEditMode) {
      customToast.error('Edit mode is off');
      return;
    }

    const newTimecode: { start: number; end: number; headline: string } =
      await timecodeRef.current?.show({
        start: item.startTime,
        end: item.endTime,
        prevEnd: 0,
        nextStart: msToSec(media?.metadata?.length),
        mode: 'chapter',
        chapterId: item?.id,
        headline: item?.headline,
      });
    if (!newTimecode) return;

    const newChapters = [...chapterTimeList];
    const foundIndex = newChapters.findIndex(
      (c) => c?.id === item?.id,
    );
    if (foundIndex === -1) return;

    newChapters[foundIndex] = {
      ...newChapters[foundIndex],
      startTime: newTimecode?.start,
      endTime: newTimecode?.end,
      headline: newTimecode?.headline,
    }

    // if (foundIndex !== newChapters.length - 1)
    //   newChapters[foundIndex + 1] = {
    //     ...newChapters[foundIndex + 1],
    //     startTime: newTimecode?.end,
    //   }

    // if (foundIndex !== 0)
    //   newChapters[foundIndex - 1] = {
    //     ...newChapters[foundIndex - 1],
    //     endTime: newTimecode?.start,
    //   }

    dispatch(setChapter(newChapters));
    dispatch(setNewTimeCode(newTimecode.start));
  };

  const handleChangeHeadline = (
    item: ChapterLayerData,
    newHeadline: string,
  ) => {
    const newChapters = chapterTimeList.map((c) => {
      const cloneChap = { ...c };
      if (item?.startTime === cloneChap?.startTime) {
        cloneChap.headline = newHeadline.trim();
      }
      return cloneChap;
    });
    dispatch(setChapter(newChapters));

    // customToast.success('Headline updated');
  };

  return (
    <div tw="height[calc(100vh - 21rem)] flex flex-col mt-6 pb-4 mr-2">
      <div tw="flex w-full justify-between text-15 mb-1.5">
        <div tw="flex space-x-3">
          <div tw="font-medium text-sonnant-blue-dark">Chapters</div>

          {toggle.isEditMode && (
            <>
              <Hint text="Add new chapter" enterDelay={100}>
                <div tw="cursor-pointer flex" onClick={handleAddChapter}>
                  <AddSvg fill="#5551FF" />
                </div>
              </Hint>

              <HintDisallowed
                disabled={isEmpty(selectedChapter)}
                hintEnabled="Removed selected chapter(s)"
                hintDisabled="None item selected"
                tw="flex items-center"
                onClick={handleRemoveChapter}
              >
                <MinusSvg
                  tw="cursor-pointer"
                  fill="#5551FF"
                  width={20}
                  height={20}
                />
              </HintDisallowed>
            </>
          )}
        </div>
      </div>

      <div tw="flex-grow[1] overflow-y-auto" css={[greyScrollbar]}>
        <VirtualTable
          columns={CHAPTER_COLUMN_HEADERS}
          tableData={chapterData}
          noDataMessage="There is no chapter detected for this item"
          tableRow={(row, index) => (
            <ChapterRow
              key={index}
              itemRow={row as any}
              selectedChapter={selectedChapter}
              handleSelectChapter={handleSelectChapter}
              handleCreateClip={handleCreateClip}
              handleDoubleClickChapter={handleDoubleClickChapter}
              handleChangeHeadline={handleChangeHeadline}
            />
          )}
          stickyHeader
        />
      </div>

      <Modal
        classes="publish-modal-width"
        show={clipOptions.show}
        modalClosed={resetClipModal}
      >
        <Publish
          startTime={clipOptions?.start}
          endTime={clipOptions?.end}
          isOpen={clipOptions.show}
          mediaid={media?.metadata?.mediaid}
          filename={clipOptions.headline ?? getLatestTitle(media?.metadata)}
          closeShareModal={resetClipModal}
        />
      </Modal>
      <TimeCodeChapterModal ref={timecodeRef} />
    </div>
  );
};
