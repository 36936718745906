/** @jsxImportSource @emotion/react */
import 'twin.macro';

import Skeleton from 'react-loading-skeleton';

interface Props {
  count?: number;
}

export const TranscriptSkeleton = ({ count = 1 }: Props) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <div
          key={index}
          tw="flex border-bottom[1px solid] border-sonnant-grey-3 py-4 pr-5"
        >
          <div>
            <Skeleton width={165} />
            <Skeleton width={80} />
          </div>
          <div tw="mx-6">
            <Skeleton width={40} height={40} />
          </div>
          <div tw="flex-1">
            <Skeleton height={10} />
            <Skeleton height={10} />
          </div>
        </div>
      ))}
    </>
  );
};
