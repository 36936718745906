/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { useSearchParam } from 'react-use';
import { Social } from 'utils/enum';

export const SharePostTiktok = () => {
  const code = useSearchParam('code');
  const provider = Social.TIKTOK;

  useEffect(() => {
    handleGetAccessToken();
  });

  const handleGetAccessToken = async () => {
    window.document.cookie = `access_token=${code};domain=.${window.location.host}; path=/`;
    window.document.cookie = `state=true;domain=.${window.location.host}; path=/`;
    window.document.cookie = `provider=${provider};domain=.${window.location.host}; path=/`;
    window.close();
  };

  return (
    <div className="App">
      <header className="App-header"></header>
    </div>
  );
};
