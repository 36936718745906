import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title: string;
  thumbnail: string;
  description: string;
  link?: string;
  meta?: any[];
}

export const SEO = ({
  title,
  description,
  link,
  meta = [],
  ...props
}: Props) => {
  return (
    <Helmet
      title={title}
      htmlAttributes={{ lang: 'en' }}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: 'og:image',
          content: props.thumbnail,
        },
        // {
        //     property: "og:type",
        //     content: "website sdkfjlsdfjl"
        // },
        // {
        //     property: "og:title",
        //     content: title
        // },
        // {
        //     property: "og:description",
        //     content: description
        // },
        // {
        //     property: "og:video",
        //     content: video
        // },
        // {
        //     property: "twitter:title",
        //     content: title
        // },
        // {
        //     property: "twitter:description",
        //     content: description
        // }
      ]}
    />
  );
};
