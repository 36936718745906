/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import PodderLogoImg from 'assets/Icons/PodderLogo.png';

type Props = {
  title: string;
};

export const DemographicsTitle = ({ title, ...props }: Props) => {
  return (
    <div tw="flex flex-1 justify-start items-center space-x-4">
      <img src={PodderLogoImg} alt="Podder Logo" tw="w-[4.2rem]" />

      <div tw="font-bold text-4xl">{title}</div>
    </div>
  );
};
