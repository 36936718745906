/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { Checkbox } from 'pretty-checkbox-react';
import { svgShadow } from './twin.styles';

import '@djthoms/pretty-checkbox';

type Props = {
  isListView?: boolean;
  checked: boolean;
  onChange: (checked?: boolean) => void;
  [props: string]: any;
};

export const PrettyCheckbox = ({
  checked = false,
  isListView,
  onChange,
  checkboxCss,
  props,
}: Props) => {
  return (
    <div
      css={[colorCss]}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Checkbox
        checked={checked}
        color="primary"
        hasFocus
        variant="fill"
        animation="jelly"
        onChange={(e) => {
          const checked = e.target.checked;

          if (typeof onChange === 'function') {
            onChange(checked);
          }
        }}
        icon={
          <svg className="svg svg-icon" viewBox="0 0 20 20">
            <path
              tw="fill[white]"
              d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
            ></path>
          </svg>
        }
        css={[
          customCheckbox,
          svgShadow(0.4),
          checkboxCss,
          isListView ? listCss : cardCss,
        ]}
        {...props}
      />
    </div>
  );
};

const cardCss = css`
  ${tw`top[1.5rem]`}
`;

const listCss = css`
  ${tw`top[2rem]`}
`;

const customCheckbox = css`
  ${tw`absolute right[-0.4rem] shadow-xl`}

  label {
    ${tw`font-size[inherit]`}
  }
`;

const colorCss = css`
  .pretty input:checked ~ .state.p-primary label:after,
  .pretty.p-toggle .state.p-primary label:after {
    ${tw`bg-sonnant-green!`}
  }
`;
