/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { css } from '@emotion/react';

import { SimpleMenuSurface } from '@rmwc/menu';
import { ReactComponent as AddNewSvg } from 'assets/Icons/library_add_black_24dp.svg';
import { ReactComponent as StandardSvg } from 'assets/Icons/collections_standard.svg';
import { ReactComponent as SmartSvg } from 'assets/Icons/collections_smart.svg';
import { hoverDropShadow } from 'components/shared/twin.styles';
import { Hint } from 'components/shared/Hint';
import { useClickAway, useToggle } from 'react-use';
import { Ripple } from '@rmwc/ripple';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { showAddingInput } from 'slices/collection.slice';
import { CollectionType } from 'utils/enum';

interface Props {}

export const AddCollectionButton = (props: Props) => {
  const menuRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  useClickAway(menuRef, () => {
    if (!show) return;

    toggleShow(false);
  });

  const [show, toggleShow] = useToggle(false);

  const showAddingInputStandard = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    dispatch(showAddingInput(CollectionType.STANDARD));
    toggleShow(false);
  };

  const showAddingInputSmart = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const handleClickAdd = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    // toggleShow();
    dispatch(showAddingInput(CollectionType.STANDARD));

  };

  return (
    <div ref={menuRef} css={[wrapperCss]}>
      <SimpleMenuSurface
        open={show}
        fixed
        handle={
          <Hint text="Add a new album / folder" enterDelay={200}>
            <AddNewSvg
              tw="fill[#7f8090] cursor-pointer"
              css={[hoverDropShadow(0.15)]}
              onClick={handleClickAdd}
            />
          </Hint>
        }
        anchorCorner="bottomLeft"
        tw="py-1"
      >
        <div>
          <Hint
            text="A standard collection is manually created and managed."
            notTransparent
            arrow
          >
            <Ripple onClick={showAddingInputStandard}>
              <div css={[itemCss]}>
                <StandardSvg />
                Standard Collection
              </div>
            </Ripple>
          </Hint>

          <Hint
            text="Coming Soon: A smart collection will automatically add new items based on search filters."
            align="bottom"
            notTransparent
            arrow
          >
            <Ripple onClick={showAddingInputSmart}>
              <div css={[itemCss, tw`opacity-40 select-none text-black`]}>
                <SmartSvg />
                Smart Collection
              </div>
            </Ripple>
          </Hint>
        </div>
      </SimpleMenuSurface>
    </div>
  );
};

const itemCss = css`
  ${tw`flex items-center padding[.8rem] px-5 text-15 text-sonnant-dark`}

  svg {
    ${tw`mr-3`}
  }
`;

const wrapperCss = css`
  .mdc-menu-surface--anchor {
    ${tw`flex`}
  }
`;
