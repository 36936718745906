/** @jsxImportSource @emotion/react */
import 'twin.macro';

import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from '../../actions/types';
import * as actions from '../../actions';
import {
  ValidityCheck,
  PasswordMatch,
  validatePassword,
  requiredErrorMsg,
  inValidErrorMsg,
  noMatchErrorMsg,
  validatePasswordField,
  AlertBlock,
} from '../../utils/utils';
import './ChangePassword.scss';
import Loader from '../loader/loader';
import { Notification } from '../UiControls/notification/Notification';
import '../../sass/base/_input.scss';
import { useHistory } from 'react-router-dom';
import { AuthService } from 'services';
import { getDomainWithoutSubdomain } from 'utils/utils';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { customToast } from 'utils/toast.util';
import toast from 'react-hot-toast';
import { borderGradientBg } from 'components/shared/twin.styles';

export const ChangePassword = () => {
  const dispatch = useDispatch();
  const history: any = useHistory();
  const confirmRef = useRef<any>();

  const alertsData = useSelector((state: any) => state.alerts);
  const changePasswordData = useSelector((state: any) => state.changePassword);

  const [changepasswordform, setchangepasswordform] = useState({
    newpassword: '',
    confirmpassword: '',
  });

  const { loading } = changePasswordData;
  const { isSuccess, statusMessage } = alertsData;
  const [error, setError] = useState({
    newpassword: true,
    confirmpassword: true,
    isvalid: true,
    ismatch: true,
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    let formData: any = changepasswordform;
    formData[name] = value;
    handleError(name, value, formData);
    setchangepasswordform((preValue) => ({ ...preValue, [name]: value }));
  };

  const handleError = (name: any, value: any, data: any) => {
    let errordata = validatePassword(name, value, data, error);
    setError({ ...error, ...errordata.data });
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    const postData = {
      subdomain: history?.location?.state?.subdomain,
      username: history?.location?.state?.userDetails?.email,
      session: history?.location?.state?.userDetails?.Session,
      newpassword: changepasswordform?.newpassword,
    };
    let updatedUserData = {
      email: history?.location?.state?.userDetails?.email,
      password: changepasswordform.newpassword,
      subdomain: history?.location?.state?.subdomain,
    };

    try {
      customToast.loading('Authenticating...');
      try {
        await AuthService.auth({ ...postData });
      } catch (e: any) {
        console.log('error :>> ', e);
        toast.dismiss();
        if (e?.status === 401) {
          await confirmRef.current?.show();
          history.push('/signin');
          return;
        }
      }

      const signInRes: any = await AuthService.signin({
        ...updatedUserData,
      });
      customToast.success('Authentication completed. Redirecting...');

      window.location.replace(
        `${window.location.protocol}//${
          updatedUserData?.subdomain
        }.${getDomainWithoutSubdomain()}/?access_token=${
          signInRes?.data?.AccessToken
        }&id_token=${signInRes?.data?.IdToken}&refresh_token=${
          signInRes?.data?.RefreshToken
        }`,
      );
    } catch (error: any) {
      toast.dismiss();
      dispatch({
        type: actionTypes.CHANGE_PASSWORD_FAILED,
        payload: error?.response?.data,
      });
      dispatch(actions.handleStatusAlert(error?.message, false));
    }

    setchangepasswordform({
      newpassword: '',
      confirmpassword: '',
    });
  };

  const handleAlertClose = () => {
    dispatch(actions.handleStatusAlertClose());
  };

  const handleClose = (property: any) => {
    if (property === 'newpassword') {
      setError((prevState) => ({
        ...prevState,
        [property]: true,
        isvalid: true,
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        [property]: true,
        ismatch: true,
      }));
    }
  };

  return (
    <>
      <form className="changePasswordForm" onSubmit={handleSave}>
        {loading ? (
          <div className="loader__component">
            <Loader />
          </div>
        ) : (
          ''
        )}
        {statusMessage?.message && !isSuccess && (
          <AlertBlock
            isSuccess={isSuccess}
            statusMessage={statusMessage.message}
            handleClose={handleAlertClose}
          />
        )}
        <div className="grid-x">
          <h1 className="small-12 medium-4 grid-container cell text-center margin-bottom-3 changeHeader" tw="sm-down:(w-full)!">
            Choose New Password
          </h1>
        </div>
        <div className="grid-x">
          <label className="small-12 medium-8 large-4 grid-container cell ">
            New Password
            <input
              value={changepasswordform.newpassword}
              onChange={handleChange}
              className={`${error.newpassword ? 'error' : ''}`}
              name="newpassword"
              type="password"
              placeholder="New Password"
              maxLength={50}
            />
            <Notification
              name={'newpassword'}
              close={handleClose}
              type={!error.newpassword || !error.isvalid ? 'error' : null}
              message={
                !error.newpassword
                  ? requiredErrorMsg('New Password')
                  : inValidErrorMsg
              }
            />
          </label>
        </div>
        <div className="grid-x">
          <label className="small-12 medium-8 large-4 grid-container cell " tw="sm-down:(w-full)!">
            Confirm New Password
            <input
              value={changepasswordform.confirmpassword}
              className={`${error.confirmpassword ? 'error' : ''}`}
              name="confirmpassword"
              type="password"
              maxLength={50}
              onChange={handleChange}
              placeholder="Confirm New Password"
            />
            <Notification
              name={'confirmpassword'}
              close={handleClose}
              type={!error.ismatch || !error.confirmpassword ? 'error' : null}
              message={
                !error.ismatch
                  ? noMatchErrorMsg
                  : requiredErrorMsg('Confirm New Password')
              }
            />
          </label>
        </div>
        <div className="grid-x applywrapper" tw="mt-8! mx-6">
          <div className="small-12 medium-8 large-4 grid-container cell">
            <button
              type="submit"
              tw="relative flex justify-center items-center font-size[1.8rem]! (rounded-full bg-sonnant-purple-4 w-full height[4rem])!"
              css={[borderGradientBg('2px', '#201F66')]}
              disabled={
                !error.isvalid ||
                validatePasswordField(changepasswordform.newpassword) ||
                ValidityCheck(
                  changepasswordform.newpassword,
                  changepasswordform.confirmpassword,
                ) ||
                PasswordMatch(
                  changepasswordform.newpassword,
                  changepasswordform.confirmpassword,
                )
              }
            >
              <div tw="relative flex items-center">
                <span tw="(line-height[initial])!">Apply</span>
              </div>
            </button>
          </div>
        </div>
      </form>

      <ConfirmModal
        ref={confirmRef}
        message="Your authentication session has been expired. Please try to verify your email again."
        title="Session Expired"
        confirmText="Close"
        hideCancel
      />
    </>
  );
};
