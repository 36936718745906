import { combineReducers } from 'redux';
import { captionReducer } from 'slices/caption.slice';
import { chapterReducer } from 'slices/chapter.slice';
import { clipsReducer } from 'slices/clips.slice';
import { globalReducer } from 'slices/global.slice';
import { iabReducer } from 'slices/iab.slice';
import { layersReducers } from 'slices/layers.slice';
import { mediaReducer } from 'slices/media.slice';
import { notificationReducer } from 'slices/notification.slice';
import { paginationReducer } from 'slices/pagination.slice';
import { paymentReducer } from 'slices/payment.slice';
import { playerReducer } from 'slices/player.slice';
import { revivalReducer } from 'slices/revival.slice';
import { toggleReducer } from 'slices/toggle.slice';
import { transcriptReducer } from 'slices/transcript.slice';

import UppyReduxStore from '@uppy/store-redux';

import { alertReducer } from './alerts';
import { changePasswordReducer } from './changePassword';
import { forgetPasswordReducer } from './forgetPasswrod';
import { getPublishedVersionReducer } from './getPublishedVersion';
import { idleTimerReducer } from './idleTimer';
import { libraryReducer } from './library';
import { loginReducer } from './login';
import { publicExportReducer } from './publicExportContentItem';
import { publishLibraryReducers } from './publishLibrary';
import { tenantReducer } from './tenant';
import { updatePasswordReducer } from './updatePassword';
import { collectionReducer } from 'slices/collection.slice';
import { originalReducer } from 'slices/original.slice';
import { rssReducer } from 'slices/rss.slice';
import { omnyReducer } from 'slices/omny.slice';
import { adMarkerReducer } from 'slices/admarker.slice';
import { advancedSearchReducer } from 'slices/advanced-search.slice';
import { watchListReducer } from 'slices/watchlist.slice';
import { apiKeyReducer } from 'slices/api-key.slice';
import { speakerIdentificationReducer } from 'slices/speaker-identification.slice';
import { adBreakReducer } from 'slices/ad-break.slice';
import { userReducer } from 'slices/user.slice';
import { insightReducer } from 'slices/insight.slice';
import { insightTrendingReducer } from 'slices/trending-insight.slice';
import { embedReducer } from 'slices/embed.slice';
import { tagReducer } from 'slices/tag.slice';
import { aliasTermReducer } from 'slices/alias-term.slice';
import { mediaSegmentReducer } from 'slices/media-segment.slice';
import { adminPortalReducer } from 'slices/admin-portal.slice';
import { termExplorationReducer } from 'slices/term-exploration.slice';
import { demographicsReducer } from 'slices/demographics.slice';
import { megaphoneReducer } from 'slices/megaphone.slice';

export const rootReducer = combineReducers({
  alerts: alertReducer,
  adBreaks: adBreakReducer,
  adMarker: adMarkerReducer,
  apiKey: apiKeyReducer,
  advancedSearch: advancedSearchReducer,
  caption: captionReducer,
  clips: clipsReducer,
  changePassword: changePasswordReducer,
  collection: collectionReducer,
  chapter: chapterReducer,
  forgetPassword: forgetPasswordReducer,
  getPublishedVersion: getPublishedVersionReducer,
  global: globalReducer,
  iab: iabReducer,
  idleTimer: idleTimerReducer,
  layers: layersReducers,
  library: libraryReducer,
  login: loginReducer,
  media: mediaReducer,
  notification: notificationReducer,
  original: originalReducer,
  omny: omnyReducer,
  megaphone: megaphoneReducer,
  payment: paymentReducer,
  pagination: paginationReducer,
  player: playerReducer,
  publishLibrary: publishLibraryReducers,
  publicExportContentItem: publicExportReducer,
  revival: revivalReducer,
  rss: rssReducer,
  speakerIdentification: speakerIdentificationReducer,
  tenant: tenantReducer,
  toggle: toggleReducer,
  transcript: transcriptReducer,
  userProfile: userReducer,
  updatePassword: updatePasswordReducer,
  uppy: UppyReduxStore.reducer,
  watchList: watchListReducer,
  insight: insightReducer,
  insightTrending: insightTrendingReducer,
  embed: embedReducer,
  tags: tagReducer,
  aliasTerm: aliasTermReducer,
  mediaSegment: mediaSegmentReducer,
  adminPortal: adminPortalReducer,
  termExploration: termExplorationReducer,
  demographics: demographicsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type DefaultRootState = ReturnType<typeof rootReducer>;
