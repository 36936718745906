import { BaseAPI } from 'apis/amazon';
import { AxiosResponse, CancelTokenSource } from 'axios';
import { isEmpty } from 'lodash';
import { PinnableChart } from 'slices/insight.slice';
import { Layers } from 'utils/enum';
import { ensureArray } from 'utils/generic.util';
import {
  AdvertisingProfile,
  AliasTerm,
  Demographics,
  FilterMenuOptions,
  GeneratedChartRaw,
  Insights,
  PodderDemographic,
  SimilarShowsRaw,
} from 'utils/models';
import { typeMediaEntities } from 'utils/utils';

export type GetInsightByCollection = {
  fromDate?: string | number;
  toDate?: string | number;
  collectionIds?: string[] | null;
  tagIds?: string[];
  integrationIds?: string[];
  layer?: string | null;
};

export type GetAdvertisingProfileParams = Pick<
  GetInsightByCollection,
  'collectionIds' | 'tagIds' | 'integrationIds'
> & {
  categoryName?: string;
};

export type PostInsightParams = {
  category: string;
  terms: string[];
  fromDate?: string | number;
  toDate?: string | number;
  calendarInterval?: string;
} & Partial<FilterMenuOptions>;

export type GetTrendingTermsParams = {
  fromDate?: string | number;
  toDate?: string | number;
  collectionIds?: string[];
  calendarMode?: string;
};

export type TrendingTerm = {
  term: string;
  slopes: number;
  tagline?: string;
};

export type ExportAliasTerm = AliasTerm & {
  doc_count: number;
  relevance_score?: number;
  tagline?: string;
};

export type ExportAliasPayload = {
  layer: Layers;
  isDownloadMetaChart: boolean;
  activeTerms: ExportAliasTerm[];
  isTrendingChart: boolean;
};

export type ExportAdvertisingProfilePayload = GetAdvertisingProfileParams;

export type PostSimilarShowsParams = {
  collectionId: string;
  primaryLayer: Layers;
  secondaryLayer: Layers;
};

export type PostGenerateWordCloudParams = {
  collectionIds?: string[];
  fromDate?: string | number;
  toDate?: string | number;
  keyTerm: string;
  layer?: string | null;
  relatedLayer?: string;
};

export type PutGenerateWordCloudParams = {
  chartId: string;
  disabledTerms: AliasTerm[];
};

export type PostDemographicsParams = Demographics & FilterMenuOptions;

export const InsightService = {
  getInsightsAsync: (params?: GetInsightByCollection) => {
    return BaseAPI.get<Insights>('/insight', {
      params: {
        from_date: params?.fromDate,
        to_date: params?.toDate,
        collection_ids: ensureArray<string>(params?.collectionIds),
        tag_ids: ensureArray<string>(params?.tagIds),
        integration_ids: ensureArray<string>(params?.integrationIds),
        layer: params?.layer,
      },
    });
  },

  postInsightsAsync: <TResponse>(
    params?: PostInsightParams,
  ): Promise<AxiosResponse<TResponse>> => {
    return BaseAPI.post('/insight', {
      category: [params?.category],
      terms: params?.terms ?? [],
      from_date: params?.fromDate,
      to_date: params?.toDate,
      calendar_interval: params?.calendarInterval,

      tag_ids: ensureArray<string>(params?.tagIds),
      collection_ids: ensureArray<string>(params?.collectionIds),
      integration_ids: ensureArray<string>(params?.orgIds),
    });
  },

  postTrendingInsightsAsync: <TResponse>(
    params?: PostInsightParams,
  ): Promise<AxiosResponse<TResponse>> => {
    return BaseAPI.post(
      '/insight',
      {
        category: [params?.category],
        terms: params?.terms ?? [],
        from_date: params?.fromDate,
        to_date: params?.toDate,
        calendar_interval: params?.calendarInterval,

        tag_ids: ensureArray<string>(params?.tagIds),
        collection_ids: ensureArray<string>(params?.collectionIds),
        integration_ids: ensureArray<string>(params?.orgIds),
      },
      {
        params: {
          trending_terms: true,
        },
      },
    );
  },

  getAdvertisingProfileAsync: (params?: GetAdvertisingProfileParams) => {
    return BaseAPI.get<AdvertisingProfile>('/insight', {
      params: {
        collection_ids: ensureArray<string>(params?.collectionIds),
        tag_ids: ensureArray<string>(params?.tagIds),
        integration_ids: ensureArray<string>(params?.integrationIds),
        layer: 'advertising-profile',
        category_name: params?.categoryName,
      },
    });
  },

  getTrendingTermsAsync: (
    params: GetTrendingTermsParams,
    cancelToken?: CancelTokenSource,
  ) => {
    return BaseAPI.get<TrendingTerm[]>('/insight/trending-terms', {
      params: {
        collection_ids: params?.collectionIds,
        from_date: params?.fromDate,
        to_date: params?.toDate,
        calendar_interval: params?.calendarMode,
      },
      cancelToken: cancelToken?.token,
    });
  },

  getPinnedCharts: () => {
    return BaseAPI.get<PinnableChart[]>('/insight/pinned-charts');
  },

  savePinnedCharts: (bodyPinnedCharts: PinnableChart[]) => {
    return BaseAPI.post('/insight/pinned-charts', bodyPinnedCharts);
  },

  getIabMediaDetailsAsync: ({
    categoryName,
    pageSize,
  }: {
    categoryName: string;
    pageSize: number;
  }) => {
    return BaseAPI.get('/insight', {
      params: {
        media_ids_info: 'true',
        category_name: categoryName,
        page_size: pageSize,
      },
    });
  },

  exportAliasTermsInsight: (payload: ExportAliasPayload) => {
    return BaseAPI.post('/insight', {
      layer: typeMediaEntities(payload.layer),
      is_download_meta_chart: true,
      active_terms: payload.activeTerms,
      is_trending_chart: payload.isTrendingChart,
    });
  },

  postSimilarShowsAsync: (
    params: PostSimilarShowsParams,
    cancelToken?: CancelTokenSource,
  ) => {
    return BaseAPI.post<SimilarShowsRaw[]>(
      '/insight/similar-shows',
      {
        collection_id: params.collectionId,
        primary_layer: typeMediaEntities(params.primaryLayer),
        secondary_layer: typeMediaEntities(params.secondaryLayer),
      },
      { cancelToken: cancelToken?.token },
    );
  },

  fetchAllGeneratedTermCharts: () => {
    return BaseAPI.get<GeneratedChartRaw[]>('/insight/generated-word-cloud');
  },

  postGenerateWordCloud: (payload: PostGenerateWordCloudParams) => {
    return BaseAPI.post('/insight/generated-word-cloud', {
      layer: payload.layer,
      collection_ids: payload.collectionIds,
      from_date: payload.fromDate,
      to_date: payload.toDate,
      key_term: payload.keyTerm,
      related_layer: payload.relatedLayer,
    });
  },

  updateGeneratedTermChart: (payload: PutGenerateWordCloudParams) => {
    return BaseAPI.put('/insight/generated-word-cloud', {
      chart_id: payload.chartId,
      setting_disable_terms: JSON.stringify(payload.disabledTerms),
    });
  },

  deleteContentGeneratedChart: (ids: string[]) => {
    return BaseAPI.delete('/insight/generated-word-cloud', {
      params: {
        word_cloud_ids: ids,
      },
    });
  },

  postDemographicsAsync: (params: PostDemographicsParams) => {
    return BaseAPI.post<PodderDemographic[]>('/insight/demographics', {
      query: {
        'Home type': params.homeType,
        'Building age': params.buildingAge,
        Education: params.education,
        Home: params.home,
        Wealth: params.wealth,
        'Life stage': params.lifeStage,
        'Home size': params.homeSize,
        Area: params.area,
        Savings: params.savings,
        'Tech proficiency': params.techProficiency,
        'Employer size': params.employerSize,
        Device: params.device,
        Source: params.source,
        Country: params.country,
        Gender: params.gender,
      },

      tagIds: params.tagIds,
      collectionIds: params.collectionIds,
      orgIds: params.orgIds,

      category_name: isEmpty(params.iab) ? undefined : params.iab,
    });
  },

  exportAdvertisingProfileInsight: (
    payload: ExportAdvertisingProfilePayload,
  ) => {
    return BaseAPI.post(
      '/insight/advertising-profile',
      {
        collection_ids: ensureArray<string>(payload?.collectionIds),
        tag_ids: ensureArray<string>(payload?.tagIds),
        integration_ids: ensureArray<string>(payload?.integrationIds),
        layer: 'advertising-profile',
        category_name: payload?.categoryName,
      },
      {
        params: {
          export: true,
        },
      },
    );
  },
};
