/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { CustomSelectSearch } from 'components/shared/CustomSelectSearch';

// import RatioDefault from 'assets/Icons/ratio-x.svg';
// import RatioSquare from 'assets/Icons/square-blue.png';
// import RatioLandscape from 'assets/Icons/landscape-blue.png';
// import RatioVertical from 'assets/Icons/vertical-blue.png';
import RatioDefault from 'assets/Icons/Sonnant_Default.png';
import RatioSquare from 'assets/Icons/Sonnant_Square_1_1.png';
import RatioLandscape from 'assets/Icons/Sonnant_4_3.png';
import RatioVertical from 'assets/Icons/Sonnant_Vertical_9_16.png';

import { GroupBase, SingleValue, StylesConfig } from 'react-select';
import { Ratio } from 'utils/enum';

const options: RatioOption[] = [
  {
    value: 'Default',
    label: 'Default',
    description: 'Keep original size.',
    icon: RatioDefault,
  },
  {
    value: '1:1',
    label: 'Square (1:1)',
    description: 'Great for Instagram, LinkedIn.',
    icon: RatioSquare,
  },
  // {
  //   value: '16:9',
  //   label: 'Widescreen (16:9)',
  //   description:
  //     'Most universal size. Great for YouTube, FaceBook, and LinkedIn.',
  //   icon: RatioLandscape,
  // },
  {
    value: '9:16',
    label: 'Vertical (9:16)',
    description: 'Great for Instagram Stories, IGTV, Snapchat, or TikTok.',
    icon: RatioVertical,
  },
  {
    value: '4:3',
    label: 'Landscape (4:3)',
    description: 'Great for getting the most visibility in the Instagram Feed.',
    icon: RatioLandscape,
  },
];
interface IProps {
  onChange: (ratio: Ratio) => void;
  disabled?: boolean;
  tabIndex?: number;
  defaultValue: Ratio;
}

type RatioOption = {
  value: string;
  label: string;
  description: string;
  icon: string;
};

export const PublishRatioPicker = (props: IProps) => {
  const handleChangeOption = (e: SingleValue<RatioOption>) => {
    if (!e?.value) return;

    props.onChange(e.value as Ratio);
  };

  const ratioToLabel = (ratio: Ratio) => {
    return options.find((option) => option.value === ratio)?.label ?? 'Default';
  };

  return (
    <div>
      <CustomSelectSearch
        options={options}
        formatOptionLabel={renderCustomOption}
        onChange={handleChangeOption}
        isSearchable={false}
        defaultValue={ratioToLabel(props.defaultValue)}
        disabled={props?.disabled}
        customStyles={customSelectStyles}
        tabIndex={props?.tabIndex}
        canCreate={false}
      />
    </div>
  );
};

const renderCustomOption = ({ label, description, icon }: RatioOption) => (
  <div tw="flex flex-row">
    <div tw="max-width[3.5rem] mr-5">
      <img src={icon} tw="w-full h-full object-contain rounded!" alt="" />
    </div>

    <div tw="flex flex-col w-full">
      <div tw="text-15">{label}</div>
      <div tw="text-12 font-normal opacity-80">{description}</div>
    </div>
  </div>
);

const customSelectStyles: StylesConfig<any, false, GroupBase<any>> = {
  container: (base) => ({
    ...base,
    height: '4rem',
    width: '25rem',
    zIndex: 10, // May affect z-index on session modal
  }),
  menu: (base) => ({
    ...base,
    marginBottom: '4px',
    width: '110%',
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '340px',
  }),
  control: (base) => ({
    ...base,
    height: '3rem',
    width: '18rem',
    backgroundColor: '#f0f3f6',
    borderColor: 'transparent',
    cursor: 'pointer',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    height: '3rem',
    paddingLeft: '0.6rem',
    display: 'flex',
  }),
  placeholder: (base) => ({
    ...base,
    color: 'black',
    fontWeight: 'normal',
    fontSize: '14px',
    position: 'relative',
    top: '-6px',
  }),
  option: (base) => ({
    ...base,
    cursor: 'pointer',
  }),
};
