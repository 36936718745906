/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { isEmpty, isNil, orderBy, toNumber } from 'lodash';
import {
  applyIABSearch,
  isPublicShared,
  isSensitiveIabId,
  scrollToNearestNode,
} from '../../MediaUtilities';
import { Info } from 'components/shared/Info';
import { ReactComponent as Back } from 'assets/Icons/Back.svg';
import { ReactComponent as Next } from 'assets/Icons/Right.svg';
import { ReactComponent as AddTermSvg } from 'assets/Icons/library_add_black_24dp.svg';
import { ReactComponent as WarningSvg } from 'assets/Icons/warning.svg';
import { Hint } from 'components/shared/Hint';
import { iabConfidenceColor, svgShadow } from 'components/shared/twin.styles';
import { AddIABCategoryModal } from 'components/shared/AddIABCategoryModal/AddIABCategoryModal';
import { IABItemModel } from 'utils/models';
import { ReactComponent as CancelIcon } from 'assets/Icons/cancel_circle.svg';
import { removeIABItem } from 'slices/iab.slice';
import { triggerEditModeEvent } from 'slices/player.slice';
import { useSearchParam } from 'react-use';
import { layerSelector } from 'slices/layers.slice';

interface Props {}

export const IABLayer = (props: Props) => {
  const iabIdParam = useSearchParam('iab-category');

  const dispatch = useDispatch();
  // const history = useHistory();

  const iab = useSelector((state: RootState) => state.iab);
  const layers = useSelector((state: RootState) => state.layers);
  const isIABlayer = useSelector(layerSelector).isIAB;
  const toggle = useSelector((state: RootState) => state.toggle);
  const player = useSelector((state: RootState) => state.player);

  const [chapterIndex, setChapterIndex] = useState<number>(0);

  const by = layers.sort.by;
  const isAsc = layers.sort.isAsc;

  const searchedResult = orderBy(
    applyIABSearch({
      items: iab.lockedList,
      searchTerm: layers.searchTerm,
    }).map((item) => ({ ...item, relevance: item.relevance || 0 })),
    [
      (item) => {
        if (by === 'alphabet') return item?.name;
        if (by === 'relevance') return item?.relevance;
      },
      (item) => {
        return toNumber(item?.isManual);
      },
    ],
    // revert order for relevance 1 -> 0
    [
      by === 'relevance' ? (isAsc ? 'desc' : 'asc') : isAsc ? 'asc' : 'desc',
      isAsc ? 'asc' : 'desc',
    ],
  );

  const addIABModalRef = useRef<any>(null);

  const handleAddCategories = async () => {
    if (!toggle.isEditMode) {
      dispatch(triggerEditModeEvent());
      return;
    } else {
      await addIABModalRef.current?.show();
    }
  };

  const [currentItem, setCurrentItem] = useState<IABItemModel | null>(null);

  const handleClickNext = () => {
    if (isNil(currentItem)) return;

    if (!isLastIABItem()) {
      const nextIndex = chapterIndex + 1;
      setChapterIndex(nextIndex);
      jumpToChapterIndex({ iabItem: currentItem, index: nextIndex });
    }
  };

  const handleClickBack = () => {
    if (isNil(currentItem)) return;

    if (!isFirstIABItem()) {
      const lastIndex = chapterIndex - 1;
      setChapterIndex(lastIndex);
      jumpToChapterIndex({ iabItem: currentItem, index: lastIndex });
    }
  };

  const isFirstIABItem = () => isNil(currentItem) || chapterIndex === 0;

  const isLastIABItem = () =>
    isNil(currentItem) ||
    !Array.isArray(currentItem?.chapters) ||
    chapterIndex === currentItem?.chapters?.length - 1;

  const handleClickItem = (iabItem: IABItemModel) => {
    setCurrentItem(iabItem);
    setChapterIndex(0);
    jumpToChapterIndex({ iabItem, index: 0 });
  };

  const jumpToChapterIndex = ({
    iabItem,
    index,
  }: {
    iabItem: IABItemModel;
    index: number;
  }) => {
    if (Array.isArray(iabItem?.chapters) && iabItem.chapters.length > 0) {
      if (!isNil(window?.Video?.currentTime)) {
        const time = iabItem?.chapters?.[index]?.s;

        window.Video.currentTime = time;

        scrollToNearestNode(time);
      }
    }
  };

  useEffect(() => {
    if (!player.loadCompleted) return;

    if (iabIdParam && isIABlayer && searchedResult?.length > 0) {
      const item = searchedResult.find((item) => item.id === iabIdParam);

      if (item) {
        handleClickItem(item);

        // clear iab-category when clicked and scrolled
        // const url = removeSearchQuery('iab-category');
        // history.replace(url);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iabIdParam, isIABlayer, searchedResult.length, player.loadCompleted]);

  const isActiveIABItem = (iabItem: IABItemModel) => {
    if (isNil(currentItem)) return false;

    return iabItem?.id === currentItem?.id;
  };

  const handleRemove = (
    e: React.MouseEvent<HTMLElement>,
    item: IABItemModel,
  ) => {
    e.stopPropagation();

    dispatch(removeIABItem(item));
    setCurrentItem(null);
  };

  const isSensitive = (item: IABItemModel): boolean => {
    return isSensitiveIabId(item?.id) || isSensitiveIabId(item?.parentId);
  };

  return (
    <div tw="w-full cursor-pointer margin-top[6px] overflow-y-hidden pb-6">
      <div className="left_blcok" tw="flex flex-wrap! justify-between pr-0!">
        <div tw="flex mb-3 flex-1">
          <div tw="flex-1 flex justify-start">
            <div className="arrow_block" tw="my-0!">
              <a
                className={`button ${isFirstIABItem() && 'disabled'}`}
                onClick={handleClickBack}
              >
                <Back />
              </a>

              {!isNil(currentItem) && (
                <span
                  tw="text-15 height[4rem] mx-1.5 text-center text-sonnant-dark font-medium border[2px solid] border-sonnant-grey-light rounded whitespace-nowrap py-3 px-5 hover:(border-sonnant-purple-2)"
                  title={currentItem?.hierarchy}
                >
                  <span tw="relative top[-1px]">
                    {currentItem.name} ({currentItem.id})
                  </span>
                </span>
              )}

              <a
                className={`button  ${isLastIABItem() && 'disabled'}`}
                onClick={handleClickNext}
              >
                <Next />
              </a>
            </div>
          </div>

          {!isPublicShared() && (
            <Hint text="You should enable edit mode to add a new category">
              <span tw="cursor-pointer">
                <button
                  className="button btn-primary large"
                  onClick={handleAddCategories}
                  tw="(max-width[unset] width[auto] flex items-center px-4 mb-0 whitespace-nowrap rounded-full)!"
                  css={[
                    !toggle.isEditMode &&
                      tw`bg-disabled-lighter hover:(bg-disabled-lighter)`,
                  ]}
                >
                  <AddTermSvg tw="mr-3" /> Add category
                </button>
              </span>
            </Hint>
          )}
        </div>
      </div>

      <div tw="flex flex-wrap overflow-y-auto height[calc(100% - 3rem)]">
        {searchedResult.map((item) => (
          <div key={item.id} tw="pr-3 my-2">
            <span
              tw="rounded-full flex text-15 text-center text-sonnant-dark font-medium border[2px solid] border-sonnant-grey-3  whitespace-nowrap py-3 pl-4 pr-4 hover:(border-sonnant-purple-2)"
              title={item?.hierarchy}
              css={[
                // iabConfidenceColor(Math.random()),
                iabConfidenceColor(item?.relevance, item?.isManual),
                isActiveIABItem(item) && tw`border-sonnant-purple-2 shadow`,
              ]}
              onClick={() => handleClickItem(item)}
            >
              <div tw="flex items-center">
                {isSensitive(item) && (
                  <div tw="flex items-center mr-2 relative top[-2px]">
                    <WarningSvg css={[svgShadow(0.35)]} />
                  </div>
                )}

                <div css={[isActiveIABItem(item) && tw`text-sonnant-purple-2`]}>
                  <span>
                    {item.name} ({item.id})
                  </span>
                  {!toggle.isEditMode && item.chapters?.length && (
                    <span tw="border[1px solid] border-sonnant-grey-5 rounded-full min-width[2.5rem] h-10 inline-block items-center text-center ml-2 px-1.5">
                      {item.chapters.length}
                    </span>
                  )}
                </div>
                {toggle.isEditMode && (
                  <div
                    className="closeButton"
                    tw="flex items-center justify-center pt-0!"
                    onClick={(e) => handleRemove(e, item)}
                  >
                    <CancelIcon width={16} />
                  </div>
                )}
              </div>
            </span>
          </div>
        ))}

        {isEmpty(searchedResult) && layers.searchTerm && (
          <Info text="No results found" />
        )}
      </div>

      <AddIABCategoryModal ref={addIABModalRef} />
    </div>
  );
};
