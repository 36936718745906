/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { ILibraryItem } from 'utils/models';

interface Props {
  mediaid: string;
  isListView?: boolean;
}

export const DragCounter = (props: Props) => {
  const library = useSelector((state: RootState) => state.library);

  const getDragCount = () => {
    const selectedIds =
      library.selectedRows.map((item: ILibraryItem) => item?.mediaid) ?? [];

    return selectedIds.includes(props?.mediaid)
      ? library?.selectedRows?.length
      : 1;
  };

  return (
    <div
      css={[
        tw`absolute z-index[1] rounded-full bg-sonnant-green shadow-lg flex items-center justify-center text-white font-medium opacity-100`,
        props?.isListView
          ? tw`left[1rem] top[1rem] height[3rem] width[3rem]`
          : tw`right[-3rem] top[-2.5rem] height[3.4rem] width[3.4rem]`,
      ]}
    >
      {getDragCount()}
    </div>
  );
};
