import { useEffect, useState } from 'react';
import { useMeasure } from 'react-use';

export const useReflex = (isTranscriptRightPanel?: boolean) => {
  const [reflexRef, { width }] = useMeasure();

  // MAXIMUM FLEX
  const [flexLeft, setFlexLeft] = useState(0.65);
  const [flexRight, setFlexRight] = useState(0.35);

  const [minWidthLeft, setMinWidthLeft] = useState(width * flexLeft);
  const [minWidthRight, setMinWidthRight] = useState(width * flexRight);

  const [maxWidthLeft] = useState(width);
  const [maxWidthRight, setMaxWidthRight] = useState(width);

  useEffect(() => {
    if (width === 0) return;

    setMinWidthRight(width * 0.2);

    // Default FLEX
    if (width > 2220) {
      setFlexLeft(0.5);
      setFlexRight(0.5);
    } else if (width > 1280) {
      setFlexLeft(isTranscriptRightPanel ? 0.7 : 0.6);
      setFlexRight(isTranscriptRightPanel ? 0.3 : 0.4);
    } else {
      setFlexLeft(isTranscriptRightPanel ? 0.5 : 0.65);
      setFlexRight(isTranscriptRightPanel ? 0.5 : 0.35);
    }
    setMinWidthLeft(width * flexLeft);
    setMaxWidthRight(width * flexRight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, isTranscriptRightPanel, flexLeft, flexRight]);

  return {
    reflexRef,
    flexLeft: {
      grow: flexLeft,
      maxWidth: maxWidthLeft,
      minWidth: minWidthLeft,
    },
    flexRight: {
      grow: flexRight,
      maxWidth: maxWidthRight,
      minWidth: minWidthRight,
    },
  };
};
