/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import 'twin.macro';

import { Hint } from 'components/shared/Hint';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { triggerEditModeEvent } from 'slices/player.slice';
import { Keys } from 'utils/enum';

import { ReactComponent as PlusSvg } from 'assets/Icons/AddKeyterm.svg';
import { ReactComponent as CloseSvg } from 'assets/Icons/close.svg';
import { getLayerAddMessage, getPlaceholderMessage } from 'utils/utils';
import { QuestionTooltipHint } from 'components/shared/Tooltip/QuestionTooltipHint';
import { css } from '@emotion/react';
import { layerSelector } from 'slices/layers.slice';

interface IProps {
  isEditMode: boolean;
  onAddItem: (newItem: string) => unknown;
  setIsInputting: (isInputting: boolean) => unknown;
}

export const AddItemInput = (props: IProps) => {
  const [showItemInput, setShownKeyTermInput] = useState(false);
  const [newItem, setNewItem] = useState('');

  const layers = useSelector((state: RootState) => state.layers);
  const toggle = useSelector((state: RootState) => state.toggle);

  const itemInputRef = useRef<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!showItemInput) {
      setNewItem('');
    }
  }, [showItemInput]);

  const handleKeyDownInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const newKeywordTrim = newItem?.replace(/\s+/g, ' ')?.trim();

    if (!newKeywordTrim) return;

    if (e.key === Keys.ENTER || e.key === Keys.ENTER_NUMPAD) {
      setNewItem(newKeywordTrim);

      props.onAddItem(newKeywordTrim);
      closeInput();
    }

    if (e.key === Keys.ESC) {
      closeInput();
    }
  };

  const handleShowInput = () => {
    if (!toggle.isEditMode) {
      dispatch(triggerEditModeEvent());
    }

    setShownKeyTermInput(true);
    props.setIsInputting(true);

    setTimeout(() => {
      itemInputRef.current?.focus();
    }, 0);
  };

  const closeInput = () => {
    setNewItem('');
    setShownKeyTermInput(false);
    props.setIsInputting(false);
  };

  const currentLayer = useSelector(layerSelector);

  return (
    <>
      {!showItemInput ? (
        <Hint
          disabled={showItemInput}
          text={`Enter Edit mode to add ${getLayerAddMessage(layers.currentLayer)}`}
        >
          <div tw="w-full! whitespace-nowrap">
            <button
              className="btn-primary large addkeyterm"
              onClick={handleShowInput}
              // disabled={!props.isEditMode}
              tw="(min-width[10.5rem] max-width[155px] shadow w-auto rounded-full pr-4)!"
              css={[
                !toggle.isEditMode &&
                  tw`bg-disabled-lighter hover:(bg-disabled-lighter)`,
              ]}
            >
              <PlusSvg />
              <span tw="md-down:font-size[1.4rem]!">
                Add {getLayerAddMessage(layers.currentLayer)}
              </span>
            </button>
          </div>
        </Hint>
      ) : (
        <div
          className="button keywordBtn keywordEdit"
          tw="p-0! mb-0 mr-0! w-full"
        >
          <input
            type="text"
            value={newItem}
            ref={itemInputRef}
            onChange={(e) => setNewItem(e.target.value)}
            onBlur={closeInput}
            onKeyDown={handleKeyDownInput}
            className="small-10 keywordInput"
            placeholder={getPlaceholderMessage(layers.currentLayer)}
            tw="rounded-r-none! w-full placeholder:(text-13)! padding-right[3.2rem]!"
          />
          {currentLayer.isCustomTerms && (
            <QuestionTooltipHint
              message="Applied list terms can be words or phrases. The transcript will be searched for these terms or 
              alternatives on save. If any occurrences are found the term will be displayed in the layer."
              customStyle={tooltipStyle}
              align="top"
            />
          )}
          <button
            onClick={closeInput}
            className="small-2 cancelBtn"
            tw="rounded-l-none"
          >
            <CloseSvg className="whiteIcon" />
          </button>
        </div>
      )}
    </>
  );
};

const tooltipStyle = css`
  ${tw`absolute top[1rem] right[5rem]`}
`;
