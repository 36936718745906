/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { CollapsibleList } from '@rmwc/list';
import { css } from '@emotion/react';

type Props = {
  isShow?: boolean;
  onReset: () => void;
  onSubmit: () => void;
};

export const ResetBottomBar = ({ isShow, ...props }: Props) => {
  const handleReset = () => {
    if (typeof props.onReset !== 'function') return;

    props.onReset();
  };

  const handleSubmit = () => {
    if (typeof props.onSubmit !== 'function') return;

    props.onSubmit();
  };

  return (
    <CollapsibleList handle={<></>} open={isShow}>
      <div tw="fixed bottom-0 w-[100vw] left-0 bg-black z-index[21]">
        <div
          css={[buttonStyle, !isShow && tw`hidden!`]}
          tw="flex items-center justify-between px-[8rem] py-5 m-auto"
        >
          <div tw="text-white text-18 mt-[2px]">You have unsaved changes</div>
          <div className="btnReset">
            <button
              type="button"
              className={`success button reset`}
              tw="(bg-black text-white line-height[1] m-0 active:bg-gray-500 padding[unset])!"
              onClick={handleReset}
            >
              Cancel
            </button>
            <button
              type="button"
              className={`success button apply`}
              onClick={handleSubmit}
              tw="mt-0! text-white!"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </CollapsibleList>
  );
};

const buttonStyle = css`
  .btnReset {
    float: right;
    button {
      margin: 15px 0px 0px 15px;
      box-sizing: border-box;
      border-radius: 3px;
      font-size: 15px;
      height: 40px;
      width: 125px;
    }
    .reset {
      background: #fff;
      border: 2px solid #f0f0f0;
      color: #54566c;
      &:active {
        color: #fff !important;
      }
    }
    .btn-Disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    .apply {
      background: #5551ff;
      color: $color-white;
      letter-spacing: 0.01rem;

      &:focus {
        background: #9b99ff;
        border: 2px solid #5551ff;
        box-shadow: 0px 0px 15px 10px #9b99ff;
        color: $color-white;
      }
      &:active {
        background: #19afa7;
        box-shadow: none;
        border: none;
        color: #fff !important;
      }
    }
  }
`;
