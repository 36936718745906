/** @jsxImportSource @emotion/react */
import 'twin.macro';
import tw from 'twin.macro';

import { useClickAway, useHoverDirty, useLatest, useToggle } from 'react-use';
import React, { useState, useRef, useEffect } from 'react';
import { removeHTMLTag, getTenantidFromIdToken } from '../../../utils/utils';
import { CardCell } from './CardCell';
import { FavoriteEnum, Keys, VideoStatusCode } from '../../../utils/enum';
import {
  addQueryToUrl,
  canShare,
  formatLibraryTitle,
  isPaymentRequired,
  msToTime,
} from 'components/VideoPlayer/Transcription/MediaUtilities';
import { ILibraryItem } from 'utils/models';
import * as actions from 'actions';
import { renameLibraryItem, setLibrary } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, isEmpty, isNil, toNumber } from 'lodash';
import { ThreeDotMenu } from 'components/shared/ThreeDotMenu/ThreeDotMenu';
import { toDateWithHours } from 'utils/date.util';
import { Draggable } from 'react-beautiful-dnd';
import { RootState } from 'reducers';
import { DragCounter } from './DragCounter';
import { Notification } from 'components/UiControls/notification/Notification';
import { ReactComponent as Share } from 'assets/Icons/Share.svg';
import { ReactComponent as Export } from 'assets/Icons/Export.svg';
import { ReactComponent as Link } from 'assets/Icons/link.svg';
import { ReactComponent as MentionSvg } from 'assets/Icons/voice.svg';
import { ReactComponent as IntegrateSvg } from 'assets/Icons/power.svg';
import { ReactComponent as Movie } from 'assets/Icons/movie.svg';
import { ReactComponent as Version } from 'assets/Icons/versions.svg';
import { ReactComponent as AddThumbnailSvg } from 'assets/Icons/add_photo_alternate.svg';
import { ReactComponent as TextSnippet } from 'assets/Icons/textSnippet.svg';
import { ReactComponent as Subtitles } from 'assets/Icons/subtitles.svg';
import { ReactComponent as EditNote } from 'assets/Icons/editNote.svg';
import { ReactComponent as Rerun } from 'assets/Icons/rerun.svg';
import { ReactComponent as EditAttributes } from 'assets/Icons/EditAttributes.svg';
import { ReactComponent as Delete } from 'assets/Icons/delete.svg';

import { css } from '@emotion/react';
import {
  roundedRippleHoverCss,
  simpleMenuDisabled,
} from 'components/shared/twin.styles';
import { tierSelector } from 'slices/payment.slice';
import { useItemFunction } from 'hooks/useItemFunction';
import { Hint } from 'components/shared/Hint';
import { ConfirmModal } from 'components/shared/ConfirmModal/ConfirmModal';
import { HintRestrictedChecker } from 'components/shared/HintRestrictedChecker';
interface ThumbNailItemProps {
  item: ILibraryItem;
  index: number;
}

export const ThumbNailItem = ({ item, index }: ThumbNailItemProps) => {
  const [isOpenMenu, setOpenMenu] = useState(false);
  const [hoverSummary, setHoverSummary] = useState(true);
  const [rotate, setRotate] = useState<any>(null);
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);
  const [isOpenSummary, setIsOpenSummary] = useState(false);
  const [isCursorIn, setIsCursorIn] = useState(false);
  const [isRenaming, toggleRenaming] = useToggle(false);
  const [isHoverIcon, toggleHoverIcon] = useToggle(false);

  const hoverFavoriteLatest = useLatest(isHoverIcon);

  const [defaultTitle, setDefaultTitle] = useState(item.title ?? item.filename);

  const collection = useSelector((state: RootState) => state.collection);
  const library = useSelector((state: RootState) => state.library);
  const favorite = useSelector((state: RootState) => state.global.favorite);
  const payment = useSelector((state: RootState) => state.payment);
  const tier = useSelector(tierSelector);

  const rawLibrary = useSelector(
    (state: RootState) => state.library?.data?.library,
  );

  const global = useSelector((state: RootState) => state.global);

  const { selectedRows } = useSelector((state: RootState) => state.library);

  const libraryArray = formatLibraryTitle(rawLibrary);

  const dispatch = useDispatch();

  const thumbnailRef = useRef(null);
  const renameRef = useRef<HTMLInputElement>(null);
  const isHovering = useHoverDirty(thumbnailRef);
  const hoveringRef = useLatest(isHovering);

  const confirmModalRef = useRef<any>(null);

  const [error, setError] = useState({
    isInvalidTitle: false,
  });

  const [title, setTitle] = useState(item.title ?? item.filename);

  const {
    handleIntegrate,
    handleShare,
    handleExport,
    handleDeleteSingleItem,
    handleRedirectClick,
    handlePublish,
    handleMentionReport,
    handleCopyLink,
    handleViewClip,
    handleUpdateThumbnail,
    handleEditTranscript,
    handleEditCaption,
    handleRerun,
  } = useItemFunction({ item });

  useEffect(() => {
    return () => {
      !isNil(timeoutRef.current) && clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleCancelTitleEditing = () => {
    toggleRenaming(false);
  };

  const triggerSaveTitle = (event: any, item: any) => {
    handleCancelTitleEditing();

    let value = event.target.value.trim();
    // title not change
    if (value === defaultTitle) return;

    if (!value) {
      value = defaultTitle;
      toggleRenaming(true);
      setError({ isInvalidTitle: true });

      return;
    }

    const payload = {
      mediaid: item.mediaid,
      title: value,
      tenantid: getTenantidFromIdToken(),
    };
    dispatch(renameLibraryItem(payload));
    setDefaultTitle(value);

    const data = libraryArray.map((data: any) =>
      data.mediaid === item.mediaid
        ? { ...data, isSelected: false, filename: value, title: value }
        : data,
    );
    dispatch(setLibrary({ library: data }));
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (isEmpty(value)) {
      setError({ isInvalidTitle: true });
    } else {
      setError({ isInvalidTitle: false });
    }
    setTitle(value);
  };

  const handleKeyDownTitle = (event: any, item: ILibraryItem) => {
    if (event.key === Keys.ESC) {
      handleCancelTitleEditing();
      setError({ isInvalidTitle: false });

      const data = libraryArray.map((data: any) =>
        data.mediaid === item.mediaid
          ? { ...data, isSelected: false, title: defaultTitle }
          : data,
      );
      dispatch(setLibrary({ library: data }));
    } else if (event.key === Keys.ENTER || event.key === Keys.ENTER_NUMPAD) {
      if (isEmpty(event?.target?.value?.trim())) {
        return;
      }
      triggerSaveTitle(event, item);
    }
  };

  const openSummary = () => {
    setHoverSummary(!hoverSummary);

    setIsOpenTooltip(!isOpenTooltip);
    setOpenMenu(false);
    setIsOpenSummary(!isOpenSummary);

    if (rotate) setRotate(null);
    else setRotate({ transform: 'rotate(180deg)' });
  };

  useClickAway(renameRef, () => {
    // setRotate(null);
    // setIsOpenTooltip(false);

    if (error.isInvalidTitle) {
      toggleRenaming(false);
      setError({ isInvalidTitle: false });

      const data = libraryArray.map((data: any) =>
        data.mediaid === item.mediaid
          ? { ...data, isSelected: false, title: defaultTitle }
          : data,
      );
      dispatch(setLibrary({ library: data }));
    }
  });

  const timeoutRef = useRef<any>(null);
  let timeout = timeoutRef.current;

  const handleHover = () => {
    // setIsOpenSummary(false); // Turn on if always hide on hover

    timeoutRef.current = setTimeout(() => {
      if (!hoveringRef.current || hoverFavoriteLatest.current) return;

      setIsOpenTooltip(true);
      setOpenMenu(false);
      setRotate({ transform: 'rotate(180deg)' });
    }, 3500);
  };

  const handleRenameTitle = () => {
    toggleRenaming(true);

    setOpenMenu(false);
    setIsOpenSummary(false);

    renameRef.current?.focus();
  };
  const clickThreeDot = () => {
    setOpenMenu(!isOpenMenu);

    if (!isOpenMenu) {
      setIsOpenSummary(false);
    }

    dispatch(actions.setFocusRow(item));
  };

  const handleMouseMove = () => {
    if (!isNil(timeout)) {
      clearTimeout(timeout);
    }

    if (!item.summary) return;

    handleHover();
  };

  const handleMouseEnter = () => {
    setIsCursorIn(true);
  };

  const handleMouseLeave = () => {
    setIsCursorIn(false);
    clearTimeout(timeout);
    setOpenMenu(false);

    // Ignore if opened by clicked
    if (isOpenSummary === false) {
      setIsOpenTooltip(false);
      setRotate(null);
    }
  };

  const shouldDisable = (): boolean => {
    if (
      [
        VideoStatusCode.DRAFT,
        VideoStatusCode.EDITING,
        VideoStatusCode.NOT_EDITED,
        VideoStatusCode.THUMBNAIL_UPDATING,
      ].includes(toNumber(item.statuscode)) ||
      isNil(item.statuscode)
    ) {
      return false;
    }

    return true;
  };

  const disableByStalledStatus =
    (isPaymentRequired(item) || shouldDisable()) && !canShare(item);

  const shouldDisableBySubscription =
    payment.subscription?.isLoading || payment.isExpiredTrial;

  const noTranscript = item?.noTranscript;

  const isLibraryItemThumbnailUpdating =
    toNumber(item?.statuscode) === VideoStatusCode.THUMBNAIL_UPDATING;

  const shouldDisableRerun = (): boolean => {
    return (
      [
        VideoStatusCode.TRANSCRIBING,
        VideoStatusCode.AI_PROCESSING,
        VideoStatusCode.PAYMENT_REQUIRED,
        VideoStatusCode.ERROR,
      ].includes(toNumber(item.statuscode)) ||
      tier.isPAYG ||
      tier.isTrial ||
      payment.isExpiredTrial
    );
  };

  const isSelectMode = !isEmpty(selectedRows);

  const listActions = [
    {
      id: FavoriteEnum.SHARE,
      itemName: 'Share',
      svgComponent: <Share css={[iconSvgStyle, tw`h-6`]} />,
      itemCss:
        (disableByStalledStatus || shouldDisableBySubscription) &&
        simpleMenuDisabled,
      handleOnClick: handleShare,
    },
    {
      id: FavoriteEnum.INTEGRATE,
      itemName: 'Integrate',
      svgComponent: (
        <IntegrateSvg
          css={[iconSvgStyle, tw`(width[2.5rem] height[2.5rem])!`]}
        />
      ),
      itemCss:
        (disableByStalledStatus || shouldDisableBySubscription) &&
        simpleMenuDisabled,
      handleOnClick: handleIntegrate,
    },
    {
      id: FavoriteEnum.EXPORT,
      itemName: 'Export',
      svgComponent: <Export css={iconSvgStyle} />,
      handleOnClick: handleExport,
      itemCss:
        (shouldDisableBySubscription || shouldDisable() || noTranscript) &&
        simpleMenuDisabled,
    },
    {
      id: FavoriteEnum.CREATE_CLIP,
      itemName: 'Create clip',
      svgComponent: <Movie css={iconSvgStyle} />,
      handleOnClick: handlePublish,
      itemCss:
        (disableByStalledStatus ||
          noTranscript ||
          shouldDisableBySubscription) &&
        simpleMenuDisabled,
    },
    {
      id: FavoriteEnum.VIEW_CLIPS,
      itemName: 'View clips',
      svgComponent: <Version css={backgroundIcon} />,
      handleOnClick: handleViewClip,
      itemCss: shouldDisable() && simpleMenuDisabled,
    },
    {
      id: FavoriteEnum.UPDATE_THUMBNAIL,
      itemName: 'Update thumbnail',
      svgComponent: <AddThumbnailSvg css={iconSvgStyle} />,
      handleOnClick: handleUpdateThumbnail,
      itemCss:
        (shouldDisable() ||
          isLibraryItemThumbnailUpdating ||
          item?.statuscode === VideoStatusCode.THUMBNAIL_UPDATING ||
          shouldDisableBySubscription) &&
        simpleMenuDisabled,
    },
    {
      id: FavoriteEnum.VIEW_TRANSCRIPT,
      itemName: 'View transcript',
      svgComponent: <TextSnippet css={iconSvgStyle} />,
      handleOnClick: () => handleRedirectClick(item),
      itemCss: shouldDisable() && simpleMenuDisabled,
    },
    {
      id: FavoriteEnum.EDIT_CAPTIONS,
      itemName: 'Edit captions',
      svgComponent: <Subtitles css={iconSvgStyle} />,
      handleOnClick: () => handleEditCaption(item),
      itemCss:
        (shouldDisable() || noTranscript || shouldDisableBySubscription) &&
        simpleMenuDisabled,
    },
    {
      id: FavoriteEnum.EDIT_TRANSCRIPT,
      itemName: 'Edit transcript',
      svgComponent: <EditNote css={iconSvgStyle} />,
      handleOnClick: () => handleEditTranscript(item),
      itemCss:
        (shouldDisable() || noTranscript || shouldDisableBySubscription) &&
        simpleMenuDisabled,
    },
    {
      id: FavoriteEnum.COPY_LINK,
      itemName: 'Copy link',
      svgComponent: <Link css={iconSvgStyle} />,
      handleOnClick: handleCopyLink,
      itemCss:
        (shouldDisable() || noTranscript || shouldDisableBySubscription) &&
        simpleMenuDisabled,
    },
    {
      id: FavoriteEnum.MENTIONS_REPORT,
      itemName: 'Mentions report',
      svgComponent: (
        <MentionSvg
          css={[iconSvgStyle, tw`[> path:nth-of-type(2)]:fill[#7f8090]`]}
        />
      ),
      itemCss:
        (shouldDisable() || noTranscript || shouldDisableBySubscription) &&
        simpleMenuDisabled,
      handleOnClick: handleMentionReport,
    },
    {
      id: FavoriteEnum.RERUN_AI,
      itemName: 'Rerun AI',
      svgComponent: <Rerun css={iconSvgStyle} />,
      handleOnClick: handleRerun,
      itemCss:
        (shouldDisableRerun() || noTranscript || shouldDisableBySubscription) &&
        simpleMenuDisabled,
    },
    {
      id: FavoriteEnum.RENAME,
      itemName: 'Rename',
      svgComponent: <EditAttributes css={iconSvgStyle} />,
      handleOnClick: handleRenameTitle,
    },
    {
      id: FavoriteEnum.DELETE,
      itemName: 'Delete',
      svgComponent: <Delete css={iconSvgStyle} />,
      handleOnClick: handleDeleteSingleItem,
    },
  ];

  const getItemHref = () => {
    let transcriptHistory = `/transcription/${item.mediaid}`;

    if (library.searchTerm) {
      transcriptHistory = addQueryToUrl(
        transcriptHistory,
        `search=${library.searchTerm}`,
      );
    }

    if (global.preference.paragraphMode) {
      transcriptHistory = addQueryToUrl(transcriptHistory, 'mode=paragraph');
    }

    return transcriptHistory;
  };

  return (
    <div
      className="cell grid-padding-x small-12 medium-3 large-3 xlarge-2 videoThumbnail"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={thumbnailRef}
      key={index}
    >
      <Draggable
        isDragDisabled={!collection?.showSidebar || isRenaming}
        draggableId={item.mediaid}
        index={index}
        shouldRespectForcePress={false}
        disableInteractiveElementBlocking
        key={index}
      >
        {(provided, snapshot) => (
          <div
            className="card cell"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            css={[
              snapshot.isDragging &&
                tw`(border[2px solid] background-color[rgba(255, 255,255, 0.75)]! shadow-lg h-auto pb-4 border-sonnant-purple-2 shadow-lg rounded-md pt-3)!`,
              !snapshot.isDragging && tw`transform-none!`,
            ]}
            key={item?.mediaid}
          >
            <a
              tw="relative"
              onMouseMove={debounce(handleMouseMove, 50)}
              href={getItemHref()}
            >
              {snapshot.isDragging && library.isSelectMode && (
                <DragCounter mediaid={item?.mediaid} />
              )}

              <CardCell libraryItem={item} isDragging={snapshot.isDragging} />
            </a>

            <div
              className="card-section customThumbnail"
              css={[snapshot.isDragging && tw`hidden! h-0!`]}
            >
              <div>
                <div className="card-actions">
                  <div tw="flex flex-row-reverse min-height[5rem]!">
                    {isRenaming ? (
                      <div className="title_rename" tw="flex items-center">
                        <input
                          type="text"
                          name="title"
                          tw="mb-0!"
                          ref={renameRef}
                          onKeyDown={(event) => handleKeyDownTitle(event, item)}
                          onChange={handleOnChange}
                          onBlur={(event) => triggerSaveTitle(event, item)}
                          onFocus={() => setError({ isInvalidTitle: false })}
                          value={title}
                          placeholder="Title"
                          maxLength={128}
                          autoFocus={true}
                        />
                        <Notification
                          name={'emailError'}
                          customStyle={{ top: '3.5rem', left: 0 }}
                          close={() => {
                            handleCancelTitleEditing();
                            setError({ isInvalidTitle: false });
                          }}
                          type={error.isInvalidTitle ? 'error' : null}
                          message={'Invalid Title.'}
                          direction={null}
                          hideCloseButton
                        />
                      </div>
                    ) : (
                      <div
                        className="title_block"
                        onClick={() => handleRedirectClick(item)}
                      >
                        <h5
                          onClick={(e) => e.stopPropagation()}
                          onDoubleClick={(e) => {
                            e.stopPropagation();

                            toggleRenaming(true);

                            setOpenMenu(false);
                            setIsOpenSummary(false);
                          }}
                          title={item.title}
                        >
                          {item.title}
                        </h5>
                      </div>
                    )}

                    <div css={[isRenaming && tw`hidden`]}>
                      <div
                        className="arrow-button"
                        style={rotate}
                        onClick={openSummary}
                        css={[isEmpty(item?.summary) && tw`hidden`]}
                      >
                        <div className="arrow-down arrow-style" />
                      </div>

                      <div
                        tw="overflow-auto mt-3 border-0 break-words w-[90%] absolute background-color[#5550FF] right-[12px] rounded-l-md rounded-r-sm shadow-lg"
                        className={`summary-container ${
                          isOpenTooltip && 'is-active'
                        }`}
                      >
                        <div tw="m-4">
                          {item?.summary && (
                            <h2 tw="text-3xl font-bold text-white">Summary</h2>
                          )}
                          <p tw="text-2xl text-white">
                            {removeHTMLTag(item?.summary) || 'Not Found'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div tw="min-height[3.3rem]">
                    {!isSelectMode && isCursorIn && (
                      <div
                        className="dropdown show thumbnailDropdown"
                        tw="(flex justify-end gap-3 items-center)!"
                      >
                        <div tw="flex items-center space-x-4 justify-end">
                          {listActions
                            .filter((action) => favorite.includes(action.id))
                            .map((item) => (
                              <HintRestrictedChecker
                                isRestrictedPublish={[
                                  FavoriteEnum.SHARE,
                                  FavoriteEnum.INTEGRATE,
                                ].includes(item.id)}
                                isRestrictedEdit={[
                                  FavoriteEnum.EDIT_CAPTIONS,
                                  FavoriteEnum.EDIT_TRANSCRIPT,
                                ].includes(item.id)}
                              >
                                <div
                                  key={item.id}
                                  tw="cursor-pointer flex"
                                  css={item?.itemCss}
                                  onClick={item.handleOnClick}
                                  onMouseEnter={() => {
                                    toggleHoverIcon(true);

                                    !isNil(timeout) && clearTimeout(timeout);
                                  }}
                                  onMouseLeave={() => toggleHoverIcon(false)}
                                >
                                  <Hint
                                    text={item?.itemName}
                                    enterDelay={100}
                                    notTransparent
                                    arrow
                                  >
                                    <div css={[roundedRippleHoverCss]}>
                                      {item.svgComponent}
                                    </div>
                                  </Hint>
                                </div>
                              </HintRestrictedChecker>
                            ))}
                        </div>

                        <ThreeDotMenu
                          openMenu={isOpenMenu}
                          clickThreeDot={clickThreeDot}
                          libraryItem={item}
                          handleRename={handleRenameTitle}
                          handlePublish={handlePublish}
                          handleShare={handleShare}
                          handleDelete={handleDeleteSingleItem}
                          handleExport={handleExport}
                          handleIntegrate={handleIntegrate}
                          handleMentionReport={handleMentionReport}
                          handleCopyLink={handleCopyLink}
                          handleViewClip={handleViewClip}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="time_block">
                <span>{`${toDateWithHours(item?.modifieddatetime)}`}</span>
                <span>{`${msToTime(toNumber(item?.length))}`}</span>
              </div>
            </div>
          </div>
        )}
      </Draggable>

      <ConfirmModal ref={confirmModalRef} type="warning" />
    </div>
  );
};

const iconSvgStyle = css`
  fill: #7f8090 !important;
  width: 20px;
  ${tw`zoom[110%]`}
`;

const backgroundIcon = css`
  width: 25px;
  path {
    fill: #7f8090 !important;
  }
`;
