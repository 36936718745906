/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { index as SonnantLogo } from 'components/UiControls/homeSVG';
import 'foundation-sites';
import { isEmpty, isUndefined, isNull } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { RootState } from 'reducers';
import { useDispatch, useSelector } from 'react-redux';
import { REGEX } from 'utils/regex';
import * as actions from '../../../actions';
import { AlertBlock, formateImageURL } from '../../../utils/utils';
import Loader from '../../loader/loader';
import { Modal } from '../../UiControls/modal/modal';
import '../TenantProfile/TenantProfile.scss';
import { UserService } from 'services';
import { customToast } from 'utils/toast.util';
import { ResetBottomBar } from 'components/shared/ResetBottomBar/ResetBottomBar';

export const TenantProfile = () => {
  const dispatch = useDispatch();
  const tenantDetails = useSelector((state: RootState) => state.tenant);
  const { subscription } = useSelector((state: RootState) => state.payment);
  const displayAlert = useSelector((state: RootState) => state.alerts);
  const [tenantData, setTenentData] = useState(tenantDetails);
  const [isTenantUpdated, setTenantUpdated] = useState(false);
  const [tenantProfile, setTenantProfile] = useState<string>('');
  const [openModal, handleOpenModal] = useState(false);
  const [avatar, setAvatar] = useState<any>(null);
  const [avatarBase64, setAvatarBase64] = useState<
    string | ArrayBuffer | null
  >();
  const inputImg = useRef<any>(null);
  const { statusMessage, isSuccess } = displayAlert;
  const { companyName, description, loading } = tenantData;

  useEffect(() => {
    if (
      tenantDetails &&
      tenantDetails.image &&
      tenantDetails.image !== 'NULL' &&
      !avatarBase64
    ) {
      let image = formateImageURL(tenantDetails.image);
      inputImg.current.src = image;
      setTenantProfile(image);
    }
    setTenentData(tenantDetails);
  }, [tenantDetails]);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    setTenentData((prevState) => ({ ...prevState, [name]: value }));
    setTenantUpdated(true);
  };

  const handleReset = () => {
    setTenentData(tenantDetails);
    if (
      tenantDetails &&
      tenantDetails.image &&
      tenantDetails.image !== 'NULL'
    ) {
      setTenantProfile(tenantDetails.image);
      inputImg.current.src = formateImageURL(tenantDetails.image);
    }
    setTenantUpdated(false);
  };

  const saveTenantData = async () => {
    const payload = {
      companyname: companyName || '',
      description: description || '',
    } as any;

    if (
      !isEmpty(avatarBase64) &&
      !isUndefined(avatarBase64) &&
      !isNull(avatarBase64)
    ) {
      payload.image = (avatarBase64 as string).split(',')[1];
      payload.extension = avatar?.name?.match(REGEX.FILE_EXTENSION)[0] || '';
    }

    const tenantUpdateAsync = UserService.updateTenantDetails(payload);
    customToast.promise(tenantUpdateAsync, {
      loading: 'Updating...',
      success: 'Account updated successfully',
      error: 'Account update failed',
    });

    try {
      await tenantUpdateAsync;
      setTenantUpdated(false);
      dispatch({ type: 'GET_PROFILES' });
    } catch (error) {
      console.error('error :>> ', error);
    }
  };
  const handleModalClose = () => {
    handleOpenModal(false);
    handleClose();
  };

  const handleClose = () => {
    dispatch(actions.handleStatusAlertClose());
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    let file;
    if (e?.target?.files !== null) {
      file = e.target.files[0];
    }
    if (!file?.type.startsWith('image/')) {
      customToast.error('Invalid file format! Please try again');
      return;
    }

    setAvatar(file);

    if (file && file.size <= 4 * 1024 * 1024) {
      // 4MB limit
      handleClose();
      var reader = new FileReader();
      reader.onloadend = function () {
        inputImg.current.src = reader.result;
        setAvatarBase64(reader.result);
        if (reader.result) {
          setTenantProfile(
            reader.result.toString().replace(/^data:(.*,)?/, ''),
          );
        }
        setTenantUpdated(true);
      };
      reader.readAsDataURL(file);
    } else {
      customToast.error('Picture should be less than 4MB');
    }
  };

  return (
    <div>
      {(loading || subscription.isLoading) && (
        <div className="loader__component" tw="top[13rem]">
          <Loader />
        </div>
      )}

      <ResetBottomBar
        isShow={isTenantUpdated}
        onReset={handleReset}
        onSubmit={saveTenantData}
      />

      <div
        className="grid-container fluid"
        tw="overflow-y-auto height[calc(100vh - 13rem)]"
      >
        <div className="grid-x  tenantProfile">
          <div className="cell small-6 large-6">
            <div className="tenantTitle">
              <h2>Account Profile</h2>
            </div>
          </div>
          
        </div>
        <div className="grid-x tenantProfile_picture">
          <div className="cell large-6">
            <h3>Profile Picture</h3>
            <div className="mgn_btm">
              <span className="paramount">
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  id="file"
                  onChange={handleImageUpload}
                  style={{ display: 'none' }}
                />
                <div className="imageWrapper">
                  <img
                    alt=" "
                    id="output"
                    tw="object-contain shadow"
                    className={`${tenantProfile ? 'tenantProfile' : 'noImage'}`}
                    ref={inputImg}
                  />
                  {!tenantProfile && <SonnantLogo width={30} height={32} />}
                </div>
                <label className="paramount_title" htmlFor="file">
                  Edit Account Profile Picture
                </label>
              </span>
            </div>
            <h3>Details</h3>
            <form onSubmit={e => e.preventDefault()}>
              <label>
                Company Name
                <input
                  type="text"
                  maxLength={50}
                  placeholder="Company Name &#63;"
                  onChange={handleChange}
                  name="companyName"
                  value={companyName}
                />
              </label>
              <label>
                Sonnant Domain
                <div className="domain">{tenantDetails?.domainName}</div>
              </label>
              <label>
                Description
                <textarea
                  placeholder="Describe your company and any public content &#63;"
                  onChange={handleChange}
                  name="description"
                  value={description}
                ></textarea>
              </label>
            </form>
          </div>
        </div>
      </div>
      <Modal show={openModal} modalClosed={handleModalClose}>
        <h2>Update Tenant Profile Picture </h2>
        <div className="cell small-12">
          {statusMessage && (
            <AlertBlock
              statusMessage={statusMessage}
              isSuccess={isSuccess}
              hideClose={true}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};
