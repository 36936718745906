/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

import { first, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Ripple } from '@rmwc/ripple';
import { ButtonItem } from 'utils/models';
import { simpleMenuDisabled } from './twin.styles';

type Props = {
  items: ButtonItem[];
  onChange: (item: ButtonItem) => void;
  defaultValue?: ButtonItem['value'];
  disabled?: boolean;
};

export const ButtonGroup = ({
  items,
  onChange,
  defaultValue,
  disabled = false,
}: Props) => {
  const [selected, setSelected] = useState<ButtonItem | undefined>();

  const isSelected = (item: ButtonItem) => item.value === selected?.value;

  useEffect(() => {
    if (isEmpty(items)) return;

    if (isEmpty(defaultValue)) {
      setSelected(first(items));
    } else {
      setSelected(items.find((item) => item.value === defaultValue));
    }
  }, [defaultValue]);

  const handleSelect = (item: ButtonItem) => {
    setSelected(item);

    if (typeof onChange === 'function') {
      onChange(item);
    }
  };

  return (
    <div
      tw="inline-flex rounded-md shadow-sm items-end cursor-pointer"
      css={[disabled && simpleMenuDisabled]}
      role="group"
    >
      {items.map((item, index) => (
        <Ripple key={index} css={[item.disabled && simpleMenuDisabled]}>
          <div
            tw="h-[4rem] min-w-[10rem] shadow-sm justify-center inline-flex items-center space-x-2 px-4 py-2 text-15 text-sonnant-grey-6 border[1px solid] border-sonnant-grey-4 first:rounded-l-lg last:rounded-r-lg hover:bg-gray-100 border-r-transparent last:border-r-sonnant-grey-4 hover:cursor-pointer"
            css={[
              isSelected(item) &&
                tw`border-sonnant-purple-2! border-2 [svg]:fill-[#5551FF] text-sonnant-purple-2`,
            ]}
            onClick={() => handleSelect(item)}
          >
            {item.icon} <span>{item.label}</span>
          </div>
        </Ripple>
      ))}
    </div>
  );
};
