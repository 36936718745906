/** @jsxImportSource @emotion/react */
import 'twin.macro';
import tw from 'twin.macro';
import { css } from '@emotion/react';

import React, { useState } from 'react';
import { useToggle } from 'react-use';
import { Keys } from 'utils/enum';
import { Term } from 'utils/models';
import { Ripple } from '@rmwc/ripple';
import { ReactComponent as CloseIcon } from 'assets/Icons/close.svg';

export const AlterItem = ({
  term,
  isDefault,
  enterEdit,
  handleRemoveAlter,
}: {
  term: Term;
  isDefault?: boolean;
  enterEdit: (id: string, alterText: string) => void;
  handleRemoveAlter: (id: string) => void;
}) => {
  const [isEditAlter, toggleEditAlter] = useToggle(false);
  const editAlter = (e: any) => {
    e.stopPropagation();
    if (isDefault) {
      return;
    }
    toggleEditAlter(true);
  };
  const [alterText, setAlterText] = useState(term?.name);

  const handleKeyDownEdit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (e.code === Keys.ESC) {
      toggleEditAlter(false);
    }
    if (e.code === Keys.ENTER || e.code === Keys.ENTER_NUMPAD) {
      if (!alterText) {
        handleRemoveAlter(term.id)
      }
      else {
        enterEdit(term.id, alterText);
        toggleEditAlter(false);
      }
    }
  };

  return (
    <div tw="flex" onDoubleClick={editAlter}>
      {isEditAlter ? (
        <div>
          <input
            tw="(text-2xl height[3rem] pl-3 mb-0)!"
            type="text"
            value={alterText}
            onChange={(e) => setAlterText(e.target.value)}
            placeholder={alterText}
            onKeyDown={handleKeyDownEdit}
            autoFocus
          />
        </div>
      ) : (
        <>
          <Ripple>
            <>
              <div
                tw="ml-1 mb-1 mr-1.5 text-left p-0.5 text-14 font-medium block w-[12rem] max-w-[15rem] truncate"
                css={isDefault && tw`text-sonnant-grey-6 text-13 mb-0!`}
                className={isDefault ? '' : 'line-clamp-1'}
                title={term?.name}
              >
                {term.name}
              </div>
              {!isDefault && (
                <div onClick={() => handleRemoveAlter(term.id)} tw="flex items-center">
                  <CloseIcon css={fillPath} tw="flex relative top[-2px] cursor-pointer hover:(zoom[103%])" />
                </div>
              )}
            </>
          </Ripple>
        </>
      )}
    </div>
  );
};

const fillPath = css`
  path {
    fill: #7f8090;
  }
`;
