import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneName } from 'components/VideoPlayer/Transcription/MediaUtilities';
import { isArray, isEmpty, isEqual, isNil } from 'lodash';
import { ReactElement } from 'react';
import { LibraryService, UserService } from 'services';
import { FavoriteEnum } from 'utils/enum';
import {
  CustomTerm,
  PreferenceOption,
  PreferenceResponse,
  Term,
} from 'utils/models';
import { customToast } from 'utils/toast.util';
import { getTenantidFromIdToken } from 'utils/utils';
import {
  EmailSetting,
  Monetisation,
  MidRollSetting,
  UserGroupResponse,
} from './../utils/models/media.model';

const updateFavourite = async (newFavourite: FavoriteEnum[]) => {
  try {
    await LibraryService.updateFavoriteAction(newFavourite);
  } catch (error) {
    customToast.error('Update favourite failed');
  }
};

const globalSlice = createSlice({
  name: 'global',
  initialState: {
    showInPageLogin: false,
    showThumbnailModal: false,
    showMentions: false,
    preference: {
      clips: 10,
      suggestedTotalShorts: 0,
      suggestedTotalClips: 10,
      suggestedTotalSegments: 0,
      autoSaveMinutes: 10,
      summary: true,
      suggest: true,
      caption: false,
      captionfile: true,
      transcripts: true,
      keyterm: true,
      products: true,
      nationalities: true,
      datasummary: true,
      orgs: true,
      people: true,
      locations: true,
      globalList: false,
      chapters: true,
      includeUrlCsv: true,
      paraheader: true,
      paragraphMode: false,
      logo: false,
      isSubscriber: false,
      iab: true,
      visualplaybar: true,
      autoSave: true,
      hasAdMarkersLayer: false,
      speakers: true,
    } as PreferenceOption,
    emailSettings: {
      hasProcessingNotification: true,
      hasRevivalNotification: true,
      hasWatchListNotification: true,
    } as EmailSetting,
    monetisation: {
      monetisationEnabled: true,
      preRoll: true,
      midRolls: true,
      postRoll: true,
      midRollsConfigs: {
        isEverySelected: true,
        isMaximumSelected: false,
        everyMinutes: 10,
        maximumMinutes: 10,
        gapMinutes: 10,
      },
      isCheckAdsNumber: false,
      adsNumber: 2,
    } as Monetisation,
    customTerms: [] as CustomTerm[],
    focusCustomList: null as CustomTerm | null,
    favorite: [
      FavoriteEnum.SHARE,
      FavoriteEnum.EXPORT,
      FavoriteEnum.COPY_LINK,
      FavoriteEnum.MENTIONS_REPORT,
    ] as FavoriteEnum[],
    blacklistInsight: [] as Term[],
    insightScrollY: 0,
    currentUserGroup: null as UserGroupResponse | null,
  },
  reducers: {
    addFavorite: (state, action: PayloadAction<FavoriteEnum>) => {
      if (state.favorite.length >= 4) {
        customToast.error('Max of 4 Quick Actions allowed.');
        return;
      }
      const newFavorite = [...state.favorite, action.payload];
      state.favorite = newFavorite;
      updateFavourite(newFavorite);
    },
    removeFavorite: (state, action: PayloadAction<FavoriteEnum>) => {
      const newFavorite = state.favorite.filter(
        (item) => item !== action.payload,
      );
      state.favorite = newFavorite;
      updateFavourite(newFavorite);
    },
    setFavorite: (state, action: PayloadAction<FavoriteEnum[]>) => {
      if (isEmpty(action.payload) || isNil(action.payload)) return;

      state.favorite = action.payload;
    },
    setMidRollItems: (state, action: PayloadAction<MidRollSetting>) => {
      state.monetisation = {
        ...state.monetisation,
        midRollsConfigs: {
          ...state.monetisation.midRollsConfigs,
          ...action.payload,
        },
      };
    },
    setMonetisation: (state, action: PayloadAction<Monetisation>) => {
      state.monetisation = action.payload;
      if (!action.payload?.monetisationEnabled) {
        state.preference.hasAdMarkersLayer = false;
      }
    },
    setTermByListId: (
      state,
      { payload }: PayloadAction<{ newTerm: Term[]; id: string }>,
    ) => {
      const { newTerm, id } = payload;

      const foundIndex = state.customTerms.findIndex((term) => term.id === id);
      if (foundIndex < 0) return;
      state.customTerms[foundIndex].terms = newTerm;
    },
    toggleInPageLogin: (state, action) => {
      if (isNil(action) || isNil(action.payload)) {
        state.showInPageLogin = !state.showInPageLogin;
      } else {
        state.showInPageLogin = action.payload;
      }
    },
    toggleThumbnailModal: (state, action) => {
      if (isNil(action) || isNil(action.payload)) {
        state.showThumbnailModal = !state.showThumbnailModal;
      } else {
        state.showThumbnailModal = action.payload;
      }
    },
    setPreferenceSetting: (
      state,
      { payload }: PayloadAction<PreferenceOption>,
    ) => {
      if (isEmpty(payload)) return;

      if (!isNil(payload.clips) && isNil(payload.suggestedTotalClips)) {
        const newPayload: PreferenceOption = {
          ...payload,
          suggestedTotalClips: payload.clips,
        };

        state.preference = { ...state.preference, ...newPayload };

        return;
      }

      state.preference = { ...state.preference, ...payload };
    },
    setPreference: (state, { payload }: PayloadAction<PreferenceResponse>) => {
      if (isEmpty(payload)) return;

      const settings = payload?.settings;
      if (settings) {
        state.preference = { ...state.preference, ...settings };
      }

      if (payload?.customterms && isArray(payload?.customterms)) {
        state.customTerms = payload?.customterms;
        if (isEmpty(state.focusCustomList)) {
          state.focusCustomList = payload.customterms[0];
        }
      }

      const emailSetting = payload?.emailsettings;
      if (!isEmpty(emailSetting)) {
        // If value if null. Default as true
        state.emailSettings = {
          hasProcessingNotification: emailSetting?.receive_processing ?? true,
          hasRevivalNotification: emailSetting?.receive_revival ?? true,
          hasWatchListNotification: emailSetting?.receive_watch_list ?? true,
        };
      }
    },
    setEmailSettings: (state, { payload }: PayloadAction<EmailSetting>) => {
      if (!payload) return;

      state.emailSettings = { ...payload };
    },
    setCustomTerms: (state, { payload }: PayloadAction<CustomTerm[]>) => {
      if (!payload) return;
      state.customTerms = payload;
    },
    appendCustomVocab: (state, { payload }: PayloadAction<CustomTerm>) => {
      if (!payload) return;

      const cloneNameRecursive = (name: string): string => {
        const isExist = state.customTerms.find((term) => term.name === name);

        return isExist ? cloneNameRecursive(cloneName(name)) : name;
      };

      state.customTerms.push({
        ...payload,
        name: cloneNameRecursive(payload.name),
      });
    },
    toggleTerm: (state, { payload }: PayloadAction<CustomTerm>) => {
      if (!payload) return;

      const index = state.customTerms.findIndex((i) =>
        isEqual(i.id, payload.id),
      );
      if (index < 0) return;

      const found = state.customTerms[index];
      state.customTerms[index] = {
        ...found,
        canApply: found.active ? false : found.canApply,
        active: !found.active,
      };
      state.focusCustomList = state.customTerms[index];
    },
    toggleApplyTerm: (state, { payload }: PayloadAction<CustomTerm>) => {
      if (!payload) return;

      const index = state.customTerms.findIndex((i) =>
        isEqual(i.id, payload.id),
      );
      if (index < 0) return;

      const found = state.customTerms[index];
      state.customTerms[index] = {
        ...found,
        canApply: !found.canApply,
      };
      state.focusCustomList = state.customTerms[index];
    },
    toggleInsightTerm: (state, { payload }: PayloadAction<CustomTerm>) => {
      if (!payload) return;

      const index = state.customTerms.findIndex((i) =>
        isEqual(i.id, payload.id),
      );
      if (index < 0) return; // Not found

      const found = state.customTerms[index];

      state.customTerms[index] = {
        ...found,
        insightVisible: !found?.insightVisible,
      };
      state.focusCustomList = state.customTerms[index];
    },
    setIsShowMentions: (state, { payload }: PayloadAction<boolean>) => {
      if (typeof payload !== 'boolean') return;

      state.showMentions = payload;
    },
    setFocusCustomList: (
      state,
      { payload }: PayloadAction<CustomTerm | null>,
    ) => {
      state.focusCustomList = payload;
    },
    deleteCustomList: (
      state,
      {
        payload,
      }: PayloadAction<{ newCustomTerm: CustomTerm[]; focusTerm: CustomTerm }>,
    ) => {
      const { newCustomTerm, focusTerm } = payload;

      state.customTerms = newCustomTerm;
      state.focusCustomList = focusTerm;
    },
    updateItemCustomList: (
      state,
      { payload }: PayloadAction<CustomTerm | null>,
    ) => {
      if (!payload) return;

      const foundIndex = state.customTerms.findIndex(
        (i) => i.id === payload.id,
      );
      if (foundIndex < 0) return;
      state.customTerms[foundIndex] = payload;
    },
    triggerSaveCustomVocab: (
      state,
      {
        payload,
      }: PayloadAction<{
        message?: string | ReactElement;
        runBackground?: boolean;
      }>,
    ) => {
      (async () => {
        try {
          const tenantid = getTenantidFromIdToken();
          const saveAsync = UserService.savePreference({
            tenantid,
            customterms: JSON.stringify(state.customTerms),
          });

          if (!payload?.runBackground) {
            customToast.promise(saveAsync, {
              loading: 'Saving changes...',
              success: payload?.message || 'Saved successfully',
              error: 'Save failed',
            });
          }

          await saveAsync;
        } catch (error: any) {
          console.log('error :>> ', error);
        }
      })();
    },
    setBlacklistInsight: (state, { payload }: PayloadAction<Term[]>) => {
      state.blacklistInsight = payload;
    },
    setInsightScrollY: (state, { payload }: PayloadAction<number>) => {
      state.insightScrollY = payload;
    },
    setCurrentUserGroup: (
      state,
      { payload }: PayloadAction<UserGroupResponse>,
    ) => {
      state.currentUserGroup = payload;
    },
  },
});

export const {
  setMidRollItems,
  addFavorite,
  setFavorite,
  removeFavorite,
  setMonetisation,
  setPreferenceSetting,
  deleteCustomList,
  updateItemCustomList,
  setTermByListId,
  toggleInPageLogin,
  toggleThumbnailModal,
  setPreference,
  setCustomTerms,
  toggleTerm,
  toggleApplyTerm,
  toggleInsightTerm,
  setIsShowMentions,
  setFocusCustomList,
  setEmailSettings,
  appendCustomVocab,
  triggerSaveCustomVocab,
  setBlacklistInsight,
  setInsightScrollY,
  setCurrentUserGroup,
} = globalSlice.actions;

export const globalReducer = globalSlice.reducer;
