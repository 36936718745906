/** @jsxImportSource @emotion/react */
import 'twin.macro';

import { Hint } from 'components/shared/Hint';
import { memo, ReactElement } from 'react';

interface Props {
  children: ReactElement;
  disabled?: boolean;
}

export const TikTokHelper = memo((props: Props) => {
  return (
    <Hint
      disabled={props?.disabled}
      arrow
      notTransparent
      enterDelay={200}
      leaveDelay={200}
      text={
        <div>
          To be able to send this clip to your TikTok account:
          <ul tw="mb-0 mt-1">
            <li>
              The size of the uploaded clip must be less than or equal to 50 MB.
            </li>
            <li>
              Shared clip must be at least 3 seconds and shorter than 60
              seconds.
            </li>
            <li>Supported video file formats are MP4 or WebM.</li>
          </ul>
        </div>
      }
    >
      {props.children}
    </Hint>
  );
});
